<template>
    <pattern :id="id" x="0" y="0" width="502.2" height="814" viewBox="0 0 502.2 814" patternUnits="userSpaceOnUse" :patternTransform="transform">
    <rect x="-0.1" :fill="colors[`${id}-p-0`]" width="502.5" height="813.9"/>
    <g>
    <defs>
    <rect id="SVGID_1_" x="0" width="502.3" height="813.9"/>
    </defs>
    <clipPath id="SVGID_2_">
    <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
    </clipPath>
    <g style="clip-path:url(#SVGID_2_);">
    <rect x="502.8" y="208.8" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="251.4" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="41.9" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="125.8" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="-0.1" y="83.6" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="83.8" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="-0.1" y="626.3" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="-0.1" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="-0.1" y="41.8" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="502.8" y="793.3" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="-0.1" y="167" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="167.6" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="209.6" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="-0.1" y="125.2" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="20.9" y="20.9" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="481.9" y="814.2" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="146.6" y="20.9" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="104.8" y="20.9" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="41.9" y="125.2" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="20.9" y="146.1" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="62.8" y="20.9" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="125.8" y="83.6" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="125.8" y="41.8" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <path :fill="colors[`${id}-p-1`]" d="M462,793.3v-19.9h20v19.9h20.9v-20.9h-20v-19.9h20v19.9h21v-20.9h-20v-20.9h20v-20.9H509V695h14.8v-33.3
    h-17.9v-11.5h17.9v-27.1h-20v-16.7h20v-23h-18.9v-18.8h18.9v-23H509V528h14.8v-33.4h-20V481h20v-23h-20v-18.8h20v-22.8h-18.9
    v-17.8h18.9v-25.1h-18.9v-16.7h18.9v-25.1h-20v-17.8h20v-23h-20v-18.8h20v-23h-21v-19.9h-21v20.9h20V270h-20v-19.9h-21V271h20
    v19.9h-20V271H442v-20.9h18.9v-20.9h-20v-19.9h20v19.9h21v-20.9h-20v-19.9h20v19.9h21v-20.9h-20v-19.6h20v19.9h21V167h-20v-19.9
    h20v-23H509v-13.6h14.8V77.2H509v-8.4h14.8V35.4h-20V21.8h20V0h-22v19.9h-17.9V0h-25.2v18.8h-18.9V0h-21v19.9h-20V0h-23.1v19.9
    H358V0h-25.2v18.8h-18.7V0h-21v20.9H312v18.8h-18.9V20.9h-20.9v19.9h-20.9V20.9h-21v20.9h20v19.9h-20V41.8h-21v20.9h20v19.9h-20
    V62.7h-21v20.9h20v19.9h-20V83.6h-20.9v20.9h20v19.9h-20v-19.9h-21v20.9h20v19.9h-20v-19.9h-21v20.9h19.9V166h-20v-19.9h-18.9
    v-20.9h18.9v-20.9h-18.9V83.5h18.9V62.6h-21v18.8H85.8V62.6h18.9V41.7h-21v18.8h-21V41.7h-21v20.9h18.9v18.8H41.8V62.6H20.9v20.9
    h18.9v20.9H20.9v20.9h21v-18.8h21v18.8h18.9v20.9H62.8V167h21v-18.8h21V167h20v19.9h-20V167h-21v19.9h-21V167h-21v20.9h20v19.9
    h-20v-19.9h-21v20.9h20v19.9h-20v-19.9h-21v20.9h20v19.9h-20v23h18.9v17.8H-0.1v25.1h18.9v17.8H-0.1v23h20v18.8h-20v23h21v19.6h20
    v19.9h-20v-19.9h-21v20.9h20v19.9h-20v23h14.8v13.6H-0.1v33.4h14.8v8.4H-0.1V570h20v13.6h-20v23h21v19.9h21v-20.9H22v-19.9h20
    v19.9h21v-20.9H43v-19.9h20v19.9h21v-20.9h-20v-19.9h20v19.9h20v20.9H83.8v20.9h20v19.9h-20v-19.9h-21v20.9h20v19.7h-20v-19.9h-21
    v20.9h20V667h-20v-19.9h-21V668h20v19.9h-20V668h-21v20.9h20v19.9h-20v23h18.9v17.8H-0.1v25.1h18.9v16.7H-0.1v25.1h20v18.8H43
    v-18.8h18.9v18.8h23.1v-19.9h18.9v19.9h23.1v-20.9h16.8v20.9h27.2v-17.8h11.5v17.8h33.5v-14.7h14.8v14.7h20.9v-20.9h-14.8v-14.7
    h14.8v14.7h19.8v20.9h23.1v-19.9H308v19.9h33.5v-14.7h8.4v14.7h33.4v-14.7H397v14.7h23.1v-19.9h20v19.9h21v-20.9h-20v-19.9h20
    v19.9h21v-20.9L462,793.3L462,793.3z M501.8,586.7v17.8h-17.9v-17.8H501.8z M488.2,561.6V549h13.6v12.6H488.2z M485,627.3h14.8
    V644H485V627.3z M496.6,671.2v11.5h-8.4v-11.5H496.6z M502.8,716.1v13.6h-14.8v-13.6H502.8z M501.8,419.6v17.8h-17.9v-17.8H501.8z
    M485,394.6v-16.7h15.8v16.7H485z M482.9,293.3h18.9v18.8h-18.9V293.3z M482.9,335.1h17.9v17.8h-17.9V335.1z M482.9,460.4h18.9
    v18.8h-18.9V460.4z M482.9,502h13.6v13.6h-13.6V502z M464.1,661.8v-11.5h14.7v11.5H464.1z M475.6,695.2v8.4h-8.4v-8.4H475.6z
    M480.8,750.5h-13.6v-13.6h13.6V750.5z M480.8,458.3H463v-17.8h17.9V458.3z M464.1,415.5v-15.7h15.8v15.7H464.1z M462,314.2h18.9
    V333H462V314.2z M462,355.9h17.9v16.7H462V355.9z M462,481.3h18.9v18.8H462V481.3z M462,522.9h13.6v13.6H462V522.9z M479.8,569.9
    v12.6h-15.8v-12.6H479.8z M462,607.5h18.9v15.7H462V607.5z M501.8,145.1h-13.6v-13.6h13.6V145.1z M488.2,98.1v-8.4h8.4v8.4H488.2z
    M482.9,42.8h13.6v13.6h-13.6V42.8z M480.8,166h-14.8v-13.6h14.8V166z M467.2,119v-8.4h8.4v8.4H467.2z M463,23h17.9v17.8H463V23z
    M462,63.7h13.6v13.6H462V63.7z M459.9,186.9h-16.8v-14.7h16.8V186.9z M446.2,140.9v-9.5h8.4v9.5H446.2z M442.1,43.9H460v17.8
    h-17.9V43.9z M441,84.6h13.6v13.6H441V84.6z M81.7,104.4H64.9V85.5h16.8C81.7,85.5,81.7,104.4,81.7,104.4z M104.8,123.2h-21v-16.7
    h21V123.2z M22,460.4h14.8V474H22V460.4z M36.6,507.2v8.4h-9.5v-8.4L36.6,507.2L36.6,507.2z M40.8,562.6H27.2V549h13.6V562.6z
    M43,230.6h17.9v17.8H43V230.6z M60.7,273.5v16.7H44v-16.7H60.7z M22,251.5h17.9v17.8H22V251.5z M39.7,294.4v16.7H23v-16.7H39.7z
    M22,336.1h18.9v17.8H22V336.1z M43,454.1v-14.7h13.6v14.7H43z M56.6,485.4v10.5h-8.4v-10.5H56.6z M61.8,541.7H48.2V527h13.6
    C61.8,527.1,61.8,541.7,61.8,541.7z M61.8,416.5h-20v-19.9H22v-19.9h20v19.9h20C61.8,396.6,61.8,416.5,61.8,416.5z M61.8,375.8H43
    v-19.9h18.9V375.8z M61.8,333H44v-17.8h17.9C61.8,315.3,61.8,333,61.8,333z M63.9,209.9h18.9v17.8H63.9V209.9z M82.8,294.4v17.8
    H64.9v-17.8H82.8z M64.9,269.3v-16.7h16.8v16.7H64.9z M63.9,335.1h18.9v18.8H63.9V335.1z M78.6,432.2H63.9v-13.6h14.8v13.6H78.6z
    M80.7,465.6v8.4H68v-8.4H80.7z M82.8,520.9H68v-13.6h14.8V520.9z M83.8,395.6h-21v-18.8h21V395.6z M39.7,813.1H23v-17.8h16.8
    V813.1z M39.7,770.3H23v-16.7h16.8V770.3z M39.7,728.6H22v-17.8h17.9v17.8H39.7z M60.7,791.2H44v-16.7h16.8V791.2z M44,749.4
    v-16.7h11.5v16.7H44z M60.7,707.7H43V690h17.9C60.7,690,60.7,707.7,60.7,707.7z M63.9,669.1h17.9v17.8H63.9V669.1z M81.7,758.8
    v11.5H64.9v-11.5H81.7z M64.9,723.4v-11.5h11.5v11.5H64.9z M82.8,813.1H64.9v-17.8h17.9V813.1z M84.9,648.2h17.9V666H84.9V648.2z
    M91.1,738h11.5v11.5H91.1V738z M85.9,702.5V691h11.5v11.5H85.9z M103.8,792.2H85.9v-17.8h17.9
    C103.8,774.4,103.8,792.2,103.8,792.2z M399,42.8h17.9v17.8H399V42.8z M399,126.3h13.6V143H399V126.3z M415.9,170.2v14.7h-14.8
    v-14.7H415.9z M378.2,63.7H396v17.8h-17.9V63.7z M377.2,147.2H395v16.7h-17.9V147.2z M394.9,191v16.7h-14.8V191H394.9z
    M357.2,42.8h18.9v18.8h-18.9V42.8z M357.2,84.6h17.9v19.9h-17.9V84.6z M357.2,125.2h18.9v20.9h-18.9V125.2z M356.2,311.1v-18.8
    h-18.9v-18.8h18.9v18.8h18.9v18.8H356.2z M375.1,336.1v16.7h-16.8v-16.7H375.1z M335.2,332v-18.8h-20v-18.8h20v18.8h18.9V332
    H335.2z M354.1,356.9v18.8h-16.8v-18.8H354.1z M333.1,334.1v18.8h-17.9v-18.8H333.1z M315.3,269.3v-17.8h17.9v17.8H315.3z
    M336.3,248.4v-17.8h18.9v17.8H336.3z M337.3,23h17.9v17.8h-17.9V23z M336.3,63.7h18.9v18.8h-18.9V63.7z M336.3,105.4h20v18.8h-20
    V105.4z M336.3,147.2h20V167h17.9v17.8h-17.9V167h-20V147.2z M376.2,211.9v16.7h-18.9v-16.7H376.2z M316.3,43.9h17.9v17.8h-17.9
    V43.9z M316.3,84.6h17.9v18.8h-17.9V84.6z M315.3,126.3h18.9v18.8h-18.9V126.3z M315.3,168.1h19.9V188h17.9v19.9h-17.9V188h-20
    v-19.9H315.3z M295.4,64.8h17.9v16.7h-17.9V64.8z M295.4,106.4h17.9v17.8h-17.9V106.4z M294.4,147.2h18.9V166h-18.9V147.2z
    M294.4,189h20v19.9h19.9v19.9h-20v-19.9h-20V189H294.4z M294.4,333v-18.8h20V333H294.4z M312.1,355.9v19.9h-17.9v-19.9H312.1z
    M294.4,291.2v-18.8h17.8v18.8H294.4z M275.5,127.3h16.8v17.8h-16.8V127.3z M274.4,102.3V85.5h16.8v16.7h-16.8V102.3z M273.4,41.8
    h17.9v18.8h-17.9V41.8z M273.4,168.1h18.9v18.8h-18.9V168.1z M273.4,209.9h20v19.9h20v19.9h-20v-19.9h-20V209.9z M254.6,149.3
    h16.7V166h-16.7L254.6,149.3L254.6,149.3z M253.5,122.1v-15.7h16.7v15.7H253.5z M252.5,63.7h17.8v17.8h-17.8L252.5,63.7
    L252.5,63.7z M252.5,189h18.8v18.8h-18.8L252.5,189L252.5,189z M252.5,230.6h19.8v19.9h20v19.9h-20v-19.9h-19.8L252.5,230.6
    L252.5,230.6z M273.4,312.1v-18.8h18.9v18.8H273.4z M292.3,335.1v18.8h-18.9v-18.8H292.3z M233.6,170.2h16.8v16.7h-16.8V170.2z
    M232.5,143v-15.7h15.8V143H232.5z M231.5,84.6h17.9v17.8h-17.9V84.6z M231.5,209.9h18.9v18.8h-18.9V209.9z M231.5,251.5h20v19.9
    h19.8v19.9h-19.8v-19.9h-20V251.5z M252.5,333v-18.8h18.8V333H252.5z M271.3,355.9v18.8h-16.7v-18.8H271.3z M229.4,191v16.7h-16.8
    V191H229.4z M212.7,163.9v-15.7h14.8v15.7H212.7z M210.6,105.4h17.9v17.8h-17.9V105.4z M210.6,230.6h18.9v18.8h-18.9V230.6z
    M210.6,272.4h20v19.9h20v19.9h-20v-19.9h-20V272.4z M231.5,351.7V335h18.9v16.7H231.5z M250.4,378.9v16.7h-16.8v-16.7H250.4z
    M208.5,211.9v16.7h-16.8v-16.7H208.5z M191.7,184.8v-14.7h14.8v14.7H191.7z M189.6,126.3h17.9V143h-17.9V126.3z M189.6,251.5
    h18.9v18.8h-18.9V251.5z M189.6,293.3h20v19.9h20V333h-20v-19.9h-20V293.3L189.6,293.3z M229.4,399.8v16.7h-16.8v-16.7H229.4z
    M212.7,372.6v-16.7h14.8v16.7H212.7z M189.6,334.1h18.9v17.8h-18.9V334.1z M194.8,420.7h13.6v16.7h-13.6V420.7z M191.7,393.5
    v-14.7h14.8v14.7H191.7z M168.6,147.2h16.8v16.7h-16.8V147.2z M185.5,191v14.7h-14.8V191H185.5z M168.6,232.7h18.9v16.7h-18.9
    V232.7z M168.6,272.4h18.9v18.8h-18.9V272.4z M167.6,314.2h21V333h-21V314.2z M172.8,399.8h12.6v11.5h-12.6V399.8z M168.6,372.6
    v-16.7h16.8v16.7H168.6z M173.8,444.7h13.6v13.6h-13.6V444.7z M167.6,502v19.9h20v19.9h-20v-19.9h-20V502H167.6z M169.7,623.1
    v-15.7h15.8v15.7H169.7z M185.5,650.3V665h-14.8v-14.7H185.5z M168.6,582.5v-17.8h17.9v17.8H168.6z M169.7,692h17.9v16.7h-17.9
    V692z M166.5,465.6v13.6h-13.6v-13.6H166.5z M152.9,432.2v-9.5h8.4v9.5H152.9z M164.5,391.4h-16.8v-14.7h16.8V391.4z M147.6,168.1
    h16.8v16.7h-16.8V168.1z M164.5,211.9v16.7h-14.8v-16.7H164.5z M147.6,251.5h18.9v18.8h-18.9V251.5z M147.6,293.3h18.9v19.9h-18.9
    V293.3z M126.8,189h16.8v16.7h-16.8V189z M145.5,232.7v16.7h-16.8v-16.7H145.5z M126.8,272.4h18.9v18.8h-18.9V272.4z M126.8,314.2
    h20v19.9h19.9V354h-20v-19.9h-20v-19.9H126.8z M105.8,209.9h16.8v16.7h-16.8C105.8,226.6,105.8,209.9,105.8,209.9z M124.8,253.6
    v16.7h-17.9v-16.7L124.8,253.6L124.8,253.6z M105.8,293.3h18.9v18.8h-18.9C105.8,312.1,105.8,293.3,105.8,293.3z M105.8,335.1h20
    V355h20v19.9h-20V355h-20C105.8,355,105.8,335.1,105.8,335.1z M128.9,411.3v-13.6h12.6v13.6H128.9z M140.3,444.7v8.4h-8.4v-8.4
    H140.3z M145.5,486.5v13.6h-16.8v-13.6H145.5z M84.9,187.9h18.9v19.9H84.9V187.9z M103.8,273.5v17.8H85.9v-17.8H103.8z
    M85.9,248.4v-17.8h15.8v17.8H85.9z M84.9,314.2h18.9V333H84.9V314.2z M84.9,355.9h20v19.9h20v17.8h-20v-17.8h-20V355.9z
    M119.5,465.6v11.5H108v-11.5H119.5z M110,432.2v-11.5h9.5v11.5H110z M124.8,504.1v17.8h-18.9v-17.8L124.8,504.1L124.8,504.1z
    M84.9,396.6h16.8v15.7H84.9V396.6z M101.7,483.3V500H90v-16.7H101.7z M90,456.2v-12.6h8.4v12.6H90z M112.1,717.1h11.5v11.5h-11.5
    V717.1z M106.9,681.7v-11.5h15.8v11.5H106.9z M105.8,645.1v-17.8h17.9v17.8H105.8z M106.9,753.6h17.9v17.8h-17.9V753.6z
    M104.8,604.4v-18.8h20v18.8H104.8z M125.8,813.1h-20v-18.8h20V813.1z M125.8,542.8v19.9h-21v-19.9H84.9v-19.9h20v19.9H125.8
    v-19.9h21v19.9h19.9v19.9h-20v-19.9H125.8z M148.7,644v-15.7h15.8V644H148.7z M164.5,671.2v15.7h-14.8v-15.7H164.5z M147.6,603.4
    v-17.8h17.9v17.8H147.6z M148.7,711.9h17.9v17.8h-17.9V711.9z M133.1,692h11.5v15.7h-11.5V692z M127.9,664.9v-15.7h15.8v15.7
    H127.9z M126.8,624.2v-17.8h17.9v17.8H126.8z M127.9,732.8h17.9v17.8h-17.9C127.9,750.6,127.9,732.8,127.9,732.8z M126.8,583.5
    v-19.9h18.9v19.9H126.8z M164.5,811.1h-17.9v-17.8h-20v-19.9h20v19.9h17.9V811.1z M185.5,790.2h-17.9v-17.8h-20v-19.9h20v19.9
    h17.9V790.2z M203.2,807.9h-11.5v-11.5h11.5V807.9z M206.3,769.3h-17.9v-17.8h-19.9v-19.9h20v19.9h17.9v17.8H206.3z M208.5,730.7
    h-18.9v-19.9h18.9V730.7z M190.6,602.3v-15.7h15.8v15.7H190.6z M206.3,629.4V644h-14.7v-14.7h14.7V629.4z M208.5,687.9h-16.8
    v-16.7h16.8V687.9z M208.5,561.6h-18.9v-17.8h18.9V561.6z M208.5,520.9h-20V501h-19.9v-19.9h20V501h20v19.9H208.5z M224.2,787.1
    h-11.5v-11.5h11.5V787.1z M229.4,748.4h-20v-16.7h20V748.4z M229.4,708.8h-18.9V690h18.9V708.8z M211.6,581.5v-16.7h15.8v16.7
    H211.6z M227.3,608.6v14.7h-14.8v-14.7H227.3z M229.4,667h-16.8v-16.7h16.8V667z M229.4,541.7h-18.9v-18.8h18.9V541.7z
    M248.3,766.2h-14.8v-13.6h14.8V766.2z M250.4,729.7h-18.9v-18.8h18.9V729.7z M250.4,687.9h-18.9v-18.8h18.9V687.9z M248.3,543.8
    v16.7h-16.8v-16.7H248.3z M231.5,520.9V501h18.9v19.9H231.5z M248.3,587.7v14.7h-14.8v-14.7H248.3z M251.4,646.1h-17.9v-16.7h17.9
    V646.1z M270.3,608.6v17.8h-15.7v-17.8H270.3z M254.6,581.5v-14.7h14.7v14.7H254.6z M252.5,539.7V523h17.8v16.7H252.5z
    M271.3,647.1V667h-18.8v-19.9H271.3z M271.3,690v18.8h-18.8V690H271.3z M271.3,731.8v16.7h-18.8v-16.7H271.3z M292.3,813.1h-20
    v-19.9h-14.7v-17.8h14.7v17.8h20V813.1z M291.2,587.7v15.7h-15.8v-15.7H291.2z M275.5,560.6v-15.7h14.8v15.7H275.5z M273.4,519.8
    V502h17.9v17.8H273.4z M292.3,628.3v17.8h-20v-17.8H292.3z M273.4,687.9v-18.8h18.9v18.8H273.4z M292.3,710.9v18.8h-18.9v-18.8
    H292.3z M313.2,792.2h-20v-19.9h-17.9v-19.9h17.9v19.9h20V792.2L313.2,792.2z M313.2,751.5h-18.9v-19.9h18.9V751.5z M313.2,708.8
    h-18.9V690h18.9V708.8z M313.2,667h-18.9v-18.8h18.9V667z M296.4,566.8h15.8v15.7h-15.8V566.8z M295.4,539.7V524h15.8v15.7H295.4z
    M294.4,498.9v-17.8h17.8v17.8H294.4z M313.2,625.2h-17.9v-17.8h17.9V625.2z M329,807.9h-13.6v-13.6H329V807.9z M332.1,771.4
    h-17.9v-18.8h17.9V771.4z M334.2,729.7h-18.9v-18.8h18.9V729.7z M334.2,687.9h-18.9v-18.8h18.9V687.9z M334.2,646.1h-18.9v-18.8
    h18.9V646.1z M316.3,518.8v-15.7h16.8v15.7H316.3z M333.1,545.9v15.7h-15.8v-15.7H333.1z M315.3,478.1v-17.8h17.9v17.8H315.3z
    M334.2,604.4h-17.9v-17.8h17.9V604.4z M350,787.1h-13.6v-11.5H350V787.1z M353.1,748.4h-16.8v-16.7h16.8V748.4z M355.2,708.8
    h-18.9V690h18.9V708.8z M355.2,667h-18.9v-18.8h18.9V667z M355.2,625.2h-18.9v-18.8h18.9V625.2z M338.3,524h15.8v16.7h-15.8V524z
    M337.3,498.9v-16.7h15.8v16.7H337.3z M336.3,457.2v-17.8h17.9v17.8H336.3z M355.2,583.5h-17.9v-17.8h17.9V583.5z M355.2,416.5
    h-20v-19.9h-21v20.9h20v19.9h-20v-19.9h-21v20.9h20v19.9h-20v-19.9h-21v20.9h20v19.9h-20v-19.9h-20.9v20.9h19.8v19.9h-19.8v-19.9
    h-21v19.9h-21v-19.9h-20v-19.9h20v19.9h21v-20.9h-20v-19.9h20v19.9h21v-20.9h-20v-19.9h20v19.9h20.9v-20.9h-19.8v-19.9h19.8v19.9
    h21v-20.9h-20v-19.9h20v19.9h21v-18.8h21v18.8h20V416.5L355.2,416.5z M370.9,807.9h-8.4v-8.4h8.4V807.9z M370.9,766.2h-11.6v-11.5
    h11.5v11.5H370.9z M374,727.6h-16.8v-16.7H374V727.6z M376.2,687.9h-18.9v-18.8h18.9V687.9z M376.2,646.1h-18.9v-18.8h18.9V646.1z
    M376.2,604.4h-18.9v-18.8h18.9V604.4z M375.1,504.1v15.7h-16.8v-15.7L375.1,504.1L375.1,504.1z M358.3,477.1v-15.7H374v15.7
    H358.3z M357.2,436.3v-17.8h17.9v17.8H357.2z M376.2,562.6h-17.9v-17.8h17.9V562.6z M376.2,395.6h-20v-17.8h20V395.6z
    M358.3,271.4v-19.9h18.9v19.9H396v18.8h-18.9v-18.8H358.3z M395.9,315.3V332h-16.8v-16.7H395.9z M380.3,483.3H396V499h-15.8
    V483.3z M379.3,456.2v-15.7H395v15.7H379.3z M378.2,415.5v-17.8H396v17.8H378.2z M394.9,564.7v18.8h-16.8v-18.8H394.9z
    M391.8,787.1h-8.4v-8.4h8.4V787.1z M391.8,745.3h-11.5v-11.5h11.5V745.3z M394.9,706.7h-16.8V690h16.8V706.7z M396.9,667h-18.9
    v-18.8h18.9V667z M396.9,625.2h-18.9v-18.8h18.9V625.2z M396.9,541.7h-17.9V524h17.9V541.7z M396.9,374.7h-17.9v-17.8h17.9V374.7z
    M396.9,250.5h-18.9v-19.9h18.9V250.5z M396.9,124.2h-20v-17.8h20V124.2z M396.9,40.7h-18.9V21.9h18.9V40.7z M398,251.5h18.9v17.8
    H398V251.5z M416.9,294.4v16.7h-16.8v-16.7H416.9z M401.1,462.4h15.8v15.7h-15.8V462.4z M400.1,435.3v-15.7h15.8v15.7H400.1z
    M399,394.6v-17.8h17.9v17.8H399z M415.9,543.8v16.7h-16.8v-16.7H415.9z M399,669.1h16.8v16.7H399V669.1z M401.1,712.9h11.5v11.5
    h-11.5V712.9z M412.7,757.7v8.4h-8.4v-8.4H412.7z M417.9,813.1h-13.6v-13.6h13.6V813.1z M417.9,646.1H399v-18.8h18.9V646.1z
    M417.9,604.4H399v-16.7h18.9V604.4z M417.9,520.9H400v-17.8h17.9V520.9z M417.9,353.8H400V336h17.9V353.8z M417.9,228.6H399
    v-16.7h18.9V228.6z M417.9,103.3H400V85.5h17.9V103.3z M438.9,191v16.7h-16.8V191H438.9z M422.1,163.9v-11.5h12.6v11.5H422.1z
    M421.1,82.5V64.8H439v17.8h-17.9V82.5z M420,20.9h17.9v18.8H420V20.9z M420,105.4h13.6V119H420V105.4z M420,648.2h16.8v16.7H420
    V648.2z M422.1,692h11.5v11.5h-11.5V692z M433.7,737v8.4h-8.4V737H433.7z M438.9,792.2h-13.6v-13.6h13.6V792.2z M438.9,625.2H420
    v-18.8h18.9V625.2z M422.1,583.5v-16.7h14.8v16.7H422.1z M438.9,539.7H420V523h18.9V539.7z M438.9,500H421v-17.8h17.9V500z
    M436.9,414.4h-15.8v-15.7h15.8V414.4z M437.9,441.5v15.7h-15.8v-15.7H437.9z M438.9,373.7H420v-17.8h18.9V373.7z M438.9,333H421
    v-17.8h17.9V333z M440,290.2h-18.9v-16.7H440V290.2z M440,248.4h-20v-17.8h20V248.4z M441,587.7h17.9v16.7H441V587.7z
    M443.1,560.6v-16.7h11.5v16.7H443.1z M441,627.3h16.8V644H441V627.3z M443.1,671.2h11.5v11.5h-11.5V671.2z M454.6,716.1v8.4h-8.4
    v-8.4H454.6z M459.9,771.4h-13.6v-13.6h13.6V771.4z M459.9,520.9H441V502h18.9V520.9z M459.9,479.2H442v-17.8h17.9V479.2z
    M457.8,393.5H442v-16.7h15.8V393.5z M458.9,420.7v15.7h-15.8v-15.7H458.9z M459.9,353.8H441V335h18.9V353.8z M459.9,312.1H442
    v-19.9h17.9V312.1z"/>
    <rect x="167.6" y="41.8" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="146.6" y="62.7" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    <rect x="188.6" y="20.9" :fill="colors[`${id}-p-1`]" width="21" height="20.9"/>
    </g>
    </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
mixins: [pattern]
}
</script>