<template>
    <pattern :id="id" x="0" y="0" width="2299.5" height="2300" viewBox="0 0 2299.5 2300" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect id="waves_x5F_4" :fill="colors[`${id}-p-0`]" width="2299.5" height="2300"/>
        <g>
            <defs>
                <rect id="SVGID_1_" width="2299.5" height="2300"/>
            </defs>
            <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
            </clipPath>
            <g style="clip-path:url(#SVGID_2_);">
                <g id="waves_x5F_3">
                    <path :fill="colors[`${id}-p-1`]" d="M2314.6,182.9c-23.6,0-65.4,0-122.5-0.3c-17.1-0.8-34.2-1.2-50.8-1.2c-133.5,9.6-259.1,43-386,74.9
				c-22.8,5.6-46,8.1-69.6,7.9c-15.4,0-31-1.8-45.7-4.6c-15-2.8-29.3-6.9-43.5-11.9c-16.3-5.3-32.5-10.4-48.5-15.7
				c-32.1-10.4-64.7-20.6-98.5-29c-17.1-5.3-34.2-10.2-52.1-14.5c-27.6-6.6-55.8-12.5-85.7-16.3c-73.6-9.9-143.9-14.8-216.4-15.1
				c-99.3-0.3-198.9,2.8-298.2,6.6c-16.7,0.5-32.9,1.2-49.6,2c-12.5,0-25.3,0.8-38.2,2.3c-165.6,18.6-324.2,17.4-487.4,12.5
				c-14.3,0.3-28.1,0.5-42.2,0.5c-70,0.8-139.9,1.5-210.3,1.8c0,7.2,0.7,14.5,0.4,21.7c65-1.2,129.7-2,194.9-3.3
				c16.3-0.3,32.5-0.5,48.9-0.5c81.4-0.8,161.4,6.6,242.4,5.8c17.1-0.3,34.6-0.8,51.7-1.8c88.6-5.3,175.4-19.1,264-21.7
				c16.3-0.5,32.9-1.2,49.3-1.8c81.7-2.8,164-4.9,246.1-4.9c79.7-0.3,158.9,4.3,237.2,16.6c26.1,4.1,51.7,9,77.2,15.1
				c17.9,4.1,35,9,52.1,14.2c47.5,11.9,90.7,29,133.2,48.7c10.7,4.9,21.2,10.2,31.7,14.5c14.3,9,29.3,15.3,45.1,20.1
				c15.4,4.3,32.1,6.1,48.9,5.8c64.3-0.8,123.2-17.4,178.5-43.6c14.3-6.6,28.5-12.5,43.5-18.3c86.3-30.1,182.8-44.6,278.6-44.6
				c21.5,0,41.5,0,59.3,0c26.8,0,47.5,0.3,61.8,0.3v-22.4h0.6v0.2H2314.6z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2307.1,2173.9c-25.6,7.2-68,16.7-120.5,16.4c-44-0.3-76.1-7.9-87.9-9.7c-170.4-24.8-353.5-43.2-526.7-10.6
				c-63.9,12.1-126.8,23.3-192.1,33.2c-33.9,5.1-67.6,9.7-101.7,13.8c-34.2,4.2-68.3,7.4-103.8,9.7c-44.7,2.6-89.5,4.5-134.6,6.2
				s-90.4,3.2-135.9,4.3c-93.2,1.9-186.4-4.5-275.8-20.3c-155.3-26.8-299-78.3-470.7-63.6c-68.8,5.9-131.8,16.9-195.7,28.9v19.5
				c72.5-11.7,143.9-25.4,220-29.4c146-7.4,276.3,30,411.7,54.2c78.5,13.8,157.8,24.2,239.9,24.4c52.9,0.2,105.3-0.9,157.5-2.6
				c52.1-1.7,104.2-4.2,155.8-6.5c29.7-1.1,58.9-3.2,88.2-5.9c29.3-2.8,58.2-6.2,87.9-10.2c37.9-4.9,76.1-10.4,113.9-15.5
				c93.9-12.1,186.4-26.9,284.7-32.2c115.6-6.7,227.8,5.4,337.7,19.2c16.1,2,48.5,7.4,93.5,8.8c66.6,2.1,121.2-5,156.4-11.2
				C2308.3,2188,2307.7,2181,2307.1,2173.9z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2307.1,2253.8c-40.4,1.5-73.4,2-95.9,2.3c-44.3,0.5-65.4-0.1-112.5,0.9c-32.9,0.7-55.4,1.7-66.9,2.1
				c-82.6,3.1-139-1.1-309.5-5c-76.4-1.8-115.4-2.6-150.4-2.1c-78.6,1.2-126.9,2-184.8,9.1c-16.8,2.1-57.4,7.5-106.7,10.1
				c-8,0.4-23.7,1.1-40.4,1.8c-9.9,0.4-36.7,1.4-65.7,2.1c-59.6,1.4-66.7-0.2-127.5,1.2c-29.5,0.7-28.3,1-54.5,1.5
				c-38.6,0.7-66.9,0.3-90.4,0c-50.2-0.6-100.4-1.1-150.7-1.8c-82.8-1.2-107.3-1.4-123.4-1.7c-198.6-3.3-290.8-37.5-470.7-30.2
				c-68.8,2.8-131.8,8-195.7,13.7v9.3c72.5-5.5,143.9-12.1,220-14c127.3-3.1,205.6,15.6,411.7,25.8c77.7,3.8,159.5,5.9,159.5,5.9
				c42.7,1.1,74.6,1.4,81.4,1.5c69.7,0.8,80.8-0.4,162.4-0.3c83.1,0.1,93,1.3,149.9,0.2c38.9-0.8,71.6-2.1,88.2-2.8
				c13.4-0.6,44.3-2,87.9-4.8c60.5-4,105.7-8.1,114.8-8.9c31.9-2.8,75.8-5.1,287.9-2c166,2.4,227.8,5.1,331.6,3.5
				c45.8-0.7,95.6-2,95.6-2c63-1.6,116.3-3.6,156.4-5.3C2308.3,2260.4,2307.7,2257.1,2307.1,2253.8z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2300.1,273.7c-9.3,0.5-22.2,2.1-31.5,2.8c-17.1,0.8-22.9,0.9-39.1,2.4c-133.2,6.9-257.5,53-342.5,128.2
				c-10.1,7.6-20.3,14.8-31,22.1c-10.7,7.2-21.5,14.5-33.3,21.4c-5.3,2.8-10.1,6.1-15.4,7.9c-12.5,5.6-25.7,10.2-39.9,14
				c-13.9,3.8-28.5,6.6-43.9,8.1c-16.7,0.8-34.2-0.3-52.5-4.3c-51.7-11.7-69.2-57.5-108.2-84.1c-13.6-11.3-27.2-22.1-41.8-32.2
				c-43.5-30.5-92.8-55.5-153.3-69.1c-14.3-4.3-28.5-8.7-42.8-13.3c-35-10.4-71.7-18.9-108.6-25c-37.5-6.1-75.3-10.2-113.6-12.2
				c-54.6-2.6-109.3-4.1-163.6-4.6c-18.3,0-36.1-0.3-54.6,0c-40.7,0.5-81,0.5-121.7,3.8c-92.4,6-181,22.3-273.4,24.8
				c-16.3,0.8-32.5,1.5-48.9,1.8c-16.3,0.3-32.5,0.3-48.9-0.3c-33.9-1.2-67.9-2.3-101.7-3.3c-33.9-0.9-67.6-1.2-101.3-0.3
				c-18.3,0.8-36.1,2-54.6,2.8c-54.6,3.3-109.3,6.6-164.3,11.3c-1.1,0-0.4,8.1,0,13c54.9-2.6,110-5.6,164.7-7.9
				c20.7-0.8,41.1-2,61.8-2.6c33.9-1.2,67.9-0.5,101.7,0.5c14.7,0.5,29,1.2,43.5,1.8c14.7,0.5,29,0.8,43.5,1.2
				c21.5,0.3,43.5,0.5,65,0.3c91.9,0.8,178.5-14.5,271.4-27.3c53.6-7.6,104.2-8.1,156.7-9.2c17.9,0,35.7,0,54.2,0.3
				c90.4,1.5,181,8.1,272.9,22.9c23.6,3.8,46.8,8.1,69.6,13.7c22.8,5.3,44.7,11.3,66.3,18.6c138.3,28.8,236.8,117.5,280.3,212.5
				c17.5,0.8,35.3,0.8,52.9,0c17.9-1.5,35.3-3.5,52.9-6.4c17.1-2.8,33.3-6.9,49.3-12.2c12.5-4.1,24.7-8.4,36.7-13
				s23.2-10.2,34.2-16.6c10.7-6.1,21.2-12.5,31.4-19.4c63.9-42.6,123.2-92.3,207.5-114.9c73.2-19.7,139.7-36.9,216.9-43.9l11.4-6.5
				L2300.1,273.7z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2304.1,326c-32.5,4.1-55,8-88.7,11.7c-80.1,8.4-154.3,53.1-213.6,90c-15,9.2-29,19.1-41.8,30.1
				c-17.5,13.3-26.8,29.9-43.9,43.6c-20.3,16.6-45.1,28.2-73.9,33.9c-12.2,2.6-23.9,4.9-36.1,7.6c-22.5,4.6-44.4,9.2-66.7,14.5
				c-14.7,3.5-29,7.9-42.2,12.8c-13.6,5.3-26.4,11-38.2,18.3c-11.4,6.9-22.8,15.1-33.3,24.4c-6.1,5.3-17.1,5.3-25.7,2
				c-22.1-7.6-26.8-25.8-33.3-42.3c-32.1-90.5-92.4-192.2-226.1-225.5c-12.2-4.9-24.7-9.9-37.5-14.2c-12.5-4.3-25.7-8.4-38.6-12.2
				c-79.7-22.7-163.6-33.7-248.9-37.7c-19.9-0.8-39.5-1.8-59.3-2.3c-112.7-4.6-219.3,8.4-333.6,25.2c-59.3,8.4-115.6,6.1-173.2,6.1
				c-12.5,0-25.7,0-38.2,0.3c-14.3,0.3-29,0.3-43.1,0.5c-14.3,0-29,0-43.1-0.3c-17.1-0.3-34.6-0.5-51.7-1.5
				c-27.6,0-54.9,0.5-82.1,2.3c-18.3,1.2-36.7,2.3-54.9,3.8c-54.9,3.8-110.3,7.6-165.3,11.3c-0.7,6.4-1.1,13-1.1,19.7
				c54.2-4.1,108.2-8.4,162.4-12.8c17.9-1.5,36.1-2.8,54.2-4.1c15-1.2,30.1-1.8,45.1-2.3s30.1-0.8,45.1-1.2
				c19.9,0.3,39.9,0.3,59.8,0.3c19.8,0,39.9-0.3,60.3-0.5c14.7-0.3,29.7-0.5,44.4-0.5c68.8,0.8,135.4,0,205.3-10.2
				c104.2-14.8,202.2-31.1,305-24.4c13.6,0.5,26.8,1.5,40.4,2c40.4,2.6,81,6.4,121.7,11.3c24.7,3.3,49.3,7.2,73.2,12.2
				s47.1,11,70,18c12.2,3.8,23.9,7.9,35.7,11.9c11.8,4.3,23.2,8.7,34.6,13.3c113.6,37.5,188.3,117.9,204.2,205.6
				c2.9,9.2,4.9,18.6,7.4,27.3c6.9,26.7,19.2,51.4,51.7,69.1c11.8,6.6,29.3,4.3,39.9-3.5c28.5-20.1,41.8-46.7,77.2-63
				c15.8-7.2,32.1-12.8,48.9-17.4c16.7-4.3,33.9-7.9,50.8-11c46.8-10.4,100.4-11.7,135-40.3c35.7-29.9,60.7-63,98.2-90.2
				c9.7-6.6,19.9-12.8,31-18.3c11-5.6,23.2-10.4,35.7-14.8c66.3-21.7,128.3-51.1,198.8-59.3l-0.2-19.2L2304.1,326L2304.1,326z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2299.6,425.1c-6.7,1.1-17.6,3.2-30.5,7.2c-30.5,9.3-61.5,29.1-122.2,68.2c-14.1,9.1-28.2,18.2-29.3,18.9
				c-30.4,39.2-65.4,79-133.5,90.9c-59.3,10.7-121.7,15.1-177.4,31.9c-14.3,4.1-27.2,8.1-39.5,12.8c-36.7,14-60.7,38.9-74.3,66.8
				c-7.4,9.9-17.1,19.7-25.3,30.5c-9.3,10.2-23.6,14.8-38.2,19.1c-13.9,3.3-27.6,5.3-42.2,6.1c-15.4,0.8-31.4,0-46.8-3.3
				s-30.4-8.7-44.7-16.3c-21.5-12.8-37.1-30.1-41.5-50.2c-4.9-21.7-8.2-42.6-11-63.5c-1.1-10.4-2.9-20.9-4.5-31.3
				c-3.6-33.1-12.2-66.8-34.2-96.6c-6.9-10.2-14.7-19.7-23.6-28.8c-26.4-27.5-60.7-51.2-100.4-68.8c-32.5-15.7-68.3-28.5-105.8-38.7
				s-76.8-17.6-116.7-22.9c-20.3-2.6-41.1-4.9-61.4-6.9c-79-8.4-160.3-5.8-237.5,11.9C688.5,378.4,616,386.8,542.5,385
				c-39.5-1.2-78.5-3.8-117.9-2.6c-19.6,0.5-38.6,1.5-58.2,2.6c-17.9,0.8-36.1,1.8-54.6,2.3c-18.3,0.5-36.1,0.8-54.6,0.8
				c-11.4,0-22.1,2.8-32.9,3.5c-17.9,2.3-35.7,4.9-53.6,7.6c-53.6,7.6-108.2,16-164.3,24.7c-15.8,2.3-30.1,4.3-44.7,9.2
				c-2.1,0.5-3.6,1.8-4.9,3.3v4.9C18,432.6,78,422.7,138.7,412.8c15.4-2.3,30.4-4.9,46-7.2c13.9-2.3,27.2-4.6,41.1-6.6
				c10.1-1.8,20.3-3.5,30.1-3.5c18.3-0.3,36.7-0.8,54.9-1.8c18.3-0.8,36.7-1.8,54.6-2.6c15-1.2,30.1-2,44.7-2.3
				c37.9-1.2,75.7,1.8,113.6,4.3c46.4,2.8,90.7,0.3,137.5-6.6c107.4-15.7,205.3-42.1,312.4-32.6c12.9,1.2,25.7,2.3,38.6,3.8
				c12.9,1.5,26.1,2.8,39,4.6c49.6,6.6,96.8,16.3,141.5,29.6c44.7,13.3,86.8,31.1,126.5,53.5c43.1,23.2,68.3,53.1,84.3,86.4
				c5.3,11,9.3,22.7,12.9,34.6s6.1,23.7,8.5,36c2.5,12.2,4,24.4,5.7,36.6c3.2,24.4,6.5,49.1,11.4,72.3c4.9,22.7,21.5,42.3,48.5,56.6
				c18.7,10.4,39.5,17.1,61.4,20.6c19.2,2.6,37.5,2.6,55.3,0.8c17.5-1.8,34.2-6.1,50.4-13c6.9-3.3,12.5-6.9,17.9-11.7
				c8.5-7.6,16.3-15.3,23.6-22.9c15.4-29.9,35.7-61.6,72.1-80.1c12.2-6.1,23.6-10.7,41.8-13.7c40.7-7.6,77.8-16,120-19.1
				c83.5-6.1,151.3-43.3,190.8-93.8c9.7-6.3,20.3-13.5,31.7-21.4c59.5-41.5,77.7-61.9,116-73.2c13.3-3.9,24.4-5.4,30-6
				C2300.9,431.3,2300.3,428.2,2299.6,425.1z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2302.2,1793.2c-6.7,2.3-17.4,5.1-30.5,4.7c-30.9-1-53.2-19.4-60.5-25.3c-25.9-20.8-82.4-54-194.4-121.9
				c-27.5-16.6-109.5-67.8-201.3-71.1c-104.2-3.8-191.1,42.1-255.3,98.7c-114.3,101.6-196.4,224-370.6,273.2
				c-39.5,11.7-80.6,20.4-124,25.2c-29,2.3-57.8,3.5-86.8,3.3c-57.8-0.3-116-5.6-172.9-17.4c-104.9-22.1-193.9-64.2-268.5-121.3
				c-53.6-41-100.4-81.3-160.7-118.5c-68.3-42.1-165.3-17.6-232.1,12.5c-63.1,25.5-122.1,50.2-186.4,72.3v19.7
				c50.8-15.1,98.2-34.6,147.2-54.3c58.2-22.7,118.5-44.6,185-42.3c53.3,2.3,97.5,26.2,132.1,54.6c70.7,57.1,152.5,108,242.8,145.7
				s189.6,62.2,296.1,66.8c49.3-0.3,97.5-3.8,145.7-10.7c39-6.4,75.7-16,111.1-27.5c158.2-53.5,239.9-165.7,347.4-264.5
				c61.4-56.9,149.7-97.3,249.7-96.1c73.4,0.9,135.7,43.2,193.5,77.8c66.2,39.6,135.3,74.7,199.2,117.9c8,5.4,31.7,21.7,64.1,22.6
				c12.5,0.4,22.7-1.6,29.2-3.3C2301.7,1807.1,2302,1800.2,2302.2,1793.2z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2300.6,1826.5c-6.1,2-16.2,4.8-28.8,6.2c-29.4,3.1-55.1-3.9-76.1-12.6c-63.6-26.4-140.4-93.5-200-129.8
				c-34.2-20.8-96.4-59.8-168.1-66.8c-110-10.7-200,40-267.8,97.8c-115.1,98.9-207.8,214.8-380,260.4
				c-34.6,9.6-70.3,16.8-107.4,20.9c-29.3,2.8-58.5,4.3-87.9,4.6c-58.9,0.5-117.6-3.8-176.1-15.7c-58.5-11.9-110.7-29-163.1-52.2
				c-93.2-40.6-158.2-95-237.5-146.3c-29.3-18.3-61.4-32.2-99.7-39.2c-61.8-10.7-121.2,4.3-175.7,23.2
				c-59.8,21-114.7,45.5-175.4,64.3v21.2c64.7-20.1,125-46.4,189.6-68c71.7-23.7,161.1-27.8,227,10.4
				c66.3,38.7,129.3,78.4,196.4,113.2c67.1,34.9,137.9,64.7,219.6,83.6c60.7,14,122.5,19.7,184.6,20.1c31,0,62.2-1.2,93.2-4.1
				c25.7-2.3,50.4-6.4,75-11.7c23.9-5.3,47.5-11.7,70.7-19.1c79.3-25,143.2-66,202.2-112.6c110-87.9,189.9-217.7,369.3-228.5
				c85.8-5.2,162.5,43.2,196.4,63.2c57.4,33.9,144.5,107.4,210.7,133.4c39.5,15.6,69.3,13.7,83.1,11.9c11.2-1.4,20.1-3.9,25.5-5.6
				C2300.5,1841.3,2300.5,1833.9,2300.6,1826.5z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2301.1,1915.9c-92.9,23.2-153.7,9-191.8-7.9c-59.3-26.2-66.2-59.9-145.3-103.2
				c-26.8-14.7-86.6-49.1-157.8-52c-91.9-3.8-174.2,31.9-232.1,81.3c-80.6,68-161.4,131-266.4,175.3c-39,15.3-78.1,27-119.2,36.6
				c-49.3,11-100,18.3-151.8,20.4c-35.7,1.8-71.2,2-107.1,0.5c-35.4-1.5-71.2-4.6-107.8-10.4c-177-27.5-305.6-104.8-457.7-168.1
				c-48.9-20.4-99.7-35.1-156.2-30.5c-39.5,3.5-76.5,10.7-112.2,22.9c-47.1,16-91.9,34.2-138.6,49.7v22.7
				c50-15.7,97.5-35.1,148.2-51.4c60.3-19.7,129-24.2,191.7-7.6c97.5,26.2,179,67.3,270,106c129,54.8,269.3,85.6,415.7,83.3
				c37.5-0.5,75-2.3,111.8-5.3c38.6-4.1,76.1-10.7,112.7-19.4c7.3-1.7,42.1-10.1,77.8-22.1c30.6-10.3,89.8-32.7,186.4-93.1
				c109.9-68.8,101.8-82.7,175.3-117.6c50.2-23.8,95.4-44.6,155.1-41.8c50.2,2.3,85.8,20.2,142.6,49.4
				c98.1,50.5,106.8,82.5,168.3,105.2c38.5,14.2,99.4,25.4,188.7,0.4C2301.3,1931.4,2301.2,1923.6,2301.1,1915.9z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2301.3,2012.7c-17.2,3.1-41.7,6.7-71.3,8.4c-58.2,3.2-106.1-2.9-140-9.1c-140.5-25.3-174-77.5-270-83.9
				c-122.1-8.1-219.6,47.4-320.6,93.2c-74.3,33.7-148.6,58.6-227.8,80.1c-32.5,8.7-65.4,15.3-98.9,20.1
				c-33.3,4.6-67.6,7.9-101.3,9.2c-29,1.8-58.2,2.8-87.5,3.3s-58.9,0.3-88.6-1.2c-59.8-2.6-121.7-9.9-184.3-22.4
				c-98.5-20.4-187.5-57.5-277.8-91.5c-89.9-30.1-178.5-53.5-269.3-47.6c-68.8,8.1-136.8,34.6-206.7,54.8v24.4
				c63.6-17.1,125.4-39.5,188.8-49.9c77.2-9.2,154.2,5.3,231.8,25.8c70.3,21.7,141.5,46.1,214.3,68.5
				c72.8,22.4,146.8,40.6,222.5,50.5c39,3.8,77.2,5.8,115.6,5.8c38.2,0,75.7-0.8,112.7-3.3c54.6-1.8,108.2-7.6,160.7-16.3
				c25.3-4.1,50.4-9,75.3-14.8s49.3-12.2,73.9-19.4c34.2-10.5,67.7-21.4,100.9-33c78.6-27.5,91.8-36,142.7-54.4
				c94.2-34,141.2-51,190.9-50.9c31.1,0,70.9,11.2,150.4,33.5c56.3,15.8,123.7,38.2,189.1,48.3c21.8,3.4,42.5,5.2,52.5,6.1
				c32.9,2.9,49.8,4.3,71.4,1.9c18.9-2.1,35.9-6.5,50.7-11.8C2301.5,2029,2301.4,2020.8,2301.3,2012.7z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2300,586.4c0.5-2.9,1-5.8,1.5-8.7c-28.3,13.8-49,28.1-62.8,38.8c-15.7,12.2-19.4,17.3-39.5,32.9
				c-20.5,15.9-33.6,26.1-52.2,35.8c-26.1,13.7-48.2,18.7-85.2,27.1c-22.2,5-33.4,7.6-49.4,8.3c-25.5,1.2-28.2-3.4-56-1.4
				c-17,1.2-21.1,3.3-45.5,4.9c-16.6,1-18,0.3-28.2,1.4c-6.8,0.7-25.3,2.9-45.2,11.1c-24.2,10-41.4,25.2-52.5,37.4
				c-13.8,13.2-24.4,24.6-31.7,32.6c-10.1,11-19.9,22.1-29.7,32.6c-9.3,8.4-19.2,16.6-29,24.2c-12.5,11.7-26.1,20.6-42.8,27.8
				c-14.7,7.2-31,13-47.5,17.6c-17.1,4.9-34.6,8.4-53.3,11c-16.7,2-33.3,3.8-51.3,4.3c-12.9,0.5-26.1,0.8-39.5,0.3
				c-13.6-0.5-27.2-1.8-41.1-3.8c-26.8-3.3-52.9-9-76.8-17.6c-23.9-8.7-46-20.4-65-35.1c-32.1-25.2-50.4-57.5-41.8-94
				c8.5-32.2,22.8-62.2,29-93.8c5.7-29,6.1-58.4-5.3-87.9c-8.9-23.2-24.3-43.6-44.7-61.2c-20.3-17.6-46-32.6-75.3-44.1
				c-37.9-15.1-76.8-24.7-117.9-31.3c-13.9-2-27.6-4.1-41.8-5.6c-17.9-2.6-36.1-4.1-54.6-3.8c-18.3,0.3-36.7,2.3-54.6,6.4
				c-22.8,5.6-45.1,11.9-67.6,18.3s-44.4,13-67.1,19.4c-60.3,18-126.1,17.1-189.6,12.8c-15.8-1.2-32.1-2.3-47.9-3.5
				c-15.8-1.2-31.7-2.6-47.5-3.5c-15-0.8-30.1-1.8-45.7-2.3c-17.5-0.8-35-1.5-52.5-1.8c-20.3,0.5-40.4,1.5-60.7,2.8
				c-12.5,0.8-24.7,2-37.1,3.5c-12.2,1.5-24.3,3.3-37.1,5.6c-20.3,4.3-39.9,9.6-59.8,15.1c-12.9,4.1-26.1,8.1-39,12.5
				c-12.9,4.3-26.1,9-39,14c-43.1,16.3-83,33.1-122.8,53.1v5.6c58.5-24.7,117.9-47.4,179.9-67c14.3-4.6,28.5-9,43.1-13.3
				c19.6-5.8,39.5-10.4,59.3-14.2c19.2-3.5,39-6.4,58.5-6.4c20.3,0.3,40.7-0.3,60.7-1.2c24.3-1.5,48.9-2.6,73.9-2.3
				c12.5,0.3,25.3,1.2,37.9,2.3c12.5,1.2,24.7,2.3,37.5,3.8c80.6,15.1,166.7,16.6,242.4-11.9c12.9-4.1,26.4-8.1,39.5-12.2
				c12.9-4.1,26.1-8.1,39.5-11.9c20.3-5.6,40.7-9.9,62.2-11.9c12.9-1.2,25.7-1.8,38.6-1.8c25.7,0,51.3,2.6,76.1,7.2
				c13.6,2,26.4,4.3,39.9,6.9c83.5,15.7,159.6,52.2,192.1,109.8c17.9,31.9,18.7,65,12.9,98.1c-4,28.8-16.7,57.8-22.8,86.8
				c-6.1,29-5.7,58.1,16.7,85.9c15,18.9,33.3,34.2,54.2,46.4c28.1,16.3,58.9,26.7,91.5,32.8c31.7,5.8,62.2,7.9,91.5,6.6
				c13.6-0.8,26.8-2,39.5-3.5c20.7-1.5,40.4-4.6,58.9-9.2c18.7-4.3,36.7-10.2,53.6-16.8c9.7-3.5,18.3-7.2,27.2-12.5
				c14.3-7.9,26.8-17.1,39.5-27.5c8.9-6.9,17.5-13.7,26.4-20.6c8.5-6.9,16.3-14.2,23.6-22.1c8.4-9.2,12.7-13.7,23.2-26.5
				c31.4-38.3,65.4-54.8,65.4-54.8c7.9-3.8,20.5-8.8,72-12.2c56.5-3.7,62,1,100.8-1.4c47.9-3,93-5.8,135.6-33
				c10.6-6.8,9.8-7.8,56.3-41.4c17.4-12.5,25.8-18.3,46.2-33C2271.8,607.1,2288.9,594.6,2300,586.4z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2300.6,600.1c-0.5,0.2-1.4,0.6-2.4,1.2c-52.8,25.9-65.1,56.5-111.3,86.8c-5.9,3.8-17.5,9.8-40.7,21.7
				c-20.7,10.6-56.5,29.1-76,36.3c-64.6,24.2-97.5-18.7-169.8-7.1c-24.6,3.9-61.1,15.4-102.7,52.2c-6.8,6.3-17.7,16.3-31,29
				c-10.1,9.6-19.9,19.4-30.1,27.8c-12.2,9.2-24.3,18.6-37.1,27.8c-12.5,9.2-25.3,18.3-38.2,27.3c-11.8,4.9-23.9,9.6-36.1,13.7
				c-24.7,8.1-50.4,14-77.8,17.6c-16.7,2.6-33.9,4.9-51.3,6.1c-16.3,1.8-32.5,2.8-49.3,3.5c-16.7,0.3-33.9,0-51.3-1.2
				c-33.9-3.3-67.9-10.7-102.5-23.5c-34.2-14.5-61.8-35.1-74.3-64.2c-14.3-28.8-15.4-61.2-4.5-92.3c24.7-68,61.8-145.7-6.1-210.5
				c-47.5-45.1-114.7-68.8-187.5-80.7c-14.7-2.3-29.3-4.3-44.4-5.8c-16.3-1.5-32.5-1.8-48.9-1.5c-15.8,0.5-31.7,2-47.5,4.3
				c-16.7,2.3-32.9,5.8-49.3,9.9c-15.8,4.1-31.7,8.4-47.1,13C739,509.1,691.9,520,643,524.1c-16.3,1.5-32.5,2-48.9,1.8
				c-16.3-0.2-32.5-1.2-49.3-2.8c-16.3-1.8-32.5-4.1-48.9-7.2c-12.9-1.5-25.7-2.8-38.6-4.1c-8.9-0.3-17.1-1.8-26.1-1.8
				c-12.9-0.5-26.1-0.8-39-0.5c-12.9,0.5-26.1,1.2-39,1.5c-19.6,0.8-39,1.5-58.9,2c-11.4,0.3-22.8,1.8-34.2,3.5
				c-17.9,2.3-35.7,5.6-53.3,9.6c-16.3,4.3-32.5,9.6-48.5,14.8c-15.9,5.2-31.7,10.7-47.1,16.3C58.4,576.1,7.1,597-43.7,618.4v24.2
				c52.9-24.7,106.5-48.2,161.8-69.1c12.9-4.9,26.4-9.9,39.5-14.5c19.9-6.9,39.9-13.3,60.3-18.9c15.4-3.3,31.4-5.8,47.1-7.9
				c15.8-2,31.7-3.3,47.5-3.8c17.1,0.5,34.6,0.5,51.7,0.3c24.3-1.2,48.5-0.5,72.8,1.5c18.3,2,36.1,4.3,54.6,6.6
				c18.3,2.3,36.1,4.3,54.6,6.1c64.3,6.9,131,1.5,189.9-18c12.2-3.8,24.3-7.9,37.1-11.7c12.2-3.8,24.3-7.9,37.1-11.3
				c12.2-3.5,24.7-6.6,37.5-9.6c25.3-5.6,50.8-9.2,77.8-9.9c15.8-0.3,32.1,0.3,48.5,2.3c12.2,1.8,24.7,3.8,37.1,6.1
				c36.7,6.9,72.5,16.6,107.1,29.6c21.5,8.1,38.6,18.9,52.5,33.4c72.5,77.8,29.7,166.1,9.7,246.7c-7.8,33.7-0.4,68,19.9,97.6
				c19.9,29.9,51.3,57.5,95.3,67c31,6.4,62.2,9.6,93.5,9.6c26.1,0,51.3-1.8,76.1-5.6c16.3-2.3,32.5-5.6,47.9-8.7
				c17.5-3.5,34.6-7.9,51.3-11.9c26.1-6.4,50.8-14.8,74.3-24.7c11.8-4.9,23.2-10.2,34.2-16.3c10.1-5.8,19.9-12.2,28.1-19.7
				c7.8-6.9,15.4-14,23.6-21.2c8.2-6.9,15.8-14.2,24.3-20.9c7.4-7.2,14.7-14.8,22.5-21.7c7.8-7.2,16.3-14.2,24.7-20.9
				c43.3-46.9,78.5-57.2,103.5-57.4c19.6-0.1,63.6,6,96,14.1c5.6,1.4,19.9,5.1,39.1,5.2c19.1,0.1,33.1-3.4,36-4.2
				c20-5.2,29.1-13.5,50.1-26.6c39.6-24.8,40.8-16.4,71.6-37.6c15.1-10.3,12.4-10.7,50.1-39.3c8.3-6.3,21.1-15.9,39.6-25.9
				c7.4-4,13.8-7,18.3-9.1C2300.7,615,2300.6,607.5,2300.6,600.1z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2301.5,739.7c-26,15.1-62,34.2-106.7,52.5c-24.3,9.9-47.6,19.4-77.8,27.4c-14.2,3.8-34,8.3-173.2,19.7
				c-18.4,1.5-72.1,6.6-107.1,24.4c-11,5.6-21.5,11.7-31.4,18.3c-11.8,6.9-23.2,14.8-33.9,22.9c-10.7,8.1-20.3,16.6-29.7,25.5
				c-12.2,9.9-25.7,19.1-39,28.2c-13.6,9-27.2,18-41.1,26.5c-19.2,14-37.9,23.7-56.9,32.6c-15.4,7.6-31,15.1-46.4,22.4
				c-15.4,7.6-31.4,14.8-47.5,21.7c-11.8,5.8-23.9,11.3-36.1,16.8c-12.2,5.6-24.3,10.7-37.1,16c-24.7,10.4-50.4,20.1-76.8,29
				c-26.4,8.4-52.9,16.3-80.6,23.2c-30.4,6.9-62.5,9.6-93.5,4.1c-11.4-1.8-20.3-8.1-24.3-15.7c-21.5-35.4-37.5-72.1-48.5-109.1
				c-11-35.7-18.3-71.7-22.5-108.3c-8.2-72.9-4.5-146.8,5.3-220.9c4.9-41.8-23.6-76-66-101.4c-14.3-8.4-29.7-16-46.4-22.4
				c-12.2-5.3-25.3-9-38.6-11.7c-13.6-2.6-27.2-3.8-41.1-4.1c-18.3,0-37.5,0.8-54.9,5.6c-17.5,5.3-35.4,10.7-53.6,16
				c-17.9,5.3-36.1,10.4-54.6,15.3c-89.5,25-186.8,43.3-284.3,33.1c-19.2-1.8-38.6-4.6-58.2-8.7c-14.3-0.5-28.1-1.2-42.2-2
				c-14.3-0.5-28.1-1.5-41.8-2c-19.6-0.8-38.6,1.8-57.8,5.3c-15.8,3.3-31.4,7.9-46,13.3c-15.4,6.1-30.4,12.5-46,19.1
				c-15,6.6-30.4,14-45.7,21.2C63.6,704,12,735.1-43.3,764v25.5c52.9-28.2,101.7-59.6,155.3-90c14.3-7.6,28.5-14.8,42.8-21.4
				c14.3-6.9,28.5-13.3,42.8-19.4c16.3-7.9,33.9-15.1,52.1-20.1c12.5-3.3,24.3-6.4,37.5-6.1c19.6,0.3,38.6,1.2,58.2,2.3
				c19.6,1.2,38.6,2.3,58.2,2.6c14.3,0.8,28.5,2,42.8,2.8c17.5,1.8,35,2.8,52.1,3.5c52.1,2,104.6-3.3,154.6-12.5
				c16.7-3.3,33.3-7.2,49.3-11.9c16.3-4.6,32.1-9.9,47.5-16c15-4.6,30.1-8.7,45.1-12.5c15.4-3.5,31-6.6,47.1-8.4
				c12.9-1.5,26.4-2.6,39.5-2.6c13.6,0,26.8,0.5,40.4,2.3c15.4,2,31,5.8,46,11.3c15.8,5.8,31,12.8,44.4,20.6
				c40.7,23.5,69.2,55.5,66.3,94.3c-4.5,58.1-8.2,115.9-6.5,173.3c0.7,28.8,2.9,57.1,6.9,85.9c3.6,28.5,9.3,56.9,17.1,85.4
				c10.1,23.2,12.9,53.5,23.6,79.2c10.7,25.8,29.7,47.6,71.7,54c22.8,3.8,46,0.8,67.6-4.6c35.3-9.2,69.6-19.4,102.9-31.3
				c23.6-8.4,46-17.6,67.6-27.8c14.3-6.9,28.5-14,42.8-21.2c13.9-7.2,27.6-14.8,41.5-22.1c14.3-7.9,28.5-15.7,43.1-23.2
				c15.8-8.4,31.7-17.1,47.5-25.8c17.9-9.2,35.7-19.4,54.2-33.4c11.8-7.6,23.2-15.1,34.6-22.9c11.4-7.8,23.2-15.7,34.6-23.2
				c20.7-18,43.9-35.7,69.6-50.5c12.9-7.6,26.4-14.2,40.4-20.4c58.2-6.8,107.4-14.1,144.9-20.1c81.6-13.1,122.6-22.8,139.9-27
				c57.2-13.9,85.6-24.6,95.7-28.5c35.2-13.6,63.5-28.3,83.6-39.8C2301.8,756.1,2301.7,747.9,2301.5,739.7z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2314.3,832c-26.8,21.5-118.9,92-203.6,94.3c-65.4,1.8-99.4-5.8-99.4-5.8s-26.1-5.8-59.8-9.2
				c-9.5-0.9-17.4-1-19.7-1c-4.8,0.1-9.7,0.4-14.5,0.5c-16.7,0.8-33.3,3.5-49.3,7.2c-32.1,7.9-61.1,21.4-84.3,40.6
				c-13.9,8.1-27.2,16.8-39.9,25.8c-38.2,27-72.5,57.1-108.6,85.6c-8.5,6.4-17.5,11.9-26.4,17.6c-15,9.2-29.7,18.9-43.9,28.5
				s-28.5,19.1-42.2,28.8c-9.7,6.4-19.6,12.8-29.3,19.1c-29.3,19.1-59.3,37.5-91.5,54.8c-33.9,18.3-69.2,34.6-107.4,48.2
				c-28.1,9.2-56.9,17.1-87.5,23.7c-16.3,3.5-32.9,4.6-48.5,0.3c-51.7-15.7-74.3-57.8-83-94.3c-7.8-36.9-14.3-73.4-19.9-110.1
				c-5.7-36.6-11-72.9-15.8-109.8c-9.3-73.7-17.9-147.2-31-221.7c-2.9-16-9.7-30.8-20.3-44.4c-10.1-13.7-23.9-26.2-40.7-36.2
				c-12.9-8.7-27.2-15.3-43.1-20.6c-15.4-4.9-32.5-8.4-49.6-9.9c-15.4-1.8-31.4-1.8-46.8-0.5c-15.4,1.5-31,4.3-44.7,9.6
				c-103.9,36.7-220.5,45.9-335,44.7c-16.3,0-32.5-0.3-48.9-0.5c-16.3,0-32.5-0.3-48.9-1.5c-46.4-2.8-93.5,8.4-130.3,28.8
				c-11.8,6.1-23.2,12.2-34.6,18.6C109,774.8,55.2,810.2-2.6,843.9c-13.9,7.9-27.6,13.7-41.1,21.4c-0.4,0-0.4,0.3-0.7,0.5v24.4
				c14.3-7.2,27.6-14.8,41.5-22.9c59.8-36,113.9-74.4,173.6-107.5c11.8-6.6,23.9-13,36.7-19.1c18.3-7.2,37.5-14.5,57.4-19.4
				s40.7-7.6,62.2-5.8c24.3,2.3,49.3,3.5,73.6,4.1c129,14,256-16.8,376.3-47.1c17.1-4.3,35.7-4.6,53.6-4.3
				c12.5,0.3,25.3,1.5,37.1,3.8c12.2,2.3,23.9,5.6,35.4,9.6c15.8,6.1,30.4,14.5,42.2,25.5c11.4,10.7,20.3,22.9,25.7,36.6
				c29,75.2,45.1,150.9,56.9,227c11.8,76.4,21.5,151.6,33.3,227.8c4.5,26.7,8.9,52.8,28.5,76.7c18.7,23.5,50,53.1,89.5,44.6
				c31-6.6,61.1-14.5,89.9-23.5c28.1-8.4,54.6-18.3,79.7-29.6c51.3-22.7,97.5-49.7,142.1-78.4c14.7-9.6,29.3-19.4,43.9-29.3
				s29.7-19.7,44.7-29.9c15-10.2,30.4-20.4,46.8-31.1c8.9-5.8,16.7-12.8,24.7-19.1c38.2-31.1,73.6-63.2,115.1-90.2
				c10.7-6.6,21.2-13.3,32.5-19.1c27.6-20.1,62.5-31.9,99.7-36.2c12.2-1.5,24.7-2,37.5-2c27.5,0,47.6,4.4,54.2,5.6
				c0,0,42.8,8,122.4,4.6c29.3-1.3,97.9-10,191.5-78.3C2307.5,852.9,2310.9,842.4,2314.3,832z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2302,891.2c-4.1,2.9-10.9,7.6-19.1,13.5c-31.7,22.6-45.1,32.9-70.1,50.3c-20.8,14.4-31.2,21.7-35.9,23.5
				c-18.1,6.8-34.2,5-68.8,2.8c-23.2-1.5-46-4.9-69.2-9c-65-11.7-139.9-29.9-199.7,1.2c-11.8,4.6-23.2,9.9-34.2,15.3
				c-21.5,11.3-41.1,24.4-59.8,38.3c-12.2,9.2-24.3,19.1-35.7,28.8c-23.6,20.1-47.1,39.8-72.1,58.9c-14.7,10.7-29,20.6-43.9,30.8
				c-15,10.2-30.1,20.6-45.1,30.5c-12.2,8.7-24.7,17.4-37.5,25.8c-37.5,25.8-75.7,50.5-116.4,73.7c-27.2,15.1-55.3,29.6-85,42.3
				c-39.5,16.6-79.3,31.3-125,32.8c-39.9,1.8-76.1-16.3-98.5-39.2c-21.5-21.2-34.2-47.9-37.9-73.7c-6.9-71.1-14.3-141.4-25.7-212.5
				c-11.4-71.1-26.4-142.8-52.1-214c-4.9-13.7-11-27.3-18.7-40c-8.9-20.4-26.4-37.5-49.3-49.9c-11.4-6.4-23.6-11.7-37.1-15.7
				c-18.3-5.8-37.9-9.2-57.4-10.4c-17.1-0.5-34.6-0.5-50.4,4.1c-120.7,33.9-251,52-380.3,41c-16.3-0.3-32.9-0.3-49.3-0.8
				c-15.4,0.8-31,2.6-46,5.3c-15,2.6-29.7,6.4-43.9,11.7c-17.9,7.6-35.3,16-52.1,24.7c-82.4,43.1-152.4,89.9-229.6,138.9v4.3
				c73.2-35.7,135.4-78.4,205.3-119.8c18.7-11.3,37.9-21.7,57.4-31.3c10.7-5.6,22.1-9.9,33.3-13.3c11.4-3.5,23.6-6.1,35.4-8.1
				c14.7-2.6,29.3-3.8,43.5-4.3c12.2-0.3,24.7,0,37.5,0.5c36.1,1.2,70.7,7.9,107.1,9.9c92.4,5.6,174.2-26.5,261.1-44.6
				c26.1-5.6,52.9-7.2,79.3-4.3c13.9,1.5,27.6,3.8,40.7,7.6c12.9,3.5,25.7,8.4,37.1,14.2c11.8,5.8,22.5,12.8,31.4,21.2
				c8.9,8.1,16.3,17.4,21.2,27.8c10.1,15.1,17.5,31.3,22.8,48.2c21.5,72.6,32.1,144,45.7,214.3s16.3,139.9,25.7,210.5
				c2.9,20.4,12.2,42.1,28.1,60.7c12.9,16.8,32.9,30.5,56.5,38.7c23.6,8.1,50.4,10.7,77.2,5.6c24.3-4.9,47.9-10.7,70.7-17.6
				c38.2-11.3,73.2-25.8,106.5-42.6c58.5-29.9,109.3-67,159.6-103c10.7-7.9,21.5-15.3,32.1-22.9c14.3-9.9,28.5-20.1,43.5-31.1
				c39-27.3,72.8-57.5,107.4-87.1c10.1-8.4,20.3-16.8,31.7-25c16.7-12.2,34.6-23.5,54.2-32.8c19.6-9.6,40.7-17.4,63.1-23.2
				c46-11.7,94.7-1.2,140.4,6.4c15.4,2.8,30.4,5.8,46,7.9c16.7,2.3,33.3,4.3,50.4,5.3c18.9,1.1,35.4,1.4,52.1-2
				c42.1-8.6,52.4-36.2,127.1-80.9c6.2-3.7,11.3-6.6,14.5-8.4C2301.9,897.7,2301.9,894.5,2302,891.2z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2303.6,928.3c-31.2,16.5-53.4,33.6-68.1,46.5c-20.3,17.9-32,32.5-56,40.3c-18.9,6.1-34.9,3.3-45.1,3.5
				c-56.9,1.2-113.1-6.6-168.5-18.6c-43.9-9.9-86.3-5.3-124,7.9c-12.5,4.3-24.7,9.6-35.3,15.3c-13.6,8.1-26.1,16.6-38.2,25.2
				c-12.2,8.7-23.9,17.6-35.7,27c-46,37.5-89.9,75.7-139.2,111.4c-11.8,7.9-23.2,16-34.6,24.2c-68.8,48.7-138.6,96.6-220,135
				c-41.5,18.3-83.9,33.9-129,47.4c-27.2,8.1-56.5,5.6-83-3.3c-26.4-8.7-50-24.2-65.4-41.3c-22.1-23.2-34.6-51.4-36.7-78.7
				c-2.1-41-4.9-81.8-9.3-123.1c-4.5-41-10.7-82.1-19.2-122.8c-9.3-43.3-19.9-86.2-35.7-128.6c-6.1-16-12.5-31.6-20.3-47.1
				c-8.9-16-15.8-33.1-26.1-48.7C839,695.6,660,754.5,540.4,780.7c-56.1,12.2-111.4-3.5-166.7-7.2c-15.8-1.2-31.4-1.8-47.1-2.6
				c-19.2-0.8-38.2,2.8-55.3,8.4c-12.5,3.3-24.7,7.2-36.1,11.9c-11.8,4.6-23.2,9.9-34.2,15.3c-15,7.2-30.1,14.8-44.4,22.7
				C83.2,868.6,16,914.8-42.9,965.5c-0.7,0.5,2.1,3.5,2.9,5.6c16.3-9,32.9-17.4,49.3-27.3c59.8-35.7,116.4-72.9,176.1-105.7
				c15-8.1,30.1-16,46-23.5c15.4-7.2,31.4-14,48.9-18.3c12.2-3.8,26.1-6.6,39.9-4.9c20.3,2.3,40.7,4.1,61.1,5.3
				c120.3,12.2,242,0.3,357.5-28.2c90.7-22.1,167.9,39.2,188.8,98.1c4.9,8.7,9.3,17.6,13.6,26.5c20.7,44.4,34.2,90,43.5,136.1
				c13.9,66.2,26.4,133.3,29,199.8c1.7,46.1,8.5,74.6,20.7,97.3c12.2,22.9,29.7,40,52.5,63.2c19.2,22.1,57.4,24.7,90.7,20.1
				c37.1-5.3,72.1-13.3,106.5-24.4c27.2-9.9,53.3-20.9,78.5-32.8c49.3-23.2,93.2-52.2,136.3-81.3c20.7-14,40.7-28.2,61.1-42.1
				c10.1-6.9,20.3-14,30.1-20.9c54.9-44.1,113.9-83.3,166.7-131.8c8.5-7.6,17.1-14.8,28.5-20.9c11.4-6.6,23.2-12.2,35.7-17.1
				c50.8-19.1,111.4-23.7,168.5-16.8c8.3,1,24,5.5,47.5,8.4c14,1.7,24.2,2.4,44.5,3.6c26.3,1.6,39.8,2.4,56.3,1.7
				c19.9-0.8,29.8-1.2,42-5.4c14.8-5.1,25.3-12.7,36.5-20.9c16.6-12,12.5-12.4,28.3-23.9c15.3-11.1,23-13.6,47.4-30.4
				c4.5-3.1,8.2-5.7,10.4-7.4C2302.9,940.9,2303.2,934.6,2303.6,928.3z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2177.8,1047.6c-13.6,3.6-27.6,4.7-41.8,6.1c-15,1.5-30.1,1.8-45.1,1.2c-15-0.5-29-2-42.8-4.1
				c-32.5-4.6-61.4-13.7-93.2-20.6c-31.4-6.6-62.5-4.9-91.5,2.6c-14.7,3.8-28.5,8.7-41.1,15.1c-81.7,50.2-147.7,112.1-220.4,168.7
				c-10.1,8.1-20.3,15.3-30.4,22.7c-10.1,7.2-20.3,14.2-30.4,21.2c-61.1,41.8-124.5,81.3-196.8,112.6
				c-23.9,10.2-49.3,19.7-75.3,27.5c-32.1,9.6-65.4,18-99.7,25c-46.4,9.9-91.1-17.6-115.1-45.9c-29.3-31.1-45.1-71.1-48.5-106.8
				c-2.9-40-4.5-80.5-7.8-121c-3.2-40.3-8.2-80.7-16.7-120.8c-8.5-40.6-22.5-81.3-42.2-119.8c-7.4-14.5-15.8-28.8-25.3-42.6
				c-28.5-54.8-104.2-108.8-192.1-83.3c-116.6,33.9-246,36.9-365.6,12.5c-17.5-0.8-35-0.8-52.1,0.5c-17.1,1.5-34.2,4.6-50.8,9.9
				c-12.5,4.1-24.7,9.2-36.1,16c-11.4,5.8-22.5,11.7-33.3,18C101.6,889.4,34,938.6-34.8,994.7c-0.4,0.3,1.7,1.2,1.7,2
				c72.1-42.6,141.5-91.2,217.6-131.5c15-8.1,31-16,46.4-23.2c12.5-5.8,26.1-11.7,40.4-15.7c12.2-3.8,25.3-6.4,39-4.6
				c13.9,1.8,27.6,2.8,41.1,4.1c133.9,15.3,265.6-4.3,400.3-17.6c91.1-9,138.6,60.4,163.1,112.1c8.2,14.2,15,28.8,21.5,43.6
				c17.1,39.2,29.3,79.5,38.2,120.2c8.5,40.3,14.7,81.3,19.2,122.5c4.9,41.3,8.5,82.5,12.9,123.4c2.9,27.8,18.7,57.5,44.4,79.8
				c23.2,20.9,59.3,36,97.5,34.6c31-1.2,60.7-5.3,89.5-14.5c26.1-8.1,50.8-17.4,75.3-27.5c96.4-39.8,177.4-94.6,256.7-149.8
				c9.3-6.4,18.3-12.8,27.6-20.1c85-63.2,156.2-144.5,273.4-178.6c57.4-16.6,117.6,6.6,175.7,14.5c12.5,1.8,25.3,2.8,37.9,2.6
				c11.4,0,23.2-0.3,35.7-1.2c15.9-1.1,25.3-2,37.5-4.9c24.6-5.8,42.3-15.8,52.6-21.7c4.9-2.8,12.6-8.2,28.1-18.9
				c28.8-20,44.2-32.6,51.6-38.8c4.8-4,8.6-7.3,11.2-9.6c0.1-3.4,0.2-6.7,0.3-10.1c-5,3.4-13.2,9-23,16
				C2217.4,1025.2,2207.6,1039.7,2177.8,1047.6z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2304.6,1026.5c-26.7,20.7-47.4,37.2-60.9,48.1c-23.1,18.6-31.5,25.9-47.5,32.1
				c-13.6,5.3-31.2,9.2-43.3,11.9c-12.6,2.8-23.3,4.5-25.4,4.8c-3.9,0.6-7.8,1.1-11.7,1.6c-65,8.7-126.1-7.6-191.1-15.1
				c-43.9-5.6-85,4.6-120.3,23.7c-81,45.1-148.9,88.9-212.8,147.2c-3.6,3.5-8.5,6.1-12.5,9c-9.7,6.6-19.6,13-29.3,19.4
				c-9.7,6.4-19.6,13-29.3,19.4c-77.8,51.2-158.6,100.4-251.4,137.3c-37.1,13.3-74.3,25-113.6,35.4c-38.6,9.9-82.1,4.1-116-12.5
				c-23.2-11-41.8-26.7-52.9-45.3c-22.8-37.2-29-76-29.7-115.5s3.2-79.5,0.4-118.5c-3.2-70.6-22.1-139.4-64.3-203.6
				c-8.5-12.8-17.9-25.5-28.1-38c-26.1-44.6-89.5-80.5-157.5-77.2c-130.6,6.1-262,9.9-388.5-16.3c-16.3-3.3-32.1-2.8-47.9-0.3
				c-15.8,2.6-31.4,7.6-45.7,13c-11.8,4.3-22.8,9.9-32.5,16c-20.3,9.6-39.5,19.7-58.2,30.5c-59.3,34.2-112.2,74.6-160.3,119.6
				c0,0.3,2.1,1.8,2.5,2.8c41.5-25.2,81-52,122.5-77.5c17.5-11.7,35.7-22.7,54.9-33.1c19.2-10.4,38.6-20.4,58.9-28.8
				c16.3-7.2,34.2-13.3,52.9-17.1c18.7-3.5,38.2-4.9,58.2-2.3c116.7,16.3,236.4,19.1,355.7,14c80.6-2.8,145.3,43.8,188.3,89.2
				c8.5,9.2,16.3,19.1,23.6,29c35.4,50.2,52.9,107.5,51.7,163.1c0,45.9-6.5,93.5-4.9,140.2c2.1,46.7,12.5,92.5,47.1,135.6
				c27.6,34.2,75.7,65.3,132.6,54.8c41.5-10.7,81.7-22.4,121.7-35.4c131.4-41.8,233.9-115.9,336.8-185.8c11.4-7.6,22.8-16,33.9-25.5
				c58.5-56.9,134.6-99.9,221.4-137.6c21.5-9.2,46-12.8,70.7-9.9c95,11.1,152.4,19.4,240.4,9.2c18.8-2.2,38.9-6,60-17.7
				c16.1-9,23.2-17.3,48.1-38.9c12-10.4,29.2-24.8,50.9-41.3C2302.9,1035.7,2303.8,1031.1,2304.6,1026.5z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2301,1159.8c-3.4,3.2-8.7,7.9-15.1,13.4c-36.7,31.6-85.1,73.2-147.9,93.6c-15.7,5.1-67.6,21.8-130.1,10.7
				c-54.6-9.7-76.2-34.1-136.2-35.2c-8.1-0.2-39.3-0.4-77.8,10.2c-44.1,12.1-88.8,41.4-178.1,99.9c-3.7,2.4-13.6,9.2-27.6,18
				c-18.7,11.7-37.5,23.5-56.1,35.7c-115.6,75.2-212.8,159.3-344.6,216.9c-23.2,9-47.1,16.8-72.1,24.2
				c-31.4,8.7-64.3,15.3-97.1,10.7c-54.9-6.4-97.1-28.8-126.8-58.6c-29.7-29.8-47.1-67.3-51.7-103.7c-8.5-75.4,0.4-151.3-0.7-227.3
				c-1.7-61.9-31.7-119.8-86.3-163.4c-11-8.7-22.8-16.8-35.7-23.7c-11.8-7.2-24.3-13.3-38.2-18.6c-82.1-24.7-174.5-26.5-260-42.6
				c-14.3-2.6-28.5-5.3-43.1-8.4c-76.8-17.1-169.6-10.2-225,34.9c-11,6.9-21.5,14.5-31.7,22.1c-45.7,31.6-86.3,67-129.7,101
				c-4.5,3.5,0.7,7.6,0.4,11.7c10.7-6.4,19.9-13,30.1-19.7c33.9-24.2,68.3-49.9,104.2-73.7c12.2-8.1,24.3-15.7,36.7-23.2
				c41.5-26.5,92.4-51.4,151.3-45.1c40.4,4.3,79.7,9,118.9,14.5c19.6,2.6,39,5.6,58.2,8.4c58.9,8.7,116.7,20.1,174.5,31.1
				c23.2,5.3,43.5,15.3,58.9,28.8c15.8,11.7,30.1,24.2,42.8,37.2c12.5,13.3,23.2,27.5,31.7,42.3c19.2,35.4,23.6,74.6,24.3,114.7
				c0.4,59.8-7.8,119.8,0,179.7c8.9,73.1,39.9,152.1,133.9,187.3c34.2,12.8,72.1,15.3,110.3,10.4c35.7-5.6,70-13.3,102.9-23.5
				c81-23.5,137.1-72.1,201.3-114.1c70-46.1,133.9-95.5,201.8-140.9c13.6-9,27.2-18,41.1-26.7c16.7-10.5,24.6-17.9,34.2-25
				c14.9-11,51.5-37.8,100.4-60c19.9-9,32.4-14.7,51.4-19.2c11-2.6,38-8.1,82.3-3.9c53.1,5,52.9,17.7,116.8,27.8
				c44.3,7,72.5,5.5,84.2,4.6c25.5-0.8,44.9-5.7,57.4-9.8c27.6-8.9,48.2-22.2,153.6-103.3c2.3-1.7,5.7-4.4,7.9-6.1
				C2300.9,1169.2,2301,1164.5,2301,1159.8z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2301.7,1344.3c-24.4,32.9-51,50.6-67,59.6c-20.8,11.6-38,21.3-60.8,19.9c-9.4-0.6-23.5-4.7-51.7-13.1
				c-12.5-3.7-22.7-6.9-29.5-9.1c-33.2-13.1-159.3-60-277.7-36.8c-36.2,7.1-95,24.8-153.8,53.1c-15.8,7.6-31.4,15.3-46,23.7
				c-102.9,57.1-175.7,128.6-264.3,196.5c-58.9,45.1-117.6,87.7-189.2,119.6c-22.1,9.9-45.1,17.1-69.2,22.1
				c-23.9,4.9-48.9,7.9-73.9,8.4c-29.7,0.8-60.3-1.2-90.7-7.6c-66.3-15.1-114.7-47.1-152.5-85.9c-36.1-36.6-60.7-76.9-77.8-119.6
				c-15.4-38.3-24.7-77.5-31.7-116.7c-8.2-42.1-10.7-85.6-17.9-128c-5.7-31.9-14.3-63.2-30.4-93.2c-5.3-9.9-11.8-19.7-19.2-29.3
				c-25.3-38-76.8-64.2-133.9-75.4c-81-16.6-172.1-4.6-231,38.3c-12.2,7.6-23.6,15.7-34.2,23.5c-81.9,52.8-147.5,110.6-218.3,167.5
				c-2.9,11.7-1.1,23.7-4.5,35.7c41.1-32.6,79-66,119.2-98.4c40.4-32.6,82.5-64.2,131.4-93.8c9.7-6.4,19.6-12.8,29.3-18.9
				c43.5-29.6,101.7-47.1,163.1-41.8c70.3,6.4,131,39.2,157.5,85.1c7.4,10.7,13.6,22.1,18.7,33.7c16.3,34.9,24.7,72.6,30.4,106.5
				c6.9,40,12.2,78.7,21.5,117.9c18.7,77.5,45.7,150.4,104.6,214.5c43.1,45.1,100.4,81.3,179.4,95c35,5.3,69.2,6.1,102.2,4.1
				c32.9-1.5,64.3-7.2,93.9-16.3c63.1-18.3,113.6-50.5,162.4-85.4c121.7-86.8,216-197.7,356.8-264.8c15.4-7.6,31-14.8,46.8-22.1
				c23.6-10.9,70.4-28.8,133.2-36c25.7-3,78.1-8.5,141.4,6c18.9,4.3,12,4.3,128.2,41.4c28.4,9,50.4,15.8,80.8,15.4
				c15.6-0.2,41-0.8,69.3-14.8c31.2-15.4,48.9-38.6,56.8-50.5C2302.8,1364.4,2302.3,1354.4,2301.7,1344.3z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2052.5,1544.7c-44.9-23-69.6-33.3-91.5-41.1c-11.7-4.2-22-7.9-36.1-11.7c-43.9-11.9-91.5-16-138.3-10.2
				c-19.6,2-39,5.6-57.4,10.2c-75,18.3-141.1,55.5-193.9,100.1c-119.2,99.9-204.2,222.1-373.1,279.3
				c-43.9,13.3-89.5,22.1-138.3,24.2c-49.6,2.3-100.4,0.8-153.3-7.2c-88.2-13.3-169.2-50.2-224.2-101.6
				c-52.5-48.2-77.2-97.8-109.3-148.9c-40.4-62.7-59.8-153.3-155-187.3c-87.1-12.8-167.2,46.7-233.9,95.5
				c-63.9,46.4-124,87.1-190.8,119.3v27.3c83-30.8,150.6-80.7,227.4-132c42.2-28.2,88.2-54.6,141.1-60.1
				c101.3-6.1,148.2,72.9,179,130c22.8,41.8,44.7,83.9,78.5,123.1c33.3,39.2,78.5,77.2,145.7,107.7c45.7,20.9,93.9,33.9,143.2,41.5
				s98.9,9.2,147.2,7.6c49.3-2.8,95.7-11.3,139.9-24.4c79-22.4,141.5-63.5,196.8-111.1c128.1-110.6,226.7-266.2,446.3-277.2
				c94.2-4.7,226.3,43.7,288.8,92.5c29.2,22.8,62.9,39,93.7,59.5c23,15.3,48.2,32.7,82,31.5c13.8-0.5,25.1-3.9,32.6-6.8
				c0.2-10.7,0.4-21.4,0.6-32c-8.4,4.5-20.7,9.7-35.9,11c-43.4,3.8-67.4-27.4-151-76C2092.3,1565.1,2076.5,1557,2052.5,1544.7z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2314.6,90.2h-154.2c-27.2,0-57.4,0-89.9,0c-36.7,0-72.8-0.3-109.3,1.8c-111.4,6.4-222.1,14.8-333.9,11
				c-45.7-2-91.1-4.3-136.8-6.9c-45.7-2.6-91.1-4.9-137.1-7.6c-203.1-15.1-406-9.8-609.1-4.3c-22.5,0.5-45.1,1.2-67.6,1.8
				c-122.5,4.2-243.6,4.2-366.1,4c-15.8,0-31.7,0-47.5,0s-31.7,0-47.5,0c-151.3,0-246,0-246,0v22.4c0,0,94.7,0,246.1,0
				c14.7,0,29.3,0,43.9-0.3c14.7,0,29.3,0,43.9-0.3c138.3,2.3,275.8-1.2,414.2-6.9c19.9-0.5,39.9-1.5,59.8-2
				c179.4-5.8,358.8-11.3,539.9,1.8c32.9,2.3,65.4,5.8,97.5,9.6c32.1,3.8,64.7,7.9,97.1,11.7c78.5,9.2,157.1,15.3,237.5,8.1
				c148.9-14,293.8-25,442.1-21.4H2314V90.2H2314.6z"/>
                    <path :fill="colors[`${id}-p-1`]" d="M2314.6,57.8h-154.2c-27.2,0-57.4,0-89.9,0c-36.7,0-72.8-0.2-109.3,1c-111.4,3.4-222.1,7.9-333.9,5.9
				c-45.7-1.1-91.1-2.3-136.8-3.7c-45.7-1.4-91.1-2.6-137.1-4c-203.1-8-406-5.2-609.1-2.3c-22.5,0.3-45.1,0.6-67.6,1
				c-122.5,2.2-243.6,2.2-366.1,2.1c-15.8,0-31.7,0-47.5,0s-31.7,0-47.5,0c-151.3,0-246,0-246,0v11.9c0,0,94.7,0,246.1,0
				c14.7,0,29.3,0,43.9-0.2c14.7,0,29.3,0,43.9-0.2c138.3,1.2,275.8-0.6,414.2-3.7c19.9-0.3,39.9-0.8,59.8-1.1
				c179.4-3.1,358.8-6,539.9,1c32.9,1.2,65.4,3.1,97.5,5.1s64.7,4.2,97.1,6.2c78.5,4.9,157.1,8.1,237.5,4.3
				c148.9-7.5,293.8-13.3,442.1-11.4H2314v-12L2314.6,57.8L2314.6,57.8z"/>
                </g>
                <g id="waves_x5F_2">
                    <path :fill="colors[`${id}-p-2`]" d="M2302.6,218.2c-5.6,0.1-12.7,0.2-20.9,0.3c-19.6,0.3-34.2,0.6-34.8,0.6c-31.4,0.7-280.9,33.2-430.2,107.5
				c-5.9,2.9-19.1,9.6-38.2,14.8c-39.9,11-84.3,10.4-122.8-4.9c-14.3-5.6-27.6-12.2-41.5-18.6c-13.6-6.4-27.2-12.8-41.1-18
				c-13.9-6.9-27.6-13.7-41.1-20.4c-41.5-19.4-84.3-36.6-132.6-47.9c-14.3-4.3-28.5-8.1-42.8-11.7c-14.3-3.6-29-6.6-43.5-9.2
				c-29.3-5.6-59.3-9.6-89.9-12.5c-128.5-12.5-254.6-11-381.1-6.6c-17.9,0.5-36.1,1.2-54.2,2c-16.7,0.5-32.9,1.2-49.3,2
				c-43.1,3.3-84.3,9.6-127.4,13.3c-15.4,1.8-29.7,3.5-45.7,4.1c-20.3,1.5-40.4,2.3-60.7,3.3c-100.4,3.8-198.9,0.8-300.3-2
				c-12.9,0.3-26.1,0.5-39,0.8c-65,1.5-130.3,2.8-195.7,3.5c0,6.9,0,14.2,0,20.9c49.3-1.5,98.2-2.6,147.2-4.1
				c16.3-0.5,32.5-0.8,49.3-1.5c80.1-3.5,160,2.6,239.6,4.9c15.8,0.3,31.7,0.3,47.5,0s31.7-1.2,47.1-2c73.9-3.8,145.7-15.3,221-22.1
				c24.3-1.8,47.5-3.8,72.1-3.8c17.1-0.5,34.2-1.2,51.3-1.5c85.7-2,171.7-2.8,257.5-0.5c44.7,1.2,88.6,5.3,132.1,12.2
				c43.5,6.6,86.3,16.3,128.1,28.5c14.3,4.1,27.6,8.7,42.2,12.5c49.6,13,92.4,33.1,133.2,55.5c13.6,7.6,26.8,15.1,40.4,22.4
				c11.4,5.6,22.5,11.9,32.9,18.3c10.7,6.4,21.2,13,32.5,19.4c35,23.2,84.3,28.8,128.1,18.3c20.3-4.9,34.4-12.2,41.5-16
				c50.9-26.9,245.2-129.5,444.9-140.5c17.3-0.9,31.8-1.1,42.5-0.9C2301.5,231.9,2302.1,225,2302.6,218.2z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2268.8,385.1c-14.7,3.5-20,7.3-59.4,21.9c-19.6,7.3-25,9.1-61.6,27.2c-45.7,25.9-60.6,32.4-86.3,55
				c-8.5,7.6-16.3,15.3-24.3,23.5c-17.1,16.3-29.7,33.7-49.3,49.1c-35.7,26.7-88.2,31.3-135,39.2c-17.1,2.6-33.9,5.3-49.3,8.7
				c-12.9,1.8-25.7,4.3-37.1,7.9c-58.2,17.1-98.2,56.3-122.1,96.6c-10.7,8.4-24.3,11.7-39.9,13.7c-13.9,2-27.2,0.8-40.4-3.3
				c-16.3-4.9-29.3-14.5-35-27.3c-19.2-38.9-32.1-79.8-49.3-119.6c-5.7-13.3-12.2-26.5-19.9-39.8c-28.1-49.9-69.2-97.6-129.7-130.4
				c-15-8.4-31.4-16-48.9-22.7c-25.7-11.3-52.9-20.6-81.4-27.8c-47.9-12.2-96.4-21.2-145.3-27.8c-24.3-3.3-48.9-5.8-73.6-8.1
				c-52.9-5.3-104.6-5.8-158.9,1.2C688.6,338.9,563,361,430,355.7c-15.8,0-31.7-0.3-47.9-1.2c-17.9,0-35.4-0.3-53.3-0.3
				s-35.3,0.3-53.3,0.8c-19.2,0.3-37.5,1.8-56.9,3.5c-17.1,1.5-34.2,3.3-51.3,4.9C99,370.3,30.9,377.5-37.4,384.2
				c-1.1,6.4-1.7,13-3.2,19.1c71.2-8.1,142.4-16.8,213.6-25.2c17.9-2,35.4-4.1,53.3-6.1c20.3-0.3,40.7-0.8,61.1-1.8
				c20.3-0.5,40.7-1.5,61.1-2.6c18.3-1.5,36.1-2.3,54.6-2.8c50-1.5,99.3,2.3,149.3,1.5c83-0.8,160-16.3,243.2-28.5
				c56.1-8.4,110-9.9,164.7-5.8c15.4,1.2,31,2.6,46.4,4.1c46.4,4.6,92.8,11.3,138.6,20.4c46.4,9,91.5,21.4,132.6,38.3
				c18.7,6.9,36.7,15.1,53.3,24.4c52.1,28.2,92.8,67.3,112.2,112.1c4.9,10.4,8.5,21.2,11.4,31.6c8.5,31.6,11.8,64.5,19.6,95.3
				c2.1,9.9,5.7,19.7,11,28.8c5.3,9.2,12.5,18,21.2,25.8c8.5,7.9,18.7,14.8,30.1,20.6s24.3,10.2,38.2,12.2
				c23.9,4.6,47.9,3.5,70.3-4.1c2.5-1.2,6.1-1.8,7.4-3.5c22.1-32.6,43.5-70,81.7-94c12.5-8.1,27.2-14.8,44.4-19.4
				c11.8-3.5,23.2-6.4,35.3-9c43.5-9.9,85.7-17.1,132.1-23.5c56.5-8.1,93.9-35.4,118.9-68.8c8.2-8.1,38.5-42.6,48.2-49.8
				c38.2-28.8,87.8-45.4,105.2-50.6c13.4-4,31.3-14.7,57.8-20.4c11.6-2.5,21.5-3.7,28.3-4.3c-1.1-5.8-2.3-11.7-3.4-17.5
				C2287.2,381.3,2276.2,383.3,2268.8,385.1z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2198.1,1716.7c-39.1-23-30.3-13.5-63.2-34.9c-104.2-67.7-118.1-78.8-139-91.2
				c-69.8-41.6-141.2-52.5-172-54.5c-5.7-0.4-13-1-22.5-1.1c-77.2-0.7-138.4,31.3-173.1,52.2c-101.8,61.3-115.1,111.3-239.3,208.1
				c-59.4,46.3-127.8,99.7-222.9,125.4c-41.8,11.3-86.3,19.4-133.2,22.9c-33.9,2.3-67.9,2.6-101.3,0.8
				c-100.9-5.6-198.6-30.5-282.4-76.9c-45.1-25-80.6-53.7-111.8-84.8c-31.4-31.1-58.5-64.7-86.8-100.4
				c-50.8-73.1-169.6-84.5-259.1-33.9C112,1691.6,38.8,1726.7-43,1755.7v21.7c70-20.9,129.7-50.8,196.8-81.6
				c52.5-25,108.9-44.1,170.7-36.9c44.4,5.8,80.1,24.4,107.4,51.7c40.7,38,78.5,74.6,120.7,108s88.6,63.9,146,88.5
				c82.5,35.7,171.7,56.9,265.6,56.6c38.2-1.8,75.7-5.3,112.7-9.9c39.9-6.4,77.2-15.3,113.6-27.3c26.5-8.5,51.5-18.7,75-30.4
				c46.1-22.9,78-47.1,120.2-81.4c88.3-71.8,111.9-97.7,191.3-162c53.3-43.2,80.4-65,100.8-74.2c101.3-45.3,203.1-17.6,228.9-9.7
				c42.8,13.1,73.5,32.4,134.6,71.1c98.6,62.4,148,93.5,148.6,94c13.1,10.1,42.7,33.6,82.8,33.5c12.3,0,22.4-2.2,29.1-4.1
				c-0.3-7.9-0.6-15.7-0.9-23.6c-7.2,3-18.5,6.7-32.4,6.7C2245.3,1746.3,2230.3,1735.6,2198.1,1716.7z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2302.1,1971.8c-16,2.5-40,5.3-69.2,5.2c-141.6-0.5-200.9-69.4-309.8-99.3
				c-161.9-44.5-319.8,26.8-337.7,37.7c-86.3,52.8-167.9,105.4-269.6,138.8c-22.8,7.9-45.7,14.8-68.8,20.9
				c-23.2,6.1-47.1,11.3-72.1,15.3c-50.4,9-102.2,14.2-154.6,15.7c-40.4,1.5-80.6,1.2-120.3-1.2c-39.9-2.3-80.1-6.9-120.3-13.7
				c-132.1-22.4-237.5-70.8-353.5-117c-88.6-34.9-185.4-63.5-287.2-44.6c-60.7,11.3-110.7,34.9-166.7,52.2
				c-4.9,1.5-9.7,2.6-14.7,3.8v24.4c36.1-10.4,70.7-23.2,107.4-34.6c55.3-17.1,113.6-31.9,175.7-23.7
				c66.3,8.7,125.7,22.4,183.5,47.1c179.4,76.7,371.1,130,576.8,121.9c30.1-0.8,59.8-2.3,89.9-4.1c42.2-2.3,83.5-8.4,123.6-16.8
				c28.1-6.1,56.1-12.8,83-20.9c91.7-27.6,158.9-59.7,177.8-69.1c80.8-40.2,69.4-48.1,129.5-73.9c78.9-33.7,145.2-40.1,164.7-41.7
				c83.2-6.5,147.9,9.5,170.9,15.9c80.5,22.4,77.9,44.7,170.1,68.4c58.3,14.9,105.8,17.9,129.2,18.7c24.9,0.8,45.9-0.1,61.6-1.2
				C2301.6,1988,2301.8,1979.9,2302.1,1971.8z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2301.9,2118.3c-25.6,9.7-68,22.5-120.5,22.1c-44-0.3-76.1-10.7-87.9-13c-170.4-33.4-353.5-58.1-526.7-14.2
				c-63.9,16.3-126.8,31.3-192.1,44.6c-33.9,6.9-67.6,13-101.7,18.6c-34.2,5.6-68.3,9.9-103.8,13c-44.7,3.5-89.5,6.1-134.6,8.4
				c-45.1,2.3-90.4,4.3-135.9,5.8c-93.2,2.6-186.4-6.1-275.8-27.3c-155.3-36-299-105.4-470.7-85.6c-68.8,7.9-131.8,22.7-195.7,38.9
				v26.2c72.5-15.7,143.9-34.2,220-39.5c146-9.9,276.3,40.3,411.7,72.9c78.5,18.6,157.8,32.6,239.9,32.8
				c52.9,0.3,105.3-1.2,157.5-3.5c52.1-2.3,104.2-5.6,155.8-8.7c29.7-1.5,58.9-4.3,88.2-7.9c29.3-3.8,58.2-8.4,87.9-13.7
				c37.9-6.6,76.1-14,113.9-20.9c93.9-16.3,186.4-36.2,284.7-43.3c115.6-9,227.8,7.2,337.7,25.8c16.1,2.7,48.5,10,93.5,11.9
				c66.6,2.8,121.2-6.8,156.4-15.1C2303.1,2137.1,2302.5,2127.7,2301.9,2118.3z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2307.1,2226.3c-25.6,3.8-68,8.7-120.5,8.6c-44-0.1-76.1-4.2-87.9-5.1c-170.4-13-353.5-22.6-526.7-5.5
				c-63.9,6.3-126.8,12.2-192.1,17.3c-33.9,2.7-67.6,5.1-101.7,7.2c-34.2,2.2-68.3,3.8-103.8,5.1c-44.7,1.4-89.5,2.4-134.6,3.3
				c-45.1,0.9-90.4,1.7-135.9,2.3c-93.2,1-186.4-2.4-275.8-10.6c-155.3-14-299-41-470.7-33.3c-68.8,3.1-131.8,8.8-195.7,15.1v10.2
				c72.5-6.1,143.9-13.3,220-15.3c146-3.8,276.3,15.7,411.7,28.3c78.5,7.2,157.8,12.7,239.9,12.7c52.9,0.1,105.3-0.5,157.5-1.4
				c52.1-0.9,104.2-2.2,155.8-3.4c29.7-0.6,58.9-1.7,88.2-3.1c29.3-1.5,58.2-3.3,87.9-5.3c37.9-2.6,76.1-5.4,113.9-8.1
				c93.9-6.3,186.4-14.1,284.7-16.8c115.6-3.5,227.8,2.8,337.7,10c16.1,1.1,48.5,3.9,93.5,4.6c66.6,1.1,121.2-2.6,156.4-5.9
				C2308.3,2233.6,2307.7,2229.9,2307.1,2226.3z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2300.1,497.8c-7.6,2.7-19.1,7.2-32.1,14.5c-30.8,17.1-49,37.3-56.6,45.3c-33.7,35.3-75.8,55-116.3,74.2
				c-21.5,10.2-68.3,31.9-131.3,40.6c-31.8,4.4-72.1,6-121.2,15.8c-3.9,0.8-27.7,5.6-55.7,18.4c-17.2,7.8-25.2,13.7-29.7,17.4
				c-10.1,8.4-19.6,18.9-26.8,31.9c-11.4,13.7-21.5,27.5-29.3,42.3c-5.3,9-16.7,15.3-26.1,22.1c-14.7,10.7-29.7,18.9-47.1,24.7
				c-15.8,5.6-32.5,9.2-50.4,10.4c-17.5,1.2-35.7,0.5-53.6-2.3c-19.2-1.5-37.9-4.6-56.1-9.6c-25.3-6.6-48.9-16.6-69.2-29.9
				c-36.1-23.7-45.1-54.3-45.1-86.2c9.3-76,25.7-166.4-55.3-226.5c-18.3-15.7-40.4-29.6-65-42.1c-58.5-29.6-120.3-46.1-185.9-56.3
				c-12.9-2-26.1-3.8-39.5-5.6c-61.4-8.7-124.5-6.1-185.4,6.9c-13.9,2.8-27.6,6.4-41.1,9.6c-86.3,27-181.8,35.1-274.6,22.4
				c-16.7-1.5-33.3-2.8-50-3.8c-12.9-0.3-25.7-1.2-38.6-1.2c-12.5,0-25.7,0.3-38.2,1.5c-16.3,1.2-32.1,2.3-48.5,3.5
				c-16.3,1.2-32.5,2-48.9,2.8c-15.4,1.5-31,3.3-46.4,5.3s-31,4.6-46.4,7.6c-16.7,3.5-33.3,7.2-50,11
				c-61.4,14-122.5,30.1-182.8,47.6v16.8c61.1-18.9,122.5-36,185-51.2c13.9-3.3,27.6-6.6,41.5-9.6c23.9-4.9,47.9-9.2,72.5-11.3
				c17.9-1.2,35.4-2.3,53.3-3.5s35.4-2.6,53.3-4.1c17.1-2,34.6-3.3,52.1-3.5c11.8-0.3,23.2,0.3,35,0.5c15.8,0.8,31.7,2.3,47.5,3.8
				c74.3,10.2,152.2,15.7,224.5-5.6c40.7-11.7,79.7-22.9,121.7-31.3c22.1-4.3,43.9-7.2,66.3-8.4c22.1-1.2,44.4-0.8,66.7,1.2
				c17.9,1.8,35.7,3.8,53.6,6.4c70.7,10.4,138.6,29,198.6,63c31.4,18,54.9,40.3,71.2,64.7c33.3,51.7,32.1,110.1,29.3,165.7
				c-0.4,18.9-1.1,37.5-0.4,56c1.7,28.2,23.2,52.8,55.3,70.3c27.2,14.8,57.8,24.4,89.9,29.6c13.9,2.3,28.1,4.1,42.2,4.6
				c13.9,2,28.1,2.8,41.5,2.6c13.9-0.3,27.2-1.5,40.4-3.8c20.3-3.8,39.5-9.9,56.5-19.1c17.5-9,33.3-19.7,46.8-32.8
				c8.9-11,17.5-22.9,26.8-34.6c9.3-11.9,17.9-24.2,28.1-35.1c3.8-4.3,9.3-9.9,16.6-15.5c5.4-4.1,13.6-9.5,25.9-14.7
				c22.4-9.3,42.4-11,46.2-11.3c0,0,9.8-0.8,121.2-16.3c64.9-9,113.4-31.5,137.5-42.8c57-26.9,95.3-43.9,120.6-75
				c5.5-6.8,21.4-27.6,49.8-42.2c10.6-5.5,20-8.5,26.2-10.2C2300.1,508.2,2300.1,503,2300.1,497.8z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2301.6,690.3c-11.7,6.7-29.1,16.7-50,29c-112.4,65.6-105.4,65.7-123.7,73.5c-66.3,28.3-75.2-7.5-205.4,15.2
				c0,0-74.2,11.8-118.9,43.8c-10.1,7.2-20.7,15.1-31,22.9c-10.1,7.9-19.9,16-29,24.4c-12.2,10.4-23.6,20.9-37.5,29.9
				s-27.6,17.6-42.2,25.8c-15,9.6-29.7,17.1-44.4,23.7c-14.7,6.6-29.7,13-45.1,19.1c-14.7,6.4-29.7,12.8-45.7,18.6
				c-15.8,6.1-31.7,12.5-47.9,18.6c-32.5,12.2-66.3,22.7-101.7,31.3c-31.7,7.6-65.4,10.2-100.4,11.7c-26.1,0.5-50-2.6-68.8-15.3
				c-42.2-28.5-51.3-74.4-70-113.9c-13.6-33.9-21.2-68.5-21.2-108c0-37.2,12.5-72.1,24.7-109.1c14.3-45.9,14.7-95.5-30.4-133.3
				c-31.4-25.8-69.2-43.6-110.7-55.8c-13.9-4.1-27.6-7.6-42.2-10.4c-17.5-3.5-35.7-4.9-54.2-4.6c-18.3,0.3-36.1,2.6-53.3,6.6
				c-15,3.5-29.7,7.6-44.4,11.7c-14.7,4.1-29.7,8.4-44.4,12.8c-14.3,4.6-29,9-43.5,13c-14.7,4.1-29.7,7.6-44.7,10.4
				c-45.7,9-91.9,13.7-138.6,11.3c-15.4-0.8-31.4-2.3-46.8-4.6c-15-1.5-30.1-2.6-45.7-3.8c-15-1.2-30.1-2-45.7-2.6
				c-17.1,0.3-34.2,0.3-51.7,0c-17.1-0.3-34.6-0.8-51.7-2c-14.3,0-28.1,1.8-41.8,4.3c-22.5,4.1-43.9,10.4-64.3,18.3
				c-15,5.6-30.1,11-45.1,17.1c-69.6,28.8-130.3,60.9-188.3,98.4v9.9C13.3,697.1,71,666,132,638.5c11.8-5.3,23.6-10.4,35.3-15.7
				c11.8-4.9,23.9-9.9,36.1-14.5c11.4-4.1,22.8-8.1,35-11c7.4-2.3,15.8-4.1,23.6-4.1c21.2,0.5,42.2,0.8,63.9,0.5
				c21.2-0.3,42.2-1.2,63.6-2.6c18.7,0.8,37.5,1.5,56.5,2.3c16.7,0.8,33.3,1.8,50,2c16.7,0.5,33.3,0.5,50.4,0.5
				c50-0.5,100-3.8,149.3-12.2c16.3-2.8,32.1-6.1,47.5-9.9c31.4-7.9,61.4-18,90.4-31.3c15.4-6.6,33.9-9,52.5-9
				c12.5-0.5,25.3,0,37.9,1.5c12.5,1.5,24.7,3.8,37.1,6.6c15.8,3.8,31.4,8.1,46.4,13s30.4,10.4,45.7,16.3
				c67.1,26.7,91.9,83.6,78.5,136.4c-8.9,36-21.2,68.8-24.3,104.2c-3.6,43.6,3.2,85.6,15,127.4c13.9,45.3,27.6,90,48.5,134.8
				c6.1,11.9,22.1,20.9,39.5,22.1c32.1,2.3,63.9,2,94.7-0.8s61.4-8.1,91.1-15.3c13.9-3.5,27.2-7.2,40.7-11.3
				c12.9-4.1,26.1-9,39.5-14.8c15.4-6.6,31-13.7,46.4-20.4c16.7-6.6,32.9-13.7,48.9-20.6c17.5-6.9,34.6-14.2,50.8-21.7
				c9.7-4.6,18.7-9.2,27.6-14.5c12.5-7.2,25.3-14.8,37.5-22.7s24.3-15.3,36.7-23.2c10.7-9.2,21.5-18.6,32.9-27.8
				c11.8-9,23.9-17.6,36.7-25.8c25.6-16.5,38.1-25.1,52.1-32.2c34.7-17.7,65.3-23.5,86.6-27.3c23.2-4.2,44.8-8.1,73.6-5.7
				c23.2,1.9,33.3,6.5,61.4,6.2c11.4-0.1,26.2-0.4,43.5-4.6c26.3-6.4,44-18.7,53.4-25.1c30.1-20.5,75.8-48.3,137.5-84.8
				C2301.7,699,2301.7,694.7,2301.6,690.3z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2303.3,794.2c-53.2,23.8-99,40.7-134.1,52.5c-16.7,5.6-51,16.8-92.8,25.9c-21.4,4.7-42.3,8.3-66.8,10
				c-10.8,0.7-19.9,1-26.4,1c-10-0.4-84.1-2.8-137.6,15.5c-24.1,8.2-42.2,18.6-42.2,18.6c-12.9,7.2-25.3,15.7-36.7,25.2
				c-14.3,9.9-29.7,20.1-44.4,30.1c-29.7,20.4-58.2,41.8-85,61.9c-7.4,5.3-15,9-22.8,13c-15.4,9.2-31.4,18.3-46.8,27.3
				c-14.3,8.1-28.1,16.3-41.8,24.4c-14.7,8.4-29.3,16.8-44.4,25.5c-44.7,25-88.2,46.4-135.9,65.5c-23.6,9.2-47.9,18-73.2,25.5
				c-29,8.7-58.5,13.7-89.5,8.7c-64.3-9.9-83.5-67.6-90.4-110.3c-5.3-34.9-10.1-70-14.7-104.8c-4.9-34.9-9.3-69.6-13.6-104.8
				c-8.5-70-6.5-139.4-15-210.5c-1.7-13.3-8.9-25.5-19.9-36.6s-25.3-20.9-40.7-29.3c-13.9-7.9-29-14-44.7-18.3
				c-22.5-6.1-46-9.2-69.6-9.6c-21.5-0.3-42.2,1.2-63.1,5.8c-16.7,4.1-33.9,8.4-50.8,12.8c-17.1,4.3-34.2,8.7-51.3,13
				c-14.3,3.5-28.1,6.9-42.8,10.2c-14.3,3.3-29,6.4-43.1,9.6c-28.5,6.4-58.2,10.4-88.2,13.3c-15,1.5-29.7,2-44.7,2.3
				s-30.1-0.3-45.1-1.5c-17.9-1.5-35.7-3.8-54.2-7.2c-23.9-1.2-47.9-2.3-71.7-1.8c-23.6,0.5-47.1,2.8-70.3,8.7
				c-20.7,4.9-40.7,12.5-58.5,22.4c-15.4,7.2-30.4,14.5-45.1,22.1c-66.7,34.6-127.1,73-188.9,113.5v7.2
				c69.6-32.6,129.7-71.7,196.8-110.3c11-6.4,22.5-12.2,33.9-18c11.4-5.8,22.8-11.3,34.6-16.3c28.5-11,59.3-21.4,91.9-20.1
				c19.6,0.5,39,1.8,58.9,2.6c17.9,2.8,35.7,4.6,53.3,5.8c17.9,1.2,35.4,1.5,53.3,1.5c59.3,0,118.5-9.6,175.7-21.2
				c14.3-2.8,28.5-6.1,42.8-9.6s28.1-6.9,42.2-10.4c16.7-4.3,33.3-9,50-13.3c13.9-3.8,27.6-6.1,41.8-7.9c14.3-1.8,29-2,43.5-1.5
				c14.3,0.5,28.1,2,41.8,4.6c13.6,2.8,26.4,6.6,39,12.2c11,4.9,20.7,11,29.7,18.6c12.2,10.7,21.5,23.2,28.5,37.2
				c6.9,14,11,29.3,12.9,45.9c10.7,84.8,12.9,164.1,27.2,238c6.9,36.9,11,69.3,15.4,101.6c4.5,32.2,10.1,64.5,20.3,101
				c15.4,29.9,34.6,68,82.5,73.1c32.5,3.5,63.6-1.2,93.9-9.6c29.7-9.2,58.2-19.7,85.7-31.1c50.8-21.2,97.5-45.6,142.8-71.7
				c15-8.7,29.7-17.4,44.7-26.2c13.9-8.1,27.2-16.6,41.1-24.7c11.4-6.6,23.2-13.3,34.2-20.1c5.7-3.5,11-6.9,16.3-10.4
				c38.6-27.3,73.9-56.9,112.7-83.6c12.5-9,26.1-17.4,37.5-26.5c12.2-10.2,25.7-18.6,40.7-25.8s31-13,47.9-17.1
				c48-11.6,110-6.6,110-6.6c78.5,6.4,157.4-22.4,315.2-80c2.6-0.9,4.6-1.7,5.8-2.1C2302,804,2302.7,799.1,2303.3,794.2z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2299.9,1130.7c-5.4,4.1-13.5,10.2-23.4,17.4c-66.4,47.8-99.5,71.7-138.9,83.5c-58.9,17.6-120.6,5.8-192.6-8
				c-11.4-2.2-24.8-5-44.9-6.9c-15.7-1.5-64.3-3.5-113.1,8.1c-80.6,19.1-127.4,69.3-179.4,112.9c-12.9,9.2-26.4,18.3-39.5,27.3
				c-118.9,81-232.8,166.6-372.7,229.6c-26.8,12.5-55.3,19.7-84.3,26.7c-37.9,6.9-76.1,7.9-113.1-2.3
				c-58.9-16.3-93.5-49.9-112.2-89.7c-19.2-39.8-22.8-85.6-21.2-125.7c2.1-39.2,7.8-78.7,8.9-117.9c1.1-39.2-2.9-78.4-20.3-117
				c-17.5-38.3-48.5-72.1-88.6-99.3c-10.1-6.9-20.7-13.3-31.7-18.9c-18.7-7.9-37.5-15.3-58.5-18.9
				c-105.3-15.7-205.3-23.7-304.2-47.4c-13.6-3.3-27.2-5.6-41.1-6.4c-67.9-5.6-134.6,15.1-181.4,51.7c-9.7,5.8-19.6,11.7-29.3,17.6
				c-47.9,29.3-91.9,59.6-130.6,94.6c47.5-30.1,92.8-63.5,141.5-93.8c12.9-8.4,26.4-16.6,40.7-24.2c48.5-30.5,117.6-43.8,180.3-30.5
				c14.7,3.3,29.3,6.4,43.5,9c81.4,15.3,163.1,19.4,248.9,32.8c32.9,4.6,63.1,11.9,91.1,23.7c12.5,6.1,23.9,13.3,35,20.9
				c21.5,15.1,39.9,32.8,54.6,52c29.3,38.3,43.9,82.8,41.5,126.9c-2.9,49.7-11.8,100.7-12.2,150.6s8.2,98.9,39.9,144.8
				c12.9,18.6,30.1,35.7,50.8,49.7s45.7,25,74.3,30.5c44.7,8.7,87.1,2.3,127.4-9.6c82.1-26.2,150-67.3,215.6-112.4
				c70.7-47.9,136.3-97.8,205.8-144c13.9-9.2,28.1-18.3,42.2-27.5c6.9-4.3,12.9-9,18.3-14.2c122.4-119.2,252.2-103.4,252.2-103.4
				c49.1,0,33.3,0.7,63.2,8.7c20.8,5.6,41.7,8.6,83.5,14.8c48.7,7.1,76.8,11.3,108.9,3c28.3-7.3,67.8-38.3,145.8-100
				c8.3-6.6,15.2-12.1,19.9-15.9C2300.2,1132.5,2300.1,1131.6,2299.9,1130.7z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2300.3,1203.9c-1.9,1.5-4.7,3.8-8.1,6.5c-41.8,34.1-49.3,47.4-74.1,67c-15.6,12.4-35.6,27.9-65.2,36.8
				c-24.3,7.3-44.8,7-56.8,6.5c-76.7-3.4-180.7-40.8-215.7-42.4c-61.9-2.9-150.4,13.1-261.1,101.4c-16.3,9.2-31.7,19.1-47.1,29
				c-108.6,69.6-204.2,150.4-317.4,221.2c-19.2,11.7-37.5,22.7-57.4,32.8c-26.1,13.3-54.2,22.4-83,27.8c-29,5.6-58.9,7.9-89,7.6
				c-42.2-2.3-83.9-12.5-120-32.6c-49.3-27.5-78.1-64.7-96.4-106.3c-31.7-72.9-10.1-149.3-11.8-225.8
				c-1.7-62.4-12.5-123.9-57.4-176.5c-8.2-9.6-17.5-18.6-27.2-27.3c-24.7-24.2-58.5-43-100.4-50.2c-16.7-2.8-32.9-5.8-49.3-9
				c-16.3-3.1-32.1-6.1-47.9-9.2c-15.8-2.8-31.7-6.1-47.5-9c-47.5-8.4-95.3-16-145.3-19.7c-52.9-3.8-101.3,16.3-140.8,38.9
				c-13.6,7.9-26.4,16-39.5,24.7C84.1,1134.4,33,1170.2-13.4,1218c-0.7,0.8,0.7,2.6,0.7,4.1c57.4-36.9,106.5-77.8,163.1-117
				c10.7-7.6,22.1-15.1,33.3-22.1c31-17.4,64.3-38.9,105.3-37.5c144.9,6.1,316.4,11.7,415.3,98.7c9.3,9.6,17.5,19.7,24.3,30.5
				c20.7,31.6,32.1,66.8,37.9,103.9c11,71.4,3.6,148.3,11.4,216.9c7.8,62.2,43.9,116.7,96.4,168.7c9.3,9,19.6,16,32.1,22.7
				c29.7,15.1,61.8,22.9,95.3,25.2c36.1,2.6,71.7-0.3,105.8-7.6c34.2-7.2,66.7-18.9,97.5-34.9c128.1-74.9,234.7-162.8,355.7-246.7
				c13.9-9.6,28.1-18.9,42.2-27.8c14.3-9,28.5-18.3,42.2-27.8c11.1-7.7,12.1-9.2,19.2-14.2c0,0,21-14.9,51.2-28.5
				c38.8-17.5,97.1-43.7,166.4-38.3c38.4,3,37.6,12.6,112.5,29.4c43.9,9.8,80.4,14.8,104.3,17.5c12.7-0.4,29.6-2,48.8-7.1
				c24.2-6.4,41.8-15.7,50.9-20.9c3.7-2.1,21.7-12.6,41.3-30.9c8.1-7.6,14.7-14.8,18.4-18.9c13.1-14.5,18.6-22.8,31.7-33.8
				c4.5-3.8,8.3-6.6,10.9-8.4C2300.5,1210.1,2300.4,1207,2300.3,1203.9z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2303,1243.2c-71.3,72.9-133.5,92.4-176.4,97.1c-64.5,7-87.1-13-229-29.3c-46-5.3-92.4-6.1-134.6,7.2
				c-46.4,14.5-79.7,42.3-117.9,66c-12.5,7.9-26.1,15.1-39,22.7c-39,22.7-76.1,47.6-111.4,73.1c-107.8,77.8-199.7,162.6-323.5,224
				c-39.5,19.1-81,31.1-126.8,32.6c-49.3,2.3-98.9-4.6-143.5-27.3c-59.8-29.9-89.9-73.4-113.1-119.6c-25.7-51.4-37.1-104.5-42.8-159
				c-6.1-57.5-4.9-114.9-11.4-172.7c-2.1-16.8-6.9-33.4-14.7-49.4c-7.4-15.7-17.5-30.8-29.7-45.1c-19.9-24.4-49.3-45.3-85.7-57.1
				c-94.7-31.3-201.3-43-304.2-41c-48.9,1.2-89.5,25-125.4,47.9c-9.3,6.1-18.7,12.2-27.6,18.9C91.7,1170.9,44.2,1215.5-9,1253
				c-6.5,4.9-3.6,15.7-3.2,24.4c52.9-39.2,108.6-82.1,167.2-121.9c11.8-7.9,23.6-15.7,35.7-23.2c98.2-73.4,261.1-60.1,380-18
				c36.1,13,69.2,32.2,88.2,58.1c8.5,9.2,16.3,19.1,23.2,29.3c20.3,30.1,33.3,63.5,37.1,97.8c4.5,43.6,3.6,87.7,5.7,130.7
				c2.5,43.3,8.2,85.4,25.3,126.9c29.3,70,71.7,143.7,168.9,176.5c48.5,16.3,99.3,18,149.7,12.2c27.2-2.6,52.5-8.7,76.8-17.1
				c73.9-26.5,131.8-68.5,188.8-111.4c83-61.9,164.3-131.5,256.7-187.5c12.9-8.1,26.4-15.7,40.4-23.2c45.7-24.2,83-56.9,135.9-72.6
				c47.2-14,101.3-10.3,212,4.3c104,13.7,117.7,26.6,163.1,20c71.5-10.4,124.3-54.9,157.7-90.8
				C2301.1,1259.5,2302.1,1251.3,2303,1243.2z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2301.7,1584.7c-5.8,3.4-15.7,8.3-28.6,10.7c-37.1,6.8-55.5-15.6-138.4-50.8c-23.1-9.8-32.5-12.6-69.1-28.4
				c-14.6-6.3-24.3-10.7-40.9-17.4c-25.3-10.3-44.9-17.4-48.9-18.6c-66-19.1-139.2-32.2-209.6-20.6c-16.7,2.6-33.9,6.6-51.3,11.7
				c-17.5,4.9-34.6,11-51.3,18.6c-67.6,30.1-116.7,67.6-166.7,110.6c-106.5,90.5-186.4,198.8-339.2,248.7
				c-33.9,10.7-69.6,18.6-107.8,22.9c-27.6,2.8-55.8,3.8-85,2.3c-54.2-1.5-107.8-9-158.9-24.2c-82.5-24.7-140.4-76-177.4-132.7
				c-38.6-60.7-70-126.6-107.4-194.7c-23.9-56.3-40.7-117.9-86.8-155.9c-72.1-49.9-161.4,12.5-239.6,71.4
				c-40.7,30.8-79.7,62.4-118.9,92.3c-39,29.9-78.1,57.8-119.2,81.6v31.9c84.6-41,152.9-102.7,236.8-162.8
				c52.9-37.7,110.3-80.5,176.1-69.1c53.3,11.9,86.8,43,111.1,80.7c24.3,37.7,40.7,81.3,58.5,118.7
				c41.5,72.1,76.1,140.9,148.6,195.7c72.1,55.1,175.4,76.4,276.3,80.7c35-0.3,69.6-2.6,103.8-6.6c41.5-6.1,80.6-16,117.1-28.8
				c135.4-51.2,210.3-146,303.9-232.9c64.7-59.8,136.8-114.7,232.8-139.9c19.2-4.9,39.5-9,59.8-11.7c47.9-5.6,97.5-0.5,142.8,11.7
				c15.3,4.1,26.6,7.9,45.1,14.2c21.8,7.5,45.6,16.5,82.3,32.6c37,16.1,55.5,24.1,77.5,35.7c70.6,37.2,87.5,61.5,130.5,63.3
				c18.7,0.8,34.1-2.9,43.8-6C2302.8,1607.9,2302.2,1596.3,2301.7,1584.7z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2314.6,128.4c-23.6,0-65.4,0-122.5,0c-60.3,0-119.2-0.5-179,2c-15,0.5-30.1,1.5-45.1,2.8
				c-29,1.8-56.5,5.6-85,9.2c-19.9,2.6-39.9,4.9-59.8,7.2s-39.5,4.3-59.3,6.4c-13.6,1.5-26.4,2.3-39.9,3.3
				c-13.6,0.8-26.4,1.5-39.9,2c-67.6,2.6-131.8-10.7-196.4-19.4c-34.6-4.9-69.6-9.6-104.6-13.7c-104.9-12.2-210.7-20.1-317.4-18.3
				c-93.9,1.5-187.5,4.3-281,7.6c-18.7,0.5-37.5,1.2-56.1,1.8c-148.2,6.6-295,13-442.5,8.4c-17.5,0-35,0-52.5,0.3
				c-17.5,0-35,0.3-52.9,0.3c-70.3,0.3-140.8,0.3-211.1,0.3V151c71.2,0.3,142.1,0,213.1-0.3c14.3,0,28.5-0.3,42.8-0.3
				c149.3,2.8,296.5,4.3,447.9-9c19.6-1.8,38.6-2.6,57.8-2.6c21.2-0.5,41.8-1.5,63.1-2c105.3-3.3,210.3-6.1,315.7-6.1
				c82.1-0.3,164.3,4.3,245,16.3c26.8,4.1,53.3,8.7,79.7,14.2c15,3.3,29.7,6.6,44.4,10.2s29.7,7.2,44.4,11
				c39.9,9.6,81.4,16.8,123.2,19.1c13.9,0.8,28.5,0.5,43.1-0.3c12.5-0.3,24.3-1.2,37.9-3.3c113.9-18,228.2-34.9,343.9-45.3
				c19.6-1.5,39.9-2.3,60.3-2.6c20.3-0.3,40.7,0,61.4,0.8c56.9,0,99.3,0,122.5,0v-22.5h-1.1L2314.6,128.4L2314.6,128.4z"/>
                    <path :fill="colors[`${id}-p-2`]" d="M2314.6,21.1h-154.2c-27.2,0-57.4,0-89.9,0c-36.7,0-72.8-0.1-109.3,0.5c-111.4,1.9-222.1,4.4-333.9,3.3
				c-45.7-0.6-91.1-1.3-136.8-2.1c-45.7-0.8-91.1-1.5-137.1-2.3c-203.1-4.5-406-2.9-609.1-1.3c-22.5,0.2-45.1,0.4-67.6,0.5
				C554.2,21,433.1,21,310.6,20.9c-15.8,0-31.7,0-47.5,0s-31.7,0-47.5,0c-151.3,0-246,0-246,0v6.7c0,0,94.7,0,246.1,0
				c14.7,0,29.3,0,43.9-0.1c14.7,0,29.3,0,43.9-0.1c138.3,0.7,275.8-0.4,414.2-2.1c19.9-0.2,39.9-0.5,59.8-0.6
				c179.4-1.7,358.8-3.4,539.9,0.5c32.9,0.7,65.4,1.7,97.5,2.9c32.1,1.1,64.7,2.4,97.1,3.5c78.5,2.8,157.1,4.6,237.5,2.4
				c148.9-4.2,293.8-7.5,442.1-6.4H2314v-6.8h0.6V21.1z"/>
                </g>
                <g id="waves_x5F_1">
                    <path :fill="colors[`${id}-p-3`]" d="M2310.2,360.3c-73.2,8.1-144.6,43.4-211,69c-15,5.8-28.5,12.5-41.1,20.4c-31,18.3-51.3,42.6-75,66
				c-7.8,7.9-15.8,15.1-24.7,22.4c-16.3,13-38.6,20.4-62.2,25.8s-48.5,9-70.7,15.3c-15,3.8-29.3,6.4-43.5,9.6
				c-37.5,8.4-73.2,19.1-98.5,42.3c-15.4,14-29.3,26.7-44.7,41c-2.5,2.6-4.9,5.6-7.4,8.1c-19.2,17.1-52.5,3.8-69.6-8.1
				c-26.8-18-38.2-40-45.1-63.9c-3.2-11.9-5.7-24.4-7.8-36.9c-12.5-80.1-75.3-157.3-177.8-193.9c-17.9-8.4-37.1-16-56.5-22.9
				c-19.6-6.9-39.9-13-60.7-18.3c-41.5-10.7-84.6-18.3-127.4-22.9c-21.5-2.3-43.1-4.3-64.3-6.1c-21.5-1.8-42.8-2.8-64.3-4.1
				c-54.6-3.3-108.2-3.5-163.6,4.9c-134.3,20.9-261.1,28.5-392.5,27.5c-19.9,0.5-40.4,0.8-60.3,0.8s-40.4-0.3-60.3-0.8
				c-15.4,0.3-31.4,0.8-46.8,1.8c-15.4,0.8-31.4,1.8-46.8,3.3c-18.3,1.8-36.7,3.5-54.9,5.6C77.9,352,22.6,358.4-32.7,365.6
				c-2.1,0.3-0.7,3.8-1.1,6.1c54.9-4.9,109.3-9.9,164.3-15.1c18.3-1.8,36.7-3.5,54.6-5.3c19.6-2,38.6-3.8,58.2-4.1
				c35.7-0.5,71.7-1.2,107.4-2.3c17.9-0.5,35.7-1.2,53.6-1.8c39-1.5,79,1.2,118.5,1.2c85-0.8,164.3-14.5,251-26.5
				c62.5-9,121.7-12.2,183.2-9c23.9,1.2,48.5,2.8,73.2,5.6c49.6,4.9,100,12.8,148.6,23.7c24.3,5.6,47.9,11.9,71.2,19.1
				c12.9,4.1,25.7,8.4,37.9,13c12.2,4.6,24.3,9.6,36.1,15.1c102.2,33.9,156.2,107.7,175.7,184c2.1,9.9,3.6,19.7,5.7,29.6
				c7.4,39.5,17.9,78.4,69.6,99.6c26.1,11,60.3,8.4,77.2-10.2c23.2-25.8,39.5-53.7,76.8-70.6c17.9-8.1,36.7-14.5,55.8-19.7
				c19.2-5.3,38.6-10.4,58.9-15.3c11.4-2.6,23.6-5.3,35.7-7.9c35-7.9,72.5-16.8,96.8-37.7c11-9.2,21.5-18.9,32.1-28.5
				c32.1-28.8,63.6-57.1,108.9-75.4c13.9-5.3,28.1-9.6,42.2-13c49.6-12.2,98-49.3,150.5-52.5l4.9,22.6l-0.2,0.1L2310.2,360.3z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2301.3,455.3c-6.9,1.3-17.2,4-28.5,10c-13.3,7-18,13.4-35.4,26c-11.3,8.2-13.4,8.2-28.6,18.4
				c-22.7,15.3-38.7,27.8-44.6,32.4c-59.4,46-88.6,70.4-119.2,82.4c-28.1,11-59.1,18.5-93.2,21c-52.5,3.5-105.3,6.1-155.3,18
				c-25.7,3.3-43.5,14.8-56.5,29c-12.9,14.5-21.2,31.9-28.5,47.1c-8.2,10.7-16.3,20.9-24.3,31.1c-7.4,9-17.9,15.7-28.5,20.9
				c-16.3,8.4-33.3,14.2-51.3,16.8c-17.9,2.8-36.1,2.8-54.9-0.3c-18.7-1.8-37.5-5.3-55.3-11c-41.1-13-78.5-38.7-83-72.1
				c-4.5-29-4.5-58.1-6.1-87.1c-1.7-28.8-5.3-57.5-17.1-85.9c-21.5-51.4-75.3-90.9-135.4-119.8c-26.8-12.8-56.1-22.9-87.1-30.5
				c-43.5-10.4-87.9-18.6-132.1-25c-14.7-2-29.7-3.8-44.4-5.6c-48.9-5.6-100-10.2-146.4,0.8c-33.9,7.9-67.9,14-102.2,18.9
				c-17.1,2.3-34.2,4.3-51.3,6.1s-34.2,3.3-51.7,4.1c-17.1,1.2-34.6,2-51.7,2.6c-51.7,1.8-103.8,1.2-155.8-0.5
				c-18.7,0.3-37.9,0.3-56.5,0.8c-16.7,1.2-33.3,2-50,2.6s-33.9,1.2-50.8,1.5c-32.9,0-65,6.6-96.8,12.5c-15,2.6-30.1,5.3-44.7,7.9
				c-58.9,10.7-117.9,23.2-176.5,37.2v11.7c7.4-1.2,14.7-2.6,22.5-4.3c47.9-10.7,95.7-21.2,143.2-30.8c23.9-4.6,47.5-9.2,71.7-13.7
				c15.8-2,30.4-4.9,46-6.4c18.7-1.5,37.5-2.8,55.8-4.3s36.7-2.8,54.9-4.1c13.6-1.2,26.4-2,39.9-2.8c37.9-2.8,76.1,0.5,113.9,3.8
				c12.5,1.2,25.3,2,37.9,2.8c68.3,6.4,138.3,0.8,203.5-16.6c17.5-3.5,34.6-6.9,52.1-10.2c69.6-12.5,139.9-20.1,211.1-8.4
				c15,1.8,29.7,4.1,44.4,6.4c44.4,6.9,87.9,16.6,130.6,28.5c42.2,11.9,81,30.8,111.8,54.6c75,54,84.3,127.2,94.7,200.8
				c3.6,24.7,7.4,49.1,13.6,73.1c7.4,26.7,35.7,47.6,69.6,59.2c22.1,7.6,44.7,11.9,67.9,13.7c13.6,2.3,26.8,3.3,40.4,2.8
				c13.6-1.5,26.4-3.5,38.6-6.4c12.2-2.8,23.9-7.2,35.3-12.8c11.8-6.1,25.3-11.3,32.5-21.2c6.5-10.7,14.3-20.9,22.8-29.9
				c4.9-8.4,11.4-16.3,17.1-23.5c6.9-9,15.4-16.8,25.3-23.5c12.1-8.2,22.8-13.6,31.7-17.1c35.9-14.2,79-20,134.1-27.7
				c43.9-6.2,50.9-7.3,56.2-8.2c49.1-8.1,73.7-12.2,90.8-19.9c36.8-16.5,65.2-44.4,75.6-54.5c7.8-7.7,16-16.6,31.1-29.2
				c16-13.3,18.7-13.1,39.1-28.2c35-25.9,42.1-37.5,66.6-46.3c9.1-3.3,17-4.8,21.7-5.6C2301.2,463.5,2301.2,459.4,2301.3,455.3z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2300.2,1699.5c-7.1,2.7-17.9,5.8-31.1,6.4c-17.9,0.8-37.6-3.4-76.4-26.1c-21.2-12.5-49.6-31.3-80.1-59
				c0,0-32.7-22.5-82.3-46.8c-18.3-9-139.4-73.2-247.7-63c-116.7,11-196.4,71.4-268.2,133.8c-111.4,96.3-201.3,209.3-366,255.8
				c-44.4,12.2-90.7,19.4-139.2,20.9c-43.1,1.5-86.8,0.8-129.3-4.1c-42.8-4.6-84.3-13.7-123.6-28.5c-83-31.6-148.9-77.5-193.9-136.1
				c-39-51.4-72.8-101.6-118.9-156.5c-16.7-21.2-36.7-38.9-63.9-48.4c-82.5-27.3-165.3,29.6-236.8,72.3
				c-64.3,36.9-118.9,72.3-185.4,96.3v23.7c84.3-30.5,155.3-73.4,240.8-114.9c38.6-19.4,79-37.5,122.1-36
				c57.4,1.8,107.4,30.5,133.5,71.4c28.5,40,58.5,76.9,93.5,110.6c35,33.4,73.9,64.5,122.1,92.3c43.9,25.2,91.9,43.8,142.8,56
				c50.8,12.2,104.2,17.6,158.6,16.3c31.7,0,62.5-2,92.8-5.8c33.4-4.2,79.2-13.8,116-25.8c82.5-26.9,145-74.6,193.2-112
				c3.1-2.4,43.4-38.3,123.8-110.1c64.3-57.4,99-88.4,155.5-118.1c25.1-13.1,45.7-23.9,75.7-31.9c80.6-21.4,150.2-1.7,183.4,8.2
				c91.7,27.2,170,85.5,204.4,111.1c25.6,19,39.7,27.3,68.5,43.3c33.5,18.7,56.9,28.1,85,30.1c13.6,0.9,25-0.1,33-1.3
				C2301.5,1715.5,2300.8,1707.5,2300.2,1699.5z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2299.7,1876.8c-7.5,2.3-19.1,5.2-33.5,6.5c-8.6,0.8-43.8,3.3-107-21.7c-26.6-10.5-46.5-21.4-60-29
				c-64.8-36.1-107.6-72.5-116.7-79.7c-75.8-60.3-292.4-93.1-404.8,14.8c-96.4,92.5-187.5,185-334.9,230.8
				c-27.6,9-56.1,16.8-85.7,23.2c-38.2,8.1-77.8,13.7-118.9,16.3c-46.8,2.3-93.5,2.3-139.9-1.2s-92.4-10.7-137.1-22.4
				c-83-21.7-153.8-52.5-230.7-89.2c-81-38.9-150.6-80.7-237.2-106.8c-119.2-20.9-225,34.9-327.4,70.8c-3.2,1.2-6.1,2-9.3,2.8v22.4
				c6.5-2,12.9-4.3,19.6-6.6c83.5-29.3,163.6-70,258.2-70c46.4-0.3,87.9,15.7,128.1,31.9c190.8,76,362.4,184.3,586.7,187.5
				c32.5,0.8,65,0,97.1-2c47.5-2.6,93.2-10.2,137.9-21.4c11.7-2.9,39.3-9.9,71.2-20.9c122.2-42.2,210.6-109.4,239.4-132.2
				c68.6-54.6,59.1-64.6,113-97.9c32.5-20,89-53.9,164.2-58.3c69.6-4.1,121.2,19.1,179.7,45.3c84.2,37.7,110.6,72.8,202,111.9
				c21.3,9.1,64.7,26.4,119.6,21.8c11.5-1,21-2.7,27.1-4C2300,1892.1,2299.9,1884.5,2299.7,1876.8z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2301.6,526.6c-4.7,2.2-13.5,6.5-24.1,12.8c-72.2,42.9-82.5,92.9-150.4,127.7c-16.2,8.3-29.9,12.8-38.6,15.6
				c-31.9,10.3-65.6,15.2-101,14.5c-76.8-1.2-180.7-5.6-217.6,53.5c-9.7,10.4-18.3,21.7-27.6,32.6c-9.3,11-18.3,22.1-27.2,32.2
				c-8.5,8.1-18.3,15.3-28.5,21.7c-10.1,6.4-21.2,12.8-32.1,18.6c-19.6,7.9-40.4,14.2-63.1,17.4c-14.7,2.3-29.7,3.8-45.1,4.1
				c-15,0.3-30.4-0.3-46.4-1.8c-15-0.5-30.1-2.3-45.1-4.6c-35.3-6.4-69.2-18.6-99.7-37.7c-35-21.7-43.5-49.9-43.1-79.5
				s9.7-60.7,10.1-88.9c0.4-44.6-3.6-91.7-37.5-130.4c-19.6-24.4-46.4-43.8-77.8-59.6c-31-15.3-66.3-26.7-102.9-34.9
				c-17.1-3.8-34.2-7.2-51.3-10.4c-17.1-3.3-34.2-6.1-51.3-8.7c-42.2-7.6-87.5-11.9-130.3-5.6c-14.3,2-28.1,5.6-41.1,10.4
				C728.6,465.1,611,466.9,499.2,459c-18.7-0.8-37.5-1.8-55.8-2.6c-18.7-0.8-37.1-1.8-55.3-2.3c-16.3,1.2-32.1,2-48.5,2.8
				c-16.3,0.8-32.5,1.2-48.9,1.5c-15,1.2-29.7,2.8-44.7,4.6c-14.7,2-29.3,4.1-43.9,6.6c-12.9,2.8-25.7,5.8-38.6,9
				c-12.9,3.3-25.7,6.6-38.6,10.2c-58.9,16-114.3,33.1-169.2,55.8v5.8c11-3.3,22.1-6.6,33.3-10.4c30.4-10.7,61.1-20.9,91.5-30.1
				c15.4-4.6,31-9.2,46.4-13.3c15.4-4.3,31.4-8.4,46.8-12.5c22.8-5.6,45.7-9.9,68.8-13.7c21.5-3.5,43.1-6.1,65-6.4
				c19.9-0.5,39.9-1.8,59.3-2.8c12.9-0.5,25.7-1.2,38.6-1.5c11.4-0.5,22.5-0.3,33.9,0.5c16.7,0.8,32.9,2,49.3,3.5
				c39,3.8,77.8,9.2,116.7,10.2c12.9,0.3,26.1,0,39.5-1.2c16.7-1.5,33.3-3.8,49.3-6.6c48.5-8.7,93.9-22.7,142.1-33.1
				c13.9-3.3,27.6-5.6,41.5-7.2c41.5-5.3,84.3-4.6,126.1-0.5c18.3,2,36.1,4.3,54.2,7.6c53.6,9.2,105.3,24.2,153.8,48.4
				c22.1,11,40.7,23.7,55.8,38.3c53.3,46.4,47.9,110.6,37.9,166.9c-3.6,19.4-8.2,38.9-11,57.8c-5.7,33.9,10.7,64.7,39.9,88.2
				c29,23.7,70.7,40.6,116,47.1c19.9,2.6,39.5,4.1,58.5,4.1c17.1,1.2,33.3,0.8,50-0.3c16.3-1.2,32.1-3.5,47.9-6.4
				c18.7-3.8,36.1-9.6,53.3-16.3c13.6-7.6,26.8-14.8,39.5-22.7c12.5-7.9,23.9-16.8,32.5-28.2c5.7-8.1,12.2-16.3,19.6-23.7
				c7.4-7.6,15-15.1,22.8-22.4c25.3-33.7,77.2-53.1,129-56.6c73.5-5,96.7,13.8,168.9-7.6c26.8-7.9,69.8-20.7,105.3-57.1
				c9.4-9.7,38.1-41.3,54.8-59.8c9-9.9,23.3-25.8,47.1-38.1c8.6-4.5,16.2-7.3,21.5-9C2301.2,532.5,2301.4,529.6,2301.6,526.6z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2301.4,646.2c-31.1,12.7-54.2,26.3-69.4,36.3c-22.7,14.9-68.6,48.5-109.1,77.9c-5.5,4-16.4,11.8-32.5,16.2
				c-20,5.6-36.8,2.9-43.5,1.9c-23.9-3.5-86.8-12.8-138.1-5c-8.9,1.4-62.3,11-99.3,41.3c-11,9-21.5,18-30.4,27.3
				c-11.4,10.7-23.9,21.2-36.1,31.6c-12.2,10.4-23.6,21.2-34.6,31.3c-10.1,8.1-21.5,15.3-33.3,22.1c-17.5,9.9-34.2,17.4-51.3,24.2
				c-17.1,6.6-35,12.5-53.3,17.6c-15,4.3-30.4,8.7-46.4,12.5c-17.1,4.3-34.6,8.4-52.5,12.2c-28.1,5.8-56.5,10.2-86.8,12.5
				c-34.6,2-70,1.5-106.5-2.8c-46-6.4-71.7-39.2-87.9-68.3c-12.9-22.7-20.7-47.6-27.6-71.7c-16.3-59.6,10.1-112.4,24.7-170.7
				c11.4-40.6,4-85.1-36.1-118.5c-40.4-33.4-91.1-56.3-146.8-70.3c-13.9-3.5-28.1-6.4-42.8-8.7c-20.7-1.8-42.2-2.6-63.1-1.2
				c-20.9,1.4-41.1,5.3-60.3,12.5c-15.4,6.4-31.7,11.9-47.9,16.8c-16.3,4.9-32.9,9.6-50,13.3C673,552.3,601.4,558,529.9,557
				c-17.9-0.3-35.7-1.2-53.3-2.3c-17.9-1.2-35.3-2.8-52.9-4.9c-18.7-0.8-37.9-2-56.5-3.8c-13.6,0-26.8,0.3-39.9,0.5
				c-13.6,0.5-26.4,1.2-39.9,2.6c-21.5,1.8-41.8,5.3-61.8,9.9c-20.3,5.8-40.4,12.5-59.8,19.4c-13.9,4.9-27.2,10.2-41.1,15.3
				C111.2,599,98,604.4,84.4,610c-43.3,18-85.7,36.9-127.6,56.7V688C7.6,661.8,59,636,112.6,613.1c21.2-9,42.8-17.6,64.7-26.2
				c18.7-6.9,37.9-13.3,57.4-18.3c23.9-4.9,47.5-8.1,72.1-9.2c14.3,0,29,0.3,43.5,0.8c14.7,0.3,29.3,0.5,43.9,0.8
				c13.6,1.5,26.4,2.8,39.9,4.3c13.6,1.5,26.4,2.8,39.9,4.3c86.8,11.7,178.5,9,259.6-18.3c15.4-4.6,31-9.2,46.4-14.5
				c15.4-4.9,30.4-9.9,46-15.1c9.3-2.8,19.2-5.6,28.5-7.6c12.2-2.8,24.7-4.9,37.5-6.4c25.3-2.8,50.8-2.3,76.1,2.6
				c15.4,3.3,30.4,6.6,45.7,10.7c15.2,4.1,29.7,8.7,44.4,14.2c68.8,25.2,133.5,74.6,116,133.3C1160.3,728.3,1140,784,1142,843
				c1.1,44.1,18.3,86.2,39.5,127.7c12.9,25,31.4,55.1,68.3,59.6c35.7,4.1,70.7,4.3,104.6,2c41.8-3.5,81-10.7,118.5-20.9
				c18.7-4.9,37.1-10.7,55.3-16.3c17.1-5.3,33.9-11.3,50.4-17.1c20.3-6.9,39.9-14.5,58.9-23.2c17.9-7.9,32.1-17.6,48.5-27.3
				c10.1-6.4,19.6-12.8,28.1-20.4c8.5-7.6,17.1-15.3,26.1-23.2c8.9-7.9,17.5-15.3,26.8-22.7c16.6-13.2,38.6-31.6,46-37.2
				c53-39.9,138.6-37.4,151.4-37c21.9,0.7,32,3.4,84.4,5.8c23.6,1.1,43.9,1.6,66.2-8.4c11.6-5.2,18-10.7,31.2-21.4
				c41.2-33.6,68.8-56.1,96.1-72.1c12.7-7.4,32.6-17.6,59.7-25.9C2301.9,658.7,2301.7,652.4,2301.4,646.2z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2152.1,1089.8c-13.8,3.4-23.6,3.1-41.8,5.6c-62.2,8.4-121.2-7.9-183.2-16c-33.9-4.3-66.3,0-95.3,14
				c-88.2,44.1-155.3,95-221.8,150.4c-13.6,11.3-26.8,20.6-40.7,29.6c-13.6,8.7-26.8,17.6-40.7,26.5c-27.2,17.6-54.6,35.1-82.5,52
				c-28.1,16.8-56.9,32.8-87.1,47.9c-30.1,14.8-61.4,28.5-94.4,40.3c-24.3,9-49.6,17.6-75.3,25.8c-19.6,6.4-42.2,5.6-63.6,2.3
				c-61.1-8.7-92.4-38-109.3-73.7c-17.1-36-20.3-78-25.3-113.2c-4.9-41-6.9-81.3-11.4-121.9s-11.4-81.3-25.7-122.3
				c-6.1-17.6-12.5-35.1-19.6-52c-7.4-16.8-15-33.7-24.3-49.9c-17.5-32.8-39.5-66.8-84.6-84.8c-45.1-17.4-96.4-21.2-146.4-12.2
				C576.9,856.5,477.2,871,373,851.9c-19.2-3.5-37.5-3.8-56.9-3.5c-18.3,0.3-36.1,1.8-53.6,5.6c-17.1,3.5-33.9,9.2-49.3,17.6
				c-14.7,6.6-29,14-42.8,21.4c-70,37.7-131.4,83.9-191.7,131.2c-2.9,2,2.5,5.3,4,8.1c24.3-13.7,47.1-27.8,71.7-43
				c39.5-24.7,78.1-49.4,118.5-71.7c13.6-7.6,27.2-14.5,41.1-21.2c15.8-7.9,32.9-16,50.8-21.2c17.9-5.3,37.5-7.9,57.8-4.3
				c129.7,23.7,262.8,12.8,396.3,0.8c66.7-6.1,129.3,25.5,157.1,67.6c12.2,14,22.8,28.5,32.1,43s17.5,29.3,24.7,44.1
				c18.7,39.8,29,81,32.1,123.4c4,54-0.7,111.8,6.9,166.9c4,27.5,11,54.8,23.6,80.5c12.9,25.8,31.7,50.2,58.9,72.9
				c29.3,24.4,78.5,26.7,119.2,17.1c38.2-9,73.9-20.4,110-33.7c68.3-26.5,128.5-61.6,185.4-98.4c19.2-12.5,37.9-25.2,56.5-38
				c14.3-9.6,28.5-19.1,42.8-29c14.3-9.6,29-20.1,43.1-31.9c27.6-24.7,56.1-47.9,90.7-68.8c52.5-31.9,100-67.6,164.3-85.1
				c19.2-5.3,40.7-4.3,60.7-0.3c76.1,15.2,136.3,20,189.6,12.2c19.6-2.9,38.1-8.6,38.1-8.6c17.8-5.5,32-12.2,42.1-17.5
				c39.6-27.3,58.5-38.7,68.3-43.8c2.8-1.4,13.7-7,27.2-16.3c4.3-3,7.7-5.6,9.9-7.2c-0.5-4.6-1-9.1-1.4-13.7
				c-2.3,1.4-12,7.6-14.3,9.1C2200.7,1070.6,2181.1,1082.6,2152.1,1089.8z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2300.9,1075.7c-6.5,4.7-16.5,12.1-28.4,21.5c-21,16.7-27.3,23.3-40,32.6c0,0-19,13.9-46.4,26.4
				c-13.2,6-52,21.7-145.8,19.5c-37-0.8-87.8-4.6-147.6-17.1c-18.8-2.3-47.3-3.5-79.6,5.2c-34.3,9.2-56.6,25.2-86.2,47
				c-55.3,40.5-98.4,72.8-128.3,95.3c-15.8,10.7-31.7,21.2-47.9,31.3c-112.2,72.1-226.7,142.8-356,199.5
				c-35,16.3-75.3,23.7-116,20.6c-32.9-2.3-59.8-11-81.4-23.7c-21.5-12.8-38.2-29.9-50.4-49.4c-22.1-35.4-31-74.6-29.3-113.2
				c2.1-48.4,8.9-98.1,8.2-147.2c-0.4-53.1-12.9-106-50.8-153.6c-9.7-11.9-20.3-23.2-31.7-33.9c-11.4-10.7-23.9-20.6-37.5-29.9
				c-28.5-20.1-61.1-38.3-102.2-42.1c-115.6-10.2-228.5-13.7-341.4-31.1c-15-2-30.1-4.1-45.1-6.4c-60.3-9.2-113.9,15.3-157.1,41.3
				c-11,6.1-22.1,12.2-32.5,18.6c-52.9,31.6-101.3,68-146,103.9c52.5-31.9,102.2-67,155-99.3c13.9-8.4,28.1-16.8,42.8-24.7
				c36.7-22.4,82.5-43,132.6-35.1c13.6,2,26.4,4.1,39.5,6.1c116.4,21.4,235,27.8,356.8,38c48.5,4.3,84.6,30.1,116.4,55.1
				c15.4,12.5,29.3,25.8,40.7,40.3c11.8,14.5,21.2,30.1,28.1,47.1c18.7,45.1,22.8,90.5,22.8,136.1c0,45.9-4.9,91.7-4,137.3
				c0.7,57.1,30.1,129.7,98.5,162.3c22.8,10.7,49.6,17.4,81.4,17.1c31.7-2.8,62.2-9,91.5-17.6c23.2-6.4,43.9-14,64.7-24.7
				c117.9-61.2,212-128,314.9-194.7c23.4-15.2,17-11.2,30.4-20.1c145.1-96.6,223.5-156.9,272.2-146c4.8,1.1,23.2,5.9,49.6,8.4
				c14.1,1.4,24.3,1.2,36.1,1.6c25.1,0.9,50.2,1.4,75.2,2.3c36.9,1.4,66.5,2.7,102.9-3c0,0,31.6-4.9,65.2-17.8
				c8.8-3.4,15.9-6.6,23.2-10.8c4.3-2.5,15.3-9,27.9-20.2c7.9-7,11.4-11.4,17.2-17.5c8-8.5,20.1-19.9,37.4-32
				C2300.6,1078,2300.7,1076.8,2300.9,1075.7z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2299.7,1092.2c-26.2,30.8-68.1,70.7-128.9,92.7c-104.8,37.9-135.9,6.7-269.3-3.5c-10-0.8-16.8-1-22.4-1
				c0,0-4.6,0-9,0.2c-8.3,0.4-30.7,2.9-53.2,10.2c-59.9,19.6-122,60.2-122,60.2c-32.1,21-77.1,54.4-96.9,67.4
				c-20.8,13.7-21.5,14.1-21.5,14.1c-8.8,5.8-9.5,6.2-13.1,8.6c-56.9,37.7-112.2,76.7-172.9,114.4c-56.1,34.9-111.4,66.8-175.4,92.3
				c-26.8,11.7-52.9,20.1-80.1,29c-6.9,2-14.3,3.5-21.5,4.1c-33.9,4.3-67.6,0.5-97.1-9.2c-29.7-9.9-55.3-26.2-71.7-47.6
				c-29.7-38-42.8-79.8-41.8-122.3c1.7-55.8,11.4-113.2,8.2-169.5c1.1-63.2-28.5-124.6-81-174.5c-10.7-9.9-22.1-19.4-33.9-28.5
				c-61.4-49.9-161.1-48.4-247-52.5c-67.6-3.5-130.6-18-193.5-32.2c-17.5-3.5-35-4.6-52.1-4.1c-51.7,1.8-101.3,19.7-139.9,44.4
				c-10.7,5.6-21.2,11-31.7,16.6c-61.4,30.8-109.3,63.9-149.3,108.3c-0.7,0.8,2.1,2.6,2.5,4.3c10.1-6.1,19.9-12.5,29.7-18.9
				c35-22.9,69.2-47.4,104.9-70.6c11.8-7.6,23.9-15.1,36.7-22.4c45.7-30.8,110.7-54.6,172.9-40.3c15.8,3.8,32.1,6.9,47.9,9.9
				c86.3,17.1,173.6,23.2,263.6,31.3c49.6,4.3,101.3,11.9,133.9,40.6c12.2,9,23.2,18.6,33.9,28.5c10.1,10.2,19.9,20.6,28.1,31.3
				c40.7,52.2,61.1,113.4,56.9,173c-4.9,75.2-12.2,150.9,11.8,224.7c10.1,32.6,36.7,61.6,72.1,80.1c35.4,18.5,79.7,27,125.7,17.1
				c30.4-7.9,61.1-14.2,89-26.2c143.2-59.8,260.4-143.4,380.3-224c26.9-18.1,35.6-24.6,39.9-27.8c46.3-33.5,58.5-46.8,91.1-67.5
				c26.4-16.8,48.1-26.8,53.1-29.1c24-11,67.1-30.8,120.8-33.8c16.4-0.9,30.3-0.1,165.7,20.2c2.4,0.4,8.1,1.2,15.6,1.9
				c12.3,1.1,29.8,1.8,50.4-0.3c29-3,52-10.5,66.6-16.1c21.3-7.7,51.3-21.4,81.4-46.4c18.4-15.3,32.3-31,42.5-44.4
				C2299.7,1100.7,2299.7,1096.5,2299.7,1092.2z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2302.6,1294.1c-10.1,9.5-25.1,22.4-44.9,35.2c-18.7,12.2-48.5,31.2-90.2,40.2c-34.9,7.5-62.2,4.5-91.2,1.4
				c-32.6-3.6-35.6-7.7-94.8-20.8c-58.6-12.9-88.7-19.3-126.6-18.2c-30.7,0.9-55,5.9-67.3,8.9c-13.4,3.2-40.1,9.7-71,25.6
				c-23.5,12.1-26.1,17.5-68.2,42.4c-16.9,10-27.3,15.2-37.1,20.9c-36.7,21.4-71.7,45.1-107.1,68.5
				c-126.5,85.9-215.6,200.3-378.2,249c-38.6,10.2-79,16.3-120.3,17.4c-27.6,0.5-55.8-2.3-81.7-9.2c-26.1-6.9-50-17.6-70-32.2
				c-41.1-29.6-71.2-62.7-93.5-98.1c-40.4-63-53.6-131.5-65-200.6c-10.7-65.3-10.1-129.5-34.6-193.4c-5.7-14.5-13.6-28.5-23.2-41.8
				c-53.3-75.7-187.9-98.7-298.2-92.3c-41.1,2.6-81,14.5-112.7,33.9c-18.3,10.2-35.4,21.4-50.4,33.4
				c-68.3,45.9-130.6,98.4-191.7,143.4c0,10.2-0.7,20.9-2.5,31.1c48.9-38.3,95.7-79.2,147.2-117c17.1-12.8,35.3-27,54.9-41
				c9.7-6.9,19.9-14,30.1-20.6c35.3-22.9,76.5-45.6,126.8-49.4c108.9-7.2,231,24.2,281,97c9.3,13,17.1,26.7,23.2,41
				c6.1,14.2,10.7,29,12.9,43.8c11.4,63.2,15.8,127.7,30.1,189.8c14.3,62.2,37.5,121.9,85,176.5c33.9,49.1,84.3,100.1,169.2,115.5
				c55.3,8.1,107.8,4.3,158.6-6.4c39-9.2,75-22.1,110-41.5c26.1-15.1,50-31.3,74.3-48.7c114.7-82.5,210-180.9,338.1-249
				c5.6-3,20.6-10.3,43.1-22.4c50.1-26.9,48.9-29.3,66.7-36.2c17-6.5,38.8-10.1,82.6-17.3c26.8-4.4,40.3-6.6,50.6-7.3
				c11.5-0.8,33.7-1.5,104.6,12.3c62.9,12.3,102.6,21.4,102.6,21.4c12.4,2.8,29.6,6.9,53.4,8.1c0,0,24.9,1.2,48-1.9
				c60.8-8.3,119.6-56.3,129-64.1C2303.7,1312.2,2303.2,1303.2,2302.6,1294.1z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2304,1427.1c-14.7,12.8-28.1,20.5-37.1,24.9c-72.3,35.9-150.7,10.1-176.6,1.5c-14.8-4.9-15-7.8-35-14
				c-80.6-25-166.7-48.7-256.7-39.2c-44.7,4.6-83,20.9-120.3,37.2c-15.4,6.1-30.1,12.8-44.4,19.7
				c-113.9,56.6-196.1,142.5-290.1,218.6c-63.1,51.2-125.4,103.4-214.9,127.2c-31.7,8.7-64.3,14-98.9,15.1
				c-45.7,2.3-92.4-0.8-140.4-11.9c-58.5-15.1-105.3-41.8-139.5-77.5c-58.2-59.8-103.3-124.6-120.7-200.3
				c-16.3-76.7-26.1-148-56.5-218.6c-8.9-19.1-20.7-40.6-36.7-60.4c-7.8-9.9-16.7-19.4-26.4-27.8c-50.8-52.2-158.6-50.2-220-9.2
				c-11.4,5.6-22.1,11.3-32.5,17.4c-52.5,30.1-97.5,66-142.4,103.4c-48.9,41.3-93.5,80.7-143.9,119c-2.5,13-2.9,26.5-4.9,39.8
				c19.2-14.2,37.5-28.5,56.1-43.6c41.1-33.4,78.5-67,117.9-99.3c39.5-32.6,80.1-63.9,127.4-93.2c10.7-6.6,21.5-13,32.9-18.9
				c11.4-5.8,23.6-11,36.1-14.8c50.4-16.8,113.1-4.9,151.3,22.4c9.7,6.6,18.3,14.2,26.1,22.4c23.2,24.4,37.5,54.6,46,83.6
				c17.9,64.2,30.4,127.4,50.4,188.4c19.9,60.9,47.5,119.3,95.3,173.6c32.9,36.9,74.3,69.1,130.6,91.7
				c40.4,16.3,83.5,25.2,127.8,28.8c43.9,3.3,89,0.5,132.1-7.6c34.6-6.9,66.7-17.6,97.5-31.1c47.1-24.2,88.2-52.5,127.8-84.5
				c98.9-80.1,188.3-180.6,309.6-241.3c15-7.6,31-14.5,46.8-20.6c109.3-54.3,250.2-34.2,362,5.6c19.5,7,19.9,8.9,38.2,15.1
				c37.4,12.7,116.8,38.5,196.2,9.4c12.5-4.6,22.4-9.6,29.1-13.3C2303.6,1452.2,2303.8,1439.6,2304,1427.1z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2302.5,1491.9c-24.1,16.2-45.1,22.1-59,24.6c-54.1,9.6-101.4-14.8-170.8-38.3c-2.4-0.8-7.7-2.6-14.5-5.5
				c-7.4-3.2-10.2-5.4-28.3-12.1c-100.9-37.2-227.8-56.3-329.9-11.3c-16.7,5.6-32.9,11.9-48.9,18.9
				c-125,55.8-212.8,152.9-310.7,234.2c-54.2,45.1-111.4,86.2-185.9,112.1c-38.6,12.5-79,21.2-122.1,24.4
				c-38.2,2.6-77.8,2-118.5-3.8c-112.7-15.7-196.4-78.4-240.8-143.2c-33.3-50.2-63.1-99.9-81.4-155.9
				c-17.9-57.1-26.8-110.3-46.4-166.1c-18.3-53.1-50-114.4-135.4-124.8c-103.8-13.7-177,50.2-237.5,101.9
				c-70,60.7-132.6,119.6-209.1,172.7c-1.1,13.3-2.9,26.5-4,40c83-52.5,147.7-115.5,222.1-176.8c46.8-38.3,96-72.9,158.2-96.1
				c116-19.4,172.1,75.4,194.9,141.7c23.9,70.3,43.1,137.3,78.5,202.9c38.2,70.8,78.5,136.4,167.6,180.6
				c44.7,21.7,93.2,34.6,142.8,40c49.6,5.6,100.4,4.1,150-3.8c33.3-5.8,65-14.2,95.7-25c141.5-58.4,226.1-159,332-248.5
				c50.4-42.6,105.8-81.6,172.5-107.5c16.7-6.4,34.2-12.2,51.7-17.1c92.4-29.9,197.4-11.7,284.3,19.1c19,6.7,23.3,9.6,35.8,15.1
				c6,2.7,10.5,4.4,14.6,6.1c97.6,39.2,140.7,58.9,193.7,53.3c10.9-1.1,27.6-3.9,47.8-11.8
				C2301.8,1518.6,2302.2,1505.2,2302.5,1491.9z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2301.4,2073.4c-35.2,10-79.7,18.4-131.1,18.1c-16.8-0.1-32.7-1.1-47.4-2.7
				c-304.4-66.5-446.1-58.4-517.8-33.9c-9.6,3.3-80.8,29-175.7,54.3c-37.1,9.9-73.9,19.1-111.8,27.5c-34.2,7.6-68.8,14.2-103.8,19.7
				c-35,5.4-70.3,9.6-106.1,11.9c-37.9,2.6-75.3,4.9-113.1,6.6c-106.1,4.9-211.1-1.2-315.7-21.7c-81-16-152.2-41.5-227.8-64.7
				c-69.2-21.2-137.1-40.6-212.4-48.2C137.8,2029.9,47,2063.8-44.5,2087v25.2c58.5-13.3,113.9-29.6,176.5-38.7
				c85-11.9,166-3.8,243.6,17.1c118.5,31.9,225,75.4,353.5,92c91.1,11.7,179.4,11.3,267.8,7.6c29.3-1.2,58.9-2.8,88.6-4.6
				c39-1.2,77.2-4.6,115.1-9.9c37.9-5.3,75-11.9,112.2-19.4c31.4-6.4,62.2-13.7,93.2-20.9c133.9-29,264-81,408.4-72.1
				c136.2,8.4,150.8,50.9,315.7,52.8c74.7,0.9,136.2-7.8,176.8-15.3C2305.1,2091.6,2303.3,2082.5,2301.4,2073.4z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2314.6,41.5h-154.2c-27.2,0-57.4,0-89.9,0c-36.7,0-72.8-0.1-109.3,0.5c-111.4,1.9-222.1,4.4-333.9,3.3
				c-45.7-0.6-91.1-1.3-136.8-2.1c-45.7-0.8-91.1-1.5-137.1-2.3c-203.1-4.5-406-2.9-609.1-1.3c-22.5,0.2-45.1,0.4-67.6,0.5
				c-122.5,1.3-243.6,1.3-366.1,1.2c-15.8,0-31.7,0-47.5,0s-31.7,0-47.5,0c-151.3,0-246,0-246,0V48c0,0,94.7,0,246.1,0
				c14.7,0,29.3,0,43.9-0.1c14.7,0,29.3,0,43.9-0.1c138.3,0.7,275.8-0.4,414.2-2.1c19.9-0.2,39.9-0.5,59.8-0.6
				c179.4-1.7,358.8-3.4,539.9,0.5c32.9,0.7,65.4,1.7,97.5,2.9c32.1,1.1,64.7,2.4,97.1,3.5c78.5,2.8,157.1,4.6,237.5,2.4
				c148.9-4.2,293.8-7.5,442.1-6.4H2314v-6.8h0.6V41.5z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2300.1,3.4h-151.3c-26.7,0-56.3,0-88.2,0c-36,0-71.4-0.1-107.3,0.4c-109.3,1.5-217.8,3.4-327.5,2.5
				c-44.8-0.5-89.3-1-134.1-1.6s-89.3-1.1-134.4-1.8c-199.2-3.5-398.2-2.3-597.4-1c-22.1,0.1-44.2,0.3-66.3,0.4
				c-120.1,1-239,1-359.1,0.9c-15.5,0-31.1,0-46.6,0s-31.1,0-46.6,0C92.8,3.3,0,3.3,0,3.3v5.2c0,0,92.9,0,241.4,0
				c14.4,0,28.7,0,43.1-0.1c14.4,0,28.7,0,43.1-0.1C463.3,8.8,598.1,8,733.9,6.7c19.5-0.1,39.2-0.3,58.6-0.5
				c175.9-1.3,352-2.6,529.5,0.4c32.2,0.5,64.2,1.3,95.7,2.2s63.4,1.8,95.3,2.7c77,2.1,154.1,3.5,232.9,1.9
				c146-3.2,288.2-5.8,433.6-5h120.1v-5C2299.6,3.4,2300.1,3.4,2300.1,3.4z"/>
                    <path :fill="colors[`${id}-p-3`]" d="M2300.9,2292.9h-151.3c-26.7,0-56.3,0-88.2,0c-36,0-71.4,0.1-107.3-0.4c-109.3-1.5-217.8-3.4-327.5-2.5
				c-44.8,0.5-89.3,1-134.1,1.6c-44.8,0.6-89.3,1.1-134.4,1.8c-199.2,3.5-398.2,2.3-597.4,1c-22.1-0.1-44.2-0.3-66.3-0.4
				c-120.1-1-239-1-359.1-0.9c-13.6,0-27.4-0.1-46.6,0c-78.3,0.3-80.2,1.5-120.3,1.1c-50.4-0.5-57.8-2.6-107.9-2.4
				c-25.4,0.1-46.2,0.8-59.7,1.3c0-1.7,0-3.5,0-5.2c11.9-2.1,29.9-4.9,51.9-6.7c39.8-3.2,53,0.1,122.9,2.1
				c44.9,1.3,83.6,1.2,110.9,1.2c22,0,37.9-0.2,44.6-0.3c104.6-1.1,217.1,3.5,403.6,5.4c11,0.1,26.8,0.2,58.6,0.5
				c175.9,1.3,352,2.6,529.5-0.4c32.2-0.5,64.2-1.3,95.7-2.2s63.4-1.8,95.3-2.7c77-2.1,154.1-3.5,232.9-1.9
				c146,3.2,288.2,5.8,433.6,5h120.1C2300.6,2289.4,2300.7,2291.1,2300.9,2292.9z"/>
                </g>
            </g>
        </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>