<template>
    <div class="tool row g-0">
        <transition name="fade">
            <div class="d-flex justify-content-center align-items-center loading" v-if="creatingOrder">
                <font-awesome-icon icon="spinner" size="3x" color="#fff" spin/>
            </div>
        </transition>

        <div class="editor col-lg-9">
            <div id="preview" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item" :class="{active: index === 0}" v-for="(view, index) in product.views"
                         :key="view.title">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                             x="0px" y="0px" xml:space="preserve" :viewBox="view.viewBox" :class="activeElement"
                             v-if="carouselIndex === index || creatingOrder" :ref="'view-' + index">
                            <g v-if="view.before" v-html="view.before"></g>

                            <defs>
                                <component v-for="(pattern, key) in patterns" :key="key" :id="key" :is="pattern.value"
                                           :colors="colors"
                                           :scale="pattern.scale"
                                           :rotate="pattern.rotate"></component>
                            </defs>

                            <template v-for="(item, itemIndex) in view.items" :key="itemIndex">
                                <path @click="selectItem(item)"
                                      v-if="item.type === 'path' && (!item.layout || item.layout === this.activeLayout)"
                                      :d="item.content"
                                      :fill="colors[item.id]"
                                      :style="item.style"
                                      :transform="item.transform" :class="{editable: item.editable}"></path>

                                <g v-if="item.type === 'g' && (!creatingOrder || item.print) && (!item.layout || item.layout === this.activeLayout)" :class="item.id" :stroke="colors[item.id]"
                                   v-html="item.content"
                                   :style="item.style" style="pointer-events: none;"></g>

                                <svg v-else-if="item.content === 'content'" @click="selectItem(item)" :id="item.id"
                                     :x="item.x"
                                     :y="item.y" :width="item.width" :height="item.height" overflow="visible"
                                     class="content" :class="{active: item.id === activeItem.id}">

                                    <g :transform="'rotate(' + item.rotate + ')'" transform-origin="center"
                                       transform-box="fill-box">
                                        <transition name="fade">
                                            <rect width="100%" height="100%" x="0" y="0"
                                                  v-if="activeElement === 'logos' && !creatingOrder" stroke="#eeeeee"
                                                  stroke-width="8px"></rect>
                                        </transition>

                                        <text v-if="content[item.id] && content[item.id].text" x="50%" y="50%"
                                              dominant-baseline="central"
                                              text-anchor="middle"
                                              style="font-size: 2rem;"
                                              :style="{'font-family': content[item.id].fontFamily, 'font-size': content[item.id].fontSize}"
                                              :fill="colors[item.id]" v-text="content[item.id].text">
                                        </text>

                                        <svg
                                            v-if="content[item.id] && content[item.id].image && imageObjects[content[item.id].name]"
                                            width="100%"
                                            :height="content[item.id].height + '%'"
                                            x="0"
                                            :y="(100 - content[item.id].height) / 2 + '%'"
                                            v-html="imageObjects[content[item.id].name].html"
                                            :viewBox="imageObjects[content[item.id].name].viewBox"></svg>
                                        <image
                                            v-else-if="content[item.id] && content[item.id].image"
                                            width="100%"
                                            :height="content[item.id].height + '%'"
                                            x="0"
                                            :y="(100 - content[item.id].height) / 2 + '%'"
                                            :xlink:href="creatingOrder ? '/' + content[item.id].name : content[item.id].image"
                                            onerror="this.style.opacity='0'"
                                        />
                                    </g>
                                </svg>
                            </template>
                        </svg>
                    </div>
                </div>

                <div class="carousel-indicators">
                    <button class="carousel-control-prev" type="button" data-bs-target="#preview" data-bs-slide="prev">
                        <font-awesome-icon icon="chevron-left"/>
                        <span class="visually-hidden">Previous</span>
                    </button>

                    <button type="button" data-bs-target="#preview" :data-bs-slide-to="index" class="indicator"
                            :class="{active: index === 0}" aria-current="true" :aria-label="index"
                            v-for="(view, index) in product.views" :key="view.name"
                            @click="carouselIndex = index"></button>

                    <button class="carousel-control-next" type="button" data-bs-target="#preview" data-bs-slide="next">
                        <font-awesome-icon icon="chevron-right"/>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="design-ready d-flex justify-content-center">
            <div class="d-flex align-items-center">
                <button type="button" class="btn btn-primary text-uppercase" @click="designReady">Design klaar</button>

                <div class="d-lg-none" @click="toggleView">
                    <font-awesome-icon icon="chevron-down" :rotation="fullScreen ? '0' : '180'"/>
                </div>
            </div>
        </div>

        <aside class="col-lg-3">
            <div :class="{'active': activeElement === 'layout'}" class="options-wrapper">
                <h4 @click="selectActiveElement('layout')">Ontwerp</h4>
                <div class="options grid">
                    <div v-for="layout in product.layouts" :key="layout.value"
                         :class="{active: layout.value === activeLayout}" class="item" data-bs-toggle="tooltip"
                         :title="layout.name" @click="activeLayout = layout.value">
                        <div class="position-relative">
                            <font-awesome-icon icon="check" v-if="layout.value === activeLayout"/>
                            <img :src="layout.image">
                        </div>
                    </div>
                </div>
            </div>


            <div :class="{'active': activeElement === 'color'}" class="options-wrapper">
                <h4 @click="selectActiveElement('color')">
                    <span v-if="activeElement === 'color' && activeItem.name">{{ activeItem.name }}</span>
                    <span v-else>Kleur</span>
                </h4>

                <div class="options">
                    <div v-show="activeItem.type === 'path'">
                        <div class="sub-options">
                            <h5 @click="activeSubOption = 0">Kleur</h5>
                            <color v-show="activeSubOption === 0" :preview-colors="previewColors"
                                   :selected-color="colors[activeItem.id]" :detail="{key: activeItem.id}"
                                   @open-color-modal="showColorModal" @select-color="selectFillColor"></color>
                        </div>
                        <div :class="{'active': activeElement === 'color'}" class="sub-options grid-wrapper">
                            <h5 @click="activeSubOption = 1">Patterns</h5>
                            <div v-show="activeSubOption === 1" class="options grid">
                                <div v-for="pattern in patternOptions"
                                     :class="{active: aPattern.value === pattern.value}"
                                     :value="pattern.value" :key="pattern.value" class="item" data-bs-toggle="tooltip"
                                     :title="pattern.name" @click="updateFill(pattern.value)">

                                    <div class="position-relative">
                                        <font-awesome-icon icon="check" v-if="aPattern.value === pattern.value"/>
                                        <img :src="pattern.image">
                                    </div>
                                </div>
                                <div class="item cancel" @click="cancelPattern">
                                    <font-awesome-icon icon="times"/>
                                </div>
                            </div>
                        </div>
                        <template v-if="aPattern.colors">
                            <div class="sub-options" v-for="(color, index) in aPattern.colors"
                                 :key="color">
                                <h6 @click="activeSubOption = index + 10">{{ aPattern.name }} kleur
                                    {{ color }}</h6>
                                <color v-show="activeSubOption === index + 10" :preview-colors="previewColors"
                                       :selected-color="colors[`${activeItem.id}-p-${index}`]"
                                       :detail="{key: `${activeItem.id}-p-${index}`}" @open-color-modal="showColorModal"
                                       @select-color="selectColor"></color>
                            </div>

                            <div class="sub-options px">
                                <h6 @click="activeSubOption = 4">Transform</h6>
                                <div v-show="activeSubOption === 4">
                                    <div class="range mb-4">
                                        <label for="patternRotate">
                                            <span>Rotate</span>{{ aPattern.rotate }} &deg;
                                        </label>
                                        <input type="range" class="form-range" min="0" max="360"
                                               v-model.lazy.number="aPattern.rotate" @change="render"
                                               id="patternRotate">
                                    </div>

                                    <div class="range">
                                        <label for="patternScale" class="form-label">
                                            <span>Scale</span>{{ aPattern.scale }} &percnt;
                                        </label>
                                        <input type="range" class="form-range" min="10" max="200"
                                               v-model.lazy.number="aPattern.scale" @change="render"
                                               id="patternScale">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div v-show="activeItem.type !== 'path'" class="select-element">
                        <font-awesome-icon :icon="['far', 'hand-pointer']" size="3x"/>
                        <p>klik op een vlak om de kleur te wijzigen</p>
                    </div>
                </div>
            </div>


            <div :class="{'active': activeElement === 'logos'}" class="options-wrapper">
                <h4 @click="selectActiveElement('logos')">
                    <span v-if="activeElement === 'logos' && activeItem.name">{{ activeItem.name }}</span>
                    <span v-else>Logo's</span>
                </h4>
                <div class="options">
                    <div v-if="activeItem.content === 'content'">
                        <div class="sub-options grid-wrapper">
                            <h5 @click="activeSubOption = 0">LOGO</h5>
                            <div v-show="activeSubOption === 0">
                                <div class="help text-sm">
                                    <p>Wij acepteren: .svg, .jpg en .png bestanden. <a
                                        target="_blank" href="tel:+31682098033">Help met logo's.</a></p>
                                </div>
                                <div class="options grid">
                                    <label for="logo" class="item">
                                        <font-awesome-icon icon="plus"/>
                                    </label>
                                    <input type="file" id="logo" @change="updateImages($event)" accept=".svg, .png, .jpg, .jpeg"
                                           multiple class="d-none" maxlength="2" max="50">

                                    <div class="item cancel" v-if="aContent.image" @click="cancelContent">
                                        <font-awesome-icon icon="times"/>
                                    </div>

                                    <div @click="selectImage(image)" :key="image.name" v-for="image in imageGallery"
                                         class="item">
                                        <img :src="image.src" :title="image.name" style="width: 50%;">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="sub-options px" v-if="aContent.image">
                            <h6 @click="activeSubOption = 2">Transform</h6>
                            <div v-show="activeSubOption === 2">
                                <div class="range">
                                    <label for="patternRotate">
                                        <span>Height</span>{{ aContent.height }} &percnt;
                                    </label>
                                    <input type="range" class="form-range" min="20" max="100"
                                           v-model.number="aContent.height" id="height">
                                </div>
                            </div>
                        </div>

                        <div class="sub-options px">
                            <h5 @click="activeSubOption = 1">Lettering</h5>
                            <div v-show="activeSubOption === 1">
                                <label for="text" class="form-label">
                                    <span>
                                        max:
                                        <template v-if="aContent.text">
                                            ({{ aContent.text.length }} / {{ aContent.maxTextLength }})
                                        </template>
                                    </span>
                                </label>
                                <input type="text" id="text" :maxlength="aContent.maxTextLength"
                                       v-model="aContent.text" @input="updateText" autocomplete="false"
                                       class="form-control">
                            </div>
                        </div>

                        <div v-if="aContent.text">
                            <div class="sub-options">
                                <h6 @click="activeSubOption = 2">Text Size</h6>
                                <div v-show="activeSubOption === 2" class="font-size">
                                    <button class="btn btn-primary" @click="decreaseFontSize"
                                            :disabled="aContent.fontSize <= 20">
                                        <font-awesome-icon icon="minus"/>
                                    </button>
                                    <span>{{ aContent.fontSize }}</span>
                                    <button class="btn btn-primary" @click="increaseFontSize"
                                            :disabled="aContent.fontSize >= aContent.maxFontSize">
                                        <font-awesome-icon icon="plus"/>
                                    </button>
                                </div>
                            </div>

                            <div class="sub-options">
                                <h6 @click="activeSubOption = 4">Text Color</h6>
                                <color v-show="activeSubOption === 4" :preview-colors="previewColors"
                                       :selected-color="colors[activeItem.id]"
                                       :detail="{key: activeItem.id}" @open-color-modal="showColorModal"
                                       @select-color="selectColor"></color>
                            </div>

                            <div class="sub-options grid-wrapper">
                                <h6 @click="activeSubOption = 3">Font</h6>
                                <div v-show="activeSubOption === 3" class="options grid">
                                    <div @click="updateFont(fontFamily)" class="font item"
                                         v-for="fontFamily in fontOptions"
                                         :title="fontFamily.name" :key="fontFamily.name" data-bs-toggle="tooltip"
                                         :style="{'font-family': fontFamily.value}">
                                        <div class="position-relative">
                                            <font-awesome-icon icon="check"
                                                               v-if="aContent.fontFamily === fontFamily.value"/>
                                            Aa
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="select-element">
                        <font-awesome-icon :icon="['far', 'hand-pointer']" size="3x"/>
                        <p>Klik op een vlak om een logo of tekst toe te voegen.</p>
                    </div>
                </div>
            </div>

            <div :class="{'active': activeElement === 'details'}" class="options-wrapper">
                <h4 @click="selectActiveElement('details')">Details</h4>
                <div class="options">
                    <div v-for="(detail, index) in product.details" :key="detail.key" class="sub-options">
                        <h5 @click="activeSubOption = index">{{ detail.name }}</h5>
                        <color v-show="activeSubOption === index" :preview-colors="previewColors"
                               :selected-color="colors[detail.key]" :detail="detail" @open-color-modal="showColorModal"
                               @select-color="selectColor"></color>
                    </div>
                </div>
            </div>

            <div :class="{'active': activeElement === 'order'}" class="options-wrapper">
                <h4 @click="selectActiveElement('order')" class="order-title">Custom shirt design</h4>
                <div class="options px">
                    <p>Kies hieronder de maten die je wilt bestellen en vul je gegevens in. Je ontvangt van ons spoedig
                        een betaallink. Na betaling gaan we direct in productie en leveren we je shirts binnen 3 tot 5
                        weken bij je thuis af.</p>

                    <div class="d-flex justify-content-between align-items-lg-end my-4 flex-column flex-lg-row">
                        <button type="button" class="btn btn-dark text-uppercase" @click="returnToOverview">Bewerk custom design
                        </button>
                        <a href="https://tdsportswear.com/nl/maattabellen/" target="_blank" class="text-black">Maat advies</a>
                    </div>

                    <form @submit.prevent="createOrder">
                        <div class="order-items">
                            <div class="header">
                                <div>Aantal</div>
                                <div>Geslacht</div>
                                <div>Maat</div>
                            </div>

                            <div class="order-item" v-for="(orderItem, index) in orderItems" :key="index">
                                <input type="number" class="form-control" min="0" max="999" name="quantity"
                                       v-model="orderItem.quantity">
                                <select name="gender" class="form-select" v-model="orderItem.gender">
                                    <option value="male">Man</option>
                                    <option value="female">Vrouw</option>
                                </select>
                                <select name="size" class="form-select" v-model="orderItem.size">
                                    <option value="4xs">4XS</option>
                                    <option value="3xs">3XS</option>
                                    <option value="xxs">XXS</option>
                                    <option value="xs">XS</option>
                                    <option value="s">S</option>
                                    <option value="m">M</option>
                                    <option value="l">L</option>
                                    <option value="xl">XL</option>
                                    <option value="2xl">2XL</option>
                                    <option value="3xl">3XL</option>
                                    <option value="4xl">4XL</option>
                                    <option value="5xl">5XL</option>
                                </select>
                            </div>

                            <div class="buttons">
                                <button type="button" class="btn" :disabled="orderItems.length === 1"
                                        @click="removeOrderItem">
                                    <font-awesome-icon icon="minus"/>
                                </button>
                                <button type="button" class="btn" @click="addOrderItem">
                                    <font-awesome-icon icon="plus"/>
                                </button>
                            </div>
                        </div>

                        <div class="customer mt-5 mb-2">
                            <p>
                                <strong>Contact Gegevens</strong>
                            </p>

                            <div class="row small gy-2">
                                <div class="col-md-6">
                                    <label for="name" class="form-label">Naam</label>
                                    <input required type="text" class="form-control" id="name" v-model="customer.name">
                                </div>
                                <div class="col-md-6">
                                    <label for="phoneNumber" class="form-label">Telefoonnummer</label>
                                    <input required type="tel" class="form-control" id="phoneNumber"
                                           v-model="customer.phoneNumber">
                                </div>
                                <div class="col-12">
                                    <label for="email" class="form-label">E-mail</label>
                                    <input required type="email" class="form-control" id="email"
                                           v-model="customer.email">
                                </div>
                            </div>
                        </div>

                        <input type="submit" :disabled="creatingOrder"
                               class="btn btn-success text-uppercase text-white mb-5" value="Prijs aanvragen">
                    </form>
                </div>
            </div>

            <div class="footer col-lg-3 col-12" v-show="activeElement !== false">
                <div @click="returnToOverview">
                    <font-awesome-icon icon="arrow-left" size="lg"/>
                    <span>Terug</span>
                </div>
            </div>
        </aside>

        <color-modal :initialised="colorModalInitialised" :color-options="colorOptions"
                     :selected-color="colors[colorModalId]" :id="colorModalId"
                     @select-color="selectColor"></color-modal>

        <start-customize-modal @select-product="selectProduct" :product-id="productId" :title="title" :image="image"></start-customize-modal>
        <order-created-modal></order-created-modal>
    </div>
</template>

<script>
import Checkerboard from "../clothing/pattern/checkerboard";
import Clouds from "../clothing/pattern/clouds";
import StripesDiagonally from "../clothing/pattern/stripes-diagonally";
import AbstractStripes from "../clothing/pattern/abstract-stripes";
import Stripes from "../clothing/pattern/stripes";
import Drops from "../clothing/pattern/drops";
import Caos from "../clothing/pattern/caos";
import Dots from "../clothing/pattern/dots";
import Triangles from "../clothing/pattern/triangles";
import Maze from "../clothing/pattern/maze";
import Panther from "../clothing/pattern/panther";
import GradientLines from "../clothing/pattern/gradient-lines";
import Separation from "../clothing/pattern/separation";
import Waves from "../clothing/pattern/waves";
import Zebra from "../clothing/pattern/zebra";
import Color from "./Color";
import ColorModal from "./ColorModal";
import StartCustomizeModal from "./StartCustomizeModal";
import {colors} from "./options/patone-colors";
import {patterns} from "./options/patterns";
import {fonts} from "./options/fonts";
import {Modal, Tooltip, Carousel} from "bootstrap";
import OrderCreatedModal from "./OrderCreatedModal";

const axios = require('axios').default;

export default {
    components: {
        OrderCreatedModal,
        StartCustomizeModal,
        Clouds,
        StripesDiagonally,
        Stripes,
        Drops,
        Caos,
        Dots,
        Triangles,
        Maze,
        Panther,
        GradientLines,
        Separation,
        Checkerboard,
        Waves,
        Zebra,
        AbstractStripes,
        Color,
        ColorModal
    },
    data() {
        // product -> views (front, back, left, right) -> items
        return {
            maxFiles     : 22,
            maxFileSizeMB: 5,

            imageGallery: [],
            imageObjects: {},

            content: {},

            patternOptions: [],
            patterns      : {},

            colorModalId: false,
            colorModal  : false,

            colorOptions : [],
            colors       : {},
            previewColors: [],

            fontOptions: [],

            activeLayout   : 'default',
            activeItem     : false,
            activeElement  : false,
            activeSubOption: 0,

            fullScreen: true,

            carousel     : false,
            carouselIndex: 0,

            creatingOrder: false,

            blackColor: '#2D2926',

            customer: {
                name       : "",
                email      : "",
                phoneNumber: "",
            },

            orderItems: [{
                quantity: 1,
                gender  : 'male',
                size    : 'm'
            }],

            products : ['shirt', 'shorts', 'pants', 'coat'],
            productId: false,

            product  : {
                views: []
            }
        }
    },

    created() {
        this.colorOptions   = colors;
        this.fontOptions    = fonts;
        this.patternOptions = patterns;
        this.previewColors  = colors.slice(0, 15);

        // Get product id
        let urlParams = new URLSearchParams(window.location.search);
        let productId = urlParams.get('product');
        if (!productId || !this.products.includes(productId)) {
            productId = this.products[0];
        }
        this.selectProduct(productId);

        window.onunload = window.onbeforeunload = function (e) {
            let message = "Weet u zeker dat u de pagina wilt verlaten?";
            e           = e || window.event;

            if (e) {
                e.returnValue = message;
            }

            return message;
        };
    },

    mounted() {
        // console.log(JSON.stringify(this.product))
        let previewEl = document.querySelector('#preview');
        this.carousel = new Carousel(previewEl, {
            interval: false,
            keyboard: true,
            touch   : true
        });
        previewEl.addEventListener('slide.bs.carousel', (e) => {
            this.carouselIndex = e.to;
        })

        let startCustomize = new Modal(document.getElementById('startCustomize'));
        startCustomize.show();

        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        });
    },

    methods: {
        selectItem(item) {
            if (item.editable === false) {
                return;
            }

            // Open color menu
            if (item.type === 'path') {
                this.activeElement = 'color';
            }

            if (this.activeElement) {
                this.activeItem = item;
            }

            // Path has pattern, preselect element
            if (item.type === 'path') {
                this.activeSubOption = this.patterns[item.id] ? 1 : 0;
            }

            // Path has content, preselect element
            if (item.content === 'content') {
                this.activeSubOption = this.aContent.text ? 1 : 0;
            }
        },

        selectActiveElement(element) {
            // Reset
            if (this.activeElement) {
                this.activeElement = false;
            } else {
                this.activeElement = element;
            }
        },

        selectImage(image) {
            this.aContent = {
                name  : image.name,
                image : image.src,
                height: 75,
            }
        },

        selectFillColor(obj) {
            this.selectColor(obj);
            this.updateFill(obj.color.hex);
        },

        selectColor(obj) {
            this.colors[obj.id] = obj.color.hex;
            this.render();

            // check if in preview
            let inPreview = this.previewColors.find(color => color.hex.toLowerCase() === obj.color.hex.toLowerCase());
            if (!inPreview && obj.color.hex) {
                this.previewColors = this.previewColors.slice(0, -1);
                this.previewColors.unshift(obj.color);
            }

            if (this.colorModal) {
                setTimeout(() => {
                    this.colorModal.hide();
                }, 300);
            }
        },

        // Change pattern to first color in preview and return to color tab
        cancelPattern() {
            let obj                         = this.previewColors[0];
            this.colors[this.activeItem.id] = obj.hex;
            this.updateFill(obj.hex);
            this.activeSubOption = 0;
        },

        cancelContent() {
            delete this.content[this.activeItem.id];
        },

        showColorModal(id) {
            if (this.colorModal === false) {
                this.colorModal = new Modal(document.getElementById('colorModal'));
            }
            this.colorModalId = id;
            this.colorModal.show();
        },

        updateText() {
            if (this.aContent.type !== 'text') {
                this.aContent                   = {
                    fontFamily   : this.fontOptions[0].value,
                    fontSize     : 70,
                    maxTextLength: 20,
                    maxFontSize  : 100,
                    text         : this.aContent.text
                }
                this.colors[this.activeItem.id] = this.blackColor;
            }

            this.validateText();
        },

        updateFont(fontFamily) {
            this.aContent.fontFamily = fontFamily.value;
            this.validateText();
        },

        decreaseFontSize() {
            this.aContent.fontSize = this.aContent.fontSize - 4;
            this.validateText();
        },

        increaseFontSize() {
            this.aContent.fontSize = this.aContent.fontSize + 4;
            this.validateText();
        },

        validateText() {
            this.$nextTick(() => {
                let parent        = document.getElementById(this.activeItem.id);
                let parentWidth   = parent.getBBox().width > parent.getBBox().height ? parent.getBBox().width : parent.getBBox().height;
                let childWidth    = parent.getElementsByTagName('text')[0].getBBox().width;
                let textLength    = this.aContent.text.length;
                let maxTextLength = Math.round(parentWidth / (childWidth / textLength)) - 2;

                this.aContent.maxTextLength = maxTextLength;

                // Block width
                if (textLength === maxTextLength) {
                    this.aContent.maxFontSize = this.aContent.fontSize;
                } else if (maxTextLength < textLength) {
                    // Slice text, to big (font change)
                    this.aContent.text = this.aContent.text.slice(0, maxTextLength);
                } else {
                    // Room for more text
                    this.aContent.maxFontSize = 100;
                }
            })
        },

        // value can be hex color or pattern name
        updateFill(value) {
            if (value[0] === '#') {
                // RGB color, reset pattern
                delete this.patterns[this.activeItem.id];
            } else {
                let pattern = this.patternOptions.find(pattern => pattern.value === value);

                // Add pattern
                this.patterns[this.activeItem.id] = {
                    scale : pattern.scale,
                    rotate: 0,
                    value,
                    name  : pattern.name,
                    colors: pattern.colors.length
                };

                // Set pattern default colors
                pattern.colors.map((color, index) => {
                    this.colors[`${this.activeItem.id}-p-${index}`] = color;
                });

                // Set pattern as fill color
                this.colors[this.activeItem.id] = `url(#${this.activeItem.id})`;
            }
        },

        updateImages(event) {
            if (this.imageGallery.length >= this.maxFiles) {
                return;
            }

            const parser = new DOMParser();

            this.imageGallery = this.imageGallery
                .concat(
                    Array.from(event.target.files)
                        .filter((file, index) => {
                            // Filename exists
                            if (!file.name || this.imageGallery.find(image => image.name === file.name)) {
                                return false;
                            }

                            // To large 5MB
                            if (file.size > (this.maxFileSizeMB * 1024 * 1024)) {
                                return false;
                            }

                            // To many files
                            if (this.imageGallery.length + index >= this.maxFiles) {
                                return false;
                            }

                            return true;
                        })
                        .map(file => {
                            file.src = URL.createObjectURL(file);
                            if (!file.type.includes('svg')) {
                                return file;
                            }

                            let reader    = new FileReader();
                            reader.onload = (() => {
                                return (e) => {
                                    let doc                      = parser.parseFromString(e.target.result, "text/html");
                                    let svg                      = doc.querySelector("svg");
                                    this.imageObjects[file.name] = {
                                        viewBox: svg.getAttribute('viewBox'),
                                        html   : svg.getInnerHTML()
                                    };
                                }
                            })(file);
                            reader.readAsText(file);

                            return file;
                        })
                );

            // Auto select first
            if (this.imageGallery.length > 0) {
                this.selectImage(this.imageGallery[0]);
            }
        },

        returnToOverview() {
            this.activeElement   = false;
            this.activeItem      = false;
            this.activeSubOption = 0;
        },

        addOrderItem() {
            this.orderItems.push({
                quantity: 1,
                gender  : 'male',
                size    : 'm'
            });
        },

        removeOrderItem() {
            this.orderItems.splice(-1, 1);
        },

        designReady() {
            this.activeElement = 'order';
            this.fullScreen    = false;
        },

        render() {
            let tmp            = this.carouselIndex;
            this.carouselIndex = false;
            this.$nextTick(() => {
                this.carouselIndex = tmp;
            })
        },

        createOrder() {
            this.creatingOrder = true;
            let form           = new FormData();
            let contentKeys    = Object.keys(this.content);
            let data           = {
                name         : this.customer.name,
                email        : this.customer.email,
                phone        : this.customer.phoneNumber,
                product      : this.productId,
                layout       : this.activeLayout,
                referer      : document.referrer,
                order_lines  : this.orderItems,
                user_text    : contentKeys
                    .filter(contentKey => {
                        return this.content[contentKey].text;
                    })
                    .map(contentKey => {
                        return {
                            key          : contentKey,
                            text         : this.content[contentKey].text,
                            'font-size'  : this.content[contentKey].fontSize,
                            'font-family': this.content[contentKey].fontFamily,
                        }
                    }),
                image_details: contentKeys
                    .filter(contentKey => {
                        return this.content[contentKey].image;
                    })
                    .map(contentKey => {
                        return {
                            key           : contentKey,
                            'image-name'  : this.content[contentKey].name,
                            'image-height': this.content[contentKey].height
                        }
                    }),
                colors_used  : Object.keys(this.colors)
                    .filter(color => {
                        return this.colors[color];
                    }).map(color => {
                        let hex     = this.colors[color];
                        let pantone = hex[0] === '#' ? this.colorOptions.find(option => option.hex === hex) : null;
                        pantone     = pantone ? pantone.name : null;

                        return {
                            key: color,
                            hex,
                            pantone
                        }
                    }),
                patterns     : Object.keys(this.patterns)
                    .map(patternKey => {
                        return {
                            key     : patternKey,
                            name    : this.patterns[patternKey].name,
                            scale   : this.patterns[patternKey].scale,
                            rotation: this.patterns[patternKey].rotate
                        }
                    })
            };

            // Append images that are use in design
            this.imageGallery.map(file => {
                if (data.image_details.find(image => image['image-name'] === file.name)) {
                    form.append('images', file);
                }
            });

            // Wait for DOM update
            this.$nextTick(() => {
                // Save all svg views
                this.product.views.map((view, index) => {
                    let filename = `view-${index}.svg`;
                    data.image_details.push({
                        key           : null,
                        'image-name'  : filename,
                        'image-height': null
                    });
                    let svgBuffer = new Blob([this.$refs['view-' + index].outerHTML.replace(/<!--v-if-->|<!---->/gi, '')], {type: "image/svg+xml;charset=utf-8"});
                    form.append('images', svgBuffer, filename);
                });

                form.append('json_string', JSON.stringify(data));
                axios
                    .post("https://inspiration.tdsportswear.com/design-tool/", form)
                    .then(() => {
                        let orderCreated = new Modal(document.getElementById('orderCreated'));
                        orderCreated.show();
                    })
                    .catch((error) => {
                        console.log(error);
                        alert('Er is iets fout gegaan, neem contact op met +31 6 82098033 of info@tdsportswear.com.')
                    })
                    .then(() => {
                        this.activeElement = false;
                        this.creatingOrder = false;
                    });
            });
        },

        selectProduct(productId) {
            if (this.productId === productId) {
                return;
            } else {
                this.productId = productId;
            }

            // Fetch product and set default detail colors
            axios
                .get(`/product/${productId}.json`)
                .then((response) => {
                    // console.log(JSON.stringify(coat));
                    this.product = response.data;
                    this.colors = {};

                    // Set colors
                    this.product.views.map(view => {
                        view.items.map(item => {
                            if (item.editable === false || item.content === 'content') {
                                return;
                            }

                            this.colors[item.id] = item.fill ? item.fill.toUpperCase() : null;
                        });
                    });

                    // Set detail colors
                    this.product.details.map(detail => {
                        let color = this.blackColor;

                        if (detail.nullable) {
                            color = '';
                        } else if (detail.options) {
                            color = detail.options[0].hex;
                        }

                        this.colors[detail.key] = color;
                    });
                });
        },

        toggleView() {
            document.querySelector(this.fullScreen ? 'aside' : '.editor').scrollIntoView({
                behavior: 'smooth'
            });
            this.fullScreen = !this.fullScreen;
        }
    },

    computed: {
        colorModalInitialised() {
            return this.colorModal !== false;
        },

        title() {
            return this.product.title;
        },

        image() {
            return this.product.image;
        },

        aPattern: {
            get() {
                return this.patterns[this.activeItem.id] ? this.patterns[this.activeItem.id] : {};
            },
            set(val) {
                this.patterns[this.activeItem.id] = val;
            }
        },

        aContent: {
            get() {
                return this.content[this.activeItem.id] ? this.content[this.activeItem.id] : {};
            },
            set(val) {
                this.content[this.activeItem.id] = val;
            }
        }
    }
}
</script>
