<template>
    <div class="modal active start-customize" id="startCustomize" tabindex="-1" aria-labelledby="startCustomizeLabel" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="fs-4 my-2 fw-bold">{{ title }} ontwerpen</p>

                    <p>
                        De ontwerp tool is geschikt voor mannen en vrouwen. Na afronden van het ontwerp kan je zowel mannen als vrouwen maten bestellen. Let op, de tool is in een pilot fase, hierdoor kan je voor nu alleen een
                        <a href="#" @click="$emit('selectProduct', 'shirt')">wielershirt korte mouw</a>,
                        <a href="#" @click="$emit('selectProduct', 'coat')">thermo jack</a>,
                        <a href="#" @click="$emit('selectProduct', 'shorts')">wielerbroek kort</a>,
                        <a href="#" @click="$emit('selectProduct', 'pants')">wielerbroek lang</a>
                        van de pro 800 lijn ontwerpen. Mocht je ook andere producten in jouw ontwerp willen hebben vraag dan een prijs aan via de vorige pagina. Je kan je ontwerp wensen per mail doorgeven via de prijzen mail die je van ons ontvangt. Succes!
                    </p>

                    <div class="d-flex flex-column align-items-center">
                        <transition name="fade">
                            <div v-if="image">
                                <img :src="image" class="w-100">
                            </div>
                            <div v-else class="d-flex flex-column align-items-center">
                                <div class="mb-2">loading product {{ productId }}</div>
                                <font-awesome-icon icon="spinner" size="2x" spin/>
                            </div>
                        </transition>

                        <button type="button" class="btn btn-primary mt-5" data-bs-dismiss="modal" aria-label="Close">
                            Start ontwerp
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['title', 'image', 'productId'],
    data() {
        return {
        }
    }
}
</script>