<template>
    <pattern :id="id" x="0" y="0" width="2300" height="2300" viewBox="0 0 2300 2300" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect :fill="colors[`${id}-p-0`]" width="2300" height="2300"/>
        <g>
            <defs>
                <rect id="SVGID_1_" width="2300" height="2300"/>
            </defs>
            <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
            </clipPath>
            <path :fill="colors[`${id}-p-1`]" d="M1221.1,1008.4c-0.3-4.1-2.5-6.1-5.8-8c-14.8-8.7-33-3-31.7,16.1c0.5,6.7,5.7,11.9,4.7,19
		c-0.6,4-2.1,10.4,1.5,13.4c2,1.8,4.8,1.8,7.3,1c4.3-1.4,5.1,12.4,7.2,16.1c1.7,3.1,6.1,4.7,9.4,4.9c5.2,0.2,7.9,7.5,12.7,10.7
		c3.2,2,6.9,2.8,10.6,2.3c10.9-1.2,17.1-0.2,25.7,7.7c1.2,1,2.9,1.2,4.2,0.3c2.4-2,27.3-11.9,29-9.1c3.2,5.2,5,13.7,11.2,16.6
		c9.7,4.5,21-4.1,23.7-13.8c0.4-1.3,7.1-10.8,7.9-10.9c2.6-0.4,4.8-1.7,6.6-3.6c2.4-2.7,3.3-8.9,4.8-12c2.2-4.3,4.8-8.2,7.9-11.9
		c5.2-6.5,15.5-16.9,8.8-24.5c4.4-10.3,6.2-16.2-4.6-21.1c3.2-9.5,0.6-22.4-8.5-25.8c2.6-5.1,0.6-9.1-6.1-13
		c-4.3-2.5-1.4-8.4-3.4-12.1c-1.1-2.2-3.4-3.6-5.7-3.6v-4.2c-0.1-1.6-1.3-2.8-2.8-3.2c-6.2-1.6-12,3.5-17.2-1.2
		c-5.6-5-13.5,5.2-18.8-0.5c-1.5-1.7-4.4-0.8-5.3,1l-6.1-0.3c0.4-3.1-3.9-6-5.9-3c-4.4,6.6-9.5,1-16.1,1.8c-7.1,0.8-9.5,8.1-17,5.6
		c-10.2-3.4-11,8.8-10.5,15.7c-4.2,5.9-2.5,15.1-2.3,22.1c0.2,1.9,1.8,3.3,3.7,3.2c7.9,0,14.9-0.9,22.1,3.1c3.3,2,7.2,2.4,10.8,1
		c1.3-0.7,2.4-1.6,3.4-2.7c2.6-2.4,9-1.4,12.1-1.4c4.7,0,10.6-0.7,15.3,1c-1.6,8.8-0.8,18.4,6.4,25c0.4,0.4-3.6,13.2-4.1,15.4
		c-2.3,10.5-5.5,12.6-16.8,13.9c-6.5,0.8-12.9,0-19.3,1.9c-3.5,1-10.5,3.6-12.1-1c-1.9-5.4-8.3-7.1-12-3.7
		c-7.2-3.6-9.8-13.2-19.1-12.8C1219.1,1024.7,1221.4,1013,1221.1,1008.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1211,885.8c4-3.4,4.4-7.4,5.1-12.2c1.4-8.9,3.5-15.5-5.8-20.7c-6.9-3.8-9.6-12.4-16.8-15.7
		c-2.3-1.1-5.1-1.2-7.5-2.5c-1.2-0.7-2.8-5.1-3.7-6.4c-0.7-1-1.9-1.6-3.1-1.4c-5.1,0.8-6.3,1.7-6.3-4.4c0-2.7-3-5.9-4.2-8.4
		c-2-3.9-7.6-7.6-12.1-7.2c-7.3,0.7-21.4,6.3-27.6,0.8c-4.7-4.3-8.8-8.5-14.9-8.5c-1.8-4.1-4.4-6.4-9.6-5.8
		c-4.2,0.5-3.7,0.4-6.7-2.9c-1.5-1.6-3.8-4-6.2-3.6c-8.9,1.3-9.5,7.4-16.1,12c-6.9,4.7-16,6.3-22.1,12.4c-5.2,5.2-5.6,10.6-4.7,17.7
		c0.4,3.1,2.8,5.7,4.7,7.9c4.8,5.7-0.1,8.2,0.8,13.8c1,6,6.1,13.4,13,13.7c2,0.1,3.9-0.1,5.7-0.8c0.5,0.2,1.1,0.3,1.7,0.4h2.5
		c1.2-0.1,2.3-0.9,2.8-2c-0.1-0.2-0.2-0.4-0.3-0.5c2-0.3,3.3-2.2,3-4.1c-0.1-0.5-0.2-0.9-0.5-1.3c0.8-1.1,1.6-2.2,2.4-3.4
		c0.1-0.2,0.2-0.4,0.3-0.5c3.6-1,7.8-1.7,11.3-2.3v3.5c0.2,1.5,1.3,2.8,2.8,3.1c17.6,2.5,42.3-0.6,42.3,24.5c0.1,1.2,0.9,2.3,2,2.8
		c10.4,5.1,6.8,16.1-1.3,22c-0.7,0.5-15.6,9.7-16.7,6.2c-0.8-2-2.4-3.5-4.3-4.4c-4.8-2.3-10,2.7-13.3-5.3c-0.7-2.1-2.3-3.9-4.3-4.7
		c-3.9-2.2-8.8-2.8-13.1-2.8c-9.1-0.1-14.4-8.9-15.1-17c-0.2-1.5-1.3-2.8-2.8-3.1c-7.6-2.4-12.5-1.3-15.4,6.4
		c-2.7,7-0.3,11.7-1.5,18.7c-1,5.9-2.9,9.1-1.2,15.2c3.2,12.1,7.7,25.5,17.8,33.3c1.5,1.1,3.2,1.7,4.9,1.9c6.2,0.4,9.4,3.7,13.1,8.3
		c2.6,3.3,9.8,3,11.2,6c0.4,1.1,1.4,2,2.6,2.2c6.3,0.5,12.4,2.4,18.8,2.7c8.9,0.5,17.7,7,26.6,5.9c3.8-0.5,10.6-4.2,12.9-2.7
		s5.7,0.5,8-0.4c8.6-3,15.9-11.3,22.1-17.6c7.4-7.5,13.7-16,18.9-25.2c4-7.3,1.6-18.2-1.9-25.4c-0.7-1.4-1.6-2.6-2.7-3.6
		C1200.8,895.2,1206.8,889.5,1211,885.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1805,1453.9c3.4-2.9,3.8-6.4,4.4-10.5c1.2-7.6,3.1-13.3-5-17.8c-5.9-3.3-8.2-10.6-14.4-13.5
		c-2-0.9-4.4-1-6.5-2.2c-1-0.6-2.5-4.4-3.2-5.5c-0.6-0.9-1.6-1.3-2.7-1.2c-4.4,0.7-5.4,1.5-5.4-3.8c0-2.3-2.6-5.1-3.7-7.2
		c-1.7-3.4-6.5-6.6-10.4-6.2c-6.3,0.6-18.4,5.4-23.7,0.7c-4.1-3.7-7.6-7.3-12.9-7.3c-1.6-3.5-3.9-5.5-8.3-5
		c-3.6,0.4-3.2,0.4-5.8-2.5c-1.3-1.4-3.2-3.5-5.4-3.1c-7.6,1.2-8.2,6.4-13.8,10.3c-5.9,4.1-13.8,5.4-19,10.7
		c-4.5,4.5-4.8,9.1-4.1,15.2c0.3,2.7,2.5,4.9,4.1,6.8c4.2,4.9-0.1,7,0.7,11.9c0.8,5.1,5.2,11.5,11.2,11.8c1.7,0.1,3.4-0.1,4.9-0.7
		c0.5,0.2,1,0.3,1.5,0.3h2.2c1.1-0.1,2-0.7,2.4-1.7l-0.2-0.5c1.7-0.3,2.8-1.9,2.5-3.6c-0.1-0.4-0.2-0.7-0.4-1c0.7-0.9,1.4-1.9,2-2.9
		c0.1-0.2,0.2-0.3,0.3-0.5c3.1-0.9,6.7-1.4,9.7-2v3c0.1,1.3,1.1,2.4,2.4,2.7c15.1,2.1,36.5-0.5,36.5,21.2c0.1,1.1,0.7,2,1.7,2.4
		c8.9,4.4,5.8,13.8-1.1,18.9c-0.6,0.5-13.4,8.3-14.3,5.3c-0.7-1.7-2-3-3.7-3.8c-4.2-2-8.6,2.3-11.5-4.6c-0.6-1.8-2-3.3-3.7-4.1
		c-3.3-1.8-7.5-2.3-11.3-2.4c-7.8-0.1-12.4-7.6-13-14.6c-0.2-1.3-1.1-2.4-2.4-2.7c-6.6-2.1-10.7-1.2-13.3,5.5
		c-2.3,6-0.3,10.1-1.3,16.1c-0.9,5.1-2.5,7.8-1,13.2c2.8,10.4,6.6,22,15.3,28.8c1.2,0.9,2.7,1.5,4.3,1.6c5.3,0.3,8,3.2,11.2,7.1
		c2.2,2.9,8.4,2.6,9.6,5.1c0.4,1,1.3,1.7,2.3,1.9c5.4,0.4,10.6,2.1,16.2,2.3c7.7,0.4,15.2,6,22.9,5c3.3-0.4,9.1-3.6,11.1-2.3
		c2,1.2,4.9,0.4,6.9-0.3c7.4-2.6,13.7-9.7,19-15.1c6.3-6.4,11.8-13.7,16.2-21.7c3.5-6.3,1.3-15.7-1.6-21.9c-0.6-1.2-1.4-2.2-2.3-3.1
		C1796.2,1461.9,1801.4,1457,1805,1453.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2054.1-48.7c4.3-3.7,4.8-8.1,5.6-13.3c1.5-9.7,3.9-16.9-6.3-22.4c-7.5-4.1-10.4-13.4-18.3-17.1
		c-2.6-1.2-5.6-1.3-8.2-2.8c-1.3-0.7-3.1-5.6-4.1-7c-0.8-1.1-2.1-1.7-3.5-1.5c-5.6,0.9-6.9,1.9-6.9-4.8c0-3-3.3-6.4-4.6-9.2
		c-2.2-4.3-8.3-8.3-13.2-7.9c-8,0.7-23.2,6.9-30.1,0.8c-5.1-4.6-9.6-9.2-16.3-9.3c-2-4.4-4.8-7-10.5-6.4c-4.6,0.5-4,0.5-7.4-3.2
		c-1.7-1.8-4.1-4.4-6.8-4c-9.7,1.4-10.4,8.1-17.5,13.1c-7.5,5.1-17.4,6.8-24,13.4c-5.7,5.7-6.1,11.5-5.1,19.3
		c0.4,3.4,3.1,6.2,5.1,8.7c5.2,6.3-0.1,8.9,0.9,14.9c1,6.5,6.6,14.6,14.1,14.9c2.2,0.1,4.3-0.2,6.3-0.8c0.6,0.2,1.2,0.4,1.9,0.4h2.8
		c1.3-0.1,2.5-0.9,3.1-2.2l-0.3-0.6c2.1-0.3,3.6-2.4,3.3-4.4c-0.1-0.5-0.3-1-0.5-1.5c0.9-1.2,1.7-2.4,2.6-3.7
		c0.1-0.2,0.2-0.4,0.3-0.6c3.9-1.2,8.5-1.8,12.3-2.5v3.8c0.1,1.7,1.4,3.1,3.1,3.4c19.2,2.7,46.1-0.7,46.1,26.7
		c0.1,1.3,0.9,2.5,2.2,3.1c11.3,5.6,7.4,17.5-1.4,23.9c-0.8,0.6-17,10.5-18.2,6.7c-0.9-2.1-2.5-3.8-4.6-4.6
		c-5.2-2.5-10.9,2.9-14.4-5.7c-0.8-2.3-2.4-4.2-4.6-5.1c-4.2-2.3-9.6-3-14.2-3.1c-9.9-0.1-15.7-9.7-16.4-18.5
		c-0.2-1.7-1.4-3-3.1-3.4c-8.3-2.7-13.5-1.5-16.8,7c-3,7.7-0.3,12.8-1.7,20.3c-1.2,6.4-3.1,9.9-1.3,16.6
		c3.5,13.2,8.4,27.8,19.4,36.4c1.6,1.2,3.5,1.9,5.3,2c6.7,0.4,10.3,4,14.2,9c2.8,3.6,10.6,3.3,12.2,6.5c0.5,1.2,1.6,2.2,2.9,2.4
		c6.9,0.6,13.4,2.6,20.5,3c9.8,0.5,19.3,7.6,29,6.4c4.2-0.5,11.5-4.4,14-3c2.5,1.6,6.2,0.5,8.8-0.4c9.4-3.3,17.3-12.2,23.9-19.2
		c8-8.2,14.9-17.4,20.6-27.4c4.3-8,1.7-19.9-2-27.6c-0.8-1.5-1.8-2.8-3-3.9C2043-38.5,2049.5-44.7,2054.1-48.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2063.9,1362.1c-1.9,1.5-2.6,5.2-3.7,7.4c-1.7,3.5-1.4,9.4,1.3,12.3c4.4,4.7,15.8,11.6,15.2,19
		c-0.5,5.6-1.3,10.7,2,15c-1.9,3.5-2.2,6.5,1,9.9c2.6,2.7,2.3,2.3,1.6,6.2c-0.3,1.9-0.8,4.7,0.8,6.3c5.6,5.5,10.3,2.8,16.9,4.9
		c7,2.3,12.9,7.9,20.4,8.9c6.5,0.9,10.4-1.7,14.9-6.1c2-1.9,2.5-5,3.1-7.5c1.5-6.4,5.7-4.3,9.2-7.9c3.7-3.9,6.1-11.4,2.8-16.3
		c-1-1.4-2.2-2.7-3.6-3.7c-0.1-0.5-0.3-1-0.7-1.4l-1.3-1.8c-0.7-0.8-1.8-1.2-2.9-0.9l-0.2,0.5c-1.3-1.2-3.3-1.2-4.4,0.1
		c-0.3,0.3-0.5,0.7-0.7,1.1c-1.2,0-2.4,0.1-3.6,0.1c-0.2,0-0.4,0-0.5,0.1c-2.6-2-5.2-4.6-7.6-6.6l2.5-1.9c1-0.9,1.3-2.4,0.7-3.6
		c-7.6-13.6-22.9-29.4-5.2-42.7c0.8-0.7,1.2-1.8,0.9-2.9c-1.9-10,7.7-13.3,16.1-10.8c0.8,0.2,15,5.8,13.3,8.4
		c-0.9,1.6-1.2,3.5-0.8,5.2c1,4.6,7.1,5.6,3.4,12.2c-1.1,1.6-1.5,3.6-1.1,5.5c0.6,3.9,2.8,7.6,4.9,10.6c4.7,6.4,1.4,14.8-4,19.6
		c-1,0.9-1.3,2.4-0.7,3.6c2.4,6.6,5.6,9.4,12.8,7.4c6.4-1.9,8.4-6,13.8-8.8c4.7-2.5,7.8-2.8,11.4-7.2c6.7-8.6,13.8-19,13.9-30.3
		c0-1.6-0.5-3.1-1.3-4.4c-3.1-4.6-2.5-8.5-1.2-13.5c1-3.6-3.1-8.4-1.7-11c0.6-0.9,0.6-2.1,0.1-3c-3.1-4.7-4.8-9.9-8-14.6
		c-4.4-6.5-4.4-16.1-10-21.8c-2.3-2.4-8.5-5.2-8.7-7.6s-2.8-4.3-4.5-5.4c-6.6-4.4-16.3-5.2-24-6.1c-9.2-1.2-18.5-1.2-27.6,0.1
		c-7.2,1.2-13.6,8.6-16.8,14.8c-0.6,1.2-0.9,2.5-1.1,3.8c-0.9,6.7-8,5.5-12.9,4.5c-4.4-1-7.6,0.8-11.3,2.9
		c-6.9,3.8-12.8,5.7-11.4,15c1,6.9-3.6,13.3-2.1,20.2c0.5,2.2,1.9,4.3,2.3,6.6c0.2,1.2-2.1,4.7-2.6,6c-0.3,1-0.1,2.2,0.7,3
		C2066.9,1358.4,2068.1,1358.8,2063.9,1362.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1114.4,1003.4c-2.3-8-10.4-13.7-13.2-22c-1.5-4.4-4-6.7-8.1-8.6c-5.6-2.6-10,2.5-13.6-4
		c-0.9-1.3-2.6-1.8-4.1-1.2c-6.6,3.5-19.5,1.8-23.1-5.6c-0.6-1.5-2.1-2.3-3.7-2.1c-4.5,0.8-4.9-3.4-8.2-4.7s-7.9-3-11.4-1.7
		c-4.3,1.6-8.8,2.9-12.9,4.9c-2.3,1.2-3.2,5.8-6.2,2.9c-5.6-5.3-10-10.2-18-7.7c-9.1,2.8-2.9,15.3-13.6,15.6
		c-9.4,0.3-20,2.4-24.4,11.3c-1.1,2.8-0.8,6,0.9,8.5c4,5,7.3,10.5,10.1,16.3c2.8,5.9,8.5,6.3,13.5,2.8c6.5-4.5,13.9-6.5,20.1-11.8
		c3-2.6,4-5.3,6.4-7.7c3,0.2,5.9,0.7,8.8,1.4c-0.1,0.3-0.2,0.6-0.4,0.9c-0.5,1.5-0.7,3.1-0.7,4.6c-0.2,4.7,2.3,14.2,8.9,13.4
		c3-0.4,5.5-2.6,7.9-4.3c6.4-4.5,19.2,4,23,8.9c2.9,3.7,7.6,5.2,12.1,4.3c5.1,4.1,4.3,11.2,10,15.1c3.7,2.6,9.2,0.9,13.8-0.3v6.3
		c0.2,1.6,1.4,2.8,3,3c6.1,0,11,0.1,15.9,4.4c1.2,1,3,1,4.2,0c10.2-6.4,15.8-22.2,4-27.6C1115.5,1013.5,1115.9,1008.7,1114.4,1003.4
		z"/>
            <path :fill="colors[`${id}-p-1`]" d="M971.6,797.8c-6.3-7.4-14.3-15-24.8-13.5c-10.1,1.5-16.5,14.3-27.8,9.2c-6.5-3-6-14.8-10.7-19.9
		c-4.8-5.2-12.9-6-18.6-1.8c-4.4,3.2-9.8,11.6-15.5,5.3c-2.4-2.2-5.4-3.5-8.6-3.6c-7.5,0.2-14.1-0.7-20.6-4.9c-3.1-2-6-4.2-9.9-3.5
		c-5.4,1-9,4-13.1,7.5c-7.1,6.3-13.8,8.3-22.4,11.8c-8.6,3.5-13.2,14.3-13.7,23c-0.6,11.3-2.6,22.5-5.9,33.3
		c-2.3,7.5,6.4,11.8,12.3,12.7c11.4,1.8,7.1,14.7,7.7,22.2c0.5,6.1,4.7,11.5,4.8,17.7c0.1,8.7,3.1,15.5,12.3,15.8
		c6.7,0.2,12.2,2.3,18.5,4.2c2.2,0.6,5.4,0.3,6.9-1.6c6.7-8.3,5.1-22.9,1.6-32.2c-3.1-8-6.8-15.9,3.9-20.1c6.6-2.6,13.8-1,20.6-0.6
		c3.4,0.2,6.2-4.9,7.6-7.4c2-3.5,2.8-7,5.1-10.3c4.8-6.4,14.9-2.9,20.7-0.4c6.9,3,7.8,10.5,11.3,16.3c0.9,1.6,2.9,2.7,4.3,3.8
		c5.6,4.3,6,10.4,2.8,16.5c-2.1,3.8-6.2,9.4-4.8,14c0.4,1.4,1.2,2.6,2.4,3.5c3.7,2.2,1.5,5.4,0.5,8.9c-3.5,11.5,14.1,14.1,21.4,17.2
		c3.5,1.6,7.5,1.8,11.2,0.5c0.4,1.4,1.7,2.4,3.2,2.5c17.2,1.4,27.1-18.9,19.6-32.7c5.3-4.2,11-10.2,7.4-17.1
		c-1.8-3.5-7.2-13.2-5.9-16.4c1.7-4.2,7.4-6.3,9.5-10.8c1.4-3.1,1.9-6.5,1.2-9.9c-1.6-6.2,0.5-12.3,1.4-18.4
		C988.8,806.7,978.3,805.7,971.6,797.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M828,962.8c5.4,1.6,10.7,6.7,15.6,9.6c14,8,31.7-6.7,44.6,4.7c7.3,6.5,16.3,8.3,23.8,0.8s19,6.2,22.8-8.7
		c2.4-9.2-5.1-19.7-10.1-26.6c-5.9-8.3-7.2-14.9-18.1-19.5c-5.7-2.4-10.9-3.8-17.1-2.8c-4,0.7-6.6,2.5-11.2,2
		c-4.3-0.5-8.2-2-12.6-2.5c-1.2-0.1-2.4,0.5-3.1,1.6c-1.3,2-2.5,4-3.5,6.1c-2.7,5.2-7.2,1-10.2,0c-4.3-1.4-9.2-0.8-13.4,0.2
		c-5.2,1.1-9.6,4.6-11.8,9.5c-5.6,5.4-6.2,14.4-2.4,21.7C822.8,961.3,825.6,962.1,828,962.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1910.5,1143.3c-3.9-0.6-7.7,0.3-10.9,2.6c-0.8-1.3-2.3-1.9-3.7-1.6c-16.9,3.3-21.1,25.4-10.2,36.7
		c-4.1,5.4-7.8,12.7-2.6,18.5c2.6,2.9,10.5,10.8,10.1,14.3c-0.5,4.4-5.4,8-6.2,12.9c-0.6,3.4-0.1,6.8,1.5,9.9
		c3.2,5.5,2.8,11.9,3.6,18.1c1.4,12.3,11.8,10.4,20.4,16.2c8,5.5,17.8,10.7,27.6,6.4c9.3-4.1,12.2-18.2,24.4-16.2
		c7,1.2,9.8,12.7,15.5,16.3c6,3.9,14,2.5,18.4-3.2c3.4-4.2,6.3-13.8,13.5-9.3c2.9,1.5,6.1,1.9,9.3,1.2c7.1-2.2,13.8-3.1,21.2-0.7
		c3.5,1.2,6.9,2.4,10.4,0.7c4.9-2.5,7.6-6.2,10.6-10.8c5.2-7.9,11.2-11.7,18.6-17.2s8.9-17.4,7.1-25.8c-2.5-11.1-3.5-22.4-3.2-33.7
		c0.3-7.9-9.3-9.6-15.1-8.9c-11.5,1.3-10.9-12.4-13.3-19.3c-2.1-5.7-7.7-9.9-9.4-15.8c-2.4-8.4-7.1-14.2-16-12
		c-6.5,1.7-12.4,1-19,0.9c-2.2,0-5.3,1.2-6.3,3.4c-4.3,9.8,1.2,23.4,7,31.5c5.1,6.9,10.8,13.5,1.7,20.4c-5.7,4.3-13,4.6-19.7,6
		c-3.4,0.7-4.6,6.4-5.3,9.1c-1,3.8-0.8,7.5-2.3,11.3c-3,7.4-13.6,6.7-19.8,5.8c-7.4-1.1-10.4-8-15.2-12.8c-1.3-1.3-3.5-1.8-5-2.5
		c-6.5-2.7-8.6-8.4-7-15.2c1-4.3,3.6-10.7,0.9-14.8c-0.7-1.3-1.9-2.3-3.3-2.8c-4.2-1.2-2.9-4.8-2.8-8.4
		C1935.9,1142.2,1918.2,1144.4,1910.5,1143.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1941.5,1132.7c6.2,0.8,11.5,0.7,17.2-1.9c3.7-1.7,5.7-4.2,10.2-4.8c4.3-0.7,8.5-0.3,12.8-0.9
		c1.2-0.3,2.2-1.2,2.5-2.3c0.8-2.3,1.3-4.5,1.7-6.9c1.2-5.8,6.7-2.9,9.8-2.7c4.5,0.2,9.1-1.7,13-3.8c4.7-2.5,8-7,8.8-12.3
		c4-6.7,2.2-15.6-3.5-21.5c-1.8-1.9-4.7-2.1-7.1-2.1c-5.7-0.1-12.1-3.7-17.6-5c-15.7-4-28.8,14.9-44.2,7.2
		c-8.8-4.4-17.9-3.7-23.2,5.5c-5.3,9.2-20-1-19.8,14.4c0.1,9.6,10.2,17.6,16.8,23C1926.8,1125.2,1929.8,1131.4,1941.5,1132.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M672.6,842.5c1.7,6.3,7.3,8.3,5.4,16.4c-0.9,4-4,7.6-3.9,11.9c0.3,9.1,13,9.5,19.5,10.3
		c16.3,1.9,33.8,7,47.8-3.9c18.1-14,19.9-45,2.1-59.6c-4-3.2-14.2-3.3-15.7-7.1c-3-7.9-4.8-8-12.8-9.3c-5.8-0.9-13.9-4.1-19.8-1.5
		c-8.7,3.8-13.5,10.7-18.8,18.4c-0.7,1-0.9,2.3-0.6,3.5C671.9,827.8,670.7,835.4,672.6,842.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M700.5,777.6c16.3,6.6,28.6-3.4,32.2-19.8c6.5-30.4-49.8-42.4-50-13.3c-2.1,5.1-2.5,10.8-1.2,16.1
		C683.9,769.9,692.5,774.4,700.5,777.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M802.3,771.7c10.3-2.2,35.6-13.5,32.9-27.4c-1.1-5.5-6.3-12.2-8.9-17.2c-3.5-6.9-8.7-9-14.6-13
		c-1-0.7-2.4-0.8-3.5-0.3c-11.5,5.9-23.9,1.3-31.2,10.9c-1.2-1.4-3.3-1.6-4.6-0.4c-0.2,0.2-0.5,0.4-0.6,0.7c-0.4,0.6-0.7,1.1-1,1.6
		c-3.6,1.9-5,7.8-5.6,11.3c-1.2,6.8,0,13.7,3.5,19.8C775.1,769.4,789.5,774.3,802.3,771.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M711,704.2c5.8-0.4,12.6-2.5,18,0.7c2,1.2,3.8,4.2,6.1,4.4c6.1,0.7,14.1-3.1,19-6.4c4.2-2.9,11.5-9.1,9.9-15.1
		s-8.5-9.4-10.1-15c-1.9-6.7-0.4-16.5-6.7-21.2c-5.3-4-11.5-4.6-17.9-4.1c-11.1,1.1-23.3,9.9-17.7,21.1c-1,1.9-1.7,4.1-1.9,6.2
		c-0.5,2.7,0,5.4,1.3,7.8c2.5,5.6-3.3,12.5-4.1,18C706.6,702.8,708.9,704.3,711,704.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M561.2,969.2c2.8,3.8,6.1,7.3,11.3,6.7c4.1-0.5,8-2.1,12.2-1.1c3.8,0.9,7.6,4.6,11.3,6.4c4,2,12.7,0,16.2-1.7
		c5.9-2.8,10.4-6.9,14.4-12c8.3-10.2,15-25.6,18.2-38.2c2.5-10.2-9.8-16.6-17-20.7c-4.7-2.7-11.7-0.8-16.6-0.1
		c-3.7,0.6-7.3,1.2-11,1.7c-2.2,0.3-3.9-5.5-4.7-6.8c-7.4-10.9-21.3-7.9-32.7-7.9c-12.7,0-19.6,20.2-22.1,29.4
		c-0.6,2.4,0.8,4.7,3.2,5.4c0.7,0.2,1.3,0.2,2,0c0.2,2.9,0.5,5.7,0.5,8.6c0.1,1.9,2,3.1,3.7,3.4
		C565.2,945.1,554.1,959.4,561.2,969.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M161.8,1024.2c-13,6.6-25,13.6-40,13.6c-3.2,0-7.4-0.8-10.4,1.2c-5.7,3.9-13.3-6.4-17.6-8.4
		c-1.8-0.8-7.2-1.7-8.7-3.1c-4.3-4.1-6.7-16.6-9.1-22.6c-3.8-9.7-17.1-14.3-26.6-11.5c-10.4,3.1-13.3,15.7-11.5,24.9
		c1.2,5.6,4.4,10.6,7.4,15.3c3.9,5.8,5.6,12.4,10.1,17.8c3,3.7,8.3,6.9,12.5,8.8c7.7,3.7,9,12.4,15,17.6s11.4,9.1,16,15.7
		c2.8,4.1,6.6,7.1,11.3,8.9c4.3,1.6,9.5,0.6,13.4-1.3c6-3,6.6-11,12.9-13.9c8.7-4.3,16,2,23.9,5.6c5.4,2.5,11.3,2.9,17.2,2.7
		c14.6-0.5,18-15,29.2-20.9c1.1-0.5,1.9-1.6,2-2.8c0-11.4,7-16.6,16.2-22.2c6.7-4.1,11.4-8.9,13.2-15.8c0.6-0.6,0.9-1.4,0.9-2.2
		v-1.3c8.8-12.9,1.2-32.5-3.8-46.3c-1.4-4.1-1.8-9.3-4.4-12.7c-3.5-4.3-11.1-3.1-10.8-10.7c0.5-10.5-13.3-8.7-19.4-7.6
		c-7.6,1.4-6.8-13.1-10-16.9c-2.4-2.9-5.5-4.9-9.1-6c-10.3-3.5-18.7,2.2-26.5,7.8c-8.2,5.8-14.3,5.5-20.4-2.4
		c-2.5-3.3-6.2-5.5-9.9-7.4c-2.2-1.2-4.6-0.3-6.6,0.6c-5.8,2.8-12.3,4.2-17.9,7.2c-13.1,7.1-6.5,33.6-2.1,44.1
		c2.9,6.7,11.5,6.5,17.6,6.5c11.8-0.1,23.2-3,35-1.7c7.8,0.9,15.3,3.9,19.6,10.8C176.2,1005.3,171.9,1019.1,161.8,1024.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M371.6,941.7c15.6,0.9,47.9,7.6,54.7-12.7c5.9-17.6-16.5-30.9-29.1-37.3c-9-4.6-18.5-7.3-28.3-9.5
		c-8-1.8-16.3-1.3-24.3,0c-7.3,1.2-20.4,8.5-27.1,5.2c-8.5-4.1-19.3,3.5-25.3,8.7c-3.6,3.1-3.9,9.1-6.8,12.9
		c-4.9,6.3-11.1,9-17.7,12.9c-7.2,4.1-15.9,12-15.2,20.9c0.3,4.2,4.4,8,6.9,10.9c7.9,9,9.6,14.9,1.9,24.9c-1,1.2-1.2,2.8-0.6,4.2
		c6.1,12.4,10.6,22.2,6.1,36.1c-3,9.2-4.9,18.6-1.5,27.9c1.8,4.9,7.2,6.4,11.9,6.9c7.5,0.8,13.1,1.6,18.3,8.4
		c2.6,3.3,5.9,5.9,9.7,7.6c1.4,0.6,3,0.4,4.2-0.5c14.7-10.9,32.9-14.7,49.5-21.9c10.4-4.4,14.4-12.4,19.2-20.3
		c0.6-0.2,1.1-0.6,1.5-1c2.2-2.6,4.1-5.4,5.4-8.5c0.9-1,1.9-2,2.9-2.9c1.8-1.6,2-4.4,0.4-6.1c-0.1-0.1-0.2-0.2-0.3-0.3
		c2-9.4,0.9-19.4-5.4-25.9c-11.8-12.2-29.9-4.9-35.7,8.5c-2.4,6.1-7.7,7.9-15.8,5.2c-2.3-2.8-3.4-7.1-4.4-10.5
		c-3.1-9.9,0.2-21.5,5.5-29.9C340.6,942.7,357.5,940.9,371.6,941.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M513.9,891.8c-0.3-2.3-0.6-5.4-3-6.6c-6.9-3.5-17.6,0.9-24.1,3.3c-10.2,3.8-17.4,11.4-23.4,20.1
		c-3.1,4.4-11.4,6.7-12.7,12.1c-3.3,3.9-7.1,7.2-9.5,12c-2.9,5.7-1.7,14.1-3,20.2c-0.8,3.8,0.7,8.3,0,12.6c-0.4,2.3,1.3,5.3,2.9,6.9
		c2.3,2.3,6.2,2.3,9.2,2.3c0.9,0,8.7,7.2,9.6,7.8c7.8,5.1,15.5,6.1,22.2,0.6c4.2,3.4,3.7,8.9,3.7,13.5c0.1,1.9,1.8,3.4,3.7,3.2
		c0.6,0,1.2-0.2,1.7-0.6c7.2-5.7,11.2-13.4,13.4-22.2c3.3-12.8-5.5-23,3.7-34.4c6.5-8,26.6-29.7,10.6-39.1c-3-1.8-6.3-1.5-5.6-5.6
		C513.7,895.7,513.9,893.7,513.9,891.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M439.8,884c4.1-0.7,7.9-2,11.7-3.7c5.4-2.3,11.3-3.3,16.8-5.1c15.5-5.4,15.1-25.1,1.9-32.1
		c-5.6-3-15.8-1.3-22.1-0.7c-4.2,0.4-8.2,1.5-12,3.3c-6,1.8-12.1,5.9-14.2,11.7c-2.6,6.9,0.3,12,3.3,18.4
		C427.8,880.9,433.8,884.9,439.8,884z"/>
            <path :fill="colors[`${id}-p-1`]" d="M578,834.5c-13.8-1.7-24.5-4.6-38.4-0.1c-11.7,3.8-21.3,10.7-23.6,23.3c-0.2,1.9,1.1,3.6,3,3.9h4.2
		c4,3.8,9.8,6.9,13.3,8.7c14.7,7.6,31.1,14.8,47.9,14.2c24.3-0.8,51-16.4,61.2-38.7c5.1-11.2,4.7-23.2,8.3-34.7
		c3.3-10.6,5.8-18.2,2-29c-7.8-21.8-31.6-35.3-53.5-38c-12-1.5-23.5,2.3-34.6,6.4c-13.3,4.9-27.2,3-39.5,11.1
		c-14.3,9.5-42.2,22.7-37,44.5c2.2,9.1,12.2,14.5,20,18s12.6-3.3,16.5-9c5.5-8,10.4-12.7,19.6-16c8.5-3.1,17.1-7.3,25.9-9.4
		c8.7-2.1,21.6,13.1,27.1,18.5c5.5,5.5,2.5,14.2-1.4,19.6C594.2,834.4,585.2,835.3,578,834.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1942.5,990.6c-8,11.4-15.7,19.4-18.3,33.8c-2.2,12.1-0.6,23.8,9.4,31.8c1.6,1.1,3.7,0.7,4.7-0.8l2-3.7
		c5.1-1.7,10.7-5.4,13.9-7.6c13.6-9.4,27.8-20.5,35.2-35.5c10.8-21.9,9.6-52.8-5.1-72.2c-7.4-9.8-18.2-15.2-26.7-23.7
		c-7.8-7.8-13.3-13.7-24.6-15.4c-22.8-3.4-46,11.3-58.7,29.3c-6.9,9.9-9,21.9-10.7,33.5c-2,14-10.3,25.3-8.9,40
		c1.6,17.2,0.1,48.1,21.8,53.6c9,2.3,18.5-3.9,25.3-9.1c6.7-5.2,3.1-12.7-0.1-18.8c-4.4-8.7-6.2-15.1-4.8-24.8
		c1.3-8.9,1.7-18.6,4-27.2c2.4-8.7,21.7-12.9,29.1-15c7.7-2.3,13.7,4.5,16.6,10.5C1950,976.4,1946.7,984.7,1942.5,990.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1567.1,137.6c-13.8-1.7-24.5-4.6-38.4-0.1c-11.7,3.8-21.3,10.7-23.6,23.3c-0.2,1.9,1.1,3.6,3,3.9h4.3
		c4,3.8,9.8,6.9,13.3,8.7c14.7,7.6,31.1,14.8,47.9,14.2c24.3-0.8,51-16.4,61.2-38.7c5.1-11.2,4.7-23.2,8.3-34.7
		c3.3-10.6,5.8-18.2,2-29c-7.8-21.8-31.6-35.3-53.5-38c-12-1.5-23.5,2.3-34.6,6.4c-13.3,4.9-27.2,3-39.5,11.1
		c-14.4,9.5-42.2,22.8-37,44.5c2.2,9.1,12.2,14.5,20,18s12.6-3.3,16.5-9c5.5-8,10.4-12.7,19.6-16c8.5-3.1,17.1-7.3,25.9-9.3
		s21.5,13.1,27,18.5c5.4,5.5,2.5,14.2-1.4,19.6C1583.3,137.5,1574.4,138.4,1567.1,137.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M61,839.6c-3.4-3.8-8.2-6.4-12.4-9.2s-7.7-7.1-12.3-9.4c-10.9-5.2-24.2-5-35.9-2.8c-5.1,1-7.3,2.6-11.1-3.5
		c-1.5-2.8-2.9-5.5-4.1-8.5c-1.7-3.7-6.2-7.2-9.4-9.6c-8.5-6.4-18.4-0.7-27.5,0.6c-8.5,1.2-15.7,0-22.7,5.6
		c-7.5,5.9-16.7,20.3-18.6,29.8c-1.9,9.7,2.9,18.8,11.9,23.2c12.9,6.3,21-1.6,33.2-5.2c5.6-1.5,11.5-2.5,17.4-2.8
		c9-0.7,11.8,1.7,16.8,8.4c10.2,13.4,37.1,24.1,23.3,44.6c-7.4,11-30.8,17.4-40.5,8c-9.8-9.3-20.1-19.5-27.9-30.5
		c-4.8-6.7-13.8-11-21.5-5.6c-9.4,6.6-12.3,17.2-12.6,28.3c-0.3,10.3,2.5,20,9.5,27.6c5.2,5.6,12.5,9,18,14.3
		c5.3,5.2,11.3,9.7,17.7,13.3c11.8,6.6,27.1,8.1,40,11.1c15.6,3.6,29.9,6.3,45.6,1.7c0.6-0.2,1.1-0.5,1.5-0.9
		c1.9-0.4,3.7-1.2,5.1-2.4c4.9-4.2,1.5-13.3,8-16.5c5-2.4,9.6-5.8,13.3-10c4.7-5.4,11.4-14.3,5.4-21.6c-5-6.1-8.2-9.6-5.4-18.4
		c1.8-5.6,4.6-10.3,5.1-16.3c0.8-8.9-16.7-15.7-10.4-23.4C66.6,852.8,67.6,847.2,61,839.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M349.2,762.4c-2.5-15-17.7-20.4-30.9-21.5c-0.6,0-1.2-0.1-1.9-0.1c-0.5-1.2-1.8-1.9-3.1-1.7
		c-15.2,1.6-25.9,16.8-20.9,31.3c3.1,8.7,5.2,18.1,9.1,26.4c1.6,3.3,5.3,5.7,8,8c6.1,4.8,7.5,11.8,9.6,18.9
		c2.8,9.6,6.9,17.5,14.4,24.1c1.8,1.6,4.9,2.2,7,3.2c4.7,2.2,9.2,4.9,13.8,7.4c12.5,6.9,33.2-3.3,33.6-17.7
		c0.4-15-12.7-23.6-23.4-31.6c-6.5-4.9-14.4-10.7-15.3-19.4C348.4,780.6,350.8,771.5,349.2,762.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M461.1,754.4c-2.2-9.9-9.5-18.7-13.9-27.7c-5.3-10.6-12.7-15.7-23.3-20.1c-9.8-4.1-19.6-7.7-30.2-8.6
		c-7-0.6-13.5-1.1-20.4-3.3c-4.6-1.5-8.6-3.2-13.4-3.5c-13.4-0.8-29.2,19.9-15.4,29.1l0,0c1.8,3.1,4.2,5.7,6.9,7.9
		c6,4.9,13.3,8.5,20.2,12.1c3.1,1.6,6,3.2,9,4.8c7,4.1,7,13.9,10.7,20.4c2.3,4.1,3.1,8.8,6.1,12.4c3.7,4.3,8.6,7.1,12.1,11.5
		c7.5,9.5,2.5,22.8,9.6,32.8c9.7,13.6,33.5,3.7,38.5-9.6c3.3-8.7-0.3-19.6-0.7-28.6C456.5,774.2,463.5,765,461.1,754.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1292.1,796.7c5.5-9.7,6.9-18.6,7.1-29.4c2.6-2.3,6.5-3.8,9-5.7c2.7-2.3,3-7.8,3.5-10.9
		c0.8-5.8,0.5-11.7,1.3-17.5c0.6-3.9,1-8.5-0.5-12.3c-7-18.1-35.6-22.7-49.4-11c-7.7,6.5-12.4,20.3-14.3,29.8c-1,4.6,0,9.1,1.5,13.5
		c1.7,5.1,7.1,7.2,9.2,12.1c6.6,15.1-2.3,30.2-6.4,44.6c-4.3,14.8,1,30.9-1.5,46c-1.6,9.6,4.6,21.2,10,28.6
		c7.6,10.4,19.9,10.6,31.1,14.6c12,4.4,22.2,7.6,35.1,9.2c10.4,1.3,19.1,10.4,29.9,7.1c21.7-6.4,41.8-20.3,51-41.1
		c5.1-11.8,3.7-24.3,3.7-36.9c0-9,0.8-16.3,3.9-24.8c2.1-5.9,1.2-9.2-1.7-14.5c-2.1-3.9-4.4-7.5-6.3-11.5s-1.9-10.3-2.7-14.8
		c-1-5.9-5.3-9.4-9.9-12.9c-9.6-7.4-9.8-14.7-12.1-25.8c-1.4-7-6.2-12.9-11.1-17.8c-9.4-9.6-25-14.4-35.8-4.7
		c-10.1,9-10.7,31.7-6,43.4c3.2,7.8,11.3,13.5,16.3,20c9.1,11.4,17.6,23.7,19.7,38.5c0.7,4.9-1.8,10.5-2.4,15.5
		c-0.6,4.9,4.9,9.4-0.2,14.2c-8.2,7.8-25,1.5-33.6-0.8c-8-2.1-14.8-1.8-22.4,1.5c-5.1,2.3-8,4.4-12.6-0.9c-3.4-4-5.5-9.4-7.7-14
		c-2.7-5.4-5.6-13.3-3.8-19.5C1285.3,804.1,1290,800.6,1292.1,796.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M27.4,1405.5c4.2-8.3,5.1-15.8,5.3-24.9c2-2,4.8-3.2,6.7-4.9c2-1.9,2.3-6.6,2.6-9.3c0.6-4.9,0.4-10,1-14.9
		c0.4-3.3,0.7-7.3-0.3-10.4c-5.2-15.4-26.5-19.4-36.9-9.3c-5.8,5.4-9.3,17.1-10.8,25.1c-0.5,3.9-0.2,7.8,1.1,11.5
		c1.3,4.4,5.2,6.1,6.9,10.4c4.9,12.9-1.8,25.6-4.7,38c-3.2,12.7,0.7,26.3-1.2,39.3c-1.2,8.2,3.5,18,7.4,24.3
		c5.6,8.8,14.8,9,23.1,12.5c9,3.8,16.6,6.4,26.2,7.8c7.7,1.2,14.2,8.8,22.3,6c16.1-5.4,31.2-17.2,38.1-35
		c3.9-10.1,2.8-20.7,2.8-31.4c-0.2-7.1,0.8-14.2,2.9-21.2c1.6-5,0.8-7.8-1.2-12.4c-1.5-3.4-3.4-6.4-4.7-9.8c-1.4-3.5-1.4-8.7-2-12.7
		c-0.8-5-4.1-8-7.4-11c-7.1-6.3-7.3-12.6-9-22c-1.1-5.9-4.6-11-8.2-15.2c-7-8.1-18.7-12.3-26.7-4.1c-7.5,7.7-7.9,27-4.4,37
		c2.3,6.6,8.4,11.5,12.2,17c6.8,9.7,13.2,20.2,14.7,32.7c0.5,4.2-1.4,8.9-1.8,13.2c-0.4,4.3,3.7,8-0.1,12.2
		c-6.1,6.6-18.7,1.3-25.1-0.7c-5.5-1.9-11.6-1.4-16.7,1.3c-3.9,1.9-5.9,3.8-9.3-0.8c-2.5-3.4-4.2-8-5.7-12c-2-4.6-4.3-11.3-2.9-16.5
		C22.3,1411.8,25.8,1408.6,27.4,1405.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2327.5,1405.5c4.2-8.3,5.1-15.8,5.3-24.9c2-2,4.8-3.2,6.7-4.9c2-1.9,2.3-6.6,2.6-9.3c0.6-4.9,0.4-10,1-14.9
		c0.4-3.3,0.7-7.3-0.3-10.4c-5.2-15.4-26.5-19.4-36.9-9.3c-5.7,5.5-9.2,17.2-10.7,25.3c-0.5,3.9-0.2,7.8,1.1,11.5
		c1.3,4.4,5.2,6.1,6.9,10.4c4.9,12.9-1.8,25.6-4.7,38c-3.2,12.7,0.7,26.3-1.2,39.3c-1.2,8.2,3.5,18,7.4,24.3
		c5.6,8.8,14.8,9,23.1,12.5c9,3.8,16.6,6.4,26.2,7.8c7.7,1.2,14.2,8.8,22.3,6c16.1-5.4,31.2-17.2,38.1-35
		c3.9-10.1,2.8-20.7,2.8-31.4c-0.2-7.1,0.8-14.2,2.9-21.2c1.6-5,0.8-7.8-1.2-12.4c-1.5-3.4-3.4-6.4-4.7-9.8s-1.4-8.7-2-12.7
		c-0.8-5-4.1-8-7.4-11c-7.1-6.3-7.3-12.6-9-22c-1.1-5.9-4.6-11-8.2-15.2c-7-8.1-18.7-12.3-26.7-4.1c-7.5,7.7-7.9,27-4.4,37
		c2.3,6.6,8.4,11.5,12.2,17c6.8,9.7,13.2,20.2,14.7,32.7c0.5,4.2-1.4,8.9-1.8,13.2c-0.4,4.3,3.7,8-0.1,12.2
		c-6.1,6.6-18.7,1.3-25.1-0.7c-5.5-1.9-11.6-1.4-16.7,1.3c-3.9,1.9-5.9,3.8-9.3-0.8c-2.5-3.4-4.2-8-5.7-12c-2-4.6-4.3-11.3-2.9-16.5
		C2322.3,1411.8,2325.8,1408.6,2327.5,1405.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1436.3,989.1c4.9-8.6,4.8-20.2,7.5-29.7c4.6-16.5,20-25,36.2-25.7c19.1-0.8,39,0.5,51.3-17.6
		c11.2-16.5-5.6-24.1-19.6-29c-3.6-1.2-7.2-0.8-10.9-0.8c-5.6,0-13.4-7.6-18.5-10c-8-3.8-17.1-4.7-25.7-2.7
		c-7.4,1.9-15.5,10.8-20.7,16c-2,2-3.8,4.1-5.4,6.2c-11.7,5.8-14.8,23.6-21.3,34.2c-8.9,14.7-17,38.4-9.1,55.4
		C1408,1002.7,1426.5,1006.2,1436.3,989.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1443.9,808.5c1.4,4.6,3.6,8.8,5.3,13.3c1.5,4,5.2,2.6,4,8.4c-2.4,11.1,8.7,6.9,12.3,13.4
		c4,7.4,8,11.5,17,11.6c6.1,0,6.9,2,12,4.8c4,2.3,8.2,2.7,12.8,3c7.5,0.7,15-0.6,21.9-3.8c11.9-5.8,15.6-23.1,7.1-33.2
		c-4.3-4.9-11.6-6.6-17.5-8.5c-4.5-1.4-8.8-3.5-12.7-6.2c-6.5-4.9-5.2-13.5-5.3-20.7c-0.1-9.6-4.3-13.5-10.6-20.3
		c-4.4-4.6-6.3-10.7-6.9-16.9c-0.3-3.8,0.4-7.7-1.5-11.2c-2.8-4.9-10.7-10.9-16.8-9c-2.2,0.7-4,2.2-4.9,4.2
		c-5.1,3.2-8.8,7.6-12.1,13c-5.8,9.6,1.6,21.8,1,32C1448.3,791.7,1440.9,798.4,1443.9,808.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1568.3,714c-0.4-6.9-4.6-11.4-10.5-14.3c-13.9-7.1-28-14.9-44-15.4c-4.6-0.3-9.2,0.7-13.3,2.9
		c-1.3-1.2-3.3-1.1-4.4,0.1c-0.2,0.2-0.3,0.4-0.4,0.5c-5.2,8.3-11.5,16-14.2,25.5c-2.8,9.6,3.3,16.4,9.9,22.4
		c8.4,7.6,17.6,14.1,25.7,22.2c6.7,6.6,16,10.1,22.5,16.7c7.7,7.8,4.1,21.9,7.4,31.2c2.8,7.8,8.8,15.1,17.1,17.2
		c14.6,3.7,26.4-13.8,30.8-25.2c3-7.7,2.3-16.2-0.2-23.9c-3.1-8.8-7.2-17.1-12.4-24.8c-3.3-4.7-7.5-8.4-11.2-12.9
		C1566,730.1,1568.7,721.2,1568.3,714z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1569.5,654.9c-7.2,4.6-14.2,8.4-14.8,17.8c-0.4,6.1,5.7,11.1,11,15.1c1.2,2.4,3.5,4.2,6,4.6
		c0.6,0.2,1.3,0.3,2,0.1c10.4-0.3,24.6-16.8,28.1-22.9c7.9-13.5,17.9-44.4-3.4-52.6c-8.8-3.5-9-14.8-15.4-21.4
		c-6.7-6.9-18.6-8.6-27.7-9.8c-20.7-2.8-36.7,16.1-42.8,33.8c-4.9,14.2,0.4,31.9,13.1,40.7c14.9,10.2,22.5-11.4,23-22.2
		c0-1.2-0.5-10-2.6-9.7c4.1-0.7,8.1-1.3,12.3-1.6c4.5-0.4,8.3,4.9,11,8C1574.9,641.7,1578.6,649.1,1569.5,654.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1026.6,1061.4c6,6.1,11.2,12.2,20.6,10.8c6-0.9,9.6-7.9,12.5-13.9c2.1-1.7,3.3-4.3,3.3-6.9
		c0.1-0.7,0-1.3-0.3-1.9c-2.5-10.1-21.7-20.5-28.3-22.6c-14.9-4.8-47.2-8.2-50.7,14.3c-1.5,9.4-12.7,12-17.7,19.6
		c-5.3,8-4.4,20-3.8,29.1c1.7,20.8,23.4,32.5,42.1,34.8c14.9,1.8,31.2-7.1,37.1-21.4c6.9-16.7-15.8-19.6-26.5-17.8
		c-1.1,0.2-9.7,2.6-8.9,4.4c-1.5-3.9-3-7.7-4.2-11.7c-1.3-4.4,3.2-9.1,5.5-12.4C1012.6,1059,1019.1,1053.7,1026.6,1061.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1291.2,2172.5c6,6.1,11.2,12.1,20.6,10.8c6-0.9,9.6-7.9,12.5-13.9c2.1-1.7,3.3-4.3,3.3-6.9
		c0.1-0.7,0-1.3-0.3-1.9c-2.5-10.2-21.7-20.5-28.3-22.6c-14.9-4.8-47.2-8.2-50.7,14.3c-1.5,9.4-12.7,12-17.7,19.6
		c-5.3,8-4.4,20-3.8,29.1c1.7,20.8,23.4,32.5,42.1,34.8c14.9,1.8,31.1-7.1,37.1-21.4c6.9-16.7-15.8-19.6-26.5-17.8
		c-1.2,0.2-9.7,2.6-8.9,4.4c-1.5-3.9-3-7.7-4.2-11.7c-1.3-4.4,3.2-9.1,5.5-12.4C1277.1,2170.1,1283.6,2164.8,1291.2,2172.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2097.4,436.5c-0.9-6-8-9.6-13.9-12.5c-1.7-2.1-4.3-3.3-6.9-3.3c-0.7-0.1-1.3,0-1.9,0.3
		c-10.1,2.6-20.5,21.7-22.6,28.4c-4.8,14.9-8,47.2,14.5,50.6c9.4,1.4,12,12.6,19.7,17.6c8,5.2,20.1,4.4,29.1,3.7
		c20.8-1.8,32.4-23.5,34.7-42.2c1.8-14.9-7.2-31.1-21.5-37c-16.7-6.8-19.6,15.9-17.8,26.6c0.2,1.2,2.7,9.7,4.5,8.9
		c-3.9,1.5-7.7,3-11.6,4.2c-4.4,1.3-9.1-3.1-12.4-5.5c-6.9-5.1-12.2-11.6-4.5-19.2C2092.8,451,2098.8,445.9,2097.4,436.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1077.7,1135.2c-0.9-6.1-8-9.6-13.9-12.5c-1.7-2.1-4.3-3.3-6.9-3.3c-0.7-0.1-1.3,0-1.9,0.3
		c-10.1,2.6-20.5,21.7-22.6,28.4c-4.8,14.9-8,47.2,14.5,50.6c9.4,1.5,12,12.7,19.7,17.6c8,5.2,20,4.4,29.1,3.7
		c20.8-1.8,32.4-23.5,34.7-42.2c1.8-14.9-7.2-31.1-21.5-37c-16.7-6.8-19.6,15.9-17.7,26.6c0.2,1.1,2.6,9.7,4.5,8.9
		c-3.9,1.5-7.7,3-11.7,4.2c-4.4,1.3-9.1-3.1-12.4-5.5c-6.9-5.1-12.2-11.6-4.5-19.2C1073.1,1149.8,1079,1144.6,1077.7,1135.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M896.2,1817.9c-0.9-6-8-9.6-13.9-12.5c-1.7-2.1-4.3-3.3-6.9-3.3c-0.7-0.1-1.3,0-1.9,0.3
		c-10.1,2.6-20.5,21.7-22.6,28.4c-4.8,14.9-8,47.2,14.5,50.6c9.4,1.4,12,12.6,19.7,17.6c8,5.2,20.1,4.4,29.1,3.7
		c20.8-1.8,32.4-23.5,34.6-42.2c1.8-14.9-7.2-31.1-21.5-37c-16.7-6.8-19.6,15.9-17.7,26.6c0.2,1.2,2.7,9.7,4.5,8.9
		c-3.9,1.5-7.7,3-11.6,4.2c-4.4,1.3-9.2-3.1-12.4-5.5c-6.9-5.1-12.2-11.6-4.5-19.2C891.7,1832.5,897.6,1827.3,896.2,1817.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M652.1,1718c-8.4,1.5-16.8,0.9-17.1-9.9c-0.2-8.6,0.1-16.5-7.6-22c-5-3.6-12.5-0.8-18.6,1.7
		c-2.7-0.2-5.3,0.8-7.1,2.8c-0.5,0.4-0.9,1-1.2,1.6c-5.1,9.1,1.8,29.8,5.1,35.9c7.5,13.8,28.9,38.3,46.8,24.1
		c7.4-5.8,17.4-0.1,26.3-2.2c9.4-2.3,17-11.5,22.6-18.7c13-16.3,5.1-39.8-6.9-54.2c-9.6-11.5-27.6-16.2-41.6-9.8
		c-16.4,7.5-1.9,25.1,7.2,31.1c0.9,0.6,8.8,4.7,9.6,2.8c-1.5,3.9-3.2,7.6-4.9,11.3C662.5,1716.8,656,1717.2,652.1,1718z"/>
            <path :fill="colors[`${id}-p-1`]" d="M102.8,2124.1c-8.4,1.5-16.8,0.9-17.1-9.9c-0.2-8.6,0.1-16.5-7.6-22c-5-3.6-12.5-0.8-18.6,1.6
		c-2.7-0.2-5.3,0.8-7.1,2.8c-0.5,0.4-0.9,1-1.2,1.6c-5.1,9.1,1.8,29.8,5.1,35.9c7.5,13.8,28.9,38.3,46.8,24.1
		c7.4-5.8,17.4-0.1,26.3-2.2c9.4-2.3,16.9-11.5,22.6-18.7c13-16.3,5.1-39.8-6.9-54.2c-9.6-11.5-27.6-16.2-41.6-9.8
		c-16.4,7.5-1.9,25.1,7.2,31.1c0.9,0.6,8.8,4.7,9.6,2.8c-1.5,3.9-3.2,7.6-4.9,11.3C113.3,2122.9,106.8,2123.4,102.8,2124.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M326.6,1385.2c-7.8,3.7-15.1,6.6-16.9,15.9c-1.2,6,4.4,11.7,9.1,16.3c0.9,2.6,2.9,4.5,5.4,5.3
		c0.6,0.3,1.3,0.4,1.9,0.4c10.4,1,26.4-13.7,30.7-19.4c9.5-12.6,23.1-41.9,3-52.6c-8.4-4.4-7.1-15.8-12.8-23.1
		c-5.8-7.6-17.5-10.8-26.3-13.1c-20.2-5.2-38.4,11.5-46.7,28.5c-6.6,13.5-3.5,31.8,8,42c13.5,12,23.7-8.6,25.5-19.3
		c0.2-1.2,0.7-10-1.4-9.9c4.2-0.2,8.2-0.3,12.4-0.1c4.5,0.2,7.6,5.9,9.9,9.3C333.4,1372.6,336.3,1380.6,326.6,1385.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M242.1,1673.8c8.3-2.5,15.9-4.2,19.2-13.1c2.1-5.7-2.5-12.3-6.4-17.6c-0.5-2.7-2.2-4.9-4.6-6.1
		c-0.5-0.4-1.2-0.6-1.8-0.7c-10.1-2.6-28.3,9.4-33.3,14.2c-11.4,10.9-29.5,37.8-11.3,51.5c7.5,5.7,4.6,16.7,8.9,24.8
		c4.6,8.5,15.5,13.3,23.9,17c19.2,8.3,39.8-5.3,50.6-20.7c8.6-12.3,8.5-30.9-1.4-42.7c-11.5-13.9-24.8,4.7-28.3,14.9
		c-0.4,1.1-2.3,9.8-0.2,10c-4.1-0.5-8.2-1.1-12.2-1.9c-4.4-0.9-6.5-7.1-8.3-10.8C233.4,1685,231.8,1676.7,242.1,1673.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1619.1,857c4.7-3.5,9.5-7.4,10.6-13.3s-3.6-6.6-7.9-8.4c-9.4-3.7-16.5-6.1-26.1-1.5
		c-5.5,2.7-9.9,6.4-11.2,11.8c-1.5,1.6-2.8,3.3-3.8,5.1c-2.2,4-3.8,12.8,0.9,15.8C1592.6,873.9,1610.2,863.3,1619.1,857z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1168.4,1024.3c-1.3-5.7-3.3-11.6-8.3-14.9c-5-3.4-7.5,0.7-10.8,4.1c-7,7.2-12,13-11.4,23.6
		c0.3,6.1,2.2,11.5,6.6,14.8c0.9,2,2,3.9,3.4,5.5c2.8,3.6,10.4,8.4,14.9,5.2C1174,1055.2,1170.9,1034.9,1168.4,1024.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M266.3,1092c4.7-3.5,9.5-7.4,10.6-13.3s-3.6-6.6-7.9-8.4c-9.4-3.8-16.5-6.1-26.1-1.5
		c-5.5,2.7-9.9,6.4-11.2,11.8c-1.5,1.6-2.8,3.3-3.8,5.1c-2.2,4-3.8,12.8,0.9,15.8C239.8,1109,257.4,1098.4,266.3,1092z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1870.7,628.6c18.7,2.4,34.3-22.3,41.6-36.2c4-7.4,7.3-15.4,4.9-23.7s-8.7-6.2-15.3-5.6
		c-14.2,1.3-24.9,2.8-34.1,14.8c-5.3,6.9-8.4,14.4-6.7,22.2c-0.9,3-1.4,5.9-1.5,9C1859.3,615.5,1862.8,627.6,1870.7,628.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1851.2,1289.9c12.9,1.4,14.1-4.7,17.8-10.4c7.8-12.3,12.9-21.7,2.9-34.1c-6.1-7.9-15.2-13.2-25.2-14.4
		c-3.5-2-7.2-3.7-11.1-4.9c-8.4-2.8-27.2-4.7-33.7,1.3c-15.7,14.5,7,37.3,20.7,48.9C1829.8,1282.5,1838.4,1288.5,1851.2,1289.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1731.4,759.4c2.3-5.6-0.5-17.4-4.9-21.6c-5.7-5.4-16-6.4-22.6-1.7c-8,5.7-12.2,19.7-23.4,18.8
		c-1.4-0.1-2.8,0.7-3.4,2c-4.4,7.9-7.1,16.8-10.9,25.1l-0.2,0.2c-7.9,6.7-11.7,12.3-9.8,23c1.6,8.4,7.3,15.4,15.8,16.9s23-8,30-12.4
		c9.8-6,23.8-16.2,28.1-27.5c1.6-4.2-0.9-8.9-1.4-13.1c-0.2-1.2-2.5-4.6-1.5-5.5C1728.6,762.6,1730.8,761.3,1731.4,759.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1662.7,748.4c5.4-6.5,11.4-19.1,12.6-27.5c0.7-4.6-2.9-8.4-5.7-11.3c-6.4-6.4-8-12.8-12.7-20.1
		c-5.3-8.6-23-20-33.2-12.9c-8.5,5.9-12.5,14.9-16.8,23.8c-6.7,2.1-11.8,6.6-11.4,16c0.2,5.4,2.2,9.4,5.8,13.4
		c5.9,6.6,9,9.3,8.6,18.6C1608.9,778.2,1651.6,761.6,1662.7,748.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1672.9,583.3c-4.4,3-8.5,6.5-12,10.7c-6.8,8-9.1,19.6-7.4,29.7c1.1,6.1,3,20.8,9.8,23.9c5,2.3,3,4,5,8.3
		c2.2,4.6,5.9,6.2,10.6,7.9c5.2,1.9,10.9,3,16.2,4.7c8.8,3,12.9,8.6,14.2,17.2c1.7,10.5,16.2,10,19.9,18c1.3,2.9,2.6,5.6,5.6,6.9
		c0.5,0.2,3.5,0.9,3.5,1.2c0,1.3-0.4,3.9,0.5,5.3c4.2,7.4,15.5,5.8,21.7,3.3c10.4-4.4,20.5-9.6,30-15.6c6.4-4.2,9.9-10.8,10.7-18.3
		c2.1-20.5-7.8-42.6-31.1-32.3c-8,3.6-11.1,0.9-13.4-6c-2.2-5.6-5-11-8.5-15.9c-10.7-16-18.6-36.1-3.9-52.7c8-9,18.9-4.1,29.2-5.8
		c2.9-0.7,5.3-2.3,7.1-4.6c0.7-0.8,2.3-4.4,3.8-6.9c0.2-0.1,0.5-0.3,0.7-0.4c10.9-2.7,17.4-11.6,16.5-23.7
		c-0.4-4.6-3.4-8.6-7.6-10.4c-3.2-1.2-6.3-2.3-9.6-3.1c-6.6-1.9-9.4-6.8-14.7-10.3c-13.4-8.7-34.2-6.4-49.2-2.8
		c-10.1,2.5-20.5,10.7-22.5,21.1c-6.5,0.3-10.7,1.9-13,10.2c-1.4,4.7-1.4,9.8-0.2,14.5c0.8,3.3,8.3,12.1,5.3,15.4
		C1685.9,577.1,1677.9,580,1672.9,583.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1850.6,582c4.6-2.7,8.3-5.6,8.6-11.5c0.5-10.4-5.7-28.5-17.6-30.6c-5-0.9-8.9,2.7-12,6.2
		c-5.1,6-13.5,4.4-19.4,9.4c-4,3.4-14,13.5-14,21.1c-2.8,9.4,2.4,15.9,11.7,21.1c8.4,4.6,12.5,5.9,9.7,16.6c-1.8,7-4.3,13.3-3,20.7
		c1.9,10.6,18.5,15.1,26.2,8.5c9-7.8,13-23.7,8.1-34.7c-1.3-3-4-5.6-5.7-8.3C1838.3,593.5,1844.4,585.6,1850.6,582z"/>
            <path :fill="colors[`${id}-p-1`]" d="M601.3,248.3c-0.9,4.6-1.1,9.4-0.7,14c0.8,9.2,6.7,17.7,14.1,22.8c4.5,3.2,14.8,10.9,21.1,8.5
		c4.5-1.8,4.4,0.6,8.3,1.9c4.3,1.4,7.6,0.1,11.5-1.8c4.4-2.1,8.5-5,13-7.3c7.3-3.8,13.3-2.8,19.6,1.7c7.6,5.3,16.2-4.1,23.5-1.5
		c2.6,1,5.1,1.8,7.8,0.8c0.9-0.6,1.9-1.1,2.9-1.5c0.8,0.8,2.2,2.6,3.7,3c7.2,2,13.2-6.1,15.3-11.7c3.7-9.2,6.5-18.8,8.6-28.5
		c1.4-6.6-0.7-12.9-4.9-18c-11.5-13.8-31.6-21.3-39.5-0.3c-2.7,7.2-6.2,7.5-12.1,4.7c-4.8-2.1-10-3.6-15.2-4.4
		c-16.6-3.1-34.1-10.5-35.5-29.9c-0.7-10.6,9.1-14.3,14.2-22c1.4-2.2,1.9-4.7,1.5-7.3c-0.1-0.9-1.4-4.2-2.1-6.6
		c0.1-0.2,0.1-0.4,0.1-0.7c4.9-8.5,3.5-18.1-4.7-24.9c-3.2-2.6-7.4-3.2-11.2-1.6c-2.7,1.2-5.3,2.6-7.8,4.2c-5.2,3-10.1,1.7-15.5,3
		c-13.8,3.2-25,17.5-31.9,29.3c-4.6,7.8-5.8,19.5-0.6,27.1c-3.9,4.3-5.3,7.9-1.5,14.4c2.2,3.8,5.2,6.9,9,9c2.5,1.5,12.8,2.2,13,6.1
		C605.5,236.3,602.3,243.2,601.3,248.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M709.7,135.8c1.2-4.5,1.6-8.7-1.9-12.5c-6.2-6.6-21.4-13.9-30.1-7.6c-3.7,2.7-3.8,7.2-3.5,11.3
		c0.6,6.9-5.5,11.3-6,17.9c-0.3,4.6-0.1,17.1,4.6,21.9c4.3,7.5,11.5,8.3,20.5,5.5c8-2.5,11.4-4.2,16.4,4.2
		c3.3,5.4,5.8,10.9,11.2,14.5c7.8,5.3,20.9-2.4,21.5-11.3c0.7-10.4-7-22.7-16.8-26.4c-2.7-1-5.9-1-8.8-1.5
		C709.4,150.5,708.1,141.8,709.7,135.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1741.6,746.4c0.9,9.4,6.8,13.8,14.5,14.4c5.5,4.8,14.4,7,21,3.9c4.8-2.3,6.6-6.9,9.5-11.1
		c3.8-5.4,11-3.9,14.6-9.3c6.4-9.8-2.5-15.8-10.8-17.3c-17.6-3.2-28.4,16-45.1,15.4c-1.9-0.2-3.6,1.2-3.8,3.1
		C1741.6,745.8,1741.6,746.1,1741.6,746.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1884.2,673.5c-1.4-4.6-4.4-8.1-7.5-11.8c-5.7-6.6-12-11.5-21.5-10.5c-12,1.3-18.3,10.8-22.6,21.1
		c-2.1,4.9-3.7,10.1-5.3,15.1c-0.4,0.2-0.7,0.6-0.9,0.9c-5,7.6-14.3,19.8-10,29.6c3.4,7.6,8.6,7.2,15.9,5.7c9-1.9,9.6,6.9,10.6,13.2
		c0.7,4.2,2.7,6.3,6.1,8.5c8.4,5.3,20.8-1.1,29.4-2.6c20-3.5,32.4,29.6,52.1,8.3c4.7-5.1,7.5-8.3,14.8-10.3s13.5-0.4,20.4,2.4
		c8.6,3.5,13.8,9.6,23.9,5.7c15.8-5.9,39.3-29.9,21-45.9c-23.9-21-50.9,15.4-74.9,7.9c-10.2-3.3-18.9-11.4-29.7-13.3
		c-0.4-0.1-5.2-7.1-7.1-8.2c-4.1-2.3-10.4,0.1-13.4-3.8C1882.7,682.3,1885.3,677.6,1884.2,673.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1954.6,676.2c2.2,13.7,21.2,12,27.7,3c3.6-4.8,5.6-13.2,7.5-18.8c3.1-9.4,13.7-13.7,16.8-23.8
		c2.1-6.6-2.2-12.3-5.6-17.5c-3.4-4.9-11-18.6-17.7-19.2c-5-0.5-11.5,4.1-16.3,0.6c-3.1-2.2-2.7-6.2-5.4-8.4
		c-4.4-3.6-11.9-6.7-17.7-4.9c-18.2,5.6-17.2,23.2-30.4,34c-5.6,4.6-16.5,2.7-20.7,8.6c-3.8,5.3-5.3,14.2-1.4,20
		c8,11.7,23.3,5.3,35-1.6c6.7,0.4,13.3,1.5,19.9,3.3C1957.2,654.3,1953.2,667.7,1954.6,676.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2100.8,237.4c-12.3-11.7-43.9-22.3-50.4,1.1c-8.5,30.7,36.1,39.2,39.7,64c1.5,10.6-2.1,22,0.9,32.4
		c0.1,0.4-4.2,7.8-4.3,9.9c-0.3,4.6,4.6,9.2,2.5,13.8c-2,4.2-7.3,3.9-10.5,6.6c-3.6,3.3-5.4,7.5-7.3,11.9c-3.5,8-5.1,15.8-0.1,23.8
		c6.3,10.2,17.7,11.8,28.9,11.3c5.3-0.2,10.6-1.1,15.9-1.8c0.4,0.2,0.8,0.3,1.2,0.4c9.1,1.2,24.1,4.3,31-3.9
		c5.3-6.3,2.8-10.9-1.8-16.8c-5.6-7.3,2-11.7,7.2-15.2c3.5-2.4,4.5-5.1,5-9.2c1.2-9.9-10-18.3-15-25.4
		c-11.8-16.5,12.6-42.1-15.1-50.6c-6.7-2.1-10.8-3.2-15.7-8.9c-4.9-5.7-6.2-12.1-6.7-19.4C2105.5,252.3,2108.8,244.9,2100.8,237.4z"
            />
            <path :fill="colors[`${id}-p-1`]" d="M2050.6,300.7c11.4-7.9,1.6-24.3-9.4-26.2c-5.9-1.1-14.2,0.6-20.2,1.4c-9.8,1.3-18.3-6.4-28.8-4.8
		c-6.9,1-10.1,7.2-13.3,12.7c-3.1,5.1-12,18-9.6,24.3c1.8,4.7,8.7,8.6,7.6,14.3c-0.7,3.7-4.4,5-5.2,8.6c-1.3,5.5-1,13.5,3.3,18.1
		c13,13.9,28.5,5.5,43.9,12.6c6.6,3.1,9.6,13.6,16.8,14.8c6.4,1.1,15.1-1.4,18.6-7.4c7-12.4-5.3-23.3-16.6-30.9
		c-2.6-6.2-4.5-12.7-5.7-19.3C2029.7,307.9,2043.5,305.7,2050.6,300.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2056.5,768.3c-12.3-11.7-43.9-22.3-50.4,1.1c-8.5,30.7,36.1,39.2,39.7,64c1.5,10.6-2.1,22,0.9,32.5
		c0.1,0.4-4.2,7.8-4.3,9.9c-0.3,4.6,4.6,9.2,2.5,13.8c-2,4.2-7.3,3.9-10.5,6.6c-3.6,3.3-5.4,7.5-7.3,11.9c-3.5,8-5.1,15.8-0.1,23.8
		c6.3,10.2,17.7,11.8,28.9,11.3c5.3-0.2,10.6-1.1,15.9-1.8c0.4,0.2,0.8,0.3,1.2,0.4c9.1,1.3,24,4.4,31-3.9
		c5.3-6.3,2.8-10.9-1.8-16.8c-5.6-7.3,2-11.7,7.2-15.2c3.5-2.4,4.5-5.1,5-9.2c1.2-9.9-10-18.3-15-25.3
		c-11.8-16.5,12.6-42.1-15.1-50.6c-6.7-2.1-10.8-3.2-15.7-8.9s-6.2-12.1-6.7-19.4C2061.1,783.2,2064.5,775.9,2056.5,768.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2006.2,831.6c11.4-7.9,1.6-24.3-9.4-26.2c-5.9-1.1-14.2,0.6-20.2,1.4c-9.8,1.3-18.3-6.4-28.8-4.8
		c-6.9,1-10.1,7.2-13.3,12.7c-3.1,5.1-12,18-9.6,24.3c1.8,4.7,8.7,8.6,7.6,14.3c-0.7,3.7-4.4,5-5.2,8.5c-1.3,5.5-1,13.5,3.3,18.1
		c13,14,28.5,5.5,43.9,12.6c6.6,3.1,9.6,13.6,16.8,14.8c6.4,1.1,15.1-1.3,18.6-7.4c7-12.4-5.3-23.3-16.6-30.9
		c-2.6-6.2-4.5-12.7-5.7-19.3C1985.3,838.9,1999.1,836.6,2006.2,831.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1023.4,844.8c-2.9,7.5-10.5,6.2-15.2,11.3c-2.7,3-4.1,6.8-4,10.8c-1.2,1.9-1.9,4-2,6.1
		c-1.1,10.2-1,17.3-7.6,25.9c-7.4,9.6-12.5,25.8,4.6,26.9c6.9,0.4,12-2,14.8-8c2.4-4.9,10.6-3.6,14.5-7.4c3.1-3.1,5.6-5,7.1-9
		c2.9-7.4,0.2-16.4-1.9-23.6c-1.2-4.3-2.8-8.3-4.3-12.4c-2.1-5.9-0.1-12.7,0.2-18.7C1030.1,843,1024.9,840.9,1023.4,844.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1010,677.7c-6-6.8-16.1-6.8-24.3-5.2c-11.2,2.1-18.8,16.2-23,25.6c-2.4,4.9-3.6,10.4-3.7,15.9
		c-0.1,9.9-8.3,14.6-10.1,23.4c-0.5,1.9,0.6,3.8,2.4,4.3c0.3,0.1,0.6,0.1,0.9,0.1h0.2c1.9,7.8,6.7,14.6,13.5,18.9
		c9,5.9,23.2,0.3,33.3,2.5c11.1,2.4,14.5,15.6,25.3,18.9c5.1,1.6,8.7,0.2,13.4-1.5c3.6-1.3,10.6-1.2,13.4-3.4
		c3.7-2.8,6.8-6.1,9.3-10c7.6-12.1,16.5-5.9,25.5-2.2c9.2,3.9,18,5.8,27.5,2c13.1-5.3,25.8-30.8,14.3-42.8
		c-10.6-11.1-30.3-6.4-42.5-1.1c-13.4,5.8-32.9,18-43,0.4c-5.9-10.4-13.8-14.7-23.1-22.1c-5.5-4.4-8.3-11.7-8.1-18.6
		C1011.3,681,1011.4,679.3,1010,677.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1036.2,681.8c2.1,4.6,7.6,7.2,15.3,4.6c3.7-1.3,6.9-3.7,10.1-5.8c6.8-4.7,13.8-3.8,21,0.2
		c8.8,4.9,5.3,19.2,13.3,23c10.6,5.1,29.9-5.4,33.5-16.1c2.8-8,0-16.9,3.2-25.2c3.8-10,7.5-16.2,5.7-27.4
		c-2.3-14.6-16.2-26.3-31.3-20.6c-10.6,4.2-16.5,0.2-24.7-5c-2.2-1.4-6.2-1.3-8.7-0.8c-8,1.7-15.8,6.9-18.5,15.1
		c-4.2,12.5-8.9,20.7-20.9,27c-5.8,3.1-8.8,8.4-9.5,14.8C1024.1,673.4,1028.5,686.7,1036.2,681.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2171.2,2130.9c7.1-5.6,7.6-15.7,6.4-24c-1.6-11.3-15.3-19.6-24.4-24.3c-4.8-2.6-10.2-4.1-15.7-4.4
		c-9.9-0.6-14.2-9-23-11.2c-1.8-0.6-3.8,0.4-4.4,2.2c-0.1,0.3-0.1,0.6-0.2,0.9v0.2c-7.9,1.5-14.9,6-19.6,12.7
		c-6.3,8.7-1.4,23.2-4.1,33.2c-3,11-16.2,13.8-20.1,24.3c-1.9,5.1-0.6,8.7,0.9,13.5c1.2,3.6,0.7,10.7,2.7,13.5
		c2.6,3.9,5.8,7.1,9.6,9.8c11.7,8.1,5.1,16.8,0.9,25.6c-4.3,9-6.7,17.7-3.3,27.4c4.6,13.3,29.5,27.3,42,16.3
		c11.6-10.1,7.8-29.9,3.1-42.4c-5.2-13.8-16.3-33.8,1.7-43c10.7-5.4,15.3-13.1,23.1-22.1c4.6-5.3,12.1-7.7,19-7.2
		C2167.8,2132,2169.6,2132.2,2171.2,2130.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2165.8,2156.8c-4.7,1.9-7.6,7.2-5.3,15c1.1,3.8,3.3,7.1,5.3,10.4c4.4,7,3.1,13.9-1.3,21
		c-5.4,8.6-19.4,4.4-23.6,12.2c-5.6,10.4,4,30.2,14.4,34.2c7.9,3.1,16.8,0.8,25,4.4c9.8,4.3,15.8,8.3,27.1,7.1
		c14.7-1.6,27.1-14.9,22.1-30.3c-3.6-10.8,0.6-16.5,6.2-24.5c1.5-2.2,1.6-6.2,1.2-8.6c-1.3-8-6.1-16.1-14.2-19.2
		c-12.3-4.7-20.2-9.9-25.9-22.2c-2.8-5.9-7.9-9.2-14.3-10.2C2174.8,2145,2161.3,2148.9,2165.8,2156.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1192.3,717.7c6.1,1.4,10.4,6.3,16.6,7.7c15.2,3.3,18.6-14.5,29.8-19.9c9.7-4.6,3.2-19.9,0.3-26.8
		c-1.2-3.1-2.5-9.4-6.4-10.6c-1.6-0.4-3.2-0.6-4.7-0.4c-4.2,0.2-9-4.7-12.5-6.6c-9.5-5.2-23.2-2.3-31.4,4c-3.7,2.8-6.5,6.5-7.1,10.4
		c-11.2,10.3-15.8,34.7-1.9,39.8C1180.3,717,1186.4,716.3,1192.3,717.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1189,680.1c0,0.1-0.1,0.1-0.1,0.2c-0.4,1.2-1.4,2.1-2.7,2.3c-0.4,2.2-2.7,3.9-4.9,2.3c-1-0.6-1.9-1.4-2.7-2.3
		c-3.5,5-4.8,12.8-4.9,17.8c-0.3,11.3,10.6,8.4,17.8,10c6.2,1.4,11.2,6.5,17,7.8c9.5,2.1,14.3-9.6,20.6-15c1.9-1.5,4-2.7,6.1-3.7
		c3-1.7,0.2-11-0.6-13.3c-0.3-0.9-3.8-12-5.4-11.9c-2.1,0.1-4.2-0.2-6.1-0.8c-7.1-2-10.9-9.1-19.2-8.4c-5.5,1.2-10.8,3.1-15.9,5.5
		h0.3c1.9,0,3.5,1.6,3.5,3.5v2.6C1191.7,678.4,1190.6,679.7,1189,680.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1187,800.6c8.7,2.1,14.6,8.5,23.2,2.5c4.4-3.1,6.4-9.5,8.9-14c2.8-5,6.9-9.5,9-14.9c4-10.6-1-18.7-9.8-24.8
		c-14.3-9.9-35-8.6-45.4,5c-1.8,1.4-3.5,2.9-5.1,4.5c-10.3,10.1-0.5,16.2,3.7,26.4C1175.1,794,1177.3,798.3,1187,800.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1502.6,581.7c8.7,2.1,14.6,8.5,23.2,2.5c4.4-3.1,6.4-9.5,8.9-14c2.8-5,6.9-9.5,9-14.9
		c4.1-10.6-1-18.7-9.8-24.8c-14.4-10-35.1-8.7-45.4,4.9c-1.8,1.4-3.5,2.9-5.1,4.5c-10.3,10.1-0.4,16.2,3.7,26.4
		C1490.7,575,1493,579.4,1502.6,581.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1765.2,394.5c6.7,1.6,11.4,6.5,18,2c3.5-2.4,4.9-7.3,6.9-10.9c2.2-4,5.3-7.3,7-11.5
		c3.1-8.2-0.8-14.4-7.6-19.2c-11.2-7.7-27.2-6.7-35.2,3.8c-1.4,1.1-2.8,2.3-4,3.6c-7.9,7.8-0.3,12.6,2.9,20.5
		C1755.9,389.3,1757.7,392.7,1765.2,394.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M874.7,746.9c0.2,0,8.1-0.9,7.9-1.3c0.7,1.8,1.6,3.5,2.6,5.1c2.7,3.7,9.5,2.9,13.3,2.2
		c6.6-1.2,24.6-8.5,23.7-17.5c-0.4-4.4-1.7-9.2-6.1-11.4c-4.3-2.1-15.7,0.4-17.2-2.3c-0.6-1.4-2.1-2.3-3.7-2.1H887
		c-1.5,0-6-5.9-7.1-7c-1.9-1.9-7.3-2.2-9.6-1.3c-9.3,3.5-19.7,11.3-21.7,21.5c-0.3,1.7,0.8,3.4,2.5,3.7c0.3,0.1,0.6,0.1,0.9,0
		C855.6,743.9,866.2,746.8,874.7,746.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M883.7,739.3c2.9,1,4,1.2,5.2,3.8c2.1,4.1,3.7,4.4,8.6,3.1c5.2-1.2,10.2-3.7,14.3-7c5.7-5.1-0.4-11.7-7.2-8.4
		l-3.5,0.9c-3,0.8-5.6-2.3-7.4-4.9c-3.5,0.3-8.8,0.9-10.6-0.9c-3.6-3.6-9.1-11.3-15.8-5.2c-3,2.7-6.8,4.7-9.4,7.8c0,0.1,0,0.2,0,0.3
		C854.7,744,878.4,737.4,883.7,739.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1033.6,578.6c15.3,18.1,46.8,7.5,58.4-9.6c29.4-42.9-64.4-73.6-66.4-25.6c-0.1,0.9,0.3,1.9,1,2.5
		C1022.9,556.4,1025.5,569,1033.6,578.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1043.6,536.2c-14.3,7.9-16.3,28.2-3.8,39.1c13.3,11.5,35.1,4.2,45.2-8.5c23.8-30.1-33.6-53-48.9-33.8
		c1.3-1,2.7-1.9,4.1-2.8C1044.1,528.1,1047.5,534,1043.6,536.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2150.4,1077.5c25.9,4.7,23.4,30.9,25.8,50.6c2.5,20.1,25.3,37,40.5,17.8c6.9-8.7,8.8-20.6,13.9-30.3
		c4.4-8.2,10.7-20.1,7.4-29.8c-4.2-12.3-15.7-10.2-21.5-20.4c-3.4-6-2.5-13.5-4.6-20c-2.5-7.3-6.3-14.1-11.4-20.1
		c-7.3-8.8-18.7-12.7-29.3-15.7c-6-1.8-12.3-2.2-18.5-2.5c-9.5-0.6-17.2-5-26.2-7.2c-7.5-1.8-14.4-2.6-21.1,1.9
		c-11.5,7.8-20.2,22.4-28.1,33.4c-11.8,16.4-34.3,41.2-20.4,63.1c5.5,8.6,12.3,11.7,21.9,14.1c11.1,2.8,12.6,22.1,17.2,31
		c9.2,18,23.7,23.1,39.1,9c5.3-4.8,11.8-12.5,11.9-20.1c0.1-11.8-5.6-19.5-12.5-28.2c2.1-2.8-0.8-7.2-4.1-6
		C2128.2,1086,2132.6,1074.2,2150.4,1077.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1364.6,599.9c1.1-0.7,1.6-1.9,1.4-3.2l-0.1-1.3c-0.3-2.5-2.7-3.3-4.7-2.7c-1.1,0.4-2,1.2-2.5,2.3
		c-2.7-8.9-8.4-15.7-20.5-15c-8.2,0.4-12.9,4.9-16.7,11.9c-2.8,5.2-3.5,12.4-7.8,16.7c-4.4,4.4-12.2,4.4-17.7,6
		c-8.3,2.5-15.2,7.1-16.9,16.1c-1.8,9.4,8.3,13,14.4,17c8.3,5.6,5.1,14.7,8.2,22.6c3,7.6,15.4,1.4,18.7,5.3
		c7.8,9.6,15.3,6.9,26.3,9.6c4.1,1,4.7,5.6,8.3,7c4.1,1.7,8.4,2,12.7,0.9c4.6-1.1,8.6-3.7,13.3-4.7c7.5-1.8,10.1,8.9,13,13.3
		c4.6,7.1,18.7,4.1,25.6,4.1c4.6,0,9-1.9,12.9-4.3c8.3-5.1,15.2-4.3,24.4-5c10.2-0.9,19-12,24.6-19.4c4.3-5.6,9.7-14.4,7.2-21.9
		c-2.6-7.9-6.1-13.3-2.7-22c2.3-5.7,6.1-10.4,6.1-16.8c-0.1-8.4-3.6-14.4-9.1-20.6c-2.5-2.8-5-6.9-8-9.1c-4.1-3.2-5.1-10.1-7.5-14.4
		c-5.9-10.9-23.8-5.9-32.2-1.4c-13.7,7.5-16.2,27.3-13.6,41.1c1.3,7.3,8.3,16.9-2.6,22.2c-9.1,4.5-17.8-3.6-23.6-9.1
		c-5.5-5.3-22.5-3.1-24.3-13.2c-0.3-2-2.7-3.9-4.7-2.7c-1.9,1.1-3.8,2.2-5.5,3.5c0-0.9,0-1.8-0.1-2.7c0.8-2.4,2-4.7,2.7-7.5
		C1363.7,601.8,1364.3,600.7,1364.6,599.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1359,543.3c1,6.7,0.4,13.5,1.2,20.3c1.4,11.5,14.9,15.2,24.1,12.9c6.9-1.8,13.3-4.2,20.3-5.3
		c4.7-0.8,10.4-1.9,14.2-4.9c8-6.4,10.1-18,9.4-27.8c-1-13.8-9.3-23.4-19.1-32.6c-8.5-8-18-8.1-28.5-4.4c-10.9,4-24.9,8.3-28.9,20.3
		c-1.3,3.8,3.9,6.2,5.9,3.5c-0.2,1.4-0.3,2.9-0.3,4.4C1357.3,534.1,1358.3,538.7,1359,543.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1908.5,455.4c1.1-0.7,1.6-1.9,1.4-3.2l-0.1-1.3c-0.3-2.5-2.7-3.3-4.7-2.7c-1.1,0.4-2.1,1.2-2.5,2.3
		c-2.7-8.9-8.4-15.7-20.5-15c-8.2,0.4-12.9,4.9-16.7,11.9c-2.8,5.2-3.5,12.4-7.8,16.6c-4.4,4.3-12.2,4.4-17.7,6
		c-8.3,2.5-15.2,7.1-16.9,16.1c-1.8,9.4,8.3,13,14.4,17.1c8.3,5.6,5.1,14.7,8.2,22.6c3,7.6,15.4,1.4,18.7,5.3
		c7.8,9.6,15.3,6.9,26.3,9.6c4.1,1,4.7,5.6,8.3,7c4.1,1.7,8.4,2,12.7,0.9c4.6-1.1,8.6-3.7,13.3-4.7c7.5-1.8,10.1,8.9,13,13.3
		c4.7,7.1,18.7,4.1,25.6,4.1c4.6,0,9-1.9,12.9-4.3c8.3-5.1,15.2-4.3,24.4-5c10.2-0.9,19-12,24.6-19.4c4.3-5.6,9.7-14.4,7.2-21.9
		c-2.6-7.9-6.1-13.4-2.7-22c2.3-5.7,6.1-10.4,6.1-16.8c-0.1-8.4-3.6-14.4-9.1-20.6c-2.5-2.8-5-6.9-8-9.1c-4.1-3.2-5.1-10.1-7.5-14.4
		c-5.9-10.9-23.8-5.9-32.2-1.4c-13.7,7.5-16.2,27.3-13.6,41.1c1.3,7.3,8.3,16.9-2.6,22.2c-9.1,4.4-17.8-3.6-23.6-9.1
		c-5.5-5.3-22.5-3-24.3-13.2c-0.3-2-2.7-3.9-4.7-2.7c-1.9,1.1-3.8,2.3-5.5,3.6c0-0.9,0-1.8-0.1-2.7c0.8-2.4,2-4.7,2.7-7.5
		C1907.6,457.3,1908.3,456.2,1908.5,455.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1902.9,398.7c1,6.7,0.4,13.5,1.2,20.3c1.4,11.5,14.9,15.2,24.1,12.9c6.9-1.8,13.3-4.3,20.3-5.3
		c4.7-0.8,10.4-1.9,14.1-4.9c8-6.4,10.1-18,9.4-27.9c-1-13.8-9.3-23.4-19.1-32.6c-8.5-8-18-8.1-28.5-4.4c-11,4-24.9,8.3-28.9,20.3
		c-1.3,3.8,3.9,6.2,5.9,3.5c-0.2,1.4-0.3,2.9-0.3,4.4C1901.3,389.5,1902.3,394.1,1902.9,398.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1754.3,412.8c-0.9-6-8-9.6-13.9-12.5c-1.7-2.1-4.3-3.3-6.9-3.3c-0.7-0.1-1.3,0-1.9,0.3
		c-10.1,2.6-20.5,21.7-22.6,28.4c-4.8,14.9-8,47.2,14.5,50.6c9.4,1.4,12,12.6,19.7,17.6c8,5.2,20.1,4.4,29.1,3.7
		c20.8-1.8,32.4-23.5,34.7-42.2c1.8-14.9-7.2-31.1-21.5-37c-16.7-6.8-19.6,15.9-17.7,26.6c0.2,1.1,2.7,9.7,4.5,8.9
		c-3.9,1.5-7.7,3-11.6,4.2c-4.4,1.3-9.1-3.1-12.4-5.5c-6.9-5.1-12.2-11.6-4.5-19.2C1749.7,427.3,1755.7,422.1,1754.3,412.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1866.1,435c5.4-6.5,11.4-19.1,12.6-27.5c0.7-4.6-2.9-8.4-5.7-11.3c-6.4-6.4-8-12.8-12.7-20.1
		c-5.3-8.6-23-20-33.2-12.9c-8.5,5.9-12.5,14.9-16.8,23.8c-6.7,2.1-11.8,6.6-11.4,16c0.2,5.4,2.2,9.4,5.8,13.3
		c5.9,6.6,9,9.3,8.6,18.6C1812.3,464.9,1855,448.4,1866.1,435z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1660.4,134.5c-4.4,3-8.5,6.5-12,10.7c-6.8,8-9.1,19.6-7.4,29.7c1.1,6.1,3,20.8,9.8,23.9c5,2.3,3,4,5,8.3
		c2.2,4.6,5.9,6.2,10.6,7.9c5.2,1.9,10.9,3,16.2,4.7c8.8,3,12.9,8.6,14.2,17.2c1.7,10.5,16.2,10,19.9,18c1.3,2.9,2.7,5.6,5.6,6.9
		c0.5,0.2,3.5,0.9,3.5,1.2c0,1.3-0.4,3.9,0.5,5.3c4.2,7.4,15.4,5.8,21.7,3.3c10.4-4.4,20.4-9.6,30-15.6c6.4-4.2,9.9-10.8,10.7-18.3
		c2.1-20.5-7.8-42.6-31.1-32.3c-8,3.6-11,0.9-13.4-6c-2.2-5.6-5-11-8.5-15.9c-10.7-16-18.6-36.1-3.9-52.7c8-9,18.9-4.1,29.2-5.8
		c2.9-0.7,5.3-2.3,7.2-4.6c0.7-0.8,2.3-4.4,3.8-6.9c0.2-0.1,0.5-0.3,0.7-0.4c10.9-2.7,17.4-11.6,16.5-23.7
		c-0.4-4.6-3.4-8.6-7.6-10.4c-3.2-1.2-6.3-2.3-9.6-3.1c-6.6-1.9-9.4-6.8-14.7-10.3c-13.4-8.7-34.2-6.4-49.2-2.8
		c-10,2.5-20.5,10.7-22.5,21.1c-6.5,0.3-10.7,1.9-13,10.2c-1.4,4.7-1.4,9.8-0.2,14.5c0.8,3.3,8.3,12.1,5.3,15.4
		C1673.5,128.3,1665.4,131.1,1660.4,134.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1838.1,133.2c4.6-2.7,8.3-5.6,8.6-11.5c0.5-10.4-5.7-28.5-17.6-30.6c-5-0.9-8.9,2.7-12,6.2
		c-5.1,6-13.5,4.4-19.4,9.4c-4,3.4-14,13.5-14,21.2c-2.8,9.4,2.4,15.9,11.7,21.1c8.4,4.6,12.5,5.9,9.7,16.6c-1.8,7-4.3,13.3-3,20.7
		c1.9,10.6,18.5,15.1,26.2,8.5c9-7.8,13-23.7,8.1-34.7c-1.3-3-4-5.6-5.7-8.3C1825.8,144.7,1832,136.7,1838.1,133.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1367,156.4c1.1-0.7,1.6-1.9,1.4-3.2l-0.1-1.3c-0.3-2.5-2.7-3.3-4.7-2.7c-1.1,0.4-2.1,1.2-2.5,2.3
		c-2.7-8.9-8.4-15.7-20.5-15c-8.2,0.4-12.9,4.9-16.7,11.9c-2.8,5.2-3.5,12.4-7.8,16.7c-4.4,4.3-12.2,4.4-17.7,6
		c-8.3,2.5-15.2,7.1-16.9,16.1c-1.8,9.4,8.3,13,14.4,17.1c8.4,5.6,5.1,14.7,8.2,22.6c3,7.6,15.4,1.4,18.7,5.3
		c7.8,9.6,15.3,6.9,26.3,9.6c4.1,1,4.7,5.6,8.3,7c4.1,1.7,8.4,2,12.7,0.9c4.6-1.1,8.6-3.7,13.3-4.7c7.5-1.8,10.1,8.9,13,13.3
		c4.7,7.1,18.7,4.1,25.6,4.1c4.6,0,9-1.9,12.9-4.3c8.3-5.1,15.2-4.3,24.4-5c10.2-0.9,19-12,24.6-19.4c4.3-5.6,9.7-14.4,7.2-21.9
		c-2.6-7.9-6.1-13.3-2.6-22c2.3-5.7,6.1-10.4,6-16.8c-0.1-8.4-3.6-14.4-9.1-20.6c-2.5-2.8-5-6.9-8-9.1c-4.1-3.2-5.1-10.1-7.5-14.4
		c-5.9-10.9-23.8-5.9-32.2-1.4c-13.8,7.5-16.2,27.3-13.6,41.1c1.3,7.3,8.3,16.9-2.6,22.2c-9.1,4.5-17.8-3.6-23.6-9.1
		c-5.5-5.3-22.5-3.1-24.3-13.2c-0.4-2-2.7-3.9-4.7-2.7c-1.9,1.1-3.8,2.2-5.5,3.5c0-0.9,0-1.8-0.1-2.7c0.8-2.4,1.9-4.7,2.7-7.5
		C1366.1,158.3,1366.7,157.2,1367,156.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1361.4,99.8c1,6.7,0.4,13.5,1.2,20.3c1.4,11.5,14.9,15.2,24.1,12.9c6.9-1.8,13.2-4.3,20.3-5.3
		c4.7-0.8,10.4-1.9,14.2-4.9c8-6.4,10.1-18,9.4-27.9c-1-13.8-9.3-23.4-19.1-32.6c-8.5-8-18-8.1-28.5-4.4c-10.9,4-24.9,8.3-28.9,20.3
		c-1.3,3.8,3.9,6.2,5.9,3.5c-0.2,1.4-0.3,2.9-0.3,4.4C1359.7,90.6,1360.7,95.2,1361.4,99.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M489.3,755.3c20.4,13.4,37.2-10.2,41.7-27.3c5.4-20.7-7.1-42.4-28.2-47.1c-20.9-4.5-37.4,14.4-35.1,34.2
		c0.5,3.2,3.4,5.3,6.5,4.8c1-0.1,1.9-0.5,2.7-1.1C473.5,732.5,475.1,745.8,489.3,755.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M31.1,782.3c3.6,8.3,12.7,12.3,20.1,16.3c9.5,5.2,15.1,6.1,23.7-0.8c8-6.6,13.6-15.8,15.8-26
		c2-9.4-5.9-19.3-11.5-25.8c-4.4-5.1-6.1-12.7-14.3-9.3c-4.6,1.9-8.4,4.4-13.3,5.7s-8.6,3.2-11.9,6.9c-6.2,7.1-5.5,16.5-2.3,24.6
		C33.4,774.9,29,777.2,31.1,782.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M501.9,1303.4c9.9-4,15.8,3.2,21.8,9.7c4.4,4.9,11.7,12,18.2,13.9c9.1,2.8,15.6-5.1,22.9-9.2
		c8.9-5,19.7-2,29.4-3.3c8.9-1,17.3-4.9,23.8-11.1c11.2-10.8,11.8-31.4-6.7-33.3c-9.2-1-16.4,3.2-24.3,7.3
		c-12.8,6.6-34.8,7.6-43.7-5.3c-4.4-6.5-8.5-10-16.5-11.1c-3.7-0.5-8.1-0.8-11.1-3.2c-3.3-2.6-4.7-7.1-6.9-10.6
		c-1.6-2.5-3.8-4.4-6.3-5.8c-3.1-3.8-7.6-3.7-12.7-1.8c-7.1,2.7-11,10.8-11.8,17.9c-0.8,7.4-0.1,13.3-7.3,17.7
		c-2.9,1.5-5.3,3.7-7.3,6.2c-4.2,5.8-3.3,13.8,2.1,18.6c5.5,4.7,14.9,6,21.9,6.5C493,1307,496.6,1305.5,501.9,1303.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M524.6,1238.8c2.8,9.3,11.7,14.4,21,8.4c3.8-2.5,6.6-5.3,10.9-7.1c4.6-2,12.7,1.4,17.1,2.7
		c11.5,3.4,5.6,9.2,10.4,16.6c5.1,8,20.7,8,27.9,2.9c1.5-1.2,2.5-2.9,2.9-4.6c0.7-4.1,2.1-8,4-11.7c3.6-6.7,9.6-13.4,9.5-21.6
		c0.1-6.9-2.4-13.7-6.9-19c-4.4-4.9-14.2-4.5-16.2-11.4c-1.5-5.2-2.1-8.7-7.3-11.5c-5.9-3.1-11.7-0.4-16.3,3.5s-7.7,4.2-13.8,2.9
		c-2.8-0.6-5.8-1.2-8.7-0.6c-7.6,1.6-10.5,5.5-13.3,12.4c-3,7-11.5,9.8-16.3,15.2c-4.4,5-5,11.8-5.1,18.2c0,0.2,0,0.4,0,0.6
		c0,0,0,0,0,0.1c0,0.7,0,1.4,0,2C523.8,1237.6,524.1,1238.3,524.6,1238.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M22.1,697.1c6.4,13.2,32.6,12.2,39.5,0.3c4.6-8,5-19.7,4.4-28.6c-0.6-9.2-5.5-16.2-12.3-22.2
		c-12.3-11-30.3-9.4-31.1,9.3c-0.1,1.6,1,3,2.5,3.5C21.1,671.7,16.5,685.8,22.1,697.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M96.2,687.2c0.6,10.6,13,14.4,13.6,25.5c0.1,1.7,1.5,3.7,3.5,3.5c16.7-2,42.5-18.2,34.3-37.2
		c-4.3-9.9-9-23.7-18.8-29.7c-10.2-6.1-20.1-5.2-26.1,5.3c-2,3.6,2.8,6.7,5.2,4.3C101.7,667.3,95.5,676.2,96.2,687.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M81,624.5c2.9,7.8,13.8,8,20.9,6.1c10.5-2.9,16.8-13.5,19.2-23.3c2.2-8.4,0.5-17.3-4.6-24.3
		c-2.4-3.1-6.3-5-9.7-6.7c-4.4-2.3-6.4-6-10.2-8.9c-7.9-5.9-18.8,1.8-24.2,7.1c-2.3,2.3-4.2,5-5.3,8c-0.3,0.3-0.5,0.7-0.7,1.2
		c-3.2,9.5-10.1,15.3-4.7,25.4c2,3.8,5.7,5.8,9.4,7.4c2.5,1.1,5.5,1.3,8.1,2C84.4,619.5,80,621.9,81,624.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M233.6,598.8c-6-10.1-13.8-10.5-24.5-7.4s-21.2,1-31.5,3.9c-9.2,2.6-16,12.6-18.4,21.4c-1.6,6,9.7,13,5.9,18
		c-4.6,6-5.9,15.2,0.9,20.5c9.2,7,4.6,18.9,13.5,26.1c5.6,4.6,12.2,4.9,19.1,3.6c7.4-1.6,14.4-5.4,21.4-8.5c3.4-1.7,7-2.9,10.7-3.7
		c6.6-1.3,7.3,5.9,8.3,10.7c1.3,6.7,2.8,14.3,9.6,17.5c10.8,5.1,24.6,5.1,36.2,4.3c22.8-1.7,47.6-15.6,62.2-33.3
		c5.6-6.8,4-13.4-0.3-20.7c-6.7-11.8,12.3-17.9,13.4-29.4c0.9-8.5,1.5-17.3-3.7-24.5c-3.6-5-10.1-4.4-14.7-8.6
		c-3.1-3-4.4-6.4-8.5-8.3c-14.7-6.7-41.1-12.8-52.5,3c-5.4,7.2-6.9,16.6-4,25.1c1.3,3.9,4.1,7,6.8,10c3.7,3.9,14.9,9.8,5.3,16.1
		c-12.4,8-27.9,3.2-41.4,3.2c-5.2,0-10.4-0.1-15.6,0.1c-8,0.3-4.6-12.6-2.6-18.1C232.9,613,237.7,605.7,233.6,598.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M222.9,616.7L222.9,616.7c0.1-0.3,0.3-0.6,0.5-0.9c0.3-0.6,0.7-1.2,1-1.8c2.6-4.3,10.9-22-7.9-17.6
		c-5.9,1.4-11.7,3.7-17.8,4.2c-5.7,0.5-11.8-0.9-17.4,0.7c-4.4,1.2-19.4,12.3-14,18.4c3,3.5,8.4,12.3,4.8,16.9
		c-3.2,4.2-7.2,8.9-0.8,13.8c1.6,1.1,3,2.3,4.3,3.7c3,3.9,3.8,6.8,4.3,11.7c2.8,26.4,33.4,4,46.4,0.6c4.7-1.2,11.3-1.8,14.7,2.7
		c5.1,6.6,2.5,18.1,8.2,23.6c9.3,9,39.3,8,49.9,3c11.9-5.7,22.3-12.4,33.4-19.3c5.6-3.6,14.3-11,10.3-18.5
		c-2.1-3.8-3.9-6.8-4.4-11.2c-1.2-10.1,5.8-12.3,11.4-18.7c4.4-5.1,6.9-20.7,1.7-26.4c-1-1-4.4-1.6-5.5-1.9c-2-0.5-4.4-3-6.1-4
		c-5.1-3.4-6.7-9-13.2-11c-6.3-2-12.9-3.3-19.5-4c-8.4-0.9-17.4-0.1-22.1,8.2c-4.1,7.4-4.2,16.3,1.9,22.6
		c4.1,4.4,14.1,10.4,13.6,17.3c-1.5,22.4-40,15.2-53.5,15.2c-4.9,0-19.5,3.1-23.1-1.4c-4.1-4.9-4.4-11.8-3.3-18.1
		c-0.6-0.9-0.7-2-0.3-3C221.1,620,221.9,618.3,222.9,616.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M215.1,562.9c-0.1,9.4,0.6,11.9,11.1,14.8c10.7,3,23.3,1.8,34.2,0.9c8.4-0.7,20.1-2.1,20.9-12.5
		c0.5-6.9-5.3-13-5.4-20.1c0-7.7-2.2-14.4-3.8-22c-2.3-10.1-10.3-12.4-19.9-12.4c-7,0-7.5-5.6-15-5c-7.1,0.5-15.2,2.6-21,7.1
		c-3.3,2.6-4.9,5.3-4.9,9.6c0.1,2.7,0.6,5.2,1.4,7.8c1.3,5.1-0.5,8.7-1.7,13.5C208.8,553.2,209.6,559.8,215.1,562.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M223.9,557.9c4.4,0,3.8,6.9-0.7,6.8c-0.6,0-1.2,0-1.7-0.1c-0.2,2.6-0.2,4.7,3.1,5.5c3.3,0.9,6.6,1.5,10,1.8
		c8.2,0.7,16.3,0.6,24.5-0.2c3.8-0.4,8.2-0.6,11.8-2.1c5.4-2.3,1.8-8.8,0.3-12.4c-3.3-8.3-1.3-17.1-4.6-25.4
		c-1.2-3.1-0.6-10.4-4.6-11.8c-3.5-1.2-7-1.7-10.6-1.6c-3,0-6.1,0.5-8.7-1.4c-5.2-3.8-8.4-4.4-15.2-1.5c-5.7,2.5-10.7,4.6-8.9,11.7
		c0.7,3.1,1.9,6.4,1.3,9.6C218.3,544.8,211.7,557.9,223.9,557.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1763.5,894.7c-10.9,4.3-12.7,12-11.4,22.9c1.3,11-2.5,21.1-1.4,31.8c1,9.6,9.8,17.8,18,21.7
		c5.6,2.6,14.3-7.4,18.7-2.9c5.1,5.4,14,8.3,20.3,2.5c8.4-7.9,19.4-1.5,28-9c5.4-4.8,6.9-11.2,6.6-18.2c-0.3-7.6-3-15.1-4.9-22.4
		c-1.1-3.7-1.7-7.3-1.8-11.1c-0.2-6.7,7.1-6.2,12-6.4c6.9-0.2,14.5-0.4,18.9-6.5c6.8-9.8,9.1-23.4,10.2-35
		c2.2-22.8-7.6-49.4-22.5-66.8c-5.7-6.7-12.6-6.1-20.5-3.2c-12.7,4.7-15.6-15-26.7-18.1c-8.3-2.3-16.9-4.4-24.8-0.4
		c-5.5,2.8-5.9,9.3-10.9,13.1c-3.4,2.6-7.1,3.3-9.6,6.9c-9,13.4-19.4,38.5-5.7,52.3c6.2,6.5,15.2,9.6,24.1,8c4.1-0.6,7.6-2.8,11-5
		c4.4-3,12.2-13.2,16.8-2.6c5.9,13.5-1.6,28.1-3.8,41.4c-0.9,5.1-1.8,10.3-2.5,15.4c-1.1,7.9-13.1,2.5-18.3-0.5
		C1777.5,897.7,1771.1,891.8,1763.5,894.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1779.5,908.2L1779.5,908.2c-0.3-0.2-0.6-0.4-0.8-0.7l-1.6-1.3c-3.8-3.2-19.9-14.3-18.7,4.9
		c0.4,6.1,1.7,12.2,1.2,18.3c-0.5,5.7-2.9,11.5-2.2,17.2c0.5,4.5,8.9,21.2,15.9,16.9c4-2.4,13.4-6.2,17.4-2
		c3.6,3.8,7.6,8.6,13.4,3.1c1.3-1.4,2.8-2.6,4.4-3.6c4.3-2.3,7.3-2.5,12.2-2.2c26.4,1.7,9.4-32.3,8.3-45.7
		c-0.4-4.9,0.2-11.4,5.1-14.1c7.3-4,18.2,0.5,24.6-4.2c10.4-7.6,14.4-37.5,11.2-48.8c-3.7-12.7-8.4-24.1-13.5-36.2
		c-2.6-6.1-8.5-16-16.5-13.2c-4.1,1.4-7.4,2.7-11.7,2.5c-10.2-0.6-11.1-7.8-16.5-14.2c-4.4-5.2-19.3-10.2-25.8-6
		c-1.2,0.8-2.3,4.1-2.8,5.2c-0.8,1.8-3.7,4-4.9,5.4c-4.2,4.6-10,5.1-13.1,11.2c-3,5.9-5.3,12.2-7.1,18.5c-2.3,8.1-3,17.1,4.4,23.1
		c6.6,5.2,15.4,6.7,22.6,1.9c4.9-3.3,12.5-12.3,19.3-10.6c21.9,5.2,8.4,42,6.2,55.2c-0.8,4.9-0.2,19.7-5.1,22.5
		c-5.5,3.2-12.4,2.4-18.4,0.2c-1,0.5-2.1,0.4-3-0.2C1782.5,910.4,1780.9,909.4,1779.5,908.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1725.1,907.1c9.2,1.7,11.9,1.4,16.4-8.5c4.7-10.1,5.6-22.7,6.5-33.6c0.7-8.4,1.3-20.2-8.8-22.6
		c-6.7-1.6-13.6,3.2-20.7,2c-7.6-1.3-14.6-0.2-22.2,0.1c-10.4,0.5-13.9,8-15.5,17.5c-1.2,6.9-6.7,6.5-7.5,13.9
		c-0.7,7.1,0,15.5,3.6,21.9c2,3.7,4.4,5.7,8.6,6.5c2.7,0.3,5.3,0.3,7.9,0c5.2-0.4,8.5,2,13.1,3.9
		C1714.5,911.5,1721.1,911.8,1725.1,907.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1721.7,897.5c0.8-4.4,7.4-2.6,6.6,1.8c-0.1,0.6-0.2,1.1-0.3,1.6c2.5,0.7,4.6,1,6-2.1c1.5-3.1,2.6-6.2,3.5-9.5
		c2-7.9,3.3-16,3.9-24.1c0.2-3.9,0.7-8.2-0.1-12c-1.3-5.7-8.3-3.2-12.2-2.4c-8.7,1.9-17-1.5-25.8,0.3c-3.3,0.7-10.4-1.2-12.4,2.6
		c-1.7,3.2-2.9,6.6-3.4,10.2c-0.5,3-0.6,6.1-2.8,8.4c-4.6,4.4-5.6,7.5-4,14.7c1.5,6.1,2.8,11.3,10,10.8c3.1-0.2,6.6-0.8,9.7,0.3
		C1707.8,900.8,1719.6,909.5,1721.7,897.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M418.6,629.2c11.4-8,20.9-32.9,6.9-42.4c-5.6-3.9-14.3-4-21.2-0.9C371.8,582.2,388.3,650.4,418.6,629.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M432.7,641.4c-3.3-1.4-6.8-2-10.4-1.7c-14.6,0.2-36.8,10-35.4,27.3c0.1,1,0.7,1.9,1.6,2.5
		c1.3,4.3,4.5,6.7,10.6,7.8c4.4,0.8,23.2-6,25.4-1.1c3.6,8.3,6.9,12.2,15.6,14.6c7,2,15.3-4.2,21.3-7.1c7.2-3.7,13.8-8.3,19.8-13.8
		c8.7-7.7,11.7-18,0.5-21.4c4.2-12.8,24.3-23,11.2-37.2c-14.8-16-31.5-2-37.4,13.9c-1.3,3.5-2,7.1-1.9,10.9
		c0.2,2.3,3.2,10.5,1.3,12.2C447.3,655.3,438.9,644,432.7,641.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1574,1398.3c-14.9-10.5-49.9-10.8-55.5,10.8c-2.2,8.7,1.7,19.5,8.5,26.4
		C1537.2,1477.2,1613.7,1425.9,1574,1398.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1582.6,1375.3c-0.3,5,0.8,8.9,2.6,13.5c6.9,18,29,40.8,49.6,31.3c1.2-0.6,2-1.7,2.3-3
		c4.6-3.5,6.2-8.6,4.9-16.6c-1-5.7-17.9-26-12.8-30.8c8.6-8,11.9-14.1,11-25.9c-0.7-9.5-12.1-17-18.4-23
		c-7.8-7.1-16.5-13.3-25.9-18.2c-13.4-7.2-27.5-6.2-26.6,9.1c-17.5,0.7-39.4-19.7-50.8,3c-13,25.5,11.8,39.9,34.1,39.8
		c4.8,0.1,9.7-0.8,14.2-2.7c2.8-1.2,11.5-8.6,14.4-7.1C1593.2,1351.1,1583.1,1366.5,1582.6,1375.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1821.4,2150.6c-3.6-13.4-23.6-31-37.5-21.2c-5.5,4-8.7,12.1-8.1,19.6
		C1761.1,2178.2,1830.8,2186.2,1821.4,2150.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1837.7,2141.5c-2.4,2.6-4.2,5.7-5.1,9.1c-4.8,13.8-3.4,38,13.3,42.7c1,0.2,2,0,2.8-0.6
		c4.4,0.3,7.8-1.9,11.1-7.2c2.3-3.9,2.5-23.9,7.8-24.1c9-0.4,13.8-2.3,19.2-9.6c4.3-5.8,1.4-15.8,0.7-22.4c-1-8-3.1-15.8-6.1-23.3
		c-4.3-10.8-12.9-17.2-19.9-7.8c-10.5-8.3-13.2-30.9-31-23.3c-20.2,8.4-12.9,29,0.2,39.9c2.8,2.5,6,4.4,9.6,5.5
		c2.2,0.7,10.9,0.7,11.9,3C1855.8,2132.6,1842.4,2136.5,1837.7,2141.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M552.8,602.5c0-1.9-1.5-3.5-3.4-3.6c-22-1.5-33.7,11.7-38,32.2c-3.4,16-5.3,45.7,19.1,44.7
		c7.8-0.3,7.5,12.7,9.6,17.8c2,5,4.9,9.6,8.8,13.4c6,5.9,14.4,5.4,22,3.3c10.9-3.2,20.4-9.4,32-9.8c4.4-0.2,8.3,2.2,12.7,2.4
		c4.9,0.2,9.8-4.4,12.9-7.5c2.7-2.8,4-6.8,5.2-10.4c4.4-11.4,13.1-4.9,19.9-2.9c8,2.5,18.3-1,23.5-7.4c5.6-6.8,8.1-15.8,6.7-24.5
		c-1.2-7.7-7.3-12.7-5.6-21.4c1.2-5.4,5.5-9.8,7.4-15.2c5.3-15.8-9.2-31.1-22.8-35.6c-16.5-5.5-30.9,4.9-35.5,20.6
		c-1.4,4.7,0,10.5,1.2,15.1c0.8,3,1.3,6.1,1.4,9.2c0.2,8.6-10.2,8-15.1,7.6c-7.9-0.6-14-0.7-16.8,7.9c-1.7,5.1-0.6,11.4-0.2,16.7
		c0.8,10.7-8.2,11.2-17,9c-7.4-1.9-16.8-6.6-19.9-13.9c-0.3-0.8-0.8-1.6-1.3-2.3c0.1-0.1,0.2-0.3,0.3-0.5c4.7-8.2,9.7-14.5,5.2-23.9
		c-1.2-2.4-4.6-2.7-6.8-3.1c-4.6-0.7-9.2,2.5-7.3-7.7C551.7,609.5,552.5,606.2,552.8,602.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M588.7,519.5c-12.1-2.4-20.9-0.5-27.3,4.8c-5.2,0.3-9.5,3.1-12.7,8c-4.6,7.4-14.6,13.1-14.6,23
		c0,9.6,6,15.2,13.5,20.5c6,4.2,10.5,14,18,15.8c6.6,1.6,14.3-4,20.6-5.8c11.9-3.6,20.2-5.7,27.3-16.7c4.7-7.2,2.8-16.2-1.8-22.8
		c-2.2-3.4-4.9-6.3-8.1-8.7c-5.1-3.4-14-5.6-12.5-13.8C591.6,521.9,590.5,520,588.7,519.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1702.7,1317.4c-1.3-0.5-2.7,0-3.3,1.3c-7,14.2-1.5,25.6,11,34.2c9.7,6.6,29,16.1,35-0.4
		c2-5.2,10.5-1.6,14.5-1.5c3.9,0.1,7.8-0.6,11.4-2.2c5.6-2.4,7.6-8.1,8.2-13.7c0.9-8.1-0.6-16.1,2.3-24c1.2-3,3.8-4.9,5.1-7.8
		c1.5-3.3-0.2-7.7-1.5-10.7c-1.2-2.6-3.5-4.4-5.4-6.3c-6.4-6,0.3-10.1,3.6-14c3.9-4.6,4.4-12.5,1.6-17.7c-3-5.6-8.3-9.7-14.5-11.3
		c-5.4-1.3-10.4,1.4-15.7-2.2c-3.4-2.3-5-6.4-8-9.1c-9-7.9-23.2-2.4-30,5.3c-8.2,9.5-5.2,22,4,29.4c2.8,2.3,7,2.9,10.4,3.5
		c2.2,0.3,4.4,0.9,6.5,1.6c5.7,2.3,2.6,9,0.9,12.2c-2.6,5.1-4.4,9.2,0.7,13.3c3,2.6,7.4,3.6,11.1,4.7c7.3,2.4,5.1,8.6,1.3,13.8
		c-3.3,4.4-9.1,9.4-14.7,9.4c-0.6,0-1.3,0.1-1.9,0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-4.2-5.4-7-10.5-14.5-10.1c-1.9,0.1-3.1,2.3-4,3.7
		c-1.7,2.9-0.9,6.8-7.2,2.8C1707.1,1320,1705.1,1318.6,1702.7,1317.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1657.4,1270.7c-4.8,7.3-6,13.7-4.3,19.6c-1.3,3.6-0.6,7.1,1.9,10.6c3.7,5.1,4.6,13.3,11.3,16.1
		c6.4,2.7,11.9,0.2,17.3-3.4c4.4-2.9,12.3-3.2,15.5-7.6c2.9-4,1.3-10.6,1.8-15.3c0.9-8.9,1.7-14.9-3.7-22.7
		c-3.6-5.1-10-6.3-15.7-5.1c-2.9,0.5-5.5,1.5-8,3.1c-3.7,2.5-7.6,7.8-12.7,4.5C1659.8,1269.3,1658.2,1269.6,1657.4,1270.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M165,1381.4c0-1.4-1.1-2.5-2.4-2.6c-15.8-1.1-24.3,8.4-27.4,23.2c-2.4,11.5-3.9,32.9,13.7,32.2
		c5.6-0.2,5.4,9.1,6.9,12.9c1.4,3.7,3.6,6.9,6.3,9.7c4.4,4.3,10.4,4,15.8,2.4c7.9-2.3,14.6-6.7,23.1-7c3.2-0.1,5.9,1.6,9.2,1.8
		c3.6,0.1,7.1-3.2,9.3-5.4c2-2,2.9-4.8,3.9-7.4c3.2-8.2,9.4-3.6,14.3-2.1c5.8,1.8,13.2-0.7,17-5.3c4.1-4.9,5.8-11.4,4.8-17.7
		c-0.9-5.5-5.2-9.1-4.1-15.3c0.8-4,4-7.1,5.3-11c3.9-11.4-6.6-22.3-16.4-25.6c-12-4-22.2,3.6-25.6,14.8c-1,3.5,0.1,7.5,0.8,10.9
		c0.6,2.2,0.9,4.4,1,6.6c0.1,6.2-7.3,5.7-10.9,5.5c-5.7-0.4-10.1-0.5-12.2,5.7c-1.2,3.8-0.4,8.2-0.1,12.1c0.6,7.7-5.9,8-12.3,6.5
		c-5.3-1.4-12.2-4.7-14.3-10.1c-0.2-0.6-0.6-1.1-1-1.6c0.1-0.1,0.2-0.2,0.2-0.3c3.4-5.9,7-10.5,3.8-17.2c-0.8-1.8-3.4-2-4.9-2.2
		c-3.3-0.5-6.6,1.8-5.3-5.5C164.3,1386.5,164.8,1384.1,165,1381.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M190.9,1321.6c-8.7-1.7-15-0.4-19.7,3.6c-3.8,0.2-6.8,2.2-9.1,5.7c-3.4,5.3-10.5,9.4-10.6,16.6
		c0,6.9,4.4,11,9.8,14.7c4.4,3,7.6,10.1,13,11.4c4.7,1.2,10.4-2.9,14.8-4.3c8.5-2.6,14.5-4.2,19.7-12.1c3.5-5.2,2-11.7-1.3-16.4
		c-1.6-2.5-3.6-4.5-5.8-6.3c-3.7-2.5-10.1-4.1-9-10C192.9,1323.3,192.1,1322,190.9,1321.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M650.7,732c7.7-4.1,8.2-13.2,3.1-19.5c-2.6-3.2-7-5.9-11.2-5.7c-3.6,0.4-7.1,1.3-10.5,2.7c-3,1-5.5,3-7.4,5.4
		c-0.5,0.2-0.9,0.6-1.3,1c-3.4,4-3.2,9.6-3.2,14.5c-0.1,1.9,1.3,3.6,3.2,3.7C633.1,734.3,641.7,736.7,650.7,732z"/>
            <path :fill="colors[`${id}-p-1`]" d="M990.2,196.6c0.4-8.7-7.2-13.8-15.2-12.5c-4.1,0.6-8.7,3.1-10.6,6.7c-1.5,3.4-2.5,6.8-3,10.4
		c-0.6,3.1-0.3,6.2,1,9.1c0,0.5,0,1.1,0.2,1.6c1.8,4.8,6.7,7.5,10.9,10c1.6,1.1,3.7,0.7,4.7-0.9
		C983.3,212.9,989.8,206.7,990.2,196.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M797.5,620.6c-2.5,8.3,2.2,18.5,4.4,26.3c2.5,8-1.8,16.8,2.2,24.1c2.8,5.1,5.5,8.7,11.3,10.2
		c8.7,2.3,16,6.2,25.1,6.7c3.6,0.2,5.9-2.7,9.8-2.3c3.9,0.4,8.1,5,11.5,7.1c6.5,3.9,19.8,1.6,26.3-1.2c17.6-7.2,34.3-11.6,45-28.6
		c2.3-3.8-3.8-6.9-6.1-3.2c-0.6,1-1.2,1.9-1.9,2.7c-1.4-8.7-11-15.2-22-11.8c-4.9,1.6-8.7,4.6-13.3,6.7c-7.9,3.6-9.4-9.3-11.9-13.2
		c-5.1-7.8-21.8-4.5-24.2-12.3c-2.8-8.4,20.1-16.9,24.9-21.3c7.2-6.5-3.4-15.8-6.5-20.9c-12.5-19.8,11.8-23.3,26.2-24.3
		c10.1-0.7,22.7-2.4,28.1-12.3c9.4-17.4-7.5-31.4-21.7-38.9c-8.7-4.6-17.3-5.6-27-6.4c-10.6-0.9-14.6,3.8-19.9,12.1
		c-8.7,13.5-23.9,5.9-36.2,11.6c-8.4,3.9-13.5,16.7-13.9,25.4c-0.2,4.9,0.1,9.1,2.8,13.3c4.4,6.9,9.9,9.7,8,19.3
		C816.1,602.6,801.1,608.4,797.5,620.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M934.3,606.9c2.3,2.9,5.4,4.7,8.5,6.6c2.8,1.7,3.9,1.2,2.1,4.6c-1.6,3-3.1,6-4.2,9.2
		c-3.6,11.3,9.4,21.7,17.2,26.7c6.7,4.3,13.8-3.6,19.5-6.4c8.3-4.1,17.1-7.2,24.7-12.7c5.9-4.3,10.2-10.4,9.9-17.9
		c-0.5-10.7-19.2-21.4-8.4-29.9c28.3-22.2-11.8-69.7-40.3-51.4c-6.8,4.4-7.2,13.4-12.6,19.1c-6.2,6.5-16.1,7.9-17.5,18.5
		c-0.9,6.9-0.5,14.2,2.9,20.4C932.1,596.9,930.3,601.9,934.3,606.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1497.4,1088.3c-8.7,0.1-17,7.5-23.7,12.1c-6.9,4.7-16.6,3.4-22.3,9.2c-4.2,4.2-6.6,7.9-6.4,13.8
		c0.3,9-1.2,17.1,1,26c0.9,3.5,4.4,4.8,5.1,8.7c0.8,3.8-2.5,9.3-3.4,13.1c-1.8,7.3,4.4,19.4,8.8,24.8c12.2,14.6,21.2,29.4,40.6,34.5
		c4.3,1.2,5.4-5.6,1.2-6.7c-1.1-0.3-2.2-0.6-3.2-1c7.9-3.9,11.3-14.9,4.7-24.4c-3-4.3-6.9-6.9-10.4-10.8c-5.7-6.5,6.1-11.8,9-15.2
		c6-7.2-2.1-22.1,4.5-26.8c7.2-5.1,22.1,14.1,27.7,17.5c8.4,4.9,14.1-7.9,18-12.4c15.2-17.7,25.8,4.4,31,17.8
		c3.7,9.5,9,21,20.1,23.1c19.4,3.9,27.8-16.4,30.8-32.2c1.8-9.7,0.2-18.2-1.9-27.6c-2.3-10.4-7.9-12.9-17.4-15.4
		c-15.5-4.3-12.8-21.2-21.8-31.1c-6.2-6.9-20-8-28.4-5.8c-4.8,1.3-8.6,2.8-11.9,6.6c-5.3,6.2-6.3,12.3-16.1,13.3
		C1520.1,1100.8,1510.1,1088.1,1497.4,1088.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1551,1214.9c-2.1,3.1-3,6.6-3.8,10.1c-0.8,3.2,0,4-3.8,3.4c-3.3-0.7-6.6-1.1-10-1.3
		c-11.8-0.1-17.9,15.3-20.4,24.4c-2.1,7.7,7.5,12.1,11.9,16.7c6.3,6.8,12,14.2,19.4,19.9c5.8,4.4,12.9,6.7,20,4.2
		c10.1-3.6,14.7-24.6,26.1-16.8c29.6,20.5,63.1-31.8,37.2-53.8c-6.2-5.2-14.9-2.9-22-6.3c-8.1-4.1-12.4-13.1-22.8-11.3
		c-6.9,1.2-13.7,3.8-18.7,8.7C1559.9,1209.9,1554.6,1209.7,1551,1214.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M20.2,506.8c4-3.4,4.4-7.4,5.1-12.2c1.4-8.9,3.5-15.5-5.8-20.7c-6.9-3.8-9.6-12.4-16.8-15.7
		c-2.3-1.1-5.1-1.2-7.5-2.5c-1.2-0.7-2.8-5.1-3.7-6.4c-0.7-1-1.9-1.6-3.1-1.4c-5.1,0.8-6.3,1.7-6.3-4.4c0-2.7-3-5.9-4.2-8.4
		c-2-3.9-7.6-7.6-12.1-7.2c-7.3,0.7-21.4,6.3-27.6,0.8c-4.7-4.3-8.8-8.5-14.9-8.5c-1.8-4.1-4.4-6.4-9.6-5.8
		c-4.2,0.5-3.7,0.4-6.7-2.9c-1.5-1.6-3.8-4-6.2-3.6c-8.9,1.3-9.5,7.4-16.1,12c-6.9,4.7-16,6.3-22.1,12.4c-5.2,5.2-5.6,10.6-4.7,17.7
		c0.4,3.1,2.8,5.7,4.7,7.9c4.8,5.7-0.1,8.2,0.8,13.8c1,6,6.1,13.4,13,13.7c2,0.1,3.9-0.1,5.7-0.8c0.5,0.2,1.1,0.3,1.7,0.4h2.6
		c1.2-0.1,2.3-0.9,2.8-2l-0.3-0.6c2-0.3,3.3-2.2,3-4.1c-0.1-0.5-0.2-0.9-0.5-1.3c0.8-1.1,1.6-2.2,2.4-3.4c0.1-0.2,0.2-0.4,0.3-0.5
		c3.6-1,7.8-1.7,11.3-2.3v3.5c0.2,1.5,1.3,2.7,2.8,3c17.6,2.5,42.3-0.6,42.3,24.5c0.1,1.2,0.9,2.3,2,2.8c10.4,5.1,6.8,16.1-1.3,22
		c-0.7,0.5-15.6,9.7-16.7,6.2c-0.8-2-2.4-3.5-4.4-4.3c-4.8-2.3-10,2.7-13.3-5.3c-0.7-2.1-2.3-3.9-4.3-4.7c-3.9-2.2-8.8-2.8-13.1-2.8
		c-9.1-0.1-14.4-8.9-15.1-17c-0.2-1.5-1.3-2.8-2.8-3.1c-7.6-2.4-12.5-1.3-15.4,6.4c-2.7,7-0.3,11.7-1.5,18.7
		c-1,5.9-2.9,9.1-1.2,15.2c3.2,12.1,7.7,25.5,17.8,33.4c1.5,1.1,3.2,1.7,4.9,1.9c6.2,0.4,9.4,3.7,13.1,8.3c2.6,3.3,9.8,3,11.2,6
		c0.4,1.1,1.4,2,2.6,2.2c6.3,0.5,12.4,2.4,18.8,2.7c8.9,0.5,17.7,7,26.6,5.9c3.8-0.5,10.6-4.2,12.9-2.7s5.7,0.5,8-0.4
		c8.6-3,15.9-11.3,22.1-17.6c7.4-7.5,13.7-16,18.9-25.2c4-7.3,1.6-18.2-1.9-25.4c-0.7-1.4-1.6-2.6-2.7-3.6
		C10,516.1,15.9,510.4,20.2,506.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-14,335.2c6.1,1.4,10.4,6.3,16.6,7.7c15.2,3.3,18.5-14.5,29.8-19.9c9.7-4.6,3.2-19.9,0.3-26.8
		c-1.2-3.1-2.5-9.4-6.4-10.6c-1.6-0.4-3.2-0.6-4.7-0.4c-4.2,0.2-9-4.7-12.5-6.6c-9.5-5.2-23.2-2.4-31.4,4c-3.7,2.8-6.4,6.6-7.1,10.4
		c-11.2,10.3-15.8,34.7-1.9,39.8C-26,334.6-19.9,333.9-14,335.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-3.8,421.6c8.7,2.1,14.6,8.5,23.2,2.5c4.4-3.1,6.4-9.5,8.9-14c2.8-5,6.9-9.5,9-14.9c4.1-10.5-1-18.6-9.8-24.8
		c-14.3-10-35.1-8.7-45.4,4.9c-1.8,1.4-3.5,2.9-5.1,4.5c-10.3,10.1-0.5,16.2,3.7,26.4C-15.7,414.9-13.4,419.2-3.8,421.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M154.9,1935.2c16.3,6.6,28.6-3.4,32.2-19.8c6.5-30.4-49.8-42.4-49.9-13.3c-2.1,5.1-2.5,10.8-1.2,16.1
		C138.2,1927.4,146.7,1931.9,154.9,1935.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M477.8,2014.1c10.3-2.2,35.6-13.5,32.9-27.4c-1.1-5.5-6.3-12.2-8.9-17.2c-3.5-6.9-8.7-9-14.6-13
		c-1-0.7-2.4-0.8-3.5-0.3c-11.6,5.9-23.9,1.3-31.2,10.9c-1.2-1.4-3.3-1.6-4.6-0.4c-0.2,0.2-0.4,0.4-0.6,0.7c-0.4,0.6-0.7,1.1-1,1.6
		c-3.6,1.9-5,7.8-5.6,11.3c-1.2,6.8,0,13.7,3.5,19.8C450.8,2011.9,465.2,2016.8,477.8,2014.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M405.5,1940.2c5.8-0.4,12.6-2.5,18,0.7c2,1.2,3.8,4.2,6.1,4.4c6.1,0.7,14.1-3.1,19-6.4
		c4.2-2.9,11.6-9.1,9.9-15.1s-8.5-9.4-10.1-15c-1.8-6.7-0.4-16.5-6.7-21.2c-5.3-4-11.5-4.6-17.9-4.1c-11.1,1.1-23.2,9.9-17.7,21.1
		c-1,1.9-1.7,4.1-1.9,6.2c-0.5,2.7-0.1,5.4,1.3,7.8c2.5,5.6-3.3,12.5-4.1,18C401.2,1938.8,403.4,1940.4,405.5,1940.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M984.4,2048.7c5.5-9.7,6.9-18.6,7.1-29.4c2.6-2.3,6.5-3.8,9-5.7c2.7-2.3,3-7.8,3.5-10.9
		c0.8-5.8,0.5-11.7,1.3-17.5c0.6-3.9,1-8.5-0.5-12.3c-7-18.1-35.6-22.7-49.4-11c-7.7,6.5-12.4,20.3-14.3,29.8c-1,4.6,0,9.1,1.5,13.5
		c1.7,5.1,7,7.2,9.2,12.1c6.6,15.1-2.3,30.2-6.4,44.6c-4.3,14.9,1,30.9-1.5,46.1c-1.6,9.6,4.6,21.2,10,28.6
		c7.6,10.4,19.9,10.6,31,14.6c12,4.4,22.2,7.6,35.1,9.2c10.4,1.3,19.1,10.4,30,7.1c21.6-6.4,41.8-20.3,51-41.2
		c5.1-11.8,3.7-24.3,3.7-36.9c0-9.1,0.8-16.3,3.9-24.8c2.1-5.9,1.2-9.2-1.7-14.4c-2.1-4-4.4-7.5-6.3-11.5s-1.9-10.3-2.7-14.8
		c-1-5.9-5.3-9.4-9.9-12.9c-9.6-7.4-9.8-14.7-12.1-25.7c-1.4-7-6.2-12.9-11.1-17.8c-9.4-9.6-25-14.4-35.8-4.7
		c-10.1,9-10.7,31.7-6,43.4c3.2,7.8,11.3,13.5,16.3,20c9.1,11.4,17.6,23.7,19.7,38.5c0.7,4.9-1.8,10.5-2.4,15.5
		c-0.6,4.9,4.9,9.4-0.2,14.2c-8.2,7.8-25,1.5-33.6-0.8c-8-2.1-14.8-1.8-22.4,1.5c-5.1,2.3-8,4.4-12.6-0.9c-3.4-3.9-5.5-9.4-7.7-14
		c-2.7-5.4-5.6-13.3-3.8-19.5C977.6,2056.1,982.2,2052.5,984.4,2048.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1126.4,2087.9c1.6,5.2,4.1,10,6,15c1.7,4.4,5.9,3,4.4,9.6c-2.7,12.6,9.9,7.7,13.8,15.2
		c4.4,8.3,9.2,13.1,19.3,13.1c6.9,0,7.9,2.3,13.5,5.5c4.4,2.6,9.3,3,14.4,3.4c8.4,0.8,17-0.7,24.6-4.3c13.4-6.6,17.7-26.2,8-37.6
		c-4.7-5.6-13.1-7.4-19.8-9.6c-5.1-1.5-10-4-14.3-7.1c-7.4-5.6-5.9-15.2-6-23.3c-0.2-10.9-4.8-15.3-11.9-22.8
		c-4.9-5.2-7.2-12.1-7.8-19.1c-0.4-4.3,0.5-8.7-1.7-12.7c-3.1-5.5-12.1-12.4-19-10.2c-2.5,0.8-4.4,2.5-5.6,4.6
		c-5.7,3.6-10,8.6-13.6,14.6c-6.6,10.9,1.8,24.6,1.2,36.2C1131.3,2069.1,1123,2076.6,1126.4,2087.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1267,1981.2c-0.4-7.8-5.1-12.9-11.9-16.2c-15.8-8-31.6-16.8-49.7-17.4c-5.2-0.3-10.4,0.8-15,3.2
		c-1.4-1.4-3.7-1.3-5,0.2c-0.2,0.2-0.3,0.4-0.5,0.6c-5.9,9.4-13.1,18.1-16.1,28.9c-3.1,10.8,3.7,18.6,11.2,25.4
		c9.5,8.6,19.9,15.9,29.1,25c7.6,7.5,18.1,11.4,25.5,18.9c8.7,8.8,4.6,24.7,8.4,35.3c3.2,8.9,10,17,19.4,19.4
		c16.6,4.2,29.9-15.6,34.7-28.5c3.4-8.7,2.6-18.3-0.3-27c-3.5-9.9-8.2-19.4-14-28.1c-3.7-5.3-8.5-9.6-12.7-14.5
		C1264.4,1999.4,1267.4,1989.3,1267,1981.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M525.8,2045.6c1.1,3.6,2.8,6.9,4.2,10.4c1.2,3.1,4.1,2,3.1,6.5c-1.9,8.6,6.7,5.3,9.5,10.5
		c3.1,5.7,6.2,8.9,13.3,9c4.7,0,5.4,1.5,9.3,3.8c3.1,1.8,6.3,2.1,9.9,2.3c5.8,0.5,11.7-0.5,16.9-3c9.2-4.5,12.2-18,5.4-25.8
		c-3.3-3.9-9-5.1-13.5-6.6c-3.6-1.1-6.8-2.7-9.8-4.8c-5.1-3.9-4.1-10.5-4.2-16.1c-0.1-7.4-3.3-10.6-8.2-15.7
		c-3.3-3.7-5.1-8.2-5.3-13.2c-0.3-3,0.3-6-1.2-8.7c-2.1-3.9-8.3-8.5-13.1-7c-1.7,0.5-3.1,1.7-3.9,3.3c-3.9,2.6-7.1,6-9.4,10.1
		c-4.5,7.4,1.3,16.9,0.8,24.8C529.2,2032.6,523.5,2037.9,525.8,2045.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M622.4,1972.4c-0.3-5.4-3.6-8.9-8.1-11.2c-10.9-5.4-21.8-11.5-34.1-12c-3.6-0.2-7.1,0.5-10.4,2.2
		c-1-0.9-2.5-0.9-3.5,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-4.1,6.4-8.9,12.4-11.1,19.8c-2.2,7.4,2.5,12.8,7.6,17.4
		c6.5,5.9,13.6,11,20,17.1c5.2,5.1,12.4,7.8,17.5,13c5.9,6,3.2,16.9,5.7,24.2c2.2,6.1,6.8,11.7,13.3,13.3
		c11.4,2.9,20.5-10.8,23.8-19.6c2.3-5.9,1.8-12.6-0.2-18.5c-2.4-6.8-5.6-13.3-9.6-19.3c-2.5-3.7-5.8-6.5-8.7-10
		C620.5,1984.8,622.7,1977.9,622.4,1972.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M470,1109.3c1.4,4.6,3.6,8.7,5.2,13.2c1.5,4,5.1,2.6,4,8.4c-2.3,11,8.6,6.8,12.2,13.3c4,7.3,8,11.4,16.9,11.5
		c6,0,6.9,2,11.9,4.8c4,2.3,8.1,2.6,12.7,3c7.4,0.7,14.8-0.7,21.6-3.8c11.8-5.7,15.5-22.9,7-32.9c-4.2-4.9-11.5-6.5-17.3-8.4
		c-4.5-1.3-8.7-3.5-12.6-6.2c-6.5-4.9-5.1-13.3-5.2-20.5c-0.1-9.5-4.3-13.4-10.5-20.1c-4.4-4.6-6.3-10.6-6.8-16.7
		c-0.3-3.8,0.4-7.6-1.5-11.1c-2.7-4.8-10.6-10.8-16.6-9c-2.1,0.7-3.9,2.2-4.9,4.2c-5,3.2-8.7,7.5-12,12.9c-5.7,9.5,1.6,21.6,1,31.7
		C474.4,1092.8,467,1099.4,470,1109.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M593.2,1015.9c-0.4-6.9-4.5-11.3-10.4-14.2c-13.8-7-27.8-14.7-43.6-15.3c-4.5-0.3-9.1,0.7-13.2,2.8
		c-1.3-1.2-3.2-1.1-4.4,0.1c-0.2,0.2-0.3,0.4-0.4,0.6c-5.1,8.2-11.4,15.8-14.1,25.3c-2.8,9.5,3.2,16.3,9.8,22.2
		c8.3,7.6,17.4,13.9,25.5,21.9c6.7,6.5,15.8,10,22.3,16.6c7.6,7.7,4.1,21.7,7.4,31c2.8,7.8,8.7,14.9,17,17
		c14.5,3.7,26.1-13.7,30.5-24.9c3-7.6,2.3-16.1-0.2-23.6c-3.1-8.7-7.2-16.9-12.3-24.6c-3.2-4.6-7.5-8.4-11.1-12.7
		C590.9,1031.7,593.7,1022.9,593.2,1015.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1547.9,928c-4.2,3.9-7.5,8.3-11.4,12.5c-3.5,3.7-0.1,6.9-6.9,8.5c-12.9,3.2-2.8,12.8-8,19.9
		c-5.7,7.9-8,14.4-3.6,23.9c3.1,6.4,1.5,8.4,1,15.1c-0.4,5.3,1.4,10,3.5,14.9c3.1,8.2,8.3,15.5,15.1,21.2
		c12.3,9.6,32.4,4.6,38.8-9.6c3.1-7,1.1-15.6,0-22.8c-0.9-5.5-0.9-11.1,0.1-16.6c1.9-9.5,11.6-12.5,19.2-16.2
		c10.1-5,12.2-11.5,16-21.5c2.7-7,8-12.2,14.3-15.9c3.9-2.3,8.4-3.6,11.1-7.3c3.8-5.4,6-16.8,1-22.3c-1.8-1.9-4.3-3.1-6.9-3.2
		c-5.9-3.8-12.5-5.4-19.9-6.1c-13.2-1.3-22.2,12.9-33.3,17.4C1567.6,924,1556.9,919.6,1547.9,928z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1711.2,1011.1c7.1-4,9.7-10.7,9.9-18.4c0.3-18.4,1.4-37.3-6.2-54.4c-2.1-5-5.4-9.4-9.8-12.6
		c0.6-2-0.5-4-2.4-4.5c-0.3-0.1-0.5-0.1-0.8-0.2c-11.5-1.3-22.7-4-34.3-2c-11.5,2-15.7,11.9-18.7,21.9c-3.8,12.8-5.9,25.8-10.2,38.5
		c-3.6,10.6-2.5,22.1-6.1,32.4c-4.3,12.1-21,15.4-29.3,23.8c-6.9,7-11.5,17-9.4,26.9c3.6,17.4,28.1,20.8,42.3,19.6
		c9.6-0.8,18.3-5.8,25.1-12.5c7.7-7.7,14.3-16.4,19.9-25.8c3.4-5.8,5-12.3,7.8-18.4C1693,1016.8,1703.8,1015.2,1711.2,1011.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1258.3,1846.2c-0.9-6.1-8-9.6-13.9-12.5c-1.7-2.1-4.3-3.3-6.9-3.3c-0.7-0.1-1.3,0-1.9,0.3
		c-10.1,2.6-20.5,21.7-22.6,28.4c-4.8,14.9-8,47.2,14.5,50.6c9.4,1.5,12,12.6,19.7,17.6c8,5.2,20.1,4.4,29.1,3.7
		c20.8-1.8,32.4-23.5,34.7-42.2c1.8-14.9-7.2-31.1-21.5-37c-16.7-6.8-19.6,15.9-17.7,26.6c0.2,1.1,2.7,9.7,4.5,8.9
		c-3.9,1.5-7.7,3-11.6,4.2c-4.4,1.3-9.2-3.1-12.4-5.5c-6.9-5.1-12.2-11.6-4.5-19.2C1253.8,1860.7,1259.7,1855.5,1258.3,1846.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1354.9,2000.3c5.4-6.5,11.4-19.1,12.6-27.5c0.7-4.6-2.9-8.4-5.7-11.3c-6.4-6.4-8-12.8-12.7-20.1
		c-5.3-8.6-23-20-33.2-12.9c-8.5,5.9-12.5,14.9-16.8,23.8c-6.7,2.1-11.8,6.6-11.4,16c0.2,5.4,2.2,9.4,5.8,13.4
		c5.9,6.6,9,9.3,8.6,18.6C1301.1,2030.1,1343.9,2013.6,1354.9,2000.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M702.4,1929.7c-6-6.8-16.1-6.9-24.3-5.2c-11.2,2.2-18.8,16.2-23,25.6c-2.4,4.9-3.6,10.4-3.7,15.9
		c-0.1,9.9-8.3,14.6-10.1,23.5c-0.5,1.9,0.6,3.8,2.5,4.3c0.3,0.1,0.6,0.1,0.8,0.1h0.2c1.9,7.8,6.7,14.6,13.5,18.9
		c9,5.9,23.2,0.3,33.3,2.5c11.1,2.4,14.5,15.6,25.3,18.9c5.1,1.6,8.7,0.2,13.4-1.5c3.6-1.3,10.6-1.2,13.4-3.4
		c3.7-2.8,6.8-6.1,9.3-10c7.6-12.1,16.5-5.9,25.5-2.2c9.2,3.9,18,5.8,27.5,2c13.1-5.3,25.8-30.8,14.3-42.8
		c-10.6-11.1-30.3-6.3-42.5-1.1c-13.4,5.8-32.9,18-43,0.4c-5.9-10.4-13.8-14.7-23.1-22.1c-5.5-4.4-8.3-11.7-8.1-18.6
		C703.6,1932.9,703.8,1931.2,702.4,1929.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M728.5,1933.8c2.1,4.6,7.6,7.2,15.3,4.6c3.7-1.3,6.9-3.7,10.1-5.7c6.8-4.7,13.8-3.8,21,0.2
		c8.9,4.9,5.3,19.2,13.3,23c10.6,5.1,29.9-5.4,33.5-16.1c2.8-8,0-16.9,3.2-25.2c3.8-9.9,7.5-16.2,5.7-27.4
		c-2.3-14.6-16.2-26.3-31.3-20.6c-10.6,4.1-16.5,0.2-24.7-5c-2.2-1.4-6.2-1.3-8.7-0.8c-8,1.7-15.8,6.9-18.5,15.1
		c-4.2,12.5-8.9,20.7-20.9,27c-5.8,3.1-8.8,8.4-9.5,14.8C716.3,1925.3,720.8,1938.7,728.5,1933.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M875.3,1976.1c6.1,1.4,10.4,6.3,16.6,7.7c15.2,3.3,18.6-14.5,29.8-19.9c9.7-4.6,3.2-19.9,0.3-26.8
		c-1.2-3.1-2.5-9.4-6.4-10.6c-1.6-0.4-3.2-0.6-4.7-0.4c-4.2,0.2-9-4.7-12.5-6.6c-9.5-5.2-23.2-2.3-31.4,4c-3.7,2.8-6.4,6.5-7.1,10.4
		c-11.2,10.3-15.8,34.7-1.9,39.8C863.5,1975.6,869.6,1974.8,875.3,1976.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M725.8,1830.5c15.3,18.1,46.8,7.5,58.4-9.6c29.4-42.9-64.4-73.6-66.4-25.6c-0.1,0.9,0.3,1.9,1,2.5
		C715.1,1808.3,717.7,1821,725.8,1830.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M735.9,1788.1c-14.3,7.9-16.3,28.2-3.8,39.1c13.3,11.5,35.1,4.2,45.2-8.5c23.8-30.1-33.6-53.1-48.9-33.8
		c1.3-1,2.7-1.9,4.1-2.8C736.4,1780,739.8,1785.9,735.9,1788.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1056.9,1851.8c1.1-0.7,1.6-1.9,1.4-3.2l-0.1-1.3c-0.3-2.5-2.7-3.3-4.7-2.7c-1.1,0.4-2.1,1.2-2.5,2.3
		c-2.7-8.9-8.4-15.7-20.5-15c-8.2,0.4-12.9,4.9-16.7,11.9c-2.8,5.2-3.5,12.4-7.8,16.7c-4.4,4.3-12.2,4.4-17.7,6
		c-8.3,2.5-15.2,7.1-16.9,16.1c-1.8,9.4,8.3,13,14.4,17.1c8.3,5.6,5.1,14.7,8.2,22.6c3,7.6,15.4,1.4,18.7,5.3
		c7.8,9.6,15.3,6.9,26.3,9.6c4.1,1,4.7,5.6,8.3,7c4.1,1.7,8.4,2,12.7,0.9c4.6-1.1,8.6-3.7,13.3-4.7c7.5-1.8,10.1,8.9,13,13.3
		c4.6,7.1,18.7,4.1,25.6,4.1c4.6,0,9-1.9,12.9-4.3c8.3-5.1,15.2-4.3,24.4-5c10.2-0.9,19-12,24.6-19.4c4.3-5.6,9.7-14.4,7.2-21.9
		c-2.6-7.9-6.1-13.4-2.7-22c2.3-5.7,6.1-10.4,6.1-16.8c-0.1-8.4-3.6-14.4-9.1-20.6c-2.5-2.8-5-6.9-8-9.1c-4.1-3.2-5.1-10.1-7.5-14.4
		c-5.9-10.9-23.8-5.9-32.2-1.4c-13.8,7.5-16.2,27.3-13.6,41.1c1.3,7.3,8.3,16.9-2.6,22.3c-9.1,4.4-17.8-3.6-23.6-9.1
		c-5.5-5.3-22.5-3.1-24.3-13.2c-0.3-2-2.7-3.9-4.7-2.7c-1.9,1.1-3.8,2.3-5.5,3.6c0-0.9,0-1.8-0.1-2.7c0.8-2.4,2-4.7,2.7-7.5
		C1056,1853.8,1056.6,1852.6,1056.9,1851.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1051.3,1795.3c1,6.7,0.4,13.5,1.2,20.3c1.4,11.5,15,15.2,24.1,12.9c6.9-1.8,13.3-4.2,20.3-5.3
		c4.7-0.8,10.4-1.9,14.1-4.9c8-6.4,10.1-18,9.4-27.8c-1-13.8-9.3-23.4-19.1-32.6c-8.5-8-18-8.1-28.5-4.4c-11,4-24.9,8.3-28.9,20.3
		c-1.3,3.9,3.9,6.2,5.9,3.5c-0.2,1.4-0.3,2.9-0.3,4.4C1049.6,1786,1050.6,1790.6,1051.3,1795.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M378,1865.8c5.3-15.8-9.2-31.1-22.8-35.7c-16.5-5.5-30.9,4.9-35.5,20.6c-1.4,4.7,0.1,10.5,1.2,15.1
		c0.8,3,1.3,6.1,1.4,9.2c0.2,8.6-10.2,8-15.1,7.6c-7.9-0.6-14-0.7-16.8,7.9c-1.7,5.1-0.6,11.4-0.2,16.7c0.8,10.7-8.2,11.2-17,9
		c-7.4-1.9-16.8-6.6-19.9-13.9c-0.3-0.8-0.8-1.6-1.3-2.3c0.1-0.2,0.2-0.3,0.3-0.5c4.7-8.2,9.7-14.5,5.2-23.9
		c-1.2-2.4-4.6-2.8-6.8-3.1c-4.6-0.7-9.2,2.5-7.3-7.7c0.7-3.6,1.5-6.9,1.8-10.6c0-1.9-1.5-3.5-3.4-3.6c-22-1.5-33.7,11.7-38,32.2
		c-3.4,16-5.3,45.7,19.1,44.7c7.8-0.3,7.5,12.7,9.6,17.8c2,5,4.9,9.6,8.8,13.4c6,5.9,14.4,5.5,22,3.3c10.9-3.2,20.4-9.4,32-9.8
		c4.4-0.2,8.3,2.2,12.7,2.4c4.9,0.2,9.8-4.4,12.9-7.5c2.7-2.8,4-6.8,5.2-10.4c4.4-11.4,13.1-4.9,19.9-2.9c8,2.5,18.3-1,23.5-7.4
		c5.6-6.8,8.1-15.8,6.7-24.5c-1.2-7.7-7.3-12.7-5.6-21.4C371.7,1875.6,376.2,1871.1,378,1865.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M281,1771.5c-12.1-2.4-20.9-0.5-27.3,4.8c-5.2,0.3-9.5,3.1-12.7,8c-4.6,7.4-14.6,13.1-14.6,23
		c0,9.6,6,15.2,13.5,20.5c6,4.2,10.5,14,18,15.8c6.6,1.6,14.3-4,20.6-5.8c11.9-3.7,20.2-5.7,27.3-16.7c4.7-7.2,2.8-16.2-1.8-22.8
		c-2.2-3.4-4.9-6.3-8.1-8.7c-5.1-3.4-14-5.6-12.5-13.8C283.9,1773.9,282.8,1772,281,1771.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M481,1867.4c-2.5,8.3,2.2,18.5,4.5,26.3c2.5,8-1.8,16.8,2.2,24.1c2.8,5.1,5.5,8.7,11.3,10.2
		c8.7,2.3,16,6.2,25.1,6.7c3.6,0.2,5.9-2.7,9.8-2.3c3.9,0.4,8.1,5,11.5,7.1c6.5,3.9,19.8,1.6,26.4-1.2c17.6-7.2,34.3-11.6,45-28.6
		c2.3-3.8-3.8-6.9-6.1-3.2c-0.6,1-1.2,1.9-1.9,2.7c-1.4-8.7-11-15.2-22-11.8c-4.9,1.6-8.7,4.6-13.3,6.7c-7.9,3.6-9.4-9.3-11.9-13.1
		c-5.1-7.9-21.8-4.5-24.2-12.3c-2.8-8.4,20.1-16.9,24.9-21.3c7.2-6.5-3.4-15.8-6.5-20.9c-12.5-19.8,11.8-23.3,26.2-24.3
		c10.1-0.7,22.7-2.4,28.1-12.3c9.4-17.4-7.5-31.4-21.7-38.9c-8.7-4.5-17.2-5.6-27-6.4c-10.6-0.9-14.5,3.8-19.9,12.1
		c-8.7,13.5-23.9,5.9-36.2,11.6c-8.4,3.9-13.5,16.7-13.9,25.4c-0.2,4.9,0.1,9.1,2.8,13.3c4.4,6.9,9.9,9.7,8,19.3
		C499.7,1849.5,484.5,1855.2,481,1867.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M617.7,1853.6c2.3,2.9,5.4,4.7,8.5,6.6c2.8,1.7,3.9,1.2,2.1,4.6c-1.6,3-3.1,6-4.2,9.2
		c-3.6,11.2,9.4,21.6,17.2,26.6c6.7,4.3,13.7-3.6,19.5-6.3c8.3-4.1,17.1-7.2,24.7-12.7c5.9-4.4,10.2-10.4,9.9-17.9
		c-0.5-10.7-19.1-21.4-8.4-29.9c28.3-22.2-11.8-69.7-40.3-51.4c-6.8,4.4-7.2,13.4-12.6,19.1c-6.2,6.5-16.1,7.9-17.5,18.5
		c-0.9,6.9-0.4,14.2,2.9,20.4C615.6,1843.6,613.9,1848.7,617.7,1853.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2227.5,714.6c3.9,2.6,8.6,0.7,13.1-1.5c1.1-0.5,1.9-1.6,2-2.8c0-6.7-2-10.8-4.5-16.8c-1-2.4-1.6-5.2-3.8-6.9
		s-5.2-2.5-7.4-4.6c-3.4-3.3-3.4-8.8-6.9-12.1c-3.6-3.3-8.9-2.9-13.4-2.3c-1.5,0.3-2.7,1.5-2.9,3c-0.1,3-0.4,6-0.5,8.9
		C2192,690.4,2208,728.4,2227.5,714.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1858.7,1094.4c-2.5-7.8-10.2-10.6-17.7-10.5c-13.5,0.2-14.1-18.3-27.8-17c-7.8,0.8-21.7-1.5-28.6,3.8
		c-2.3,1.7-4.2,4.4-6.2,6.3c-3.5,3-8.9,2.3-13,2c-3.2-0.3-6.3,0.8-8.7,3c-8.6,7-9.4,20.6-15.3,29.6c-6.9,10.5-12.6,19.3-9.2,32.3
		c1.6,6.1,4.1,12,3.7,18.4c-0.3,4.6-1.2,9-1,13.6c0.3,7.8,11.5,7.1,16.8,6.6c18.4-2,37.8-12.4,37.1-33c-0.3-10.4-0.5-19.2,3.5-29.1
		c5.8-14.6,20.5-9.3,27.7,0.1c4.8,6.2,4.2,21.4,0.1,27.9c-4.3,6.7-11.5,6.2-16.1,11.5c-9.2,10.6-28.5,29.9-17.2,41.7
		c-0.6,0-1.3,0-1.9-0.1c-4.4-0.5-5.3,6.3-0.9,6.8c5.3,0.6,8.4-0.2,12.7,3.8c2.8,2.6,4.9,3.2,8.6,3c11.8-0.7,8.3-20.2,21.7-19.4
		c13.1,0.8,13.8-7.5,23.3-15c7.4-5.9,18.2-12.2,20.3-22.3c1.2-5.7,0-10-1.4-15.5c-1.4-5.6-7.3-8.8-8.7-14.5s1.5-10,2.1-15.5
		C1863.1,1106.9,1860.4,1099.8,1858.7,1094.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1931.5,44.1c-4-3-10.8-3.6-14.6-0.4c-2.7,2.2-2.5,18.6-5,17.9c-5.9-1.7-9.7-2.5-14.9,1.7
		c-5.2,4.3-6,12.7-6.2,18.7c0,1.5,1.1,2.8,2.7,2.8h0.1c-2.7,5.4,4.8,15.6,10.5,18.4c3.4,1.7,6.4,3,9,5.8c3.3,3.7,1.3,11.5,1.2,15.9
		c-0.1,3.5,1.5,12.7,6,13.6c13.9,3,24.6-13.9,22.3-26.1c-0.4-2.3-1.3-4.6-1.9-6.9c-1.6-5.7,4.9-7.3,9-5.1c2.6,1.4,5.3,3.7,8.2,4.2
		c6.4,1,13.1,0.4,17.4-4.7c8-9.5-13.4-24.7-20-27.4c-2.9-1.2-6.6-1-9.7-1.4c-8.3-0.9-9.5-5.8-8.9-12.7
		C1937.3,52.6,1936.5,47.8,1931.5,44.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1588.3,238.5c3.5-1.2,8-1.7,11.1-4c6.5-5.1,11.9-11.7,10.9-20.4c-1.7-15.8-34.6-9.3-42.8-5.5
		c-3.6,1.6-6.8,5.2-9.8,7.8c-8.1,6.9-13.7,3.7-19.5-2.9c-5-5.6-10.1-9.2-17.9-7.9c-6.2,1-12.8,6.8-13.2,13.3
		c-0.3,4.4,15.1,18.6,12.1,20.4c-6.8,4.1-10.8,6.7-11.5,15.3c-0.7,8.5,6.3,16.7,11.9,22.2c1.3,1.4,3.6,1.4,4.8,0l0.1-0.1
		c2.8,7.2,18.7,9.2,26.3,6.4c4.5-1.7,8.4-3.4,13.3-3.2c6.2,0.2,11.8,8.9,15.7,13c3.1,3.1,13.1,9.8,17.9,6.4c15-10.3,8.7-35-4.6-43.6
		c-2.5-1.7-5.4-2.9-8-4.3C1578.4,247.8,1582.7,240.4,1588.3,238.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M281.2,2036.7c3.5-3,4-6.6,4.6-10.9c1.3-7.9,3.2-13.8-5.2-18.4c-6.1-3.4-8.6-11-15-14c-2.1-1-4.6-1.1-6.7-2.3
		c-1.1-0.6-2.5-4.6-3.3-5.7c-0.6-0.9-1.7-1.4-2.8-1.3c-4.6,0.7-5.6,1.5-5.6-4c0-2.5-2.7-5.3-3.8-7.5c-1.8-3.5-6.7-6.8-10.8-6.4
		c-6.5,0.6-19.1,5.6-24.6,0.7c-4.3-3.8-7.9-7.6-13.3-7.6c-1.6-3.6-4-5.7-8.6-5.2c-3.8,0.4-3.3,0.4-6-2.6c-1.4-1.5-3.3-3.6-5.6-3.3
		c-7.9,1.2-8.5,6.6-14.3,10.7c-6.1,4.3-14.2,5.6-19.7,11.1c-4.6,4.7-4.9,9.5-4.3,15.8c0.3,2.8,2.5,5.1,4.2,7.1
		c4.4,5.1-0.1,7.3,0.7,12.3c0.9,5.3,5.4,12,11.6,12.3c1.8,0.1,3.5-0.1,5.1-0.7c0.5,0.2,1,0.3,1.5,0.3h2.3c1.1-0.1,2.1-0.8,2.5-1.8
		c-0.1-0.2-0.2-0.3-0.3-0.5c1.8-0.3,3-1.9,2.7-3.7c-0.1-0.4-0.2-0.8-0.4-1.2c0.7-1,1.4-2,2.1-3c0.1-0.2,0.2-0.3,0.3-0.5
		c3.2-0.9,6.9-1.5,10-2.1v3.2c0.1,1.4,1.2,2.5,2.5,2.8c15.7,2.2,37.8-0.6,37.8,22c0.1,1.1,0.8,2.1,1.8,2.5c9.2,4.6,6,14.3-1.2,19.6
		c-0.7,0.5-13.9,8.6-14.9,5.5c-0.7-1.8-2.1-3.2-3.9-3.9c-4.3-2-8.9,2.3-11.9-4.7c-0.6-1.9-2-3.5-3.8-4.3c-3.5-1.9-7.8-2.5-11.7-2.5
		c-8.1-0.1-12.9-7.9-13.4-15.2c-0.2-1.4-1.2-2.5-2.5-2.8c-6.8-2.2-11.1-1.2-13.8,5.7c-2.5,6.3-0.3,10.5-1.4,16.6
		c-0.9,5.2-2.6,8-1.1,13.6c2.9,10.8,6.9,22.7,15.9,29.8c1.3,1,2.8,1.5,4.4,1.7c5.5,0.3,8.4,3.3,11.7,7.4c2.3,3,8.7,2.7,10,5.3
		c0.4,1,1.3,1.8,2.3,2c5.6,0.5,11,2.1,16.8,2.4c8,0.4,15.8,6.2,23.7,5.2c3.4-0.4,9.4-3.7,11.5-2.4s5.1,0.4,7.2-0.3
		c7.7-2.7,14.2-10,19.7-15.7c6.6-6.7,12.3-14.2,16.8-22.4c3.6-6.5,1.4-16.3-1.7-22.6c-0.6-1.2-1.5-2.3-2.5-3.2
		C272.1,2045,277.5,2039.8,281.2,2036.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1518.6,1810.7c-0.9-6-8-9.6-13.9-12.5c-1.7-2.1-4.3-3.3-6.9-3.3c-0.7-0.1-1.3,0-1.9,0.3
		c-10.1,2.6-20.5,21.7-22.6,28.4c-4.8,14.9-8,47.2,14.5,50.6c9.4,1.4,12,12.6,19.7,17.6c8,5.2,20.1,4.4,29.1,3.7
		c20.8-1.8,32.4-23.5,34.7-42.2c1.8-14.9-7.2-31.1-21.5-37c-16.7-6.8-19.6,15.9-17.7,26.6c0.2,1.2,2.6,9.7,4.5,8.9
		c-3.9,1.5-7.7,3-11.6,4.2c-4.4,1.3-9.1-3.1-12.4-5.5c-6.9-5.1-12.2-11.6-4.5-19.2C1514,1825.2,1520,1820,1518.6,1810.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1332.4,1746.3c-1.4-4.6-4.4-8.1-7.5-11.8c-5.7-6.6-12-11.5-21.5-10.5c-12,1.3-18.3,10.8-22.6,21.1
		c-2.1,4.9-3.7,10.1-5.3,15.1c-0.3,0.2-0.6,0.6-0.9,0.9c-5,7.6-14.3,19.8-10,29.6c3.4,7.6,8.6,7.2,15.9,5.7c9-1.9,9.6,6.9,10.6,13.2
		c0.7,4.2,2.7,6.3,6.1,8.5c8.4,5.3,20.8-1.1,29.4-2.6c20-3.5,32.4,29.6,52.2,8.3c4.7-5.1,7.5-8.3,14.8-10.3s13.5-0.4,20.4,2.4
		c8.6,3.5,13.8,9.6,23.9,5.7c15.8-5.9,39.3-29.9,21-45.9c-23.9-21-51,15.4-74.9,7.9c-10.2-3.3-18.9-11.4-29.7-13.3
		c-0.4-0.1-5.2-7.1-7.1-8.2c-4.1-2.3-10.3,0.1-13.4-3.8C1331,1755,1333.6,1750.3,1332.4,1746.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1402.8,1749c2.2,13.7,21.2,12,27.7,3c3.6-4.8,5.6-13.2,7.5-18.8c3.1-9.4,13.7-13.7,16.8-23.8
		c2.1-6.6-2.2-12.3-5.6-17.5c-3.4-4.9-11-18.6-17.7-19.2c-5-0.5-11.5,4.1-16.3,0.6c-3.1-2.2-2.7-6.2-5.4-8.4
		c-4.4-3.6-11.9-6.7-17.7-4.9c-18.2,5.6-17.3,23.2-30.4,34c-5.6,4.6-16.5,2.7-20.7,8.7c-3.8,5.3-5.3,14.2-1.4,20
		c8.1,11.7,23.3,5.3,35-1.6c6.7,0.4,13.3,1.5,19.9,3.3C1405.4,1727.1,1401.4,1740.4,1402.8,1749z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1603.9,1727.4c1.9-6,2.6-12.3,2.1-18.5c-0.6-9.3-6.2-17.1-11.7-24.1c-3.3-3.9-6.9-7.3-10.9-10.4
		c-5.9-4.8-8.8-11.5-13.6-17.2c-4.1-4.7-8.2-8.5-14.6-8.7c-11.2-0.3-23.8,5-34.2,8.5c-15.5,5.1-42,10.7-43.3,31.6
		c-0.5,8.3,2.5,13.4,7.6,19.6c5.9,7.1-2.3,20.4-3.5,28.4c-2.5,16.1,4.6,26.4,21.3,24.5c5.8-0.7,13.6-2.6,17.2-7.5
		c5.6-7.7,5.5-15.4,5.2-24.3c2.7-0.9,2.9-5,0.2-5.9c4.1-9.3,12.6-14.9,22.6-4.4c14.7,15.4,0.6,31.3-7.1,45.4
		c-7.9,14.3-1.1,36.2,18.1,30.9c8.7-2.4,15.5-9.3,23.4-13.2c6.7-3.3,16.5-8,19-15.9c3.2-10-5.4-14.1-4.4-23.5
		C1598.1,1737.1,1602.3,1732.7,1603.9,1727.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1569.2,1972.2c2.9-2.3,7.1-4.3,9.1-7.4c4.4-7,7.2-14.9,3.6-22.8c-6.9-14.3-35.7,2.8-42.2,9
		c-2.9,2.8-4.6,7.2-6.6,10.6c-5.4,9.2-11.7,8-19.3,3.8c-6.6-3.7-12.7-5.2-19.6-1.5c-5.5,3.1-9.7,10.7-8,16.8
		c1.2,4.3,20.5,12.6,18.2,15.2c-5,6.1-7.9,10-5.7,18.3s11.5,13.6,18.6,17c1.7,0.9,3.8,0.1,4.6-1.6c0-0.1,0-0.1,0.1-0.2
		c4.9,5.9,20.7,2.5,26.9-2.7c3.8-3.1,6.8-5.9,11.5-7.4c5.9-1.9,14,4.5,19.2,7c3.9,1.9,15.5,4.9,19,0.1c10.8-14.7-3.5-36-18.8-39.7
		c-3-0.7-6-0.9-9-1.4C1563,1984.3,1564.5,1975.9,1569.2,1972.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2223.2-5.7c4.2-6.9-5.9-12.1-10.9-13.3c-6.1-1.6-12.5-2-18.7-1.2c-8.4,0.9-18.5,4.1-25.5-2.8
		c-7.7-7.5-13.3-10.8-24.1-5.1c-7.6,3.9-17.7,2.7-23.9,9.4c-1.7,1.8-1.9,4.4-2.6,5.2c-4.9,5.3-10.2,7.9-15.7,12.3
		c-14.1,11.3-10.2,34.5,3.8,43.9c5.3,3.6,10.9,6.6,14.8,11.9c4.8,6.3-5.8,14.1-9,18.2c-6.4,8-7.4,20.4-5.7,30.3
		c1.2,7.1,11.9,13.1,16.6,17.7c8,7.9,16.1,15.7,28,15.5c24.9-0.4,34.3-22.2,24.2-43.6c-7.2-15.3-14.3-33.4-14.3-50.7
		c0-5,0.2-10.2,0.7-15.2c0.7-6.3,2.2-14.6,9-6.4c8.5,10.2,1.4,28.9,12.5,37c6.5,4.7,13.2,0.9,18.7,9.7c2.5,4.2,6.1,7.4,10.6,9.2
		c7.6,2.9,20.3-0.7,27.3-4.1c4.1-2,6.3-6.1,7.5-10.5c7.5-8.2,21.5-28.6,6.3-35.9c-11.7-5.7-33.8,0.7-42.4-10.3
		C2204.6,8.5,2220.1-0.5,2223.2-5.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2191.3,175.2c3.8-0.4,7.5-1.3,11.2-2.5c6.8-2.2,12.4-1.5,19.2-1.5c5.2,0,12.8-1.2,14.9-6.9
		c5.7-15.4,27.9-18.8,31.5-36c2.2-9.9,3.9-32.7-5.1-40.1c-9-7.5-25.5-0.2-33.5,5.7c-6.2,4.6-6.6,11.6-8.2,18.7
		c-3.5,15-16.4,14.2-28.7,14.9c-10.3,0.6-18.8,4.7-20.7,13.9C2155.7,145.7,2179.9,176.4,2191.3,175.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1958.7,1320.9c4.2-6.9-5.9-12.1-10.9-13.3c-6.1-1.6-12.5-2-18.7-1.2c-8.4,0.9-18.5,4.1-25.5-2.8
		c-7.7-7.6-13.3-10.8-24.1-5.1c-7.6,3.9-17.7,2.7-23.9,9.4c-1.7,1.8-1.9,4.4-2.6,5.2c-4.9,5.3-10.2,7.9-15.8,12.4
		c-14.1,11.3-10.2,34.5,3.8,43.9c5.3,3.6,10.9,6.6,14.8,11.9c4.8,6.3-5.8,14.1-9,18.2c-6.4,8-7.4,20.4-5.7,30.3
		c1.2,7.1,11.9,13.1,16.6,17.7c8,7.9,16.1,15.7,28,15.5c24.9-0.4,34.3-22.2,24.2-43.6c-7.2-15.3-14.3-33.4-14.3-50.7
		c0-5.1,0.2-10.2,0.7-15.2c0.7-6.3,2.2-14.6,9-6.4c8.5,10.2,1.4,28.9,12.5,37c6.5,4.7,13.2,0.9,18.7,9.7c2.5,4.2,6.1,7.4,10.6,9.2
		c7.6,2.9,20.3-0.7,27.3-4.1c4.1-2,6.3-6.1,7.5-10.5c7.5-8.2,21.5-28.6,6.3-35.9c-11.7-5.7-33.8,0.7-42.4-10.3
		C1940.2,1335.2,1955.6,1326.2,1958.7,1320.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1926.9,1501.9c3.8-0.4,7.5-1.3,11.2-2.5c6.8-2.2,12.4-1.5,19.2-1.5c5.2,0,12.8-1.2,14.9-6.9
		c5.7-15.4,27.9-18.8,31.5-36c2.2-9.9,3.9-32.7-5.1-40.1c-9-7.5-25.5-0.2-33.5,5.7c-6.2,4.6-6.6,11.6-8.2,18.7
		c-3.5,15-16.4,14.2-28.7,14.9c-10.3,0.6-18.8,4.7-20.7,13.9C1891.2,1472.3,1915.4,1503.1,1926.9,1501.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2033.9,1044.3c8,0.2,13-3.8,17.7-9.8c7.8-10,15.8-30.7,4.1-40.8c-8.6-7.4-17.6-4.6-25.3,1.3
		c-0.8-2-3.3-3.3-5.2-1.4c-5.7,5.5-12.6,11-14.1,19.2c-1.2,6.3-1.7,17.2,2.2,22.7C2018,1042.5,2025.9,1044.2,2033.9,1044.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1782.1,1372.1c2.3,9.2,9.8,16.5,17.5,21.4c8.4,5.2,16.9,5.9,24.8-0.2c6.8-5.2,4.3-13.9-0.8-19.6
		c-3.4-3.6-7.4-6.5-11.9-8.6c-0.6-1.7-2.3-2.7-4-2.3l-1.1,0.2c-2.3-0.8-4.6-1.2-7.1-1.5c-1.9-0.2-3.6,1.2-3.8,3.1c0,0.2,0,0.4,0,0.6
		l-10.9,2.3C1782.9,1368.2,1781.6,1370.2,1782.1,1372.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M86.7,1156.1c8.6-0.4,16.5-5.8,22.2-12c6.2-6.7,8.4-14.3,4.4-22.7c-3.5-7.2-11.6-6.5-17.4-3
		c-3.9,2.4-7.1,5.5-9.8,9.2c-1.6,0.2-2.8,1.6-2.7,3.3v1c-1.1,2-2,4.1-2.6,6.2c-0.5,1.7,0.4,3.6,2.2,4.1c0.1,0,0.3,0.1,0.4,0.1v10.4
		C83.4,1154.5,84.8,1156.1,86.7,1156.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1654.1,1168.8c6.1-0.7,10.9-3.4,16.2-6.5c7.7-4.6,16-5.6,24.4-8.3c13-4,19.2-15.8,25.6-26.8
		c3.5-5.8,8.2-10.3,10.9-16.6c3.1-7.2,1.5-15.6,4.4-23c4-9.7,14.4-16.1,21.7-23.2c13.1-13.1,12.3-28.6-3.2-39.3
		c-10.8-7.4-25-9-32,0.1c-1.2-3-6.3-2.9-6.6,1c-0.9,13.1-15.6,21.1-21.6,32c-2.9,5.3-2.9,10.8-3,16.7c-0.2,9.1-10.2,11.6-17,15.3
		c-27.4,14.7-38.6,48.8-23.4,76.6C1651.1,1168.3,1652.6,1169.1,1654.1,1168.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1645.5,1239.8c4.8,7.9,16.9,6.4,24.6,4.6c5.7-1.3,12.1-3.1,15.3-8.3c3.6-5.6,4.6-14.3,4.6-20.8
		c0.3-4-2.1-7.6-5.8-8.9c-2.6-1.1-5.7-1.2-8.4-1.6c-5.3-0.9-9.9-4.1-14.9-5.9c-11.9-4.5-29.8,11-16.9,18.9
		C1643.3,1225.7,1640.9,1232.5,1645.5,1239.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1691.3,1198.2c1.4,3.7,5.7,6.3,9.5,6.6c8.6,0.7,15.8-8.2,18.5-15.4c3.3-8.7-5.9-19.6-14.1-22.1
		c-11.7-3.5-16,11.6-16.2,19.8c-0.1,1.3,0.7,2.5,2,2.9C1690.3,1192.8,1690.4,1195.5,1691.3,1198.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1483.7,1291.9c1.4,3.7,5.7,6.3,9.5,6.6c8.6,0.7,15.8-8.2,18.5-15.4c3.3-8.7-5.9-19.6-14.1-22.1
		c-11.7-3.5-16,11.6-16.2,19.8c-0.1,1.3,0.7,2.5,2,2.9C1482.7,1286.4,1482.9,1289.3,1483.7,1291.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1629.4,1485.2c5.5,3.7,14.1,3.1,19.6-0.2c12.5-7.5,13.6-26.8,10.2-39.5c-4.2-15.1-27.4-21-41-16.3
		c-19.4,6.7-10.6,31.6-2.8,43.1c1.1,1.9,3.5,2.8,5.5,2.1C1622.9,1478.6,1625.3,1482.5,1629.4,1485.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2165.6,2064.3c1.4,3.7,5.7,6.3,9.5,6.6c8.6,0.7,15.8-8.2,18.5-15.4c3.3-8.7-5.9-19.6-14.1-22.1
		c-11.7-3.5-16,11.6-16.2,19.8c-0.1,1.3,0.7,2.5,2,2.9C2164.6,2058.8,2164.7,2061.7,2165.6,2064.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2034.5,1508.3c-7.4,0-14.7-0.3-21.7-3.4c-3.9-1.7-6.9-2.7-10.9-0.8c-7.7,3.7-8.3,19.9-4.6,26.2
		c5.6,9.6,17.7,13.8,27.8,7.9c4.8-2.8,7.3-6.4,9.3-11.6c1.1-2.7,2.7-5.1,3-7.9c1.3-0.7,1.8-2.4,1.1-3.8c-0.4-0.7-1-1.2-1.8-1.4
		c0.2-0.9,0.3-1.9,0.4-2.8C2037.4,1509.5,2035.8,1508.3,2034.5,1508.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2074,1555.4c9.6,1.3,21.6,3.2,30.9-0.7c6-2.6,13.1-10.3,10-17.2c-2.3-5.2-7.8-3-9.6-10.3
		c-1.3-5-0.9-8.3-4.4-12.6c-3.8-4.5-13.6-5.7-19-7c-6.8-1.7-12.6-5.7-19.4-7.2c-8.6-1.9-14.2,3.7-17.1,11c-2.4,2.2-4,5-4.5,8.2
		c-1.9,10.4,7.9,15.6,9.4,25C2051.8,1554.3,2066.7,1554.5,2074,1555.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2026.5,1569c-1.5-3.6-4.6-5.7-8.2-7.1c-0.2-3.5-1.2-6.5-4.1-7.9c-3.9-1.8-7.2-2.3-10.4-5.5
		c-2-2.1-4.4-4.6-7.3-5c-15.4-2.3-26.3,18.2-16.3,29.9c7.1,8.3,19.8,10.4,14.8,24.6c-2.3,6.7-7.6,15.3-2.5,22.2
		c3.6,4.8,8.9,4.9,14.5,4.5c4.6-0.4,8.8-1.3,13.1,1.5c3.8,2.5,6.1,7.8,8.9,11.3c4.8,6,12.4,8,19.9,7.4c8.3-0.7,18.8-1.4,24.5-8.2
		c2.1-2.5,3.1-6,4.6-8.8c3.1-5.4,8.8-4.7,13.7-3.3c7.2,2.3,12.7-0.8,17.6-6.1c7.9-8.6,18.3-27.6,5.5-36.1c-11-7.3-28.2-5.9-40.6-5
		c-6.3,0.5-11.3,1.7-15.9,6.3c-2.9,2.9-4.9,6.3-7.8,9.2c-5.6,5.7-11.5,2-16.7-1.9c-4.4-3.3-8-7.5-7.5-13.2
		C2026.6,1575,2027.6,1571.8,2026.5,1569z"/>
            <path :fill="colors[`${id}-p-1`]" d="M732.4,2053.9c-7.4,0-14.7-0.3-21.7-3.4c-3.9-1.7-6.9-2.7-10.9-0.8c-7.7,3.7-8.3,19.9-4.6,26.2
		c5.6,9.6,17.7,13.8,27.8,7.9c4.8-2.8,7.3-6.4,9.3-11.6c1.1-2.7,2.7-5.1,3-7.9c1.3-0.7,1.8-2.4,1.1-3.8c-0.4-0.7-1-1.2-1.8-1.4
		c0.2-0.9,0.3-1.9,0.4-2.8C735.3,2055,733.7,2053.9,732.4,2053.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M771.9,2100.9c9.6,1.3,21.6,3.2,30.9-0.7c6-2.6,13.1-10.3,10-17.2c-2.3-5.2-7.8-3-9.6-10.3
		c-1.3-5-0.8-8.3-4.4-12.6c-3.8-4.5-13.6-5.7-19-7c-6.8-1.7-12.6-5.7-19.4-7.2c-8.6-1.9-14.2,3.7-17.1,11c-2.4,2.2-4,5-4.5,8.2
		c-1.9,10.4,7.9,15.6,9.4,25C749.7,2099.8,764.6,2100,771.9,2100.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M724.3,2114.5c-1.5-3.6-4.6-5.7-8.2-7.1c-0.2-3.5-1.2-6.5-4.1-7.9c-3.9-1.8-7.2-2.3-10.4-5.5
		c-2-2.1-4.4-4.6-7.3-5c-15.4-2.3-26.3,18.2-16.3,29.9c7.1,8.3,19.8,10.4,14.8,24.6c-2.3,6.7-7.6,15.3-2.5,22.2
		c3.6,4.8,8.9,4.9,14.5,4.5c4.6-0.4,8.8-1.3,13.1,1.5c3.8,2.5,6.1,7.8,8.9,11.3c4.8,6,12.5,8,19.9,7.4c8.3-0.7,18.8-1.4,24.5-8.2
		c2.1-2.5,3.1-6,4.6-8.8c3.1-5.4,8.8-4.7,13.7-3.3c7.2,2.3,12.7-0.8,17.6-6.1c8-8.6,18.3-27.6,5.5-36.1c-11-7.3-28.2-5.9-40.6-5
		c-6.3,0.5-11.3,1.7-15.9,6.3c-2.9,2.9-4.9,6.3-7.8,9.2c-5.6,5.7-11.5,2-16.7-1.9c-4.4-3.3-8-7.5-7.5-13.2
		C724.5,2120.5,725.5,2117.3,724.3,2114.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M825.9,1190.7c-6.5,7.4,3.5,17.5,8.6,23.9c3,3.6,5.5,7.4,7.7,11.5c1.6,3.1,2.5,9.1,4.7,11.8
		c1.7,1.9,4,3.1,6.4,3.5c0.8,0.1,7.4,9.3,7.8,10.6c2.8,9.5,13.8,17.8,23.2,13.4c6-2.8,7.8-11.1,10.9-16.2c1.7-2.7,26,7,28.3,8.9
		c1.3,0.9,3,0.8,4.1-0.3c8.4-7.7,14.5-8.7,25.1-7.6c3.6,0.5,7.2-0.3,10.4-2.2c4.6-3.1,7.1-10.2,12.4-10.4c3.2-0.1,7.5-1.8,9.1-4.7
		c2-3.7,2.8-17.1,7-15.7c2.4,1,5,0.6,7.1-1c3.5-3.1,2-9.3,1.4-13.2c-1.1-6.9,4.2-12,4.6-18.5c1.3-18.6-16.5-24.1-31.1-15.7
		c-3.3,1.9-5.4,4-5.7,7.9c-0.3,4.4,2,15.8-5.6,15.5c-9.1-0.4-11.5,9-18.6,12.5c-3.6-3.4-9.8-1.8-11.7,3.6c-1.6,4.4-8.4,2-11.8,1
		c-6.2-1.9-12.5-1.1-18.8-1.9c-11-1.4-14.1-3.4-16.4-13.6c-0.5-2.2-4.4-14.7-4-15c7.1-6.5,7.9-15.8,6.3-24.4c4.6-1.7,10.3-1,14.9-1
		c3.1,0,9.3-1,11.9,1.3c0.9,1.1,2.1,2,3.3,2.7c3.5,1.4,7.4,1,10.6-0.9c6.9-3.9,13.8-3.1,21.6-3.1c1.8,0.1,3.4-1.3,3.6-3.1
		c0.2-6.8,1.8-15.8-2.3-21.6c0.4-6.8-0.4-18.7-10.3-15.4c-7.4,2.5-9.7-4.7-16.6-5.5c-6.5-0.7-11.4,4.7-15.7-1.7
		c-2.1-3-6.2-0.1-5.7,2.9l-5.9,0.3c-0.9-1.8-3.8-2.7-5.2-1c-5.1,5.6-12.9-4.4-18.4,0.5c-5.1,4.6-10.7-0.4-16.8,1.2
		c-1.5,0.3-2.6,1.6-2.7,3.1v4.1c-2.4,0.1-4.4,1.4-5.5,3.5c-2,3.6,0.9,9.3-3.3,11.8c-6.5,3.9-8.5,7.6-6,12.7
		c-8.9,3.3-11.4,16-8.3,25.3C819.8,1174.8,821.7,1180.6,825.9,1190.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M924.2,1720.6c0.5-3.7-0.8-7.4-3.5-10c-5.4-5.7-6.3-12.7-8.2-20.2c-0.4-1.8-2.1-3-3.9-2.8
		c-6.6,1.5-15.7,2.2-20.3,7.5c-6.7,1.3-18,4.9-12.4,13.8c4.2,6.6-2.2,10.5-1.3,17.5c0.9,6.4,7.4,9.9,2.2,15.7
		c-2.4,2.7,1.5,6,4.3,4.9l1.8,5.7c-1.5,1.3-1.7,4.3,0.3,5.3c6.7,3.7-1.2,13.5,5,17.7c5.6,3.9,2.3,10.5,5.2,16
		c0.7,1.4,2.1,2.1,3.6,1.9l4-1c0.6,2.3,2.5,4.1,4.7,4.5c4,1,8.8-3.2,12.2,0.3c5.3,5.4,9.5,6.4,13.8,2.7c5.3,7.8,18.3,7.1,26.5,1.8
		c7.2,9.1,12.4,5.8,21.1-0.7c8.8,4.4,16.1-7.7,21.1-14.2c2.8-3.8,5.8-7.2,9.3-10.4c2.6-2.3,8.2-4.6,10.2-7.5c1.4-2.1,2-4.6,1.8-7.1
		c-0.1-0.8,7.2-9.4,8.4-10.1c8.5-5,13.8-17.7,7.3-25.8c-4.2-5.1-12.7-4.8-18.4-6.6c-3-0.9,0.4-26.9,1.7-29.7c0.5-1.5,0-3.1-1.3-3.9
		c-9.6-6.2-12.1-12-13.5-22.4c-0.4-3.7-2-7-4.6-9.5c-4.2-3.8-11.7-4.4-13.2-9.4c-0.9-3-3.6-6.9-6.9-7.7c-4.1-1-17.2,1.5-16.9-3
		c0.3-2.6-0.7-5-2.8-6.6c-3.8-2.6-9.6,0.3-13.2,1.9c-6.4,2.8-12.7-1.2-19.1,0.1c-18.4,3.4-19.4,22-7.6,33.9c2.7,2.7,5.2,4.3,9.1,3.6
		c4.4-0.8,14.8-5.8,16.4,1.7c1.9,8.9,11.6,9,16.7,14.9c-2.4,4.3,0.7,9.9,6.3,10.4c4.6,0.4,4,7.7,3.9,11.2c-0.3,6.5,2,12.4,2.8,18.7
		c1.4,11,0.2,14.5-9.2,19.3c-2,1-13.2,7.8-13.6,7.5c-8-5.2-17.2-3.8-25.2-0.1c-2.8-4.1-3.5-9.7-4.6-14.2c-0.8-3-3.3-8.8-1.6-11.8
		C923.3,1723.3,923.9,1722,924.2,1720.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M832.3,1530.1c0.7-6,0.4-10.5-4-14.9c-5-5.1-12.7-6.4-18.5-10.4c-5.5-3.8-6-9-13.5-10.1
		c-2.2-0.3-4,1.7-5.2,3.1c-2.6,2.8-2.2,2.8-5.7,2.4c-4.4-0.5-6.5,1.6-8,4.9c-5.1,0-8.6,3.6-12.7,7.1c-5.2,4.6-17-0.1-23.2-0.7
		c-3.8-0.4-8.5,2.8-10.2,6.1c-1.1,2.1-3.6,4.7-3.6,7c0,5.1-1,4.4-5.3,3.8c-1-0.1-2,0.3-2.6,1.2c-0.7,1.1-2.1,4.8-3.1,5.4
		c-2,1.2-4.4,1.2-6.3,2.1c-6.1,2.9-8.3,10.1-14.1,13.3c-7.8,4.3-6.1,9.9-4.9,17.3c0.6,4,1.1,7.4,4.4,10.2c3.6,3.1,8.6,7.8,4,12.4
		c-0.9,0.9-1.7,1.9-2.3,3c-2.9,6-4.8,15.1-1.6,21.4c4.4,7.7,9.7,14.8,15.8,21.2c5.1,5.3,11.3,12.3,18.5,14.8c2,0.7,4.8,1.5,6.7,0.3
		c2-1.2,7.6,1.9,10.8,2.3c7.5,0.9,14.8-4.6,22.3-4.9c5.4-0.3,10.5-1.9,15.8-2.3c1-0.2,1.9-0.9,2.2-1.8c1.2-2.6,7.2-2.3,9.4-5
		c3.1-4,5.7-6.6,11-7c1.5-0.1,3-0.7,4.2-1.6c8.5-6.6,12.4-17.9,14.9-28.1c1.4-5.2-0.2-7.8-1-12.9c-1-5.8,1-9.8-1.3-15.7
		c-2.5-6.5-6.5-7.4-13-5.4c-1.3,0.3-2.2,1.3-2.4,2.6c-0.6,6.8-5,14.2-12.7,14.3c-3.7,0-7.7,0.6-11,2.3c-1.7,0.8-3,2.2-3.6,4
		c-2.8,6.7-7.1,2.6-11.2,4.4c-1.6,0.7-2.9,2-3.6,3.7c-0.9,2.9-13.4-4.7-14-5.2c-6.7-4.9-9.7-14.1-1.1-18.5c0.9-0.4,1.6-1.3,1.7-2.3
		c0-21.2,20.8-18.6,35.7-20.7c1.3-0.2,2.3-1.3,2.4-2.6v-3c2.9,0.6,6.4,1.2,9.4,2c0.1,0.2,0.1,0.3,0.2,0.5c0.7,1,1.3,1.9,2,2.8
		c-0.9,1.4-0.5,3.3,0.9,4.2c0.4,0.2,0.8,0.4,1.2,0.4l-0.2,0.5c0.4,0.9,1.3,1.6,2.3,1.7h2.1c0.5,0,1-0.1,1.4-0.3
		c1.6,0.5,3.2,0.7,4.8,0.6c5.7-0.3,10.1-6.5,10.9-11.5c0.8-4.6-3.5-6.7,0.7-11.6C830,1534.8,832,1532.6,832.3,1530.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M551,1454.8c0.2,12.6,8,23.9,15.5,33.6c3.9,4.9,7.3,5.3,12.6,8c6.1,3.2,8.3,7.8,15.4,9.8
		c7.8,2.3,11.5-0.8,14.1-8.2c0.7-1.4,0.4-3-0.7-4c-5.9-5.3-9.7-14.6-4.4-21.8c2.5-3.4,4.9-7.5,5.5-11.8c0.5-2.1,0.1-4.4-1.2-6.1
		c-4.3-7.2,2.6-8.4,3.7-13.4c0.5-2,0.2-4.1-0.8-5.8c-2.1-2.9,13.8-9.1,14.7-9.3c9.3-2.8,19.9,0.9,17.8,12c-0.3,1.2,0.1,2.4,1,3.2
		c19.6,14.8,2.7,32.3-5.8,47.5c-0.7,1.4-0.4,3,0.7,4l2.8,2.1c-2.6,2.3-5.5,5.2-8.4,7.4c-0.2,0-0.4-0.1-0.6-0.1
		c-1.4-0.1-2.7-0.1-4-0.1c-0.7-1.8-2.7-2.7-4.4-2.1c-0.5,0.2-0.9,0.4-1.3,0.8c-0.1-0.2-0.2-0.4-0.3-0.5c-1.2-0.3-2.4,0.1-3.2,1
		l-1.5,2c-0.3,0.5-0.6,1-0.7,1.5c-1.6,1.1-3,2.5-4,4.1c-3.8,5.5-1.1,13.9,3.1,18.2c3.8,4,8.6,1.6,10.3,8.7c0.7,2.8,1.3,6.2,3.5,8.3
		c5,4.8,9.4,7.7,16.6,6.7c8.3-1.2,14.8-7.2,22.6-9.9c7.4-2.5,12.6,0.7,18.8-5.5c1.8-1.8,1.3-4.8,0.9-7c-0.7-4.4-1.1-4,1.8-6.9
		c3.5-3.7,3.2-7.1,1.1-11c3.7-4.8,2.8-10.5,2.2-16.7c-0.7-8.1,12.1-15.7,16.9-21.1c3-3.3,3.4-9.9,1.5-13.6c-1.2-2.5-2-6.6-4.1-8.2
		c-4.7-3.7-3.4-4,0.3-7.5c0.8-0.9,1.1-2.1,0.7-3.3c-0.5-1.4-3.1-5.3-2.9-6.6c0.4-2.7,2-4.8,2.5-7.3c1.7-7.6-3.5-14.7-2.3-22.3
		c1.6-10.3-4.9-12.6-12.7-16.7c-4.2-2.2-7.6-4.3-12.6-3.2c-5.2,1.2-13.3,2.5-14.2-5c-0.1-1.5-0.5-2.9-1.2-4.3
		c-3.6-6.9-10.7-15.1-18.7-16.4c-10.2-1.4-20.6-1.5-30.8-0.1c-8.6,1-19.3,1.9-26.7,6.7c-2,1.3-4.7,3.5-5,6s-7.1,5.7-9.7,8.4
		c-6.1,6.3-6.2,17-11.1,24.1c-3.6,5.2-5.6,11-9,16.2c-0.5,1.1-0.5,2.3,0.1,3.3c1.5,2.9-3,8.3-1.9,12.3c1.5,5.5,2.2,10-1.2,15
		C551.6,1451.3,551.1,1453,551,1454.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M369.9,1146c10.1,2.3,25.3,1.7,32.7-6.4c1.7-1.8,1.5-5.2,0.6-7.4c-2.8-6.2-5.8-11.4-7-18.3
		c-1.6-9.3-9-11.4-18-10.2c-6.3,0.9-12.6-2.8-18.9-2.3c-7.6,0.5-20.4,6.7-23.8-4.6c-1.8-5.7-7.4-14.1-14.7-10.7
		c-10.6,5-21.9,8.7-33.3,11c-8.8,1.9-19.3,7.9-21.6,17.5c-2.3,9.3-3.3,16.5-8.7,24.7c-3.1,4.6-5.5,8.7-5.7,14.4
		c-0.2,4.1,2.5,6.8,4.9,9.6c5.2,5.9,7.1,12.7,8,20.4c0.7,3.3,2.4,6.2,4.9,8.3c7.2,5-0.6,11.8-3.2,16.7c-3.5,6.5-1.5,14.6,4.6,18.8
		c5.8,4,17.9,1.8,21.9,8c6.9,10.9-5.3,19.4-5.3,29.9c0,11,8.9,18.1,17.5,23.4c9.1,5.7,11.6,16.3,23.8,12.6
		c6.1-1.9,12.1-4.8,18.6-4.2c3.6,0.2,7-0.8,9.9-2.7c4.3-2.8,5.6-8.9,9.6-11.3c3.2-1.9,13.9,2.3,17.7,3.7c7.7,2.6,13-4.1,16.4-10.1
		c15.2,5.6,34.5-7.5,30.7-24.8c-0.3-1.5-1.5-2.7-3-2.9c0.8-4,0.1-8-2.1-11.4c-4.3-6.9-9.5-24.5-20.8-19.4c-3.4,1.6-6.3,4.4-9.2,0.9
		c-1-1.1-2.5-1.8-4-2c-5-0.8-10.1,4.4-13.6,7c-5.8,4.3-12.2,4.7-17.3-0.4c-1.3-1.3-2.8-3.1-4.4-3.8c-6.5-2.7-14.3-2.6-18.4-9.2
		c-3.4-5.4-8.5-15.3-2.7-21.2c3-3,6.5-4.3,9.7-6.8c2.3-1.8,7.1-5.4,6.4-8.9c-1.5-6.9-4.2-14-2.5-21.2
		C352.6,1141.3,361.2,1144,369.9,1146z"/>
            <path :fill="colors[`${id}-p-1`]" d="M421.4,1154.8c-2.1,1.3-4,2.8-5.7,4.4c-1,0.8-1.4,2.1-1.2,3.4c1.2,4.4,3.3,8.1,4.4,12.5
		c1.2,4.5-0.2,7.5-0.3,11.7c-0.2,6.4,2,11.5,5.3,17.1c6.2,10.5,13.3,10.8,22.6,15.7c7.9,4.1,19.7,10.3,28.8,6.4
		c14.7-6.2-1-15.9,5.5-24.7s3.5-17.8-4.4-24.3c-13.6-11.5-1.2-31.6-11.5-44.9c-3.7-4.7-9.7-9.2-12.1-14.6c-1.1-2.3-2.3-4.9-4.9-5.9
		c-7.9-2.9-17.1-0.9-21.9,5.6c-4.6,3-7.6,7.9-8,13.4c-0.4,4.5-0.3,9.6,1.8,13.8C421.3,1147.3,426.3,1151.3,421.4,1154.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M684,409.8c0.8,7.6,4.7,18.1,12.2,21.5c1.7,0.8,4.1-0.2,5.3-1.3c3.7-3.5,6.6-6.8,11.3-9.2
		c6.1-3.3,5.8-9,3-14.9c-2.1-4.3-1-9.4-2.8-13.8c-2.2-5.2-9.5-12.7-2.3-17.8c3.7-2.6,8.2-8.5,4.1-12.9c-6-6.2-11.3-13.3-15.5-20.8
		c-3.4-5.7-10.1-11.7-17.2-11.1c-7.1,0.6-12.4,1.6-19.4-0.3c-4.1-1.1-7.4-1.9-11.5-0.7c-2.9,0.8-4.2,3.4-5.5,5.7
		c-3,5-7.2,7.9-12.4,10.4c-2.2,1.2-3.8,3.2-4.6,5.4c-1.9,6.2-8.4,2.3-12.5,1.7c-5.3-1-10.6,2.3-12.1,7.6c-1.4,5,3,13-0.5,17.2
		c-5.9,7.4-14.8,0.8-22.2,3.3c-7.7,2.6-10.6,10.5-12.4,17.8c-1.9,7.7-8.7,12-3.3,19.7c2.8,3.9,6.2,7.3,7.3,12.1
		c0.7,2.5,2.2,4.7,4.3,6.3c3,2.3,7.6,1.9,10.2,4.2c2.1,1.8,1.6,10.4,1.6,13.3c0,6,5.8,8.1,11,9.2c-0.4,12.1,13.3,22.4,24.6,15.6
		c1-0.6,1.5-1.7,1.3-2.8c3-0.3,5.6-1.8,7.4-4.2c3.9-4.6,14.9-12.5,8.7-19.1c-1.9-2-4.5-3.5-2.8-6.2c0.5-1,0.7-2.2,0.5-3.3
		c-0.6-3.7-5.4-6-8.1-7.9c-4.4-3.1-6.1-7.4-3.8-12.3c0.8-1.2,1.4-2.6,1.6-4.1c0.3-5.1-1.6-10.7,2.1-15c3.1-3.7,8.8-9.5,14.2-6.8
		c2.8,1.4,4.6,3.6,7,5.2c1.8,1.2,5.4,3.8,7.7,2.5c4.5-2.7,8.9-6.1,14.2-6.6C683.2,396.5,683.4,403.2,684,409.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M689.8,447.9c-1.4-1.1-2.9-2.2-4.4-3.1c-0.8-0.5-1.8-0.5-2.6,0c-2.8,1.8-4.9,4.3-7.7,6
		c-2.8,1.8-5.3,1.6-8.3,2.5c-4.6,1.4-7.6,4.2-10.8,7.7c-5.9,6.8-4.4,11.8-5.7,19.6c-1,6.5-2.6,16.2,2.2,21.7
		c7.8,8.9,10.9-4.4,18.6-1.9c7.7,2.5,13.3-1.8,16-8.8c4.8-12.3,22-8.2,28.9-18.6c2.5-3.7,4.3-8.9,7.4-11.9c1.4-1.3,3-2.8,3-4.8
		c0.1-6.2-3.5-12.2-9.1-13.9c-3.2-2.6-7.3-3.6-11.3-2.5c-3.3,0.8-6.8,2.1-9.3,4.4C695.2,446,693.5,450.5,689.8,447.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M975.9,1536.7c-9.8-3.5-16.2-9.2-23.6-16.1c-14.8-13.8-34.9-6.2-48.2,6.1c-3.8,3.6-11.5,11.4-5.9,16.6
		c3.3,3.2,7.1,5.2,10.7,8c4.9,3.9,7.7,9.4,11.2,14.5c7.4,10.9,17.6,12.3,29.7,13.9c10.4,1.4,21,9.5,31.6,7.7c3.2-0.3,5.9-2,7.8-4.4
		c2.4-3.2,5.3-7.6,9.7-8.3c2.8-0.4,5.9,0,8.7-0.6c8.2-1.8,15.5-13.3,21.1-18.7c9.8-9.2-3.6-21.6,5.3-31.3c4.9-5.5,7.8-12.9,3.7-19.8
		c-2.9-4.7-6.4-11.3-11-14.5c-4.4-3.1-9.3-3.6-12.8-8.4s-1.9-11.1-5.1-15.7c-3.3-4.6-5.6-8-10.6-11c-8.8-5-20.3-1.2-29.5,0.6
		c-11.6,2.2-18.2-2.6-26,7.5c-8,10.4-20.2-4.7-28.2,3.5c-2.5,2.6-3.6,4.6-3.4,8.2c0.2,5.6-2.3,7.4-5.3,11.8
		c-2.5,3.6,3.2,7.2,5.6,3.8c0.4-0.5,0.7-1,1.2-1.5c1.5,15.9,28.1,14.1,41.8,14.1c6.9,0,11.2-5.7,18.9-4.4c7.4,1.2,19,10.4,20.5,17.9
		C995.9,1527.6,990.4,1541.7,975.9,1536.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M550.6,1627.2c-10.8-3.8-17.8-10-25.9-17.6c-16.2-15.1-38.2-6.8-52.7,6.7c-4.2,3.9-12.6,12.5-6.5,18.2
		c3.6,3.5,7.8,5.7,11.7,8.8c5.4,4.2,8.4,10.3,12.3,15.8c8.1,11.9,19.3,13.4,32.4,15.2c11.4,1.6,22.9,10.4,34.6,8.4
		c3.4-0.4,6.5-2.2,8.5-4.9c2.7-3.5,5.8-8.3,10.7-9c3-0.4,6.5,0,9.5-0.6c9-2,16.9-14.6,23-20.4c10.7-10.1-3.9-23.5,5.7-34.3
		c5.4-6,8.5-14.1,4-21.7c-3.2-5.2-6.9-12.4-12.1-15.8c-4.8-3.4-10.2-3.9-13.9-9.2c-3.8-5.3-2.1-12.2-5.6-17.2
		c-3.7-5-6.1-8.9-11.6-12c-9.6-5.5-22.2-1.2-32.2,0.6c-12.7,2.3-19.9-2.9-28.5,8.2c-8.7,11.3-22.1-5.1-30.9,3.8
		c-2.8,2.8-3.9,5-3.7,9c0.3,6.1-2.5,8.1-5.8,12.9c-2.8,3.9,3.5,7.9,6.1,4.1c0.4-0.6,0.8-1.1,1.3-1.6c1.7,17.4,30.8,15.4,45.7,15.4
		c7.6,0,12.3-6.2,20.7-4.8c8.1,1.3,20.8,11.4,22.4,19.6C572.5,1617.2,566.5,1632.7,550.6,1627.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1213.1,1147.7c-11.3,7.6-30-4.9-42.5-3.2c-16.3,2.4-9.4,25.5-4.8,35.3c-0.2,4.4,0.7,9,3.9,12.1
		c5.4,5.1,16.2,12.4,24.1,11.9c4.6-0.4,9.1-2.3,12.7-5.3c7.8-6.5,12.9-1,20.4-3.5c12.7-4.3,11.7-23.7,22.6-30.7
		c8.9-5.6,7.8,2.7,6.5,8.7c-1.1,4.8-2.4,9.7-4,14.3c-5.1,16.1-17.2,30.8-28.5,42.9c-15.8,16.9-13.5,40,9.6,47.8
		c11,3.8,20.8-1.2,30.7-6.1c5.7-2.9,17.5-5.2,20.7-11.5c4.4-8.7,7.2-20.5,3.7-29.9c-1.8-4.7-9.4-15.1-3-19.7
		c5.2-3.7,11.3-4.8,17.3-6.5c15.7-4.5,26.3-25,16.5-39.8c-3.9-5.7-8-9.8-11-16.2c-0.4-1,0.2-3.5-0.8-5.6
		c-3.8-8.2-13.5-10.1-19.5-15.9c-8.5-8.4-14.5-7.1-23.9-2.4c-8.5,4.3-16.9-1.7-24.5-5c-5.5-2.6-11.6-4.2-17.7-4.4
		c-4.9-0.3-15.9,1.5-14.1,9.2C1208.9,1129.8,1220.6,1142.8,1213.1,1147.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1171.9,1214.8c-5.7-7.9-18.7-19.7-29.5-15.4c-10.8,4.4-15.8,25.9-16.8,35.8c-1.7,17.1,17.9,26.9,18.6,42.9
		c0.3,5.9,7,9.3,11.8,10.9c6.3,2.1,11.8,3.1,17.4,7.1c3,2.3,6.2,4.2,9.6,5.6c10.4,4.4,42-16.8,28.2-25.6c1-9.2-5.7-15.5-15.1-19.2
		c-11.2-4.4-23.4-7.4-22.2-22.4C1174.7,1227.6,1176.4,1221,1171.9,1214.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M842.4,1425.7c-4.2-2.7-8-5.7-12-8.8c-13.2-10.6-22.5-27.1-29.8-42c-10.1-20.9-32.3-27-48-8.3
		c-7.4,8.9-6.4,19.8-5.3,30.9c0.6,6.4-1.5,18.2,3.3,23.5c6.5,7.3,16.4,14.1,26.6,14.2c5,0,17.5-3.3,19.4,4.3
		c1.5,6.2,0.4,12.3-0.1,18.5c-1.5,16.3,13.8,33.5,31.1,29.8c6.8-1.5,12-4,19.1-4.4c1-0.1,3.2,1.5,5.5,1.3c9-0.6,14.2-9,21.9-12.4
		c10.9-4.8,11.9-11,10.9-21.6c-0.9-9.5,7.7-15.2,13.5-21.1c4.4-4.3,8-9.3,10.6-14.8c2.1-4.6,4.4-15.3-3.5-16.5
		c-5.9-0.9-22.2,5.3-24.1-3.3c-3-13.3,15.4-26.2,18.3-38.6c3.7-16.1-20.4-17.9-31.2-17.2c-4.1-1.8-8.6-2.7-12.7-0.8
		c-6.8,3.3-17.3,10.7-19.8,18.3c-1.4,4.4-1.2,9.3,0.5,13.7c3.3,9.7-3.8,12.4-4.1,20.2c-0.6,13.3,17.9,19.4,20.5,32.2
		C854.9,1433.1,847.6,1429.1,842.4,1425.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M762.1,1347c8.1,4.3,16.5,0.3,23.2-7.2c8-8.9,15.3-19.2,28.9-12.7c6.4,3.1,11.9,7.1,19.3,5.1
		c9.5-2.5,25.1-10.4,25-22s-18.5-24.1-27.4-28.6c-15.3-7.7-31.5,7-46.8,1.9c-5.6-1.9-11.2,3.2-14.3,7.1c-4.3,5.2-7.1,9.8-13,13.6
		c-3.2,2-6.1,4.3-8.8,6.9C740.5,1319.4,749,1356.7,762.1,1347z"/>
            <path :fill="colors[`${id}-p-1`]" d="M953.4,1302c-14.7,7.2-1.2,27.1,6.2,35.1c1.2,4.3,3.5,8.4,7.3,10.3c6.8,3.3,19.3,6.7,26.6,4
		c4.4-1.8,8-4.9,10.4-9c5.4-8.6,12-4.8,18.3-9.5c10.8-7.9,3.9-26.2,12.2-36.2c6.7-8,8.2,0.1,8.8,6.2c0.5,4.9,0.7,9.9,0.7,14.8
		c0,16.9-6.9,34.5-14,49.5c-9.9,21-0.7,42.1,23.6,42.5c11.7,0.2,19.5-7.5,27.4-15.2c4.6-4.5,15-10.4,16.2-17.2
		c1.6-9.6,0.7-21.7-5.6-29.6c-3.2-4-13.5-11.6-8.8-17.8c3.9-5,9.3-8,14.5-11.5c13.6-9.1,17.4-31.8,3.7-42.9
		c-5.4-4.4-10.6-6.9-15.4-12.1c-0.7-0.8-0.9-3.5-2.5-5.1c-6.1-6.6-15.9-5.4-23.4-9.2c-10.6-5.4-16-2.3-23.5,5
		c-6.8,6.6-16.6,3.6-24.9,2.7c-6.1-0.8-12.3-0.4-18.2,1.2c-4.8,1.3-14.7,6.2-10.7,13.1c3.1,5.1,18.1,13.9,12.7,20.9
		C986.4,1302.7,964.7,1296.5,953.4,1302z"/>
            <path :fill="colors[`${id}-p-1`]" d="M969.1,1437.3c2.1,5.5,9.5,6.7,14.5,6.7c6.7,0,12.2-0.6,18.8,1.5c3.6,1.2,7.2,2,10.9,2.5
		c11.3,1.2,34.8-28.9,19-33c-1.9-9-10.2-13.1-20.2-13.6c-12-0.7-24.5,0.1-28-14.5c-1.6-6.9-2-13.7-8-18.2
		c-7.9-5.8-23.8-13.1-32.7-5.6s-7.1,29.6-5,39.3C941.9,1418.9,963.5,1422.3,969.1,1437.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M111.4,1187.9c-11,9.3,4.8,23.2,12.9,28.4c1.9,3.4,4.6,6.3,8.4,7.1c6.4,1.3,17.7,1.6,23.4-2.5
		c3.3-2.5,5.7-5.8,6.9-9.8c2.8-8.5,9.1-6.7,13.4-12c7.4-9-2.4-23,2.5-33.2c4-8.3,6.9-1.7,8.8,3.5c1.5,4.1,2.8,8.2,3.8,12.5
		c3.7,14.3,1.6,30.8-1.2,45c-3.9,19.9,8.5,35.9,29.3,31c9.9-2.4,14.8-10.6,19.9-18.8c3-4.8,10.6-12,10-18.2
		c-0.7-8.5-4.2-18.5-11.2-23.8c-3.5-2.7-14-6.9-11.3-13.2c2.2-5.1,6.1-8.8,9.8-13c9.6-10.7,7.9-30.8-6.1-37.1
		c-5.5-2.5-10.5-3.6-15.6-6.9c-0.8-0.5-1.5-2.7-3.3-3.9c-6.6-4.3-14.7-1.2-21.9-2.8c-10.2-2.3-14,1.5-18.8,9.4
		c-4.4,7-13.3,6.5-20.5,7.6c-5.3,0.7-10.5,2.3-15.2,4.8c-3.8,2.1-11.1,8.4-6.2,13.3c3.7,3.7,18.4,7.9,15.2,14.9
		C139.5,1181.4,119.9,1180.8,111.4,1187.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M154,1299.1c3,4.3,9.5,3.7,13.8,2.6c5.6-1.4,10.2-3.2,16.2-2.8c3.3,0.3,6.5,0.2,9.8-0.3
		c9.8-1.5,23.2-31.9,9-32c-3.5-7.2-11.4-8.8-20.1-7.1c-10.3,2-20.8,5.3-26.8-6.3c-2.9-5.5-4.6-11.2-10.8-13.6
		c-7.9-3.3-23-5.9-29,2.3c-5.9,8.2,0.3,26.5,4.2,34.3C127,1289.4,146.1,1287.5,154,1299.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1067.8,1562.5c-14.8,7.2-1.2,27.1,6.2,35.1c1.2,4.3,3.5,8.4,7.3,10.3c6.8,3.3,19.3,6.7,26.6,4
		c4.4-1.8,8-4.9,10.4-9c5.4-8.6,12-4.8,18.3-9.5c10.8-7.9,3.9-26.2,12.2-36.2c6.7-8,8.2,0.1,8.8,6.2c0.5,4.9,0.7,9.9,0.7,14.8
		c0,16.9-6.9,34.5-14,49.5c-9.9,21-0.7,42.1,23.6,42.5c11.7,0.2,19.5-7.5,27.3-15.2c4.6-4.5,15.1-10.4,16.2-17.2
		c1.6-9.6,0.7-21.7-5.6-29.6c-3.2-4-13.5-11.6-8.8-17.8c3.9-5,9.3-8,14.5-11.5c13.6-9.1,17.4-31.8,3.7-42.8
		c-5.4-4.4-10.6-6.9-15.4-12.1c-0.7-0.8-0.9-3.5-2.5-5.1c-6.1-6.6-15.9-5.4-23.4-9.2c-10.6-5.4-16-2.3-23.5,5
		c-6.8,6.6-16.6,3.6-24.9,2.7c-6.1-0.8-12.3-0.4-18.2,1.2c-4.8,1.3-14.7,6.2-10.7,13.1c3.1,5.1,18.2,13.9,12.7,20.9
		C1100.8,1563.2,1079.1,1557,1067.8,1562.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1083.5,1697.8c2.1,5.5,9.5,6.7,14.5,6.7c6.7,0,12.2-0.6,18.8,1.5c3.6,1.2,7.2,2,10.9,2.5
		c11.3,1.2,34.8-28.9,19-33c-1.8-9-10.2-13.1-20.2-13.6c-12-0.7-24.5,0.1-28-14.5c-1.6-6.9-2-13.7-8-18.2
		c-7.9-5.8-23.8-13.1-32.7-5.6c-8.9,7.4-7.1,29.6-5,39.3C1056.3,1679.4,1077.9,1682.8,1083.5,1697.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M455.2,1688.4c13.6-9.1,17.4-31.8,3.7-42.8c-5.4-4.4-10.6-6.9-15.4-12.1c-0.7-0.8-0.9-3.4-2.5-5.1
		c-6.1-6.6-15.9-5.4-23.4-9.2c-10.6-5.4-16-2.3-23.5,5c-6.8,6.6-16.6,3.6-24.9,2.7c-6.1-0.8-12.3-0.4-18.2,1.2
		c-4.8,1.3-14.7,6.2-10.7,13.1c3.1,5.1,18.1,13.9,12.7,20.9c-8.4,10.7-30.1,4.4-41.4,10c-14.8,7.2-1.2,27.1,6.2,35.1
		c1.2,4.3,3.4,8.4,7.3,10.3c6.8,3.3,19.3,6.7,26.6,4c4.4-1.8,7.9-4.9,10.4-9c5.4-8.6,12-4.8,18.3-9.5c10.8-7.9,3.9-26.2,12.2-36.2
		c6.7-8,8.2,0.1,8.8,6.2c0.5,4.9,0.7,9.9,0.7,14.8c0,16.9-6.9,34.5-14,49.5c-9.9,21-0.7,42.1,23.6,42.5c11.7,0.2,19.5-7.5,27.3-15.2
		c4.6-4.5,15.1-10.4,16.2-17.2c1.6-9.6,0.7-21.7-5.6-29.6c-3.2-4-13.5-11.6-8.8-17.8C444.6,1694.8,450,1691.8,455.2,1688.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M334.1,1738.3c-7.9-5.8-23.8-13.1-32.7-5.6c-8.9,7.4-7.1,29.6-5,39.3c3.7,16.8,25.3,20.2,30.9,35.2
		c2.1,5.5,9.5,6.7,14.5,6.7c6.7,0,12.2-0.6,18.8,1.5c3.6,1.2,7.2,2,10.9,2.5c11.3,1.2,34.8-28.9,19-33c-1.8-9-10.2-13.1-20.2-13.6
		c-12-0.7-24.5,0.1-28-14.5C340.6,1749.7,340.2,1742.9,334.1,1738.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1272,1354.9c-1.8,8.8,4.9,16.1,6.1,24.4c1.8,11.8,7.4,14.3,17.8,16.2c9.4,1.7,12.7,11.5,16.7,18.7
		c2.9,5.4,6.8,10.2,11.5,14.1c3.9,3.2,13.6,8.3,16.8,1.1c2.4-5.5,0.8-22.8,9.6-22.4c13.5,0.7,21.2,21.9,32.3,27.9
		c14.5,7.8,22.7-14.8,24.9-25.5c2.8-3.5,4.8-7.6,4.1-12c-1.3-7.4-5.7-19.6-12.4-23.9c-4-2.5-8.6-3.7-13.3-3.2
		c-10.2,0.6-10.9-6.9-18.4-9.3c-12.8-4.2-23.5,12.2-36.5,11.2c-10.5-0.8-4.6-6.7,0-10.8c3.8-3.3,7.6-6.3,11.7-9.2
		c13.7-9.9,32.1-14.5,48.4-17.5c22.7-4.3,34.7-24,20.8-44.1c-6.6-9.6-17.4-11.5-28.3-13.3c-6.3-1.2-17.2-6.2-23.5-3.2
		c-8.8,4.3-18,12.1-20.7,21.9c-1.4,4.8-1.5,17.8-9.3,17.5c-6.4-0.2-12-2.9-17.8-5c-15.3-5.7-36,4.4-37,22.1
		c-0.4,6.9,0.6,12.7-0.9,19.6C1274.5,1350.9,1272.5,1352.6,1272,1354.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1423.8,1377.5c-0.1,9.8,3.3,27,14.5,29.9c11.3,3,28.2-11.5,34.8-18.8c11.5-12.8,1.7-32.2,10.6-45.6
		c3.4-4.9-0.1-11.7-3-15.7c-3.9-5.4-7.6-9.5-9.7-16.1c-1.1-3.6-2.6-7-4.4-10.3c-5.6-9.8-43.7-11.5-38,3.9
		c-6.2,6.7-4.6,15.8,0.7,24.3c6.4,10.2,14.4,19.9,4.4,31.2C1429.2,1365.7,1423.9,1370,1423.8,1377.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M315.8,1478.8c0.4,3.4,2.3,6.5,3.7,9.9c2.6,6.4,5.5,11.1,11.7,14.5c12.7,7.2,27.8,2.1,34.8-10
		c4.6-7.9,3.9-28.2-5.7-32.7c-4.9-2.4-8.7-1.2-13.5,1c-8.7,3.9-17.7,4.3-27.1,4.3c-1.7,0-3.7,1.4-3.5,3.3c0.1,1.2,0.3,2.3,0.5,3.5
		C313.5,1473,313.2,1477.7,315.8,1478.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M284.5,1455.8c-8.5,1.9-15.7,6.9-24.2,9c-6.6,1.6-19,3.1-23.7,8.8c-4.4,5.2-3.9,9.3-5.4,15.7
		c-2.3,9.1-9.2,6.3-12.1,12.9c-3.9,8.7,4.9,18.4,12.6,21.6c11.7,4.9,26.6,2.5,38.6,0.9c9.2-1.2,27.9-1.4,29.8-13.5
		c1.9-11.8,14-18.3,11.8-31.3c-0.7-4-2.7-7.6-5.6-10.3C302.4,1460.5,295.4,1453.4,284.5,1455.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M387.4,1546.9c12.5-14.6-1.1-40.1-20.5-37.4c-3.8,0.5-6.7,3.7-9.2,6.2c-4,4.2-8.1,4.7-13,6.9
		c-3.7,1.7-4.8,5.6-5,9.9c-4.4,1.7-8.4,4.4-10.2,8.9c-1.5,3.5-0.2,7.5,0.1,11.1c0.6,7.1-4.1,12.5-9.4,16.5
		c-6.4,4.8-13.8,9.5-20.9,2.3c-3.6-3.6-6.2-7.9-9.8-11.5c-5.7-5.8-12-7.3-19.9-7.9c-15.6-1.2-37.2-2.9-50.9,6.2
		c-15.9,10.6-3.1,34.5,6.9,45.2c6.1,6.6,13,10.5,22,7.6c6.2-2,13.3-2.8,17.2,4.1c2,3.6,3.2,7.9,5.8,11.1c7.2,8.5,20.3,9.4,30.7,10.3
		c9.4,0.8,18.8-1.8,24.9-9.3c3.5-4.3,6.4-11,11.2-14c5.2-3.5,10.5-2.3,16.3-1.8c7,0.5,13.6,0.4,18.2-5.6c6.4-8.7-0.1-19.5-3.1-27.9
		C362.7,1559.9,378.6,1557.4,387.4,1546.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1255.8,1538.2c-2.7,3.1-4.4,6.8-4.7,10.8c-0.9,11.1,4.2,21.2,12.9,28.1c8,6.4,17.9,6.8,26.3,0.9
		c7.6-5.3,9.2-15.5,10.7-24c3.3-18.7,20.8-13.6,35.4-13.6c3.7-0.1,7.3-1.1,10.5-3c2.2-1.2,4.3-2.7,6.5-3.7c1.4-0.6,5.8-3.7,5.8-1.8
		c0,3.8,5,4.3,6.4,1.6c2.6,5.6,4,13.1,5.1,16.4c2.7,7.3,5.8,12.5,11.8,17.6c14.4,12.3,39.1,5.2,41-14.7c1-9.4-15.8-19.8-20.2-26.9
		c-4.4-7.4-3.3-19.1-13.1-21.4c-9.2-2.1-20.6-10.1-22.3-20.3c-0.8-4.7-0.9-9.9-2.2-14.5c-2.3-8.4-7.2-12.9-15.8-14.6
		c-10.3-2.2-21.2,3-29.2,8.9c-3,2.2-5.4,5.1-7.2,8.4c-3,5.5-3.3,8.1-10.8,5c-11.1-4.6-15.9,0.2-21.3,10.3
		c-4.1,7.7-19.3,16.3-18.5,26.4c0.4,5.2,2.7,11.9-0.7,16.6C1260.4,1533.3,1258.1,1535.8,1255.8,1538.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1279.7,1653.9c0.7,10.9,27.8,7.9,33.2,3.9c3.7-2.8,5-7.1,8.5-9.9c3.4-2.8,8.5,3,10.1,5.3
		c3.3,4.6,5.7,10,9.4,14.3c9.2,11,20.8,5.4,28.5-4.4c3.2-4.4,7.2-7.9,11.9-10.6c3.1-1.8,6.9-4.1,8.9-6.9c1.5-1.9,2.2-4.3,2.1-6.7
		c0.4-0.6,0.6-1.3,0.5-2c-1-13.8-14.2-25.5-28.5-24.6c-10.9,0.7-22.8,14.3-28.1-2.1c-1.9-5.8-4-14.7-9.1-18.8
		c-12.2-9.4-30.3-6.9-38.9,5.8c-6.3,9.4-12.2,18.2-11.4,29.9c0.3,5,2.6,9.7,3.5,14.5C1281.5,1648.5,1279.4,1649.1,1279.7,1653.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1203.5,1380c-13.7,11.2-6.7,28.1-13.8,41.8c-9.2-1.5-17.6-2.9-25.7-8c-6.4-4.2-11.9-6.4-19.5-4.6
		c-12.4,3-10.4,15.9-7.8,25.2c1.7,6,2.9,12.2,5.3,17.9c2.1,4.9,8,12.2,13.6,13.2c2.2,0.4,4.4,0,6.6,0c5.1,0.2,4.6,3.5,8,5.3
		c4.4,2.2,8.2,5.1,11.4,8.8c2.1,2.7,5,4.4,8.3,4.8c7.5,1.3,13.3-1.2,19.6-5.3s9.4-15,17.3-15.8c11-1,22.9,10.8,31.2-2.5
		c1.2-1.9,1.1-4.6,1.1-6.6c-0.1-6.2,3.8-4.7,7.9-5.5c8-1.5,12.2-7.5,16-14.3c3.8-6.5,2-13.1-3.1-18c-4.4-4.3-13.5-2.7-16.8-7.8
		c4.8-1.5,6-3.1,7.1-9.5c0.9-5.6-6.9-9.4-11.2-10.6c-4-1.2-2.6-1.9-3.6-5.1c-1.5-5-2.2-6.7-6.4-9.9c-6.6-4.8-16.2-6.4-23.6-3.1
		c-1.2,0.5-1.8,1.8-1.3,3c0.1,0.2,0.1,0.3,0.2,0.5C1218.8,1369,1209.1,1375.4,1203.5,1380z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1134.1,1380.4c7,6.7,16.6,9.5,25.5,4.8c2.7-1.4,5.1-3,7.6-4.7c2.6-1.9,7.4-1.6,9.4-3.9
		c4.3-4.9,7-9.1,13.1-12.1c8.2-4.1-1.6-24.9-3.5-29.9c-0.9-2.5-2.4-4.7-4.4-6.6c-5.6-8.2-17.7-8.9-26.1-4.9
		c-5.8,2.8-9.3,9.9-16.5,9.2c-4.7-0.5-8.9-2.9-13.8-2.7c-10.7,0.3-10.4,17.2-9.1,24c1.3,6.1,1.9,12.5,3.4,18.5
		C1120.8,1376,1131.9,1378.3,1134.1,1380.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1134.9,1373.5c7.1,7.6,15.9,11.6,25.6,4.9c2.8-2,5.1-4,8.4-4.8c5.1-1.5,5.6-4.8,8.7-8.3
		c2.2-2.6,4.4-3.6,7.5-4.6c4.4-1.6,0.3-10-0.7-13.3c-1.2-4.1-2.1-9.6-4.6-13.3c-0.6-0.2-1.1-0.6-1.4-1.2c-0.2-0.3-0.4-0.6-0.6-0.9
		c-5-4.2-10.6-4.8-16.8-3.4c-0.9,0.3-2,0-2.7-0.7c-3,1.7-5,3.9-7.8,5.9c-5,4-9.8,4.7-16,3c-6.2-1.8-13.1-4.6-13.2,6.5
		c-0.1,4.4,0.3,8.8,1.2,13.1c0.7,3.7,1.1,7.4,1.8,11.2C1125.3,1372.3,1132.3,1370.9,1134.9,1373.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M369.2,1400.2c-2.5,6.7-0.9,14.9-0.9,22.1c0,5.2,5.7,6.1,9.6,5.5c1.6-0.2-1.2,11.5-0.9,13.2
		c0.7,4.2,2.1,5.8,5.7,8c5.3,3.4,12.5,0.9,17.6-1.4c4.9-2.2,15.5-7.6,18-12.5c2.7-5.1,9-4.8,7.4-12.6c-0.5-1.5-1.6-2.8-3.1-3.3
		c-6.4-3.4,5.1-12.8,6.7-15.4c1.1-1.6,0.7-3.8-0.9-4.8c-0.2-0.2-0.5-0.3-0.7-0.4c-2.5-1.1-4.8-2.1-7.4-3c-1.2-0.4,9.5-9.8,10.5-10.3
		c5.2-2.9,19.4-10.6,21.2-0.5c1.2,6.5,5.1,7.3,9.6,5.5c1.9,2.3,4.6,2.7,7.7,2.1c-0.1,0.4-0.2,0.9-0.3,1.3c-0.7,1.8,0.3,3.8,2.1,4.4
		c0.3,0.1,0.5,0.2,0.8,0.2c9.2,0.9,13,10.8,23.1,9.3c8.2-1.2,13-8.6,14.5-16.1c2.8-6.6,2.7-15.1,2.8-21.5c0-1.7-1.1-3.8-3.1-3.8
		c-4.5,0-8.7,0.3-12.3,2.2c-1.4-1.4-3.3-2.3-5.2-2.4c-2.6-0.1-5.1,0.2-7.6,0.8c-6.8,1.2-6.6-1.3-4.1-7.4c0.5-1.6-0.1-3.3-1.5-4.2
		c1.6-9.4,0.3-14.9-9.8-16.2v-5.6c0-1.7-1.2-3.8-3.1-3.8c-2.7-0.1-5.4,0.2-8,1.1c-4.3-7.3-11.4-13-18.9-16.6
		c-8.8-4.3-15.9-2.7-24.1,2.2c-6.7,4-14.4,10.6-17.6,18c-1.3,2.7-1.8,5.6-1.5,8.5c0.3,2.4,6.7,6.3,8.8,5.4c-1.4,0.7-2.2,2.2-1.9,3.7
		c0.3,1.2,4,12.3,3.3,12.9c-4.5,4.6-7.1,7.4-7.8,13.3c-9.3,1.4-21.5,6.7-14.7,13.8C378,1393.8,371.7,1393.6,369.2,1400.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M425.6,1485.4c14.4,3.1,30.3,3.8,38.3-10.4c3.7-6.6,5.1-14.1,4.3-21.7c-0.9-8.5-10.7-10.5-17.4-12.5
		c-13.4-4-22.1,1.7-31,11.2c-0.8-0.8-2-1.1-3.1-0.8c-7.4,1.9-9.4,9.5-11.7,16c-0.6,1.8,0.5,3.8,2.3,4.4c0.3,0.1,0.6,0.1,0.9,0.2
		c4.6,0,19.6,0.7,15.5,8.4C422.8,1482.1,423.3,1484.9,425.6,1485.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M483.6,1459.2l4.4,6.5c2.8,4.2,7.2,6.3,11.7,4.1c5.9-3.1,14.2-0.2,19.3-4.8c6.4-5.8,7.5-13.5,5.9-21.7
		c-1.2-6.1,0.8-9.6-4.2-13.3c-4.9-3.7-3.2-4.4-4.5-8.8c-1.4-4.4-6.7-7-11.2-6c-6.3,1.4-12.4,8.3-17.5,12.1c-7,5-12.8,11.9-11.5,20.9
		c0.5,3.6,5.2,4.1,6.5,1.7C483.4,1452.1,482.2,1457.2,483.6,1459.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M623.5,1163.1c-4.4,5.5,6.3,16,11.3,17c6.6,1.4,11.8,0.5,15.3-3.2c6.1,8.9,23.5-12.8,29.1-18.7
		c1.2-1.5,1-3.7-0.5-4.8c-2.3-2.1-3-10-3.3-14.9c0.1-0.4,0.1-0.8,0-1.2c0-0.9,0-1.6,0-2.1c-0.1-1.1-0.6-2.1-1.3-2.9
		c-0.3-4.2,2-7.9,3.6-11.9c1.9-0.7,2.8-2.9,2.1-4.7c-0.1-0.2-0.2-0.4-0.3-0.6c0.6-2.4,1.6-4.5,2.8-6.6c3.3-5.1,6.9,1.2,7.1,4.5
		c0.1,3.2,4.3,4,6.1,1.8c2.8-3.3,17.5-6.6,16.8,1.9c-0.3,1.9,1,3.6,2.9,4c13.3,3.1,9.3,23.7,1.7,32.3c-1.1-0.3-2.2-0.2-3.2,0.4
		l-0.7,0.4c-1.5,1.1-1.8,3.1-0.9,4.6v0.1c-0.3,5.7-2.4,11.3-5.9,15.9c-3,4-9.3,3.2-12.2,7.2c-4.8,6.7-8,12.9-6.7,21.5
		c2.9,18.8,31.2-0.6,40.1-2.2c1.4-0.2,3-1.3,3.1-2.9c0.2-4.1,8.8,0.2,12.1,0.5c1.5,0.2,3.1-0.6,3.7-2c2.5-7.2,4.6-13.7,4.8-21.5
		c0.1-3.7-5.6-14.9-0.7-15.4c2.3-0.3,4.3-3.5,2.4-5.4c-4.7-5-1.9-6.1,3.1-8.5c1.8-0.7,2.7-2.7,1.9-4.4c-0.1-0.3-0.2-0.5-0.4-0.8
		c-3.5-4.6-2.8-1.7,0.7-4c2.8-1.8,4.1-3.8,5.6-6.6c2.8-4.9-1.9-9.2-5.8-12.5c5.1-5.2,2.2-11.5-4.1-14.8c2-3.6-1.4-8.3-5.7-11.9
		c1.9-1.4,2.7-3.5,1.2-6.4c-2.5-4.4-7.7-6.3-12.1-8.8c3-3.6,4.7-7.9,0-11.3c-5-3.5-21.7-1.9-22.4-9c-0.2-1.5-1.3-2.8-2.9-3.1
		c-4.6-0.5-9.1-1.8-13.7-2.3c-3.9-0.4-6.6,1.5-10.6,0.3c-1.4-0.6-3.1-0.2-4.1,1c-1,1.4-4.9,9.3-7.9,3.2c-0.7-1.8-2.8-2.6-4.4-1.9
		c-0.5,0.2-1,0.6-1.4,1c-1.3,1.5-5.9,10.9-7.5,10.7c-3.6-0.5-5,0.2-7.9,2.5c-1.6,1.2-1.9,3.2-2.1,4.9c-0.2,2.1,0.1,4.2,0,6.2
		c-0.4,5.4-7-1.5-7.5-2c-2.6-2.6-6.4,0.9-5.3,4c2.4,6.8,3.5,13.3-4.9,16.1c-2.3,0.7-2.6,3.5-1.5,5.2c3.3,5.3,5.8,11.1-1.6,15.8
		c-2.1,1.3-1.8,5,0.6,5.9c14.9,5-4.8,13-1.2,22.4c1.2,3.1,3.3,4.7,5.8,6.7C638.1,1153.3,625,1161.3,623.5,1163.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1378.2,1911.9c10.9,1,21.4,1.2,30.9-4.9c9.2-5.9,15.5-15.8,19.2-25.9c2.9-8,3.8-19.3-2.2-26.2
		c-6.7-7.7-17.5-8.5-27.1-8.5c-12,0-30.7,3-41.7,11.8c-1.6-1.1-3.9-0.7-4.9,1c-0.3,0.5-0.5,1.1-0.6,1.7c-0.1,1.5-0.3,2.9-0.7,4.3
		c-1.7,3-2.7,6.2-2.9,9.6c-2.9,8.5-5.5,16.8,0.3,25.3C1354.7,1909.1,1368,1911,1378.2,1911.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1408.3,1969.7c6.6,0.4,12.2,6.3,18.9,7.2c12.9,1.9,28.3-6.4,38.8-13.3s20.8-18,26.1-29.6
		c5.1-11.2,1.5-21.4-9.3-26.3c-8-3.7-24.2-10.4-33.1-5.3c-6.7,3.8-12.4,9.1-16.8,15.4c-3.9,5.4-16.6,8.1-22.6,11.3
		c-4.3,2.1-8.1,5-11.2,8.7c-1.3-1.5-3.6-1.7-5-0.4c-0.2,0.2-0.4,0.4-0.5,0.6c-6,8.3-6.2,23.1,2.2,29.9
		C1398.7,1970.4,1404.6,1969.4,1408.3,1969.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M722.5,1281.7c-5.3-15.1-34.3-19.6-47.4-9.4c-1.2-1.6-3.5-1.9-4.9-0.7c-0.4,0.3-0.8,0.8-1.1,1.3
		c-4.1,8.5-9,16.5-12.9,24.9c-2.8,6.1-4.4,9.7-10.9,12.8c-3.2,1.4-6.2,3.1-9.2,4.8c-11.2,7.6-2.9,20.6,7.1,23.1
		c6.9,1.8,13.9,2.1,21,3.1c5.7,0.8,7,12.3,13.4,14.6c7.1,2.7,14.1,1.7,21.3-0.5c5.9-1.8,15.6-6.1,19-11.6
		c6.6-10.7-21-13.7-11.6-28.6C713.4,1304.4,727.8,1297,722.5,1281.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M675.6,1284.9c-0.2,2.9-3.2,3.5-5.1,2.3c-1.2,2.9-2.7,5.5-4.6,8c-3.8,4.6-4.6,10.6-8.1,15.3
		c-2.7,3.7-8.2,5.4-12.1,7.3c-4.4,2.1-12.2,9.3-4.3,12.7c2.3,1,4.5,1.8,7,2.2c7.4,1.3,19.3-0.3,24.4,6.1c2,2.5,2.3,6.1,4.6,8.4
		c4,4.2,9.5,4.3,14.5,3.2c5.7-1.3,15.3-5,19.6-9.2c0.2-0.2-7.2-5.7-8.5-7.1c-3-3.1-4.9-7-5.5-11.2c-1.5-8.8,7.2-15.9,12.4-21.7
		C735.5,1272.4,677,1265.6,675.6,1284.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M679.6,1241.7l3.2,6.3c0.6,1.4,2.1,2.3,3.7,2.1c10.7-0.7,20.6-6.3,31.3-3c4.8,1.6,6.9,7.8,10.2,11.3
		c8.5,8.9,26.7,5.5,37.6,4.6c12.9-1.1,28.5-5.1,35-17.5c5.2-10,4.5-21.8-5.9-27.8c-14.8-8.6-27,5.1-38.7,12.4
		c-13.9,8.6-30.4,2.3-44.9-2.6c-8.3-2.8-28-10.6-39.8-7.4c-0.7-0.9-1.9-1.3-3.1-1.1c-9.7,1.7-9.8,12.1-9.8,20.2c0,1.7,1,3.8,3,3.8
		C663.1,1243.3,680.4,1243.4,679.6,1241.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M685.7,1238.5l2.3,4.6c11.8-1.5,22.6-7,34.2-1.4c8.3,4.1,8.2,14.7,19.1,15.2c13.3,0.6,30.3,1.2,42.5-5.1
		c6.4-3.3,13.2-9.3,13.3-17.2c0.1-11.9-13.3-15.5-22.2-9s-20.3,16.8-32.3,16.5c-7.8-0.2-16.2-2.4-23.7-4.6
		c-8.6-2.7-49-20.4-49.9-4.1c0,1.7-1.4,3-3.1,3c-0.1,0-0.3,0-0.4,0v0.1h17.6C684.2,1236.7,685.2,1237.5,685.7,1238.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M793.8,1175.2c3.4-0.4,7.4-3.5,9.5-6c4.1-5.2,1.6-11.1,5.6-16.5c6.2-8.5,13-16.1,15.9-26.4
		c2.7-9.3,3.5-20,1.2-29.5c-2-8.1-9-10.7-16.7-9c-3.3,0.8-6.2,2.5-8.7,4.8c-2.1-0.5-4.2,0.7-4.6,2.8v0.1
		c-4.6,22.4-28.6,42.6-21.6,67.1C776.8,1171,785.1,1176.4,793.8,1175.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M799.3,1159.7c0.1-2.1,0.5-4.1,1.1-5.9c5-12.4,16.4-19.7,18.7-33.6c1-6.3,2.1-13.6,0.6-20
		c-3.7-14.9-19.6,0.5-20.5,9.5c-0.1,1.5-1.2,2.7-2.6,3c-3.5,7.1-7.6,14-11,21.3c-3.4,7.2-7.7,16.6-5.3,24.9
		C783.2,1168.9,797.8,1172.3,799.3,1159.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1099,1108c6.6,4.1,10.6,10.4,16.6,14.5c4.5,3,9.7,5,15,5.9c7.7,1.3,11.9-1.5,18.8-2.4
		c7.8-1,15.3-6.4,23.2-8.1c5-1.1,8.6-2.1,12-6.1c6.8-8.2,0.6-20.5-7.8-23.7c-6.1-2.3-13.8-1.4-20.2-1.7c-6.3-0.3-10.9-3-16.4-6.1
		c-11.4-6.5-34.5-21.5-49.4-16.1c-0.6-2-2.6-3.1-4.5-2.5c-0.4,0.1-0.9,0.3-1.2,0.6c-9.9,7.9-16.9,17.6-8.5,29.1
		C1081.6,1098.4,1091.7,1103.7,1099,1108z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1368.2,1158.4c9.5-2.1,17.1,2.5,26.3,3.6c20.2,2.3,35.6-30.9,36.2-46.6c0.6-8.5-4.8-16.2-13-18.6
		c-12.3-4-24,3.7-32.2,11.9c-6.7,6.7-10.1-0.6-17.3,0.9c-5.1,1.2-11,4.4-15.6,6.9c-3.3,1.6-6.1,4-8.2,6.9c-1.7,0-3,1.4-2.9,3.1
		c0,0,0,0,0,0.1c0,13.5,2.9,23.4,15.2,30.5C1360.5,1159.1,1363.9,1159.2,1368.2,1158.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1296.7,1274.3c-2,7.3,3.5,18.1,12.2,17.4c11.5-0.8,18.1-12.6,24.3-20.9c3.4-4.6,6.2-9.6,8.6-14.7
		c3.8-7.5,11.7-11.9,14.6-20.1c3.4-9.5,3.9-21.5-4.4-28.6c-7.6-6.6-18-5.6-25.4-0.5c-1.4-1.6-4.1-2.1-5.4,0.3
		c-3.3,5.7-4.9,12.3-4.7,18.9c0,6.2,2.4,14.2-1.4,19.9C1308.7,1255.4,1299.8,1262.8,1296.7,1274.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1377.5,1253.9c1.7,8.4,21.7,8.3,27.8,11.2c2.6,1.2,2.3,8.8,5.8,11.8c4,3.3,7.6,1,11.1-1.7
		c4.7-3.8,13.9,0,19.5-3.7c5.2-3.6,6.5-8,7.5-14.1c2.7-15.2,2.2-31.5,1.8-47c-0.3-11.8-3.5-25.5-16.5-28.8
		c-7.6-1.9-16.7-0.9-24.9,2.1c-1-1.8-3.3-2.5-5-1.5l0,0c-10.7,6-23.9,19.4-26.2,31.9c-1.4,7.6-0.2,15.3,0.5,23
		C1379.3,1242.6,1376.6,1248.8,1377.5,1253.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1392.7,1483.5c3.6,0.5,6.9,1.9,10.5,2.8c0.7,7.4,3.8,16.6,10.6,20.2c11.2,5.8,23.4-2.1,32.6-8
		c8.2-5.2,19.6-1.6,28.7-1.2c5.2,0.2,10.5-0.8,15.3-2.8c9.9-4.1,14.9-17.2,17.8-26.6c5.2-16.6,0.3-49.3-23.2-47.4
		c-15.2,1.3-34.3-2.4-47.6,6c-2.4,1.5-4.6,3.5-7,5c-2.8,2-6.9,2.5-9.2,4.8c-5.1,5.4-6.9,5.9-14.7,5.9c-1.3,0-2.5,0.7-3.1,1.8
		c-12.3,5.8-17.3,24.2-14,36.8C1389.9,1482.3,1391.2,1483.4,1392.7,1483.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1395.7,1477.2c3.7,0.9,7.1,2.4,10.9,2.9c1.9,0,3.4,1.6,3.4,3.5c0,5.8,1.6,9.2,4.8,14c4.2,6.1,13.8,4,19.4,0.6
		c10.7-6.6,16.7-10.1,29.6-8.7c11.8,1.3,25,2.8,31.6-9.3c4.7-8.7,8.5-18.3,7.9-28.4c-0.6-12.1-6.4-26.3-20.7-24
		c-11.5,1.8-29.5-3.2-39.4,3.2c-7.6,4.8-14.9,10-22.1,15.5c-3.4,2.7-7.3,2.8-11.4,2.8v6.9c0,4.4-6.9,4.4-6.9-0.1v-3.8
		C1396.9,1457.8,1394.5,1469.5,1395.7,1477.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1441.3,1581.2c8.6,13.4,22.1,1.5,27-7.6c6.2-11.4,13-26.5,7.9-39.1c-3.6-8.8-16.2-13-24.5-9.7
		c-1.7-0.2-3.3,0.8-3.8,2.4c-2.1,2-3.4,4.7-3.6,7.6c-0.2,1.3,0.5,2.5,1.6,3C1441.6,1552.3,1432.3,1567.2,1441.3,1581.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1459.5,1574.9c4.8-7.4,7.7-15.8,10.4-24.1c1.4-4.4,2.9-8.5,0.4-13c-2.1-3.8-5-7.1-9.9-6.9
		c-2.1,0-4.2,0.2-6.1,0.8c-1.3,8.9-5.5,17.1-7.8,25.7c-1.7,6.2-4.6,17.7,2.8,21.8C1453.5,1581.5,1457,1578.6,1459.5,1574.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1426.6,1655c10.9,0.5,21.9,5.3,32.7,4.4c9.1-0.8,17.2-6.3,24.6-11.4c11.6-8,18.5-23.6,6.9-34.7
		c-12.6-12-47.8-13.2-61.8,1c-1.1,0.3-1.9,1.2-2,2.3c-1.6,2.1-2.8,4.5-3.4,7.1c-0.7,2.8,1.9,4.1,4.1,3.6c-0.7,7.9-3.6,15.8-4.1,23.8
		C1423.6,1652.9,1424.7,1654.9,1426.6,1655z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1452.8,1652.1c8.7,1,15-1.9,22.2-6.4c9.2-5.7,24.3-21.6,8.1-29.4c-10.2-4.9-38.5-8.8-49,1.8
		c2,10.4-2,20.2-3.4,30.4C1438.3,1649.2,1445.4,1651.3,1452.8,1652.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1054.8,1488.5c3.5,6.6,12,15.3,19.6,16.8c10.7,2.1,19.6-2.5,27.5-10c9.1-8.7,18.6-13.4,19.5-27.5
		c0.6-9.2-1.9-15.6-10.9-18.9c-9.7-3.6-18.9-8-29.2-10.1c-17.7-3.5-36.8,1.7-32.3,23.2c0.2,1.6,1.7,2.7,3.3,2.5
		c0.2,0,0.4-0.1,0.6-0.2C1051.2,1472.4,1051,1481,1054.8,1488.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1008.1,1598.7c-1.1,1-2.1,2-2.9,3.1c-3.9,4.8-6,10.9-6.2,17.1c0,13.1,15.3,17.2,24.6,9.4
		c8.9-7.6,20.1-23.3,15.9-35.7c-2.5-7.1-10.9-7.5-16.8-5.3c-3.4,1.4-6.3,3.5-8.8,6c-2.1,0.1-4.1,0.7-4.9,2.8
		C1008.2,1597.7,1009.3,1597.7,1008.1,1598.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M889.6,1524.3c3.6-10.9-9-12.2-16.4-9.7c-7.4,2.6-11.5,8.6-13.5,15.6c-10.2,7.1-13.8,23.4-13.3,34.8
		c0.3,6.5,4,10.1,9.4,13.3c8,4.7,0.5,15.9,2.6,23.5c7.4,27.5,64.5,15.4,46.7-9.3c-10.1-14-39.4-18.4-33.2-39.9
		C874.8,1542,886,1535.3,889.6,1524.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M857.7,1571.5c7.1,4.3,12,8,9,16.9c-3,9.1-1.9,16.8,8,20.6c5.1,1.9,11,3.3,16.3,1.5c23.2-7.7-0.5-21.8-10.2-28
		c-17-11-23-26.4-8.9-43.3c2.6-3.2,17.4-21.2,5.9-18.6c-4.9,1-9.1,4.4-10.9,9.2c1.1,1.4,0.8,3.5-0.6,4.4c-0.2,0.2-0.4,0.3-0.7,0.4
		c-0.1,0-0.2,0.1-0.3,0.2c-1.3,5.7-1.2,12.2-1.2,17.5c0,4.4-6.9,4-6.9-0.5c0-2.7,0-5.5,0.2-8.4
		C852.3,1552.9,849.9,1567,857.7,1571.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M446.1,1853.2c15.2-10,28.8-31.8,14.1-48.1c-10.3-11.4-23-6.4-32.6,2.7c-15.4,8.2-32.6,21.6-24.6,40.9
		C410.6,1867,433.5,1861.4,446.1,1853.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M415.9,1823.7c-6.3,5.6-10.6,12.7-7.1,21c5.4,13.3,21.5,10.8,31.3,4.3c9.9-6.4,24.7-20.5,18.5-34
		c-5.8-12.8-15.4-10.7-23.5-4.3c-0.3,0.9-0.9,1.5-1.8,1.9l-1.2,0.6c-4.4,4.3-8.1,9.3-10.7,14.8c-1.8,4.1-8,1.2-6.2-2.9
		C415.4,1824.7,415.6,1824.2,415.9,1823.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M479.5,1742c8,9.7,26.2,1.3,36.3,2.7c7.6,1,14.9,2.7,22.6,3c3.6,0.1,8.9-4.6,11.5-6.6
		c8.9-6.9,14.2-15,12.1-26.6c-1.7-8.8-18.7-13.4-25.4-16.3c-17-7.2-38.5-12.8-52.9-2.5c-2.7,1.8-4.9,4.1-6.7,6.7
		c-1.3,1.8-2.5,3.8-3.4,5.7c-0.4,0.7-0.5,1.5-0.3,2.3C470.3,1721.1,472.7,1733.8,479.5,1742z"/>
            <path :fill="colors[`${id}-p-1`]" d="M499,1738.9c7.2-1.5,14.6-1.6,21.9-0.3c10.7,2,17.9,3.5,26.4-4c9.1-8,12.4-18,0.1-24.1
		c-6.2-3.3-12.7-6.1-19.3-8.4c-11.9-3.8-25-7.4-35.4-3.3c-0.4,0.4-0.8,0.6-1.3,0.8c-0.9,0.3-1.7,0.7-2.5,1.2
		c-1.3,0.8-2.5,1.8-3.6,2.9c-4.6,4.9-6.9,13-6.6,19.3C479,1735.4,486.9,1741,499,1738.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M435.2,1592c4.4-6.6,5.9-15.4,7.6-23.1c1.4-6.5,1.5-19.3,8-22.7c11.4-6.1,26.4-0.5,36.7-10.2
		c9.2-8.6,19-14.5,20.1-28.3c0.1-1.6-0.9-3-2.3-3.5c-17.5-3.7-36.5-1-54.2,0c-4.2,0.2-8.3,1.3-12.1,3.2c-1.2-0.1-2.3,0.5-2.9,1.6
		c-0.1,0.2-0.3,0.3-0.4,0.5c-2.3,1.8-4.3,4-5.8,6.4c-3.8,3.3-8,6.1-11,10.2c-3.2,4.4-5.9,8.9-8.2,13.8c-5.6,12.1-9.1,25.1-6.1,38.4
		C407.5,1591.5,424.8,1607.4,435.2,1592z"/>
            <path :fill="colors[`${id}-p-1`]" d="M782.9,1718.2c10.1-17.6,5.6-35.4-13.3-43.3c-10.4-4.4-24.1-3.2-27.9,6.9c-4.4,4.1-7.6,9.5-10.9,14.8
		c-5.5,9-5.4,20.4,0.4,29.2C745.7,1748.1,772.2,1736.8,782.9,1718.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M532.6,1347.9c-3.1,4-5.4,8.4-7,13.2c-2.8,9.6,0.7,20.1,10.4,24.5c16.1,7.5,30.6-6.6,35-21.6
		c3.8-12.7-2-23.1-14.7-27c-2.5-0.8-4.9-1.2-7.5-1.3c-0.2,1.7-1.5,3.1-3.3,3.3c-2.4,0.4-4.7,1.4-6.6,2.9c-0.3,1.3-0.4,2.7-0.2,4.1
		C538.9,1349.4,534.6,1350.1,532.6,1347.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M879.1,1651.9c4.4-13.8-7.9-25.9-21.5-22.8c-4.9,1.3-9.4,3.9-13.1,7.4c-7.2,1.8-12.7,6.8-17.8,12.3
		c-6.2,6.6-15.3,15.2-18.9,23.6c-4.3,10.2-4.2,20.8-5.1,31.6c-1.3,12.7-9.1,23.3-9.1,36.3c0,27.4,55.1,64.2,62.2,23.9
		c1.8-9.3,1-19-2.5-27.8c-5-13.8-17.2-30.9-8.2-45.6C854.5,1675.8,873.5,1670,879.1,1651.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1236,1652.6c-10.4,12.4-28.5,13.7-40.9,23.5c-13.5,10.7-7.2,25,0.6,36.8c7.8,11.8,2.2,25.6,20.7,26
		c9.3,0.2,19.1-1.3,28.5-1.8c7.3-0.3,14.5-2.1,21.3-5.2c8.4-3.9,23-14.4,21-25.4c-0.7-3.6-4.7-6-7.4-7.8
		c-6.9-4.4-11.5-8.1-14.7-16.1c-2.9-7.2,6.9-22.4-4-26.3c-6.2-1.9-12.9-2-19.2-0.4C1242.2,1652.9,1238.5,1649.7,1236,1652.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1228.5,1666.9c-4.1,2.2-8.5,3.9-12.9,5.6c-5.9,2.5-13.3,5.1-17.7,10.3c-7.1,8.4-3.6,15.9,1.7,23.7
		c3.3,4.9,6.6,9.7,8.6,15.3c1,2.8-2.5,8.9,2.9,9.7c6.9,1.1,13.5-1.2,20.4-1.2c5.4,0.2,10.9,0,16.2-0.5c7.3-0.7,14.2-3.2,20.3-7.3
		c2.4-1.7,16.1-11.8,11-15.9c-5.3-4.4-11.4-6.9-15.9-12.4c-4.5-5.7-6.5-13.1-5.6-20.4c0.3-2.6,3.8-11.1-0.6-11.8
		c-6.2-1-20.7-1.3-22.2,7.4c-0.7,4.4-7.3,2.5-6.6-1.9C1228.3,1667.5,1228.4,1667.2,1228.5,1666.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1165.7,1770.7c6.5-7,11.5-17.7,10.8-27.5c-0.5-6.9-6.4-12.4-11.8-16c-9.8-6.7-22.5-7-27.4,2.3
		c-11.8,8-15.2,26-12,39.1C1130.8,1789.5,1154.7,1782.3,1165.7,1770.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M929.5,1324.7c6.5-7,11.5-17.7,10.8-27.5c-0.5-6.9-6.4-12.4-11.8-16c-9.8-6.7-22.5-7-27.4,2.3
		c-11.8,8-15.2,25.9-12,39.1C894.6,1343.6,918.6,1336.5,929.5,1324.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1256.9,1339.2c6.5-7,11.5-17.7,10.8-27.5c-0.5-6.9-6.4-12.4-11.8-16c-9.8-6.7-22.5-7-27.4,2.3
		c-11.8,8-15.2,26-12,39.1C1221.9,1358.2,1245.9,1351,1256.9,1339.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1196.6,1812.9c6.2,4.3,15.8,2.5,22.5,1.3c9-1.7,16-6.6,22.1-13.3c9.7-10.7,12.7-28.7-4.1-34.1
		c-23.1-7.5-44.1,5.8-53.3,27.3c-1.6,3.8,3.4,6.8,5.5,4.2C1188.9,1804.1,1191.7,1809.7,1196.6,1812.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1829.2,1595.6c-10,1.6-18.8,9.8-24.1,18c-3.9,5.9-0.1,17.2,0.2,23.7c0.6,11,1.2,22,9.8,29.7
		c18.2,16.2,39.4,6.8,46.3-15.2c4.9-15.8,11.8-33.5,23.2-45.9c3.4-3.7,6.8-7.2,10.6-10.5c4.6-4.1,11.3-9.1,10.8,1.4
		c-0.7,13-18.1,21.7-15.5,34.8c1.5,7.7,8.9,9.3,7,19.4c-1,4.5-0.4,9.4,1.6,13.6c3.5,7.1,15,13,22.2,15.1c4.2,1.3,8.6-0.2,12.4-2.6
		c10.9-0.9,34.3-6.2,28.4-21.7c-4.6-11.9-24.7-21.9-23.7-35.4c0.7-8.9,17.7-5.1,23.4-6.8c7.5-2.3,3.7-12.7,1-16.8
		c-3.4-5.1-7.6-9.6-12.7-13.2c-6.6-4.9-16-9.4-16.5-18.9c-0.6-10.6-2.4-16.5-13.8-19.7c-8-2.3-14.5-9.8-23.4-9.1
		c-2.4,0.2-4.3,2-5.3,2.1c-7.1,0.6-12.6-1.1-19.6-1.5c-17.6-1.2-30.2,18.1-26.4,34c1.5,6.1,3.5,12,2.9,18.3
		C1847.1,1596.3,1834.2,1594.8,1829.2,1595.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1828.6,1727.7c6.3,3,9.9,7.1,14.8,11.6c3.8,3.5,10,7.6,15.2,4.9c14.3-7.3,32.4,4.9,46.5-4.9
		c8-5.7,24.4-21,22.8-32.2c-1.5-11.4-18.2-17-27.9-18.1c-7.5-0.8-12.4,4-18.3,7.9c-12.5,8.5-21.2-0.6-30.5-8.2
		c-7.8-6.3-16.6-9.1-24.1-3.7c-14.4-7.6-17.4,30.4-8.4,37.2C1822,1724.4,1825.2,1726.3,1828.6,1727.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2095,1688.5c4.1-6.7-5.8-11.8-10.7-13.1c-5.9-1.6-12.2-1.9-18.2-1.2c-8.3,0.9-18.1,4-24.9-2.7
		c-7.6-7.4-13-10.5-23.5-5c-7.4,3.8-17.3,2.6-23.4,9.2c-1.6,1.8-1.8,4.4-2.5,5.1c-4.8,5.2-10,7.7-15.4,12.1
		c-13.8,11.1-10,33.7,3.7,42.9c5.2,3.5,10.6,6.4,14.5,11.5c4.7,6.2-5.7,13.8-8.8,17.8c-6.2,7.9-7.2,20-5.6,29.6
		c1.2,6.9,11.6,12.8,16.2,17.2c7.9,7.7,15.7,15.3,27.4,15.2c24.3-0.4,33.5-21.7,23.6-42.5c-7-14.9-14-32.6-14-49.5
		c0-4.9,0.2-9.9,0.7-14.8c0.6-6.1,2.1-14.3,8.8-6.2c8.3,10,1.4,28.2,12.2,36.2c6.3,4.6,12.9,0.9,18.3,9.5c2.4,4.1,6,7.2,10.4,9
		c7.4,2.9,19.9-0.7,26.6-4c4-1.9,6.1-6,7.3-10.3c7.4-8,21-27.9,6.2-35.1c-11.4-5.6-33,0.7-41.4-10
		C2076.9,1702.4,2091.9,1693.6,2095,1688.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2064,1865.3c3.8-0.4,7.4-1.3,10.9-2.5c6.6-2.2,12.1-1.5,18.8-1.5c5,0,12.5-1.2,14.5-6.7
		c5.6-15,27.2-18.4,30.9-35.2c2.1-9.7,3.8-31.9-5-39.3s-24.9-0.2-32.7,5.6c-6.1,4.4-6.4,11.3-8,18.2c-3.5,14.6-16,13.8-28,14.5
		c-10.1,0.6-18.4,4.6-20.2,13.6C2029.2,1836.5,2052.8,1866.5,2064,1865.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1980.7,1956.4c4.1-6.7-5.8-11.8-10.7-13.1c-5.9-1.6-12.2-2-18.2-1.2c-8.3,0.9-18.1,4-24.9-2.7
		c-7.6-7.4-13-10.5-23.5-5c-7.4,3.8-17.2,2.6-23.4,9.2c-1.6,1.8-1.8,4.4-2.5,5.1c-4.8,5.2-10,7.7-15.4,12.1
		c-13.8,11.1-10,33.7,3.7,42.8c5.2,3.5,10.6,6.4,14.5,11.5c4.7,6.2-5.7,13.8-8.8,17.8c-6.2,7.9-7.2,20-5.6,29.6
		c1.2,6.9,11.6,12.8,16.2,17.2c7.9,7.7,15.7,15.3,27.4,15.1c24.4-0.4,33.5-21.7,23.6-42.5c-7-14.9-14-32.6-14-49.5
		c0-4.9,0.2-9.9,0.7-14.8c0.6-6.1,2.1-14.3,8.8-6.2c8.3,10,1.4,28.3,12.2,36.2c6.3,4.6,12.9,0.9,18.3,9.5c2.4,4.1,6,7.2,10.4,9
		c7.4,2.9,19.9-0.7,26.6-4c4-1.9,6.2-6,7.3-10.3c7.4-8,21-27.9,6.2-35.1c-11.4-5.5-33,0.7-41.4-10
		C1962.5,1970.4,1977.6,1961.5,1980.7,1956.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1949.5,2133.3c3.8-0.4,7.3-1.2,10.9-2.5c6.6-2.2,12.1-1.5,18.8-1.5c5,0,12.5-1.2,14.5-6.7
		c5.5-15,27.2-18.4,30.9-35.2c2.1-9.7,3.8-31.9-5-39.3c-8.8-7.3-24.9-0.2-32.7,5.6c-6.1,4.5-6.4,11.3-8,18.2
		c-3.5,14.6-16,13.8-28,14.5c-10.1,0.6-18.4,4.6-20.2,13.6C1914.7,2104.4,1938.4,2134.4,1949.5,2133.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1512.1,2087.1c-5-2-7.3,5.2-7.6,8.7c-0.4,4.2,0,8.4,1.2,12.5c1.5,5.5,4.6,11.9,0.9,17.2
		c-4.2,5.9-5.7,9.9-0.9,16.5c3.5,4.6,3.7,11.5,8.8,14.8c1.4,0.9,3.2,0.8,3.8,1.2c4.1,2.7,6.3,5.9,9.9,9.1c9,8.1,23.9,3.1,28.7-7.1
		c1.8-4,3.3-7.9,6.2-11.1c3.7-4,10,2.3,13.1,4.1c6,3.5,14.3,2.8,20.7,0.6c4.6-1.6,7.4-9.3,10-13c4.4-6.2,8.7-12.4,7.3-20.2
		c-3-16.5-18.3-20.4-31.4-11.4c-9.4,6.4-20.6,13.1-32,14.9c-3.4,0.6-6.7,0.9-10.2,1.2c-4.3,0.2-9.9,0.1-5.2-5.3
		c5.8-6.7,19-4.1,23.1-12.2c2.5-4.8-0.8-8.8,4.4-13.4c2.5-2.1,4.3-4.9,4.9-8c1.2-5.3-2.7-13.3-5.5-17.6c-1.7-2.5-4.7-3.6-7.8-3.9
		c-6.2-4.2-21.2-11.2-24.5-0.4c-2.6,8.3,4.1,22.3-2.3,29.2C1523.5,2097.9,1515.9,2088.7,1512.1,2087.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1635.5,2089c-0.7-2.5-1.7-4.8-2.9-7.1c-2.2-4.3-2.3-8-3.1-12.6c-0.6-3.5-2.2-8.3-6.1-9.2
		c-10.8-2.2-15.4-16.4-27.2-17.1c-6.8-0.4-22.1,0.9-26.1,7.7s2.6,16.9,7.4,21.7c3.8,3.7,8.4,3.2,13.3,3.5c10.4,0.7,11.2,9.3,13,17.4
		c1.5,6.7,5.1,12,11.5,12.3C1619.7,2115.7,1637.4,2096.4,1635.5,2089z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1816.9,1762.7c-0.9-17.6-21.6-27.8-37-22.1c-5.8,2.2-11.5,4.8-17.8,5c-7.8,0.2-7.9-12.8-9.3-17.5
		c-2.8-9.7-12-17.5-20.7-21.9c-6.3-3.1-17.2,2-23.5,3.2c-10.9,2-21.7,3.8-28.3,13.3c-13.9,20.1-2,39.9,20.8,44.1
		c16.3,3,34.7,7.6,48.4,17.5c4.1,2.9,7.9,5.9,11.7,9.2c4.6,4.1,10.4,10.1,0,10.8c-13,1-23.7-15.3-36.5-11.2
		c-7.5,2.5-8.2,9.9-18.4,9.3c-4.6-0.4-9.4,0.7-13.3,3.2c-6.6,4.4-11.1,16.5-12.4,23.9c-0.8,4.3,1.3,8.5,4.1,12
		c2.2,10.7,10.4,33.3,24.9,25.5c11.2-6,18.7-27.2,32.3-27.9c8.9-0.4,7.2,17,9.6,22.4c3.2,7.2,13,2.1,16.8-1.1
		c4.6-4,8.6-8.7,11.5-14.1c4.2-7.2,7.3-17,16.7-18.7c10.4-1.9,16.1-4.4,17.8-16.2c1.3-8.3,8-15.6,6.1-24.4c-0.5-2.3-2.5-4.1-2.8-5
		C1816.3,1775.2,1817.3,1769.6,1816.9,1762.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1626,1733.3c-1.8,3.3-3.3,6.7-4.4,10.3c-2.2,6.6-5.8,10.7-9.7,16.1c-3,4.2-6.3,10.8-3,15.7
		c8.9,13.3-1,32.8,10.6,45.6c6.6,7.3,23.7,21.7,34.8,18.8c11.1-2.9,14.6-20.1,14.5-29.9c-0.1-7.6-5.4-11.9-10.1-17.2
		c-9.9-11.3-2-21.1,4.4-31.2c5.3-8.5,7-17.6,0.7-24.3C1669.7,1721.8,1631.6,1723.5,1626,1733.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1800.6,1919.8c-5.3-10.1-10.1-14.8-21.3-10.3c-7.6,3.1-7.8,0.5-10.8-5c-1.7-3.4-4.2-6.2-7.2-8.4
		c-8-5.9-18.9-11.1-29.2-8.9c-8.5,1.8-13.4,6.2-15.8,14.6c-1.3,4.6-1.4,9.8-2.2,14.5c-1.8,10.3-13.2,18.3-22.3,20.3
		c-9.9,2.3-8.7,13.8-13.1,21.4c-4.3,7.2-21.1,17.5-20.2,26.9c2,19.9,26.6,27,41,14.7c5.9-5.1,9.1-10.3,11.8-17.6
		c1.2-3.4,2.7-10.8,5.1-16.4c1.3,2.8,6.4,2.2,6.4-1.5c0-1.9,4.4,1.2,5.8,1.8c2.3,1,4.4,2.5,6.5,3.7c3.2,1.9,6.7,2.9,10.5,3
		c14.6,0,32.2-5,35.4,13.6c1.5,8.5,3.1,18.7,10.7,24c8.4,5.8,18.4,5.4,26.3-0.9c8.7-6.9,13.8-17,12.9-28.1
		c-0.4-4.1-2.1-7.8-4.7-10.8c-2.4-2.4-4.5-4.8-6.6-7.5c-3.4-4.6-1.2-11.4-0.7-16.6C1820,1936,1804.7,1927.5,1800.6,1919.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1805.5,2059.5c0.8-11.8-4.9-20.5-11.4-29.9c-8.6-12.8-26.7-15.2-38.9-5.8c-5.1,4-7.2,12.9-9.1,18.8
		c-5.2,16.4-17.2,2.8-28.1,2.1c-14.2-1-27.5,10.7-28.5,24.6c-0.1,0.7,0.1,1.4,0.5,2c-0.1,2.4,0.6,4.7,2.1,6.6c2,2.9,5.8,5.1,8.9,6.9
		c4.6,2.7,8.7,6.2,11.9,10.6c7.7,9.8,19.3,15.3,28.5,4.4c3.7-4.4,6.2-9.7,9.4-14.3c1.7-2.4,6.6-8.3,10.2-5.3
		c3.5,2.9,4.7,7.1,8.4,9.9c5.4,4.1,32.5,6.9,33.2-4c0.3-4.7-1.8-5.3-0.6-12C1802.9,2069.2,1805.2,2064.6,1805.5,2059.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1805.7,1875.4c-4.1,5.7-4.6,12.6,0.3,18.2c5.1,5.9,10.2,11.2,18.4,11.1c4.3,0,7.7-2.2,8.8,4
		c0.4,2.1,0.8,4.7,2.3,6.3c10.7,11.5,20.2-2.4,31.1-3.5c7.9-0.8,13.2,9.3,20,12.3c6.9,2.9,13.1,4.3,20.2,1.6
		c3.2-1.1,5.7-3.4,7.3-6.3c2.5-4.2,5.6-7.8,9.5-10.8c3-2.5,1.9-5.6,6.9-6.7c2.2-0.5,4.4-0.5,6.5-1.3c5.3-2,9.8-10.2,10.9-15.4
		c1.3-6.1,1.3-12.4,1.9-18.6c0.8-9.6,0.3-22.7-12.4-23.3c-7.8-0.3-12.7,3-18.3,8.3c-7,6.7-14.9,9.6-23.7,12.8
		c-9.5-12.3-5.9-30.1-21.5-38.6c-6.3-3.5-17-7.8-21.9-2.2c0.5-1.2,0-2.5-1.2-3.1c-0.2-0.1-0.3-0.1-0.5-0.2c-7.9-1.9-17,1.5-22.6,7.4
		c-3.6,3.9-4,5.6-4.4,10.9c-0.3,3.5,1.2,4-2.6,5.7c-4,2-11,7.1-9,12.5c2.3,6.1,3.8,7.4,8.7,8
		C1818.5,1870.2,1809.2,1870.4,1805.7,1875.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1899.8,1816.9c2.4,1.9,7.1,0.7,10,2.1c2.7,1.3,5.5,2.4,8.4,3.3c9.7,2.9,18.5-1.7,24.1-9.6
		c1.8-2.5,12.3-6.8,12.5-10.9c0.3-6.2-0.3-12.6-0.2-18.8c0.1-6.9-2.9-23.5-13.4-21.9c-4.7,0.8-8.4,3.9-13.1,5.2
		c-6.9,2.1-11.7-4.4-18-5.8c-9-2.3-20.7,0.6-24.7,9.8c-1.6,2.2-2.6,4.6-3,7.3c-0.9,5.2-6.5,27.6,2.3,30.1
		C1891.3,1809.3,1894.8,1812.9,1899.8,1816.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1896.7,1806.1c3.7,2.9,4.8,6,10.1,6.5c3.5,0.3,6,1.9,9.2,3.2c10.7,4.6,18.6-0.9,24.1-9.7c2-3.2,9.3-3,9.2-7.9
		c0-3.8-0.4-7.5-0.4-11.3c0-4.4-0.5-8.8-1.4-13.1c-2.3-10.9-8.3-6.9-14.1-4c-5.8,3-10.6,3-16.2,0.1c-3.1-1.5-5.5-3.3-8.8-4.4
		c-0.6,0.8-1.5,1.3-2.5,1.2c-6.4-0.3-11.7,1.5-15.9,6.4c-0.1,0.3-0.3,0.7-0.4,1c-0.2,0.6-0.6,1.2-1.2,1.5c-1.9,4.2-1.7,9.7-2.1,13.9
		c-0.3,3.4-2.8,12.4,1.8,13.1C1891.8,1803.4,1894.1,1803.9,1896.7,1806.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1685.5,1602.1c9.1-1.1,16.8-5.6,26.3-3.6c4.2,0.9,7.7,0.8,11.7-1.4c12.4-7,15.2-16.9,15.2-30.5
		c0.1-1.7-1.2-3.1-2.8-3.1h-0.1c-2.1-3-4.9-5.3-8.2-6.9c-4.6-2.4-10.5-5.7-15.6-6.9c-7.2-1.6-10.6,5.7-17.3-0.9
		c-8.2-8.2-20.1-15.9-32.2-11.9c-8.2,2.4-13.5,10.1-13,18.6C1649.9,1571.2,1665.4,1604.3,1685.5,1602.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1761.1,1639.2c-1.3-2.4-4-1.9-5.3-0.3c-7.5-5.1-17.9-6.1-25.4,0.5c-8.2,7.1-7.7,19.1-4.4,28.6
		c3,8.2,10.9,12.6,14.6,20.1c2.4,5.1,5.3,10.1,8.6,14.7c6.2,8.3,12.9,20.1,24.3,20.9c8.8,0.6,14.1-10.2,12.2-17.4
		c-3.2-11.5-12-18.9-18.4-28.4c-3.8-5.6-1.4-13.5-1.4-19.9C1766,1651.5,1764.3,1645,1761.1,1639.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1652.4,1618.7c-12.7-1.7-29.5,6.7-38.6,14.9c-1.5,1.4-1.6,3.8-0.2,5.2c0,0,0,0,0.1,0.1
		c-5.3,6.9-9.2,15.1-9.8,23c-1,13.4,11.1,20.7,22.2,24.6c14.5,5.2,29.9,10.8,45.2,13.1c6,0.9,10.8,1.2,15.7-2.8
		c5.2-4.1,4.6-13.9,9.6-17.3c3.6-2.4,6.9-5.2,5-10c-1.7-4.4-9-6.4-9.3-9.2c-0.8-6.7,5.5-25.7-2-29.9c-4.4-2.6-11.3-1.9-16.3-4.1
		C1667,1623.3,1660.1,1619.8,1652.4,1618.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1591.9,1926.6c4.8,2,10.1,3,15.3,2.8c9.1-0.3,20.5-4.1,28.7,1.2c9.2,5.9,21.5,13.8,32.6,8
		c6.8-3.6,10-12.8,10.6-20.2c3.5-0.9,6.9-2.3,10.5-2.8c1.6-0.1,2.9-1.1,3.3-2.6c3.3-12.6-1.8-30.9-14-36.8c-0.6-1.1-1.8-1.8-3.1-1.8
		c-7.8,0-9.6-0.5-14.7-5.9c-2.3-2.4-6.4-2.9-9.2-4.8c-2.3-1.7-4.6-3.6-7-5c-13.3-8.4-32.3-4.7-47.6-6c-23.5-2-28.5,30.8-23.2,47.4
		C1576.9,1909.3,1582,1922.6,1591.9,1926.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2034.6,1897.7c4.4-21.5-14.6-26.7-32.3-23.2c-10.2,2-19.4,6.5-29.2,10.1c-9,3.3-11.5,9.8-10.9,18.9
		c0.9,14,10.4,18.8,19.5,27.5c7.9,7.5,16.8,12.1,27.5,10c7.6-1.5,16.2-10.2,19.6-16.8c3.9-7.5,3.7-16.1,2-24.2
		c1.5,0.6,3.2-0.1,3.8-1.6C2034.5,1898.1,2034.6,1897.9,2034.6,1897.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2264.1,1907.8c3.4-13.2-0.1-31.1-12-39.1c-4.7-9.4-17.6-9-27.4-2.3c-5.3,3.7-11.3,9.1-11.8,16
		c-0.7,9.8,4.3,20.5,10.8,27.5C2234.8,1921.7,2258.8,1928.8,2264.1,1907.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2205.5,1235.5c-2.1,7.1,1.8,15.8,3.9,22.4c2.1,6.8-1.5,14.3,1.9,20.6c2.3,4.4,4.7,7.4,9.6,8.7
		c7.4,2,13.6,5.2,21.5,5.7c3.1,0.2,5-2.3,8.3-2s6.9,4.4,9.8,6c5.5,3.3,16.9,1.4,22.4-1c14.9-6.2,29.3-9.9,38.4-24.3
		c2-3.2-3.2-5.8-5.2-2.7c-0.5,0.8-1.1,1.6-1.6,2.3c-1.2-7.4-9.4-13-18.7-10.1c-4.2,1.3-7.4,4-11.4,5.7c-6.8,3.1-8-7.9-10.1-11.2
		c-4.4-6.7-18.5-3.9-20.7-10.5c-2.3-7.1,17.1-14.4,21.3-18.1c6.2-5.6-2.9-13.4-5.5-17.8c-10.6-16.9,10.1-19.9,22.3-20.7
		c8.6-0.6,19.4-2.1,23.9-10.5c8-14.8-6.3-26.8-18.4-33.1c-7.4-3.9-14.7-4.7-23-5.4c-9-0.7-12.5,3.3-16.9,10.3
		c-7.4,11.6-20.5,5-30.9,9.9c-7.2,3.4-11.6,14.2-11.9,21.7c-0.2,4.3,0.1,7.7,2.4,11.4c3.7,5.9,8.4,8.2,6.8,16.4
		C2221.3,1220.2,2208.5,1225.1,2205.5,1235.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2322.1,1223.7c2.1,2.3,4.5,4.3,7.3,5.6c2.4,1.5,3.3,1,1.8,4c-1.4,2.5-2.6,5.1-3.6,7.8
		c-3.1,9.6,8,18.4,14.7,22.7c5.7,3.7,11.7-3.1,16.6-5.4c7.1-3.5,14.6-6.1,21.1-10.8c5.1-3.7,8.7-8.8,8.4-15.2
		c-0.4-9.1-16.3-18.2-7.1-25.5c24.1-19-10.1-59.4-34.4-43.8c-5.8,3.7-6.1,11.5-10.7,16.3c-5.3,5.6-13.8,6.7-14.9,15.7
		c-0.8,5.8-0.4,12.2,2.4,17.4C2320.2,1215.2,2318.7,1219.6,2322.1,1223.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2205.6,2034.9c-2.3,7.3,2,16.3,4.3,23.1c2.3,7-1.7,14.8,2.1,21.3c2.6,4.6,5.2,7.7,10.7,9
		c8.2,2.1,15.1,5.4,23.8,5.9c3.5,0.2,5.6-2.4,9.3-2.1c3.7,0.3,7.7,4.4,10.9,6.2c6.1,3.5,18.7,1.4,24.9-1
		c16.6-6.4,32.4-10.2,42.5-25.2c2.3-3.3-3.6-6-5.7-2.8c-0.6,0.8-1.2,1.6-1.8,2.4c-1.3-7.7-10.4-13.4-20.8-10.4
		c-4.6,1.4-8.2,4.1-12.7,5.9c-7.5,3.2-8.9-8.2-11.2-11.5c-4.8-6.9-20.6-4-23-10.8c-2.6-7.4,19-14.9,23.5-18.8
		c6.9-5.7-3.2-13.9-6.1-18.4c-11.8-17.5,11.2-20.7,24.8-21.5c9.6-0.6,21.5-2.2,26.6-10.9c8.9-15.3-7.1-27.8-20.5-34.2
		c-8.2-4.1-16.4-4.9-25.5-5.6c-10-0.8-13.8,3.4-18.8,10.7c-8.2,12-22.6,5.2-34.3,10.2c-8,3.5-12.9,14.7-13.3,22.4
		c-0.2,4.4,0.1,8,2.7,11.8c4.2,6.1,9.3,8.5,7.6,17C2223.3,2019.1,2209,2024.2,2205.6,2034.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2335.1,2022.8c2.2,2.6,5.1,4.2,8,5.8c2.7,1.5,3.7,1,2,4.1c-1.5,2.6-2.9,5.2-4,8c-3.5,9.9,8.9,19.1,16.4,23.5
		c6.4,3.8,13.1-3.2,18.4-5.6c7.9-3.6,16.2-6.3,23.4-11.2c5.6-3.8,9.7-9.1,9.4-15.8c-0.4-9.4-18.1-18.9-7.9-26.4
		c26.8-19.6-11.2-61.5-38.3-45.4c-6.4,3.9-6.8,11.9-11.9,16.8c-5.9,5.7-15.2,6.9-16.5,16.3c-0.8,6.1-0.4,12.6,2.7,18
		C2333,2013.9,2331.3,2018.5,2335.1,2022.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2375.9,1558.5c-9.6,0-18.9-0.4-27.7-4.4c-4.9-2.2-8.8-3.5-13.8-1c-9.9,4.7-10.7,25.5-5.9,33.5
		c7.2,12.3,22.6,17.6,35.7,10.2c6.2-3.6,9.3-8.3,12-14.8c1.4-3.5,3.4-6.6,3.8-10.1c2.8-1.2,2.4-5.9-0.9-6.5c0.2-1.2,0.4-2.4,0.5-3.6
		C2379.6,1559.9,2377.5,1558.5,2375.9,1558.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2426.6,1618.8c12.4,1.6,27.6,4.2,39.6-0.9c7.7-3.3,16.7-13.2,12.9-22.1c-3-6.7-10.1-3.9-12.4-13.2
		c-1.6-6.5-1.1-10.7-5.6-16.1c-4.8-5.7-17.5-7.3-24.3-9c-8.8-2.1-16.1-7.3-24.8-9.2c-11.1-2.5-18.3,4.7-22,14
		c-3.1,2.8-5,6.5-5.7,10.6c-2.4,13.3,10.1,20.1,12.1,32C2398,1617.3,2417.2,1617.6,2426.6,1618.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2365.6,1636.2c-1.9-4.6-5.8-7.4-10.5-9.1c-0.2-4.4-1.4-8.4-5.1-10.2c-4.9-2.3-9.2-2.9-13.3-7.1
		c-2.6-2.7-5.5-5.8-9.4-6.4c-19.8-2.9-33.6,23.2-21,38.3c9.1,10.7,25.3,13.3,19,31.5c-3,8.6-9.7,19.6-3.2,28.6
		c4.6,6.2,11.5,6.3,18.6,5.7c5.9-0.5,11.4-1.6,16.7,1.9c4.8,3.2,7.8,10,11.4,14.3c6.2,7.7,15.9,10.3,25.5,9.5
		c10.7-0.9,24-1.8,31.4-10.5c2.7-3.2,4-7.7,5.9-11.3c4-7,11.3-6.1,17.6-4.2c9.3,2.9,16.2-1,22.4-7.8c10.2-11,23.4-35.4,7.1-46.3
		c-14-9.3-36.1-7.6-52.1-6.4c-8.1,0.6-14.4,2.2-20.4,8.1c-3.7,3.7-6.3,8.1-10,11.8c-7.2,7.3-14.7,2.6-21.4-2.4
		c-5.5-4.2-10.3-9.6-9.7-16.9C2365.8,1643.9,2367,1639.7,2365.6,1636.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2222.9,1608.1c-10.8-3.8-17.8-10-25.9-17.6c-16.2-15.1-38.2-6.8-52.7,6.7c-4.2,3.9-12.6,12.5-6.5,18.2
		c3.6,3.5,7.8,5.7,11.7,8.8c5.4,4.2,8.4,10.3,12.3,15.8c8.1,11.9,19.3,13.4,32.5,15.2c11.4,1.6,22.9,10.4,34.6,8.4
		c3.4-0.4,6.5-2.2,8.5-4.9c2.6-3.5,5.8-8.3,10.7-9c3-0.4,6.5,0,9.5-0.6c9-2,16.9-14.6,23-20.4c10.7-10.1-3.9-23.5,5.7-34.3
		c5.4-6,8.5-14.1,4-21.7c-3.2-5.2-6.9-12.4-12.1-15.8c-4.8-3.4-10.2-3.9-13.9-9.2c-3.8-5.3-2.1-12.2-5.6-17.2
		c-3.6-5-6.1-8.9-11.6-12c-9.6-5.5-22.2-1.2-32.2,0.6c-12.7,2.3-19.9-2.9-28.5,8.2c-8.7,11.3-22.1-5.1-30.9,3.8
		c-2.8,2.8-3.9,5-3.7,9c0.3,6.1-2.5,8.1-5.8,12.9c-2.7,3.9,3.5,7.9,6.1,4.1c0.4-0.6,0.8-1.1,1.3-1.6c1.7,17.4,30.8,15.4,45.7,15.4
		c7.6,0,12.3-6.2,20.7-4.8c8.1,1.3,20.7,11.4,22.3,19.6C2244.6,1598.1,2238.7,1613.6,2222.9,1608.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2373.3,1861.3c-4.9-0.5-9.9-1.2-14.7-2.1c-16.6-3.2-32.6-13.3-46.1-23c-18.8-13.5-41.3-8.5-46.2,15.3
		c-2.3,11.4,3.8,20.5,9.8,29.7c3.6,5.4,7.3,16.7,13.9,19.2c9.2,3.4,21.2,4.6,30.1,0c4.4-2.3,13.8-11.2,19.1-5.3
		c4.3,4.7,6.2,10.6,8.7,16.4c6.4,15,28.1,23,41.4,11.5c5.2-4.6,8.7-9.2,14.7-12.9c0.9-0.6,3.5-0.2,5.5-1.5
		c7.6-4.7,8.3-14.6,13.4-21.3c7.3-9.4,5.2-15.2-0.6-24.1c-5.2-7.9-0.4-17,2-24.9c1.9-5.8,2.7-12,2.3-18.1
		c-0.4-4.9-3.5-15.6-10.8-12.9c-5.6,2.1-17.1,15.2-22.8,8.5c-8.9-10.3,1.3-30.4-2.1-42.5c-4.4-15.8-26.4-6.1-35.7-0.4
		c-4.4,0.3-8.9,1.8-11.5,5.2c-4.4,6-10.2,17.7-8.8,25.4c1,4.5,3.4,8.7,6.9,11.9c7.5,7,2.6,12.7,5.9,19.8c5.7,12.1,25,8.6,33.2,18.7
		C2387.7,1861.9,2379.4,1861.8,2373.3,1861.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2265.3,1829.9c9.2-0.1,14.6-7.6,17.1-17.3c3-11.7,4.4-24.1,19.6-24.8c7.1-0.3,13.8,0.6,19.4-4.5
		c7.2-6.6,17.2-21,11.7-31.1c-5.5-10.2-27.7-12.5-37.7-12.3c-17.2,0.4-24.4,21.1-40.2,23.7c-5.8,1-8.4,8-9.3,13.1
		c-1.3,6.6-1.7,12.1-4.9,18.2c-1.9,3.3-3.4,6.7-4.4,10.3C2233.2,1815.9,2258.3,1844.7,2265.3,1829.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2151.1,989.8c12.6,5.9,21.4,4,34.4,2.6c4-0.4,8.3-1.2,12.2,1.3c8.3,5.2,11.8,9.3,23.3,9.5
		c10.2,0.2,21.4-0.9,28.9-5.9c26.3-17.5-52.9-80.4-67.9-53.9c3.2,1.6,5.1,4.7,2.3,6.9c-1.5,1.1-3.1,2-4.7,2.7
		c-0.5,2.7-3.7,3.3-6.5,2.3c-2.4,0.7-4.5,1.7-6.7,2.9c-7.6,4.4,2.9,9.9,4,15.4C2172.5,985,2158.6,984.9,2151.1,989.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2286.7,1033.7c-0.1-0.9-0.3-1.8-0.4-2.7c-0.4-1.8-2-3-3.8-3.1c-10.5,0-24.1,12.9-27.9,22.2
		c-4.8,12.4-6.4,26.2,1.8,37.4c3.3,4.5,7,7.7,8.9,13.3c0.9,3.2,2,6.3,3.3,9.4c2.8,6.4,7.6,11.8,13.8,15.1c10,5.6,35.8,9,45.7,1.8
		c7.1-5.1,9.8-13.5,17.9-17.9c6.5-3.5,12.2-4.4,16.3-11c6.7-10.5-7.2-21.8-14.8-26.4c-8.6-5.3-27.8-5-31.4-16.2
		c-2.2-6.5-1.7-12.3-5.9-18.3c-5.7-8.2-15.4-5-23.8-1.3C2286.7,1035.3,2286.8,1034.5,2286.7,1033.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2228.9,1377.8c5.3,5.1,16.1,19,25.2,15.6c4.7-1.8,8.3-5.5,12.2-8.8c4.8-4.3,10.5-7.5,15.1-12.2
		c15.1-15,8.6-42.8-10.8-50.3c-6.5-2.5-14-2.8-21-3.8c-4.6-0.6-10.9-5-15.6-3.6c-6.6,2-12.9,9.5-10.8,15.7
		c-3.3,5.8-5.7,12.1-7.3,18.6C2213.3,1360.3,2221.3,1370.6,2228.9,1377.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2272.5,1427.1c-1.4-6.6-8-14-15.8-16.4c-10.4-3.1-17.7,1.1-22.2,7.5c-2.3-0.7-5.4,0.2-5.6,2.9
		c-0.9,12.9-13.2,10.7-24.5,10.5c-8.8-0.1-19.4,4.2-26.6,8c-16.3,8.6-24,23.8-31.8,37.8c-3.2,5.6-16.2,22.6-8,28.9
		c3.9,3,8.3,2.7,13.2,1.8c12.8-2.5,24-9.2,36.8-12.2c22.3-5.1,47.1,1.3,68.7-6c15.7-5.2,27.6-20.5,19.8-34.7
		c-3.8-6.9-7.2-12.1-4.9-19.9C2271.9,1432.8,2273.1,1429.9,2272.5,1427.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2286.3,1533.9c4.5,5.4,10.4,3.7,16.8,3.2c7.1-0.6,13.3-0.1,20,2.5c5.9,2.3,11.3-4.6,10.5-10.1
		c-0.4-2.3-1.8-4.2-3.7-5.4c-3.1-2.3-4.6-3.4-6.9-6.4c-4.6-6.5-13.3-10.9-20.7-13.3c-5.1-1.8-13.3-3.2-17.9,0.7
		c-5,4.3-3.3,6.8,0.1,9.7C2284.2,1520.6,2282.8,1529.6,2286.3,1533.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2131.6,1699.6c20.4,7.3,27.9-16.5,24.3-32.2c-2.2-9.4-8.2-19.5-16.7-24.3c-6-3.5-13.8-1.6-19.9,0.8
		c-11,4.4-18.5,14.7-13.4,23.9C2106,1682.1,2118.8,1695,2131.6,1699.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2164.3,1241.1c8.5-9.1,15-23.1,14-35.8c-0.7-9-8.4-16-15.4-20.9c-12.8-8.7-29.4-9.2-35.7,3
		c-15.3,10.5-19.9,33.8-15.5,50.9C2118.8,1265.7,2150,1256.5,2164.3,1241.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2174,1726.1c-9.2,5.1-16.9,12.7-22.3,21.7c-10.9,18.4,1.2,38,19.1,44.5c8,2.9,18.4,3.5,25.9-1
		c5.6-3.4,7.1-11.4,9.1-17.1c4.2-12,11.4-20.3,23.6-23.9c9.3-2.8,19-3.6,28.1-7c11.4-4.4,35.5-16.2,38.3-29.6
		c3.4-16.7-16.7-19.1-28.9-18.7c-6.7,0.2-13.2,2-20,1c-7.2-1.2-12.4-8.1-17.4-12.7c-6.4-5.8-15.7-7.9-24.1-6.6
		c-5,0.7-9.5,3.9-12,8.3c-2.7-1.3-6.4,1.7-4.4,4.8c0.6,0.9,1.1,1.8,1.5,2.7c-1.7,6.5-1.9,13.9-2,19.7c0,0.3,0,0.7,0.1,1
		C2185.2,1718.3,2179.8,1722.7,2174,1726.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1884.6,1516.3c7.4-5.4,7.5-13.3,5.1-21.6c-3.1-10.5-9.2-17.9-18.4-23.6c-9.2-5.7-18.8-5.3-29.3-3.4
		c-18.8,3.6-30.8,13.5-29.9,33.4c0.6,13.5,8.3,28.4,22.5,28.5c9,3,17.9-1.1,25.5-5.5c0.6-0.3,1-0.8,1-1.5c-0.3-7.3,6-4.1,10.2-3.5
		C1876.2,1519.8,1880.5,1519.3,1884.6,1516.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2039.1,1964.3l2.3-2.3c1-1.1-0.6-2.7-1.6-1.7l-2.3,2.3C2036.5,1963.7,2038,1965.3,2039.1,1964.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2186.3,1894c2.9-1.5,6.7-0.8,9.8-0.4c0.6,0.1,1.2-0.3,1.3-1c0-0.2,0-0.4-0.1-0.6c-2.1-4.7-2.7-11.2-6.9-14.7
		c-4.3-3.7-10.6-3.5-15.5-5.7c-5.5-2.5-9.4-5.4-15.6-5.8c-5.9-0.4-8.9,7.3-14.7,7.3c-3.7,0-11.1-2.2-12.1,2.8
		c-0.5,2.7-2.7,4.7-5.3,5.2c-3.1,0.5-4,0.6-6.2,3c-2.4,2.4-4.2,4.6-7.4,5.6c-2,0.6-5.4,0.8-7,2c-1.1,1-1.7,2.4-1.6,3.8
		c0,3-1.5,3.9-3.2,5.7c-3,3.2-6,4.2-10.1,1.8c-0.6-0.3-1.3,0-1.5,0.6c0,0.1-0.1,0.2-0.1,0.3c-0.4,13.9-12,17.2-23.8,15
		c-0.6-0.1-1.2,0.3-1.3,1c0,0.1,0,0.2,0,0.3c0,6.6-0.6,12.6-3.6,18.3c-4-5.1-9.6-9.6-14.6-2.9c-2.3,3.2-3.5,7.1-4.4,10.8
		c-1.3,4.4-1.5,10.2-4.4,13.8c-0.6-0.1-1.1-0.2-1.7-0.2c-1.5,0-1.2,2.3,0.3,2.3c5.8,0.1,3,7.4,3.8,10.9c0.5,2,3.7,4,5.2,4.8
		c3.5,1.8,12.5,4.9,15.3,1.4c0.5-0.7,0.9-1.4,1.3-2.2c1.5-3,5.1-3.1,8-2.5c2.3,0.5,5.6-0.9,7.9-1.2c3.3-0.4,9.9-8.2,12-6.2
		c3.2,3.1,9.2,1.8,13,0.8c5.7-1.6,8.6-6.6,8.6-12.2c0-4.8,1.2-7.4,5.6-9.9c3.5-1.9,8,0.2,11.5-2.3c3.6-2.7,6.1-7.4,8.9-10.9
		c2.3-2.9,3.4-2.5,6.7-1.4c1.4,0.5,2.8,0.8,4.2,1c0.6,0.1,1.2-0.3,1.3-1c0-0.1,0-0.2,0-0.3v-4.6c0-2.7,5.3-2.3,6.5-2.3
		c3.3,0.1,6.4,1.4,9.5,2.3l0.4,0.1c0.1,0.8,0.1,1.7,0,2.5c-0.8,5.9-6.3,12.1-9.5,16.9c-5.3,8-12.6,18-8.6,28
		c1.8,4.6,5.5,7.4,7.5,11.7c-0.6,0-1.2,0.5-1.2,1.2c0,5.6-2.7,9-5.2,13.6c-0.7,1.3-1,2.9-2.1,4c-0.6,0.5-1.2,1.1-1.7,1.7
		c-3.7,6.3-8.8,3.4-14.2,2.1c-3.8-0.9-7.9-0.4-11.7-0.4H2076c-2.3,0-6.5-7.5-8.9-9c-0.6-0.3-1.3-0.1-1.6,0.4
		c-2.4,5.1-3.8,10.2-1,15.4c1.2,2.1,2.9,3.9,4.7,5.3c1.4,0.8,2.9,1.4,4.4,1.8c3.2,1.1,5.1,3.6,7.4,5.8c5.5,5.5,10.8,7.3,18.5,6.9
		c7.9-0.3,14.5,4.8,22.3,3.5c8.3-1.5,15.8-1.9,23.6-5.4c7.2-3.3,13.3-8.6,18.8-14.3c4.6-4.8,9.2-10.4,9.6-17.2
		c0.1-0.7,0.1-1.4,0-2.1c3.3-0.8,7-1.9,8.9-4.4c2.8-3.3,4.4-7.3,4.6-11.5c0.2-6.2-3.3-11.6-3.2-17.9c0.1-5.6,6.4-6.9,11-8.8
		c1.8-0.8,4.3-1.7,4.8-3.8c0.9-3.2,0.2-6.9,0.2-10.2c0-4.1,1.9-7.8,2.2-11.8c0.2-3.1,0.1-6.1-0.4-9.2c0.7-0.2,1.4-0.5,2.1-0.8
		c0.5-0.2,0.7-0.7,0.7-1.2c-0.1-5.4-0.4-10.4-5.3-13.4c-2.7-1.7-5.7-3-8.6-4.4C2188.5,1901,2181.3,1896.5,2186.3,1894z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1776.2,1606.1c4.9-5.4,7.7-12.7,12.9-17.8c4.2-4.3,11.2-5.9,16.1-9.5c8.2-6,9.1-18.4,0.9-24.6
		c-4.9-3.8-12-4.2-18.2-2.2c-0.7-0.2-1.5-0.3-2.2-0.2c-23,1.4-43.3,45.2-17.6,56.7C1771.2,1609.7,1774.1,1608.4,1776.2,1606.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M669.2-8.1c-5.4-5.8-13.1-5.1-20.2-6.8c-7.3-2-14.7-3-22.3-3.2c-6.4-0.1-12.5-5-19-2.2
		c-12.9,5.5-24.3,15.5-28.5,29.1c-2.3,7.7-0.5,15.3,0.4,23c0.8,5.1,0.6,10.5-0.6,15.6c-0.9,3.8,0,5.7,2.1,8.8
		c1.6,2.3,3.3,4.4,4.7,6.6c1.5,2.4,1.9,6.2,2.7,9c1,3.6,4,5.4,7,7.2c6.4,3.9,7.1,8.4,9.3,15c1.4,4.3,4.7,7.5,8.1,10.2
		c6.4,5.2,16.4,7.1,22.4,0.4c5.5-6.3,4.3-20.4,0.6-27.2c-2.5-4.6-7.9-7.5-11.5-11.2C618,60,611.9,53,609.5,44
		c-0.8-3,0.4-6.6,0.4-9.7s-3.8-5.4-0.9-8.8c4.4-5.4,15.3-2.8,20.8-2c4.7,0.9,9.7-0.1,13.8-2.6c3-1.8,4.6-3.3,7.8-0.4
		c2.3,2.2,4.1,5.4,5.7,8c2.1,3.2,4.4,7.7,3.8,11.8c-0.5,2.7-3.1,5.2-4.2,7.8c-2.7,6.4-2.9,12-2.3,18.6c-1.4,1.6-3.8,2.8-5.1,4.3
		c-1.4,1.5-1.3,5-1.3,7c-0.1,3.7,0.5,7.2,0.4,10.9c-0.1,2.4,0,5.3,1.2,7.5c5.6,10.7,23.6,11.5,31.3,3.2c4.3-4.6,6.2-13.4,6.7-19.4
		c0.1-2.9-0.5-5.7-1.9-8.2c-1.4-3.1-4.8-4-6.5-6.8c-5.1-8.8-0.7-18.8,0.8-28c1.5-9.5-2.9-19-2.4-28.6C677.7,2.8,673-4,669.2-8.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M773.8,511.5c-5.4-5.7-13.1-5.1-20.2-6.8c-7.3-1.9-14.7-3-22.3-3.2c-6.4-0.1-12.5-4.9-19-2.2
		c-12.9,5.5-24.3,15.5-28.5,29.1c-2.3,7.6-0.5,15.3,0.4,23c0.8,5.1,0.6,10.5-0.6,15.6c-0.9,3.8,0,5.7,2.1,8.8
		c1.6,2.3,3.3,4.4,4.7,6.6c1.5,2.3,1.9,6.2,2.7,8.9c1.1,3.6,4,5.4,7,7.2c6.4,3.9,7.1,8.4,9.3,15c1.4,4.3,4.7,7.5,8,10.2
		c6.4,5.2,16.4,7.1,22.4,0.4c5.5-6.3,4.3-20.4,0.6-27.2c-2.5-4.6-7.9-7.5-11.5-11.2c-6.4-6.3-12.6-13.3-14.9-22.3
		c-0.8-3,0.4-6.6,0.4-9.8s-3.8-5.4-0.9-8.8c4.4-5.4,15.3-2.8,20.8-2c4.7,0.8,9.6-0.1,13.7-2.6c3.1-1.8,4.6-3.3,7.8-0.3
		c2.3,2.2,4.1,5.4,5.7,8c2.1,3.2,4.4,7.7,3.8,11.8c-0.5,2.7-3.1,5.2-4.2,7.8c-2.7,6.4-3,12-2.3,18.6c-1.5,1.6-3.8,2.8-5.1,4.3
		c-1.4,1.5-1.3,5-1.3,7c-0.1,3.7,0.5,7.2,0.4,10.9c0,2.4,0,5.3,1.2,7.5c5.6,10.7,23.6,11.5,31.3,3.2c4.3-4.6,6.2-13.3,6.7-19.4
		c0.1-2.9-0.5-5.7-1.9-8.2c-1.4-3.1-4.8-4-6.5-6.8c-5.1-8.8-0.7-18.8,0.8-28c1.5-9.5-2.8-19-2.4-28.6
		C782.2,522.4,777.6,515.7,773.8,511.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1639.9,1523.3c-1.6-9.9-10.1-14.4-16.3-21.3c-6.8-7.3-12.8-13.3-21.2-18.9c-6.7-4.6-9.6-13.9-18.3-15.6
		c-17.2-3.3-36.3-0.8-50.1,10.5c-7.8,6.3-11.3,15.6-15.7,24.3c-2.9,5.9-6.7,11.4-11.5,15.9c-3.6,3.4-4.1,5.9-4.1,10.7
		c0,3.5,0.4,6.9,0.3,10.4c-0.2,3.5-2.3,7.8-3.5,11.3c-1.4,4.4,0.4,8.4,2.3,12.5c4.1,8.6,1.6,13.7-0.8,22.2
		c-1.5,5.4-0.3,11.2,1.3,16.3c3.1,10,12.3,19,23.2,16.1c10.2-2.7,18.7-18.3,19.7-28.1c0.6-6.5-3-13.4-4.3-19.7
		c-2.3-11.2-3.8-22.7,0-33.8c1.3-3.7,5-6.6,7.2-9.9s-0.1-8.3,5.2-9.9c8.5-2.5,17.9,7.9,23.1,12.5c4.4,4.2,10.1,6.6,16.1,6.9
		c4.4,0.3,7.1-0.2,8.4,5.1c1,3.9,0.5,8.5,0.4,12.6c-0.1,4.7-0.8,11.2-4.3,14.8c-2.4,2.5-6.9,3.4-9.8,5.2
		c-7.3,4.7-11.4,10.5-15.4,17.9c-2.7,0.7-5.8,0.3-8.3,0.8c-2.7,0.6-4.8,4.4-6.3,6.3c-2.7,3.8-4.5,7.9-7.2,11.8
		c-1.8,2.5-3.7,5.6-4.1,8.7c-1.6,15.1,16.6,28.5,30.5,25.3c7.6-1.8,15.8-9.7,20.6-15.5c2.1-3,3.5-6.3,3.9-9.9
		c0.7-4.2-2.3-7.6-2.1-11.7c0.8-12.9,12.4-20.2,20.4-28.7c8.2-8.9,10.4-21.9,17.5-31.5C1641.2,1540.7,1641,1530.4,1639.9,1523.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M918.6,2019.8c-4.4-6.6-12.1-7.1-18.8-9.9c-6.9-3.1-14.1-5.3-21.6-6.6c-6.3-1.1-11.5-6.9-18.4-5.2
		c-13.5,3.5-26.5,11.5-32.7,24.1c-3.6,7.2-3,14.9-3.3,22.8c-0.1,5.2-1.1,10.4-3.1,15.3c-1.5,3.7-1,5.6,0.6,9
		c1.2,2.5,2.6,4.8,3.7,7.3c1,2.6,0.9,6.4,1.3,9.3c0.5,3.8,3.1,5.9,5.7,8.3c5.7,4.8,5.6,9.4,6.7,16.3c0.7,4.4,3.5,8.2,6.3,11.4
		c5.5,6.2,15.1,9.6,22.1,4c6.5-5.3,7.5-19.4,4.9-26.8c-1.7-4.9-6.6-8.7-9.6-12.9c-5.3-7.3-10.3-15.2-11.2-24.4
		c-0.3-3.1,1.4-6.4,1.9-9.6c0.5-3.1-2.8-5.9,0.5-8.9c5.2-4.6,15.5-0.3,20.9,1.4c4.5,1.6,9.5,1.5,13.9-0.3c3.3-1.3,5-2.5,7.7,0.9
		c2,2.5,3.2,6,4.4,8.9c1.5,3.5,3.2,8.4,1.8,12.2c-0.9,2.6-3.9,4.7-5.3,7c-3.7,5.8-4.7,11.4-5.2,18c-1.7,1.4-4.2,2.2-5.7,3.4
		c-1.6,1.2-2.1,4.7-2.5,6.7c-0.7,3.6-0.6,7.2-1.3,10.9c-0.5,2.4-0.8,5.2,0,7.6c3.9,11.5,21.5,15.1,30.4,8.1
		c4.9-3.9,8.2-12.3,9.7-18.1c0.6-2.8,0.4-5.7-0.5-8.4c-0.9-3.3-4.2-4.6-5.3-7.8c-3.7-9.6,2.3-18.7,5.2-27.5
		c3.1-9.1,0.2-19.2,2.2-28.6C925.3,2031.8,921.7,2024.5,918.6,2019.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1569.2,542c0.4,8.3,5.6,17,14.7,16.9c8.4,0,18-10.4,20.7-17.8c1.8-4.9,0.3-10.9,0.6-16
		c0.4-9.1,1.6-18.3,6.6-26c1.7-2.6,5.1-4.2,7.5-6.2c2.4-2.1,1.6-6.4,5.9-6.6c7-0.3,12.3,9.6,15.3,14.1c2.6,4.1,6.5,7.1,11.1,8.5
		c3.4,1.1,5.5,1.3,5.4,5.5c0,3.2-1.3,6.6-2.2,9.7c-1,3.7-2.8,8.5-6.2,10.6c-2.3,1.5-6,1.2-8.6,2.1c-6.5,2.3-10.9,5.8-15.3,10.8
		c-2.2,0-4.6-1-6.5-1c-2.2,0-4.6,2.4-6.1,3.7c-2.8,2.4-5,5.2-7.8,7.6c-1.8,1.6-4,3.6-4.8,5.9c-4.3,11.4,7.2,25.2,18.5,25.4
		c6.2,0.1,14.1-4.4,18.9-7.9c2.2-1.9,3.9-4.3,4.8-6.9c1.3-3.1-0.3-6.3,0.7-9.4c3.2-9.8,13.5-13.1,21.4-18.1
		c8-5.2,12.3-14.7,19.7-20.9c4.6-3.9,6.6-11.8,7.1-17.4c0.7-7.9-4.9-13.2-8.4-19.6c-3.5-6.7-7.6-13-12.6-18.7
		c-4.3-4.8-4.6-12.7-11.1-15.6c-12.7-5.9-27.8-7.8-40.6-1.8c-7.2,3.4-11.8,9.8-16.9,15.6c-3.4,4.1-7.4,7.4-12,10
		c-3.5,1.9-4.3,3.9-5.2,7.4c-0.7,2.7-1,5.3-1.8,8s-3.4,5.5-4.8,8c-2,3.2-1.4,6.6-0.7,10.1c1.4,7.4-1.5,10.9-5,16.9
		C1569.1,533,1568.9,537.6,1569.2,542z"/>
            <path :fill="colors[`${id}-p-1`]" d="M844.8,987.2c-8,1.9-15.7,8.6-14,17.5c1.6,8.3,13.4,15.9,21.2,17.2c5.1,0.9,10.8-1.6,15.8-2.3
		c9-1.2,18.3-1.7,26.8,2c2.9,1.2,5,4.4,7.4,6.2c2.5,1.9,6.6,0.4,7.5,4.6c1.5,6.9-7.2,13.8-11.3,17.6c-3.6,3.3-5.8,7.6-6.4,12.4
		c-0.5,3.5-0.3,5.6-4.5,6.3c-3.2,0.5-6.8-0.1-10-0.4c-3.8-0.4-8.8-1.3-11.5-4.3c-1.9-2.1-2.3-5.6-3.6-8c-3.4-6-7.6-9.7-13.3-13.3
		c-0.4-2.1,0.1-4.6-0.2-6.6c-0.3-2.2-3.2-4.2-4.6-5.3c-2.9-2.3-6-4.1-8.9-6.3c-1.9-1.5-4.3-3.3-6.6-3.7
		c-11.9-2.2-23.5,11.5-21.8,22.7c1,6.1,6.7,13.2,11.2,17.2c2.2,1.9,4.8,3.1,7.6,3.6c3.3,0.8,6.1-1.4,9.4-1
		c10.2,1.4,15.2,11,21.6,17.8c6.5,7,16.7,9.4,23.9,15.6c4.6,4,12.8,4.4,18.4,3.9c7.9-0.7,12.1-7.1,17.8-11.8
		c6-4.5,11.5-9.8,16.2-15.6c4.1-5,11.6-6.8,13.4-13.6c3.6-13.5,2.8-28.8-5.4-40.3c-4.6-6.5-11.8-9.8-18.4-13.8
		c-4.5-2.6-8.6-6-12-10.1c-2.5-3.1-4.5-3.6-8.2-3.8c-2.8-0.2-5.4,0-8.2-0.4s-6-2.3-8.7-3.4c-3.5-1.4-6.7-0.2-10.1,1.2
		c-7,2.7-11,0.5-17.6-1.9C853.6,985.6,848.9,986.2,844.8,987.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2011.5,672.5c0.5,9.1,6.2,18.7,16.1,18.6c9.2-0.1,19.8-11.4,22.6-19.6c1.9-5.3,0.4-12,0.7-17.6
		c0.5-9.9,1.8-20.1,7.3-28.6c1.9-2.9,5.6-4.6,8.2-6.9c2.6-2.3,1.7-7,6.5-7.2c7.7-0.3,13.4,10.5,16.8,15.5c2.8,4.4,7.1,7.8,12.2,9.4
		c3.7,1.2,6,1.4,6,6.1c0,3.5-1.4,7.3-2.4,10.7c-1.2,4-3.1,9.3-6.8,11.7c-2.6,1.6-6.5,1.3-9.4,2.3c-7.2,2.5-11.9,6.3-16.9,11.8
		c-2.4,0-5-1.1-7.2-1.2c-2.4,0-5,2.7-6.7,4.1c-3.1,2.6-5.5,5.7-8.6,8.4c-2,1.7-4.4,4-5.2,6.4c-4.6,12.5,7.9,27.7,20.3,27.9
		c6.9,0.1,15.5-4.7,20.7-8.7c2.5-2,4.3-4.6,5.3-7.6c1.5-3.4-0.3-6.9,0.8-10.4c3.5-10.7,14.8-14.3,23.4-19.9
		c8.9-5.7,13.4-16.2,21.6-22.8c5.1-4.3,7.2-12.9,7.8-19.1c0.8-8.7-5.3-14.3-9.2-21.5c-4.2-7.6-7.9-13.9-13.8-20.6
		c-4.6-5.3-5-13.8-12.1-17.1c-13.9-6.5-30.6-8.5-44.6-2c-8,3.7-12.9,10.8-18.6,17.2c-3.7,4.4-8.1,8.1-13.2,10.9
		c-3.8,2.1-4.7,4.2-5.7,8.1c-0.7,2.9-1.2,5.8-2,8.8c-0.8,2.9-3.7,6.1-5.3,8.8c-2.2,3.5-1.5,7.2-0.8,11.1c1.6,8-1.7,12-5.4,18.6
		C2011.4,662.6,2011.3,667.9,2011.5,672.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1466.3-141.9c4.2-6.9-5.9-12.1-10.9-13.3c-6.1-1.6-12.5-2-18.7-1.2c-8.4,0.9-18.5,4.1-25.5-2.8
		c-7.7-7.5-13.3-10.7-24.1-5.1c-7.6,3.9-17.7,2.7-23.9,9.5c-1.7,1.8-1.9,4.4-2.6,5.2c-4.9,5.3-10.2,7.9-15.7,12.4
		c-14.1,11.3-10.2,34.5,3.8,43.9c5.3,3.6,10.9,6.6,14.8,11.9c4.8,6.3-5.8,14.1-9,18.2c-6.4,8.1-7.4,20.4-5.7,30.3
		c1.2,7.1,11.9,13.1,16.6,17.7c8,7.9,16.1,15.7,28,15.5c24.9-0.4,34.3-22.2,24.2-43.6c-7.2-15.3-14.3-33.3-14.3-50.7
		c0-5,0.2-10.2,0.7-15.2c0.7-6.2,2.2-14.6,9-6.4c8.5,10.2,1.4,28.9,12.5,37c6.5,4.7,13.2,0.9,18.7,9.8c2.5,4.2,6.1,7.3,10.6,9.2
		c7.6,2.9,20.3-0.7,27.3-4.1c4.1-2,6.3-6.1,7.5-10.5c7.5-8.2,21.5-28.5,6.3-35.9c-11.7-5.7-33.8,0.7-42.4-10.2
		C1447.7-127.7,1463.1-136.7,1466.3-141.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1434.4,39.1c3.8-0.4,7.5-1.3,11.2-2.5c6.8-2.2,12.4-1.5,19.2-1.5c5.2,0,12.8-1.2,14.9-6.9
		c5.7-15.4,27.9-18.8,31.5-36c2.2-9.9,3.9-32.7-5.1-40.1c-9-7.5-25.5-0.2-33.5,5.7c-6.2,4.6-6.6,11.6-8.2,18.7
		c-3.5,15-16.4,14.2-28.7,14.9c-10.3,0.6-18.8,4.7-20.7,13.9C1398.7,9.5,1422.9,40.2,1434.4,39.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1701-116.8c-6-6.6-16.1-11.4-23.9-15.6c-8.6-4.6-15.9-6.9-25.7-6.6c-8.1,0.2-16.1,1.8-23.7,4.5
		c-8.6,3.3-15.5-2.6-23.9-2.7c-13.9-0.2-18.8,14.8-20,26.4c-1.3,12.2-11.2,8.1-16.5,15.3c-3.4,4.6-5.5,7.6-11.3,9.8
		c-2.8,1-6.2,2.1-8.4,4.2c-12.8,12.9,9.8,36,24.1,30.9c7.5,6.8,18.2,10.7,23.9-1.3c0.5-1.2,3.5-6,5.1-5.5c1.4,0.5,3,0.2,4.2-0.7
		c1.3-1.4,2.4-3.1,3.1-4.9c2.1,0.7,4.5-0.3,4.4-3.1c-0.8-14.8,21.3-10.6,30.9-10.4c15.2,0.3,36.9,14,40.2,29.4
		c0.5,1.9,2.4,3,4.2,2.5c0.3-0.1,0.5-0.2,0.8-0.3c9.1-5.7,20-5.1,29.8-9.2c1.1-0.6,1.8-1.7,1.8-3c-0.1-7.3-1.3-14.5-3.5-21.6
		c-1.2-3.9-3.9-12.6-8.1-13.8c-8.5-2.6-2.1-2.9-2.2-8C1706.3-106.9,1705.5-111.8,1701-116.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1688.2-8.9c-4.4-4.6-10.6-5.7-15.5-9.8c-4.6-3.8-7-6.6-13.2-7.4c-12.1-1.4-15,9.4-21.4,16.6
		c-1.5-1-3.6-0.5-4.4,1c-0.9,1.2-1.7,2.5-2.2,3.9c-3,4.4-6.7,7.5-12.9,5c-5.8-2.3-9.8-2.5-10.8-10c-0.4-1.9-2.3-3-4.2-2.6
		c-6.3,2-11.4,6.6-13.9,12.7c-5.7,4-13.8,16.6-6,21c3.1,1.7,5.8,6,9.9,9.4c5.3,4.4,13.1,6.3,19.7,7.5c8.8,1.7,19.4,6.8,24.8-3.3
		c1.6-2.9,1.8-17.5,5.9-16.5c7.4,1.8,14,6.3,21.6,8.1c8.7,2.1,25.7,1.4,29.2-9.4c1.2-3.8-3.5-7.9-4.4-11
		C1688.4,1,1693-3.9,1688.2-8.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1595.2,5.3c-0.6,2.2-0.9,4.4-1,6.5c-0.2,3.2-3.9,4-5.7,2.4c0,1.1,0.6,2.1,1.5,2.7c3.8,2.5,5.4,7.4,9.8,9.8
		c7,3.8,20.5,8.6,28.7,7.9c6.9-0.6,4.4-18,10.1-22c3.9-2.8,9.4-0.1,13.1,1.7c6.6,3.2,14,6.5,21.5,6.6c2.9,0,17.4-1.2,13.3-6.8
		c-5.4-7.5-0.5-18.4-10-22.7c-8.6-3.9-19-17.4-26.8-5.3c-3.8,5.9-7.1,11.7-13.6,15l0,0c-4.3,5.5-10.2,9.5-17.7,6.5
		c-7.2-2.9-13.4-4.7-15.9-11.7c-2.5,1.4-4.5,3.6-5.7,6.1C1596.8,3.3,1596.3,4.6,1595.2,5.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1738.6,308.1c-1.1,0.4-2.2,0.7-3.4,0.9c-6.8-3.7-14.9-5.9-21.3-7.5c-0.4-0.1-0.8-0.1-1.2-0.1
		c-4.8-5.2-8.3-12.6-10.4-19.7c-3.3-11.6-9.5-22.1-18-30.6c-17.4-17-42.3-9.1-54.5,9.1c-5.3,8-8.8,19.6-5.9,29
		c2.2,7.1,10.7,11.1,16.4,14.7c12.2,7.8,19.4,18.1,20.1,32.6c0.5,11.1-1.2,22,0.2,33c1.7,13.8,8.3,43.7,22.3,50.3
		c17.5,8.3,25.7-13.3,28.5-26.9c1.6-7.5,1.4-15.1,4.4-22.3c3.2-7.6,12.4-11.5,18.8-15.8c8.2-5.5,13.1-15.2,13.8-24.9
		c0.6-5.8-1.7-11.6-6-15.5C1744.9,311.7,1742.7,306.7,1738.6,308.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M214.9,296.5c-2.2-8.2-2.9-14.7-12.9-15.6c-7.4-0.6-13-7-20.6-7.2c-2.5-0.1-4.9,0.9-7.4,0.7
		c-1.3-0.1-4.4-3.4-5.6-4.2c-1-0.6-2.3-0.6-3.3,0c-4.2,2.7-4.8,3.9-7.1-1.5c-1-2.3-4.8-4.1-6.8-5.6c-3.2-2.7-9.5-3.8-13.3-1.7
		c-6.1,3.4-16.1,13.6-23.6,11.2c-5.7-1.9-10.9-4-16.2-1.7c-3.1-2.8-6.3-3.9-10.6-1.4c-3.5,2-3,1.8-6.9,0.1c-1.9-0.8-4.7-2-6.8-0.7
		c-7.2,4.4-5.4,10.1-9.4,16.5c-4.2,6.7-11.5,11.5-14.3,19.1c-2.5,6.5-0.8,11.3,2.7,17.2c1.5,2.6,4.6,3.9,7.1,5.1
		c6.4,3.2,3.1,7.1,5.9,11.6c3.1,4.8,10.4,9.3,16.4,6.9c1.8-0.7,3.4-1.6,4.7-2.9c0.6,0,1.1-0.1,1.6-0.3l2.2-1c1-0.6,1.7-1.6,1.7-2.8
		l-0.5-0.4c1.6-1,2-3.1,1-4.6c-0.2-0.4-0.6-0.7-0.9-1c0.3-1.2,0.5-2.5,0.8-3.8c0-0.2,0-0.4,0-0.6c2.7-2.3,6.1-4.4,8.8-6.2l1.3,3.1
		c0.7,1.3,2.2,1.9,3.6,1.6c16.2-4.6,36.5-16.6,46,5.1c0.6,1,1.6,1.7,2.8,1.7c10.9,0.5,12,11.4,7.3,19.5c-0.4,0.7-9.9,14.3-12.1,11.8
		c-1.4-1.4-3.4-2.2-5.3-2.1c-5-0.1-7.7,6.1-13.5,0.5c-1.4-1.6-3.4-2.5-5.5-2.5c-4.2-0.4-8.6,1-12.4,2.6c-7.9,3.4-15.8-2.3-19.6-9
		c-0.7-1.3-2.2-1.9-3.6-1.6c-7.5,0.8-11.3,3.6-10.9,11.5c0.3,7.1,4.2,10.3,5.7,16.7c1.4,5.5,1,8.9,4.7,13.6
		c7.3,9.2,16.4,19.1,28.2,22.2c1.7,0.4,3.4,0.3,4.9-0.3c5.5-2.1,9.6-0.4,14.4,2.3c3.5,1.9,9.6-1.2,12,1c0.8,0.8,2,1.2,3.1,0.9
		c5.7-2,11.6-2.7,17.3-4.8c7.9-3,18-0.7,25.3-5c3.1-1.9,7.5-7.6,10.1-7.2s5.1-1.8,6.8-3.4c6.3-5.8,9.5-15.7,12.4-23.6
		c3.6-9.3,5.8-19.1,6.7-29c0.6-7.9-5.6-16.4-11.3-21.3c-1.1-0.9-2.4-1.6-3.8-2.1c-6.8-2.6-3.8-9.9-1.5-14.5
		C217.2,304.6,216.1,300.9,214.9,296.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M392.8,42.9c-5.5,1.2-8.2,4.7-11.5,8.9c-6.1,7.7-11.7,13.1-5.4,22.9c4.6,7.3,2.6,17,7.7,24.1
		c1.7,2.3,4.3,3.9,5.8,6.4c0.8,1.3,0,6.4,0.1,8.1c0.1,1.3,1,2.5,2.2,3.1c5.3,2,6.9,1.8,3.7,7.6c-1.5,2.6-0.3,7.3-0.5,10.4
		c-0.2,4.7,3.2,11.4,7.7,13.4c7.4,3.3,23.9,5.3,26.9,14c2.3,6.6,4,12.9,9.9,16.2c-0.4,4.8,0.8,8.6,6,10.8c4.3,1.8,3.8,1.6,4.9,6.4
		c0.6,2.4,1.5,5.7,4.1,6.8c9.2,3.6,13.2-2.1,21.9-2.9c9.2-0.8,18.7,2.6,27.8-0.1c7.8-2.3,11.1-7.2,14-14.5c1.3-3.2,0.4-7-0.3-10.2
		c-1.6-8.1,4.4-7.8,6.6-13.6c2.3-6.2,1.3-16.1-5.1-20.2c-1.8-1.1-3.9-1.9-5.9-2.3c-0.4-0.5-0.9-0.9-1.5-1.3l-2.4-1.5
		c-1.2-0.6-2.7-0.4-3.8,0.4c0,0.2,0,0.5,0,0.7c-2.1-0.7-4.3,0.3-5,2.4c-0.2,0.5-0.3,1-0.2,1.5c-1.3,0.6-2.7,1.3-4.1,2
		c-0.2,0.1-0.4,0.2-0.6,0.4c-4-0.9-8.3-2.6-12.1-3.8l1.9-3.4c0.7-1.6,0.3-3.4-1.1-4.4c-15.6-11.8-41-22.1-27.6-46.3
		c0.6-1.2,0.4-2.7-0.4-3.8c-7.1-10.5,2.1-19.1,13-20.5c1-0.1,20.2-0.9,19.4,3c-0.3,2.3,0.3,4.6,1.8,6.4c3.5,4.7,11.1,2.9,9.9,12.3
		c-0.5,2.4,0.1,4.8,1.6,6.8c2.6,4.2,6.9,7.3,11.1,9.7c8.7,4.9,9.1,16.3,5.3,24.4c-0.7,1.6-0.2,3.4,1.1,4.4c6,6.4,11.3,7.9,18.3,2.1
		c6.4-5.3,6.5-11.1,11.5-17.1c4.2-5.1,7.6-7.1,9.3-14c3.5-13.3,6.2-28.6,0.8-41.6c-0.8-1.8-2.1-3.3-3.8-4.4c-5.7-3.7-7.1-8.5-8-14.9
		c-0.7-4.6-7.8-8.1-7.5-11.8c0.2-1.3-0.3-2.6-1.4-3.5c-5.8-3.9-10.6-8.9-16.6-12.7C514,4.4,509-6.6,500-10.3
		c-3.9-1.6-12.4-1.7-13.8-4.4c-1.5-2.7-5.2-3.5-7.9-4c-9.9-1.7-21.3,2.3-30.6,5.1c-10.8,3.3-21.4,7.9-31.2,14
		c-7.7,4.9-11.3,16.7-11.8,25.4c-0.1,1.7,0.2,3.3,0.7,4.8C407.6,39.4,398.7,41.7,392.8,42.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M255.2-15.8c-7.9,2.2-14-3.6-21-6.1c-1.9-0.7-4.3-0.3-6.2-0.3c-7.8,0.3-12.4-4.6-13.8-12.2
		c-0.9-4.6-1.2-12.4-5.6-15.3c-1.3-1-2.9-1.4-4.4-1.3c-4.6,0.7-5-3.7-6.5-7.2c-5-12.2-21.9-2.2-30.2,0.1c-4.2,1.1-7.7,3.7-9.9,7.3
		c-1.4-0.9-3.1-0.9-4.4,0.1c-15.5,10.8-10,34.9,6,41.4c-1.6,7.2-2.3,16.2,5.6,19.8c3.9,1.7,15.3,6.1,16.4,9.9
		c1.4,4.6-1.9,10.6-0.6,15.7c0.9,3.7,3,6.8,5.8,9.3c5.6,4.3,8,10.8,11.5,16.7c6.8,11.8,16.4,5.2,27.6,7.4c10.6,2,22.6,2.9,30.6-5.7
		c7.5-8.2,4.2-23.6,17.3-27.1c7.6-2,15.4,8.4,22.8,9.6c7.7,1.2,15.1-3.8,17.1-11.4c1.6-5.7,0.3-16.6,9.6-15.3c3.6,0.2,7-0.9,9.9-3
		c6.2-5.3,12.6-9.2,21-10.1c4-0.4,8-0.6,10.8-4c4-4.6,4.9-9.6,5.9-15.4c1.7-10.4,6-16.7,11.1-25.6s1.3-21.4-4.2-29.2
		c-7.3-10.1-13.4-21-18.1-32.5c-3.3-8-13.6-5.5-19.2-2.3c-11,6.4-16.3-7.6-22-13.5c-4.6-4.9-12.1-6.5-16.4-11.8
		c-6.1-7.4-13.4-11.2-21.4-4.9c-5.9,4.6-12.1,6.4-18.7,9.3c-2.3,1-4.8,3.6-4.8,6.1c0,11.8,11.5,23.1,21,28.6
		c8.2,4.7,16.9,8.9,10.7,19.8c-3.9,6.8-11.1,10.4-17.1,14.7c-3.1,2.2-1.9,8.5-1.4,11.6c0.7,4.3,2.6,7.9,2.7,12.4
		C272.8-21.7,261.8-17.7,255.2-15.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M241.7-172c-2.7-1.2-5.6,0-8.1,1.1c-5.7,2.5-13.7,1.7-20,2.7c-17.6,3-22.3,27.7-41.3,26.9
		c-10.8-0.5-19.7,4.2-21,15.8s-20.5,7.8-13.4,23.3c4.4,9.6,18,13.3,27.1,15.7c10.9,3,16.5,7.8,29,4.1c6.5-2,11.9-4.4,16.5-9.5
		c2.9-3.3,4-6.7,8.1-9.4c4.1-2.6,8.4-4,12.5-6.5c1.1-0.8,1.7-2.1,1.5-3.5c-0.2-2.6-0.7-5.1-1.3-7.6c-1.4-6.4,5.5-5.8,8.7-7.1
		c4.6-1.8,8.4-5.7,11.4-9.5c3.7-4.5,4.9-10.6,3.5-16.2C255.6-160.4,249.9-168.5,241.7-172z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1498.7,427.3c-8.2,2.3-14.4-3.7-21.8-6.3c-1.9-0.7-4.4-0.3-6.4-0.3c-8,0.3-12.9-4.8-14.3-12.7
		c-0.9-4.8-1.2-12.8-5.7-15.8c-1.3-1-3-1.5-4.6-1.4c-4.8,0.7-5.2-3.8-6.7-7.5c-5.2-12.7-22.6-2.3-31.2,0.1
		c-4.3,1.1-7.9,3.9-10.2,7.6c-1.4-1-3.3-0.9-4.6,0.1c-16.1,11.2-10.4,36.1,6.2,42.9c-1.7,7.5-2.4,16.8,5.7,20.5
		c4,1.8,15.8,6.4,17,10.3c1.5,4.8-2,10.9-0.6,16.3c1,3.8,3.1,7.1,6,9.6c5.8,4.4,8.4,11.1,12,17.2c7.1,12.2,17,5.4,28.6,7.6
		c11,2.1,23.4,3,31.6-5.9c7.8-8.5,4.3-24.5,18-28.1c7.8-2.1,15.9,8.7,23.6,9.9c8,1.2,15.6-3.9,17.7-11.7c1.6-5.9,0.3-17.2,9.9-15.9
		c3.7,0.2,7.2-0.9,10.2-3.1c6.5-5.5,13-9.5,21.7-10.5c4.1-0.4,8.3-0.7,11.2-4.1c4.1-4.8,5.1-9.9,6.1-16c1.8-10.7,6.2-17.2,11.4-26.4
		c5.1-9.2,1.3-22.2-4.4-30.2c-7.6-10.4-13.9-21.8-18.7-33.7c-3.4-8.3-14.1-5.7-19.9-2.4c-11.4,6.6-16.9-7.9-22.7-14
		c-4.8-5-12.6-6.7-17-12.2c-6.3-7.6-13.8-11.5-22.2-5.1c-6.1,4.7-12.5,6.6-19.4,9.6c-2.3,1-5,3.7-5,6.4c0,12.2,11.9,23.9,21.8,29.6
		c8.5,4.9,17.5,9.2,11.1,20.5c-4,7-11.4,10.8-17.7,15.3c-3.2,2.3-2,8.8-1.4,12c0.7,4.4,2.7,8.1,2.8,12.8
		C1517,421.3,1505.5,425.4,1498.7,427.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1484.8,265.7c-2.8-1.2-5.8,0-8.4,1.2c-6,2.5-14.2,1.7-20.7,2.8c-18.2,3.1-23.1,28.7-42.7,27.8
		c-11.2-0.5-20.4,4.4-21.7,16.4c-1.3,12.1-21.3,8.1-13.9,24.1c4.5,9.9,18.6,13.6,28.1,16.2c11.2,3.1,17.1,8,29.9,4.2
		c6.8-2.1,12.4-4.5,17-9.9c3-3.5,4.1-6.9,8.4-9.7c4.2-2.7,8.7-4.2,12.9-6.8c1.1-0.8,1.7-2.2,1.6-3.6c-0.2-2.7-0.7-5.3-1.4-7.9
		c-1.4-6.6,5.7-6,9-7.3c4.7-1.9,8.7-5.9,11.8-9.9c3.9-4.7,5.1-11,3.6-16.8C1499.2,277.8,1493.3,269.4,1484.8,265.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M182.2,843.3c-6.3-2.3-7.6-8.9-11-13.9c-0.9-1.3-2.7-2.2-4-3.1c-5.2-3.5-6-8.9-3.5-14.5
		c1.6-3.5,5-8.7,3.5-12.8c-0.4-1.2-1.2-2.3-2.3-3c-3.5-1.7-1.7-4.7-0.9-7.9c2.4-10.5-13.4-11.8-20.1-14.1c-3.3-1.2-6.8-1.1-10,0.3
		c-0.5-1.2-1.7-2-3-2c-15.3-0.2-23,18.4-15.5,30.3c-4.5,4.1-9.2,9.7-5.6,15.7c1.8,3,7.2,11.3,6.2,14.3c-1.3,3.8-6.2,6.1-7.8,10.2
		c-1.1,2.9-1.3,5.9-0.5,8.9c1.8,5.4,0.3,10.9-0.2,16.5c-1,11,8.4,11.2,14.8,17.9c6,6.2,13.6,12.6,22.9,10.6
		c8.9-1.9,13.8-13.7,24.2-9.8c5.9,2.3,6.2,12.9,10.7,17c4.5,4.4,11.8,4.6,16.6,0.5c3.7-3.1,8-10.9,13.5-5.6c2.3,1.8,5,2.8,7.9,2.7
		c6.6-0.6,12.7-0.2,18.6,3.2c2.9,1.6,5.6,3.4,9,2.5c4.8-1.2,7.8-4.1,11.2-7.4c5.9-6,11.9-8.2,19.4-11.8s10.9-13.6,10.9-21.4
		c-0.2-10.1,1-20.3,3.4-30.1c1.7-6.9-6.4-10.1-11.7-10.6c-10.2-1.1-7.2-13-8.2-19.5c-0.8-5.4-4.9-10-5.3-15.5
		c-0.6-7.8-3.7-13.6-11.8-13.3c-6,0.3-11-1.4-16.7-2.7c-2-0.4-4.8,0-6.1,1.8c-5.5,7.8-3.3,20.7,0.5,28.8c3.3,7,7,13.8-2.2,18.1
		c-5.7,2.7-12.2,1.7-18.3,1.7c-3.1,0-5.2,4.7-6.3,7c-1.5,3.2-2,6.4-4,9.4C196.7,847.8,187.4,845.3,182.2,843.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M246.7,733.5c-1.3-2-3.8-2.7-5.8-3.2c-4.9-1.2-9.9-5.3-14.5-7.6c-13.1-6.3-27.9,7.8-40-1.7
		c-6.9-5.4-14.9-6.4-21.3,0.7c-6.3,7.1-17.2-4.4-19.9,9.1c-1.6,8.4,5.7,17.2,10.6,23.1c5.7,7,7.3,12.9,17.2,16.3
		c5.2,1.8,9.9,2.7,15.3,1.4c3.5-0.8,5.7-2.6,9.8-2.4c4.1,0.2,7.5,1.3,11.4,1.5c1.1,0,2.1-0.6,2.7-1.6c1.1-1.8,2-3.7,2.7-5.6
		c2.1-4.8,6.4-1.3,9.1-0.6c3.9,1,8.2,0.2,12-1c4.5-1.3,8.3-4.7,9.9-9.2C250.5,747.9,250.5,739.8,246.7,733.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M144.6,131.5c9.7,5.5,13.5-2.1,19.1-8.5c12-13.8,20.4-24.6,18.2-43.9c-1.3-11.1-5-20.7-13.4-26.2
		c-1.8-3.5-4-6.7-6.6-9.6c-5.4-6.1-19.6-14.1-27.6-8c-19.3,14.4-11.7,50.9-6.1,70.1C130.9,115.6,135,126,144.6,131.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M40,183.5c-1.6-1.4-4.3-0.5-5.9,0c-3.6,1-5.8,4.6-7.8-3.3c-0.7-2.8-1.2-5.5-2.1-8.3c-0.6-1.4-2.2-2.1-3.6-1.5
		c-16.4,5.9-20.7,19.2-17.2,35.4c2.6,12.7,10.6,34.9,28,26.4c5.5-2.7,9.5,6.7,12.7,9.9c3,3.1,6.6,5.3,10.7,6.9
		c6.2,2.4,12.3-0.6,17-4.6c6.9-5.7,11.8-13.3,20.2-17.2c3.2-1.5,6.7-1.1,10-2.3c3.7-1.4,5.7-6.2,6.9-9.6c1.1-2.9,0.7-6.1,0.6-9.2
		c-0.5-9.6,7.9-7.7,13.5-8.4c6.6-0.8,13-6.5,14.7-12.9c1.9-6.7,0.9-14-2.9-20c-3.4-5.2-9.3-6.8-10.9-13.6c-1-4.3,0.9-8.9,0.5-13.3
		c-1.2-13.2-16.6-19.6-27.9-18.6c-13.8,1.3-20.9,13.4-19.2,26.3c0.5,4,3.5,7.6,5.6,10.7c1.5,2,2.9,4.1,4,6.2
		c2.9,6.2-4.8,9.1-8.5,10.4c-5.9,2.1-10.4,4-9.7,11.1c0.4,4.3,3.3,8.5,5.2,12.2c4,7.5-2.4,10.8-9.5,12c-6,1-14.3,0.5-18.8-3.8
		c-0.5-0.5-1.1-0.9-1.7-1.2c0-0.1,0.1-0.3,0.1-0.4C44.5,195.2,46.2,189,40,183.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M12,154.4c5.7,1.1,12.1,6.8,18.1,5.7c5.3-1,9.1-7.4,13.1-10.8c7.4-6.4,12.8-10.6,14.4-20.8
		c1.2-6.7-3.2-12.7-8.6-16c-2.7-1.8-5.5-3.1-8.7-3.8c-4.7-0.8-12,0.4-13.4-6c-0.2-1.5-1.6-2.5-3.2-2.3c-9.5,2.2-15.2,6.3-18.3,12.3
		c-3.7,1.9-5.9,5.2-6.6,9.8c-1,6.9-6.4,14-3.3,21.4C-1.4,150.8,4.8,153,12,154.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-34,22.8c5,6.4,11,12,13.8,19.8c2.1,5.5,3.3,11.3,5.9,16.6c4.4,9,17.3,2.5,23.3-0.9
		C29.9,46.5,47.4,24.1,35.8,0c-5.9-12.1-10.5-22.3-11-36.1c-0.7-20.3,19.3-21.6,32.7-14.2c8.9,4.8,16,23,14.5,32.8
		C70.6-7.3,61.7-4.3,59,4.4c-5.4,17.3-18.1,50.1,1.3,58.3c-0.7,0.3-1.5,0.6-2.3,0.9c-5.4,1.8-3,10.3,2.5,8.5
		c6.6-2.1,9.9-4.6,16.9-2.1c4.6,1.7,7.5,1.2,11.8-1c13.5-6.9-0.6-28.2,15.5-34.1c15.8-5.8,12.5-16.1,19.7-29.9
		c5.7-10.8,15.2-23.7,12.3-36.8c-1.7-7.4-5.1-11.8-9.7-17.7s-13.2-6.6-17.7-12.8c-4.5-6.1-3.5-12.6-5.5-19.4
		c-2.3-7.1-9.1-14-13.9-19.6c-7-7.9-17.5-7.3-26.4-3.2c-15.8,7.3-26.1-14.3-41.5-5.6c-9,5.2-26.4,9.7-32,19.4
		c-1.8,3.2-2.6,7.4-4.2,10.7c-2.6,5.2-9.3,7.2-14.2,9c-4.3,1.5-6.7,4.1-8.7,8c-6.5,12.8-0.5,29.3-2.8,42.9
		C-42.5-4.2-44.7,9.2-34,22.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M174.3,157.5c-9.2,17.6,4.9,39.3,23.1,42.5c6.9,1.3,13.8,1.9,20.2,5.3c7.7,4.3,0.4,16.8-1.1,22.3
		c-2.9,11,1.6,23.8,7.6,33c4.4,6.6,17.8,7.9,24.6,10.5c11.6,4.4,23.1,8.7,35,3.3c24.9-11.5,24.7-37.5,5.1-54.6
		c-14-12.3-29.2-27.3-36.8-44.7c-2.3-5.1-4.4-10.4-6-15.6c-2.2-6.6-4.3-15.7,6.2-10.5c13.1,6.5,14.2,28.5,28.9,31.7
		c8.7,1.9,13.6-4.9,23.1,1.5c4.3,3.1,9.5,4.6,14.7,4.5c8.9-0.4,20.2-9.7,25.6-16.1c3.2-3.8,3.7-9,2.9-13.8c4-11.6,9-38.2-9.5-39
		c-14.2-0.6-33.7,15.6-47.3,8.5c-8.8-4.6,2.8-20.6,3.6-27.3c1.1-8.8-11.4-9.5-16.9-8.6c-6.8,1.1-13.3,3.6-19.3,7.1
		c-8.1,4.6-16.8,12.3-26.9,8.5c-11.2-4.2-18.1-4.9-26.6,5.4c-5.9,7.3-16.7,10.5-20,20.1c-0.9,2.6,0.1,5.3-0.3,6.4
		C182.1,145.7,178,150.6,174.3,157.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M342.4,295.4c3.7-2.1,7-4.6,10.1-7.4c5.8-5.1,11.8-7,18.7-10c5.2-2.3,12.4-6.9,12-13.5
		c-1.1-18,19.7-31.2,15.8-50.1c-2.3-11-10.6-34.6-23-38.2c-12.5-3.6-25.7,11.1-31.2,20.7c-4.3,7.4-1.5,14.5-0.1,22.4
		c3.2,16.6-10.2,21.6-22.2,27.7c-10.1,5.1-16.8,13.1-14.6,23.1C293.5,281.4,331.4,301.6,342.4,295.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M777,199.4c-8.8,1-6.4,13.3-4.4,18.4c2.7,6.3,6.6,12.2,11.5,17c6.4,6.7,15.9,13.4,14.6,24.1
		c-1.4,11.8-0.5,18.7,11.6,24.5c8.5,4.1,14.1,13.6,24.2,14.5c2.7,0.2,5.1-1.4,6.3-1.3c8,0.7,13.7,3.6,21.4,5.4
		c19.3,4.7,36.9-14.1,35.7-32.5c-0.5-7-1.5-13.9,0.4-20.9c2.3-8.5,16.2-4.4,22-4.3c11.4,0.2,22.7-7.2,30.3-15.2
		c5.4-5.8,3.5-19.1,4.4-26.4c1.5-12.4,3-24.5-5.1-34.8c-17-21.6-42.2-15.1-54.2,8c-8.6,16.6-19.6,34.8-34.6,46.4
		c-4.4,3.5-9,6.6-13.7,9.6c-5.9,3.7-14.2,7.9-11.7-3.6c3.2-14.2,24.2-20.6,23.9-35.7c-0.2-8.9-8-12.1-4.1-22.8
		c2-4.9,2.3-10.3,0.9-15.4c-2.5-8.5-14.1-17.2-21.8-21.1c-4.4-2.2-9.6-1.4-14.2,0.5c-12.2-1.2-39.3,0.3-35.7,18.5
		c2.8,14,23.1,29.1,19.5,43.9C802,206,783.8,198.6,777,199.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M881.7,121.9c15.4-7,23.3,4.7,32.2,14.9c7.4,8.6,16.7,13.3,26,8.7c14.5,11.3,25.1-30.4,16.5-39.6
		c-3-3.1-6.1-5.7-9.7-8c-6.4-4.4-9.6-9.8-14.1-15.7c-3.5-4.5-9.6-10.4-16-8.4c-17.2,5.3-35-11.8-52.5-3.5
		C854,75,833,88.8,832.5,101.6c-0.6,12.9,16.9,22.3,27.5,25.4C868.1,129.5,874.4,125.2,881.7,121.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M315.7,83c0.4,3,3.1,5.1,6.1,4.8c0.2,0,0.5-0.1,0.7-0.1l6.9,15.9c1.3,3,4.6,4.3,7.5,3.1
		c13-6.3,21.4-20,25.9-33.2c5-14.5,3.2-27.6-8.6-37.8c-10.1-8.8-22.2-2.2-28.8,7.1c-4.3,6.2-7.2,13.3-8.8,20.7
		c-2.3,1.4-3.2,4.4-2,6.7l0.7,1.6C315.2,75.5,315.2,79.2,315.7,83z"/>
            <path :fill="colors[`${id}-p-1`]" d="M233.9,411c-0.1,1.3,0.5,2.6,1.6,3.3c2.8,2.2,0.7,9.6,3.5,13.2c4,4.9,6.6,9.1,5.4,15.7
		c-0.3,1.9-0.1,3.9,0.7,5.6c5.7,12.6,18.7,20.6,30.5,27c6,3.3,9.8,2.2,16.2,2.6c7.6,0.5,11.9,4.2,19.9,3.1
		c8.9-1.2,11.2-5.9,10.6-14.5c0.1-1.7-1-3.2-2.5-3.7c-8.3-2.8-16.2-10.5-14-20c1-4.4,1.6-9.8,0.4-14.3c-0.4-2.4-1.9-4.4-3.9-5.6
		c-7.5-5.4-1.2-9.6-2.3-15.2c-0.4-2.3-1.6-4.3-3.5-5.5c-3.4-2,9.9-15.2,10.7-15.8c8.2-6.9,20.5-7.9,23.2,4.2
		c0.3,1.3,1.2,2.4,2.5,2.8c26.3,6.2,16.9,31.3,15.1,50.3c-0.1,1.7,0.9,3.2,2.5,3.7l3.7,0.9c-1.6,3.5-3.3,7.7-5.2,11.2
		c-0.2,0-0.4,0.1-0.6,0.2c-1.4,0.5-2.8,1.1-4.1,1.7c-1.5-1.5-4-1.5-5.4,0c-0.4,0.4-0.6,0.8-0.8,1.3l-0.5-0.4
		c-1.3,0.2-2.4,1.2-2.8,2.5l-0.6,2.7c-0.1,0.6-0.1,1.3,0,1.9c-1.1,1.8-1.9,3.8-2.3,5.8c-1.4,7.2,5,14.4,11.1,17
		c5.5,2.3,9.3-2.3,14.1,4.3c1.9,2.6,4.1,5.7,7.1,6.9c7.2,2.7,12.9,3.7,19.7-0.5c7.9-4.7,11.8-13.8,18.4-20c6.3-5.7,13-4.8,16.5-13.8
		c1-2.6-0.9-5.4-2.2-7.4c-2.7-4.1-2.9-3.6-1.3-7.8c1.9-5.2,0-8.6-3.8-11.5c1.5-6.4-1.9-11.8-5.1-17.8c-4.3-7.9,5.1-21.2,7.7-28.7
		c1.6-4.6-1-11.4-4.6-14.4c-2.3-1.9-4.9-5.8-7.7-6.4c-6.4-1.5-5.1-2.5-3.1-7.7c0.4-1.3,0.2-2.6-0.7-3.6c-1.2-1.2-5.4-4.1-5.7-5.4
		c-0.8-2.9-0.2-5.7-0.8-8.5c-1.7-8.4-10-13.3-12.3-21.5c-3-11.1-10.5-10.5-20.1-11.3c-5.1-0.4-9.5-0.9-14,2.4
		c-4.7,3.5-12.3,8.3-16.6,1.3c-0.8-1.4-1.9-2.7-3.1-3.8c-6.6-5.3-17.4-10.6-26.1-8.3c-10.9,3.1-21.4,7.6-31.1,13.4
		c-8.2,4.7-18.6,10.4-23.8,18.6c-1.4,2.2-3.3,5.5-2.4,8.3c0.9,2.8-4.6,9-6,12.8c-3.5,9.1,1.3,19.8-0.5,29.3
		C234.5,397.5,235,404.1,233.9,411z"/>
            <path :fill="colors[`${id}-p-1`]" d="M545.5,248.6c-5.3-3.8-12.3-9.2-18.8-10.5c-6.2-1.3-11.8,0.6-17.8-3s-7.3-11.2-13.1-14.5
		c-5.7-3.5-10-6.1-16.7-6.9c-12-1.3-22.5,8.5-31.5,14.6c-11.5,7.8-21,5.8-24.6,20.5c-3.8,14.9-24,4.4-28.9,17.2
		c-1.5,4-1.7,6.6,0.3,10.6c3,6,1.1,9.1-0.2,15.2c-1,5,6.8,6.3,7.8,1.4c0.1-0.7,0.3-1.4,0.5-2.1c9.2,16.5,37.2,1.9,52-4.6
		c7.5-3.3,9.4-11.5,18.3-13.8c8.6-2.3,25.5,2.2,30.8,9.7c7.9,11.3,8.7,29.2-9.4,30.6c-12.3,1-22-2.2-33.3-6.1
		c-22.6-7.9-40.7,9.9-49.2,29.6c-2.5,5.6-7,17.8,1.5,20.9c5,1.8,10.2,2.3,15.3,3.6c7.2,1.8,12.8,6.5,19,10.4
		c13.3,8.2,24.9,4.9,38.8,1c12-3.5,27.2,0.3,38-6.7c3.3-1.9,5.5-4.9,6.3-8.6c1.1-4.6,2.2-10.8,6.6-13.5c2.8-1.8,6.5-2.9,9.1-4.7
		c8-5.8,10.4-21.9,13.9-30.3c6.2-14.6-14-21.7-9.2-36.5C553.7,263.4,553.3,254,545.5,248.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M655.8-158.5c-2.3-8.7-3.1-15.6-13.6-16.5c-7.8-0.7-13.8-7.4-21.8-7.6c-2.6-0.1-5.1,1-7.9,0.7
		c-1.4-0.1-4.6-3.6-5.9-4.4c-1.1-0.6-2.4-0.6-3.5,0c-4.4,2.8-5.1,4.1-7.5-1.5c-1.2-2.5-5.1-4.3-7.2-6c-3.4-2.8-10-4-13.9-1.8
		c-6.4,3.6-17,14.4-25,11.9c-6.1-2-11.5-4.3-17.2-1.8c-3.3-3-6.7-4.2-11.2-1.5c-3.7,2.1-3.2,1.9-7.3,0.1c-2.1-0.9-4.9-2.2-7.2-0.8
		c-7.6,4.7-5.7,10.7-9.9,17.4c-4.4,7.1-12.1,12.2-15.2,20.2c-2.7,6.9-0.9,12,2.8,18.2c1.6,2.7,4.8,4.1,7.5,5.4
		c6.7,3.4,3.3,7.5,6.2,12.3c3.3,5.1,11,9.8,17.4,7.3c1.9-0.7,3.6-1.7,4.9-3.1c0.6,0,1.2-0.1,1.8-0.3l2.3-1c1.1-0.6,1.8-1.7,1.8-3
		l-0.5-0.4c1.7-1.1,2.2-3.3,1.1-4.9c-0.3-0.4-0.6-0.8-1-1c0.3-1.3,0.6-2.7,0.8-4.1c0-0.2,0-0.4,0-0.6c2.9-2.3,6.4-4.6,9.4-6.6
		l1.4,3.2c0.8,1.4,2.3,2,3.8,1.7c17.1-4.8,38.6-17.6,48.8,5.4c0.6,1.1,1.7,1.8,3,1.8c11.5,0.6,12.7,12,7.7,20.7
		c-0.7,0.8-10.7,15.1-13,12.5c-1.5-1.5-3.6-2.3-5.6-2.3c-5.3-0.1-8.1,6.4-14.3,0.5c-1.5-1.7-3.6-2.6-5.8-2.6
		c-4.4-0.4-9.1,1-13.1,2.7c-8.4,3.6-16.8-2.3-20.7-9.5c-0.8-1.3-2.3-2-3.8-1.7c-8,0.9-11.9,3.8-11.5,12.2c0.4,7.5,4.4,10.9,6.1,17.7
		c1.5,5.8,1.1,9.5,5,14.4c7.8,9.8,17.3,20.3,29.8,23.4c1.8,0.4,3.6,0.3,5.2-0.3c5.8-2.2,10.1-0.5,15.3,2.4c3.7,2,10.2-1.2,12.7,1
		c0.9,0.9,2.1,1.2,3.3,0.9c6-2.1,12.3-2.8,18.3-5.1c8.4-3.2,19-0.7,26.8-5.3c3.3-2,8-8,10.7-7.6s5.4-1.9,7.2-3.6
		c6.6-6.2,10.1-16.7,13.1-25c3.8-9.9,6.1-20.2,7.1-30.7c0.7-8.3-5.9-17.3-12-22.5c-1.2-1-2.5-1.7-4-2.2c-7.2-2.8-4.1-10.5-1.7-15.4
		C658.3-150,657.1-154,655.8-158.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M420.8-30.3c7.4,5.2,20.5,4.3,26.9-2.5c3.5-3.6,4.4-8.8,8-12.2c3.9-3.7,12.8-2.7,17.7-6
		c9.3-6.5,4.1-21-1.7-27.8c-3.5-4.2-8.5-6.7-13.3-9c-9.4-4.6-11.8-5.7-13.8-16.6c-5-25.7-31.1-27.3-48.9-12.7
		c-0.4,0.4-0.8,0.8-1.2,1.2c-1.3,1.3-2,3.2-1.9,4.9c0,0.2,0,0.5,0.1,0.7c0,0.1,0,0.2,0,0.3c0.1,0.5,0.2,1.1,0.5,1.6
		c6.6,15.7,5,28.2,4.7,44.6C397.5-49,410-37.9,420.8-30.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1733.7-71.3c-1.7,12.8-6,24.3-4.1,37.5c1.7,10.8,11.6,14.4,16.3,23.1c5.9,10.8,9.4,12,21.6,12.8
		c4.7,0.3,10.4,5.4,14.9,6.9c4.4,1.4,9.1,1,13.2-1.2c1.4-0.7,9,2.2,10.8,2.3c4.4,0.3,8.9-0.8,12.7-3.3c7.4-4.9,14.5-13.2,20.1-20.1
		c6.4-8,10.7-12.9,11.1-23.8c0.3-10.6-3.8-23.5-7.2-33.3c-2.8-7.6-7.4-14.1-16.1-14.4c-17.1-0.5-39.5,23.8-46.7-3.6
		c-4.5-17-15.1-30.5-32.8-35.9c-8.4-2.6-13.5,1.2-15.4,6.9c-3.8,2.2-3.5,8.2,0,11.2c1.1,3.8,3.4,7,6.4,9.4c0.3,0.2,0.7,0.4,1.1,0.6
		c1,2.8,1.1,5.7,0.2,8.5C1737.8-82,1734.5-77.4,1733.7-71.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M729.7-9.2c5.5-9.7,6.9-18.6,7.1-29.3c2.6-2.3,6.5-3.8,8.9-5.7c2.7-2.3,3-7.8,3.5-10.9
		c0.8-5.8,0.5-11.7,1.4-17.5c0.5-3.9,1-8.5-0.5-12.3c-7-18.2-35.6-22.7-49.4-11c-7.7,6.5-12.4,20.3-14.3,29.8c-1,4.6,0,9.1,1.5,13.5
		c1.7,5.1,7.1,7.2,9.2,12.1c6.6,15.1-2.3,30.2-6.4,44.6c-4.3,14.9,1,30.9-1.5,46.1c-1.6,9.6,4.6,21.2,10,28.6
		c7.6,10.4,19.9,10.6,31,14.6c12,4.4,22.2,7.6,35.1,9.2c10.4,1.3,19.1,10.4,30,7.1c21.6-6.4,41.8-20.3,51-41.1
		c5.1-11.8,3.7-24.3,3.7-36.9c0-9.1,0.8-16.3,3.9-24.8c2.1-5.9,1.2-9.2-1.7-14.5c-2.1-3.9-4.4-7.5-6.3-11.5s-1.9-10.3-2.7-14.8
		c-1-5.9-5.3-9.4-9.9-12.9c-9.6-7.4-9.8-14.7-12.1-25.8c-1.4-7-6.2-12.9-11.1-17.8c-9.4-9.6-24.9-14.4-35.8-4.7
		c-10.1,9-10.7,31.7-5.9,43.4c3.1,7.8,11.3,13.5,16.3,20C793.6-20.4,802-8,804.1,6.7c0.7,4.9-1.8,10.5-2.4,15.5
		c-0.6,5,4.9,9.4-0.2,14.2c-8.2,7.8-25,1.5-33.6-0.8c-8-2.1-14.8-1.8-22.4,1.5c-5.1,2.3-8,4.4-12.6-0.9c-3.4-3.9-5.5-9.4-7.7-14
		c-2.7-5.4-5.6-13.3-3.8-19.4C722.9-1.7,727.5-5.3,729.7-9.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1037.3-108.1c-10.6-10.8-26.6,9-37.1-4.6c-10.4-13.3-19.1-7.1-34.3-9.9c-12-2.2-27.2-7.4-38.8-0.8
		c-6.5,3.8-9.6,8.4-13.9,14.4c-4.4,6-2.4,14.5-6.8,20.7c-4.4,6.2-11,6.9-16.8,11.1c-6.1,4.3-10.7,12.9-14.5,19.1
		c-5.4,9.1-1.8,18.8,4.7,26c11.7,12.9-5.8,29.2,7,41.2c7.4,6.9,16.9,22.2,27.8,24.5c3.6,0.8,7.8,0.2,11.4,0.7
		c5.7,0.9,9.7,6.7,12.9,10.9c2.8,3.7,5.8,5.1,10.3,5.9c14.1,2.4,28-8.3,41.7-10.1c15.9-2.2,29.3-4.1,39.1-18.4
		c4.6-6.7,8.2-14,14.7-19.1c4.6-3.7,9.8-6.4,14-10.6c7.3-6.9-2.8-17.2-7.8-21.9c-17.5-16.2-43.9-26.2-63.5-8
		c-9.8,9.1-18.2,16.6-31.1,21.2c-19.1,6.6-26.3-12-23.3-27c2-10,17.2-22.1,27-23.6c10.2-1.6,15.7,5.9,24.8,5.9
		c18.1,0,53.1,2.3,55.1-18.6c0.5,0.6,1,1.2,1.5,1.9c3.3,4.6,10.7-0.2,7.3-4.8c-4-5.7-7.3-8-7-15.4
		C1042-102.1,1040.6-104.7,1037.3-108.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M879.1-77.7c15.1-14.3,27.9-42.1,12.7-60.4c-5.2-6.2-17.3-14.1-25.9-9.8c-5.8,3-9.8,8.4-10.4,14.4
		c-1.8,3.3-3.2,6.8-4.2,10.4c-3.6,13.2-6.6,25.5-13.4,37.6c-4.2,7.1,6.8,15.5,12.9,16.4C861.5-67.4,871-70,879.1-77.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1286.6,66.9c-4.9-0.5-9.9-1.2-14.7-2.1c-16.6-3.2-32.6-13.3-46.1-23c-18.8-13.5-41.3-8.5-46.2,15.3
		c-2.3,11.4,3.8,20.5,9.8,29.7c3.6,5.4,7.3,16.7,13.9,19.2c9.2,3.4,21.2,4.6,30.1,0c4.4-2.3,13.8-11.2,19.1-5.3
		c4.3,4.7,6.2,10.6,8.7,16.4c6.4,15,28.1,23,41.4,11.5c5.2-4.5,8.7-9.1,14.7-12.9c0.9-0.5,3.5-0.2,5.5-1.5
		c7.6-4.7,8.3-14.6,13.4-21.3c7.3-9.4,5.2-15.2-0.6-24.1c-5.2-7.9-0.4-17,2-24.9c1.9-5.8,2.7-12,2.3-18.1
		c-0.4-4.9-3.5-15.6-10.8-12.9c-5.6,2.1-17.1,15.2-22.8,8.5c-8.9-10.3,1.3-30.4-2.2-42.5c-4.4-15.9-26.4-6.1-35.7-0.4
		c-4.4,0.3-8.9,1.8-11.5,5.2c-4.4,6-10.2,17.7-8.8,25.4c1,4.5,3.4,8.8,6.9,11.9c7.5,7,2.6,12.7,5.9,19.8c5.7,12.1,25,8.6,33.2,18.7
		C1301,67.5,1292.7,67.4,1286.6,66.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1178.6,35.4c9.2-0.1,14.7-7.6,17.1-17.3c3-11.7,4.4-24.1,19.6-24.8c7.1-0.3,13.8,0.6,19.4-4.5
		c7.2-6.6,17.2-21,11.7-31.1c-5.5-10.2-27.8-12.5-37.7-12.3c-17.2,0.4-24.4,21.1-40.2,23.7c-5.8,1-8.4,8-9.3,13.1
		c-1.3,6.6-1.7,12.1-4.9,18.2c-1.9,3.3-3.4,6.7-4.4,10.3C1146.5,21.5,1171.5,50.2,1178.6,35.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1311.4-47.2c10-0.4,18.9-0.9,25.9-8.9c12.9-14.6,5-36.8-9.5-46.8c-7.3-5-17.1-7.2-24.1-4.6
		c-3.9-2.8-10.8-1.3-11.3,4.9c-1,15.2-29.6,40.1-6,52.6C1293.8-46,1303.2-46.9,1311.4-47.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M325.6,562.7c8.2,1.2,16,4.6,23.8,7.3c12.1,4.3,25.5,3.2,35.1-5.8c7.7-7.2,10.4-17.6,4.3-27
		c-6.6-10.2-19.2-12.4-27.9-20c-9.8-8.6-20.9-8.9-32.9-10.7c-8.7-1.3-17-3.5-22.4,2.7c-0.3,0.2-0.7,0.4-1,0.7
		c-16.1,14.2-13.4,38.9,5.2,49C314.5,561.5,320.5,561.9,325.6,562.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M515.1,512.9c2.5-6.6,2.5-13.8,0.1-20.6c-2.4-6.6-9.4-10-12.1-16.4s-6.8-8.9-13.6-8.1c-4.2,0.5-5.7,2.1-10,0.6
		c-2.7-1-5.4-1.8-8.2-2.3c-16.3-3-24.5,16.8-34.9,24.6c-5.1,4-9.3,9-12.3,14.8c-4.4,9.2-3.7,17.4-3.7,27.5c0,1.9,0.7,3.8,2.1,5.1
		c-0.9,9.1,2.4,16.8,12.3,23.8c3.1,2,6.4,3.5,10,4.6c3.8,1.4,9.6,0.4,12.9,2.5c5.3,3.4,11.4,5.5,17.6,6.6
		c12.7,1.7,28.6-3.3,34.3-15.8c3.2-6.9,2.6-13.8,1.5-21.2c-0.7-4.7-2.1-9.7-0.7-14.4C511.5,520.3,513.7,516.7,515.1,512.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M441,437c7.8,15.1,22.1,15.7,36.7,12.5c12.6-2.8,20.2,10.4,33.2,6.9c6.3-1.7,12.3-8.4,16.8-12.7
		c6-5.3,10.5-12.2,12.9-19.9c3.4-10.7,1.2-25.3-11.4-28.3c-11.3-2.7-22.3,0-33.6-3.7c-17.1-5.5-47.3-16.3-54.5,8
		c-0.6,1.7-0.4,3.6,0.6,5C439.1,415.7,435.4,426.1,441,437z"/>
            <path :fill="colors[`${id}-p-1`]" d="M143.6,238.2c12.2,3.4,27.2,12.7,40,7c10.4-4.6,14.5-15.5,8.6-25.4c-5.5-8.8-14.6-14.7-24.8-16.4
		c-5.1-0.8-9.2,0.7-12,3.6c-2.9-0.2-5.6,1.5-6.7,4.2c-1.4,3.4-4.7,5.6-6.9,8.5c-3,3.9-3.4,8.7-2.6,13.3
		C139.6,235.3,141.3,237.4,143.6,238.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1940,203.1c2.8,12.9,14,19.9,21.9,29.6c11.9,14.7,31.9,23.6,51.1,18.6c20.3-5.2,22.6-28.3,12.9-44.2
		c-6.2-10.2-18.8-14-27.6-21.4c-8.8-7.3-2.5-14.8,5.2-18.3c6.7-3.1,13.5-2.8,20.4-0.2c12.5,4.7,19.6,16.3,33,19.6
		c8,1.9,17.3-3,18.8-11.5c2-11.7-3.6-19.6-11.3-27.6c-4.8-5.1-9.4-10.2-11.4-17.1c-1.7-5.6-3-12.1-7.8-16.1s-9.7-5.2-15.5-7.6
		c-5.2-2.1-9.4-6.5-14.1-9.4c-10-5.9-21.4-4.2-31.1,1.7c-10.5,6.4-19,15.5-24.7,26.4c-2.9,5.4-3.8,12.2-4.7,18.1
		c-1.5,8.6-7.2,10.3-10.8,17.1c-1.5,3-2.1,6.3-1.8,9.6C1937.3,180.4,1937.7,192.5,1940,203.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M53.4,528.9c5.5,8.2,16.3,17.9,27.2,16.5c4.7-0.7,9.2-2.5,13.1-5.3c3.8-2.6,7.9,4.4,11,5.4
		c9.6,3.2,12.5-5.5,13.9-13.3c0.9-4.8-0.6-9.3-2.7-13.5c-2.9-6-8.3-12.6,1.3-14.9c14.2-3.6,24.2,5.8,32.3,16.3
		c4,5.1,5.1,9.9,4.6,16.3c-0.3,4-1,9.6,1,13.3c5.3,9.6,15.2,11.6,25.4,8.5c13.9-4.3,15.5-25.4,18.6-36.8c1.5-5.4,2.2-11.1,2-16.8
		c5.7-5.9,6-13.2,1.3-21.1c-4.1-6.4-6.2-13.8-6-21.5c0-6.3-1.3-13.5-6.2-17.9c-7.9-6.9-19-10-29.3-11.1c-5.6-0.6-10.7,0.8-15.5,2.8
		c-2.5-1.2-4.7-2.7-7.4-2.9c-4.2-0.5-8.4-0.1-12.5,1c-5.6,1.9-10.6,5-15.6,8c-18.3-5.3-45.3-18.9-55.5-2.3c-4.7,7.6-2.7,19.6,1,27.3
		c-1,1.2-2,2.5-2.9,3.8c-4.2,6.1-4.1,13.1-1.9,19.7c-0.4,3-0.6,6-0.9,8.8C48.6,508.8,47.8,520.5,53.4,528.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M655,1007.1c5.5,8.2,16.3,17.9,27.2,16.5c4.7-0.7,9.2-2.5,13.1-5.3c3.8-2.6,7.9,4.4,11,5.4
		c9.6,3.2,12.5-5.5,13.9-13.3c0.9-4.8-0.6-9.3-2.7-13.5c-2.9-6-8.3-12.6,1.2-14.9c14.2-3.6,24.2,5.8,32.3,16.3
		c4,5.1,5.1,9.9,4.6,16.3c-0.3,4-1,9.6,1,13.3c5.3,9.6,15.3,11.6,25.4,8.5c14-4.3,15.5-25.4,18.6-36.8c1.5-5.4,2.2-11.1,2.1-16.8
		c5.7-5.9,6-13.2,1.3-21.1c-4.1-6.4-6.2-13.8-6-21.5c0-6.3-1.3-13.5-6.2-17.9c-7.9-6.9-18.9-10-29.3-11.1
		c-5.6-0.6-10.7,0.8-15.5,2.8c-2.5-1.2-4.7-2.7-7.4-2.9c-4.2-0.5-8.4-0.1-12.5,1c-5.6,1.9-10.6,5-15.6,8
		c-18.3-5.3-45.3-18.9-55.5-2.3c-4.7,7.6-2.7,19.6,1,27.3c-1,1.2-2,2.5-2.9,3.8c-4.2,6.1-4.2,13.1-1.9,19.7c-0.4,3-0.6,6-0.9,8.8
		C650.2,986.9,649.4,998.7,655,1007.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2361,839.6c-3.4-3.8-8.2-6.4-12.4-9.2s-7.7-7.1-12.3-9.4c-10.9-5.2-24.2-5-35.9-2.8c-5.1,1-7.3,2.6-11.1-3.5
		c-1.5-2.8-2.9-5.5-4.1-8.5c-1.7-3.7-6.2-7.2-9.4-9.6c-8.5-6.4-18.4-0.7-27.5,0.6c-8.5,1.2-15.7,0-22.7,5.6
		c-7.5,5.9-16.7,20.3-18.6,29.8c-1.9,9.7,2.9,18.8,11.9,23.2c12.9,6.3,21-1.6,33.2-5.2c5.6-1.5,11.5-2.5,17.4-2.8
		c9-0.7,11.8,1.7,16.8,8.4c10.2,13.4,37.1,24.1,23.3,44.6c-7.4,11-30.8,17.4-40.5,8c-9.8-9.3-20.1-19.6-28-30.6
		c-4.8-6.7-13.8-11-21.5-5.6c-9.4,6.6-12.3,17.2-12.6,28.3c-0.3,10.3,2.5,20,9.5,27.6c5.2,5.6,12.5,9,18,14.3
		c5.3,5.2,11.3,9.7,17.7,13.3c11.8,6.6,27.1,8.1,40,11.1c15.6,3.6,29.9,6.3,45.6,1.7c0.6-0.2,1.1-0.5,1.5-0.9
		c1.9-0.4,3.7-1.2,5.1-2.4c4.9-4.2,1.5-13.3,8-16.5c5-2.4,9.6-5.8,13.3-10c4.7-5.4,11.4-14.3,5.4-21.6c-5-6.1-8.2-9.6-5.4-18.4
		c1.8-5.6,4.6-10.3,5.1-16.3c0.8-8.9-16.7-15.7-10.4-23.4C2366.6,852.8,2367.6,847.2,2361,839.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2131.8,757.1c7,0.5,11.4-1.4,17.8-4c12.2-4.8,19.4,4,26.6,11.8c5.4,6,14.2,14.6,22.2,17.1
		c11.2,3.5,19.2-6.2,28-11.3c10.9-6.1,24-2.5,35.9-4c10.9-1.3,21.2-6,29.2-13.5c13.6-13.3,14.4-38.5-8.2-40.8
		c-11.3-1.2-20.2,4-29.9,9c-15.6,8.2-42.5,9.4-53.4-6.5c-5.4-7.9-10.4-12.2-20.2-13.6c-4.4-0.6-10-0.9-13.5-3.8
		c-4-3.2-5.8-8.8-8.4-12.9c-1.9-3-4.5-5.4-7.7-7.2c-3.8-4.6-9.3-4.5-15.4-2.2c-8.7,3.3-13.4,13.2-14.4,22c-1,9.1-0.1,16.3-9,21.7
		c-3.6,2.2-6.5,4.3-8.9,7.6c-5,7.1-4,16.9,2.6,22.7C2111.7,755,2123.3,756.5,2131.8,757.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2177.4,674.1c3.4,11.4,14.3,17.7,25.6,10.4c4.6-3,8.1-6.5,13.3-8.7c5.6-2.4,15.4,1.7,21,3.3
		c14,4.1,6.9,11.3,12.7,20.4c6.2,9.9,25.3,9.8,34.1,3.6c1.8-1.5,3-3.5,3.5-5.7c0.9-4.9,2.5-9.8,4.8-14.2
		c4.4-8.2,11.7-16.5,11.7-26.3c0.1-8.5-3-16.8-8.5-23.2c-5.3-6-17.5-5.5-19.9-13.9c-1.9-6.4-2.6-10.7-8.9-14
		c-7.3-3.8-14.2-0.5-20,4.2c-5.9,4.8-9.5,5-17,3.5c-3.4-0.7-7.2-1.5-10.6-0.7c-9.3,2-12.8,6.7-16.3,15.2c-3.7,8.6-14,12.1-20,18.7
		c-5.4,6.1-6.2,14.4-6.3,22.3c0,0.2,0,0.5,0,0.7v0.1c0,0.8,0,1.7,0,2.5C2176.4,672.6,2176.7,673.5,2177.4,674.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-168.3,757.1c7,0.5,11.4-1.4,17.8-4c12.2-4.8,19.4,4,26.6,11.8c5.4,6,14.2,14.6,22.2,17.1
		c11.2,3.5,19.2-6.2,28-11.3c10.9-6.1,24-2.5,35.9-4c10.9-1.3,21.2-6,29.2-13.5C5,740,5.8,714.7-16.8,712.3c-11.3-1.2-20.2,4-29.9,9
		c-15.6,8.2-42.5,9.4-53.4-6.5c-5.4-7.9-10.4-12.2-20.2-13.6c-4.4-0.6-10-0.9-13.5-3.8c-4-3.2-5.8-8.8-8.4-12.9
		c-1.9-3-4.5-5.4-7.7-7.2c-3.8-4.6-9.3-4.5-15.4-2.2c-8.7,3.3-13.4,13.2-14.4,22c-1,9.1-0.1,16.3-9,21.7c-3.6,2.2-6.5,4.3-8.9,7.6
		c-5,7.1-4,16.9,2.6,22.7C-188.3,755-176.8,756.5-168.3,757.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-122.7,674.1c3.4,11.4,14.3,17.7,25.6,10.4c4.6-3,8.1-6.5,13.3-8.7c5.6-2.4,15.4,1.7,21,3.3
		c14,4.1,6.9,11.3,12.7,20.4c6.2,9.9,25.3,9.8,34.1,3.6c1.8-1.5,3-3.5,3.5-5.7c0.9-4.9,2.5-9.8,4.8-14.2C-3.5,674.7,4,666.5,4,656.7
		c0.1-8.5-3-16.8-8.5-23.3c-5.3-6-17.5-5.5-19.9-13.9c-1.9-6.4-2.6-10.7-8.9-14c-7.3-3.8-14.2-0.5-20,4.2c-5.9,4.8-9.5,5-17,3.5
		c-3.4-0.7-7.2-1.5-10.6-0.7c-9.3,2-12.8,6.7-16.3,15.2c-3.7,8.6-14,12.1-20,18.7c-5.4,6.1-6.2,14.4-6.3,22.3c0,0.2,0,0.5,0,0.7v0.1
		c0,0.8,0,1.7,0,2.5C-123.7,672.6-123.3,673.5-122.7,674.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2016.8,410.3c0.4,6.4,7.8,8.7,8.2,15.4c0,1,0.9,2.3,2.1,2.1c10.1-1.2,25.7-11,20.7-22.4
		c-2.6-5.9-5.4-14.3-11.4-17.9c-6.1-3.7-12.2-3.2-15.7,3.3c-0.6,1-0.2,2.3,0.8,2.9c0.8,0.4,1.7,0.3,2.4-0.3
		C2020.1,398.3,2016.4,403.7,2016.8,410.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2320.2,506.8c4-3.4,4.4-7.4,5.1-12.2c1.4-8.9,3.5-15.5-5.8-20.7c-6.9-3.8-9.6-12.4-16.8-15.7
		c-2.3-1.1-5.1-1.2-7.5-2.5c-1.2-0.7-2.8-5.1-3.7-6.4c-0.7-1-1.9-1.6-3.1-1.4c-5.1,0.8-6.3,1.7-6.3-4.4c0-2.7-3-5.9-4.2-8.4
		c-2-3.9-7.6-7.6-12.1-7.2c-7.3,0.7-21.4,6.3-27.6,0.8c-4.7-4.3-8.8-8.5-14.9-8.5c-1.8-4.1-4.4-6.4-9.6-5.8
		c-4.2,0.5-3.7,0.4-6.7-2.9c-1.5-1.6-3.8-4-6.2-3.6c-8.9,1.3-9.5,7.4-16.1,12c-6.9,4.7-16,6.3-22.1,12.4c-5.2,5.2-5.6,10.6-4.7,17.7
		c0.4,3.1,2.8,5.7,4.7,7.9c4.8,5.7-0.1,8.2,0.8,13.8c1,6,6.1,13.4,13,13.7c2,0.1,3.9-0.1,5.7-0.8c0.5,0.2,1.1,0.3,1.7,0.4h2.6
		c1.2-0.1,2.3-0.9,2.8-2l-0.3-0.6c2-0.3,3.3-2.2,3-4.1c-0.1-0.5-0.2-0.9-0.5-1.3c0.8-1.1,1.6-2.2,2.4-3.4c0.1-0.2,0.2-0.4,0.3-0.5
		c3.6-1,7.8-1.7,11.3-2.3v3.5c0.2,1.6,1.3,2.8,2.8,3.1c17.6,2.5,42.3-0.6,42.3,24.5c0.1,1.2,0.9,2.3,2,2.8c10.4,5.1,6.8,16.1-1.3,22
		c-0.7,0.5-15.6,9.7-16.7,6.2c-0.8-2-2.4-3.5-4.3-4.4c-4.8-2.3-10,2.7-13.3-5.3c-0.7-2.1-2.3-3.9-4.3-4.7c-3.9-2.2-8.8-2.8-13.1-2.8
		c-9.1-0.1-14.4-8.9-15.1-17c-0.2-1.5-1.3-2.8-2.8-3.1c-7.6-2.4-12.5-1.3-15.4,6.4c-2.7,7-0.3,11.7-1.5,18.7
		c-1,5.9-2.9,9.1-1.2,15.2c3.2,12.1,7.7,25.5,17.8,33.4c1.5,1.1,3.2,1.7,4.9,1.9c6.2,0.4,9.4,3.7,13.1,8.3c2.6,3.3,9.8,3,11.2,6
		c0.4,1.1,1.4,2,2.6,2.2c6.3,0.5,12.4,2.4,18.8,2.7c8.9,0.5,17.7,7,26.6,5.9c3.8-0.5,10.6-4.2,12.9-2.7c2.3,1.5,5.7,0.5,8-0.4
		c8.6-3,15.9-11.3,22.1-17.6c7.4-7.5,13.7-16,18.9-25.2c4-7.3,1.6-18.2-1.9-25.4c-0.7-1.4-1.6-2.6-2.7-3.6
		C2310,516.1,2316,510.4,2320.2,506.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2286,335.2c6.1,1.4,10.4,6.3,16.6,7.7c15.2,3.3,18.6-14.5,29.8-19.9c9.7-4.6,3.2-19.9,0.3-26.8
		c-1.2-3.1-2.5-9.4-6.4-10.6c-1.6-0.4-3.2-0.6-4.7-0.4c-4.2,0.2-9-4.7-12.5-6.6c-9.5-5.2-23.2-2.4-31.4,4c-3.7,2.8-6.4,6.6-7.1,10.4
		c-11.2,10.3-15.8,34.7-1.9,39.8C2274.1,334.6,2280.2,333.9,2286,335.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2296.2,421.6c8.7,2.1,14.6,8.5,23.2,2.5c4.4-3.1,6.4-9.5,8.9-14c2.8-5,6.9-9.5,9-14.9c4-10.6-1-18.7-9.8-24.8
		c-14.3-10-35.1-8.7-45.4,4.9c-1.8,1.4-3.5,2.9-5.1,4.5c-10.3,10.1-0.5,16.2,3.7,26.4C2284.3,414.9,2286.6,419.2,2296.2,421.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2211.6,395.9c8.7,2.1,14.6,8.5,23.2,2.5c4.4-3.1,6.4-9.5,8.9-14c2.8-5,6.9-9.5,9-14.9
		c4.1-10.6-1-18.7-9.8-24.8c-14.4-10-35.1-8.7-45.4,4.9c-1.8,1.4-3.5,2.9-5.1,4.5c-10.3,10.1-0.4,16.2,3.7,26.4
		C2199.6,389.3,2201.9,393.7,2211.6,395.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2340,183.5c-1.6-1.4-4.3-0.5-5.9,0c-3.6,1-5.8,4.6-7.8-3.3c-0.7-2.8-1.2-5.5-2.1-8.3
		c-0.6-1.4-2.2-2.1-3.6-1.5c-16.4,5.9-20.7,19.2-17.3,35.4c2.7,12.7,10.7,34.8,28.1,26.3c5.5-2.7,9.5,6.7,12.7,9.9
		c3,3.1,6.6,5.3,10.7,6.9c6.2,2.4,12.3-0.6,17-4.6c6.9-5.7,11.8-13.3,20.2-17.2c3.2-1.5,6.7-1.1,10-2.3c3.7-1.4,5.7-6.2,6.9-9.6
		c1.1-2.9,0.7-6.1,0.6-9.2c-0.5-9.6,7.9-7.7,13.5-8.4c6.6-0.8,13-6.5,14.7-12.9c1.9-6.7,0.9-14-3-20c-3.4-5.2-9.3-6.8-10.9-13.6
		c-1-4.3,0.9-8.9,0.5-13.3c-1.2-13.2-16.6-19.6-27.9-18.6c-13.8,1.3-20.9,13.4-19.2,26.3c0.5,4,3.4,7.6,5.6,10.7
		c1.5,2,2.9,4.1,4,6.2c2.9,6.2-4.8,9.1-8.5,10.4c-5.9,2.1-10.4,4-9.7,11.1c0.4,4.3,3.3,8.5,5.2,12.2c4,7.5-2.4,10.8-9.5,12
		c-6,1-14.3,0.5-18.8-3.8c-0.5-0.5-1.1-0.9-1.7-1.2c0-0.1,0.1-0.3,0.1-0.4C2344.6,195.2,2346.2,189,2340,183.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2312,154.4c5.7,1.1,12.1,6.8,18.1,5.7c5.3-1,9.1-7.4,13.1-10.8c7.4-6.4,12.8-10.6,14.4-20.8
		c1.2-6.7-3.2-12.7-8.6-16c-2.7-1.8-5.5-3.1-8.7-3.8c-4.7-0.8-12,0.4-13.4-6c-0.2-1.5-1.6-2.5-3.2-2.3c-9.5,2.1-15.2,6.3-18.3,12.3
		c-3.7,1.9-5.9,5.2-6.6,9.8c-1,6.9-6.4,14-3.3,21.4C2298.7,150.8,2304.8,153,2312,154.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2266.1,22.8c5,6.4,11,12,13.8,19.8c2.1,5.5,3.3,11.3,5.9,16.6c4.4,9,17.3,2.5,23.3-0.9
		c20.8-11.9,38.3-34.2,26.7-58.3c-5.7-12.1-10.5-22.4-11-36.2c-0.7-20.3,19.3-21.6,32.7-14.2c8.9,4.8,16,23,14.5,32.8
		c-1.5,10.2-10.4,13.3-13.1,22.1c-5.3,17.2-18.1,50,1.3,58.2c-0.7,0.3-1.5,0.6-2.3,0.9c-5.4,1.8-3,10.3,2.5,8.5
		c6.6-2.1,9.9-4.6,16.9-2.1c4.6,1.7,7.5,1.2,11.8-1c13.5-6.9-0.7-28.2,15.4-34.1c15.8-5.8,12.5-16.1,19.7-29.9
		c5.7-10.8,15.2-23.7,12.3-36.8c-1.7-7.4-5.1-11.8-9.7-17.7c-4.5-5.9-13.2-6.6-17.8-12.7c-4.6-6-3.4-12.7-5.5-19.5
		c-2.3-7.1-9.1-14-13.9-19.6c-7-7.9-17.5-7.3-26.4-3.2c-15.8,7.3-26.1-14.3-41.5-5.6c-8.9,4.9-26.3,9.5-31.8,19.2
		c-1.8,3.2-2.6,7.4-4.2,10.7c-2.6,5.2-9.3,7.2-14.2,9c-4.3,1.5-6.7,4.1-8.7,8c-6.5,12.8-0.5,29.3-2.8,42.9
		C2257.5-4.2,2255.3,9.2,2266.1,22.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2083.9,160c4.2,4.7,7,10.6,10.1,15.9c4.8,8.4,13.2,14.3,23.1,13.8c8-0.4,14.6-5,15.7-13.4
		c1.2-9.1-5-16.5-6.2-25.3c-1.4-9.7-7.6-15.3-13.8-22.3c-4.4-5-8.2-10.4-14.3-9.5c-0.3-0.1-0.6-0.1-0.9-0.1
		c-12.5-0.1-22.6,10-22.7,22.4c0,2.9,0.5,5.7,1.6,8.4C2078.1,153.8,2081.4,157,2083.9,160z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1186.2,527c-6-10.1-13.8-10.5-24.5-7.4c-10.6,3.1-21.2,1-31.5,4c-9.3,2.6-16,12.6-18.4,21.4
		c-1.6,6,9.7,13,5.9,18c-4.6,6-5.9,15.2,0.9,20.5c9.2,7,4.6,18.9,13.5,26.1c5.6,4.6,12.2,4.9,19.1,3.6c7.4-1.6,14.4-5.4,21.4-8.5
		c3.4-1.7,7-2.9,10.7-3.7c6.6-1.3,7.3,5.9,8.3,10.7c1.3,6.7,2.8,14.3,9.6,17.5c10.8,5.1,24.6,5.1,36.2,4.3
		c22.8-1.7,47.6-15.6,62.2-33.3c5.6-6.8,4-13.4-0.3-20.7c-6.7-11.8,12.3-17.9,13.4-29.4c0.9-8.5,1.5-17.3-3.7-24.5
		c-3.6-5-10.1-4.4-14.7-8.6c-3.1-3-4.4-6.5-8.4-8.3c-14.7-6.6-41.1-12.8-52.5,3c-5.4,7.2-6.9,16.6-4,25.1c1.3,3.9,4.1,7,6.8,10
		c3.7,3.9,14.9,9.8,5.3,16.1c-12.4,8.1-28,3.2-41.4,3.2c-5.2,0-10.4-0.1-15.6,0.1c-8,0.3-4.6-12.6-2.6-18.1
		C1185.5,541.2,1190.4,533.9,1186.2,527z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1175.6,544.9L1175.6,544.9c0.1-0.3,0.3-0.6,0.5-0.9c0.3-0.6,0.7-1.2,1-1.8c2.6-4.3,10.9-22-7.9-17.6
		c-5.9,1.4-11.7,3.7-17.8,4.2c-5.7,0.5-11.8-0.9-17.4,0.7c-4.4,1.2-19.4,12.3-14,18.5c3,3.5,8.4,12.3,4.8,16.9
		c-3.2,4.2-7.2,8.9-0.8,13.8c1.6,1.1,3,2.3,4.3,3.7c3,3.9,3.7,6.8,4.2,11.7c2.8,26.4,33.4,4,46.4,0.6c4.7-1.2,11.3-1.8,14.7,2.7
		c5.1,6.6,2.5,18.1,8.2,23.6c9.2,9,39.4,8,49.9,3c11.9-5.7,22.3-12.3,33.4-19.3c5.6-3.6,14.3-11,10.3-18.4
		c-2.1-3.8-3.9-6.9-4.4-11.2c-1.2-10.1,5.8-12.3,11.4-18.7c4.4-5.1,6.9-20.8,1.7-26.4c-1-1-4.4-1.6-5.5-1.9c-2-0.5-4.4-3-6.1-4
		c-5.1-3.4-6.7-9-13.2-11c-6.3-2-12.9-3.3-19.5-4c-8.4-0.9-17.4-0.1-22.1,8.2c-4.1,7.4-4.2,16.3,1.9,22.6
		c4.1,4.4,14.1,10.3,13.6,17.3c-1.5,22.4-40,15.2-53.5,15.2c-4.9,0-19.5,3.1-23.1-1.4c-4.1-4.9-4.4-11.8-3.3-18.1
		c-0.6-0.9-0.7-2-0.3-3C1173.7,548.3,1174.6,546.5,1175.6,544.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1167.7,491.1c-0.1,9.4,0.6,11.9,11.1,14.8c10.7,3,23.3,1.8,34.2,0.9c8.4-0.7,20.1-2.1,20.9-12.5
		c0.5-6.9-5.3-13-5.4-20.1c0-7.8-2.2-14.4-3.8-22c-2.2-10.1-10.2-12.4-19.8-12.4c-7,0-7.5-5.6-15-5c-7.1,0.5-15.2,2.6-21,7.1
		c-3.3,2.6-4.9,5.3-4.9,9.6c0.1,2.7,0.6,5.2,1.4,7.8c1.2,5.1-0.5,8.7-1.7,13.5C1161.5,481.4,1162.3,488,1167.7,491.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1176.6,486.1c4.4,0,3.8,6.9-0.7,6.8c-0.6,0-1.2,0-1.7-0.1c-0.2,2.6-0.2,4.7,3.1,5.5c3.3,0.9,6.5,1.5,9.9,1.8
		c8.2,0.7,16.3,0.6,24.5-0.2c3.8-0.4,8.2-0.6,11.8-2.1c5.4-2.3,1.8-8.8,0.3-12.4c-3.3-8.3-1.3-17.1-4.6-25.4
		c-1.2-3.1-0.6-10.4-4.6-11.8c-3.5-1.2-7-1.7-10.6-1.7c-3,0-6.1,0.5-8.7-1.4c-5.1-3.8-8.4-4.4-15.2-1.5c-5.7,2.5-10.7,4.6-8.9,11.7
		c0.7,3.1,1.9,6.4,1.3,9.6C1171,473,1164.3,486.1,1176.6,486.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M377.2,2044.8c-6-10.1-13.8-10.5-24.5-7.4c-10.6,3.1-21.2,1-31.5,4c-9.2,2.6-16,12.6-18.4,21.4
		c-1.6,6,9.7,13,5.9,18c-4.5,6-5.9,15.2,0.9,20.5c9.2,7,4.6,18.9,13.5,26.1c5.6,4.6,12.2,4.9,19.1,3.6c7.4-1.6,14.4-5.4,21.4-8.5
		c3.4-1.7,7-2.9,10.7-3.7c6.6-1.3,7.3,5.9,8.3,10.7c1.3,6.7,2.8,14.3,9.6,17.5c10.8,5.1,24.6,5.1,36.2,4.3
		c22.8-1.7,47.6-15.6,62.2-33.3c5.6-6.8,4-13.4-0.3-20.7c-6.7-11.8,12.3-17.9,13.4-29.4c0.9-8.5,1.5-17.3-3.7-24.5
		c-3.7-5-10.2-4.4-14.7-8.6c-3.1-3-4.4-6.5-8.5-8.3c-14.7-6.6-41.1-12.8-52.5,3c-5.4,7.2-6.9,16.6-4,25.1c1.3,3.9,4.1,7,6.8,10
		c3.7,3.9,14.9,9.8,5.3,16.1c-12.4,8-28,3.2-41.4,3.2c-5.2,0-10.4-0.1-15.6,0.1c-8,0.3-4.6-12.6-2.6-18.1
		C376.6,2059,381.4,2051.6,377.2,2044.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M366.6,2062.7L366.6,2062.7c0.1-0.3,0.3-0.6,0.5-0.9c0.3-0.6,0.7-1.2,1-1.8c2.6-4.3,10.9-22-7.9-17.6
		c-5.9,1.4-11.7,3.7-17.8,4.2c-5.7,0.5-11.8-0.9-17.4,0.7c-4.4,1.2-19.4,12.3-14,18.4c3.1,3.5,8.4,12.3,4.8,16.9
		c-3.2,4.2-7.2,8.9-0.8,13.8c1.6,1.1,3,2.3,4.3,3.7c3,3.9,3.7,6.8,4.2,11.7c2.8,26.4,33.4,4,46.4,0.6c4.7-1.2,11.3-1.8,14.7,2.7
		c5.1,6.6,2.5,18.1,8.2,23.6c9.2,9,39.3,8,49.8,3c11.9-5.7,22.3-12.3,33.4-19.3c5.6-3.6,14.3-11,10.3-18.4
		c-2.1-3.8-3.9-6.9-4.4-11.2c-1.1-10.1,5.8-12.3,11.4-18.7c4.4-5.1,6.9-20.7,1.7-26.4c-1-1-4.4-1.6-5.5-1.9c-2-0.5-4.4-2.9-6.1-4
		c-5.1-3.4-6.7-9-13.2-11c-6.3-2-12.9-3.3-19.5-4c-8.4-0.9-17.4-0.1-22.1,8.2c-4.1,7.3-4.2,16.3,1.9,22.6
		c4.1,4.4,14.1,10.3,13.6,17.2c-1.5,22.4-40,15.2-53.5,15.2c-4.9,0-19.5,3.1-23.1-1.4c-4.1-4.9-4.4-11.8-3.3-18.2
		c-0.6-0.9-0.7-2-0.3-3C364.8,2066,365.6,2064.4,366.6,2062.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M358.7,2008.9c-0.2,9.4,0.6,11.9,11.1,14.8c10.7,3,23.3,1.8,34.2,0.9c8.4-0.7,20.1-2.1,20.9-12.5
		c0.5-6.9-5.3-13-5.4-20.1c0-7.8-2.2-14.4-3.8-22c-2.2-10.1-10.2-12.4-19.8-12.4c-7,0-7.5-5.6-15-5c-7.1,0.5-15.2,2.6-21,7.1
		c-3.3,2.6-4.9,5.3-4.9,9.6c0.1,2.7,0.6,5.2,1.4,7.8c1.2,5.1-0.5,8.7-1.7,13.5C352.5,1999.1,353.4,2005.8,358.7,2008.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M367.6,2003.9c4.4,0,3.8,6.9-0.7,6.8c-0.6,0-1.2,0-1.7-0.1c-0.2,2.6-0.2,4.7,3.1,5.5c3.3,0.9,6.5,1.5,9.9,1.8
		c8.2,0.7,16.4,0.6,24.5-0.2c3.8-0.4,8.2-0.6,11.8-2.1c5.4-2.3,1.8-8.8,0.3-12.4c-3.3-8.3-1.3-17.1-4.6-25.4
		c-1.2-3.1-0.6-10.4-4.6-11.8c-3.4-1.2-7-1.7-10.6-1.7c-3,0-6.1,0.5-8.7-1.4c-5.1-3.8-8.4-4.4-15.2-1.5c-5.7,2.5-10.7,4.6-8.9,11.7
		c0.7,3.1,1.9,6.4,1.3,9.6C362,1990.8,355.3,2003.8,367.6,2003.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M992.4,419.5c4-3.4,4.4-7.4,5.1-12.2c1.4-8.9,3.5-15.5-5.8-20.7c-6.9-3.8-9.6-12.4-16.8-15.7
		c-2.3-1.1-5.1-1.2-7.5-2.5c-1.2-0.7-2.9-5.1-3.7-6.4c-0.7-1-1.9-1.5-3.2-1.4c-5.1,0.8-6.3,1.7-6.3-4.4c0-2.7-3-5.9-4.2-8.4
		c-2-3.9-7.6-7.6-12.1-7.2c-7.3,0.7-21.4,6.3-27.6,0.8c-4.7-4.3-8.8-8.5-14.9-8.5c-1.8-4.1-4.4-6.4-9.6-5.8
		c-4.2,0.5-3.7,0.4-6.7-2.9c-1.5-1.6-3.8-4-6.2-3.6c-8.9,1.3-9.5,7.4-16.1,12c-6.9,4.7-16,6.2-22.1,12.4c-5.2,5.2-5.6,10.6-4.7,17.7
		c0.4,3.1,2.8,5.7,4.7,7.9c4.8,5.7-0.1,8.2,0.8,13.8c1,6,6.1,13.4,13,13.7c2,0.1,3.9-0.2,5.7-0.8c0.5,0.2,1.1,0.3,1.7,0.3h2.5
		c1.2-0.1,2.3-0.9,2.8-2l-0.3-0.5c2-0.3,3.3-2.2,3-4.1c-0.1-0.5-0.2-0.9-0.5-1.3c0.8-1.1,1.6-2.2,2.3-3.4c0.1-0.2,0.2-0.4,0.3-0.5
		c3.6-1,7.8-1.7,11.3-2.3v3.5c0.1,1.6,1.3,2.8,2.8,3.1c17.6,2.5,42.3-0.6,42.3,24.5c0.1,1.2,0.9,2.3,2,2.8c10.4,5.1,6.8,16.1-1.3,22
		c-0.7,0.5-15.6,9.7-16.7,6.2c-0.8-2-2.4-3.5-4.3-4.4c-4.8-2.3-10,2.7-13.3-5.3c-0.7-2.1-2.3-3.9-4.3-4.7c-3.9-2.2-8.8-2.8-13.1-2.8
		c-9.1-0.1-14.4-8.9-15.1-17c-0.2-1.5-1.3-2.8-2.8-3.1c-7.6-2.4-12.5-1.3-15.4,6.4c-2.7,7-0.3,11.7-1.5,18.7
		c-1,5.9-2.9,9.1-1.2,15.2c3.2,12.1,7.7,25.5,17.8,33.3c1.5,1.1,3.2,1.7,4.9,1.8c6.2,0.4,9.4,3.7,13.1,8.3c2.6,3.4,9.8,3,11.2,6
		c0.4,1.1,1.4,2,2.6,2.2c6.3,0.5,12.4,2.4,18.8,2.7c8.9,0.5,17.7,7,26.6,5.8c3.8-0.5,10.6-4.2,12.9-2.7c2.4,1.5,5.7,0.5,8-0.4
		c8.6-3,15.9-11.2,22.1-17.6c7.4-7.5,13.7-16,18.9-25.2c4-7.3,1.6-18.2-1.9-25.4c-0.7-1.4-1.6-2.6-2.7-3.6
		C982.2,428.9,988.3,423.1,992.4,419.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1824.2,77.3c6.1,1.4,10.4,6.3,16.6,7.7c15.2,3.3,18.6-14.5,29.8-19.9c9.7-4.6,3.2-19.9,0.3-26.8
		c-1.2-3.1-2.5-9.4-6.4-10.6c-1.6-0.4-3.2-0.6-4.7-0.4c-4.2,0.2-9-4.7-12.5-6.6c-9.5-5.2-23.2-2.4-31.4,4c-3.7,2.8-6.5,6.6-7.1,10.4
		c-11.1,10.3-15.8,34.7-1.9,39.8C1812.3,76.7,1818.4,76,1824.2,77.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1167.6,224.7c-2.2-8.2-2.9-14.8-12.9-15.6c-7.4-0.6-13-7-20.6-7.2c-2.5,0-4.9,0.9-7.5,0.7
		c-1.3-0.1-4.4-3.4-5.6-4.2c-1-0.6-2.3-0.6-3.3,0c-4.2,2.7-4.8,3.9-7.1-1.5c-1-2.3-4.8-4.1-6.8-5.6c-3.2-2.7-9.5-3.8-13.3-1.7
		c-6.1,3.4-16.1,13.6-23.6,11.2c-5.7-1.9-10.9-4-16.2-1.7c-3.1-2.8-6.3-3.9-10.6-1.4c-3.5,2-3,1.8-6.9,0.1c-1.9-0.8-4.7-2-6.8-0.7
		c-7.2,4.4-5.4,10.1-9.4,16.5c-4.2,6.7-11.5,11.5-14.3,19.1c-2.5,6.5-0.8,11.3,2.7,17.2c1.5,2.6,4.6,3.9,7.1,5.1
		c6.4,3.2,3.1,7.1,5.9,11.6c3.1,4.8,10.4,9.3,16.4,6.9c1.8-0.7,3.4-1.6,4.7-2.9c0.6,0,1.1-0.1,1.6-0.3l2.2-1c1-0.5,1.7-1.6,1.7-2.8
		l-0.5-0.4c1.6-1,2-3.1,1-4.6c-0.2-0.4-0.6-0.7-0.9-1c0.3-1.2,0.5-2.5,0.8-3.8c0-0.2,0-0.4,0-0.6c2.7-2.3,6.1-4.4,8.8-6.2l1.3,3.1
		c0.7,1.3,2.2,1.9,3.6,1.6c16.2-4.6,36.5-16.6,46,5.1c0.6,1,1.6,1.7,2.8,1.7c10.9,0.6,12,11.4,7.3,19.6c-0.4,0.7-9.9,14.3-12.1,11.8
		c-1.4-1.4-3.4-2.2-5.3-2.1c-5-0.1-7.7,6.1-13.5,0.5c-1.4-1.6-3.4-2.5-5.5-2.5c-4.2-0.4-8.6,1-12.4,2.6c-7.9,3.4-15.8-2.3-19.6-9
		c-0.7-1.3-2.2-1.9-3.6-1.6c-7.5,0.8-11.3,3.6-10.9,11.5c0.3,7.1,4.2,10.3,5.7,16.7c1.4,5.5,1,8.9,4.7,13.6
		c7.3,9.2,16.4,19.1,28.2,22.2c1.7,0.4,3.4,0.3,4.9-0.3c5.5-2.1,9.6-0.4,14.4,2.3c3.5,1.9,9.6-1.2,12,1c0.8,0.8,2,1.2,3.1,0.9
		c5.7-2,11.6-2.7,17.3-4.8c7.9-3,18-0.7,25.3-5c3.1-1.9,7.5-7.6,10.1-7.2s5.1-1.8,6.8-3.4c6.3-5.8,9.5-15.7,12.4-23.6
		c3.6-9.3,5.8-19.1,6.7-29c0.6-7.8-5.6-16.4-11.3-21.3c-1.1-0.9-2.4-1.6-3.7-2.1c-6.8-2.6-3.9-9.9-1.6-14.5
		C1169.8,232.8,1168.7,229.1,1167.6,224.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1089.2-80.1c-9.2,5.1-16.9,12.7-22.3,21.7c-8.7,14.7-2.8,30.2,9.2,39.1c-3.7,14.4-0.6,32.7,2.8,46.3
		c-4,4.1-7.4,8.8-7.4,14.2c0,12.8,10.8,16.4,21,20c5.5,2,9.2,7.3,13.8,10.7c3.7,2.8,8.3,2.8,12.7,2.6c8.4-0.5,20.7-8.3,24-16.2
		c4.4-10.2-2.7-21-11.5-28.7c3.1-7,4.1-14.7,3.1-22.3c-1.3-11.1-5-20.7-13.4-26.2c-0.8-1.6-1.8-3.2-2.8-4.6c1.2-2.9,1.9-5.8,2.8-8.4
		c4.2-12,11.4-20.3,23.6-23.9c9.3-2.8,19-3.6,28.1-7c11.4-4.4,35.5-16.2,38.3-29.6c3.4-16.7-16.7-19.1-28.9-18.7
		c-6.7,0.2-13.2,2-20,1c-7.2-1.2-12.4-8.1-17.4-12.7c-6.4-5.8-15.7-7.9-24.1-6.6c-5,0.7-9.5,3.9-12,8.3c-2.7-1.3-6.4,1.7-4.4,4.8
		c0.5,0.9,1,1.8,1.5,2.7c-1.7,6.5-1.9,13.9-2,19.7c0,0.3,0,0.7,0.1,1C1099.9-87.7,1095-83.3,1089.2-80.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1072.4,58c-5.5-3.6-12.4-4.5-18.7-2.8c-5.6,1.6-8.7,6.5-15.2,5.9c-4.2-0.3-7.7-3.3-11.9-4.2
		c-12.2-2.6-22.2,9.5-24.3,19.9c-2.7,12.7,6.3,22.3,18.3,24.4c3.7,0.6,7.7-1,11.1-2.2c2.2-0.8,4.4-1.5,6.6-1.8
		c6.3-0.9,6.8,6.8,6.9,10.5c0.2,5.9,0.7,10.4,7.3,11.8c4,0.8,8.5-0.6,12.4-1.3c7.8-1.5,9,5.1,8.1,11.8c-0.7,5.6-3.5,13.1-8.6,15.8
		c-0.6,0.3-1.1,0.7-1.6,1.2c-0.1-0.1-0.2-0.1-0.4-0.2c-6.4-2.8-11.6-5.9-18.2-1.8c-1.7,1.1-1.6,3.7-1.7,5.3c-0.1,3.5,2.6,6.5-5,6.1
		c-2.7-0.2-5.2-0.5-8-0.4c-1.4,0.1-2.4,1.4-2.3,2.8c0.8,16.3,11.5,23.8,27.1,25.3c12.1,1.1,34.2,0,31.3-17.9
		c-0.9-5.7,8.7-6.6,12.3-8.6c3.6-1.9,6.7-4.4,9.1-7.6c3.9-4.9,2.8-11.2,0.5-16.5c-3.3-7.8-8.7-14.2-10-22.8
		c-0.5-3.3,0.9-6.3,0.7-9.6c-0.3-3.7-4.1-6.9-6.7-8.9c-2.3-1.7-5.3-2.3-8-3c-8.8-2.2-4.7-9.2-3.8-14.4
		C1081,68.6,1077.6,61.4,1072.4,58z"/>
            <path :fill="colors[`${id}-p-1`]" d="M978.4,165.2c5.9,2.8,10.9,9.6,18.3,8.8c7.1-0.8,10.8-5.7,13.9-11.8c2.6-4.8,9.4-9,10.1-14.7
		c0.6-5-4.2-10.2-6.1-14.7c-3.7-8.4-5.9-14.4-14.7-18.7c-5.7-2.9-12.2-0.7-16.7,3.3c-2.4,1.9-4.3,4.2-5.7,6.7
		c-2.1,4.1-3,10.9-9.2,10.4c-1.4-0.2-2.7,0.8-2.9,2.2c-0.7,9.1,1.5,15.4,5.9,19.8C972,160.4,974.4,163.3,978.4,165.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1268.3,11.2c0.5,3.9,3.9,5.2,6.8,4.6l6.9,15.8c1.3,3,4.6,4.3,7.5,3.1l0,0c13-6.3,21.4-20,25.9-33.2
		c5-14.5,3.2-27.6-8.6-37.8c-10.1-8.8-22.2-2.2-28.8,7.1c-4.3,6.2-7.2,13.3-8.8,20.7c-2.3,1.4-3.2,4.4-2,6.7l0.7,1.6
		C1267.7,3.7,1267.8,7.4,1268.3,11.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1184.3,346.7c-0.1,1.3,0.5,2.6,1.6,3.3c2.8,2.2,0.7,9.6,3.5,13.2c4,4.9,6.6,9.1,5.4,15.7
		c-0.3,1.9-0.1,3.9,0.7,5.6c5.7,12.6,18.7,20.6,30.5,27c6,3.3,9.8,2.2,16.2,2.6c7.6,0.5,11.9,4.2,19.9,3.1
		c8.9-1.2,11.2-5.9,10.6-14.5c0.1-1.7-1-3.2-2.5-3.7c-8.3-2.8-16.2-10.5-14-20c1-4.4,1.6-9.8,0.4-14.3c-0.4-2.4-1.9-4.4-3.9-5.6
		c-7.5-5.4-1.2-9.6-2.3-15.2c-0.4-2.3-1.6-4.3-3.5-5.5c-3.4-2,9.9-15.2,10.7-15.9c8.1-6.9,20.5-7.9,23.2,4.2
		c0.3,1.3,1.2,2.4,2.5,2.8c26.3,6.2,16.9,31.3,15.1,50.3c-0.1,1.7,0.9,3.2,2.5,3.7l3.7,0.9c-1.6,3.5-3.3,7.7-5.2,11.2
		c-0.2,0-0.4,0.1-0.6,0.2c-1.4,0.5-2.8,1.1-4.1,1.7c-1.5-1.5-4-1.5-5.4,0c-0.4,0.4-0.6,0.8-0.8,1.3l-0.5-0.4
		c-1.3,0.2-2.4,1.2-2.8,2.5l-0.6,2.7c-0.1,0.6-0.1,1.3,0,1.9c-1.1,1.8-1.8,3.8-2.1,5.8c-1.4,7.2,5,14.4,11.1,17
		c5.5,2.3,9.3-2.3,14.1,4.3c1.9,2.6,4.1,5.7,7.1,6.9c7.2,2.7,12.9,3.7,19.7-0.5c7.9-4.7,11.8-13.8,18.4-19.9
		c6.3-5.7,13-4.8,16.5-13.8c1-2.6-0.9-5.4-2.2-7.4c-2.7-4.1-2.9-3.6-1.3-7.8c1.9-5.2,0-8.6-3.8-11.5c1.5-6.4-1.9-11.8-5.1-17.8
		c-4.3-7.9,5.1-21.2,7.7-28.7c1.6-4.6-1-11.4-4.6-14.4c-2.3-1.9-4.9-5.7-7.7-6.4c-6.4-1.5-5.1-2.5-3.1-7.7c0.4-1.3,0.2-2.6-0.7-3.6
		c-1.2-1.2-5.4-4.1-5.7-5.4c-0.8-2.9-0.2-5.7-0.8-8.5c-1.7-8.4-10-13.3-12.3-21.5c-3-11.1-10.5-10.5-20.1-11.3
		c-5.1-0.4-9.5-0.9-14,2.4c-4.7,3.5-12.3,8.3-16.6,1.3c-0.8-1.4-1.9-2.7-3.1-3.8c-6.6-5.3-17.4-10.6-26.1-8.3
		c-10.9,3.1-21.4,7.6-31.1,13.4c-8.2,4.7-18.6,10.4-23.8,18.6c-1.4,2.2-3.3,5.5-2.4,8.3s-4.6,9-6,12.8c-3.5,9.1,1.3,19.8-0.5,29.3
		C1185,333.2,1185.4,340,1184.3,346.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1278.3,490.9c8.2,1.2,16.1,4.6,23.8,7.3c12.1,4.3,25.4,3.2,35.1-5.8c7.7-7.2,10.4-17.6,4.3-27
		c-6.6-10.2-19.2-12.4-27.9-20c-9.8-8.6-20.9-8.9-32.9-10.7c-8.7-1.3-17-3.5-22.4,2.7c-0.3,0.2-0.7,0.4-1,0.7
		c-16.1,14.2-13.4,38.9,5.2,49C1267,489.7,1273.2,490.1,1278.3,490.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1011.8,457.2c5.5,8.2,16.3,17.9,27.2,16.5c4.7-0.7,9.2-2.5,13.1-5.3c3.8-2.6,7.9,4.4,11,5.4
		c9.6,3.2,12.5-5.5,13.9-13.3c0.9-4.8-0.6-9.3-2.7-13.5c-2.9-6-8.3-12.6,1.3-14.9c14.2-3.6,24.2,5.8,32.3,16.3
		c4,5.1,5.1,9.9,4.6,16.3c-0.3,4-1,9.6,1,13.3c5.3,9.6,15.2,11.6,25.4,8.5c13.9-4.3,15.5-25.4,18.6-36.8c1.5-5.4,2.2-11.1,2-16.8
		c5.7-5.9,6-13.2,1.3-21.1c-4.1-6.4-6.2-13.8-6-21.5c0-6.3-1.3-13.5-6.2-17.9c-7.9-6.9-19-10-29.3-11.1c-5.6-0.6-10.7,0.8-15.5,2.8
		c-2.5-1.2-4.7-2.7-7.4-2.9c-4.2-0.5-8.4-0.1-12.5,1c-5.6,1.9-10.6,5-15.6,8c-18.3-5.3-45.3-18.9-55.5-2.3c-4.7,7.6-2.7,19.6,1,27.3
		c-1,1.2-2,2.5-2.9,3.8c-4.2,6.1-4.1,13.1-1.9,19.7c-0.4,3-0.6,6-0.9,8.8C1007,437,1006.2,448.7,1011.8,457.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1369.4,2110.4c6.7,4.1,17.8,7.4,25.1,2.8c3.2-2.1,5.8-4.8,7.6-8.2c1.9-3.2,7.2,0.6,9.8,0.3
		c7.9-1,7.1-8.2,5.6-14.2c-1-3.8-3.6-6.5-6.5-8.9c-4.1-3.4-10.2-6.2-4.2-11.2c9.1-7.3,19.5-4,28.8,0.9c4.3,2.1,7.4,5.7,8.8,10.3
		c1.1,3,2.5,7.2,5.2,9.2c7.1,5.1,14.9,3.3,21.2-2.4c8.7-7.7,2.7-23.5,1.1-32.8c-0.8-4.4-2.2-8.8-4.3-12.8c2.2-6.2-0.1-11.5-6.1-15.6
		c-5.1-3.3-9.1-7.9-11.6-13.4c-2.2-4.6-5.4-9.4-10.5-10.9c-8-2.3-17-0.8-24.8,1.9c-4,1.5-7.5,4.1-10.4,7.2c-2.2,0-4.4-0.4-6.3,0.4
		c-3.2,1.1-6.1,2.8-8.6,4.9c-3.5,3.3-5.9,7.2-8.6,11.1c-15,2.3-39.1,1.6-40.8,16.9c-0.8,7.1,4.6,14.9,9.9,19.4
		c-0.3,1.2-0.6,2.4-0.8,3.7c-0.9,5.8,1.4,10.8,5.2,14.8c0.7,2.3,1.6,4.6,2.3,6.6C1359.1,2097.5,1362.6,2106.3,1369.4,2110.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2135,269.8c4.8,7.2,14.2,15.6,23.7,14.4c4.2-0.6,8-2.2,11.4-4.6c3.3-2.3,6.9,3.9,9.6,4.7
		c8.3,2.8,10.9-4.8,12.2-11.6c0.8-4.2-0.6-8-2.3-11.9c-2.5-5.2-7.2-11,1.1-13.1c12.5-3.1,21.2,5.1,28.3,14.2
		c3.5,4.5,4.4,8.6,4.1,14.2c-0.3,3.5-0.9,8.4,0.9,11.6c4.6,8.3,13.3,10.1,22.2,7.4c12.3-3.7,13.5-22.2,16.2-32.2
		c1.3-4.7,1.9-9.7,1.8-14.6c5-5.2,5.2-11.5,1.2-18.4c-3.6-5.6-5.4-12.2-5.2-18.8c0-5.5-1.2-11.9-5.4-15.6
		c-6.9-6.1-16.5-8.7-25.6-9.7c-4.8-0.5-9.3,0.7-13.6,2.5c-2.2-1-4.2-2.4-6.5-2.6c-3.7-0.4-7.3-0.1-10.9,0.9c-4.9,1.6-9.2,4.4-13.6,7
		c-16-4.6-39.7-16.5-48.6-2.1c-4.2,6.7-2.3,17.1,0.9,23.8c-0.9,1.1-1.7,2.2-2.5,3.3c-3.7,5.3-3.6,11.4-1.7,17.2
		c-0.3,2.7-0.5,5.2-0.8,7.7C2130.7,252.2,2130,262.4,2135,269.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1145.7,184.1c2.3-0.1,4.4-0.6,6.5-1.6c1.2-0.5,2.2-1.3,3.1-2.2c14,4.8,23.4,1.2,33.6-9.7
		c8.2-8.7,13.3-23.9,5.6-34.3c-4-5.4-5.2-12.7-9.1-17.8c-3.9-5.1-7.1-4.6-11.6-7.2c-1.8-1.1-3.2-6.5-4.5-9.7
		c-1.8-4.3-5.8-5.7-10-6.6c-11.3-2.4-21.1,4.4-26.3,13.8c-1.5,0.3-3,1.1-4,2.2c-15.4,17-20.1,54.3,0.9,69.3
		C1134.6,183.4,1140.1,184,1145.7,184.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1230.3,177c2.8,9.3,13.4,12.5,22.2,12.6c8.2,0.1,15.6-4.8,18.6-12.5c4.7-12.8-2.5-21.6-8-32.6
		c-4.2-8.3-9.2-14-18.3-16.8c-6.6-2.1-15-1.8-20.7,2.4c-11.8,5.6-17.6,26.2-10.4,36.4C1218.6,173.4,1227.4,167.4,1230.3,177z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2082.1,77.9c8.3-4.4,9.4-15.1,8-23.3c-1.4-7.8-7.4-13.9-15.2-15.5c-13-2.3-20.1,6.3-29.7,13.5
		c-7.1,5.4-11.7,11.4-12.8,20.5c-0.8,6.7,1,14.6,6,19.3c7.5,10.2,28.2,12,36.6,3.3C2080.8,89.8,2073.5,82.5,2082.1,77.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1230,232.3c12.4-11.4,1.2-28.1-5.9-38.6c-4.3-6.2-9.6-12.8-17.9-12.2c-2.6,0.1-4.9,1-7,2.6
		c-3.7-0.7-7.6,1.2-7.7,5.9c-0.2,12.6-3.9,25.1,0.9,37.2C1198.1,241.9,1220,241.6,1230,232.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1891.6,108.9c-5.1-4.5-12.9-4.5-18-0.1c-0.8,0.2-1.5,0.4-2.2,0.8c-25.1,14.9-16.6,55-12.8,77.9
		c1.8,10.8,8.5,24.8,20.6,26.2c8,0.9,20.3,5.7,24.8-3.8c4.4-9,4.3-20.4,4.6-30.1c0.3-9.8,2-23.5-1.7-32.9c-1.2-2.9-3.5-5.3-5.6-7.5
		c-4.8-4.9-3.6-10.1-3.2-16.4C1898.5,118.4,1895.3,111.8,1891.6,108.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1931.2,254.2c-0.1-5.2-2.2-10.3-5.7-14c-3.6-3.9-8.9-5.7-13.5-8c-5-2.5-11.8-6.7-17.5-7.1
		c-11-0.8-20.7,4.3-30.4,8.9c-4.6,2.2-9.6,4.8-14.7,4.6c-3.3-0.1-6.3-8.5-8-11c-5.6-8.1-16.8-4.1-21.8,2.5c-2.6,3.4-4.3,7.3-5,11.5
		c-2.4-0.4-4.8,0.4-6.4,2.2c-3.5,3.7-2.7,7-4.2,11.6c-1.8,5.5-4.8,10.2-6.9,15.6c-2.4,6.5-12.7,5.5-18.2,6.3s-9.3,4.4-11.5,9.2
		c-3.9,8.4,3.3,17.6,8.3,23.5c3.2,3.7,7.1,7,8.8,11.7c1.9,5.4,4.5,10.7,7.8,15.5c5.4,7.8,17.4,13,26.7,12.5
		c5.9-0.3,11.2-2.3,16.9-3.8c7.4-2,10.4,3.2,15.9,6c3.9,2,7.7,1.9,11.9,1.7c6.9-0.3,15-1.8,22-2.8c5.2-0.7,11.2-5.2,16.4-3.8
		c6.2,1.8,11.7,4.4,18.4,4.2c27-1.1,28.8-40.5,28-60.2c-0.4-10.4-6.5-14.7-14.5-19.6C1929.5,268.6,1931.4,258.5,1931.2,254.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2047.1,572.5c8-7.8,18.3-5.1,28.4-6.7c16.3-2.4,21.7-16.4,14.3-30.2c-2.3-4.3-9-7.2-12.8-9.9
		c-5-3.7-10.1-7.5-15.4-10.6c-13.7-7.9-27.5,17.1-31.7,34.7c-0.8,1.1-1.2,2.4-1.2,3.8v2.5c-0.1,0.8-0.2,1.7-0.2,2.4
		c-4.3,3-10.4,1.4-14.1,6.4c-8.3,11,6.9,18.5,15.7,19.2C2037.6,584.6,2042.6,576.9,2047.1,572.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1716.4,41.7c4.4,4.2,10.9,4.7,16.7,4.7c5.2,0,11.5,2.9,16.5,2.3c4-0.5,6.1-0.8,8.7,2.8
		c15.4,21.4,41-15.4,20.9-31c-4.6-3.7-10.4-5.1-15.9-6.7c-6.4-1.8-11-5.1-16.2-9.1c-8.2-6.4-21.1-14-29.5-3.4
		c-1.5,1.9-2.5,4.1-3,6.4c-1.6,2.2-1.9,5-0.8,7.4C1710.9,24.4,1708.7,34.6,1716.4,41.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M812.4,325.7c-2.8-7.5-0.5-17.2-2.7-25.3c-1.8-6.4-6.6-12.8-13.2-14.9c-13.8-4.5-28.8,0.8-35.4,13.6
		c-1.6,3.1-11.8,2.7-15.3,3.8c-3.7,1.4-6.8,4-9,7.3c-4.4,7.8-1.2,14-0.7,22.2c0.1,1.9,1,3.7,2.5,4.9c1,6.9,4,13.3,10.1,17
		c10,6.1,17.7,9.7,16.8,23.3c-0.6,8.7,1.2,14.9,6.8,21.5c8.6,10,26.4,16.6,39,11c15.8-7.2,14.6-23.4,8.3-36.8
		c-4.4-9.5-3.5-15-1.2-24.8C820.6,340,815.1,333.2,812.4,325.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M741.5,468.9c8.4,13.4,29,3.7,40.5,12.2c11.9,8.8,8.6,28.9,24.3,33.8c15.1,4.7,26.3-11.3,23.4-25.3
		c-1.4-6.9-5.4-12.7-8-19.2c-3.2-7.8,0.9-15.2,3.8-22.4c5.1-13.3-5.4-18.9-16.8-21.5c-8.4-1.9-15.3-7.5-23.5-10
		c-7.9-2.4-14.7-1.8-22.6,0c-8.7,2-9.5,13.4-7.4,22.2C746.5,447.7,735.3,458.9,741.5,468.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M970.2,340.9c5.5,3.9,8.3,9,15.2,10.7c5.8,1.3,11.9-0.1,16.6-3.7c10.5-7.9,16.6-20.6,13.2-33.6
		c-2.1-7.7-6.9-14.1-9-21.9c-1.9-7.2-2.4-14.5-4.1-21.9c-2.4-10.5-11.7-19.5-23-18.5c-5.5,0.5-10.8,2.4-15.3,5.5
		c-25.6,8.4-35.4,36.4-23.1,60.7C946.6,330.4,960.1,333.9,970.2,340.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1549.7,30.4c6.5-5.3,12-9.6,15.7-17.6c4.4-9.3,4.7-20.3,3.4-30.4c-0.5-3.4-2.9-7.2-6.9-6.8
		c-5.7,0.8-11.3,3.2-15.7,6.9c-1.9-0.1-3.8,0.6-5,2c-10.5,10.4-43.1,39.4-26,55.3C1527.2,51.1,1540.5,38,1549.7,30.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2054.1,2251.3c4.3-3.7,4.8-8,5.6-13.3c1.5-9.7,3.9-16.9-6.3-22.4c-7.5-4.1-10.4-13.4-18.3-17.1
		c-2.6-1.2-5.6-1.3-8.2-2.8c-1.3-0.7-3.1-5.6-4.1-7c-0.8-1.1-2.1-1.7-3.5-1.5c-5.6,0.9-6.9,1.9-6.9-4.8c0-3-3.3-6.4-4.6-9.2
		c-2.2-4.3-8.3-8.3-13.2-7.9c-8,0.7-23.2,6.9-30.1,0.8c-5.1-4.6-9.6-9.2-16.3-9.3c-2-4.4-4.8-7-10.5-6.4c-4.6,0.5-4,0.5-7.4-3.2
		c-1.7-1.8-4.1-4.4-6.8-4c-9.7,1.4-10.4,8-17.5,13.1c-7.5,5.1-17.4,6.9-24,13.4c-5.7,5.7-6.1,11.5-5.1,19.3c0.4,3.4,3.1,6.2,5.1,8.7
		c5.2,6.3-0.1,8.9,0.9,14.9c1,6.5,6.6,14.6,14.1,14.9c2.2,0.1,4.3-0.2,6.3-0.8c0.6,0.2,1.2,0.4,1.9,0.4h2.8c1.3-0.1,2.5-0.9,3.1-2.2
		l-0.3-0.6c2.1-0.3,3.6-2.4,3.3-4.4c-0.1-0.5-0.3-1-0.5-1.5c0.9-1.2,1.7-2.4,2.6-3.7c0.1-0.2,0.2-0.4,0.3-0.6
		c3.9-1.2,8.5-1.8,12.3-2.5v3.8c0.1,1.7,1.4,3.1,3.1,3.4c19.2,2.7,46.1-0.7,46.1,26.7c0.1,1.3,0.9,2.5,2.2,3.1
		c11.3,5.6,7.4,17.5-1.4,23.9c-0.8,0.6-17,10.5-18.2,6.7c-0.9-2.1-2.5-3.8-4.6-4.6c-5.2-2.5-10.9,2.9-14.4-5.7
		c-0.8-2.3-2.4-4.2-4.6-5.1c-4.2-2.3-9.6-3-14.2-3.1c-9.9-0.1-15.7-9.7-16.4-18.5c-0.2-1.7-1.4-3-3.1-3.4c-8.3-2.7-13.5-1.5-16.8,7
		c-3,7.7-0.3,12.8-1.7,20.3c-1.2,6.4-3.1,9.9-1.3,16.6c3.5,13.2,8.4,27.8,19.4,36.4c1.6,1.2,3.5,1.9,5.3,2c6.7,0.4,10.3,4,14.2,9
		c2.8,3.6,10.6,3.3,12.2,6.5c0.5,1.2,1.6,2.1,2.9,2.3c6.9,0.6,13.4,2.6,20.5,3c9.8,0.5,19.3,7.6,29,6.4c4.2-0.5,11.5-4.4,14-3
		c2.5,1.6,6.2,0.5,8.8-0.4c9.4-3.3,17.3-12.2,23.9-19.2c8-8.2,14.9-17.4,20.6-27.4c4.3-8,1.7-19.9-2-27.6c-0.8-1.5-1.8-2.8-3-3.9
		C2043,2261.5,2049.5,2255.3,2054.1,2251.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2223.2,2294.3c4.2-6.9-5.9-12.1-10.9-13.3c-6.1-1.6-12.5-2-18.7-1.2c-8.4,0.9-18.5,4.1-25.5-2.8
		c-7.7-7.5-13.3-10.8-24.1-5.1c-7.6,3.9-17.7,2.7-23.9,9.4c-1.7,1.8-1.9,4.4-2.6,5.2c-4.9,5.3-10.2,7.9-15.7,12.4
		c-14.1,11.3-10.2,34.5,3.8,43.9c5.3,3.6,10.9,6.6,14.8,11.9c4.8,6.3-5.8,14.1-9,18.2c-6.4,8.1-7.4,20.4-5.7,30.3
		c1.2,7.1,11.9,13.1,16.6,17.7c8,7.9,16.1,15.7,28,15.5c24.9-0.4,34.3-22.2,24.2-43.6c-7.2-15.3-14.3-33.4-14.3-50.7
		c0-5,0.2-10.2,0.7-15.2c0.7-6.3,2.2-14.6,9-6.4c8.5,10.2,1.4,28.9,12.5,37c6.5,4.7,13.2,0.9,18.7,9.7c2.5,4.2,6.1,7.4,10.6,9.2
		c7.6,2.9,20.3-0.7,27.3-4.1c4.1-2,6.3-6.1,7.5-10.5c7.5-8.2,21.5-28.6,6.3-35.9c-11.7-5.7-33.8,0.7-42.4-10.3
		C2204.6,2308.5,2220.1,2299.5,2223.2,2294.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2191.3,2475.3c3.8-0.4,7.5-1.3,11.2-2.5c6.8-2.2,12.4-1.5,19.2-1.5c5.2,0,12.8-1.2,14.9-6.9
		c5.7-15.4,27.9-18.8,31.5-36c2.2-9.9,3.9-32.7-5.1-40.1c-9-7.5-25.5-0.2-33.5,5.7c-6.2,4.6-6.6,11.6-8.2,18.7
		c-3.5,15-16.4,14.2-28.7,14.9c-10.3,0.6-18.8,4.7-20.7,13.9C2155.7,2445.8,2179.9,2476.4,2191.3,2475.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M669.2,2291.9c-5.4-5.8-13.1-5.1-20.2-6.8c-7.3-2-14.7-3-22.3-3.2c-6.4-0.1-12.5-5-19-2.2
		c-12.9,5.5-24.3,15.5-28.5,29.1c-2.3,7.7-0.5,15.3,0.4,23c0.8,5.1,0.6,10.5-0.6,15.6c-0.9,3.8,0,5.7,2.1,8.8
		c1.6,2.3,3.3,4.4,4.7,6.6c1.5,2.4,1.9,6.2,2.7,9c1,3.6,4,5.4,7,7.2c6.4,3.9,7.1,8.4,9.3,15c1.4,4.3,4.7,7.5,8.1,10.2
		c6.4,5.2,16.4,7.1,22.4,0.4c5.5-6.3,4.3-20.4,0.6-27.2c-2.5-4.6-7.9-7.5-11.5-11.2c-6.4-6.3-12.6-13.3-14.9-22.3
		c-0.8-3,0.4-6.6,0.4-9.7c0-3.1-3.8-5.4-0.9-8.8c4.4-5.4,15.3-2.8,20.8-2c4.7,0.9,9.7-0.1,13.8-2.6c3-1.8,4.6-3.3,7.8-0.4
		c2.3,2.2,4.1,5.4,5.7,8c2.1,3.2,4.4,7.7,3.8,11.8c-0.5,2.7-3.1,5.2-4.2,7.8c-2.7,6.4-2.9,12-2.3,18.6c-1.4,1.6-3.8,2.8-5.1,4.3
		c-1.4,1.5-1.3,5-1.3,7c-0.1,3.7,0.5,7.2,0.4,10.9c-0.1,2.4,0,5.3,1.2,7.5c5.6,10.7,23.6,11.5,31.3,3.2c4.3-4.6,6.2-13.4,6.7-19.4
		c0.1-2.9-0.5-5.7-1.9-8.2c-1.4-3.1-4.8-4-6.5-6.8c-5.1-8.8-0.7-18.8,0.8-28c1.5-9.5-2.9-19-2.4-28.6
		C677.7,2302.8,673,2296,669.2,2291.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1466.3,2158.1c4.2-6.9-5.9-12.1-10.9-13.3c-6.1-1.6-12.5-2-18.7-1.2c-8.4,0.9-18.5,4.1-25.5-2.8
		c-7.7-7.5-13.3-10.7-24.1-5.1c-7.6,3.9-17.7,2.7-23.9,9.5c-1.7,1.8-1.9,4.4-2.6,5.2c-4.9,5.3-10.2,7.9-15.7,12.4
		c-14.1,11.3-10.2,34.5,3.8,43.9c5.3,3.6,10.9,6.6,14.8,11.9c4.8,6.3-5.8,14.1-9,18.2c-6.4,8.1-7.4,20.4-5.7,30.3
		c1.2,7.1,11.9,13.1,16.6,17.7c8,7.9,16.1,15.7,28,15.5c24.9-0.4,34.3-22.2,24.2-43.6c-7.2-15.3-14.3-33.3-14.3-50.7
		c0-5,0.2-10.2,0.7-15.2c0.7-6.2,2.2-14.6,9-6.4c8.5,10.2,1.4,28.9,12.5,37c6.5,4.7,13.2,0.9,18.7,9.8c2.5,4.2,6.1,7.3,10.6,9.2
		c7.6,2.9,20.3-0.7,27.3-4.1c4.1-2,6.3-6.1,7.5-10.5c7.5-8.2,21.5-28.5,6.3-35.9c-11.7-5.7-33.8,0.7-42.4-10.2
		C1447.7,2172.4,1463.1,2163.3,1466.3,2158.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1434.4,2339.2c3.8-0.4,7.5-1.3,11.2-2.5c6.8-2.2,12.4-1.5,19.2-1.5c5.2,0,12.8-1.2,14.9-6.9
		c5.7-15.4,27.9-18.8,31.5-36c2.2-9.9,3.9-32.7-5.1-40.1c-9-7.5-25.5-0.2-33.5,5.7c-6.2,4.6-6.6,11.6-8.2,18.7
		c-3.5,14.9-16.4,14.2-28.7,14.9c-10.3,0.6-18.8,4.7-20.7,13.9C1398.7,2309.6,1422.9,2340.2,1434.4,2339.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1701,2183.3c-6-6.6-16.1-11.4-23.9-15.6c-8.6-4.6-15.9-6.9-25.7-6.6c-8.1,0.2-16.1,1.8-23.7,4.5
		c-8.6,3.3-15.5-2.6-23.9-2.7c-13.9-0.2-18.8,14.8-20,26.4c-1.3,12.2-11.2,8.1-16.5,15.3c-3.4,4.6-5.5,7.6-11.3,9.8
		c-2.8,1-6.2,2.1-8.4,4.2c-12.8,12.9,9.8,36,24.1,30.9c7.5,6.8,18.2,10.7,23.9-1.3c0.5-1.2,3.5-6,5.1-5.5c1.4,0.5,3,0.2,4.2-0.7
		c1.3-1.4,2.4-3.1,3.1-4.9c2.1,0.7,4.5-0.3,4.4-3.1c-0.8-14.8,21.3-10.6,30.9-10.4c15.2,0.3,36.9,14,40.2,29.4
		c0.5,1.9,2.4,3,4.2,2.5c0.3-0.1,0.5-0.2,0.8-0.3c9.1-5.7,20-5.1,29.8-9.2c1.1-0.6,1.8-1.7,1.8-3c-0.1-7.3-1.3-14.5-3.5-21.6
		c-1.2-3.9-3.9-12.6-8.1-13.8c-8.5-2.6-2.1-2.9-2.2-8C1706.3,2193.2,1705.5,2188.3,1701,2183.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1688.2,2291.2c-4.4-4.6-10.6-5.7-15.5-9.8c-4.6-3.8-7-6.6-13.2-7.4c-12.1-1.5-15,9.4-21.4,16.5
		c-1.5-1-3.6-0.5-4.4,1c-0.9,1.2-1.7,2.5-2.2,3.9c-3,4.4-6.7,7.5-12.9,5c-5.8-2.3-9.8-2.5-10.8-10c-0.4-1.9-2.3-3-4.2-2.6
		c-6.3,2-11.4,6.6-13.9,12.8c-5.7,4-13.8,16.6-6,21c3.1,1.7,5.8,6,9.9,9.4c5.3,4.4,13.1,6.3,19.7,7.5c8.8,1.7,19.4,6.8,24.8-3.3
		c1.6-2.9,1.8-17.5,5.9-16.5c7.4,1.8,14,6.3,21.6,8.1c8.7,2.1,25.7,1.4,29.2-9.4c1.2-3.8-3.5-7.9-4.4-11
		C1688.4,2301.1,1693,2296.2,1688.2,2291.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1595.2,2305.3c-0.6,2.2-0.9,4.4-1,6.5c-0.2,3.2-3.9,4-5.7,2.4c0,1.1,0.6,2.1,1.5,2.7c3.8,2.5,5.4,7.4,9.8,9.8
		c7,3.8,20.5,8.6,28.7,7.9c6.9-0.6,4.4-18,10.1-22c3.9-2.8,9.4-0.1,13.1,1.7c6.6,3.2,14,6.5,21.5,6.6c2.9,0,17.4-1.2,13.3-6.8
		c-5.4-7.5-0.5-18.4-10-22.7c-8.6-3.9-19-17.4-26.8-5.3c-3.9,5.9-7.1,11.7-13.6,15l0,0c-4.3,5.5-10.2,9.5-17.7,6.5
		c-7.2-2.9-13.4-4.7-15.9-11.7c-2.5,1.4-4.5,3.6-5.7,6.1C1596.8,2303.4,1596.3,2304.6,1595.2,2305.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M392.8,2342.9c-5.5,1.2-8.2,4.7-11.5,8.9c-6.1,7.7-11.7,13.1-5.4,22.9c4.6,7.3,2.6,17,7.7,24.1
		c1.7,2.3,4.3,3.9,5.8,6.4c0.8,1.3,0,6.4,0.1,8.1c0.1,1.3,1,2.5,2.2,3.1c5.3,2,6.9,1.8,3.7,7.6c-1.5,2.6-0.3,7.3-0.5,10.4
		c-0.2,4.7,3.2,11.4,7.7,13.4c7.4,3.3,23.9,5.3,26.9,14c2.3,6.6,4,12.9,9.9,16.2c-0.4,4.8,0.8,8.6,6,10.8c4.3,1.8,3.8,1.6,4.9,6.4
		c0.6,2.4,1.5,5.7,4.1,6.8c9.2,3.6,13.2-2.1,21.9-2.9c9.2-0.8,18.7,2.6,27.8-0.1c7.8-2.3,11.1-7.2,14-14.5c1.3-3.2,0.4-7-0.3-10.2
		c-1.6-8.1,4.4-7.8,6.6-13.6c2.3-6.2,1.3-16.1-5.1-20.2c-1.8-1.1-3.9-1.9-5.9-2.3c-0.4-0.5-0.9-0.9-1.5-1.3l-2.4-1.4
		c-1.2-0.6-2.7-0.4-3.8,0.4c0,0.2,0,0.5,0,0.7c-2.1-0.7-4.3,0.3-5,2.4c-0.2,0.5-0.3,1-0.2,1.5c-1.3,0.6-2.7,1.3-4.1,2
		c-0.2,0.1-0.4,0.2-0.6,0.4c-4-0.9-8.3-2.6-12.1-3.8l1.9-3.4c0.7-1.6,0.3-3.4-1.1-4.4c-15.6-12-41-22.2-27.5-46.5
		c0.6-1.2,0.4-2.7-0.4-3.8c-7.1-10.5,2.1-19.1,13-20.5c1-0.1,20.2-0.9,19.4,3c-0.3,2.3,0.4,4.6,1.8,6.4c3.5,4.7,11.1,2.9,9.9,12.3
		c-0.5,2.4,0.1,4.8,1.6,6.8c2.6,4.2,6.9,7.3,11.1,9.7c8.7,4.9,9.1,16.3,5.3,24.4c-0.7,1.6-0.2,3.4,1.1,4.4c6,6.4,11.3,7.9,18.3,2.1
		c6.4-5.3,6.5-11.1,11.5-17.1c4.2-5.1,7.6-7.1,9.3-14c3.5-13.3,6.2-28.6,0.8-41.6c-0.8-1.8-2.1-3.3-3.8-4.4c-5.7-3.7-7.1-8.5-8-14.9
		c-0.7-4.6-7.8-8-7.5-11.8c0.2-1.3-0.3-2.6-1.4-3.5c-5.8-3.9-10.6-8.9-16.6-12.7c-8.3-5.2-13.3-16.2-22.4-19.9
		c-3.9-1.6-12.4-1.7-13.8-4.4c-1.5-2.7-5.2-3.5-7.9-4c-9.9-1.7-21.3,2.3-30.6,5.1c-11.2,3.3-21.8,7.9-31.6,14
		c-7.7,4.9-11.3,16.7-11.8,25.4c-0.1,1.7,0.2,3.3,0.7,4.8C407.6,2339.5,398.7,2341.7,392.8,2342.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M255.2,2284.2c-7.9,2.2-14-3.6-21-6.1c-1.9-0.7-4.3-0.3-6.2-0.3c-7.8,0.3-12.4-4.6-13.8-12.2
		c-0.9-4.6-1.2-12.4-5.6-15.3c-1.3-1-2.9-1.4-4.4-1.3c-4.6,0.7-5-3.7-6.5-7.2c-5-12.2-21.9-2.2-30.2,0.1c-4.2,1.1-7.6,3.7-9.8,7.3
		c-1.4-0.9-3.1-0.9-4.4,0.1c-15.5,10.8-10,34.9,6,41.4c-1.7,7.2-2.3,16.2,5.5,19.8c3.9,1.7,15.3,6.1,16.4,9.9
		c1.4,4.6-1.9,10.6-0.6,15.7c0.9,3.6,3,6.8,5.8,9.2c5.6,4.3,8,10.8,11.5,16.7c6.8,11.8,16.4,5.2,27.6,7.4c10.6,2,22.6,2.9,30.6-5.7
		c7.5-8.2,4.2-23.6,17.3-27.1c7.6-2,15.4,8.4,22.8,9.6c7.7,1.2,15.1-3.8,17.1-11.4c1.6-5.7,0.3-16.6,9.6-15.3c3.6,0.2,7-0.9,9.9-3
		c6.2-5.3,12.6-9.2,21-10.1c4-0.4,8-0.6,10.8-4c4-4.6,4.9-9.6,5.9-15.4c1.7-10.4,6-16.7,11.1-25.6c5-9.1,1.3-21.4-4.3-29.2
		c-7.3-10.1-13.4-21-18.1-32.5c-3.3-8-13.6-5.5-19.2-2.3c-11,6.4-16.3-7.6-22-13.5c-4.6-4.9-12.1-6.5-16.4-11.8
		c-6.1-7.4-13.4-11.2-21.4-4.9c-5.9,4.6-12.1,6.4-18.7,9.2c-2.3,1-4.8,3.6-4.8,6.1c0,11.8,11.5,23.1,21,28.6
		c8.2,4.7,16.9,8.9,10.7,19.8c-3.9,6.8-11.1,10.4-17.1,14.7c-3.1,2.2-1.9,8.5-1.4,11.5c0.7,4.3,2.6,7.9,2.7,12.4
		C272.8,2278.3,261.8,2282.4,255.2,2284.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M241.7,2128c-2.7-1.2-5.6,0-8.1,1.1c-5.7,2.5-13.7,1.7-20,2.7c-17.6,3-22.3,27.7-41.3,26.9
		c-10.8-0.5-19.7,4.2-21,15.8s-20.5,7.8-13.4,23.3c4.4,9.6,18,13.3,27.1,15.7c10.9,3,16.5,7.8,29,4.1c6.5-2,11.9-4.4,16.4-9.5
		c2.9-3.3,4-6.7,8.1-9.4c4.1-2.6,8.4-4,12.5-6.5c1.1-0.8,1.7-2.1,1.5-3.5c-0.2-2.6-0.7-5.1-1.3-7.6c-1.4-6.4,5.5-5.8,8.7-7.1
		c4.6-1.8,8.4-5.7,11.4-9.5c3.7-4.5,4.9-10.6,3.5-16.2C255.6,2139.7,249.9,2131.5,241.7,2128z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-34,2322.8c5,6.4,11,12,13.8,19.8c2.1,5.5,3.3,11.3,5.9,16.6c4.4,9,17.3,2.5,23.3-0.9
		c20.8-11.9,38.3-34.2,26.7-58.3c-5.8-12.1-10.5-22.4-11-36.2c-0.7-20.3,19.3-21.6,32.7-14.2c8.9,4.8,16,23,14.5,32.8
		c-1.5,10.2-10.4,13.3-13.1,22.1c-5.3,17.2-18.1,50,1.3,58.2c-0.7,0.3-1.5,0.6-2.3,0.9c-5.4,1.8-3,10.3,2.5,8.5
		c6.6-2.1,9.9-4.6,16.9-2.1c4.6,1.7,7.5,1.2,11.8-1c13.5-6.9-0.7-28.2,15.4-34.1c15.8-5.8,12.5-16.1,19.7-29.9
		c5.7-10.8,15.2-23.7,12.3-36.9c-1.7-7.4-5.1-11.8-9.7-17.7c-4.5-5.9-13.2-6.6-17.8-12.7c-4.6-6-3.4-12.6-5.5-19.4
		c-2.3-7.1-9.1-14-13.9-19.6c-7-7.9-17.5-7.3-26.4-3.2c-15.8,7.3-26.1-14.3-41.5-5.6c-8.9,4.9-26.3,9.5-31.8,19.2
		c-1.8,3.2-2.6,7.4-4.2,10.7c-2.6,5.2-9.3,7.2-14.2,9c-4.3,1.5-6.7,4.1-8.7,8c-6.5,12.8-0.5,29.3-2.8,42.9
		C-42.5,2295.8-44.7,2309.2-34,2322.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M655.8,2141.5c-2.3-8.7-3.1-15.6-13.6-16.5c-7.8-0.7-13.8-7.4-21.8-7.6c-2.6,0-5.1,1-7.9,0.7
		c-1.4-0.1-4.6-3.6-5.9-4.4c-1.1-0.6-2.4-0.6-3.5,0c-4.4,2.8-5.1,4.1-7.5-1.5c-1.2-2.5-5.1-4.3-7.2-6c-3.4-2.8-10-4-13.9-1.8
		c-6.4,3.6-17,14.4-25,11.9c-6.1-2-11.5-4.3-17.2-1.8c-3.3-3-6.7-4.2-11.2-1.5c-3.7,2.2-3.2,1.9-7.3,0.1c-2.1-0.9-4.9-2.2-7.2-0.8
		c-7.6,4.7-5.7,10.7-9.9,17.4c-4.4,7.1-12.2,12.2-15.2,20.2c-2.7,6.9-0.9,12,2.8,18.2c1.6,2.7,4.8,4.1,7.5,5.4
		c6.7,3.4,3.3,7.5,6.2,12.3c3.3,5.1,11,9.8,17.4,7.3c1.9-0.7,3.6-1.7,4.9-3.1c0.6,0,1.2-0.2,1.8-0.4l2.3-1c1.1-0.6,1.8-1.7,1.8-3
		l-0.5-0.4c1.7-1.1,2.2-3.3,1.1-4.9c-0.3-0.4-0.6-0.8-1-1c0.3-1.3,0.6-2.7,0.8-4.1c0-0.2,0-0.4,0-0.6c2.9-2.3,6.4-4.6,9.4-6.6
		l1.4,3.2c0.8,1.4,2.3,2,3.8,1.7c17.1-4.8,38.6-17.6,48.8,5.4c0.6,1.1,1.7,1.8,3,1.8c11.5,0.6,12.7,12,7.7,20.7
		c-0.4,0.8-10.5,15.1-12.8,12.5c-1.5-1.5-3.6-2.3-5.6-2.3c-5.3-0.1-8.1,6.4-14.3,0.5c-1.5-1.7-3.6-2.6-5.8-2.6
		c-4.4-0.4-9.1,1-13.1,2.7c-8.4,3.6-16.8-2.3-20.7-9.5c-0.8-1.3-2.3-2-3.8-1.7c-8,0.9-11.9,3.8-11.5,12.2c0.4,7.5,4.4,10.9,6.1,17.7
		c1.5,5.8,1.1,9.5,5,14.4c7.8,9.8,17.3,20.3,29.8,23.4c1.8,0.4,3.6,0.3,5.2-0.3c5.8-2.2,10.1-0.5,15.3,2.4c3.7,2,10.2-1.2,12.7,1
		c0.9,0.9,2.1,1.2,3.3,0.9c6-2.1,12.3-2.8,18.3-5.1c8.4-3.2,19-0.7,26.8-5.3c3.3-2,8-8,10.7-7.6s5.4-1.9,7.2-3.6
		c6.6-6.2,10.1-16.7,13.1-25c3.8-9.9,6.1-20.2,7.1-30.7c0.7-8.3-5.9-17.3-12-22.5c-1.2-1-2.5-1.7-4-2.2c-7.2-2.8-4.1-10.5-1.7-15.4
		C658.3,2150.1,657.1,2146.1,655.8,2141.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M425.1,2271c8.6,6,23.6,4.8,31-2.9c4-4.2,5.1-10.2,9.3-14c4.4-4.2,14.7-3.1,20.4-6.9
		c10.8-7.5,4.6-24.1-1.9-31.9c-4-4.7-9.8-7.7-15.2-10.4c-10.9-5.3-13.5-6.6-16-19.2c-5.8-29.6-35.7-31.4-56.4-14.5
		c-0.5,0.4-1,0.9-1.4,1.4c-1.5,1.5-2.3,3.6-2.2,5.7c0,0.3,0,0.5,0.1,0.8c0,0.1,0,0.2,0,0.3c0.1,0.6,0.3,1.2,0.6,1.8
		c7.6,18.1,5.8,32.4,5.4,51.3C398.3,2249.5,412.8,2262.4,425.1,2271z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1733.7,2228.7c-1.7,12.8-6,24.3-4.1,37.5c1.7,10.8,11.6,14.4,16.3,23.1c5.9,10.8,9.4,11.9,21.6,12.8
		c4.7,0.3,10.4,5.4,14.9,6.9c4.4,1.4,9.1,1,13.2-1.2c1.4-0.7,9,2.2,10.8,2.3c4.4,0.3,8.9-0.8,12.7-3.3c7.4-4.9,14.5-13.2,20.1-20.1
		c6.4-8,10.7-12.9,11.1-23.8c0.3-10.6-3.8-23.5-7.2-33.3c-2.8-7.6-7.4-14.1-16.1-14.4c-17.1-0.5-39.4,23.7-46.6-3.6
		c-4.5-17-15.1-30.5-32.8-35.9c-8.4-2.6-13.5,1.2-15.4,6.9c-3.8,2.2-3.5,8.2,0,11.2c1.1,3.8,3.4,7,6.4,9.4c0.3,0.2,0.7,0.4,1.1,0.6
		c1,2.8,1.1,5.7,0.2,8.5C1737.8,2218.1,1734.5,2222.7,1733.7,2228.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M729.7,2290.8c5.5-9.7,6.9-18.6,7.1-29.3c2.6-2.3,6.5-3.8,8.9-5.7c2.7-2.3,3-7.8,3.5-10.9
		c0.8-5.8,0.5-11.7,1.4-17.5c0.5-3.9,1-8.5-0.5-12.3c-7-18.2-35.6-22.7-49.4-11c-7.7,6.5-12.4,20.3-14.3,29.8c-1,4.6,0,9.1,1.5,13.5
		c1.7,5.1,7.1,7.2,9.2,12.1c6.6,15.1-2.3,30.2-6.4,44.6c-4.3,14.9,1,30.9-1.5,46.1c-1.6,9.6,4.6,21.2,10,28.6
		c7.6,10.4,19.9,10.6,31,14.6c12,4.4,22.2,7.6,35.1,9.2c10.4,1.3,19.1,10.4,30,7.1c21.6-6.4,41.8-20.3,51-41.1
		c5.1-11.8,3.7-24.3,3.7-36.9c0-9.1,0.8-16.3,3.9-24.8c2.1-5.9,1.2-9.2-1.7-14.5c-2.1-3.9-4.4-7.5-6.3-11.5
		c-1.9-4-1.9-10.3-2.7-14.8c-1-5.9-5.3-9.4-9.9-12.9c-9.6-7.4-9.8-14.7-12.1-25.8c-1.4-7-6.2-12.9-11.1-17.8
		c-9.4-9.6-24.9-14.4-35.8-4.7c-10.1,9-10.7,31.7-5.9,43.4c3.1,7.8,11.3,13.5,16.3,20c9.1,11.4,17.6,23.7,19.7,38.5
		c0.7,4.9-1.8,10.5-2.4,15.5c-0.6,5,4.9,9.4-0.2,14.2c-8.2,7.8-25,1.5-33.6-0.8c-8-2.1-14.8-1.8-22.4,1.5c-5.1,2.3-8,4.4-12.6-0.9
		c-3.4-3.9-5.5-9.4-7.7-14c-2.7-5.4-5.6-13.3-3.8-19.4C722.9,2298.3,727.5,2294.7,729.7,2290.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1037.3,2191.9c-10.6-10.8-26.6,9-37.1-4.6c-10.4-13.3-19.1-7.1-34.3-9.9c-12-2.2-27.2-7.4-38.8-0.8
		c-6.5,3.8-9.6,8.4-13.9,14.4c-4.4,6-2.4,14.5-6.8,20.7s-11,6.9-16.8,11.1c-6.1,4.3-10.7,12.9-14.5,19.1c-5.4,9.1-1.8,18.8,4.7,26
		c11.7,12.9-5.8,29.2,7,41.2c7.4,6.9,16.9,22.2,27.8,24.5c3.6,0.8,7.8,0.2,11.4,0.7c5.7,0.9,9.7,6.7,12.9,10.9
		c2.8,3.7,5.8,5.1,10.3,5.9c14.1,2.4,28-8.3,41.7-10.1c15.9-2.2,29.3-4.1,39.1-18.4c4.6-6.7,8.2-14,14.7-19.1
		c4.6-3.7,9.8-6.4,14-10.6c7.3-6.9-2.8-17.2-7.8-21.9c-17.5-16.2-43.9-26.2-63.5-8c-9.8,9.1-18.2,16.6-31.1,21.2
		c-19.2,6.7-26.3-12-23.3-26.9c2-10,17.2-22.1,27-23.6c10.2-1.6,15.7,5.9,24.8,5.9c18.1,0,53.1,2.3,55.1-18.6c0.5,0.6,1,1.2,1.5,1.9
		c3.3,4.6,10.7-0.2,7.3-4.8c-4-5.7-7.3-8-7-15.4C1042,2197.9,1040.6,2195.3,1037.3,2191.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M879.1,2222.4c15.1-14.3,27.9-42.1,12.7-60.4c-5.2-6.2-17.3-14.1-25.9-9.8c-5.8,3-9.8,8.4-10.4,14.4
		c-1.8,3.3-3.2,6.8-4.2,10.4c-3.6,13.2-6.6,25.5-13.4,37.6c-4.1,7.1,6.8,15.5,12.9,16.4C861.5,2232.7,871,2230,879.1,2222.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1286.6,2366.9c-4.9-0.5-9.9-1.2-14.7-2.1c-16.6-3.2-32.6-13.3-46.1-23c-18.8-13.5-41.3-8.5-46.2,15.3
		c-2.3,11.4,3.8,20.5,9.8,29.7c3.6,5.4,7.3,16.7,13.9,19.2c9.2,3.4,21.2,4.6,30.1,0c4.4-2.3,13.8-11.2,19.1-5.3
		c4.3,4.7,6.2,10.6,8.7,16.4c6.4,15,28.1,23,41.4,11.5c5.2-4.5,8.7-9.1,14.7-12.9c0.9-0.5,3.5-0.2,5.5-1.5
		c7.6-4.7,8.3-14.6,13.4-21.3c7.3-9.4,5.2-15.2-0.6-24.1c-5.2-7.9-0.4-17,2-24.9c1.9-5.8,2.7-12,2.3-18.1
		c-0.4-4.9-3.5-15.6-10.8-12.9c-5.6,2.1-17.1,15.2-22.8,8.5c-8.9-10.3,1.3-30.4-2.2-42.5c-4.4-15.9-26.4-6.1-35.7-0.4
		c-4.4,0.3-8.9,1.8-11.5,5.2c-4.4,6-10.2,17.6-8.8,25.4c1,4.5,3.4,8.8,6.9,11.9c7.5,7,2.6,12.7,5.9,19.8c5.7,12.1,25,8.6,33.2,18.7
		C1301,2367.5,1292.7,2367.4,1286.6,2366.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1178.6,2335.5c9.2-0.1,14.7-7.6,17.1-17.3c3-11.7,4.4-24.1,19.6-24.8c7.1-0.3,13.8,0.6,19.4-4.5
		c7.2-6.6,17.2-21,11.7-31.1c-5.6-10.2-27.7-12.5-37.7-12.3c-17.2,0.4-24.4,21.1-40.2,23.7c-5.8,1-8.4,8-9.3,13.1
		c-1.3,6.6-1.7,12.1-4.9,18.2c-1.9,3.3-3.4,6.7-4.4,10.3C1146.5,2321.5,1171.5,2350.2,1178.6,2335.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2017.1,1348.4c10-0.4,18.9-0.9,25.9-8.9c12.9-14.7,5-36.8-9.5-46.8c-7.3-5-17.1-7.2-24.1-4.6
		c-3.9-2.8-10.8-1.3-11.3,4.9c-1,15.2-29.6,40.1-6,52.6C1999.5,1349.6,2009,1348.7,2017.1,1348.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2266.1,2322.8c5,6.4,11,12,13.8,19.8c2.1,5.5,3.3,11.3,5.9,16.6c4.4,9,17.3,2.5,23.3-0.9
		c20.8-11.9,38.3-34.2,26.7-58.3c-5.7-12.1-10.5-22.4-11-36.2c-0.7-20.3,19.3-21.6,32.7-14.2c8.9,4.8,16,23,14.5,32.8
		c-1.5,10.2-10.4,13.3-13.1,22.1c-5.3,17.2-18.1,50,1.3,58.2c-0.7,0.3-1.5,0.6-2.3,0.9c-5.4,1.8-3,10.3,2.5,8.5
		c6.6-2.1,9.9-4.6,16.9-2.1c4.6,1.7,7.5,1.2,11.8-1c13.5-6.9-0.7-28.2,15.4-34.1c15.8-5.8,12.5-16.1,19.7-29.9
		c5.7-10.8,15.2-23.7,12.3-36.9c-1.7-7.4-5.1-11.8-9.7-17.7c-4.5-5.9-13.2-6.6-17.8-12.7c-4.6-6-3.4-12.6-5.5-19.4
		c-2.3-7.1-9.1-14-13.9-19.6c-7-7.9-17.5-7.3-26.4-3.2c-15.8,7.3-26.1-14.3-41.5-5.6c-8.9,4.9-26.3,9.5-31.8,19.2
		c-1.8,3.2-2.6,7.4-4.2,10.7c-2.6,5.2-9.3,7.2-14.2,9c-4.3,1.5-6.7,4.1-8.7,8c-6.5,12.8-0.5,29.3-2.8,42.9
		C2257.5,2295.8,2255.3,2309.2,2266.1,2322.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1089.2,2219.9c-9.2,5.1-16.9,12.7-22.3,21.7c-8.7,14.7-2.8,30.2,9.2,39.1c-3.7,14.4-0.6,32.7,2.8,46.3
		c-4,4.1-7.4,8.8-7.4,14.2c0,12.8,10.8,16.4,21,20c5.5,2,9.2,7.3,13.8,10.7c3.8,2.8,8.3,2.9,12.8,2.6c8.4-0.5,20.7-8.3,24-16.2
		c4.4-10.2-2.7-21-11.5-28.7c3.1-7,4.1-14.7,3.1-22.3c-1.3-11.1-5-20.7-13.4-26.2c-0.8-1.6-1.8-3.2-2.8-4.6c1.2-2.9,1.9-5.8,2.8-8.4
		c4.2-12,11.4-20.3,23.6-23.9c9.3-2.8,19-3.6,28.1-7c11.4-4.4,35.5-16.2,38.3-29.6c3.4-16.7-16.7-19.1-28.9-18.7
		c-6.7,0.2-13.2,2-20,1c-7.2-1.2-12.4-8.1-17.4-12.7c-6.4-5.8-15.7-7.9-24.1-6.6c-5,0.7-9.5,3.9-12,8.3c-2.7-1.3-6.4,1.7-4.4,4.8
		c0.5,0.9,1,1.8,1.5,2.7c-1.7,6.5-1.9,13.9-2,19.7c0,0.3,0,0.7,0.1,1C1100,2212.4,1095,2216.7,1089.2,2219.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1268.3,2311.2c0.5,3.9,3.9,5.2,6.8,4.6l6.9,15.8c1.3,3,4.6,4.3,7.5,3.1l0,0c13-6.3,21.4-20,25.9-33.2
		c5-14.5,3.2-27.6-8.6-37.8c-10.1-8.8-22.2-2.2-28.8,7.1c-4.3,6.2-7.2,13.3-8.8,20.7c-2.3,1.4-3.2,4.4-2,6.7l0.7,1.6
		C1267.7,2303.7,1267.8,2307.4,1268.3,2311.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1716.4,2341.8c4.4,4.2,10.9,4.7,16.7,4.7c5.2,0,11.5,2.9,16.5,2.3c4-0.5,6.1-0.8,8.7,2.8
		c15.4,21.4,41-15.4,20.9-31c-4.6-3.7-10.4-5.1-15.9-6.7c-6.4-1.8-11-5.1-16.2-9.1c-8.3-6.3-21.1-13.9-29.5-3.4
		c-1.5,1.9-2.5,4.1-3,6.4c-1.6,2.2-1.9,5-0.8,7.4C1710.9,2324.4,1708.7,2334.6,1716.4,2341.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1549.7,2330.4c6.5-5.3,12-9.6,15.7-17.6c4.4-9.3,4.7-20.3,3.4-30.4c-0.5-3.5-2.9-7.2-6.9-6.8
		c-5.7,0.8-11.3,3.2-15.7,6.9c-1.9-0.1-3.8,0.6-5,2c-10.5,10.4-43.1,39.4-26,55.3C1527.2,2351.2,1540.5,2338,1549.7,2330.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2215.6,1814.5c-4.9-5.1-13.2-4-19.6-3.5c-6.3,0.5-11.9,1.3-17.8,3.6c-1.1,0.4-2.1,1.1-3,1.8
		c-6.9,1.4-12.8,5.7-14.5,13c-2.2,9.2,6.7,15.7,13.5,19.5c7.1,4,15.4,12.2,24.4,9.5c5-1.6,9.3-4.8,12.2-9.2c0.8-1.4,1.4-3.1,1.6-4.6
		c0.5-2.7,1.4-5.1,2.6-7.6C2217.6,1830.6,2221.1,1820.2,2215.6,1814.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2348.8,2155c-5.7-4.7-14.2-5.2-19.7-10.6c-5.4-5.3-0.6-10.9,2.8-15.7c3.4-4.8,3.5-11.8-1.6-15.8
		c-6.2-5-13.9-4.7-21.6-4.9c-3.4-0.1-6.5-1.4-9.9-1.5c-0.5,0-0.9,0-1.4,0.1c-2.7-1.6-6.1-1.4-8.6,0.7c-4.7,4-8.9,8.6-12.4,13.7
		c-3.8,5.3-5.3,11.8-6.9,18c-1.5,6-4,11.8-7.2,17c-4.6,7.6-6.9,16.9-4.2,25.5c3.5,10.9,16.2,13.9,26,11.4c6.3-1.6,12.2-5,18.1-7.8
		c6.3-2.9,13.3-4.4,20.3-4.4c9.2-0.2,21.2,0.8,27.5-7.3C2354.7,2167.7,2354.3,2159.7,2348.8,2155z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2063.5,2036.1c-3.6-3.6-14.5-11.6-19.4-5.3c-3.4,4.3-1.2,8.4,2.1,12c0.4,3.9,1.6,7.5,3.3,11
		c1.2,2.9,1.6,5.9,1.3,9c-0.3,6.2-1.3,12.4-1.4,18.6c-0.1,3.7-0.3,9.6,3.3,11.9c4.5,2.9,9.7-2.8,12.5-5.7c8.9-9.2,4.8-19.4,6.9-30.8
		C2073.7,2047.9,2069.4,2042.1,2063.5,2036.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2035,2129.7c-5.8,0.2-11.5,4.6-15.1,10.4c-4.6,2.8-8,7.4-7.9,13.2c0,2.7,0.4,7.6,2.6,9.5
		c1.6,1,3.3,1.9,4.9,2.6c4.6,2.3,8.4,5.2,13.8,4.6c6-0.7,7.9-5.7,8-11.2c0.1-6.9,2-13.5,2.3-20.4
		C2043.8,2133.9,2039.2,2129.5,2035,2129.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2250.4,2121c-1.2-11.7-12.3-11-21.3-10.8c-5.2,0.1-10.4-2.5-15.5-0.3c-2.7,1.3-4.9,3.4-6.3,5.9
		c-0.9,0.6-1.7,1.4-2.3,2.3c-2.7,4.3-2.7,10.1-2.7,14.9c0,0.8,0.4,1.5,1.1,1.9c0.1,0.1,0.2,0.3,0.3,0.6c0.6,1,1,2.1,1.5,3.1
		c0.5,1.1,1.7,3.7,2.1,4.9c-0.4,0.7-0.4,1.5-0.1,2.2c2.2,4.4,3.9,5.7,8.8,5.7h10.5c2.1,0,4.8,0.5,6.8-0.3
		C2243.9,2147.1,2251.4,2132.1,2250.4,2121z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-36,1907.8c3.4-13.2-0.1-31.1-12-39.1c-4.7-9.4-17.6-9-27.4-2.3c-5.3,3.7-11.3,9.1-11.8,16
		c-0.7,9.8,4.3,20.5,10.8,27.5C-65.3,1921.7-41.3,1928.8-36,1907.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-94.6,1235.5c-2.1,7.1,1.8,15.8,3.9,22.4c2.1,6.8-1.5,14.3,1.9,20.6c2.3,4.4,4.7,7.4,9.6,8.7
		c7.4,2,13.6,5.2,21.5,5.7c3.1,0.2,5-2.3,8.3-2s6.9,4.4,9.8,6c5.5,3.3,16.9,1.4,22.4-1c14.9-6.2,29.3-9.9,38.4-24.3
		c2-3.2-3.2-5.8-5.2-2.7c-0.5,0.8-1.1,1.6-1.6,2.3c-1.2-7.4-9.4-13-18.7-10.1c-4.2,1.3-7.4,4-11.4,5.7c-6.8,3.1-8-7.9-10.1-11.2
		c-4.4-6.7-18.5-3.9-20.7-10.5c-2.3-7.1,17.1-14.4,21.3-18.1c6.2-5.6-2.9-13.4-5.5-17.8c-10.6-16.9,10.1-19.9,22.3-20.7
		c8.6-0.6,19.4-2.1,23.9-10.5c8-14.8-6.3-26.8-18.4-33.1c-7.4-3.9-14.7-4.7-23-5.4c-9-0.7-12.5,3.3-16.9,10.3
		c-7.4,11.6-20.5,5-30.9,9.9c-7.2,3.4-11.6,14.2-11.9,21.7c-0.2,4.3,0.1,7.7,2.4,11.4c3.7,5.9,8.4,8.2,6.8,16.4
		C-78.7,1220.2-91.6,1225.1-94.6,1235.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M22,1223.7c2.1,2.3,4.5,4.3,7.3,5.6c2.4,1.5,3.3,1,1.8,4c-1.4,2.5-2.6,5.1-3.6,7.8c-3.1,9.6,8,18.4,14.7,22.7
		c5.7,3.7,11.7-3.1,16.6-5.4c7.1-3.5,14.6-6.1,21.1-10.8c5.1-3.7,8.7-8.8,8.4-15.2c-0.4-9.1-16.3-18.2-7.1-25.5
		c24.1-19-10.1-59.4-34.4-43.8c-5.8,3.7-6.1,11.5-10.7,16.3c-5.3,5.6-13.8,6.7-14.9,15.7c-0.8,5.8-0.4,12.2,2.4,17.4
		C20.2,1215.2,18.7,1219.6,22,1223.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-94.4,2034.9c-2.3,7.3,2,16.3,4.3,23.1c2.3,7-1.7,14.8,2.1,21.3c2.6,4.6,5.2,7.7,10.7,9
		c8.2,2.1,15.1,5.4,23.8,5.9c3.5,0.2,5.6-2.4,9.2-2.1c3.6,0.3,7.7,4.4,10.9,6.2c6.1,3.5,18.7,1.4,24.9-1
		c16.6-6.4,32.4-10.2,42.5-25.2c2.3-3.3-3.6-6-5.7-2.8c-0.6,0.8-1.2,1.6-1.8,2.4c-1.3-7.7-10.4-13.4-20.8-10.4
		c-4.6,1.4-8.2,4.1-12.7,5.9c-7.5,3.2-8.9-8.2-11.2-11.5c-4.8-6.9-20.6-4-23-10.8c-2.6-7.4,19-14.9,23.5-18.8
		c6.9-5.7-3.2-13.9-6.1-18.4c-11.8-17.5,11.2-20.7,24.8-21.5c9.6-0.6,21.5-2.2,26.6-10.9c8.9-15.3-7.1-27.8-20.5-34.2
		c-8.2-4.1-16.4-4.9-25.5-5.6c-10-0.8-13.8,3.4-18.8,10.7c-8.2,12-22.6,5.2-34.3,10.2c-8,3.5-12.9,14.7-13.3,22.4
		c-0.2,4.4,0.1,8,2.7,11.8c4.2,6.1,9.3,8.5,7.6,17C-76.8,2019.1-91.1,2024.2-94.4,2034.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M35,2022.8c2.2,2.6,5.1,4.2,8,5.8c2.7,1.5,3.7,1,2,4.1c-1.5,2.6-2.9,5.2-4,8c-3.4,9.9,8.9,19.1,16.4,23.5
		c6.4,3.8,13.1-3.2,18.4-5.6c7.9-3.6,16.2-6.3,23.4-11.2c5.6-3.8,9.7-9.1,9.4-15.8c-0.4-9.4-18.1-18.9-7.9-26.4
		c26.8-19.6-11.2-61.5-38.3-45.4c-6.4,3.9-6.8,11.9-11.9,16.8c-5.9,5.7-15.2,6.9-16.5,16.3c-0.8,6.1-0.4,12.6,2.7,18
		C33,2013.9,31.3,2018.5,35,2022.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M75.8,1558.5c-9.6,0-18.9-0.4-27.7-4.4c-4.9-2.2-8.8-3.5-13.8-1c-9.9,4.7-10.7,25.5-5.9,33.5
		c7.2,12.3,22.6,17.6,35.7,10.2c6.2-3.6,9.3-8.3,12-14.8c1.4-3.5,3.4-6.6,3.8-10.1c2.8-1.2,2.4-5.9-0.9-6.5c0.2-1.2,0.4-2.4,0.5-3.6
		C79.5,1559.9,77.5,1558.5,75.8,1558.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M126.5,1618.8c12.4,1.6,27.6,4.2,39.6-0.9c7.7-3.3,16.7-13.2,12.9-22.1c-3-6.7-10.1-3.9-12.4-13.2
		c-1.6-6.5-1.2-10.7-5.6-16.1c-4.8-5.7-17.5-7.3-24.3-9c-8.8-2.1-16.1-7.3-24.8-9.2c-11.1-2.5-18.3,4.7-22,14
		c-3.1,2.8-5,6.5-5.7,10.6c-2.4,13.3,10.1,20.1,12.1,32C98,1617.3,117.1,1617.6,126.5,1618.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M65.6,1636.2c-1.9-4.6-5.8-7.4-10.5-9.1c-0.2-4.4-1.4-8.4-5.1-10.2c-4.9-2.3-9.2-2.9-13.3-7.1
		c-2.6-2.7-5.5-5.8-9.4-6.4c-19.8-2.9-33.6,23.2-21,38.3c9.1,10.7,25.3,13.3,19,31.5c-3,8.6-9.7,19.6-3.2,28.6
		c4.6,6.2,11.5,6.3,18.6,5.7c5.9-0.5,11.4-1.6,16.7,1.9c4.8,3.2,7.8,10,11.4,14.3c6.2,7.7,15.9,10.3,25.5,9.5
		c10.7-0.9,24-1.8,31.4-10.5c2.7-3.2,4-7.7,5.9-11.3c4-7,11.3-6.1,17.6-4.2c9.3,2.9,16.2-1,22.4-7.8c10.2-11,23.4-35.4,7.1-46.3
		c-14-9.3-36.1-7.6-52.1-6.4c-8.1,0.6-14.4,2.2-20.4,8.1c-3.7,3.7-6.3,8.1-10,11.8c-7.2,7.3-14.7,2.6-21.4-2.4
		c-5.5-4.2-10.3-9.6-9.7-16.9C65.7,1643.9,67,1639.7,65.6,1636.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M73.2,1861.3c-4.9-0.5-9.9-1.2-14.7-2.1c-16.6-3.2-32.6-13.3-46.1-23c-18.8-13.5-41.3-8.5-46.2,15.3
		c-2.3,11.4,3.8,20.5,9.8,29.7c3.6,5.4,7.3,16.7,13.9,19.2c9.2,3.4,21.2,4.6,30.1,0c4.4-2.3,13.8-11.2,19.1-5.3
		c4.3,4.7,6.2,10.6,8.7,16.4c6.4,15,28.1,23,41.4,11.5c5.2-4.6,8.7-9.2,14.7-12.9c0.9-0.6,3.5-0.2,5.5-1.5
		c7.6-4.7,8.3-14.6,13.4-21.3c7.3-9.4,5.2-15.2-0.6-24.1c-5.2-7.9-0.4-17,2-24.9c1.9-5.8,2.7-12,2.3-18.1
		c-0.4-4.9-3.5-15.6-10.8-12.9c-5.6,2.1-17.1,15.2-22.8,8.5c-8.9-10.3,1.3-30.4-2.1-42.5c-4.4-15.8-26.4-6.1-35.7-0.4
		c-4.4,0.3-8.9,1.8-11.5,5.2c-4.4,6-10.2,17.7-8.8,25.4c1,4.5,3.4,8.7,6.9,11.9c7.5,7,2.6,12.7,5.9,19.8c5.7,12.1,25,8.6,33.2,18.7
		C87.6,1861.9,79.3,1861.8,73.2,1861.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-34.8,1829.9c9.2-0.1,14.6-7.6,17.1-17.3c3-11.7,4.4-24.1,19.6-24.8c7.1-0.3,13.8,0.6,19.4-4.5
		c7.2-6.6,17.2-21,11.7-31.1c-5.5-10.2-27.8-12.5-37.7-12.3c-17.2,0.4-24.4,21.1-40.2,23.7c-5.8,1-8.4,8-9.3,13.1
		c-1.3,6.6-1.7,12.1-4.9,18.2c-1.9,3.3-3.4,6.7-4.4,10.3C-66.8,1815.9-41.8,1844.7-34.8,1829.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-13.3,1033.7c-0.1-0.9-0.3-1.8-0.4-2.7c-0.4-1.8-2-3-3.8-3.1c-10.5,0-24.1,12.9-27.9,22.2
		c-4.8,12.4-6.4,26.2,1.8,37.4c3.3,4.5,7,7.7,8.9,13.3c0.9,3.2,2,6.3,3.3,9.4c2.8,6.4,7.6,11.8,13.8,15.1c10,5.6,35.8,9,45.6,1.8
		c7.1-5.1,9.8-13.5,18-17.9c6.5-3.5,12.2-4.4,16.3-11c6.7-10.5-7.2-21.8-14.8-26.4c-8.6-5.3-27.8-5-31.4-16.2
		c-2.2-6.5-1.7-12.3-5.9-18.3c-5.7-8.2-15.4-5-23.8-1.3C-13.3,1035.3-13.2,1034.5-13.3,1033.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-13.8,1533.9c4.5,5.4,10.4,3.7,16.8,3.2c7.1-0.6,13.3-0.1,20,2.5c5.9,2.3,11.3-4.6,10.5-10.1
		c-0.4-2.3-1.8-4.2-3.7-5.4c-3.1-2.3-4.6-3.4-6.9-6.4c-4.6-6.5-13.3-10.9-20.7-13.3c-5.1-1.8-13.3-3.2-17.9,0.7
		c-5,4.3-3.3,6.8,0.1,9.7C-15.8,1520.6-17.3,1529.6-13.8,1533.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M61,839.6c-3.4-3.8-8.2-6.4-12.4-9.2s-7.7-7.1-12.3-9.4c-10.9-5.2-24.2-5-35.9-2.8c-5.1,1-7.3,2.6-11.1-3.5
		c-1.5-2.8-2.9-5.5-4.1-8.5c-1.7-3.7-6.2-7.2-9.4-9.6c-8.5-6.4-18.4-0.7-27.5,0.6c-8.5,1.2-15.7,0-22.7,5.6
		c-7.5,5.9-16.7,20.3-18.6,29.8c-1.9,9.7,2.9,18.8,11.9,23.2c12.9,6.3,21-1.6,33.2-5.2c5.6-1.5,11.5-2.5,17.4-2.8
		c9-0.7,11.8,1.7,16.8,8.4c10.2,13.4,37.1,24.1,23.3,44.6c-7.4,11-30.8,17.4-40.5,8c-9.8-9.3-20.1-19.5-27.9-30.5
		c-4.8-6.7-13.8-11-21.5-5.6c-9.4,6.6-12.3,17.2-12.6,28.3c-0.3,10.3,2.5,20,9.5,27.6c5.2,5.6,12.5,9,18,14.3
		c5.3,5.2,11.3,9.7,17.7,13.3c11.8,6.6,27.1,8.1,40,11.1c15.6,3.6,29.9,6.3,45.6,1.7c0.6-0.2,1.1-0.5,1.5-0.9
		c1.9-0.4,3.7-1.2,5.1-2.4c4.9-4.2,1.5-13.3,8-16.5c5-2.4,9.6-5.8,13.3-10c4.7-5.4,11.4-14.3,5.4-21.6c-5-6.1-8.2-9.6-5.4-18.4
		c1.8-5.6,4.6-10.3,5.1-16.3c0.8-8.9-16.7-15.7-10.4-23.4C66.6,852.8,67.6,847.2,61,839.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-84.5,1814.5c-4.9-5.1-13.2-4-19.6-3.5c-6.3,0.5-11.9,1.3-17.8,3.6c-1.1,0.4-2.1,1.1-3,1.8
		c-6.9,1.4-12.8,5.7-14.5,13c-2.2,9.2,6.7,15.7,13.5,19.5c7.1,4,15.4,12.2,24.4,9.5c5-1.6,9.3-4.8,12.2-9.2c0.8-1.4,1.4-3.1,1.6-4.6
		c0.5-2.7,1.4-5.1,2.6-7.6C-82.4,1830.6-79,1820.2-84.5,1814.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M48.8,2155c-5.7-4.7-14.2-5.2-19.7-10.6c-5.4-5.3-0.6-10.9,2.8-15.7s3.5-11.8-1.6-15.8
		c-6.2-5-13.9-4.7-21.6-4.9c-3.4-0.1-6.5-1.4-9.9-1.5c-0.5,0-0.9,0-1.4,0.1c-2.7-1.6-6.1-1.4-8.6,0.7c-4.7,4-8.9,8.6-12.4,13.7
		c-3.8,5.3-5.3,11.8-6.8,18c-1.5,6-4,11.8-7.2,17c-4.6,7.6-6.9,16.9-4.2,25.5c3.5,10.9,16.2,13.9,26,11.4c6.3-1.6,12.2-5,18.1-7.8
		c6.3-2.9,13.3-4.4,20.3-4.4c9.2-0.2,21.2,0.8,27.5-7.3C54.7,2167.7,54.3,2159.7,48.8,2155z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1750.3,2123.4c-1-9.1-10.4-8.8-17.1-7.3c-4.6,1-9.2,2.6-13.8,3.4c-6.9,1.2-13.3-4.8-19.1-8
		c-2.7-1.5-5.4-1.8-8-3.4c-2.3-1.4-1.5-2.9-2-5.3c-0.7-3.7-5.7-7.6-8.9-9.2c-5.2-2.7-8.1,2.2-9.7,7.7l-0.3,0.1
		c-7.8,3.3-12.5,14.5-12.9,22.5c-0.2,5.6,2.7,10.5,5.9,14.7c3.9,5,8.1,9.8,12.8,14.1c4.4,4.2,9.3,8.1,14.9,10.4c6,2.3,11.5-1,16.6-4
		c5.2-3.1,12.4-1,18-3.3c4.4-1.7,8-5.7,11.1-9C1743.8,2140.7,1751.3,2132.5,1750.3,2123.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M115.2,1310.8c-4.4-3.1-12.6-2.9-13.8-9c-1.3-6.3,2.3-11.5-0.9-17.7c-4.4-8.6-15.7-14.5-24.9-16
		c-7-1.2-11.2,3.1-14.5,8.1c-1.8,1.4-2.7,3.7-3.6,5.7c-0.2,0.4-0.5,0.8-0.7,1.2c-0.3,0.4-0.4,0.9-0.4,1.3c-1.8,3.5-3,7.1-3.7,10.9
		c-1.5,9.7,5.7,15.3,13,19.8c3.9,2.6,7.9,4.7,12.2,6.6c2.1,0.8,4.6,0.8,6.7,1.1c3,0.4,7.9,3.9,10.4,5.7c4,3.1,6.6,6.1,11.9,6.5
		c4.3,0.3,8.4-1.5,11.1-4.7C122.2,1324.4,121.5,1315.2,115.2,1310.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M258.2,1261.3c-7.1-2.1-11.3,1.8-12.8,7.6c-6.2,8-13.8,15-20.6,22.7c-5.8,6.6-6.6,14.3-4.6,22.8
		c1.8,7.1,5.7,14,11.8,18.2c6.3,4.4,13.3,2.2,19.3-1.7c7.2-4.6,14.1-14.3,11.3-23.3c-0.9-2.9-2.2-4.8-0.5-7.8c1.2-2.1,2.7-4,3.8-6
		c2.3-4.1,2.8-9.5,3.2-14C270,1271.3,266.9,1263.9,258.2,1261.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M99.1,1512.3c-11.7-3.1-28-4.8-33.4,8.7c-0.6,1.5,0.8,2.7,2.1,2.9c-0.3,8.8-0.5,19.8,8.6,23.2
		c10.7,4.2,24.6-6.6,30.5-14.5C113.6,1523.4,109.3,1514.9,99.1,1512.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M190.7,1510.1c3-6.1,11.1-10.9,11.3-18.3c0.2-8.7-16.1-9.9-21.7-8.9c-7.2,1.3-15.5,3.8-21.8-1.5
		c-3.5-2.9-6.5-6.2-10.6-8.2c-6.3-3-13.6-1.6-15.4,4.4c-5,4.5-8.2,11.6-3.5,16.7c1.4,1.5,3.2,2.2,4.7,3.5c3.1,2.5-1.2,9.2-2.4,11.8
		c-2.5,4.9-2.4,10,1.1,14.5c3.5,4.4,9.1,6.4,13.3,9.7c2.6,1.9,5,4,7.2,6.2c2.3,2.5,4.9,7.4,8,8.8c8.1,3.8,17,5.6,25.9,5.5
		c7.3-0.1,13.3-6.1,13.2-13.4c0-0.8-0.1-1.6-0.2-2.3C198.2,1529.7,186.2,1519.3,190.7,1510.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M564.5,1515.4c-0.4-4.5-5.5-7.4-8.9-9.5c-5.2-3.2-6.3-6.5-7.9-12.3c-1.3-4.4-4.6-5.4-8.8-5.3
		c-6,0.2-7.8,6.5-8.4,12.4c-1.5,0-3.4,1.5-2.4,3.2c3.3,5.9-1.2,12.2,2.3,18.4c0.5,1.2,1.9,1.7,3.1,1.2c0.4-0.2,0.7-0.4,0.9-0.7
		c3.7-4.4,5.8-1.9,9.2,0.7c1.8,1.3,3.9,2.4,5.9,3c3.3,1.2,7.6,1.2,10.6-0.8C563.7,1523.5,564.9,1519.6,564.5,1515.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M558.1,1521.7c-3.2,2.8-9,0.2-11.9-1.7c-2.7-1.9-4.5-4.2-7.8-4.7c-1.9-0.2-3.8,0.5-5,1.9
		c-0.2-2.5-0.1-4.8,0.2-7.3c0.7-0.3,1.2-1,1.2-1.8c0-2.8,0.1-5.5,0.4-8.3c0.2-1.5,0.6-3.1,1.3-4.4c0.9-2.2,5.1-3.8,6.5-1
		c1.7,3.4,1.4,7.1,3.8,10.2c2,2.4,4.4,4.4,7.1,5.7C557.7,1512.7,563.3,1517.1,558.1,1521.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M676.1,1582.3c-0.4-3.5-1.7-5.7-5-7c-3.2-1.2-6.3-1.2-5.6-5.4c0.3-1.6,0.3-3.2,0-4.7
		c-1.5-7.5-13.8-6.4-19.2-5.5c-4.4,0.7-7.3,3-7.6,5.9c-3.9,4.3-7.2,10-3.8,13.5c2.9,3,6.7,3.9,10.4,5.6c4.1,2-1.7,8.8-2.6,12.1
		c-1.6,6.1,3,10.4,8.6,10.8c6.5,0.4,13.1-4.4,17.5-8.7C673.1,1594.5,676.9,1588.4,676.1,1582.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M670.9,1587.1c-1.3,4.7-5.1,8.6-8.9,11.5c-3.3,2.6-8.2,5.2-12.6,4.2c-6.6-1.7,1.3-11.7,2.3-15.1
		c1.2-3.8-3-6.2-5.9-7.5c-2.1-0.9-8.1-2.2-7.3-5.4c0.4-1.5,1-2.9,1.8-4.2c0.2,0.3,0.5,0.6,0.8,0.9c2.1,2.1,5.4-1,3.4-3.2
		c-0.4-0.4-0.7-0.9-0.9-1.4c0.8-0.7,1.7-1.4,2.7-1.9c0.3-0.2,0.5-0.4,0.7-0.6c3.6-0.5,7.2-0.6,10.9-0.1c5.9,1.2,0.5,9.1,4.5,12.2
		c2.5,1.9,5.3,2.4,8,3.8C672.4,1581.1,671.4,1585.5,670.9,1587.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M642.8,1628.7c-9-2.3-14.9,3-15.9,11c-4.5,7.6-11.1,19.6-0.5,21.9c3.3,0.7,5.8-0.5,8.7-2c4-2.3,8-4.3,12.2-5.9
		s8.7-4.3,9.8-8.9C658.9,1636.6,649.4,1630.3,642.8,1628.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M645.3,1649.4c-5.4,2.1-10.1,5.5-15.5,7.6c-6.5,2.5-3.8-5.9-2.6-8.5c0.5-1,1-2,1.5-3c1.1,0.4,2.2-0.2,2.6-1.3
		c0.1-0.3,0.1-0.5,0.1-0.8c0-0.8,0-1.6,0-2.3c0.7-1.2,1.3-2.3,2-3.5c0.4-0.8,0.4-1.7-0.1-2.4c1.6-2.2,4.3-3.2,8.2-2.3
		c4.5,1,11.7,4.9,11.2,10.4C652.4,1646.7,647.8,1648.5,645.3,1649.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M692.1,1637.4c-3-3.1-6.4-4-9.4-2.9c-1-0.3-2.1-0.1-2.7,0.7c-1.2,1.4-2,3-2.4,4.6c-0.1,0.2-0.1,0.3-0.2,0.4
		c-0.2,0.5-0.2,1,0,1.5c0,3,1,5.9,3,8.1c3,3.6,8,3.5,11.4,6.4c0.9,1,2.3,1,3.3,0.2c0.1-0.1,0.3-0.3,0.4-0.5
		C699.5,1650.1,696.9,1642.2,692.1,1637.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M682.2,1640.8c1.8-3.6,4.7-2.5,7.3,0.5c2.3,2.7,3.9,6.6,3,10.1c-1.8-1-3.6-1.8-5.4-2.5
		C683.5,1647.5,681.5,1644.2,682.2,1640.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M767.9,1459.2c-4.6-5-7.7-14-15.8-13.8c-3.4,0-6.3,2-7.8,4.9c-1.1-0.3-2.2,0.4-2.5,1.5c-0.1,0.3-0.1,0.5,0,0.8
		c1.9,15.8-22.9,29.6-11.8,45.8c3.7,5.2,10.2,2.2,14.3-0.6c3.3-2.2,6.2-4.8,9.5-7.1s7.2-1.9,11.1-1.6c8.6,0.6,11.2-4.8,14.3-11.9
		C783.5,1467,773.1,1464.8,767.9,1459.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M774.2,1476.7c-1.5,3.4-2.5,7.2-6.6,7.7c-4.6,0.6-9.5-1.3-13.9,0.9s-7.9,5.7-11.9,8.5c-1.9,1.3-5.4,4-7.7,2.3
		c-3-2.2-2.4-7.5-1.6-10.5c2-7.9,8.3-14.3,11.8-21.8c1.2-0.1,2.2-1.1,2.1-2.3c-0.2-2.9,0.3-5.6,1.3-8.3c3-6.3,8.3-2.3,11.1,1.2
		c3.8,4.7,6,8.5,11.3,11.8C774.3,1469,776.6,1471.4,774.2,1476.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M199.7,1739.1c-5.6-3.3-6.3-7.4-8.7-12.9c-3.7-8.6-17.7-1.3-22.6,2.2s-8.6,6.7-13.5,1.5
		c-2.8-2.9-7.3-4.9-11.3-3.9c-4.1,1-6.4,3.7-8.8,6.9c-1.9,2.6-3.5,5-6,7l-0.2,0.1c-0.3-0.1-0.6-0.1-0.8-0.1
		c-11.7,0.9-17.2,12.4-12.1,22.3c1.4,2.6,3.1,4.7,2.5,7.8c-0.4,2-0.9,3.9-1.4,5.8c-0.8,3.6-1.7,7.8,0,11.3
		c4.2,9.1,17.1,5.3,23.7,1.6c6.1-3.6,11.8-3.9,18.7-1.7c5.9,1.8,9.8,2.2,15.4-0.9c3.7-1.9,4.4-6,5-9.7c0.4-2.5,1.2-4.4,1-6.9
		c-0.2-3.3,0.3-3.3,3.6-4.2c4.6-1.3,10.4-2.1,14.1-5.1c4.3-3.4,6.3-9.3,6.9-14.5C205.3,1742.5,202.2,1740.6,199.7,1739.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M209.8,1807.2c0.6-3.3,0.6-7.3-2.4-9.5c-3.2-2.4-7.8-3.8-11.5-2c-1.6,0.7-2.8,2.5-3.8,3.9
		c-2.3,3-4.3,4.9-8.1,5.7c-3.5,0.7-7.6-0.5-11.1-0.9c-2.5-0.3-5-0.2-7.6-0.2c-7.9-0.2-12.1,3.2-12.6,9.6c-1.1,1.6-2,3.4-2.9,4.9
		c-3.2,5.5-1.6,12,1,17.4c1.5,3,3.3,5.7,5.3,8.3c2.4,3,0.3,6.8,0.3,10.2c-0.1,4,3.8,7.3,7.1,8.6c4.6,1.8,10.8,1,15.6,0.7
		c3.5-0.2,5.6-2.2,7.4-4.9c2.8-4.4,5-7.8,9.4-10.9c3.6-2.6,9.2-5.6,11-10.1c1.7-4.6,2.4-9.7,1.9-14.6c-0.3-3.3-1.1-6.3-0.7-9.6
		C208.6,1811.7,209.4,1809.5,209.8,1807.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M333,1971.9c-3-2-7.9-2.8-10.5-0.5c-1.1-1.1-2.4-2.1-3.7-2.9c-1.9-1-4.2-0.9-6.1-1.6
		c-3.3-1.2-8.2-3.7-11.5-1.1c-2.4,1.8-4.1,4.2-4.8,7c-0.4,0.3-0.7,0.9-0.7,1.4c-0.4,3.2-2.1,5.9-2.3,9.2c-0.2,3,0,5.9,0,8.9
		c0,1.9-0.6,4.8,0.4,6.6c2.3,3.9,5.7,6.5,8.3,10.1c2.4,3.3,0.5,8.5,0.7,12.3c0.2,3.3,2.3,5.4,4.8,7.1c4.7,3.2,10.5,1.2,13.4-3.4
		c1.8-2.9,3.1-5.9,4-9.2c1.3-4.3-1.4-10.9,1.6-14.3c2.3-2.7,5.6-4.4,8.2-6.9s3.6-6.2,4.3-9.5C340.2,1979.5,337.6,1974.8,333,1971.9z
		"/>
            <path :fill="colors[`${id}-p-1`]" d="M300.7,2132.3c-0.1-3.8-0.6-7.5-0.5-11.3c0.1-3.2,0.5-6.4-0.6-9.5c-1.9-5.2-11.5-8-16.4-6.8
		c-4.9,1.3-7.8,5.6-10,10.2c-4.3,3.7-8,9.9-7.5,14.9c0.7,6.3,3.4,12.7,6,18.4c1.7,3.7,4.4,8.9,9.3,7.7c5.7-1.6,11-4.4,15.4-8.4
		C300.5,2144,300.8,2137.3,300.7,2132.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M368.3,2138.3c-11.1-8.6-20.2,4.3-30.1,9.2c-0.8-0.8-2.1-0.7-2.9,0.1c-0.2,0.2-0.4,0.5-0.5,0.8
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.9,0.2-1.8,0.4-2.7,0.5c-1.3,0.2-2.2,1.3-2,2.6v4.6c-0.1,1,0.7,1.9,1.7,2c0.2,0,0.3,0,0.5,0
		c-0.6,4-0.3,7.9,1.7,11.5c3.8,6.5,13.2,6.5,19.5,8.7c2.5,0.9,4.6,4.2,6.7,5.7c1.6,1.2,3.6,1.7,5.4,1.5c5.4-0.5,7.8-9,8.7-13.2
		C376.3,2163.1,376.9,2145,368.3,2138.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M612.5,329.4c-0.3-1.2-1-2.2-1.9-3.1c-2.1-2.3-1.9-4.6-3-7.3c-1.4-3.3-2-5.6-1-9.2c0.6-2.2,1.5-4,1.4-6.2
		c-0.3-6.8-9.3-9.4-14.6-9.9c-5.7-0.5-13.3,0.9-17.2,5.4c-3.7,4.3-2.4,9.1,0.4,13.2c-1.4,1.5-3.3,2.9-4.9,4.7
		c-3.7,4.3-5.5,10.4-6.3,15.8c-0.8,5.5-2.6,19.7,6.3,20.5c3.7,0.3,7.4-0.8,10.4-3.1c2.8-1.9,5-4.3,8.6-4.3c2.7,0,7.2,1,9.7-0.2
		c2.7-1.3,7-4.2,8.8-6.6C610.5,336.9,613.1,332.4,612.5,329.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M580.6,478c-2.7-10.7-2.2-25.7-13.6-31.4c-12.1-6-21.2,4.3-25.9,14.7c-1.7,2-3.7,3.7-5.3,5.7
		c-2.8,3.5-3.4,7.9-3.1,12.3c0.4,6.6,5.6,11.9,7.1,18.2c1.5,6.3-4.3,12.4-2.7,19.1c0.1,1,0.9,1.9,1.9,2c14.1,1.8,21.6-8.7,31.6-16.5
		c4.3-3.3,8.6-6.5,10.5-11.7C582.6,486.4,581.6,482,580.6,478z"/>
            <path :fill="colors[`${id}-p-1`]" d="M656.9,534.9c-3.8-4.4-8.1-8.4-10.8-13.7c-2.3-4.4-3.1-9.9-4.7-14.5c-3.5-9.9-11.5-13.1-21.5-13.3
		c-4.2-0.1-8.6,0-12.1,2.6c-3.3,2.5-3,8.9-0.5,11.7c1.8,2.1,4.1,3.8,6.4,5c2.3,1.3,5.6,1.9,7.4,3.9c4.4,4.7,7.2,12.7,7.7,19.2
		c-0.5,3.8-0.7,7.7,1,11.1c2,4.1,4.8,7.6,8.5,10.4c5.9,4.4,15.6,7.3,21.7,1.6C667.6,551.6,662.1,541.1,656.9,534.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M466.1,1017.3c-1.2-0.9-4.6-1.3-5-2.9c-0.9-2.8-0.7-5.4-1.6-8.2c-2.4-7.5-13.3-11.7-20-13.3
		c-8.1-2-13.5-1-18.3,5.9c-1.6,2.4-3,4.8-4.4,7.4c-0.9,0.2-1.6,1-1.5,1.9c0,0.2,0,0.5,0,0.7c-0.2,0.4-0.5,0.8-0.7,1.3
		c-0.6,1-1.8,2-1.6,3.3c0.2,0.9,0.7,1.7,1.2,2.5c-1.2,3.1-3.5,5.3-7.7,7.8c-4,2.3-4,6.8-3.3,10.9s3.8,9.7,7.6,11.5
		c2,0.9,4.1,1.4,6.2,1.6c3.3,0.4,6.5,1,9.7,1.8c6.9,1.6,13.9,3.1,20.9,4.7c4,1,8.4,1.4,12-1.1c3.4-2.4,5-5.8,6.2-9.6
		c0.8-2.6,1.4-5.1,1.9-7.7c0.5-2.5,2.5-4.4,2.8-7C471.1,1024.4,469.4,1020.1,466.1,1017.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M448.9,1072.7c-1.3-4.5-4.2-8.6-8.1-11.2c-12.3-7.2-24.4,2.3-35.7,6.9c-6.6,2.8-11.8-0.9-17.2-4.2
		c-5-2.9-10.8-4.4-16.5-4.3c-8.8,0-15.1,5.7-18.5,13.1c-1.2,1.1-1.9,2.5-2.2,4.1c-0.6,4.4-0.6,8,3.3,10.8c3.7,2.6,9,3.9,13.3,4.4
		c6.1,0.7,12-1.6,18.2-0.4c2.9,0.6,5.5,2.6,8,3.9c2.6,1.1,5.3,1.6,8.1,1.5c5.8,0,11.2-2.2,16.6-4c9.2-3.1,23,0.9,30.4-6.6
		C451.8,1083.4,450,1076.4,448.9,1072.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1664.3,2044.3c-0.7-4.1-3.3-7.3-5.7-10.4c-4.1-4.9-8.2-8.5-10.4-14.8c-2.5-8.1-4.4-16.4-5.7-24.8
		c-1.3-7-4.3-16.7-0.3-23.2c1.8-3,4.8-5.1,5.2-8.8c0.4-4-5.3-6.5-7.8-8.3c-4.2-3.1-13.3-8.8-18.8-7.2c-2.4,0.6-4.3,2.4-4.8,4.7
		c-4.9,0.7-10.5,5.4-9.9,10.2c0.8,6.2,6.4,9.6,9.4,14.6c3.6,5.8,1.9,12.2,3.1,18.5c0.7,2.4,1.7,4.5,3.1,6.5
		c1.8,3.1,1.9,7.2,1.2,10.6c-1,5.4-2.8,9.4,0.2,14.5c1.5,2.5,3.4,4.7,5.5,6.8c3.4,3.4,3.5,6,3.5,10.6c0,3.5-0.6,5,2,7.3
		c2.6,2.2,5.6,3.8,8.9,4.5C1649.4,2057.3,1666,2054.2,1664.3,2044.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1497.8,955.4c-4.6-10.9-18.8-4.7-23.8,2.8c-0.9-0.6-2.1-0.3-2.7,0.6c-0.1,0.1-0.1,0.2-0.2,0.3
		c-4.4,8.7-7.1,16.6-7.2,26.4c0,1.1,0.6,2,1.6,2.3c5.9,1.5,4.4,6.1,4.8,10.9c0.4,3.6,3.6,5.4,6.9,5.5c8.7,0.1,11.3-13.5,13.2-19.7
		C1493.1,975.8,1501.9,964.8,1497.8,955.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1511.5,1053.9c-2.6-1.6-4.7-2.9-6.6-5.3c-2.4-3.2-0.6-7.3-0.2-10.8c0.5-4.4-2-9.6-6.9-9.7
		c-3.6,0-6.6-4.8-8.9-7.1s-8.4-1.7-11.3-1.9c-3.8-0.3-8.4-0.4-11.5,2.2c-3.4,2.9-5.1,7.8-6.5,11.9c-0.3,1-0.7,2.1-1,3.3
		c-1,2-1.5,4.2-1.4,6.3c0.2,2.6,1.9,4.8,2.2,7.3c0.3,2.3,0,4.6,1.6,6.6c0.7,0.9-3,3.9-3.5,4.9c-0.7,1.7-0.5,3.7,0.5,5.2
		c2.3,3.8,6.4,6.2,10.6,7.7c2,0.6,4.1,0.6,5.9,0c1.1-0.3,2.2-0.6,3.3-1.1c2.6-1,4.6,0.2,6.9,1.2c6.7,2.8,18.5,4.7,25.1,0.8
		C1517.5,1071.1,1519.7,1059.1,1511.5,1053.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1382.8,1040.1c0.7-0.8,1.3-1.6,2-2.4c-0.4,1.5-0.8,3.2-1.3,4.8c-0.3,1.2,0.4,2.5,1.7,2.8l0,0
		c0.2,0.3,0.4,0.6,0.5,0.9c-1.5,1-2,2.7-2.2,4.7c-0.2,1.9-1.3,4-1.8,5.7c-0.2,0.8-0.4,1.7-0.4,2.5c-1.1,0-2,0.7-2.3,1.7
		c-0.2,1.2-0.6,2.3-1.1,3.3C1375.3,1056.9,1377.7,1046.5,1382.8,1040.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1439.7,1047.8c1.3-2.8,1.4-5.9,0.2-8.8c-0.9-1.8-2-3.5-3.2-5c-1.3-2-1.2-6.2-1.9-8.5
		c-2.5-8-8.9-13.3-17.4-13.3c-1.4,0-2.8,0.2-4.2,0.5c-0.1-1.1-1-1.9-2.1-1.9c0,0,0,0-0.1,0c-5.5,0.3-10.6,3.6-13,8.6
		c-2,1.3-4,2.8-5.7,4.2c-6.8,5.1-12.5,11.6-16.6,19c-6.5,12.1-4.3,28.9,7.5,33.7c0.1,0.4,0.2,0.9,0.2,1.3c0,1.3,1,2.3,2.3,2.3
		c11.1,0.2,22.2,4.3,33.3,1.8c6.3-1.5,10.5-5.9,13.2-11.4C1437.6,1064,1440.3,1055.9,1439.7,1047.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1830.9,1027.4c-4.1-6.6,1.2-11,4.9-15.4c3.2-3.8,3.4-7.1,2.3-11.9c-1-3.8-3.6-6.9-7.1-8.6
		c-4.5-2.2-7.5-0.1-11.9,0.8c-1.4-1.9-2.3-3.8-4.8-4.3c-2.5-0.6-4.8-2.3-7.1-3.3c-4.3-1.8-9-3.5-13.5-3.3c-3.9,0.2-5.7,1.8-6.2,4.2
		c-4.4,3.9-6,11.7-6,16.8c0,1.7,0.2,3.4,0.8,4.9c1,2.5,3.5,3.2,3.9,6.1c0.4,3.3,0.5,5.1-1,8.1c-1,2.1-1.5,4.4-1.3,6.8
		c0.4,10.8,10.5,5,14.8,12.2c2.5,4.2,0.7,9,4.1,13.1c3.2,3.8,6.4,4.8,11.3,4.4c7.4-0.7,19-1.5,23.9-7.9
		C1843.4,1043.1,1834.4,1033.2,1830.9,1027.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2134.3,965.7c-3-6.3-11.9-6.6-17.7-8.6s-13.6-8.3-20.2-5.6c-7.5,3.1-11.2,9.1-11.5,16.7
		c-0.3,1.6-0.4,3.2-0.3,4.7c0.4,5.2,5.7,10.2,10.8,10.9c3.1,0.4,5.7-4.5,8.9-2.2c2.6,2,4.6,3.5,7.9,3.9c3.2,0.3,7-2.2,10-3.1
		c3.1-0.7,6-1.6,8.9-2.9C2136.7,976.9,2136.6,970.6,2134.3,965.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1906.3,776.7c-3.7-3.2-3.4-5.9-3.3-10.2c-0.2-2.4-1.4-4.5-3.3-5.9c-4.2-3-10.4-0.6-14.9-0.3s-9.7,7.1-11,11.1
		c-1,2.9-1.1,6-0.1,8.9c-0.5,2.5-0.3,5,0.6,7.4c1.7,4.4,6.2,6.8,10.4,8.6c-0.1,8.6,10.7,11.2,17.1,5.4c3.9-3.8,7.1-8,9.6-12.9
		C1914.2,783.6,1910.1,779.8,1906.3,776.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1983.7,771.1c-0.3-1-1.2-1.8-2.3-1.8h-7.8c-3.7-6.6-9.7-12.7-17.9-12c-6.4,0.6-9.1,6.5-10.3,12.8
		c-3.3,3.9-7.7,10-5.4,13.5c2.9,4.6,12.1,5.1,16.9,5.9c6.5,1,12.4,1.6,18.6-1.3C1982.4,785.2,1984.6,778,1983.7,771.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1944.7,779.6c0,0.4,0,0.8,0,1.2s0.1,0.8,0.3,1.2C1944.1,781.4,1944.2,780.6,1944.7,779.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2131,799.9c-0.5-1.6-1.5-2.9-2.8-3.9c-1.6-1.4-4.8-6.5-3.7-8.9c1.8-3.7,3.2-7.5,2.6-11.7
		c-0.8-5.7-8.6-8.6-13.5-9.1c-6.1-0.6-12.2,0.1-18.3,0.5c-1.9-0.6-3-1.9-4.7-3.2c-2.8-1.8-5.8-2.9-9.1-3.4
		c-5.9-0.7-10.2,2.9-10.2,8.8c0,8.7-3.4,28.8,9,28.3c0.3,3.6,3,5.6,7.3,6.2c2.8,0.5,5.6,0.6,8.4,0.1c1.9-0.5,3.8-1.2,5.6-1.7
		c2.5-0.8,2.7-0.5,3.5,1.6c1.2,3,4.3,3.3,6.9,4.5c2.7,1.2,5.8,3,8.9,2.3C2124.5,809.6,2132.2,804.5,2131,799.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2190.5,807.1c-2.4-1.9-5.1-2.7-7.9-3.7c-2.3-0.7-4.1-2.3-4.9-4.4c-1.7-3.5,0.8-4.3,1.2-6.8
		c1.6-8.6-10.3-6.6-15-6.6c-5,0-7.6,2.3-8.8,5.6c-6.1,9.7-6.5,23.8-0.6,33.6c8,13.2,25.8,8.4,35.3-0.4
		C2194.4,819.9,2196.2,811.7,2190.5,807.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2148.8,841.3c-1.9-7.7-9-16.1-17.1-17c-3.5-0.4-7.9,1.2-9.9,4.2c-0.6,1-1.1,2-1.4,3.1
		c-1.4-0.4-2.8,0.4-3.1,1.8c-0.1,0.2-0.1,0.4-0.1,0.6c-0.3,10.7-3.7,20,0.1,30.5c0.4,1,1.3,1.6,2.3,1.6c5.1,0.3,9.2,4.2,14.3,4.4
		c4.3,0.3,7.1-2.8,9.4-6.1C2147.6,858.1,2150.5,848.8,2148.8,841.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2195.4,869.4c0-8.2,0.5-19.1-5.2-25.8c-0.9-0.9-2.4-0.9-3.3,0c-0.1,0.1-0.2,0.2-0.3,0.3
		c-5-5.4-13.4-4-18.3,2.2c-2.6,3.5-4.3,7.5-4.8,11.8c-1.2,6.6,3.8,16.7-3.3,21c-4.1,2.2-7.3,5.6-9.2,9.9c-0.8,1.6-1.3,3.3-2.2,4.7
		c-1.6,2.7-6.1,2.3-8.7,2.3c-1,0-2,0.7-2.3,1.7c-1.9,9-12.5,13.3-13.1,22.4c-0.3,4.2,3.1,9,6.2,11.3c6.5,4.6,15.1,4.4,21.2,9.9
		c0.7,0.8,1.9,0.9,2.8,0.4c4.9-2.9,8.1-8,13.6-10.1c4.4-1.6,9.5-2.1,13.1-5.2c5.1-4.5-0.8-12.8,3.8-18.1c3.3-3.8,7.4-5.4,10-10
		C2200.4,889.9,2195.4,878.3,2195.4,869.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1655.3,869.4c-1.2-4.4-4.4-7.7-9-8c-6.2-0.4-11,5.2-13.8,10c-2.7,4.4-5.1,8.9-2,13.6c0.9,1.3,1.9,2.5,3.2,3.5
		c0.5,4.4,2.9,7.9,7.7,8c0,0.1,0.1,0.1,0.1,0.2c0.5,1.2,1.8,1.8,3,1.3c0.9-0.4,1.5-1.3,1.5-2.3C1654,892,1657.2,876.8,1655.3,869.4z
		"/>
            <path :fill="colors[`${id}-p-1`]" d="M2188.5,598.2c-2.8-4.4-9.4-5-13.7-7.1c-6.5-3.3-11.4-6.3-15.5-12.6c-4.2-6.2-6.3-13.6-7.6-20.9
		s0.4-16.5-5.7-21.7c-3.1-2.6-7.8-3.2-11.7-3.4s-9.6-1-12.7,2.3c-2.9,3.1-6.7,7.4-7.8,11.5c-1.4,5-0.1,10.4-1.2,15.5
		c-0.3,1.3,0.5,2.5,1.8,2.7c0.2,0,0.4,0.1,0.6,0c3.6,0,11-0.5,11.4,4.7c0.2,2.1,3.2,3.6,4.6,4.5c1.6,1,3.1,2.2,4.4,3.6
		c0.5,0.9,1,1.9,1.5,2.8c0.2,0.4,0.3,0.8,0.4,1.3c1.2,4.6,1.7,9.2,3.1,13.7c0.3,1.1,1.5,1.8,2.6,1.5c0.2,0,0.3-0.1,0.5-0.2
		c1,3.3,1.8,6.6,2.9,9.9c3.1,9.5,8.3,20.4,18.6,23.4c11.8,3.5,21.7-8,25.3-17.7C2191.8,607.6,2191.2,602.3,2188.5,598.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2116.9,1468.8c-2.1-4-4.8-10.8-9.6-12l-7.8-2c-3-0.8-5.1-3.4-8.3-3.7c-4.7-0.5-8.8,3.9-11.1,8.6
		c-5.3,1.4-7,6-6.5,12.1c0.5,6.2,7.3,12,11.8,15.5c4.4,3.6,9.9,7.4,15.7,6.2c6-1.3,13.1-6.2,17.2-10.7
		C2121.8,1479,2118.8,1472.6,2116.9,1468.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2057.5,1447c-4.8-7.6-13.6-9.2-22.1-8.2c-8.7,1-15.3,4.4-18.7,12.5c-1.1,2.6-1.6,5.3-1.4,8.1
		c-0.9,1.5-1.6,3.2-2,4.8c-1.7,8.5,3.5,16.1,10.7,20.2c15.2,8.8,30.8-3.5,35.2-18C2061.1,1460.3,2061.1,1452.6,2057.5,1447z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2045.5,1403.5c-1.3-1.5-2.7-2.8-4.3-4c-2.9-2.5-2.4-5.6-1.9-9c0.4-2.5,1.1-4.9,1.6-7.3c0.3-1.3-0.6-4-0.1-4.9
		c1.3-2.7-2.3-5.5-4-6.8c-3.3-2.6-9.2-2.1-13.2-3.2s-6.7-0.5-8.4,1.3c-2.6,0.5-5,1.8-6.9,3.7c-5.7,6.4-1.8,17.6-1.8,25.3
		c0,2.5,0.1,4.8,2.1,6.5c1.2,1,2.6,2.8,4,3.4c4,1.6,6.9-1.2,9.9,3.9c3.7,6.3,6.7,11.5,14.8,10.5
		C2046.6,1421.8,2050.6,1410.2,2045.5,1403.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1971.2,1526.9c-0.6-5.4-3.2-10.5-8.3-13c-4.7-2.3-10.9-0.9-15.8,0.2c-5.5,1.3-12,3.4-13.4,9.5
		c-1.1,4.4,0.8,8.2,3.9,11c-1.2,6.9,5.4,13.2,13.9,14.4C1964.2,1551.1,1972.6,1539,1971.2,1526.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M841.5,1806.9c-4.1-1.2-5.1-4.3-7.5-7.3c-2.5-3.1-6-4-9.7-4.4c-2-0.2-4-0.1-5.8,0.2c-0.7-0.3-1.6-0.2-2.2,0.2
		l-1.4,0.9c-0.9,0.4-1.8,1-2.5,1.6c-2.6,1.5-4.4,3.9-5.5,6.6c-1.7,5.1-0.1,8.9,2.8,12.8c-2.3,1.1-4.8,1.8-5.6,4.4
		c-1.2,4-2.9,7.6-2.3,11.9c1,6.7,10.1,10.6,16.1,9.8c13.7-1.9,20.6-23,24.9-33.7C843.3,1808.6,842.7,1807.3,841.5,1806.9
		L841.5,1806.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1142,1978.6c-0.7-4.8-4.8-6.9-9.2-7.5c-6.5-1.1-14.7-1.2-21,1.2c-6.7,2.6-6.3,9.4-5.2,15.2
		c0.4,2.6,1.9,4.7,4.1,6c-0.5,2.8-0.4,5.5,0.1,8.2c1.2,4.9,5.3,6.8,10.1,5.7C1134.3,2004.5,1144.1,1992.8,1142,1978.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1334.6,2092.6c-4.9-3.9-12.6-5.9-18.2-2.1c-3.2,2.2-4.4,5.4-4.9,9c-2.1,1.1-3.6,3.4-4.7,5.1
		c-3.6,5.6-2.3,11.4,1.9,16c3.7,4.1,7.8,10.6,14.2,9.5c5.7-1,12.5-5.9,17.2-9.1c4-2.6,4.3-6.9,4.3-11.3
		C1344.5,2102.3,1340.5,2097.3,1334.6,2092.6z"/>
            <path :fill="colors[`${id}-p-1`]" d="M599.2,125.5c0.1-1.3-0.9-2.4-2.1-2.5c-6.4-0.4-10.6-6.1-15.7-9.3c-3.6-2.1-7.8-2.7-11.9-1.7
		c-7.6,1.6-18,6.6-16.3,15.9c-0.9,1-1.7,2.2-2.2,3.5c-4.2,9.8,4.2,15.4,6.8,24.1c0.3,1.2,1.5,2,2.7,1.9c3.1-0.2,6-1.4,8.4-3.5
		c2.2,2.6,4.7,4.1,8.3,2.9c4.2-1.4,5.5-6.6,9.1-8.8c3.9-2.4,11.2-1.1,13.2-6.5C601.2,136.5,599.4,130.7,599.2,125.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M562,203.3c14.5-13.3-14.1-43.2-26.9-19.4c-0.4,0.7-0.4,1.5-0.1,2.2c-1.6,1.6-3,3.5-3.9,5.4
		c-1.7,4.1-2.9,8.4-3.6,12.8c-1.3,8,3.7,12.6,10.9,15.3c3.8,1.4,7.7,2.1,11.8,2.3c2.8,0.2,6.6,1.2,8.9-0.8c3-2.6,4.8-7.7,5.5-11.5
		C565,207.3,564,204.8,562,203.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M580.1,239.8c-4.9-1.4-14-0.7-18.4,2.5c-2.3,1.6-1.7,4.1-0.8,6.3c1,2.6,3.5,4.1,6.2,4.8
		c0.4,8,2.4,14.5,10.6,19c0.8,0.3,1.7,0.2,2.3-0.3c5.4-4.2,6.3-12.3,6.3-18.6C586.2,248.7,585.6,241.4,580.1,239.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M753.2,111c-3.4-1.1-7.1-0.5-10,1.4c-0.9-0.1-1.8,0.4-2.2,1.2c-0.2,0.4-0.4,0.7-0.6,1.2
		c-2.7,3.1-4.8,6.5-6.3,10.4c-0.5,1.2,0,2.5,1.2,3.1c0.1,0.1,0.2,0.1,0.4,0.1c0.4,4.6,3.9,8.4,7.4,11.1c7,5.4,17.8,2.6,22.2-5
		C770.3,125.2,762.6,113.7,753.2,111z"/>
        </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>