export let fonts = [
    {
        name : "Audiowide",
        value: "'Audiowide', cursive"
    },
    {
        name : "Black Han Sans",
        value: "'Black Han Sans', sans-serif"
    },
    {
        name : "Faster One",
        value: "'Faster One', cursive"
    },
    {
        name : "Monoton",
        value: "'Monoton', cursive"
    }
];