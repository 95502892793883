<template>
    <pattern :id="id" x="0" y="0" width="100" height="200" viewBox="0 0 100 200" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect x="0" y="0" :fill="colors[`${id}-p-0`]" width="100" height="100"/>
        <rect x="0" y="100" :fill="colors[`${id}-p-1`]" width="100" height="100"/>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>