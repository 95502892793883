<template>
    <pattern :id="id" x="0" y="0" width="2300" height="3500" viewBox="0 0 2300 3500" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect :fill="colors[`${id}-p-0`]" width="2300" height="3500"/>
        <g>
            <defs>
                <rect id="SVGID_1_" width="2300" height="3500"/>
            </defs>
            <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
            </clipPath>
            <polygon :fill="colors[`${id}-p-1`]" points="2269.2,571.8 2437.7,608.8 2309.9,739.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="243,627.4 37,501.4 265.7,387.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="775.8,540.8 589.2,694 556.1,440.6 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="286.6,745.2 193.7,900.5 106.2,729.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="651.1,874.2 502.3,770.8 678.6,695.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="458,636.4 276.3,477.4 521.2,403.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="372.1,757 259.3,658.2 411.4,612.2 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="304.5,1075.5 244.2,1008.2 338.6,991.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="433.1,1019.1 452.7,945.4 508,1004.2 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="394.1,794.8 437.2,720.5 479.8,801 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="4.8,1051.2 72.6,924.4 148.6,1056.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="189.8,1011.8 248,1004 222.3,1060.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="23.5,815.3 96.2,769.7 96.2,860.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="630.8,948.9 689.4,886.2 712.8,974.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="484.1,866.4 569.8,860.4 527.2,940.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="579.1,1054.8 614.3,953 686,1041.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="314.6,804.8 417.1,878 293.7,929.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="168.1,675.4 320.5,574.2 326,768 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="437,532.3 605.4,569.2 477.7,700 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="171.5,545.9 261.3,505.5 247.2,608.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="493.9,521.2 583.5,480.7 569.6,584 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="505.2,348 397.1,552.8 273.5,340.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="533.4,363.8 642.4,445.7 507.6,497.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="436.2,912.5 381.3,998.9 334.5,901.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="437.1,636 390.8,708.9 351,626.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="744.9,455.4 615.1,385.9 750.2,308.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="334.5,850.3 251.1,886.2 265.6,791.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="571.2,968.4 523.5,1043.2 482.9,958.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="642.3,725.7 611.2,658.5 689.1,668.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="705,872.3 673.9,805 751.8,814.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="544.6,686.4 595.1,681.8 570.9,729.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="247.4,984 298.1,979.4 273.7,1027.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="261.3,478.5 312,473.8 287.7,521.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="116.9,332.6 167.4,328 143.3,376.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="57.4,730.8 107.9,726.3 83.7,774.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="452.8,857.5 367.1,768.4 495.2,741.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="151.5,783.9 182.4,716.5 225.5,782.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="643.6,1043.8 659.4,971.5 715.7,1026.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="112.2,383.4 280.5,420.4 153,551.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="37,837.5 205.5,874.4 77.8,1005.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="742.8,464.4 711.6,633.9 576.7,510.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="620.2,750.2 642.4,921.2 476.4,845.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-24.4,572 144.1,609 16.3,739.6 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1026.5,627.4 820.5,501.4 1049.2,387.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1559.2,540.8 1372.6,694 1339.6,440.6 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1070.1,745.2 977.2,900.5 889.7,729.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1434.6,874.2 1285.8,770.8 1462.1,695.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1241.5,636.4 1059.8,477.4 1304.6,403.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1155.5,757 1042.8,658.2 1194.8,612.2 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1097.4,1038.1 1008.7,1020.1 1074.7,950.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1216.6,1019.1 1236.2,945.4 1291.4,1004.2 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1177.6,794.8 1220.7,720.5 1263.3,801 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="788.9,1068.4 767.1,926.3 906.9,986.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="906.9,1002.4 965.1,994.6 939.4,1051.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="806.9,815.3 879.7,769.7 879.7,860.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="733,751.7 805.7,706.1 805.7,797.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1414.2,948.9 1472.9,886.2 1496.3,974.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1267.6,866.4 1353.3,860.4 1310.7,940.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1362.6,1054.8 1397.8,953 1469.5,1041.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1098,804.8 1200.6,878 1077.1,929.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="951.6,675.4 1104,574.2 1109.5,768 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1220.5,532.3 1388.9,569.2 1261.2,700 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="955,545.9 1044.8,505.5 1030.7,608.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1277.4,521.2 1367,480.7 1353,584 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1304.4,372.4 1160.5,553.9 1078.1,322.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1316.9,363.8 1425.9,445.7 1291.1,497.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1219.6,912.5 1164.7,998.9 1118,901.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1220.6,636 1174.3,708.9 1134.5,626.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="912.2,465.4 803.6,565 775,411.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1552.6,375.2 1444.2,474.9 1415.4,321.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1118,850.3 1034.6,886.2 1049.1,791.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1354.7,968.4 1307,1043.2 1266.4,958.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1425.8,725.7 1394.6,658.5 1472.6,668.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1488.5,872.3 1457.3,805 1535.3,814.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1328,686.4 1378.6,681.8 1354.3,729.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1005.4,964.4 1056.1,959.8 1031.7,1007.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1044.8,478.5 1095.4,473.8 1071.2,521.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="865.5,334.5 882.6,382.4 829.9,371 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="840.8,730.8 891.4,726.3 867.2,774.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1236.3,857.5 1150.5,768.4 1278.7,741.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="934.9,783.9 965.9,716.5 1009,782.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1418.2,1018.2 1449.1,950.9 1492.4,1016.4 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="895.6,383.4 1064,420.4 936.5,551.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="787.7,837.5 956.2,874.4 828.5,1005.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1526.3,464.4 1495.1,633.9 1360.2,510.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1403.7,750.2 1425.9,921.2 1259.9,845.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="741.7,599 910.2,636 782.4,766.6 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1803.4,627.4 1597.4,501.4 1826.1,387.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2405.7,391.8 2300.5,609 2164.4,392.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="104.8,391.8 -0.4,609 -136.5,392.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1847,745.2 1754.1,900.5 1666.6,729.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2236.4,838.9 2087.6,735.5 2263.9,660 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2018.5,636.4 1836.7,477.4 2081.6,403.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1932.5,757 1819.7,658.2 1971.8,612.2 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1841,1028.3 1752.3,1010.3 1818.2,940.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1993.5,1019.1 2013.1,945.4 2068.4,1004.2 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1954.6,794.8 1997.7,720.5 2040.2,801 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1519.8,978.7 1655.9,932.3 1621.4,1080.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1683.8,1002.4 1742,994.6 1716.3,1051.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1583.9,815.3 1656.7,769.7 1656.7,860.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1509.9,751.7 1582.7,706.1 1582.7,797.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2201.2,948.9 2259.9,886.2 2283.3,974.1 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2044.5,866.4 2130.2,860.4 2087.6,940.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2152.2,533 2229.7,570 2153.1,618.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2149.5,1054.8 2184.7,953 2256.4,1041.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1875,804.8 1977.5,878 1854.1,929.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1728.6,675.4 1880.9,574.2 1886.4,768 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1997.4,532.3 2165.8,569.2 2038.2,700 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1731.9,545.9 1821.7,505.5 1807.6,608.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2054.3,521.2 2144,480.7 2130,584 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2068.6,351.8 1954.2,553.2 1837.2,337.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2115.4,320.6 2224.5,402.5 2089.6,454.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1996.6,912.5 1941.7,998.9 1894.9,901.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1997.6,636 1951.2,708.9 1911.5,626.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1689.1,465.4 1580.5,565 1551.9,411.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1894.9,850.3 1811.5,886.2 1826,791.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2131.6,968.4 2084,1043.2 2043.4,958.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2246.4,725.7 2215.2,658.5 2293.1,668.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2265.8,818.2 2339.9,816.2 2300.2,883.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-34,818.2 40.1,816.2 0.4,883.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2105,686.4 2155.5,681.8 2131.3,729.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1748.9,954.6 1799.6,950 1775.2,998.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1821.7,478.5 1872.4,473.8 1848.1,521.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1600.8,350.8 1648.8,334.6 1636.6,387 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1617.8,730.8 1668.4,726.3 1644.1,774.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2013.3,857.5 1927.5,768.4 2055.6,741.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1711.9,783.9 1742.9,716.5 1786,782.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2205.2,1018.2 2236,950.9 2279.4,1016.4 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1702.7,361.4 1851.4,448.7 1689.6,533.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1564.6,837.5 1733.1,874.4 1605.4,1005.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2205.6,714.9 2227.8,885.9 2061.8,810 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1518.7,599 1687.2,636 1559.4,766.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="0,331.4 77.2,456.9 -78.6,456 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2300.8,331.4 2378,456.9 2222.2,456 	"/>
        </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>