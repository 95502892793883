<template>
    <pattern :id="id" x="0" y="0" width="905.1" height="987" viewBox="0 0 905.1 987" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect x="-0.1" y="0.3" :fill="colors[`${id}-p-0`]" width="904.8" height="987.2"/>
        <path :fill="colors[`${id}-p-0`]" d="M492.3,427.2c-23.2,0-42.1,18.8-42.1,42.1l0,0c0,23.2,18.8,42.1,42.1,42.1l0,0c23.2,0,42.1-18.8,42.1-42.1l0,0
        C534.4,446,515.6,427.2,492.3,427.2L492.3,427.2z"/>
        <path :fill="colors[`${id}-p-0`]" d="M605.1,202.5c21.9,0,39.9-17.9,39.9-39.9c0-10.9-4.4-20.9-11.7-28.2s-17.3-11.7-28.2-11.7
        c-21.9,0-39.9,17.9-39.9,39.9c0,10.9,4.4,20.9,11.7,28.2C584.1,198.1,594.1,202.5,605.1,202.5z"/>
        <path :fill="colors[`${id}-p-0`]" d="M605.1,940.8c23.2,0,42.1-18.8,42.1-42.1h-42.1V940.8L605.1,940.8z"/>
        <circle :fill="colors[`${id}-p-0`]" cx="379.8" cy="653.3" r="39.9"/>
        <path :fill="colors[`${id}-p-0`]" d="M563,898.7L563,898.7h42.1v-42.1C581.8,856.5,563,875.5,563,898.7z"/>
        <path :fill="colors[`${id}-p-0`]" d="M903.5,346.7L903.5,346.7c0,3.5,0.4,6.7,1.2,9.8v-19.6C903.9,340,903.5,343.3,903.5,346.7z"/>
        <path :fill="colors[`${id}-p-0`]" d="M266.6,346.7l1-56.5c-14.8-0.2-29.6,5.2-40.9,16.5L266.6,346.7z"/>
        <path :fill="colors[`${id}-p-0`]" d="M904.7,508.1v-77.6C884.1,452.2,884.1,486.4,904.7,508.1z"/>
        <path :fill="colors[`${id}-p-0`]" d="M1,509.2l39.9-39.9L1,429.4c-0.4,0.4-0.8,0.8-1.2,1.2v77.6C0.3,508.5,0.6,508.8,1,509.2z"/>
        <path :fill="colors[`${id}-p-0`]" d="M-0.1,356.5c4.4,18.4,21.1,32.3,40.9,32.3l0,0c23.2,0,42.1-18.8,42.1-42.1l0,0c0-23.2-18.8-42.1-42.1-42.1l0,0
        c-19.8,0-36.5,13.8-40.9,32.3V356.5L-0.1,356.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M671.7,855.9c-2.3,0-4.8,0-7.1,0c1.7,5,4,9.6,7.1,13.8C671.7,864.9,671.7,860.5,671.7,855.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M701.1,891.2c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C687.4,885.5,694,888.9,701.1,891.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M661.7,833.4c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8
        C666.7,833.4,664.2,833.4,661.7,833.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M701.1,783.5c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C701.1,794.8,701.1,789.3,701.1,783.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M697,859.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C697,867.6,697,863.4,697,859.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M681.5,830.9c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C694.6,830.9,688,830.9,681.5,830.9z
        "/>
        <path :fill="colors[`${id}-p-1`]" d="M684,823.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C684,813.5,684,818.3,684,823.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M532.1,141.2c10.9-10.9,16.5-25.5,16.5-39.9h-16.5c0,10.2-3.8,20.4-11.7,28.2c-15.6,15.6-40.9,15.6-56.5,0
        c-7.7-7.7-11.7-18.1-11.7-28.2h-16.5c0,14.4,5.6,28.8,16.5,39.9C474.3,163.3,510.2,163.3,532.1,141.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M647.3,898.7c0-23.2-18.8-42.1-42.1-42.1l0,0v42.1H647.3L647.3,898.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M605.1,653.3v42.1l0,0c23.2,0,42.1-18.8,42.1-42.1H605.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M675.7,224h-14.2c0,31.1,25.4,56.5,56.5,56.5v-14.4C694.7,266.1,675.7,247.3,675.7,224z"/>
        <path :fill="colors[`${id}-p-1`]" d="M671.1,806c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6C671.5,806,671.3,806,671.1,806z"/>
        <path :fill="colors[`${id}-p-1`]" d="M548.6,346.7c0-31.1-25.4-56.5-56.5-56.5l0,0v56.5v56.5l0,0C523.4,403,548.6,377.9,548.6,346.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M605.1,372.9L605.1,372.9c9.6,0,18.4,4,24.8,10.4l15-15c-10.2-10.2-24.4-16.5-39.9-16.5
        c-31.1,0-56.5,25.4-56.5,56.5c0,15.6,6.3,29.6,16.5,39.9l15-15c-6.3-6.3-10.4-15.2-10.4-24.8C569.9,388.6,585.7,372.9,605.1,372.9z"
        />
        <path :fill="colors[`${id}-p-1`]" d="M709.5,806c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C722.4,806,716.1,806,709.5,806z"/>
        <path :fill="colors[`${id}-p-1`]" d="M754.3,859.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C754.3,867.6,754.3,863.4,754.3,859.2z
        "/>
        <path :fill="colors[`${id}-p-1`]" d="M661.5,714.7c14,0,35.3,0,56.5,0c21.1,0,42.4,0,56.5,0C773.1,640.6,662.9,640.6,661.5,714.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M738.9,889.7c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C738.9,883.7,738.9,886.8,738.9,889.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M766.8,809c0,5.6,0,10.9,0,16.5c2.1,0,4.2,0,6.3,0C772,819.8,769.6,814.2,766.8,809z"/>
        <path :fill="colors[`${id}-p-1`]" d="M766.8,865.7c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C766.8,859.2,766.8,862.4,766.8,865.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M774.4,837.5c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0
        C774.1,844.6,774.4,840.9,774.4,837.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M758.5,830.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C758.5,844,758.5,837.5,758.5,830.9
        z"/>
        <path :fill="colors[`${id}-p-1`]" d="M379.4,229c-31.1,0-56.5,25.4-56.5,56.5c0,15.6,6.3,29.6,16.5,39.9l11.7-11.7c-7.3-7.3-11.7-17.3-11.7-28.2
        c0-21.9,17.9-39.9,39.9-39.9c10.9,0,20.9,4.4,28.2,11.7l11.7-11.7C409.2,235.4,394.9,229,379.4,229z"/>
        <path :fill="colors[`${id}-p-1`]" d="M726.6,833.4c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C726.6,843,726.6,838.2,726.6,833.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M758.5,798.1c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C758.5,799.8,758.5,799,758.5,798.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M711.8,893.5c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C711.8,886.6,711.8,890.1,711.8,893.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M725.7,783.7c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C725.9,792.3,725.7,788.1,725.7,783.7z
        "/>
        <path :fill="colors[`${id}-p-1`]" d="M741.4,823.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C741.2,813.5,741.4,818.3,741.4,823.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M728.9,855.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C728.9,868.8,728.9,862.4,728.9,855.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M802.7,804.2c7.3,7.3,17.3,11.7,28.2,11.7c21.9,0,39.9-17.9,39.9-39.9c0-10.9-4.4-20.9-11.7-28.2
        s-17.3-11.7-28.2-11.7C809,736.1,791,754,791,776C791,786.9,795.4,796.9,802.7,804.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M757.7,926c-10.2-10.2-24.4-16.5-39.9-16.5c-31.1,0-56.5,25.4-56.5,56.5c0,7.7,1.5,14.8,4.2,21.5h30.7
        L757.7,926z"/>
        <path :fill="colors[`${id}-p-1`]" d="M774.4,530.7c0,7.1,1.3,14,3.8,20.4v-40.7C775.8,516.7,774.4,523.4,774.4,530.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M76.5,922.2c-4.4-3.5-9.2-6.5-14.4-8.6v73.8h14.4V922.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M19.7,987.5v-73.8c-5.2,2.1-10.2,5-14.4,8.6v65.3L19.7,987.5L19.7,987.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M351.1,870.5c-15.6,15.6-15.6,40.9,0,56.5c7.7,7.7,18.1,11.7,28.2,11.7c10.2,0,20.4-3.8,28.2-11.7
        c15.6-15.6,15.6-40.9,0-56.5c-7.7-7.7-18.1-11.7-28.2-11.7S359,862.8,351.1,870.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M809.6,478.5c-5.2,2.1-10.2,5-14.4,8.6v87.4c4.4,3.5,9.2,6.5,14.4,8.6V478.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M32.3,910.1c-2.1,0.4-4,0.8-6,1.2v76.1h6V910.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M866.4,486.9c-4.4-3.5-9.2-6.5-14.4-8.6V583c5.2-2.1,10.2-5,14.4-8.6V486.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M893,986.3v-40.7c-2.5,6.3-3.8,13.3-3.8,20.4S890.6,980,893,986.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M678,18.5l18.4-18.4h-30.7C668.6,7.2,672.8,13.3,678,18.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M883.3,551.1c2.5-6.3,3.8-13.3,3.8-20.4c0-7.1-1.3-14-3.8-20.4V551.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M839.4,474.8v111.6c2.1-0.4,4-0.8,6-1.2v-109C843.2,475.6,841.3,475.2,839.4,474.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M19.7,0.3H5.2v22.2c4.4,3.5,9.2,6.5,14.4,8.6L19.7,0.3L19.7,0.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M816.3,476.2v109.1c1.9,0.6,3.8,1,6,1.2V474.8C820.4,475.2,818.2,475.6,816.3,476.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M55.2,911.4c-1.9-0.6-3.8-1-6-1.2v77.2h6V911.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M113.8,613.5L113.8,613.5l39.9,39.9l39.9-39.9C171.6,591.4,135.8,591.4,113.8,613.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M266.6,280.5V224h-56.5C210.2,255.1,235.5,280.5,266.6,280.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M113.8,693.2c22.1,22.1,57.8,22.1,79.7,0l-39.9-39.9L113.8,693.2L113.8,693.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M322.9,224c0-31.1-25.4-56.5-56.5-56.5l0,0V224H322.9L322.9,224z"/>
        <path :fill="colors[`${id}-p-1`]" d="M266.6,552.1c10.9,0,20.9,4.4,28.2,11.7l11.7-11.7c-10.1-10.2-24.3-16.6-39.9-16.6c-31.1,0-56.5,25.4-56.5,56.5
        c0,15.6,6.3,29.6,16.5,39.9l11.7-11.7c-7.3-7.3-11.7-17.3-11.7-28.2C226.7,570.1,244.6,552.1,266.6,552.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M308.7,714.7h-42.1v42.1l0,0C289.7,756.8,308.7,738,308.7,714.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M97.2,965.9c0-7.1-1.3-14-3.8-20.4v40.7C95.9,980,97.2,973.1,97.2,965.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M76.5,0.3H62.1V31c5.2-2.1,10.2-5,14.4-8.6V0.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M32.3,0.3h-6v33c1.9,0.6,3.8,1,6,1.2V0.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M55.2,0.3h-6v34.2c2.1-0.4,4-0.8,6-1.2V0.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M182.7,133.8l10.8-10.8c-10.2-10.2-24.4-16.5-39.9-16.5c-31.1,0-56.5,25.4-56.5,56.5c0,15.6,6.3,29.6,16.5,39.9
        l10.8-10.8c7.5,7.5,17.7,12.1,29,12.1c22.7,0,41.1-18.4,41.1-41.1C194.8,151.4,190.2,141,182.7,133.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M153.7,250.1L153.7,250.1c9.6,0,18.4,4,24.8,10.4l15-15c-10.2-10.2-24.4-16.5-39.9-16.5
        c-31.1,0-56.5,25.4-56.5,56.5c0,15.6,6.3,29.6,16.5,39.9l15.4-15.2c-6.3-6.3-10.4-15.2-10.4-24.8
        C118.6,266.1,134.3,250.1,153.7,250.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M113.8,938.7c22.1,22.1,57.8,22.1,79.7,0l-39.9-39.9L113.8,938.7L113.8,938.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M266.6,224v56.5l0,0c31.1,0,56.5-25.4,56.5-56.5H266.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M210.2,101.3c0,31.1,25.4,56.5,56.5,56.5v-14.3c-23.2,0-42.1-18.8-42.1-42.1L210.2,101.3L210.2,101.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M249.7,16.4c-6.5,0-12.9,0-19.2,0c5.6,4.6,12.1,8.3,19.2,10.4C249.7,23.3,249.7,19.9,249.7,16.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M275.1,28.7c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0c0,3.5,0,6.9,0,10.4c1.9,0.2,4,0.4,6.1,0.4
        C269.5,29.5,272.4,29.1,275.1,28.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M210.2,224L210.2,224h56.5v-56.5C235.5,167.6,210.2,192.9,210.2,224z"/>
        <path :fill="colors[`${id}-p-2`]" d="M193.7,938.7c22.1-22.1,22.1-57.8,0-79.7l0,0l-39.9,39.9L193.7,938.7L193.7,938.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M220.4,928.9c-0.2,0-0.4,0-0.6,0c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0
        C220.4,941.7,220.4,935.4,220.4,928.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M206.3,510.4v40.7c2.5-6.3,3.8-13.3,3.8-20.4C210.2,523.4,208.8,516.7,206.3,510.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M322.9,101.3c0-31.1-25.4-56.5-56.5-56.5l0,0V59l0,0c23.2,0,42.1,18.8,42.1,42.1l0,0L322.9,101.3L322.9,101.3z"
        />
        <path :fill="colors[`${id}-p-2`]" d="M306.4,552.1l-11.7,11.7c7.3,7.3,11.7,17.3,11.7,28.2c0,21.9-17.9,39.9-39.9,39.9c-10.9,0-20.9-4.4-28.2-11.7
        l-11.7,11.7c10.2,10.2,24.4,16.5,39.9,16.5c31.1,0,56.5-25.4,56.5-56.5C322.9,576.4,316.6,562.4,306.4,552.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M210.2,960.2c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8c-2.5,0-5,0-7.7,0
        C210.2,957.5,210.2,958.8,210.2,960.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M306.4,386.5L306.4,386.5l-39.8-39.8l-39.9-39.9l0,0c-22.1,22.1-22.1,57.8,0,79.7
        C248.6,408.6,284.5,408.6,306.4,386.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M9.3,54.6c0.8-0.6,1.5-1,2.3-1.5C10.8,53.7,10,54,9.3,54.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M14.5,51.4c-0.6,0.4-1.2,0.6-1.7,1C13.3,52.1,13.9,51.7,14.5,51.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M15.8,50.8c-0.4,0.2-0.8,0.4-1.2,0.6C15,51.2,15.4,51,15.8,50.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M73.4,147.4c0.4-0.2,0.8-0.6,1.2-0.8c0.8-0.6,1.3-1.2,2.1-1.7c1.3-1.2,2.7-2.3,4-3.6c2.3-2.3,4.2-4.6,6.1-7.3
        c6.5-9.2,10.4-20.6,10.4-32.7c0-31.1-25.4-56.5-56.5-56.5c-9,0-17.5,2.1-25,6c7.5-3.8,16.1-6,25-6c15.6,0,29.6,6.3,39.9,16.5
        L68.9,73c7.3,7.3,11.7,17.3,11.7,28.2c0,21.9-17.9,39.9-39.9,39.9c-10.9,0-20.9-4.4-28.2-11.7L1,141.1l0,0
        c10.2,10.2,24.4,16.5,39.9,16.5C53.1,157.8,64.2,153.9,73.4,147.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M2.9,59.6C3.5,59,4.2,58.4,5,57.9C4.3,58.5,3.5,59,2.9,59.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M80.7,429.5l-39.9,40l39.9,39.9l0,0C102.8,487.3,102.8,451.4,80.7,429.5L80.7,429.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M287.6,16.4c0,3.1,0,6,0,9c5.6-2.3,10.6-5.2,15.2-9C297.6,16.4,292.6,16.4,287.6,16.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M220.4,978.6c-2.3,0-4.8,0-7.1,0c1.2,3.1,2.3,6,3.8,8.8h3.1C220.4,984.4,220.4,981.5,220.4,978.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M258,11c6.5,0,12.9,0,19.6,0c0-3.6,0-7.1,0-10.8H258C258,3.7,258,7.4,258,11z"/>
        <path :fill="colors[`${id}-p-2`]" d="M232.6,7.8c4.4,0,8.6,0,13.1,0c0-2.5,0-5,0-7.5h-13.1C232.6,2.6,232.6,5.1,232.6,7.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M289.9,7.8c4.4,0,8.6,0,13.1,0c0-2.5,0-5,0-7.5h-13.1C289.9,2.6,289.9,5.1,289.9,7.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M105.1,747.4l77.2,77.2c1.7-1,3.5-2.1,5-3.3l-78.9-78.9C107.2,743.9,106.1,745.7,105.1,747.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M220.4,5.3c0-1.7,0-3.3,0-5h-3.1C218.1,2,219.2,3.5,220.4,5.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M6,56.9c0.8-0.6,1.3-1.2,2.1-1.5C7.6,56,6.8,56.4,6,56.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M139.3,476.2v109.1c1.9,0.6,3.8,1,6,1.2V474.8C143.1,475.2,141.2,475.6,139.3,476.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M289.9,946c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C289.9,936.2,289.9,941,289.9,946z"/>
        <path :fill="colors[`${id}-p-2`]" d="M322.9,714.7c0-31.1-25.4-56.5-56.5-56.5l0,0v14.3l0,0c23.2,0,42.1,18.8,42.1,42.1l0,0L322.9,714.7L322.9,714.7
        z"/>
        <path :fill="colors[`${id}-p-2`]" d="M287.6,973.2c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C287.6,960.4,287.6,966.7,287.6,973.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M307,920.8c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C307,922.5,307,921.8,307,920.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M277.6,928.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C277.6,941.7,277.6,935.4,277.6,928.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M260.5,970.7c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C260.5,961.1,260.5,965.9,260.5,970.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M274.3,906.4c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C274.3,915.1,274.3,910.8,274.3,906.4z
        "/>
        <path :fill="colors[`${id}-p-2`]" d="M224.4,714.7L224.4,714.7h42v-42.1C243.4,672.5,224.4,691.5,224.4,714.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M302.9,981.9c-4.4,0-8.6,0-13.1,0c0,1.9,0,3.6,0,5.6h13.1C302.9,985.5,302.9,983.8,302.9,981.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M322,970.7c0.6-3.5,1-7.1,1-10.8c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6
        C319.3,970.7,320.6,970.7,322,970.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M232.6,981.9c0,1.9,0,3.6,0,5.6h13.1c0-1.9,0-3.6,0-5.6C241.1,981.9,236.9,981.9,232.6,981.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M266.6,771.2V757c-23.2,0-42.1-18.8-42.1-42.1h-14.1C210.2,745.8,235.5,771.2,266.6,771.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M315.4,948.3c2.1,0,4.2,0,6.3,0c-1.3-6-3.5-11.3-6.3-16.5C315.4,937.5,315.4,942.9,315.4,948.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M209.8,781.4l-61.5-61.5c-5.8,0.6-11.1,1.9-16.3,4l73.8,73.8C207.9,792.7,209.4,787.1,209.8,781.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M189.4,486.9c-4.4-3.5-9.2-6.5-14.4-8.6V583c5.2-2.1,10.2-5,14.4-8.6V486.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M277.6,978.6c-6.5,0-13.1,0-19.6,0c0,2.9,0,6,0,8.8h19.6C277.6,984.4,277.6,981.5,277.6,978.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M132.4,478.5c-5.2,2.1-10.2,5-14.4,8.6v87.4c4.4,3.5,9.2,6.5,14.4,8.6V478.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M202.7,804.2l-77-77c-1.7,1-3.5,2.1-5,3.3l78.7,78.7C200.6,807.7,201.5,806,202.7,804.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M162.2,474.8v111.6c2.1-0.4,4-0.8,6-1.2v-109C166.2,475.6,164.3,475.2,162.2,474.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M177.3,724.9l27.7,27.7C199.2,740.3,189.4,730.5,177.3,724.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M97.2,530.7c0,7.1,1.3,14,3.8,20.4v-40.7C98.6,516.7,97.2,523.4,97.2,530.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M176,827.9l-74.1-74.1c-2.1,5-3.6,10.6-4.2,16.1l62.2,62.2C165.4,831.5,171,830.2,176,827.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M230.2,973.2c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C230.2,960.4,230.2,966.7,230.2,973.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M232.6,946c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C232.6,936.2,232.6,941,232.6,946z"/>
        <path :fill="colors[`${id}-p-2`]" d="M153.7,320.4L153.7,320.4c-9.6,0-18.4-4-24.8-10.4l-15,15c10.2,10.2,24.4,16.5,39.9,16.5
        c31.1,0,56.5-25.4,56.5-56.5c0-15.6-6.3-29.6-16.5-39.9l-15,15c6.3,6.3,10.4,15.2,10.4,24.8C188.9,304.7,173.1,320.4,153.7,320.4z"
        />
        <path :fill="colors[`${id}-p-2`]" d="M249.7,906.2c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C249.7,917.7,249.7,912,249.7,906.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M131.6,828l-30-30C107.4,811.5,118.2,822.3,131.6,828z"/>
        <path :fill="colors[`${id}-p-2`]" d="M870.7,736.2L859,747.9c7.3,7.3,11.7,17.3,11.7,28.2c0,21.9-17.9,39.9-39.9,39.9c-10.9,0-20.9-4.4-28.2-11.7
        L790.9,816c10.2,10.2,24.4,16.5,39.9,16.5c31.1,0,56.5-25.4,56.5-56.5C887.2,760.4,880.9,746.4,870.7,736.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M703.6,292.2v109.1c1.9,0.6,3.8,1,6,1.2V290.9C707.4,291.2,705.5,291.6,703.6,292.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M682.3,303v87.4c4.4,3.5,9.2,6.5,14.4,8.6V294.5C691.5,296.6,686.7,299.5,682.3,303z"/>
        <path :fill="colors[`${id}-p-2`]" d="M718,413L718,413v56.5h56.4C774.4,438.2,749.1,413,718,413z"/>
        <path :fill="colors[`${id}-p-2`]" d="M661.5,469.3c0,31.1,25.4,56.5,56.5,56.5v-56.5H661.5L661.5,469.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M726.4,290.9v111.6c2.1-0.4,4-0.8,6-1.2V292.2C730.5,291.6,728.5,291.2,726.4,290.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M739.1,294.3v104.5c5.2-2.1,10.2-5,14.4-8.6v-87.4C749.3,299.5,744.5,296.6,739.1,294.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M597,709.2c-1.7-0.2-3.5-0.6-5-1C593.7,708.6,595.4,709,597,709.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M851.7,969.2c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0c0-1,0-1.7,0-2.7C865.9,976.7,859.1,972.1,851.7,969.2z"
        />
        <path :fill="colors[`${id}-p-2`]" d="M814,985c0-5.8,0-11.5,0-17.3c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3C801,985,807.3,985,814,985z"/>
        <path :fill="colors[`${id}-p-2`]" d="M825.5,980.9c4.4,0,8.6,0,13.1,0c0-4.4,0-8.6,0-13.1c-4.4,0-8.6,0-13.1,0C825.5,972.3,825.5,976.3,825.5,980.9z
        "/>
        <path :fill="colors[`${id}-p-2`]" d="M757.2,755.3L718,714.8c-21.1,0-42.4,0-56.5,0C659.8,763.3,723.9,789.8,757.2,755.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M661.5,898.7h-14.2c0,23.2-18.8,42.1-42.1,42.1l0,0V955l0,0C636.2,955.2,661.5,929.8,661.5,898.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M770.6,326.4v40.7c2.5-6.3,3.8-13.3,3.8-20.4C774.4,339.4,773.1,332.5,770.6,326.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M605.1,832.5V776l-40.5,39.2C574.9,825.7,589.1,832.5,605.1,832.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M605.1,695.5v-42H563C563,676.5,581.8,695.5,605.1,695.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M605.1,709.7c1.5,0,2.9,0,4.4-0.2c1,0,1.9-0.2,2.9-0.4c2.3-0.4,4.6-0.8,6.9-1.3c0.8-0.2,1.5-0.4,2.3-0.6
        c0.2,0,0.4-0.2,0.6-0.2c0.8-0.2,1.5-0.6,2.3-0.8c1.3-0.4,2.5-1,3.8-1.5c1.3-0.6,2.5-1.2,3.6-1.9c0.6-0.4,1.2-0.8,1.9-1
        c6.1-3.6,11.5-8.5,15.7-14c2.1-2.9,4-5.8,5.8-9l0,0c1.3-2.5,2.3-5,3.3-7.7c0.6-1.5,1-3.3,1.3-4.8c0.4-1.7,0.8-3.3,1-5
        c0-0.6,0.2-1.2,0.2-1.7c0.2-1,0.2-2.1,0.2-3.3s0-2.1,0-3.3h-14.2c0,23.2-18.8,42.1-42.1,42.1l0,0L605.1,709.7L605.1,709.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M605.1,443.2v-35.1V373l0,0c-19.4,0-35.1,15.7-35.1,35.1c0,9.6,4,18.4,10.4,24.8
        C586.6,439.1,595.4,443.2,605.1,443.2L605.1,443.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M591,598.7c1.9-0.6,3.8-1,5.8-1.2C594.9,597.8,593,598.2,591,598.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M882,30.2c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0c0.6-3.5,1-7.1,1-10.8c0-1.3,0-2.7-0.2-4
        C885.5,30.2,883.7,30.2,882,30.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M315.4,1.2c0.2-0.4,0.4-0.6,0.6-1h-0.6C315.4,0.5,315.4,0.8,315.4,1.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M684.6,146.8c1.7,1.2,3.3,2.3,5,3.3l77.2-77.2c-1-1.7-2.1-3.5-3.3-5L684.6,146.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M769.6,124.1l-28.8,28.8c6.1-2.7,12.1-6.7,17.1-11.7C762.9,136.2,766.8,130.3,769.6,124.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M695.9,153.3c5.2,2.1,10.8,3.6,16.3,4.2L774,95.7c-0.6-5.6-1.9-10.9-4.2-16.3L695.9,153.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M669.2,129.7c1,1.7,2.1,3.5,3.3,5l78.9-78.9c-1.7-1.2-3.3-2.3-5-3.3L669.2,129.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M665.4,367.1v-40.7c-2.5,6.3-3.8,13.3-3.8,20.4C661.5,353.9,662.9,360.8,665.4,367.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M718,167.6L718,167.6v14.2l0,0c23.2,0,42.1,18.8,42.1,42.1l0,0h14.2l0,0C774.4,192.9,749.1,167.6,718,167.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M868.9,5.5c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C868.9,15.2,868.9,10.3,868.9,5.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M783.9,3c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6C784.2,3,784.1,3,783.9,3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M784.4,66.5c0-4.6,0-9.2,0-13.8c-2.3,0-4.8,0-7.1,0C779.3,57.7,781.6,62.3,784.4,66.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M879.7,6c0,5.6,0,10.9,0,16.5c2.1,0,4.2,0,6.3,0C884.7,16.6,882.6,11.2,879.7,6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M774.4,34.3c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8c-2.5,0-5,0-7.7,0
        C774.4,31.6,774.4,32.9,774.4,34.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M830.7,709.7c31.1,0,56.5-25.4,56.5-56.5s-25.4-56.5-56.5-56.5s-56.5,25.4-56.5,56.5
        C774.4,684.4,799.6,709.7,830.7,709.7z M830.7,613.5c21.9,0,39.9,17.9,39.9,39.9c0,21.9-17.9,39.9-39.9,39.9
        c-21.9,0-39.9-17.9-39.9-39.9C791,631.4,808.8,613.5,830.7,613.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M665.9,123.4l73.9-73.9c-5.2-2.1-10.8-3.6-16.3-4.2l-61.8,61.8C662.5,112.6,663.8,118.2,665.9,123.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M452.4,61.5c-11,11-16.6,25.6-16.6,40h16.6c0-10.2,3.8-20.4,11.7-28.2c15.6-15.6,40.9-15.6,56.5,0
        c7.7,7.7,11.7,18.1,11.7,28.2h16.5c0-14.4-5.6-28.8-16.5-39.9C510.2,39.4,474.3,39.4,452.4,61.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M548.6,469.3L548.6,469.3c0-31.1-25.4-56.5-56.5-56.5l0,0V427l0,0c23.2,0,42.1,18.8,42.1,42.1l0,0h14.4V469.3z"
        />
        <path :fill="colors[`${id}-p-2`]" d="M379.4,858.8v-16.5c-14.4,0-28.8,5.6-39.9,16.5c-22.1,22.1-22.1,57.8,0,79.7c10.9,10.9,25.5,16.5,39.9,16.5
        v-16.5c-10.2,0-20.4-3.8-28.2-11.7c-15.6-15.6-15.6-40.9,0-56.5C359,862.8,369.2,858.8,379.4,858.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M794.4,47.5c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0C794.4,34.6,794.4,41,794.4,47.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M492.3,525.7v-14.2c-23.2,0-42.1-18.8-42.1-42.1H436C435.8,500.4,461.2,525.7,492.3,525.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M605.1,202.5c-10.9,0-20.9-4.4-28.2-11.7l-11.8,11.7c10.2,10.2,24.4,16.5,39.9,16.5c31.1,0,56.5-25.4,56.5-56.5
        c0-15.6-6.3-29.6-16.5-39.9l-11.7,11.7c7.3,7.3,11.7,17.3,11.7,28.2C645,184.6,627.1,202.5,605.1,202.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M550.9,637.9c-0.4,1.2-0.6,2.3-0.8,3.5C550.3,640.2,550.5,639.1,550.9,637.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M666.3,78.6l28.8-28.8C689,52.5,683,56.5,678,61.5S669,72.3,666.3,78.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M854.2,69c4.4,0,8.6,0,13.1,0c0-4.4,0-8.6,0-13.1c-4.4,0-8.6,0-13.1,0C854.2,60.6,854.2,64.6,854.2,69z"/>
        <path :fill="colors[`${id}-p-2`]" d="M319.8,978.6c-1.5,0-3.1,0-4.4,0c0,2.9,0,6,0,8.8h0.6C317.5,984.6,318.9,981.7,319.8,978.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M811.5,5.5c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C811.5,15.2,811.5,10.3,811.5,5.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M851.7,86.7c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C851.7,80.7,851.7,83.8,851.7,86.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M824.8,90.3c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C824.8,83.6,824.8,87.1,824.8,90.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M879.7,62.5c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C879.7,56.2,879.7,59.4,879.7,62.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M851.7,47.5c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0C851.7,34.6,851.7,41,851.7,47.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M814,88.2c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C800.4,82.5,806.9,85.9,814,88.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M810,56c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C810,64.6,810,60.4,810,56z"/>
        <path :fill="colors[`${id}-p-2`]" d="M841.9,3c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C841.9,16,841.9,9.5,841.9,3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M824.8,45c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C824.8,35.2,824.8,40,824.8,45z"/>
        <path :fill="colors[`${id}-p-2`]" d="M822.3,72.3c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0C822.3,59.4,822.3,65.8,822.3,72.3z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M306.4,306.8c-10.8-10.8-24.8-16.3-39-16.5l-1,56.5l39.9,39.9C328.5,364.6,328.5,328.7,306.4,306.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M322.9,469.3c0-31.1-25.4-56.5-56.5-56.5l0,0v56.5H322.9L322.9,469.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M308.7,101.3L308.7,101.3c0-23.2-18.8-42.1-42.1-42.1l0,0V45c-31.1,0-56.5,25.4-56.5,56.5l0,0h14.3
        c0,23.2,18.8,42.1,42.1,42.1v14.2l0,0c31.1,0,56.5-25.4,56.5-56.5H308.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M400.3,460.1c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C400.3,454.1,400.3,457.2,400.3,460.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M195.8,40c0,23.2-18.8,42.1-42.1,42.1l0,0v14.2l0,0c31.1,0,56.5-25.4,56.5-56.5h-14.4V40z"/>
        <path :fill="colors[`${id}-p-3`]" d="M266.6,525.7v-56.5h-56.5C210.2,500.4,235.5,525.7,266.6,525.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M97.6,401.9l62.2,62.2c5.8-0.6,11.1-2.1,16.1-4.2l-74.1-74.1C99.7,390.7,98.2,396.3,97.6,401.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M435.8,224c0,7.1,1.3,14,3.8,20.4v-40.7C437.2,210,435.8,216.9,435.8,224z"/>
        <path :fill="colors[`${id}-p-3`]" d="M435.8,469.3H450l0,0c0-23.2,18.8-42.1,42.1-42.1V413C461.2,413,435.8,438.2,435.8,469.3L435.8,469.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M492.3,403v-56.3l-40.7,39.2C462,396.5,476.4,403,492.3,403z"/>
        <path :fill="colors[`${id}-p-3`]" d="M325.2,48.7h109.1c0.6-1.9,1-3.8,1.2-6H323.9C324.3,44.6,324.6,46.6,325.2,48.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M330.8,418.4c0-5,0-9.8,0-14.8c-2.5,0-5,0-7.5,0c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8
        C326.2,418.4,328.5,418.4,330.8,418.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M325.2,19.7c-0.6,1.9-1,3.8-1.2,6h111.6c-0.4-2.1-0.8-4-1.2-6H325.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M193.7,693.2c22.1-22.1,22.1-57.8,0-79.7l0,0l-40,40L193.7,693.2L193.7,693.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M308.7,714.7L308.7,714.7c0-23.2-18.8-42.1-42.1-42.1l0,0v42.1H308.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M113.8,613.5c-22.1,22.1-22.1,57.8,0,79.7l39.9-39.9L113.8,613.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M210.2,714.7L210.2,714.7h14.2l0,0c0-23.2,18.8-42.1,42.1-42.1v-14.1C235.5,658.3,210.2,683.6,210.2,714.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M400.1,968.8c-6.3-2.5-13.3-3.8-20.4-3.8s-14,1.3-20.4,3.8H400.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M379.8,597c-31.1,0-56.5,25.4-56.5,56.5s25.4,56.5,56.5,56.5s56.5-25.4,56.5-56.5
        C436,622.2,410.9,597,379.8,597z M379.8,693.2c-21.9,0-39.9-17.9-39.9-39.9c0-21.9,17.9-39.9,39.9-39.9c21.9,0,39.9,17.9,39.9,39.9
        C419.5,675.4,401.7,693.2,379.8,693.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M120.5,362.5l78.7,78.7c1.2-1.7,2.3-3.3,3.3-5l-77-77C123.9,360.2,122.2,361.2,120.5,362.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M118.6,285.3c0,9.6,4,18.4,10.4,24.8c6.3,6.3,15.2,10.4,24.8,10.4l0,0v-35.1v-35.1l0,0
        C134.3,250.1,118.6,266.1,118.6,285.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M113.8,938.7l39.9-39.9l-39.9-39.9C91.9,880.9,91.9,916.6,113.8,938.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M105.1,379.4l77.2,77.2c1.7-1,3.5-2.1,5-3.3l-78.9-78.9C107.2,375.9,106.1,377.7,105.1,379.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M177.3,356.7l27.7,27.7C199.2,372.3,189.4,362.5,177.3,356.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M132,356l73.8,73.8c2.1-5.2,3.5-10.6,4-16.3L148.3,352C142.6,352.3,137.2,353.9,132,356z"/>
        <path :fill="colors[`${id}-p-3`]" d="M419.3,285.3c0-10.9-4.4-20.9-11.7-28.2c-7.3-7.3-17.3-11.7-28.2-11.7c-21.9,0-39.9,17.9-39.9,39.9
        c0,10.9,4.4,20.9,11.7,28.2c7.3,7.3,17.3,11.7,28.2,11.7C401.5,325.2,419.3,307.4,419.3,285.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M343.1,401.3c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6
        C355.9,401.3,349.6,401.3,343.1,401.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M388,403.8c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C388,413.4,388,408.6,388,403.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M419.3,858.8c-10.9-10.9-25.5-16.5-39.9-16.5v16.5c10.2,0,20.4,3.8,28.2,11.7c15.6,15.6,15.6,40.9,0,56.5
        c-7.7,7.7-18.1,11.7-28.2,11.7v16.5c14.4,0,28.8-5.6,39.9-16.5C441.2,916.6,441.2,880.9,419.3,858.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M362.5,461.6c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C349,455.8,355.4,459.3,362.5,461.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M370.9,376.3c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6
        C383.8,376.3,377.5,376.3,370.9,376.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M387.3,354c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C387.3,362.7,387.3,358.5,387.3,354z"/>
        <path :fill="colors[`${id}-p-3`]" d="M358.4,429.5c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C358.4,438,358.4,433.7,358.4,429.5z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M419.9,368.4c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C419.9,370.2,419.9,369.4,419.9,368.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M402.8,393.6c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C402.8,383.8,402.8,388.6,402.8,393.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M415.9,429.5c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C415.9,438,415.9,433.7,415.9,429.5z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M345.4,393.6c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C345.4,383.8,345.4,388.6,345.4,393.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M362.5,353.9c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C362.5,365.2,362.5,359.6,362.5,353.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M400.1,86.7h-40.7c6.3,2.5,13.3,3.8,20.4,3.8S393.8,89.2,400.1,86.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M472,662.1h40.7c-6.3-2.5-13.3-3.8-20.4-3.8C485,658.3,478.1,659.6,472,662.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M373.4,463.9c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C373.4,457,373.4,460.4,373.4,463.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M541.1,825.6c2.1,0,4.2,0,6.3,0c-1.3-6-3.5-11.3-6.3-16.5C541.1,814.6,541.1,820.2,541.1,825.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M436.4,723.2c0.4,2.1,0.8,4,1.2,6h109.1c0.6-1.9,1-3.8,1.2-6H436.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M513.4,171.8v104.5c5.2-2.1,10.2-5,14.4-8.6v-87.4C523.6,176.8,518.6,173.9,513.4,171.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M477.9,169.5v109.1c1.9,0.6,3.8,1,6,1.2V168.1C481.7,168.5,479.8,168.9,477.9,169.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M492.5,535.5c-31.1,0-56.5,25.4-56.5,56.5l0,0c0,31.1,25.4,56.5,56.5,56.5l0,0c31.1,0,56.5-25.4,56.5-56.5l0,0
        C549,560.9,523.6,535.5,492.5,535.5L492.5,535.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M500.8,168.3v111.6c2.1-0.4,4-0.8,6-1.2v-109C504.8,168.9,502.7,168.5,500.8,168.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M390.5,426.3c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C390.5,439.1,390.5,432.8,390.5,426.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M430.7,403.8c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0c0.6-3.5,1-7.1,1-10.8c0-1.3,0-2.7-0.2-4
        C433.9,403.8,432.4,403.8,430.7,403.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M456.6,180.2v87.4c4.4,3.5,9.2,6.5,14.4,8.6V171.8C465.8,173.9,461,176.8,456.6,180.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M546.9,700.3H437.8c-0.6,1.9-1,3.8-1.2,6h111.6C547.8,704.2,547.2,702.2,546.9,700.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M439.9,693.4h104.5c-2.1-5.2-5-10.2-8.6-14.4h-87.4C445.1,683.4,442.2,688.2,439.9,693.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M131.6,460.1l-30-30C107.4,443.5,118.2,454.3,131.6,460.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M814,339.4c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C800.4,333.7,806.9,337.1,814,339.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M796.9,271.3c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C796.9,261.7,796.9,266.5,796.9,271.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M794.4,298.7c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C794.4,285.9,794.4,292.2,794.4,298.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M814,231.7c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0C814,243,814,237.5,814,231.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M887.2,162.8c0-31.1-25.4-56.5-56.5-56.5l0,0v56.5H887.2L887.2,162.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M830.7,219v-56.5h-56.5C774.4,193.9,799.6,219,830.7,219z"/>
        <path :fill="colors[`${id}-p-3`]" d="M887.2,285.5c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0
        C886.8,292.8,887.2,289.1,887.2,285.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M824.8,296.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C824.8,286.4,824.8,291.2,824.8,296.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M822.3,323.5c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0C822.3,310.6,822.3,317,822.3,323.5
        z"/>
        <path :fill="colors[`${id}-p-3`]" d="M838.6,231.9c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C838.6,240.3,838.6,236.1,838.6,231.9z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M822.3,273.8c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C822.3,260.9,822.3,267.2,822.3,273.8z"/>
        <ellipse :fill="colors[`${id}-p-3`]" cx="830.7" cy="408" rx="39.9" ry="39.9"/>
        <path :fill="colors[`${id}-p-3`]" d="M824.8,341.6c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C824.8,334.8,824.8,338.3,824.8,341.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M810,307.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C810,315.8,810,311.6,810,307.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M893.7,245.9l10.9-10.9v-20.4l-15.2,15.2C890.1,235.4,891.4,240.7,893.7,245.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M889.3,833.4c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8
        C894.3,833.4,891.8,833.4,889.3,833.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M879.7,257.2c0,5.6,0,10.9,0,16.5c2.1,0,4.2,0,6.3,0C884.7,267.8,882.6,262.4,879.7,257.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M458.3,823.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C458.3,813.5,458.3,818.3,458.3,823.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1,141.2l11.7-11.7C5.4,122.2,1,112.2,1,101.3c0-21.9,17.9-39.9,39.9-39.9c10.9,0,20.9,4.4,28.2,11.7l11.7-11.7
        C70.6,51.2,56.4,44.9,40.9,44.9C25.4,44.8,11.4,51.2,1,61.3c-0.4,0.4-0.8,0.8-1.2,1.2v77.6C0.3,140.5,0.6,140.9,1,141.2L1,141.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M541.9,987.5c4.6-8.1,7.1-17.5,7.1-27.5c0-31.1-25.4-56.5-56.5-56.5S436,928.9,436,960c0,10,2.5,19.2,7.1,27.5
        h20.4c-6.7-7.1-10.9-16.7-10.9-27.5c0-21.9,17.9-39.9,39.9-39.9c21.9,0,39.9,17.9,39.9,39.9c0,10.6-4.2,20.2-10.9,27.5H541.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M492.5,29.3c21.1,0,39.8-11.7,49.4-29h-20.4c-7.3,7.7-17.5,12.5-29,12.5c-11.3,0-21.7-4.8-29-12.5h-20.4
        C452.9,17.6,471.4,29.3,492.5,29.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M897.4,72.1c-5.2,8.5-8.3,18.6-8.3,29.2c0,15,6,28.6,15.4,38.8V62.7C902,65.6,899.5,68.6,897.4,72.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M899.3,855.9c-2.3,0-4.8,0-7.1,0c1.7,5,4,9.6,7.1,13.8C899.3,864.9,899.3,860.5,899.3,855.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M879.7,313.7c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C879.7,307.4,879.7,310.6,879.7,313.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M894.1,201.2l10.6-10.6v-5.4C900.1,190,896.6,195.4,894.1,201.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M893,694.4c-2.5,6.3-3.8,13.3-3.8,20.4s1.3,14,3.8,20.4V694.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M904.7,337v-29c-9.6,10.2-15.4,23.8-15.4,38.8l0,0c0,15,6,28.6,15.4,38.8v-29c-0.8-3.1-1.2-6.3-1.2-9.8l0,0
        C903.5,343.3,903.9,340,904.7,337z"/>
        <path :fill="colors[`${id}-p-3`]" d="M503.3,855.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C503.3,868.8,503.3,862.4,503.3,855.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M483.7,806c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C496.7,806,490.2,806,483.7,806z"/>
        <path :fill="colors[`${id}-p-3`]" d="M501,833.4c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C501,843,501,838.2,501,833.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M486.2,893.5c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C486.2,886.6,486.2,890.1,486.2,893.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M515.6,823.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C515.6,813.5,515.6,818.3,515.6,823.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M532.6,798.1c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C532.6,799.8,532.6,799,532.6,798.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M544.6,735.9H440.1c2.1,5.2,5,10.2,8.6,14.4h87.4C539.6,746,542.4,741.2,544.6,735.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M455.8,830.9c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6
        C468.9,830.9,462.4,830.9,455.8,830.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M471.4,859.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C471.4,867.6,471.4,863.4,471.4,859.2z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M475.4,783.5c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C475.4,794.8,475.4,789.3,475.4,783.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M500,783.7c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C500,792.3,500,788.1,500,783.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M472,767.4c6.3,2.5,13.3,3.8,20.4,3.8s14-1.3,20.4-3.8H472z"/>
        <path :fill="colors[`${id}-p-3`]" d="M475.4,891.2c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C461.8,885.5,468.3,888.9,475.4,891.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M532.6,830.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C532.6,844,532.6,837.5,532.6,830.9
        z"/>
        <path :fill="colors[`${id}-p-3`]" d="M854.2,271.3c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C854.2,261.7,854.2,266.5,854.2,271.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M851.7,337.9c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C851.7,332,851.7,335,851.7,337.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M661.5,469.3H718v-56.5C686.9,413,661.5,438.2,661.5,469.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M871.2,246.3c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C871.2,248,871.2,247.1,871.2,246.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M871.2,279.1c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C871.2,292,871.2,285.7,871.2,279.1
        z"/>
        <path :fill="colors[`${id}-p-3`]" d="M867.2,307.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C867.2,315.8,867.2,311.6,867.2,307.2z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M774.4,469.3h-56.5v56.5l0,0C749.1,525.7,774.4,500.4,774.4,469.3L774.4,469.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M757.2,755.3c10.6-10.2,17.3-24.6,17.3-40.5c-14,0-35.1,0-56.5,0L757.2,755.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M528.6,859.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C528.6,867.6,528.6,863.4,528.6,859.2z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M513.2,889.7c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C513.2,883.7,513.2,886.8,513.2,889.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M599.9,986.7c4.4,0,8.6,0,13.1,0c0-4.4,0-8.6,0-13.1c-4.4,0-8.6,0-13.1,0C599.9,978,599.9,982.3,599.9,986.7z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M661.5,898.7L661.5,898.7c0-31.1-25.4-56.5-56.5-56.5l0,0c-31.1,0-56.5,25.4-56.5,56.5l0,0
        c0,31.1,25.4,56.5,56.5,56.5V941c-23.2,0-42.1-18.8-42.1-42.1l0,0c0-23.2,18.8-42.1,42.1-42.1l0,0c23.2,0,42.1,18.8,42.1,42.1l0,0
        L661.5,898.7L661.5,898.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M336,985.7c-0.4,0.6-1,1.2-1.3,1.7h90.1c-0.4-0.6-1-1.2-1.3-1.7H336z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1,184.1c-0.4,0.4-0.8,0.8-1.2,1.2v5.4L18,172.5C11.8,175,6,179.1,1,184.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M62.9,172c-5.2-2.1-10.8-3.6-16.3-4.2l-46.7,46.7v20.4L62.9,172z"/>
        <path :fill="colors[`${id}-p-3`]" d="M93.4,694.4v40.7c2.5-6.3,3.8-13.3,3.8-20.4C97.2,707.4,95.9,700.7,93.4,694.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M92.8,202.1L18.9,276c5.2,2.1,10.8,3.6,16.3,4.2L97,218.4C96.5,212.7,94.9,207.3,92.8,202.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M97.2,837.5c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0
        C96.9,844.6,97.2,840.9,97.2,837.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M89.8,809c0,5.6,0,10.9,0,16.5c2.1,0,4.2,0,6.3,0C94.7,819.8,92.6,814.2,89.8,809z"/>
        <path :fill="colors[`${id}-p-3`]" d="M89.8,865.7c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C89.8,859.2,89.8,862.4,89.8,865.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M40.8,304.5v-14.4c-16.1,0-30.7,6.7-40.9,17.7v29C4.3,318.3,21,304.5,40.8,304.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M40.8,592L0.9,552.1c-0.4,0.4-0.8,0.8-1.2,1.2v77.6c0.4,0.4,0.8,0.8,1.2,1.2L40.8,592z"/>
        <path :fill="colors[`${id}-p-3`]" d="M80.7,632c22.1-22.1,22.1-57.8,0-79.7l0,0l-39.9,39.9L80.7,632L80.7,632z"/>
        <path :fill="colors[`${id}-p-3`]" d="M92.4,246.9l-28.8,28.8c6.1-2.7,12.1-6.7,17.1-11.7C85.9,258.8,89.8,253,92.4,246.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M-0.1,244.6v8.5l74.3-74.3c-1.7-1.2-3.3-2.3-5-3.3L-0.1,244.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M7.4,269.5c1.7,1.2,3.3,2.3,5,3.3l77.2-77.2c-1-1.7-2.1-3.5-3.3-5L7.4,269.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M40.8,403L40.8,403c31.1,0,56.5-25.4,56.5-56.5H83.1l0,0c0,23.2-18.8,42.1-42.1,42.1l0,0
        c-19.8,0-36.5-13.8-40.9-32.3v29C10.2,396.3,24.6,403,40.8,403z"/>
        <path :fill="colors[`${id}-p-3`]" d="M654,68.5c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C654,61.9,654,65.2,654,68.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M784.6,304.1c-2.3,0-4.8,0-7.1,0c1.7,5,4,9.6,7.1,13.8C784.4,313.1,784.6,308.7,784.6,304.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M782.1,296.2c0-5,0-9.8,0-14.8c-2.5,0-5,0-7.7,0c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8
        C777.5,296.2,779.8,296.2,782.1,296.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M760,224c0,23.2-18.8,42.1-42.1,42.1l0,0v14.2l0,0c31.1,0,56.5-25.4,56.5-56.5H760V224z"/>
        <path :fill="colors[`${id}-p-3`]" d="M654,28.3c2.1,0,4.2,0,6.3,0c-1.3-6-3.5-11.3-6.3-16.5C654,17.6,654,22.9,654,28.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M656.3,36.2c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0c0.6-3.5,1-7.1,1-10.8c0-1.3,0-2.7-0.2-4
        C659.8,36.2,658.1,36.2,656.3,36.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M327.3,12.9h104.5c-1.9-4.6-4.2-8.8-7.3-12.7h-90.1C331.8,4.1,329.3,8.3,327.3,12.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M97.2,40L97.2,40h14.2l0,0c0-18.4,11.9-34.2,28.4-39.8h-26.1C103.6,10.4,97.2,24.5,97.2,40z"/>
        <path :fill="colors[`${id}-p-3`]" d="M568.8,3.5c6.5,0,12.9,0,19.6,0c0-1.2,0-2.3,0-3.3h-19.6C568.8,1.2,568.8,2.4,568.8,3.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M678,18.5C688.2,28.7,702.4,35,717.9,35c23.4,0,43.6-14.4,52.2-35h-73.8L678,18.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M783.9,254.2c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6
        C784.2,254.2,784.1,254.2,783.9,254.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M626,3.5c6.5,0,12.9,0,19.6,0c0-1,0-1.7,0-2.7c-0.2-0.2-0.6-0.6-0.8-0.8H626C626,1.2,626,2.4,626,3.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M24.1,891.2c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C10.4,885.5,17,888.9,24.1,891.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M675.7,224c0-23.2,18.8-42.1,42.1-42.1v-14.2c-31.1,0-56.5,25.4-56.5,56.5l0,0L675.7,224L675.7,224z"/>
        <path :fill="colors[`${id}-p-3`]" d="M7,823.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C7,813.5,7,818.3,7,823.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M4.5,800.8c6.5,0,12.9,0,19.6,0c0-5.8,0-11.5,0-17.3c-7.3,2.3-13.8,6-19.6,10.8C4.5,796.4,4.5,798.5,4.5,800.8z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M153.7,985.2V971c-15.6,0-29.8,6.3-39.9,16.5h26.1C144.3,985.9,148.9,985.2,153.7,985.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M4.5,830.9c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C17.3,830.9,11,830.9,4.5,830.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M20,859.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C20,867.6,20,863.4,20,859.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M5.2,670.9v87.4c4.4,3.5,9.2,6.5,14.4,8.6V662.5C14.5,664.6,9.5,667.5,5.2,670.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M890.5,825.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6c-0.2,0-0.4,0-0.6,0C894.9,811.9,892,818.4,890.5,825.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M77.3,859.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C77.3,867.6,77.3,863.4,77.3,859.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M896.8,252.4c1,1.7,2.1,3.5,3.3,5l4.4-4.4v-8.5L896.8,252.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M588.3,973.6c-7.3,2.3-13.8,6-19.6,10.8c0,1,0,2.1,0,3.1h19.6C588.3,982.8,588.3,978.2,588.3,973.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M774.4,965.9c0-15.6-6.3-29.6-16.5-39.9l-61.5,61.5h73.8C772.9,980.7,774.4,973.4,774.4,965.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M626,975c0,4.2,0,8.3,0,12.5h18.8C639.4,982.1,633.1,977.9,626,975z"/>
        <path :fill="colors[`${id}-p-3`]" d="M904.7,553.2c-20.6,21.7-20.6,55.9,0,77.6V553.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M62.1,662.5V767c5.2-2.1,10.2-5,14.4-8.6V671C72.1,667.5,67.3,664.6,62.1,662.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M55.2,660.2c-1.9-0.6-3.8-1-6-1.2v111.6c2.1-0.4,4-0.8,6-1.2V660.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M64.2,823.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C64.2,813.5,64.2,818.3,64.2,823.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M61.7,800.8c6.5,0,12.9,0,19.6,0c0-1,0-1.7,0-2.7c-5.6-5.6-12.1-10.2-19.6-13.1
        C61.7,790.2,61.7,795.4,61.7,800.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M61.7,889.7c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C61.7,883.7,61.7,886.8,61.7,889.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M81.3,830.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C81.3,844,81.3,837.5,81.3,830.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M51.9,855.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C51.9,868.8,51.9,862.4,51.9,855.9z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M48.7,783.7c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C48.7,792.3,48.7,788.1,48.7,783.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M32.3,658.8c-2.1,0.4-4,0.8-6,1.2v109.3c1.9,0.6,3.8,1,6,1.2V658.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M34.8,893.5c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C34.8,886.6,34.8,890.1,34.8,893.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M49.4,833.4c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C49.4,843,49.4,838.2,49.4,833.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M32.3,806c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C45.4,806,38.9,806,32.3,806z"/>
        <path :fill="colors[`${id}-p-3`]" d="M558.8,72.5c0-4.6,0-9.2,0-13.8c-2.3,0-4.8,0-7.1,0C553.6,63.6,555.9,68.3,558.8,72.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M548.6,40.2c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8c-2.5,0-5,0-7.5,0
        C548.8,37.5,548.6,38.9,548.6,40.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M568.8,53.3c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0C568.8,40.4,568.8,46.7,568.8,53.3z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M419.9,401.3c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C419.9,414.4,419.9,407.8,419.9,401.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M565.3,202.5l11.7-11.7c-7.3-7.3-11.7-17.3-11.7-28.2c0-21.9,17.9-39.9,39.9-39.9c10.9,0,20.9,4.4,28.2,11.7
        l11.7-11.7c-10.2-10.2-24.4-16.5-39.9-16.5c-31.1,0-56.5,25.4-56.5,56.5C548.6,178.3,554.9,192.3,565.3,202.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M558.2,8.9c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6C558.6,8.9,558.4,8.9,558.2,8.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M544.9,203.7v40.7c2.5-6.3,3.8-13.3,3.8-20.4C548.6,216.9,547.2,210,544.9,203.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M599.1,50.8c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C599.1,41.2,599.1,46,599.1,50.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M599.1,96.3c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C599.1,89.4,599.1,92.8,599.1,96.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M605.1,530.7h-56.5c0,31.1,25.4,56.5,56.5,56.5c15.2,0,29-6,39.2-15.9c10.6-10.2,17.3-24.6,17.3-40.5
        L605.1,530.7L605.1,530.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M585.8,11.2c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C585.8,21,585.8,16.2,585.8,11.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M596.6,78.2c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0C596.6,65.4,596.6,71.7,596.6,78.2z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M588.3,94c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C574.7,88.2,581.2,91.9,588.3,94z"/>
        <path :fill="colors[`${id}-p-3`]" d="M584.1,61.9c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C584.1,70.6,584.1,66.3,584.1,61.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M616.2,8.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C616.2,21.8,616.2,15.4,616.2,8.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M443.5,848c0-5,0-9.8,0-14.8c-2.5,0-5,0-7.5,0c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8
        C439.1,848,441.2,848,443.5,848z"/>
        <path :fill="colors[`${id}-p-3`]" d="M432.8,426.3c-1.5,0-3.1,0-4.4,0c0,3.3,0,6.5,0,9.8C430.1,432.8,431.6,429.5,432.8,426.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M333.1,439.9c0-4.6,0-9.2,0-13.8c-2.3,0-4.8,0-7.1,0C327.7,431.1,330.2,435.9,333.1,439.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M434.5,395.9c-1.3-6-3.5-11.3-6.3-16.5c0,5.6,0,10.9,0,16.5C430.3,395.9,432.4,395.9,434.5,395.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M332.5,376.3c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6
        C332.9,376.3,332.7,376.3,332.5,376.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M661.5,653.3c0-31.1-25.4-56.5-56.5-56.5l0,0c-1.2,0-2.1,0-3.3,0c-1,0-2.1,0.2-3.1,0.2
        c-2.7,0.4-5.2,0.8-7.7,1.3c-1.3,0.4-2.7,0.8-3.8,1.2c0,0,0,0-0.2,0c-2.9,1-5.8,2.3-8.5,3.6c-1.5,1-3.3,1.7-4.8,2.9
        c-2.1,1.3-4,2.9-5.8,4.4c-1,0.8-1.7,1.7-2.7,2.5c-0.8,0.8-1.7,1.7-2.5,2.7c-0.4,0.4-0.8,0.8-1.2,1.3c-2.9,3.5-5.4,7.1-7.3,11.1
        c-0.2,0.2-0.2,0.6-0.4,0.8c-0.2,0.6-0.6,1.2-0.8,1.7c-0.2,0.4-0.2,0.6-0.4,1c-0.2,0.6-0.4,1-0.6,1.5c-0.2,0.4-0.2,0.6-0.4,1
        c-0.2,0.6-0.4,1.2-0.6,1.7c-0.2,0.6-0.4,1.2-0.6,1.7c-0.6,2.3-1.2,4.6-1.5,6.9c0,0.4-0.2,1-0.2,1.3c-0.2,1-0.2,1.9-0.4,2.9
        c-0.2,1.3-0.2,2.9-0.2,4.4l0,0c0,2.1,0.2,4.2,0.4,6.3c0,0.6,0.2,1,0.2,1.5c0.2,1.9,0.6,3.8,1.2,5.8c0.2,1,0.6,2.1,0.8,3.1
        c0.2,0.8,0.6,1.5,0.8,2.3c0,0.2,0.2,0.4,0.2,0.6c0.6,1.5,1.3,3.3,2.1,4.8l0,0c2.7,5.8,6.5,10.9,10.9,15.4c0.6,0.6,1.2,1.2,1.9,1.7
        c1.2,1.2,2.5,2.3,3.8,3.3c3.3,2.5,6.9,4.8,10.9,6.5c0,0,0.2,0,0.2,0.2c1.2,0.6,2.3,1,3.3,1.3c0.2,0,0.4,0.2,0.6,0.2
        c0.8,0.2,1.5,0.6,2.3,0.8h0.2c1.2,0.4,2.3,0.8,3.5,1c2.3,0.6,4.4,1,6.7,1.2c1,0.2,2.1,0.2,3.1,0.2s2.1,0,3.3,0v-14.2
        c-23.2,0-42.1-18.8-42.1-42.1l0,0c0-23.2,18.8-42.1,42.1-42.1v42.1h42.1L661.5,653.3L661.5,653.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M432,55.4H327.5c2.1,5.2,5,10.2,8.6,14.4h87.4C427,65.6,429.9,60.6,432,55.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M605.1,719.5L605.1,719.5V776v56.5l0,0c31.1,0,56.5-25.4,56.5-56.5C661.5,744.9,636.2,719.5,605.1,719.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M548.6,837.5c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0
        C548.4,844.6,548.6,840.9,548.6,837.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M446,855.9c-2.3,0-4.8,0-7.1,0c1.7,5,4,9.6,7.1,13.8C446,864.9,446,860.5,446,855.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M445.3,806c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6C445.8,806,445.6,806,445.3,806z"/>
        <path :fill="colors[`${id}-p-3`]" d="M545.5,855.9c-1.5,0-3.1,0-4.4,0c0,3.3,0,6.5,0,9.8C542.8,862.4,544.4,859.2,545.5,855.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M616.4,304.1c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C616.2,317,616.2,310.6,616.4,304.1
        z"/>
        <path :fill="colors[`${id}-p-3`]" d="M588.3,231.7c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C588.3,243,588.3,237.5,588.3,231.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M559,304.1c-2.3,0-4.8,0-7.1,0c1.7,5,4,9.6,7.1,13.8C559,313.1,559,308.7,559,304.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M626,53.3c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0C626,40.4,626,46.7,626,53.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M568.9,279.1c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6
        C581.8,279.1,575.3,279.1,568.9,279.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M613.1,231.9c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C613.1,240.3,613.1,236.1,613.1,231.9z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M588.3,339.4c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C574.7,333.7,581.2,337.1,588.3,339.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M584.3,307.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C584.3,315.8,584.3,311.6,584.3,307.2z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M654,313.7c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C654,307.4,654,310.6,654,313.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M549,281.4c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8
        C554,281.4,551.5,281.4,549,281.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M654,257.2c0,5.6,0,10.9,0,16.5c2.1,0,4.2,0,6.3,0C659.2,267.8,656.9,262.4,654,257.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M558.4,254.2c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6
        C558.8,254.2,558.6,254.2,558.4,254.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M645,368.1l-15,15c6.3,6.3,10.4,15.2,10.4,24.8c0,19.4-15.7,35.1-35.1,35.1l0,0c-9.6,0-18.4-4-24.8-10.4l-15,15
        c10.2,10.2,24.4,16.5,39.9,16.5c31.1,0,56.5-25.4,56.5-56.5C661.5,392.5,655.2,378.4,645,368.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M571.2,271.3c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C571.2,261.7,571.2,266.5,571.2,271.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M626.2,233.2c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0c0-1,0-1.7,0-2.7C640.2,240.7,633.7,236.1,626.2,233.2z"
        />
        <path :fill="colors[`${id}-p-3`]" d="M626.2,337.9c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C626.2,332,626.2,335,626.2,337.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M643.3,256.7c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C643.3,266.5,643.3,261.5,643.3,256.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M645.8,279.1c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C645.8,292,645.8,285.7,645.8,279.1
        z"/>
        <path :fill="colors[`${id}-p-3`]" d="M643.1,11.2c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C643.1,21,643.1,16.2,643.1,11.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M641.7,307.2c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C641.7,315.8,641.7,311.6,641.7,307.2z
        "/>
        <path :fill="colors[`${id}-p-3`]" d="M596.8,254.2c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6
        C609.7,254.2,603.3,254.2,596.8,254.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M613.9,281.4c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C613.9,291.2,613.9,286.4,613.9,281.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M628.5,75c4.4,0,8.6,0,13.1,0c0-4.4,0-8.6,0-13.1c-4.4,0-8.6,0-13.1,0C628.5,66.3,628.5,70.6,628.5,75z"/>
        <path :fill="colors[`${id}-p-3`]" d="M599.1,341.6c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C599.1,334.8,599.1,338.3,599.1,341.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M661.7,285.5c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0
        C661.3,292.8,661.7,289.1,661.7,285.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M626,92.7c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C626,86.7,626,89.6,626,92.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M428.2,150.5c2.1,0,4.2,0,6.3,0c-1.3-6-3.5-11.3-6.3-16.5C428.2,139.5,428.2,145.1,428.2,150.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M434.9,172.9c0.6-3.5,1-7.1,1-10.8c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6
        C432,172.9,433.4,172.9,434.9,172.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M428.2,190.6c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C428.2,184.1,428.2,187.3,428.2,190.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M315.4,809.4c0,5.6,0,10.9,0,16.5c2.1,0,4.2,0,6.3,0C320.4,820,318.3,814.4,315.4,809.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M464.1,73.1c-7.7,7.7-11.7,18.1-11.7,28.2c0,10.2,3.8,20.4,11.7,28.2c15.6,15.6,40.9,15.6,56.5,0
        c7.7-7.7,11.7-18.1,11.7-28.2c0-10.2-3.8-20.4-11.7-28.2C505,57.7,479.6,57.7,464.1,73.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M492.3,346.7v-56.5c-31.1,0-56.5,25.4-56.5,56.5c0,15.2,6,29,15.9,39.2L492.3,346.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M315.4,865.9c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C315.4,859.5,315.4,862.6,315.4,865.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M400.3,214.6c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C400.3,208.7,400.3,211.7,400.3,214.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M373.4,218.4c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C373.4,211.5,373.4,215,373.4,218.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M370.9,200.2c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C370.9,187.3,370.9,193.9,370.9,200.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M419.9,123c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C419.9,124.7,419.9,124,419.9,123z"/>
        <path :fill="colors[`${id}-p-4`]" d="M402.8,197.1c4.4,0,8.6,0,13.1,0c0-4.4,0-8.6,0-13.1c-4.4,0-8.6,0-13.1,0C402.8,188.5,402.8,192.5,402.8,197.1z
        "/>
        <path :fill="colors[`${id}-p-4`]" d="M402.8,148.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C402.8,138.4,402.8,143.2,402.8,148.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M400.3,175.4c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C400.3,162.6,400.3,168.9,400.3,175.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M287.6,890.1c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C287.6,884.1,287.6,887.2,287.6,890.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M289.9,823.4c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C289.9,813.8,289.9,818.6,289.9,823.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M260.5,893.7c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C260.5,887,260.5,890.3,260.5,893.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M277.6,856.1c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C277.6,869.1,277.6,862.6,277.6,856.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M275.1,833.6c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C275.1,843.4,275.1,838.6,275.1,833.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M258,806.3c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C271.1,806.3,264.5,806.3,258,806.3z"
        />
        <path :fill="colors[`${id}-p-4`]" d="M274.3,784.1c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C274.3,792.5,274.3,788.3,274.3,784.1z
        "/>
        <path :fill="colors[`${id}-p-4`]" d="M373.4,172.9c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C373.4,163.1,373.4,167.9,373.4,172.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M308.7,714.7c0,23.2-18.8,42.1-42.1,42.1l0,0V771l0,0c31.1,0,56.5-25.4,56.5-56.5h-14.4V714.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M302.9,859.4c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C302.9,868,302.9,863.8,302.9,859.4z"
        />
        <path :fill="colors[`${id}-p-4`]" d="M307,798.5c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C307,800.2,307,799.2,307,798.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M224.4,714.7c0,23.2,18.8,42.1,42.1,42.1v-42.1H224.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M307,831.3c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0C307,844.2,307,837.8,307,831.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M322.9,837.7c0-1.3,0-2.7-0.2-4c-1.7,0-3.3,0-5,0c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0
        C322.5,844.9,322.9,841.3,322.9,837.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M249.7,891.6c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C236.1,885.7,242.6,889.3,249.7,891.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M492.3,511.5L492.3,511.5v14.2l0,0c31.1,0,56.5-25.4,56.5-56.5h-14.2C534.4,492.5,515.6,511.5,492.3,511.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M605.1,719.5c-31.1,0-56.5,25.4-56.5,56.5c0,15.2,6,29,15.9,39.2L605,776L605.1,719.5L605.1,719.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M605.1,940.8v-42.1H563C563,922,581.8,940.8,605.1,940.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M605.1,611.2c-23.2,0-42.1,18.8-42.1,42.1l0,0h42.1V611.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M419.3,245.5l-11.7,11.7c7.3,7.3,11.7,17.3,11.7,28.2c0,21.9-17.9,39.9-39.9,39.9c-10.9,0-20.9-4.4-28.2-11.7
        l-11.8,11.8c10.2,10.2,24.4,16.5,39.9,16.5c31.1,0,56.5-25.4,56.5-56.5C435.8,269.7,429.5,255.7,419.3,245.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M432,510.4v40.7c2.5-6.3,3.8-13.3,3.8-20.4C435.8,523.4,434.5,516.7,432,510.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M387.3,108.6c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C387.3,117.2,387.3,113,387.3,108.6z"
        />
        <path :fill="colors[`${id}-p-4`]" d="M415.1,486.9c-4.4-3.5-9.2-6.5-14.4-8.6V583c5.2-2.1,10.2-5,14.4-8.6V486.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M630,383.2c-6.3-6.3-15.2-10.4-24.8-10.4v35.1V443c19.4,0,35.1-15.7,35.1-35.1
        C640.2,398.4,636.4,389.6,630,383.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M548.6,530.7L548.6,530.7h56.5h56.5l0,0c0-31.1-25.4-56.5-56.5-56.5S548.6,499.6,548.6,530.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M661.5,592.2c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8c-2.5,0-5,0-7.7,0
        C661.5,589.5,661.5,590.9,661.5,592.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M139.9,0.3c-16.3,5.6-28.2,21.3-28.2,39.8l0,0H97.4c0,31.1,25.4,56.5,56.5,56.5V82.4l0,0
        c23.2,0,42.1-18.8,42.1-42.1h14.2l0,0c0-15.6-6.3-29.6-16.5-39.8L139.9,0.3L139.9,0.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M671.7,624.5c0-4.6,0-9.2,0-13.8c-2.3,0-4.8,0-7.1,0C666.3,615.6,668.8,620.2,671.7,624.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M671.1,560.9c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6
        C671.5,560.9,671.3,560.9,671.1,560.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M432,796.4c2.5-6.3,3.8-13.3,3.8-20.4s-1.3-14-3.8-20.4V796.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M400.7,828.2c5.2-2.1,10.2-5,14.4-8.6v-87.4c-4.4-3.5-9.2-6.5-14.4-8.6V828.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M362.5,108.4c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C362.5,119.7,362.5,114.2,362.5,108.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M345.4,148.2c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C345.4,138.4,345.4,143.2,345.4,148.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M362.5,216.1c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C349,210.4,355.4,213.8,362.5,216.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M358.4,184.1c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C358.4,192.5,358.4,188.3,358.4,184.1z
        "/>
        <path :fill="colors[`${id}-p-4`]" d="M370.9,150.5c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C370.9,137.6,370.9,143.9,370.9,150.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M343.1,175.4c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6c-6.5,0-13.1,0-19.6,0
        C343.1,162.6,343.1,168.9,343.1,175.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M387.8,831.9c2.1-0.4,4-0.8,6-1.2V721.6c-1.9-0.6-3.8-1-6-1.2V831.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M343.9,819.8c4.4,3.5,9.2,6.5,14.4,8.6V724c-5.2,2.1-10.2,5-14.4,8.6V819.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M370.9,474.8c-2.1,0.4-4,0.8-6,1.2v109.1c1.9,0.6,3.8,1,6,1.2V474.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M393.8,476.2c-1.9-0.6-3.8-1-6-1.2v111.6c2.1-0.4,4-0.8,6-1.2V476.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M358.3,478.5c-5.2,2.1-10.2,5-14.4,8.6v87.4c4.4,3.5,9.2,6.5,14.4,8.6V478.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M365,830.5c1.9,0.6,3.8,1,6,1.2V720.1c-2.1,0.4-4,0.8-6,1.2V830.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M766.8,580.5c2.1,0,4.2,0,6.3,0c-1.3-6-3.5-11.3-6.3-16.5C766.8,569.5,766.8,574.9,766.8,580.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M701.1,646.2c0-3.5,0-6.9,0-10.6c-6.5,0-12.9,0-19.2,0C687.4,640.2,694,643.9,701.1,646.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M725.7,538.4c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C725.9,547.1,725.7,542.8,725.7,538.4z
        "/>
        <path :fill="colors[`${id}-p-4`]" d="M697,613.9c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C697,622.5,697,618.3,697,613.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M684,578c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C684,568.2,684,573.2,684,578z"/>
        <path :fill="colors[`${id}-p-4`]" d="M709.5,560.9c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6
        C722.4,560.9,716.1,560.9,709.5,560.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M701.1,538.4c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C701.1,549.8,701.1,544.2,701.1,538.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M681.5,585.9c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C694.6,585.9,688,585.9,681.5,585.9z
        "/>
        <path :fill="colors[`${id}-p-4`]" d="M758.5,585.9c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C758.5,598.7,758.5,592.4,758.5,585.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M726.6,588.2c-5,0-9.8,0-14.6,0c0,5,0,9.8,0,14.6c4.8,0,9.6,0,14.6,0C726.6,598,726.6,593.2,726.6,588.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M760,224c0-23.2-18.8-42.1-42.1-42.1l0,0c-23.2,0-42.1,18.8-42.1,42.1l0,0c0,23.2,18.8,42.1,42.1,42.1l0,0
        C741.2,266.1,760,247.3,760,224L760,224z"/>
        <path :fill="colors[`${id}-p-4`]" d="M852.8,950.6l-73.9-73.9c-2.1,5.2-3.6,10.8-4.2,16.3l61.8,61.8C842.1,954.2,847.6,952.9,852.8,950.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M741.4,578c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C741.2,568.2,741.4,573.2,741.4,578z"/>
        <path :fill="colors[`${id}-p-4`]" d="M711.8,648.3c1.9,0.2,4,0.4,6.1,0.4c2.9,0,5.8-0.2,8.6-0.6c0-3.3,0-6.7,0-10c-5,0-9.8,0-14.6,0
        C711.8,641.6,711.8,644.8,711.8,648.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M728.9,610.6c-6.5,0-13.1,0-19.6,0c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0
        C728.9,623.7,728.9,617.2,728.9,610.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M758.5,553c-5.6-5.6-12.1-10.2-19.6-13.1c0,5.4,0,10.6,0,15.7c6.5,0,12.9,0,19.6,0
        C758.5,554.7,758.5,553.8,758.5,553z"/>
        <path :fill="colors[`${id}-p-4`]" d="M766.8,620.4c1.7-3.1,3.3-6.3,4.4-9.8c-1.5,0-3.1,0-4.4,0C766.8,614.1,766.8,617.2,766.8,620.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M830.7,219L830.7,219c31.1,0,56.5-25.4,56.5-56.5h-56.5V219z"/>
        <path :fill="colors[`${id}-p-4`]" d="M769.3,588.2c0,5,0,9.8,0,14.6c1.3,0,2.7,0,4.2,0c0.6-3.5,1-7.1,1-10.8c0-1.3,0-2.7-0.2-4
        C772.5,588.2,770.8,588.2,769.3,588.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M774.4,162.8L774.4,162.8h56.5v-56.5C799.6,106.3,774.4,131.6,774.4,162.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M492.5,12.8c11.3,0,21.7-4.8,29-12.5h-58C470.8,8,481.2,12.8,492.5,12.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M791,815.9l11.7-11.7c-7.3-7.3-11.7-17.3-11.7-28.2c0-21.9,17.9-39.9,39.9-39.9c10.9,0,20.9,4.4,28.2,11.7
        l11.7-11.7c-10.2-10.2-24.4-16.5-39.9-16.5c-31.1,0-56.5,25.4-56.5,56.5C774.4,791.6,780.8,805.8,791,815.9z"/>
        <circle :fill="colors[`${id}-p-4`]" cx="830.7" cy="653.3" r="39.9"/>
        <path :fill="colors[`${id}-p-4`]" d="M879.5,927.2l-77-77.2c-1.7,1-3.5,2.1-5,3.3l78.9,78.9C877.4,930.4,878.5,928.9,879.5,927.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M864.3,944.1l-78.9-78.8c-1.2,1.7-2.3,3.3-3.3,5l77.2,77.2C860.9,946.5,862.6,945.4,864.3,944.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M808.1,950.4l-28.8-28.8c2.7,6.1,6.7,12.1,11.7,17.1C796,943.7,801.7,947.5,808.1,950.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M887,904.5l-61.8-61.8c-5.6,0.6-10.9,1.9-16.3,4.2l73.9,73.9C884.9,915.4,886.4,910.1,887,904.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M830.7,351.5c-31.1,0-56.5,25.4-56.5,56.5s25.4,56.5,56.5,56.5s56.5-25.4,56.5-56.5S861.8,351.5,830.7,351.5z
        M830.7,448c-21.9,0-39.9-17.9-39.9-39.9c0-21.9,17.9-39.9,39.9-39.9c21.9,0,39.9,17.9,39.9,39.9C870.7,430.1,852.8,448,830.7,448z"
        />
        <path :fill="colors[`${id}-p-4`]" d="M853.6,847.1l28.8,28.8c-2.7-6.1-6.7-12.1-11.7-17.1S859.7,849.8,853.6,847.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M266.6,525.7c31.1,0,56.5-25.4,56.5-56.5h-56.5V525.7L266.6,525.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M220.4,856.1c-2.3,0-4.8,0-7.1,0c1.7,5,4,9.6,7.1,13.8C220.4,865.3,220.4,860.7,220.4,856.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M210.2,833.6c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0c0-5,0-9.8,0-14.8
        C215.4,833.6,212.7,833.6,210.2,833.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M266.6,413c-31.1,0-56.5,25.4-56.5,56.5l0,0h56.5V413z"/>
        <path :fill="colors[`${id}-p-4`]" d="M219.6,806.3c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6
        C220.2,806.3,219.8,806.3,219.6,806.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M326.8,755.6c-2.5,6.3-3.8,13.3-3.8,20.4s1.3,14,3.8,20.4V755.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M266.6,552.1c-21.9,0-39.9,17.9-39.9,39.9c0,10.9,4.4,20.9,11.7,28.2c7.3,7.3,17.3,11.7,28.2,11.7
        c21.9,0,39.9-17.9,39.9-39.9c0-10.9-4.4-20.9-11.7-28.2C287.6,556.5,277.6,552.1,266.6,552.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M245.7,859.4c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C245.7,868,245.7,863.8,245.7,859.4z"
        />
        <path :fill="colors[`${id}-p-4`]" d="M249.7,783.9c-7.3,2.3-13.8,6-19.6,10.8c0,2.1,0,4.2,0,6.3c6.5,0,12.9,0,19.6,0
        C249.7,795.2,249.7,789.6,249.7,783.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M232.6,823.4c4.8,0,9.6,0,14.6,0c0-5,0-9.8,0-14.8c-5,0-9.8,0-14.6,0C232.6,813.8,232.6,818.6,232.6,823.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M230.2,831.3c0,6.7,0,13.1,0,19.6c6.5,0,12.9,0,19.6,0c0-6.5,0-13.1,0-19.6C243,831.3,236.7,831.3,230.2,831.3z
        "/>
        <path :fill="colors[`${id}-p-4`]" d="M113.8,858.8L113.8,858.8l39.9,39.9l39.9-39.9C171.6,836.9,135.8,836.9,113.8,858.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M193.7,122.8l-10.8,10.8c7.5,7.5,12.1,17.7,12.1,29c0,22.7-18.4,41.1-41.1,41.1c-11.3,0-21.5-4.6-29-12.1
        l-10.8,10.8c10.2,10.2,24.4,16.5,39.9,16.5c31.1,0,56.5-25.4,56.5-56.5C210.2,147.2,203.8,133,193.7,122.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M178.5,260.5c-6.3-6.3-15.2-10.4-24.8-10.4v35.1v35.1c19.4,0,35.1-15.7,35.1-35.1
        C188.9,275.7,184.8,266.8,178.5,260.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M332.5,130.9c-3.8,5.8-6.7,12.5-8.3,19.6c2.9,0,6,0,8.8,0c0-6.5,0-13.1,0-19.6
        C332.9,130.9,332.7,130.9,332.5,130.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M1,632c22.1,22.1,57.8,22.1,79.7,0l-39.9-39.9L1,632L1,632z"/>
        <path :fill="colors[`${id}-p-4`]" d="M326.8,510.4c-2.5,6.3-3.8,13.3-3.8,20.4c0,7.1,1.3,14,3.8,20.4V510.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M153.7,970.7L153.7,970.7v14.2c-4.8,0-9.4,0.8-13.6,2.3h53.6C183.5,977.1,169.3,970.7,153.7,970.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M532.5,960c0-21.9-17.9-39.9-39.9-39.9c-21.9,0-39.9,17.9-39.9,39.9c0,10.6,4.2,20.2,10.9,27.5h58
        C528.2,980.2,532.5,970.6,532.5,960z"/>
        <path :fill="colors[`${id}-p-4`]" d="M754.3,613.9c-4.4,0-8.6,0-13.1,0c0,4.4,0,8.5,0,13.1c4.4,0,8.6,0,13.1,0C754.3,622.5,754.3,618.3,754.3,613.9z
        "/>
        <path :fill="colors[`${id}-p-4`]" d="M97.2,346.7L97.2,346.7c0-31.1-25.4-56.5-56.5-56.5l0,0v14.4l0,0c23.2,0,42.1,18.8,42.1,42.1H97.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M1,552.1L1,552.1L40.9,592l39.9-39.9C58.8,530.2,22.9,530.2,1,552.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M333.1,180.8c-2.3,0-4.8,0-7.1,0c1.7,5,4,9.6,7.1,13.8C333.1,189.8,333.1,185.4,333.1,180.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M330.8,158.3c-2.5,0-5,0-7.5,0c0,1.3-0.2,2.7-0.2,4c0,3.6,0.4,7.3,1,10.8c2.3,0,4.4,0,6.7,0
        C330.8,167.9,330.8,163.1,330.8,158.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M738.9,644.6c5.6-2.3,10.6-5.2,15.2-9c-5,0-10,0-15.2,0C738.9,638.7,738.9,641.6,738.9,644.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M1,509.2c22.1,22.1,57.8,22.1,79.7,0l-39.9-39.9L1,509.2L1,509.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M80.7,101.3c0-10.9-4.4-20.9-11.7-28.2c-7.3-7.3-17.3-11.7-28.2-11.7c-21.9,0-39.9,17.9-39.9,39.9
        c0,10.9,4.4,20.9,11.7,28.2c7.3,7.3,17.3,11.7,28.2,11.7C62.9,141.2,80.7,123.4,80.7,101.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M1,429.5L1,429.5l39.9,39.9l39.9-39.9C58.8,407.4,22.9,407.4,1,429.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M258,998.2c6.5,0,12.9,0,19.6,0c0-3.6,0-7.1,0-10.8H258C258,990.9,258,994.6,258,998.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M232.6,994.9c4.4,0,8.6,0,13.1,0c0-2.5,0-5,0-7.5h-13.1C232.6,990,232.6,992.3,232.6,994.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M289.9,994.9c4.4,0,8.6,0,13.1,0c0-2.5,0-5,0-7.5h-13.1C289.9,990,289.9,992.3,289.9,994.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M220.4,992.5c0-1.7,0-3.3,0-5h-3.1C218.1,989.2,219.2,990.7,220.4,992.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M315.4,988.4c0.2-0.4,0.4-0.6,0.6-1h-0.6C315.4,987.7,315.4,988,315.4,988.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M568.8,990.7c6.5,0,12.9,0,19.6,0c0-1.2,0-2.3,0-3.3h-19.6C568.8,988.4,568.8,989.6,568.8,990.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M626,990.7c6.5,0,12.9,0,19.6,0c0-1,0-1.7,0-2.7c-0.2-0.2-0.6-0.6-0.8-0.8H626C626,988.4,626,989.6,626,990.7z"/>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>