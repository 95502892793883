<template>
  <tool></tool>
</template>

<script>
import Tool from './components/Tool.vue'

export default {
  name: 'App',
  components: {
    Tool
  }
}
</script>