<template>
    <pattern :id="id" x="0" y="0" width="2300" height="2300" viewBox="0 0 2300 2300" patternUnits="userSpaceOnUse"
    :patternTransform="transform">
        <rect x="1" :fill="colors[`${id}-p-0`]" width="2298" height="2300"/>
        <g>
    <defs>
    <rect id="SVGID_1_" x="-0.5" width="2301" height="2300"/>
    </defs>
    <clipPath id="SVGID_2_">
    <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
    </clipPath>

    <path :fill="colors[`${id}-p-1`]" d="M2155.8,717.2c-0.7,0.7-1,0.7-1.9,1c-0.7-0.7-1-1-1.5-1.6C2153.8,716.5,2154.8,717.2,2155.8,717.2z
    M2104.8,760.8c4.1,0.7,8.2,1,12.6,1.6C2113.6,758.6,2108.9,758.6,2104.8,760.8z M2154.8,788.3c-0.7-1.6-1.5-2.5-1.9-3.4
    c0,0-1,0.7-1.9,1l2.5,3.4C2153.8,789.3,2154.8,788.3,2154.8,788.3z M2153.2,856c-3.2-0.7-6-1-9.2-0.7c-0.7,0.7-0.7,1-1,1.6l-0.7,1
    l0.7,1c1,4.8,0.7,8.2-0.7,11.4C2146.9,865.4,2155.1,864.5,2153.2,856z M2182.8,987.5c3.4,6,9.2,9.8,15.4,12.3
    C2193.2,995.8,2187.9,991.6,2182.8,987.5z M2214.6,1123.7c1.9,0.7,4.7-1,6-1c0-2.5,0-4.1,0-5.7c-3.2,1-6,1.6-9.2,2.5
    C2213,1120.6,2213.3,1123.1,2214.6,1123.7z M2233.7,1069c1.5,0,2.5,0,3.4,0c-0.7-0.7-0.7-1.6-1-1.9c-1,0-1.9-0.7-2.5,0
    C2233.7,1066.8,2233.7,1067.8,2233.7,1069z M2285.6,456.2c1,1,4.7,1,4.7,0.7c1-1.9,1-4.8,1.5-7.3c-1-1.6-1.9-2.5-3.2-4.1
    c-1.9,1.9-4.1,3.4-5.6,6C2283.1,452.6,2284.1,455.2,2285.6,456.2z M2279.3,484c0-0.7,0-1.6,0-1.9c-0.7,0-1.5-0.7-1.9-0.7
    c-0.7,0.7-1,1-1,1.9C2277.4,483.3,2278.4,484,2279.3,484z M2268.4,698.3c0,0-0.7-1.9-1-1.9c-1.5-0.7-3.2,0-4.7-0.7
    c0.7,1.6,1.5,2.5,1.9,4.1C2266.5,699.2,2267.4,698.9,2268.4,698.3z M2208.9,1078.6c-0.7-0.7-1.5,0-1.9,0c0,0.7,0,1.6,0,1.9
    c0.7,0,1.5,0,2.5,0C2209.3,1079.8,2209.3,1078.6,2208.9,1078.6z M2275.6,661c0.7,1.9,0,4.8,1,5.7c1.5,1.6,3.4,1.9,5.6,3.2
    c-1-1.9-1.5-4.8-3.2-6C2278.8,662.5,2277.1,661.7,2275.6,661z M2162.3,736.5c-1,0-1.9,0.7-2.5,0.7c0,1,0.7,1.9,0.7,2.5
    c1,0,2.5-0.7,4.1-1C2163.3,737.7,2162.9,737.1,2162.3,736.5z M2108.9,838.9L2108.9,838.9c-5.6-8.2-3.2-14.2,4.1-20.5
    c9.7,3.4,13.2,9.2,10,20.5C2117.9,838.9,2113.6,838.9,2108.9,838.9z M2111.4,832.8c0.7,1,1,1.9,1.5,3.2c0.7-0.7,1.5-0.7,1.9-1l0,0
    l0,0c-0.7-1-1-1.9-1.5-2.5C2113.6,832.3,2112.3,832.8,2111.4,832.8z M2146.6,765.2l0.7-1.9c-1,0.7-1.9,1-3.2,1.6
    C2144.4,764.3,2145.6,764.7,2146.6,765.2z M2193.2,1073.1c-0.7-2.5-1.5-5.1-1.9-7.5c-1.9,1.6-3.4,1.9-4.7,3.2
    c1.5,1.9,3.2,3.4,4.7,5.7C2190.9,1074.5,2193.2,1073.5,2193.2,1073.1z M2181.2,988.1L2181.2,988.1L2181.2,988.1L2181.2,988.1z
    M2192.5,1087.4l0.7-1.9l-2.5,0.7L2192.5,1087.4z M2156.3,1089.8c-7.5-14.2-22.3-19-37.1-22.4c-3.2-0.7-9.2,3.4-10.7,7.3
    c-3.4,6.7,1.5,12.3,4.1,19c2.5,6,5.6,10.1,12.2,9.8h3.2c1.5,0,3.4,0.7,5.1,1c-1.9-0.7-3.4-0.7-5.6-1c1-5.1,1.9-10.8,3.4-17.4
    c5.6,4.8,9.2,9.2,14.1,11.6C2150,1099,2154.1,1096.4,2156.3,1089.8z M2148.2,1182.4c0.7-1,0.7-4.8-0.7-5.1c-1.9-1-4.7-1.6-10-2.5
    c3.2,4.8,4.1,7.3,5.6,8.9C2144.1,1184,2146.9,1183.3,2148.2,1182.4z M2076.1,1152.1l-1.5,1.6c1,0.7,1.9,1,3.2,1.9
    c0-1,0.7-1.6,0-2.5C2078.3,1152.7,2077.4,1152.1,2076.1,1152.1z M2081.5,1244.7c0.7,0,1.5-0.7,2.5-1c-1-0.7-2.5-0.7-4.1-1
    C2080.2,1243.1,2080.9,1244.4,2081.5,1244.7z M2135.2,1222.2c15.4-1,9.2-11.4,10-18.1C2142.5,1209.9,2139.3,1215.6,2135.2,1222.2z
    M2187.2,1128l-1.9-1l0.7,2.5L2187.2,1128z M2111.7,1409.7c-4.7-2.5-9.7-5.1-18-9.2C2099.5,1410.7,2106.8,1408.1,2111.7,1409.7z
    M2150,1309.1c-7.5,1.6-7.5,6.7-7.5,14.9c8.8-2.5,15.4-5.1,22.1-7.3C2159.8,1314.6,2155.1,1311.7,2150,1309.1z M2172.4,1290.2
    c0.7-1.6-1-3.4-1.9-5.1c-1.5,1-4.1,1.6-4.1,3.2c-0.7,1,1,3.2,1.9,4.8C2169.9,1291.7,2172.4,1291.5,2172.4,1290.2z M2175.5,969.1
    c0-0.7-1-1.6-1.5-1.9c-1,1-1.9,1.9-3.4,3.2l3.2,3.4C2174.6,971.9,2175.5,970.4,2175.5,969.1z M2168.9,1376.7
    c-0.7,0.7-1.9,1-1.9,1.6c-0.7,3.2-1,6.7-1.9,9.8c1.9-2.5,4.1-5.1,6-8.2C2171.1,1380,2169.6,1378.4,2168.9,1376.7z M2158.2,966.8
    c7.5-5.1,7.5-5.7,10.7-15.5C2160.7,953,2155.1,956.8,2158.2,966.8z M2151.7,1042.1c-9.2,3.4-10.7,12.3-2.5,23.4
    c4.7-5.1,9.2-9.2,12.6-14.2c1-1-1-5.7-2.5-7.3C2157.6,1042.8,2154.1,1041.1,2151.7,1042.1z M2145.9,1009.8
    c-3.4-4.1-9.7-6.7-13.8-10.1c-5.1-4.1-10.7-8.2-13.8-13.3c0,0,0,0,0,0.7c-0.7,0.7-3.2,1.6-5.6,1.6c-1,0-1.5,0-1.5,0
    c-10-3.4-19.9-7.3-32.1-12.3c-1-0.7-1.9-0.7-3.2-1c-1.5,1.6-2.5,4.1-3.2,6c-1,7.5,1,15.5,1.5,22.2c6,7.3,16.3,0.7,20.7,9.2
    c3.2,6,8.8,10.8,11.4,16.7c2.5,6.7,2.5,14.2,3.4,22.2c4.7-3.2,8.8-5.7,12.2-8.2c1.9,1.6,4.1,1.9,5.1,3.4c1.9,3.4,4.1,7.5,8.8,5.1
    c5.6-3.2,4.7-7.5,1-12.3c-1-1.6-0.7-3.4-1-6c7.3-3.4,14.8-7.3,20.7-10.8C2158.9,1012.4,2149.7,1013.9,2145.9,1009.8z M2121.1,974.2
    c-0.7,0-1,0-1.5,0.7c0,0.7,0.7,0.7,0.7,1c0.7,0,1,0.7,1.9,0.7C2121.8,975,2121.1,973.5,2121.1,974.2z M2225.3,1211.2
    c-1-1-1.9-2.5-3.2-3.2c-1.5-0.7-3.2,0-4.7,0.7c1,1,1.9,2.5,3.4,3.4C2222.1,1212.9,2223.7,1211.6,2225.3,1211.2z M2226.5,1152.1
    c-1,0-1.9,0-2.5,0c0.7,1,1.5,2.5,1.9,3.4c0.7-0.7,1.5-1,1.9-1.6C2227.5,1153.6,2227.1,1153.1,2226.5,1152.1z M2157.6,1072.8
    c-0.7,0.7-0.7,1.6-0.7,2.5l1.9,1c0-1.6,0.7-2.5,0.7-4.1C2159.2,1072.5,2158.2,1072.5,2157.6,1072.8z M2220,926.1
    c-1,0-1.9,0-3.4,0.7c1.5,0.7,1.9,1,3.2,1C2219.6,927.8,2220,926.5,2220,926.1z M2199.8,1263.4c-0.7-7.3-1.5-13.3-1.9-20
    c-0.7-6-0.7-12.3-1-22.4c-5.6,10.1-9.2,16.4-12.2,22.4C2178.4,1254.5,2181.6,1258.6,2199.8,1263.4z M2214.6,688.2
    c-2.5,0.7-5.1,0-7.5,1c-1,0.7-0.7,3.4-1,5.1c2.5,0.7,5.1,1,7.5,1.6C2213.3,693.3,2213.9,690.7,2214.6,688.2z M2259.2,614.9
    c0-2.5-0.7-4.8-1-6.7c-0.7-1-2.5-1-4.1-1.6c0,1.6-1,3.4-0.7,4.8C2255.8,613,2257.7,613.9,2259.2,614.9z M2255.2,501.6
    c3.4,7.3,7.5,10.8,15.4,14.9C2270.2,504.8,2263.7,503.8,2255.2,501.6z M2217.1,700.6c1,0.7,3.2,0,4.7,0c-1-1-1.9-2.5-3.2-3.4
    c-1-0.7-2.5-0.7-4.1-0.7C2214.9,698,2215.8,699.2,2217.1,700.6z M2225.6,778.3c0-3.2-1-8.2-3.2-8.9c-3.2-0.7-6.6,1.6-9.7,2.5
    c1.5,3.2,3.4,6,5.6,9.8C2221.5,780.2,2225.6,779.3,2225.6,778.3z M2247.2,400.9l7.5,1.6c-1.9-1.6-4.7-2.5-6.6-4.1L2247.2,400.9z
    M2211.4,356c-0.7,0-1,0.7-1.5,1c1,1,1.5,1.6,2.5,2.5c0.7-0.7,0.7-1.6,1-1.9C2212.4,356.9,2211.7,356.3,2211.4,356z M2173.6,661
    c-0.7,0.7-1,1.9-0.7,1.9c1.9,1.6,4.7,3.4,6.6,4.8c0.7,0,1.5-1,1.9-1.6c-1.5-1.9-3.2-4.1-4.7-5.1
    C2175.6,661.7,2174.3,661,2173.6,661z M2179.4,697.4c1.5,1,6-1.6,7.3-3.2c1.9-4.8-1-7.3-5.6-7.5c-2.5-0.7-5.1,1-9.7,1.6
    C2174.3,692.6,2176.2,695.8,2179.4,697.4z M2227.5,528.8c-1-0.7-2.5,0.7-5.1,1l8.8,3.2C2229.9,532,2229,529.8,2227.5,528.8z
    M2185.3,810.5L2185.3,810.5v-1.9l-1-0.7c0.7-0.7,0.7-0.7,1-1c2.5-2.5,7.3-7.3,2.5-13.3c0,0,0,0-0.7-0.7
    c-6.6,1.9-13.2,4.1-21.4,6.7C2173.6,803.9,2179.7,807.3,2185.3,810.5z M2193.2,637.6c-2.5-0.7-4.7-0.7-12.2-0.7
    c6.6,3.4,8.8,4.8,10.7,5.1c1.5,0.7,3.4-1,5.1-1.6C2195,639.5,2194.5,637.9,2193.2,637.6z M2177.7,600c-1-7.3-5.1-11.6-13.8-9.8
    c4.1,8.2,7.5,15.9,11.6,23.4C2176.8,609.5,2178.4,604.8,2177.7,600z M2175.5,642.9l1.5,1.6l0.7-1.6H2175.5z M2180.9,988.1
    c-8.8-9.8-1-22.2-6-33.8c-3.4,1-6.6,1.9-11.4,3.4c2.5,9.8,5.1,18.3,7.5,28.2C2174.3,986.6,2177.2,986.9,2180.9,988.1z M2195,781.7
    l-0.7,0.7c0.7,0.7,1.5,0.7,1.9,1C2196,782.7,2195.7,782.4,2195,781.7z M2183.7,856.2l1,1.9c0.7-0.7,1.9-1.6,1.5-1.6
    c-0.7-1-1.5-1.9-1.9-2.5L2183.7,856.2z M2157,619.6c6.6,0.7,15.8,6,18.9-5.7c-8.2-1.6-16.3-3.4-24.5-5.7
    C2147.6,616.1,2152.3,619.6,2157,619.6z M2095.6,1265.2c0.7,0.7,0.7,1,1,1.9c0.7-0.7,1.5-1,1.5-1.6c0-0.7-0.7-1-1-1.9
    C2096.6,1264.2,2096.3,1264.6,2095.6,1265.2z M2082.4,1208.4c-0.7,1-1,1.9-1.9,3.2l7.5,3.4
    C2086.5,1213,2084.3,1210.9,2082.4,1208.4z M2090,1216.6c-0.7,0.7-0.7,1.6-0.7,1.9c0.7,3.4,0,8.2,1.5,9.8c3.4,2.5,6-1,8.2-4.1
    C2096.3,1221.3,2093.1,1219.1,2090,1216.6z M2148.2,1153.6c-7.5,4.8-15.4,8.9-22.3,12.6l-0.7-0.7l0,0l0,0l0,0l0,0
    c-0.7,0.7-1,1.6-1.5,1.9c-2.5,1.9-5.6,4.1-8.2,6l1.9,1.9c1.9-2.5,4.1-5.7,6-8.2c0.7-0.7,1-1,1.5-1.6l0,0l0,0
    c1.5,2.5,3.4,5.1,3.2,6.7c-2.5,10.1-6,20.5-16.7,25.6c-15.8-15.5-16.3-15.5-17.3-31.3c-0.7-5.1-1-8.2-4.1-12.6
    c-9.7-13.3-14.1-26.3-15.4-37.9c6,3.4,10.7,8.2,16.7,10.1c7.3,1.9,15.4,1.9,22.9,3.2c3.2-3.2,6.6-6,10-9.8
    c7.3,5.7,13.8,11.4,20.4,16.7C2146.6,1142,2147.5,1147.6,2148.2,1153.6z M2111.4,1161.9c-0.7,0-1-0.7-1.5-0.7c0,0.7,0,0.7-0.7,1
    C2109.8,1162.8,2111.1,1161.9,2111.4,1161.9z M2167.3,611.4c0,0,3.2-2.5,3.2-3.2c-0.7-1.6-1.9-3.2-3.4-4.1c-1-0.7-2.5,0.7-5.1,1.6
    C2163.9,608.1,2165.5,609.7,2167.3,611.4z M2183.8,622.1c1,1,2.5,1.9,3.4,3.2c0.7-1,1.9-2.5,1.9-3.2c-0.7-1.6-1.9-2.5-3.2-3.4
    C2185.3,619.6,2184.3,620.5,2183.8,622.1z M2202,427.4c0,1.6,0.7,4.1,1.5,5.1c0.7,0.7,4.1-0.7,4.1-1c0-2.5-0.7-4.8-1-7.5
    C2204.2,426.1,2202,426.5,2202,427.4z M2197.9,502.6c0.7,0,1.9-1.6,3.2-1.9c-1.5-1-3.2-1.9-4.7-2.5c-0.7,0-1.5,1-1.9,1.6
    C2195,500.7,2196.6,502.3,2197.9,502.6z M2100.7,1224.8c1.5,1.6,3.2,3.2,5.1,4.1c1,0.7,2.5-0.7,4.1-1c-0.7-0.7-1-1.6-1.5-1.6
    C2105.4,1225.8,2103.1,1225.4,2100.7,1224.8z M541.2,2128.1C541.2,2128.1,541.9,2128.1,541.2,2128.1
    C541.9,2128.1,541.9,2128.7,541.2,2128.1L541.2,2128.1c0.7,0,1,0,1,0s0,0,0.7,0c0,0,0,0-0.7,0h0.7c-0.7,0-0.7,0-1,0
    C541.9,2128.1,541.9,2128.1,541.2,2128.1C541.9,2128.1,541.2,2128.1,541.2,2128.1z M315.4,1144c0,1,0,1.9,0,3.2
    c0.7-0.7,1-1,1.5-1.6C317,1145,316.1,1144.7,315.4,1144z M346.5,1020.8c0.7,0,0.7-0.7,1-0.7c-0.7,0-1-0.7-1-0.7c-0.7,0.7-0.7,1-1,1
    C345.7,1021.4,345.9,1021.4,346.5,1020.8L346.5,1020.8z M356.6,1029.1h-0.7c0.7,0.7,0.7,1,1,1.6L356.6,1029.1z M351,1022.8
    c0.7,0.7,1,1,1.5,1.6c0-1,0-1.6,0-1.9l0,0l0,0l0,0l0,0c-0.7,0-0.7,0-1-0.7C351,1022.4,351,1022.4,351,1022.8L351,1022.8z
    M356.4,1029.3c-1-1.6-2.5-3.4-4.1-5.1C352.3,1026.9,350.6,1030.6,356.4,1029.3z M351.6,1017.3c-1.5,1-3.2,1.9-4.1,3.2
    c1,0.7,2.5,1,3.4,1.6C351.6,1020.2,351.6,1018.6,351.6,1017.3z M339,1137.5c-0.7,0-0.7,0-1-0.7c1.9,6.7,3.2,6.7,10.7,1
    C345.7,1138,342.5,1137.5,339,1137.5z M324.3,1045.8c0.7,1.9,1.5,5.1,3.2,6s4.7-1,9.7-1.9c-4.1-3.4-6.6-5.7-9.2-7.5
    C327,1042.3,324.3,1044.8,324.3,1045.8z M300,1116.9c-0.7-0.7-1-0.7-1.9-1c0,1-0.7,1.9-0.7,3.2h1.9
    C299.7,1118.4,300,1117.5,300,1116.9z M260.1,1146.6c7.3,8.9,1,20.8,13.2,23.1c9.2-21.5,10.7-26.6,8.8-37.2
    C269.5,1129.8,272.6,1147.2,260.1,1146.6z M278.6,1092.9c0.7,1,0.7,1.9,1,3.2c0.7,0,0.7-0.7,1-0.7l-1-1
    C279.3,1094.2,278.6,1093.5,278.6,1092.9z M339,1121c-0.7,0-1,0-1,0.7c0.7,0,0.7,0.7,1,0.7C339,1121.6,339,1121.6,339,1121z
    M336.8,1121c0,0.7,0,0.7,0,1c0,0,0.7,0,0.7-0.7L336.8,1121z M336.8,1121l0.7,1h0.7C337.4,1121,336.8,1121,336.8,1121z
    M284.9,1029.3c1.9,5.7,6.6,6,11.6,4.8c1.5-0.7,2.5-2.5,3.4-4.1c-1.5-1-2.5-3.2-4.1-3.2c-0.7,0-1.5,0-1.9-0.7
    C290,1028.1,286.8,1029.1,284.9,1029.3C284.9,1029.1,284.9,1029.1,284.9,1029.3z M513.6,1027.4l-0.7,1c-0.7-0.7-0.7-0.7-1-1
    c-7.3,3.2-14.8,5.7-21.4,8.9c1,3.4,1.5,6,2.5,9.2c-5.1-0.7-10.7-1.9-14.1-0.7c-16.7,8.2-31.4,2.5-46.2-4.8l0,0l0,0l0,0l0.7,1
    c0,0,0,0,0-0.7v0.7c0-0.7-0.7-0.7-0.7-0.7c-4.7,11.4-13.2,14.9-24.5,12.3c-4.7-0.7-8.8-0.7-13.2-1l0,0c0,0,0,0-0.7,0h-0.7l0,0
    c-7.5-4.8-19.5,0-24.5-14.2c1.5-4.1,4.7-10.1,6-16.4c0.7-2.5-1.5-6-3.2-11.6c-6.6,7.5-11.4,13.3-15.4,19c1.9-9.8,4.1-19.6,7.3-29
    c0.7-1.6,4.1-3.2,6-4.8c2.5,1,5.1,1.9,7.5,3.4c1,0.7,1.5,1,2.5,1.6c-0.7-1-1-1.9-1.9-2.5c-2.5-1-5.1-1.9-8.2-3.2
    c4.1-1.6,8.8-3.2,13.2-5.1c1,0.7,1.9,1,2.5,1l0,0c0.7,0,1.5-0.7,3.2-1.6c-0.7-0.7-1-1-1-1c1.5-0.7,2.5-1,4.1-1.6
    c-4.1-5.1-7.5-8.9-11.4-12.6c12.2-11.6,12.6-11.6,22.1,0.7l0,0v0.7l0,0l0,0c1,2.5,1.5,4.8,2.5,6.7s1.9,3.2,3.2,4.1
    c-1.5-0.7-3.2-0.7-4.1-0.7c-1.9,0-4.7,0.7-7.3,4.1c0,0,0.7,0,0.7,0.7c4.1-0.7,8.2-1.6,12.2-2.5c2.5,1.9,5.1,1,10.7-1.9
    c1.9-1.6,4.7-3.2,7.3-4.1c3.4,5.1,8.2,10.1,10.7,15.9c2.5,5.1,3.2,11.4-5.6,14.9c-4.7-10.1-12.6-15.5-23.9-12.3
    c-1,16.7-1,16.7,15.4,29h0.7c1,1.9,2.5,4.1,3.4,5.7c-1-1.9-2.5-4.1-3.4-6h-0.8c5.6-3.2,10.7-6,16.3-8.2c7.3-2.5,14.8-6,21.4-5.1
    c6,0.7,9.7-0.7,14.8-4.1c10-7.3,20.4-16.7,34.6-13.9c0.7-0.7,0.7-0.7,1-1l0,0l0.7,0.7c13.2-3.4,15.8-1.9,16.3,9.2
    c0,2.5-1.5,5.1-2.5,7.5c0,0.7,0.7,0.7,0.7,1c-0.7,0-0.7,0.7-1,0.7C527.5,1026.9,526.8,1027.4,513.6,1027.4z M351.6,1069.5l1.5,1.6
    l0.7-1.9L351.6,1069.5z M335.3,1129.8L335.3,1129.8c0.7,0,0.7,0,0.7-0.7L335.3,1129.8z M314.4,1004c-2.5-1.9-5.6-3.2-8.8-4.8
    c-1,1-1.9,1.9-3.2,3.2c1.5,1.9,3.2,5.1,5.1,6.7c1.9,1,4.7,0,7.3,0C314.8,1006.9,315.4,1004.4,314.4,1004z M345.7,1021.4
    c-6.6,0.7-13.8,1-22.3,1.9C335,1034.1,335.3,1034.1,345.7,1021.4z M282.5,1045.5l1.5,0.7l-0.7-1.9L282.5,1045.5z M481.9,2252.2
    c-14.1,1.9-29.6,3.2-38.1,20c5.1,7.5,10,9.8,18,9.2c6-0.7,12.2,3.4,18.9,5.7c5.1-9.8,10-18.3,16.3-29
    C491.6,2255.7,486.3,2251.5,481.9,2252.2z M214.7,2010.5L214.7,2010.5c-1.5-0.7-3.2-0.7-3.4-0.7
    C211.3,2009.2,213.2,2009.8,214.7,2010.5z M230.2,2005.1c-5.1,1.6-10.7,3.2-15.8,4.8v0.7c-5.6,9.2-4.1,18.1,3.2,26.3
    c12.6-6,15.8-7.5,15.4-16.4c-1-4.1-1.5-7.3-1.5-9.8C231.1,2008.2,230.5,2006.7,230.2,2005.1z M114,2239.1
    c4.1,3.4,5.6,11.4,14.8,4.1c-2.5-3.4-4.7-7.3-7.5-10.8c-5.1-6-7.5-11.6-3.4-20.8c3.2-7.5,1.5-16.7,6.6-26.3
    c4.1-7.3,3.4-17.4,5.6-26.6c-2.5,0-5.1,1-6.6,3.2c-1.9,1.9-3.2,4.8-4.7,7.3s-3.2,5.1-4.7,7.3l0,0c-1.9,1.9-5.1,3.4-10.7,4.8
    c-1,0-1.5,0.7-2.5,0.7c-10,15.5-4.7,32.3-5.1,48.7C102.4,2233.3,109.4,2235,114,2239.1z M353.8,2176.1c-1,0.7-1.9,1-1.9,1.6
    c-0.7,0.7,0.7,1.6,1,1.9c1,0,1.9,0,2.5,0C354.7,2178.3,354.4,2177.4,353.8,2176.1z M435.2,1904.9l-1,1.6l2.5,0.7L435.2,1904.9z
    M372.3,2114.1c0,1,0,2.5-0.7,3.4c0.7,0,1.9,0,1.9-0.7c0.7-0.7,0-1.6,0-1.9S372.7,2114.1,372.3,2114.1z M394.6,1954.6
    c-1-1.6-1.5-2.5-1-3.4c0-0.7,1-1.6,1.5-1.9c1,1,1.5,1.6,2.5,2.5c-3.2-6-9.2-9.8-7.5-18.1c3.4-3.2,7.5-6.7,11.6-10.1
    c-9.7,3.2-13.8,7.3-22.1,15.5c-1,1-1.9,1.9-3.2,2.5c6.6,5.7,13.8,11.6,22.1,18.3C397.2,1958,395.6,1956.7,394.6,1954.6z
    M428.1,979.8C428.1,980.3,428.1,980.3,428.1,979.8c1,0.7,1.5,0.7,1.9,0.7C428.9,980.3,428.6,979.8,428.1,979.8
    C427.4,979.8,427.4,979.8,428.1,979.8C427.4,979.8,427.4,979.8,428.1,979.8L428.1,979.8C428.1,980.3,428.1,980.3,428.1,979.8
    L428.1,979.8z M357.2,1030.6c0-1,0.7-1.6,0.7-2.5c-0.7,0.7-0.7,1-1,1L357.2,1030.6z M335.8,1129.2L335.8,1129.2
    c0-0.7-0.7-0.7-0.7-0.7c0,0.7,0,0.7,0,1L335.8,1129.2z M83.9,2260.2c-1-1-3.4-0.7-5.1-1c0.7,1.6,0,4.1,1,5.1c0.7,1,3.2,0.7,4.7,0.7
    C84.5,2263.6,84.9,2261.1,83.9,2260.2z M122.6,2006.4l1.9-0.7l-1.5-1L122.6,2006.4z M215.4,1982.3c3.2-1.6,7.5-2.5,8.8-5.1
    c1-2.5-0.7-8.2-3.2-9.8c-5.6-4.1-12.6-6.7-20.7-10.8c-0.7,11.4,10.7,7.3,12.6,13.3c0,4.1,0,8.9-0.7,13.3l-8.8-3.4l-0.7,1
    c3.2,1,5.6,1.6,8.8,2.5c0,0,0,0,0.7,0l0,0C213.8,1982.6,214.7,1982.6,215.4,1982.3z M139.9,2271.2c0.7,0,0.7-0.7,1-0.7
    c3.2-1.9,6-4.1,7.5-7.5l0.7-0.7v-0.7c0-1.9-0.7-4.1-1-6C143.3,2259.5,139.9,2264.3,139.9,2271.2z M180.7,2282
    c-2.5,1.6-4.7,2.5-7.3,4.1l0,0c2.5,0,5.1,0,8.2,0c1,1.6,1.9,3.4,1.9,5.1c0.7,0,1-0.7,1-0.7C183.9,2287.3,182.4,2285.4,180.7,2282z
    M148.7,2092.4l1.5-0.7c0-0.7-0.7-1-0.7-1l-0.7-0.7L148.7,2092.4z M174.2,2161.9c-0.7-0.7-1-1.6-1.5-1.9c0.7-6-1.5-10.8-4.1-14.2
    c-1.5,0.7-2.5,1-3.2,1.9c-1.9,3.4-3.2,9.2-1.9,13.3c1.2,4.1,5.6,7.3,9.2,11.4c0.7,0,1,0,1.5-0.7c0.7-0.7,1.5-0.7,1.9-1l2.5-1.6
    l-1.5-2.5C176.1,2165,175.1,2163.4,174.2,2161.9z M169.5,2245.3L169.5,2245.3L169.5,2245.3C169.5,2245.7,169.5,2245.7,169.5,2245.3
    z M83.8,2297.3c3.4-12.3-1.5-19-8.2-21.5c-9.2-3.4-19.9-3.4-35.2-6C53.6,2289.1,69.4,2290.6,83.8,2297.3z M417.9,2010.8
    c-0.7-0.7-2.5-0.7-2.5-0.7c-0.7,1.6-0.7,3.2-0.7,4.8c1.5,0,3.4,0,5.1,0C418.9,2014,418.6,2012.3,417.9,2010.8z M66.7,2146.4l-1,1
    h1.9L66.7,2146.4z M159.4,2014c0.7,2.5,1,5.1,1.5,7.5c2.5-0.7,4.7-0.7,6-1c-0.7-2.5-1-5.1-2.5-7.5
    C163.9,2012.3,161,2013.3,159.4,2014z M259.1,2158.4c-0.7-0.7-1.5-0.7-2.5-1c0,0.7-0.7,1.9-0.7,1.9c0.7,0.7,1.5,0.7,2.5,1
    C259.1,2159.3,259.7,2158.4,259.1,2158.4z M278.6,2245.3c-10-3.2-15.4-4.1-20.7-6C259.7,2253.5,259.7,2253.5,278.6,2245.3z
    M225.4,2113.9h-1.9l1.5,1.6L225.4,2113.9z M227.9,2098.4c-1.5-0.7-3.4-0.7-5.1,0c-1,0.7-1,2.5-1.5,4.1c2.5,0.7,4.7,0.7,7.3,1
    C228.6,2100.9,228.6,2099,227.9,2098.4z M245.3,2173.5c1,0,1.5,0,3.2,0l-1.9-1.6C246.5,2172.6,245.9,2173.3,245.3,2173.5z
    M273.3,2210.9c-5.1,7.5-3.4,13.9-7.5,24.9C280.5,2226.9,274.5,2220.3,273.3,2210.9z M257.5,2270.9l0.7-1.9l-1.9,1L257.5,2270.9z
    M261.6,2068.3c-6.6-9.2-11.4-3.4-15.8,2.5c4.7,6,13.8,10.1,9.7,22.4c8.2-0.7,15.8-1,22.9-1.6c-1.5-8.9-3.2-16.4-4.7-24.1
    C269.8,2067.7,266.3,2074.3,261.6,2068.3z M335.3,1129c0.7-1.9,1-4.8,1-6.7C333.6,1124.2,328.7,1125.7,335.3,1129z M202.5,2306.6
    c0.7,0,1.5,0,1.9,0.7C204.7,2306.6,203.8,2306.6,202.5,2306.6z M209.1,2157.1c-1.9,3.4-6.6,5.7-11.4,7.5c-1,0.7-1.9,1.6-3.2,3.2
    c8.8-1.6,18-3.2,28.9-5.1C218.9,2160.9,213.8,2159.3,209.1,2157.1z M180.4,2282.7c1.5,2.5,2.5,5.1,3.4,7.5c1.5-1,4.1-1.6,4.1-2.5
    c0-0.7,0-1.6,0-1.9c-1.5,0-3.4-0.7-5.6-1c0,0-0.7-0.7-0.7-1c0-0.7,0.7-1,1-1C181.6,2282.7,180.6,2282.7,180.4,2282.7z M96.2,2030.4
    c0,1.6,1,3.4,2.5,4.1c1,0.7,2.5-0.7,5.1-1.6c-1.5-1.9-3.2-3.4-4.7-5.7C98.1,2028.2,96.2,2029.4,96.2,2030.4z M400,947.5
    c2.5-0.7,5.6-1,8.2-1.6c-4.7-4.1-9.2-8.9-14.1-13.3C388.7,947.2,389.3,948.7,400,947.5z M375.4,952.3c-0.7-0.7-1-0.7-1.9-1
    c0,1,0,2.5,0,3.4c0.7,0,1.5,0,1.9-0.7C376.1,954.2,375.4,953.2,375.4,952.3z M694.5,2035.4c0-0.7-1.5,0-1.9,0v1.9c1,0,1.5,0,1.9,0
    C694.5,2037.1,695,2036.1,694.5,2035.4z M360.7,978.4l1.5,1.6l0.7-1.6H360.7z M408.5,946.5c-0.7,1.6-1,3.2-1.5,4.8
    C407.5,949.7,408.2,948.2,408.5,946.5z M408.5,946.5L408.5,946.5L408.5,946.5L408.5,946.5z M408.5,946.3h-0.3
    C408.2,946.3,408.5,946.5,408.5,946.3c0,0.7,0,0.7,0,0.7C408.5,946.5,408.5,946.3,408.5,946.3z M511.7,2888.5
    c-0.7,1.6,0,3.4,0.7,5.7c1.5-0.7,4.1-0.7,4.1-1c0.7-2.5,0-5.1,0.7-8.2C513.9,2886.6,511.7,2886.9,511.7,2888.5z M499.1,2901.8
    c-2.5,4.1-4.7,8.2-6.6,12.3C500.6,2911.5,500.6,2910.9,499.1,2901.8z M484.3,2892.6c-1.9,3.2-4.1,5.7-6,8.9
    C482.8,2900.1,483.6,2896.7,484.3,2892.6z M472.1,2747.1c2.5,1,5.6,1.9,8.2,2.5c0,0.7,0.7,1,0.7,1.9c-4.7,6.7-10.7,8.2-17.3,4.8
    c-1-0.7-1.9-1-3.2-1.6c-0.7-1-0.7-1.6-1-2.5c0-0.7,0-0.7-0.7-1c-0.7,0.7-1,1-1.5,1.6c-9.7-4.1-19.5-6-30.2-6.7
    c-2.5,0-5.1-2.5-8.8-4.8c2.5-6,5.6-12.3,6.6-18.3c0.7-3.2-1.5-8.2-3.4-9.8c-1.9-1-6,1.6-9.2,2.5c-14.8-2.5-16.3-4.8-16.3-20
    c-3.2,5.7-6.6,11.4-9.7,17.4c-4.1,7.5-8.2,8.9-15.8,4.1c-6-4.1-10.7-8.2-4.7-15.5c-6.6-7.3-5.1-13.9,1-20.5c2.5-3.2,4.1-6,1.5-9.8
    c6,1.6,12.2,3.4,18.2,5.7c-1.5,8.9,3.4,12.6,10,16.4c-1.5-6.7-1.5-13.9-9.2-16.7c1.9-5.1,5.6-8.2,10.7-8.2c2.5,1.6,5.1,2.5,7.5,3.2
    c4.7,3.2,8.8,7.3,13.2,10.1c18,10.8,18.2,11.4,25.5,23.4c-3.2,14.2-1.5,27.2,10.7,37c3.2,1.9,6,4.1,8.8,6c1.5-1,2.5-1.9,3.4-2.5
    C469.5,2746.9,471.1,2747.1,472.1,2747.1z M374.9,2701.4c-0.7-0.7-1.5-1-1.9-1.6l0,0c0,0.7,0.7,1.6,0.7,1.9
    C373.6,2701.7,374.2,2701.4,374.9,2701.4z M411.9,2689.1c-1,0-1.9,0.7-2.5,1c-0.7,1.6-0.7,3.4-0.7,6c1-1,1.9-1.9,1.9-3.2l0,0
    C411.9,2691.6,411.9,2689.9,411.9,2689.1z M434.3,2694c-1.5-1.6-3.4-1.9-5.6-2.5C430.3,2692.5,432.5,2693.2,434.3,2694z
    M504.7,2779.4c0.7,9.2,0.7,18.1,1,27.5c-7.3-1.9-12.2-4.8-17.3-5.1c-11.4-1-10.7-8.2-10.7-17.4c5.1-1.9,10-4.8,15.8-6
    C496.9,2778.5,500.6,2779.1,504.7,2779.4z M496.9,2799.4c0-0.7-0.7-1.6-0.7-1.6c-1.9,1-4.7,1.6-6.6,2.5v0.7c0,0,0.7,0.7,1,0.7
    C492.5,2800.9,494.3,2800,496.9,2799.4L496.9,2799.4z M510.1,2976c3.4,4.8,7.3,9.8,11.6,16.4C522.4,2976.9,522.4,2976.9,510.1,2976
    z M503.5,2405.8c0,1,0,2.5-0.7,3.4c0.7,0,1.5,0,1.9-0.7c0.7-0.7,0-1.6,0-1.9C504.7,2406.5,504.2,2406.5,503.5,2405.8z
    M508.2,2447.3l-0.7-0.7l0,0C507.6,2447,507.6,2447.3,508.2,2447.3z M502.5,2357.9c0.7-1,1-1.6,1-2.5c0-1.6-0.7-2.5-1-4.1
    c-0.7,1-1,2.5-1.5,3.4C501,2356.2,502,2357.2,502.5,2357.9z M523.9,2209c-6-8.9-7.5-9.2-28.6-14.9
    C504.7,2214.6,504.7,2214.6,523.9,2209z M432.7,2925.5c1.9,0,3.4,0,5.6,0.7C436.8,2925.5,434.9,2925.5,432.7,2925.5L432.7,2925.5z
    M509.8,2324c-2.5-0.7-5.1,0-7.5,0.7c0.7,1.6,0.7,4.8,1.5,4.8c2.5,0.7,5.6,0,8.8-0.7C510.7,2326.5,510.1,2324,509.8,2324z
    M576.8,2130.3c0.7,1.6,1.5,3.2,1.9,4.1l1.9-1.6c-1-1.6-1.5-2.5-2.5-4.1C578.4,2129.6,576.8,2130.3,576.8,2130.3z M571.5,2314.2
    c5.1,1,10,1.6,15.4,2.5l0,0c-4.1-2.5-8.8-5.1-12.6-7.5C573.3,2310.7,572.3,2313,571.5,2314.2z M579.6,2381.8c0,1.9,1.9,5.1,3.4,7.3
    c1,1,4.7,1,5.1,0c3.4-4.1,7.5-8.2,9.7-13.3c1-2.5-2.5-6-3.4-9.8C583.4,2367.6,580.5,2374.3,579.6,2381.8z M570.8,2324.9
    c1-1,3.2-1.9,3.2-3.2c-0.7-1.9-1.5-4.8-2.5-6.7c-4.1,1.9-5.6,4.8-2.5,8.9C569.3,2324.6,570.1,2324.9,570.8,2324.9z M587.5,2309.2
    c-0.7,2.5,0,5.1,0,7.5c1.9-1,5.1-1.9,6-4.1c0.7-1.6-1.5-4.1-1.9-6.7C589.7,2307.3,587.5,2307.5,587.5,2309.2z M569.9,2275
    c-0.7-0.7-1-1-1.5-1c-0.7-0.7-1-0.7-1.5-1C567.7,2273.4,568.9,2274.4,569.9,2275z M567.4,2305.1c1.9,1,3.4,1.6,5.6,2.5
    c-1-1.9-1.5-4.1-3.2-5.7c-0.7-0.7-2.5,0.7-4.1,1C566.1,2303.4,566.1,2304.8,567.4,2305.1z M619.2,2429.6l-1.9,1
    c0.7,0.7,1,1.6,1.5,1.6c0.7,0,1-0.7,1.9-1C620.2,2430.6,619.6,2430.6,619.2,2429.6z M603.8,2270.9c-2.5-1.6-5.6-1-13.8-2.5
    c4.1,6.7,5.6,10.8,8.2,12.3c2.5,1.6,7.3,1.6,10.7,1c1,0,3.4-4.1,3.2-4.1C609.5,2275.4,607,2272.5,603.8,2270.9z M598.8,2198.9
    c1-7.5,1-13.9-13.8-14.9C591.5,2190.9,595.3,2194.7,598.8,2198.9z M618.6,2119.9c3.2-1,6-7.3,6.6-11.4c1-5.1-3.2-9.8-8.2-9.8
    c-1.5,0-3.2,0-4.7,0l-4.1,10.1c-1-1.9-1.9-4.1-3.2-5.7c-1-1.6-1.5-3.2-2.5-4.8c-5.6,0-11.4,0-16.7,0c-1.9,1-3.4,1.6-5.6,2.5
    c0.7,4.8,2.5,8.9-1.9,9.8c0.7,1,0.7,1.9,1,2.5c-1,0-1.9,0-3.2,0c-0.7,0-0.7,0-1,0C588.8,2120.8,603.6,2124,618.6,2119.9z
    M620.8,2197.6l0.7-0.7h0.7l0,0C621.8,2197.2,621.1,2197.2,620.8,2197.6L620.8,2197.6z M603.8,2214
    C603.8,2214,603.6,2214,603.8,2214c-2.5,5.7-4.7,10.8-6.6,15.9l10,4.1C606.3,2227.3,605.4,2220.6,603.8,2214z M511.3,2230.5
    c0,1,0,1.9,0,3.4c1,0,1.5,0,2.5-0.7c0-0.7,0-1.6-0.7-1.9C513.2,2231,512.3,2231,511.3,2230.5z M589,2160.3c-0.7-0.7-1.5,0-2.5,0.7
    c0.7,0.7,1,1.6,1.5,1.9c1,0,1.5-0.7,2.5-0.7C590.3,2161.2,589.7,2160.3,589,2160.3z M603.8,2213.4L603.8,2213.4L603.8,2213.4
    C603.8,2214,604.4,2214,603.8,2213.4L603.8,2213.4z M624.8,2055.4c0.7,0.7,1,1.6,1.5,1.6c0.7,0,1.5,0,1.9-0.7c-0.7-0.7-1-1-1.5-1.6
    C626.5,2055,625.8,2055.4,624.8,2055.4z M646.6,2037.9c-0.7-0.7-1.9,0-2.5,0.7c-4.1,5.1,0.7,6.7,3.2,9.2
    C648.8,2044.6,652.3,2041.2,646.6,2037.9z M612.1,2077.9c0.7,1,1,1.9,1.5,3.2c0.7,1.6,1.5,2.5,1.9,4.1c7.3,5.7,15.4,9.2,26.1,3.4
    c1-6-3.2-11.4-7.5-10.1C626.8,2079.4,619.2,2080,612.1,2077.9z M519.8,2720.7c1-0.7,1.9-0.7,3.2-1c1-1,1.5-2.5,2.5-4.1
    c-1,1-1.9,1.9-3.2,3.4C521.4,2719.7,520.5,2720.3,519.8,2720.7z M644,2104c0.7,0.7,1.5,1.6,1.9,1.6c0.3,0,1-1,1.5-1.9
    c-0.7-0.7-1.5-1-1.9-1.6C645,2103.1,644.7,2103.3,644,2104z M517.3,2176.1c4.1,1,6,2.5,7.5,1.9c5.1-1.6,9.7-3.4,15.8-5.7
    c0,0,0,0,0-0.7C531.5,2167.9,524.6,2167.9,517.3,2176.1z M545.3,2548.1c-0.7-1.6-1-6-1.9-6c-4.1-0.7-9.2-1-12.2,1
    c-3.4,2.5-1,7.3,2.5,8.9C537.8,2553.8,542.2,2554.4,545.3,2548.1z M624.8,2301.5v-1.6h-1.9L624.8,2301.5z M621.1,2261.1
    c-0.7-0.7-1.5-0.7-2.5-1c0,1-1,1.9-0.7,1.9c0.7,0.7,1.5,0.7,2.5,1C620.8,2262.1,621.1,2261.1,621.1,2261.1z M637.2,2318.3
    c-1.5-0.7-3.2-1.9-4.1-1.6c-1,0.7-1.9,2.5-3.2,4.1c1.5-0.7,3.2-0.7,5.1-1.6C635.5,2319.6,635.9,2318.9,637.2,2318.3z M629.3,2321.2
    L629.3,2321.2c-1-0.7-2.5-1.6-4.1-1.9C626.5,2319.6,627.7,2319.9,629.3,2321.2z M641.6,2288.2c-1.9-0.7-3.4-1-5.6-1.6
    C638.1,2287.4,639.7,2287.7,641.6,2288.2L641.6,2288.2z M631.8,2146L631.8,2146c-0.7,0.7-0.7,0.7-0.7,0.7L631.8,2146L631.8,2146z
    M645,2244.3c-1.9-0.7-4.1-0.7-6.6-1c-0.7-12.6-1.9-15.5-11.6-13.9c-6.6,1-12.6,4.1-18.9,6c0,0,0,0,0-0.7
    c-5.1,3.2-8.2,0.7-10.7-4.1l0,0c-4.1,0.7-8.2,0.7-11.6,1c-1,1.6-1.9,3.2-3.2,4.8l0,0l0,0l0,0l0,0l0,0c0,0.7,0,0.7-0.7,1
    c4.1,3.2,8.8,6.7,14.1,11.4c8.8-1,18.9-1.9,27.4-7.5c6-4.1,10.7-1,14.8,2.5c1.9,0.7,4.1,1,6,1.6C645,2244.7,645,2244.3,645,2244.3z
    M365.4,2929.6l-1.9,0.7l1.5,1.6L365.4,2929.6z M361.6,2803.5c-1,0-1.5,1-1.9,1.9c1.5,0.7,2.5,1,3.4,1.6c0-0.7,0.7-1.6,0.7-2.5
    C362.9,2804.1,362,2803.1,361.6,2803.5z M453.8,2800.9c-0.7-0.7-1.9-1-1.9-1c-0.7,1-1.5,1.9-1.9,3.4l1.9,1
    C452.2,2803.1,453.2,2802.2,453.8,2800.9z M411.9,2717.4c-1,0.7-2.5,1.6-3.2,2.5c-0.7,0.7,1.5,2.5,1.9,3.4c0.7-1.6,1.5-2.5,1.5-4.1
    C412.9,2719.1,412.3,2718.8,411.9,2717.4z M381.5,2042.7l-1.5-1l-0.7,1.6L381.5,2042.7z M437.4,2831.3c0.7,1.6,1.5,2.5,2.5,4.1
    c0,0,1.5-1,1.9-1c-1-1.6-1.5-3.2-2.5-4.8C438.4,2830.3,436.8,2830.6,437.4,2831.3z M471.1,2745.3c1,0.7,1.9,1,3.2,1
    c1.9,0.7,3.4,1.6,5.6,1.9C477,2744.7,474.6,2743.7,471.1,2745.3z M443.1,2850.6c0.7,0.7,1,1,1.5,1.6c1-1,1.9-1.9,3.2-3.2
    c-0.7-0.7-1.5-1.6-1.5-1.6C444.4,2848.7,443.4,2849.6,443.1,2850.6z M476.5,2901.8c-3.2-1-6.6-2.5-9.7-4.1
    C468,2906.5,469.5,2907.1,476.5,2901.8z M375.2,1984.9c4.7,3.4,7.5,5.7,11.6,8.9C386.8,1985.2,383.4,1983.6,375.2,1984.9z
    M342.1,2195.7c1,1.9,1.5,4.1,2.5,6c1.5-1,3.4-1.6,4.1-2.5c0-1.6-0.7-2.5-1.5-4.1c-1,0-1.5-0.7-2.5-0.7
    C344,2195,343.1,2195,342.1,2195.7z M431.1,2201.3c0-2.5,0-4.1,0.7-6c-3.2,1-6,1.6-9.2,2.5c0.7,1.6,1,4.8,1.9,4.8
    C426.7,2202.3,429.3,2201.3,431.1,2201.3z M467,2275.4c0,0,0.7,1.6,0.7,1.9c1.5-0.7,3.2-1,4.1-1.9c0,0-0.7-1.6-0.7-1.9
    C469.9,2274.4,468.5,2275,467,2275.4z M427.7,2904.3c0.7,0.7,1,1.6,1.5,1.9c0.7,0.7,1.5,0,2.5-0.7c-0.7-0.7-1-1.9-1.5-1.9
    C429.6,2904,428.6,2904.3,427.7,2904.3z M443.4,2758.6L443.4,2758.6c0.7,0,1.5,0,1.9,0C445,2757.9,444,2758.6,443.4,2758.6z
    M469.5,2804.7H468l1,1.6L469.5,2804.7z M444.4,2760.4l-1-1.6c0,0.7,0,1,0,1.6C444,2760.4,444,2760.4,444.4,2760.4z M471.1,2826.2
    c-1-1-2.5-3.2-3.4-3.2c-1.9,0.7-4.1,1.9-6,3.4c1.5,1.9,2.5,3.2,3.4,4.8C467,2829.1,468.9,2827.8,471.1,2826.2z M495,2734.8
    c0.7-0.7,1-1.6,1.5-1.9c-0.7-0.7-1-1-1.5-1C495,2733,495,2734,495,2734.8z M343.1,2772.5l1.9-0.7l-1.9-1.6V2772.5z M476.1,2716.2
    c1-3.2,1.9-6.7,3.2-9.8c-0.7,0-1,0-1,0C477.7,2709.6,476.5,2713.1,476.1,2716.2L476.1,2716.2z M322.4,2741.2c0.7,0,1.5-0.7,1.9-1
    c0-1,0-1.6,0-1.9c-1,0.7-2.5,1-3.4,1.6C321.4,2740.3,321.7,2741.2,322.4,2741.2z M2301.4,260.9
    C2301.4,260.2,2301.4,260.2,2301.4,260.9c-1,0-1.5,0.7-1.9,1c0,0.7,0.7,1.6,0.7,1.9c0.7-0.7,1-0.7,1.5-1L2301.4,260.9L2301.4,260.9
    z M334.9,2854.3c0.7,1,1.5,1.9,1.9,3.2c1-0.7,1.5-0.7,1.9-1c-1-1-1.5-2.5-2.5-3.2C337.2,2853.3,335.5,2854.3,334.9,2854.3z
    M462.9,2701.4c-6-5.1-10-3.4-17.3,5.7c8.8,7.5,20.4,5.1,30.2,9.8C471.4,2710.9,468,2705.8,462.9,2701.4z M343.1,2948.6
    c-0.7,0.7-1,1-1.5,1.9c0,1,1,1.9,1.5,3.2C343.1,2952,343.1,2950.1,343.1,2948.6z M333.1,2929.9c1.9,0,4.7-0.7,7.3-1.6
    c-1.5-1.9-2.5-4.1-3.4-4.1c-1.9,0-4.1,1-6.6,1.9C330.9,2927.9,332.1,2929.9,333.1,2929.9z M349.4,2952.7c-1-1.9-2.5-4.1-3.4-6
    c-0.7,0.7-0.7,0.7-1,1c1,0.7,1.5,1.6,1.9,1.9c0.7,1-0.7,2.5-1,3.4c-0.7,0.7-0.7,1-1,1.6l0,0l0,0
    C346.2,2953.9,347.2,2953,349.4,2952.7z M465.8,2677c-1,0.7-3.2,1-3.4,1.6c-0.7,1.6,0,3.4,0.7,5.1h0.7l-0.7-0.7
    c1-1.6,2.5-2.5,3.4-4.1C466.3,2679.2,465.8,2678.3,465.8,2677z M900.8,1930.5c-6,4.8-11.4,8.2-19.9,14.2
    c12.6,5.7,20.4,8.9,29.6,12.6C906.8,1948.5,903.6,1940.6,900.8,1930.5z M918.4,1848.6c-5.1-1.6-6.6-11.4-13.8-7.5
    c-5.6,3.2-10,8.2-13.8,13.9c-4.7,6.7-1.9,12.3,5.6,16.4c12.6,5.7,24.8,9.8,38.1,1c3.2-1.9,7.3-1.9,12.6-3.4
    C940.4,1855.9,929.7,1852.2,918.4,1848.6z M982.6,2085.7c0.7,0.7,0.7,1.9,0.7,1.9c2.5,0.7,5.1,0.7,7.5,0.7c-0.7-1.6-0.7-3.2-1-3.2
    C988,2085.1,985.8,2085.7,982.6,2085.7z M1010.2,2125.5c0,1,0,1.9,0,1.9c1.5,0.7,3.2,0,4.7,0.7c0-1,0-2.5,0-2.5
    C1013.4,2125.5,1011.8,2125.5,1010.2,2125.5z M937,2049.7c-5.6,1-6.6,5.1-4.7,10.1c4.7-0.7,9.2-1,8.8-7.5
    C940.4,2051.9,937.9,2049.7,937,2049.7z M937.9,1800.6c-3.2,0.7-6,3.2-8.8,4.8c1.5,1.9,3.2,5.7,4.7,5.7c3.2,0,6-1.9,11.4-4.1
    C941.4,1803.5,939.4,1800,937.9,1800.6z M1024,1839.5c0.7-0.7,0.7-1,1-1.9c-1,1-1.5,1.9-2.5,3.2
    C1022.8,1840.4,1023.5,1839.9,1024,1839.5z M891.7,1802.5c-2.5-1.9-5.1-4.1-7.5-6c-0.7-0.7-2.5,1.9-4.1,3.2
    c2.5,2.5,4.7,5.1,6.6,7.5C887.9,1806.6,888.8,1805.1,891.7,1802.5z M977.3,1823c-1-7.3-6.6-10.8-12.2-9.2
    c-8.2,1.9-18,2.5-21.4,12.3c-1.5,5.1,5.6,8.9,21.4,9.8c4.7,0,11.6,6,13.8-4.1l0,0l-0.7-0.7c-1.5-1-2.5-1.6-4.1-1.9
    c1,0,1.9,0,2.5,0.7c0.7,0.7,1,1,1.5,1.6c0,0,0.7,0,0.7,0.7l0,0C978.5,1828.8,977.5,1825.6,977.3,1823z M978.5,1831.9L978.5,1831.9
    C979.1,1831.3,979.1,1831.3,978.5,1831.9L978.5,1831.9L978.5,1831.9z M990.4,1842c-0.7,1.9,1.9,4.8,3.2,7.3
    c4.1-1.9,7.5-4.1,14.1-7.3c-5.1-3.4-8.2-7.3-10.7-7.3C994.5,1834.8,991.4,1838.9,990.4,1842z M954.5,1985.2
    c14.1,10.1,27,19.6,40.9,29.7c-1.9,7.5-4.7,16.7-7.3,26.6l21.4,8.2c7.3-4.8,14.8-9.8,22.9-15.5c10.7,13.9,11.6,27.2,8.8,41.5
    c-13.8,3.4-13.8-15.5-26.5-14.2c-10,1-20.7,0-34.6,0c-5.6-7.3-11.4-13.3-16.3-20.5c-5.1-7.5-20.4,2.5-20.4-12.6
    c-2.5,0.7-5.6,0-7.5,1.6c-2.5,1.9-4.1,7.3-9.2,4.1c-0.7-3.4,0.7-7.3,1.9-9.8c1.5-1.6,3.2-3.2,3.4-4.8c1-1,1.9-1.6,3.2-1.9
    C951.1,2012.3,954.3,2000.7,954.5,1985.2z M980.7,2015.6c1,0.7,1.5,0.7,2.5,1c1.5,0.7,3.4,1,5.1,1c0.7,0,1.5,0,2.5-1
    c-3.2-2.5-5.1-4.8-6-4.8C983.2,2012.3,982.2,2013.3,980.7,2015.6z M1038.3,1904.9c-1,0.7-1.9,1.6-3.2,1.9l1.5,1.9
    c0.7-0.7,1-1,1.5-1.6L1038.3,1904.9L1038.3,1904.9z M1025,2133.7c0.7,0.7,1,1,1.5,1.6c0.7-1,1-1.6,1.5-2.5c-0.7,0-1.5-0.7-1.9-0.7
    C1025.4,2132.2,1025,2133.1,1025,2133.7z M814.9,1861.3c-15.4,1-9.2,11.4-10,18.1C808.3,1873.7,811.1,1868.2,814.9,1861.3z
    M963.4,1936.2c-5.1,0.7-8.2,0-10,0.7c-1.5,0.7-1.9,2.5-3.2,3.4c1.5,0.7,3.4,1.6,4.7,1C957.1,1940.6,958.6,1939.4,963.4,1936.2z
    M964.4,1905.8c-6-1-12.2,0-22.3,0c6.6,14.2,14.1,14.9,22.9,11.6c1.9-1,4.7-4.1,4.7-6C969.3,1909.7,966.6,1905.8,964.4,1905.8z
    M794.8,1818c-0.7-0.7-0.7-1-1-1.9c-0.7,0.7-1.5,1-1.5,1.6c0,0.7,0.7,1,1,1.9C793.8,1818.9,794.1,1818.4,794.8,1818z M784.1,1854.4
    c0.7,0,1,0,1.5,0.7c0,0-0.7,0-0.7-0.7C784.8,1854.4,784.8,1854.4,784.1,1854.4z M878.1,1961.8c-1-1-2.5-3.2-3.2-3.2
    c-1.9,0.7-3.2,2.5-4.7,3.4c1.5,1,2.5,2.5,4.1,2.5C875.7,1965,877.2,1962.8,878.1,1961.8z M825.9,1789.9
    c-8.8-5.1-13.8-12.3-12.2-24.9c-13.8,2.5-26.5-1.6-35.2,14.2c-4.1,7.5-14.8,11.6-22.3,18.1c-7.3-1.9-14.1-4.1-24.5-7.5
    c5.1,7.5,8.2,12.6,11.6,16.7c7.3,8.2,16.3,10.1,26.5,5.7c9.7-4.8,18.9-8.9,28-12.6c4.1,4.1,6.6,6.7,9.2,8.9c1.5,0,2.5,0.7,3.4,0
    c6-2.5,12.2-5.7,18.9-8.9C827.5,1795,827.8,1790.9,825.9,1789.9z M808.9,1838.9c-0.7,0-1.5,0.7-2.5,1c1,0.7,2.5,0.7,4.1,1
    C809.9,1839.9,809.6,1839.5,808.9,1838.9z M841,1861.9L841,1861.9c1.5,0,4.1,1.9,7.3,5.7l0,0c0.7,1,1,3.4,0.7,4.8l0,0
    c-0.7,0.7-1.9,1.6-3.4,1.6h-0.7c-1.5-0.7-3.2-1.6-5.1-2.5c-1.5-1-3.2-1.9-5.1-2.5l0,0c-0.7,0-0.7,0-1-0.7c-0.7,3.2-1.5,6.7-1,9.8
    c1,5.7,10,9.2,13.8,6c5.6-5.1,15.4-9.8,11.6-18.1C853.9,1856.8,847.6,1859.4,841,1861.9z M799.2,1855.3c-3.2-1.9-5.1-0.7-7.3,1.9
    c1.9,0.7,3.4,1,5.1,1c1,0,1.9,0,3.2-0.7C800.4,1856.8,800.4,1855.9,799.2,1855.3z M869,2209.9c1-1.6,1.9-2.5,2.5-4.1
    c0.7-1-1-1.9-1.5-3.4c0,1-0.7,1.9-1,2.5C869,2206.4,869,2208.3,869,2209.9z M973.4,1780.7c1-1.9,1.5-5.1,1.9-7.3
    c-5.1-5.1-9.2-2.5-12.6,0.7c-1-0.7-2.5-1.6-2.5-1.6c0.7,0,1.5,1,2.5,1.6c1.5,4.8,3.2,9.2,5.1,15.5
    C970.6,1784.8,972.5,1782.6,973.4,1780.7z M950.1,1753.5c0,1-0.7,1.9-0.7,3.4c0.7-0.7,1.5-1,1.5-1.6
    C951.1,1755.1,950.5,1754.5,950.1,1753.5z M860.9,2056.3c0-0.7,0-1.6-0.7-1.9c-0.7-0.7-1.5-0.7-1.9-1c0,1,0,1.9,0,3.2
    C859.6,2056.3,859.9,2056.3,860.9,2056.3z M865,1985.2c3.4,0.7,7.3,0.7,10,0.7c0.7,0,1-0.7,1.5-1l-0.7-0.7l0.7-0.7
    c-0.7-1.6-1.5-3.2-2.5-3.4c-2.5-0.7-5.1,0-7.5,0C866.2,1981.7,865.5,1983.3,865,1985.2z M862.1,2078.4c-1.9,0.7-4.7,1.6-6,2.5
    c-0.7,0.7,0.7,2.5,0.7,4.1c1.9-1.6,4.1-2.5,6.6-3.4C862.4,2080,862.1,2079.4,862.1,2078.4z M1018.7,1939.4c6-8.9,5.1-22.4-1-29.7
    c-1-1.6-3.4-1.6-4.7-3.2c-5.1-4.1-9.2-8.9-13.8-12.6c-6,3.4-5.1,7.5-2.5,10.8c3.4,5.1,8.2,10.1,12.6,14.9
    c-1.5,7.3-2.5,13.3-4.1,22.2C1010.6,1941.3,1016.9,1941.8,1018.7,1939.4z M574.9,1982.6c0,0,1,1,1.5,1c0.7-0.7,1-1,1-1.6
    c-0.7-0.7-1-0.7-1.5-1C575.9,1981.7,575.5,1982.3,574.9,1982.6z M567.4,1862.9L567.4,1862.9c0.7,5.1,1.5,10.8,2.5,16.7
    c6.6-5.1,6.6-10.8,5.6-15.9C574.2,1858.8,570.8,1859.4,567.4,1862.9z M557.6,1858.8l9.7,3.4h0.7c-3.2-1.9-6-4.1-9.2-5.7
    C558.2,1857.8,557.6,1858.5,557.6,1858.8z M1001.1,1574.9c-1,0.7-1.9,3.2-1.9,4.8c0.7,2.5,1.5,5.1,2.5,7.5c1.5,0.7,3.2,0.7,3.2,0.7
    s-1.5-0.7-3.2-1c1.5-1.9,3.4-4.1,4.7-6.7c0.7-1,0.7-3.4-0.7-4.8C1004.6,1574.9,1002.1,1574.3,1001.1,1574.9z M654.7,1876.8
    c-3.4-1-7.5-1.9-11.4-3.2c3.4,1.9,6.6,4.1,10,6C653.9,1879,654.5,1877.8,654.7,1876.8z M621.8,1821.5l1.9-0.7l-1.5-1.6
    L621.8,1821.5z M642.5,1873.3L642.5,1873.3c-1.9-2.5-3.2-7.5-8.2-4.8c-1,1-1.5,6,0,7.5C638.4,1880.9,640.6,1876.1,642.5,1873.3z
    M593.7,1961.1h0.7c-1.5-4.8-2.5-10.8-9.7-7.3c0,0.7,0,0.7,0,1L593.7,1961.1z M459.8,1872c-0.7-0.7-2.5,0.7-4.1,1
    c1,1.6,1,3.4,1.9,4.1s2.5-0.7,4.1-1C461.4,1874.9,461.4,1872.7,459.8,1872z M456.3,2060.5c-4.1,2.5-4.1,4.1,1.5,20.8
    c8.2-5.1,14.1-9.2,22.1-14.9C471.4,2060.5,465.4,2055,456.3,2060.5z M458.2,1911.3c9.2,6.7,9.2,6,26.5,7.5
    C475.5,1911.3,468.9,1905.6,458.2,1911.3z M583.4,1954.3L583.4,1954.3h0.7l0,0l0,0l0,0H583.4z M659.5,1846.7c0,0-0.7,1-1,1.6
    c1.5,0,3.2,0,4.7-0.7C662,1847.7,661,1847.1,659.5,1846.7z M593.7,1961.1c-5.1,0.7-8.2-1-9.2-6l-1-0.7l0,0l0,0l0,0l0.7-0.7
    c-1.9-0.7-4.1-0.7-6-0.7c-1.9,5.1-2.5,10.1,3.4,13.3c6.6,4.1,9.2-1.9,12.6-5.1l0,0l0,0L593.7,1961.1z M594.1,1961.8l0.7-0.7
    C594.7,1961.1,594.1,1961.1,594.1,1961.8C594.1,1961.1,594.1,1961.8,594.1,1961.8z M614.5,1890.7c0.7-3.2,0.7-6.7,1-9.8
    c-3.4,0-6.6-0.7-6.6,0c-0.7,3.2-1,6-1,9.8C610.1,1890.7,614.5,1891,614.5,1890.7z M1043.2,1746c1-1.9,0.7-6.7-0.7-7.3
    c-5.1-1.9-9.2,1-11.6,5.1c-1,1.9,0,4.8,0,6.7C1038.3,1753.5,1041.4,1749.7,1043.2,1746z M1004,1709.6l0.7-1.6l-1.9,0.7L1004,1709.6
    z M1024,1722.9l-1.5-1.6l-0.7,1.9L1024,1722.9z M1047.3,1712.2c-1,0-1.9,0-3.2,0l1.5,1.6
    C1046.8,1713.1,1046.8,1712.4,1047.3,1712.2z M1056.1,1586.7c-1.5,0-3.4,0-5.6,0c-1.5,0-3.4,0-5.1,0c2.5,6,5.6,12.3,10.7,17.4
    c0.7,0,1,0,1,0c4.1,0,6.6-2.5,7.3-6c0.7-3.2-2.5-7.3-3.2-7.5C1059.7,1587.6,1057.5,1586.7,1056.1,1586.7z M1036.1,1697.5
    c0-0.7-0.7-1.6-0.7-1.9c-0.7,0.7-1.5,0.7-1.5,1.6c0,0.7,0.7,1.6,0.7,1.9C1035.1,1698.2,1036.1,1698.2,1036.1,1697.5z
    M1007.7,1456.1c-1,2.5-2.5,5.1-3.2,7.5c-0.7,1.6,0.7,4.1,1.5,4.8c1,1,3.4,0.7,4.7-0.7c1-1,1.5-3.2,1-4.8
    C1010.6,1461.2,1009.3,1458.7,1007.7,1456.1c1.5,0,3.2-0.7,3.2-0.7C1010.6,1456.1,1009.3,1456.1,1007.7,1456.1z M972.5,1630.3
    c-0.7,0.7-1.5,1-1.9,1.6c0,0.7,0.7,1.6,0.7,1.9c0.7-0.7,1.5-1,1.9-1.6C973.2,1631.6,973.2,1631.3,972.5,1630.3z M895.7,1776
    c1-0.7,1.9-0.7,2.5,0c1.9,0,4.1,0.7,5.6,1c-1.9-9.8-4.1-10.8-12.2-11.4c-4.1-0.7-8.2,0.7-8.2,5.7c0,2.5,0.7,4.8,1.9,5.7
    c1.9-0.7,5.1-1,8.2-1C895.1,1776,895.1,1776,895.7,1776z M952.1,1578.5c-0.7-1.9-1.5-3.4-2.5-5.7c-1,1-1.9,1.6-3.2,2.5
    c0,0,0,0,0.7,0.7l0,0l-0.7,0.7C948.6,1577.5,950.1,1577.8,952.1,1578.5c-0.7,0-0.7,0-0.7-0.7L952.1,1578.5
    C952.1,1577.8,952.1,1578.5,952.1,1578.5z M843.2,1912.5c-4.1-1-7.5-4.8-12.2-7.5c-6,16.4,6,25.6,9.7,37
    c-9.7,12.6-9.7,12.6-4.7,34.1c6.6,3.4,13.2,7.5,20.4,10.8c1.9,1,5.1-1,7.5-1.6c-1.9-3.2-0.7-4.1,1.9-5.1c0.7-1,1-1.9,0.7-2.5
    c-10-20.5-11.6-41.5-7.3-63.5C854.3,1913.8,848.9,1913.8,843.2,1912.5z M862.1,1913.8c1.5,0.7,3.2,1,4.7,1c0-1,0-1.6,0-2.5
    C865,1912.5,863.6,1913.1,862.1,1913.8z M971.5,1651.8L971.5,1651.8L971.5,1651.8L971.5,1651.8z M927.8,1660.3
    c-1.9,5.1-2.5,10.8-4.7,16.4c-1.5,4.1-4.1,8.2-7.5,13.9l0,0c22.3,11.6,41.8,24.9,46.2,50.5c10,0.7,18.2,1,27.4,1.6
    c4.7,7.3,9.7,13.3,12.6,20.5c2.5,6,3.2,13.3,4.7,20.5c6.6,1,13.2,2.5,22.3,4.1c-3.2-9.2-3.4-16.4-7.3-21.5
    c-8.8-13.3-18.2-26.6-28.6-38.9c-6-7.5-12.6-14.9-22.9-17.4c-7.3-1.9-11.4-7.5-12.2-14.9c-1.9-10.8,3.2-15.5,15.4-13.3
    c1.9,0,3.4,0.7,7.3,1c4.1-12.6-3.2-22.2-9.2-31.6v0.7l0,0l0,0l0,0c-1,4.8-1.9,9.2-4.1,13.3c-0.7,1-4.7,0.7-7.3,0.7
    c0-1.6-0.7-4.1,0.7-5.1c3.2-3.2,7.3-6,10.7-8.9v-0.7c-0.7-1-1-1.9-1-1.9c-9.2-0.7-18-1-27-0.7
    C935.7,1649.5,930.4,1653,927.8,1660.3z M785.4,1696c0-0.7,0-0.7,0-1C785.4,1695.7,784.7,1695.7,785.4,1696
    C784.7,1696,785.4,1696,785.4,1696z M1819.2,1358.8c0.7-0.7,1-1,1.5-1.9c-1-1-1.9-1.9-3.2-3.2c-0.7,0.7-1.5,1.6-1.5,1.9
    C1816.7,1356.9,1817.9,1357.8,1819.2,1358.8z M1808.8,1064.5c-1-1-3.2-0.7-5.1-0.7c0.7,1.6,0,3.4,1,4.1c1.5,1,3.2,0.7,5.1,1
    C1810,1067,1810,1065.2,1808.8,1064.5z M1824.2,1223.8c-1.5,0-2.5,0-4.1,0c1.5,0.7,3.2,1.6,4.7,1.9
    C1824.8,1225.4,1824.2,1224.4,1824.2,1223.8z M1985.6,1242.2c5.6-3.2,10-8.2,13.8-13.9c4.7-6.7,1.9-12.3-5.6-16.4
    c-12.6-5.7-24.8-9.8-38.1-1c-0.7,0.7-1.5,1-1.9,1l0,0c0,1-1.9,3.2-4.7,6c0.7-1.9,1-3.4,1.5-5.1c-1.9,0.7-4.7,1-7.3,1.6
    c8.2,12.6,18.2,16.4,29.6,20C1977.1,1236.4,1978.6,1246.3,1985.6,1242.2z M1868,1362.6l0.7-1.9h-1.9L1868,1362.6z M1808.5,1274.1
    c-1,0-1.9,0-4.1-0.7c1.5,1,1.9,1.6,2.5,1.6C1807.2,1276,1807.8,1274.4,1808.5,1274.1z M1836.5,1115.7c0.7-0.7,1.5-1.6,1.5-1.9
    c0-0.7-1-1-1.5-1.9c-0.7,0.7-1,1.6-1.5,1.9C1834.9,1114.8,1835.9,1115.4,1836.5,1115.7z M1766.1,1409c0.7-0.7-0.7-1.6-0.7-1.9
    c-1,0-1.9,0-3.2,0c0.7,1.6,1,2.5,1.5,3.4C1765.1,1410,1766.1,1410,1766.1,1409z M1744.3,1200.2c0,1,1.9,2.5,3.2,4.1
    c1-1,3.2-2.5,2.5-3.4c-1-3.2-1.9-6-3.4-9.2C1745.8,1194.1,1744.6,1197.3,1744.3,1200.2z M1740.5,1276.7c-1.5-4.1-1.9-7.5-4.1-9.2
    c-1.9-1.6-5.1-0.7-7.5-1c1,2.5,1,5.7,2.5,6.7C1733.6,1275.3,1736.1,1275.3,1740.5,1276.7z M1788,1370.8c3.4-5.7,4.1-10.1-2.5-13.9
    C1783,1361.9,1783.3,1366.7,1788,1370.8z M1737.4,1427.1c-1,0-1.5,0-2.5,0C1735.9,1427.1,1736.5,1427.1,1737.4,1427.1z
    M1734.9,1427.1c-4.1-1-8.2-6-12.6-0.7C1726.1,1430.5,1730.8,1427.4,1734.9,1427.1z M1790.5,1305.7c-0.7-0.7-1-1.6-1.5-1.6
    c-0.6,0-1.5,0.7-2.5,0.7c0.7,0.7,1,1.6,1.5,1.9C1788.6,1306.6,1789.6,1306.4,1790.5,1305.7z M1737.4,1427.1L1737.4,1427.1
    L1737.4,1427.1L1737.4,1427.1z M2164.5,684.3c-0.7-0.7-2.5,0.7-4.1,0.7c0.7,1.6,0,3.2,1,4.1l4.7-1
    C2165.5,686.5,2165.5,685,2164.5,684.3z M2178,384.3c-0.7-0.7-1.5-1-1.9-1c-0.7,0-1,0.7-1.5,1c0.7,0.7,0.7,1,1,1.9
    C2176.5,386,2177.7,385.7,2178,384.3z M1102.9,1324l-1.9-0.7l0.7,1.9L1102.9,1324z M2213.2,418.3c1.9,4.1,3.4,7.3,5.6,9.8
    c0.7,1,3.4,0,5.1,0c-1-3.2-1.9-6.7-4.1-9.2C2219.9,417.9,2216.8,418.3,2213.2,418.3z M2290.7,407.7c-10.7,3.2-17.3-1-20.4-11.6
    c-1.5-1-2.5-1.9-4.1-3.2c1.5,0.7,2.5,1.6,4.1,2.5l0,0l0,0l0.7,0.7c1-3.4,1.9-7.3,3.2-11.4c-6.6-5.1-13.8-7.5-20.7-1.9
    c-8.2,6-8.2,14.2-6,22.4h0.7c0,0,0,0.7-0.7,0.7c0,0,0,0,0,0.7l0,0c-1.9,4.1-4.7,7.5-6,12.3c-2.5,7.5,1,12.6,9.7,16.7
    c5.6-5.1,11.6-10.8,18.9-17.4c6.6,4.8,12.6,8.9,19.9,13.3c1-3.2,1-6,1.9-8.9v-14.9L2290.7,407.7L2290.7,407.7z M2244.7,490.5
    c5.1-3.4,4.7-9.2,0.7-11.6c-10.7-7.3-18.9-19.6-34-18.1C2224.6,468.5,2227.1,489,2244.7,490.5z M2233.1,298.1c3.2,0.7,6,1,8.8,0.7
    c1.5,0,3.2-1.9,3.4-4.1c0.7-1.6,0-4.1-1-5.1c-3.4-5.1-9.2-6.7-15.4-5.7c-1.9,0.7-4.1,3.4-5.6,5.7c-0.7,1,0.7,4.1,1.5,5.1
    C2227.5,295.9,2230.6,297.2,2233.1,298.1z M2056.4,1219.1C2056.4,1219.7,2056.4,1219.7,2056.4,1219.1
    C2056.4,1219.7,2056.4,1219.7,2056.4,1219.1C2056.4,1219.7,2056.4,1219.7,2056.4,1219.1z M1998.5,1318c4.1,0.7,8.2-0.7,8.2-5.7
    c0-4.1-1.9-7.5-7.3-6.7c-3.2,0.7-6,5.1-9.2-0.7c-1-1.9-2.5-3.4-5.6-7.3C1987.8,1315.8,1988.1,1317.1,1998.5,1318z M2050.6,1216.6
    c0-1.9,0.7-2.5,0.7-3.2c0.7-0.7,1.9-1.6,6.6-1.6c0.7-1.9,0.7-4.8,0-6.7c-1-5.7-10-9.2-13.8-6c-5.6,5.1-15.4,9.8-11.6,18.1
    c4.7,10.1,12.2,5.1,20.4,2.5C2052,1219.7,2050.6,1218.1,2050.6,1216.6z M2023.9,1169.1c0,1,0,1.6,0,2.5c1.5-0.7,3.2-1,4.7-1.6
    C2026.8,1169.5,2025.2,1169.5,2023.9,1169.1z M2086.8,967.5c-3.2,1.6-5.6,2.5-8.2,4.8c3.2-0.7,6.6-1,9.7-1.6
    c8.8-1.6,16.3-2.5,24.5-4.1l0,0c7.5-3.4,13.8-6.7,21.4-10.8c-6.6-11.4-12.2-21.5-18.2-32.3c7.5,1.6,14.8,2.5,22.1,4.1l11.6-29.7
    c4.1-4.8,11.6,1,12.2-8.9c-6-0.7-12.2-1-19.5-1.9c-0.7-5.1-1.5-9.8-1.9-13.9c-2.5,3.4-6.6,6-10.7,8.9c-3.2,1.9-5.6,4.1-8.8,6
    c-0.7,0-1.5-0.7-1.9-0.7c-0.7-0.7-1.5-1.6-1.5-1.9c1-3.2,1.9-6.7,2.5-9.8c-3.4,2.5-9.7,0.7-13.2-4.1c-0.7-1-0.7-1.9-1-2.5
    c-2.5-6-7.5-10.8-13.2-8.2c-4.1,1.6-5.1,8.9-8.8,15.5c10.7,5.1,19.9,9.8,28.9,13.9c-6,10.1-15.8-1-22.3,7.3
    c-1.9,16.7,8.2,30.4,18.2,43.6c0.7,0,1,0,1.5,0.7l-0.7,0.7l0.7-0.7c0,0.7,0.7,1,0.7,1.6l3.2,2.5c-0.7,0-1.9,0-1.9,0
    c-0.7-1-0.7-1.9-1-2.5l-1.5-1.6l0,0c0-0.7-0.7-0.7-0.7-1c-6-1.6-11.6-3.4-17.3,2.5c-5.1,5.1-3.4,10.1-1.9,15.9
    C2090.6,962.4,2089,966.8,2086.8,967.5z M2119.6,776.6c1.9-0.7,4.1-1.6,6.6-3.4c1.5-7.5-4.7-7.5-8.2-10.1l-0.7-0.7l-13.6-1.2l0,0
    l0,0c-0.7,3.2-1,6-1,8.9c0.7,0.7,1,0.7,1,1c3.4,1.6,7.3,2.5,10.7,3.4C2116.1,775.3,2117.9,776,2119.6,776.6z M2049.8,1142
    c6-7.5,8.2-10.8,8.2-16.7c-1-4.1-1.5-7.3-1.5-9.8c-0.7-1.9-1-4.8-1.5-7.3c-6.6-3.4-13.2-7.5-20.4-10.8c-1.9-1-5.1,1-7.5,1.6
    c1.9,3.2,0.7,4.1-1.9,5.1c-0.7,1-1,1.9-0.7,2.5c10,20.5,11.6,41.5,7.3,63.5c5.6,0.7,11.4,0,16.7,1.6c4.1,1,7.5,4.8,12.2,7.5
    C2065.4,1161.9,2053.5,1153.1,2049.8,1142z M798.5,2041.5c9.2-3.4,10.7-12.3,2.5-23.4c-4.7,5.1-9.2,9.2-12.6,14.2
    c-1,1,1,5.7,2.5,7.3C792.2,2041.2,796.3,2042.1,798.5,2041.5z M730.6,1722.9c-1.5-1-3.2-2.5-4.1-2.5s-1.9,1.9-3.4,3.2
    c1.5,1,2.5,2.5,4.1,2.5C728.1,1725.7,729.6,1723.8,730.6,1722.9z M794.4,1680.8c-1,0-1.9,0-2.5,0c1.5,1,3.2,1.6,4.7,2.5
    C796.1,1682.5,795.4,1681.8,794.4,1680.8C794.7,1680.8,794.4,1680.8,794.4,1680.8z M684.3,1894.8c5.6,3.2,11.4,6,18,9.8
    c1.5-2.5,3.4-4.1,4.7-6.7c3.4-8.9-2.5-15.9-5.1-24.1c-3.4-1.6-6.6-3.4-10-3.4c-2.5-0.7-5.1,1.6-7.5,2.5
    C683.8,1880.2,684.3,1887.5,684.3,1894.8z M755.5,1679.2c-4.7,8.9-8.2,15.5-11.6,22.2l-1-0.7c-1.5,6.7-3.2,13.3-5.1,21.5h0.7
    c-0.7,1.9-1,3.4-1.5,5.7c1.9,3.2,5.1,8.9,8.2,13.9c3.4-0.7,5.6-0.7,6.6-1.6c1-1,2.5-1.6,3.4-2.5c-0.7-1-0.7-2.5-1-4.1
    c-0.7-3.2-1.5-6.7-1.9-10.1h0.7c1.5,0,3.2,0,4.1,0.7c1.5,3.2,3.2,5.7,5.1,8.2c1-1,2.5-1.9,3.4-2.5c4.1-3.2,7.5-6.7,4.7-11.6
    c-1-1.6-6.6,0.7-10,0.7c-1.9,0-5.1-1-6.6-2.5c-1-0.7-0.7-4.1,0.7-5.1c3.4-3.2,8.2-5.1,11.6-8.2l0,0c-1.5-0.7-3.2-1-4.7-1.6
    c0,0-0.7-0.7-0.7-1c2.5-0.7,5.1-1,8.2-1.6c1.9-4.1,1.5-8.9-1-12.3c-1-1.6-2.5-2.5-4.7-3.2c-1.5-0.7-2.5-0.7-4.1-0.7
    c0-0.7,0.7-0.7,0.7-1C758,1680.2,757.1,1679.9,755.5,1679.2z M686,1941.3c7.5,2.5,14.8-3.2,15.8-10.1c0-2.5-0.7-6.7-1.9-7.5
    c-3.4-2.5-8.2-3.4-18-7.3C683.8,1928.7,685,1935.5,686,1941.3z M780.3,1820.6c0.7,7.3,1.5,13.3,1.9,20c0.7,6,0.7,12.3,1,22.4
    c5.6-10.1,9.2-16.4,12.2-22.4C801.7,1828.8,798.5,1825.2,780.3,1820.6z M814,1931.4l1.5-1.6c-1-0.7-1.9-1-3.2-1.9
    c0,1-0.7,1.6,0,2.5C812.7,1931.2,813.7,1931.2,814,1931.4z M714.5,1953.6c1.5,0.7,3.2,0,4.7-0.7c-1-1-1.9-2.5-3.4-3.4
    c-1-0.7-2.5,0.7-4.1,0.7C712.3,1951.4,713,1952.9,714.5,1953.6z M781.3,2132.2c8.2-1.9,13.8-5.7,10.7-15.5
    C784.7,2122.1,784,2122.4,781.3,2132.2z M802,1766.2h0.7h0.7c1-0.7,2.5-0.7,4.1-1c0-1.6,0-3.4,0-6c-4.1,1.6-8.2,2.5-11.6,4.1
    C797.9,1764.6,800.1,1765.8,802,1766.2z M723.7,1931.2c1,0,1.9,0,2.5,0c-0.7-1-1.5-2.5-1.9-3.4c-0.7,0.7-1.5,1-1.9,1.6
    C722.7,1929.9,723,1930.5,723.7,1931.2z M783.7,1795.5c0.7-1-1-3.2-1.9-4.8c-1.5,1-4.1,1.6-4.1,2.5c-0.7,1.6,1,3.4,1.9,5.1
    C781.3,1797.4,783.1,1796.9,783.7,1795.5z M477,1896.4c0.7,1.6,1,3.2,1.9,4.1c0.7,0.7,2.5-0.7,3.4-1c-1-1-1.9-2.5-3.4-3.4
    C478.7,1895.7,477.7,1895.7,477,1896.4z M781.5,1706.7c0.7-0.7,1.9-1,1.9-1.6c0.7-1.6,0.7-2.5,1-4.1c-1.5,0.7-3.2,1-4.7,1.6v0.7
    C779.1,1703.9,780.6,1705.5,781.5,1706.7z M802,1768.4c-1.5,0.7-3.2,0.7-4.7,0.7c-3.2,0-6-1-9.7-3.2c-1,0.7-1.5,0.7-2.5,1
    c4.7,2.5,9.7,5.1,14.8,7.5c4.1-0.7,6-2.5,6.6-5.1c-1,0-1.9-0.7-3.2-1l-1-0.7L802,1768.4z M869,2179.3c-2.5-8.9-4.1-15.5-6.6-20.5
    c-1-1.9-6.6-3.2-9.7-2.5c-6,0.7-7.3,5.7-5.6,10.8C849.9,2175.7,856.5,2177.7,869,2179.3z M892,2107.2v-2.5c-1.5,0-2.5,0.7-4.1,0.7
    c0.7,0.7,1,1.6,1.5,1.9C890.4,2107.6,891.7,2107.2,892,2107.2z M892.7,2161.2c-1.9-0.7-3.4-0.7-5.6-1l0,0
    C888.8,2160.9,890.4,2161.2,892.7,2161.2z M1042.7,2118c-1.5,0.7-2.5,2.5-4.1,3.4c1,1,2.5,3.2,3.2,3.2c1.9-1,4.1-3.2,6.6-5.1
    C1045.8,2118.2,1044.2,2117.3,1042.7,2118z M1052.4,1867c-56.6,7.3-51.8,7.5-72.4,27.2c-15.4-5.7-15.4-5.7-9.7-18.3
    c0.7-1.6,0-3.4,0.7-5.7c-2.5-5.1-11.6-6.7-9.2-16.7c11.6,6,22.1,11.4,35.5,18.3c10-13.3,25.5-13.9,39.6-16.4
    C1044.9,1853.4,1049.9,1858.5,1052.4,1867z M985.8,1886.5c-1.5,0-2.5,0.7-3.2,1c-0.7,0.7-0.7,1-1,1.9c0.7,0,1,0,1.5,0
    c0.7,0,1,0,1,0C984.8,1889.1,985.1,1888.2,985.8,1886.5z M878.1,2061c1,0.7,3.2,0,4.7,0c0-1.6,0-3.2-0.7-5.1c-1.9,0-4.1,0.7-6,0.7
    C877.2,2057.9,877.2,2060.1,878.1,2061z M1149.9,1609.1c-0.7-1-1-1.9-1.5-3.2C1148.7,1607.2,1149.2,1608.2,1149.9,1609.1z
    M1149.9,1615.4c-0.7,1-1,1.6-1.5,2.5c0.7,0,0.7,0,1,0.7C1149.9,1616.7,1149.9,1615.7,1149.9,1615.4z M869,2209.9L869,2209.9
    c1.5,0.7,3.2,0.7,5.1,0.7C872.1,2210.5,870.6,2210.5,869,2209.9z M808,1874.9c0.7-1,1-1.9,1.9-3.2l-4.7-1.9c-0.7,0-1,0-1.9,0
    C804.8,1871.7,806.4,1873.3,808,1874.9z M776.2,2116.7c1-1,1.9-1.9,3.4-3.2l-3.2-3.4c-0.7,1.6-1.5,3.2-1.9,4.8
    C774.7,2115.4,775.5,2115.8,776.2,2116.7z M754.8,2174.2L754.8,2174.2L754.8,2174.2L754.8,2174.2z M772.4,2109.1
    c-1.5-0.7-2.5-0.7-3.4-1c0.7,0.7,0.7,1.9,1,1.6C770.3,2109.7,771.2,2109.1,772.4,2109.1z M260,1920.7c1,1.6,1.5,2.5,2.5,4.1
    c0.7-0.7,1.5-1,1.9-1c-1-1-1.5-2.5-2.5-3.4C262.3,1919.8,261.3,1920.5,260,1920.7z M910.2,1570.8c1,1,1.9,3.2,3.2,3.2
    c1,0,2.5-1.6,4.1-1.9c-1-1.6-1.9-3.4-3.2-3.4C913.1,1568.6,911.8,1570.2,910.2,1570.8z M844.8,2136.9c3.4,0,8.2,1,10-1
    c2.5-1.9,2.5-6,3.4-9.2c-2.5,0.7-5.6,0.7-7.5,1.6c-3.2,1.6-5.1,4.1-7.5,6C843.2,2134.7,844.2,2136.9,844.8,2136.9z M2266.5,1655.1
    c2.5,5.7,12.6,23.4,21.4,41.1c0,0.7,0,1,0.7,1c3.4,6.7,6.6,12.6,9.2,18.3c-1.5,0.7-2.5,0.7-4.1,0.7c-2.5,0-5.1-0.7-7.3-1
    s-3.4-1-5.1-1c-0.7,0-1,0-1.5,0c-1.5,0.7-3.2,1-4.7,1.9c-2.5,1.6-5.6,3.2-9.7,3.2c-2.5,0-5.1-0.7-7.5-1.9l-3.4-1.9l3.2-2.5
    c1.5-1.6,2.5-2.5,4.1-3.4c1-1,1.9-1.9,3.2-2.5c-1.5-1.6-3.2-3.2-5.1-4.8c-3.2-2.5-5.6-5.1-9.2-8.2c-9.7,4.1-20.4,8.9-30.2,12.6
    c-8.2-8.2-10.7-15.5-10-21.5c1-0.7,1.9-1,3.4-1.6c-0.7-1-1.5-1.9-1.9-3.2C2219.6,1664,2259.2,1657.7,2266.5,1655.1z M2271.5,1664.6
    c-6,1.6-25.1,8.8-43.9,14.8c-0.7-0.1-1-0.2-1.1,0.5c-7.1,2.3-13.5,4.6-19.5,6.3c-0.5-1.6-0.3-2.6-0.1-4.2c0.4-2.5,1.5-4.9,2.1-7.1
    c0.6-2.1,1.5-3.2,1.8-4.9c0.1-0.7,0.2-1,0.2-1.5c-0.5-1.6-0.5-3.3-1.2-4.9c-1.2-2.7-2.3-6-1.7-10.1c0.4-2.5,1.5-4.9,3-7.1l2.4-3.1
    l2,3.5c1.3,1.7,2.1,2.9,2.7,4.6c0.8,1.1,1.6,2.2,2,3.5c1.8-1.2,3.7-2.7,5.5-4.3c3-2.8,5.9-4.7,9.5-7.8c-2.6-10.2-5.6-21.5-7.8-31.8
    c9.4-6.8,17-8.2,22.8-6.6c0.5,1.1,0.7,2,1.1,3.6c1.1-0.5,2.1-1.2,3.5-1.4C2269.9,1616.9,2270.1,1657,2271.5,1664.6z M1874,2362.4
    c1.5-3.2,3.4-5.7,5.6-8.2c-6,1.6-10.7,3.2-15.4,4.1c-12.6-7.5-10-18.1-7.5-27.2c-6-4.1-11.6-7.5-16.3-10.8
    c3.2-6.7,11.6-0.7,10.7-8.2c-0.7-3.2-4.1-6-7.3-8.2c-3.2-1.6-7.3-1.6-11.4-1.9c0.7-5.1,0.7-9.8-0.7-13.9c1.9,0.7,4.1,1,6,1.6
    c1.5,0.7,3.2,0.7,4.7,1c3.2-1,5.6-1.9,8.8-2.5c0.7,0,0.7,0,1-0.7c0,0.7,0.7,1,1,1.6c2.5,4.1,7.3,3.2,10,0c7.3-6,14.8-3.4,22.1-1
    c6,1.9,11.6,6,18,10.1c2.5-3.2,7.5-6.7,7.3-8.9c-1.9-10.1,1.9-16.4,11.4-20c4.7-1.9,9.7-3.2,14.1-5.1c0.7-0.7,1-1,1.5-1.6l0.7,0.7
    l-0.7-0.7h0.7c-1-7.5-7.3-9.8-13.8-11.4c-4.1-1-10,0-9.2-7.5c1-0.7,1.9-1.6,2.5-1c7.3,1.9,14.1,4.8,21.4,6.7
    c12.2,3.4,12.6,4.1,12.6,18.3l0,0l0,0v1c4.1,1.9,9.7,6.7,12.6,5.1c14.8-5.7,26.1,4.8,38.7,7.5c0.7,0,1,3.2,1.5,4.8
    c-4.7,1-8.8,1.9-13.2,3.2c1,5.7,1.9,10.8,3.4,15.5c-8.2,4.8-13.8,3.4-18.9-3.4c-3.4-5.1-3.4-14.2-14.1-11.6
    c-3.2,8.2-6,17.4-9.7,25.6c-5.6,12.6-10.7,14.9-27,10.8l0.7,1l0,0l0,0h-0.9v-0.7c-3.2-4.1-6.6-8.2-9.7-11.6
    c-11.6,2.5-22.3,4.8-31.4,6.7c-7.3,10.8-6,20.5-1.5,29.7c7.5-8.9,16.7-12.6,26.5-3.2c1.5,1.6,3.4,1.9,6.6,1.9
    c-3.4-4.1-7.3-8.9-10.7-12.6c9.7-8.9,9.7-8.9,20.4-9.8c0.7,11.6,1,15.9,5.1,18.1c12.2-4.8,28-8.9,42.1,6
    c10.7,10.8,67.6-14.2,57.6-24.1c-27.4-27.5,18.2-22.4,40.3-27.2c-1-0.7-2.5-1-3.4-1c1-2.5,1.5-5.7,2.5-8.2c3.4-0.7,7.5-1,11.4-0.7
    c-1-1.9-2.5-4.8-5.6-7.3c-6.6-6.7-11.4-12.3-13.8-16.7c-0.7,0-1.5,0-1.9,0c0-1-0.7-1.6-0.7-2.5c0.7,0,1,0,1.5,0
    c-4.7-11.6,6.6-14.9,21.4-14.9c-0.7-0.7-1-1.6-1.9-2.5c-5.1-7.5,7.5-8.2,5.1-14.2c-5.1-5.1-14.1-4.8-18.2-12.3
    c2.5-0.7,4.7-1.9,5.6-1c9.7,4.8,18.9,9.8,28.6,15.5c0,6,0.7,11.4,0.7,16.7c8.2,1,15.8,2.5,20.4,3.2c4.7-7.5,4.1-16.4,1.5-25.6
    c9.7-1.9,18.2-3.4,27.4-5.7l0,0l0,0l0,0c1.5-4.8,3.2-8.9,4.1-11.6c-8.2-0.7-16.7,0.7-22.3-7.3c-1.9-2.5-1.9-7.3-1.5-10.1
    c0.7-1.6,4.7-4.1,6.6-3.4c6,2.5,11.4,1.6,15.8-1c-3.2-9.8-6-22.2-8.2-33.1c12.2-19.6,12.6-20,16.3-40.5l0.7-4.1l3.4,1.9
    c1.5,1,3.4,1.9,5.1,3.2c2.5,1.6,5.1,3.2,7.5,4.1c1.5,1,3.4,1.6,6,2.5c-4.7-6-10.7-9.2-16.7-12.3c-5.1-2.5-10-5.1-14.1-9.2l-1-1
    l0.7-1.6c1-6.7,5.1-7.5,7.5-7.5c2.5,0,5.1,1,7.3,1.9l1,0.7c4.1,1.9,7.5,4.1,11.4,4.1c1.9,0,4.7-0.7,6.6-1.6c0.7,0,0.7-0.7,1-0.7
    c1,0.7,2.5,1,3.4,1.6c3.2,1.6,6.6,4.1,9.2,6.7c7.5,9.8,14.8,19.6,22.3,29.7c-7.3,1.9-14.1,3.4-20.4,5.1c-5.1-3.4-9.2-6.7-12.6-9.2
    c14.8,15.5,15.4,26.6,12.2,35.4c6-1,11.4-1.9,16.7-4.1c6-2.5,12.2-6,20.4,1c-5.1,1.6-9.7,1.9-13.2,4.1c-4.7,3.2-8.8,7.5-12.6,11.4
    c-4.7,4.1-8.8,0.7-12.6-1c0-1.9-0.7-3.4-0.7-5.7c-1,1.6-1.5,3.2-2.5,4.8c0.7,0.7,1.5,0.7,1.9,1c-1,1.6-2.5,2.5-4.1,3.4
    c-1,3.4-1,7.3,2.5,10.8c-18.9-19-9.7,65.1-24.8,49.7c58.5,58.7,7.5,75.3-22.3,62.9h-0.7c-2.5,0.7-6-3.2-9.2-5.1l0.7,1
    c0,0,0-0.7-0.7-0.7v0.7c-0.7,0-0.7,0-1-0.7l0.7-0.7c-0.7-1-1-1.6-1.5-2.5l0,0c-18.2-18.3-28.9-22.2-36.2-19.6
    c-1.9-1.6-4.7-2.5-7.5-2.5h-1c1.9,1.9,3.4,3.2,5.1,4.1c-4.1,3.2-6.6,9.2-9.2,14.9c3.2,1,6,1.9,9.2,3.4c4.1-7.5,10.7-6.7,17.3-5.1
    c7.5,1.6,15.4,4.8,22.9,7.3c-2.5,1.9-5.1,4.8-6.6,7.3c-0.7,1,2.5,4.8,5.1,6c8.2,4.1,19.5,0,27,11.4c-11.4,5.1-22.3,6.7-33.6,3.4
    c-3.4-1-7.3-2.5-9.2-5.1c-7.5-7.5-14.8-16.4-22.3-24.6l0,0c-3.2-1.6-6-1.9-8.8-1.9c-1-1-2.5-1.9-4.1-2.5c-0.7,0-1-0.7-1-0.7
    c-1.9,1.9-3.2,4.8,0,9.2c0.7,1,1,1,1.5,1.6c-3.2,7.3-6.6,11.4-15.4,2.5c-1.9-1.9-51.8,32.9-44.7,39.8
    c26.1,26.3,22.9,72.7-15.8,55.3c-0.7,1.6-1.5,3.4-2.5,4.1c-1.5,1-6,0-7.3-1c-1.9-3.2-3.2-7.3-4.1-10.8
    c-7.5-5.7-16.3-12.6-26.5-22.4c9.7,9.8-12.6,3.2-34-2.5c-0.7,0-1,0.7-1.5,0.7c-4.7,3.2-5.1,3.2-10-3.4c-16.7-3.4-28.6-4.1-14.8,9.2
    C1900.5,2361.9,1887.2,2360,1874,2362.4z M417.7,2275C417.9,2275.4,417.9,2275.4,417.7,2275c1.5-0.7,3.2-3.2,3.2-4.8l0,0
    c0-0.7-0.7-1.6-1-2.5c-1,1-1.5,1.9-1.9,3.2C417.7,2274.1,417.7,2275,417.7,2275z M431.1,2305.1
    C431.1,2305.1,430.8,2305.1,431.1,2305.1c0,0.7,0,1,0.7,1l0,0l0,0l0,0l0,0C431.8,2305.6,431.8,2305.1,431.1,2305.1L431.1,2305.1
    L431.1,2305.1z M128,1133.4c-0.7,0.7,0,1.6,0,2.5c1-0.7,3.2-1,3.2-1.6c0-1.6-0.7-3.2-1-4.8C128.9,1131.4,128.6,1132.4,128,1133.4z
    M211,1130.8l1.9,1v-2.5L211,1130.8z M400.4,761.9c2.5,1,4.7,2.5,7.3,3.4c0.7,0.7,1.9-0.7,2.5-0.7c0-1,0.7-1.9,0-2.5
    c-1.9-1.6-4.7-3.2-6.6-4.1C403.1,758.2,401.6,760.7,400.4,761.9z M206,1116.5c1,1,3.2,3.4,3.4,3.2c3.2-1.6,5.6-3.4,8.2-5.7
    c-1-1.6-1.9-2.5-4.1-5.1C211,1111.6,208.4,1114.1,206,1116.5z M268.2,1848.6c-0.7,0-1.9,1-1.9,1c0,1.6,1,3.2,1.5,4.1
    c1.5-0.7,2.5-1.6,4.1-2.5C270.4,1850.5,269.2,1849.3,268.2,1848.6z M266.7,2048.7c1,0,2.5-1.6,4.1-2.5c-0.7-1.6-1-3.2-1.5-4.1
    c-1.9,1-4.1,1.9-6,3.4C264.1,2046.8,265.1,2048.7,266.7,2048.7z M55.3,2023.1c1.9,0,4.1-1,6-1.9c-0.7-1.6-1.5-3.2-1.9-4.8l-0.7-0.7
    c-1.9,0.7-4.7,1.9-6.6,3.2C54,2020.5,54.3,2023.8,55.3,2023.1z M146.2,1137.5v1.9l1.5-1.6L146.2,1137.5z M515.8,2163.4
    c4.1-3.4,9.7-6.7,14.8-8.9c12.6-5.1,17.3-14.2,15.8-27.2c-1.5,0.7-2.5,0.7-4.1,0.7c-14.1,13.9-31.1,24.9-55.4,32.3
    C500.1,2165.3,506.6,2171.1,515.8,2163.4z M546.9,2127.4c0.7,0,0.7,0,1,0c-0.7,0-1-0.7-1.5-0.7
    C546.3,2126.5,546.9,2127.4,546.9,2127.4z M546.3,2126.5v0.7h0.7L546.3,2126.5z M737.1,1990c3.2,0,5.6,0.7,8.2,1
    c0.7,10.1-1.5,18.3-10,15.5c-3.2,0.7-6.6-0.7-9.7-1.9c-1-0.7-2.5-1.6-3.4-3.2c-0.7,11.4-18.9,26.3-37.1,29c-1-1.6-1.5-3.4-2.5-5.1
    s-1.5-3.4-2.5-5.1c1,0,1.9,0,3.2,0.7c0,0.7,0,1.6,0,1.9c4.7,1,7.5,2.5,8.8,3.4c-10.7-10.8-10-19.6-3.4-25.6
    c-5.6-4.8-9.7-10.8-16.3-9.8c0-0.7-0.7-1-0.7-1.9c-3.4,0.7-7.3,1-11.4,1.9c1.5,5.7,3.2,11.4,4.7,16.7c1-1.6,1.9-2.5,3.2-3.4
    l0.7-0.7c0.7,2.5,1.5,5.1,1.9,7.5c-4.1,1.6-8.2,3.4-12.2,5.1c0.7-0.7,1-1,1.9-1.6c-1.9-1.9-4.1-3.4-6-5.1c-1.9-1.6-4.7-2.5-6.6-2.5
    l0,0l0,0c-0.7,0-1.5,1-2.5,1.9c-1.5,1.9-4.1,4.1-7.3,4.1c-1.5,0-3.4-0.7-5.1-1.9c-0.7,0-0.7-0.7-1-0.7c-1.9-1.6-4.7-3.2-6.6-3.2
    l0,0c1,3.4,2.5,6.7,3.4,10.1c-13.8,1-21.4,5.1-12.6,14.2c-3.2-3.2-5.6-4.8-8.2-5.7c-2.5,1.9-6.6,3.2-12.2,2.5
    c0-0.7-0.7-1.6-0.7-1.9c-20.7,9.2-38.7,53.1-45.3,46.4c18.2,18.3,0.7,32.3-19.5,30.7c6,6.7,16.7,10.8,13.2,23.1
    c-6-1-12.2-1.9-18-3.4c-9.7-2.5-19.9-5.1-29.6-8.2c-1.5-0.7-1.5-4.8-2.5-7.5c1.9-6.7,5.6-12.3,12.2-14.2c-4.1-4.1-8.8-6.7-13.2-8.9
    c3.4-1.9,5.1-3.4,4.1-4.1c-8.2-8.2,10.7-24.6,28-25.6c0-0.7,0-1,0-1.6c3.4-6.7,9.7-3.4,14.8-1.9c2.5,0.7,5.1,1.9,7.5,3.4
    c-1,1.9-1.5,3.4-2.5,4.8c-1,1.2-1.5,1.6-2.5,1.9c15.4,8.2,35.2-48.7,51.6-37.2v-0.7c-9.2-9.2-11.4-15.5-9.7-19
    c-1-1.9-0.7-4.8,1.5-8.2c1.5,0.7,3.2,0.7,4.7,1l0,0c0,1.6,1.9,4.1,4.1,6.7l0.7,0.7c1-1.6,1.9-3.2,3.2-4.8c0.7,0,1,0.7,1.9,0.7
    c5.1,0.7,11.6,1.6,18,3.2c6.6-9.8,6-13.3-0.7-23.1c-3.4-5.7-7.5-10.8-11.6-16.4c7.3-7.5,11.6-14.9,22.3-13.3
    c7.5,1,16.3-0.7,22.9,2.5c8.2,4.1,14.8,3.2,22.1,1c6-1.9,12.6-1.9,20.7-3.4c1.9,7.5,3.2,14.2,4.7,20.8c1.9,8.2,8.8,12.3,18,11.6
    c6-0.7,11.4-6.7,8.8-12.3c-2.5-6-5.6-11.6-4.7-19c15.8,4.1,16.3,4.1,15.4,17.4C734.4,1978.2,733.7,1984.3,737.1,1990z
    M544.7,2076.5L544.7,2076.5c-0.7,0.7-1,0.7-1.9,1v0.7C543.8,2077.5,544.4,2076.9,544.7,2076.5z M649.1,1970.3c0-0.7-0.7-1-1.9-1.9
    l-0.7,0.7c0,1,0.7,1.6,1,3.2C648.8,1971,649.1,1970.3,649.1,1970.3z M702.6,2000c-0.7-0.7-1.5-1-1.9-1.6l-0.7-0.7
    c-3.2,5.1-2.5,10.1-1.5,15.9l0.7,0.7C700.7,2009.8,701.6,2004.8,702.6,2000z M702.6,1998.5c-3.4-3.4-9.7-6-16.7-7.3
    c-0.7-15.9-6-37-3.4-34.1c-55-55.3,34,0,48.4,14.2c-15.8-15.9-1.5-26.6,12.6-34.8c0-1.9-0.7-4.1-0.7-6c5.1-3.2,10-5.7,14.8-8.9
    c-7.5-7.5-12.2-12.3-14.1-14.9c0,0,0,0-0.7,0s-0.7-0.7-0.7-1c-0.7-1-0.7-1,0-1c0-1,0-1.9,0.7-2.5c0.7-1,4.1-1.6,5.1-1
    c1.9,1.6,3.2,4.1,5.6,8.9c-2.5-0.7-4.1-1-5.6-1c4.7,3.4,10.7,8.2,14.1,11.4c0.7,0,0.7-0.7,1-0.7c-0.7-0.7-0.7-1-1-1.6
    c0.7,0.7,1,1,1.5,1.6c1-0.7,1.5-1,2.5-1.6c0,0,0,0.7,0.7,0.7l0,0l0,0l0,0l0,0c0.7-0.7,1-1.6,1.5-1.9c2.5-1.9,5.6-4.1,8.2-6
    l-1.9-1.9c-1.9,2.5-4.1,5.7-6,8.2c-0.7,0.7-1,1-1.5,1.6l0,0l0,0c-1.5-2.5-3.4-5.1-3.2-6.7c2.5-10.1,6-20.5,16.7-25.6
    c15.8,15.5,16.3,15.5,17.3,31.3c0.7,5.1,1,8.2,4.1,12.6c9.7,13.3,14.1,26.3,15.4,37.9c-6-3.4-10.7-8.2-16.7-10.1
    c-7.3-1.9-15.4-1.9-22.9-3.2c-3.2,3.2-6.6,6-10,9.8c-1.9-1.9-4.7-3.4-6.6-5.7c-2.5-0.7-4.7-1.9-6.6-4.1c-1.9-1.9-7.3,18.1-7.5,32.3
    c0.7,0,1,0.7,1.5,0.7c4.7,2.5,8.8,6.7,14.1,11.6c1.5-7.3,2.5-12.3,3.4-17.4c-1.9-0.7-3.4-0.7-5.6-1c1.5,0.7,3.4,0.7,5.1,1h3.2
    c7.3,0,9.7,4.1,12.2,9.8c2.5,6,7.5,12.3,4.1,19c-1.5,3.4-8.2,7.5-10.7,7.3c-10-2.5-20.4-5.7-28.6-11.6
    C739.8,2013,732.5,2028.2,702.6,1998.5z M797.6,1975.8c0.7,0.7,1.5,1,2.5,1c0,0,0.7,0,0.7,0.7c0.7-1,1-2.5,1.5-3.2
    c-1-0.7-2.5-1-3.2-1l0,0C798.5,1973.5,797.9,1975.1,797.6,1975.8z M783.7,1972.9c0.7,1,1,1.9,1.5,3.4h0.7c1-0.7,1.9-0.7,2.5-1
    c0-1-1-2.5-1.5-3.2l0,0C785.6,1972.5,784.7,1972.5,783.7,1972.9z M765.5,1965l0.7-0.7C765.5,1963.7,765.5,1963.7,765.5,1965
    C765.5,1964.3,765.5,1964.3,765.5,1965z M449.1,2131.2c-3.2-3.4-5.6-7.3-5.6-11.6c-0.7-7.3-4.1-11.4-8.2-14.2c1.5,6,1.5,12.6,0,20
    c-0.7,0-0.7,0-1,0c-3.4,0-6-3.2-8.8-6c-3.2-4.1-6.6-8.9-13.2-8.9c-0.7,0-0.7,0-1,0l0,0c4.7,11.6,3.2,26.6,16.3,37
    c8.2,0.7,18.2,0.7,28.6,1c0-1,0-2.5,0-3.4c-0.7,0-1.5-0.7-1.9-0.7c0.7-0.7,1-1.6,1.5-1.9c-0.7-1.6-1-3.2-1.9-4.8
    c0.7,0.7,1,1,1.5,1.6c0.6,0.6,1,1,1.5,1.6c0.7-0.7,0.7-0.7,1-1c-6-6.7-9.2-13.9-10-20C446.6,2123,447.2,2127.1,449.1,2131.2z
    M447.5,2119.5c1-5.7,5.6-10.8,10.7-15.5c0.7,2.5,1.5,4.8,1.9,6.7c11.4-1,21.4-1.9,31.4-2.5c-5.1-2.5-9.7-5.7-14.8-7.5
    c-3.2-1-6-1.9-9.2-3.2c8.8-5.1,18-9.2,23.3-12.3C468.5,2077.5,443.1,2095.1,447.5,2119.5z M731.2,2321.2c-0.7-0.7-1.9-1.6-2.5-1
    c-1,0.7-3.2,1.9-3.2,3.2c0.7,2.5,1.5,4.8,2.5,7.3C731.2,2327.8,734.4,2325.6,731.2,2321.2z M727.8,2330.7c-3.4,1-4.1,2.5-1.9,5.7
    C726.2,2334.8,727.1,2332.8,727.8,2330.7z M730.6,2286c-3.2,1.9-6,4.8-10,7.5C728.7,2295.6,728.7,2289.9,730.6,2286z M742.9,2088.5
    c0.7,2.5,1.5,5.1,1.9,7.5c1.9-1.6,3.4-1.9,4.7-3.2c-1.5-1.9-3.2-3.4-4.7-5.7C745.1,2087.6,742.9,2088.5,742.9,2088.5z
    M554.5,2126.5c-1,0-1.5,0.7-2.5,0.7c-1.5,0-3.2,0-4.7,0.7c1,0.7,2.5,1,3.4,1C552.6,2128.7,553.5,2127.1,554.5,2126.5z
    M742.9,2076.9l2.5-0.7l-1.5-1.6L742.9,2076.9z M737.8,2162.5c5.1,4.1,10,8.2,15.4,12.3C749.5,2168.5,744.4,2165,737.8,2162.5z
    M495,1935.5l1.9,1.6l0.7-1.9L495,1935.5z M546.3,1971L546.3,1971v0.7c0,0,0,0,0.7,0L546.3,1971z M518.3,1929.5L518.3,1929.5
    C518.9,1929.5,518.9,1929.5,518.3,1929.5h-0.7H518.3z M519.8,1991.8c1.5,0.7,3.4,1.6,5.1,1.9c8.2-4.1,16.3-8.2,25.5-12.3
    c-1.5-3.4-2.5-6.7-3.4-9.8c-0.7,0-1,0-1.5-0.7C535.3,1976,523,1978.5,519.8,1991.8z M509.8,2001.6l-2.5,4.8h0.7l0,0
    c0,0.7,0,1,0,1.6c11.4,8.9,23.3,4.8,36.8,3.4c-1.5-14.2-11.6-13.3-19.9-16.4C520.8,1999.2,516.7,2003.3,509.8,2001.6z
    M481.1,2029.7l-7.5-3.2c1.9,1.6,4.1,3.4,6.6,5.1C480.6,2031.3,481.1,2030.4,481.1,2029.7z M511.7,1991.8c-6,1.6-7.5,4.8-1.9,8.9
    c3.2-2.5,5.6-5.1,8.8-8.2C516.4,1991.8,513.9,1991.5,511.7,1991.8z M505,1833.2c-1-0.7-6,1.6-6,2.5c-0.7,3.2,1,6,3.2,12.3
    c4.1-3.2,8.8-5.1,8.2-6.7C509.8,1838.5,507.6,1835.4,505,1833.2z M488.4,1990c-1.5,0-2.5,1.9-4.1,2.5c1,1.6,1.5,2.5,2.5,4.1
    c1.5-1,3.4-2.5,5.1-3.4C490.9,1991.8,489.4,1990,488.4,1990z M445.9,1942.8l0.7,2.5l1.5-1.6L445.9,1942.8z M472.1,2025.6
    c1.5-4.1,1.5-8.2-3.2-9.2c-8.2-1.6-16.3-1.9-24.5-1C452.5,2023.1,461.4,2027.2,472.1,2025.6z M472.1,2026.3L472.1,2026.3
    L472.1,2026.3L472.1,2026.3l1.5,0.7c-0.7-0.7-0.7-0.7-1-1C472.9,2025.6,472.6,2025.6,472.1,2026.3h1
    C472.6,2025.6,472.6,2025.6,472.1,2026.3C472.1,2025.6,472.1,2026.3,472.1,2026.3L472.1,2026.3z M713.9,2218.1
    c0.7-0.7,1-1.6,1.5-1.9c-0.7-0.7-1.5-0.7-1.9-1c-0.7,0.7-1,1-1,1.6C713,2217.2,713.3,2217.5,713.9,2218.1z M475.2,1978.5
    c11.6-1.6,22.3,9.8,33.6,1C495,1970,482.1,1968.4,475.2,1978.5z M492.8,1919.1l0.7,1.9l1.5-1.6L492.8,1919.1z M540.3,2586.6
    c4.1-0.7,8.2-3.2,12.2-4.8c-4.7-8.2-10-7.5-15.4-5.7c-1.5,1-3.4,4.8-3.2,6.7C534,2584.4,538.1,2587.3,540.3,2586.6z M482.8,1881.9
    c0.7,0.7,1.5,0.7,1.9,1c0.7,0,1-1,1-1.6c-0.7-0.7-1-1-1.5-1.6C483.6,1880,483.3,1880.9,482.8,1881.9z M529.9,2717.4
    c-1.5-1-3.2-1.6-4.7-2.5l0,0c1,1,2.5,1.9,4.1,3.2c0.7,0.7,1.5,1,1.9,1.6C531.2,2719.1,530.5,2718.1,529.9,2717.4z M516.4,2794.3
    c0.7,1,0.7,1.9,1,1.9c1.5,0,3.2,0,4.7-0.7c-0.7-1-0.7-1.6-1-2.5C519.8,2793.9,518.3,2793.9,516.4,2794.3z M483.6,3015.8
    c-6-5.1-11.6-10.1-20.7-18.3c1.5,6,1,10.1,3.2,11.6c4.7,4.1,10,6.7,15.8,10.1C482.8,3018.7,483.3,3017.4,483.6,3015.8z
    M550.1,2611.2c-3.2-2.5-4.7-4.8-7.3-5.7c-0.7-0.7-4.1,1.9-4.1,3.2c0.7,2.5,1.9,4.1,3.2,6.7
    C544.4,2614.1,546.3,2612.6,550.1,2611.2z M565.2,2642.9c-6-7.5-7.5-10.1-9.7-11.6c-1.9-1-4.7-0.7-7.3-0.7c1,2.5,1.5,5.1,3.2,7.3
    C552.9,2639.4,556.7,2639.4,565.2,2642.9z M662.9,2372c-0.7,0-0.7-0.7-1-0.7l0,0C662,2371.7,662.4,2372,662.9,2372L662.9,2372z
    M702.3,2095.1c0.7-0.7,0.7-1.6,0.7-1.9c-1.5,0-2.5,0-3.4,0c0.7,0.7,0.7,1.6,1,1.9C700.7,2095.1,701.3,2095.8,702.3,2095.1z
    M666.4,2177.7L666.4,2177.7c-1.9-0.7-3.4-1-5.1-1.6c-1,0-1.5-1-2.5-1.6c0.7,1,1,2.5,1.9,2.5
    C662.4,2177.7,664.6,2177.7,666.4,2177.7z M729.3,2083.5c0-0.7,0-1.6,0-1.9c-0.7,0-1.5,0-2.5,0c0,0.7,0,1.6,0,1.9
    C727.8,2084.2,728.7,2083.5,729.3,2083.5z M738.5,2270.9c-2.5-1.9-6-4.1-9.2-5.7c0,0-1.5,1.6-1.5,1.9c2.5,2.5,4.7,5.1,7.3,7.5
    c0.7,0.7,1.9,0.7,5.1,0.7C739.4,2272.5,738.8,2271.2,738.5,2270.9z M721.5,2038.6c-1.9-0.7-4.7,1-6,1c0,2.5,0,4.1,0,5.7
    c3.2-1,6-1.6,9.2-2.5C723.7,2041.2,722.7,2038.9,721.5,2038.6z M661.4,2371.7c-1.5-1-3.4-2.5-5.1-3.4
    C658.6,2369.2,659.8,2370.2,661.4,2371.7L661.4,2371.7z M639.7,2394.2c-0.7-0.7-1-1.6-1.5-1.9c-0.7,0-1.5,0-1.9,0
    c0.7,1,1,1.9,1.5,3.2C638.1,2395.1,639.1,2394.8,639.7,2394.2z M573.3,2594.8c1,0,1.9-1.9,3.4-3.4c-3.4-1.9-6.6-4.1-11.4-7.3
    C565.2,2591.7,567.7,2594.8,573.3,2594.8z M731.2,2285.8L731.2,2285.8c-1.5-0.7-3.4-0.7-5.1-1
    C727.8,2285.1,729.6,2285.1,731.2,2285.8z M559.4,2189c0.7,0,0.7-0.7,0.7-0.7c-1,0-1.9,0-3.2,0C558.2,2189,558.6,2189,559.4,2189z
    M656.9,2159.7c3.2-1,5.1-4.8,7.3-7.3c-4.7-6-9.7-9.2-14.8-4.8c-1.9,1.9-1.5,7.3-1.9,10.8C650.3,2159.3,654.5,2160.9,656.9,2159.7z
    M651.7,2348c0-1.6,0-3.2,0-4.8c-1.5,0.7-3.2,0.7-4.1,1c0.7,1.6,1,2.5,1.9,4.1L651.7,2348z M655.4,2280.7c0,0.7-0.7,1.6-0.7,1.9
    c1-0.7,1.9-1,3.4-1.6C657.3,2281,656.4,2280.7,655.4,2280.7z M201.7,1327.7c0.7,1,1,2.5,1.5,4.1c1.5-1,2.5-1.6,3.4-2.5
    c-1-1-1.9-1.9-3.2-2.5C203.9,1326.7,201.7,1327.7,201.7,1327.7z M244.1,1230c-0.7,0.7-1,1-1.9,1.9l0,0h-0.7l0,0l0,0l0,0l0,0
    c0.7,0.7,0.7,0.7,1,1v-0.7c8.2,1,15.8,0.7,20.7-8.2c1.5-1.9,4.7-3.2,7.3-4.8l-0.7-0.7c-1.5-9.8-3.2-19.6-4.7-30.4
    C249.2,1198.7,239.1,1210.7,244.1,1230L244.1,1230L244.1,1230L244.1,1230c-8.2-7.3-20.7-11.6-16.3-26.6c1.5-5.1,3.2-10.1-3.4-10.8
    c-3.4-0.7-9.2,2.5-11.6,6c-3.2,4.1-3.4,9.8-5.1,14.2c6.6,7.3,13.2,14.2,18.2,20.5C233.4,1232.2,238.5,1230.9,244.1,1230z
    M249.8,1256.5c0-0.7,0-0.7-0.7-1c-0.7,0-1,0-1,0.7C248.6,1256.2,249.2,1256.5,249.8,1256.5z M191,1183.9c-4.1,1-7.5,1.6-11.6,2.5
    l0,0c-1,0-1.5-0.7-2.5-0.7v41.1c6-6,12.6-9.8,23.3-7.3c1,0,3.4-4.8,5.6-7.3C200.8,1202.8,196.4,1193.6,191,1183.9z M207.3,1213.3
    c-0.7-0.7-0.7-1-1-1c0,0,0,0.7-0.7,0.7c0.7,0.7,0.7,1.6,1,1.9C207.1,1214.1,207.1,1213.6,207.3,1213.3z M249.2,1255.6
    c4.1,6.7,9.2,8.9,17.3,4.1c-2.5-14.2-14.1-19.6-23.9-27.2C245.1,1240.8,247.3,1248,249.2,1255.6L249.2,1255.6z M245.1,1257.2
    c1-0.7,1.9-0.7,3.2-1c-0.7,0-0.7,0-1,0C246,1256.2,245.7,1256.5,245.1,1257.2z M279.7,1576.4L279.7,1576.4
    C279.3,1576.4,279.3,1576.4,279.7,1576.4L279.7,1576.4L279.7,1576.4C279.7,1576.4,280.3,1576.4,279.7,1576.4L279.7,1576.4
    L279.7,1576.4z M264.5,1581.7c-4.7,8.2-10.7,15.5-18.2,20.8c-1.5,1.6-1.5,5.1-2.5,9.8c7.3-1.6,12.2-3.2,17.3-4.1l-0.7,1l0,0l0,0
    h0.7c-1.5,8.2,3.2,11.4,10.7,12.3c9.2,0.7,7.3-6,7.5-11.4c0.7,0,0.7,0,1,0l-1-0.7l0.7,0.7c-0.7-0.7-0.7-0.7-1-1
    c4.7-7.5,2.5-14.9-1.9-21.5c-3.2-5.1-2.5-8.9,1.9-11.6C273.7,1576.1,268.1,1576.1,264.5,1581.7z M279.7,1576.4
    c1.5-4.8,3.4-9.8,6-17.4c-10,4.8-17.3,5.1-23.9-1.6c-1.9-1.9-5.1-3.2-7.5-3.4c-6-1.6-11.4-0.7-14.1,5.7c-1.5,3.4-3.4,6.7-5.1,10.1
    c3.4,5.1,6.6,9.8,10.7,15.9c1-4.1,1.5-6.7,2.5-9.2c1.5-3.2,3.2-6,4.7-10.1c7.3,1.9,12.6,3.2,18,4.8
    C273.7,1572,276.6,1574.5,279.7,1576.4L279.7,1576.4z M273,1791.3c2.5,1,5.1,2.5,7.5,2.5c1.5,0,2.5-3.4,4.1-5.7
    c-2.5-1-4.7-2.5-8.2-4.1C275.6,1786.9,274.7,1788.8,273,1791.3z M217.1,1592.5c-1-1-1.9-3.2-3.4-3.4c-1-0.7-2.5,1-4.1,1.9
    c1,1,1.9,3.2,3.4,3.4C214.6,1594.4,216.1,1592.8,217.1,1592.5z M190.6,1183.6c0,0,0,0.7,0.7,0.7h0.7L190.6,1183.6z M256.4,1670.5
    c0.7,0,1-1,1.9-1.6c-0.7-0.7-1.5-1.6-1.9-1.6c-1,1-1.9,1.9-2.5,3.2C254.8,1670.3,255.8,1670.5,256.4,1670.5z M310.2,1292.8
    c1.5-1.9,4.1-5.1,3.4-7.3c-0.7-1.6-4.1-3.2-7.3-5.1c-1,3.2-3.4,6-2.5,7.5C304.5,1291,308.3,1292,310.2,1292.8z M199.8,1557.8
    c0.7-0.7,1-1.6,1.5-1.9c-0.7,0-1.5-0.7-1.9-1c-0.7,1-1,1.9-1.5,3.2C198.2,1557.8,199.1,1557.8,199.8,1557.8z M188.4,1368.1
    c-0.7,0-1.5,0.7-1.9,1c0.7,0.7,1.5,1.6,1.9,1.9c0.7-0.7,1-1,1.5-1.6C189.8,1368.8,189.1,1368.1,188.4,1368.1z M175.3,1300.9
    L175.3,1300.9L175.3,1300.9L175.3,1300.9c0.7,0,0.7-0.7,1-0.7c0,0-0.7,0-0.7-0.7l0,0C175.3,1300.3,175.3,1300.9,175.3,1300.9
    L175.3,1300.9z M213,1282.8c-1.5,0.7-2.5,0.7-4.1,1c0,0-0.7,0-1,0c-2.5,0-5.6-1.6-6-2.5c-1.5-3.2-1.9-8.2-0.7-10.8l0,0
    c0-0.7,0.7-1.6,1-1.9c-2.5-5.7-4.1-11.4-4.1-18.3c0-14.2-5.1-17.4-21.4-17.4v3.2c4.7,4.8,9.2,9.2,13.2,13.3
    c-1.9,13.9-3.4,26.3-5.1,37.9c7.3,6,11.6,13.3,19.9,17.4c4.7-7.3,8.2-12.6,11.6-18.1C214.9,1284.6,213.9,1283.8,213,1282.8z
    M222.1,1563.1c-0.7-0.7-0.7-0.7-1-1c0.7,1,1,1.6,1,2.5C222.1,1564.3,222.1,1563.8,222.1,1563.1z M256.4,1418.3l-2.5-0.7l0.7,1.9
    L256.4,1418.3z M220.5,1561.5c0,0,0.7,0.7,1,0.7C221.2,1562.1,221.2,1561.5,220.5,1561.5L220.5,1561.5z M237.8,1500.8l-13.8,3.4
    c0.7,1.6,1,3.2,1.5,4.8c4.7-1.6,8.8-3.4,13.2-5.1C239.1,1502.7,238.5,1501.8,237.8,1500.8z M229.7,1555.6c-1.5-0.7-4.1-1.6-4.7-0.7
    c-1.9,1.9-3.2,4.8-4.7,6.7l0.7-0.7c2.5-1,5.1-1.9,7.5-3.4C228.7,1557.8,229.4,1556.4,229.7,1555.6z M223.1,1738.8
    c1.5-1.6,3.4-4.1,3.4-5.7c-1-2.5-3.4-5.1-7.3-10.1c-1,6-1.9,8.9-1.5,10.1C218.7,1735.7,221.9,1737.2,223.1,1738.8z M228.4,1331.4
    c2.5,1,4.7,1.9,7.3,3.2c1-1.6,1.5-1.9,1.9-3.2c-2.5-1.6-4.7-2.5-7.3-4.1C229.7,1329.2,228.7,1330.5,228.4,1331.4z M299.2,1532.2
    c-0.7-1-1-1.6-1.5-1.9c-1,1-1.9,1.6-2.5,2.5c0.7,0.7,1.5,1,2.5,1C298,1533.4,298.5,1532.4,299.2,1532.2z M329.1,1265.4
    c-0.7-1.9-1-3.4-1.9-5.1c-0.7-0.7-3.2,0.7-4.7,1c0.7,1.9,0.7,3.4,1.9,5.1C325,1267.2,327.5,1266,329.1,1265.4z M295.1,1273
    c-0.7,1.6-1.5,3.2-1.9,4.8c0,0.7,1,1,1.5,1.6c1.5-1,2.5-2.5,4.1-3.4C297,1274.6,296,1273.6,295.1,1273z M40.8,1451.6
    c0-0.7-0.7-1.6-1-1.9c-1,1-1.5,1.6-2.5,1.9c0.7,0.7,1,1,1.9,1.6C39.8,1452.9,40.8,1452.3,40.8,1451.6z M283.2,1348.2
    c0.7-0.7,1-1,1-1.6c0.7-0.7,0-1.6,0-1.9C283.7,1345.6,283.2,1346.6,283.2,1348.2z M225.6,1618.1c-0.7,2.5-1,5.1-1,7.3
    c0,0.7,1.5,1,1.9,1.6c1.5-1.9,2.5-4.1,4.1-6.7C229.4,1620,227.8,1619.1,225.6,1618.1z M209.3,1300c1,0,1.9-2.5,3.4-5.1
    c-3.2-3.2-6-6-9.2-9.2c-10,5.7-18.9,10.1-28,15.5c2.5,1.9,4.7,3.4,7.3,5.1C190.1,1296.2,200.8,1300.9,209.3,1300z M167.5,1310.7
    l15.8-4.8l0,0l0,0l0,0l0,0l0,0C178.2,1306,173.1,1304.4,167.5,1310.7z M250.1,1540L250.1,1540L250.1,1540c0.7,0,1,0,1,0v-0.7
    c9.2-9.2,7.3-19,1-28.7c-3.2-0.7-4.1,1.9-4.7,4.1c0,0.7,0,1,0,1.9c-0.7,1.6-1.9,3.4-3.2,4.1c-0.7,0-1.5-0.7-2.5-1.6
    c-0.7,0-1-0.7-1-0.7C236.6,1536.6,236.6,1537.2,250.1,1540z M225.6,1529c4.7-3.2,8.8-5.7,12.2-8.2
    C233.8,1522.3,227.1,1520.1,225.6,1529z M240.4,1519.8L240.4,1519.8C240.4,1519.8,240.4,1519.2,240.4,1519.8
    C240.4,1519.2,240.4,1519.2,240.4,1519.8C240.4,1519.2,240.4,1519.2,240.4,1519.8C240.4,1519.2,240.4,1519.2,240.4,1519.8
    L240.4,1519.8c-0.7,0.7-1.5,1-1.9,1.6C239.1,1520.7,239.4,1520.1,240.4,1519.8C240.1,1519.8,240.1,1519.8,240.4,1519.8z
    M270.6,1219.9c0-0.7,0-0.7,0-1l-0.7,0.7L270.6,1219.9z M282.9,1348.8C282.9,1348.2,283.2,1348.2,282.9,1348.8
    C282.9,1348.2,282.9,1348.8,282.9,1348.8L282.9,1348.8z M271.2,1217.4c-0.7,0.7-0.7,1-0.7,1.6c1-0.7,1.9-1,3.2-1.9
    C272.5,1216.7,271.5,1216.7,271.2,1217.4z M210.5,1171.1c0,1.9,0.7,4.8,0.7,6.7c1-1,1.5-2.5,2.5-4.1
    C212.4,1172.8,211.5,1171.8,210.5,1171.1z M249.2,1183.6c0.7,0,1.5-1,1.5-1.6l-4.1,1C247.3,1183.6,248.2,1183.9,249.2,1183.6z
    M1634.2,987.1l4.1,1.9c0.7-0.7,1.5-1,1.9-1.6c-1-1-1.9-2.5-3.2-3.4L1634.2,987.1z M191.6,1183.9c0-0.7-0.7-0.7-0.7-1
    c0,0-0.7,0-0.7,0.7L191.6,1183.9z M200.8,1176.9c-3.2-2.5-6-5.1-8.2-8.2c-3.2,4.1-4.1,8.2-1,13.9c4.1-1.9,9.2-1,14.1-1.9
    C203.9,1180,202.3,1178.5,200.8,1176.9z M2090.6,1857.1c-0.7,0.7-1.5,1.6-1.5,1.9c0,1.6,0.7,3.2,0.7,4.8c0.7-1.6,1-2.5,1-4.1
    C2091,1858.6,2090.6,1857.8,2090.6,1857.1z M2123.1,1749L2123.1,1749c0-0.7-0.7-0.7-0.7-1l0,0L2123.1,1749z M2092.5,1789.1l1-0.7
    C2093.2,1788.5,2093.2,1788.5,2092.5,1789.1C2092.5,1788.5,2092.5,1789.1,2092.5,1789.1z M2114.9,1842.9c1-1,0-6-1.5-6.7
    c-5.6-2.5-10-0.7-12.2,6C2105.7,1845.8,2110.5,1847.9,2114.9,1842.9z M2150.7,1866L2150.7,1866L2150.7,1866L2150.7,1866z
    M2150.7,1866L2150.7,1866L2150.7,1866L2150.7,1866z M2132.8,1759.8h-2.5l1.5,1.6L2132.8,1759.8z M2124.3,1789.1l-0.7,1.6h1.9
    L2124.3,1789.1z M2127.8,1734.5c-8.2,2.5-8.2,7.5-5.1,13.9l0.7,0.7c3.4-6,7.3-12.3,10.7-19
    C2131.9,1731.3,2129.7,1733.2,2127.8,1734.5z M2159.8,1878.3c2.5,2.5,5.1,4.8,9.7,8.9C2168,1877.6,2163.9,1878.3,2159.8,1878.3z
    M148.4,141.2c-0.7-0.7-1-1-1.9-1.6C147.4,140.7,147.8,141.2,148.4,141.2L148.4,141.2C149.1,141.5,149.1,141.5,148.4,141.2
    C149.1,141.5,149.1,141.5,148.4,141.2C149.1,141.5,149.1,141.5,148.4,141.2C149.1,141.5,149.1,141.5,148.4,141.2
    C148.4,141.5,148.4,141.5,148.4,141.2L148.4,141.2z M193.1,88.4C193.1,88.8,193.1,88.8,193.1,88.4
    C193.1,88.4,193.6,88.4,193.1,88.4C193.6,88.4,193.6,88.4,193.1,88.4C193.1,88.4,193.6,88.4,193.1,88.4L193.1,88.4L193.1,88.4
    C193.1,88.8,193.1,88.4,193.1,88.4z M193.1,88.8L193.1,88.8L193.1,88.8z M451.4,2098.5c-0.7,1-1,2.5-1.5,4.1
    c-5.6,24.9-22.1-3.4-35.2-30.4c-6.6-0.7-12.6-0.7-18.9-1c11.6-4.8,12.2-4.8,18.2,0c-9.7-19-17.3-35.7-18.2-30.4
    c14.1-62.8-68.3-42-66.6-48c27.4-119.2-28.9,6.7-25.5-9.2c10.7-45.6-18,43.9-14.8,23.1c-8.8,48.7,7.3,52.1,6,56.9
    c-8.8,37.9-28.9,62.9-28.9,63.8c11.4-49,0.7-21.5-10.7,11.4c0.7,5.1-1.5,9.8-4.7,14.2c-1,3.2-1.9,6-3.2,9.2
    c0.7,1.6,1.5,2.5,3.2,3.4c-1.5,0-3.2,0-4.1,0c-0.7,1-1,2.5-1,3.4c1.9-1,3.4-1.9,5.6-3.2l0,0c1,4.8,2.5,9.8,4.1,18.1
    c-5.1-2.5-9.2-4.8-13.2-4.8c-1.5,4.8-1.9,7.5-2.5,9.2c-1.9,9.8-9.7,11.6-18.9,13.9c5.6,9.2,2.5,17.4-3.4,26.3
    c-7.3,0.7-1.5-12.6-10.7-9.8c-0.7-5.7-1-9.2-1-11.6c-2.5,1-5.1,2.5-7.5,5.1c1.9,1.9,4.7,3.4,6.6,5.7c-2.5-1-6.6-1-8.8-3.2
    c-4.1,4.1-7.3,10.8-9.2,19.6c0.7-3.2-3.2,0-9.2,4.8v-121.4c8.8-25.6,18.2-54.3,19.9-58.4c-1.5,0.7-13.2-9.8-19.9-28.2v-67
    c2.5-23.1,5.6-55.3,4.7-59.4c10-31.3,56.9-183.3,34.6-86.6c-5.6,24.9,79,17.4,81.4,6c1.5-7.5,40.3,113.5,52.8,59.4
    c-14.1,62.6,26.5,91,38.7,73.3c-8.2-0.7-15.8,1-16.3-6.7c-0.7-6,4.1-10.1,12.2-8.9c1.5,4.1,2.5,8.2,4.7,13.9c1-1.6,1.5-3.4,1.9-5.7
    c-16.7,72.7,28.9,94.2,34.6,70.5c-1.5,7.3,1,22.2,5.6,37.2c1.9-1.9,5.1-4.1,8.2-5.7c-0.7,4.8-1,8.9-1,13.3c0,3.2-1,5.1-1.9,6
    C441,2083,446.7,2092.8,451.4,2098.5z M130.4,137.4c-1.5-2.5-3.2-5.1-4.7-7.5c0,2.5,0.7,4.8,1,5.7
    C127.7,135.9,128.6,136.6,130.4,137.4z M126.9,1644.1c-0.7,0-0.7-0.7-1-0.7l0,0c0,0,0,0,0,0.7
    C126,1643.8,126.4,1644.1,126.9,1644.1z M159.4,1643.8l-0.7-0.7l0,0l0,0l0,0l0,0v0.7l0,0C159,1643.8,159.4,1643.8,159.4,1643.8z
    M195.5,1653c-1.5-2.5-1.9-5.1-3.2-5.1c-8.8-1.9-19.5,1.6-25.5-8.9c-2.5,1.6-5.1,3.2-7.5,4.8c8.2,5.7,16.7,11.4,26.5,18.1
    C188.9,1658.7,192.7,1655.5,195.5,1653z M167.2,1639L167.2,1639L167.2,1639l-15.4-6C156.8,1639.7,162.4,1638.2,167.2,1639
    L167.2,1639L167.2,1639L167.2,1639z M126.4,419.4c-1,2.5-1.9,5.1-3.2,7.5c1.5-1,3.4-1.9,5.1-3.2C127.7,421.9,127,421,126.4,419.4z
    M143.3,437.4c-4.7-8.9-11.6-10.1-20.4-9.8c0,0,0-0.7,0.7-0.7c-0.7,0-1,0.7-1,0.7l0,0l0,0l0,0l0,0h0.7l0,0c0.7,0.7,1,1.6,1.5,1.9
    c-1.5-0.7-3.4-1-5.1-1.6v5.1c1.9-1,3.4-1.9,5.1-3.2l0,0l0,0l0,0c-7.3,19,2.5,31.6,17.3,43c2.5-10.1,5.1-20,7.5-29.7l0.7-0.7
    C147.8,440.8,144.3,439.6,143.3,437.4z M169.1,1278.8c-8.8,11.4-0.7,15.9,6,20.8c0-4.8,0-8.9,0-13.3
    C175.7,1281,173.8,1278.5,169.1,1278.8z M118.9,629.3c1-1,2.5-1.9,3.4-3.2c-0.7-0.7-1-1-1.9-1.9l-1.5,0.7V629.3L118.9,629.3z
    M131.8,404.5c0.7,0,0.7,0,1-0.7l0,0C132.1,404.2,131.8,404.2,131.8,404.5C131.1,404.5,131.1,404.5,131.8,404.5z M153.2,242.1
    l-0.7,1.9h2.5L153.2,242.1z M130.2,404.5c0.7,0,0.7,0,1,0c-1-0.7-1.9-0.7-3.2-1C128.6,403.6,128.9,404.2,130.2,404.5z M131.8,154.8
    l13.2,5.1c0.7-1,1-1.9,1.5-3.2c-4.1-1.9-8.8-4.1-12.6-6.7C132.6,152,132.1,153.3,131.8,154.8z M149.3,402
    c-7.3-5.1-12.2-3.4-16.7,1.6c1.9-0.7,4.7-1.6,6.6-1.9c1,6,1.9,10.1,1.9,13.3C145.2,411.8,148.4,407.7,149.3,402z M149.3,443
    l0.7,0.7c0-0.7,0-0.7,0-1L149.3,443z M126,324.9c-1.9,0.7-4.7,1.6-6.6,2.5v1.6c0.7,1,1,1.6,1,2.5c2.5-1,5.1-1.9,7.5-3.2
    C127,326.8,126.4,326.4,126,324.9z M150.3,444.9c0.7,0.7,1.5,0.7,1.9,0.7c-1-0.7-1.9-1.6-2.5-1.9C150,444.7,150,444.9,150.3,444.9z
    M227.4,763.8c0,0,0.7-0.7,1-0.7c1-1,1.9-1.9,3.2-3.2c-0.7-0.7-1-1-1.9-1C228,761.4,227.4,762.9,227.4,763.8z M356.6,180.1
    c3.4-20.8,3.4-20.8-13.2-21.5C350.6,165.3,342.5,176,356.6,180.1z M109.1,1634.1c-1.5-0.7-4.1,1-6.6,1.6c1.5,2.5,2.5,5.1,4.1,6.7
    c0.7,0.7,3.2-0.7,8.2-1.6C112.5,1637.5,111.3,1634.9,109.1,1634.1z M359.8,637.1L359.8,637.1C359.8,637.1,359.8,637.5,359.8,637.1
    L359.8,637.1c0.7,0,0.7,0,0.7,0l0,0l0,0l0,0C360.4,637.1,360.4,637.1,359.8,637.1L359.8,637.1z M348.1,676.4l3.2,4.1
    c1,0,1.9,0,2.5,0c-0.7-1.6-1-3.4-1.9-4.8C351.6,674.7,349.1,676,348.1,676.4z M45.8,1644.1c1-1,3.2-1.9,3.2-3.2
    c0-1.6-1.9-2.5-3.2-4.1c-1,1-3.2,1.9-3.2,3.4C43,1641.6,44.8,1642.6,45.8,1644.1z M351.6,538.8v-0.7l0,0c0,0.7,0,1-0.7,1.9
    C351.6,539.8,351.6,539.1,351.6,538.8z M331.8,75.1c-0.7,0-1,0.7-1.9,1c1,1,1.9,1.6,2.5,2.5l1-1.9
    C332.8,76.1,332.4,75.5,331.8,75.1z M223.9,334.1c1,0,1.9,0,3.4,0.7c-1-1-1.5-1.6-2.5-1.6C224.9,332.4,224.2,333.4,223.9,334.1z
    M327,669.4l-1.5-1.6l-0.7,1.6H327z M384.6,610.3c-9.2,1.6-18.2,3.2-27,4.1c0,0.7,0,0.7,0,1l0,0l0,0l0,0l0,0h-0.7
    c2.5,6,5.1,11.6,8.2,18.1c-1.9,1-3.4,2.5-5.1,3.4c-5.6-7.5-9.7-16.7-16.7-22.2c-9.7-7.3-7.3-14.9-6.6-23.4c6,1,12.2,1.6,18.2,2.5
    l0,0h0.7h0.7l0,0c4.1,1,8.2,3.2,12.2,2.5c4.7-0.7,8.2-4.1,12.6-5.1c8.2-1.9,14.8,1.9,15.4,8.9C396.8,604.9,391.9,609,384.6,610.3z
    M339.9,591.9c-0.7,0-1,0-1,0.7C339.4,592.6,339.9,591.9,339.9,591.9L339.9,591.9z M39.2,1609l1.5,1.6l0.7-1.9L39.2,1609z
    M34.8,1663.8c4.1-0.7,8.2-1.6,13.2-1.9c-3.2-3.2-4.1-5.1-5.1-5.1c-2.5,0-5.6,1-7.5,2.5C34.8,1659,35.1,1661.5,34.8,1663.8z
    M243.1,30.9c-1.9-0.7-6.6,2.5-8.2,5.1c-2.5,3.4,0.7,5.7,10,5.7C247.8,37.2,249.7,32.6,243.1,30.9z M245.3,41.7c0,0,0.7,0,0.7,0.7
    c0-0.7,0-0.7,0-1L245.3,41.7l1-0.7h-0.7C245.3,41.7,245.3,41.7,245.3,41.7L245.3,41.7z M351,541.4c0-0.7,0-0.7,0-1
    C351,540.5,351,540.7,351,541.4L351,541.4z M305.7,319.2L305.7,319.2c-0.7,0-0.7,0-1,0.7C305.4,319.8,305.4,319.8,305.7,319.2z
    M308.8,164.6c1.9,0,5.1-3.2,5.6-5.1c0.7-1.9-1.9-6-3.4-6c-11.4-1.6-20.4-10.8-32.8-7.5c-3.2,0.7-7.3-1.6-10.7-1.9l0,0l0,0
    c-5.1-3.4-10.7-7.3-16.7-11.6c1.5,11.6,11.6,8.2,16.7,11.6l0,0c2.5,5.7,7.5,7.5,13.2,9.2c6,1.6,12.2,4.1,14.1,11.4
    C299.7,164,304.1,165.3,308.8,164.6z M237.1,115l1.9-1l-1.9-1V115z M245.3,709.5c-1.5-3.2-3.4-6-5.6-8.9c-1,0.7-1.9,1-3.2,1
    c1,3.2,1.9,6.7,3.2,9.8c0.7,1,3.2,0.7,5.1,0.7C245.3,712.4,245.6,710.2,245.3,709.5z M234.9,548c1-1,1.9-1.9,3.2-3.2
    c-1.5-0.7-2.5-1.6-4.1-1.9c-0.7,1.6-1,2.5-1.5,4.1C233,547,234.9,548,234.9,548z M295,164.6c0-0.7,0-0.7-0.7-1c0,0,0,0-0.7,0
    C294.7,163.7,295,164.6,295,164.6l-0.7-1C294.7,164,294.7,164,295,164.6L295,164.6L295,164.6z M269.5,161.2l0.7,1.9l2.5-0.7
    c1,0,1.5-0.7,2.5-1C273.6,161.5,271.7,161.2,269.5,161.2z M296.2,178.6L296.2,178.6c-0.7-4.8-1-9.2-1.5-13.9
    c-1.9-0.7-3.4-0.7-5.6-1c0,0.7,0.7,1,0.7,1.9s0.7,1.6,0.7,2.5c0,0.7-0.7,1-0.7,1.6c-0.7,1-0.7,1.9-1,3.2c-1,7.5,1,14.9,4.7,20.5
    c3.2-1,6-1.6,9.7-2.5C300,185.5,298.1,182,296.2,178.6z M258.8,309l1.5-1.6l-1.5-0.7V309z M272,553.7L272,553.7
    C272,554,272,553.7,272,553.7L272,553.7L272,553.7z M261,253.2c-1,1-1.5,1.9-1.5,2.5c0,0,1,1,1.5,1C261,255.7,261,254.7,261,253.2z
    M277.7,496.8c0,1.6,0.7,4.1,1.5,5.1c1,1,3.4,0.7,6,1c-1.5-3.2-2.5-5.1-4.1-7.3C281.5,494.6,277.7,496.1,277.7,496.8z M264.5,598.6
    c0.7,1,1.9,1.9,3.2,2.5c0-0.7,0-1.6,0.7-2.5c-1-1-1.9-1.6-3.2-2.5C264.5,597,264.1,598.2,264.5,598.6z M257.9,385.8
    c0,1.6,0,3.2,0.7,4.8c1-1,1.9-1.9,3.2-3.4c0.7-0.7-0.7-1.9-1-1.9C260.1,384.6,257.9,385.3,257.9,385.8z M247.8,57.9
    c4.1,4.8,8.8,3.2,13.8,1.9c10.7-1.9,16.3,4.1,19.9,13.3c-1.9,5.1-4.1,10.1-6,15.9c-2.5,7.5-0.7,13.9,5.1,19c0,0,0-0.7,0.7-0.7
    c3.4-4.1,2.5-9.2,1.9-13.3c-1-5.1-1-7.5,3.4-9.2c1,1.9,2.5,3.4,3.4,5.1c5.6,7.5,10.7,14.9,8.8,24.1c1.5-0.7,3.2-1,4.7-1.9
    c7.5-3.4,15.4-7.5,18.9-17.4c-2.5-1.9-4.7-4.1-7.3-5.1c-19.5-7.3-22.1-11.4-16.7-29c1-3.4,3.2-6,5.1-9.8c-20.4-3.2-39.6-1-57.6-8.9
    C244.1,48,243.7,53.1,247.8,57.9z M131.3,1299.3c-5.1-1.6-7.3-2.5-8.2-1.9c-1.9,1.6-3.4,4.1-5.1,6c1.9,0.7,4.7,2.5,6,1.9
    C126.9,1304.4,128.8,1301.9,131.3,1299.3z M152.4,1277.8c-7.3-0.7-13.2-0.7-19.5-1C144.6,1282.6,144.6,1282.6,152.4,1277.8z
    M119.8,1367.9c-0.7,0-1,1.9-0.7,1.9c1,1,1.9,1.6,3.2,2.5l1.9-4.1C122.8,1368.5,121.3,1367.9,119.8,1367.9z M109.9,1571.1
    c1.5,0,3.2,0,4.1,0l-1.5-4.1c-1,0.7-1.9,1-3.2,1.9C109.6,1568.9,109.6,1571.1,109.9,1571.1z M98.9,1395.1c-1,6,3.2,10.8,11.4,9.8
    c1.9-4.1,3.4-8.2,6.6-14.9C108.1,1390,100.6,1387.2,98.9,1395.1z M141.7,1306l1.5-1.6l-1.9-0.7L141.7,1306z M116.6,1420.7l-1-1.6
    l-0.7,1.6H116.6z M180.4,1610.9c0,1-0.7,1.9-0.7,3.2c1,0,1.9,0,1.9,0c0.7-0.7,0-1.6,0.7-1.9L180.4,1610.9z M192.7,1263.6
    c0,0-0.7,1.6-1,1.9c1.5,0.7,3.2,1,4.1,1.6c0,0,0.7-1.6,1-1.9C195.5,1264.5,194.2,1263.6,192.7,1263.6z M193.7,1335l1.9-0.7
    c0-0.7,0.7-1.6,0-1.9c-0.7-0.7-1.5-0.7-1.9-0.7C193.7,1333.1,193.7,1334.1,193.7,1335z M144.2,1347.3l-2.5-0.7l0.7,1.9
    L144.2,1347.3z M179.8,1245.5l1.9,0.7l-0.7-1.6L179.8,1245.5z M157.5,1257c-1.5,1.9-3.2,4.1-4.7,6c1.9,0.7,4.1,1.9,4.7,1.6
    c1.5-1,2.5-3.2,2.5-4.8C160.6,1258.8,158.4,1258,157.5,1257z M146.1,1243.1c1,1,2.5,1.6,3.4,1.6c9.7,2.5,11.6,1,12.2-11.4
    C153.4,1236.8,150.2,1238.9,146.1,1243.1z M111.3,1520.2l1-1.6h-1.5L111.3,1520.2z M387.1,75.1c0.7-0.7,0-1.6,0.7-2.5
    c-1,0-1.9-0.7-1.9,0c-0.7,0.7,0,1.6-0.7,2.5C386.1,75.1,387.1,75.1,387.1,75.1z M367.9,217.7c0,0.7,0.7,1.6,0.7,1.9
    c0.7-0.7,1.5-1,1.9-1.6c0-0.7-0.7-1.6-0.7-2.5C368.9,216.8,367.9,217.5,367.9,217.7z M373.9,648.2c3.4-2.5,7.3-6,9.7-8.9
    c-2.5-6.7-5.6-6.7-10-5.7c-4.7,1-9.2,1.9-13.8,2.5c2.5,4.1,4.7,8.2,7.3,11.4C370.5,648.2,373,649.1,373.9,648.2z M424.9,554.7
    c-1.5,1-2.5,1.9-3.4,1.9c1.5,1.6,3.2,3.4,5.1,4.1c1,0.7,2.5-1.6,5.1-3.2C428.9,556.5,427.1,555,424.9,554.7z M382.1,206.1
    c0.7,1,1,2.5,1.5,4.1c1-1,1.5-1.9,2.5-3.2c0,0-1-1-1.5-1.6C384.3,205.7,383.1,206.1,382.1,206.1z M365.4,633.4L365.4,633.4
    C365.4,633.4,365.4,634,365.4,633.4L365.4,633.4c1-1.9,1.5-3.4,2.5-5.7C367.3,629.8,366.4,631.5,365.4,633.4L365.4,633.4
    L365.4,633.4L365.4,633.4C365.4,633.4,365.4,634,365.4,633.4z M392.4,579.6c1.5,3.4,3.4,6.7,8.8,5.1c9.7-3.4,5.1-10.1,5.6-18.3
    C395.3,568.5,389.3,569.9,392.4,579.6z M335.7,1676.8c0,1-0.7,1.9-0.7,3.2c0.7-1,1-1.9,1.5-3.2
    C336.2,1676.8,335.7,1676.8,335.7,1676.8z M337.2,1732.1l-0.7-1.9l-1.9,1.6L337.2,1732.1z M130.5,1591.9l-0.7,1.9l2.5-0.7
    L130.5,1591.9z M385.9,258.1c-1.9-1-4.7-0.7-7.3-1c0.7,2.5,0.7,5.7,2.5,7.3c1.9,1.6,5.1,1,9.2,1.9
    C388.3,262.3,387.8,259.8,385.9,258.1z M344.5,1697.4c-1,1-3.2,1.9-3.2,3.2c0,1,1.5,2.5,2.5,3.4c1-1,3.2-1.9,3.2-3.4
    C347.3,1700,345.4,1698.3,344.5,1697.4z M401.9,226c2.5,1,5.1,1.9,10.7,4.1c1-6,3.2-10.1,1.9-12.6c-1.5-4.1-4.7-8.2-8.2-10.8
    c-3.4-1.9-11.6,4.1-12.2,9.2C393.8,221.6,396.8,224.3,401.9,226z M176.7,1681.1c0-0.7-0.7-2.5-0.7-2.5c-1.5,0.7-3.2,0.7-4.7,1
    c0.7,1,0.7,2.5,1,1.9C173.8,1682.1,175.3,1681.4,176.7,1681.1z M378,529.9l-9.2,23.4c7.3-0.7,10-0.7,12.6-1.6
    c5.6-1.9,13.8-1.6,15.4-10.1C398.5,535.7,392.8,532.2,378,529.9z M246.3,770.1h0.7C246.3,769.6,245.6,769.6,246.3,770.1
    C245.6,769.6,245.6,770.1,246.3,770.1z M213.5,450.4c3.4,3.4,6,6.7,10.7,10.8C223.9,452.9,221.7,448.8,213.5,450.4z M257.9,831.6
    c-0.7-0.7-1.5-1.6-1.9-1.9c0.7,1,0.7,1.6,1,2.5C257.2,831.6,257.2,831.6,257.9,831.6z M245.3,770.5c-1,0.7-1.5,1-2.5,1
    c0,1,0,1.6,0,2.5c-1.5,0-3.2,0.7-4.1,0.7l0,0c-1.5,1.6-2.5,3.2-2.5,6C244.6,781.9,242.7,774.3,245.3,770.5z M317,381.7
    c-0.7,0-1.5,1-4.7,2.5c2.5,1.9,3.4,3.4,5.1,4.1c4.1,1,8.2,1.6,12.2,1.6c0,0,1-1.6,1-2.5C328.3,380.5,322.6,381.7,317,381.7z
    M295,310c-0.7,0-1,0.7-1.5,1c-1.5,1-1.9,3.2-2.5,5.1C293,314.1,294.7,311.9,295,310z M384.3,516.1l2.5,1c0.7-0.7,0.7-1.6,1-1.9
    c-1-0.7-1.5-1-2.5-1C384.6,514.2,384.3,515.8,384.3,516.1z M204.3,217.5c0,0.7,0.7,1.9,1,1.9c1.5,0,2.5-0.7,4.1-1
    c-0.7-1.6-1.5-3.2-1.9-4.8C206.6,214.9,205.3,215.8,204.3,217.5z M206.6,133c-1,0-1.9,0.7-2.5,1c1,1,1.5,1.9,2.5,3.2
    c0.7-0.7,1.5-1.6,1.9-1.9C207.9,134.3,207.5,133,206.6,133z M207.9,401c-1,1-1.5,3.2-2.5,5.1c1.5,0.7,3.4,1.9,4.1,1.6
    c1-1,1.5-3.2,2.5-5.1C211,402,208.4,400.4,207.9,401z M229.8,803.7l-1.9-1.6c0,1,0,2.5,0,4.1C228.9,805,229.3,804.3,229.8,803.7z
    M245.6,770.1L245.6,770.1c0,0.7,0.7,0,0.7,0C246.5,770.1,246.3,770.1,245.6,770.1z M201.9,324.9c0.7,1.6,1.5,3.2,2.5,4.8
    c1-1,1.9-1.9,2.5-2.5c-1-1-2.5-1.9-3.4-3.4C202.8,323.9,201.3,324.9,201.9,324.9z M207.9,817.3c1.9-0.7,3.2-1.6,5.1-1.9
    c-0.7-0.7-1-1.6-1.5-1.9c-1.5,0.7-3.4,0.7-5.1,1C206.9,815,207.9,817.3,207.9,817.3z M243.1,769.6c1,0,1.5,0,2.5,0.7
    c-1-0.7-1.5-1-2.5-1.9C243.1,768.6,243.1,768.9,243.1,769.6z M140.2,1682.7c0-1.6-1-3.4-1.9-4.8c-0.7-0.7-2.5,0.7-5.1,1
    c1.5,2.5,2.5,4.8,4.1,6.7C138,1684.3,140.2,1683.6,140.2,1682.7z M351,26.8c-2.5,0.7-5.6,2.5-6.6,4.1c-0.7,1.9,1.5,5.7,3.4,6.7
    c4.1,1.6,9.2,1.9,13.8,3.2C362.9,27.5,361.7,25.6,351,26.8z M2301.4,1736.4c-0.7,0-1-0.7-1,0c-0.7,0-1.5,1-1.9,1.6
    c1,1,1.5,1.9,2.5,3.2c0-0.7,0.7-1,0.7-1.6L2301.4,1736.4L2301.4,1736.4z M2301.4,1962.8c-1,0-1.9,0-3.2,0c0,3.4-0.7,6.7,0,9.8
    c0,0,1.5,0.7,3.2,0.7V1962.8z M2297.8,1903.4v-1.9l-1.5,1L2297.8,1903.4z M2301.4,1854.4c-0.7-0.7-1-1-1.9-1.6
    c-1,1-2.5,2.5-1.9,3.4c0.7,1.6,1.9,1.9,3.2,3.4c0-0.7,0.7-0.7,0.7-1v-4.8l0,0v0.4L2301.4,1854.4L2301.4,1854.4z M2301.4,2085.7
    c-4.1,1.6-8.8,3.4-12.6,5.7c0.7,9.8,0.7,19.6,12.6,21.5V2085.7z M2287.1,1298c0-1.6,0.7-3.4,1-5.1c0-1,0.7-1.6,1-2.5
    c-1,1-2.5,1.6-2.5,2.5C2285.6,1294.3,2286.6,1296.5,2287.1,1298C2286.6,1298,2286.6,1298.4,2287.1,1298
    C2286.6,1298.4,2287.1,1298.4,2287.1,1298L2287.1,1298z M2281.5,1425.5c4.1,1,1.9-5.7-1.9-13.9
    C2270.2,1417.2,2266.7,1422,2281.5,1425.5z M2284.1,3077.8c5.6-6,9.2-10.8,13.2-15.5C2285.6,3062.6,2285.6,3062.6,2284.1,3077.8z
    M2290.7,3264.6c0-0.7-3.4-1.9-4.7-1.6c-1.9,1-3.2,3.2-6,6c4.7,1.6,6.6,1.9,9.2,2.5C2289.7,3269.3,2290.7,3266.8,2290.7,3264.6z
    M2294.1,3284.2c-1,0.7-0.7,3.2-1,4.8c1.5,0,3.4,1,4.7,0.7c1-0.7,0.7-3.2,1-4.8C2297.8,3284.2,2295.4,3283.5,2294.1,3284.2z
    M2297,3341.4c-0.7,0-1.5,1-1.9,1c0.7,0.7,0.7,1.6,1,1.9c1-0.7,1.9-1.6,3.2-1.9C2297.8,3342.3,2297.3,3342.1,2297,3341.4z
    M2198.2,3292.7c0.7,1.9,4.1,4.8,5.6,4.8c1.9,0,6-4.1,5.6-4.8c-1.5-3.2-3.4-5.1-7.3-9.8C2200.4,3288.3,2198.2,3290.8,2198.2,3292.7
    z M2184.7,3150.2c-1,0-1.9-0.7-3.4-0.7l1,1.6C2183.1,3151.1,2184.1,3150.5,2184.7,3150.2z M2231.5,3248.7
    c-12.6-3.4-24.5-7.3-33,6.7C2219.6,3257.9,2220.3,3257.9,2231.5,3248.7z M2184.7,3071.1c1.5-0.7,3.2-1,4.7-1.6l-0.7-1.9
    c-1.5,0.7-3.2,1-4.7,1.6L2184.7,3071.1z M430.5,697.5L430.5,697.5L430.5,697.5L430.5,697.5L430.5,697.5c-1.5-0.7-3.2-0.7-3.2-0.7
    S428.9,697.5,430.5,697.5c0,0.7,0,0.7,0,0.7S430.5,697.8,430.5,697.5L430.5,697.5z M396.6,427.3c1.5,1.6,0.7,0-2.5-3.2
    C392.8,423.2,393.4,424.1,396.6,427.3z M423.3,661.5C423,661.5,423,661.5,423.3,661.5c0,0.7,0,1,0,1v-0.7v0.7
    c0-0.7,0.7-0.7,0.7-0.7C424,661.5,423.3,661.5,423.3,661.5L423.3,661.5z M294,265.5c-1,0-1.9,0-3.2,0.7c1,0.7,2.5,1,3.4,1.6
    C294.7,266.4,294,265.5,294,265.5z M179.2,1907.2c-39.6-41.1,50.3-19,30.6-52.8c2.5-1,5.1-2.5,7.5-3.4c1.5-0.7,2.5-1,4.1-1.9
    c0-0.7,0-1-0.7-1.9c0-1-0.7-2.5-0.7-3.2c-9.7-6-14.1-13.3-13.8-24.1c-2.5,0.7-4.7,0.7-7.3,0.7c-1.9,0-4.1,0.7-5.6,0.7
    c-6-24.6-14.1-61.3-4.1-51.2c13.2,13.3,19.5-18.1-30.6-67.9c-4.1-4.1-1.5-20.5-20.4-39.5c-27-27.2,15.4-39.8,3.2-52.1
    c37.1,37.2,36.8,41.5,47.5,54.3c3.4,1.6,44.7,39.8,51.6,46.4c13.8,13.9,28.9,50.2,30.6,68.6l0,0c0,1.6,0,3.2,0,4.8l0,0
    c-0.7,8.9-6.6,10.1-20.7-4.1c41.3,41.5,4.7,79.9-10,65.4c-11.4-11.4-6,48-8.2,66.4c0.7,0,1.5,0,1.5,0c1.5,0.7,3.2,2.5,3.4,4.8
    c-1.9,0.7-3.4,1.6-5.6,1.9c0,0.7-0.7,1.6-0.7,1.9c-0.7,1.9-0.7,3.4-1,5.7c-1.9,0-4.7,0-8.2,0c1.5-4.1,2.5-7.5,4.7-10.8
    c0,0,0,0,0-0.7C209.1,1897.3,191.8,1898.3,179.2,1907.2z M182.7,1709c-0.7-1-1-2.5-1.9-3.4c-0.7,0-0.7,0.7-1,0.7
    c-0.7,1.6,0,2.5,0.7,3.2s1,1,1.9,1C182.4,1709.6,182.4,1709,182.7,1709z M423.3,292.6C423.3,292.6,423.3,293,423.3,292.6
    c1.5,1,3.2,1.6,4.1,1.9c-1.5-1-3.2-1.6-4.7-2.5l0,0l0,0l0,0l0,0C423,292.6,423,292.6,423.3,292.6C423,293,423.3,293,423.3,292.6
    L423.3,292.6z M417.9,655.8c-1.9-1-5.1-1-7.5,0c-1,0.7-1.9,4.1-1.5,4.8c1.5,1.9,4.1,4.1,6.6,3.4c2.5,0,5.1-1.9,7.5-3.2
    C421.4,659.5,419.9,657.1,417.9,655.8z M764,2226.9c0.7,1,1.5,1.9,1.9,2.5l1-2.5l-1-1.9C764.8,2225.7,764,2226.4,764,2226.9z
    M651.7,2365.1l1-0.7l0,0l0,0c-6-1.6-12.2-3.2-18.2-4.8l-1,2.5c5.6,2.5,11.4,5.1,16.7,7.5C650.7,2368.6,651.7,2366.9,651.7,2365.1
    C652.3,2365.1,652.3,2365.1,651.7,2365.1L651.7,2365.1L651.7,2365.1z M706.7,2153.7c-0.7,0-1,0-1,0
    C706.4,2153.7,706.7,2153.7,706.7,2153.7L706.7,2153.7z M655.7,2127.1l0.7,0.7C655.7,2127.4,655.7,2127.4,655.7,2127.1
    L655.7,2127.1L655.7,2127.1z M672.1,2133.7c0-0.7,0-0.7,0-1c-5.1-1.9-10.7-4.1-15.8-6v0.7C660.5,2132.8,664.6,2137.8,672.1,2133.7
    L672.1,2133.7L672.1,2133.7z M706.4,2153.7h1l0,0l0,0c-4.1-6-8.8-11.6-13.2-18.3c13.8-2.5,13.8-2.5,30.2,16.7
    c-6,0.7-11.6,1-16.7,1.6C706.7,2153.7,706.4,2153.7,706.4,2153.7c4.7,3.2,9.2,6.7,13.2,9.8c6.6,5.1,12.2,4.1,16.3-3.4l0,0
    c3.4-8.2,4.1-15.9-4.7-22.4c-4.7-3.2-9.2-7.5-12.6-12.3c-5.1-7.5-11.6-12.3-20.7-14.2c-4.7-1-8.2-7.3-12.2-11.4v0.7l0,0l0,0l0,0
    l0,0c-0.7,0-1,0-1,0c-4.7,4.8-9.2,8.9-14.8,13.9c1,5.7,1.5,12.3,2.5,18.3c0.7,0,1,0.7,1,0.7c-0.7,0-0.7,0.7-1,0.7
    c4.1,3.4,9.7,6,11.6,10.1C689.1,2155.2,696.7,2156.2,706.4,2153.7L706.4,2153.7z M681.2,2347.1c-1,0-1.9,0-3.2,0
    c0.7,0.7,1,1,1.9,1.6L681.2,2347.1z M677.1,2367.9c1.5-0.7,3.2-1,4.7-1l0.7-5.1c-1.5,0.7-4.1,0.7-4.7,1.6
    C676.8,2364.5,677.1,2366.7,677.1,2367.9z M656.4,2127.4L656.4,2127.4C655.7,2127.4,655.7,2127.4,656.4,2127.4L656.4,2127.4z
    M683.4,2163.4l1.5-1.6l-1.9-0.7L683.4,2163.4z M719.6,2196.7c-1.5-0.7-3.4,0-5.1,0c1,1.6,1.9,3.2,3.4,3.4c0.7,0.7,2.5-1,3.4-1.9
    C720.5,2198.2,719.9,2196.7,719.6,2196.7z M801,2155.9c-2.5-10.1-9.7-12.3-17.3-13.9c-4.1-1-4.7-4.8-3.2-8.2c-4.1,0-8.8-0.7-15.4-1
    c-3.4,14.2-7.3,27.5-10.7,41.5c10.7-1.9,21.4-5.1,32.8-6C796.1,2166.6,796.3,2159.3,801,2155.9z M715.9,2236.1c1,0,1.9,0,3.4-0.7
    c-1.5-0.7-1.9-1-3.2-1C716.4,2234.6,716.4,2235.5,715.9,2236.1z M656.4,2127.4L656.4,2127.4
    C655.7,2127.4,656.4,2127.4,656.4,2127.4L656.4,2127.4z M698.5,2318.9c1,0,1.5-0.7,2.5-1c0-1.9,0-4.1-0.7-5.7
    c-1.5-4.1-2.5-9.8-9.2-8.2c-1.5,0.7-3.4,2.5-3.4,3.4C687.5,2313.3,693.1,2318.9,698.5,2318.9z M671.2,2399.2l-2.5,0.7l1.9,1.6
    L671.2,2399.2z M686.5,2259.5c-0.7,0-1.5,0.7-1.9,0.7c1,0.7,1.9,0.7,3.4,1C687.5,2261.1,686.8,2260.2,686.5,2259.5z M538.1,2762
    L538.1,2762L538.1,2762c-0.7,0-0.7,0-1,0c-6.6,8.9-7.3,8.9-16.3-1c6.6,0.7,11.4,0.7,16.3,1c0-0.7,0.7-0.7,0.7-1
    c-4.1-6.7-8.2-13.9-13.8-22.4c-5.6,3.4-10,6-13.8,9.8c-2.5,2.5-4.7,6-6.6,9.8c4.1,1.6,8.8,3.2,12.6,5.1c3.4,1.6,6,3.2,8.2,5.1
    c-3.4-2.5-6.6-4.8-10.7-6c-3.4-1.6-7.3-2.5-10.7-3.4c-0.7,1-1.5,2.5-1.9,3.4c1,5.1,1.9,10.8,3.4,16.7c12.6,11.4,28,9.2,43.4,9.2
    c4.1-11.6-3.2-19.6-10-27.2l0,0L538.1,2762L538.1,2762z M609.5,2521.2c0.7,1,1,1.9,1.5,3.2c0.7-0.7,1.5-0.7,1.9-1
    c-0.7-0.7-0.7-1.6-1-1.6C611.7,2521.2,610.4,2521.2,609.5,2521.2z M582.2,2629c1,2.5,1.9,5.1,3.2,8.2c1.5-0.7,3.4-1,5.1-1.6
    c0-1,0-2.5,0.7-3.2c1-0.7,1.5-0.7,2.5-1c0-1.6-0.7-3.2-0.7-4.8C588.8,2627.4,584.9,2628.6,582.2,2629z M531.2,2623
    c-1-2.5-1.9-4.8-3.2-7.3c-1,0.7-2.5,0.7-3.4,1c0.7,3.2,1.5,6.7,2.5,9.8c0.7,0.7,1.5,1,1.9,1.6c1-1.6,2.5-3.2,2.5-4.1
    c0-0.7,0-0.7,0-1c-0.7,0-1,0-1.5,0C529.9,2623.3,530.5,2623,531.2,2623z M546,2894.8c0.7,0,1-1,1-1.6c-0.7-0.7-1-1-1.5-1.6
    c-0.7,1-1,1.9-1.5,2.5C544.4,2894.1,545.3,2894.8,546,2894.8z M544.7,2837.9c0.7,0.7,1.5,0,2.5,0c0-0.7,0-1.9,0-1.9
    c-0.7,0-1.5,0-2.5,0C544.7,2836.4,544.7,2837.9,544.7,2837.9z M668,2242.8l1.9-0.7l-1.5-1L668,2242.8z M537.8,2761.1
    c0,0.7,0.7,0.7,0.7,1l0,0L537.8,2761.1L537.8,2761.1L537.8,2761.1z M640,2469.4c-9.7-10.8-18-10.1-31.4-1c8.8,6,8.8,6,19.5,8.2
    c1,1.6,2.5,3.2,3.2,4.8c0.7,3.4,0,7.5,1,11.4c1,3.4,3.2,6.7,6,11.6c4.7-4.1,8.8-7.3,12.6-10.8c-1.9,0-3.4,0-5.6,0
    c0-1,0.7-4.8,0.7-5.1c1.9,1.6,4.1,3.2,6,4.8c0.7-0.7,1-0.7,1-1l0,0C648.1,2484.2,645.7,2475.7,640,2469.4z M671.2,2184.3
    c-1-1.9-3.2-4.8-4.7-6.7c-1.5,3.2-4.1,6-4.1,9.2c0,1.6,2.5,4.8,4.7,6c1,0.7,4.7-0.7,4.7-1C671.7,2189,671.7,2186.5,671.2,2184.3z
    M663.6,2371.7L663.6,2371.7L663.6,2371.7L663.6,2371.7z M663.9,2327.8l1.5-1.9l-1.9-0.7L663.9,2327.8z M179.2,1907.2
    c-0.7,0-0.7,0.7-1,0.7c1,0,1.5,0,1.9,0.7C179.5,1907.5,179.2,1907.2,179.2,1907.2z M652.3,2365.1c0-0.7,0-0.7,0.7-1L652.3,2365.1
    L652.3,2365.1z M90.5,1969.4c-1.5-2.5-1.5-4.8,0-6l-4.7-1.9c4.1-3.2,7.3-3.4,10-3.2c-1-1.9-3.2-4.1-5.1-6
    c5.1,5.1,74.2-43.9,87.8-44.6c-16.3,11.4-24.5,35.4-14.8,46.4c-0.7,1.6-1,2.5-1.5,4.1c-10.7,1.9-45.9-1.6-63.2,1.9
    c1.9,1.6,4.1,4.8,6.6,9.2l-8.2-3.2C96.2,1967.5,94,1968.4,90.5,1969.4z M140.8,1954.6c0,0.7,0,1,0,1c0.7,0.7,1.5,0.7,2.5,0.7
    c-0.7-1-1-1.6-1.5-1.9C141.5,1954.6,140.8,1954.6,140.8,1954.6z M2061.4,1775.3c1-4.1,1.9-8.9,1.5-12.6c0-1.9-3.4-4.1-5.6-5.7
    c-1-0.7-4.1,1-4.7,1.9c-0.7,3.4-1.5,8.2,0,10.8C2054.4,1771.7,2058.5,1773.4,2061.4,1775.3z M2026.8,1741.1
    c-4.1-17.4-4.1-17.4-19.9-14.2C2012.6,1730.7,2017.7,1734.8,2026.8,1741.1z M655.7,2018.7c-0.7,0.7-0.7,0.7-1,1
    c3.2,1.6,5.1,3.2,6.6,4.8c3.2,3.2,6.6,5.1,10,6c0.7,5.1,0.7,9.8,0.7,15.9c4.7-3.2,7.5-6.7,9.2-10.1c-1-3.4-3.4-6.7-6-8.9
    c-1.9-1.9-3.4-3.4-4.7-6c0.7,0,0.7,0,1-0.7C666.4,2020,661.4,2019,655.7,2018.7z M2046.9,1802.8l-3.2,4.8c0,0.7-0.7,0.7-0.7,1
    c1.9,0,4.1-0.7,6-0.7c-1-2.5-1.9-5.1-3.4-7.5C2046.3,1800.9,2046.9,1801.8,2046.9,1802.8z M2049.7,1808.1
    C2049.5,1808.1,2049.5,1808.1,2049.7,1808.1c0,0.7,0,1,0,1.6V1808.1z M491.1,1319c0-2.5-0.7-4.8-1-5.1c-1.9-1.6-4.7-2.5-9.2-4.1
    c-1-0.7-2.5-1-3.4-1.6c0.7,4.1,1,7.5,1.5,10.8c-1.9,0-2.5,1-1.5,1.9c-32.1-32.3-52.8,6-62.3,22.2c-3.2,0-5.6,1.6-7.5,3.2
    c-71.7-71.7-6,27.5-16.3,17.4c-29.6-29.7,24.8,34.8,10,22.2c3.4,3.2,7.3,6,10,8.9c-0.7,0.7-1,1.6-1.5,1.9c1.5,0.7,2.5,1,4.1,1.6
    c1.6,0.6,2.5,1,4.1,1.6c8.8,5.7,15.4,7.5,19.5,8.2c0-1,0-1.6,0.7-2.5c0.7,0,1,0,1.5,0c5.1,0,10-1.9,15.4-4.8c1.5-0.7,2.5-1,4.1-1.6
    c-0.7-1.9-1-4.1-1.5-5.7c0-0.7-0.7-1-0.7-1.6c6-7.3,13.8-3.4,20.7-5.1c0-1,0-1.6,0-2.5c0.7,0.7,1,1,1.5,1c1.9,1.9,4.1,4.1,6,5.1
    c2.5-0.7,5.6-1,8.8-1.9c5.6-1,11.4-2.5,17.3-4.1l1.5-0.7l1,1c0.7,0.7,1,1.6,1.9,1.9c1,1.6,2.5,2.5,3.4,3.2c2.5,1,5.1,1.6,7.3,1.6
    c0.7,0,0.7,0,1,0c0,0,0.7-0.7,0.7-1c8.2,5.7,15.8,13.3,20.7,26.3c-20.4-14.9-37.1-20.8-54.4-6c4.1,15.5,18.2,24.9,20.4,42
    c-9.7,3.4-18.9,6.7-27.4,10.1c1.9,6.7,4.1,13.9,6.6,20.8c9.7,11.6,18.9,22.2,20.7,24.6c0.7,0.7,1,1.6,1.5,1.9
    c0.7-0.7,1.5-1,1.9-1.6c0.7,1.9,2.5,4.1,1.9,6c-0.7,1-1,2.5-1.5,3.4c0,1.9-0.7,4.8-1.5,6.7c-3.4-3.2-7.3-6.7-9.7-10.1
    c-5.1-7.3-10.7-11.4-18.9-13.3c-4.1-1-7.3-4.8-10-7.5c-0.7-1-1.5-1.6-1.9-1.9c-3.4,3.4-7.5,7.3-12.6,11.6
    c0.7,5.1,1.5,10.1,1.9,15.5l5.1,1.9l-1.9,1.6l0.7,0.7c3.4,2.5,7.3,4.8,8.8,8.9c3.4,8.2,8.2,9.2,12.2,9.2h0.7l-0.7-0.7l3.4-0.7
    c-2.5-3.4-5.1-6.7-7.5-10.1l-5.6-7.5l4.1-1c4.1-1,6.6-1.6,9.2-1.6c6.6,0,10,3.4,19.9,14.9c-1,3.2-1.9,5.7-1.9,8.9
    c-2.5,0-4.7,0.7-6.6,0.7c1.9,1.6,4.7,3.2,6.6,5.1c0.7,6,3.4,13.3,11.4,21.5c-8.2-8.2,23.9,93.6-39.6,29.7
    c-16.3-16.4,9.7,70.2-15.8,44.9c-9.7-9.8-35.5,67.6-65.1,37.9c-11.4-11.4-20.7-12.3-29.6-8.9c1.5,0.7,3.4,0.7,5.1,0.7
    c6,15.9,21.4,21.5,34.6,30.7c-1,5.1-1.5,9.8-1.9,14.2c-6-4.1-4.7,7.3-9.7,4.8c-8.8-4.1-17.3-8.2-26.1-11.6
    c-10-4.8-19.9-9.8-24.5-18.1c-6,7.5-11.4,14.2-15.4,14.9c1.5,0,3.4,0.7,5.1,0.7c0.7,7.3,1,13.3,1.9,20.8c-10-1-18.2-1.9-26.5-2.5
    c0,7.3,0,13.3,0,19.6c-7.5,1-11.4-3.4-12.2-7.5c-1.5-5.7-4.1-10.1-7.5-13.9c-12.2,8.9-24.8,15.5-33,7.3c-7.5-7.5,0,18.1,4.1,21.5
    c-10.7-10.8,15.8,24.1,15.4,24.6c5.6,4.1,51,39.5,64.8,50.2c3.4,5.7,19.5,24.9,33.6,43h0.7v0.7c22.3,28.2,40.3,52.8,1.5,13.3
    c-3.2-3.2-62.3-7.3-29.6,25.6c20.7,20.8-95.6-17.4-64.2,13.9c-35.5-35.7-78.3,12.3-61.7,29c8.8,8.9,6.6,14.9-1,18.3
    c0,0,0-0.7,0.7-0.7c0.7-0.7,0-3.2-1.5-6.7c-1.5,0-3.4,0.7-4.1,1c-2.5,1.6-5.1,4.8-7.3,7.3c-0.7,1-1,1.6-1,1.6
    c-12.6,1-28.6-1-40.9-6c0.7,0,0.7,0,1,0c1,0,2.5-0.7,3.2-1c1-2.5,1.9-4.8,2.5-7.5c-2.5,1.6-5.1,3.2-7.3,5.1c0,0.7,0,1.9,0,3.2
    c0,0,0,0,0,0.7c-5.8-6.5-10-9.3-12.6-8.2c-7.2,3-2.6,34.7,13.9,95.1c-6.4,35.1-13.3,43.7-18.8,44.1c-19.1,1.4-44.2-93.6-43.6-93.8
    c0,0-0.1,1.6,1.7,3.3c19.5,19.6-76.4,13.3-44.3,45.6c-32.8-32.9-64.2,25.6-35.5,54.3c-9.2-9.2-40.3,13.9-21.4,33.1
    c-4.7-4.8-8.2-7.5-11.6-9.8v-201.4c5.1-5.1,7.5-8.9,5.6-11.4c-2.5-2.5-4.7-5.1-5.6-8.2v-18.3c9.3-22.3,18.6-44.6,27.9-67
    c-0.3-11.3,1.4-25.8,11.3-34.4c13.7-11.8,30.5-1.4,45.7-14c8.4-6.9,5.9-12.3,17.7-37.1c11-23.2,16.6-34.7,23.6-36
    c23.9-4.3,76.9,86,51.1,116.6c-4,4.8-12,10.5-29.2,11.2l0.7,0.7c1.9,1.9,5.1,3.4,8.2,4.8c1,0.7,1.9,0.7,3.2,0.7
    c14.1,6.7,24.8,11.6,16.3,3.2c26.1,26.3,77.3,30.7,53.5,6.7c-3.4-3.4,21.4,0.7,28.6-7.5c0,0,0,0,0.7,0c0,0,0,0,0-0.7
    c3.4-4.8,1.9-12.6-13.2-28.2c5.6,5.7,5.6,10.1,1.5,11.4c-3.2-2.5-6.6-5.1-10.7-7.5c-1.5-1-4.1-1-7.3-1c-1,0-1.9,0-3.2,0
    c-6-4.1-12.6-9.8-20.7-18.1c-48.4-48.7,23.9-1.6-14.8-41.1c-11.6-11.6,1-67,12.6-54.6c-20.4-20.5,0-22.2,23.3-19c0,0.7,0,1,0,1.6
    c0,1.6,0,2.5,0.7,4.1c0.7,0,0.7,0.7,1,0.7c1.5,1,3.4,1.6,4.7,1.6c0.7,0,1.5,0,3.2-1s2.5-2.5,4.1-3.4c0.7,0,0.7-0.7,1-0.7
    c15.8,3.2,29.6,7.3,31.1,8.9c1.9,1.9,18,3.4,33.6,1.9c1.9-1.9,4.7-3.4,6.6-5.7c-0.7,1.6-0.7,3.2-0.7,4.8
    c19.5-3.2,37.1-11.4,27.4-29c-1,1-1.9,2.5-1.9,3.4c0,6.7,1.9,13.9-8.8,18.1c-1-4.1-1.9-7.3-1.9-10.8c-0.7-17.4-1.5-19-20.7-22.4
    c7.3-10.8,18.9-14.9,30.6-19c4.7-1.6,9.2-6,6.6-10.8c-1.9-3.4-7.3-6-11.4-7.3c-5.1-1-11.4,4.8-14.8-1.6
    c12.6-5.7,25.5-11.4,37.7-16.7c1.5-9.8,3.2-18.1,4.7-27.5c-4.7-0.7-8.2-1-11.6-1.6c-3.4-0.7-7.3-1-10.7-1.9c0-0.7,0-1,0-1
    c3.4,1,7.3,1.9,10.7,3.2c5.1-5.1,10.7-4.8,15.8-1c7.5,5.1,15.8,6.7,24.5,7.3h0.7c0.7,0.7,1,1,1,1c0-0.7-0.7-1-0.7-1
    c4.7,0.7,8.8,0.7,12.6,1.9c7.3,2.5,13.8,5.1,20.4,7.5c6-18.3,29.6-39.8,33-36.4c5.1,5.1,1.9,1-4.7-8.2c1-1,1.5-1.9,2.5-3.2l0.7-0.7
    c-1.9-2.5-4.1-4.1-6-4.1c-1,0-1.5,0.7-2.5,1c-8.8-11.4-20.7-25.6-30.6-37.2c0.7-1.6,1-2.5,1-4.1c0.7-1.9-0.7-3.4-1-4.1
    c-1-1.6-3.2-1.9-4.7-2.5c-1.5,0-3.2,0-4.1,0c-1-1.6-1.9-2.5-3.2-3.4c0,1-1,1.9-2.5,3.4c-5.1,0-10,0-15.4,0c-3.2,0-5.6,0-8.8,0
    c0,0.7,0,1.6,0.7,1.9c0.7,2.5,1,5.7-1,8.2c-0.7,1-1.5,1.6-2.5,1.6c3.4,1.9,6.6,3.4,10,4.8l-0.7,0.7c0,0,0,0,0.7,0
    c-2.5,1-5.1,2.5-8.2,3.4c-17.3,4.1-27-4.8-22.3-21.5c1-3.2,1.9-6,3.4-9.8c-7.5-3.2-15.8-8.2-22.9-15.5c-1-1-52.5-42-58.1-43.9
    c-12.2-14.9-56-66.1-67.3-81.8c-2.5-1-4.7-2.5-6-6c-4.1-8.2-5.6-15.9,1-23.4c1.9-2.5,2.5-5.7,4.1-8.9c-5.6-1.9-10.7-4.8-15.8-8.2
    c-0.7-1.9,0-4.8,0-7.5c-3.2,0.7-5.6,1-7.5,1.9c-5.6-4.8-11.6-9.8-18-13.9c-1.5-1-0.7-6.7-1-9.8c1.9,0,3.4,0,5.6,0
    c0-0.7-0.7-1-0.7-1c0.7,0.7,1.5,0.7,1.9,1c-0.7,0-1,0-1.5,0c2.5,5.1,5.1,9.8,11.4,10.1c11.6,1,18.2,8.2,23.9,17.4v-1l0.7,0.7
    c1.5-1,3.2-1.9,4.7-1.9c3.4,0.7,7.5,0.7,10,2.5c4.7,4.1,1,8.9-1.9,12.3c-1,1-2.5,1.9-3.4,3.2v0.7h-1c13.8,8.2,15.4,19.6,8.8,32.3
    c-3.4,0-7.3,0.7-10,0c3.4,3.2,8.2,7.5,14.1,13.9c8.8,8.9,22.3-6.7,32.1-24.1c-1-4.1-1.9-8.2-3.2-12.3c3.4,1,5.6,1.9,7.5,3.2
    c5.6-11.6,8.8-23.1,6-25.6c-1.9-1.9,11.6,1,28,3.2c-1-1-1.9-1.9-3.2-3.2c1.9-1.6,4.7-3.2,7.3-4.1c0.7-0.7,1.5-0.7,2.5-0.7
    c0,2.5-0.7,5.1-0.7,9.2c26.5,3.4,56,4.8,35.5-15.9c43.7,43.9,88.7,19,69.2-0.7c46.9,47.1,83.9,22.4,68.8,7.3
    c9.7,9.8,52.5,21.5,69.8,18.1c0.7,1,1.5,1.9,2.5,3.2c3.7,3.7,5.2,6.7,4.5,8.9C551.8,1324.7,530.8,1325,491.1,1319z M415.2,372.9
    c-5.6-3.4-10.7-5.1-15.4-5.1c-2.5,0-4.7,0.7-6.6,1.6c2.5,0.7,5.6,1.6,8.2,1.9c3.4,1,6.6,1.9,10,1.9
    C412.3,373.5,413.8,373.5,415.2,372.9z M420.8,390.3c1-0.7,1.5-1.6,2.5-1.9c-1.9,0.7-3.2,1-3.2,1.6
    C420.5,389.4,420.8,389.6,420.8,390.3z M436.6,403.6c4.1,0,7.5-0.7,12.2-1c1.5,0,3.2-0.7,4.7-0.7c-1.5-7.3-6.6-8.2-12.2-9.8
    c-1-0.7-2.5-0.7-3.4-1c-3.2,3.2-6,6-10.7,6.7l-3.2,1.9C427.1,402.6,431.2,403.6,436.6,403.6z M337.4,699.4c-1.9-1-4.1-1.6-6-1.6
    c-3.4,0-7.3,1.6-10.7,5.1c1.5,0,2.5,0,4.1,0C329.6,702.9,333.6,702,337.4,699.4z M151.8,920c-1-4.1-3.2-5.7-7.5-5.7c0,0,0,0-0.7,0
    c0,0.7,0,0.7,0,1c1,3.2,1.5,5.7,3.2,8.2c0.7,0,1.5,0.7,2.5,0.7c0.7,0,0.7,0,1,0C151.5,923.1,152.5,920.9,151.8,920z M192.1,939
    c-1-1.6-1.9-1.6-2.5-1.6c-1,0-1.9,0.7-3.2,1c-4.1,1.6-7.5,3.4-12.6,5.7c1.9,1,4.1,1.6,6,1.6c4.7,0,8.2-3.2,12.2-6
    C191.8,939.6,191.8,939,192.1,939z M221.7,857.2l-11.6-4.8c-4.7,3.2-5.6,5.7-4.7,8.9c4.1-1,8.8-1.9,13.2-3.4
    C219.8,857.4,220.8,857.4,221.7,857.2z M298.8,957.9c-1.5-4.1-3.4-8.2-5.6-13.3c-1,0.7-1.9,0.7-2.5,0.7c-3.4,1-7.3,1.9-9.7,3.4
    c-0.7,0.7-1,3.2-1,4.8c0,0.7,0,1-0.7,1.9C285.9,955.7,292.5,956.9,298.8,957.9z M412.6,911.8c0,0.7,1,1.9,4.7,4.8
    c3.4-1.6,7.3-3.4,10.7-5.1c-1.5-3.4-3.2-7.3-5.6-7.3c-1.5,0-3.4,1-8.2,5.7C413.3,910.3,412.6,911.1,412.6,911.8z M387.8,721.5
    c-1.5-3.4-3.2-6.7-4.7-10.1s-3.2-6.7-4.7-10.8c-9.2,6.7-11.4,10.8-8.8,16.7c1.5,4.1,4.7,6,8.8,6C381.8,724,384.6,723.2,387.8,721.5
    z M-83.1,2285.8l1,1l2.5,1.6L-83.1,2285.8z M-59.9,2281c-0.7-1.9-1-4.8-1.5-6.7c0,0-1.5,3.4-1.9,5.1L-59.9,2281z M10.6,2082.5
    c0,3.2,0.7,6,0.7,9.2v0.7c5.1-5.1,5.6-10.8,6-17.4l0,0h-0.7c-2.5,0-4.1,1.6-5.6,7.3L10.6,2082.5z M1.5,2238.7c0,0.7,1,1.9,2.5,3.2
    c0.7,0,0.7,0,1-0.7c0-1.6,0-3.4-0.7-4.8c-0.7-1-1-1.6-1.5-2.5C1.8,2235.5,1.5,2237.1,1.5,2238.7z M-18.3,2094.3c0,0,0.7,0.7,1,1
    c0.7-1.6,0.7-1.9-0.7-2.5C-18.3,2093.9-18.3,2094.3-18.3,2094.3z M-47.5,2133.1c4.7,4.1,9.7,6.7,14.8,6.7c2.5,0,5.1-0.7,8.2-1.9
    c0-1.9-0.7-3.4-1.5-4.8c-0.7-1-1.5-1-1.9-1h-0.7c-4.7,1-8.8,1.6-12.2,1.6C-43.4,2133.7-45.4,2133.1-47.5,2133.1z M-57.7,2144.5
    L-57.7,2144.5L-57.7,2144.5c-0.7-0.7-1-1-1-1.6c0,0.7-1.5,1-3.4,1.9C-60.7,2145.1-59.2,2145.1-57.7,2144.5z M-77.4,2052.8
    c-1.5,3.2-0.7,4.8,1.9,7.5c1,1,1.9,2.5,2.5,3.4c1.9,3.4,3.2,7.5,4.1,11.4c0.7,1,0.7,2.5,1,3.4c0.7,0,0.7,0,1,0l0,0
    c9.7,0,11.6-6,12.6-15.9C-62.4,2057.6-69.9,2054.5-77.4,2052.8z M-83.7,2007.9c-1,4.1,0,6,3.2,8.2c1,0.7,1.9,1,2.5,1
    c1,0,1.5-0.7,3.2-1.6c-3.2-1-5.6-3.2-6.6-6.7l0,0l-0.7-0.7C-83.1,2007.9-83.1,2007.9-83.7,2007.9z M-79.6,2289.9L-79.6,2289.9
    L-79.6,2289.9L-79.6,2289.9C-79.6,2289.2-79.6,2289.9-79.6,2289.9z M2175.2,1812.5l3.2,1.6c0-0.7,0-1.6,0.7-2.5
    c-0.7-0.7-1.5-1-1.5-1C2176.2,1811,2175.6,1812.2,2175.2,1812.5z M2130.5,1629.9c-3.4,3.2-7.3,5.7-10.7,8.9
    c-0.7-5.1-1-10.8-0.7-15.9c0.7-7.3-1.9-12.3-8.8-14.9c1-1.9,1.5-4.1,2.5-5.7l-1,0.7l0,0l0,0c0-0.7,0-0.7,0-1c0.7,0,0.7,0,1,0.7
    c3.2-3.2,6.6-6,9.7-8.9c-0.7,0-0.7,0-1,0l0,0l0,0l1-0.7l-10.7-4.1l9.7-8.2h0.7h-0.4l0,0h-0.7h0.7l0,0h-0.7h0.7
    c-1-0.7-2.5-1-3.4-1.9c-1.9-1-4.7-1.9-7.3-3.2c1.9,5.1,0,8.9-4.7,12.3l0,0l0,0c0,0,0,0-0.7,0h1v0.7h-0.7c-6.6,4.1-18,7.3-29.6,9.2
    c0.7,8.2-7.3,13.3-15.4,10.1c-5.1-1.9-10.7-4.1-16.3-6c-5.6,0.7-9.2,1-8.8,1.6c7.3,7.3,5.1,34.8-24.5,13.9c-0.7,0.7-1,0.7-1,0.7
    c-1.5-0.7-3.2-1.6-4.7-1.9l0.7-0.7c-4.7-0.7-9.7-1-14.8-2.5c4.1-4.1,5.6-7.5,2.5-10.8c5.1,5.1,9.7,9.2,13.8,12.3
    c0.7-0.7,1-1,1.5-1.6c1,1,1.5,1.6,2.5,2.5c12.6,0,17.3-6.7-6-29.7c-3.4-3.4,28.9,1.6,43.4-4.8c-0.7-4.1,1.9-8.9,4.1-13.9
    c3.2,0.7,6,1.6,9.7,2.5c1,0.7,1.9,0.7,3.2,1c-0.7-7.5-0.7-14.2-1-21.5c-0.7,0-0.7,0-1,0c-1.5,0-3.2-0.7-4.1-0.7
    c-0.9,0-1.9,0-2.5,0.7l0,0c-3.4,1-7.3,2.5-10.7,4.1c-1,0.7-1.9,0.7-2.5,1c-9.7-10.1-10.7-24.9-4.1-33.1c0.7-0.7,1-1,1.5-1.9
    c6-5.1,16.3-6,31.1,3.2c-1,1.6-1.9,3.2-3.2,4.8c-0.7,1-1,1.9-1.5,3.2c0.7,1.9,1,4.8,1.5,7.3c0.7,2.5,1,5.1,1.5,7.5
    c9.2,7.5,19.9,8.2,31.1,8.2c1.5,0,2.5,0,4.1,0s2.5,0,4.1,0c1.5-6-0.7-10.8-4.7-15.9c1.9-3.2,4.1-5.7,6-8.9
    c4.1-6.7-8.2-14.9,1.9-21.5c4.1,0,8.2,0,12.6-0.7c0.7-2.5,1-5.1,1.9-8.2c14.1,8.2,25.5,20.8,42.1,24.1c2.5,0.7,3.4,5.7,6.6,10.1
    c-15.8,3.2-24.5,14.2-34.6,23.1c13.8,13.9,26.5,20.8,35.5,24.1c0-0.7-0.7-1.6,0-1.9c1-1,2.5-1.9,4.1-3.2c0,1.6,0,3.4,0,5.1
    c-1,0-1.5,0.7-2.5,0.7c0.7,0,1,0.7,1.5,0.7c-12.2,6-33.6,9.2-52.8,3.2c3.2,1.9,5.1,5.7,7.3,9.2c0.7,0,0.7,0,1,0l-0.7,0.7l0,0l0,0
    l0,0c3.4,5.7,7.5,10.8,10.7,15.5c10-1.6,18.2-3.2,27-4.8c0,6,0,10.8,0,14.9c4.1,4.8,12.6,0,13.2,9.2c-11.4,3.2-11.4,3.2-24.5,0
    c-1-0.7-3.2,1-4.7,1.9c3.2,4.1,6,7.5,9.2,12.3C2150.7,1648.2,2142.9,1633.3,2130.5,1629.9z M-49.5,2294c-1,2.5-1.5,5.1-3.2,7.3
    c6.6,2.5,6-2.5,7.5-5.7c1-1.6,1.5-3.4,2.5-5.1C-45.1,2291.8-47,2292.7-49.5,2294z M2087.2,1512.4c1-9.8-5.6-7.5-10-8.9
    C2080.3,1505.8,2083.5,1508.9,2087.2,1512.4z M2134.4,1286.7c7.3,1.9,14.1,4.1,24.5,7.5c-5.1-7.5-8.2-12.6-11.6-16.7
    c-7.3-8.2-16.3-10.1-26.5-5.7c-9.7,4.8-18.9,8.9-28,12.6c-4.1-4.1-6.6-6.7-9.2-8.9c-1.5,0-2.5-0.7-3.4,0c-6,2.5-12.2,5.7-18.9,8.9
    c1,4.1,1,7.5,2.5,8.9c8.8,5.1,13.8,12.3,12.2,24.9c13.8-2.5,26.5,1.6,35.2-14.2C2116.1,1296.8,2126.8,1293.1,2134.4,1286.7z
    M2183.7,1355.9c-1.5-3.2-5.1-8.9-8.2-13.9c-3.4,0.7-5.6,0.7-6.6,1.6c-4.7,3.4-9.2,7.3-13.8,10.8c-4.1,3.2-7.5,6.7-4.7,11.6
    c1,1.6,6.6-0.7,10-0.7c1.9,0,5.1,1,6.6,2.5c1,0.7,0.7,4.1-0.7,5.1c-3.4,3.2-8.2,5.1-11.6,8.2c-4.7,4.8-4.7,11.4-1.5,16.4
    c1.9,3.2,7.3,4.1,11.4,6.7c4.7-8.9,8.2-15.5,11.6-22.2l1,0.7C2179.1,1374.3,2180.9,1366.7,2183.7,1355.9z M2041,1426.1
    c0.7,2.5,2.5,5.1,3.4,7.3c1.9-1,4.7-1.6,4.7-2.5c1-4.1,0-7.5-4.7-8.2C2043.4,1422.3,2041,1425.5,2041,1426.1z M1971.7,1459.6
    l0.7-0.7c-3.4,3.2-6.6,6-10,9.2l0,0l-0.7,0.7l0,0l0,0h1c-10,6-19.9-3.2-32.1-3.2c1.5,6,3.2,10.1,4.1,14.2c16.3-1,31.1-1.6,47.8-2.5
    C1978.4,1470.3,1974.9,1465.3,1971.7,1459.6z M1964.6,1452c-1-1-4.7-1-5.1,0c-1.5,1.9-2.5,4.8-1.9,7.3c1,3.2,3.2,6,4.7,9.2
    c3.2-2.5,6-5.7,9.7-8.2C1969.5,1456.8,1967.1,1454.2,1964.6,1452z M1972.4,1459.3l-0.7,0.3l0,0L1972.4,1459.3z M2191.4,1819.6
    c-1.9-2.5-5.1-4.1-8.8-5.1c-0.7-0.7-2.5,3.4-5.1,7.5c5.1,1.9,9.2,3.4,12.6,4.8C2190.7,1824,2192.2,1820.6,2191.4,1819.6z
    M2190.7,1458.7l-9.2-3.4c-0.7,0.7-0.7,1-0.7,1c3.2,1.6,5.6,3.2,8.8,4.1C2190.4,1459.6,2190.4,1459.3,2190.7,1458.7z
    M2237.8,1387.5c-1.5,0-2.5,0-4.1,0.7l1.9,4.1c1-0.7,2.5-1,3.2-1.9C2238.7,1389.7,2238.2,1387.5,2237.8,1387.5z M2170.8,1680.2
    c1,1,4.1,1,5.1,0s1.5-4.1,1-4.8c-3.2-3.2-6.6-5.7-10-9.2C2163.3,1674.2,2167.8,1677.4,2170.8,1680.2z M2116.8,1542.7
    c0.7,1.6,1,2.5,1.9,3.4c1-1,2.5-1.6,3.2-3.2c0.7-0.7-1-1.9-1-1.9C2119.9,1541.5,2118.3,1542.1,2116.8,1542.7z M2181.2,988.1
    c-10.7,1.9-21.4,5.1-32.8,6c-8.8,1-9.2,8.2-13.8,11.6c2.5,10.1,9.7,12.3,17.3,13.9c4.1,1,4.7,4.8,3.2,8.2c4.1,0,8.8,0.7,15.4,1
    C2174.6,1015.6,2177.7,1001.7,2181.2,988.1z M2179,1700.1c-0.7-1-0.7-1.6-1-1.9c-3.2,1-6,2.5-9.2,3.4c0,0.7,0.7,1,0.7,1.6
    L2179,1700.1z M1954.5,1502.2c0.7,0.7,1.5,1,1.9,1.9c0.7-1,1-2.5,1.5-3.4c-1,0-1.9-0.7-2.5-0.7
    C1954.5,1500,1954.5,1501.3,1954.5,1502.2z M2174.9,1490.6c4.1-3.2,8.2-5.1,11.4-8.2c0.7-0.7,0.7-4.1-0.7-5.1c-1-1-4.1-1-5.1-0.7
    C2176.8,1480.2,2172.4,1482.7,2174.9,1490.6z M1846.2,1371.1l-1.5-1.6c-0.7,0.7-1,1-1,1.6C1844,1371.8,1845,1371.1,1846.2,1371.1z
    M1654.4,1553.4c-0.7-0.7-0.7-1-1-1.6c-0.7,0.7-1.5,1-1.9,1.6c0,0,0,0.7,0.7,0.7c0,0,0,0.7-0.7,0.7c0.7,0.7,1,1,1.9,1.6
    C1654.1,1555.1,1654.4,1554.4,1654.4,1553.4v0.7C1655,1553.4,1655,1553.4,1654.4,1553.4C1655,1553.4,1655,1553.4,1654.4,1553.4
    L1654.4,1553.4z M1793.5,1451l0.7-1.9h-2.5L1793.5,1451z M1709.7,1256c0.7,0.7,2.5-0.7,4.1-0.7c-0.7-1.6-0.7-3.4-1-4.8
    c-0.7-0.7-2.5,0.7-4.1,0.7C1708.4,1253.5,1708.4,1255.2,1709.7,1256z M1658.4,1446.9c-1,0.7-1.5,1.6-2.5,2.5c1.5-0.7,3.2-1,4.7-1
    C1659.4,1447.9,1658.4,1446.9,1658.4,1446.9z M1712.2,1054.4c-1,0-1.9,0-3.4-0.7c1,1,1.5,1.6,1.9,1.6
    C1711,1056,1711.6,1055.1,1712.2,1054.4z M1859.2,1432.1L1859.2,1432.1L1859.2,1432.1L1859.2,1432.1z M1854.1,1386
    c0,0.7,0.7,1.6,0.7,1.9c0.7-0.7,1.5-0.7,1.5-1.6c0-0.7-0.7-1.6-0.7-1.9C1855.4,1385.1,1854.7,1385.6,1854.1,1386z M1847.1,1338
    c-1,1.9-0.7,6.7,0.7,7.3c5.1,1.9,9.2-1,11.6-5.1c1-1.6,0.7-3.4,0-5.7c-0.7-0.7-1-1-1.5-1.6
    C1852.2,1330.3,1849.1,1333.9,1847.1,1338z M1858.2,1453.6c0.7-0.7,1.5-1,1.9-1.6c0-0.7-0.7-1.6-0.7-1.9c-0.7,0.7-1.5,1-1.9,1.6
    C1857.6,1452,1858.2,1452.7,1858.2,1453.6z M1859.2,1432.1C1859.8,1432.1,1859.8,1431.5,1859.2,1432.1c3.4-3.4,7.5-6,10.7-9.2
    c1-1,0.7-3.2,0.7-5.1c-2.5,0-6.6,0-7.3,0.7C1861.6,1422.3,1860.8,1427.1,1859.2,1432.1C1859.8,1431.5,1859.2,1431.5,1859.2,1432.1
    L1859.2,1432.1C1859.2,1431.5,1859.2,1431.5,1859.2,1432.1L1859.2,1432.1c-1.5-1.6-2.5-3.4-4.1-5.7c0.7-1.9,1-3.4,2.5-5.1
    c2.5-3.2,3.2-8.9,4.1-14.2c-2.5,1.9-5.1,4.1-7.5,6l-1,0.7h-1c-0.7,0-1.5-0.7-1.9-0.7c-0.7-3.4-0.7-7.5,0.7-11.6
    c3.2,0.7,5.1,1,7.3,1c12.2,1.6,17.3-3.2,15.4-13.3c-1.5-7.3-5.6-12.6-12.2-14.9c-1.5-0.7-3.2-1-4.1-1.6c0.7-0.7,1-1,1.9-1.6
    c1.9-1.6,4.7-4.1,7.5-4.8l2.5-0.7l1,4.1c1,4.1,1.9,7.5,3.2,11.6c7.3,3.2,12.6,6,18.2,8.9l4.7-1.9c-0.7-1.6-1.5-3.2-1.9-4.8
    c-1-1.9-1.5-4.1-2.5-5.7c7.3,5.7,15.8,10.8,24.5,15.5c-3.2,5.7-6,9.8-7.5,13.9c-1.9,5.1-2.5,11.4-4.7,16.4
    c-2.5,7.3-8.2,10.8-15.4,11.6c-9.2,0.7-18,0-27-0.7C1860.1,1433.9,1859.8,1433.1,1859.2,1432.1z M1955,1388.5L1955,1388.5
    L1955,1388.5L1955,1388.5z M1887.1,1417.2c1.5,1,3.2,2.5,3.4,2.5l0,0c1,0,1.9-1.6,3.4-2.5c-1-0.7-1.5-1-2.5-1l0,0
    C1890.3,1416.7,1888.8,1416.7,1887.1,1417.2z M1910.4,1369.2c-1-1.9-1.5-4.1-1.9-6c0-1-0.7-1.6-0.7-2.5c0-0.7,0.7-1,0.7-1.6
    c0.7-1,0.7-1.9,1-3.2c0.7-5.1,0-10.1-1.5-14.9c-1.9,0-4.1,0-6-0.7c-3.4-5.7-7.5-10.8-10-15.9c-2.5-1-5.1-1.6-7.3-1.6
    c-3.2,0-6,1-9.2,1.9c7.3,10.8,14.8,20.8,22.9,30.7C1900.6,1361,1905,1365.4,1910.4,1369.2z M1888.4,1375.2l-1.5-1.6l-0.7,1.6
    H1888.4z M1818,1440.6c1-1.9,1.9-4.8,3.2-6.7l0,0C1819.8,1436.2,1818.9,1438.7,1818,1440.6L1818,1440.6z M2127.2,1679.2
    c18.9-7.5,18.9-7.5,24.5-18.3c-9.2,4.1-16.7,6.7-24.5,10.8C2126.2,1671.7,2127.2,1676.1,2127.2,1679.2z M2294.1,1758.6
    c-1.5-0.7-3.2-0.7-5.1-1c0,1.6-0.7,3.4,0,5.1c0.7,1,3.2,0.7,4.7,1L2294.1,1758.6z M2146.4,1686.9c-4.7,3.4-8.8,7.3-12.6,10.1
    c4.1,5.1,6.6,8.9,9.7,12.3c1.5-5.1,3.4-10.1,5.1-15.9C2148.6,1692.6,2147.6,1690.7,2146.4,1686.9z M2289.7,1819.6l-0.7,1.9h1.5
    L2289.7,1819.6z M2207,1740.5c-0.7,0-1-0.7-1.9-1c-0.7,0.7-1,1-1.5,1c0.7,0.7,1.5,1,1.9,1C2205.8,1741.5,2206.4,1741.2,2207,1740.5
    z M2194.8,1791.8c0.7,0.7,1.5,0.7,1.9,0.7c0-1-0.7-2.5-0.7-3.4c-0.7,0.7-1,0.7-1.9,1C2194.8,1790.9,2194.8,1791.8,2194.8,1791.8z
    M2267.7,1696c-0.7,0,0,1.6,0,1.9c0.7,0,1.5,0,1.9,0c0.7,0,0-1,0-1.9C2269.3,1696,2268.3,1695.7,2267.7,1696z M2280.9,1465.3
    c0-0.7,0-1.6,0-1.9c-0.7,0-1.5,0-1.9,0c0,0.7-0.7,1.9,0,1.9C2279.6,1465.3,2280.6,1465.3,2280.9,1465.3z M2201.1,1957.7
    c3.4-1.6,7.3-3.2,10.7-5.1c-4.1,0.7-7.5,1.6-11.6,1.9C2199.7,1956.1,2200.4,1957,2201.1,1957.7z M2192.9,1380.4
    c1.9-2.5,3.4-5.1,4.1-8.9c0.7-0.7,2.5-1,8.2-1.6c8.8-1,9.2-7.5,9.7-11.6c0-0.7,0-0.7,0-0.7s0,0,0.7,0c4.1,0,5.1,3.4,7.3,12.3
    c0,1,0.7,2.5,1,3.4l1.5,7.5l3.4-6.7c5.6-11.6,14.1-12.6,22.9-14.2c5.1-1,9.7-1.6,14.1-4.1c1.5,3.2,1.9,5.1,1.5,7.3
    c-0.7,1.9-1.9,3.4-5.1,5.1c-1.5,0.7-3.2,1-5.1,1c-1.9,0.7-4.1,0.7-5.6,1.6c-6.6,2.5-11.4,7.5-12.6,14.2c-1.2,6.7,0,13.3,4.1,19v0.7
    c1.9,2.5,4.7,5.1,7.5,6c11.6,4.1,20.4,12.3,29.6,20.8c1,1,1.9,1.6,2.5,2.5l0,0c-3.4,1.6-7.3,2.5-10.7,3.2c-3.2,0.7-6,0.7-9.2,1
    c-3.4,0-7.5,0.7-11.4,1c-4.1,1-7.5,2.5-11.6,4.1c-0.7,0-0.7,0.7-1,0.7c0-0.7,0.7-1,0.7-1.6c1-4.1,1.9-7.5,2.5-11.6
    c0.7-3.4-3.2-7.5-6-9.2c-1-0.7-1.9-0.7-3.4-0.7c-2.5,0-5.1,1-7.3,1.9c-6,4.1-8.2,9.8-8.8,15.9c1.5-0.7,3.2-0.7,4.1-1
    c3.2,6.7,20.4,14.9,32.8,22.4c3.4-1,7.5-1.9,11.6-2.5c-3.2,1-6,2.5-8.8,4.8c8.2,5.7,12.2,10.8,0.7,13.3
    c40.3-8.9,14.8,30.4,43.4,32.3v-66.4c-1,0.7-2.5,1.6-3.4,1.9c-0.7-1-1-2.5-1.5-3.4h0.7c1.5,0,3.2-0.7,4.7-1v-101.4
    c-0.7,0-1,0-1.5,0c-1.5,0-3.2,0-5.1,0c-6.6-8.9-13.2-16.7-20.4-26.3c0.7-1,1-1.9,1.5-2.5c1.5-3.2,3.2-6,5.1-7.3
    c7.3-3.2,7.5-9.2,7.5-12.6c0-1,0-1.9,0-2.5c4.7-1.6,9.2-3.4,13.2-5.1v-93.6c-5.6,2.5-8.8,5.1-14.1,6.7c8.8-1.9,9.2,0,5.1,3.4
    c-4.7,1.9-9.2,3.4-14.1,5.1c0,0.7,0,0.7,0,1l0,0l0,0c1.5,1,3.2,1,4.7,1.6c-5.6,4.1-12.2,8.2-18.2,12.6c0.7-1,1.5-1.9,1.9-3.4
    c1.9-4.1,7.5-7.3,11.6-10.1c-0.7,0-0.7-0.7-1-0.7c0.7,0,1-0.7,1-0.7c0.7-6,1.5-12.3,2.5-18.3c-1.9-1.9-4.1-3.4-6-5.7l0,0
    c-2.5,1.9-5.1,4.8-7.5,7.3c-0.7,0.7-1.5,1.6-2.5,1.9c-3.2-2.5-6.6-5.1-10-7.5c-0.7,0.7-1,0.7-1.5,0.7c-9.2,1.9-15.4,6.7-20.7,14.2
    c-3.4,4.8-7.5,8.9-12.6,12.3c-9.2,6-8.8,13.9-4.7,22.4l0,0c4.1,7.3,9.7,8.9,16.3,3.4c4.1-3.4,8.8-6.7,13.2-9.8h-1.9
    c-5.6-0.7-10.7-1-16.7-1.6c16.3-19.6,16.3-19.6,30.2-16.7c-5.1,6.7-9.2,12.3-13.2,18.3l0,0l0,0h1l0,0c3.4,1,7.3,1.6,10,1
    c-13.8,10.1-22.1,17.4-1.5,13.3c-18.2,4.1-24.5,41.5-36.8,43.9c3.2-0.7,58.5,41.1,6,52.1
    C2201.4,1336.5,2188.7,1368.1,2192.9,1380.4z M2234.4,1011.2c-0.7,0-1.5-0.7-2.5-0.7c0.7,1,1.5,1.9,1.9,3.2
    C2233.7,1012.5,2234.4,1011.5,2234.4,1011.2z M2227.1,900.9c0.7,0.7,1,1,1,1.6C2228.1,901.5,2227.8,901.5,2227.1,900.9z
    M2270.6,1056.7c0.7,0,0.7,0,1,0c2.5,0,5.1-0.7,7.5-1c1.9-0.7,4.7-1,6-1c0.7,0,1.5,0,1.9,0v-8.2
    C2275.9,1047,2271.5,1050.4,2270.6,1056.7z M2280,904.7c-3.2,1.6-2.5,6.7-1.5,8.9c1.5,3.4,3.4,8.9,8.8,11.4v-22.2h-0.7
    C2284.1,902.5,2281.5,903.7,2280,904.7z M2287.2,677.4v-10.1c-1,1-2.5,1.9-3.4,3.2l0,0C2285.4,672.7,2286.3,675.3,2287.2,677.4z
    M2304.3,753.4c-7.3-4.8-15.8-8.9-22.1-12.6c-7.5,14.9-22.3,36.4-27,37c-4.1,1-12.2,13.3-18.9,27.2c-10,0.7-19.9,1-30.6,1.9
    c-5.1,5.1-11.4,10.8-18.9,18.1c10,6,20.7,14.2,29.6,8.9c3.4-1.9,6.6-3.4,10-4.8c-3.4,10.1-4.1,18.1,0,16.7
    c33.6-7.3-48.8,85-1.5,75.3c8.8-1.9-15.4,71.7-5.6,69.5c11.6-2.5,22.9-1,33,3.2c-1,1.6-1.5,4.8-1,6.7c1,7.3,8.2,12.6,15.8,10.1
    c0.7-1.9,1-4.8,1.5-7.5c8.2,6.7,13.2,14.9,14.1,23.1c-0.7,0-1,0-1.5-0.7c-6-1.9-12.6-1.9-20.7-3.4c-1.9,7.5-3.2,14.2-4.7,20.8
    c-1.9,8.2-8.8,12.3-18,11.6c-6-0.7-11.4-6.7-8.8-12.3c0.7-1.6,1.5-3.2,1.9-4.1h-0.7l0,0c0,0,0,0,0.7,0c1.9-4.8,3.4-9.2,2.5-14.2
    c-15.8,4.1-16.3,4.1-15.4,17.4c0.7,7.3,1,13.3-3.4,19.6c-1.5,1.9-1.5,5.7-1.5,8.2s1.5,7.3,3.2,7.3c0,0,0,0,0.7,0
    c5.1-3.4,10-6,14.8-8.9c3.2-1.6,6-3.2,8.8-4.8c3.4-1.9,5.1-3.2,8.2-3.2c2.5,0,7.3,0.7,13.8,1.6h1.5c0,1.6,0,2.5,0,4.1v4h1
    c6-5.7,11.6-12.6,21.4-5.7c-1,3.2-1.5,6-2.5,9.2c0.7,0.7,1,1,1,1.6c1,1,1.9,1.6,3.4,1.6c1.9,0,4.7-0.7,7.3-1.6
    c0.7,0,1.5-0.7,1.9-0.7c0.7,0.7,0.7,1,1,1.6c1.5,2.5,3.4,5.1,3.4,7.5c0,1,0,1.9,0.7,3.2v-18.1c-0.7,0-1,0-1-0.7s-0.7-1-1-1
    c0.7-0.7,1.5-1,1.9-1.6v-58.7c-3.2-1.6-4.1-3.4,0-5.7v-49c-5.1-0.7-10-0.7-14.8,0.7c0,0-41.3-44.9-4.1-52.8c0.7,0-1-1-2.5-2.5
    c2.5-1.6,4.1-3.4,5.1-7.3c1.9-8.9-4.7-11.6-11.4-13.9c-1-0.7-1.5-3.2-2.5-5.1c5.1-5.1,10.7-10.1,16.3-15.5
    c-1.5-1.9-4.7-4.1-4.1-5.1c1.5-5.7-1.5-8.2-5.6-10.1c1,0,1.5-0.7,2.5-0.7c3.2-0.7-1.9-9.2-5.6-20c0.7-2.5,1-5.1,0-6
    c-1-1-1.5-1.6-2.5-1.9c-3.2-12.3-1.5-24.9,17.3-29c5.1-1,9.2-1.9,12.6-3.4L2304.3,753.4L2304.3,753.4z M2242.8,1179.9
    c-2.5-1-4.7-1.9-7.5-1.9c-3.4,0-7.3,1-11.6,1.6l0,0c3.2,3.4,5.6,4.1,7.3,4.1c2.5,0,5.1-1,8.2-1.9
    C2239.7,1180.9,2241.3,1180.2,2242.8,1179.9z M2261.7,1275c-6,7.5-10.7,16.7-7.5,24.6C2260.1,1293.1,2260.8,1284.2,2261.7,1275z
    M2229.3,1008.4c0.7,0,1,0,1,0C2229.7,1008.4,2229.7,1008.4,2229.3,1008.4L2229.3,1008.4z M109.2,1243.2c-3.4-6-9.2-9.8-15.4-12.3
    C98.9,1235,104.2,1239.1,109.2,1243.2z M77.5,1107.1c-1.9-0.7-4.7,1-6,1c0,2.5,0,4.1,0,5.7c3.2-1,6-1.6,9.2-2.5
    C79,1110.2,78.7,1107.6,77.5,1107.1z M58.3,1161.7c-1.5,0-2.5,0-3.4,0c0.7,0.7,0.7,1.6,1,1.9c1,0,1.9,0.7,2.5,0
    C58.3,1163.9,58.3,1162.9,58.3,1161.7z M23.6,1532.4c0,0,0.7,1.9,1,1.9c1.5,0.7,3.2,0,4.7,0.7c-0.7-1.6-1.5-2.5-1.9-4.1
    C25.5,1531.5,24.6,1531.8,23.6,1532.4z M83.1,1152.2c0.7,0.7,1.5,0,1.9,0c0-0.7,0-1.6,0-1.9c-0.7,0-1.5,0-2.5,0
    C82.8,1151,82.8,1152.2,83.1,1152.2z M16.5,1569.7c-0.7-1.9,0-4.8-1-5.7c-1.5-1.6-3.4-1.9-5.6-3.2c1,1.9,1.5,4.8,3.2,6
    C13.3,1568.2,14.9,1569.1,16.5,1569.7z M98.9,1157.6c0.7,2.5,1.5,5.1,1.9,7.5c1.9-1.6,3.4-1.9,4.7-3.2c-1.5-1.9-3.2-3.4-4.7-5.7
    C101.1,1156.3,98.9,1157.3,98.9,1157.6z M110.8,1242.6L110.8,1242.6L110.8,1242.6L110.8,1242.6z M99.5,1143.3l-0.7,1.9l2.5-0.7
    L99.5,1143.3z M66.8,1019.5c1,1,1.9,2.5,3.2,3.2c1.5,0.7,3.2,0,4.7-0.7c-1-1-1.9-2.5-3.4-3.4C70,1017.8,68.3,1019.2,66.8,1019.5z
    M72.1,1304.6c1,0,1.9,0,3.4-0.7c-1.5-0.7-1.9-1-3.2-1C72.4,1302.9,72.1,1304.3,72.1,1304.6z M77.5,1542.6c2.5-0.7,5.1,0,7.5-1
    c1-0.7,0.7-3.4,1-5.1c-2.5-0.7-5.1-1-7.5-1.6C78.7,1537.5,78.1,1540,77.5,1542.6z M32.8,1615.8c0,2.5,0.7,4.8,1,6.7
    c0.7,1,2.5,1,4.1,1.6c0-1.6,1-3.4,0.7-4.8C36.2,1617.7,34.3,1616.8,32.8,1615.8z M74.9,1530.2c-1-0.7-3.2,0-4.7,0
    c1,1,1.9,2.5,3.2,3.4c1,0.7,2.5,0.7,4.1,0.7C77.1,1532.7,76.3,1531.5,74.9,1530.2z M66.4,1452.5c0,3.2,1,8.2,3.2,8.9
    c3.2,0.7,6.6-1.6,9.7-2.5c-1.5-3.2-3.4-6-5.6-9.8C70.5,1450.6,66.4,1451.5,66.4,1452.5z M118.4,1569.7c0.7-0.7,1-1.9,0.7-1.9
    c-1.9-1.6-4.7-3.4-6.6-4.8c-0.7,0-1.5,1-1.9,1.6c1.5,1.9,3.2,4.1,4.7,5.1C116.4,1569.1,117.7,1569.7,118.4,1569.7z M112.7,1533.4
    c-1.5-1-6,1.6-7.3,3.2c-1.9,4.8,1,7.3,5.6,7.5c2.5,0.7,5.1-1,9.7-1.6C117.7,1538.1,115.9,1534.9,112.7,1533.4z M106.7,1420.2
    L106.7,1420.2v1.9l1,0.7c-0.7,0.7-0.7,0.7-1,1c-2.5,2.5-7.3,7.3-2.5,13.3c0,0,0,0,0.7,0.7c6.6-1.9,13.2-4.1,21.4-6.7
    C118.4,1426.9,112.3,1423.4,106.7,1420.2z M98.9,1593.1c2.5,0.7,4.7,0.7,12.2,0.7c-6.6-3.4-8.8-4.8-10.7-5.1
    c-1.5-0.7-3.4,1-5.1,1.6C97,1591.2,97.5,1592.8,98.9,1593.1z M114.3,1630.7c1,7.3,5.1,11.6,13.8,9.8c-4.1-8.2-7.5-15.9-11.6-23.4
    C115.2,1621.3,113.7,1625.9,114.3,1630.7z M111.1,1242.6c8.8,9.8,1,22.2,6,33.8c3.4-1,6.6-1.9,11.4-3.4c-2.5-9.8-5.1-18.3-7.5-28.2
    C117.7,1244.2,114.9,1243.8,111.1,1242.6z M97,1449l0.7-0.7c-0.7-0.7-1.5-0.7-1.9-1C96,1448,96.3,1448.4,97,1449z M135.1,1611.1
    c-6.6-0.7-15.8-6-18.9,5.7c8.2,1.6,16.3,3.4,24.5,5.7C144.4,1614.6,139.7,1611.1,135.1,1611.1z M108.2,1608.6c-1-1-2.5-1.9-3.4-3.2
    c-0.7,1-1.9,2.5-1.9,3.2c0.7,1.6,1.9,2.5,3.2,3.4C106.7,1611.1,107.7,1610.2,108.2,1608.6z M110.8,1242.6c10.7-1.9,21.4-5.1,32.8-6
    c8.8-1,9.2-8.2,13.8-11.6c-2.5-10.1-9.7-12.3-17.3-13.9c-4.1-1-4.7-4.8-3.2-8.2c-4.1,0-8.8-0.7-15.4-1
    C117.4,1215.1,114.3,1229,110.8,1242.6z M107.6,1380.4c-1.9-2.5-3.4-5.1-4.1-8.9c-0.7-0.7-2.5-1-8.2-1.6c-8.8-1-9.2-7.5-9.7-11.6
    c0-0.7,0-0.7,0-0.7s0,0-0.7,0c-4.1,0-5.1,3.4-7.3,12.3c0,1-0.7,2.5-1,3.4c16.5,5.1,33.2,1.6,42.4-9.1c5-5.8,6.7-12.4,7.3-16.8
    c2.4-4.2,5.6-8,9.5-8.2c21.6-1,49.4,109.9,41.4,113c-0.1,0.1-0.3,0.1-0.4,0.1c-5.1-1-9.7-1.6-14.1-4.1c-1.5,3.2-1.9,5.1-1.5,7.3
    c0.7,1.9,1.9,3.4,5.1,5.1c1.5,0.7,3.2,1,5.1,1c1.9,0.7,4.1,0.7,5.6,1.6c6.6,2.5-129.6-9.4-128.4-2.7c1.2,6.7,13-62.2,8.9-56.5v0.7
    c-1.9,2.5-4.7,5.1-7.5,6c-11.6,4.1-20.4,12.3-29.6,20.8c-1,1-1.9,1.6-2.5,2.5c3.4,1.6,7.3,2.5,10.7,3.2c3.2,0.7,6,0.7,9.2,1
    c3.4,0,7.5,0.7,11.4,1c4.1,1,7.5,2.5,11.6,4.1c0.7,0,0.7,0.7,1,0.7c0-0.7-0.7-1-0.7-1.6c-1-4.1-1.9-7.5-2.5-11.6
    c-0.7-3.4,3.2-7.5,6-9.2c1-0.7,1.9-0.7,3.4-0.7c2.5,0,5.1,1,7.3,1.9c6,4.1,8.2,9.8,8.8,15.9c-1.5-0.7-3.2-0.7-4.1-1
    c-3.2,6.7-20.4,14.9-32.8,22.4c-3.4-1-7.5-1.9-11.6-2.5c3.2,1,6,2.5,8.8,4.8c-8.2,5.7-12.2,10.8-0.7,13.3
    c-40.3-8.9-14.8,30.4-43.4,32.3v-66.4c1,0.7,2.5,1.6,3.4,1.9c0.7-1,1-2.5,1.5-3.4H4.6c-1.5,0-3.2-0.7-4.7-1v-101.4c0.7,0,1,0,1.5,0
    c1.5,0,3.2,0,5.1,0c6.6-8.9,13.2-16.7,20.4-26.3c-0.7-1-1-1.9-1.5-2.5c-1.5-3.2-3.2-6-5.1-7.3c-7.3-3.2-7.5-9.2-7.5-12.6
    c0-1,0-1.9,0-2.5c-4.7-1.6-9.2-3.4-13.2-5.1v-93.6c5.6,2.5,8.8,5.1,14.1,6.7c-8.8-1.9-9.2,0-5.1,3.4c4.7,1.9,9.2,3.4,14.1,5.1
    c0,0.7,0,0.7,0,1c-1.5,1-3.2,1-4.7,1.6c5.6,4.1,12.2,8.2,18.2,12.6c-0.7-1-1.5-1.9-1.9-3.4c-1.9-4.1-7.5-7.3-11.6-10.1
    c0.7,0,0.7-0.7,1-0.7c-0.7,0-1-0.7-1-0.7c-0.7-6-1.5-12.3-2.5-18.3c1.9-1.9,4.1-3.4,6-5.7c2.5,1.9,5.1,4.8,7.5,7.3
    c0.7,0.7,1.5,1.6,2.5,1.9c3.2-2.5,6.6-5.1,10-7.5c0.7,0.7,1,0.7,1.5,0.7c9.2,1.9,15.4,6.7,20.7,14.2c3.4,4.8,7.5,8.9,12.6,12.3
    c9.2,6,8.8,13.9,4.7,22.4c-4.1,7.3-9.7,8.9-16.3,3.4c-4.1-3.4-8.8-6.7-13.2-9.8h1.9c5.6-0.7,10.7-1,16.7-1.6
    c-16.3-19.6-16.3-19.6-30.2-16.7c5.1,6.7,9.2,12.3,13.2,18.3h-1c-3.4,1-7.3,1.6-10,1c13.8,10.1,22.1,17.4,1.5,13.3
    c18.2,4.1,24.5,41.5,36.8,43.9c-3.2-0.7-58.5,41.1-6,52.1C99.1,1336.5,111.8,1368.1,107.6,1380.4z M57.6,1219.6
    c0.7,0,1.5,0.7,2.5,0.7c-0.7-1-1.5-1.9-1.9-3.2C58.3,1218.2,57.6,1219.2,57.6,1219.6z M64.9,1329.9c-0.7-0.7-1-1-1-1.6
    C63.9,1329.2,64.2,1329.2,64.9,1329.9z M21.4,1174c-0.7,0-0.7,0-1,0c-2.5,0-5.1,0.7-7.5,1c-1.9,0.7-4.7,1-6,1c-0.7,0-1.5,0-1.9,0
    v8.2C16.1,1183.8,20.6,1180.3,21.4,1174z M12.1,1326c3.2-1.6,2.5-6.7,1.5-8.9c-1.5-3.4-3.4-8.9-8.8-11.4v22.2h0.7
    C8,1328.2,10.5,1327,12.1,1326z M4.8,1553.3v10.1c1-1,2.5-1.9,3.4-3.2l0,0C6.7,1558,5.8,1555.4,4.8,1553.3z M-1.2,753.4
    c7.3-4.8,15.8-8.9,22.1-12.6c7.5,14.9,22.3,36.4,27,37c2.7,5.4,5.8,7.7,8.5,8.8c13.2,5.3,35.9-12.1,56.5-43.7
    c30.9,3,61.8,6.1,92.7,9.1c-1.3,1.1-31.4,26.2-25.3,65.3c5.9,38.4,40.9,53.5,42.8,54.3c-16.1-7.1-32.3-14.2-48.4-21.3
    c-5.4-0.8-11.4-1.2-17.8-0.9c-1,0-1.9,0.1-2.9,0.2c-17.6,6.9-35.3,13.9-52.9,20.8c-5.1-20-10.6-34.5-14.3-36.5
    c-1.3-0.7-2.9-1.5-2.9-1.5c-1.2-1.2-2.4-1.8-3.5-1.7c-7,0.6-11.9,26.9-14.6,79c54.8-12.8,83.3-16.1,85.5-9.9
    c1.6,4.4-10,13.5-34.9,27.3c-6.4-23.9-16.3-39.8-24.2-49.9c-11.1-14.3-25.4-26.1-22.7-30.5c1.3-2.2,6.2-1.6,10-0.8
    c3.8,6.1,7.7,12.1,11.5,18.2C216.7,773,283.9,732.5,292.7,742.5c7.9,9.1-32.4,60-120.8,152.5c-62.2,19.9-88.7,26.9-93.7,26.1
    c-0.1,0-0.4-0.1-0.7,0c-6.6,2.6,15.8,71.6,6.3,69.5c-11.6-2.5-22.9-1-33,3.2c1,1.6,1.5,4.8,1,6.7c-1,7.3-8.2,12.6-15.8,10.1
    c-0.7-1.9-1-4.8-1.5-7.5c-8.2,6.7-13.2,14.9-14.1,23.1c0.7,0,1,0,1.5-0.7c6-1.9,12.6-1.9,20.7-3.4c1.9,7.5,3.2,14.2,4.7,20.8
    c1.9,8.2,8.8,12.3,18,11.6c6-0.7,11.4-6.7,8.8-12.3c-0.7-1.6-1.5-3.2-1.9-4.1h0.7c0,0,0,0-0.7,0c-1.9-4.8-3.4-9.2-2.5-14.2
    c15.8,4.1,16.3,4.1,15.4,17.4c-0.7,7.3-1,13.3,3.4,19.6c1.5,1.9,1.5,5.7,1.5,8.2s-1.5,7.3-3.2,7.3c0,0,0,0-0.7,0
    c-5.1-3.4-10-6-14.8-8.9c-3.2-1.6-6-3.2-8.8-4.8c-3.4-1.9-5.1-3.2-8.2-3.2c-2.5,0-7.3,0.7-13.8,1.6H39c0,1.6,0,2.5,0,4.1v4h-1
    c-6-5.7-11.6-12.6-21.4-5.7c1,3.2,1.5,6,2.5,9.2c-0.7,0.7-1,1-1,1.6c-1,1-1.9,1.6-3.4,1.6c-1.9,0-4.7-0.7-7.3-1.6
    c-0.7,0-1.5-0.7-1.9-0.7c-0.7,0.7-0.7,1-1,1.6c-1.5,2.5-3.4,5.1-3.4,7.5c0,1,0,1.9-0.7,3.2v-18.1c0.7,0,1,0,1-0.7s0.7-1,1-1
    c-0.7-0.7-1.5-1-1.9-1.6v-58.7c3.2-1.6,4.1-3.4,0-5.7v-49c5.1-0.7,10-0.7,14.8,0.7c0,0,41.3-44.9,4.1-52.8c-0.7,0,1-1,2.5-2.5
    c-2.5-1.6-4.1-3.4-5.1-7.3c-1.9-8.9,4.7-11.6,11.4-13.9c1-0.7,1.5-3.2,2.5-5.1c-5.1-5.1-10.7-10.1-16.3-15.5
    c1.5-1.9,4.7-4.1,4.1-5.1c-1.5-5.7,1.5-8.2,5.6-10.1c-1,0-1.5-0.7-2.5-0.7c-3.2-0.7,1.9-9.2,5.6-20c-0.7-2.5-1-5.1,0-6
    c1-1,1.5-1.6,2.5-1.9c3.2-12.3,1.5-24.9-17.3-29c-5.1-1-9.2-1.9-12.6-3.4L-1.2,753.4z M62.7,1222.3c-0.7,0-1,0-1,0
    C62.4,1222.3,62.4,1222.3,62.7,1222.3L62.7,1222.3z M2301.4,1206.5c-1.5-1-3.2-1.6-4.7-2.5c-0.7,1-1,1.6-1,2.5
    c1.9,0.7,4.1,1,5.6,1.6L2301.4,1206.5L2301.4,1206.5z M2271.8,1952.6L2271.8,1952.6c1.9,3.2,3.2,8.2,8.2,3.4
    c1.5-1.6,1.9-6.7,0.7-7.5C2275.9,1944.7,2274.2,1950.4,2271.8,1952.6z M2282.2,1888.5c3.2-1.6,6-3.4,9.2-5.1
    c0.7-0.7,0.7-1.9,0.7-2.5c-0.7-0.7-1-1-1.9-1.6c-4.7-1.6-11.4,1.6-11.4,5.1C2279,1886,2281.5,1888.5,2282.2,1888.5z M2291.6,1235.2
    c-1.5,0-3.2,0-4.7,0.7c1,0.7,2.5,1,3.4,1.6C2290.7,1237.1,2291.2,1236.2,2291.6,1235.2z M2161.1,1446.9c-1.5-0.7-3.2-0.7-4.7-1
    c1,1,1.5,2.5,2.5,3.2s2.5,0,4.1-0.7C2162.4,1447.9,2162.4,1446.9,2161.1,1446.9z M2157.1,1468.4c1.5-3.4,2.5-5.7,2.5-6.7
    c-1-5.1-2.5-10.8-3.4-15.9c-3.2,3.4-6,6.7-10.7,11.4C2148.9,1460.2,2152.6,1464.3,2157.1,1468.4z M2167.4,1620.8l1.9-0.7l-1.5-1.6
    L2167.4,1620.8z M2143.5,1709.6L2143.5,1709.6v0.7V1709.6z M2156.1,1445.4v-0.7V1445.4L2156.1,1445.4z M2173.1,1536.4l-1.9-0.7
    l0.7,1.9L2173.1,1536.4z M2171.5,1413.8c-1.9-2.5-3.4-5.7-6-8.2c-0.7-0.7-4.1,0.7-4.7,1.6c-0.7,0.9-0.7,4.1,0.7,5.1
    c1.9,1.9,4.7,3.2,7.3,4.8C2168.3,1416.7,2169.3,1415.7,2171.5,1413.8z M2227.1,1360.3c-1.5-1-2.5-2.5-4.1-2.5c-1,0-1.9,1.9-3.4,3.2
    c1.5,1,3.2,2.5,4.1,2.5C2224.9,1363.6,2225.8,1361.3,2227.1,1360.3z M2156.1,1445.4C2156.1,1445.4,2156.7,1445.4,2156.1,1445.4
    c0,0,0-0.7-0.7-1C2155.4,1444.7,2156.1,1445.4,2156.1,1445.4z M2150.4,1707.4c-1.5-0.7-3.2-1.6-3.4-1c-1,0.7-1.9,1.6-3.2,3.2
    c1.5-0.7,3.4-0.7,5.1-0.7C2149.4,1708.3,2150.1,1708.1,2150.4,1707.4z M2143.5,1709.6l-0.7,1
    C2143.5,1710,2143.5,1710,2143.5,1709.6C2143.8,1709.6,2143.5,1709.6,2143.5,1709.6z M1732.4,1025.4c-1.9-1.9-3.4-4.1-4.7-4.1
    c-8.2-0.7-16.7-1-24.8-1.6c0,0.7,0.7,1.6,0.7,1.9c1,0.7,1.5,1,2.5,1.6c-0.7,0-1,0.7-1.5,1c1,1.9,1.5,4.8,3.2,7.5
    c-1.5,0.7-3.2,1.6-4.7,1.9c0,0.7,0,1,0,1.6c-1,0-1.9,0-3.2,0c-3.4,1.6-7.5,3.4-11.6,5.1c-1-1.9-1.9-3.4-3.2-5.1
    c-4.7,0-8.8,0-13.2-0.7l-5.6,14.9c0.7,1,1.5,1.9,1.9,3.4c0.7,0.7,1,1,1.5,1c-0.7,0-0.7,0-1,0c4.7,8.9,8.8,18.3,21.4,20.5
    c-2.5-9.2,7.5-16.4,2.5-26.3c11.4-1.6,19.9-3.2,30.6-4.8C1725.8,1038,1729.5,1031.4,1732.4,1025.4z M2160.8,1767.5
    c-1-1-1.9-1.6-3.2-2.5l-2.5,3.4c1.5,0.7,3.2,0.7,4.1,0.7C2160.1,1769.4,2161.1,1767.7,2160.8,1767.5z M2148.6,1749.7
    c2.5-1.9,4.7-5.1,7.3-7.3c-1.9-1.9-3.2-3.2-3.2-3.2c-2.5,1-5.1,1.9-7.5,4.1c-1,1-1.5,3.4-1,4.8
    C2144.8,1749.4,2147.9,1750.4,2148.6,1749.7z M2159.3,1495c-4.1-11.4-4.1-11.4-22.3-20.8c-0.7,3.2-1.9,7.3-1,7.5
    C2142.8,1486.5,2150.4,1490.6,2159.3,1495z M1044.9,2159.7c-12.6,1.9-24.5-1.9-33.6-10.1c-12.6,10.1-12.6,10.1-23.3,1.6
    c-2.5-2.5-5.6-5.7-9.2-6c-15.8-4.1-31.4-7.5-46.9-11.4c2.5,7.5,8.2,12.3,16.3,12.3c11.4,0,14.1,7.5,16.7,17.4
    c-5.6,2.5-11.4,4.8-19.5,7.5c18.9,4.8,18.9,4.8,28,15.5c-1.9,15.5-3.4,31.3-5.6,47.1c-0.7,0.7-1.5,1.6-1.9,1.6
    c-22.1,3.4-23.3,7.5-9.2,28.2c9.7-8.9,19.9-16.4,27-27.5c14.1-22.2,14.1-21.5,18-43.9c4.7,2.5,8.2,5.1,12.6,7.3
    c3.4,1.6,7.3,2.5,13.2,5.1c-7.5-18.1-24.8-18.3-35.2-28.7c1.5-7.5,7.3-5.1,10.7-3.4c7.3,3.4,12.2,7.3,21.4,3.2
    c5.6-2.5,14.1,1.6,22.3,3.2c0.7-0.7,1-1,1-1.6l0,0c-1-1-1-6,0-11.4c1,1.9,2.5,3.4,3.4,5.1
    C1050.8,2167.5,1049.5,2165.3,1044.9,2159.7c3.2-6,5.6-11.6,8.8-18.1c1.5,1.6,2.5,3.2,4.1,4.8c3.4,4.1,8.2,5.7,12.6,2.5
    c5.1-3.4,4.1-8.2,2.5-12.6c-0.7-1.6-1-3.4-1.9-5.7c-14.1,1.6-14.1,1.6-17.3,11.4C1050.5,2147.7,1047.3,2153.7,1044.9,2159.7z
    M1100.2,2279.2c-0.7,0-0.7,0-1,0.7c1.9,2.5,3.4,5.1,5.1,7.5c0-1.6-0.7-3.4-0.7-5.7l-0.7-4.1L1100.2,2279.2z M1139.2,1544.6
    c-7.3,0.7-14.1,1-22.1,1c1.5,3.4,2.5,7.3,4.1,10.8c1.9,6,4.1,12.3,7.5,18.1c3.4,5.7,6.6,11.4,10,16.4c3.4-3.2,8.2-1.6,12.2-0.7
    c3.4,0.7,7.5,1,11.4,1.6C1175.7,1564.5,1147,1560.1,1139.2,1544.6z M765.5,2176.1c-3.2-0.7-6.6-1.6-10-1.9c8.8,9.8,1,22.2,6,33.8
    c3.4-1,6.6-1.9,11.4-3.4C769.9,2194.7,768,2185.9,765.5,2176.1z M1131,2131.5c-0.7-4.1-1.5-8.2-1.9-11.6c0,4.1,0,9.2,0.7,14.9
    l1.9-0.7L1131,2131.5z M1086.7,2264.6c4.7,4.8,8.2,8.9,11.4,12.3c0.7,0,1-0.7,1.5-0.7c5.1-1.9,10.7-4.1,15.4-8.2l1-1v-1.6
    c-0.7-7.5-5.6-8.2-7.5-8.2s-4.7,0.7-6.6,1.6l-1,0.7c-3.4,1.6-7.3,3.2-10,3.2c-1,0-1.9,0-2.5-0.7
    C1086.7,2263.6,1086.7,2264.6,1086.7,2264.6z M1068.7,2075.3c-7.3,8.9-11.4,13.3-15.4,18.3
    C1068.2,2099.9,1069.7,2099,1068.7,2075.3z M1172.2,1636.9c1-0.7,1.5-1.6,2.5-2.5c-1.5,0.7-3.2,1-4.7,1
    C1171.3,1635.7,1172.2,1636.9,1172.2,1636.9z M1200.5,1527.8c-1.9-1.9-4.1-3.2-6-4.8c-1,1.9-2.5,4.1-1.9,4.8c1.9,1.6,4.1,2.5,6,3.4
    c-2.5,0.7-4.7,1.6-6.6,3.2c-0.7,0,1,2.5,1.9,4.8c2.5-1.6,4.7-2.5,6.6-4.1c0.7,0-1.5-3.4-1.5-3.4
    C1199.3,1531.4,1201.2,1527.8,1200.5,1527.8z M1217.5,1641c0-0.7,0.7-1.9,0-1.9c-0.7-0.7-1.5,0-1.9,0c0,0.7-0.7,1.9,0,1.9
    C1216,1641,1216.9,1641,1217.5,1641z M1228.2,1573.7l-0.7,1.9l2.5-0.7L1228.2,1573.7z M1204.3,1466.2c-1.9,0-4.1,0-5.6,1
    c3.4,1.9,7.5,4.1,10.7,5.7c0-1,0-1.6,0-2.5c0-0.7,0-1,0-1.6l0.7-3.2l-3.2,0.7C1205.9,1466.2,1204.6,1466.2,1204.3,1466.2z
    M1177.3,1528.2c-0.7,0.7-0.7,1-1,1.6h-0.7c0,0,0,0,0,0.7l0,0h0.7c0.7,0.7,0.7,1,1,1.6c0.7-0.7,1.5-1,1.9-1.6c0,0,0-0.7-0.7-0.7
    c0,0,0-0.7,0.7-0.7C1178.3,1529.5,1177.9,1528.8,1177.3,1528.2z M1232.6,1480.2c-1,1-1.9,1.6-3.4,1.9
    C1232.6,1483.3,1233.9,1482.7,1232.6,1480.2z M1276.7,1639.5c-6.6-14.2-22.1-13.9-34-22.2c-3.4,6-7.3,12.3-10,18.3
    c8.2-0.7,15.4,4.1,21.4,8.9C1260.3,1638.1,1267.8,1637.2,1276.7,1639.5z M1234.2,1489.9l-2.5-0.7l0.7,1.9L1234.2,1489.9z
    M1071.3,1724.8c-0.7,0.7-1,1-1.5,1.9c1,1,1.9,1.9,3.2,3.2c0.7-0.7,1.5-1.6,1.5-1.9C1073.8,1727,1072.2,1725.7,1071.3,1724.8z
    M1085.2,2184.3c-0.7-0.7-1-1-1.9-1c0,1,0,1.9,0,3.2C1084.2,2185.9,1084.5,2185.3,1085.2,2184.3z M896.7,2121.4L896.7,2121.4
    L896.7,2121.4L896.7,2121.4z M900.8,2084.4c3.2,0,6-1.6,9.2-2.5c-1.5-1.9-2.5-5.1-4.7-6c-1.5-1-4.7,0.7-11.6,1.9
    C896.7,2081.6,898.6,2084.4,900.8,2084.4z M795.4,2295.6c0.7,1.6,1.5,2.5,1.9,3.4c0,0,1-0.7,1.9-1l-2.5-3.4
    C796.9,2294.3,795.4,2294.9,795.4,2295.6z M939.8,2186.8c-1.5-0.7-3.4-0.7-6.6,1c1.5,0.7,3.2,0.7,4.7,1
    C938.5,2188.1,938.9,2187.5,939.8,2186.8z M806.1,2253.5c-1.5,0-2.5,0.7-4.1,1c1,1.6,1.5,2.5,2.5,3.4c1-0.7,1.9-1.6,3.2-2.5
    C807.6,2255.1,806.8,2253.5,806.1,2253.5z M767.1,2244.7c-3.2,10.8,0.7,16.7,10,20.5c7.3-6,9.7-12.3,4.1-20c0,0,0,0,0-0.7
    C776.8,2244.7,772.4,2244.7,767.1,2244.7z M800.8,2123.6c1.5,5.7,3.4,10.8-1.9,15.9c-5.6,5.7-11.6,4.1-17.3,2.5
    c0-0.7-0.7-0.7-0.7-1l0,0l-1.5-1.6c0-1-0.7-1.9-1-2.5c0-0.7-1.5,0-1.9,0l3.2,2.5c0,0.7,0.7,1,0.7,1.6l0.7-0.7l-0.7,0.7
    c0.7,0,1,0,1.5,0.7c10,13.3,20.4,26.6,18.2,43.6c-6.6,8.9-16.3-3.2-22.3,7.3c9.2,4.8,18.2,8.9,28.9,13.9c-3.4,6-4.7,13.3-8.8,15.5
    c-5.6,2.5-10.7-1.9-13.2-8.2c-0.7-1-0.7-1.9-1-2.5c-3.4-5.1-10.7-7.3-14.1-3.2c-3.4,4.1-5.6,10.1-9.2,13.9
    c-2.5,3.2-7.5,4.8-11.6,5.7c-3.2,1-7.3,0-10.7-0.7c-1.9-10.1,10-10.1,13.2-17.4c-0.7-4.1-1-8.9-1.9-13.9
    c-7.3-0.7-12.6-1.6-19.5-1.9c1-9.8,8.8-4.1,12.2-8.9l11.6-29.7c7.3,1.6,14.1,2.5,22.1,4.1c-6-10.8-11.6-20.5-18.2-32.3
    c8.2-4.1,14.8-7.3,22.9-11.4c-1.9-3.2-3.4-5.1-5.1-7.5c1.5-12.6-8.8-18.1-16.3-24.6c-4.1-4.1-10-6-13.8-10.1
    c-3.4-4.1-12.6-2.5-10.7-13.3c6.6-3.2,13.8-7.3,20.7-10.8c-0.7-2.5,0-4.8-1-6c-3.2-4.8-4.1-9.2,1-12.3c4.7-2.5,6.6,1.9,8.8,5.1
    c1,1.6,3.2,1.9,5.1,3.4c3.4-2.5,7.3-5.1,11.6-8.2c1,7.5,1,15.5,3.4,22.2c1.9,6,8.2,10.8,11.4,16.7c4.7,8.9,14.8,1.9,20.7,9.2
    c0.7,6.7,2.5,14.2,1.5,22.2c-1,7.3-7.5,10.1-14.1,13.3C802,2116.7,800.4,2121.4,800.8,2123.6z M767.1,2061c0-0.7-0.7-0.7-1-1
    c-0.7,0-1,0.7-1,1c-0.7,2.5,0,5.7,0,8.2c0,0,0,0,0,0.7C765.9,2067.1,766.8,2064.2,767.1,2061z M1103.4,2147.7c-0.7,0-1,0-1.5,0l0,0
    C1103,2147.7,1103.4,2147.7,1103.4,2147.7z M1118.8,2148.9c-0.7,0-1,0-1.9,0c-4.7,6.7-9.2,13.3-13.8,20.5
    C1114.1,2163.8,1114.9,2163.4,1118.8,2148.9z M1020,2218.1c-1,3.4-1.9,7.5,3.2,8.9c1.5,0,4.1-4.8,5.1-7.3c0.7-1.9-1-4.8-2.5-8.9
    C1022.5,2214.6,1020.9,2216.2,1020,2218.1z M1101.5,2185.3c0-1.6-1-3.2-1.5-4.1c-1,1-1.9,2.5-3.2,3.4c1,1,1.9,1.6,3.2,2.5
    C1099.9,2186.8,1101.5,2185.9,1101.5,2185.3z M1080.1,2014.9c0.7,1.6,0.7,3.4,1,4.1c1,1,3.2,0.7,5.1,0.7c-0.7-1.6,0-3.4-1-4.1
    S1082,2014.9,1080.1,2014.9z M1075.3,2209.9c1,0,1.9,0,2.5,0c-0.7-1.6-0.7-3.4-1.5-4.1c-1-0.7-2.5,0-4.1,0.7
    C1073.5,2207.4,1074.5,2209,1075.3,2209.9z M1623.3,1584.5c-0.7,0-1.5,0-1.9,0l1,3.2C1622.4,1586.7,1622.9,1585.7,1623.3,1584.5z
    M1636.5,1544.6c-2.5,1.6-4.7,2.5-6.6,4.1c-0.7,0,1.5,3.4,1.5,3.4s-2.5,3.2-1.9,3.4c1.9,1.9,4.1,3.2,6,4.8c1-1.9,2.5-4.1,1.9-4.8
    c-1.9-1.6-4.1-2.5-6-3.4c2.5-0.7,4.7-1.6,6.6-3.2C1638.7,1549.3,1637.7,1546.8,1636.5,1544.6z M1588.6,1653
    c-3.2-6.7-5.6-12.6-8.8-19.6c-13.2,6.7-28.6,5.1-36.2,18.3c8.8-1,16.3,0.7,22.3,7.3C1573.6,1655.9,1580.5,1652.1,1588.6,1653z
    M1206.8,1564.5c-0.7,0-1.5,0-1.9-0.7c0.7,1,1,1.9,1,3.2L1206.8,1564.5z M1599.1,1594.2l-0.7-1.9l-1.9,1.6L1599.1,1594.2z
    M1544.1,1652.8L1544.1,1652.8l-2.5-9.2l0,0L1544.1,1652.8z M1573.8,1691.6c0.7,1.6,0.7,3.4,1,5.1c0.7,1,2.5,1.6,3.4,1.9
    c-0.7-1-0.7-3.2-1-3.4C1576.4,1693.2,1575.2,1692.6,1573.8,1691.6z M1573.6,1691.6l-9.7,2.5c0.7,0.7,0.7,1,0.7,1.6
    C1567.3,1694.8,1570.4,1693.2,1573.6,1691.6C1573.8,1691.6,1573.8,1691.6,1573.6,1691.6C1573.8,1691.6,1573.8,1691.6,1573.6,1691.6
    L1573.6,1691.6z M734.4,1638.1v0.7C734.7,1638.8,734.7,1638.1,734.4,1638.1L734.4,1638.1z M733.7,1615.4c-1.5,3.4-2.5,5.7-2.5,6.7
    c1,5.1,2.5,10.8,3.4,15.9c3.2-3.4,6-6.7,10.7-11.4C741.2,1623.1,737.8,1619.5,733.7,1615.4z M1340.2,1643.6L1340.2,1643.6
    L1340.2,1643.6L1340.2,1643.6L1340.2,1643.6z M734.4,1637.9L734.4,1637.9c0,0,0,0.7,0.7,1C734.7,1638.8,734.4,1638.1,734.4,1637.9z
    M1490.5,1440.4L1490.5,1440.4L1490.5,1440.4L1490.5,1440.4L1490.5,1440.4z M1579.2,1495.9c14.1,14.2,28,24.9,38.7,32.9
    c-22.3,4.8-61.7,8.9-46.9,24.1c-12.6-12.6-37.1-19.6-55.4-17.4c1.5-1,2.5-1.9,4.1-3.2c0-0.7,0-1,0-1.6c0,0-0.7,0-1,0h-0.7
    c-0.7,0-0.7,0.7-1,0.7c-1.5,1.6-3.2,2.5-3.4,4.1c-22.1,3.4-33,20-0.7,52.8c5.6,5.7-59.1-18.3-64.2-23.4
    c-17.3-17.4-87.1-32.3-64.2-9.8c-6-6-21.4-16.7-35.5-24.9c-3.4,0-5.1-1.6-6-3.4c-18.9-10.8-32.1-14.2-9.7,7.5
    c-9.2-9.2-20.4-16.4-30.2-22.4c0.7-0.7,1-1,1-1c-1.5-1.9-3.2-3.4-4.7-5.1c0,0-0.7,0-1,0h-0.7c-0.7,1-1,1.6-1,2.5
    c-6-3.4-11.4-7.3-14.8-11.4c18,18.1-17.3,8.9-48.4-18.1c1-1.6,2.5-3.2,3.2-5.1c1-2.5,1.5-4.8,1.9-7.5c0.7-3.2,1.5-6,2.5-8.9
    c1-3.2,3.2-6,4.7-9.2c0.7-1,1-1.6,1.5-2.5c-20.4-10.8-39.6-24.6-44.7-49.7c-10-0.7-18.2-1-25.5-1.6h-1.5l-0.7-1
    c-1.5-1.9-3.2-4.1-4.1-6c-3.2-4.8-6-9.2-8.8-14.2c-1.9-4.8-2.5-9.2-3.4-14.2c-0.7-1.6-0.7-3.2-1-5.1c-3.2-0.7-5.6-1-8.8-1.6
    c-2.5-0.7-5.1-1-7.5-1.6c0.7,1.6,1,3.2,1,4.8c1,5.1,1.9,8.9,4.1,11.6c8.8,13.9,18.2,26.6,28,38.9c6.6,8.2,12.6,14.2,22.1,16.7
    c7.5,2.5,12.6,8.2,14.1,16.7c1,5.7,0.7,9.8-1.9,12.6c-2.5,3.2-6,4.1-11.4,4.1c-1.5,0-3.2,0-5.1-0.7c-1,0-1.9-0.7-3.4-0.7
    c-0.7,0-0.7,0-1,0c-0.7,1.9-0.7,4.8-0.7,6.7c-15.8-8.9-28.6-15.9-23.9-10.8c-2.5-2.5-4.7-4.8-6-6c-0.7,10.1-13.2,11.4-14.1,20
    c3.4,5.1,8.8,4.1,13.2,3.2c3.4-0.7,7.5-0.7,11.4-0.7c10.7,28.2-18.2,30.4-27.4,44.6c-9.2-1.6-18.2-3.2-28-3.4
    c-1.9,0-6.6,5.1-6.6,7.5c0,4.8,4.7,4.8,8.8,3.4c2.5-0.7,5.6-0.7,7.5-1c2.5,3.2,4.7,6,7.3,9.2h0.7l0,0l0,0h-1.5
    c-2.5,2.5-5.1,5.7-7.5,8.2c-1.9-0.7-5.1-0.7-7.5-1.6c-1.5-0.7-3.2-1-4.7-1c-0.7-0.7-1.5-1-1.9-1.9l0,0c-1-1-1.5-2.5-1.9-3.4
    c-0.7-0.7-0.7-1.6-1-1.9c-3.4-4.1-5.6-4.8-7.3-4.8c-1.9,0-4.1,1-6.6,2.5c-0.7,0.7-1,1.6-1.9,2.5c-1,1.6-1.9,3.4-4.1,4.1l-1,0.7
    c-3.4,1.6-7.5,3.2-11.6,3.2c-4.7,0-8.2-1.9-11.6-6l0,0c-1-1.6-4.7-3.2-6-3.2l0,0c-3.4,1.9-7.3,2.5-11.4,2.5
    c-6.6,0-12.6-2.5-18.2-5.1c-1.5-0.7-3.2-1.6-4.7-1.9c-0.7,4.8,1.9,7.3,5.6,10.8c3.2,3.2,6.6,6,6.6,11.6v1l-0.7,1
    c-1.5,1.6-2.5,2.5-4.7,3.4c-5.1-8.9-9.7-17.4-14.1-26.3l0,0l0,0l0,0l0,0c8.8-12.6,17.3-25.6,27-37.9c9.7-12.6,11.6-30.7,26.5-40.5
    c1.5-1,1.9-3.2,3.4-4.8c5.6-6,11.4-6.7,18-1c1.9,1.9,2.5,5.7,5.1,6.7c6.6,3.4,13.8,7.5,21.4,0.7c1.9-1.9,7.5-4.1,9.7-3.2
    c10.7,7.3,22.1,1.9,32.1-1c-8.2-8.2-1.9-3.4,1.9-0.7c0.7,0,1,0,1-0.7c-2.5-3.2-9.2-10.1-23.3-24.1c12.2,12.3-92.8-62.8-41.8-11.6
    c39.3,39.5-10.7,23.1-43.7,10.1l-1,0.7v-1c-14.1-5.7-25.5-10.8-24.8-9.8c15.4,15.5-33.6,56.9-59.5,33.8c1.9-4.8,3.2-9.8,4.1-15.5
    c-14.8-9.8-30.6-20.5-45.9-30.7c-4.7,3.2-9.2,6-14.1,9.8l-3.2,1.9c0.7,2.5,1,6,1.9,9.8l0.7,2.5l-2.5,0.7c-2.5,0.7-4.7,1.6-7.3,1.9
    c0,0.7,0,1,0.7,1.6c1.5,2.5,8.8,5.7,18.9,5.7l0,0c5.6,0,10.7-1,15.8-2.5c2.5-0.7,5.1-1.6,7.5-2.5c1-0.7,1.9-1,3.4-1.6l1.5-0.7l1,1
    c8.2,7.3,9.2,15.9,9.7,24.1c0,1.9,0.7,4.1,0.7,6c3.2-3.4,5.1-7.5,7.3-11.6c14.8,15.5,6.6,21.5-6.6,24.9c-0.7,0.7-0.7,1-1,1.6
    c0-1.9-1-4.1-3.2-5.7c-1-0.7-1.9-1-3.2-1c-0.7,0-1,0-2.5,1.6c-1.5,1.9-3.2,4.1-4.7,6.7c-18,3.4-34.6,7.5-16.7,25.6
    c22.3,22.4-28,70.5-30.6,68.6c-39.6-39.8,47.5,108.1,16.3,76.8c-26.5-26.6-23.3,24.9,1.5,52.8c-3.4,1.6-7.3,2.5-10.7,3.4
    c-6.6,1.9-12.6,3.4-17.3,7.5c4.7,0,8.8,1,12.2,3.2c-15.8,13.9-36.8,33.1-38.7,31.3c2.5,2.5-30.6-18.3-43.4-25.6l-1.5-6l-3.2-1.6
    l2.5-1.9l-1-3.4l4.1,1.6c1.5,0,3.4,0,5.6-0.7l4.7-0.7v3.2l0,0c0,0.7,0,1,0,1.6c0.7,0,1.5,0,1.9,0c2.5,0,4.7-0.7,7.3-2.5
    c-5.6-5.1-9.7-11.4-13.8-17.4c-5.6-9.2-11.4-17.4-22.1-21.5c-4.7-1.6-7.3-6-9.7-10.8c-0.7-1-1.5-2.5-1.9-3.4
    c-1-1.6-2.5-3.4-3.4-5.1c-7.3-10.8-13.8-21.5-10.7-18.3c-9.7-9.2-35.5-23.4-34.6-22.4c28.9,29,9.2,35.7-10,31.3
    c4.1-1.9,5.1-6,5.1-13.3c-0.7,0-0.7,0-1,0.7c-1.9,1-4.1,1.6-4.7,1.9c-2.5,2.5-4.7,5.7-7.3,8.2c-4.7-1.9-8.8-4.1-11.4-7.3
    c-22.1-22.2-51-11.6-38.1,12.3c1,0.7,1.9,1.9,2.5,3.2c-0.7,0-0.7,0-1-0.7c1.9,3.2,4.7,6.7,8.2,10.1c0.7,0.7-4.7,11.4-11.6,23.4l0,0
    c1,1,1,4.1,0.7,5.1c-0.7,1-3.4,1.9-4.7,1.6c-3.2,5.7-6.6,10.8-9.7,15.9l1,1.6c-0.7,0-1.5,0-1.9,0.7c-8.2,12.3-15.8,22.2-18.9,19
    c-7.3-7.3,0.7,8.9,9.7,27.5c1.5-2.5,2.5-4.8,3.4-6.7c12.6,1,24.8,1.6,38.1,2.5c5.1,5.1,11.4,10.8,18.9,18.1
    c-10,6-20.7,14.2-29.6,8.9c-7.5-5.1-15.4-6-23.3-6c10,22.4,17.3,41.5-1.9,25.6c0-9.8-3.4-13.3-20.7-13.3l0,0c0,1,0,2.5,0.7,3.2
    c6.6,6.7,9.7,14.2,10.7,24.6c0,0,0,0,0,0.7c-3.4,1.9-7.5,3.2-12.2,4.8c-0.7,3.4-0.7,7.5-1,11.4c-0.7,4.8-0.7,9.2-0.7,13.9
    c-15.4,0.7-20.4-1-22.1-8.9c-1.5-8.9,3.4-11.4,10-13.3c-22.1,4.1-45.3,5.7-36.8,14.2c-28.6-28.7-74.2-29.7-50,6
    c1-1,3.2-1.9,4.1-3.4c1,1.9,1.9,4.1,3.4,7.3c-1.5,0.7-3.2,1-4.1,1.6c3.2,4.1,7.3,8.2,11.6,12.6c5.1,5.1-9.7,1.9-26.1-1.9
    c0,0,0,0,0,0.7c-0.7,0-1.5,0-1.9,0c0,0,0-0.7,0-1c-16.7-4.1-34.6-9.2-32.8-7.5c-6-6-14.8-10.8-23.3-13.3c0.7,0.7,1,1,1.5,1.9
    c-1,0.7-3.2,1.6-3.4,1c-1.5-1-3.2-3.2-4.7-4.8c-25.5-5.1-50,4.1-28.9,25.6c45.9,46.1-84.9-30.4-42.8,12.3
    c37.1,37.2-22.1,49-46.2,24.1c0,0-16.3,4.1-27.4,11.6c0.7,1,1,1.9,1,3.2c1.5,4.8-1.5,11.4-1.5,16.7v0.7c-3.2-1.6-5.6-3.2-8.8-4.8
    c0.7,2.5,1.9,4.1,4.7,6c1.9,1.6,3.4,3.2,5.1,5.7l0.7,0.7v0.7c0,0.7,0,1,0,1.9c0,1.6,0,3.2-0.7,4.8l0,0c-1,1.9-1.5,3.4-1.9,5.1
    c-3.2-3.2-5.6-6-7.5-9.2c-5.1,0.7-13.2,1.9-21.4,4.8c-0.7,1-0.7,1.9-1,3.2c-0.7-0.7-1.5-1.6-1.9-1.9c-17.3,6-31.1,18.1-6,43
    c1.5,1.6-1.9,4.1-7.3,7.5l0.7,0.7c1,2.5,0.7,5.7,0.7,8.2c-1.5,1-3.2,2.5-4.7,3.2c-0.7,0-1.9-0.7-2.5-1c-0.7-1.9-1-3.4-1.5-5.7
    c-5.6,4.1-11.4,8.9-14.1,14.2c-0.7-1-0.7-1.6-1-2.5c-1-3.4-2.5-6.7-3.4-10.1c-1,0.7-1.9,1.6-3.2,1.9c-3.2,1.9-6,4.8-10,7.3
    c2.5,1,4.7,1.9,6.6,3.2c3.2,1.6,6,2.5,9.2,4.1c-1.9,6.7-1,13.9,7.3,22.2c38.7,38.9-6.6,28.2-28.9,28.2c1.5-16.4,0.7-42.3-2.5-45.6
    c-7.3-7.3,3.4-81.3,12.2-72.7c8.2,8.2,11.4,0.7,13.2-12.6c5.1,1.9,10,3.4,15.8,3.4c5.1,0,10-1.6,14.8-4.8c2.5-1.6,5.1-1.9,8.2-2.5
    c0.7,0,1,0,1.5-0.7c-7.5-9.8-16.7-12.6-26.1-15.9c-2.5-1-4.7-3.2-6-5.1c-1.5-1.6-2.5-3.2-4.1-3.2c-0.7,0-1,0-1.5,0.7
    c2.5-21.5,6.6-39.8,22.9-23.4c1.9,1.9,5.6-4.1,9.2-13.3c0.7,0,0.7,0,1-0.7c0,0,0.7,0,0.7-0.7c0,0,0,0-0.7-0.7l-0.7-0.7
    c4.1-9.8,8.8-23.1,13.2-32.9c-3.2-1-5.6-1.9-7.5-3.4c-4.7-4.1-6-11.4-9.2-17.4c15.4-18.3,5.6-35.7-1.9-52.8
    c14.8,9.8,27,22.2,30.6,41.1c8.2,0.7,14.8,0.7,20.7,1c1.9,6.7,6,11.6,12.2,15.5c1.5,0.7,2.5,1,4.1,1.6c1,1.6,1.9,3.4,2.5,5.7
    c1,3.2,1.9,6,2.5,9.2c-1,1-1.9,1.9-3.4,2.5c-7.5-1.9-18.2-4.8-28.9-7.3l0,0c10.7,6,22.9,8.9,33.6,7.5c0,0.7,0,1,0,1.6
    c0.7,1.6,0.7,3.2,1,5.1c3.2,0.7,5.6,1,8.8,1.6c2.5,0.7,4.7,1,7.5,1.6c-0.7-1.6-1-3.2-1-4.8c-0.7-3.2-1.5-5.7-1.9-7.5
    c1.9-1,4.1-1.6,6-3.2c-0.7-3.4-0.7-7.5-1-12.6c-3.4,2.5-6.6,5.1-9.7,7.5c-1.5-2.5-3.2-4.8-4.7-7.3c5.1-1.6,9.2-5.1,12.6-11.4
    c1-1.6,3.2-1.6,4.7-2.5c1.5,1.9,3.2,4.1,4.1,5.1c0,0,0,0,0,0.7l0,0c1.5,1.9,3.2,3.4,4.7,5.1c0.7-1.9,0.7-4.1,0-6
    c0.7-0.7,1.5-1.6,1.9-3.2c0.7-0.7,0-2.5,0-3.4c-0.7,0-1-0.7-1.5-0.7c-0.6,0-1.5,0-1.9,0.7c-1.9-6-6.6-12.3-13.8-19.6
    c14.1,14.2,101.9,44.9,35.5-21.5c11.6,11.6,32.8,20,43.7,20.8c0,0.7,0.7,1,0.7,1c0-0.7,0-1,0-1c7.3,0,9.7-3.2,1-11.6
    c10,10.1-8.8-111,43.4-58.7c-10.7-10.8-10-17.4-16.7-23.4c11.4,11.4,6.6-1.9-0.7-19c4.1,1,8.2,2.5,12.2,4.1
    c1.5,0.7,3.2,1.6,4.7,1.9c0.7-4.8-1.9-7.3-5.6-10.8c-3.2-3.2-6.6-6-6.6-11.6v-1l0.7-0.7c2.5-3.2,5.1-4.1,8.8-4.1c1.9,0,4.1,0.7,6,1
    c0.7,0,1,0,1.5,0.7c1.9,0.7,4.7,0.7,7.3,1c0.7,0,1.5,0,1.9,0c6.6-14.9-1.5-21.5-11.4-29c-4.7-3.4-9.2-7.3-12.2-12.3
    c-1.9,0-4.1,0-5.6,0.7c-3.4,0-7.5,0.7-11.4,0.7c0-5.7,1-11.4,1.5-16.4c1.5-1.6,3.2-3.2,4.7-4.8c-1.5-1.6-2.5-3.4-4.1-5.1
    c-0.7-3.4-1-6-3.2-8.2c1.9,1.9,50-1,44.3-24.1c4.1-4.8,6-10.1,3.2-20c-0.7,0-1.5,0-1.9,0c-2.5,0-4.7,0-7.3,0.7c-0.7,0-1,0-1.5,0
    c-1.5,0.7-3.2,0.7-5.1,0.7h-0.7c-3.2-4.1-4.1-9.2-4.7-15.5c1-1,1.9-1.9,3.2-2.5c3.4-3.2,6.6-5.1,6.6-10.1c-1.5,0.7-3.2,1-4.7,1.6
    c-1.5,0.7-3.2,1-4.7,1.6c1.5-18.1,10.7-34.8,18.9-26.3c-3.2-3.2,68.3-1.6,47.8-22.4c-28.9-29,72.4,1.6,47.5-23.4
    c33,33.1,28-31.6,65.7,6c-4.7-4.8,5.1-8.9,18.2-11.4c4.1-8.9,5.6-9.2,23.3-16.4c0,3.2,1,7.3,0,7.5c-5.1,2.5-10.7,5.1-16.7,7.3
    c17.3-2.5,37.1-3.2,39.6-1c-8.8-8.9,15.4-54.3,66.1-3.2c-18.9-19,46.2-46.4,53.5-39.5c1,1,1.5,1.6,2.5,2.5
    c-1.9,0.7-4.7,1.6-7.3,2.5c1,1.6,1.9,2.5,3.2,3.4c3.4,4.8,7.3,8.9,7.3,16.4c0,10.8,3.2,14.2,20.7,14.2l0,0c0-1,0-2.5-0.7-3.2
    c-6.6-6.7-9.7-14.2-10.7-24.6l0,0c32.1,17.4,56.6-12.6,45.3-23.4c4.1,4.1,11.6-67.6,50-31.3c0.7-0.7,1-0.7,1.5-1
    c1-1.9,4.1-4.1,8.8-6.7c7.5-6.7,13.8-15.5,16.7-27.2c10,0.7,18.2,1,27.4,1.6c5.1-6.7,10.7-12.3,14.1-19c3.2-5.7,4.1-12.6,6.6-20
    c7.3-0.7,13.2-1,22.9-1.9c-4.1,8.9-5.1,15.9-9.2,20.5c-10,12.6-20.7,24.6-32.1,36.4c-1.9,1.9-4.1,4.1-6.6,6
    c3.4,1.6,6.6,3.4,9.7,6.7c5.6,5.7,71.4-5.1,57.6-18.3c-19.5-19.6,16.7-37.2,52.5-1.6c-38.7-38.9,67.3-37,72.4-31.3
    c-1-1-1-1.6-1-1.9c1.5,1,3.2,1.6,5.1,2.5c-0.7-1.6-1-3.2-2.5-4.1c4.7-1.6,14.8-1.6,26.1-1.6c6.6,6.7,14.8,9.2,22.3,11.4
    c2.5,1,4.7,3.2,6,5.1c1.5,1.6,2.5,3.2,4.1,3.2c0.7,0,1,0,1.5-0.7c4.1-1.9,8.8-6.7,12.6-12.6c1.5-1.9,1.9-4.1,1.5-6
    c-0.7-1.6-1.9-3.4-4.1-4.8c10-4.1,13.2-11.4-1-25.6c27.4,27.5,58.1-33.8,93.1,1.6c-8.8-8.9-4.7-5.7,4.7,1.9c-7.5-1-10.7,5.1-6,16.4
    c13.2-1,25.5,5.1,38.7,11.4c-10.7-9.2-23.9-20.5-32.8-27.5c7.3,1,18.9,8.2,33,22.4c31.4,31.6,19.9,22.4,0.7,6
    c-1,4.8-1.5,7.3-2.5,11.4c-7.3-1-14.8-1-21.4-4.1c-1.9-1-4.1-1.6-6-1.9c7.3,10.8,18,24.1,33,39.5c0.7,0.7-1,2.5-3.4,6
    c-0.7,0-1,0.7-1.9,0.7c-5.1,2.5-10,4.8-15.4,7.3c-4.7,1.9-8.8,4.1-12.6,6l-1.5,1l-1.5-1c-3.4-3.4-6-6-8.8-8.2h-2.2
    c-0.7,0-0.7,0-0.7,0l0,0c-4.1,1.9-8.2,3.4-12.6,5.7c-1.5,0.7-2.5,1-4.1,1.9c0,0.7,0,1,0.7,1.9c0,1,0.7,2.5,0.7,3.2
    c5.6,3.4,9.7,7.3,11.6,11.6c-11.4,4.1-20.4,29,4.1,53.8c-9.7-9.8-14.1-4.8-12.2,6.7c9.2-3.2,18.2-6.7,27.4-8.9
    c3.4-0.7,11.4,3.2,11.6,5.7c2.5,12.6,11.4,16.7,22.1,20l0,0c-1.5,1-2.5,1.9-3.4,3.2c-1.5,1.6-1.9,2.5-2.5,4.8
    c-3.4,0.7-7.3,1-11.6,1.9c6.6,3.4,11.4,7.3,16.3,8.9c1,0.7,1.5,1,2.5,1c0.7,0.7,1.5,0.7,1.9,1c0.7-0.7,0.7-1.6,1-1.9
    c1-0.7,2.5-0.7,3.4-1.6c-5.1,6.7-9.7,13.3-14.8,20c16.3,7.3,33.6,15.5,34.6,15.9c3.2,3.2,6,6,10,8.9c1.5-3.4,1-8.2-1.5-12.6
    c-5.6-10.8-12.2-21.5-18.2-32.3c8.8,5.7,15.4,0.7,26.5-4.8c-9.7-2.5-15.8-4.1-22.1-6c2.5-2.5,3.4-4.8,5.1-5.1
    c10.7-1.9,19.9-5.7,23.3-18.1c1-2.5,8.8-5.7,12.2-4.8c9.7,3.2,18.2,7.5,27.4,11.6l-3.4,9.8l-3.2,7.5l0,0l0,0l0,0
    c2.5,0.7,5.1,1,7.3,1c0-0.7,0-0.7,0-0.7l-7.3-0.7l0,0c7.3-2.5,14.1-6,22.9,3.4c-17.3,9.8-21.4,31.6-42.8,37.2
    c-4.7,1-8.2,7.5-11.6,11.6c-1.9,1.9-3.4,4.8-5.6,6.7c35.2,23.4,83.6,36.4,51,3.4C1536.8,1536,1537.4,1454.6,1579.2,1495.9z
    M1428.5,1273.4c0,1.6,0,3.4,0.7,4.1s0.7,0.7,1,0.7c0.7,0,1-0.7,1.9-1.6C1430.5,1275.3,1429.5,1274.4,1428.5,1273.4z M361.6,1826.6
    c0.7,1,0.7,1.6,1,2.5c0.7,1.9,1,4.1,1.9,6c1.5-1.6,1.9-3.2,2.5-4.1l0,0c0.7-1.6,1-3.2,1.5-4.8l0,0c-1-1-1.9-1-3.2-1
    C364.2,1824.7,362.9,1825.6,361.6,1826.6z M359.4,1901.7c-1.9,3.2-3.2,6.7-1.9,10.1c0-1.6,0.7-3.4,1-5.1
    C358.8,1904.9,358.8,1903.4,359.4,1901.7z M371.1,1885l-1.5-0.7l1-1c-0.7-0.7-1-0.7-1.9-1l1.5-5.1h0.7c0-1-0.7-1.9-0.7-3.2
    c-0.7-4.8-3.2-7.3-7.3-7.3c-0.7,0-1,0-1.9,0c-1,0.7-1.9,0.7-3.4,0.7c-7.3,1.6-13.8,3.2-15.8,9.8c0,0.7,0,1,0,1.6
    c1.5,1.9,6.6,4.1,18.2,4.8c1.5,0,3.2,0.7,4.7,1c1.5,0.7,2.5,1,3.4,1C370.1,1886.5,370.8,1886.5,371.1,1885z M624.3,1456.1
    c1.5-0.7,3.4-0.7,5.1-1c1.9-0.7,4.7-1,6.6-1.6c0,0,0-0.7-0.7-0.7c-1.9-4.8-4.1-8.2-6-11.6c-1-1.9-1.9-1.9-2.5-1.9
    c-1,0-1.9,0.7-3.2,1C619.2,1444.5,619.2,1448.6,624.3,1456.1z M610.4,1727.9c1,0.7,1.9,1,3.2,1c0.7,0,1,0,2.5-1.6
    c2.5-3.4,4.7-6.7,7.3-11.4l0.7-0.7c-1.9-0.7-4.7-1.6-6.6-1.9c-1.5-0.7-3.4-1-5.1-1.6C606.3,1718.7,606,1723.1,610.4,1727.9z
    M665.4,1766.8c-1.5-2.5-8.8-5.7-18.9-5.7c-5.6,0-10.7,1-15.8,2.5c-2.5,0.7-5.1,1.6-7.5,2.5c-1,0.7-2.5,1-3.4,1.6l-1.5,0.7l-1-1
    c-8.2-7.3-9.2-15.9-9.7-24.1c0-1.9-0.7-4.1-0.7-6c-6,8.2-9.7,16.7-11.4,27.5c14.8,9.8,30.6,20.5,45.9,30.7c4.7-3.2,9.2-6,14.1-9.8
    l3.2-1.9c-0.7-2.5-1-6-1.9-9.8l-0.7-2.5l2.5-0.7c2.5-0.7,4.7-1.6,7.3-1.9C666.1,1767.5,666.1,1766.8,665.4,1766.8z M667.1,1687.5
    c-4.1-4.8-9.7-7.3-16.3-9.2c-3.4-1.6-7.5-3.2-11.6-5.1c-2.5,4.8-4.7,8.9-7.3,12.3c6,1,11.6,4.1,16.7,7.5c4.7-3.4,9.7-5.1,15.8-5.1
    C665.4,1687.5,666.1,1687.5,667.1,1687.5z M676.8,1485.8c-6.6,0-12.6-2.5-17.3-7.3c-5.6,3.2-11.4,5.7-17.3,6
    c1.5,4.1,3.4,8.2,5.6,12.6c4.1-1.6,8.2-3.2,11.6-4.1C666.1,1491.8,672.1,1489.9,676.8,1485.8z M742.9,1601.5
    c-1.9,0-3.2,1.6-4.7,4.8c0,0,0,0.7-0.7,0.7c0.7,0,1.5,0,1.9,0h0.7c0.7,0,1.9-0.7,1.9-1C742.9,1604,742.9,1603.1,742.9,1601.5z
    M744.1,1574.9c-0.7-0.7-1.5-1.6-1.9-1.6s-0.7,0-1,0s-1,0-1,0v0.7c1,3.2,1.9,4.8,4.1,4.8C744.1,1577.5,744.1,1575.9,744.1,1574.9z
    M804.2,1504.8c-5.1,0-10-3.2-15.8-6c-1.5-1-3.2-1.9-5.1-3.2l-6.6-3.4l11.6-1.9c5.6-1,9.7-1.6,14.1-2.5c-0.7-0.7-0.7-1-0.7-1
    c-10.7-3.2-20.4-7.5-23.3-22.2c-0.7-1-5.1-3.4-8.2-3.4h-0.7c-6.6,1.6-13.2,3.4-19.9,6c-1.9,0.7-4.1,1.6-6,1.9l4.1,14.2l3.2,1.6
    l-2.5,1.9l1,3.4l-4.1-1.6c-1.5,0-3.4,0-5.1,0.7l-5.1,0.7v-3.2l0,0c0-0.7,0-1,0-1.6c-0.7,0-1.5,0-1.9,0c-2.5,0-4.7,0.7-7.3,2.5
    c5.6,5.1,9.7,11.4,13.8,17.4c5.6,9.2,11.4,17.4,22.1,21.5c4.7,1.6,7.3,6,9.7,10.8c0.7,1,1.5,2.5,1.9,3.4c2.5,3.4,4.7,7.3,7.3,10.8
    c3.2,4.8,5.6,9.2,9.2,13.3c1,1,2.5,1.6,4.7,2.5c0.7,0,0.7,0.7,1,0.7c0.7-7.5,0.7-11.6-4.1-14.2c-4.7-2.5-7.5-6-8.2-9.8
    c-1-4.1,0-8.9,3.4-13.9c4.7-7.3,10-14.2,15.4-21.5C802.9,1506.7,803.6,1505.8,804.2,1504.8z M765.2,1464.3c1,0,1.9,0,2.5,0
    c1,0,1,0,1.5,0c5.1-7.5,11.4-13.9,17.3-20c2.5-2.5,5.1-5.1,7.5-7.5c-1.5-1.6-3.2-2.5-4.7-4.1c-4.7-4.1-9.2-7.5-13.8-11.4
    c-3.4-2.5-7.3-3.4-10.7-3.4c-1.5,0-3.2,0.7-4.7,0.7l-1,0.7l-1-0.7c-1.9-1-3.4-1-5.6-1c-3.2,0-6,1-9.7,2.5c-5.1,2.5-9.7,6-14.8,9.8
    c-1.5,1-3.4,2.5-5.1,3.4c1.9,3.2,4.7,5.7,7.3,8.2c5.6,6.7,11.4,13.3,15.8,21.5c1,0,2.5,0.7,4.1,0.7h0.7c-1-3.4-1.5-7.3-1.9-10.1
    c-0.7-3.2-1-5.7-1.5-7.5l-0.7-1.9l1.5-1c3.4-1.9,6-4.1,9.2-5.7l0.7-0.7c-3.2-1-6.6-1.6-10-2.5l-3.4-1l1.9-3.2
    c3.4-6.7,9.2-8.2,13.2-9.2h0.7h0.7c4.1,1.6,9.2,3.4,12.2,10.8l1.5,3.2l-3.4,0.7c-3.4,0.7-7.3,1-10,1.6l0.7,0.7
    c2.5,1.9,5.1,4.1,8.8,6.7l1.5,1l-0.7,1.9c-0.7,1.9-1.5,4.8-2.5,7.5C766.8,1457.1,765.9,1460.2,765.2,1464.3
    C764.6,1464.3,764.6,1464.3,765.2,1464.3z M823.4,1613.2v-0.7c-1-3.2-1.9-4.8-4.1-4.8c0,1.6-0.7,3.2,0,4.1c0.7,0.7,1.5,1.6,1.9,1.6
    c0.7,0,0.7,0,1,0C822.2,1613.2,822.8,1613.2,823.4,1613.2z M824.7,1580.6c-0.7,0-1.5,0-1.9,0h-0.7c-0.7,0-1.9,0.7-1.9,1
    c-0.7,1-0.7,2.5-0.7,4.1c1.9,0,3.2-1.6,4.7-4.8C824.7,1580.9,824.7,1580.6,824.7,1580.6z M845.8,1495c-3.4-2.5-6-3.2-7.5-3.2
    c-0.7,0-0.7,0-1,0c0,0.7,0,1,0,1.6l0,0l-0.7,3.2l-4.7-1c-1.5-0.7-3.4-0.7-5.1-1l-4.1,1.6l1.5-3.4l-1.9-1.9l3.4-1l5.1-13.9
    c-1.9-1-3.4-1.6-5.6-2.5c-6-3.2-12.6-5.7-18.9-7.5c0,0-0.7,0-1,0c-3.2,0-7.3,1.9-8.2,3.2c-4.1,13.9-14.8,17.4-25.5,19.6
    c0,0-0.7,0.7-1,1c4.1,1,8.2,1.9,13.8,3.4l11.6,3.2l-7.3,3.2c-1.9,1-3.4,1.6-5.1,2.5c-5.6,2.5-10,5.1-14.8,5.1c-0.7,0-1,0-1,0
    c0.7,1,1,1.9,1.5,2.5c4.7,7.5,9.2,15.5,13.2,23.1c2.5,5.7,3.4,9.8,1.9,13.9c-1.5,3.4-4.7,6.7-9.2,8.9c-4.7,1.9-5.1,6-5.6,13.9
    c0.7,0,0.7,0,1-0.7c1.9-1,4.1-1.6,4.7-2.5c3.4-4.1,7.3-8.2,10-12.3c2.5-3.2,5.1-6.7,8.2-9.8c1-1,1.5-1.9,2.5-3.2
    c3.2-4.1,6-8.9,10.7-9.8c11.4-3.2,17.3-10.8,23.9-19.6C835.1,1505.1,839.2,1499.1,845.8,1495z M895.7,1499.1
    c4.1,4.8,9.7,7.3,16.3,9.2c3.4,1.6,7.5,3.2,11.6,5.1c2.5-4.8,4.7-8.9,7.3-12.3c-6-1-11.6-4.1-16.7-7.5c-4.7,3.4-9.7,5.1-15.8,5.1
    C897.1,1499.8,896.7,1499.8,895.7,1499.1z M1063.8,1414.1c0-0.7-0.7-1-0.7-1c0,1.6,0,3.2,0.7,4.1
    C1064.1,1415.7,1064.1,1415.3,1063.8,1414.1z M1192.6,1386.6L1192.6,1386.6c-0.7-0.7-1.5-0.7-1.9-0.7c-1.5,0-3.2,0.7-5.1,4.8
    c-0.7,0.7,0,2.5,0,3.4c0.7,0,1,0.7,1.5,0.7c3.2,0,4.7-3.2,5.6-4.8C1193.3,1389.2,1193.3,1387.5,1192.6,1386.6z M1157.7,1440.4
    c0,0.7,0,1,0.7,1.6C1159.4,1442.8,1159.9,1442.8,1157.7,1440.4z M1233.9,1293.1c0-0.7-1-1.9-1.5-2.5c0,0,0,0,0-0.7
    c-1.9,1-4.1,1.9-6,3.2c-0.7,0.7-1,0.7-1.9,1c0.7,0.7,1,1,1.5,1c1.5,1,3.2,2.5,4.1,3.2C1231,1297.5,1232.9,1294.9,1233.9,1293.1z
    M1260.9,1360.3c-0.7-1-0.7-1.6-1-2.5c-0.7-1.9-1-4.1-1.9-6c-1.5,1.6-1.9,3.2-2.5,4.1l0,0c-0.7,1.6-1,3.2-1.5,4.8l0,0
    c1,1,1.9,1,3.2,1C1258.4,1361.9,1259.9,1361.3,1260.9,1360.3z M1279.8,1306.6c-1.5-1.9-6.6-4.1-18.2-4.8c-1.5,0-3.2-0.7-4.7-1
    c-1.5-0.7-2.5-1-3.4-1c-0.7,0-1.5,0-1.9,1.9c1,0.7,1.9,1,3.2,1.6l-1.5,5.1h-0.7c0,1,0.7,1.9,0.7,3.2c0.7,4.8,3.2,7.3,7.3,7.3
    c0.7,0,1,0,1.9,0c1-0.7,1.9-0.7,3.4-0.7c7.3-1.6,13.8-3.2,15.8-9.8C1280.4,1307.6,1280.4,1307.2,1279.8,1306.6z M1293.6,1329.1
    L1293.6,1329.1c-0.7-0.7-1-1.6-1.5-1.9c-1.5,0-3.2,1-5.6,1.9c0,0-0.7,0-0.7,0.7c0,0,0,0,0.7,0.7s1.5,1.6,1.9,1.9
    C1290.5,1332,1292,1330.3,1293.6,1329.1z M1339.8,1364.1c0-4.1-1.9-4.1-3.2-4.1c-0.7,0-0.7,0-1,0c-0.7,0-1,0.7-1.9,1
    s-2.5,1.6-4.7,1.6c-1.5,0-3.2-0.7-4.1-1.9c1.5,5.7,3.2,6,9.2,6.7c0.7,0,1,0,1.5,0c1.5,0,2.5-0.7,3.2-1
    C1339.8,1366,1339.8,1365.1,1339.8,1364.1z M1343,1334.7c-1-1-1.9-1.9-3.2-3.4l-0.7,0.7C1340.4,1333.2,1341.4,1334.4,1343,1334.7z
    M1391.7,1257.9c-0.7-2.5-4.1-4.8-7.5-4.8c-1,0-1.5,0-1.9,0.7c-1,1-2.5,1.9-4.1,3.2c-6,4.8-9.2,7.5-7.3,11.6
    c1.5,4.1,3.4,4.8,5.6,4.8c1.9,0,5.1-1,8.2-1.9c1.9-0.7,3.4-1.6,5.6-1.9c0-0.7,0.7-1.6,0.7-1.9
    C1391.4,1263.7,1392.4,1260.8,1391.7,1257.9z M1383,1478.3c-0.7-0.7-1-1-1.9-1c-0.7,0-0.7,0.7-1,1c0.7,1,1,2.5,1.9,3.4
    c0.7,0,0.7-0.7,1-0.7C1383.5,1479.8,1383.5,1478.6,1383,1478.3z M1489.9,1540.5c-1.9-4.1-5.1-5.7-14.8-10.8c0,0.7-0.7,1.6-0.7,1.9
    C1479.8,1534.8,1484.3,1537.7,1489.9,1540.5z M1631.1,1520.6c3.4,3.4-3.2,6-13.2,8.2C1642.1,1545.2,1654.1,1543.7,1631.1,1520.6z
    M270.4,2066.1c3.2,3.2,4.7-3.4,5.6-12.6C265.1,2053.5,259.7,2055.4,270.4,2066.1z M1605.1,1659.6c0.7,0,0-1.6,0-1.9
    c-0.7,0-1.5-0.7-1.9,0c-0.7,0,0,1.6,0,1.9C1603.5,1659.6,1604.4,1659.6,1605.1,1659.6z M1507.9,1757c1.5-9.2,5.6-15.9,12.2-22.2
    c1.5-1.6,1.5-4.8,1.9-7.5c-5.1-0.7-9.7-1-13.2-0.7c0.7,0.7,1,1,1.5,1.6c0,0.7,0,1-0.7,1.6h-0.3c-0.7,0-0.7,0-1,0
    c-0.7-0.7-0.7-0.7-1-0.7c-0.7-0.7-1.5-1-1.9-1.9c-8.2,1-11.4,5.1-12.2,14.9c-0.9,9.8-7.5,12.3-13.2,20
    C1496.2,1771.8,1505.3,1770.3,1507.9,1757z M1504.1,1689c1-0.7,1.9-1,3.2-1.9C1506.2,1686.6,1504.1,1684.3,1504.1,1689z
    M1373.2,1667.8c3.4-12.3,12.6-15.9,23.3-18.1c1.5-0.7,2.5-2.5,5.1-5.1c-6.6-1.9-12.2-3.4-22.1-6c11.4-5.1,18-10.1,26.5-4.8
    c-6-10.8-12.6-20.8-18.2-32.3c-4.1-7.5-4.1-14.9,6-19c7.5-3.2,7.3-10.8,7.3-20.5c-5.1,1.9-8.8,2.5-10.7,5.1
    c-6,7.3-12.2,15.5-18.2,22.4c-3.4,4.8-7.3,10.8-11.6,11.6c-21.4,5.7-26.1,27.5-42.8,37.2c9.2,9.8,15.8,6,22.9,3.4l0,0l-7.3-0.7
    v-0.7c2.5,0.7,5.1,1,7.3,1l0,0l0,0l0,0l-3.2,7.5l-3.4,9.8c9.2,4.1,18,8.9,27.4,11.6C1364.1,1673.6,1372.3,1670.4,1373.2,1667.8z
    M1309.6,1677.4c1,0.7,1.9,1.6,3.2,1.9C1313.2,1675.1,1311.3,1676.7,1309.6,1677.4z M1210,1499.1l-1-3.2c-0.7,1-1,1.9-1.5,3.2
    C1208.4,1499.1,1209.4,1499.1,1210,1499.1z M1457.6,1677.4c9.7-1.9,18.9-5.7,28.6-8.9l-2.5-9.8l-1.9-8.2l0,0l0,0l0,0
    c2.5,0,5.1-0.7,7.3-0.7c0,0.7,0,0.7,0,0.7h-7.3l0,0c6.6,3.2,13.8,7.3,23.3-1.6c-16.3-11.4-18.2-33.8-39.3-41.5
    c-4.7-1.6-7.5-8.2-10.7-12.6c-5.6-8.2-10-16.4-15.8-24.1c-1.9-1.9-5.6-3.2-10-5.7c-1,9.8-1.9,16.7,5.1,20.8
    c9.2,5.1,8.8,12.3,4.1,19.6c-6.6,10.1-14.1,20-21.4,30.4c9.2-4.8,15.4,1,26.1,7.3c-10,1.6-15.8,2.5-22.3,4.1
    c1.9,2.5,3.2,5.1,4.7,5.7c10.7,3.2,19.5,7.3,22.1,20C1445.5,1674.2,1453.7,1677.7,1457.6,1677.4z M1277,1639.5l3.4-9.2l0,0
    L1277,1639.5C1276.7,1639.5,1277,1639.5,1277,1639.5z M1471.7,1698.2c0-1.6-1.5-2.5-2.5-4.1c-0.7,1.6-1.5,3.2-1.9,4.8
    c0,0.7,1,1.6,1.5,1.9C1469.5,1699.8,1471.7,1698.9,1471.7,1698.2z M1337.7,1556.3c2.5,0.7,4.7,1.6,6.6,1c1.5,0,4.1-1.6,4.7-3.2
    c1-1.9,0.7-5.1,0.7-7.5C1341.2,1545.6,1341.2,1552.9,1337.7,1556.3z M1389.3,1715.6c-7.3-4.8-13.2-10.8-20.7-17.4
    c9.2-10.1,19.9-18.3,27-29.7c1-1.6,6-0.7,10-0.7c-3.2,9.2-5.1,15.9-6.6,21.5c3.2,2.5,6,4.8,8.8,6.7c2.5-1.6,5.6-3.4,9.2-5.7
    c-1-5.7-2.5-12.6-4.7-22.2c4.1,0.7,9.2,0,10,1.6c6,11.6,15.8,20.8,23.9,32.3c-8.2,5.7-14.8,11.4-22.3,15.5c-6,3.4-12.2,4.1-18,1.6
    C1401.2,1721.3,1395.2,1719.7,1389.3,1715.6z M1396.8,1703c2.5,5.7,6,7.5,10.7,9.2c4.1-1,8.2-2.5,11.4-8.2c-4.1-1-7.5-1.9-10.7-2.5
    C1404.4,1701.8,1400.9,1702.3,1396.8,1703z M1480.2,1650.2L1480.2,1650.2L1480.2,1650.2L1480.2,1650.2L1480.2,1650.2z
    M1344.3,1692.2c0,0.7,1.9,1.9,3.2,3.2c0.7-0.7,1.5-1.6,1.5-1.9c-0.7-1.6-1-3.2-1.5-4.8C1346.5,1689.3,1344.9,1690.7,1344.3,1692.2
    z M1415.6,1634.7c0-6.7-0.7-13.3-0.7-19.6c-1-0.7-1.9-0.7-3.2-1l-1,18.1l0.7-0.7C1411.6,1632.1,1414.1,1634.4,1415.6,1634.7z
    M1406.6,1634.4c1.5-0.7,4.1-2.5,4.1-1.9l0.7,0.7l1-18.1c-1,0-1.9,0.7-3.2,0.7C1407.8,1621.4,1406.8,1628,1406.6,1634.4z
    M1427.6,689.4c4.1,0.7,6,1.6,7.5,1c2.5-0.7,4.7-1.9,7.3-3.4c0,0-0.7-1.9-1-2.5c-2.5-0.7-5.6-1.6-8.2-1
    C1432,683.4,1431,686,1427.6,689.4z M1487.9,1165c1.5-0.7,4.1-4.8,3.4-6c-0.7-1.9-4.1-2.5-7.3-4.8c-1.5,3.4-3.2,6-4.1,8.9
    C1483,1163.5,1486.1,1165.4,1487.9,1165z M1456.5,596.8c-0.7,0-1.5,0.7-1.9,1.6c-0.7,0.7,0,1.6,0.7,3.4c2.5,0.7,5.1,1.6,7.5,2.5
    c0.7-1,1-1.9,0.7-2.5C1460.9,599.9,1459,598.1,1456.5,596.8z M1496.1,1308.8c-0.7,0-0.7,0-0.7-0.7c-1.5,1.9-2.5,3.4-3.4,5.7l2.5,1
    C1494.6,1312.9,1495.2,1310.8,1496.1,1308.8z M1553.1,741.8c6-1,11.4-1.6,16.3-2.5c-1.9-10.1-9.2-8.9-16.3-11.4
    C1553.1,733.6,1553.1,736.5,1553.1,741.8z M1555.9,710.6c1.5,1.6,3.4,2.5,5.1,4.1c0-1,1-1.9,1-2.5
    C1561,707.3,1558.4,709.6,1555.9,710.6L1555.9,710.6z M1461.6,630.6c-0.7,0.7-1.5,1-1.9,1c1,0.7,1.9,0.7,3.4,0.7
    C1462.2,632.2,1462.2,631.2,1461.6,630.6z M1443.3,827.2l-0.7-1.6l-1.5,1.6H1443.3z M1468.2,1173.2c0-1.9,1-4.1,0-5.7
    c-1-2.5-3.2-4.8-5.1-6.7c-0.7,3.2-1,5.7-0.7,8.2C1463.1,1171,1466.3,1171.7,1468.2,1173.2z M1432,759.6c1,3.4,1.9,7.3,3.2,10.8
    c2.5-0.7,5.1-1,6.6-2.5c1.5-1.6,1.5-5.1,1.9-7.3c-2.5-0.7-4.7-1.6-7.3-1.9C1435.1,758.6,1433.5,759.2,1432,759.6z M1567.6,682.4
    c0-1.6,0-3.2-0.7-5.1c-1.5,0.7-3.2,1.6-4.1,1.9c1,1.6,1.5,2.5,2.5,3.4C1565.4,684,1567.6,682.7,1567.6,682.4z M1441.7,736.5
    c0.7,0,0-1.6-0.7-1.9c-1,0-1.5,0-2.5,0.7c-0.7,0,0,1.9,0.7,1.9C1440.2,737.1,1441.2,737.1,1441.7,736.5z M1428.5,1100
    c0.7,1.6,1.5,4.1,2.5,4.1c1,0.7,4.1-1,4.7-1.9c0.7-1.6-0.7-3.4-1.9-5.1c-1.5-1.9-3.4-3.2-5.6-4.8
    C1428.5,1094.9,1428.5,1097.4,1428.5,1100z M1428.5,1091.8l15.4-1c0-1,0-1.6-0.7-2.5c-3.4-0.7-7.3-0.7-10-1l0,0
    C1432,1088.5,1430.1,1090.1,1428.5,1091.8z M1444.9,864.5c1-0.7,1.5-1.9,2.5-3.4c-1.5,0-3.2,0-4.7,0l-1.5,1.9
    C1442,863.5,1443.6,864.5,1444.9,864.5z M1588,1075.9c-1,7.5,16.7,10.1,5.6,20.5c3.4,7.3,7.3,14.9,12.2,24.6
    c6-2.5,9.7-3.4,13.8-5.1c-1-4.8-4.7-7.5-7.5-10.8c-1.5,5.7-3.2,7.3-5.6,7.3l0,0h-0.7l0,0c0.7-4.8,0.7-8.9,2.5-12.3
    c-1-2.5-1-5.1,1-8.9C1604,1082.6,1597.5,1077.5,1588,1075.9z M1606.5,819.6l12.6,4.8c-3.4-2.5-7.5-5.1-11.4-7.3
    C1607.2,818,1607.2,818.6,1606.5,819.6z M1590.9,1133.8c1.5-5.1,0.7-10.1-0.7-14.9l-5.1-1.9c-1.5,6-3.4,12.3-4.1,18.3v0.7
    c1,0,1.9-0.7,3.2-0.7C1586.4,1134.7,1589,1134.4,1590.9,1133.8z M1556.2,1108.2c0.7,1,3.2,3.2,4.1,2.5c1-0.7,2.5-2.5,3.2-4.1
    c0.7-2.5,1-5.1,1-7.3c-1.9,1.6-4.7,2.5-6,4.1C1557.2,1104.6,1556.2,1106.6,1556.2,1108.2z M1560.3,1093.3L1560.3,1093.3
    c-3.4,0-7.3,0-10,0c-0.7,1-0.7,1.9-0.7,2.5l15.4,2.5C1563.5,1096.8,1561.8,1094.9,1560.3,1093.3z M1602.8,776
    C1602.8,776.6,1602.8,776.6,1602.8,776c0,0.7,0,1,0.7,1l0,0L1602.8,776z M1590.5,778.2c-0.7,0-0.7-0.7-1-0.7v0.7H1590.5z
    M1599,691.6c-2.5-0.7-5.6,0-8.2,0.7c-0.7,0-1.5,1.9-1,1.9c1.9,1.6,4.1,3.2,6.6,4.1c1.5,0.7,3.4,0,7.5-0.7
    C1601.6,694.2,1600.6,691.6,1599,691.6z M1574.4,869.2l-1.5-1.9c-1.5,0-3.2-0.7-4.7-0.7c0.7,1.6,1,3.4,1.9,3.4
    C1571.7,870.8,1573.2,869.5,1574.4,869.2z M1621,1130.6c-0.7-1.9-1-3.4-1.5-5.7c-1,1-1.5,2.5-2.5,3.4c-5.6,4.8-7.3,10.1-4.1,18.1
    h0.7c3.2,0,6-1,9.2-3.2l1.5-1l-0.7-1.9C1622.6,1136.9,1621.3,1133.8,1621,1130.6z M1605.3,1035.5c-1.9,3.4-5.1,8.2,3.2,8.2
    c5.1,0,10-1.9,15.4-3.2l0,0C1617.2,1039.6,1611.3,1037.7,1605.3,1035.5z M1541.4,826.3c3.4,2.5,10,1,15.4,1.6c0.7-1.6,1-2.5,1-4.1
    c-6.6-4.8-13.2-8.9-24.8-16.4C1537.4,817.4,1538.6,824.1,1541.4,826.3z M1629.5,1064.5c5.6-9.8-1-16.4-6-23.4
    C1618.8,1051,1622,1058.5,1629.5,1064.5z M1530.7,1077.8c0,1,2.5,3.4,3.4,3.4c1.5,0,4.1-1.9,3.4-3.2c0-4.8-1.5-9.2-1.9-13.3
    c-1.5,0-2.5,0-4.1-0.7C1531.4,1068.6,1530.7,1073.4,1530.7,1077.8z M1575.7,833.2h1.9l-1.5-1.6L1575.7,833.2z M1431,759.6
    c0.7,0,0.7,0,1,0c0-0.7,0-0.7,0-1L1431,759.6z M1593.4,766.8c0,0,0,0.7-0.7,0.7c0.7,0,0.7,0,1,0.7L1593.4,766.8z M1654.9,838.9
    C1654.9,838.9,1654.7,838.9,1654.9,838.9C1654.7,838.9,1654.9,838.9,1654.9,838.9z M1636.7,997.2c4.1,4.1,7.3,0.7,9.2-2.5
    c1-1,0-4.8-0.7-4.8c-3.2-0.7-6.6,0-10,0C1635.8,993.4,1635.8,996,1636.7,997.2z M1639.9,649.3c-1-1.6-1.5-3.4-2.5-5.1
    c1.9-1.6,4.1-3.4,6-5.1c-5.6,0-11.4-0.7-18.9-0.7C1630.8,642.3,1635.2,645.5,1639.9,649.3z M1623,1123.7
    C1623.5,1123,1623.5,1123,1623,1123.7c0-1-0.7-2.5-1-3.4c-1,1.6-1.9,3.4-3.2,5.1C1621,1124.6,1622,1123.9,1623,1123.7z
    M1622.6,1119.8L1622.6,1119.8C1622.6,1119.5,1622.6,1119.5,1622.6,1119.8L1622.6,1119.8z M1650.9,586.1c0.7,0.7,1.5,1,1.9,1
    c0-1,0.7-1.9,0.7-3.2l-2.5-0.7C1651.5,584.8,1650.9,585.7,1650.9,586.1z M1664.8,1013.9c-1-1.9-1.5-4.8-3.2-6.7
    c-0.7-0.7-4.7,1-4.7,1.6c0,1.9,0.7,4.8,1.5,6.7C1661.2,1014.9,1662.9,1014.6,1664.8,1013.9z M1667.3,850.2l1.9-0.7l-1.9-1.6V850.2z
    M1569.5,537.1c0.7,4.1,0,6,1,7.3c4.1,4.8,8.8,8.9,13.2,13.3c1.9-2.5,3.4-5.7,6.6-9.8C1583.2,543.6,1577.3,540.5,1569.5,537.1z
    M1463.4,584.8L1463.4,584.8L1463.4,584.8L1463.4,584.8L1463.4,584.8z M1615.3,588.5c1.5-5.7,3.4-11.4,5.1-16.7
    c-9.2-6-12.2,3.4-17.3,5.7c-1.9-1-4.7-1.6-4.7-2.5c-1.5-8.2-7.3-12.6-13.8-16.4c-1.9,10.1-3.4,20.8-5.6,31.6
    C1590.5,589.9,1602.8,589.2,1615.3,588.5z M1445.8,567c-0.7,1-3.2,1.6-5.1,1.9c-5.1-2.5-7.3-12.6-16.7-7.5c1,5.7,1.9,11.6,3.2,17.4
    c12.6,1.6,24.5,3.4,36.8,5.1c-0.7-10.8-1.5-21.5-2.5-32.3C1454.3,555.4,1448.3,559.1,1445.8,567z M1170.3,1244.7
    c0.7,0,1-0.7,2.5-1.6c-1.9,0-2.5,0-4.1,0C1169.7,1243.7,1170.3,1244.7,1170.3,1244.7z M1578.3,590.2
    C1578.3,590.2,1578.6,590.2,1578.3,590.2C1578.6,590.2,1578.6,590.2,1578.3,590.2C1578.6,590.2,1578.6,590.2,1578.3,590.2
    L1578.3,590.2z M1559.4,577.5c6,4.8,12.6,8.9,18.9,12.6c-5.6-7.5-11.4-15.5-16.3-22.4C1554.7,571.2,1555.9,575,1559.4,577.5z
    M1435.1,771v-0.7c-0.7,0-0.7,0-1,0.7H1435.1z M1468.7,707.3c0,1,0.7,1.9,0.7,2.5c1.9-1,3.4-2.5,5.6-3.4h-0.7
    C1472.3,704.9,1469.7,703.2,1468.7,707.3z M1474.8,706.5C1474.8,706.5,1474.8,707.1,1474.8,706.5c1.5-0.7,3.2-1,5.6-1.9
    C1477.9,705.5,1476.4,705.8,1474.8,706.5z M1466.3,677.6c0,0.7,1.5,1.6,1.9,1c1-1,1.9-2.5,3.2-3.4c-1.5-1-2.5-1.6-4.1-2.5
    C1466.5,674.9,1466.3,676.1,1466.3,677.6z M1434.5,1169.1c14.8,1.6,22.1-2.5,28-7.5L1434.5,1169.1z M1422,767.4l-0.7,1l0,0l0.7-0.7
    V767.4z M1490.1,1227.3C1490.5,1227.3,1490.5,1227.3,1490.1,1227.3L1490.1,1227.3c0.7-0.7,1-0.7,1.5-1c2.5-2.5,5.1-6,1-10.1
    c-0.7,0-1,0-1.5,0c-0.6,0-1,0-1.5-0.7c-4.7,3.4-2.5,7.3,0,10.1C1488.9,1227,1489.6,1227,1490.1,1227.3L1490.1,1227.3L1490.1,1227.3
    z M1458,734c5.1,1.6,10,2.5,16.3,4.1c0.7-5.1,0.7-8.2,1-13.9C1468.2,726.4,1460.9,723.9,1458,734z M1523.8,1172
    c1-2.5,0.7-5.1,0.7-8.2c-1.9,1.9-4.1,3.4-5.6,6c-1,1.6-0.7,3.4-0.7,5.7C1519.7,1174.6,1522.6,1174.2,1523.8,1172z M1524.1,1163.8
    c5.6,6,12.2,10.8,27,10.8L1524.1,1163.8z M1502.7,1155.3c-3.2,1.6-6.6,1.9-7.3,3.4c-0.7,1.6,1,5.7,3.2,6.7c1.9,1,5.1-1,7.5-1.6
    C1505.3,1161.3,1504.4,1158.7,1502.7,1155.3z M1669.7,1261.1c0-4.1-2.5-6.7-7.3-5.7c-1,0-1.9,2.5-4.7,5.1c3.2,1.6,6,3.4,9.2,4.8
    C1667.9,1265.2,1669.7,1262.7,1669.7,1261.1z M1664.8,1332.2l1.9,0.7c0-1,0.7-1.9,0.7-3.4c-1,0-1.5,0-1.9,0.7
    C1665.3,1330.6,1665.3,1332,1664.8,1332.2z M1635.2,1108.8c-4.1,3.4-8.8,7.3-12.6,10.8c1,1,1.9,1.6,3.2,2.5
    c4.7-1.9,8.8-3.4,13.2-3.4c1.5,0,3.2,0,4.7,0.7c7.3,1.6,14.1,4.1,21.4,6.7c0.7,0,1,0.7,1,0.7c-2.5-7.5-5.1-14.2-8.2-23.1
    C1649.7,1105,1642.4,1107.2,1635.2,1108.8L1635.2,1108.8z M1535.5,1208.9c3.2,1.6,5.6,2.5,8.8,4.1l1-1.9c-2.5-1.9-5.1-3.4-8.2-5.1
    C1537,1205.8,1536.4,1206.7,1535.5,1208.9z M1684.5,1252.6c-1-0.7-2.5-0.7-3.4-0.7c0.7,0.7,1,1,1.5,1.6
    C1683,1252.9,1684.3,1252.6,1684.5,1252.6z M1375.4,1028.8c5.1,1.6,10,4.1,15.4,4.8c7.5,0.7,5.6-4.1,3.4-8.2
    C1388.3,1026.9,1381.9,1027.9,1375.4,1028.8L1375.4,1028.8z M1311.9,495c0.7,0,0.7,0.7,1,0.7l0.7-1
    C1312.8,495,1312.4,495,1311.9,495z M1327.2,614.8c-0.7-0.7-1.5-1-1.9-1c-1,0.7-1.5,1.6-2.5,2.5c1,0,1.5,0,2.5-0.7
    C1325.7,615.8,1326.7,615.5,1327.2,614.8z M1287.9,487.4c6.6,7.5,15.4,8.2,23.9,7.5C1298,485.2,1297.6,485.2,1287.9,487.4z
    M1312.8,495.6c0.7,0,1-0.7,1.5-0.7c0,0-0.7,0-0.7-0.7C1313.4,495,1312.8,495.6,1312.8,495.6z M1316,471c1,0,1.5,0.7,1.9,0.7
    l-0.7-1.9C1317.5,470,1316.8,470.4,1316,471z M1320.4,493.1c-1.9,0-4.1,1-6,1.9c1.9,1,4.7,2.5,6.6,3.2s4.7,0,6.6-0.7
    c0,0,0.7-1.9,0-2.5C1325,494.7,1323.1,493.1,1320.4,493.1z M1430.5,431.1c-1,0-2.5,0.7-3.4,0.7l0,0c-1.9,0-4.1,0-6.6-0.7
    C1428.5,433.7,1432.9,434.7,1430.5,431.1z M1703.4,1016.5c0,0.7,0,1-0.7,1.6c0.7-1,1-1.9,1.5-3.2
    C1704.4,1015.5,1703.4,1016.2,1703.4,1016.5z M1663.8,628.1c1-0.7,1.5-1,2.5-1.6c-1.5,0-2.5,0-4.1,0
    C1662.2,627.8,1663.2,628.1,1663.8,628.1z M1474.8,575c0.7-0.7,2.5-1.6,4.7-2.5c0.7-0.7,1-0.7,1.9-1c0,0.7,0,1.6,0,1.9
    c0,0.3,0,1.6,0,1.9c1-0.7,1.5-1,2.5-1.6c3.4-1.9,5.1-6-1.5-9.8c-6,6.7-12.2,13.9-18.2,20.5c3.4-1.9,7.3-3.4,10.7-5.7
    C1474.1,577.8,1474.1,576.5,1474.8,575z M1668.2,626.2h-0.7l0,0c-0.7,0.7-1,0.7-1.5,1c0.7,0,1.5,0,1.9,0l-0.7-1l0.7,1
    C1668.2,626.5,1668.2,626.2,1668.2,626.2z M1587.7,1408.1C1587.1,1408.1,1587.1,1408.1,1587.7,1408.1c-3.2-1.6-6-3.4-9.2-5.1
    c0,0.7-0.7,1-1,1.6L1587.7,1408.1L1587.7,1408.1L1587.7,1408.1z M1316.8,454.3c-9.7-2.5-15.4-4.1-22.3-5.7
    C1298.6,459.3,1303,462.8,1316.8,454.3z M1407.4,495c0.7,0,1.9-1,1.9-1.6c-0.7-1-1.5-2.5-1.9-3.4c-1,1-2.5,1.9-3.4,3.2
    C1405,494.1,1406.2,494.7,1407.4,495z M1581.7,767.4c0.7,2.5,0,5.7,1.5,7.5c1.5,1.6,4.1,1.9,6,3.2c1.5-3.4,2.5-7.3,4.1-10.8
    c-1.5-0.7-3.2-1.6-4.7-1.6C1586.4,765.9,1583.9,766.8,1581.7,767.4z M1042.3,631.2c1,1.6,1.9,2.5,3.2,3.4l2.5-3.4
    c-1.5-0.7-3.2-1-4.1-1.6C1043.6,629,1042.3,630.6,1042.3,631.2z M1049.6,702.4c0,0.7,0.7,1,1,1.6c0.7-0.7,1.5-1,1.5-1
    c0-0.7-0.7-1-1-1.6C1050.4,701.4,1050.1,701.7,1049.6,702.4z M995.4,823.7c1.5,0,3.2,0,5.1,0C998.6,823.7,997,823.7,995.4,823.7
    L995.4,823.7z M1040.4,477c-1,1.6-1.9,3.2-1.5,4.8c0,1.6,1,3.4,2.5,4.1c1,0.7,3.4-0.7,4.1-1.6c0.7-1.6-0.7-3.4-1.5-4.8
    C1042.9,478.6,1041.4,477.7,1040.4,477z M995.4,831.3c0.7-1,1.9-1.9,1.5-3.2c0-1.6-1-3.2-1.9-4.8c0,1.6,0,3.2-0.7,5.1
    C995.1,829.7,995.4,830.6,995.4,831.3z M1036.3,567.7c0,0,0.7,1,1,1.6c0.7-0.7,1.5-0.7,1.5-1c0,0-0.7-1-1-1.6
    C1037.2,566.8,1036.3,567,1036.3,567.7z M1023.1,533.9c0.7,1,2.5,0.7,4.1,1c0-1.6,0.7-3.4,0.7-5.1c-1.5-0.7-3.2-0.7-4.7-1
    C1023.1,530.4,1022.4,532.3,1023.1,533.9z M1097,958.3c2.5,0.7,5.1,1,7.5,1.6c0,0,0.7-1.6,1-3.2c-2.5,0-5.1-0.7-7.5-0.7
    C1097.3,956.1,1097.3,957.8,1097,958.3z M1237,583.2h-0.7c-1-0.7-2.5-0.7-3.4-1c0-0.7,0-1,0.7-1.6c1,0,2.5,0.7,3.4,0.7
    c0.7,0,1,0,1.9,0c1.9-2.5,4.1-4.8,6-6.7c-14.1-4.1-25.5,3.2-37.1,8.2c0.7,3.4,0,7.3-0.7,10.8c0,1.6-0.7,3.2-0.7,4.8
    c3.4-0.7,7.5-1,11.4-1.6C1227.3,595.5,1231.9,589.2,1237,583.2z M1267.5,589.9c0.7-0.7,0.7-3.2,1.5-6c-3.2,1-5.6,1-7.3,1.9
    c-0.7,0.7-1,1.6-1,1.9c1.5,0.7,3.2,1.6,5.1,1.9C1266.5,589.9,1266.9,589.9,1267.5,589.9z M1145.1,675.8c-4.7-1.9-7.5-4.1-10.7-5.7
    C1136,674.2,1137.3,679.3,1145.1,675.8z M1272.2,526.9c2.5-0.7,5.1-1.6,7.3-3.2l0,0c-1.9-1-3.4-3.4-5.1-6.7c0,0,0.7,0,1,0
    c1.9,0,4.7,0.7,6.6,1.9c0-1,0-1.9,0-3.4c-10,1.9-20.4,3.4-31.4,11.4C1259.3,526.9,1265.9,527.9,1272.2,526.9z M1144.2,916.6
    c0.7,0,1.5,0,1.9,0c-0.7-1-1-1.9-1.5-3.2c-0.7,0.7-1,1-1.9,1C1143.2,915.6,1143.9,916.3,1144.2,916.6z M1128.4,919.2
    c0,0-0.7,1.6-0.7,1.9c1.5,0,3.2,0.7,4.7,0.7c0,0,0.7-1.6,0.7-2.5C1131.6,919.2,1130.1,919.2,1128.4,919.2z M1671.4,610.7
    c-1,5.1-1.9,10.1-3.2,15.5c4.7,1.6,9.7,3.4,15.8,5.7C1683.6,620.4,1682.6,612.2,1671.4,610.7z M1618.5,725.4l10,4.1
    c0.7-0.7,1-1.6,1-1.9c-3.2-1.9-6-4.1-9.7-6L1618.5,725.4z M1555.9,710.6L1555.9,710.6c-1.5-1-3.2-1.6-5.6-3.2
    C1552.8,709,1554.3,709.9,1555.9,710.6z M1508.5,485.2c1,0,1.5,0,2.5,0c0.7-1,1-1.6,1.5-1.9c-0.7-0.7-1.5-1-2.5-1.6
    C1510,483.3,1509,484.2,1508.5,485.2z M1524.1,526.3c-7.5-1.6-9.2-1-15.4,4.1c5.6,0.7,10.7,1.9,13.8,7.5c0.7-0.7,0.7-1,1-1.6
    c0.7,0.7,0.7,1,1,1.6c3.2-5.7,8.8-6.7,14.1-6.7C1532.9,526.3,1531.7,525.3,1524.1,526.3z M1552.8,594.3
    C1552.8,594.8,1552.8,594.8,1552.8,594.3h1C1553.7,594.3,1553.1,594.3,1552.8,594.3z M1653.4,1311.4L1653.4,1311.4v-0.7
    C1653.4,1310.8,1653.4,1310.8,1653.4,1311.4L1653.4,1311.4z M1549.6,513.4c1.5,0,3.4,0.7,5.1,0.7
    C1553.1,513.9,1551.1,513.4,1549.6,513.4L1549.6,513.4z M1653.1,1310.8L1653.1,1310.8C1653.4,1310.8,1653.4,1310.8,1653.1,1310.8
    C1653.4,1310.8,1653.4,1310.8,1653.1,1310.8L1653.1,1310.8z M1584.2,603.2c-2.5,1-4.7,2.5-7.3,4.1c-0.7,0,0.7,1.6,0.7,2.5
    c2.5-0.7,5.1-1,7.5-1.6c0.7-1.6,1-2.5,0.7-3.4C1586.4,603.4,1584.9,602.5,1584.2,603.2z M1575.1,637.8c1,0,2.5,0,3.4-0.7
    c-0.7-0.7-1.5-1-1.9-1.6C1575.7,637,1575.1,637.2,1575.1,637.8z M1586.4,741.8c0,1-1,1.9-0.7,1.9c0.7,0.7,1.5,0.7,1.9,0.7
    c0.7,0,1-1.9,0.7-1.9C1588.3,742.2,1587.3,741.8,1586.4,741.8z M1617.9,1298.4l0.7,1.9l1.5-1.6L1617.9,1298.4z M1518.5,828.2
    c-1.5,0-2.5,1.9-3.4,3.4c1.5,1,3.2,3.2,3.4,2.5c1.9-1,3.4-3.2,5.1-4.8C1521.6,828.7,1520,827.8,1518.5,828.2z M1587.3,1261.1
    c-1-0.7-1.9-1.6-3.2-1.9c-0.7,0.7-1,1-1,1.9c0.7,0.7,1.5,1.6,1.9,1.6C1585.8,1262.7,1586.4,1261.8,1587.3,1261.1z M1517.3,668.6
    c-0.7,0-0.7-0.7-1-0.7c-1,0-2.5,1.6-4.1,2.5c1,1,1.9,3.2,3.2,3.2c0.7,0,1,0,1.5-0.7c0.7,0.7,1,0.7,1.5,0.7c1,0,1.9-1.9,3.4-3.2
    c-1.5-1-2.5-2.5-3.4-2.5S1517.5,668.6,1517.3,668.6z M1477.9,1314.6l2.5-0.7c-1.5-3.2-3.2-5.7-4.7-8.9
    C1477,1308.2,1477.2,1311.4,1477.9,1314.6z M1423.5,1385.1c0-0.7-1-1.6-1.5-1.9c-1,1-3.4,1.9-3.4,2.5c0,1.6,1.5,2.5,2.5,4.1
    C1422,1388.2,1422.8,1386.6,1423.5,1385.1z M1408.7,1127.1c0-6-1.5-12.6-2.5-19l-5.1,1.6c-1.9,7.3-4.1,14.2,1,22.2
    C1404.6,1130.2,1409.1,1128.7,1408.7,1127.1z M1592.8,1401.8c-1.5,0.7-3.2,0.7-4.1,1.6c-1,1.6-1,3.2-1.5,4.8
    c1.5-0.7,3.2-1.6,4.1-2.5C1592.1,1404.6,1592.5,1403,1592.8,1401.8z M1553.7,1289c2.5,1.9,8.2,2.5,10.7,1.6
    c8.2-4.8,16.3-4.1,25.5-3.4c3.2,0,6.6-3.2,9.2-5.7c1.5-1.6,2.5-5.7,1.5-7.5c-0.7-1.6-4.7-3.4-6.6-3.2c-3.4,0.7-7.3,3.2-10.7,4.1
    c-4.7,1-10.7,3.2-13.8,1c-18.2-11.6-35.2-4.8-54.4,1.9c0.7,4.8,1,7.5,1.5,11.6c7.3-0.7,15.4,0.7,21.4-1.9
    C1544.6,1284.2,1548.7,1284.9,1553.7,1289z M1588.4,1465.9c3.4-6,7.3-12.3,10-18.3c-8.2,0.7-15.4-4.1-21.4-8.9
    c-6.6,6-14.8,7.3-22.9,5.1C1560.7,1458.3,1576,1458.3,1588.4,1465.9z M1554.1,1444.5C1554.1,1443.8,1554.1,1443.8,1554.1,1444.5
    l-3.4,9.2l0,0L1554.1,1444.5z M1544,1394.8c-0.7,0-1,0-1.5,0c0,0.7,0.7,0.7,0.7,1C1543.6,1395.2,1544,1394.8,1544,1394.8z
    M1462.5,823.1c5.1,0,11.4,1.9,15.4,0c3.4-1.9,5.1-8.2,10-18.3c-12.6,6.7-19.5,10.1-26.5,14.2
    C1462.2,820.5,1462.2,821.5,1462.5,823.1z M1487.9,474.5c-0.7,0.7,1,2.5,1.9,4.1c1.5-1,3.4-1.6,4.7-3.2c0.7-0.7-1.5-2.5-2.5-4.1
    C1490.5,472.6,1488.6,473.2,1487.9,474.5z M1499.6,511.5L1499.6,511.5c-1.5,0-3.4,0-5.6,0C1496.1,511.5,1498.3,511.5,1499.6,511.5z
    M1488.9,591.7L1488.9,591.7c0-0.7-0.7-0.7-1-0.7L1488.9,591.7z M1413.7,810.4c-0.7-1-0.7-1.9-1-2.5c-4.1,1.9-7.5,4.1-11.6,6
    L1413.7,810.4z M1399.9,716.2c0.7,0.7,0.7,1.6,1,1.9l10-3.2l-1-4.8C1406.5,712.4,1403.1,714,1399.9,716.2z M1500.8,827.2
    c-1.5-0.7-3.4,0.7-5.1,1c1.5,1.6,2.5,3.4,4.7,4.8c0.7,0.7,2.5-1.6,4.1-1.9C1502.7,829.4,1501.8,827.8,1500.8,827.2z M1583.2,557.9
    c0,0,0.7,0,0.7,0.7v-0.7H1583.2l1-0.7l-0.7-0.7C1583.9,557.6,1583.2,557.9,1583.2,557.9L1583.2,557.9z M1770.8,939.7L1770.8,939.7
    c-1.5-1-3.2-1.9-4.7-2.5C1767.2,937.8,1768.6,938.8,1770.8,939.7z M1963.3,1049.4c-5.6-3.2-6.6,1.9-9.2,4.1c-1.9,1.6-5.1,1-7.5,1.6
    c0-15.5-15.4-5.1-20.4-12.6c-4.7-7.3-10.7-13.3-16.3-20.5c-13.2,0-23.9-0.7-34.6,0c-1.5,0-2.5,0-3.4-0.7c1,4.1,2.5,7.5,5.1,11.4
    c-1,1.9-1.5,3.4-2.5,5.7c1.9-1.6,4.1-3.2,6.6-4.1l21.4,8.2c-2.5,9.8-5.1,19-7.3,26.6c13.8,10.1,26.5,19,40.9,29.7
    c0.7-15.5,3.4-26.6,18.2-32.3C1959.2,1064.3,1964.2,1057,1963.3,1049.4z M1953.5,1033.6c5.6-1,6.6-5.1,4.7-10.1
    c-4.7,0.7-9.2,1-8.8,7.5C1950,1032,1952.6,1033.9,1953.5,1033.6z M1776.4,997.5l-1.9,0.7l1.5,1.6L1776.4,997.5z M1882.1,970.9
    L1882.1,970.9h-1.5c-1,0-1.5,0.7-2.5,0.7c1.9,1,5.1,2.5,8.8,4.1c-0.7-1-1-1.9-1-3.2l-1.5-3.4L1882.1,970.9z M1940.2,1142.9
    c-1.5-0.7-3.4-1.6-4.7-1c-1.9,1-4.1,2.5-8.8,5.7c5.1-0.7,8.2,0,10-0.7C1938.7,1146.1,1939.3,1143.9,1940.2,1142.9z M1774.9,933.7
    c-1-0.7-1.5-0.7-1.9-1c-0.7,1.9-1,4.1-1.5,6.7C1772.3,937.1,1773.2,935.2,1774.9,933.7z M1900,998.4c2.5,0,5.1-0.7,7.5-0.7
    c-0.7-0.7-0.7-1.9-0.7-1.9c-1.9,0-3.4,0-5.6-0.7c-0.7,0.7-1,1-1.5,1C1900,997.5,1900,998.4,1900,998.4z M1862.9,1032.9l-1-0.7
    c-1-0.7-1.9-1-3.4-1.6c-4.7-1.9-8.8-4.8-10.7-8.9c0.7,9.2,3.2,18.1,10,27.2c2.5-1.6,4.7-3.2,7.3-4.8c0-0.7,0-1.6-0.7-1.9
    C1863.9,1039.6,1862.9,1032.9,1862.9,1032.9z M1923,1294.3c-3.2,4.8-5.1,6.7-6,8.9c-1,1.9-1.5,5.1-1.9,7.3c4.1,3.4,7.3,3.4,10,1.6
    c0.7-0.7,0.7-0.7,1-1l0,0c0.7-0.7,1-1,1.5-1c1,0.7,2.5,1.6,2.5,1.6c-0.7,0-1.5-1-2.5-1.6C1926.4,1305,1925.2,1300.6,1923,1294.3z
    M1910.4,1189.4c-1.5,1.6-3.2,3.2-4.7,4.1c3.4,3.2,7.3,5.7,10,8.9c1.5,1.6,3.2,2.5,4.7,4.1
    C1925.4,1195.1,1925.2,1194.7,1910.4,1189.4z M1911.6,1251.9L1911.6,1251.9C1911.3,1251.9,1911.3,1251.9,1911.6,1251.9
    L1911.6,1251.9L1911.6,1251.9z M1418.5,1469.1l1-18.1l-0.7,0.7c0,0.7-2.5-1.6-4.1-2.5c0,6.7,0.7,13.3,0.7,19.6
    C1416.6,1468.4,1418.2,1468.4,1418.5,1469.1z M1930.5,1248.5L1930.5,1248.5L1930.5,1248.5c1,1,1,1.9,1,3.2c0,1-1,2.5-1.9,3.4
    c-1-1.6-1.9-2.5-2.5-4.1c-1-1-1.5-1.9-2.5-3.2c-4.7-0.7-11.4-5.1-12.6,4.1l0,0l0.7,0.7c1.5,1,2.5,1.6,4.1,1.9c-1,0-1.9,0-2.5-0.7
    c-0.7-0.7-1-1-1.5-1.6c0,0-0.7,0-0.7-0.7l0,0c0.7,3.2,1.5,5.7,1.9,8.9c1,7.3,6.6,10.8,12.2,9.2c8.2-1.9,18-2.5,21.4-12.3
    C1947.8,1252.9,1942.8,1249.4,1930.5,1248.5z M1925.4,1166c-1.9,1-4.7,4.1-4.7,6s3.2,5.1,5.1,5.7c6,1,12.2,0,22.3,0
    C1941.9,1163.5,1934.3,1162.8,1925.4,1166z M1925.4,1227.3c0,0.7,0,0.7-0.7,1c1.5,0.7,2.5,1.6,4.1,1.9c1-4.8-0.7-7.5-2.5-9.8
    C1926.4,1222.9,1926.4,1225.4,1925.4,1227.3z M1626.5,1519.6c-0.7-1-1-1.9-1-3.2l-1,2.5C1624.8,1519,1625.8,1519.6,1626.5,1519.6z
    M1677.7,1405.6l0.7,0.7c0-0.7,0-0.7-0.7-1C1678,1404.9,1677.7,1404.9,1677.7,1405.6C1677.7,1404.9,1677.7,1404.9,1677.7,1405.6z
    M1693.3,1369.5c-1.5,14.9-6.6,25.6-15.4,34.8c-3.4-11.4,0.7-24.6-10.7-34.1c-4.1,1.6-7.5,3.2-11.4,4.1c-19.5,6.7-42.8,0-37.7-9.8
    c3.2-0.7,5.6-1.6,8.2-1.9c-1-5.1-1.9-9.2-2.5-11.6c7.5-5.1,13.2-9.2,20.4-13.3C1660.7,1348,1677.7,1358.8,1693.3,1369.5z
    M1683,1369.5L1683,1369.5L1683,1369.5L1683,1369.5L1683,1369.5z M1613.2,1444.5c0.7,0.7,1.5,0,1.9,0c0-0.7,0.7-1.9,0-1.9
    s-1.5,0-1.9,0C1613.2,1443.5,1613.2,1444.5,1613.2,1444.5z M1603.2,1507.3l-2.5,0.7l1.9,1.6L1603.2,1507.3z M1521,1406.5
    c-1-0.7-1.9-1.6-3.2-1.9C1517.6,1408.1,1519.5,1406.5,1521,1406.5z M1587.3,1364.1c0,0-1-0.7-4.1-3.2c0-0.7,0-1,0-1.6
    c4.1-1.9,6.6-4.8,9.2-7.3c-2.5-4.8-4.1-9.8-5.1-15.9c-1.5-13.3-10-15.9-26.5-7.5c5.1,8.2,11.4,11.6,11.4,21.5
    c0,14.2,6.6,17.4,26.5,16.7c0-2.5,0-5.7-1-7.3c-0.7,0-1,0.7-1.5,0.7C1593.4,1361.9,1590.5,1363.6,1587.3,1364.1z M1757.5,1143.9
    c-5.1-8.9-12.2-1.6-18.2-2.5C1745.8,1152.7,1745.8,1152.7,1757.5,1143.9z M1608.5,1406.5c-3.2,4.8-5.6,8.9-9.7,14.9
    c5.1,1.6,11.4,3.2,16.7,5.1c6.6-7.5,9.2-14.2,1.9-20.8C1614.8,1403.4,1611.3,1402.4,1608.5,1406.5z M1739,943.8
    c-9.2-0.7-18.2,1-28.6,1.9c-1.5,9.8,0.7,19.6,7.3,26.3c6,5.7,13.8,9.2,22.3,5.1c3.2-5.1,6-10.1,9.2-15.9c0.7-3.4,2.5-8.2,1-10.1
    C1747.7,947.6,1743.1,943.8,1739,943.8z M1746.8,1057.8c0-0.7-1.5-2.5-3.2-5.1c-1.9,1.9-4.1,3.2-5.6,5.7c-0.7,0.7,1.5,2.5,2.5,4.1
    C1743.1,1061.1,1745.3,1060.1,1746.8,1057.8z M1763.5,1061.1c-0.7,0-1,1-1.5,1.6c0.7,0.7,1,1,1.5,1.6c0.7-1,1.5-1.9,1.9-3.2
    C1765,1061.1,1764.2,1060.4,1763.5,1061.1z M1700,1136.3c0-0.7,0-1.6-0.7-1.9c-3.4-1-7.3-1.9-10.7-3.4c-4.1,7.3-6.6,14.9-6.6,23.4
    c0,6,3.2,11.4,9.2,13.9c4.7,1.6,12.2-2.5,11.4-6C1698.4,1152.7,1700,1144.5,1700,1136.3z M1799.7,1115.7c1-0.7,1.9-0.7,3.2-0.7
    l-1.9-1L1799.7,1115.7z M1952.6,1283.2c3.2-0.7,6-3.2,8.8-4.8c-1.5-1.9-3.2-5.7-4.7-5.7c-3.2,0-6,1.9-11.4,4.1
    C1949,1280.1,1950.9,1283.5,1952.6,1283.2z M1887.7,712.4c0.7-0.7,1.5-1.6,1.9-1.9c-1,0.7-1.9,1-3.4,1.6
    C1887.4,712.1,1887.7,712.4,1887.7,712.4z M1864.4,714.7c-1.5,0-2.5,0.7-4.1,1c0.7,1.6,1,2.5,1.5,4.1c1-1,2.5-1.6,3.4-2.5
    C1865.4,716.5,1864.8,714.7,1864.4,714.7z M1950.4,753.9c0.7,0.7,3.4-1.9,3.4-3.2c0-1.9-1.5-4.8-2.5-8.2c-3.2,3.2-4.7,4.8-6,6.7
    C1946.8,751,1948.5,752.9,1950.4,753.9z M1934.3,803.8c1.5-0.7,3.2-3.2,3.2-4.8c-1-8.2-8.2-6-13.2-7.3c-0.7,0-1.9,0.7-4.7,1
    c0,2.5,0,3.4,0.7,4.8C1923.6,802.6,1927.6,805.7,1934.3,803.8z M1819.5,657.1c0.7-1.6,0.7-2.5,1-4.1c-5.6-1-11.4-1.6-17.3-1.9
    L1819.5,657.1z M2021.7,873.3c-1.5,0-3.2-0.7-5.1-0.7C2018.5,873.3,2020.2,873.3,2021.7,873.3L2021.7,873.3z M2003.5,922.9
    c-1.9-0.7-3.4-0.7-5.6-0.7C2000,922.3,2001.9,922.9,2003.5,922.9L2003.5,922.9z M1848.1,748.8c0.7,0,1-1.6,1-1.9
    c-1-0.7-1.5-1-1.9-1.6c-0.7,1-1,2.5-1.5,3.4C1846.2,748.8,1847.1,749.5,1848.1,748.8z M1826.7,712.1c-0.7-1-0.7-1.6-1-2.5
    c-1,0-1.9-0.7-1.9,0c-0.7,0.7,0,1.6-0.7,2.5C1824.8,712.1,1825.8,712.1,1826.7,712.1z M2020.7,881.5c0-1,0.7-1.9,1-2.5
    c0-1.6,0-3.4,0-5.1c-1,1.6-1.9,2.5-2.5,4.1C2019.2,879,2020.2,880.3,2020.7,881.5z M1921.1,1222.9c0-3.2-1-6-2.5-9.8
    c-0.7-1.6-1-3.2-1.5-4.8l-1-4.1l-3.2,2.5c-5.6,4.8-8.8,7.3-9.7,10.1c6,3.2,11.6,6,17.3,8.9
    C1921.1,1225.4,1921.1,1223.8,1921.1,1222.9z M1886.2,725.4c-1.5,0-2.5,0-3.4,0c1,0.7,1.9,1,3.4,1.6V725.4z M1930.2,718.1
    L1930.2,718.1L1930.2,718.1L1930.2,718.1z M1927.1,775.3L1927.1,775.3l2.5,1c-1.5-6-1.5-11.4-3.4-16.4
    C1925.2,764.3,1925.4,769.3,1927.1,775.3z M1870.4,865.4c1-3.4,1.9-7.5-3.2-8.9c-1.5,0-4.1,4.8-5.1,7.3c-0.7,1.9,1,4.8,2.5,8.9
    C1868,869.2,1870.2,867.6,1870.4,865.4z M1812.6,671.1c-0.7,0-1.5,0-1.9,0c0,0.7,0,1.6,0,1.9c0.7,0,1.5,0,1.9,0
    C1812.6,672.7,1812.6,671.7,1812.6,671.1z M1871.4,1143.9c-6,8.9-5.1,22.4,1,29.7c1,1.6,3.4,1.6,4.7,3.2c5.1,4.1,9.2,8.9,13.8,12.6
    c1.5-1,2.5-1.6,3.2-2.5l0,0c-1.5-1.6-2.5-3.4-3.4-5.1c1.5,1,3.2,2.5,4.7,3.4c1-1.9,0-4.8-1.5-6c-3.4-5.1-8.2-10.1-12.6-14.9
    c1.5-7.3,2.5-13.3,4.1-22.2C1879.5,1142.3,1873.6,1142,1871.4,1143.9z M1887.7,1239.6c-1.5,0.7-3.2,1.6-4.7,2.5
    c5.1,3.4,8.2,7.3,10.7,7.3s5.6-4.8,6.6-7.3c0.7-1-0.7-2.5-1.5-4.8c-3.2,1.6-6.6,3.2-11.4,3.4
    C1887.7,1240.6,1887.7,1240.3,1887.7,1239.6z M1833.3,989.3c1,0,2.5,0,4.1,0c-15.4-6-16.3-4.8-15.4,19c5.1-6.7,8.8-10.8,12.2-14.9
    C1833.3,992.4,1833.3,990.9,1833.3,989.3z M1880.9,1219.1c3.2-2.5,6-5.1,8.8-7.5c3.2-2.5,6.6-5.7,9.2-7.5l0.7-0.7v-1
    c0-1,0-1.6,0-1.9c-10.7,9.8-17.3,11.4-61.7,16.7c2.5,8.2,7.5,13.3,15.8,12.3c9.7-1.6,19.9-1.9,28.6-6.7
    C1882.1,1221.3,1881.1,1220.3,1880.9,1219.1z M1852.8,1176.8v1.9c1-0.7,1.9-1.6,3.2-1.9l-1.5-1.9
    C1853.7,1175.8,1853.2,1176.1,1852.8,1176.8z M1883.6,1299.7c-6.6-1-13.2-2.5-22.3-4.1c3.2,9.2,3.4,16.4,7.3,21.5
    c1.5,1.9,3.2,4.8,4.7,6.7l0.7-0.7c4.7-3.4,8.2-6.7,13.2-10.1C1885.2,1308.8,1884.9,1304.1,1883.6,1299.7z M1866.1,1246.3
    c1-1,1.5-1.9,2.5-3.2c-0.7,0.7-1.5,1-1.5,1.6C1866.3,1244.7,1866.1,1245.3,1866.1,1246.3z M2035.5,947.6c-2.5,1.9-2.5,6-3.4,9.2
    c2.5-0.7,5.6-0.7,7.5-1.6c3.2-1.6,5.1-4.1,7.5-6c0,0-1-1.9-1.5-2.5C2042.1,947,2037.4,946,2035.5,947.6z M1990.3,1152.7
    c6-4.8,11.4-8.2,19.9-14.2c-12.6-5.7-20.4-8.9-29.6-12.6C1983.7,1135.3,1986.5,1142.9,1990.3,1152.7z M2021.4,904.7
    c2.5,8.9,4.1,15.5,6.6,20.5c1,1.9,6.6,3.2,9.7,2.5c6-0.7,7.3-5.7,5.6-10.8C2041.3,908.1,2034,905.5,2021.4,904.7z M2025.2,1098.3
    c-3.4-0.7-7.3-0.7-10-0.7c-0.7,0-1.9,1.6-1.9,1.6c0.7,1.6,1.5,3.4,2.5,4.1c2.5,0.7,5.1,0,7.5,0
    C2024.3,1102.2,2024.8,1100,2025.2,1098.3z M2029.2,1027.3c0,0.7,0,1.6,0.7,1.9c0.7,0.7,1.5,0.7,1.9,1c0-1,0-1.9,0-3.2
    C2030.9,1026.9,2030.6,1026.9,2029.2,1027.3z M2006.6,1286.7c0.7,0.7,2.5-1.9,4.1-3.2c-2.5-2.5-4.7-5.1-6.6-7.5
    c-1,1-1.9,2.5-4.7,5.1C2001.3,1283.2,2003.8,1285.2,2006.6,1286.7z M2012,1121.5c1,1,2.5,3.2,3.2,3.2c1.9-0.7,3.2-2.5,4.7-3.4
    c-1.5-1-2.5-2.5-4.1-2.5C2014.5,1118.9,2013.6,1120.5,2012,1121.5z M1144.9,690.6c1.5-0.7,3.2-1,4.7-1.6l-0.7-1.6
    c-1.5,0.7-3.2,1-4.7,1.6L1144.9,690.6z M1234.4,1034.8c-0.7,0-1.5,0-1.9,0c0.7,1,1,1.9,1.5,3.2c0.7-0.7,1-1,1.9-1.6
    C1234.8,1036.5,1234.8,1035.5,1234.4,1034.8z M1188.5,1034.6c-1,0.7-1,2.5-1.5,4.1c1.5,0,3.4,0.7,5.1-0.7c1-0.7,1-2.5,1.5-4.1
    C1191.6,1033.9,1190.1,1033.6,1188.5,1034.6z M1237,1179.2c1-1,3.4-2.5,3.4-3.4c0-3.2-1-6-1.5-8.9c-1.5,3.2-3.2,5.7-4.1,8.9
    C1233.9,1176.1,1236,1177.7,1237,1179.2z M1351.5,700.8l3.2-1.6c-0.7,0-1,0-1.5,0.7C1352.7,699.8,1352.1,700.1,1351.5,700.8z
    M1242.9,1125.6c-3.2-1.9-5.6-4.1-8.8-6c0.7-1,0.7-1.9,1-3.2c-1,0.7-1.9,1.6-2.5,2.5C1238,1123.7,1240.4,1125.6,1242.9,1125.6z
    M1247,1244.4c0.7,0,1,0.7,1,0.7c0-0.7,0.7-1,0.7-1.6c-2.5,0-5.6-1-7.5,0s-2.5,4.8-4.7,8.9c1.5,0,2.5-0.7,3.4-0.7
    c1-1,1.9-1.9,3.4-3.4C1244.5,1246.9,1245.5,1246,1247,1244.4z M1269.7,1234.5c1-1,1-3.2,1.5-4.8c-1.5-0.7-3.4-1.6-4.1-1
    c-1,1-1,3.2-1.5,4.8C1266.5,1234,1269.1,1235.5,1269.7,1234.5z M1355.2,699.1c0.7,0,0.7,0,1,0l0,0c0,0,0,0,0.7,0v-0.6L1355.2,699.1
    z M1298.6,998.2c0-0.7,0-1,0-1.6c0-0.7-1-1-1-1.6C1297.6,996,1298,997.2,1298.6,998.2z M1309.3,1102.2c0-0.7-0.7-1-0.7-1.6
    c-3.2,1.6-6.6,3.2-9.7,4.8h-0.3c0,0,0,0,0,0.7c0,0,0,0,0.7-0.7L1309.3,1102.2z M1276.2,1021.6c10,2.5,18.9,4.8,30.2,7.3
    c-5.6,9.2,3.4,16.7,0,26.3c14.8-1,18.9-13.9,26.5-22.4l-8.2-29c-5.1,7.3-8.8,12.3-12.6,18.3c-7.3-4.1-12.6-7.3-18.2-10.8
    c2.5-4.8,4.1-7.5,5.6-11.4c-8.2-0.7-16.7-1-24.8-1c-1.5,0-2.5,1.9-5.1,3.4C1270.9,1009.2,1274,1016.2,1276.2,1021.6z
    M1310.2,1247.8c3.2-0.7,6-1.9,9.2-3.4c-1.9-3.2-2.5-5.1-4.1-5.7c-4.7-1.6-7.3,1-7.5,5.1
    C1308.3,1244.7,1310.2,1247.8,1310.2,1247.8z M1298,1233c-1,0-1.9,0-3.4,0.7c0.7,0.7,1.5,1,1.9,1
    C1297.1,1234,1297.6,1233.6,1298,1233z M1296.4,1148c6.6-1.9,9.7-6,10-12.6c1-11.4-1.9-20.8-8.2-30.4c-1,1.6-2.5,2.5-4.1,4.1
    c-1,1.9-1.9,3.2-3.4,4.1c-0.7,1-1,1.6-1,2.5c-1,8.2-0.7,16.7-4.7,24.9C1284.5,1144.5,1292,1149.5,1296.4,1148z M1286.4,1033.9
    c0.7,0.7,0.7,1.6,1,1.6c0.7,0,1-0.7,2.5-1.6C1287.9,1033.9,1286.9,1033.9,1286.4,1033.9z M1330.7,688.2c0.7-1,1-2.5,1.5-4.1
    c-1.9,0-3.4-0.7-5.6-0.7c0.7,1.6,0.7,3.4,1,4.8C1328.2,688.2,1330.7,688.4,1330.7,688.2z M1374.1,706.5c-5.6-2.5-11.6-5.1-18-7.3
    c-1.5,13.9-7.5,24.6-22.3,28.7c-0.7,0,0,3.2-0.7,6C1354.6,735.5,1368.8,728,1374.1,706.5z M1384.8,1158.7L1384.8,1158.7
    L1384.8,1158.7L1384.8,1158.7z M1349.9,834.8v-1.9l-1.9,1.6L1349.9,834.8z M1339.8,807.2c0.7,0,1-1,1.5-1.6c-1,0.7-1.9,0.7-3.4,0.7
    C1338.9,807.2,1339.2,807.9,1339.8,807.2z M1477.9,532.3c-7.5,3.2-13.8,5.1-21.4,8.2c2.5,4.8,4.1,7.5,5.6,10.8
    c5.1-4.1,9.7-7.5,14.1-11.6C1477.2,538.6,1477,536.4,1477.9,532.3z M1407.4,1067c-9.7,0.7-16.7,5.1-22.1,12.3
    c5.1,12.3-9.7,14.9-12.6,24.1c3.4,1.9,7.3,3.4,12.6,6.7c5.6-9.2,10-16.4,14.1-23.4C1389.9,1075.9,1407.4,1074.9,1407.4,1067z
    M1422,465.3c0.7,3.4,0.7,7.3,1.9,10.1c1,1.6,5.1,3.2,6.6,1.9c1.5-1,4.1-4.8,3.4-6C1432.7,465.3,1428.8,461.8,1422,465.3z
    M1344.9,993.4c0-0.7-4.1-2.5-4.7-1.9c-1.5,1.9-1.9,4.1-3.4,6c1.5,0.7,3.2,1.6,5.6,2.5C1343.9,998.2,1344.9,995.7,1344.9,993.4z
    M1377,613.2c-1.5,0-2.5-0.7-4.1-0.7c0.7,0.7,1.5,1,2.5,1.6C1375.4,614.1,1376.6,613.2,1377,613.2z M1354.6,615.8
    c6-1.6,11.4-3.2,16.3-4.1c-0.7-5.1-1-10.1-1.5-15.9C1358.1,596.8,1356.6,604.7,1354.6,615.8z M1372.9,612.9c-0.7-0.7-1-1-1.5-1l0,0
    h-0.3v0.7l0.7-1l-0.7,1C1371.9,612.2,1372.5,612.2,1372.9,612.9z M1351.1,774.1c3.2,4.8,5.6,8.2,8.2,11.4c0,0,0.7,0,0.7-0.7l0,0
    c0,0,0,0,0.7,0c1.9-3.2,3.4-6,6-8.9c3.2-3.2,6.6-5.1,10-7.5l0,0l0,0v-0.6C1367.1,767.4,1358.6,766.2,1351.1,774.1z M1357.8,980.8
    c1.9,3.2,4.7,7.3,8.8,3.2c1-1,1-3.4,1.9-7.3c-3.4-0.7-6.6-1-10-1C1358.1,976,1357.1,979.8,1357.8,980.8z M1375.4,1029.5
    c-5.6,6.7-13.2,12.6-8.2,23.1C1375.4,1046.9,1379.2,1038.9,1375.4,1029.5z M1261.5,969.1c4.1-1.6,7.5-3.2,11.6-4.8
    c-11.4-3.2-13.2-5.1-21.4-14.2l-1-1v-1c0-1.6,0.7-3.2,0.7-4.1c1.9-5.1,3.4-11.4,5.1-18.3c1-4.1,11.4-8.2,15.4-26.6
    c0.7-1.9,1-4.1,1.5-5.7c1.5-0.7,2.5-1.6,3.2-2.5c0-0.7,0.7-1.6,0-1.9c8.8-9.2,24.5,2.5,26.5-7.3c-6.6,29.7-9.7,35.4-12.2,43
    c1.9,0.7,4.7,1,6.6,1.6c0.7,9.8-1.9,19.6-9.7,24.9c-1,0.7-1.5,1-2.5,1.6c-1.9,10.1-4.1,20.8-4.7,24.1c-1,4.1-3.2,9.8-6,14.9
    c-10.7-1.9-13.2-10.8-15.4-19.6l-0.7-1.9L1261.5,969.1z M1274.7,938.8c0.7,1,1,1.6,1,1.9c0.7,0,1,0.7,1.5,0.7c-0.7-1-1-1.9-1.5-2.5
    C1275.7,938.8,1275,938.8,1274.7,938.8z M1180.4,716.5c0.7,0.7,1.9,0,2.5,0c-0.7-1-1-2.5-1.5-3.4c-0.7,0.7-1.5,1-2.5,1.6
    C1179.4,715,1179.4,716.2,1180.4,716.5z M1181.9,970.9c-1.9-2.5-3.2-4.1-3.4-5.7c0-1,1-3.2,3.2-6c0.7,0,0.7,0,1,0
    c-1-5.7-5.1-5.1-14.8-2.5c3.4,5.7,7.3,10.8,13.2,20C1181.3,975,1181.9,973.2,1181.9,970.9z M1166.3,682.7c1,1,1.9,1.9,3.2,3.2
    c0.7-1.6,1.5-2.5,1.9-4.1c-1-0.7-2.5-1-4.1-1C1166.5,680.9,1165.6,682.7,1166.3,682.7z M1159.9,932.7c1,0.7,3.2-0.7,5.6-1
    c-2.5-2.5-4.1-4.1-6-5.7c-0.7,0-1.9,1.6-3.2,2.5C1157.4,929.6,1158.7,931.5,1159.9,932.7z M1462.2,552.5c0-0.7-0.7-0.7-0.7-1
    l-0.7,0.7L1462.2,552.5l-1-0.7v0.7C1461.6,552.5,1461.6,552.5,1462.2,552.5L1462.2,552.5z M1224.7,886.6
    c4.7,9.2,9.2,17.4,13.8,26.3c0,0,0-0.7,0.7-0.7C1236,894.2,1236,893.9,1224.7,886.6z M1150.8,837.9c1.9-4.1,4.1-6.7,3.4-8.9
    c-0.7-3.2-3.2-7.5-4.1-7.5c-5.1,0-4.7,4.8-3.4,8.2C1147.3,832.3,1148.9,834.5,1150.8,837.9z M1434.8,1414.8
    c7.3-11.4,18-19.6,27-29.7c-7.5-6.7-13.8-12.3-20.7-17.4c-5.1-3.4-10-4.8-15.4-4.1c2.5,1.6,5.1,2.5,8.2,4.1
    c-3.4,1-6.6,2.5-10.7,3.4c4.1,1.6,7.5,3.4,10,9.2c-4.1,0.7-7.5,1-11.4,1.6c-3.4-1-6.6-1.6-10.7-2.5c2.5-4.8,6-6.7,9.7-7.5
    c0-4.8,0.7-6.7,2.5-7.5h-0.7c-5.6-2.5-11.6-1.9-18,1.6c-7.5,4.1-14.1,9.8-22.3,15.5c6.6,8.9,14.8,16.7,20.4,25.6
    c1-0.7,1.5-1.6,2.5-1.9c2.5-1.9,5.1-4.1,7.5-6c-0.7-1.9-1-4.1-1-5.7c3.4-1.9,6.6-4.1,9.2-5.7c2.5,1.9,5.1,4.1,8.8,6.7
    c-1,3.4-2.5,8.2-4.1,13.3l0,0c-0.7,2.5-1.5,5.1-1.9,7.5C1429.2,1415.3,1434,1416.3,1434.8,1414.8z M1357.8,1094.9
    c-7.3-2.5-13.8-4.8-22.1-7.3c-4.1,9.2-7.5,16.4-11.6,24.6c5.6,3.2,9.7,0,13.2-2.5c8.2-4.8,8.2,6.7,13.8,6c2.5,0,5.6-1,8.2-2.5
    c3.2-1.9,6-4.1,9.2-6C1365.3,1103.1,1361.2,1099.3,1357.8,1094.9L1357.8,1094.9z M1368.8,1107.2L1368.8,1107.2L1368.8,1107.2
    L1368.8,1107.2z M1208.1,679.3c0-0.7-1.5,0-1.9,0c-0.7,1-0.7,1.6-1,2.5c1,0,1.9,0,3.4,0C1208.1,681.5,1208.4,680.2,1208.1,679.3z
    M1375.4,1136.9c4.7-8.9,4.1-14.2-1.5-20.5c-1.9-2.5-3.2-6-5.1-9.2c-3.2,7.5-6,15.9-9.7,24.9
    C1365.9,1134.4,1371,1135.3,1375.4,1136.9z M1256.2,1038.9c1-1.6,3.4-3.2,3.2-3.4c-1-1.9-3.2-3.4-5.1-6c-1.5,1.9-3.4,4.8-3.4,4.8
    C1252.7,1036.1,1255,1037.7,1256.2,1038.9z M1301.2,518.1l2.5,0.7l-0.7-1.9L1301.2,518.1z M1424.1,1448.9c-1.5,0.7-4.1,2.5-4.1,1.9
    l-0.7-0.7l-1,18.1c1,0,1.9-0.7,3.2-0.7C1422.6,1462.1,1423.8,1455.5,1424.1,1448.9z M1344.9,1152.7c0,1,3.2,3.4,3.4,3.4
    c1.9-1,4.7-3.2,5.1-4.8c0-1.6-2.5-3.4-4.7-6.7C1346.4,1148,1344.9,1150.2,1344.9,1152.7z M1221.2,913.7c1.9,0,4.1,1,5.1,0
    c1-0.7,0.7-3.2,1-4.8c-1.9,0-3.4-0.7-5.6-0.7C1221.5,910,1221.2,911.5,1221.2,913.7z M1241.1,637.8c0.7-0.7,0.7-1.6,0.7-1.9
    c-0.7,0-1.5,0-2.5-0.7c0,0.7,0,1.6-0.7,1.9C1239.5,637.8,1240.4,638.5,1241.1,637.8z M1223.2,869.2c-1,0.7-1.9,1.6-3.4,1.9
    c1,1,1.9,2.5,2.5,3.4c0.7-1,1.5-2.5,1.9-4.1C1224.7,870.2,1223.2,868.6,1223.2,869.2z M1260.2,590.2c0.7,0,1,0,1.9,0
    c-0.7,0-1-0.7-1.5-0.7C1260.2,589.9,1260.2,590.2,1260.2,590.2z M1216.6,623.7c0,1.6,0.7,2.5,0.7,4.1l16.7-4.8
    C1227.8,623,1222.2,623.7,1216.6,623.7z M1225.4,644.5c0-0.7,0-1.6,0-1.9c-0.7,0-1.5,0-1.9,0c0,0,0,1.6,0,1.9
    C1223.7,644.5,1224.7,644.5,1225.4,644.5z M1201.8,847.7c1-0.7,1.5-2.5,1.9-4.1c-1,0-1.9,0-2.5,0c-1,1-2.5,2.5-3.4,3.4
    C1199.2,847.7,1200.8,848,1201.8,847.7z M1225.6,966c1,0.7,1.5,0.7,2.5,0.7C1228.1,965.3,1227.3,965.3,1225.6,966z M1264.3,770
    c-1.9,2.5-4.1,6-4.7,9.2c0,1.6,3.4,3.2,5.1,4.8c1.9-3.4,4.7-7.3,7.5-12.3C1269.1,771,1265.7,768.8,1264.3,770z M1276.2,709
    c-1.5-0.7-3.4-1.6-4.7-1c-2.5,0.7-4.7,1.6-6.6,2.5c1.9,1,4.1,1.9,6,1.9C1273.2,712.4,1274.7,709.9,1276.2,709z M1248.3,831.3
    c-0.7,1.6-0.7,3.2-1,4.8c1,0,1.5,0.7,2.5,0.7c0.7-1.6,0.7-3.2,1-4.8C1250.2,831.9,1248.7,831.3,1248.3,831.3z M1206.4,871.1
    c0.7-0.7,1.5-1,1.9-1c-0.7-0.7-1-1.6-1.5-1.9C1206.7,869.2,1206.4,870.2,1206.4,871.1z M1260.2,605.6c1,1,3.4,2.5,4.1,1.9
    c3.2-1.9,5.6-4.8,8.8-7.3c-0.7-1-1-1.6-1-2.5c-3.4,1.6-7.5,3.2-11.4,5.1C1260.2,603.4,1259.9,605.6,1260.2,605.6z M1255,710.6
    c-3.4,5.7-6,11.6-8.8,18.1l0,0c1.5-1,2.5-1.9,4.1-3.2c0.7,1.6,0.7,2.5,1,4.1l-5.1-0.7c1.9,0.7,3.4,1.6,5.1,1.6
    c11.4,2.5,14.1,0.7,15.4-9.8c0-3.2-1-6.7-1.9-10.1l0,0C1260.9,708,1257.4,706.5,1255,710.6z M580,1235.5c-0.7,0.7-1.5,1-1.9,1.6
    c0,0,1,1,1,1.6c1-0.7,1.5-1,1.9-1.6L580,1235.5z M155.6,1182.4c0,0.7,0.7,1,1,1.9c0.7-0.7,1-1,1.5-1.6c-0.7-0.7-1-1.6-1-1.9
    C156.9,1181.4,155.6,1181.8,155.6,1182.4z M235.2,1236.2c6-8.2,10-14.2,15.4-21.5C237.1,1216.6,237.1,1216.6,235.2,1236.2z
    M210.4,865.4l-1.9-0.7l0.7,1.9L210.4,865.4z M303.5,1119.5c1.5-0.7,2.5-1.9,3.4-3.2c-1.5-1-2.5-1.9-4.1-2.5
    c-0.7,1.6-1.5,2.5-1.9,4.1C301.3,1118.3,302.8,1119.5,303.5,1119.5z M305.1,1042.8c-1,1-1.9,2.5-2.5,3.4c0.7,0.7,1.9,1.6,1.9,1.6
    c1-1,1.9-2.5,3.2-3.4C306.6,1043.7,305.9,1043,305.1,1042.8z M298.4,1182.8c-1,1.6-1.9,3.2-2.5,4.8c1.5,0.7,3.2,0.7,4.7,1
    c0-1.6,0.7-3.2,0.7-4.8C300.3,1183.3,298.4,1182.8,298.4,1182.8z M324.8,922.9c-1-1-2.5-3.2-4.1-2.5H320c-0.7,1-1,2.5-1.9,3.4
    c1.5,1,3.2,1.9,4.7,3.2C323.3,925.5,324.2,923.9,324.8,922.9z M260,1731.5c1.5,0.7,3.2,0,4.7,0c-0.7-1.6-1-3.2-1.5-4.8
    c-1,0.7-2.5,1-3.4,1.9C259.1,1729.5,259.7,1731.5,260,1731.5z M272,987.5c-0.7-1.6-2.5-3.2-4.7-4.1c-0.7,3.2-1,6-1.9,9.2
    c-0.7,1.6-1,3.4-1,5.1c-1.5,1.6-3.4,1.6-6.6,1.6c-0.7,0-1,0-1.5,0c-0.5,0-1,0-1.5,0c-1.5,0-4.7,0-7.3,1c-1,1-1.5,2.5-1.5,5.1
    c8.2-3.2,15.4-5.1,22.1-8.9C270.4,995,272.3,989.9,272,987.5z M286.5,1239.3c0.7-1.6,1-2.5,1.9-4.1c-1.5,0-3.2-0.7-4.7,0
    c-0.7,0-1,1.9-1,1.9C284.5,1238.1,285.5,1238.6,286.5,1239.3z M215.7,985.2c1,0.7,1.9,1,2.5,1.6c1.5-1,3.2-2.5,4.7-3.4
    C221.3,983.4,218.9,984.2,215.7,985.2z M265.1,1038.7c1,4.1,1.5,7.5,3.2,11.4c1,1.9,5.1,4.1,6.6,3.4c1.9-0.7,4.7-3.4,5.1-6
    C281.4,1040.6,278,1038.9,265.1,1038.7z M295.9,1851.8c-0.7,0.7-1,1.6-1.5,1.9c1,0,2.5,0,4.1,0
    C296.9,1852.7,296.2,1852.2,295.9,1851.8z M291.2,1803.2c0.7-0.7,0-1.6,0.7-1.9l-1.9-1c-0.7,1-0.7,2.5-0.7,3.4
    C289.6,1803.5,290.6,1803.5,291.2,1803.2z M210.6,944.4c1.5,0.7,4.7,1.6,5.1,1l0,0c0.7-0.7,1-1.6,1.5-1.9c0.7-1.6,1-3.4,1.9-5.1
    c-1.5-0.7-3.4-1-6-1.6C211.6,938.8,211.3,941.9,210.6,944.4z M379.3,768.4c-1,1-1.9,1.6-2.5,2.5c0,0,1,1,1.5,1.9
    c0.7-0.7,1-1,1.5-1.9L379.3,768.4z M351.9,725.4c-18.2-9.8-18.2-9.8-21.4,4.1C336.2,728,341.2,727.3,351.9,725.4z M561.1,726.4h2.5
    c0.7-1,1-2.5,1.5-3.4c-0.7,0-1.9-0.7-2.5,0C562,723.9,561.6,725.4,561.1,726.4z M547.5,713.7c-1.5-1-2.5-1.6-4.1-1.9
    c-0.7,1-1,1.9-0.7,1.9c1.5,1,3.2,1.6,4.1,1.9C546.9,715,547.5,713.7,547.5,713.7z M450,637c4.7-1.9,8.2-3.4,11.6-5.7
    c1-0.7,1.9-1.6,3.2-1.9C459.2,628.1,452.5,625.5,450,637z M472.6,641.1c-0.7,0-1,0.7-1.5,0.7c1,0,2.5,0,3.4-0.7
    C473.6,641.1,472.9,641.1,472.6,641.1z M481.8,670.1c0,0-0.7,0-1,0c0,0.7,0,0.7,0.7,1L481.8,670.1z M441.8,762.1
    c0.7-1.6,1.5-2.5,1.5-4.1c-0.7-1.6-1.9-1.9-3.2-3.2c-0.7,1.6-2.5,3.2-1.9,4.1C438.4,760.8,440.3,761.1,441.8,762.1z M546,741.8
    c1,0,1.5-0.7,3.4-1c-1.9-0.7-3.2-0.7-4.1-1C546,740.6,546,741.8,546,741.8z M338,733c3.2,11.6,1,18.1,5.6,26.3
    C345.3,750,351.9,744.4,338,733z M331.4,698.8l1.9,1v-1.9L331.4,698.8z M358.4,771c-0.7,0.7-0.7,1.6-1,2.5c1.5,1,3.2,1.9,4.1,3.2
    c0.7-1,1-1.9,1-2.5C361.6,772.5,360.1,771.5,358.4,771z M265.7,953L265.7,953c5.1,1.9,9.7,4.1,15.4,6.7c7.3-22.2,7.3-22.2-9.7-32.3
    C263.8,934.7,261.3,943.5,265.7,953z M420.1,801.6c0.7-1,1.5-1.9,1.9-3.2c-1,0-1.5-0.7-2.5-0.7c-0.7,0.7-1.5,1.6-1,1.9
    C418.6,800.7,419.6,800.9,420.1,801.6z M415.5,782.6c0.7-1.6,1.9-3.2,1.9-4.8c0-1-2.5-1.9-3.4-2.5c-1,1.9-1.9,4.1-3.2,6
    C411.9,781.6,413.5,782.3,415.5,782.6z M536.8,662.6c-5.1,8.9,0.7,15.5,10,23.4c5.1-9.2,9.2-16.4,14.1-24.9
    C547.9,653.4,540.3,655.3,536.8,662.6z M533.7,1070.8l1.9-0.7l-1-1.6L533.7,1070.8z M535.6,1142.3c-1.9,6-4.1,9.2-4.1,11.6
    c0,1,5.1,3.4,5.6,3.2c2.5-1.6,5.1-4.8,5.6-7.5C543.8,1148.6,539.7,1146.1,535.6,1142.3z M375.8,1057.8c0.7,0,1,0.7,1.5,1.9l0,0
    c1,1,1.9,1.9,3.2,3.4c1,0.7,1.9,1,2.5,1c-1.9-9.8-3.2-15.5-4.1-20.8C372.3,1049.6,370.8,1051.3,375.8,1057.8z M431.8,1055.1
    c1,5.7,1.5,10.8,2.5,15.9c0.7,3.2,1,6,1.5,9.2c-3.2,1.6-5.6,3.4-8.8,5.1c4.1,1.9,8.8,3.4,13.2,4.8c-0.7-12.6-0.7-12.6,9.7-24.6
    c-4.7-5.1-9.2-9.8-13.8-14.2l-5.6,1.6L431.8,1055.1z M359.4,1760.5c-1-3.2-1.5-5.7-2.5-8.9c-1.5-0.7-3.2-1.6-5.1-2.5
    C353.5,1754.5,356,1758,359.4,1760.5z M537.2,1054.4l2.5,0.7l-0.7-1.9L537.2,1054.4z M491.3,1042.1l-1.5-1.9l-0.7,2.5L491.3,1042.1
    z M381.5,1774L381.5,1774c-1-4.8-1.9-9.8-5.1-12.6c-4.7-4.1-7.3,0-10.7,2.5l0,0C372.3,1765.8,376.8,1769.4,381.5,1774z
    M386.5,1259.6c1.5,0,3.2,0,5.1,0h0.7c1.5,0,2.5-0.7,4.1-0.7c1,0,2.5-0.7,3.4-0.7c1,0,1.9,0,2.5,1.9c-1,0.7-1.9,1-3.2,1l1,5.1h0.7
    c-0.7,1-0.7,1.9-1,3.2c-1,4.1-3.4,6.7-7.3,6.7c-1,0-1.5,0-2.5-0.7c-0.7,0-1-0.7-1.5-0.7c-1.5,5.1-1.9,10.1,6.6,12.6
    c8.2-10.8,21.4-19.6,16.3-37c-5.1,0.7-9.7,0.7-14.1,1c-4.1-4.1-8.2-7.5-11.6-11.4C385.6,1247.2,384.9,1254.5,386.5,1259.6z
    M384.3,1240.6l0.7,0.7c0-0.7,0-1,0-1.6L384.3,1240.6z M330.9,1118.9c1-0.7,1.5-1,3.2-1.9c-1.9,0-3.2,0-4.1,0
    C329.9,1117.4,330.5,1117.9,330.9,1118.9z M477.7,1193.2l-1,2.5l1.9-0.7L477.7,1193.2z M350.3,1255.2c2.5,2.5,4.7,6,7.3,6.7
    c2.5,0.7,5.6-2.5,10-5.7c-5.1-1.6-7.5-3.2-10-3.2C354.7,1252.6,352.8,1253.8,350.3,1255.2z M376.1,1240.3c1,0.7,2.5,1.6,3.4,1.6
    c1.5,0,2.5-0.7,4.1-1.6c-1-1-1.9-1.9-3.4-2.5C379.3,1237.7,377.6,1239.3,376.1,1240.3z M384.3,1240.6l0.7-1c-0.7,0-0.7,0.7-1,0.7
    C384.3,1240.6,384.3,1240.6,384.3,1240.6z M171.7,1219.7c-1.9,1-3.2,3.4-4.7,5.1c1.5,1,2.5,1.9,4.7,3.4c1.5-1.9,3.2-4.1,4.7-6
    C174.4,1221.6,172,1219.7,171.7,1219.7z M287.1,652c0-1-2.5-1.9-4.1-3.2c-1.5,2.5-3.2,4.8-4.7,7.5c2.5,0,4.7,1,6,0
    C286.2,656.2,287.1,653.7,287.1,652z M399,1057.6c0.7,0,1,0,1.5,0c1,0,1.9,0,3.2,0c3.2,0,6.6-0.7,9.2-3.4c0.7-0.7,0.7-0.7,1-1
    c0,0,0-0.7,0.7-0.7l0,0c3.4-4.1,7.3-7.5,10.7-11.4c1.5-1.6,3.2-3.2,4.7-4.8c1.9,0,3.4,0.7,5.6,0.7c3.4-4.8,8.8-8.2,9.2-15.9
    c-12.6,8.9-22.9,15.5-33,22.4C405.3,1047.2,401.2,1051.9,399,1057.6z M262.3,953.6c0.7,0,1.9,0,3.4,0l0,0
    C264.1,953.6,262.3,953.6,262.3,953.6z M173.6,947.9v1.6l1.5-1L173.6,947.9z M282.7,866.1c1.5,1,3.4,1,5.1,0.7
    c0.7-0.7,1-2.5,1.5-5.1c-2.5,0-5.1,0-7.3,0.7C281.4,863.5,281.4,865.4,282.7,866.1z M229.5,798.5c-1.5,3.4-1,9.8,1,13.3
    c1.5,2.5,7.3,4.8,10,3.4c14.8-3.4,28.9-7.5,49.4-13.3c-20.7-5.7-35.2-9.8-50.3-13.9C235.8,791.8,230.5,794.4,229.5,798.5z
    M284,851.2h1.9l-0.7-1L284,851.2z M259.1,699.8l2.5,0.7l-0.7-1.6L259.1,699.8z M476.5,1077.8H474l1,1.6L476.5,1077.8z
    M436.8,1112.9c-5.1-4.1-9.7-9.2-18.9-1.6C431.8,1119.5,431.8,1119.5,436.8,1112.9z M435.2,986.5c-4.1,2.5-7.5,4.8-12.2,7.5
    C430.8,996,434.3,994.7,435.2,986.5z M364.5,982.4c-1.5,3.4-3.2,6.7-3.4,10.1c0,1,2.5,4.1,3.4,4.1c1.9-0.7,5.1-1.6,5.6-3.4
    c1-2.5,1-5.7,1-8.2c-1.5-1.6-2.5-2.5-4.1-3.4C366.7,980.8,364.5,981.7,364.5,982.4z M479.2,1115.4c-1.5-1.9-2.5-4.1-3.2-4.1
    c-1.5,0.7-3.4,1.6-4.1,2.5c-0.7,1,0.7,3.2,0.7,4.8C474.6,1117.4,476.5,1117,479.2,1115.4z M250.9,654.6c-1.9,0.7-3.2,4.1-4.7,6
    c1.9,1.6,4.1,2.5,6.6,4.8c1.9-3.4,3.4-5.7,5.1-8.2C255.6,656.8,253.1,654.3,250.9,654.6z M321.7,810.8c1,0,1.9,0,2.5-0.7
    c0.7,0,0-1.6-0.7-1.9c-1,0-1.9,0.7-2.5,0.7C321.4,809.2,321.7,810.4,321.7,810.8z M83.3,991.9c10.7,1,18.9,15.5,31.1-0.7
    C102.2,987.8,93.7,983.9,83.3,991.9z M481.8,670.1l-0.7,1c0.7,0,0.7,0,1,0C481.8,671.1,481.8,670.8,481.8,670.1z M-3.2,955.5
    c-0.7-1-2.5-1.9-4.1-2.5c-1,1.6-2.5,3.2-1.9,4.8c0.7,1,2.5,1.6,4.1,2.5C-4.8,958.6-3.2,956.8-3.2,955.5z M84.9,1506.3
    c5.6,3.2,11.4,8.2,18.2,1.6C97.1,1500.7,91.2,1505.8,84.9,1506.3z M68.2,1957c-10.7-16.7-19.9-3.2-31.1-3.4
    C46.2,1963.3,55.3,1959.6,68.2,1957z M99.3,1915.7c0-0.7,0-1.6,0-1.6c-0.7,0-1.5,0-1.9,0c-0.7,0,0,1,0,1.6
    C98.1,1915.7,98.7,1915.7,99.3,1915.7z M-29.6,919.7l1.5,0.7v-1.6L-29.6,919.7z M54,1932.8c-0.7,0-1.5,1-1.9,1.6
    c1,1,1.9,2.5,3.2,3.4c1-1,1.9-1.6,3.2-2.5C56.5,1934,55.3,1933.1,54,1932.8z M138,1026.3c-1,0-1.9,0.7-3.4,0.7c0.7,0.7,1.5,1,1.9,1
    C136.7,1027.9,137.4,1026.9,138,1026.3z M90.5,1853.7c0,1-0.7,2.5-0.7,2.5c1.5,0.7,3.2,1,4.7,1.6c0-1,0-1.6,0.7-2.5
    C93.7,1854.7,92,1854.4,90.5,1853.7z M139.9,1037.7c0.7,0.7,1,0.7,1.9,0.7c0,0,0-1,0-1.6c-0.7,0-1.5-0.7-1.9-0.7
    C139.6,1036.5,139.6,1037.7,139.9,1037.7z M-38.8,1673.6l-1.5-0.7v1.9L-38.8,1673.6z M93,1923.2c-0.7,0-1,0.7-1.9,0.7
    c1,0.7,1.9,0.7,3.2,1C94,1924.6,93.7,1923.2,93,1923.2z M-31.8,1300l-0.7-1.9l-1.9,1.6L-31.8,1300z M-18.9,1263.7l-1.5-1.6
    l-0.7,1.9L-18.9,1263.7z M424.2,2167.9c-5.1-9.2-6-9.8-19.5-6l-1-0.7v0.7v-0.7c0,0,0,0-0.7,0c-1.9-5.1-4.7-10.8-6.6-15.9l0,0
    c-0.7-4.8-1-8.9-1.5-15.9c-5.1,3.4-8.8,6-12.2,8.9l-0.7-1l0,0l0,0c-4.1-0.7-8.8-1-12.6-1.9c0,0.7-0.7,0.7-0.7,1
    c-6,1.6-6.6,5.1-3.4,9.8c0,0.7,0,0.7-0.7,1l0,0l0,0h1c1,6.7,0,15.5,4.1,19c6.6,5.7,1,13.3,6.6,19.6c5.1,1,10,1.6,15.4,2.5
    c-2.5,4.8-5.6,11.4-8.8,18.3c4.1,3.4,7.3,5.7,10,5.7c3.4,0,6.6-1.9,12.2-6.7c-1-6.7-1-11.6-0.7-14.9c2.5,0.7,5.6,1,8.8,1.6l0,0
    c16.3,7.3,80.5-13.3,46.9-47.8c0,0.7-0.7,1-0.7,1.6l0,0C475.5,2165,448.1,2166.6,424.2,2167.9z M422.6,2077.5l-4.7,0.7
    c-3.4,7.5-7.3,15.5-11.6,24.9l0.7,0.7l0,0c5.6-3.4,11.4-7.5,17.3-11.6C424.2,2087.3,423.6,2082.5,422.6,2077.5z M152.8,983.9
    l-20.4,5.7C141.5,996.5,146.2,994.1,152.8,983.9z M490.3,2268.7c-0.7-1.6-0.7-2.5-1-4.1l-2.5-0.7c0.7,1.6,0.7,2.5,1,3.4
    C488.4,2268.7,489.9,2268.7,490.3,2268.7z M384.9,1922l8.8,4.1l1.5-1.9c3.4-4.8,8.2-7.5,12.6-9.8c-7.5-10.8-7.5-11.4-6-23.4
    c-1.5,0.7-3.4,0.7-5.1,1c0.7,0.7,1,0.7,1.5,1c-0.7,0.7-1.5,0.7-1.9,1c-1.9,1-4.1,1.9-6,3.2c0,0,0,0,0-0.7c-0.7-0.7-1-1.9-1-2.5
    c-4.7,1.9-8.2,4.8-11.4,9.8c3.2,8.9,4.7,11.6,1.9,15.5C381.5,1919.8,383.4,1920.7,384.9,1922z M472.6,2249.4c1,0,1.9-0.7,4.1-0.7
    c-1.9-0.7-2.5-1-3.4-1C472.9,2247.8,472.9,2248.8,472.6,2249.4z M293.7,2198.2c0.7,0-0.7-1.6-0.7-2.5c-1.5,1-3.2,1.9-4.7,3.2
    c0,1,0.7,1.6,1,2.5C291.2,2200.1,292.8,2199.1,293.7,2198.2z M311,2202.7c-0.7-0.7-1-1.6-1.5-1.9c-0.7,0.7-1.9,1.6-1.9,1.6
    c0.7,1,1.5,1.9,1.9,3.2L311,2202.7z M151.3,1733.6c-1-1.9-2.5-4.1-3.4-6.7c-1.9,1.6-3.4,1.9-5.1,3.2c1.5,1.9,2.5,4.1,4.1,5.7
    C147.4,1736.1,149.6,1733.9,151.3,1733.6z M131.4,1093.3c-1.5,0.7-3.2,0.7-4.7,1c0,0,0,1.6,0,2.5c1.5,0,3.2-0.7,4.7-1
    C131.4,1094.9,131.4,1094,131.4,1093.3z M127.3,1771.3c0,0.7,1,1,1.5,1.6c0.7-0.7,1-1,1-1.9c-0.7-0.7-1-1-1.5-1.6
    C128.2,1770.3,127.3,1770.9,127.3,1771.3z M88,1442.3c6,1,11.6,6.7,18.2,0.7C100.3,1435.3,94,1439.7,88,1442.3z M299.3,2235.5
    c7.5-8.2,14.8-15.5,27.4-12.6c1.9,0.7,4.7-1.9,6-3.2c4.7-5.7-1.9-13.9,8.2-16.7c1.9,1.9,4.1,4.8,6.6,7.3
    c3.4-5.1,7.5-10.8,12.2-17.4c5.1,8.9,9.2,16.7,12.6,23.1c-4.1,13.3-7.5,24.1-11.6,36.4c-7.3-3.2-13.8-7.3-20.7-9.2
    c-1.9-0.7-5.6,2.5-8.8,4.8c9.2,7.5,18,15.9,27,24.1c0.7,3.2,3.4,8.2,7.3,13.3c0,0,0,0,0.7-0.7c3.2-2.5,6.6-5.7,9.2-8.2
    c-1.9,3.2-4.1,6-6.6,9.2c-7.5-0.7-18,1.6-22.1-2.5c-10.7-10.1-24.8-14.9-34.6-24.9C306.5,2252.2,303.8,2243.7,299.3,2235.5z
    M345.3,2245.7c0.7,1,1,1.9,1.5,2.5c0.7,0,1,0,1.5-0.7c-0.7-1-1-1.6-1-1.9C346.2,2245.7,345.6,2245.7,345.3,2245.7z M97.8,1014.9
    c1.5-0.7,3.2-1.6,4.7-1.9c-1-1-1.9-1.6-2.5-2.5c-1,1-2.5,1.9-3.4,3.2C96.8,1013.9,97.1,1014.9,97.8,1014.9z M584.7,761.8
    c0.7-0.7,0.7-1.6,0.7-1.9c-1-0.7-1.5-0.7-2.5-0.7c0,1,0,1.9-0.7,3.4C583,762.1,584,762.1,584.7,761.8z M434.9,875.2l-1,1l1.5,0.7
    C434.9,876.2,434.9,875.8,434.9,875.2z M432.7,843.6c4.1,0,8.2-0.7,12.2-0.7l0,0l0,0l0.7-1c3.4,3.2,6.6,5.7,11.6,10.1
    c1.5-7.3,2.5-11.4,3.2-15.9l0,0c2.5-5.1,5.6-10.1,8.2-15.5c0,0,0,0,0,0.7V821v0.7l1-0.7c13.2,5.1,13.8,4.8,20.7-5.7l1-0.7l-0.7-1.6
    c1-3.4,1-7.3-2.5-9.8l-0.7-1.6l-0.7,0.7c-1.9-4.1-4.1-8.9-5.6-11.4c-14.1,1-26.5,1.9-37.7,2.5c-6.6,5.1-1.5,13.3-8.8,18.3
    c-4.1,3.2-4.1,12.3-6,18.3h-1l0,0l0,0c0,0.7,0,0.7,0.7,1c-1.5,1.6-1.9,3.2-1.9,4.8C428.6,839.5,430.8,841.4,432.7,843.6z
    M503.5,709.6v1.9l1.9-1L503.5,709.6z M583.4,664.1c-0.7,0-1,2.5-1.5,4.1c2.5,0.7,5.1,1,7.5,1c1,0,1.5-1.9,2.5-4.1
    C589,664.5,586.2,664.1,583.4,664.1z M414.5,900.8c0.7-1,1-2.5,0.7-3.4c-0.7-1.6-1.9-2.5-3.2-3.4l0,0c0.7,1.6,1,3.2,1.5,4.8
    C413.8,899.2,413.8,899.9,414.5,900.8z M413.8,893.9l-2.5-0.7h0.7C412.3,893.9,413.5,893.9,413.8,893.9z M432.5,865.1
    c0.7,0,1.5-0.7,1.9-0.7c0-0.7,0-1,0-1.6c0,0,0,0,0-0.7v-0.7c-0.7-0.7-1.5-0.7-1.9-0.7C432.5,862.5,432.5,864.2,432.5,865.1z
    M509.8,680.9L509.8,680.9c0.7-0.7,0.7-0.7,0.7-1l0,0l0,0l0,0l0,0C509.8,679.9,509.1,680.2,509.8,680.9
    C509.1,680.2,509.1,680.9,509.8,680.9L509.8,680.9z M152.8,917.8c-1.5,0.7-2.5,2.5-3.2,4.1c0,1,1.5,1.9,2.5,3.2
    c1.5-1.6,3.2-3.2,5.1-5.1C155.4,918.8,153.2,917.3,152.8,917.8z M526.5,697.6c6,3.2,11.6,7.3,20.4,1c-5.1-1.9-9.7-3.2-12.6-5.7
    c-4.7-3.4-7.5-8.2-11.6-12.3c-4.1-4.8-8.8-1.6-12.6,0c-0.7,3.4-1.5,6.7-1.9,10.8C514.9,693.5,520.8,695,526.5,697.6z M476.1,719.8
    c-1.5-0.7-2.5-0.7-4.1-1c-0.7,0.7-0.7,1.6-1,1.9c1.5,0.7,2.5,1,4.1,1C475.2,722.2,475.5,720.6,476.1,719.8z M490.3,786.7
    c3.2,1,6,1.9,8.8,3.2c0-1.6,0-3.4,0-6c-1.9-0.7-4.7-1.6-6.6-1.6C491.8,782.6,491.3,785.2,490.3,786.7z M528.7,724.8
    c-7.3-2.5-14.1-5.1-19.9-7.3c-26.1,15.9-30.6,17.4-59.1,14.2c5.1,11.6,5.1,22.4,20.4,18.3c2.5-0.7,7.5,1.9,9.2,4.1
    c4.1,7.3,9.7,5.7,14.8,3.4c3.4-1.6,7.3-3.2,9.7-5.7C512.3,743.7,520.5,734.6,528.7,724.8z M445.9,646.4c-1.9,1.9-3.2,6.7-2.5,10.1
    c0,1.6,4.7,4.8,6,4.8c10.7-2.5,17.3,2.5,23.9,9.2c1,1,4.7,0,7.3,0c-3.4-9.2-7.3-19-10.7-28.2c0.7,0,0.7-0.7,1-0.7c-0.7,0-0.7,0-1,0
    l0,0C461.7,641.4,452.5,639.4,445.9,646.4z M509.1,680.2c-7.3-9.2-16.7-9.2-27-9.2C488.7,681.5,496.9,686,509.1,680.2z M311.9,794
    c0,0.7,0.7,1,1,1.6l1.9-1.6C313.5,794,313.2,794,311.9,794z M447.2,1404.9c2.5-0.7,5.1-1.9,11.6-5.1c-7.5-0.7-10.7-1-13.2-0.7
    c-1,0-1.9,2.5-2.5,4.1C444,1404,445.6,1404.9,447.2,1404.9z M383,579.4c0-1,0-1.6-0.7-1.9c-0.7-0.7-1.5-0.7-2.5-0.7
    c-0.7,1-0.7,1.6-1,2.5C380.2,579.4,381.7,579.4,383,579.4z M379.3,556.4l11.6-3.2c-4.1,0-8.2,0-12.2,0
    C379.3,554.4,379.3,555.4,379.3,556.4z M217.2,680.9c-1.5,4.8-1.5,8.2-1,11.4l0.7-0.7c0,0.7,0,0.7,0,0.7l0,0
    c2.5,10.1,13.8,15.5,20.4,23.1c0.7,0.7,3.4,0.7,5.1-0.7c1.5-1.2,3.2-3.4,3.2-4.8c-3.2-10.8,0-23.1-4.1-32.9
    c-4.1-9.9-1.5-18.3-4.1-26.6c-2.5-9.8,0-14.9,5.6-20.5c3.2-3.2,5.6-6.7,8.8-9.8c-8.8-7.5-10.7-0.7-15.4,3.2
    c-5.1,3.4-12.2,4.8-19.5,7.3C215.7,647.7,208.8,664.1,217.2,680.9z M302.5,896.5c4.1,6.7,8.2,12.6,15.4,4.1c5.1-6,8.2,1,12.2,1.6
    c2.5-7.5,4.7-14.9,7.3-23.4c-7.3-1-14.8-2.5-22.9-3.4C317.3,886.9,307.8,890.7,302.5,896.5z M284.5,585.1
    c-14.8,15.9-38.7,8.9-55.4,23.4c12.6,0.7,22.1,1,31.4,1c11.4,0,18.9,3.2,18.9,16.4c0,5.1,7.5,7.3,11.4,3.4c3.2-3.4,6-7.5,7.5-11.6
    c1.9-5.1,2.5-10.8,4.1-16.7C296.2,598.1,285.2,599.3,284.5,585.1z M474.6,506.7h1.9l-1.5-1.9L474.6,506.7z M562.6,797.5
    c22.1-3.2,23.3-4.1,30.2-11.6C574,778.2,574,778.2,562.6,797.5z M513.2,553.8c0-3.4,1-6.7,3.2-9.8l1-1.6l-0.7-1
    c-0.7,0.7-1,1-1.5,1.6C512.3,545.6,511.7,550.3,513.2,553.8z M420.4,593.3c-9.7-2.5-16.3-1.9-22.3,5.1c-5.6,7.3,6.6,9.2,3.4,14.9
    c-5.6,4.8-14.8,3.2-19.5,10.1c2.5,1,4.7,2.5,5.6,1.9c9.7-3.4,19.5-8.2,30.2-12.3C418.9,606.3,420.1,599.9,420.4,593.3z
    M391.5,552.8L391.5,552.8C391.5,553.5,391.5,553.5,391.5,552.8C391.5,553.5,391.5,552.8,391.5,552.8z M392.2,656.8
    c1,1,3.2,2.5,4.7,2.5c5.1-0.7,9.2-5.7,7.5-10.8c-1.9-1-4.1-2.5-6.6-2.5C392.4,646.4,390,652.7,392.2,656.8z M390.9,517.1l22.1-6
    c-11.4-4.1-18.9-6.7-26.1-9.8C382.4,508.3,385.9,513,390.9,517.1z M620.2,1171c1,1.9,3.2,3.4,4.7,5.7c1.5-1.9,2.5-4.1,4.7-6.7
    c-3.2-1.6-5.6-2.5-7.5-3.4C620.8,1167.6,619.2,1170.1,620.2,1171z M970.6,1236.2c2.5-1.6,5.1-3.2,8.2-5.1c-1.9-2.5-3.2-4.1-4.1-5.1
    c-2.5,2.5-5.1,4.8-7.5,7.3C968,1234,969.9,1236.4,970.6,1236.2z M975.6,1268.3c10,0,11.4-1,15.4-19c-3.2,3.4-5.1,5.1-6,6.7
    c-3.4,5.7-6,0.7-9.2-0.7c-5.1-1-7.3,1.9-7.5,6C967.5,1267.5,971.6,1268.3,975.6,1268.3z M1033.8,1283.5c0-0.7-1-1-1-1.6
    c0,1,0,1.9,0.7,3.4C1032.9,1285.2,1033.8,1284.2,1033.8,1283.5z M1032.9,1232.3c-5.1-2.5-7.5-4.8-11.4-5.1c-1.5,0-3.4,3.4-5.1,5.1
    c2.5,1.9,5.1,4.8,8.2,5.7C1026.3,1238.6,1028.8,1235.5,1032.9,1232.3z M1043.8,978.6c-2.5,5.1-1.9,9.2,3.4,10.8
    c1,0.7,4.1-1.6,4.1-2.5C1053,980.8,1048.6,979.8,1043.8,978.6z M1092.9,1139.8c-2.5,3.2-4.1,6.7,1.5,10.8
    c4.7-3.4,9.7-7.5,14.8-11.4c1.5-1,3.4-1,5.1-2.5c7.3-6.7,9.2-20,4.1-29c-1.5-2.5-7.5-2.5-13.8-3.4c0.7,8.9,1.5,15.5,1.9,22.4
    C1102.1,1130.6,1097.3,1134.7,1092.9,1139.8z M1133.2,1139.8l-1.5,1.6c1,0.7,1.9,1.6,3.2,1.9v-1.9
    C1134.2,1141,1133.5,1140.4,1133.2,1139.8z M964.6,1117.4c-1.5,0-3.2,0.7-4.7,0.7c1.5,0.7,3.2,1,4.7,1.9
    C964.6,1118.9,964.6,1118.3,964.6,1117.4z M1006.8,957.8c7.3,1.9,9.7,4.1,11.6,3.2c1.9-0.7,3.2-3.4,5.1-5.7c-3.2-1-5.6-3.4-8.8-3.4
    C1012.7,951.7,1010.8,954.2,1006.8,957.8z M1061.1,1135.7c1.9,0,5.1-3.2,5.6-5.1c0.7-1.9-1.9-5.7-4.1-6.7
    c-8.2-3.4-15.8-3.4-23.9,9.8C1048.9,1134.7,1055.2,1135.7,1061.1,1135.7z M1050.4,1099.3c1,1.6,1.5,3.4,2.5,4.1c1.9,1,5.1,1,10,1.6
    c-4.1-3.4-6-5.1-8.2-6C1054.2,1098.3,1052.1,1099,1050.4,1099.3z M951.7,1165c3.4-7.5-5.6-13.3-10-18.3l-0.7-0.7
    c-1,6,3.2,10.1,6.6,13.9C949.2,1161.3,950.7,1162.8,951.7,1165z M747,1373.6L747,1373.6v-0.7V1373.6z M1006.1,928c-1,0-1,1-1.9,1.9
    c1.5,0.7,2.5,0.7,4.1,1l0.7-1.9C1007.6,928,1006.8,927.4,1006.1,928z M977.8,1068.4c-1.5,0-3.2,1-4.1,1.9c1.5,1.6,2.5,3.2,4.1,4.1
    c0.7,0,2.5-1.9,3.4-2.5C979.7,1070.3,978.7,1068.4,977.8,1068.4z M744.1,1396.7c4.7-3.4,8.8-7.3,12.6-10.1
    c-4.1-5.1-6.6-8.9-9.7-12.3c-1.5,5.1-3.4,10.1-5.1,15.9C741.9,1391,742.9,1393.3,744.1,1396.7z M690,1125.6
    c-3.4,1.6-7.3,3.2-10.7,5.1c4.1-0.7,7.5-1.6,11.6-1.9C690.6,1127.8,690,1126.5,690,1125.6z M616.7,1327.2c1-0.7,1.5-1,1.9-1.9
    c-0.7,0-1.5,0-1.9-0.7C616.7,1325.6,616.7,1326.2,616.7,1327.2z M602.2,1133.8C602.2,1133.8,602.2,1134.4,602.2,1133.8l-10,3.2
    c0,1,0.7,1.6,0.7,1.9C596.3,1137.2,599.5,1135.7,602.2,1133.8z M619.6,1205.8c1,1,3.2,1,4.7,1.6c0-1.6,0.7-3.4,0-4.8
    c-1-1-3.2-1-4.7-1.6C619.2,1202.9,618.6,1204.8,619.6,1205.8z M611.1,1133.8c1.5-5.1,1.9-10.8-4.1-16.4c-1.5,6-3.2,11.4-4.7,16.7
    l0,0C605.4,1137.9,609.5,1138.8,611.1,1133.8z M629.3,1238.1c1-1,3.2-2.5,3.2-3.4c-0.7-1.6-2.5-2.5-3.4-3.4c-1,1.6-3.2,2.5-3.2,4.1
    C625.8,1235.5,627.7,1237.1,629.3,1238.1z M1158.7,1191.6c-0.7,0-0.7,1-1,1.9c1.5-0.7,3.2-1,4.7-1.6
    C1161.2,1191.6,1159.9,1191.6,1158.7,1191.6z M699.8,1238.6v-0.7V1238.6c0.7,0,1,0,1-0.7c6.6,4.8,8.2,4.8,10-3.2c0.7,0,0.7,0,1-0.7
    l0,0l0,0l0,0l-0.7-0.7c4.1,1.9,8.2,5.1,12.2,6c1.9,0.7,5.1-1.6,7.3-3.4c1-1,0.7-4.8,0-5.1c-15.4-9.2-12.6-24.6-12.6-38.2
    c0-4.8,0.7-9.2,1-13.9c-2.5-0.7-5.1-0.7-7.3-1c0,1.6-0.7,3.4-1.5,6c-3.2-1-6.6-1.9-11.4-3.2v-0.7c-1.5,1-2.5,3.2-3.2,5.1
    c-2.5,8.9,3.2,11.6,9.7,14.9c1,0.7,1.5,3.2,1.9,5.1c-5.6,4.8-11.6,9.2-17.3,13.9c1.5,1.9,4.1,4.8,3.4,5.7c-1.9,6,1,8.9,5.6,11.4
    L699.8,1238.6z M990.4,973.2c-1,1-1.5,3.2-1.9,4.1c1.9,0.7,4.1,0.7,6,1c0-1.6,0.7-3.2,0.7-5.1C993.5,973.5,991.3,972.6,990.4,973.2
    z M693.1,1290.8c0,1,0.7,2.5,0.7,3.4c0.7-0.7,1-0.7,1.9-1c0-1,0-1.9-0.7-1.9C695,1290.8,694.1,1291.5,693.1,1290.8z M713.3,1405.6
    c0,1-0.7,1.9,0,2.5c3.2,3.2,6.6,5.7,10,9.2c2.5-6,0-9.2-2.5-12.3c-1.5,0.7-3.4,0.7-6,0.7C714.9,1405.6,714.5,1405.6,713.3,1405.6z
    M740,1376.2c1.5,0.7,3.2,1.6,3.4,1c1-0.7,1.9-1.6,3.2-3.2c-1.5,0.7-3.4,0.7-5.1,0.7C741,1375.2,740.3,1375.9,740,1376.2z
    M691.9,1215c0-1-1.5-1.6-2.5-2.5c0,1,0.7,1.6,0.7,2.5c0,1.6,0,3.4,0.7,5.1v0.7l0,0l0,0C690.9,1218.8,691.9,1217.2,691.9,1215z
    M747,1374.3l0.7-1C747,1373.6,747,1373.6,747,1374.3L747,1374.3z M950.7,1166c-1.9,1-4.7,1.6-7.5,3.2c-1.5-2.5-2.5-5.1-3.4-7.5
    c-1.9-4.8-4.1-8.9-6-12.6c1-1,1.9-2.5,2.5-3.4c-3.4,0.7-7.5,2.5-8.8,6c-1,4.8,0,9.2,0.7,13.9C935.9,1166.9,944.8,1176.1,950.7,1166
    z M813.3,1334.7c-8.2,3.4-13.8,5.7-18.2,7.5C800.1,1341.4,807,1345.2,813.3,1334.7z M832.5,1304.7c1.5-1.6,4.7-1.6,6.6-1.6
    c3.4,0,9.2,2.5,10,1.6c3.4-4.8,0-8.9-3.4-12.3c-4.1-4.1-8.2-8.2-12.6-12.3c-1-1-3.2-1-6.6-1.9c-3.4,5.1-7.3,10.1-9.2,12.6
    c1.5,10.8,3.2,19,4.1,27.2h1c3.2,7.3,5.6,14.2,9.2,23.1c4.7-1.9,9.7-2.5,11.6-5.7c3.4-4.8,4.1-11.4,0-16.4c-3.2-3.4-7.5-6-10.7-9.2
    C832.1,1308.8,832.1,1305,832.5,1304.7z M1010.2,1188.1c5.6-1,11.6-2.5,16.7-5.1c0.7-1,1-1.6,1.5-1.9l0.7,0.7c0,0,0,0.7,0.7,0.7
    c4.1-2.5,8.2-5.7,12.2-10.8c-5.6-1.9-9.7-2.5-12.2-5.1c-11.6-9.2-23.3-7.3-35.5-3.4c0.7,1.6,1,2.5,1.5,4.1
    c1.5,4.1,3.2,7.5,4.1,11.6l0,0c-2.5,0-4.7-1.6-6.6-3.2c-0.7,0-0.7-0.7-1-0.7c-1.9-1.6-3.4-1.9-5.1-1.9c-1.5,0-2.5,0.7-4.1,1
    c0,1.6,0.7,3.4,1.5,5.7c3.2,5.7,7.3,11.4,12.2,14.9C1002,1198.8,1005.2,1189.1,1010.2,1188.1z M809.2,1227c1-1.6,2.5-3.4,2.5-4.8
    c-0.7-1.6-2.5-2.5-4.1-3.2c-1,1.6-2.5,3.2-1.9,4.8C805.1,1224.8,807.6,1225.8,809.2,1227z M808.3,1060.1l-1.9,1l1.5,1.6
    L808.3,1060.1z M1046.4,1266.8c-1,0.7-2.5,1-2.5,1C1044.5,1267.8,1045.5,1267.5,1046.4,1266.8c3.4,3.4,7.5,6,13.2,1.9
    c-0.7-1.9-0.7-5.1-1-7.5c-1-2.5-2.5-4.8-5.6-9.8C1050.4,1257.9,1048.6,1262,1046.4,1266.8z M798.5,1313.2c1.5,3.2,3.2,5.7,5.1,8.9
    c-0.7-3.4-0.7-6.7-1-9.8c0-0.7-1-1.6-1.9-1.9C800.1,1311.4,797.9,1312.9,798.5,1313.2z M1113.1,1296.5c-0.7,1.9-1.5,5.1-0.7,6.7
    c1.9,4.8,5.6,8.2,10.7,6c1-0.7,1.9-4.8,1.5-6.7C1122.8,1298,1119.9,1294.3,1113.1,1296.5z M1145.1,1182.8
    c-55.4-12.6-51-12.3-69.2-33.8c-15.8,4.1-15.8,4.1-11.6,17.4c0.7,1.6-0.7,3.4,0,5.7c-3.2,5.1-12.2,5.7-10.7,15.9
    c12.2-5.1,22.9-9.2,37.1-14.9c9.2,14.2,23.9,16.4,37.7,20C1136,1195.7,1141.7,1191,1145.1,1182.8z M827.5,1178.7
    c-2.5-6.7-5.6-13.3-10-23.4c-1.5,10.1-1.9,16.4-3.2,22.4c-1,6-2.5,12.6-4.1,19.6C829,1194.1,832.1,1190.6,827.5,1178.7z
    M1000.1,1104.6c4.7-9.8,7.5-16.7,12.2-26.3c-9.7,3.2-18,5.7-30.6,10.1C990.1,1095.9,994.5,1099.3,1000.1,1104.6z M441,1271.9v-1.9
    l-1.9,1.6L441,1271.9z M1069,1210.9L1069,1210.9L1069,1210.9C1068.4,1210.9,1068.4,1210.9,1069,1210.9L1069,1210.9z M1065.9,1219.7
    c0.7-3.2,1.5-5.7,2.5-8.2l0,0c0,0-0.7,0-0.7,0.7c-0.7,0.7-1,1-1.5,1.6c-1,0.7-1.9,0.7-3.2,0.7c1.5-0.7,3.2-1,4.7-1.9l0.7-0.7l0,0
    c-1-10.1-8.8-5.1-13.2-5.1c-11.6-0.7-18.9,1-21.4,3.4c0.7,1.6,0.7,3.2,1.5,4.8c0,0.7-0.7,1-1.5,1.6c3.2,8.2,12.2,9.8,19.5,12.3
    C1058.6,1229.5,1064.3,1227,1065.9,1219.7z M1086.6,1209.9c2.5,0.7,5.6-3.2,11.4-6c-4.7-3.2-7.5-5.1-10.7-7.3
    c-1.9,1.6-3.4,1.9-4.1,1.9c0,0-0.7,0-1-0.7c-1,1.6-1.5,2.5-1.5,3.4C1081.7,1204.8,1084.1,1209.6,1086.6,1209.9z M1157.1,1079.1
    c-0.7,0.7-1.5,1-1.5,1.6c0,0.7,1,1.6,1.5,1.9c0.7-0.7,1.5-1,1.9-1.6C1158,1080.7,1157.4,1079.3,1157.1,1079.1z M914,1095.5
    c-0.7,0-1.9,0.7-1.9,1c-0.7,0.7,0,1.6,0,2.5c1-0.7,1.9-1,3.4-1.6L914,1095.5z M858.9,1372.6c1.5-1.9,3.4-4.8,4.7-7.3
    c0-1-2.5-4.1-3.4-3.4c-4.7,0.7-6,3.4-5.6,7.5C855.2,1371.1,857.1,1371.8,858.9,1372.6z M901.7,1185.3c-1,0-2.5,0.7-4.1,0.7
    c0.7,0.7,1.5,1,1.9,1C900.7,1186.9,901.4,1185.9,901.7,1185.3z M971.2,1052.2c2.5,0.7,5.1,1,7.5,0.7c1,0,1.9-1.9,3.2-3.4
    c0-0.7-1-1.9-1.5-1.9c-1,0-1.9,0-3.2,0c-0.7,0.7-0.7,0.7-1,0.7c-0.7,0-0.7,0-1-0.7c-1.5,0-3.4,0-5.1,0
    C970.6,1048.8,970.6,1051,971.2,1052.2z M902.9,1151.2c-1.9,1.9-4.7,4.1-6.6,5.7l1-0.7l0.7-0.7v0.7l6.6-1.9
    C903.9,1153.1,903.3,1152.1,902.9,1151.2z M941.7,1088.2c-8.8-13.9-8.8-13.9-1.5-34.8c7.3-3.2,13.8-6.7,21.4-8.9
    c1.9-0.7,5.1,1.6,7.5,2.5c-2.5,2.5-1,4.1,1.5,5.7c0,1,0.7,1.9,0.7,2.5c-11.6,19.6-15.8,39.8-13.2,62.6c-5.6,0-11.4-1-16.7,0
    c-4.1,0.7-7.5,3.4-12.6,6.7C924.3,1106.6,936.9,1099,941.7,1088.2z M941.7,1092.7c0.7,0,1,0.7,2.5,0.7l0,0c1.5,0,3.2,0,4.7-0.7
    c-1.9-0.7-4.1-1-6-1.9C942,1091.4,942,1092.3,941.7,1092.7z M658.6,1203.3c0.7,0.7,1,1,1.9,1.6c4.7,1.6,11.4-1.6,11.4-5.1
    c0-1.6-2.5-4.8-3.2-4.1c-3.2,1.6-6,3.4-9.2,5.1C658.6,1200.7,658.6,1202.4,658.6,1203.3z M825.6,1244.7c-5.1,1.9-10,4.1-15.4,6
    c6.6,3.2,13.2,6,21.4,9.8C832.5,1251.9,833.1,1246.9,825.6,1244.7z M844.8,1119.8c1.9-1.6,3.2-4.1,6.6-7.5c-5.1,0.7-8.2,0.7-10,1.6
    c-1,0.7-1.9,4.1-1,5.1C840.6,1119.5,843.8,1120.5,844.8,1119.8z M848.9,1159.9c-3.2-7.3-5.6-12.6-8.2-19
    C840.6,1147.6,833.8,1156.8,848.9,1159.9z M843.2,1224.4c-7.5,1.6-14.8,3.2-24.8,5.1c6-7.3,9.2-11.6,13.2-15.5
    c8.2-7.3,17.3-8.9,27-3.2c9.2,5.1,18,10.8,26.5,15.5c4.7-3.4,7.5-5.7,10-8.2c1.5,0,2.5,0,3.4,0.7c6,3.2,11.6,6.7,18,10.1
    c-1.5,3.4-1.5,7.5-3.4,8.9c-9.2,4.8-15.4,10.8-14.1,24.1c-13.2-4.1-26.5-1-33.6-17.4C860.5,1236.2,851.1,1231.1,843.2,1224.4z
    M838.7,1213.7c-0.7-1-1-1.9-1.5-3.4h-0.7c-1,0.7-1.9,0.7-2.5,1c0,1,1,2.5,1.5,3.2l0,0C836.5,1214.7,837.5,1214,838.7,1213.7z
    M857.6,1222.2l-0.7,0.7C857.1,1222.9,857.1,1222.9,857.6,1222.2C857.1,1222.9,857.6,1222.2,857.6,1222.2z M883.1,1208.4
    c0.7-0.7,0.7-1.6,1-1.9c-0.7-0.7-1-1-1.5-1.6c-0.7,0.7-1,1.6-1,1.9C881.9,1207.4,882.5,1208,883.1,1208.4z M873.7,1167.9
    c1,0.7,3.2,1.9,3.4,1.6c1.9-1,3.4-2.5,5.6-3.4c-2.5,0-5.1,0.7-7.5,0.7C874.9,1166.6,874.4,1167.6,873.7,1167.9z M892.2,1170.1
    c1.9-1.6,1.9-6,2.5-9.8c0-0.7,0-1-0.7-1.9c-3.4,1.9-6.6,4.1-10,6.7C886,1168.5,888.2,1172.7,892.2,1170.1z M1112.1,1205.8
    c0.7,1,1.5,1.9,1.9,3.4c0-0.7-0.7-1.6-0.7-1.9C1113.6,1206.7,1113.1,1206.5,1112.1,1205.8z M525.5,1070.8
    c-17.3-0.7-17.3-0.7-27,5.1C508.6,1081.9,515.8,1076.9,525.5,1070.8z M451.5,1217.5c-0.7,0-1-0.7-1.5-0.7l-0.7,0.7v-0.7
    c-0.7,0-0.7,0-1-0.7c0,0.7,0.7,1,0.7,1l0,0C450,1218.1,450.7,1218.1,451.5,1217.5z M455.1,1217.2c-1.5,0-2.5,0.7-4.1,0.7
    c1,0.7,1.9,1,2.5,1C454.1,1219.1,455.1,1217.5,455.1,1217.2z M495.3,1109.1c-1,0.7-1.5,2.5-2.5,4.1c1.5,0.7,3.2,1.9,4.1,1.6
    c1-1,1.9-2.5,2.5-4.1C497.9,1109.7,495.9,1108.8,495.3,1109.1z M522,1089.8c-1-0.7-3.2-1.6-3.4-1c-1,0.7-1.9,2.5-2.5,4.1
    c1,0,1.5,0.7,2.5,0.7C519.2,1091.8,520.8,1090.8,522,1089.8z M522.4,1106.6c-0.7,0-1.5,0.7-2.5,0.7c0.7,1,1,1.9,1.5,2.5
    c0.7-0.7,1-1,1.9-1.6C523,1107.5,523,1106.6,522.4,1106.6z M524.6,1203.3c0-0.7-1-1-1.5-1.6c0,1,0.7,1.9,0.7,3.2
    C523.9,1204.3,524.6,1203.9,524.6,1203.3z M418.9,1712.2l0.7-1.9l-2.5,0.7L418.9,1712.2z M320.2,1780.1c0,0.7,0.7,1.6,1,1.9
    c1,0,1.5,0,2.5-0.7c-0.7-1-1.5-1.9-1.9-3.2C321.4,1779.1,320.2,1779.5,320.2,1780.1z M311,1727.2l-1.5-0.7v1.9L311,1727.2z
    M439,1209.9c1,1.6,1.5,3.4,2.5,4.1c1.9,1,4.7,1.6,7.3,2.5c-1-2.5-1.5-5.1-3.2-6.7C444.4,1209.6,441.5,1209.9,439,1209.9z
    M420.4,1752c0-1,0-1.6,0-3.2c-0.7,1-1.5,1.6-1.5,1.9C418.9,1751,420.1,1751.3,420.4,1752z M362.6,1235.2c0,0-0.7-1.6-1-3.2l0,0
    l0,0C362,1234,362.6,1235.2,362.6,1235.2z M396.3,1651.8c0.7,0.7,1,1.6,1.9,1.6c0.7,0,1-1,1.5-1.6c-0.7-0.7-1-1-1.5-1.9
    C397.2,1650.2,396.5,1650.5,396.3,1651.8z M349.7,1194.1c0.7-1,1.5-1.9,2.5-3.2c-1,0-1.5-0.7-2.5-0.7c-0.7,0.7-1.5,1-1.5,1.9
    C348.7,1193.2,349.4,1193.5,349.7,1194.1z M321.7,1166.6c-0.7-0.7-1.5-0.7-2.5-0.7c0,1,0,2.5,0.7,3.4l1.9-0.7
    C321.7,1167.9,322.4,1166.9,321.7,1166.6z M364.5,1730.5c-1,0-1.9,0-2.5,0c0,1.6-0.7,3.2-0.7,4.8c1.9-1.6,4.1-3.2,6-4.8
    C366.7,1730.5,365.4,1730.5,364.5,1730.5z M361,1736.4c0-0.7,0-1,0-1.6l-0.7,0.7L361,1736.4z M375.8,1688.5
    c-15.8,3.2-2.5,16.4-9.2,24.1c-1.5,1.9-2.5,4.8-3.2,7.3c1-0.7,1.5-1,1.9-1.6c1.5-2.5,1.9-5.7,3.2-8.2c3.2,5.1,5.6,9.8,6,14.9
    c4.7,1,8.8,1.6,13.8,2.5C395,1710.5,383,1700.1,375.8,1688.5z M473.6,1408.1c0.7,0.7,1.5,0.7,1.9,1c0.7-0.7,1-1,1.5-1.6
    c-0.7,0-1.5-1-1.9-0.7C473.9,1407.1,473.6,1407.5,473.6,1408.1z M402.2,1314.6c0.7-1,1.5-2.5,1.9-3.4c-1.5-0.7-3.2-1-4.7-1.6
    c0,0-1,1.9-0.7,1.9C400.4,1312.3,401.2,1313.2,402.2,1314.6z M360.1,1736.1c-1.5-0.7-2.5-1.6-4.1-1.6c-1,0-1.9,0.7-3.2,1
    c0,0,0,0.7-0.7,0.7c1,1,2.5,2.5,3.4,2.5C357.6,1738,358.8,1737.1,360.1,1736.1z M334,1244.4L334,1244.4c-0.7-0.7-0.7-0.7-0.7-0.7
    c-0.7,0-0.7-0.7-1-0.7v1.6L334,1244.4z M413.5,1326.5c-1,0-1.5,1-1.9,1.6c0.7,0.7,1,1.6,1.5,1.9c0.7-0.7,1.5-1,1.9-1.6
    C414.5,1327.8,413.8,1326.2,413.5,1326.5z M382.4,1666.9c-0.7,0.7,0.7,1.9,0.7,1.9c1.5,0,3.2-0.7,4.7-1c-0.7-1.6-1-2.5-1.9-4.1
    C384.3,1665.1,383.4,1665.9,382.4,1666.9z M344,1695.7l0.7-1.9h-2.5L344,1695.7z M361,1735.6l-0.7,0.7c0.7,0,0.7,0.7,1,0.7
    L361,1735.6z M364.5,1279.1l-1.9,1.6h2.5L364.5,1279.1z M341.2,1265.2c0,1-0.7,1.9-0.7,3.2c1,0,1.5,0,2.5-0.7
    c0.7-0.7,0-1.6,0.7-1.9C342.8,1265.9,341.6,1265.2,341.2,1265.2z M319.8,1706.7c0.7,0,1.5-0.7,2.5-0.7c0-0.7,0-1.6,0-1.9
    c-0.7-0.7-1.5-0.7-2.5-0.7C319.8,1704.9,319.8,1705.9,319.8,1706.7z M650.3,1347c0.7,0,1.5-1,1.9-1.6c-1-1-1.5-1.9-2.5-3.2
    c-0.7,1-1,2.5-1.5,3.4C648.8,1346.8,649.1,1347,650.3,1347z M639.1,1077.5c1.5,3.2,2.5,6,4.1,8.9c0-1,0.7-1.9,1.5-3.4l1.9-3.2
    l-3.4-0.9C641.6,1077.8,640.6,1077.5,639.1,1077.5z M761.7,1058.5c-1.5-0.7-3.2-1-4.7-0.7c-1,0-1.9,1.6-3.4,2.5
    c1.5,0.7,3.2,1.6,4.1,1C759.9,1061.1,760.8,1059.5,761.7,1058.5z M612.1,1157.2c0.7,0,0-1.6,0-1.9c-0.7,0-1.5,0-1.9,0s0,1.6,0,1.9
    C610.4,1157.2,611.7,1157.2,612.1,1157.2z M425.2,1764.6c-0.7,0-1.5-0.7-1.9-0.7l0,0c0,0.7-0.7,0.7-0.7,1c0.7,0,0.7,0,1,0v-0.7v0.7
    C424.2,1765.3,424.5,1765.3,425.2,1764.6z M740,1086.7c1.9-1,2.5-5.1,2.5-7.5c0-7.3-6.6-13.3-14.8-11.6c-1,3.4-1.9,7.5-3.4,13.3
    c1.5-0.7,3.2-0.7,4.7-1c0.7,1,0.7,1.6,0.7,1.9c0,0-0.7,0-1.5,0l0,0c-0.7,0-0.7,0-1,0c-1,0-1.9,0-2.5-0.7c-0.7,3.2-1.5,6-2.5,10.1
    C731.9,1089.2,736.3,1088.5,740,1086.7z M727.1,1251.1c-3.4,9.8,1.9,16.7,4.7,23.4c12.6,0.7,24.8,1,38.1,1
    c5.6-4.8,12.2-9.8,20.4-15.9c-9.7-7.3-19.5-15.9-28.6-11.4C750.1,1254.5,739.4,1251.9,727.1,1251.1z M725.2,1138.5
    c0.7-1.9,0.7-4.1-0.7-6.7c4.1-5.1,8.8-8.2,13.8-9.2c-0.7,0.7-1,1-1.9,1.6c-2.5,1.9-6,4.1-6,8.2c0,1.9,0.7,4.1,1.5,6
    c1.9-0.7,3.4-1,5.6-1.6c3.2-7.5,9.7-13.9,7.3-23.1c-1-2.5-2.5-4.8-4.1-7.3c-6.6,3.2-12.6,5.7-18.2,8.2c-1,7.3-1.9,14.2-2.5,21.5
    C721.2,1136.3,723,1137.9,725.2,1138.5z M687.5,1342.6c-0.7-0.7-1.5-1-1.9-1c-0.3,0-1,0.7-1.5,1c0.7,0,1,0.7,1.9,1
    C686,1342.9,686.5,1342.9,687.5,1342.6z M659.8,1262l1,1.9l0.7-1.9H659.8z M670.5,1127.1c-1.5,1.6-1.9,6.7-0.7,7.5
    c4.7,4.1,6-1.6,8.8-4.1c0,0,0,0-0.7,0C676.8,1127.8,675.3,1123,670.5,1127.1z M652.9,1227.3c-0.7-1.6-1.9-1.9-3.2-3.4
    c-1,1-2.5,2.5-2.5,3.4c0.7,1.6,1.9,2.5,3.4,3.4C651.7,1229.9,653.2,1228.2,652.9,1227.3z M656.4,1319.9l-0.7,5.1
    c1.5,0.7,3.2,0.7,5.1,1c0-1.6,0.7-3.4,0-5.1C659.8,1319.9,657.9,1319.9,656.4,1319.9z M682.4,1385.6c-0.7,0-1.5,0-1.9,0s0,1,0,1.9
    c1,0,1.5,0,1.9,0C682.8,1387.5,682.4,1386,682.4,1385.6z M652.3,1180.2v1.9l1.5-1L652.3,1180.2z M652.3,1110.7
    c0-0.7-4.7-0.7-6.6-0.7c0,3.2,0,6,0,9.8c0,0.7,3.2,0.7,6.6,0.7C652.3,1117.4,652.3,1114.2,652.3,1110.7z M616.7,1267.5
    c-1-1-2.5-3.2-3.4-3.2c-1,0-2.5,1.9-3.4,3.2c1.5,1,2.5,2.5,4.1,2.5C614.3,1270.3,615.8,1268.3,616.7,1267.5z M553.5,1169.5
    c-1-0.7-3.2-1.6-3.4-1.6c-1.9,1-3.4,3.2-5.6,4.8c2.5-0.7,4.7-1,7.3-1.6C552.6,1171,552.9,1170.1,553.5,1169.5z M544.4,1173.2
    L544.4,1173.2L544.4,1173.2C544.4,1173.2,544.4,1173.6,544.4,1173.2c1.5,3.2,1.9,6,3.2,8.9c0.7-0.7,1.5-1,1.9-1
    C547.9,1178.3,546,1175.8,544.4,1173.2L544.4,1173.2L544.4,1173.2z M566.7,1423.3c-0.7-0.7-1-1.6-1.5-1.9c0,1-0.7,1.9-0.7,3.2
    C565.7,1424.5,566.1,1423.9,566.7,1423.3z M560.8,1244.7c-1,0-3.4,1.6-4.1,2.5c-1,3.2,3.4,6.7,7.3,4.8c1.5-0.7,1.9-2.5,3.4-4.8
    C564.8,1246.9,562.6,1245.3,560.8,1244.7z M427.7,1764.3c-1,0-1.9,0.7-3.2,1c1,0.7,2.5,0.7,3.4,1
    C428.6,1765.3,428.3,1764.3,427.7,1764.3z M560.1,1062.9h-0.7C560.1,1062.9,560.1,1062.9,560.1,1062.9L560.1,1062.9L560.1,1062.9z
    M556,1213.7c1.5,0.7,3.4,0,5.1,0c-1.5-1.6-2.5-3.4-4.1-4.8c-1-0.7-3.2,0.7-4.7,1C554.1,1211.5,555.1,1213,556,1213.7z
    M762.6,1083.2c0.7,0.7,1,1,1.9,1.9c1-1,1.5-1.9,2.5-3.4c-1,0-1.5,0-2.5-0.7C764.3,1081.9,763.3,1082.6,762.6,1083.2z
    M1863.9,878.4c0,0.7,0.7,0.7,0.7,1c8.2,5.1,14.1,8.2,21.4,9.2c-3.2-1.9-5.6-3.4-8.8-5.1C1873.6,881.8,1870.2,880.9,1863.9,878.4z
    M729.6,1316.4c1,1,1.9,1.6,3.2,2.5l2.5-3.4c-1.5-0.7-3.2-0.7-4.1-0.7C730.3,1314.6,729.6,1315.8,729.6,1316.4z M711.4,1383.4
    c0.7,1,0.7,1.6,1,1.9c3.2-1,5.6-1.9,8.8-3.2c0-0.7,0-0.7,0-1c0,0,0-0.7-0.7-0.7L711.4,1383.4z M746.6,1335.4
    c-0.7-1-4.1-2.5-4.7-1.9c-2.5,1.9-4.7,5.1-7.3,7.3c1.9,1.9,3.2,3.2,3.2,3.2c2.5-1,5.1-1.9,7.5-4.1
    C746.6,1339.5,747,1336.3,746.6,1335.4z M707.9,1269.3c0.7,0.7,2.5-3.4,5.1-7.5c-5.1-1.9-9.2-3.4-12.6-4.8c-0.7,2.5-1.9,6-1,7.3
    C701.3,1266.8,704.8,1268.3,707.9,1269.3z M747,1291.5c1.5,0,3.2-1.6,4.7-1.9c-1-1-1.9-3.2-3.2-3.2c-1.5,0-3.2,1.6-4.7,1.9
    C745.1,1289.3,746,1291.5,747,1291.5z M243,1375.1c-0.7,0-1.5-0.7-1.9-0.7c-0.7,0-0.7,1-0.7,1.9c0.7,0,1.5,0.7,1.9,0.7
    C242.4,1376.1,242.4,1375.5,243,1375.1z M221.6,1071.4c3.4-1.6,7.3-5.7,9.2-9.8c1.5-2.5-0.7-7.3-0.7-13.3
    c-5.6,1.6-8.2,2.5-11.4,3.2c-5.1,1-8.8,3.4-8.8,9.2C211,1066.1,218.1,1073.1,221.6,1071.4z M452.2,507.9c-0.7,0.7-1.5,0.7-1.5,1
    c0,1,0.7,1.6,1.5,2.5C452.2,509.8,452.2,508.9,452.2,507.9z M285.1,1101.1c1,0,1.5-0.7,3.2-1.6c-1.9,0-3.2,0-4.7,0
    C284.9,1100.2,285.1,1101.1,285.1,1101.1z M258.7,1219.4c0-0.7-1-1.6-1.5-1.9l-2.5,1c0.7,1,1.5,1.9,1.9,3.2
    C257.8,1220.6,258.7,1219.9,258.7,1219.4z M197.7,1069.5c0.7,0.7,1.5,1,1.9,1c0.3,0,1.9-1,1.5-1c-0.7-1-1.5-1.9-1.9-3.4
    C198.6,1067,198.1,1068.6,197.7,1069.5z M1832.9,1305.3l-1,0.7c0,0-1.5,6.7-1.9,9.2c0,0.7,0,0.7,0,1c3.2-3.2,4.7-6.7,5.1-10.8
    C1833.9,1305,1833.3,1305.3,1832.9,1305.3z M-85.6,923.9c-2.5-1.6-5.6-3.4-8.8-4.8c-0.7,0-3.2,1.9-3.4,3.2c0,1.6,0.7,3.2,1.5,4.8
    c3.4-0.7,6.6-1,9.7-1.6C-86.2,924.8-85.6,924.5-85.6,923.9z M-90.6,950.4c4.1-0.7,9.2-1.6,8.2-9.2c-4.7,2.5-9.2,4.8-13.8,7.3
    c0,0,0,0-0.7,0C-96,950.1-94.4,951.1-90.6,950.4z M1815.6,1242.5l-0.7,1c-1,1.9-1.9,3.4-3.2,5.7v0.7c5.1,1,10.7,1.6,13.2-5.1
    c0,0,0-0.7,0-1c-1-0.7-2.5-1-4.1-1.6C1819.1,1242.5,1816.9,1242.5,1815.6,1242.5z M153.9,1113.2c16.7,1.6,16.7,1.6,15.4-20
    C154.9,1095.5,161.6,1107.4,153.9,1113.2z M183.8,1056.2c0,0.7,1,1,1.5,1.6c0.7-0.7,0.7-1.6,1-1.9c-0.7-0.7-1-1-1.9-1.6
    C184.5,1055.3,183.8,1055.7,183.8,1056.2z M1811,1251.3c-1.5,2.5-3.2,5.7-4.7,8.2l-1,1.6l0.7,0.7v1
    C1808.4,1260.8,1810,1256.3,1811,1251.3z M1875.1,1311.6c-1.5,0.7-3.4,0.7-5.1,1c-1,0-1.9,0.7-3.2,0.7c1.5-3.4,2.5-6.7,4.1-9.2
    c3.2,0.7,6,1,9.2,1.6c1-1.6,1.9-2.5,3.2-4.8c-5.1-1-9.2-1.6-14.1-1.9c-1.9,6-3.4,11.4-5.6,17.4
    C1869.4,1314.5,1872.6,1313.5,1875.1,1311.6z M1889.9,1263.3c-0.7,0-1.5,0-1.9-0.7c-0.7,0.7-1,1-1,1.6c0,0.7,1,1,1.5,1.6
    C1888.3,1265.5,1889.3,1264.5,1889.9,1263.3z M1873.2,1287c1,0,1.5-2.5,1.9-4.1c-1.5-0.7-3.4-1-5.1-1.6c-0.7,1-1.5,2.5-1.9,3.4
    C1869.4,1286,1871.7,1287,1873.2,1287z M271.7,1034.2l0.7,1.9l1.9-1L271.7,1034.2z M328,484h1c0,0,0,0,0-0.7
    C328.3,483.3,328.3,483.3,328,484z M341.6,435.6c-1.5-1-6-0.7-7.3,0.7c-2.5,3.4-4.1,7.5-6,12.3c7.5,1.9,12.6,0,14.8-6
    C344,440.3,343.1,436.9,341.6,435.6z M352.2,564.6c1.5,1.6,3.2,4.8,5.1,4.8s4.1-2.5,7.5-4.8c-4.1-1.6-6.6-3.2-8.8-3.4
    C355.4,561,353.8,563.2,352.2,564.6z M343.1,615.8c-4.1,10.1-15.8,8.9-16.7,20C344,628.1,344,628.1,343.1,615.8z M371.1,627.8
    c-0.7-0.7-1.5-1.6-1.9-1.9c-1,1-1.9,2.5-2.5,3.4c0,0,1.5,1,1.9,1.6C369.5,630.3,370.1,628.6,371.1,627.8z M361,709.6
    c5.1-7.5,8.2-15.9,14.1-21.5c10.7-9.2,25.5-12.6,37.1-21.5c4.7-3.4,14.8-0.7,22.3-0.7c2.5,4.8,5.1,8.9,7.3,12.6
    c-6,7.5-12.2-1.6-18.2,0.7c-9.7,7.3-19.5,14.2-29.6,20.8c3.2,1.9,6,5.7,8.2,5.7c7.3-1.6,14.1-4.8,21.4-7.3
    c2.5,12.6,5.1,23.4,8.2,37.2c-4.1,5.7-9.2,13.3-14.8,21.5c-4.1-6.7-6.6-11.6-9.7-16.7h0.7c4.1,0,7.3-1.9,10-5.7
    c-5.6,1-7.5,1.6-11.4,4.8c0-0.7-0.7-0.7-0.7-1c-2.5,2.5-5.1,4.8-7.3,6.7c-10-4.1-2.5-11.4-6.6-17.4c-1-0.7-3.4-3.4-5.6-3.4
    C374.2,726.4,368.3,718.1,361,709.6z M399,722.9c0.7,0,1-0.7,1-0.7c2.5-1,4.7-1.9,6-3.4c1-1,1.5-1.9,1.5-3.2c0-1-0.7-1.6-1.5-2.5
    c-1-1-1.9-1.6-3.2-1.6c-0.7,0-1.5,0-3.2,1.9C396.5,717.9,397.2,720.3,399,722.9z M314.1,660c6.6,18.1,22.1,20.5,36.2,23.4
    c4.7,1,10-2.5,15.4-4.1c-5.1-11.4-9.2-20.8-13.8-30.7c-7.3,1.6-13.8,5.1-19.5,4.1C324.8,651.1,319.8,652.7,314.1,660z M32,931.1
    c0.7-0.7,1.5-1.6,1.9-1.9c-0.7,0-1.5,0-1.9,0C32.4,929.9,32,930.5,32,931.1z M-31.2,855.3c-1-0.7-1.5-1-2.5-1.6
    c0,1,0.7,2.5,0.7,3.4C-32.2,856.9-31.8,856.2-31.2,855.3C-30.6,855.3-30.6,855.3-31.2,855.3z M-39.3,893.9c8.8,1,15.4,2.5,22.3,3.4
    c1.9-6.7,4.1-13.3,6-20.5c-6.6,1-12.2,1.9-18.2,2.5c-1.5-7.5-2.5-14.9-3.4-22.2c-4.7,6-9.7,11.6-14.1,17.4
    C-43.8,883.2-41.2,889.1-39.3,893.9z M-23.7,853.8c1.5-3.4-1-8.9-1.9-12.6c0-0.7-4.7-0.7-5.1,0c-1.5,3.2-3.2,6.7-3.2,10.1
    c-0.7,1,1.9,2.5,3.2,4.1C-28.1,856.9-24.9,857.5-23.7,853.8z M360.4,479.9L360.4,479.9C360.4,479.9,361,479.9,360.4,479.9
    C361,479.9,360.4,479.9,360.4,479.9L360.4,479.9z M361.6,480.1L361.6,480.1l-0.7-0.7C361,479.9,361,479.9,361.6,480.1l-1-0.7
    L361.6,480.1z M108.5,924.5c-1,0-1.9,2.5-3.2,3.4c1,0.7,1.5,1,2.5,1.6c1.5-1,3.2-1.9,4.7-3.4C111,925.5,110,924.8,108.5,924.5z
    M-50.4,1636.9l1.9,1.6l0.7-1.9L-50.4,1636.9z M201.8,1011.7c-1.5,1-1.9,4.8-3.2,6.7c2.5,0,5.6,0.7,7.3,0c1.9-1,3.2-4.1,5.1-7.5
    C206.8,1010.8,203.7,1010.1,201.8,1011.7z M249.6,986.1c-1,0.7-1.9,0.7-3.2,1l0,0l0,0c0,0,0,0-0.7,0c0,0.7,0,0.7,0,1l0,0l0,0l0,0
    l0,0C247.7,987.7,248.7,987,249.6,986.1C250.3,986.1,250.3,986.1,249.6,986.1z M237.3,1664c1.5,0.7,3.4,0.7,5.1,1
    c0-0.7,0-1.6,0-2.5c-1.5-0.7-3.4-0.7-5.1-1C237.3,1662.8,237,1664,237.3,1664z M233.3,1576.2c0-0.7,0-1,0-1.9c-0.7,0-1.5,0-1.9,0
    c0,0.7,0,1.6,0.7,1.9C231.7,1576.2,232.3,1576.2,233.3,1576.2z M243,983.6c0-1.6,0.7-2.5,1-3.2c0.7-0.7,1.5-1,2.5-1
    c0-1.6,0-3.4,0.7-6c-4.7,4.1-7.3,5.7-9.2,7.5c1.9,1.6,4.1,3.4,6.6,5.1c0.7,0.7,1.5,0.7,1.9,1c0-0.7,0-1.6,0-1.9
    C244.8,984.5,244,984.2,243,983.6z M260.3,1289.5c0-1,0-1.9,0-2.5c-1.5,0-3.4,0-5.1,0.7c0,0,0,1.6,0,2.5
    C257.2,1290.1,258.7,1289.5,260.3,1289.5z M515.4,1230.4c-1,4.1-5.6,3.4-10.7,0.7C507.3,1239.6,513.2,1239.6,515.4,1230.4z
    M941,64.5l3.4-1c-0.7,0-1.5-0.7-1.9-0.7C942,63.3,941.7,64.2,941,64.5z M980.6,259.2c-16.7,4.8-34,4.8-52.8,16.4
    C949.8,271.8,966.5,268.7,980.6,259.2z M462.2,553.5l-0.7-0.7c0.7,0,0.7,0,1,0C462.9,552.8,462.9,552.8,462.2,553.5
    c0.7-0.7,0.7-0.7,0.7-1L462.2,553.5c3.4-1.6,7.3-5.1,10-4.1c6.6,1.9,14.1,4.1,18.9,10.8c3.4,5.1,7.3,10.1,12.2,17.4
    c-10.7,0-22.1-4.8-32.1,5.1c0.7,4.1-0.7,9.8,1.5,14.9c3.4,10.8,3.2,20.5-3.4,29c-1,1-2.5,1.6-3.4,2.5c-8.2-2.5-16.7-5.1-25.5-7.5
    c6-13.9,5.6-27.5-11.4-39.5c8.2-5.7,15.8-10.8,22.9-16.4C455.7,562,458.8,557.6,462.2,553.5z M459.2,584.8
    c5.1-1.9,8.8-4.1,12.2-6.7C467.3,579.4,463.2,581.6,459.2,584.8z M975.3,180.8c1.9,4.1,4.1,8.2,7.3,11.4c1.5,1.6,5.6,1,7.5,0
    s2.5-4.8,4.7-8.2c-5.6-1.9-10-4.8-15.4-6C978.1,177.4,975.3,180.3,975.3,180.8z M966.5,233.9c-1.5-0.7-3.2-0.7-5.6-1
    c-0.7,1.9-1.5,4.8-1.9,7.3c1.5,0.7,4.1,1.6,4.7,1C964.6,239.4,965.5,236.5,966.5,233.9z M1032.9,275.9c0,1.9,4.7,5.7,7.5,6
    c1.9,0,6-4.8,6-7.3c0-5.1-2.5-9.8-4.1-15.5C1032.9,262.1,1032.9,269.9,1032.9,275.9z M910.4,110.3c-1,3.4-4.7,6-6,9.8
    c-1.9,6,0.7,13.3,7.3,17.4c5.6,3.4,10.7,1.9,15.4-2.5c8.2-9.2,16.3-18.1,26.1-28.2c-7.3-4.8-14.1-8.9-21.4-12.6l-19.9,5.7
    C911.4,103.1,912.1,107.2,910.4,110.3z M979.4,162.5l0.7,1.6l1.5-1L979.4,162.5z M768.4,271.6c-1.9-1.9-3.4-3.4-5.6-5.1
    c-1,1.9-1.9,4.8-3.2,6.7c1.5,0.7,3.2,1,4.7,1.6C765.8,273.5,766.7,272.5,768.4,271.6z M745.4,274.4l0.7-2.5l-1.9,0.7L745.4,274.4z
    M773,382.5c1.5-1,3.2-3.4,2.5-4.8c-1-2.5-3.2-4.8-4.7-6.7c-1,1-1.9,2.5-3.2,3.4c0,2.5,0,5.1,0.7,7.3
    C768.4,383.1,771.5,383.5,773,382.5z M792.9,86.7c-1,0-1.9-0.7-2.5-0.7c-1,0-1.5,1-1.9,1.6c1,0.7,1.9,0.7,2.5,0.7
    C792,88.3,792.2,87.3,792.9,86.7z M777.2,189.4c0-0.7,1-1.6,1-1.9c0-0.7-1-1-1.5-1.6c-0.7,0.7-1,1.6-1,1.9
    C775.5,188.5,776.5,189,777.2,189.4z M808.3,57.6c14.1-1,14.1-1,22.9-23.4C820.2,39.6,820.2,39.6,808.3,57.6z M868.3,335.5
    c5.1,1.9,9.7,4.1,16.3,3.2c1.9-0.7,6.6,5.7,7.5,9.8c0.7,1.6-4.7,5.1-6,6.7c-11.4,3.2-18.9,0.7-23.9-7.3c-1.5-1.9-1.5-5.7,0-7.5
    C862.7,337.3,866.8,334.8,868.3,335.5z M886.6,351.9C887.2,351.9,887.2,351.9,886.6,351.9c1,0,1,0,1.5,0
    C888.2,351.9,887.2,351.9,886.6,351.9z M960.9,503.3L960.9,503.3C960.9,503.3,960.5,503.3,960.9,503.3z M838.7,1008
    c0,1,0,2.5,0.7,4.1l1.9-0.7c0-1,0-1.9-0.7-2.5C840.4,1008.3,839.1,1008.3,838.7,1008z M1041.1,207.4c-0.7-0.7-0.7-1-1-1.9
    c-0.7,0.7-1.5,1-1.9,1c0.7,0.7,1,1.6,1,1.6C1039.4,208,1040.4,207.4,1041.1,207.4z M849.8,978.6c-1.9-1-6,0-7.5,1
    c-1.9,1.6-4.1,5.7-3.2,6.7c3.2,5.7,7.3,10.1,11.4,15.5C858.9,991.9,858.6,983.4,849.8,978.6z M801,927.4c1.5-1.6,3.4-3.4,5.1-5.1
    c-1-1-2.5-1.9-4.7-3.4c-1,2.5-1.9,5.1-2.5,7.3C798.8,926.4,801,927.4,801,927.4z M863,865.1c-4.1-3.4-4.1-11.4-12.2-12.6
    c-10.7-1.9-21.4-6-31.4-9.8c1.9,13.9,4.1,27.5,6.6,42c6,0,10.7,0.7,15.4,0.7c-1-3.4,0-7.5,4.1-8.2C852,876.2,858.9,875.2,863,865.1
    z M909.9,760.2c-9.7,2.5-14.1,8.2-11.6,19.6c5.1,0.7,9.7,1,14.1,1.6v-0.7C918.1,772.9,916.2,766.8,909.9,760.2z M832.5,904
    c0,1.6,1,3.2,1.5,4.8l3.4-3.4c-1-1-1.9-1.9-3.2-3.2C834.1,902.4,832.5,903,832.5,904z M851.1,903c3.4-9.8-1.5-13.9-9.2-16.4
    C843.2,896.7,843.8,897.3,851.1,903z M1009,250.4c1,6.7,6.6,8.2,12.2,5.7c1.5-5.7,3.2-11.6-5.6-12.3
    C1013.4,243.8,1008.3,248.8,1009,250.4z M1020.5,172c-1,3.2-0.7,6.7,0,10.1c0,1,3.2,1.6,4.7,1.9c1-1.6,3.2-2.5,3.2-4.1
    c0.7-3.2-0.7-6.7-0.7-9.8C1025,171.1,1020.9,171.1,1020.5,172z M1000.1,324c0.7-5.7,1.9-11.4,8.2-14.2c1.9-8.2,4.7-16.7-7.5-22.4
    c-4.7,1.6-10.7,3.2-16.3,5.7c3.4,0,8.2,1,13.8,3.2c-4.1,0-7.5,0.7-11.4,0.7c-3.4,0-7.5,0-11.4-0.7c0.7-0.7,1-1,1.9-1
    c-4.7,0-9.7-1.6-12.2,4.8c1.9,3.2,6.6,6,6,8.2c-1,4.1-5.6,4.1-9.7,2.5c-3.2-1-6.6-0.7-11.4-1c6.6,11.6,13.8,18.1,24.5,18.3
    c-3.4-3.2-7.3-5.7-10-8.9c-1.5-1-1.9-2.5-1.9-3.2s0-1,0.7-1.6c3.2,1.6,6.6,3.2,10,4.8c3.4,1.6,7.5,3.4,11.6,5.1
    c-0.7,1-0.7,1.9-1,3.2C988.5,327.1,994.2,326.3,1000.1,324z M968.7,588.5c-11.4-6.7-11.4-6.7-15.4,10.8
    C959.2,595.5,963.9,592.4,968.7,588.5z M953.9,492.5l9.7,9.8h0.7l2.5,4.8c5.6-3.4,11.4-7.3,12.2-15.9
    C969.9,484.2,962.1,485.9,953.9,492.5z M776.5,408.7c0,0.7,0,1.6,0,1.9c1,0,1.9-0.7,3.2-0.7c-0.7-1-0.7-1.6-1-1.9
    C778.1,408.1,777.2,408.7,776.5,408.7z M921.8,72.4c-1.5,0-3.2-0.7-4.7-0.7c-0.7,0-1,1.9-1,1.9c1,1,1.9,1.6,3.4,2.5
    C920.6,75.3,921.1,74.1,921.8,72.4z M1083.2,983.4c-3.2-3.2-6-4.1-7.5-0.7C1078.1,985.8,1081,986.8,1083.2,983.4z M615.1,870.2
    c-1-0.7-1.9-1.9-3.2-1.9c-1,0-2.5,0.7-3.4,0.7c1.5,0,3.2,0.7,4.1,0.7C613.6,869.5,614.5,870.2,615.1,870.2z M697.5,1061.1
    c1-4.8,1-5.1,0-3.2C697.5,1060.4,697.5,1061.7,697.5,1061.1z M1086.6,987.8c-1.5-1.6-2.5-3.2-3.4-4.1c1-1.6,1.9-3.4,2.5-6.7
    c-0.7,1,2.5,3.2,6.6,5.7l0.7-0.7c13.2,1,23.9,1.6,34,3.4c12.2,1.9,14.1-16.7,27.4-11.6c1,8.9,0.7,17.4-3.2,25.6c1.9-0.7,4.1,0,6,1
    c0,0.7,0.7,1,0.7,1.6c0.7,0,1,0,1,0c10.7,8.2,23.3,30.4,30.6-1.9c0,0.7,10.7-11.4,20.7-20.8c1.5,1,3.4,1.9,5.1,3.4
    c6-4.8,9.2-7.3,12.6-7.3s6.6,2.5,12.2,7.3c0.7,0.7,1,0.7,1.5,1c1.9,1.9,4.7,4.1,7.3,4.8c8.2,1.9,16.3,4.1,24.5,6
    c-12.6,22.2-36.2,44.6-28.9,13.9c-9.2,39.5-44.7,31.6-41.8,18.1c1.9-9.2-22.1,7.5-36.2,16.4c-1.5-0.7-3.2-1-4.1-1s-1,0-1,0.7
    c-0.7,0.7-1,1.9-1,4.1c-2.5,1-4.1,1-3.4-0.7c4.1-18.1-3.2-28.2-12.6-32.9c0-0.7,0-0.7,0.7-1c0,0.7-0.7,0.7-0.7,1h0.7
    c0,0.7,0,0.7,0,1c0-0.7,0-0.7-0.7-1c-0.7,0.7-1.5,1.6-1.9,1.9c-1.9-1.6-3.4-3.2-5.6-4.8c-11.4-1.6-23.3,2.5-25.5,11.4
    c-1,4.1-6-4.8-12.2-14.9c1.5,8.9,2.5,16.7,4.1,24.1c-14.8,8.9-28,16.4-43.4,25.6c1-15.5-1-27.2-15.4-34.1
    c-4.7-1.9-9.2-9.8-7.3-16.7c5.6-2.5,6,2.5,8.8,4.8c1,1,3.2,1.6,4.7,1.6c1,3.4,3.2,7.5,6,10.8c2.5,2.5,7.3,6,12.6,6
    c1.9,0,4.1-0.7,6-1.6l0,0c2.5-4.8,5.6-9.8,8.2-14.2c0-1,0.7-1.9,0.7-3.4c0.7-1.6,1-4.1,0.7-4.8c-1.9-2.5-5.1-4.8-8.2-5.7
    C1079.7,994.7,1083.2,990.9,1086.6,987.8z M1119.9,997.2c0.7,0.7,1.5,0.7,1.9,1c-0.7-0.7-1-1-1.5-1.6L1119.9,997.2z M799.5,361.6
    c9.2-3.2,12.6-8.2,11.4-15.9c-1-4.1-3.2-8.2-4.7-12.6l0,0c0,0,0,0,0-0.7v-0.6h0.7c3.2-8.2,3.4-16.4-3.4-23.1
    c-6-5.7-12.2-4.8-18.2-1.6l0,0c0,1-1,1.9-2.5,3.2l0,0c0.7,3.4,1,6.7,1.9,10.1l0.7-0.7l0,0l0,0c1.5-1,2.5-1.6,4.1-1.9
    c-1.5,1-3.2,1.9-4.7,2.5c-4.1,10.8-11.4,13.9-22.3,9.2c-1.9-1-5.1,1.6-9.7,3.2c3.4,4.1,7.3,6,8.8,9.2c1.5,3.4,1,7.5,1.9,11.6
    c7.5-4.1,14.1-7.5,20.7-11.4C789.4,349.6,794.4,356,799.5,361.6z M423,1128c-0.7,3.2-3.4,3.4-7.3,2.5c1.5,4.1,3.2,7.5,4.7,10.8
    C421.1,1137.9,421.8,1133.1,423,1128z M585.6,634.7c-0.7,1-1.5,1.6-1.5,2.5c0,1.6,0.7,2.5,1,3.4c0.7-1,1-2.5,1-4.1
    C586.2,637,586.2,635.6,585.6,634.7z M607.6,868.6l-0.7,0.7c0.7,0,1-0.7,1.5-0.7C608,868.6,608,868.6,607.6,868.6z M717.1,980.1
    c0,0-0.7,1-0.7,1.6c1.5-0.7,2.5-1.6,4.1-1.9C719.6,979.8,718,979.8,717.1,980.1z M392.2,553.5L392.2,553.5
    C392.2,552.8,391.5,552.8,392.2,553.5L392.2,553.5z M422,550.9c-9.7,3.4-16.7-0.7-23.9-6.7c-1.5-1-5.1,0.7-7.5,0.7
    c0.7,3.2,1,5.7,1.5,8.2C402.2,556,412.3,555,422,550.9c3.4,7.5,10,7.5,16.7,6.7c7.5-1,15.8-3.2,23.3-5.1c-0.7-0.7-1-1.6-1.9-1.9
    c-3.4,1-7.5,1.9-11.4,3.2c-1.5,0.7-3.4,1-5.1,1.6v-0.7c0-0.7,1.9-1,3.2-1.6c0.7,0,1-0.7,1.5-0.7c3.4-1.6,7.3-2.5,10.7-3.4
    c-1-1.6-1.9-2.5-2.5-4.1c-0.7-1,3.2-4.8,5.6-5.7c8.8-3.4,19.5,1.6,28-8.2c-7.3-4.1-14.1-6.7-22.1-7.3c0,0.7,0,1-0.7,1.9
    c-1.5,1-3.2,1.6-4.7,2.5c-0.7-1.6-1-3.2-1.5-4.8c-1.5,0-3.2,0-5.1,0.7c-3.4,0.7-7.3,1.9-9.7,4.1C438.4,536.1,430.3,543.6,422,550.9
    L422,550.9z M585.6,642.3h-0.7H585.6c0-0.7-0.7-1-0.7-1.6c0,0.7-0.7,0.7-0.7,1l0,0c-1,1-1.9,1.9-3.2,3.2
    C582.5,644.5,584,643.5,585.6,642.3z M607.6,868.6C607.6,868.6,607,868.6,607.6,868.6c-0.7,0-0.7,0.7-0.7,0.7L607.6,868.6z
    M583,817.1c5.6,7.5,11.6,9.2,19.5,7.3l0,0c0,0,0,0,0.7,0c1-0.7,1.9-0.7,3.4-1c-5.6-2.5-10.7-5.1-15.8-7.3
    C589,815.5,587.1,816.4,583,817.1z M420.4,1142c5.1,9.2,10.7,10.8,16.3-12.6c1-4.1,11.6,23.1,21.4,25.6c2.5-3.2,5.6-5.7,9.2-8.2
    c0.7-1.6,1-3.2,1.5-5.1c4.7-20.5,38.7-27.2,27,23.4c1.9-7.5,1.9,65.1,9.2,32.9c-3.4,15.9-3.2,27.2-0.7,33.8
    c-11.4-7.3-26.1-27.2-21.4-47.8c-2.5,12.3-12.2,7.3-21.4-1.9c-1.9,1.6-4.1,4.1-6.6,6.7c-1.5-1-3.4-1.6-4.7-3.2l0,0
    c5.1-6,6-17.4,1.9-24.6c-0.7-0.7-4.1-1-6-1.6c-0.7,0-1.5,0-1.9-0.7c0,1,0,1.9,0,2.5c-4.1-5.1-6.6-8.9-6.6-8.2
    c-0.7,1.6-1,2.5-2.5,4.1c3.4,3.2,6.6,6,9.7,9.2c0,0.7,0,1,0,1.6c0.7,3.2,0.7,6,1,9.2l0,0c-5.6-6-13.2-8.2-21.4-4.8
    c-10,4.1-16.3,13.9-15.8,25.6c0,1.9,1,4.1,1,5.7c-4.7,1-7.5,1.9-8.2,3.2c-0.7,1,0.7,4.8,1.9,9.2l0,0c-6.6,5.1-8.8-5.1-14.8-6.7
    c-1.9,4.8-3.4,9.2-5.1,14.2c-5.6,1.9-11.4,4.1-16.7,6c2.5-1.6,5.1-3.4,7.5-6c-0.7,0-1-0.7-1.5-0.7c-3.2-1-5.6-1.9-8.2-3.4
    c-1.5-1-3.2-1.9-4.7-3.2c8.2-14.2,14.1-28.7,3.2-45.6c3.4-6,6-13.3,11.4-16.4c5.1-3.4,12.2-3.4,20.7-5.1c1.9,2.5,5.1,5.7,8.2,9.2
    c6.6-1,13.8-1.9,19.9-3.2C420.4,1153.1,420.1,1148.6,420.4,1142z M400.4,1181.8c0-1-1.5-3.4-2.5-4.1c-2.5-1.6-5.1-1.9-7.3-1.9
    c-2.5,0-6,1-10.7,7.3c1.5,0,3.2,0.7,4.7,0.7c4.7,0.7,8.8,1,12.2,1c0.7,0,0.7,0,1,0C398.2,1184.3,400.4,1182.8,400.4,1181.8
    L400.4,1181.8z M732.7,874.9L732.7,874.9c-7.3-4.8-11.6,0-16.3,4.8v0.7c5.6-1.6,10.7-3.2,16.3-4.8V874.9L732.7,874.9z M769.6,858.4
    L769.6,858.4c-0.7,0-0.7,0-1,0C768.4,857.9,768.9,858.4,769.6,858.4z M716.4,879.4L716.4,879.4L716.4,879.4L716.4,879.4z
    M716.4,879.4L716.4,879.4L716.4,879.4L716.4,879.4z M733.7,875.2c-0.7,0-1,0-1,0.7c-1.5,6-2.5,12.3-4.1,18.1
    c5.1,5.7,9.2,10.1,13.2,15.5c0.7,0,1,0,1,0l0,0l0,0l0,0l0,0c0,0,0,0.7-0.7,0.7c4.7-3.4,8.8-9.2,13.2-9.8c9.7-1,16.3-5.1,22.1-12.3
    c3.4-4.1,8.8-8.2,13.8-10.8c9.7-5.7,9.7-12.6,6.6-22.2l0,0c-3.4-7.5-8.8-9.2-15.8-5.1c-4.7,3.2-9.2,5.7-14.1,8.2h0.7
    c5.1,1,10.7,1.9,16.7,3.2c-18.2,18.1-18.2,18.1-31.4,13.9c5.6-6,10-11.6,15.4-16.7l0,0l0,0h-1l0,0c-9.2-3.2-16.7-3.2-22.9,7.3
    c-2.5,4.1-8.2,6-12.6,9.2C733.4,874.9,733.4,874.9,733.7,875.2z M584,870.2c6-1,12.2-1.6,18.2-1.9c1.9,12.6-9.2,15.9-15.8,22.2
    c0,0.7,0,0.7,0.7,1c-0.7,0-0.7-0.7-1-0.7l0,0l0,0l0,0l0.7-0.7l0,0l0,0c-0.7,0.7-1.5,0.7-1.9,1c1,0.7,1.9,1,3.2,1.6
    c0-0.7-0.7-1-0.7-1c3.4,1.9,7.3,4.8,11.4,7.3c-1,0.7-1.9,0.7-3.2,0.7c9.7,9.8,17.3,25.6,13.8,40.5c12.6-54.6,11.6,44.9,16.7,23.1
    c-1,5.1-1.5,10.8-1,15.9c-6.6-1.6-12.6-2.5-18.9-3.2c0,0,0,0-0.7,0c-3.2,0-7.3,2.5-9.7,5.1c-0.7,0.7,0,3.2,0,4.8
    c0,1,0.7,1.9,0.7,3.4c1.9,5.1,4.7,10.1,7.3,15.5c2.5,1,4.7,1.9,7.3,1.9c5.1,0,9.2-2.5,11.6-4.8c2.5-1.9,4.1-4.1,5.6-6.7
    c3.4,9.2,8.8,13.9,11.6,6.7c-2.5,0.7-4.7,1-7.3,1c-4.7-8.9,1.9-10.8,5.1-15.5c3.2-4.8,4.7-10.1,7.3-15.5c8.8,0,12.6,3.4,14.8,8.2
    c0.7-4.8,1-9.8,2.5-15.9c10.7-46.1,9.7,13.9,18.2-23.4c2.5-11.4,39.3-24.1,36.8-12.6c8.2-35.7,30.6,33.8,29.6,37.2
    c-1,5.1,34.6,63.8,44.7,19c1-5.1,30.6,48.7,33.6,34.8c-1.9,9.2,7.3,9.2,15.8,7.5c9.2-13.3,23.9-16.4,38.7-18.3
    c3.2-0.7,8.2,4.1,9.7,7.5c5.1,1.6,8.8,3.4,8.8,4.8c-3.4,15.9,19.5-54.6,21.4-59.7c-0.7,0.7-4.1-2.5-7.3-7.5c-1,0.7-1.9,1.6-3.2,3.2
    c-3.4,5.7-9.7,9.8-12.6,15.9c-3.2,6.7-4.1,14.2-6,21.5c-4.7-3.4-7.5-6.7-11.4-9.2c-2.5,1.6-4.7,1.6-5.6,3.2
    c-2.5,3.2-5.1,7.3-9.2,4.8c-5.1-3.4-3.4-8.2,0-12.3c1-1,1-3.4,1.5-6c-7.3-4.1-13.8-8.9-19.9-12.6c-1-10.8,8.2-8.2,12.2-12.3
    s10-5.7,14.8-8.9c8.2-6,18.9-10.1,18.2-23.1c1.5-1.9,3.4-4.8,5.6-7.3c-7.5-5.1-13.8-8.9-22.1-13.9c4.7-6,8.8-12.3,12.6-18.1
    c1,0,1.9,0,2.5,0l-1.9-1c2.5-3.4,5.1-7.3,7.5-10.8c-7.3,0.7-14.1,1-21.4,1.9l-0.7-0.7v0.7l0,0l-8.8-30.7c-3.4-4.8-11.6,0-11.4-9.8
    c6,0,12.2,0,19.5,0c1-5.1,2.5-9.8,3.2-13.9c-2.5-7.5-14.1-8.9-11.4-18.3c3.4,0,7.5-0.7,10.7,0.7c4.1,1.6,8.8,3.4,11.4,6.7
    c3.4,4.1,4.7,10.1,8.2,14.9c3.2,4.8,10,3.2,14.8-1.6c0.7-0.7,0.7-1.9,1-2.5c3.2-5.7,8.8-9.8,14.1-6.7c1,0.7,1.9,1.9,3.2,3.4
    c6-18.3,9.7-26.6,3.2,3.2c-3.4,15.5,48.4,10.8,50,4.1c0.7-1.9,4.7,9.2,9.7,20.5h-1c0.7,0,0.7,0.7,1,0.7c7.5,16.7,18,34.1,22.3,14.9
    c-1.9,8.9-1.9,16.4-1.5,22.4c0.7,0,1.5,0,1.9,0c-0.7,0-1.5,0-1.9,0c3.4,24.6,23.3,32.3,27,18.1c-10,44.6,18,57.9,20.7,43
    c-1.9,9.2,6.6,38.9,15.4,47.8c-0.7,0.7-0.7,1.6-1,2.5c-6,26.6-31.4-45.6-33-37.2c8.8-38.9-41.8-25.6-40.9-29.7
    c16.7-73.3-18,4.1-15.4-5.7c6.6-28.2-10.7,26.6-9.2,14.2c-1.9,10.8-1.9,18.1-1,22.4c0.7,0.7,1.5,1,1.9,1.6c-0.7,1-0.7,1.9-1,2.5
    c1.5,5.7,4.1,6.7,4.1,8.2c-5.1,23.1-18,38.2-18,39.5c11.4-50.2-14.1,29.7-16.3,37.2c-3.2,14.2-23.9,1.9-31.4,24.1
    c1.5-1,3.2-1.6,4.1-1.9c-1.9,11.6-7.5,24.1-18.9,36.4c-3.4,4.1-4.7,7.3-5.1,12.3c-1.9,15.9-2.5,15.9-19.9,29.7
    c-10-6-12.6-16.4-14.1-27.2c-0.7-1.9,1.9-4.1,4.1-6.7l0,0l0,0c0.7,0.7,1,1,1.5,1.6c1.5,3.2,3.4,6,5.1,8.9l2.5-1.6
    c-2.5-2.5-5.1-4.8-7.5-7.3c-0.7-0.7-1-1.6-1.5-1.9l0,0l0,0l0,0l0,0l-0.7,0.7c-7.3-5.1-13.8-9.8-21.4-14.9c1-5.7,1.9-11.4,3.2-16.7
    c7.5-5.1,14.8-9.8,22.1-14.9c3.2,4.1,6.6,7.3,9.2,10.8c0.7,0,1,0,1.5,0c0-4.1,1-9.2,2.5-15.9c3.4-15.9-37.1,31.3-31.4,7.3
    c1.9-9.2-52.5,4.1-45.9-24.6c4.1-17.4-6-23.1-17.3-24.9c0.7,1.6,0.7,2.5,0.7,4.1c0,2.5-0.7,6-2.5,8.2c-5.1,5.7-5.1,11.6-5.1,19
    c0,13.3-1,13.3-16.7,15.9c-0.7-7.3,3.2-12.3,6-18.3c3.2-5.7-1-12.3-7.5-12.6c-8.8-1.6-15.8,1.9-18.9,9.8c-2.5,6-4.1,12.3-6.6,20
    c-7.5-1.9-14.1-3.2-20.4-5.7c-5.6-1.9-11.4-3.4-17.3-2.5c-0.7,3.4-0.7,6.7-0.7,8.9c-5.1,10.1-34,103.3-20.4,42
    c0.7-1.6-6-14.9-12.6-19l-5.1-19c-1.5,1.9-2.5,3.4-3.4,5.7c-1.9,2.5-3.4,5.7-5.6,8.2l-1.5,1.9l-1.9-1c-3.4-1.9-7.3-4.1-10-5.7
    c-2.5-5.1-4.7-9.8-7.3-13.3c0.7-1,1-1.6,1.5-2.5c-1,0-1.9,0-3.2,0l0,0c-1.9-1.9-3.4-1.9-5.1,0c-0.7,0-1,0-1,0
    c2.5-29-37.1-35.4-40.3-20.5c-4.7,19-21.4-0.7-27.4-20c-13.8,7.3-25.5,6.7-31.1-3.4c10,2.5,20.7-5.1,30.2-0.7
    c-1-5.1-1.5-9.8-0.7-13.9c-1,5.1-5.6,4.8-11.4,1.9c-1,1-2.5,2.5-3.4,2.5c-1.5-0.7-2.5-1.9-3.4-3.2c0.7-0.7,1-1.6,1.5-1.9
    c-12.2-6.7-26.5-17.4-28-9.2c-3.4,15.9-26.5-23.4-37.7-18.1c0.7,0,1.5,0,1.9,0c-0.7,1.6-1.5,3.2-1.9,4.1c-0.7,0.7-2.5,0.7-2.5,0
    s0-1,0-1c-1,1.6-1.5,3.2-1.9,5.7c7.3-31.3-38.1-28.2-44.3-1c1.9-8.9-22.9-18.3-27,0c9.2-41.1-28.6-11.4-34.6,15.9
    c-1,4.1-3.4,9.8-6,15.5c-0.7,0.7-1.5,1-1.9,1.6c0.7,0,0.7,0,1,0.7c-6,10.8-13.2,20.5-15.4,18.1c-1.9,19-7.3,67.6,0,36.4
    c3.4-15.5,54.4-29,40.3,31.6c0-0.7,9.2,0.7,17.3,3.4c-3.2-3.4-2.5-7.5,0-10.1c1-1,2.5-1.9,3.2-3.4c1.5,3.4,2.5,7.5,3.2,11.6
    c-1,1-1.5,1.9-2.5,3.2c1,0.7,1.5,0.7,2.5,1c0,0.7,0,1.6,0,1.9c0.7-0.7,1-0.7,1.5-1c4.7,2.5,7.3,5.7,6.6,9.2c1-5.1,4.7,8.2,9.2,20.5
    c-10.7-2.5-30.2-16.4-30.2-15.5c1-4.8-24.8-47.1-30.2-25.6c4.1-18.3-36.2-6-33-20c0.7-1-14.1-54.6-19.5-31.3
    c-1.5,6-16.7-11.4-11.6-38.2c-3.4,20,26.5-18.1,31.4-41.1c-10,44.6,29.6-4.8,36.2-34.8c0,0.7,28-0.7,30.6-12.6
    c3.4-15.5,21.4,22.4,28.6-9.2c-1.9,9.2,3.2,13.3,10,12.6c-5.1,4.1-8.2,5.7-16.7,7.3c4.1,1.9,7.3,3.4,11.4,5.7l1.9,1l-0.7,1.9
    c-2.5,8.9-6.6,18.1-18.9,18.1c-0.7,0-1.5,0-1.9,0c-0.7,0-1,0-1.5,0c-5.1,0-8.2,1.9-10,4.8c3.2-0.7,6-1,9.2-1
    c10.7-1.6,22.1-3.2,33-4.8c2.5-0.7,5.1-1.9,7.3-4.1c0.7-0.7,1-0.7,1.5-1c5.6-3.4,8.8-6,12.2-6c4.1,0,7.3,2.5,12.6,8.2
    c7.5-4.8,15.4-7.3,23.9-7.3c3.2,0,6,0.7,9.2,1c3.2-3.2,4.7-5.1,5.1-6c0-1-0.7-3.2-2.5-6.7c-1.9,0-4.1,0.7-6,1c-3.2,0.7-6,1-9.2,1
    s-5.6-0.7-7.3-1.6c-3.2-1.6-5.1-2.5-7.5-2.5c-3.4,0-6.6,1.6-10,3.2l-1,0.7c-1.9,1-4.1,1.6-6.6,1.6c-1.9,0-7.3-1-7.5-8.2v-1.6l1-1
    c4.7-3.4,9.7-5.7,15.4-8.2c6.6-2.5,12.6-5.1,18-10.8c-2.5,0.7-4.7,1-6,1.9c-2.5,1-5.1,1.9-7.5,3.4c-1.5,1-3.4,1.6-5.1,2.5l-3.4,1.6
    l-0.7-4.1c0-2.5-0.7-4.8-0.7-6c4.1-4.8,7.3-10.1,8.8-16.4c-0.7-1-1-1.9-1-3.4c11.6-10.8,7.3-31.3,24.8-42c8.2,0.7,18.2,1,28.6,1.6
    c1,13.3-13.8,17.4-15.4,27.5c-1.5,10.8-9.2,13.3-15.8,15.9c0-0.7,0.7-0.7,0.7-1l0,0c-0.7-1.6-1.5-4.1-1.9-5.7c-0.7,0-0.7,0-0.7,0.7
    c0,0-0.7,1,0.7,4.8c0,0.7,0.7,1.6,1,1.9c0,0,0,0-0.7,0h0.7c0,0,0,0.7,0.7,0.7v-0.7c9.7,3.2,19.9,7.5,26.1,8.2
    c3.2-6.7,4.7-10.8,6.6-15.5c3.2,0.7,6,1,9.2,1.6c6.6-1.9,15.4-0.7,22.3,2.5c0.7-2.5,1.5-5.1,2.5-5.1
    C564.2,873.3,574,871.7,584,870.2z M515.8,892.2c0.7,0,0.7,0,1,0C516.4,892.2,515.8,892.2,515.8,892.2z M524.6,958.3
    c-1.5-1-2.5-1.6-3.4-1.6c-1.5,0-3.4,1-5.6,2.5c-1,1-1.9,1.9-3.2,3.2c0.7,3.4,1,5.1,1.9,5.7c1.5,1,4.1,1.9,9.7,3.2
    c0.7-1,1-1.9,1.5-3.2l0,0C527.1,962.4,527.1,960.2,524.6,958.3z M871.8,1129c0-3.2-1-6-1.5-8.9c0,0,0,0,0,0.7
    c-0.7,2.5-0.7,5.7-0.7,8.2c0,0.7,0.7,0.7,1,1C871.2,1129.7,871.2,1129.7,871.8,1129z M909.9,873.3c-2.5,3.2-5.6,6-8.2,9.2
    c-0.7,0-1,0-1.5,0l0.7,0.7l-0.7-0.7c0,0.7-0.7,1-0.7,1.6l-3.2,2.5c0.7,0,1.9,0.7,1.9,0c0.7-1,0.7-1.6,1-2.5l1.5-1l0,0l0.7-0.7
    c2.5-0.7,5.1-1,7.5-1C909.6,879,909.9,876.2,909.9,873.3z M922.8,825.6c0.7,0,0.7,0.7,1,0.7c0.7-1,1.5-4.8,1.9-5.7
    c-0.7,0-0.7,0-1,0c-1.5,0-2.5,0.7-4.7,0.7l0,0c0,1.6,0,3.2,0.7,3.4l0,0c-6.6,2.5-12.6,4.8-18.9,7.3c3.4,8.2,10.7,4.1,16.3,5.7
    C920.3,833.2,921.1,829.4,922.8,825.6z M707.9,989.9c3.4,1.9,7.5,4.1,11.4,6c-2.5,6-4.1,11.4-6,16.7c12.2,9.8,18.2-3.4,28.6-7.5
    c8.2,2.5,16.7,5.7,26.1,8.9c1.5-1.6,3.2-3.2,5.1-4.1c-5.6-0.7-9.7-1.6-9.2-5.1c1.5-7.3-17.3-11.4-29.6-17.4c0,0.7,0,0.7,0,1
    c-8.8,1.6-10-4.1-11.4-9.8h-0.7c0-1,0.7-1.9,0.7-3.4c0-0.7,0-1.6,0-1.9c1.5-7.5-10,6.7-11.4,10.1
    C713,977.6,710.8,983.4,707.9,989.9z M696.7,1030.7c1,1,1.9,3.2,3.2,3.2c0,0,0.7,0,1.5-1c0-0.7,0.7-1.6,0.7-1.9v-0.7
    c-1.9,0-3.4-0.7-5.1-0.7C696.7,1030.4,696.7,1030.7,696.7,1030.7z M677.1,1048.4h0.7c0-0.7,0.7-1.6,0.7-1.9c0,0,0,0-0.7,0
    C677.8,1046.9,677.8,1047.2,677.1,1048.4z M645,999.1c5.1,7.3,14.1,20.5,20.7-0.7c-0.7-0.7-0.7-1-1-1.6c-1.5,1-3.4,0-5.1-4.1
    C655.4,995.7,650.3,997.5,645,999.1z M742.9,680.9l-0.7,2.5l1.9-0.7L742.9,680.9z M746.6,848.7l1.9-0.7l-1.5-1.9L746.6,848.7z
    M593.7,840.1c-5.1-2.5-10-6-13.8-9.8c-8.2-8.9-15.4-3.4-28.9,0c23.9,9.8,39.3,22.2,52.5,37.2c1.5,0.7,2.5,0.7,4.1,1
    C610.1,856,606,846.1,593.7,840.1z M715.9,879.9L715.9,879.9C715.9,879.9,715.9,879.4,715.9,879.9c0.7-0.7,0.7-0.7,0.7-0.7
    L715.9,879.9z M627.4,813.3c-0.7-1.6-0.7-4.1-1.5-4.8c-1-0.7-3.2,0.7-4.7,1c0.7,1.6,0.7,4.1,1.5,4.8
    C623.5,815,625.8,813.3,627.4,813.3z M602.2,867C602.2,867,602.2,867.6,602.2,867C602.2,867.6,602.2,867.6,602.2,867
    C602.2,867.6,602.2,867.6,602.2,867c0,0.7,0,0.7,0,0.7c0.7,0,0.7,0,1,0h-0.7c0.7,0,0.7,0,0.7,0s0,0,0-0.7
    C602.9,867.6,602.9,867.6,602.2,867L602.2,867z M638.1,901.8c12.2,1,24.5,2.5,36.8,2.5c5.1,0,9.7-3.4,9.2-9.2
    c0-4.1-2.5-10.8-5.6-12.3c-14.8-5.7-30.2-4.1-44.3,3.2c-0.7,0,0,1.6-0.7,1.9C644,889.1,638.1,895.8,638.1,901.8z M653.2,763.7
    c1,1.6,1.9,3.2,2.5,5.1c4.1,0.7,7.5,1.6,11.6,1.9l0,0c3.2-4.1,6-6.7,11.4-3.2l0,0c3.2,1,5.6,2.5,8.8,4.1c1.9-2.5,4.1-6,5.1-9.8
    c-7.5-5.7-16.7-7.3-24.5-8.9c-6,3.4-10.7,6.7-15.4,9.8C653.2,763.3,653.2,763.3,653.2,763.7L653.2,763.7L653.2,763.7L653.2,763.7
    L653.2,763.7L653.2,763.7z M124.8,476.8c-0.7,0-1.5,0-2.5,0l4.1,1.6C126,477.8,125.5,476.8,124.8,476.8z M199.4,656.4
    c-0.7,0-1.5,0.7-2.5,1c1.5,0.7,2.5,0.7,4.1,1C200.3,657.4,199.9,656.4,199.4,656.4z M199.4,380.5c0.7-1.9-1.5-4.8-3.2-7.3
    c-2.5,1-5.6,1.9-6.6,3.4s1,4.8,1.9,7.5C195.3,383.1,198.7,382.7,199.4,380.5z M198.7,765.5c-0.7-1.6-0.7-2.5-1-3.4
    c0-0.7-1.5,0-2.5,0c0.7,1,0.7,2.5,1,3.4C197.2,765.5,198.4,765.5,198.7,765.5z M162.2,719.3c0,0.7,1,1,1.9,1c0.7-0.7,1-1.6,1.5-1.9
    l-3.2-1.9C162.5,717.7,162.2,719,162.2,719.3z M175.4,317.6c0-0.7-0.7-1-1-1.6c0,1.6,0.7,2.5,0.7,3.4
    C175.4,319.2,175.8,318.2,175.4,317.6z M197.2,743.3c-1-1.6-1.9-3.4-3.4-3.4c-1.5-0.7-3.2,1-4.7,1.6c1.5,1,2.5,2.5,4.1,3.2
    C194.3,744.9,195.9,744,197.2,743.3z M177,170.7c0,1.6-0.7,3.4,0,4.8c1.5,1.9,3.4,3.2,7.3,6c-1.5-5.1-1.5-7.5-3.2-9.8
    C180.5,170.7,178,171.2,177,170.7z M155.6,681c0-12.6-13.8-21.5-24.8-15.5c-4.1,2.5-8.2,5.7-11.6,9.2v38.2c3.4,1,7.5,2.5,11.4,3.4
    c11.6,3.2,18.2,0,21.4-12.3c0-0.7,0-1,0.7-1.6c-1-1-1.5-1.9-2.5-3.2c1.5-2.5,3.2-4.8,5.1-6.7C155,689.2,155.6,685.1,155.6,681z
    M174.6,315.7l0.7,0.7C174.8,316,174.8,315.7,174.6,315.7L174.6,315.7z M1954.4,896.7C1954.4,896.7,1954.1,896.7,1954.4,896.7
    l-1.9-2.5c-0.7,0-0.7,0-1,0l-1.5,1.9C1951.6,897.3,1952.6,897.3,1954.4,896.7z M177.3,387.2c0.7,1.6,1,3.2,1.9,4.8
    c1-1,2.5-1.6,3.4-2.5c-1-1-2.5-2.5-3.4-4.1C178.6,386.8,177,387.2,177.3,387.2z M797.9,940.3l1.9-1.6l-2.5-0.7L797.9,940.3z
    M1594.9,444.8c0-1.9,0.7-3.4,1.5-5.1c-3.4-0.7-7.3-1.6-10-0.7C1589.9,441.9,1593.1,443.9,1594.9,444.8z M1642.4,426.5
    c-1-1-3.4-0.7-5.1-1c0.7,1.9,0.7,4.8,1.9,6c0.7,0.7,3.2,0,5.1,0C1643.4,429.6,1643.4,427.4,1642.4,426.5z M1592.1,423.2
    c-1.5,0-3.2,0-4.7-0.7C1589,423.2,1590.5,423.2,1592.1,423.2L1592.1,423.2z M1654.9,443.9c0,1,0.7,1.9,0.7,3.4l0,0
    c0.7-1,1-1.9,1.9-2.5C1657.2,444.4,1656.3,444.4,1654.9,443.9z M1562.8,440.3c-7.3-9.2-13.2-10.1-23.9-7.5
    C1546.2,440.3,1552.1,441.9,1562.8,440.3z M1616.3,426.1c1.5,0.7,3.4,1,5.1,1.6h0.7C1620.1,427.4,1618.5,426.5,1616.3,426.1z
    M1592.4,423.9c-0.7,7.5,4.7,8.9,9.2,10.1c0.7-0.7,1-0.7,1.5-1C1599.7,429.6,1595.8,426.5,1592.4,423.9L1592.4,423.9z M1391.4,629
    c0.7-0.7,0.7-1,1-1.6c-1,0.7-1.9,1-3.2,1.6C1389.9,629,1390.7,629.6,1391.4,629z M1316.5,665c15.8,3.2,32.8,0.7,47.8,7.5
    c1.9,1,5.6-1.9,10-4.1c-6-4.8-10-8.2-14.8-11.4c-15.8,8.2-24.8-8.9-37.1-11.4C1316,651.8,1316,658.5,1316.5,665z M1315,665.3
    c0.7,0,1,0,1.5-0.7c0,0,0,0-0.7,0h0.7l0,0h-0.7C1315.3,665,1315.3,665.3,1315,665.3z M1353.7,478.2c-1.5,2.5-1.5,9.2-0.7,9.8
    c10,5.1,13.8,12.3,9.7,23.1c1.5,0.7,3.4,0.7,5.6,1c1.9-4.1,4.1-8.9,6-12.6c0-1-0.7-1.9-0.7-3.2c0-1-0.7-2.5-0.7-3.4
    c0.7-1,1-1.9,1-3.2c0.7,0,1.5,0,1.9,0c1,0,1.9,0,3.2,0.7c1-1.9,2.5-4.8,3.4-6.7c-6-8.2-15.4,4.8-20.4-4.8
    C1359.6,473.6,1355.6,474.5,1353.7,478.2z M1392.6,627.1c0,3.4,0,7.3,0.7,12.3c6.6-4.1,12.2-7.3,19.9-11.4
    c-8.2-0.7-14.1-1-20.7-1.6L1392.6,627.1c-0.7-0.7-0.7-0.7-0.7-0.7c0,0.7,0,0.7-0.7,1C1392.4,627.8,1392.6,627.8,1392.6,627.1
    L1392.6,627.1z M1355.2,436.9l0.7,1.9l1.5-1L1355.2,436.9z M1377.8,424.8c-1-1-1.9-2.5-3.2-2.5c-1.5,0-3.2,1.6-4.7,1.9
    c1,1,1.9,1.9,3.2,3.2C1375.1,426.5,1376.6,425.5,1377.8,424.8z M1342,427.4c-1.5,0-2.5,0-3.2,0c0,0.7,0.7,1.6,1,1.6
    C1340.4,428.9,1340.8,428.7,1342,427.4z M1050.1,905.9c15.8-1.9,32.1-4.1,47.8-6.7c3.4-0.7,6.6-3.2,9.7-5.7
    c11.4-7.5,11.4-7.5,23.3,3.4c10-7.3,22.1-9.8,34-6.7c1.9,6,4.7,12.3,6.6,19c1.9,9.8,1.9,9.8,16.3,13.3c1-1.9,1.5-3.4,2.5-5.1
    c1.9-4.8,3.4-9.2-1-13.3c-4.7-3.4-9.2-1.9-12.6,1.6c-1.5,1.6-3.2,2.5-4.7,4.1c-2.5-6.7-4.7-12.6-6.6-19c8.2-8.9,8.2-8.9,3.2-18.3
    c-8.8,0.7-17.3,3.4-22.9,0.7c-8.8-5.1-14.1-1.9-21.4,1c-4.1,1.6-9.7,3.4-10.7-4.8c11.4-9.2,28.6-8.2,37.7-24.9
    c-6.6,1.9-10,2.5-13.8,4.1c-4.1,1.6-8.2,4.1-13.2,6c-1.9-22.4-1.5-22.4-13.8-45.6c-0.7-1-1-1.9-1.5-3.2c-1,0-1.9,0-2.5,0
    c-2.5,0-4.1-0.7-5.1-1c-1-1-1.5-3.4-1.5-7.5c0-1,0.7-1.9,0.7-3.2c-4.7-5.1-9.2-10.1-14.1-15.5c-16.3,19-14.8,23.1,6.6,28.7
    c0.7,0,1.5,1.6,1.9,1.9c0.7,15.9,0.7,31.6,1,47.8c-10.7,9.8-10.7,9.8-29.6,12.6c7.5,4.1,13.2,6.7,18.2,9.8
    c-3.2,9.8-6.6,16.7-18.2,15.9C1059.6,894.2,1053.6,898.3,1050.1,905.9z M1068.4,850.2c-3.4,0.7-7.3,1-10.7,1.6
    C1062.5,856,1065.2,853.4,1068.4,850.2z M1071.8,659.4c-3.4-2.5-6.6-2.5-9.7,1c-4.7,5.1-4.7,9.8,0.7,13.9c3.2-1.6,7.3-3.2,9.7-5.1
    C1075,666,1075.6,661.9,1071.8,659.4z M1129.1,690.9l1.5-0.7l-1.5-1V690.9z M1360.3,656.8L1360.3,656.8c0.7,0,0.7-0.7,0-0.7h-1l0,0
    L1360.3,656.8C1360.3,656.8,1360.3,657.1,1360.3,656.8C1360.6,657.1,1360.6,657.1,1360.3,656.8L1360.3,656.8z M1370.3,632.2
    l-27,7.5c1.5,12.3,8.8,12.3,16.7,9.8l-0.7,1c0,0,0,0,0-0.7v0.7h0.7c0-0.7,0-0.7,0-1C1362.8,644.5,1365.9,639.7,1370.3,632.2z
    M1371.3,650.2c-4.1,0-7.5,0-11.6,0.7c0,1.9,0.7,4.1,0.7,5.7l11.4-1.9C1371.9,652.7,1371.3,651.8,1371.3,650.2z M1541.1,480.1v0.7
    C1541.1,480.8,1541.1,480.8,1541.1,480.1L1541.1,480.1z M1696.2,823.1c-0.7,0-0.7,0.7-0.7,0.7c0,0.7,0.7,1,0.7,1.6
    C1696.2,824.1,1696.2,823.7,1696.2,823.1z M1704,752.9c0-0.7-0.7-1.9-2.5-4.8c0,0-0.7,0-1,0c-0.7,0-0.7,0-1,0l-0.7,0.7
    c-3.2,3.4-6.6,7.5-8.2,11.6c0,0.7,1,2.5,1.9,4.1c0.7,0.7,0.7,1,1,1.6C1700,764.7,1703.4,760.8,1704,752.9z M1615.7,424.2
    L1615.7,424.2c0,1,0,1.6,0.7,2.5V426C1616.6,425.8,1616.6,425.5,1615.7,424.2z M1857.8,936.2c0.7,0,1,0,1-0.7
    C1858.8,936.2,1858.1,936.2,1857.8,936.2L1857.8,936.2z M1622.6,503.8c0,0.7,0.7,1.6,1,1.6c0,0,0.7,0,1,0c0-1,0.7-1.9,0.7-3.2v-0.7
    l-0.7-0.7C1623.5,502.3,1623,502.6,1622.6,503.8z M1509.3,461.8c0.7,1,1.9,2.5,3.2,3.2c0.7,0.7,0.7,0.7,1,1c3.4,1,6.6,1.6,9.2,1.6
    c4.7,0,8.8-1.9,11.6-6c1-1,1-3.4,0.7-5.1l0,0c-0.7-1-1.9-2.5-3.2-2.5c-0.7,0-1.5,0.7-1.9,0.7c-3.2,0.7-6,1.6-9.7,1.6
    c-1.9,0-3.4,0-5.1-0.7C1513.4,456.2,1510.3,459.3,1509.3,461.8z M2003.5,973.2c-38.7-38.9-24.5-24.1,0.7,1.6
    C2005.1,974.5,2005.1,974.5,2003.5,973.2z M1400.6,435.3L1400.6,435.3c-0.7,0-0.7,0-0.7,0C1399.9,435.6,1400.6,435.3,1400.6,435.3z
    M1401.4,431.5c-1,0-1.5,0.7-2.5,0.7c0.7,1,1,1.9,1.5,2.5l0,0C1400.9,433.7,1400.9,432.8,1401.4,431.5z M1651.5,485.2
    c-27.4-27.5-14.1-13.3,5.1,7.5C1657.2,491.9,1655.6,489.3,1651.5,485.2z M1398.4,436.2c0.7,0,1-0.7,1.5-1c-1.5,0-3.2,0.7-4.1,0.7
    C1396.5,436.2,1397.4,436.9,1398.4,436.2z M1391.7,571.2h-2.5c0,1,0,1.9,0.7,3.2c0.7-0.7,1.5-0.7,1.9-1
    C1392.4,573.4,1391.7,572.4,1391.7,571.2z M1304.6,672c3.4-1.9,6.6-4.8,10-6.7C1310.9,666.7,1306.1,666.7,1304.6,672z
    M1660.7,448.9c-1.5-0.7-2.5-1-4.1-1c0.7,0.7,1,1,1.5,1C1658.8,449.5,1659.7,448.9,1660.7,448.9z M1740.5,463.7
    c0.7,0,0.7-1.6,1-1.9c-1,0-2.5,0.7-4.7,0.7C1739,463.4,1739.6,463.7,1740.5,463.7z M1736.1,515.6c0.7,0,1,0.7,1,0.7l-0.7-1
    C1736.5,515.6,1736.1,515.6,1736.1,515.6z M1722.9,514.6c-0.7,0-0.7,2.5-0.7,2.5c1.9,0.7,4.7,1.6,6.6,1c2.5-0.7,4.7-1.6,7.3-2.5
    c-1.9-1-4.1-2.5-6-2.5C1727.3,512.4,1724.8,513.9,1722.9,514.6z M1744.6,538l-1,1.9h2.5L1744.6,538z M1738,515.6
    c8.8,1,17.3,1.6,24.8-5.1C1753.5,507.9,1752.8,507.9,1738,515.6z M1737.1,516.5c0.7,0,0.7-0.7,1-0.7c-0.7,0-1,0-1.5,0L1737.1,516.5
    z M1728,438.8c-0.7-1-1-1.9-1.5-3.2c0,0-1.5,0.7-1.9,1c0.7,0.7,1,1.6,1.5,1.9C1726.4,438.8,1727.3,438.8,1728,438.8z M1735.1,490
    l-1,1.9c1,0,1.5-0.7,2.5-0.7C1736.1,490.9,1735.5,490.5,1735.1,490z M1834.9,662.8l1.9-0.7l-1.5-1L1834.9,662.8z M1850.6,650.5
    c0,0,0.7-1.6,1-1.9c-1,0-1.9,0-4.1,0.7C1849.1,650.2,1849.6,650.5,1850.6,650.5z M1766.7,539c-1.5,7.5,3.2,11.4,8.2,12.3
    c6,1.6,12.2,1,22.1,1.6C1786.4,544.6,1776.7,542.1,1766.7,539z M1850,671.7c1,2.5,1.9,4.8,3.2,6.7c0.7-1.9,1.5-4.8,1.9-6.7
    c0-0.7-2.5-1.6-4.1-1.9C1851.3,670.1,1850,671.1,1850,671.7z M1965.7,734.6c-8.8-8.9-8.8-9.2-22.9-12.3
    C1947.5,731.1,1953.5,738.1,1965.7,734.6z M1994.4,961.9L1994.4,961.9C1993.7,962.4,1993.7,962.4,1994.4,961.9L1994.4,961.9z
    M1774.2,476c0.7,0.7,1.5,1,1.9,1.6c0.7-1,0.7-1.9,1-3.2c-0.7,0-1.5,0-2.5,0C1774.9,475.1,1774.2,475.7,1774.2,476z M1961.6,702.4
    c-4.1,2.5-4.1,6.7-1.5,9.8c2.5,2.5,6,4.1,9.2,6c2.5-1.9,4.1-4.1,4.7-6c-0.7,0-0.7,0-1,0c0.7,0,0.7-0.7,1-0.7c0-1.9-0.7-4.8-2.5-7.3
    C1968.2,700.1,1965.1,700.1,1961.6,702.4z M1714.7,446c1,1,1.5,1.6,1.9,1.6c0.7,0,1-1,1-1.6C1717.3,446,1716.3,446,1714.7,446z
    M1797.1,665c0-0.7-0.7-1.6,0-1.9c-1,0-1.5,0-2.5,0c0,0.7,0,1.9,0.7,1.9C1795.6,665.3,1796.5,665,1797.1,665z M1759.4,471
    c-7.3,1-12.6,1.9-22.3,3.4C1749.7,484.2,1755,481.1,1759.4,471z M1678.9,439.4c1.5,1,3.2,1.9,4.7,3.2c1-1,1.9-1.6,3.2-2.5
    c-1.5-1-3.2-2.5-4.7-2.5C1681.1,437.2,1680.1,438.8,1678.9,439.4z M1656.6,448l-0.7-0.7c0,0,0,0,0,0.7
    C1656.3,448,1656.3,448,1656.6,448z M1699.1,454.5l1.5,1l0.7-1.9L1699.1,454.5z M1766.7,624.9c2.5,2.5,5.1,5.1,7.5,7.5
    c0.7,0.7,3.2-1,4.1-1.6c0.7,0,0.7-1.9,0-2.5c-3.4-1.9-7.3-4.1-10.7-6C1767.6,623.7,1767.2,624,1766.7,624.9z M817.3,842
    c-5.6,3.4-10.7,7.3-16.3,10.8C807.6,850.9,813.3,847.7,817.3,842z M1864.4,447.6c-7.3-4.1-10.7-6.7-13.2-9.2
    c-1.5-0.7-3.4-0.7-5.6-0.7C1850,447,1857.8,446.3,1864.4,447.6z M1779.3,610.7c-1.5-1.6-4.1-1.9-7.3-3.2c0.7,3.2,0,6,0.7,6
    c0.7,0.7,1.5,0.7,1.9,0.7c1.5-1,3.4-1.6,5.1-2.5C1779.8,611.7,1779.8,611.4,1779.3,610.7z M1824.8,442.9l-19.5-7.5
    C1815.1,447.6,1815.1,447.6,1824.8,442.9z M835,788.3c-0.7,1-1.5,1.6-1.9,2.5c0,0.7,1,1,1.5,1.9l1-1.9L835,788.3z M819,842.7
    L819,842.7L819,842.7L819,842.7z M874.4,626.5c1-0.7,1-2.5,1.5-4.8c-1.5-0.7-3.4-1.6-4.1-1c-1,1-1,3.2-1.5,4.8
    C871.2,626.2,873.4,627.8,874.4,626.5z M871.2,674.2c-1,0-1.5-0.7-2.5-0.7c-0.7,0.7-1.5,1-1.9,1.6c1,0.7,2.5,1,3.4,1
    C870.2,676.8,870.8,675.2,871.2,674.2z M880.3,654.3c-1,0-1.9,0.7-3.4,0.7c0.7,0.7,1,0.7,1.9,1C879.3,655.3,880,654.6,880.3,654.3z
    M872.7,727.3l2.5-3.4c-0.7-0.7-1.9-1.6-1.9-1.6c-1,1-1.9,1.9-2.5,3.4C871.2,725.7,872.4,727,872.7,727.3z M879.3,766.8
    c-1-1-1.9-1.9-2.5-2.5c-1,1-1.9,1.9-2.5,3.4c1.5,0.7,2.5,1,4.1,1.6C878.5,768.8,879.3,766.8,879.3,766.8z M821.8,639.4
    c-0.7,1,0,2.5,0.7,4.1c1-1,1.9-1.9,3.2-3.2c0.7-0.7,1-1.6,1.5-1.9c0-0.7-1-1-1-1.9C824,637.2,821.8,637.8,821.8,639.4z
    M841.3,351.2c-1.9,2.5-3.2,6-4.7,9.2c1.5,0,4.7,1.6,5.1,0.7c1.9-2.5,4.1-5.7,6.6-9.2C844.8,351.2,841.6,350.9,841.3,351.2z
    M819.3,842.7c3.4-0.7,7.3-0.7,10-1c3.4-9.8,6.6-18.1,10-27.2c-4.7-1.9-7.5-3.2-11.4-4.8C822.4,820.5,828.4,833.8,819.3,842.7z
    M841.3,492.5c-1.5,0.7-2.5,1.9-3.4,3.2c1.5,1,3.4,1.9,5.1,1.9c1.9-0.7,4.1-1.6,11.4-4.1C845.7,492.5,843.2,491.9,841.3,492.5z
    M846.4,477c-0.7,0.7,1,1.9,1.5,3.4c1.5-1,2.5-1.9,4.1-2.5c-0.7-1-1-2.5-1.9-4.1C848.2,475.1,846.9,476,846.4,477z M823.1,548.4
    c0-1.6,0.7-4.8-0.7-5.1c-1.9-1-4.7-1.6-7.3-1.9c0,2.5,0,5.1,0,7.5C817.7,548.7,820.2,548.4,823.1,548.4z M881.9,635.3
    c-2.5,2.5-5.1,5.1-8.2,8.2C881.9,645.2,886,643.5,881.9,635.3z M823.4,460.8c-1.5,0.7-2.5,2.5-3.4,3.4l8.8-2.5
    C825.8,461.2,824.3,460.3,823.4,460.8z M857.9,433.1c-1.5,0.7-3.2,1.6-5.1,1.9c1,1,2.5,2.5,2.5,2.5c1.5-0.7,3.2-1.9,4.7-3.2
    C858.9,434,858.6,432.8,857.9,433.1z M930,368.3c-0.7,1,1,2.5,1.5,3.4c1.9-1.6,4.1-3.2,6-4.8c-1.9-1.6-4.1-3.4-4.7-3.2
    C931.8,365.2,930.3,366.7,930,368.3z M1010.2,203c-0.7,0.7-0.7,1.6-1,2.5c1,0,1.9,0,3.2,0c0-1,0-1.6,0-2.5
    C1011.8,203,1010.8,203,1010.2,203z M940,466.3c1.5-1.6,3.2-3.4,5.1-5.7c-2.5-0.7-4.7-1.9-5.6-1.6h-0.7c0.7,0,0.7,0.7,0.7,0.7l0,0
    v0.7c-0.7,0-1,0-1.5,0c-0.7,1-1,1.9-1,2.5C936.9,464.4,939.1,465.9,940,466.3z M947.6,121.4c-0.7,0,0,1,0,1.6c0.7,0,1.5,0,1.9,0
    c0,0,0-1,0-1.9C949.2,121.4,948.3,121.4,947.6,121.4z M931.3,212.2c-1,0-1.5,0-1.9,0.7c0,0,0,1,0.7,1.6c0.7,0,1.5,0,1.9-0.7
    C931.3,213.7,931.3,212.4,931.3,212.2z M946.1,155.2c-0.7,0.7,2.5,3.2,3.4,4.8c1.9-1.6,4.1-3.4,5.6-5.1c-1.9-1.9-3.2-3.4-4.1-4.8
    C949.8,151.8,947.3,153.3,946.1,155.2z M1008.3,107.6c-1-0.7-3.4,1-4.7,2.5c-0.7,1-0.7,4.1,0,4.8c1,1,3.4,1,5.1,0.7
    c1.9-1,4.1-2.5,6-4.1C1012.7,110.3,1010.8,108.1,1008.3,107.6z M884.4,703c-1-0.7-1.9-1-3.2-1.6l0.7,2.5
    C882.5,703.2,883.4,703.2,884.4,703z M928.4,257.6c0.7,0,1.5-1,1.9-1.6c-1.5,0-3.2,0.7-4.7,0.7
    C926.9,256.8,927.2,257.6,928.4,257.6z M924.7,703.9L924.7,703.9L924.7,703.9l-13.8-0.7l-0.7,0.7c-4.1,1.9-9.7,1.9-9.2,9.2
    c8.2,7.5,16.3,5.7,23.9-0.7C925.2,709.6,925.2,707.1,924.7,703.9z M887.2,635.3c1,0,1.5-1.6,2.5-2.5c-1,0.7-1.5,0.7-2.5,1
    c-1.5,0.7-3.2,1-5.1,1.6l0,0C883.4,635.3,885.6,635.6,887.2,635.3z M931.8,716.2c1.9-1,2.5-4.8,3.4-6.7c-2.5,0-5.1-1-7.5-0.7
    c-1,0-1.5,1.6-1.9,2.5l0,0C924.7,717.9,927.2,719.8,931.8,716.2z M936.6,775.3c6,0,11.6-0.7,19.5-1c-6-8.9-12.6-7.5-18.9-6.7
    c-1,0-3.2,2.5-3.2,3.4C934.1,772.9,935.4,775.3,936.6,775.3z M948.3,480.8c-7.5-1.9-16.3-2.5-27,5.1c13.2,2.5,22.9,4.1,32.1,6
    C954.2,486.8,952.7,481.8,948.3,480.8z M810.2,420.7c18.2,0.7,22.3-20,36.8-26.3c-15.4-3.2-24.5,8.2-36.2,14.2
    C806.1,411.3,805.1,416.3,810.2,420.7z M779.1,694.2c-1-0.7-1.5-1-2.5-1.6c-5.1-0.7-11.6,4.8-11.6,10.1c0,1.6,1.5,3.4,3.2,4.1
    c6.6,2.5,8.2-3.4,10-7.3C779.1,698.3,779.1,696.4,779.1,694.2z M785.6,816.4c0.7,0,1.5-1,1.9-1.6c-1-1-2.5-2.5-3.4-2.5
    c-1.5,0.7-2.5,1.9-3.4,3.4C782.8,815.8,784,816.4,785.6,816.4z M857.1,367c1-1,1.9-3.2,1.9-5.1c0-1-1.9-1.9-4.1-4.1
    c-1,3.2-1.5,5.1-1.9,7.5C852.3,366.1,856.1,367.6,857.1,367z M786.2,777c0,0.7,0.7,1.6,0.7,1.6c0.7,0,1.5-0.7,3.2-0.7
    C788.1,777.5,787.2,777,786.2,777z M856.4,290.2c-0.7,0-1,0.7-1.5,1c0,0.7,0.7,1,0.7,1.9c1-0.7,1.9-1.6,2.5-1.9
    C857.6,290.8,857.1,289.9,856.4,290.2z M819,66.1l0.7,1.6l1.5-1.6H819z M952.7,133.7l1.9,0.7v-2.2L952.7,133.7z M782.8,794.4
    c-0.7,0.7-1.5,1-1.9,1.6c0,0,0.7,1,1,1.6c1,0,1.5-0.7,2.5-0.7C783.7,795.9,783.1,795.6,782.8,794.4z M807,694.7
    c0.7,0.7,1.9-0.7,2.5-1c3.4-4.1,0.7-6.7-1.9-9.2c-1,1.9-2.5,4.8-3.2,6.7C804.2,692.5,806.1,694.2,807,694.7z M806.1,728.9
    c-1.5,0-3.4,0.7-5.1,0.7C802.9,729.5,804.5,729.5,806.1,728.9C806.1,729.5,806.1,728.9,806.1,728.9z M809.2,740.3
    c-2.5,2.5-5.1,4.8-8.2,7.3c0,0,1,1.9,1.5,1.9c3.2-1.6,6.6-3.2,9.2-4.8c0.7-0.7,1-1.6,1.9-4.8C811.1,740.3,809.5,739.6,809.2,740.3z
    M807,684c-0.7-1.9-1-3.4-1.5-5.7C803.6,680.9,803.6,682.7,807,684z M809.2,672.7c-0.7,1-1.5,1.6-1.9,2.5l4.1-2.5
    C810.8,673.3,809.2,672.7,809.2,672.7z M796.3,719.8c3.4,3.4,6.6,6,9.2,8.9C804.2,724.8,805.1,719.1,796.3,719.8z M851.3,656.8
    c-11.6-4.8-20.4-8.9-29.6-12.3v0.7c-13.2,1.9-27.4,1.6-33,17.4c0.7-1,1.5-1.6,1.9-2.5c-1,1.6-1.5,3.2-2.5,4.8v-1
    c-3.2,4.8-11.6,3.4-10,12.3c14.8,7.3,20.7-10.8,33.6-11.6c3.4,1.6,7.5,3.4,12.2,5.1C832.5,665,840.6,661.2,851.3,656.8z M913.6,532
    l1.9-0.7l-1.5-1.9L913.6,532z M876.8,541.7c-1.5,0.7-3.2,1.9-4.1,3.4c-0.7,0.7,2.5,3.2,2.5,3.2c1.9-1.6,4.1-3.2,6-5.1
    C879.3,542.7,877.8,541.2,876.8,541.7z M902.3,470.4c0,1.6,4.7,4.1,6.6,3.4c5.1-1,7.5-6,6-10.8c-0.7-2.5-5.1-4.1-10-8.2
    C903.3,461.8,902.3,466.3,902.3,470.4z M905.5,665l0.7-1.9h-2.5L905.5,665z M948.5,533.9c1,0,1.5-0.7,2.5-0.7c0-0.7,0.7-1.9,0-2.5
    c-0.7-0.7-1.5-0.7-2.5-0.7C948.3,532,948.3,533,948.5,533.9z M858.6,520.4c0,0,0-1.9-0.7-1.9c-0.7-0.7-1.9,0-3.4,0
    c-1.5,1.6-3.2,3.2-5.1,4.8c0.7,0.7,1.5,1.6,1.9,1.6C853.9,523.1,856.1,521.6,858.6,520.4z M842.8,549.7c0.7,1.9,5.1,4.8,6.6,3.4
    c3.2-1.6,5.1-4.8,9.2-8.2c-4.7-1.6-7.3-2.5-9.7-2.5C844.5,542.7,840.6,545.3,842.8,549.7z M862,579.1l0.7,1.6h0.7l1-1L862,579.1z
    M860.5,471c1.5,12.3,11.6,7.3,18,7.5c4.7,0,9.2-2.5,6.6-10.8C876.8,469.1,868.6,470,860.5,471z M923.7,703.2
    c-4.1-1.9-8.8-3.2-12.6,0C915.2,703.2,919.6,703.2,923.7,703.2z M860.1,457.1c-1,4.1,0,9.2,0.7,13.9c4.7-7.3,9.2-14.9,13.8-22.2
    C866.1,446,861.7,450.2,860.1,457.1z M918.1,240.6c-1.9,1.6-4.1,3.4-4.7,5.7c0,1,3.2,2.5,6,5.7c1-4.1,1.9-6,1.9-8.9
    C921.8,242.1,918.7,240.2,918.1,240.6z M951.4,517.5l-1.9,1.6l2.5,0.7L951.4,517.5z M908.9,441.3c1,1,3.2,1,5.1,1
    c0-1.6,1-4.1,0-4.8c-1-1-3.2-0.7-4.7-0.7C908.9,438.1,908.6,441,908.9,441.3z M915.2,412.2c0.7,0,1.5,0.7,1.9,0.7
    c0.7,0,1.5-1,1.9-1c-0.7,0-1.5-0.7-1.9-0.7C916.2,411.3,915.5,411.6,915.2,412.2z M801.7,633.7c2.5-3.4,4.7-6.7,6.6-9.2
    c-3.2-1.6-6.6-4.1-9.7-3.4c-1,0-1.5,1.6-2.5,3.2c0,2.5,0.7,4.8,0.7,7.3C797.9,631.5,800.1,632.9,801.7,633.7z M643.2,725.4
    c0.7,0,1-1,1.5-1c-1,0.7-1.9,1-3.2,1.6C642.2,725.7,642.5,725.4,643.2,725.4z M637.5,869.2c0,0,1,1,1.9,1.6c1-1,1.9-2.5,3.2-3.4
    l-1.9-1.6C639.7,866.1,638.4,867.6,637.5,869.2z M899.7,184.4c0.7,0.7,1.5,1.6,1.9,1.6c1.5-0.7,2.5-1.6,3.4-2.5
    c-0.7-0.7-1.5-1.9-1.5-1.6C901.7,182.7,900.7,184,899.7,184.4z M643.2,696.4c1.5,0.7,3.4,1.9,4.1,1.6c1.5-1.6,2.5-3.4,3.4-5.1
    c-1.9,0.7-4.1,1-6,1.9C644,694.2,643.8,695,643.2,696.4z M1034.8,519l0.7,0.7l0.7-0.7C1035.3,519,1034.8,519,1034.8,519z
    M887.2,913.7c0,0-0.7,1-1,1.6c1,0,2.5-0.7,4.1-0.7C888.5,914.7,888.2,914.1,887.2,913.7z M1035.3,519.7L1035.3,519.7
    C1035.3,519.7,1034.8,519.7,1035.3,519.7C1034.8,520.4,1035.3,519.7,1035.3,519.7z M690,926.4c4.1,1.6,8.8-3.2,8.8-9.2
    c-15.4-9.8-26.5,1-38.1,10.1C670.2,922.3,679.9,923.3,690,926.4z M666.1,803.1c-4.1,3.4-7.5,7.3-15.4,13.3
    C665.4,816.4,666.4,810.4,666.1,803.1z M687.8,806.3L687.8,806.3c0.7,0,0.7,0.7,1,0.7l0,0c0,0,0,0-0.7-0.7H687.8z M693.5,858.4
    C694.1,858.4,694.1,858.8,693.5,858.4C694.1,858.8,694.1,858.8,693.5,858.4L693.5,858.4L693.5,858.4z M1034.8,519l0.7,1l0,0
    L1034.8,519L1034.8,519z M701.6,704.9v-1.6l-1.9,1L701.6,704.9z M703.8,686c1.5-0.7,3.2-1,4.7-1.6l0,0
    C706.7,685,705.2,685.6,703.8,686z M705.7,901.8c-0.7-0.7-1-1.9-1.5-1.9c-0.7,0-1.5,1-1.9,1.6c0.7,0.7,1,1.6,1.5,1.9
    C704.2,903,705.2,902.4,705.7,901.8z M1102.4,527.2L1102.4,527.2L1102.4,527.2z M1101.1,530.4c0,0,1.5,0,3.2,0v-0.6
    c-0.7-1-1-2.5-1.5-2.5c0,0,0.7,1,1,2.5C1102.4,530.4,1101.1,530.4,1101.1,530.4z M1101.4,677.6L1101.4,677.6L1101.4,677.6
    L1101.4,677.6z M1144.9,520.4c-11.4,2.5-21.4,7.3-34,19.6C1128.4,535.4,1137.6,528.8,1144.9,520.4z M1145.1,520.4
    C1144.9,520.4,1144.9,520.4,1145.1,520.4C1144.9,520.4,1145.1,520.4,1145.1,520.4L1145.1,520.4z M1145.1,520.4
    C1145.1,520.4,1144.9,520.4,1145.1,520.4c-1-2.5-2.5-5.1-3.4-7.5c-1.5-3.4-8.8-5.1-7.3-3.2C1137.3,513.4,1141.3,516.5,1145.1,520.4
    z M1078.1,711.6c1.9-1,4.1-2.5,5.6-4.1c-1.5-1.9-3.2-3.4-5.6-7.3c-1.5,3.4-3.4,5.7-3.4,7.5C1074.4,709,1077.2,711.6,1078.1,711.6z
    M1155.8,496.6c-8.2,1-16.7,1.9-24.8,3.4c-8.8,1.9-16.7,4.8-25.5,7.3c-3.2-6.7,2.5-7.3,5.1-8.2c8.2-3.2,16.3-6,24.5-7.5
    c6-1.6,12.6-1,19.5-1C1155.6,492.5,1155.8,494.7,1155.8,496.6z M1153,494.7l-1.5-0.7C1152,494.1,1152.4,494.7,1153,494.7z
    M1029,668.6c-3.2,1-5.1,4.8-7.3,7.3c1.9,1.6,4.7,3.2,8.2,5.1c2.5-3.2,4.7-6,6-8.9C1034.1,670.8,1030.7,667.6,1029,668.6z
    M694.5,743.2c0.7,0,0-1.6-0.7-1.9c-1,0-1.9,0-2.5,0.7c-0.7,0,0,1.6,0.7,1.9C692.6,743.7,693.5,743.7,694.5,743.2z M1088.8,576.5
    l-1.5,1l1.9,0.7L1088.8,576.5z M1088.2,680.2c-14.1,1.6-14.8,1.9-23.9,10.1C1076.6,695,1082.5,688.4,1088.2,680.2z M1078.1,481.8
    l1.9-0.7l-1.5-1.2L1078.1,481.8z M1089.2,762.7c6,3.2,10.7,0,14.8-4.8c0.7-0.7,0.7-1.6,1-4.8c-2.5-0.7-3.4-1.6-4.1-1.6
    c-5.1,0.7-12.2-2.5-14.1,5.7C1086.3,759.2,1087.9,761.8,1089.2,762.7z M1111.2,708c-11.4,7.5-11.4,16.7-14.8,27.5l19.5-5.1
    C1114.3,723.2,1113.1,717.2,1111.2,708z M652.9,840.1c0.7,0,1-1,1.5-1.6c-1-0.7-1.5-0.7-2.5-1c-0.7,0.7-1,1-1.5,1.9
    C651.3,840.1,652.3,840.5,652.9,840.1z M849.8-30c-1.5,1-2.5,1.9-4.1,3.2c0.7,0.7,1.5,1.9,1.5,1.9c1.5-1,2.5-1.6,4.1-2.5
    C851.1-29,849.8-30.6,849.8-30z M815.8,331.2l-0.7-1.9c-2.5,1-4.7,1.9-7.3,3.2L815.8,331.2z M830,220.4c-1,1-1.9,3.4-1.5,5.1
    c0,1.6,1.9,3.4,3.2,4.1c2.5,0.7,5.6,0.7,8.8,0.7c2.5-1,5.6-1.6,8.2-3.2c1-1,2.5-3.4,1.9-4.8c-1-2.5-2.5-5.1-4.7-6
    C840.4,214.6,834.1,215.6,830,220.4z M832.5,184.9c-4.7,2.5-8.8,7.3-10.7,12.3c-1.9,4.8,0.7,9.2,5.6,11.6c5,2.4,9.7,1.6,12.6-2.5
    c2.5-3.4,2.5-8.2,4.1-13.9C839.1,189,834.1,184,832.5,184.9z M762.6,663.5l-1-1.9c-0.7,0.7-1.5,1-1.9,1.6
    C760.8,662.8,761.7,662.8,762.6,663.5z M760.2,646.4c0.7,1,3.2,1.6,4.7,1.9v-5.1c-1.5-0.7-3.2-1-4.7-1.6
    C760.2,643.5,759.9,645.5,760.2,646.4z M1069.3,850.2c2.5,0,5.1,0.7,7.3-0.7c1.9-1,3.2-3.4,4.1-5.7c-1.9-0.7-5.6-2.5-6.6-1.9
    C1071.8,843.9,1071,847.1,1069.3,850.2z M810.8,552.8c1.5-0.7,2.5-1.9,3.4-3.4c-1.5,0-3.2-0.7-4.7,0s-2.5,1.9-3.4,3.2
    C808.3,552.5,809.5,553.5,810.8,552.8z M889.2,135.7c-1,0.7-1.9,2.5-2.5,4.1c1.9,1,4.7,1.6,6.6,2.5l1-5.1
    C892.6,136.9,890.7,135.4,889.2,135.7z M912.1,318.9c0.7,0,1.5-0.7,2.5-1c3.2-4.8-1-7.3-2.5-9.8c-2.5-4.8-6-8.9-12.2-16.7
    c-4.1,6.7-6,9.8-8.8,13.9C898.2,309.9,904.8,314.5,912.1,318.9z M886.6,321.5c0.7,0.7,1.5,1.6,1.9,2.5c0.7-0.7,1-1,1-1.9
    c-0.7-0.7-1-1-1.5-1.6C888.2,321.2,887.2,321.5,886.6,321.5z M853.9,190.7c5.1,3.2,10.7,6,15.8,9.2c7.5-8.9,3.2-14.9-1.5-20.8
    C861.7,179.9,855.2,181.5,853.9,190.7z M856.1,108.8c0,0-3.2,0-4.7,0.7c0,3.2,0,6-1,7.5c-6,8.9-4.7,15.9,1.5,23.1
    c4.7,0.7,9.2,1,16.7,1.6c-4.7-7.3-8.2-11.6-10-16.4C856.4,119.9,857.1,114.2,856.1,108.8z M848.2,109.1L848.2,109.1
    C847.9,109.1,847.2,109.1,848.2,109.1z M867.1,88.3c0,0.7,1.9,1.6,1.9,1c1.5-1.9,3.2-3.4,4.7-5.7c-0.7-0.7-1.5-0.7-5.1-2.5
    C867.8,83.5,867.1,85.7,867.1,88.3z M717.1,717.9C717.1,717.9,717.4,717.9,717.1,717.9c-1.9,0.7-3.4,0.7-5.6,1
    C713.3,718.8,715.5,718.1,717.1,717.9z M725.6,829.7c-1,0-1.5,1.6-1.9,2.5c0.7-0.7,1.5-1,2.5-1c1.9-0.7,3.4-0.7,5.1-1l0,0
    C729.6,830.4,727.8,829.7,725.6,829.7z M719.9,847.1c-2.5-1.6-6.6,0-9.7,0c0,3.4-1,8.9,1,10.8c4.7,5.1,9.7,3.2,15.4-3.2
    C724.6,852.1,723,848.7,719.9,847.1z M708.2,684.3c1,1.6,1.5,3.4,2.5,4.1c1,0.7,3.2-0.7,4.7-1c-1-0.7-1.5-1-1.9-1.6
    C712,685.6,709.8,685,708.2,684.3z M673.6,720.6c-3.2,1.6-4.7,5.7-9.2,11.6c8.8-0.7,11.6,0,14.1-1c3.2-1.6,6-3.4,8.8-6
    c0.7,0-1.5-4.1-2.5-4.8C680.9,720.3,676.1,719.8,673.6,720.6z M672.7,783.3v-1c0.7,0,1.5-0.7,1.9-1c1-4.1,2.5-8.2,3.4-12.3
    l-10.7,3.2c1.5,5.7,3.2,11.4,4.1,16.4h0.7c0.7-1.6,1-3.4,1.5-5.1C673.6,783.3,673.1,783.3,672.7,783.3z M698.2,775.3
    c8.8,1.9,10-0.7,12.2-12.6c2.5,0,4.7-0.7,6.6-0.7v-0.7c-1.9,0.7-4.1,0.7-6.6,1c-1.5,1-3.4,2.5-5.6,3.2
    C703.2,769.3,700.7,772.9,698.2,775.3z M672.7,788.9L672.7,788.9C672.7,788.3,672.7,788.3,672.7,788.9
    C672.7,788.3,672.7,788.9,672.7,788.9L672.7,788.9z M759.2,750.4c0.7,0,1-1,1.5-1.6c-1.5,0-2.5,0.7-3.4,1
    C757.7,750,758.9,750.4,759.2,750.4z M766.5,972.6c1.9,0.7,4.1,1.9,6,1.9c1,0,1.9-2.5,3.2-4.1c-3.2-1-5.6-2.5-8.8-3.4
    C766.7,967.8,766.7,970.1,766.5,972.6z M755.8,917.3c1,0,2.5,0.7,3.4,0.7c0-1,0-1.9-0.7-1.9c-0.7-0.7-1.5-0.7-2.5-0.7
    C756,916.3,755.8,916.6,755.8,917.3z M782.8,931.5c1,0,1.5,0,2.5,0.7c0-0.7,0-1.6,0-1.9c-1,0-1.5-0.7-1.9,0
    C782.8,929.9,782.8,931.1,782.8,931.5z M730.3,727c1,0,1.9-0.7,2.5-0.7c-1-0.7-1.9-1-3.2-1.6C730.3,725.4,730.3,726.4,730.3,727z
    M728.1,821.2c0,3.2,1.9,6.7,3.2,9.8c1.5-1.9,4.1-4.1,5.1-6s1.5-5.1,1-7.3c0-1-3.4-1.9-4.7-1.6C731.2,816.4,728.1,819,728.1,821.2z
    M740,767.4l1.5-1l-1.5-0.7V767.4z M411.9,1771.3c2.5,0,5.6,1.6,6.6,0c1.9-1.6,2.5-3.4,4.1-6c-2.5,0.7-5.1,1-7.5,1.9
    C413.5,1767.7,412.9,1769.9,411.9,1771.3z M2120.8,576.6c-7.3-7.3-17.3-12.3-28.6-14.9c0.7,3.4,1,7.3,0,10.8
    c-0.7,1.9-4.1,4.1-6,5.1c-1,0.7-4.1-1.6-4.1-2.5c-0.7-3.4-1-8.2,1-10.1c1-1.6,3.4-2.5,5.6-3.4c-28.6-6-59.1,3.2-41.3,33.1
    c1.9-1,4.1-2.5,6.6-4.1c-1.5,3.4-2.5,6.7-3.2,9.2c3.2,4.1,7.3,8.9,11.6,13.3c5.6,5.7-59.1-18.3-64.2-23.4
    c-17-17.7-86.8-32.3-63.9-9.8c-13.2-13.3-75.5-51.2-60.7-31.3c-0.7,0-1.5,0.7-1.9,0.7c-14.8-11.4-31.4-19-39.6-27.2
    c19.5,19.6-24.5,6.7-56.6-25.6c38.1,38.2-73.9-33.1-60.7-20c-38.7-38.9,21.4,17.4-22.1-26.6V330.4c1-0.7,1.9-1,2.5-1.6l0,0
    c1-1,1-3.4,1-5.7c0-0.7,0-0.7,0-1c-1.5,0-2.5,0-3.4,0v-20c22.9-4.8,54.1-2.5,56.6,0.7c-13.8-13.9,116.7,10.1,70.8-35.4
    c15.8,15.9,33,1.9,51.6-5.7h34.6c4.1,1.9,6,4.1,7.5,5.7c1,1,2.5,1.9,4.1,3.4c-1,0-2.5,0-3.4,0c-0.7,1.9-1.9,4.1-5.1,6.7
    c-0.7,10.8,11.9,34.5,44,66.7c1.9,1.9-33,44.9-40.3,37.9c-12.6-12.6-37.1,20-5.6,51.9c-18.2-18.3-19.5,16.4,12.2,47.8
    c-1.5-1.6,62.5,27.2,64.2,28.7c31.1,31.3,109.1,57.9,69.2,18.3c6.6,6.7,11.6,8.2,16.3,7.5c-0.7-4.1,2.5-8.2,1.5-11.6
    c4.1,0.7,7.5,1.6,11.6,1.9c0,0,0,0.7-0.7,0.7l0.7-0.7l0,0c0-0.7,0.7-1,0.7-1v1c10.7-12.3,20.7-29,44.7-5.1
    c8.8,8.9,16.7,15.9,24.5,22.2c-1,0.7-1.9,1.6-3.4,2.5c3.2,1,6,1.6,8.8,1.6c3.2,2.5,6,4.8,9.2,6.7
    C2145.4,557.7,2106.1,561.8,2120.8,576.6z M1556.8,744c12.2-11.4,26.1-29,24.8-30.4c-10-10.1-18.2-19.6-24.8-27.5V744z
    M1795.5,923.2c-13.8,3.2-34,5.7-43.7,10.8c-0.7,0-0.7,0-1-0.7c-4.7-1.6-9.2-3.2-13.2-4.8c-1.5,3.2-0.7,5.7,3.4,9.2l6.6-1.9
    c-3.4,3.2-3.4,6.7,1,10.8c-16.7-16.7-52.8-22.4-69.8-13.3c0,0.7,0,1.6,0,1.9c-0.7,6-4.1,10.8-8.8,13.3c0.7,8.2,6.6,19.6,20.4,33.1
    c2.5,2.5-7.5-0.7-20.4-5.1c1,0,1.9-0.7,3.2-0.7l0,0c-1.9-0.7-3.4-1-5.6-1.6c-1.5-0.7-3.2-1-5.1-1.6l-2.5-1l1.9-5.7
    c1-3.4,1.9-6.7,3.2-9.8c-2.5-1.6-4.7-1.9-6.6-1.9c-1.5,0-4.1,0.7-7.3,4.1c-0.7,1-1.5,1.9-1.9,3.4c-1,1.9-1.9,4.1-4.1,5.7
    c-6.6-2.5-12.6-5.7-16.3-7.3c0-0.7,0-0.7,0-1c-1.5-5.7-3.2-11.6-5.1-18.1c-3.2-9.8-6-12.3-13.8-12.3c-1.9,0-5.1,0-8.2,0.7
    c-1,1-1.5,1.6-2.5,2.5c-2.5,2.5-4.7,5.1-7.3,7.3l-1,1l-1.5-0.7c-7.3-1.9-13.2-4.1-19.5-6l-12.2-3.4l-0.7-1c0-0.7,0-0.7-0.7-1v-28.7
    c0.7-1,1-1.6,1.5-2.5c-0.7,0-1-0.7-1.5-0.7v-43.6c17.3,7.5,54.1,24.1,55.4,24.9c7.5,7.5,18.9,15.5,29.6,21.5c5.1-1,10-1.9,15.4-3.2
    c12.2-1.9,18.2,1.9,20.4,14.2c0,1,0.7,2.5,0.7,3.4c15.4,3.4,22.1-0.7,3.4-18.3c32.8,32.9,33-49,74.9-7.3
    C1770.7,904.6,1784.5,915.9,1795.5,923.2z M1628.5,902.1c-1-1-1.9-1.6-3.2-2.5c-4.1-3.2-7.5-6-11.6-8.9c-1-0.7-1.9-1.6-2.5-1.6
    c-1,0.7-3.2,3.2-6,6l-1.5,1.6c-4.7,5.1-9.2,9.8-14.1,14.2c-1.5,1.6-2.5,2.5-4.1,4.1c5.6,6,7.3,7.3,13.2,8.9
    c2.5-15.5,4.1-16.7,19.5-19.6C1621.9,904.3,1625.1,903.3,1628.5,902.1z M1556.8,817c6-0.7,10-4.8,15.4-7.5c-4.7,1-10-1-15.4,1.6
    V817z M1574.1,808.5h-0.7c-0.7,0-1,0.7-1.5,0.7c0.7,0,1,0,1.5-0.7c0,0.7,0.7,1,0.7,1.6V808.5L1574.1,808.5z M1558.4,797.1
    c-0.7,0.7-0.7,1-1,1.6v3.4c1-1,1.9-1.9,3.2-3.2C1559.4,798.8,1559,798.4,1558.4,797.1z M1578.2,662.5c-8.2-8.2-15.4-13.9-21.4-17.4
    v5.1C1583.8,672.9,1621.6,705.5,1578.2,662.5z M1809.1,915c3.4,3.4-3.2,6-13.2,8.2C1820,939.3,1831.7,938.1,1809.1,915z
    M1575.7,814.5c-0.7-1.6-1-3.2-1.5-4.1l-0.7,4.8C1574.7,814.5,1575,814.5,1575.7,814.5z M1846.5,964l0.7,1.6l1.5-1.6H1846.5z
    M58.4,583.2h1l0.7-1c0,0,0.7-0.7,0.7-1c-1,0.7-2.5,1.6-3.4,1.9C57.5,583.2,57.8,583.2,58.4,583.2z M1873.5,911.8
    c0,0.7,1,1.6,1.5,1.9c0.7-0.7,1.5-1,1.9-1.6c-0.7-0.7-1-1.6-1.5-1.9C1875.1,910.9,1873.5,911.3,1873.5,911.8z M1874.2,960.6
    c1.5-1,1.5-3.2,2.5-4.8c-1,0-1.9-0.7-2.5-0.7l-3.4,3.4C1871.7,959.9,1873.5,960.8,1874.2,960.6z M18.1,615.8c1,1,1.5,1.9,2.5,3.2
    c0,0,1-1,1.9-1c-1-1-1.9-1.9-2.5-3.2C19.8,614.8,19.1,615.5,18.1,615.8z M58.4,477.7c1-1.9,3.2-4.8,2.5-6.7c-0.7-1.9-2.5-4.1-4.1-6
    c-1,1-1.9,2.5-3.4,3.4c0,1.9-0.7,4.8,0,6.7C54.3,476,56.9,476.7,58.4,477.7z M1827.6,820.3l1.5,0.7v-1.7L1827.6,820.3z
    M1748.3,1079.6L1748.3,1079.6c-1,0.7-1.5,0.7-2.5,0.7c-0.7,0-1.5-0.7-1.9-1c-0.7-0.7-0.7-1.6-0.7-1.9l0,0c0,1.6,0.7,2.5,1,4.1
    C1745.8,1081.3,1746.8,1080.3,1748.3,1079.6z M346.1,1208.5c-4.7,4.1-7.5,6.7-11.4,9.8C342.9,1220.8,345.4,1217.4,346.1,1208.5z
    M1783,1028.5c0-1-0.7-1.6-0.7-2.5c-1,0.7-2.5,1-3.2,1.9c-0.7,0.7,0,1.6,0,2.5C1781.1,1030.1,1782,1029.4,1783,1028.5z
    M1786.4,1133.4c1.5-1.9,3.2-4.1,4.7-6.7c-2.5,0-5.1-0.7-6,0c-1,1-1.9,3.2-1.9,5.1C1783,1131.8,1786.4,1133.4,1786.4,1133.4z
    M1783,1074C1783,1074.6,1783,1074.6,1783,1074L1783,1074L1783,1074L1783,1074z M1742,809.2c1.5,0.7,3.2,1,5.1,1.6
    c0.7-0.7,1.5-1,1.9-1.9c-1.5-1-3.2-1.9-4.7-2.5C1744.2,806,1743.4,807.8,1742,809.2z M1801.8,1151.8c3.2,3.2,5.6,5.7,7.3,8.2
    c1.9,0,3.4,0,5.6,0.7C1810,1158.3,1805.2,1155.6,1801.8,1151.8z M1700.9,736.5c-1,0.7-0.7,3.2-1,4.8c1.5,0,4.1,1,4.7,0.7
    c1-1,1-3.2,1-4.8C1704.6,736.5,1702.1,735.2,1700.9,736.5z M71.3,437.2c-1,1-1.9,2.5-2.5,3.4c0.7,0.7,1.5,1,1.9,1
    c1-1,1.9-1.9,2.5-3.4C72.6,438.1,71.6,437.2,71.3,437.2z M40.1,533.6C40.1,533.6,40.1,533.9,40.1,533.6c1,1,1.9,1,3.2,1.6
    c0-0.7,0-0.7,0-0.7C41.6,533.6,40.3,533.6,40.1,533.6z M53.6,514v1.9l1.9-1L53.6,514z M16.3,485.9c-5.1-0.7-9.7-0.7-14.1-1
    C7.2,491.9,7.4,491.9,16.3,485.9z M40.3,657.8c0,0,1.5,0,3.2-0.7l0,0l0,0l0,0l0,0v-0.7c0,0,0,0-0.7,0c0,0,0,0,0,0.7
    C42,657.8,40.3,657.8,40.3,657.8z M1882.7,1040.8c4.7,1,7.5,4.8,12.2,6c7.5,2.5,14.8-0.7,16.3-7.3c1-4.8-3.4-9.8-10-11.4
    c-8.8-1.9-18-4.8-26.5-7.3c0-0.7,0-0.7,0-1l0,0l0,0l0,0l0,0h-0.7c3.2-5.7,6.6-11.4,9.7-16.7c-1.5-1.6-3.2-2.5-4.7-4.1
    c-6,7.3-11.4,15.9-18.9,20.5c-10,6-8.8,14.2-8.8,23.1c6,0,12.2-0.7,18.9-0.7l0,0h0.7h0.7l0,0
    C1874.5,1041.7,1878.6,1040.2,1882.7,1040.8z M-73.3,541.2c-0.7-1.6-1-3.2-1.9-4.1c0.7,1.6,1,3.2,1.5,5.1l0,0l0,0
    C-73.3,541.7-73.3,541.7-73.3,541.2L-73.3,541.2z M42.5,536.4L42.5,536.4h-0.7H42.5z M127.3,759.6c-1.5,6.7-2.5,9.2-1.9,11.6
    c0.7,1.9,3.2,4.1,4.7,5.7c1-1.9,3.2-4.1,2.5-5.7C132.3,768.4,130.1,765.9,127.3,759.6z M117.2,1110l0.7,0.7
    c1.5,1.6,3.2,3.2,4.7,4.8c0-0.7-0.7-0.7-0.7-1c1.5-1,3.4-4.1,3.2-5.7c0-1.9-3.2-5.1-5.1-5.7c-1,0-1.5-0.7-2.5-0.7
    c-0.7,1.6-1,3.4-0.7,5.7L117.2,1110z M132.6,801.6h-1.9l0.7,1L132.6,801.6z M-79.9,510.8c2.5-0.7,4.7-1.9,7.3-3.2
    c0.7-0.7,0-1.6,0-2.5c-1-0.7-1.9-1-2.5-1c-2.5,1-4.7,1.9-7.3,3.2C-81.5,508.3-80.6,510.8-79.9,510.8z M43.5,606.2
    c3.4,1.9,6.6,4.1,9.7,6c0.7-3.2,0.7-6,1-8.9C49.1,602.1,45.7,603.8,43.5,606.2z M1722.3,963c-1.5,0-3.2,0.7-4.1,0.7
    c1,0.7,1.5,1.6,1.9,1C1720.6,964.9,1721.3,964,1722.3,963z M30.9,644.5c3.4,3.4,8.2,8.2,12.6,12.6c-0.7-7.5,1.5-12.6,10-13.3
    c6.6-0.7,13.2,0,21.4,0c1.5-6.7,2.5-12.6,4.1-20.8c-10,0-18.2,0-26.5,0c0.7-3.4,0.7-6.7,1-10.1c-1.9,4.1-4.1,6-5.6,6s-3.4-1-7.5-6
    c-2.5,5.1-6,9.2-10.7,12.3C29.7,631.3,30.3,637.3,30.9,644.5z M-99.8,353.7c0.7,0.7,1,1,1.5,1c0.6,0,1-1,1.5-1.6
    c-0.7-0.7-1-1-1.5-1C-98.8,352.8-99.5,353.4-99.8,353.7z M67.5,532.3c-0.7,0.7-1.5,1.9-1.5,1.9c1,1,2.5,1.9,3.4,3.2l1.5-1.9
    C70,534.5,69,533.6,67.5,532.3z M64.9,590.8l0.7,1.9c1.5-0.7,3.2-1,4.7-1l-0.7-1.9C68,589.8,66.5,590.2,64.9,590.8z M1902.8,999.4
    c-2.5-3.2-5.6-6.7-9.2-9.2c-1-1-3.4-0.7-6.6-1c-2.5,3.2-5.1,7.3-8.2,10.8c4.7,1.6,8.8,2.5,13.2,4.1
    C1895.9,1005.4,1899.7,1005.4,1902.8,999.4z M1878.6,1000L1878.6,1000C1878.6,1000,1878.3,1000,1878.6,1000l-0.7-0.7
    C1878.3,999.4,1878.3,999.4,1878.6,1000c-0.7-0.7-0.7-0.7-0.7-0.7C1878.3,1000,1878.3,1000,1878.6,1000L1878.6,1000L1878.6,1000
    L1878.6,1000z M1883.2,1003.8c1,1.6,1.5,3.4,2.5,5.1C1884.9,1007.6,1884.2,1005.4,1883.2,1003.8l0.7-0.7
    C1883.9,1003.5,1883.9,1003.5,1883.2,1003.8C1883.2,1003.8,1883.2,1003.5,1883.2,1003.8C1883.9,1003.5,1883.2,1003.8,1883.2,1003.8
    L1883.2,1003.8L1883.2,1003.8L1883.2,1003.8z M16.6,373.4c-0.7,1-0.7,4.8,0,4.8c4.1,1.6,8.8,3.2,12.6,3.4c1.5,0,3.2-3.2,4.1-4.8
    C29.5,369.5,20.3,368.3,16.6,373.4z M17.2,400.5c0.7,0.7,0.7,1.9,1,1.9c3.2,0,6.6,0,9.7,0v-2.5c-3.2-0.7-6-1-9.2-1
    C18.8,398.3,18.1,399.2,17.2,400.5z M1.8,484.9L1.8,484.9C2.5,484.9,2.5,484.9,1.8,484.9L1.8,484.9z M73.7,439.4
    c-2.5-1-5.1-1.9-7.5-2.5c-0.7,0-1.5,3.2-1.9,4.8c2.5,1,5.1,2.5,7.5,3.2c0.7,0.7,1.9,0,5.6-1C75,441,74.6,439.7,73.7,439.4z
    M86.3,571.9c6-1,12.2-1.9,18.2-3.4h0.7C99.2,569.3,92.9,570.2,86.3,571.9C86.3,571.2,86.3,571.9,86.3,571.9c-0.7-0.7-1.5-1-1.9-1
    c0.7,0.7,1,1,1.5,1H86.3z M76.5,480.8c-0.7,0.7-0.7,1.6-1,1.9c1,0,1.9,0,3.4,0.7c0-0.7,0-1.6-0.7-1.9
    C78.2,480.8,77.2,480.8,76.5,480.8z M324.8,503.8c0,0.7,0.7,0.7,0.7,1C325.5,504.2,325.5,503.8,324.8,503.8L324.8,503.8z
    M518.9-350.8c-1,1-3.2,2.5-3.2,3.4c0,1.6,1.5,3.2,1.9,4.8c1.5-1.9,3.2-3.4,4.7-5.1C521.4-348.8,520.5-349.8,518.9-350.8z
    M315.8,1632.9c1.5,2.5,5.6,6,7.5,5.7c6.6-1.6,5.1-6,3.2-10.1C317.4,1627.3,314,1628.8,315.8,1632.9z M326.5,1627.6v0.7h0.7
    L326.5,1627.6l1,0.7c0-0.7,0-0.7,0-1C327.2,1627.6,327.2,1627.6,326.5,1627.6L326.5,1627.6z M247.7,1213.4c0,0,0,0.7,1,1.6
    C248.7,1214.9,248.7,1214.3,247.7,1213.4z M-69.2,608.8c0-0.7,0-0.7,0-0.7c0,0.7-0.7,0.7-0.7,1c0,0,0,0,0.7,0
    C-69.9,609.1-69.9,609.1-69.2,608.8c0,0.7,0.7,0.7,0.7,0.7C-68.9,608.8-69.2,608.8-69.2,608.8L-69.2,608.8z M43.6,889.1L43.6,889.1
    c0.7,0.7,1,1,1.5,1l0,0h0.7c0-0.7-0.7-0.7-0.7-1C44.6,889.1,44.3,889.1,43.6,889.1L43.6,889.1z M-84,608.8c-0.7,0.7,0,4.8,1,5.1
    c1.9,1,5.6,1.6,7.5,0.7c1.9-1,3.4-3.2,5.6-5.1c-2.5-1.6-4.7-3.4-7.3-3.4C-79.6,605-82.1,607.3-84,608.8z M20.7,892.6
    c5.6,7.5,16.3,8.2,24.8,1.6c0.7-0.7-1-3.2-1.9-4.8c-7.3-2.5-14.8-5.1-23.3-8.9C20.3,885.9,19.1,890.7,20.7,892.6z M59.1,553.5
    c-6.6,1.6-11.4,2.5-15.8,3.4l0,0l0,0l0,0l0,0l0,0C47.7,556.9,53.4,561,59.1,553.5z M325.5,504.8L325.5,504.8L325.5,504.8
    L325.5,504.8z M678.4,105c0.7,1,1,1.9,1,2.5c0.7-0.7,1.5-1,1.9-1c0-0.7-0.7-1.6-1-1.6C679.9,105,678.7,105,678.4,105z M729.3,236.1
    c1,1,1.9,1.6,3.2,1.9l1.9-4.1c-1.5,0-2.5-0.7-4.1-0.7C729.6,234.6,729.3,236.1,729.3,236.1z M1849.6,650.8c0,0.7,0.7,0.7,1,1
    C1862.5,663.7,1858.4,659.6,1849.6,650.8z M29.5,71.2c0.7,0,0.7,0,1,0.7l0,0L29.5,71.2z M21.3,596.8l-0.7-1.9L6.5,599l0,0
    C11.6,598.1,16.6,597.4,21.3,596.8z M302.8,7.4c-0.7-0.7-1-0.7-1.9-1c-0.7,0-0.7,0-1,0c-0.7,0-0.7,0-1,0c0.7,0.7,1,0.7,1,1l0,0
    c0,0,1,0.7,1.5,0.7c0,0,0,0,0.7,0C302.8,8,302.8,8,302.8,7.4z M366.1,25.6c-3.2,0-5.6-0.7-7.5-0.7c-1,0-1.5,0-1.9,0H356l-0.7-0.7
    c-6.6-2.5-8.2-7.3-9.7-10.8c-1-2.5-1.5-4.1-4.1-4.1c-0.7,1-1.5,1.6-1.9,2.5c-1.5,1.6-3.2,3.4-4.7,5.7c1.5,7.5,3.2,15.5,5.1,24.1
    c1-0.7,1.5-1,2.5-1c4.7-1.9,8.2-3.4,12.2-5.1l0,0h0.7h0.7c1.5,0,2.5,1,4.1,3.2c0,0,0,0,0-0.7c1.9-2.5,4.1-5.1,5.6-8.2
    c0.7-1,0.7-2.5,0.7-4.1C366.1,26.3,366.1,26.3,366.1,25.6z M448.1,41.8c-2.5-5.7-5.1-6-6.6-6c-1.9,0-4.1,1-6.6,2.5
    c3.2,4.8,3.4,5.1,5.6,5.1C441.8,43,444,42.7,448.1,41.8z M449.7,61.1c0,0-0.7,0-0.7,0.7C449.1,61.7,449.1,61.7,449.7,61.1
    C449.7,61.7,449.7,61.1,449.7,61.1z M461.4,51.2c-1.9-1-3.4-1.6-5.6-2.5c0,0,0.7,2.5,0.7,3.4C457.8,51.9,459.2,51.9,461.4,51.2z
    M64.3,348.4c1-7.3,1.9-13.9,3.2-21.5c-1,0-1.9,0.7-3.4,0.7c-4.1,1-7.5,1.6-11.4,1.6H52c-7.3,0-8.2,3.4-8.8,11.6
    c1,0.7,1.9,1,3.2,1.6c3.4,1.9,6.6,3.4,10,5.1l0,0c1.5,0.7,3.4,0.7,5.1,0.7c0.7,0,1,0,1.5,0C63.9,348.4,64.3,348.4,64.3,348.4z
    M108.6,348.7c-1.5,0-2.5-0.7-4.1-0.7c-5.1-0.7-9.7-1-14.8-1c-8.8,0-15.4,1.6-20.7,5.7c-1,2.5-1.5,5.1-1.9,7.5
    c-0.7,2.5-1.5,5.1-1.9,7.3c0.7,1,1,1.9,1.5,3.2c1.5,3.4,3.2,7.3,5.6,9.8c1.9,2.5,4.7,4.8,7.5,6.7c1,0.7,1.5,1,2.5,1.9
    c4.1-5.7,7.5-10.1,11.6-15.5c-3.4,0-7.5,0-12.2-0.7h-7.3l5.1-4.8c5.6-4.8,8.2-7.3,11.4-7.3s5.6,2.5,7.5,6.7
    C105.8,361.9,110.2,356.9,108.6,348.7z M7.4,246c-0.7,0-1,0.7-1.5,0.7l1,2.5l0,0C7.4,246.9,7.4,246,7.4,246z M24.4,261.1
    c-0.7-1.6-1-3.4-1.5-5.1c-0.7,0-0.7,0-1,0c0,0,0,0-0.7,0c0.7,1.9,0.7,3.4,1,5.1C22.9,261.1,23.2,261.1,24.4,261.1
    C23.9,261.1,24.4,261.1,24.4,261.1z M33.6,84.2l-0.7-0.7c0,1.6,0.7,2.5,0.7,3.4C33.6,85.7,33.9,84.2,33.6,84.2z M100.3,322.5
    L100.3,322.5c-0.7,0-0.7,0-0.7,0C99.7,323,99.7,323,100.3,322.5C100.3,323,100.3,322.5,100.3,322.5z M1853.7,1103.4
    c-4.7-4.1-10-5.1-16.7-5.1c3.4,4.1,7.5,6,11.6,6C1850.3,1103.7,1852.1,1103.7,1853.7,1103.4z M337.9,1456.9c-0.7-0.7-1-1-1.5-1.6
    c-1,2.5-2.5,5.7-3.2,8.2c0,0.7,0.7,1,1,1c1.5-0.7,2.5-1,3.2-1.6l0,0l0,0c0.7-1,0.7-2.5,0.7-4.8
    C337.9,1457.9,337.9,1457.2,337.9,1456.9z M348.9,1529c-0.7-3.4-1-6.7-1.9-9.8c-1.5,1.6-2.5,2.5-2.5,3.2
    C344.7,1523.9,346.4,1525.8,348.9,1529z M222.6,1216.5c0.7,0.7,1.5,1,1.9,1c1.5-1.6,3.2-3.2,3.4-4.8c-0.7-0.7-1.5-1.6-2.5-1.9
    c-0.7-0.7-0.7-0.7-1-1c0,0.7-0.7,1-0.7,1.6C223.2,1213.4,222.6,1214.9,222.6,1216.5z M111.3,1173.8c4.1,2.5,6.6,3.4,8.2,3.4
    c0.7,0,1-0.7,1.9-1.6c-1.9-1.6-4.1-3.2-6-3.2C113.7,1172.9,112.1,1172.9,111.3,1173.8z M1846.2,1210.2c-0.7,0-1-0.7-1-0.7
    S1845.5,1210.2,1846.2,1210.2L1846.2,1210.2z M1889.9,1122.6c-2.5-1-5.1-1.9-7.5-3.4c-3.2-2.5-6-5.7-8.8-8.9
    c-1-1.6-2.5-2.5-3.4-4.1c-1.5-1.6-2.5-1.6-3.2-1.6c-1.5,0-3.2,0.7-4.7,1c-0.7,0-0.7,0.7-1,0.7c-0.7,1.6-0.7,2.5-0.7,4.1
    c0,1-0.7,1.6-0.7,2.5c1.5,0.7,2.5,1,4.1,1c4.7,1.6,8.8,2.5,13.2,4.8c0.7,0.7,1,0.7,1.9,1c3.2,1.6,5.6,3.2,8.8,3.2
    C1888.3,1123.3,1889,1123.3,1889.9,1122.6z M1906.3,1086c-4.1-1.9-7.3-3.2-10-3.2c-3.4,0-6,1.6-8.2,5.1c-3.2,5.7-1.5,10.1,6.6,18.1
    c1.9-3.4,4.1-6.7,5.6-10.1C1902.8,1092.1,1904.6,1088.8,1906.3,1086z M19.1,775.1c-1.9-0.7-3.4-0.7-5.6-0.7c-1.5,0-1.9,0-3.2,0.7
    c-1.5,0.7-2.5,2.5-4.7,5.1c0,0.7-0.7,0.7-0.7,1c0.7,0,1.5,0,1.9,0c3.2,0,4.7,0,5.6-0.7c2.5-1,4.7-2.5,6.6-4.1
    C19.8,775.3,19.1,775.3,19.1,775.1z M3.3,670.1c0,7.5,2.5,12.3,8.8,13.9c0.7-0.7,0.7-1,1-1.6c1-1,1.9-3.2,1.9-3.4l0,0l0,0l0,0l0,0
    c-1-4.1-4.1-8.2-7.3-12.3L7,666c-0.7,0-0.7,0-1,0H5.8C4.3,667.9,3.3,669.4,3.3,670.1z M4.3,740.6c0,0.7,0,1,0,1.9
    c0.7-0.7,0.7-1,0.7-1C5,741.3,4.3,740.6,4.3,740.6z M-13.9,729.5L-13.9,729.5c-1,0.7-1.5,1-1,1.6c0,0,0,0,0,0.7
    C-14.2,731.1-13.9,729.9-13.9,729.5z M-8.9,815.5l-2.5,1l-1,1L-8.9,815.5L-8.9,815.5z M10.6,824.1l-3.4,1c0.7,1.6,1.5,5.7,1.5,5.7
    C9.6,828.2,10,826.3,10.6,824.1z M42.7,819.6c-1.5,0.7-3.2,1-5.1,1c-3.2,0-5.6-1-8.2-3.2c-7.5-6-16.3-8.2-24.5-9.8
    c-1.9,1-3.4,2.5-5.6,3.4c-2.5,1.6-4.7,3.2-7.3,4.8c0.7,0.7,1,1.6,1,1.9l1.5,0.7c2.5,0.7,5.1,1,7.5,1.6c1.5-1.9,4.1-4.1,7.5-4.1
    c1,0,1.9,0,3.2,0.7l0,0l2.5,1c1.9,1,3.4,1.6,5.6,2.5c4.1,1.9,8.2,4.1,12.2,5.1c1.9,0.7,3.4,0.7,4.7,0.7
    C40.5,825.3,41.7,824.6,42.7,819.6z M66.7,693.2c0.7,0,1,0,1.9-0.7v-0.6C68.2,692.3,66.7,692.5,66.7,693.2z M71.3,870.2
    c-0.7,0-0.7-0.7-1-0.7c-1.5,1-3.2,2.5-3.2,3.2c0,1.6,0.7,3.2,1.5,4.8c0.7-1,1.5-1.6,1.9-2.5C70.7,873.3,71.3,871.7,71.3,870.2z
    M128.9,486.4c-3.4,0.7-5.6,1.9-6.6,5.1C124.8,490,127.3,488.4,128.9,486.4z M140.6,677.4c-1.5-1-3.4-1.9-4.7-1.9
    c-0.7,0.7-1.5,2.5-1.5,4.8c0.7,0.7,1.5,1,1.9,1.6c0.7,0,0.7,0.7,1,0.7C138,680.2,139.6,678.6,140.6,677.4z M294.4,544.3l6,6l1-0.7
    l2.5,4.1c4.1-2.5,7.3-5.7,8.2-10.1c-3.2-1.6-5.6-2.5-8.8-2.5C301,541.2,297.8,542.1,294.4,544.3z M189.3,189c3.2,0.7,6,0.7,9.7,1
    c-0.7-0.7-0.7-1-1-1.6c-2.5-4.1-5.1-7.3-6-10.8c-1.5-3.4-1.5-7.3-1.9-10.8c0-0.7,0-1,0-1.6c0,1.9-0.7,3.4-1.5,5.1
    C183.6,176.7,183.6,182.5,189.3,189z M164.7,257.6c1.5,0.7,3.2,1,4.1,1c1.9,0,3.4-1,5.1-3.2c1.5-1.9,1.9-4.8,2.5-7.5
    c0-1,0.7-1.9,0.7-3.2c-1-1-1.9-1.6-3.4-2.5c-1.5-1-3.4-2.5-4.7-3.2c-3.4,1.9-7.3,6-8.8,10.1C158.8,252.9,161.3,256.1,164.7,257.6z
    M156.2,597.4v-0.7c-0.7,0-1.5-0.7-2.5-0.7c0,0.7,0,1,0,1.6C154.7,597.4,155.6,597.4,156.2,597.4z M174.2,448.5
    c-6,1-11.4,4.8-16.7,8.2c-3.2,1.9-6,4.1-9.2,6c-1.5,0.7-1.9,1.6-2.5,3.2c0,1,0.7,2.5,1.5,3.4c8.8-0.7,13.8-6,19.5-12.6
    C168.8,453.6,171.7,451.1,174.2,448.5z M251.3,400.6H251c-0.5,1.1-0.8,2.7-1,4.4C250.7,403.3,250.8,402.4,251.3,400.6z
    M183.3,276.6c0.7-0.7,1-1,1-1.6c-1-2.5-2.5-4.1-3.2-4.1c-1.5-0.7-3.2-0.7-4.1-0.7c-3.2,0-6,1.6-8.2,4.1c-0.7,0.7-1,1.9-1,2.5l0,0
    l0,0c0,0.7,1,1.6,1,1.9c1,0.7,2.5,0.7,3.4,0.7c0.7,0,1,0,1.9,0c0.7,0,1,0,1.5,0c0.7,0,1.5-0.7,1.9-0.7
    C180.2,278.2,181.7,277.6,183.3,276.6z M189.3,598.4c-1.9-0.7-3.2-1-4.1-1l0,0c-1.5,0-3.2,0.7-3.4,1.6c-0.7,0.7-0.7,1,0,1.9
    c0.7,0.7,2.5,1.9,3.4,1.9C186.5,602.2,187.4,600.6,189.3,598.4L189.3,598.4z M196.5,242.8c3.2,1.6,5.6,3.4,8.8,5.1
    c3.4-5.1,1.9-9.2-1.9-14.2c-5.6,1-8.8,2.5-10,7.3L196.5,242.8z M198.4,514.6c1-1.6,1.9-3.2,3.2-4.8c1.5-2.5,3.2-5.1,4.7-7.5l0,0
    c-3.4,0-6,2.5-7.5,7.3C198.4,510.8,198.4,512.4,198.4,514.6z M219.5,522.8c-7.3,1-13.8,1.6-19.5,2.5c1,3.2,3.2,3.2,5.1,3.2
    c1.5,0,3.2,0,4.7-0.7c1.5,0,3.2-0.7,4.7-0.7h0.7l0,0c1.5,0,3.2-0.7,4.1-1.6C219.5,524.7,219.5,523.8,219.5,522.8z M225.4,399.2
    c-1-2.5-3.4-6-4.7-7.3c-1,0-1.9,0-3.2,0c-4.1,0-8.2-1.6-11.4-2.5c-0.7,0-1.5-0.7-1.9-0.7c-1,0-3.2,1.6-3.4,2.5l0,0
    c-0.7,1.6-0.7,4.1,0,5.1c3.4,5.1,7.5,7.3,13.2,7.3c1.9,0,4.7-0.7,7.3-1c0.7-0.7,0.7-0.7,1-1C223,401.7,224.5,400.5,225.4,399.2z
    M230.5,355.3c6,4.1,11.6,8.2,18,11.6l0,0c0.7-1,0.7-1.9-1.5-4.1c-0.7-0.7-1-1.6-1.5-1.9l0,0c-1.5-3.2-3.4-5.7-6-9.2
    c-1-1.6-1.9-2.5-3.2-4.8c-1.5,2.5-3.2,4.8-4.1,6.7C231.7,354.4,231.1,354.6,230.5,355.3z M248.5,514.9c0-0.7-1.9-1.9-4.1-3.2
    c-0.7-0.7-1-0.7-1.9-1c0,0.7-0.7,1.6-0.7,1.9c-0.7,3.4-1,5.7-1,8.2c0.7,0.7,2.5,1.6,3.4,1.6l0,0c1.5-0.7,3.2-1,3.4-2.5
    C249,519,249.3,517.1,248.5,514.9z M255,295.6L255,295.6c-1,0-1.9,1-2.5,1.6c0.7,0.7,1,0.7,1.5,1l0,0
    C255,297.4,255,296.6,255,295.6z M284.5,158.8c-6-3.4-11.6-7.3-17.3-10.1l-16.7,4.8c0,0.7,0,1,0,1.6c0,3.2,0,5.7-1,8.9
    c-1,1.9-1.9,4.1-3.4,5.7c-1,1.6-1.9,2.5-2.5,4.1c-1.5,5.1,0.7,10.8,6,13.9c1.5,1,3.4,1.6,5.1,1.6c2.5,0,4.7-1,7.3-3.4
    C267.9,177.4,274.8,169.2,284.5,158.8z M288.1,205.9l-0.7-0.7c-0.7,0.7-1.5,1-1.9,1.6l0.7,0.7C286.5,207.1,287.1,206.4,288.1,205.9
    z M314.8,232.7c1.5,3.2,3.4,6.7,5.6,8.9c0,0,0.7,0,1,0c1,0,2.5-0.7,3.2-0.7c0.7-0.7,1-1.6,1.5-3.2c0-0.7,0.7-0.7,0.7-1
    c-0.7-0.7-1.5-0.7-1.9-1c-3.4-1.6-6.6-2.5-9.7-3.4C315.1,232.7,315.1,232.7,314.8,232.7z M336.5,342c-1.5,0.7-2.5,1-4.1,1.6
    c-4.1,1.6-8.2,2.5-12.2,4.8c-1.9,1-4.1,1-5.6,1c-1,0-1.9,0-3.2,0c-1.2,0-1.5,0-2.5,0c-1.9,0-3.4,0.7-4.7,1.9c0.7,0.7,1,1,1.5,1.6
    c2.5,2.5,4.7,5.1,4.1,7.5c-1,3.4-3.4,5.7-7.5,5.7c-1.5,0-3.4-0.7-5.6-1c-1.5-0.7-3.2-0.7-5.6-0.7c6,9.2,12.6,12.6,22.3,12.6l0,0
    c5.1,0,11.6-1,19.9-3.4c0.7-4.8,2.5-10.1,8.2-13.9l0,0C343.7,352.2,344.7,346.8,336.5,342z M346.2,162.9c-1-0.7-1.5-1-1.9-1
    c-0.7,0-1.5,0.7-1.9,1c0,0.7,0,1,0,1s0.7,0,1,0l0,0c0.7,0,1,0,1,0C344.7,164.1,345.6,163.4,346.2,162.9z M354.7,299.1
    c-0.7-1-1.9-1-3.4-1c-1,0.7-3.4,2.5-4.1,4.1c1,3.4,3.2,4.1,4.7,4.1c1,0,1.9,0,3.2-0.7C355.4,303.2,356,300.7,354.7,299.1z
    M361.6,225.7V225l0,0c-0.7,0-1.5,0-1.9,0.7c-0.7,1.9,0,4.8,0,7.3c0.7,0,0.7,0,1,0.7l0,0l0,0c0.7-0.7,1-1,1-1
    C362,229.8,361.6,227.9,361.6,225.7z M379.8,326.6c0-3.2-1-6-2.5-9.8c0-0.7-0.7-1-0.7-1.9c-5.1,2.5-5.6,6.7-5.1,12.3
    c0.7,1,3.2,3.2,4.7,3.4C377.6,330.4,379.8,327.8,379.8,326.6z M439,351.9c-1,0.7-1.9,0.7-2.5,1c-7.5,3.4-8.8,3.4-12.2,14.9
    c1.9-1.9,4.1-4.8,6-7.3C432.7,357.8,435.2,354.4,439,351.9z M440.3,394.2C440,394.2,440,394.2,440.3,394.2L440.3,394.2
    c-1.5,0.7-4.1,1-4.7,1.6c0,0,0,0.7,0,1c0.7,0,0.7,0,1,0c0.7,0,1,0,1.5,0.7C439.3,396.4,440,395.1,440.3,394.2z M441.5,241.9
    c-1.5,0-3.2,0-4.1,0c-0.7,0-1,0-1.5,0c-1.5,0-1.9,0.7-2.5,0.7c0,0.7,0.7,1.6,1.5,4.1c1.5-1,3.4-1.9,5.1-3.2
    C440.3,242.1,441,241.9,441.5,241.9z M483.6,448.5c-0.7,0-1.5-0.7-1.5-0.7c-0.7,0.7-0.7,1.6-1,1.9l0,0
    C482.1,449.5,482.8,448.9,483.6,448.5z M792.2,453v0.7c0.7,0,1.5,0.7,2.5,0.7c0-0.7-0.7-1-0.7-1.6
    C793.5,452.6,792.9,452.6,792.2,453L792.2,453z M792,486.8c0.7,1,1,1.6,1,1.6c0.7,0,1.9-0.7,3.2-1c-0.7,0-0.7,0-1,0
    C794.4,487.4,793.5,487.4,792,486.8z M761.4,518.7c0,0.7,0,1,0,1c0,1.9,0.7,4.8,1,6c0.7,0.7,1.9,1,3.2,1l0,0
    c0.7-0.7,1.9-2.5,1.9-3.2l0,0c-0.7-3.4-1.9-5.7-4.1-5.7C762.6,518.1,761.7,518.7,761.4,518.7z M743.4,443.5c0.7,0.7,1.5,1,1.9,1
    c0.7-0.7,1-1.6,1.5-2.5c0,0,0-0.7,0.7-0.7c0,0,0,0-0.7,0c-0.7-0.7-1.5-1-1.9-1C744.1,441,743.4,442.2,743.4,443.5z M704.2,660.9
    c-0.7-4.1-0.7-7.3-1-10.1c-5.6,1.6-7.5,5.1-9.2,12.6c3.4-1,6-1.6,9.2-2.5H704.2z M712.3,303.2c3.2,0.7,5.6,0.7,7.5,0.7l0,0
    c4.7,0,6-1,9.7-7.5c-6.6,1-10,1.9-12.6,3.2C714.9,300,713.9,301,712.3,303.2z M707.4,432.8c1-1.6,2.5-2.5,3.4-4.1l0,0
    c-1,0.7-3.4,1.6-4.1,1.9C706.7,431.5,706.7,432.1,707.4,432.8z M691.9,531c-0.7,1.6-1,5.1-1,6.7c10,5.1,13.8,12.6,11.4,23.1h0.7
    c0-0.7,0.7-1,0.7-1.6c1.5-4.1,3.4-8.2,5.6-11.6c1.5-3.4,3.4-6.7,5.1-9.8c0.7-1,1-1.6,1.5-2.5c-1-0.7-1.5-0.7-1.9-0.7
    c-1,0-2.5,0.7-4.1,1c-1.5,0.7-3.4,1-5.6,1c-3.4,0-6-1.6-7.5-5.1c-0.7-1-1-1.6-1.9-1.6C693.5,529.4,692.6,529.8,691.9,531z
    M685,356.9c1.5,1.6,2.5,1.9,3.2,1.9s1.9-0.7,3.4-4.1c-0.7,0-1,0-1.5,0C688.4,354.6,686.8,355.3,685,356.9z M669.5,240.2
    c0.7,0.7,1,1.6,1,1.9c2.5-1,5.1-1.9,7.3-3.2c0,0,0,0,0.7,0c-0.7-0.7-1-1-1-1.6c-1-3.2-1.5-6-1.9-10.1c0-0.7,0-0.7,0-1
    c-0.7,0.7-1,1-1.5,1.6c-3.2,2.5-5.6,5.1-6,7.3C667.1,235.6,668.6,238,669.5,240.2z M629-37.5c0.7-1,1-2.5,0.7-4.1
    C629.3-40.6,629-39.1,629-37.5z M603.8,30.4c0.7,1.9,2.5,4.8,5.1,6c0,0,0,0,0.7,0c1,0,2.5-0.7,3.2-1c1-2.5,1.5-6,1-6.7
    c-1.5-1.6-4.1-3.2-7.3-4.1c0,0-0.7,0-0.7-0.7C604.4,27,603.8,28.9,603.8,30.4z M555.1-24.9c5.1,0,8.8-0.7,10-3.4
    c-0.7,0-1.5,0-1.9,0C560.1-28.3,557-28,555.1-24.9z M546.9,7.4c-0.7,1.9-1.5,4.1-2.5,7.3l0,0c0.7,0,1.5,0,1.9,0
    c4.7,0,8.8,0,12.2,1.6c3.2,1,5.1,2.5,7.5,4.1c2.5,1.6,4.7,3.2,7.3,4.1l0,0c0.7,0,1,0,1.9,0c1.9,0,4.1-0.7,6-1
    C573.7,10.2,569,7.1,555.7,7.1C553.5,6.7,550.4,6.7,546.9,7.4z M513.9,42.2c1,0.7,1.9,0.7,2.5,0.7c0.7,0,1.5,0,2.5-1.6l0,0
    c-1,0-1.5,0-2.5,0C515.4,41.3,514.2,41.8,513.9,42.2z M509.8,76c-1.9,3.4-4.7,6-7.5,7.5c1,0,1.5,0,2.5,0c1.9,0,3.4,0,6-0.7
    c4.1-1,8.2-1,12.2-1h3.2c-0.7-1.9-1-3.4-1.5-5.7c-1,0-1.9,0-3.4,0C516.7,76,513.2,76,509.8,76z M502.5,291.5l-0.7,0.7
    c1,1,1.5,1.9,1.9,1.9c0.7-0.7,1-1,1.5-1.9C504.2,291.8,503.2,291.5,502.5,291.5z M498.4-61.3c3.2,0.7,5.1,0.7,6.6,0.7
    c0.7,0,1,0,1.5,0c0.7,0,1.5-1,1.9-1.9c-0.7-0.7-1.5-1-1.9-1.6C504.7-63.8,502-62.8,498.4-61.3z M492.8,334.5
    c9.7-1.6,13.8-8.2,18-16.7c-1.5,0-3.2,0-4.7,0C498.4,317.4,497.5,318.1,492.8,334.5z M475.5,200.1h0.7h0.7l0,0l0,0
    c1-0.7,1.9-1,1.9-1.9c0.7-1,0-2.5-0.7-3.4c0-0.7-0.7-1-1-1.6c-0.7,0-0.7,0.7-0.7,0.7C476.1,196.3,475.5,198.2,475.5,200.1z
    M449.7,231.4L449.7,231.4c3.2-4.1,3.4-6,0-8.9C445.9,225.4,447.2,227.3,449.7,231.4z M494.3,376.8c3.4,1.9,6.6,4.8,10,7.5
    c0,0,0,0,0.7,0.7c1-2.5,1.9-5.7,3.2-8.2c1.9-6,4.1-11.6,6-17.4c-1-1.6-1.9-2.5-3.2-4.8c-5.1,5.1-10,9.2-15.4,14.2l-1.5,1.6
    l-1.9-1.6c-1.5-1-2.5-1.9-3.4-3.2c-1-1-3.2-3.2-4.1-3.2l0,0l0,0c-0.7,0-1,0-1.9,0c-13.8,0-16.7-10.1-18.2-17.4
    c-1-4.8-1.5-9.8-1.9-14.2c-0.7-1.9-0.7-3.4-1-5.7v-0.8l0.7-1c3.2-4.1,6-8.2,9.7-12.3c1.9-2.5,4.1-5.7,6-8.2l0,0l0,0
    c-1.9-1.9-3.4-3.4-5.6-5.1c-1-0.7-2.5-0.7-4.7-1c-0.7,0-1,0-1.5,0l-1.5,1.9c-4.1,5.7-8.2,11.4-11.6,16.4c0,0.7,0.7,0.7,0.7,1
    c1,2.5,1.9,4.8,2.5,7.3c3.4,13.3,1.5,19.6-8.2,24.9c1,0,1.5,0,2.5,0c2.5,0,4.7,0.7,7.5,1l1,0.7l0.7,1c0.7,1,1.5,2.5,2.5,4.1
    c1.9,3.2,4.1,7.5,5.6,11.6c1,4.1,3.4,4.8,7.5,5.1l-0.7-1l1,0.7l-0.7-1.9l3.2,3.2h0.7l1.5-1.6l1,1.9c1,0,1.5,0.7,2.5,0.7
    C487.2,374.3,490.9,375.3,494.3,376.8z M518.9,460.8L518.9,460.8c-0.7-1-1-1.9-1.9-3.2c-0.7,1.9-0.7,4.1,0,6c0.7,0.7,1.5,0.7,1.9,1
    C519.2,462.8,519.2,461.8,518.9,460.8z M526.1,409.1c-5.1-1.9-10-3.2-15.8-3.2c-3.2,0-5.6,0.7-8.8,1c1.9,0,4.7,0,6.6,0h1.5l1,1
    c3.2,4.8,6,9.2,7.3,9.2C519.2,417.3,523,413.8,526.1,409.1z M529,69.3c1.9-0.7,4.1-1,5.6-1.6c0-1,0.7-1.9,0.7-3.2
    c0-1,0-2.5-0.7-3.4c-1-1-1.9-1-3.2-1.6h-0.3c-1.5,0-1.9,0-1.9,5.1c0,1,0,1.9,0,3.2C529.6,68.7,529.6,68.7,529,69.3z M533.1,83.2
    c3.2,1.6,6.6,1.9,10,1.9c6.6,0,12.6-1.9,20.4-5.1c3.4-1.6,7.5-3.2,11.4-4.8c1-0.7,5.6-1.9,5.6-1.9l1,1.6
    c8.2,11.4,1.5,16.7-3.2,21.5c-1.9,1.6-3.4,3.2-4.7,5.1c1.5,1.6,3.2,3.2,5.1,4.8c1.5,0.7,3.2,1,5.1,1c3.4,0,7.5-1.6,11.6-2.5
    c1-0.7,1.9-0.7,3.2-1c5.6-1.9,11.6-2.5,18-3.2c1.5,0,3.4,0,5.1-0.7c1.5-6.7-1.9-11.4-6.6-16.4c-4.1-4.8-8.2-9.8-7.3-16.7
    c-0.7,0-1.5,0.7-1.9,1c-1.9,1-3.4,1.6-5.6,1.6c-3.2,0-5.1-1.6-7.3-5.1l-1-1.6l1-1.6c1.9-1.9,3.4-4.8,5.6-7.5
    c-1.5-1.6-2.5-3.2-4.1-4.1C587.2,61.8,575,69.1,559,70.3c-5.6,0.7-11.4,1.6-16.3,2.5c-1.5,0-2.5,0.7-4.1,0.7
    C537.2,76.9,535.3,80.1,533.1,83.2z M607,360.4C607,360.4,607.6,360.4,607,360.4c1.9,1.6,4.7,2.5,6.6,3.4c0,0,0.7-1.6,1-1.6l0,0
    c-1.9-1-4.1-1.6-6-2.5C608,360.1,607.6,360.4,607,360.4z M600.4,324.7c0,0.7,0,1,0,1.6c0.7-0.7,1.5-1.6,1.9-1.9
    C601.4,324.7,601,324.7,600.4,324.7z M570.1,147.7C570.8,147.7,570.8,147.7,570.1,147.7c2.5-1,4.7-1.6,5.6-2.5c1-1,1-3.4,1.5-5.7
    c0-0.7,0-0.7,0-1l0,0c-1.5,0.7-3.2,1-3.4,1.9l0,0l0,0C572.3,142.6,571.5,144.5,570.1,147.7z M573.3,115.8L573.3,115.8
    c0.7-1,1-1.9,1-3.2c-1,0-1.9,0.7-3.2,0.7c-0.7,0-1,0.7-1.5,0.7c-5.1,1.9-7.3,3.2-6,6.7c0,0,0.7,0,0.7,0.7l0,0
    c1,0.7,1.9,0.7,2.5,0.7l0,0C569.3,121.4,571.5,119.5,573.3,115.8z M576.8,375.8c0,0.7,0.7,1,0.7,1.6c0.7-0.7,1-1,1.5-1.6
    C578.1,375.3,577.4,375.3,576.8,375.8z M535.6,232c3.4,3.2,6,4.1,9.2,4.1c2.5,0,5.6-1.6,9.7-4.1c-0.7-0.7-1.5-0.7-1.9-1
    c-4.7-1.9-9.2-4.1-13.8-5.7c-1,0-3.2,1.6-3.2,1.9l0,0C534.6,228.6,535.3,231.4,535.6,232z M541.9,461.2c-1.9,0.7-4.1,1-6,1.9
    C538.1,463.4,540.3,462.8,541.9,461.2z M551.3,302.5c5.1-1,10.7-2.5,16.3-2.5c1.5,0,3.4,0,5.1,0.7c0.7-1,1-2.5,1-3.4
    c-1.5-0.7-3.4-1-5.1-1.6c-5.1-1.6-10-3.2-15.4-4.8c-5.1,4.1-9.7,8.2-13.8,11.6c-1,1-1.9,1.6-3.2,2.5
    C541.9,304.8,546.3,303.8,551.3,302.5z M562.6,383.1L562.6,383.1c2.5-3.2,5.1-6,7.5-9.2c-1.5-3.4-3.2-3.4-4.1-3.4
    c-1.5,0-3.2,0.7-5.1,1.6c-2.5,1-5.1,2.5-7.5,4.1c-0.7,0.7-1.5,0.7-1.9,1C552.9,380.9,556.7,383.1,562.6,383.1z M566.1,520.6
    c-0.7-3.2-1-5.7-1.5-8.9c-0.7-3.2-1-6-1.5-9.2c-1,1-1.5,1.6-2.5,1.9c-2.5,2.5-5.1,4.8-7.5,7.3c1,3.2,1.9,6.7,3.2,10.1
    C560.1,522.2,563.3,521.6,566.1,520.6z M571.8,406.5l0.7-0.7c1-1.6,1.9-3.2,3.2-4.8c2.5-4.1,4.1-6.7,4.1-8.2c0-1.6-1.5-3.2-4.7-6.7
    c-1.5,0.7-2.5,0.7-4.1,1c-1.9,0.7-3.4,1-5.6,1.6l-8.2,9.2C565.7,399.2,568.3,400.9,571.8,406.5z M579,465.9l-1.9,0.7l0,0
    C578.1,466.3,578.4,466.3,579,465.9z M630.3,400.9c3.4-1,6.6-2.5,8.8-4.8c-3.4,1.9-7.3,4.1-11.4,5.1c-14.1,3.4-28.9,7.3-43.4,10.1
    c-3.4,1-7.3,1.6-10.7,2.5c-1,1.6-1.5,2.5-2.5,3.2c5.1-1.6,10-3.2,15.8-4.8C601,409.1,615.8,405,630.3,400.9z M635.9,426.1
    c-0.7,0-1.5,0-2.5,0.7l4.1,14.2c4.7-2.5,6-4.8,4.1-9.2C639.7,427,637.5,426.1,635.9,426.1z M614.5,570.2c-6,1-12.6,2.5-19.5,5.7
    c2.5,0,4.7,0.7,6.6,0.7s3.4,0,5.1-0.7C612.6,575.3,614.5,572.8,614.5,570.2z M635.9,462.8c0.7,0.7,1,1.6,1.5,1.9l-4.7-15.9l0,0
    c-1.9,0-3.4-0.7-5.6-0.7c-1.5,0-3.2-0.7-4.7-0.7c-1.9,0-4.1,0.7-6,2.5h0.7c4.1,1,8.2,1.6,11.4,4.1
    C631.8,456.7,634,459.6,635.9,462.8z M639.1,543.6c-3.4-1.9-5.6-3.2-7.5-3.2c-0.7,0-0.7,0-1,0C633.1,542.7,635.9,543.1,639.1,543.6
    z M645.7,506.7c-3.4-1-6.6-1.6-9.7-2.5l0,0c1.5,3.2,3.4,3.4,5.1,3.4C642.2,507.9,643.8,507.4,645.7,506.7z M636.5,340.4
    c3.2,0,6,0,9.2-0.7c0.7,0,1.9-0.7,3.2-1.6c0-0.7,0.7-1,0.7-1.6L636.5,340.4z M625.8,306.6c-1.9,0-4.1,0.7-6,1c-4.1,1-6.6,3.2-7.3,6
    c1.9-1.6,4.1-3.2,7.3-3.2c2.5,0,5.1,1,8.2,3.4c1.5,1.6,3.4,1.9,5.6,1.9s4.7-0.7,7.5-3.2C635.5,308.9,630.9,306.6,625.8,306.6z
    M635.9,233.9c-6-4.1-9.7-6-12.6-6c-3.2,0-6.6,1.9-12.2,6c0.7,0,0.7,0.7,1,0.7c2.5,1.6,4.7,2.5,6,2.5c0,0,0,0,0.7,0
    C623.6,236.5,629.9,235.6,635.9,233.9z M630.9,194.1c-4.1-1-8.8-1.9-12.6-3.4c0,0.7-0.7,0.7-0.7,1c-0.7,1-1.9,3.2-1.9,4.1l0,0
    c0.7,1.6,3.4,3.4,5.1,3.4c1.5-0.7,3.2-0.7,4.7-0.7c4.1-0.7,7.3-1,8.8-4.1L630.9,194.1z M777.4-16c-0.7,0-0.7,0-1,0
    C776.5-15.7,777.2-16,777.4-16z M779.1-31.6c-1.9-0.7-4.1-1-5.1-1c-1.5,0-2.5,0.7-4.7,3.2C772.4-30.6,776.2-30.9,779.1-31.6z
    M45.1,225.5C45.1,225.5,45.1,224.8,45.1,225.5C45.1,224.8,45.1,224.8,45.1,225.5C45.1,224.8,45.1,224.8,45.1,225.5z M-12.3,817.4
    L-12.3,817.4L-12.3,817.4L-12.3,817.4z M192.4,414.7L192.4,414.7c0,0,0,0.7-0.7,0.7L192.4,414.7z M683.4,208.3v0.7
    C683.4,209,683.4,209,683.4,208.3z M1795.2,799.3c0.7,0,0.7-1,1-1.9c-0.7-0.7-1-1-1.5-1.6c-0.7,1-1,1.9-1.5,3.2
    C1794.3,798.8,1794.5,799.3,1795.2,799.3z M1795.5,697.9v1.6l1.5-0.9L1795.5,697.9z M1777.3,856.5c-4.7,0-9.2,0-13.2,1
    c-3.2,0.7-8.2,1.6-3.4,8.2C1766.3,862.8,1771.6,859.7,1777.3,856.5z M1769.1,844.9c0,6,4.1,8.2,8.2,10.8
    C1774.8,852.2,1777.3,844.9,1769.1,844.9z M1777.3,856.5h0.7c-0.7-0.7-0.7-0.7-1-0.7C1777.3,856.3,1777.3,856.3,1777.3,856.5z
    M1777.9,856.5C1777.3,856.5,1777.3,856.5,1777.9,856.5h0.7H1777.9z M-78.1,23.8c-1.5,0.7-3.2,1-4.7,1.6v0.7c1,1,1.5,1.6,2.5,2.5
    c1.5-1,2.5-1.9,4.1-3.2C-77.2,24.8-77.4,23.8-78.1,23.8z M-76.5-24.9v1.6l1.9-1L-76.5-24.9z M-112-2.7c0,0.7,0,1,0,1.9l2.5-0.7
    C-110.5-1.8-111-2.4-112-2.7z M1626.7,802.5c1,1,1.9,1.9,3.4,3.2c0.7-1.6,1.5-2.5,1.9-4.1c-1.5-0.7-3.2-1-4.7-1
    C1627.3,799.6,1626,801.9,1626.7,802.5z M1627.3,850.8c1.9-1.6,3.4-2.5,7.3-5.1c-3.2-1.9-5.1-3.4-7.3-4.1c-1,0-3.4,2.5-3.4,3.2
    C1624.1,846.7,1625.7,849,1627.3,850.8z M1728.9,860.1c0.7-1,1-2.5,1.5-3.4c-1,0-1.5,0-2.5-0.7c-0.7,1-1,2.5-1.5,3.4
    C1727.3,859.7,1728.9,860.1,1728.9,860.1z M1722.8,881.2c1.5-0.7,2.5-1.9,4.1-3.4c-1.5-0.7-3.2-1.6-4.1-1.6
    c-1.5,0.7-2.5,1.6-4.1,2.5C1719.8,880.2,1722,881.9,1722.8,881.2z M1763.1,818.6c0,1.6-0.7,3.2-0.7,4.1l1.9-1.6
    C1764.8,820.3,1764.1,819.9,1763.1,818.6z M1679.5,670.4l-1.9-0.7l0.7,1.6L1679.5,670.4z M1692.7,899.5c-0.7-0.7-1-1.6-1.5-1.9
    c-0.7,0.7-1.9,0.7-1.9,1c-0.7,0.7,0,1.6,0,2.5L1692.7,899.5z M151.3,0.7c-1-0.7-1.9-1.6-3.2-2.5c-1,1-2.5,1.9-3.4,3.4
    c-1.5,1.9-2.5,4.8-3.2,7.3C144.9,6.4,148.1,3.3,151.3,0.7z M84.9,80.1c1,1.6,2.5,2.5,4.1,3.4c0-1.6,0.7-3.2,0.7-5.1
    c-1.5-0.7-2.5-1-4.1-1C85.5,78.4,84.9,79.4,84.9,80.1z M8.1,30.4C6.2,34.5,4,39.6,1.5,45.3c4.7,1,7.5,2.5,10,1.9
    c3.4-0.7,6.6-3.2,9.7-4.8C20.3,35.5,17.2,30.4,8.1,30.4z M33.9,12.1c-0.7,0-0.7,0.7-1,0.7c0,0,0,0,0.7,0L33.9,12.1z M20.7,42.7v0.7
    l0,0l0,0l0,0l0,0h0.6v-0.7H20.7l0.7-0.7C21.3,42.2,20.7,42.7,20.7,42.7z M72.3-20.8c0,1,0.7,1.9,0.7,2.5l1.9-1.9l-1-1.9
    C73.2-21.7,72.3-21.5,72.3-20.8z M22.2,14.6c5.6,4.1,8.2,0.7,11.4-1.9C29.8,13.3,26.4,14,22.2,14.6z M74.1,121.4
    c-7.3,1.6-14.1,4.1-22.3,6c-3.2,7.3,10,9.8,7.5,19.6c-1.5,6,3.4,14.2,5.6,22.2c6.6-4.8,12.2-9.2,18.2-13.3
    c1.5-7.3,3.4-14.2,5.1-21.5C89.7,126.2,82.8,119.5,74.1,121.4z M78.7,74.4V76l1.5-1L78.7,74.4z M84.4,156.9c-0.7-0.7-0.7-1-1-1
    c0,0,0,0-0.7,0v0.7C83.1,156.9,83.8,156.9,84.4,156.9z M89.7,157.8c-1.9,0-3.4-0.7-5.6-1c1,1.6,2.5,3.2,3.4,4.8
    C88.9,160.3,89.4,158.7,89.7,157.8z M88.5,209.6c1.5-0.7,3.4-3.4,3.2-4.1c-1.5-1.9-3.2-4.8-5.6-5.1c-2.5-0.7-5.1,0-7.5,0.7
    C79,209.6,82.2,212.2,88.5,209.6z M83.8,85.7c0,1-0.7,2.5-0.7,3.4c1,0,1.5,0,1.9-0.7c0.7-0.7,0.7-1.6,0.7-1.9L83.8,85.7z
    M72.4,194.1c-1-1-3.4-1.6-5.1-1c-1,0.7-1.9,3.2-1.9,4.1c0.7,1.6,2.5,3.2,4.1,3.2c2.5,0,4.7,0,7.3-0.7
    C75.6,198.2,74.1,195.8,72.4,194.1z M52.3,71.8h-1c0.7,0.7,0.7,1,1,1.6C52.3,72.7,52.3,72.5,52.3,71.8z M49.5,86.7
    c10-2.5,6.6-7.3,3.2-12.3L52,73.7C51,78.2,44.5,81.6,49.5,86.7z M73.1,181.5c1,0,1.5-0.7,2.5-0.7c0-0.7-0.7-1-0.7-1.6l-2.5,0.7
    C72.4,179.9,72.4,181.5,73.1,181.5z M20.3,61.7L20.3,61.7c-0.7,0.7-0.7,0.7-0.7,1L20.3,61.7z M19.8,62l1-0.7l-0.7-0.7L19.8,62z
    M30.5,71.9L30.5,71.9c-0.7-0.7-0.7-0.7-1-0.7L30.5,71.9z M15,62.7C15,62.7,14.7,62,15,62.7C14.3,62,14.3,62,14.3,62L15,62.7z
    M15,62.7L14,62l-1.5,3.4C13.8,64.5,14,64.2,15,62.7z M14.7,62c1.5-0.7,3.4-0.7,5.1-0.7c0.7-4.1,1.5-8.2,1.9-12.3
    C12.2,51.9,12.2,52.6,14.7,62z M33.6,12.5C33.6,13,33.6,13,33.6,12.5c0.7,0,0.7-0.7,0.7-0.7L33.6,12.5z M44.2,191
    c0,0.7,1.9,1.6,1.9,1c1-1,1.5-1.9,2.5-3.2c-1-0.7-2.5-1.6-3.4-1.9C44.5,188.5,44.2,189.7,44.2,191z M22.2,48.5L22.2,48.5L22.2,48.5
    c0,0.7,0,0.7,0,1c0.7,0,0.7,0,1,0c3.2,3.2,6,6.7,8.8,9.8c-0.7,0-0.7,0-1,0l0,0l0,0l1,0.7l-10.7,3.1l9.2,9.2
    c7.3,0,10.7-4.8,14.1-10.1c0.7,0,0.7,0,1,0l-0.7-0.7l0,0l0,0l0,0c4.1-5.1,8.8-10.1,12.2-14.2c9.7,2.5,18,4.8,26.5,7.3
    c0.7-6,1-10.8,1.5-14.9c4.7-4.8,12.6,1,14.1-7.5c-8.8-3.4-10-4.1-17.3-3.4c-4.1,5.7-9.7,10.1-17.3,15.9c0.7-0.7,0.7-1.6,1-1.9
    c1.9-5.1,3.4-8.2,4.1-11.6c0-1,0.7-1.6,0.7-2.5c0.7-4.1,1.5-9.8-1.9-14.2c-8.8,1.9-15.4,8.9-23.3,10.1c-3.2-3.2-6.6-6.7-10-9.8
    c-1,5.1-1.9,10.1-1.9,15.9c-0.7,7.3-3.2,12.3-10,14.2c0.7,1.9,1.5,4.1,1.9,6L22.2,48.5z M78.2,200.8c0-0.7,0-0.7,0-1l0,0
    c-0.7,0-1,0-1.5,0c0,0.7,0.7,0.7,0.7,1H78.2h-0.7H78.2z M1226.7,2419.2c0.7-1,0.7-2.5,1-3.4l-11.4-3.4c-0.7,1.9-0.7,3.4-1,5.7
    C1219.1,2418.2,1222.6,2418.8,1226.7,2419.2z M1093.3,2418.8c0,0.7,0.7,1.6,0,1.9c1,0,1.5,0,2.5,0c0-0.7,0-1.9-0.7-1.9
    C1094.9,2418.2,1094.2,2418.8,1093.3,2418.8z M1178.3,2418.8c12.2-1.6,22.9-17.4,37.7-7.5c4.7-2.5,9.2-5.7,15.8-9.8
    c-4.7-2.5-7.5-5.7-9.7-5.1c-15.8,5.7-32.8,1.6-48.4,3.2C1172.2,2405.5,1171.3,2412.2,1178.3,2418.8z M1187,2377.7
    c-0.7,1.6-1,3.2-1.5,4.8c1.5,0,3.4,0,5.6,0c-0.7-1.6-0.7-3.2-1-4.8C1189.5,2377.5,1187.3,2377.5,1187,2377.7z M1171.6,2398.3
    c-3.2-2.5-6-5.1-9.2-7.5C1163.1,2396.6,1167.6,2397.3,1171.6,2398.3z M1173.2,2398.9c-0.7,0-1-0.7-1.5-0.7c0.7,0,0.7,0.7,1,0.7
    C1172.5,2399.2,1173.2,2399.2,1173.2,2398.9L1173.2,2398.9L1173.2,2398.9L1173.2,2398.9z M1215.3,2411.5L1215.3,2411.5h1v-0.7h-0.7
    h0.7l0,0C1216,2411.3,1216,2411.5,1215.3,2411.5L1215.3,2411.5z M977.3,2536.4c-1.5,1.6,5.6,1,7.5-2.5c1-2.5,2.5-4.8,4.1-7.3l0,0
    C984.8,2529.8,980,2532.3,977.3,2536.4z M988.2,2526.6c-6.6-9.8-14.8-16.4-32.1-22.4C967.8,2517.5,977.5,2523.1,988.2,2526.6z
    M1287.4,2306.3L1287.4,2306.3l0.7,1c0,0,0-0.7,0.7-0.7C1287.7,2306.6,1287.7,2306.6,1287.4,2306.3z M945.5,2512.4
    c0,0,1.5,0.7,3.2,0.7c-0.7,1-1,1.9-1.5,2.5c0,0,0.7-1,1.5-1.9v-0.7C947,2512.4,945.5,2511.7,945.5,2512.4z M953,2544
    c2.5,1.6,5.1,2.5,8.2,4.1l0,0c0.7,0,1,0,1.9,0c-0.7,0-1,0.7-1.5,0.7c5.1,2.5,10,4.8,15.4,6c4.1,1.6,8.8,1.9,12.6,2.5
    c1-1,1.9-2.5,3.2-3.4c1-1,1.5-1.9,2.5-3.2c-7.3-1.6-14.8-3.2-22.1-5.7c-8.2-2.5-16.3-6-24.5-9.2
    C945.2,2542.1,950.1,2542.7,953,2544z M947,2514.9L947,2514.9L947,2514.9z M1215,2418.2C1215,2417.8,1214.3,2417.8,1215,2418.2v0.7
    c-8.2-3.2-14.8-4.1-17.3,8.2l26.1,10.1C1220.1,2428.9,1217.5,2423.9,1215,2418.2C1215,2418.2,1215,2417.8,1215,2418.2
    C1215,2417.8,1215,2417.8,1215,2418.2L1215,2418.2z M1152.4,2567.7c-8.8-1-17.3-1.6-24.8,5.1
    C1137,2575.9,1137.7,2575.9,1152.4,2567.7z M1155,2567.7c-0.7,0-1-0.7-1-0.7l0.7,1C1154.3,2568.3,1154.3,2568.3,1155,2567.7z
    M1239.3,2497.5c-0.7-0.7-1.5-1-1.9-1c0,1-0.7,1.9-0.7,3.2l2.5,0.7C1238.9,2498.5,1239.3,2497.5,1239.3,2497.5z M1153.3,2567
    c-0.7,0-0.7,0.7-1,0.7c0.7,0,1,0,1.5,0L1153.3,2567z M1244.9,2443.4c0-0.7-0.7-1-0.7-1.6c-0.7-0.7-1.5,0-1.9,0
    C1243,2442.2,1244,2442.9,1244.9,2443.4z M1245.5,2444.4L1245.5,2444.4c6,0.7,12.2,0.7,20.4,1c-7.3-5.1-12.2-8.9-18.2-13.3
    C1246.4,2436.6,1246.1,2440.3,1245.5,2444.4L1245.5,2444.4c0-0.7,0-0.7-0.7-0.7C1244.9,2443.8,1244.9,2444.4,1245.5,2444.4
    C1244.9,2444.4,1244.9,2444.4,1245.5,2444.4z M1219.4,2472.9c1-5.1,1.9-10.1,3.2-15.5c-4.7-1.6-9.7-3.4-15.8-5.7
    C1207.2,2462.7,1208.4,2470.9,1219.4,2472.9z M1227.2,2455.5c-1,0.7-1.5,1-2.5,1.6c1.5,0,2.5,0,4.1,0
    C1228.2,2456.1,1227.2,2455.2,1227.2,2455.5z M1167.6,2569.2c0.7,0,0.7-2.5,0.7-2.5c-1.9-0.7-4.7-1.6-6.6-1
    c-2.5,0.7-4.7,1.6-7.3,2.5c1.9,1,4.1,2.5,6,2.5C1163.1,2570.8,1165.7,2569.6,1167.6,2569.2z M1221.9,2457.7h0.7l0,0
    c0.7-0.7,1-0.7,1.5-1c-0.7,0-1.5,0-1.9,0l0.7,1l-0.7-1C1222.6,2457.1,1222.6,2457.7,1221.9,2457.7z M1174.2,2447.3
    c0.7,1,1,1.9,1.9,3.2c0.7,0.7,1.5-0.7,2.5-0.7c-0.7-0.7-1.5-1-1.9-1.6C1176.3,2447.9,1175.1,2447.9,1174.2,2447.3z M1145.8,2545.3
    l1-1.9h-2.5L1145.8,2545.3z M988.8,2526.6L988.8,2526.6C988.2,2526.6,988.8,2526.6,988.8,2526.6L988.8,2526.6z M1053.9,2420.7
    l1.5,1l0.7-1.6L1053.9,2420.7z M1112.2,2455.2c3.4,1.9,7.3,4.1,10.7,6c0.7-0.7,1-1.6,1.5-2.5c-2.5-2.5-5.1-5.1-7.5-7.5
    c-0.7-0.7-3.2,1-4.1,1.6C1111.5,2452.6,1111.5,2454.5,1112.2,2455.2z M1069.7,2430.6c5.6,1,11.4,1.6,17.3,1.9l-16.3-6
    C1070.4,2428,1070.4,2428.9,1069.7,2430.6z M1073.8,2642.3c0-0.7,0-1,0-1.6c1,0,2.5-0.7,3.4-0.7c0.7,0,0.7,0,1,0
    c-3.4-3.4-5.6-2.5-12.2,0.7l19.5,7.5c-1.9-2.5-3.4-4.8-5.1-6c-1,0-1.9,0-3.2,0C1076.3,2642.3,1074.8,2642.3,1073.8,2642.3z
    M964.9,2563.6L964.9,2563.6c0.7,0,0.7,0,0.7-0.7L964.9,2563.6z M1123.8,2544.6c1.5-7.5-3.2-11.4-8.2-12.3c-6-1.6-12.2-1-22.1-1.6
    C1104,2538.9,1113.7,2541.4,1123.8,2544.6z M1110.9,2472.9c1.5,1.6,4.1,1.9,7.3,3.2c-0.7-3.2,0-6-0.7-6c-1.9-1-5.1-1-7.5-1.6
    C1110.6,2469.4,1110,2471.9,1110.9,2472.9z M873.1,2723.2L873.1,2723.2L873.1,2723.2C873.1,2723.2,873.8,2723.8,873.1,2723.2
    C873.8,2723.8,873.8,2723.8,873.1,2723.2L873.1,2723.2z M918.1,2557.9v1.9l1.9-1L918.1,2557.9z M1508.2,3251.9v1.9l1.9-1
    L1508.2,3251.9z M1039.2,2434.7c1,0,1.9,0,4.1-0.7c-1.9-0.7-2.5-1-3.4-1C1039.8,2432.7,1039.2,2434,1039.2,2434.7z M985.8,2477.6
    c7.5,5.1,16.3,8.9,25.5,13.9c-9.7,6-18.2-5.1-27,1.9c9.2,3.2,18.2,5.1,26.1,9.2c7.3,3.4,12.6,10.1,22.3,18.1
    c-10.7-3.2-18-5.7-24.5-7.3l-1.9,3.4c3.2,2.5,6.6,5.7,9.7,8.9c1.9,4.8,1.5,9.8,0.7,14.9c5.1-0.7,10-1,14.8-1c1,0,1.9,0,3.4,0
    c7.3-6,1.9-16.4,12.2-20c6.6-2.5,12.2-8.2,19.9-12.6c-5.6-3.4-9.7-6-14.1-8.9c-1-8.9-6.6-18.1,2.5-28.2c12.2,6,22.9,15.9,38.1,12.6
    c-8.8-8.9-11.4-21.5-24.5-24.6c-16.3-4.1-32.8-6-48.8-3.4c-9.2,1.6-15.8,6.7-17.3,18.3c-8.8-3.2-14.8-5.7-20.7-8.2
    c-5.1-1.9-8.2-7.5-19.9-7.5C971.5,2465.9,978.1,2472.6,985.8,2477.6z M936.3,2463.7l1.5,1.6l0.7-1.6H936.3z M1080.4,2410.6
    c-0.7,0-1.5,0-1.9,0c0,0.7,0,1.6,0,1.9c0.7,0,1.5,0,1.9,0C1080.4,2412.2,1080.4,2411.3,1080.4,2410.6z M1051.5,2592.3
    L1051.5,2592.3L1051.5,2592.3L1051.5,2592.3v0.7c0,0,0.7,0,0.7,0.7l0,0c0,0,0,0,0-0.7C1052.1,2592.6,1052.1,2592.6,1051.5,2592.3
    L1051.5,2592.3z M1045.8,2595.8c-1.5,1-3.2,1.9-5.1,3.4c0.7,0,1,0,1.5,0c6,1,8.2-1,9.2-6c-0.7,0-0.7-0.7-1-0.7
    C1049,2593.3,1047.3,2594.2,1045.8,2595.8z M1053,2590.7c-0.7,1-0.7,1.6-1,2.5c2.5,3.2,5.6,6,8.8,9.8c0,0,0,0,0.7,0c0,0,0,0-0.7,0
    c1.5,1.6,3.2,3.4,5.1,5.7c1.9-8.2,4.1-15.9,6-24.9c-0.7,0-0.7,0-1-0.7c-3.4,1-6.6,1.6-10,3.2
    C1058,2587.6,1055.6,2589.2,1053,2590.7z M1114.1,2605.9c-0.7,1-0.7,1.9-1,3.2c0.7,0,1.5,0,2.5,0c0-0.7,0.7-1.6,0.7-1.9
    C1115.6,2607.1,1114.7,2606.6,1114.1,2605.9z M1049.5,2621.4l9.2,3.4c0-0.7,0-0.7,0-1C1055.6,2623,1052.4,2622,1049.5,2621.4
    C1049.5,2620.8,1049.5,2620.8,1049.5,2621.4z M1061.2,2580c2.5,1.9,5.6,2.5,8.8,2.5C1066.8,2581.9,1064.1,2581,1061.2,2580z
    M973.2,2568.3c0,0.7,0,0.7,0,1c0,0,0,0,0.7,0L973.2,2568.3z M965.2,2563.6c-7.3,13.3-6.6,14.9,8.8,18.1c-0.7-4.1-1-8.2-1-12.3
    C970.6,2567,967.8,2565.8,965.2,2563.6z M973.2,2568.3l0.7,1c0.7,0,0.7,0,1,0C973.4,2569.2,973.4,2568.7,973.2,2568.3z
    M960.8,2560.1c-0.7-0.7-1.5-0.7-2.5-0.7c0.7,1,1,3.2,1.9,3.4c1.5,0.7,3.2,0,4.7,0C963.4,2562,961.8,2561,960.8,2560.1z
    M965.2,2562.9c-0.7,0-0.7,0-1,0c0,0,0,0,0.7,0C964.9,2563.6,965.2,2562.9,965.2,2562.9z M974.1,2582.5L974.1,2582.5l1-0.7h0.7
    c4.1,6.7,8.2,12.6,11.6,20c6.6,13.9,15.4,24.1,32.8,22.4c4.7-0.7,9.7,1.9,15.4,4.8c1-0.7,1.9-1,3.2-1.6l2.5-1l0.7-1.9l-3.2-1.9
    c-4.7-2.5-4.7-6-5.1-10.1c-0.7-4.8-1-10.1-7.5-13.3c-4.1-1.9-7.5-5.7-11.6-9.2c-1-1-1.9-1.6-3.2-2.5c4.1-1.9,7.5-3.4,11.4-5.7
    l1-0.7l0.7-1.6c0.7-1.9,1-4.1,1.5-6c1.9-6.7,4.7-14.9-1.9-24.1l-1-1.6l-1.9,0.7c-1.5,0.7-2.5,1-4.1,1.6c0,2.5,1,5.7,1.9,8.2
    c-4.7,5.1-8.8,9.8-12.2,13.9c-12.2-1-22.3-1.9-32.8-2.5c5.6,5.7,5.6,6.7,0.7,12.3H974C974.1,2581.9,974.1,2582.5,974.1,2582.5
    L974.1,2582.5L974.1,2582.5z M1282.6,2266.2c0.7-1,1-1.6,1.5-2.5l-12.6-4.8C1275.1,2261.7,1278.5,2264.3,1282.6,2266.2z
    M1260.7,2356.2c3.2,1.9,6,4.1,9.7,6l1.5-4.8l-10-4.1C1261.9,2355.3,1261.2,2356,1260.7,2356.2z M1297.4,2316.4c-0.7,0-0.7,0-1-0.7
    l0.7,1C1296.8,2317.1,1296.8,2316.4,1297.4,2316.4z M1286.1,2385.7c3.2,3.4,4.1,6,5.1,6.7c2.5,0.7,5.6,0,8.2-0.7
    c0.7,0,1.5-1.9,1-1.9c-1.9-1.6-4.1-3.2-6.6-4.1C1292.4,2385,1290.2,2385.7,1286.1,2385.7z M1377.9,2630.3v2.5
    c1.5-0.7,2.5-1,4.1-1.6c0.7-0.7,0-1.6,0-2.5C1380.5,2629,1378.9,2629.6,1377.9,2630.3z M1379.5,2598.1c-0.7,1-1,1.6-1.5,1.9
    c0.7,0.7,1.5,1,2.5,1.6c0.7-1,1.5-1.9,1.9-3.4C1381.4,2598.1,1380.5,2598.1,1379.5,2598.1z M1432.3,2349.6
    c-5.1-1.6-10-2.5-16.3-4.1c-0.7,5.1-0.7,8.2-1,13.9C1422.3,2357.2,1429.2,2360.1,1432.3,2349.6z M1390.5,2250.3
    c-0.7-0.7-2.5,1.6-4.1,1.9c0.7,1,1.5,2.5,1.9,3.2c0.7-1,0.7-1.9,0.7-3.2c1,0,1.9,0,3.4,0C1392,2252,1391.8,2251,1390.5,2250.3z
    M1371.6,2249.8c-1.9,1-3.4,3.2-5.1,4.8c1.9,0.7,3.4,1.9,5.1,1.6c1.5,0,2.5-1.9,3.4-3.4C1374.5,2251.3,1372.3,2249.4,1371.6,2249.8
    z M1297.4,2315.8c3.4-1.6,7.3-2.5,10.7-4.1c0-1-0.7-2.5-1-3.2c-1.5-1.6-4.1-1.9-6-3.2C1299.9,2309.2,1298.4,2313,1297.4,2315.8
    C1297.4,2316.4,1297.4,2316.4,1297.4,2315.8C1297.4,2316.4,1297.4,2316.4,1297.4,2315.8z M1415.6,2376.8L1415.6,2376.8
    c-1.5,0.7-3.2,1-5.6,1.9C1412.6,2378.4,1414.1,2377.7,1415.6,2376.8z M1393.7,2668.6l2.5,0.7l-0.7-1.9L1393.7,2668.6z M1349,2257.6
    c-3.2-1.9-7.5-1.6-11.6-1.6c-1,1.6-1.9,3.2-3.4,4.8c6,4.1,12.6,8.2,23.3,15.9C1353.1,2265.8,1352.4,2259.5,1349,2257.6z
    M1299.9,2305.1c0.7,0,0.7,0.7,1,0.7v-0.7H1299.9z M1519.5,2698.3c-4.1,4.8-8.2,9.2-12.2,13.3c3.2-0.7,6.6-1.6,9.7-2.5
    C1521,2706.8,1525.4,2704.8,1519.5,2698.3z M1502.1,2710.6c0,1-0.7,1.9-0.7,3.4l3.4-1.6C1504.1,2711.5,1503.1,2710.9,1502.1,2710.6
    z M1492.1,2647.1c-0.7,0-1,0.7-1.5,1c1.5,0,3.2-0.7,4.1-0.7C1494,2647.6,1493.1,2647.1,1492.1,2647.1z M1515.4,2661.2
    c1.5,0,3.2-1.6,4.7-1.9c-1-1-1.9-1.9-3.2-3.2c-1.5,1-3.2,1.9-4.7,2.5C1513.5,2659.7,1514.5,2661.2,1515.4,2661.2z M1489.9,2647.9
    C1489.9,2648.6,1489.9,2648.6,1489.9,2647.9c0.7,0,0.7,0,0.7,0H1489.9z M1507.2,2711.5c-0.7,0-1,0-1.5,0.7l-0.7,0.7
    c0.7,0,0.7,0.7,1,0.7C1506.2,2712.1,1506.9,2711.5,1507.2,2711.5z M1501.2,2509.2c-0.7,0.7-1.5,0.7-1.9,1c-0.7,0.7,0,1.6,0,1.9h2.5
    C1501.2,2510.8,1501.2,2509.8,1501.2,2509.2z M1522,2571.1c-1.9,5.1-4.7,10.1-7.3,15.5c-1.5,3.2-2.5,5.7-4.1,8.2
    c2.5,1,5.6,2.5,9.2,5.1c-4.1,1.9-5.6,2.5-9.7,3.2c6,3.2,13.2-5.7,18,2.5c2.5,4.8,6.6,3.4,8.8,0c1.5-2.5,1.5-9.2,0.7-9.8
    c-10-5.1-13.8-12.3-9.7-23.1C1526.1,2572.5,1524.5,2571.8,1522,2571.1z M1535.2,2644.5l-1.5,1l1.9,1L1535.2,2644.5z M1537.4,2714
    l-1.9-0.7l0.7,1.6L1537.4,2714z M1489,2652c1,0,1.5-0.7,2.5-0.7c-0.7-1-1-1.9-1.5-2.5l0,0C1489.6,2649.5,1489.6,2651.2,1489,2652z
    M1533.4,2686.5l1.9-1c-1,0-1.9-0.7-3.2-0.7C1532.4,2685,1532.7,2685.8,1533.4,2686.5z M1312.8,2250.3l1.5,1.6l0.7-1.6H1312.8z
    M1439.9,2655.3c-5.6,3.2-15.4,0-15.4,10.8v0.7C1429.2,2662.5,1434.8,2659.4,1439.9,2655.3L1439.9,2655.3z M1334.6,2372.7
    C1334.6,2372.7,1335.1,2373.3,1334.6,2372.7c1.5,1,3.2,1.6,5.6,3.2C1337.7,2374.3,1336.1,2373.6,1334.6,2372.7z M1421.6,2375.8
    c0-1-0.7-1.9-0.7-2.5c-1.9,1-3.4,2.5-5.6,3.4h0.7C1418.2,2378.4,1420.7,2380.2,1421.6,2375.8z M1420.1,2650.2
    c6.6,2.5,14.8-3.2,19.9,5.1C1436.2,2641.3,1436.2,2641.3,1420.1,2650.2z M1402.7,2492.4l-1-0.7c0,0,0,0,0,0.7
    C1401.9,2491.9,1402.5,2492.4,1402.7,2492.4z M1419.7,2666c1.5,0,3.2,0,4.7,0l0,0C1422.6,2666,1421.4,2666,1419.7,2666z
    M1439.9,2654.6C1439.9,2655.3,1439.9,2655.3,1439.9,2654.6C1439.9,2655.3,1439.9,2655.3,1439.9,2654.6
    C1439.9,2655.3,1439.9,2655.3,1439.9,2654.6z M1428.2,2531.3c0,0.7,0.7,0.7,0.7,1l0.7-0.7L1428.2,2531.3l1,0.7v-0.7
    C1428.9,2531.3,1428.9,2531.3,1428.2,2531.3L1428.2,2531.3z M1460,2605.6c-1.5,1-4.1,4.8-3.4,6c1,6.7,5.1,10.1,12.2,6
    c-0.7-3.4-0.7-7.3-1.9-10.1C1466.1,2605.9,1461.3,2604.7,1460,2605.6z M1413.1,2551.3c7.5-3.2,13.8-5.1,21.4-8.2
    c-2.5-4.8-4.1-7.5-5.6-10.8c-5.1,4.1-9.7,7.5-14.1,11.6C1413.4,2545.3,1413.4,2547.2,1413.1,2551.3z M1427.3,2505.7
    c0.7,0.7,1,0.7,1.5,1c-0.7,1.6-1,3.2-1.5,4.1c0.7,6.7,1,13.3,1.5,20c7.3-2.5,13.2-6.7,15.4-14.9c0.7-1,3.2-1.6,5.1-1.9
    c5.1,2.5,7.3,12.6,16.7,7.5c-1-5.7-1.9-11.6-3.2-17.4c-12.6-1.6-24.5-3.4-36.8-5.1C1426.7,2501,1427.3,2503.8,1427.3,2505.7z
    M1408.5,2519.7c6-6.7,12.2-13.9,18.2-20.5c-6.6,3.4-13.8,6.7-20.4,10.8C1402.7,2511.7,1401.2,2515.6,1408.5,2519.7z
    M1426.7,2499.1L1426.7,2499.1L1426.7,2499.1L1426.7,2499.1L1426.7,2499.1z M1448.7,2666c1.9-0.7,3.4-0.7,5.6-1h-0.7
    C1452.2,2665,1450.3,2665.3,1448.7,2666z M1337,2342c-6,1-11.4,1.6-16.3,2.5c1.9,10.1,9.2,8.9,16.3,11.4
    C1337,2350.2,1337,2346.4,1337,2342z M1390.5,2572.5L1390.5,2572.5c1.5,0,3.4,0,5.6,0C1394.2,2572.5,1392,2572.5,1390.5,2572.5z
    M1334.2,2372.7L1334.2,2372.7c-1.5-1-3.2-2.5-4.7-4.1c0,1-1,1.9-1,2.5C1329.5,2375.8,1332,2374.3,1334.2,2372.7z M1373.2,2415
    c0.7,0,0.7,0.7,1,0.7c1,0,2.5-1.6,4.1-2.5c-1-1-1.9-3.2-3.2-3.2c-0.7,0-1,0-1.5,0.7c-0.7-0.7-1-0.7-1.5-0.7c-1,0-1.9,1.9-3.4,3.2
    c1.5,1,2.5,2.5,3.4,2.5C1373,2415,1373,2415,1373.2,2415z M1436.2,2485.8c0.7-0.7,0-1.6-0.7-3.4c-1.5-0.7-3.4-1-5.6-1.9
    c-1,0.7-1.5,1.6-2.5,1.9c1.9,1.6,4.1,3.2,6.6,4.8C1434.5,2487.4,1436.2,2486.4,1436.2,2485.8z M1427.9,2451.1c0.7,0.7,0.7,1,1,1.9
    c0.7-0.7,1.5-1,1.9-1C1430.4,2451.4,1428.9,2451.1,1427.9,2451.1z M1315.7,2445.4c-1,0-2.5,0-3.4,0.7c0.7,0.7,1.5,1,1.9,1.6
    C1314.7,2447,1315.4,2446,1315.7,2445.4z M1422.6,2405c-1,1-1.9,2.5-3.2,3.4c1.5,1,2.5,1.6,4.1,2.5c0.7-1.9,1-3.4,1-4.8
    C1424.8,2405.5,1422.6,2404.3,1422.6,2405z M1304,2342c0-1,1-1.9,0.7-1.9c-0.7-0.7-1.5-0.7-1.9-0.7c-0.7,0-1,1.9-0.7,1.9
    C1302.5,2341.3,1303.7,2341.3,1304,2342z M1327.9,2404c-1-1.6-1.5-2.5-2.5-3.4c0,0-1.9,0.7-1.9,1c0,1.6,0,3.2,0.7,5.1
    C1325.4,2405.5,1326.4,2404.3,1327.9,2404z M1337.7,2489L1337.7,2489h-1C1337,2489,1337,2489,1337.7,2489z M1306.2,2480.8
    c2.5-1,4.7-2.5,7.3-4.1c0.7,0-0.7-1.6-0.7-2.5c-2.5,0.7-5.1,1-7.5,1.6c-0.7,1.6-1,2.5-0.7,3.4
    C1304.7,2479.5,1305.6,2480.8,1306.2,2480.8z M1437.4,2684c-0.7-1.6-1-4.1-1.9-4.8c-2.5-1-5.6-0.7-8.2-1l0,0c1,2.5,1.5,5.7,3.4,7.3
    C1432.1,2686.5,1435.5,2684.3,1437.4,2684z M1850.9,3449.5c5.6-3.4,12.2-7.3,18-10.8c-5.6,0.7-10.7,0.7-15.8,1.9
    C1850.7,3441.3,1846,3443.1,1850.9,3449.5z M1397.8,3527.2c6.6,6.7,7.5,23.1,5.1,38.9h41.8l0,0c-4.7-14.9-5.1-30.4-1.9-37
    c4.7,0.7,9.7,1,14.8,1.6c-1-3.2-1.9-5.7-3.4-8.2c1-4.8,2.5-11.4,4.7-17.4l0.7,1l1.5-1.6l-1.9-0.7c5.6-18.1,14.1-37,23.9-27.5
    c6,6,8.2-38.9,8.8-55.6c-19.9,9.2-45.9,9.8-56-0.7c-13.8-13.9-30.6,31.6-26.5,35.7c0.7,0.7,0.7,1,1,1.6c-0.7,0.7-1,1-1,1.6
    c1,1,1.5,1.6,2.5,1.9c1.9,6.7,4.7,18.3,5.6,30.4c-7.3,5.7-9.7,12.6-7.3,23.1c1.9,2.5,4.7,4.8,7.3,6.7
    C1415.6,3533.3,1410,3539.6,1397.8,3527.2z M1920.8,3458.7c1,1,1.5,1,1.9,1C1918.9,3456.1,1917.3,3455.5,1920.8,3458.7z
    M1869.3,3438.7h0.7l0,0l0,0c0,0,0-0.7-0.7-0.7C1869.9,3438.1,1869.9,3438.1,1869.3,3438.7L1869.3,3438.7z M1130.4,2666.9h0.7
    C1130.4,2666.3,1130.4,2666.3,1130.4,2666.9L1130.4,2666.9h-0.7l0.7,0.7l0.7-1L1130.4,2666.9z M1020,2634.1c-0.7-0.7-1-0.7-1-1l0,0
    l0,0C1019.4,2633.7,1019.4,2634.1,1020,2634.1z M1275.1,2495c-1.5,5.7-3.4,11.4-5.1,16.7c9.2,6,12.2-3.4,17.3-5.7
    c1.9,1,4.7,1.6,4.7,2.5c1.5,8.2,7.3,12.6,13.8,16.4c1.9-10.1,3.4-20.8,5.6-31.6C1299.9,2494.1,1287.7,2494.3,1275.1,2495z
    M1134.5,2643.8c-4.1,3.4-7.3,8.9-11.4,11.4s-9.7,1.9-15.4,3.2c5.1,11.6,14.8,6,22.3,8.2l7.5-20.5
    C1137,2645.4,1134.5,2643.5,1134.5,2643.8z M1331,2506.4c-6-4.8-12.6-8.9-18.9-12.6c5.6,7.5,11.4,15.5,16.3,22.4
    C1335.8,2512.4,1334.6,2508.9,1331,2506.4z M852.4,3133.1L852.4,3133.1C852.9,3133.1,852.4,3133.1,852.4,3133.1L852.4,3133.1z
    M1307.2,2525.3c0,0-0.7,0-0.7-0.7v0.7H1307.2l-1,0.7l0.7,0.7C1307.2,2525.7,1307.2,2525.7,1307.2,2525.3L1307.2,2525.3z
    M1492.1,3414.1c0,0,0,2.5-0.7,6c8.8-4.1,15.8-9.8,20.7-16.4C1499.7,3408.3,1490.5,3412.2,1492.1,3414.1z M1027.6,2636.3
    c-2.5-0.7-5.1-1-7.3-1.9c1.9,1.6,4.1,3.2,6,4.8C1026.6,2637.9,1026.9,2637.2,1027.6,2636.3z M890.1,2728.9
    C890.1,2728.9,890.4,2729.5,890.1,2728.9L890.1,2728.9C890.4,2728.9,890.1,2728.9,890.1,2728.9z M859,3134.7c-0.7,1-4.1-1-6.6-1.6
    c1.9,4.1,4.1,8.2,6.6,14.2c8.2-2.5,15.4-5.1,23.3-7.5c-3.2-1.6-6.6-3.2-9.2-5.1C868.1,3129.6,864,3129,859,3134.7z M916.5,3261.7
    C915.9,3261.7,915.9,3261.7,916.5,3261.7L916.5,3261.7L916.5,3261.7z M1876.4,3476.6v-0.7V3476.6c0-0.7-0.7-0.7-0.7-0.7
    c-3.2,1.6-6,3.2-9.2,4.8c-1.5,4.8-3.2,9.2-3.4,13.9c0,2.5,1.5,5.7,2.5,8.9c0.7,0,1,0,1-0.7c2.5-3.2,5.1-6,8.2-9.2
    c7.3-6.7,7.3-6.7,1.9-17.4C1877.1,3476.6,1876.4,3476.6,1876.4,3476.6z M1865.7,3504.1L1865.7,3504.1v-0.7h-0.7L1865.7,3504.1z
    M1929.6,3524.7L1929.6,3524.7L1929.6,3524.7L1929.6,3524.7z M1878.6,3453.9c1.5,0,3.4,0.7,5.1,1c1-1,1.9-2.5,3.2-3.4
    c-1.5-1-3.4-2.5-4.1-2.5C1880.5,3450.1,1879.6,3452,1878.6,3453.9z M1827.1,3463.3c-5.1-1.9-10-3.4-15.4-5.7
    C1815.4,3463.3,1819.8,3465.9,1827.1,3463.3z M1874,3557.5c-1,0-1.5,1-1.9,1.6c1.5,0,3.2-0.7,4.7-0.7
    C1876.2,3557.9,1874.9,3557.5,1874,3557.5z M1831.2,3484.3c-7.5,0.7-14.1,3.2-18,11.4c2.5,3.4,5.1,7.5,7.5,11.6
    C1821.2,3498.1,1822.4,3489.9,1831.2,3484.3z M1840,3498.1L1840,3498.1c-10-5.1-13.2,4.8-18.2,9.2
    C1836.8,3510.8,1836.8,3510.8,1840,3498.1z M1900.4,3518.4c1.9,1.9,4.1,4.8,6,6.7c1.5-3.2,3.4-5.1,4.7-8.2c0.7-1,0.7-2.5,0.7-4.1
    C1907.6,3514.9,1904.1,3517.1,1900.4,3518.4z M2006.4,3561.3c0.7-0.7-1.5-2.5-2.5-3.4c-1.5,1-3.4,1.6-4.1,3.2
    c-0.7,1,1.5,2.5,2.5,3.4C2004.5,3564.2,2005.7,3563,2006.4,3561.3z M1588.6,2742.8c0.7,0.7,1,0.7,1,1
    C1589.9,2743,1589.3,2742.8,1588.6,2742.8z M1375.7,2542.7c-1-1-0.7,0.7,0.7,2.5C1377.9,2545.5,1377.9,2544.6,1375.7,2542.7z
    M1666.1,2904.3c0.7-0.7,0.7-0.7,1-0.7h-0.3C1666.7,2904,1666.1,2904.3,1666.1,2904.3z M1667.3,2904c6.6,3.2,13.2,6,20.4,8.9
    c1.5,0.7,5.6-1.6,6.6-3.4c1-1.9,0.7-6-1-7.5C1683.9,2893.6,1677.4,2894.8,1667.3,2904z M1669.1,2720.3L1669.1,2720.3L1669.1,2720.3
    L1669.1,2720.3c0.7-0.7,1-0.7,1.5-1c-0.7,0-1,0-1,0L1669.1,2720.3l0.7-0.7c-0.7,0-0.7,0-1-0.7
    C1668.6,2719.1,1669.1,2719.7,1669.1,2720.3L1669.1,2720.3z M2293.8,2163.4c3.2,3.2,5.6,6,7.5,9.2v-13.3
    C2297,2161.2,2293.8,2163.4,2293.8,2163.4z M479.6,2752.7c-12.2-29.7-20.7-57.9,8.2-28.7c-11.4-11.4,21.4-9.8,42.8-15.9v0.7
    c0.7,1.6,1.5,1.9,1.9,1.9c0.7,0,1-0.7,1.9-1.6c1.9-2.5,4.7-4.1,7.3-4.1c1.9,0,4.1,1,6,1.6c1.5,0.7,3.2,1,4.7,1c0.7,0,1,0,1.9-0.7
    c-0.7-1-0.7-1.6-1-2.5c-1.5-3.2-3.2-6.7-4.1-10.1c-1.5-4.1-3.2-8.2-4.7-12.3c0-0.7-0.7-1-0.7-1.6c0,1,0.7,1.9,0.7,3.4
    c-2.5-4.1-6-8.9-11.6-14.2c-22.3-22.4,34.6-53.8,29.6-59.4c15.4,15.5,37.7-51.2,30.2-58.7c-11.4-11.4,30.6-62.6,38.7-54.3
    c9.7,9.8,16.3-58.7,22.1-53.1c-9.7-9.8-9.2-16.7-4.1-23.1c0,0,0,0,0.7-0.7c-0.7-0.7-0.7-1.6-1-1.9v1c-2.5-4.8-11.6-4.8-9.2-13.3
    c9.2-3.2,14.1,1.6,19.9,6.7c18-10.8,44.7-18.3,26.1-37c28,28.2,91.2-12.6,65.1-38.9c42.8,43,52.5,5.1,63.2-6.7
    c-2.5,0-5.6-0.7-8.2-1.6c-1,0-2.5-2.5-2.5-4.1s1.9-4.1,3.2-4.1c6,0.7,11.6,1.9,18.9,3.2c-1.5,1.6-2.5,2.5-3.4,3.4
    c1.5,0.7,2.5,1.6,4.7,3.2c-1.5-1.6,30.6-8.2,63.5,24.9c0.7,0.7,42.8-32.3,67.3-7.5c-12.6-12.6,74.9,13.3,65.7,4.1
    c29.6,29.7,79,40.5,87.1,24.1c1.9,4.8,4.7,9.8,8.8,18.3c5.6,10.1,13.2,18.3,21.4,26.6c0,0,0,0,0.7,0.7c5.1-5.7,9.2-11.6,8.2-14.9
    c-1-3.2-8.2-5.7-15.4-7.5c-1-0.7-1.9-1.6-2.5-1.9c0,0,0-0.7-0.7-0.7l-1-1v-1c0-7.5-0.7-15.5-0.7-23.1c0-8.2-0.7-16.4-0.7-24.6v-1
    l1-1c9.2-8.9,11.4-10.1,22.3-12.3c-4.1-1.9-7.5-4.1-11.4-5.7l-1.9-1l0.7-1.9c2.5-8.9,6.6-18.1,18.9-18.1c0.7,0,1.5,0,1.9,0
    c0.7,0,1,0,1.5,0c5.1,0,8.2-1.9,10-4.8c-3.2,0.7-6,1-9.2,1c-10.7,1.6-22.1,3.2-33,4.8c-2.5,0.7-5.1,1.9-7.3,4.1
    c-0.7,0.7-1,0.7-1.5,1c-5.6,3.4-8.8,6-12.2,6c-4.1,0-7.3-2.5-12.6-8.2c-1.5,1-3.2,1.9-4.7,2.5c4.1-17.4,12.2-37.9,27.4-22.4
    c-26.1-26.3,4.1-73.3,23.3-53.8c-4.7-4.8-7.3-10.8-9.2-17.4h0.7c1.5-1.6,3.2-3.2,4.7-4.8c4.7-4.1,8.8-8.2,12.2-12.6
    c2.5-3.2,6-3.4,9.2-3.4c1,0,1.9,0,3.2,0c1,0,1.9,0,2.5,0c3.2,0,5.6-0.7,6-6l0.7-3.2l3.2,1c0.7,0,1.5,0.7,2.5,0.7
    c1.9,0.7,4.1,1,6,1.9c1,1,1.5,1.9,1.9,3.2c1,1.6,1.5,1.9,1.9,1.9c0,0,0,0,0.7,0c0.7-5.7-3.2-11.4-6-12.6
    c-13.8-6.7-17.3-17.4-16.7-31.6c-6,3.4-12.2,7.3-18.2,10.8c-6,3.4-12.6,7.3-19.5,11.4c0,0.7,0,1,0.7,1.9
    c-39.3-41.1-13.8-68.6-4.1-58.7c-28.9-29-28.9-43-33.6-49c-0.7,0.7-38.7-31.6-39.3-31.3c-14.1-19.6,21.4-36.4,24.5-33.8
    c6.6,6.7,1-42.3-2.5-45.6c36.8,37,5.1-31.3-9.2-44.9c-9.2-9.2,66.6,4.1,6.6-56.9c-11.6-11.6,32.1-39.8,12.6-58.7
    c-7.5-7.5,3.4-8.2,18-8.9c-0.7-2.5,0.7-5.1,2.5-8.2c2.5,2.5,4.1,5.1,4.1,8.2c23.9-1,50.3-4.8,16.3-38.2
    c27.4,27.5,92.8-3.2,68.3-27.5c7.5,7.5,12.6,10.1,18,11.6c1-3.2,3.2-6,5.6-9.8c5.6,1.6,11.6,3.4,16.7,5.1c-1.9,3.4-4.1,6.7-6,9.8
    c3.4,1.6,7.5,4.1,12.6,8.2c0-0.7,0.7-1,0.7-1.6c1-1,2.5-1.9,4.1-2.5c-0.7,1.6-0.7,3.4-1.5,4.8c-0.7,0.7-1,0.7-1.5,0.7
    c4.1,3.2,8.2,7.3,13.8,12.3c33,33.1,41.3,0,48.4,5.1c1.9,2.5,46.9,49.7,46.9,50.2c8.8,8.2,30.6,17.4,46.9,22.2
    c0-0.7,0.7-1.6,0.7-1.9l2.5,1c-0.7,0.7-1,1-1,1.9c5.6,1.6,10.7,2.5,13.8,1.9c-0.7-1-1-2.5-1.9-3.4l2.5-0.7c0,1.6,0.7,2.5,0.7,4.1
    c2.5-0.7,3.2-2.5-0.7-6c-18-18.1,80.9,28.2,60.1,7.3c3.2,3.2,80.9,6,62.3-13.3c8.2,8.2,37.1,8.9,40.9-5.7c-3.4,1.9-7.5,4.1-11.4,6
    c-6-5.1-11.6-9.8-18.9-15.5c0.7-1.9,1.9-6.7,3.4-11.4c-2.5-1-5.1-1.9-8.2-3.2c-4.1-10.1,19.9-14.2,38.1-6c3.4,1.6,7.3,3.2,10.7,5.1
    c12.2-8.2,9.2-22.2,13.8-32.9c7.5,10.1,12.2,21.5,11.6,35.7c-7.5,4.1-15.8,8.2-23.3,12.6c36.8,10.8,63.5-22.2,10-75.8
    c-4.7-4.8,22.3-8.2,44.3-12.6c0-0.7-0.7-0.7-0.7-1c0.7,0,1,0.7,1.5,0.7c14.8-3.2,27-6.7,22.3-10.8c7.5,7.5,12.6,1.9,17.3-8.2
    c-5.1,1-10,3.2-14.8,4.1c-3.4-14.2,12.6-14.9,13.8-24.6c-3.4-5.1-8.8-4.1-13.2-3.2c-3.4,0.7-7.5,0.7-11.4,0.7
    c-10.7-28.2,18.2-30.4,27.4-44.6c9.2,1.6,18.2,3.2,28,3.4c1.9,0,6.6-5.1,6.6-7.5c0-4.8-4.7-4.8-8.8-3.4c-2.5,0.7-5.6,0.7-7.5,1
    c-2.5-3.2-4.7-6-7.3-9.2h-0.7h0.7c2.5-2.5,5.1-5.7,7.5-8.2c1.9,0.7,5.1,0.7,7.5,1.6c4.1,1.6,8.8,1.9,9.2-3.2c0.7-2.5-3.4-8.2-6-8.2
    c-9.2-0.7-18.9,0.7-28,1c-8.2-15.5-36.8-20-23.3-47.1c3.4,0.7,7.5,1,11.4,1.6c4.7,1,9.2,3.2,13.2-1.9c0-9.8-16.3-11.6-11.4-26.3
    c11.4,3.4,22.3,11.6,35.2,5.1c1.9-1,7.5,1.6,9.7,4.1c6.6,8.2,13.8,4.8,20.7,1.6c1.9-1,3.4-4.8,5.6-6c7.3-4.8,12.2-4.1,17.3,3.2
    c1,1.6,1.5,4.1,3.2,5.1c13.8,11.4,13.8,29,22.3,43c1.5,1.9,2.5,4.1,4.1,6c16.3-3.4,33-3.4,38.1,1c-15.4-15.5-18-22.4-13.2-24.6
    c0-0.7,0-1,0.7-1c1-1.9,3.2-4.1,4.7-6.7c-1.5-0.7-3.2-1-3.2-1s1.5,0.7,3.2,0.7c0.7,2.5,1.9,4.8,1.9,7.3c17.3,1.6,51.6,17.4,51,16.7
    c-5.1-5.1-5.6-9.2-2.5-11.6c-0.7-1-1-2.5-1.5-3.4l0.7,0.7v-0.7c1.5,0.7,3.2,1,4.7,1.6c18.9-8.2,83.9,1,92.8,9.8
    c9.7,9.8,10.7-67,60.1-17.4c-5.1-5.1,40.9,35.4,46.2,41.1c8.2,8.2,20.7,20.5,28.6,27.2c22.3,29.7,15.4,58.7,15.8,59.4
    c-34.6-34.8,0,48,1,66.1c-0.7,0-0.7,0-0.7,0c-0.7,0-1.5,0-1.9,0.7c1,1,1.9,1,3.2,1c0,2.5-1.5,2.5-4.7-0.7
    c32.8,32.9,13.8,67,8.8,61.3c62.3,62.6,9.2,36.4,26.5,54.3c0.7,0,1,0,1,0c0,0.7,0.7,1,0.7,1.9c11.4,11.6,18.9,23.1,22.3,31.6
    c1.5-1,3.4,0,7.5,2.5c0.7,0,0.7,0,1-0.7v0.7v-0.7v1c4.1,2.5,7.5,5.1,5.6,11.4c-0.7,1,1.9,3.4,3.4,5.7c-6,4.8-11.6,9.2-17.3,13.9
    c0.7,1.9,1,5.1,1.9,5.1c6.6,2.5,12.6,6,9.7,14.9c-2.5,7.5-7.5,9.2-22.9,6.7c0.7-4.8,1-9.2,1-13.9c0-14.2,1.9-29-12.6-38.2
    c-1-0.7-1-4.1,0-5.1c1-0.7,1.9-1.6,3.2-1.9c-21.4-14.9,15.8,86.6,16.7,87.6c-4.1-4.1-18,2.5-24.5,15.5c2.5-1,5.6-1.9,8.2-1.9
    c2.5,0,5.1,1.9,7.5,3.2c-1,7.5-1.5,14.2-2.5,21.5c-4.1,1.6-8.2,3.4-12.6,5.7c2.5,4.8,5.6,9.2,10.7,14.2
    c-12.6-12.6-12.6-7.5-7.3,4.1c2.5-0.7,5.6-1.6,9.7-2.5c-1.5,5.7-2.5,10.1-3.4,14.2c5.1,9.2,11.4,20,16.7,28.7c4.7,1,9.7,1,15.4-1
    c6.6-2.5,15.4,0,22.9-0.7c10.7-0.7,14.8,7.3,20.7,15.5c-4.7,5.7-9.2,10.1-13.2,15.5c-8.8,10.8-8.8,13.3,0,27.5
    c-9.7,1.9-19.9,4.8-29.6,6c-4.1,0.7-7.5,0-11.4-1c-0.7,4.1,3.4,11.4,14.8,23.1c-30.2-30.4-16.3,6.7-5.6,38.9c1.5-1,3.2-1,4.7-0.7
    c10,3.2,19.5,4.1,29.6-0.7c-9.7,7.5-18.9,15.9-30.2,13.3c6.6,22.2,8.2,35.7-14.1,13.9c3.2,3.2,5.6,5.7,8.2,8.2
    c5.1,3.2,10.7,5.7,16.7,7.3c-1.9-2.5-3.4-7.3-3.4-10.8c-0.7-5.1,4.1-9.2,9.2-9.2c8.8,0.7,17.3,1,26.1,1.9v72.1c0,0.7-0.7,1-0.7,1.6
    h0.7v76.2c-0.7,1.6-0.7,3.2-1,4.8c-1.5,5.7-3.4,10.8-11.4,10.8c-2.5,0-5.6-0.7-10-1.6c-0.7,0.7-1,0.7-1,1c0,0,0.7,1,1.5,1.9
    c4.1,4.8,8.2,9.2,11.6,13.9c1.5,1.6,2.5,3.2,4.1,4.8c0.7,0.7,1,0.7,1.5,1c1.5,1,3.2,1.9,4.7,2.5h0.7v24.6c-1.5,0-3.4-0.7-5.1-1
    c-2.5-1-5.1-1.6-7.5-1.6c-6.6,0-12.2,2.5-18.2,5.7c-4.7,1.9-9.2,4.1-13.8,5.1c-1,0.7-1.9,2.5-1.9,3.4c0.7,3.4,2.5,6.7,3.2,7.3
    c3.4,0.7,6.6,1.9,9.7,3.2c5.1,1.6,10,3.4,15.4,3.4c3.2,0,6-0.7,9.2-1.9c3.2-1.6,6.6-2.5,9.7-3.4v26.6c-6,1.9-11.6,2.5-16.3,2.5
    c-1,0-2.5,0-3.4,0h-0.7c-1.5,0-3.2,0-4.1,0c-2.5,0-5.6,0-8.8,1c-13.8,3.4-27.4,7.5-42.1,11.6c-4.7,1.6-9.7,2.5-14.1,4.1
    c0,0.7-0.7,1.9-0.7,2.5c-0.7,3.4-1,9.8-8.8,11.6c-1.9,0.7-4.1,3.4-5.6,6.7c-0.7,1-1,1.9-1.5,2.5c6.6,10.1,11.6,19,18,28.2
    c1.5,0,3.2,0,5.1,0.7c3.4,0.7,7.5,0.7,11.4,0.7c0.7,0,1,0,1.5,0s1.5-0.7,1.9-1.6c0.7-1.6,0.7-4.1-0.7-5.7c-1.9-2.5-3.4-3.4-5.1-3.4
    c-1,0-1.9,0.7-3.4,1c-0.7,0.7-1.5,0.7-2.5,0.7c-2.5,0-5.6-1.6-7.3-2.5c-2.5-1.9-1.5-7.3-0.7-9.2c1.9-4.1,6-11.4,15.4-11.6
    c4.1,0,8.8-1,13.2-1.6c1.5-0.7,3.4-0.7,5.1-1l0.7-0.7c6-8.2,12.2-16.7,23.9-20.5l3.2-1l0.7,3.2c0,0.7,0,1,0,1.6
    c0,1.6,0.7,2.5,0,3.4c-0.7,1.9-1,4.1-1.5,5.7v0.7c-2.5,8.2-4.1,13.9-2.5,16.4c1.5,2.5,7.5,3.2,14.1,3.2c5.6,0,11.4,0.7,16.7,1.6
    v41.5c-5.6,5.1-13.2,6-19.5,6c-6,11.4-17.3,16.7-28,22.2c-2.5,1.6-5.6,2.5-8.2,4.1c0,3.4,0.7,6.7,0.7,9.8c0,0,0,0,0.7,0l5.6-1
    l-2.5,2.5c1,1,1.5,2.5,1.9,3.4c0,0.7,0.7,1,0.7,1.6c5.6-1.9,11.6-4.1,17.3-6c3.2-1,6.6-1.9,9.7-3.4c9.2-3.2,17.3-6,22.9-12.3v25.6
    c-9.2,14.9-6.6,57.2,0,67.6v0.7c-1.5-1-1.5,1.9-0.7,7.5h0.7v62.6v33.1c-1.9,1.6-4.1,2.5-6,3.4c1,1.6,1.9,3.4,2.5,5.1
    c-1.9-1-4.7-1.9-6-3.2c-3.2,1.9-6,3.4-8.2,5.7c5.6,1.9,10.7,5.7,15.4-1.6c1,1,1.9,1.6,3.2,1.9v4.8c-4.1,4.8-8.2,9.2-12.6,14.2
    c-4.1-1-7.3-2.5-9.7-4.8c1,2.5,3.4,5.7,6.6,8.9c-17.3-17.4-42.1,5.1,1.9,56.9c1-0.7,1.5-1.6,2.5-1.9l-0.7,0.7c0.7,0,0.7,0,1,0
    c4.1,6.7,5.6,8.9,9.7,5.7v20.8c-7.5,0.7-15.4-1-23.3-1.6c-0.7-1.6-0.7-2.5-0.7-4.1c-2.5,1-4.7,2.5-6.6,4.1c-1-0.7-1.9-1.6-3.4-2.5
    c-1-1-2.5-1.9-3.4-3.2c-7.5-6.7-14.8-13.3-24.5-13.3c-1,0-1.9,0-3.2,0h-1l-1.5-0.7c-4.7-1.6-7.5-2.5-9.2-2.5s-3.2,0-4.7,8.2
    c0,1,0.7,3.2,1,4.8c0,0.7,0.7,1.6,0.7,1.9v1v0.7v0.7c-0.7,3.4-1,7.5,0,8.9c1,1.2,5.6,1.6,10,1.9l0.7-0.7l1,0.7h0.7
    c5.6,3.2,10.7,5.7,16.3,8.2l6.6,3.4l-1,1.9c-0.7,1.9-1,3.4-1.9,5.1c0.7,0,1,0,1,0c1,0,2.5,0,3.4,0c1.9,0,4.7,0,6.6,1.6
    c4.1,2.5,8.2,4.1,12.6,4.8c1.9,9.8,3.4,26.6,6,35.7c-1,0-1.9,0-2.5,0c-3.4,0-8.8-1-10-7.3c-1,0-1.5,0-2.5,0c-3.4,0-5.1,0.7-5.6,2.5
    c1.5,1.6,3.4,2.5,5.1,4.1c1.5,1,2.5,1.9,4.1,3.2l2.5,1.9l-1.9,1.9c-1.9,1.9-3.4,3.4-5.6,5.7c-1,1-1.5,1.6-2.5,2.5
    c7.5,8.2,14.1,12.6,24.5,14.2c-1.9-5.1-3.4-9.8-5.6-14.2l-1-1.9l1-1c1-1.9,2.5-3.4,3.4-5.7c11.6,12.3-3.4,56.3-12.6,47.8
    c1.5,1.6-0.7,6-3.4,11.6c0.7-1,1-1.9,1.5-3.2c1.9-4.1,3.2-6,0-9.2c-1,0-2.5-0.7-3.4-0.7c-2.5-0.7-5.6-0.7-8.2-1l-1.9-0.7v-1.9
    c0-1.6-0.7-3.2-0.7-4.8c-3.4,1.9-7.3,4.1-10.7,5.7c-9.7,5.7-19.5,11.4-31.4,12.3c-0.7,0.7-1.9,2.5-3.2,4.1c-0.7,0.7-0.7,1-1,1.6
    c11.4,4.8,18.2,12.6,25.5,20.8c2.5,3.2,5.1,6,7.5,8.9c-5.6,8.2-18.9,33.1-8.2,43.6c-6-6-8.2-0.7-8.2,9.8l-3.4-1
    c-6.6-1.6-12.6-3.2-18.9-5.1c-0.7,4.8-0.7,8.2-1,11.6v1l-0.7,0.7c-2.5,2.5-5.6,2.5-8.2,3.2c-2.5,0-3.4,0.7-4.7,1.6
    c5.1,1.9,6.6,2.5,9.7,2.5c2.5,0,5.1-0.7,10-1h1h0.7c1.5,0,3.2,1,4.1,1.6c0.7,0.7,1,0.7,1.5,1l3.2,1.6l-2.5,2.5
    c-3.2,3.2-5.1,5.7-7.5,8.9c1.5,0.7,3.2,0.7,4.7,0.7c5.1,0,9.2-1.9,13.8-4.8c1,18.3,1,35.4-8.8,28.7c-1,0.7-1.9,1-3.4,1.6
    c0,13.3,5.1,55.3,10.7,61c-4.1-4.1-7.5-6-10-6.7c-1.5,1-3.2,1.6-5.1,1.9c-7.5,8.9-3.2,45.6,3.2,62.6c0.7,0.7,1,0.7,1.9,1
    c-0.7,0.7-0.7,1-1,1.6c1,2.5,1.9,4.8,3.2,5.7c1.9,1.9,4.7,4.8,8.2,8.2c0,0,0-0.7,0.7-0.7c1,1,1.5,1.6,2.5,2.5c0,0-0.7,0-0.7,0.7
    c13.8,13.9,33.6,34.1,35.2,35.7c18.2,18.3-10,5.1,34.6,50.2c0.7,0.7,1.5,1.6,1.9,1.9v195H2097c-7.5-3.2-12.2-3.4-9.2,0h-1.5v-0.7
    c-18.2-12.6-91.2-61.3-55-24.9c-4.1-4.1-22.3-14.2-50.3-42c30.6,30.7-33-24.6-50.3-38.2c3.4-1.6,2.5-19.6-4.1-37.9
    c-1,1.9-1.9,4.1-3.2,6c-15.8,3.2-34-7.3-45.9,9.8c-3.4-5.1-7.3-10.1-10.7-14.9c4.1-8.9,9.7-12.3,17.3-9.2c13.8,6,24.8,2.5,35.2-6.7
    c-2.5-4.8-5.6-8.9-9.2-12.3c-5.1-5.1,1.9-17.4-34-54.3c-8.8-8.9-10-16.7-8.8-24.6c-0.7-1-1-2.5-1.5-3.4c0.7,1.6,1,2.5,1.5,3.4
    c0-1.6,0.7-2.5,1-4.1c-1,0-1.5,0-2.5,0c1.5-1.9,3.2-4.1,4.7-6c3.4-10.1,8.2-19.6-1-28.7c-15.8-15.9,0.7-63.8,1-63.5
    c7.5,7.5,13.2,7.5,15.8,3.4c-1.5-1.9-3.2-4.1-3.4-6.7c0-1,2.5-3.4,5.1-5.1c-1.5-11.4-8.8-28.2-23.9-43.6c-6-6-11.6-11.6-16.3-16.4
    c0.7,0,1.5-0.7,1.9-0.7c-1.5-4.1-3.2-5.7-5.1-5.7c-0.7,0-1.5,0-1.9,0.7h-0.7c-0.7-0.7-1-1-1.5-1.6c-1,1-1.9,1.9-3.2,3.2
    c-3.4,0.7-7.3,1.6-9.2,4.8c-7.3-3.4-14.8-7.5-23.3-12.6c0-11.6,0.7-23.4,0.7-35.4c7.3-5.1,14.1-10.1,20.7-14.9
    c-15.4-11.4-24.5-3.2-28.9,6c2.5,0.7,4.7,3.2,7.5,8.9c-4.7,3.2-8.2,5.1-11.4,4.8c0,0.7,0,0.7,0.7,0.7c18,18.1-64.2,13.9-54.4,23.4
    c-23.3-23.4-39.6-20.8-47.8-8.9c1,0,1.9,0,3.2,0c-1,1.9-1.5,4.1-1.9,5.7c-2.5-3.4-1.5-8.2-0.7-11.6c0.7-1.6,1-3.2,1-4.8
    c-1.5-0.7-3.2-0.7-4.7-1c-5.1-1-9.7-1.9-14.8-3.2c-0.7,0-0.7,0-1,0c-1,0-2.5,0.7-4.1,0.7h-0.7c-4.7,9.8-8.8,19-12.6,27.5
    c-2.5,5.7-5.1,11.4-7.5,16.4l3.2,5.7c3.2,6,5.6,11.6,8.8,16.7c1,0,1.9,0,3.2,0c8.8,0,14.8-4.8,21.4-11.6c1.9-1.9,4.1-3.4,6-4.8
    c1.9,5.1,4.7,9.8,8.8,13.9c0.7,0.7,1,1,1.5,1.6c-0.7,0.7-0.7,1.6-0.7,1.9v0.7c1,0,2.5,0,3.4-0.7c64.2,67.6-24.8,53.8-43.7,35.4
    c4.7,4.8-38.1-13.9-23.9,1c15.4,15.5-40.9-13.9-10,16.4c3.4,3.4,10,103.3-15.4,77.7c35.5,35.7-87.1,20-45.3,62.6
    c15.4,15.5-19.5,29.7-43.4,38.9c3.4-5.1,5.6-11.4,6-18.1h0.7c0-0.7-0.7-1-0.7-1c0-12.6-7.3-28.2-24.5-46.1
    c-11.6-11.6,77.1,7.5,34-35.4c11.4,11.4,15.4,9.2,15.8,1.6c2.5,0.7,4.7,0.7,6.6,0.7c4.7,0,6.6-1,11.4-4.1c-3.4-1-7.3-1.6-10.7-1.6
    c-2.5,0-4.7,0.7-6.6,1.6c0-18.3-9.7-54.6,9.2-35.7c-11.6-11.6-21.4-28.7-26.1-43c-1,1-1.5,1.9-2.5,2.5c-1,0.7-4.7-1.9-6.6-3.2
    c0-4.1,3.2-8.2,6.6-9.8c-4.1-22.4,9.7-30.7,56.9,16.4c-43.7-43.9-1.9-52.1,4.7-53.8c-17.3-14.9-83.6-74.6-46.9-37.9
    c40.9,41.1-28.9-24.1-43.7-38.9c-37.1-37.2-69.8-32.3-55-17.4c-20.7-20.8-43.4,14.9-60.7-1.9c15.4,15.5-19.9,74.3-50.3,43.9
    c-3.4-3.4-8.2-6.7-12.6-8.9c0-0.7,0-0.7,0-1c0-0.7,0-1-0.7-1.6l-0.7-1.6l1-1c1.5-1.9,3.4-4.8,5.1-6.7c4.1-5.7,7.5-10.1,12.2-14.2
    c5.1-4.1,11.4-7.3,16.7-10.1c2.5-1.6,5.6-3.2,8.2-4.1c1.9-1.6,2.5-1.9,2.5-2.5c0-0.7,0-1.6,0-2.5v-0.7c-2.5,0.7-5.1,0.7-7.3,0.7
    c-10,0.7-18.9,1-24.5,12.6c-1.9,4.1-9.2,6-14.8,7.3c-8.8,1.6-19.5,3.2-30.2,3.2c-6,0-11.6-0.7-16.7-1.6c-1,1.6-1.5,2.5-2.5,3.4
    c-3.2,4.8-6,9.2-9.7,12.6c-1,1-3.2,1.6-5.1,1.6s-4.7-0.7-6-1.6c-2.5-1.9-4.7-7.3-3.2-10.1c1.5-3.4,3.4-6.7,5.1-9.2
    c0.7-0.7,1-1,1-1.6c-1.5-4.1-3.2-8.2-4.7-12.6c-1-3.4-2.5-7.3-4.1-11.4l-1.5-4.8l4.7,1c4.1,0.7,7.3,1.6,10,1.9
    c3.4,1,7.3,1.6,10.7,1.9c2.5-2.5,5.1-5.7,7.5-8.9c0-0.7,0-0.7,0-1c-10-5.7-20.7-11.4-30.6-16.7c-3.4,2.5-6.6,5.1-9.7,7.3
    c-1.9,1.6-3.4,2.5-5.1,4.1c1,1,1.9,1.9,3.2,3.2c1,1,2.5,2.5,3.4,4.1l2.5,3.2l-3.4,1.6c-1.9,1-4.1,1.6-6,1.6
    c-4.7,0-7.5-1.9-10.7-4.1c-1.5-1-3.2-1.9-4.7-2.5c-0.7,0-1.5-0.7-2.5-0.7s-2.5,0-4.1,0.7c-1.5,0.7-3.4,0.7-5.6,0.7
    c-5.1,0-8.8-1.6-12.2-5.7l-2.5-2.5l3.2-1.6c2.5-1,4.7-2.5,6.6-3.4c3.4-1.9,6.6-3.4,9.7-5.1c1-0.7,1-1,1-4.1s0-8.2,6-9.2
    c1-2.5,2.5-5.1,3.4-7.3c-2.5-1-4.7-1.6-6.6-1.9l-1.9-0.7l0.7-1.9c0-1.6,0.7-3.2,0.7-4.8c0.7-3.2,1-6.7,1.5-9.8
    c0.7-3.2,1.5-6,2.5-9.2c0.7-1,0.7-1.6,1-2.5c-3.4-1-6.6-1.9-9.7-3.2h-0.7c-1,3.2-1,6-1.5,8.9c-0.7,3.4-1,7.3-1.9,10.8
    c-0.7,1.6-1,3.2-1,4.8c-1,5.7-1.9,12.3-13.8,12.3h-1.5l-1-1c-2.5-4.1-4.7-8.9-6-13.3c-3.4-9.8-7.3-18.3-18.9-23.1
    c-3.2,1-6,1.9-9.7,3.2c-8.8,2.5-18.2,5.7-27.4,9.2c-1.9,0.7-3.2,1.9-4.1,3.4c-1,1.9-0.7,4.8,0.7,7.3c1,1.9,2.5,4.8,4.1,6.7
    c0.7,0.7,1,1.6,1.5,1.9l1,1.9l-1.9,1.6c-8.8,7.3-15.4,10.1-20.4,10.1c-9.7,0-12.6-10.8-14.1-18.3c-0.7-1.6-0.7-3.2-1-4.8
    c0-1-0.7-1.6-0.7-2.5c0,0-10.7-3.2-13.8-4.1c-4.1,6-4.1,9.8,0.7,13.9c9.7,9.2,18.9,19,28.6,28.7c3.4,3.4,6.6,6.7,10,10.1l1.5,1.9
    l-1.5,1.9c-1.9,2.5-4.1,4.8-6,7.3c-1.5,1.6-2.5,3.2-4.1,4.8l-1.5,1.6l-1.9-1c-5.6-3.4-9.2-6-13.2-8.2c-1.9,1.6-4.1,3.2-6.6,4.8
    c-1.5,1-3.4,2.5-5.6,4.1c5.6,4.8,10.7,8.9,15.4,12.6l1,0.7v1c0.7,5.7,1,11.4,1,16.7l0.7,5.7l-1.9,0.7c-1.5,0.7-3.2,1-5.1,1.6
    c-2.5,1-4.7,1.6-7.3,2.5c-1,3.4-3.2,5.7-5.1,7.3c2.5,0.7,5.1,0.7,8.2,0.7c3.2,0,6.6,0,11.4-0.7c4.7-8.2,5.6-10.8,9.2-10.8
    c1,0,1.9,0.7,3.2,0.7c5.6,1.9,11.4,3.2,16.3,3.2c10,0,19.5-4.1,27.4-12.6c1.5-1.6,3.2-1.6,4.7-1.6c0.7,0,0.7,0,1,0c0.7,0,1,0,1.5,0
    c0.7,0,1,0,1.5,0h2.5l0.7,2.5c0,1,0.7,1.9,0.7,3.2c0.7,2.5,1,5.1,0.7,7.5c-0.7,3.4-1.5,6.7-1.9,9.2c0,0.7-0.7,1-0.7,1
    c1,1.6,2.5,1.9,4.1,3.2c3.2,1.9,7.3,4.8,5.6,11.6v1l-1,0.7c-6,4.8-13.2,9.8-20.4,14.9l-4.7,3.4c0,4.8,0,9.8,0,14.2
    c-22.9-12.3-55.4-32.3-47.5-24.1c0.7,0.7-71.4-34.8-28,8.9c24.5,24.6-46.9,6.7-50.3,3.2c15.8,15.9-60.7-11.6-48.8,0
    c-23.9-24.1-42.8-3.4-41.3-1.9c-6.6-6.7-10-5.1-17.3-7.3c-1,1.6-1.5,3.4-0.7,6.7c5.6,1,11.4,1.9,14.1,2.5
    c9.2,12.3,17.3,23.1,25.5,34.1c-2.5,18.1-2.5,18.3,7.3,30.7c4.1,5.7,1.5,8.2-1,12.3c-2.5,3.4,4.1,12.6-6.6,12.3v0.7
    c0,0-0.7,0-0.7-0.7c-3.4,3.4-7.5,2.5-12.2,1l-0.7,0.7l-0.7-1l0.7,1c0-0.7-0.7-0.7-0.7-1c1.5,8.9-4.1,13.3-10,18.1
    c-8.8-1-3.2-15.9-14.1-14.9c-5.1,0.7-8.8-3.2-9.2-8.9c14.8-10.1,14.8-10.1,20.7-24.6c-18,12.6-18,12.6-39.6,9.2c0,3.4-1,7.5,0,10.8
    c2.5,5.1,7.3,9.2,9.2,14.2c2.5,6,1,11.4-6.6,12.6c-7.5-5.1-15.4-12.3-23.9-15.5c-9.2-3.4-20.4-3.4-30.6-5.1v-3.4
    c9.2-1,18-2.5,28.9-4.1c-1.9-9.2-3.4-18.1-5.6-28.7c19.9,0,38.1-7.5,58.1,1.6c-10.7-13.9-24.5-23.4-34.6-38.2
    c16.3-3.2,11.4-16.7,14.1-27.2c2.5-0.7,4.7-1,7.3-1.9c-8.2-5.1-18.9-13.9-34.6-29.7c11.6,11.6-16.3-23.4-34-46.4
    c-5.1,0-9.2,0-9.2,0c-12.2,3.4-6,14.2-17.3,13.3c-1.9-3.2-4.7-6.7-7.5-10.8c3.2-9.2,5.6-18.1,8.8-28.2c5.6,3.2,9.7,5.7,14.1,7.5
    c0.7-3.2,2.5-10.1,4.7-17.4c-3.2-6-6.6-12.6-10.7-20.5c-11.6,10.1-22.9,18.3-36.2,21.5c-6.6-4.8-16.7-5.1-18-16.7
    c-0.7-5.1-6.6-9.8-10.7-13.3c-1.9-1.9-6-1.6-9.7-2.5c-1.9,20.8,14.8,29.7,22.1,43.9c-3.4,6-3.2,13.3-8.8,19c-6,6-1,12.3,5.6,15.9
    c0.7,5.1,1.5,10.8,2.5,15.9c-5.6,4.1-11.6,8.2-18.2,13.3c-1.5-7.3-1.9-11.4-3.4-18.3c-7.3,4.1-13.2,8.2-19.9,12.3
    c-10.7-7.5-2.5-22.4-11.4-30.7c1.9-10.1,4.7-20.5,6-30.7c1-10.1-6.6-20.5-0.7-31.3c1-1.9,1.9-5.1,2.5-6
    c-5.1-8.2-8.8-16.4-14.8-22.2c-6.6-6.7-15.8-11.4-23.3-16.4c-1.5-1-3.2-2.5-3.4-4.8c-0.7-1.6,0.7-3.4,0.7-7.5
    c13.2,3.4,28.6,0,35.2,18.3c1,3.2,7.5,5.7,12.2,6.7c15.8,4.1,31.4,7.5,46.9,5.7c4.7,7.5,7.3,13.9,11.4,19c1,1.6,5.6,1.6,7.5,0.7
    c1.5-1,3.4-5.1,3.2-7.3c-1-4.8-4.1-8.9-5.6-12.3c3.4-7.5,6.6-14.2,11.4-24.1c-9.7,1-15.8,1.6-22.3,1.9c-2.5-3.4-5.6-7.3-8.2-11.4
    c-0.7-0.7,0.7-1.9,0.7-4.1c11.6-5.1,23.9-10.1,35.2-15.5c5.1,4.8,9.2,8.9,13.2,12.3c-0.7,0-1-0.7-1.5-0.7c-1,2.5-1.9,5.1-3.2,7.5
    c-2.5,6-5.1,13.3-8.2,21.5c0.7,1,1.5,3.4,2.5,6.7c1.5-1.6,2.5-3.2,3.4-4.1c5.1-5.7,9.2-10.8,13.2-15.9c1.5-1.9,1.9-5.1,3.2-7.5
    c0-0.7,0.7-1,0.7-1.6c-1.9-1-3.4-1.9-5.6-3.2c3.2-7.5,11.4-7.5,27,7.5c1.9-0.7,4.1-1.6,6.6-3.4c-6-4.1-10.7-7.5-15.4-10.1
    c-6-3.2,2.5-12.6-6.6-13.9c-1.5-4.1-3.2-7.5-4.7-11.6c4.1-1,7.3-1.6,10-1.9c0-4.8,0-9.2-0.7-13.9c-0.7-4.8-1.5-8.9-3.2-14.2
    c6-1.6,10.7-2.5,15.4-3.4c1,3.2,1,6,1,8.9c-1.9,1-3.4,2.5-5.6,7.3c2.5-0.7,4.1-1,6-1c0,2.5,0.7,5.1,1,7.5
    c1.5,7.3-1.5,14.9,10.7,16.4c3.2-3.4,5.6-8.9,8.2-13.9c-2.5-4.8-6-9.2-10-13.9c0.7-0.7,1.5-1.6,2.5-2.5c-1,0-1.9-0.7-3.2-0.7
    c3.4-8.2,11.6-67.6,23.9-55.3c-18-18.1,16.3-92.6,18-90.7c1,1,1.9-0.7,1.9-3.4c-0.7,0-0.7,0.7-1,0.7l-0.7-5.1c0.7,0.7,1,0.7,1.5,1
    c0-13.9-6-43-23.3-60.4c-2.5-2.5-3.2-8.2-1.9-15.5c0.7,0.7,0.7,1,1,1c0.7,1,1,1.6,1.9,1.6c0.7,0,1.5-0.7,1.9-1.6
    c0.7-1.6,1-5.1,1-6.7c-1.5-1-3.2-1.6-4.1-2.5c4.7-23.1,18-53.1,19.9-50.5c9.2,9.2,40.3-1.6,64.2-2.5c-1-1-1.5-1.9-2.5-2.5
    c1-1,1.9-1.6,3.2-2.5c1.9,1.9,3.4,3.4,5.6,5.7c8.2,0,14.8,1.9,19.5,6c15.4,15.5,26.1,28.7,34,39.8c0-1,0.7-1.9,0.7-3.2
    c1-1.9,5.1-1.9,7.5-3.2c0,2.5,0,5.7-0.7,8.2c-0.7,1.6-1.9,3.2-3.4,4.8c4.7,7.5,8.2,13.9,10,19.6c0,0.7,0,0.7,0,1
    c0,6.7-5.6,8.9-9.2,10.1c0.7,1.9,1.5,2.5,4.1,2.5c0.7,0,0.7,0,1,0h0.7c4.1,0,7.5,1.9,9.2,5.1c1.5,3.2,1.5,6.7-0.7,9.8
    c-1.9,3.2-4.7,5.1-7.3,7.3c-0.7,0.7-1,1-1.9,1.6l1.9,8.2c1.5-0.7,3.2-1,4.1-1.6c4.1-1.6,7.5-2.5,11.6-2.5c1,0,1.9,0,2.5,0
    c1.9,0.7,4.1,0.7,5.6,0.7c10.7,0,18.2-5.7,26.1-18.3c1-1.6,1.9-3.4,3.2-5.1c8.2,4.1,15.4,8.2,19.9,12.6c1.5,1.6,3.2,3.2,4.7,4.8
    c3.4,1.6,7.3,2.5,10.7,4.1c-1,1-2.5,2.5-3.4,4.8c0.7,0,1,0,1.9,0c1.9,0,4.1-0.7,6-1.6c0.7,0,1-0.7,1.5-0.7c1,0.7,1.9,1,3.4,1
    c2.5-1,4.7-3.2,6-5.7c0.7-0.7,1.5-0.7,1.9-1c-0.7,0-1-0.7-1.5-0.7c1.9-3.4,3.4-8.9,4.7-13.9c-2.5,1.6-5.6,1.9-10-1l7.5-19.6
    c1.5,1.6,3.4,2.5,5.1,4.1c2.5-20.8,3.4-41.1,14.1-30.7c-6-6-12.6-17.4-17.3-28.7c-0.7,0-1,0-1.5,0c0.7-0.7,0.7-1.6,1-1.9
    c-11.6-29-9.2-57.9,48.8,0.7c-19.5-19.6-6-28.7,11.4-29.7c0-1.6,0.7-2.5,1-2.5c10.7-4.1,14.8-10.8,11.6-22.2c1.5,0,3.4-0.7,5.6-0.7
    c1.9,5.1,3.4,10.8,5.6,15.9c1.9,5.1,4.1,10.1,6,14.9c-0.7,0.7-1,1-1.5,1.6c1,0.7,1.9,1.6,3.2,2.5c15.4,15.5,23.9-44.6,33-35.4
    c-7.5-7.5,40.3-4.8,60.1-10.8c-5.1-2.5-10-5.1-15.8-8.2c2.5-4.1,4.7-7.3,6.6-9.8c4.7,4.8,9.2,8.9,13.2,13.3
    c0.7,0.7,0.7,1.6,0.7,2.5c4.1-2.5,5.1-6-0.7-11.6c-28-28.2,46.9,13.9,56,14.9c1,1.9,1.9,4.8,4.1,7.5c0.7,0,1,0,1.5,0
    c-0.7,0.7-0.7,0.7-1,0.7c9.2,18.1,26.5,50.5,20.7,52.8c0.7,1,1,1.6,0.7,1.9c-0.7,1.6-2.5,1.9-4.1,3.4c-1-1.6-2.5-3.4-2.5-4.1
    c0.7-1,1.5-1.6,2.5-1.9c-2.5-1.6-6-4.8-11.6-9.8c-26.5-26.6-10.7,44.9-10.7,44.9c-5.1-5.1-3.2-1.6,2.5,6c5.1-2.5,10.7-2.5,18.2-1
    c-4.7,5.1-9.2,7.3-13.8,7.5c6,8.2,13.8,18.3,18.9,26.3c1.9,0.7,5.1,1.6,8.8,3.2c5.6-1.6,11.4-3.2,16.7-4.8
    c-5.6,1.6-11.4,3.2-16.7,4.8c8.2,3.2,18.9,8.2,28.9,12.6c-2.5,1.6-4.1,2.5-6,4.8c3.2-0.7,5.1-1.6,6.6-4.1
    c11.4,5.7,21.4,11.4,23.9,14.2c2.5,2.5,26.5,11.6,46.2,16.7c1.5-1,3.2-2.5,5.1-3.2c1-0.7,2.5,1,4.1,1.6c-1,1-1.9,1.9-2.5,2.5
    c15.4,3.4,26.5,4.1,18-4.1c18,18.1,49.4,42.3,31.4,23.1c-1.5,0-3.2,0.7-5.1,0.7c1.5-1,2.5-1.6,3.4-1.9c-2.5-3.2-6.6-7.3-11.6-11.6
    c-1.5-1.6,18,0.7,29.6,5.1c2.5-4.1,7.3-6.7,12.2-7.5c22.1-6,43.7-12.3,66.1-18.3c1,0,1.9,0,2.5,0.7c1.5,1.6,3.2,3.4,4.7,5.1
    c-5.6-1.9-10-1.9-13.8,4.8c-18.2,4.1-36.8,8.2-55,12.6c-4.1,1-8.2,3.2-11.6,5.7c0.7,0,0.7,0.7,1,0.7c-0.7-0.7-1-0.7-1.5-0.7
    l-0.7,0.7c-1.5,1.6-1,6.7,0,13.9c1-0.7,1.9-1.6,3.2-1c1,0.7,1.9,2.5,3.2,3.4c-1.5,0.7-3.2,1.9-4.1,1.9c-0.7,0-1-0.7-1.5-1
    c1.5,9.2,5.1,20.8,9.2,32.3c1.9,0,4.1,0,6,0v2.5l-4.7,1.6c3.4,9.8,8.2,19,12.2,25.6c2.5-1,4.7-3.4,7.3-5.7c1,5.1,0,8.9-3.2,11.4
    l0.7,0.7c0,0,19.5,44.6,22.9,66.1c-2.5-2.5-4.7-3.2-5.6-3.2c-1.5,0-3.2,1.6-4.7,4.1c1.9,3.2,4.7,5.1,7.5,5.1c1,0,1.5,0,2.5-0.7
    c0,7.5-3.4,8.9-14.8-2.5c-4.7-4.8-6-3.2-5.6,1.9c1,0.7,2.5,1.6,4.1,1.9c-1.5,1-2.5,1.6-3.4,2.5c3.2,23.1,22.1,78.7,6,62.8
    c8.8,8.9,16.3,20,22.3,31.6c0.7-3.2,0.7-7.3,1.9-9.2c1.9-2.5,6.6-3.2,12.2-5.1c-2.5,6-3.2,10.1-5.6,12.6c-1,1.9-4.7,2.5-6.6,3.4
    c-0.7,0-1,0-1.5-0.7c2.5,5.1,4.7,9.8,6.6,14.9c1.9-2.5,5.1-4.1,9.7-2.5c1,0.7,1.9,1,1.9,1.6c1.5,7.3-3.4,8.9-8.8,10.8
    c1,2.5,1.5,5.1,1.9,7.5c9.2-6,20.4-12.6,21.4-11.6c6.6,6.7,12.2-56.9,18.2-50.5c42.1,42.3,30.6-5.7-14.8-51.2
    c32.1,32.3,5.1-25.6-3.2-53.1c1-1.9,2.5-4.1,4.7-4.8c0.7,0,1-0.7,1.9-0.7c2.5,0,5.1,1.6,7.3,2.5c0.7,0.7,1,0.7,1.5,1
    c12.2-1,13.2-1.9,14.1-13.9l-1.9,1l1.9-6c0-0.7,0.7-1,0.7-1.6l0.7-5.7l1,1.6c1.5-4.1,3.4-8.9,8.2-10.8c-1.5-3.2-3.4-4.8-6-4.8
    c-1,0-2.5,0.7-4.1,1c-3.2,1-6,3.2-8.8,5.1c-2.5,1.6-5.1,3.4-8.2,5.1c-3.4,1.6-6.6,3.2-9.2,4.8v-0.7c-0.7,0.7-1,1-1.5,1.6
    c-5.1,2.5-7.5,4.8-10.7,7.5c-7.3-1.9-14.8-4.8-23.3-7.3c1.5-4.1,2.5-8.9,3.2-8.9c9.2,1.9,18.2-1,27.4-2.5
    c-6-11.4-12.6-23.1-16.7-27.2c-5.6-5.7-9.7-10.8-12.2-15.5c-0.7,0.7-1.9,0-3.2-0.7c0.7-0.7,1.5-0.7,1.9-1
    c-4.7-8.2-5.6-15.9-5.1-22.2c-1.5,0-3.4,0.7-5.1,0.7c1.9-1.9,4.7-4.1,6.6-6c1-2.5,2.5-4.8,4.1-6.7c-4.7-5.1-6-7.3-5.1-10.1
    c0,0,0,0,0.7,0c1,0,2.5-1,4.7-1.9c0-0.7,0-0.7,0-1c-1-0.7-2.5-0.7-3.4-0.7c0.7-1,1.5-2.5,2.5-4.1c1.9-3.2,3.4-6,5.6-9.2
    c5.6,9.8,5.6,9.8,20.4,12.3l-4.1,4.8c4.7-0.7,8.8,1,11.6,3.4c-4.7-4.8-5.6-14.2-4.7-24.9c-1.9,0.7-4.7,0-7.3,0.7
    c2.5-1.6,5.1-2.5,7.5-4.1c1-6,2.5-11.6,4.7-16.4c-1.5,0.7-3.4,1.6-5.6,1.9c-1.5,0-3.2-1-5.1-1.6l11.4-3.2c0,0.7,0.7,0.7,0.7,1
    c4.1-8.2,10-11.6,16.7-5.1c-3.2-3.2-6-9.8-8.8-17.4c-4.1,1.9-8.8,3.2-13.8,2.5c3.4-5.7,8.2-7.3,12.6-7.5c-0.7-2.5-1-5.1-1.5-8.2
    c-1,0.7-2.5,0.7-4.1,1l-3.4-12.3l-15.4,4.1c0-0.7,0-0.7,0-1c5.1-1,10-1.9,15.4-3.4c1.9-1.6,4.1-3.2,6-4.8c0-5.1,0.7-10.1,1.9-13.9
    c-1-0.7-3.2-1-4.7-1c-6,0.7-8.2-1.9-8.2-7.3c4.1-1.6,9.2-2.5,9.2-8.9c0-3.2-0.7-5.7-1-8.9c0-1.6,0-2.5,0-4.1
    c9.7-0.7,15.8-3.4,24.8-12.3c1.5-1.6,1.9-4.1,1.5-6.7c0-1-1-3.2-2.5-3.4c-4.1-1-7.5-1.6-10-1.6c-1.9,0-3.4,0-4.7,0.7
    c-0.7,0-1,0.7-1.9,0.7c1.9-2.5,5.1-4.8,9.2-6c-11.4-6.7-1.9-18.3-12.6-23.4c-4.7-1.9-8.2-5.7-12.2-9.2c-20.4,1.6-46.9,0-47.8-1
    c-15.4-15.5-34-67,5.6-27.5c-4.7-4.8-8.2-8.2-10.7-11.6c-0.7,0-0.7,0-1,0.7c0-0.7,0-1,0-1.6c-11.6-15.5-8.2-21.5-1.5-24.1
    c-0.7-0.7-1-1-1.5-1.6c6,6-114.5-35.4-64.8,15.5c12.6,12.6,1,11.6-22.9-5.7c-1.9,2.5-5.1,4.1-10,4.8c-0.7-4.8-1-9.2-1-13.3
    c-1.5-1-3.2-2.5-4.1-3.4c-0.7,0-1.5,0-1.9-0.7c0.7,0,0.7-0.7,1-0.7c-4.7-4.1-9.7-8.9-15.4-14.2c0,0-0.7,0-0.7,0.7
    c0,0,0-0.7,0.7-0.7l-0.7-0.7l-0.7-0.7c0,0.7,0,1.6,0,1.9c0.7,0,0.7,0,1-0.7c0,0.7,0,0.7-0.7,1v0.7c-6-0.7-12.2-1-20.7-1.6
    c4.1-1.9,7.3-4.1,10.7-6c-9.2-1.9-19.9,0.7-27.4,1.9c-1.9-3.4-4.7-7.5-7.3-12.3c3.2-1,5.6-4.1,5.6-7.3c0-0.7-1-1-1-1.6
    c-0.7,0-1.5-0.7-1.9-0.7c-1.5,0-2.5,1.6-4.1,4.8c0,0.7-0.7,0.7-0.7,1c-11.4-18.1-27-39.5-32.1-44.6c9.7,9.8,47.8-28.7,8.8-67.6
    c0,0-14.1-27.2-22.1-47.1c-1,0.7-1.9,1-3.2,1.6v-0.7c0.7-1.6,1.5-2.5,1.9-3.4c-5.6-14.2-7.3-24.1,3.4-12.6
    c-8.8-8.9-16.3-22.4-39.3-45.6c-8.2-8.2-14.1-14.9-19.5-19.6c0.7-0.7,1.5-1,1.9-1.6c1.9-1.6,4.7-3.2,7.3-4.1
    c3.2-1.6,5.6-2.5,7.5-4.8c1.5-1.6,4.1-2.5,6-3.2c3.4-1,5.1-1.6,5.1-5.7c-1.9-1.6-4.7-2.5-6.6-4.1c-4.1-2.5-8.2-5.1-12.2-7.5l-1.5-1
    l0.7-1.9c0-0.7,0.7-1.6,0.7-1.9c0.7-1.6,0.7-3.4,1.5-5.1c1.9-1.9,3.2-4.1,2.5-5.7c0-0.7,0-1.6-1.9-2.5c-0.7-0.7-1-0.7-1.5-0.7
    c-1,0-2.5,1.6-3.4,3.2c-0.7,0.7-0.7,0.7-1,1c-1,1-2.5,1.9-4.1,2.5c-0.7,0.7-1.5,0.7-1.9,1l-1.5,1l-4.1-3.4
    c-1.5-1.6-3.2-2.5-5.1-4.1c0,1-0.7,1.6-0.7,2.5c-1,5.1-1.9,10.8-4.7,15.5c-1.5,3.4-4.7,6.7-7.3,9.8c-1.9,1.9-4.1,4.1-5.6,6.7
    c-3.4,5.7-8.8,5.7-11.6,5.7c-0.7,0-1,0-1.5,0c-0.6,0-1,0-1.5,0c-3.2,0-5.1,0.7-6.6,1.6c-0.7,1.6-0.7,3.4-1,5.1
    c-1,5.1-2.5,10.1-1.9,14.9c0,0.7,0,1.6,0.7,1.9c-21.7,5.8-26,8.2-25.8,9.3c0.4,2.2,17.8-1,47.8,0.8c19.2,1.2,19.8,3,30,1.9
    c15.8-1.7,22.7-6.9,31-2.8c7.1,3.5,12.7,12.7,11.1,17.6c-2.7,8.5-29.5,13-136.2-5.3c-0.7,0-0.7,0-1,0c0,0,0,0,0,0.7
    c-19.5,2.5-40.3,10.1-18.2,32.3c-18.9-19-16.3,0-11.4,21.5c1.5-1.6,2.5-3.2,3.4-4.1c0,0,0.7,0,0.7,0.7c0,0,0,0,0.7,0
    c1.5,3.2,3.2,6.7,5.1,9.2c2.5,3.2,6,5.7,9.2,8.9v0.7c-5.6,0-10.7,0.7-15.8-1.6c4.7,18.3,9.2,34.1,1.9,27.5
    c4.1,4.1,2.5,10.1-1.9,16.4c3.4,4.8,6,11.4,7.5,19c-6,1.9-12.2,3.4-18.2,5.7c0-4.8-0.7-8.9-1.9-12.6c-11.4,8.2-24.8,12.6-29.6,8.2
    c1.9,1.9-59.1,19-44.3,33.8c-27.4-27.5-113.5-32.9-67.3,13.9c10.7,10.8-77.1,6.7-64.2,19c-1-1-20.7-6-38.7-9.2
    c1.5-3.2,3.2-5.7,4.7-8.9l-1-3.2c0-0.7-0.7-1.6-0.7-1.9c0-0.7,0-1-0.7-1c-1.9-0.7-3.4-0.7-4.7-0.7c-3.2,0-5.1,0.7-5.6,8.2
    c0,1.9,0.7,4.1,1,6c-13.8-2.5-24.8-3.4-22.3-1c3.4,3.4-49.4,33.1-69.2,13.3c13.2,13.3-34.6,16.7-57.6,10.1c-4.7,1.9-6,1.6-7.5-3.2
    c-1-0.7-1.9-1-2.5-1.9c10,10.1-90.2-41.1-58.1-8.9c6,6-6.6,76.8-37.1,46.1c-1.9-1.9-4.1-3.4-6-4.8c0.7,1,1,1.6,1.9,2.5
    c-4.7,1-7.5,1.9-9.7,1.6c-1.5,0-3.2-0.7-4.1-1.6c-10.7,9.2-19.9,32.3-38.7,13.3c-43.7-43.9-30.2,56.9-27.4,59.4
    c-26.5-26.6-29.6,49.7-18,61c1.5,1.6,1,10.1-1.9,20.5c1,1,1.9,3.2,1.5,4.1c-1,1.9-2.5,3.4-4.7,5.1c-2.5,7.3-5.6,13.9-9.7,19
    c6,4.1,11.6,8.2,18,11.6c7.3-6.7,13.2-12.3,18.9-17.4c8.8,4.1,12.2,9.2,9.7,16.7c-1.5,4.1-4.1,8.2-6,12.3c0,0,0,0,0,0.7
    c0,0,0,0.7-0.7,0.7h0.7c1.9,8.9,1.9,16.7-6,22.4c-7.5,5.7-14.1,3.2-20.7-1.9c1-4.1,1.9-7.5,3.2-11.4v0.7c1,1,2.5,1.6,4.1,2.5
    c-1.5-1-3.2-1.9-4.1-3.2c-3.2-11.4-10-14.9-21.4-11.4c-1.5,0.7-4.1-1.6-7.5-3.2c17.3,30.4,14.1,66.4-3.4,57.9c-0.7-1-1-2.5-1.5-2.5
    c-0.7,0-1.5,0.7-1.9,0.7c-1.5-1-2.5-1.9-4.1-3.4c-11.4-11.4-45.9,68.6-54.1,60.4c-9.7-9.8-1,14.2-3.4,24.1c-0.7-1-1-1-1.5-1
    c-0.7,0-1.5,0-3.2,1.6c1,1,2.5,1.9,3.4,2.5c-2.5,2.5-8.8,0.7-21.4-12.3c3.2,0,6-1.6,8.8-3.4h-0.7c1-3.4,1.5-7.3,3.4-9.8
    c0.7-0.7,1.9-0.7,3.4-0.7c7.3-19,3.4-51.9-6.6-62c1.9,1.9-3.2-9.2-9.7-24.1c-0.7,0-1.5,0.7-2.5,0.7c-4.7-6.7-9.2-12.6-13.2-19
    c1.9-1,3.4-1.9,5.6-3.4C487.5,2749.9,483.6,2751.3,479.6,2752.7z M685.3,2692.5c0.7,0,1,0,1.5,0c1,0,1.9,0,3.2,0
    c-1.5-1-2.5-1.6-3.4-1.6C686.5,2691.6,686,2691.9,685.3,2692.5z M1699.7,2736.2c-1.9-3.2-4.1-6-6.6-8.2c-0.7-0.7-2.5-1-4.1-1
    c-1.9,3.2-2.5,5.7-1.9,6c1,1.9,3.2,4.1,5.1,6c0.7,0.7,1.5,1.6,1.9,1.9c5.1-1.6,6-2.5,6-3.2
    C1700.4,2737.7,1700.4,2737.1,1699.7,2736.2z M1373.2,2588.5c1.9,0.7,3.2,1,4.1,1l0,0c1.5,0,3.2-0.7,3.4-1.6c0.7-0.7,0.7-1,0-1.9
    c-0.7-0.7-2.5-1.9-3.4-1.9C1377,2585.1,1375.4,2586.6,1373.2,2588.5C1373.2,2588.2,1373.2,2588.5,1373.2,2588.5z M817.8,3002.2
    c-0.7,0.7-3.4,3.4-5.1,5.1c1.9-0.7,3.4-1.6,4.1-1.9C817.8,3004.8,817.8,3003.9,817.8,3002.2z M933.1,3228.9
    c0.7-1.6,0.7-2.5,0.7-4.1C933.1,3225.7,932.9,3227,933.1,3228.9z M1114.9,3144.8c2.5,5.7,5.1,6,6.6,6c1.9,0,4.1-1,6.6-2.5
    c-3.2-4.8-3.4-5.1-5.6-5.1C1121.3,3143.5,1118.8,3143.9,1114.9,3144.8z M1113.1,3125.5c0,0,0.7,0,0.7-0.7
    C1113.7,3125.5,1113.7,3125.5,1113.1,3125.5C1113.7,3125.5,1113.1,3125.5,1113.1,3125.5z M1101.8,3135.3c1.9,1,3.4,1.6,5.6,2.5
    c0,0-0.7-2.5-0.7-3.4C1104.9,3134.7,1103.4,3135.3,1101.8,3135.3z M1079.4,2738.6c0.7,0,1.5,0.7,1.5,0.7c0.7-0.7,0.7-1.6,1-1.9l0,0
    C1080.4,2737.7,1080.1,2737.7,1079.4,2738.6z M1043.9,2725.8L1043.9,2725.8c0.7,1,1,1.9,1.9,3.2c0.7-1.9,0.7-4.1,0-6
    c-0.7-0.7-1.5-0.7-1.9-1C1043.9,2723.8,1043.9,2724.8,1043.9,2725.8z M1026.9,2954.5c-3.4-3.2-6-4.1-9.2-4.1s-5.6,1.6-9.7,4.1
    c0.7,0.7,1.5,0.7,1.9,1c4.7,1.9,9.2,4.1,13.8,5.7c1,0,3.2-1.6,3.2-1.9l0,0l0,0C1028.2,2958,1027.6,2955.4,1026.9,2954.5z
    M1020.9,2725.4c1.9-0.7,4.1-1,6-1.9C1025,2723.8,1022.8,2724.1,1020.9,2725.4z M1011.2,2884c-5.1,1-10.7,2.5-16.3,2.5
    c-1.5,0-3.4,0-5.1-0.7c-0.7,1-1,2.5-1,3.4c1.5,0.7,3.4,1,5.1,1.6c5.1,1.6,10,3.2,15.4,4.8c5.1-4.1,9.7-8.2,13.8-11.6
    c1-1,1.9-1.6,3.2-2.5C1020.9,2881.8,1016.2,2882.8,1011.2,2884z M985.1,3048.1L985.1,3048.1c1.5-0.7,3.4-1,3.4-1.9l0,0l0,0
    c1-1.6,1.9-3.4,3.2-6.7l0,0c-2.5,1-4.7,1.6-5.1,2.5c-1,1-1,3.4-1.5,5.7C985.1,3047.8,985.1,3047.8,985.1,3048.1z M986.3,2811.3
    c0-0.7-0.7-1-0.7-1.6c-0.7,0.7-1,1-1.5,1.6C984.8,2811.3,985.1,2811.3,986.3,2811.3z M988.2,3073.7c1,0,1.9-0.7,3.2-0.7
    c0.7,0,1-0.7,1.5-0.7c5.1-1.9,7.3-3.2,6-6.7c0,0-0.7,0-0.7-0.7l0,0c-1-0.7-1.9-0.7-2.5-0.7c-2.5,0-4.1,1.9-6.6,6l-0.7,0.7
    C988.8,3072.4,988.2,3072.7,988.2,3073.7z M1000.5,2804.1C999.9,2804.1,999.9,2804.1,1000.5,2804.1c-2.5,3.2-5.1,6-7.5,9.2
    c1.5,3.4,3.2,3.4,4.1,3.4c1.5,0,3.2-0.7,5.1-1.6c2.5-1,5.1-2.5,7.5-4.1c0.7-0.7,1.5-0.7,1.9-1
    C1009.6,2805.7,1006.8,2804.1,1000.5,2804.1z M996.5,2666c0.7,3.2,1,5.7,1.5,8.9c0.7,3.2,1,6,1.5,9.2c1-1,1.5-1.6,2.5-1.9
    c2.5-2.5,5.1-4.8,7.5-7.3c-1-3.2-1.9-6.7-3.2-10.1C1002.7,2664.3,999.5,2665.3,996.5,2666z M990.7,2780.7l-0.7,0.7
    c-1,1.6-1.9,3.2-3.2,4.8c-2.5,4.1-4.1,6.7-4.1,8.2s1.5,3.2,4.7,6.7c1.5-0.7,2.5-0.7,4.1-1c1.9-0.7,3.4-1,5.6-1.6l8.2-9.2
    C997,2787.4,994.5,2786.1,990.7,2780.7z M983.9,2720.7l1.9-0.7l0,0C985.1,2720.3,984.8,2720.7,983.9,2720.7z M975.9,2774.1
    c-14.1,4.1-28.9,8.2-43.7,12.3c-3.4,1-6.6,2.5-8.8,4.8c3.4-1.9,7.3-4.1,11.4-5.1c14.1-3.4,28.9-7.3,43.4-10.1
    c3.4-1,7.3-1.6,10.7-2.5c1-1.6,1.5-2.5,2.5-3.2C986.6,2770.9,981.7,2772.5,975.9,2774.1z M960.8,2862.5c0.7,0,1-0.7,1.5-0.7
    c0-0.7,0-1,0-1.6C961.8,2861,961.2,2861.9,960.8,2862.5z M927.2,2952.7c6,4.1,9.7,6,12.6,6s6.6-1.9,12.2-6c-0.7,0-0.7-0.7-1-0.7
    c-2.5-1.6-4.7-2.5-6-2.5c0,0,0,0-0.7,0C938.9,2950.1,933.1,2951.1,927.2,2952.7z M937,2879.9c1.9,0,4.1-0.7,6-1
    c4.1-1,6.6-3.2,7.3-6c-1.9,1.6-4.1,3.2-7.3,3.2c-2.5,0-5.1-1-8.2-3.4c-1.5-1.6-3.4-1.9-5.6-1.9c-1.9,0-4.7,0.7-7.5,3.2
    C927.2,2877.7,931.6,2879.9,937,2879.9z M954.5,2827.2c0,0,0.7,0,0.7-0.7c0,0,0,0,0.7,0v-0.7c-1.9-1-4.1-1.9-6-3.4
    c0,0-0.7,1.6-1,1.6l0,0C950.5,2825,952.7,2826.2,954.5,2827.2z M926.6,2760.4L926.6,2760.4c0.7,0,1.5,0,2.5-0.7l-4.1-14.2
    c-4.7,2.5-6,4.8-4.1,9.2C923.8,2760.1,925,2760.4,926.6,2760.4z M947.9,2616.3c6-1,12.6-2.5,19.5-5.7c-2.5,0-4.7-0.7-6.6-0.7
    s-3.4,0-5.1,0.7C950.1,2611.6,948.6,2613.8,947.9,2616.3z M933.8,2732.1c-3.2-1.9-5.1-5.1-7.3-8.2c-0.7-0.7-1-1.6-1.5-1.9l4.7,15.9
    l0,0c1.9,0,3.4,0.7,5.6,0.7c1.5,0,3.2,0.7,4.7,0.7c1.9,0,4.1-0.7,6-2.5h-0.7C941.4,2734.8,937.3,2734.5,933.8,2732.1z
    M924.1,2642.9c3.4,1.9,5.6,3.2,7.5,3.2l0,0c0.7,0,0.7,0,1,0C930.4,2644.5,927.2,2643.5,924.1,2642.9z M917.1,2680.2
    c3.4,1,6.6,1.6,9.7,2.5l0,0c-1.5-3.2-3.4-3.4-5.1-3.4C920.6,2678.6,919,2679.2,917.1,2680.2z M914.1,2849.6l12.6-3.4
    c-3.2,0-6,0-9.2,0.7c-0.7,0-1.9,0.7-3.2,1.6C914.1,2848.7,914.1,2849.2,914.1,2849.6z M884.5,2947.2c0.7,0.7,1,1,1,1.6
    c1,3.2,1.5,6,1.9,10.1c0,0.7,0,0.7,0,1c0.7-0.7,1-1,1.5-1.6c3.2-2.5,5.6-5.1,6-7.3c0-1-1.5-4.1-2.5-6c-0.7-0.7-1-1.6-1-1.9
    C889.5,2945.3,886.9,2946.3,884.5,2947.2L884.5,2947.2z M872.8,2832.2c1.9,0,3.4-0.7,5.6-2.5c-1.5-1.6-2.5-1.9-3.2-1.9
    s-1.9,0.7-3.4,4.1C871.6,2832.2,872.1,2832.2,872.8,2832.2z M929.1,3018.7c-1,0-1.5-0.7-2.5-0.7c-2.5-1-5.1-1.6-8.2-1.6
    c-5.1,0-10,1.9-15.8,4.1c-5.1,1.9-10,4.1-15.8,4.8c-2.5,0-5.6,3.2-9.7,6.7c-1,1-1.9,1.6-3.2,2.5c0.7,0,0.7,0,0.7,0
    c1.9,0.7,3.4,0.7,5.1,0.7c0.7,0,1,0,1.5,0c15.8-4.8,30.2-9.2,44.3-14.2C926.6,3019.6,927.8,3019.1,929.1,3018.7z M944.5,2995.9
    c0-0.7,0.7-0.7,0.7-1c0.7-1,1.9-3.2,1.9-4.1l0,0c-0.7-1.6-3.4-3.4-5.1-3.4c-1.5,0.7-3.2,0.7-4.7,0.7c-4.1,0.7-7.3,1-8.8,4.1l3.2,1
    C936.3,2993.4,940.4,2994.3,944.5,2995.9z M1024,3113.8c1.9-3.4,4.1-7.3,5.6-10.1c-3.2-1.6-6.6-1.9-10-1.9
    c-6.6,0-12.6,1.9-20.4,5.1c-3.4,1.6-7.5,3.2-11.4,4.8c-1,0.7-2.5,1-3.4,1.6l-1.9,1l-1-1.6c-8.2-11.4-1.5-16.7,3.2-21.5
    c1.9-1.6,3.4-3.2,4.7-5.1c-1.5-1.6-3.2-3.2-5.1-4.8c-1.5-0.7-3.2-1-5.1-1c-3.4,0-7.5,1.6-11.6,2.5c-1,0.7-1.9,0.7-3.2,1
    c-5.6,1.9-11.6,2.5-18,3.2c-1.5,0-3.4,0-5.1,0.7c-1.5,6.7,1.9,11.4,6.6,16.4c4.1,4.8,8.2,9.8,7.3,16.7c0.7,0,1.5-0.7,1.9-1
    c1.9-1,3.4-1.6,5.6-1.6c3.2,0,5.1,1.6,7.3,5.1l1,1.6l-1,1.6c-1.9,2.5-3.4,4.8-5.6,7.5c1.5,1.6,2.5,3.2,4.1,4.1
    c7.3-12.3,19.5-19.6,35.5-20.8c5.6-0.7,11.4-1.6,16.3-2.5C1020.9,3114.2,1022.5,3113.8,1024,3113.8z M1033.5,3117.9
    c-1.9,0.7-4.1,1-5.6,1.6c0,1-0.7,1.9-0.7,3.2c0,1,0,2.5,0.7,3.4c1,1,1.9,1,3.2,1.6h0.7c1.5,0,1.9,0,1.9-5.1c0-1,0-1.9,0-3.2
    C1033.5,3117.9,1033.5,3117.9,1033.5,3117.9z M1036.7,2777.5c5.1,1.9,10,3.2,15.8,3.2c3.2,0,5.6-0.7,8.8-1c-1.9,0-4.7,0-6.6,0h-1.5
    l-1-1c-3.2-4.8-6-9.2-7.3-9.2C1043.2,2769.3,1040.1,2773.4,1036.7,2777.5z M1046.4,3143.9c-0.7,0-1.5,0-2.5,1.6l0,0
    c1,0,1.5,0,2.5,0s1.9,0,2.5-1C1048,3143.9,1047.3,3143.9,1046.4,3143.9z M1061.2,3103.4c-1,0-1.5,0-2.5,0c-1.9,0-3.4,0-6,0.7
    c-4.1,1-8.2,1-12.2,1h-3.2c0.7,1.6,1,3.4,1.5,5.7c1,0,1.9,0,3.4,0c3.4,0,7.3,0.7,10.7,0.7C1054.9,3107.2,1058,3105,1061.2,3103.4z
    M1060.2,2895.1l0.7-0.7c-1-1-1.5-1.9-1.9-1.9c-0.7,0.7-1,1-1.5,1.9C1058.7,2894.8,1059.7,2895.1,1060.2,2895.1z M1069.7,2852.1
    c-9.7,1.6-13.8,8.2-18,16.7c1.5,0,3.2,0,4.7,0C1064.1,2869.5,1065.3,2868.5,1069.7,2852.1z M1087,2986.5h-0.7h-0.7l0,0
    c-1,0.7-1.9,1-1.9,1.9c-0.7,1,0,2.5,0.7,3.4c0,0.7,0.7,1,1,1.6c0.7,0,0.7-0.7,0.7-0.7C1086.7,2990.9,1087,2989,1087,2986.5z
    M1110,2871.7c0-0.7-0.7-0.7-0.7-1c-1-2.5-1.9-4.8-2.5-7.3c-3.4-13.3-1.5-19.6,8.2-24.9c-1,0-1.5,0-2.5,0c-2.5,0-4.7-0.7-7.5-1
    l-1-0.7l-0.7-1c-0.7-1-1.5-2.5-2.5-4.1c-1.9-3.2-4.1-7.5-5.6-11.6c-1-4.1-3.4-4.8-7.5-5.1l0.7,1l-1-0.7l0.7,2.5l-3.2-3.2h-0.7
    l-1.5,1.6l-1-1.9c-1,0-1.5-0.7-2.5-0.7c-3.4-1-7.5-1.9-10.7-4.1c-3.4-1.9-6.6-4.8-10-7.5c0,0,0,0-0.7-0.7c-1,3.2-1.9,5.7-3.2,8.2
    c-1.9,5.7-3.4,10.8-5.6,16.4l5.6,1.9l-4.7,1.6c0.7,0.7,1,1.6,1.5,1.9c5.1-5.1,10-9.2,15.4-14.2l1.5-1.6l1.9,1.6
    c1.5,1,2.5,1.9,3.4,3.2c1,1,3.2,3.2,4.1,3.2l0,0c0.7,0,1,0,1.9,0c13.8,0,16.7,10.1,18.2,17.4c1,4.8,1.5,9.8,1.9,14.2
    c0.7,1.9,0.7,3.4,1,5.7v1l-0.7,1c-3.2,4.1-6,8.2-9.2,12.3c-1.9,3.2-4.1,5.7-6.6,8.2l0,0l0,0l0,0c1.9,1.9,3.4,3.4,5.6,5.1
    c1,0.7,2.5,0.7,4.7,1c0.7,0,1,0,1.5,0l1.5-1.9C1103,2882.5,1106.6,2876.7,1110,2871.7z M1113.1,2955.2L1113.1,2955.2
    c-3.2,4.1-3.4,6,0,8.9C1116.6,2961.2,1115.6,2959.6,1113.1,2955.2z M1119.7,2757.9c1,0,1.5,0,1.9,0c4.7-1,7.3-2.5,7.5-5.1
    c-1.5,0.7-3.4,1-5.1,1c-1,0-1.9-0.7-3.2-0.7c-0.7,0-1-0.7-1.5-0.7c-1.5-1-3.2-1.6-4.1-1.6c-0.7,0-1.5,0-1.9,0.7
    c-4.7,2.5-9.2,5.1-13.2,7.5c-6.6,4.1-13.8,8.2-20.7,12.3c-0.7,0.7-0.7,1.9-0.7,3.2c0,0.7,0,1,0,1.6c6,5.1,9.2,7.3,12.2,7.3l0,0
    c3.4,0,8.2-2.5,16.7-7.5c-0.7-0.7-1-1.6-1.5-1.9c-1-1-1.9-2.5-3.2-3.4c-0.7-0.7-1-1.6-1.9-1.9l-1-1.6l1-1.6c3.4-6,8.2-7.3,11.6-7.3
    c1,0,2.5,0,3.4,0C1117.5,2757.9,1118.8,2757.9,1119.7,2757.9z M1127,2789.8c-0.7,0-0.7,0-1,0c-0.7,0-1,0-1.5-0.7
    c-0.7,1-1,1.9-1.5,3.2h0.7l0,0c1.5-0.7,3.4-1,4.1-1.6C1127,2790.2,1127,2790.2,1127,2789.8z M1129.8,2944.8c0-0.7-0.7-1.6-1.5-4.1
    c-1.5,1-3.4,1.9-5.1,3.2c-0.7,0.7-1,0.7-1.5,1c1.5,0,3.2,0,4.1,0c0.7,0,1,0,1.5,0C1128.8,2945.3,1129.5,2945.3,1129.8,2944.8z
    M1138.5,2819c-1.9,1.9-4.1,4.8-6,7.3c-2.5,3.2-5.1,6.7-8.8,8.9c1-0.7,1.9-0.7,2.5-1C1134.5,2830.6,1135.5,2830.3,1138.5,2819z
    M1184.5,2652c0,0.7,0.7,1,0.7,1.6l0,0l0,0l0,0v-0.7C1185.4,2653,1185.4,2652.7,1184.5,2652C1184.8,2652,1184.8,2652,1184.5,2652z
    M1191.1,2859.4c-0.7-1-3.2-3.2-4.7-3.4c-1,0.7-3.4,3.2-3.4,4.1c0,3.2,1,6,2.5,9.8c0,0.7,0.7,1,0.7,1.9
    C1191.1,2869.5,1191.1,2865.4,1191.1,2859.4z M1314.4,2671.7c0,0.7,1.9,1.9,4.1,3.2c0.7,0.7,1,0.7,1.9,1c0-0.7,0.7-1.6,0.7-1.9
    c0.7-3.4,1-5.7,1-8.2c-0.7-0.7-2.5-1.6-3.4-1.6l0,0c-1.5,0.7-3.2,1-3.4,2.5C1313.7,2667.9,1313.7,2669.4,1314.4,2671.7z
    M1307.4,2891C1307.4,2891,1307.4,2891.6,1307.4,2891c1,0,1.9-1,2.5-1.6c-0.7-0.7-1-0.7-1.5-1l0,0
    C1308.1,2889.5,1307.4,2890,1307.4,2891z M1276,2646.4c0,1.9,1,4.8,3.4,5.1c3.2,1,6,1,8.2,1l0,0c3.4,0,6.6-0.7,10-1.9
    c-4.7-1-9.2-1.6-12.6-2.5C1281.7,2647.6,1278.5,2647.1,1276,2646.4z M1250.5,2642.9c3.2,1.6,5.6,2.5,8.8,2.5s6-1,9.7-3.2l-6-6
    l-1,0.7l-2.5-4.1C1254.6,2635.6,1251.5,2638.2,1250.5,2642.9z M1247.1,2713.3h0.7c1,0,2.5,0,3.4,0.7c1.5,0.7,2.5,1,4.1,1.6
    c1,0.7,2.5,1,3.4,1.6c1-0.7,1.9-1.6,1.9-3.2c0.7-1.6,0-3.4-1-5.7c-3.2-4.8-6-9.2-9.7-13.3c-1-1.6-1.9-3.2-3.2-4.1
    c-4.1,6-6.6,9.8-6.6,12.3C1240.8,2705.5,1242.3,2707.4,1247.1,2713.3z M1203.7,2954.5c-0.7,0-0.7,0-1-0.7l0,0l0,0c-0.7,0.7-1,1-1,1
    c0,1.9,0,3.4,0.7,5.7v0.7l0,0c0.7,0,1.5,0,1.9-0.7C1204.3,2959.3,1203.7,2956.8,1203.7,2954.5z M1215.3,2885
    c-1-3.4-3.2-4.1-4.7-4.1c-1,0-1.9,0-3.2,0.7c-0.7,2.5-1,5.1,0,6c0.7,1,1.9,1,3.4,1C1212.3,2888.6,1215,2885.9,1215.3,2885z
    M1216,2741.8c1-5.1,2.5-9.8,3.4-14.2c-4.7,0.7-7.3,1-8.8,1.6c-1,0.7-1,0.7-1.9,3.4c-1,2.5-1.5,5.1-1.9,7.5c-0.7,1-0.7,2.5-1,3.4
    c0.7,1,0.7,1.9,1,3.2c1,0,2.5,0.7,3.4,0.7C1212.5,2747.8,1214.3,2746.9,1216,2741.8z M1220.9,3022.2c0,0-0.7,0-1,0c-0.7,0-1,0-1,0
    c-0.7,0.7-1.5,0.7-1.9,1c1,0.7,1.5,1,1.9,1c0.7,0,1.5-0.7,1.9-1C1220.9,3023.2,1220.9,3022.5,1220.9,3022.2z M1248.1,2953.9
    c-1.5-3.2-3.4-6.7-5.6-8.9c0,0-0.7,0-1,0c-1,0-2.5,0.7-3.2,0.7c-0.7,0.7-1,1.6-1.5,3.2c0,0.7-0.7,0.7-0.7,1c0.7,0.7,1.5,0.7,1.9,1
    c3.4,1.6,6.6,2.5,9.7,3.4C1247.4,2954.5,1248.1,2953.9,1248.1,2953.9z M1271.4,2822.4c-6-9.2-12.6-12.6-22.3-12.6
    c-5.1,0-11.6,1-19.9,3.4c-0.7,4.8-2.5,10.1-8.2,13.9l0,0c-1.5,7.5-3.2,13.3,5.1,18.1c1.5-0.7,2.5-1,4.1-1.6
    c4.1-1.6,8.2-2.5,12.2-4.8c1.9-1,4.1-1,5.6-1c1,0,1.9,0,3.2,0c1,0,1.5,0,2.5,0c1.9,0,3.4-0.7,4.7-1.9c-0.7-0.7-1-1-1.5-1.6
    c-2.5-2.5-4.7-5.1-4.1-7.5c1-3.4,3.4-5.7,7.5-5.7c1.5,0,3.4,0.7,5.6,1C1267.8,2822.4,1269.4,2822.4,1271.4,2822.4z M1271,2736.2
    c-0.7-3.2-1-5.7-1.5-8.9c-9.2,8.9-15.8,15.5-23.3,22.2c4.1,4.8,8.8,6.7,14.1,6.7c3.2,0,6.6-0.7,10-1.6
    C1274.1,2748.5,1272.6,2742.8,1271,2736.2z M1277,2979.5l-0.7-0.7c-0.7,0.7-1,1-1.9,1.6l0.7,0.7
    C1276,2980.8,1276.7,2980.1,1277,2979.5z M1313.7,3000c-1.5-1-3.4-1.6-5.1-1.6c-2.5,0-4.7,1-7.3,3.4c-7.3,7.5-14.1,15.5-23.3,25.6
    c6,3.4,11.6,7.3,17.3,10.1l16.7-4.8c0-0.7,0-1,0-1.6c0-3.2,0-5.7,1-8.9c1-1.9,1.9-4.1,3.4-5.7c1-1.6,1.9-2.5,2.5-4.1
    C1321.3,3009.2,1318.8,3003.2,1313.7,3000z M1332,2831.3c-6-4.1-11.6-8.2-18-11.6l0,0c-0.7,1-0.7,1.9,1.5,4.1
    c0.7,0.7,1,1.6,1.5,1.9l0,0c1.5,3.2,3.4,5.7,6,9.2c1,1.6,1.9,2.5,3.2,4.8c1.5-2.5,3.2-4.8,4.1-6.7
    C1331.7,2832.2,1331.7,2831.6,1332,2831.3z M1364.1,2672.7c-1,1.6-1.9,3.2-3.2,4.8c-1.5,2.5-3.2,5.1-4.7,7.5l0,0
    c3.4,0,6-2.5,7.5-7.3C1364.7,2675.8,1364.7,2674.2,1364.1,2672.7z M1343.4,2664.3c7.3-1,13.8-1.6,19.5-2.5c-1-3.2-3.2-3.2-5.1-3.2
    c-1.5,0-3.2,0-4.7,0.7c-1.5,0-3.2,0.7-4.7,0.7h-0.7l0,0c-1.5,0-3.2,0.7-4.1,1.6C1343.4,2661.9,1343.4,2663.5,1343.4,2664.3z
    M1357.2,2796.5c0.7,0,1.5,0.7,1.9,0.7c1,0,3.2-1.6,3.4-2.5l0,0c0.7-1.6,0.7-4.1,0-5.1c-3.4-5.1-7.5-7.3-13.2-7.3
    c-1.9,0-4.7,0.7-7.3,1c-0.7,0.7-0.7,0.7-1,1c-1,0.7-2.5,1.9-3.2,3.2c1,2.5,3.4,6,4.7,7.3c1,0,1.9,0,3.2,0
    C1349.9,2793.9,1353.4,2795.6,1357.2,2796.5z M1369.8,2946.3l-3.4-1.9c-3.2-1.6-5.6-3.4-8.8-5.1c-3.4,5.1-1.9,9.2,1.9,14.2
    C1365,2952.7,1368.8,2951.1,1369.8,2946.3z M1373.2,2998.1c-3.2-0.7-6-0.7-9.7-1c0.7,0.7,0.7,1,1,1.6c2.5,4.1,5.1,7.3,6,10.8
    c1.5,3.4,1.5,7.3,1.9,10.8c0,0.7,0,1,0,1.6c0-1.9,0.7-3.4,1.5-5.1C1378.9,3010.5,1378.9,3004.8,1373.2,2998.1z M1453.7,2756.3
    c0.7,1,1,1.9,1.9,3.2l0.7-0.7v-0.3c0-1,0-1.9,0-3.2l0,0c-0.7,0-1,0-1,0C1454.7,2755.5,1454.4,2756,1453.7,2756.3z M1383,2781.6h0.7
    c1-1,1.5-2.5,1.9-4.1C1385.2,2778.5,1383.9,2780.1,1383,2781.6z M1382,2916.3c1.5,0.7,3.2,0.7,4.1,0.7c3.2,0,6-1.6,8.2-4.1
    c0.7-0.7,1-1.9,1-2.5l0,0l0,0c0-0.7-1-1.6-1-1.9c-1-0.7-2.5-0.7-3.4-0.7c-0.7,0-1,0-1.9,0c-0.7,0-1,0-1.5,0c-0.7,0-1.5,0.7-1.9,0.7
    c-1.5,0.7-3.4,1-4.7,1.9c-0.7,0.7-1,1-1,1.6C1379.8,2914.1,1381.1,2915.6,1382,2916.3z M1398.3,2928.9c-1.5-0.7-3.2-1-4.1-1
    c-1.9,0-3.4,1-5.1,3.2c-1.5,1.9-1.9,4.8-2.5,7.5c0,1-0.7,1.9-0.7,3.2c1,1,1.9,1.6,3.4,2.5c1.5,1,3.4,2.5,4.7,3.2
    c3.4-1.9,7.3-6,8.8-10.1C1403.7,2933.7,1401.2,2930.5,1398.3,2928.9z M1415.6,2717.2c-8.8,0.7-13.8,6-19.5,12.6
    c-2.5,2.5-4.7,5.7-7.5,8.2c6-1,11.4-4.8,16.7-8.2c3.2-1.9,6-4.1,9.2-6c1.5-0.7,1.9-1.6,2.5-3.2
    C1417.3,2719.7,1416.6,2718.8,1415.6,2717.2z M1462.9,2864.1L1462.9,2864.1h-0.7l0.7,0.7V2864.1z M1470.7,2800.9l-0.7-0.7
    c-1.9-1.9-4.1-4.1-5.6-6l0,0c-1-2.5-1.5-5.7-1.5-8.9l0,0c-3.4,1.9-7.3,4.1-10.7,5.7c-1.9,1-4.7,2.5-7.3,3.4l-1.9,1l-1.5-1.6
    c-3.2-3.4-6-7.3-9.2-10.1c-2.5-2.5-4.7-5.1-6.6-7.5c-8.2,3.4-8.8,8.2-7.5,12.3c0.7,3.2,1.9,6,3.4,9.2v0.7l3.4-1.6l-1.9,5.7l1,3.2
    l-2.5,0.7c-2.5,8.2-1.5,13.9,3.4,18.3c2.5,1.9,5.1,3.2,7.5,3.2s5.1-1,8.8-2.5c-0.7-1.9-0.7-3.4-1-5.7c-0.7,0-1,0.7-1.5,0.7
    l-2.5-5.1c1-0.7,1.9-1.6,3.4-1.9h0.7c3.4-8.2,8.8-12.3,15.8-12.3c3.2,0,6,0.7,9.7,1.9c0.7,0,1.9-0.7,3.2-1
    C1470.1,2800.9,1470.7,2800.9,1470.7,2800.9z M1527.6,2765.9c0.7,0.7,1.5,1,2.5,1c0.7,0,0.7,0,1,0.7c0.7-1,1-1.6,1.5-2.5l0,0
    c-0.7,0-1-0.7-1.5-0.7c-1-0.7-1.5-0.7-2.5-1C1527.6,2763.7,1527.6,2765.2,1527.6,2765.9z M1530.2,3103.4c0-1.6-0.7-2.5-0.7-3.4
    c0,1.6,0,2.5,0,3.2C1529.3,3103.1,1529.5,3103.1,1530.2,3103.4z M1533.4,2800.9C1533.4,2800.6,1533.4,2800.6,1533.4,2800.9
    c-1.5,0-3.2,1-5.1,1.9c1,0.7,2.5,1,3.2,1l0,0C1531.7,2803.5,1532.4,2803.1,1533.4,2800.9z M1579.2,3208c0-1.6-1-3.2-3.2-4.8
    c0,0-1-0.7-1.5-0.7l0,0c0,0.7-0.7,1-0.7,1c1,2.5,2.5,5.1,3.4,7.5C1579.2,3210.5,1579.2,3208.9,1579.2,3208z M1543.1,3343.9
    c-1-1.6-1.9-2.5-3.2-4.1l0,0l-0.7,0.7c-0.7,1-1.5,3.2-1.5,3.4c0.7,1,1.9,1.9,3.2,1.9C1540.9,3345.5,1542.4,3345.2,1543.1,3343.9z
    M1564.5,3317.3c-0.7,0-0.7-0.7-1-0.7c-0.7,0.7-1.5,1-1.9,1.9c1,0.7,1.5,0.7,2.5,0.7C1563.8,3318.9,1564.5,3318.3,1564.5,3317.3z
    M1574.5,2747.1c0.7,0,1.5,1,1.5,1c0.7-0.7,1-1.6,1.5-1.9l-0.7-0.7C1576,2746.3,1575.5,2746.9,1574.5,2747.1z M1559.7,2772.8
    c1.9-0.7,3.4-1,4.1-1.6c0.7-0.7,1-1,1.5-1.9c-0.7,0-0.7,0-1,0c-0.7,0-1,0-1,0C1562.3,2770,1561.3,2770.9,1559.7,2772.8z
    M1541.5,2930.5c-0.7-1.9-0.7-3.4-1-5.1c0,0-0.7,0-1,0c0,0,0,0-0.7,0c0.7,1.6,1,3.4,1.5,5.1
    C1540.2,2930.5,1540.9,2930.5,1541.5,2930.5C1540.9,2930.5,1541.5,2930.5,1541.5,2930.5z M1564.5,2836.4c3.4-4.1,6.6-7.5,10-11.6
    l1.9-1.9c-0.7,0-0.7-0.7-1-0.7c-1.9,1.9-4.7,3.2-7.3,3.2c-2.5,0-5.1-1-7.5-1.9c-1.9-1-4.1-1.9-6-1.9l0,0
    C1553.1,2828.7,1556.3,2833.2,1564.5,2836.4z M1557.2,2940.6l-1-2.5l0,0c0,1.6,0,1.9,0,2.5
    C1556.2,2941.2,1556.6,2940.6,1557.2,2940.6z M1567.9,3117.3c-1.9,0-2.5,0.7-2.5,1c-0.7,0.7-0.7,1.6-1,3.2
    C1565.3,3120.5,1567.3,3117.9,1567.9,3117.3z M1596.6,2917.2c1-2.5,1.5-5.1,1.9-7.5c0.7-2.5,1.5-5.1,1.9-7.3c-0.7-1-1-1.9-1.5-3.2
    c-1.5-3.4-3.2-7.3-5.6-9.8c-1.9-2.5-4.7-4.8-7.5-6.7c-1-0.7-1.5-1-2.5-1.9c-4.1,5.7-7.5,10.1-11.6,15.5c3.4,0,7.5,0,12.2,0.7
    l6.6,0.7l-5.1,4.8c-5.6,4.8-8.2,7.3-11.4,7.3s-5.6-2.5-7.5-6.7c-7.3,6-11.4,11.4-10,19.6c1.5,0,2.5,0.7,4.1,0.7
    c5.1,0.7,9.7,1,14.8,1C1583.7,2922.9,1590.3,2921.4,1596.6,2917.2z M1614.1,3148c-3.2,1.9-5.6,4.1-8.2,5.7c-1,0.7-1.5,1-1.9,1.6
    c3.2-1.6,6.6-3.2,9.7-5.1C1613.2,3150.2,1613.9,3148.9,1614.1,3148z M1622.4,2929.6c-1-0.7-1.9-1-3.2-1.6c-3.4-1.9-6.6-3.4-10-5.1
    l0,0c-1.5-0.7-3.4-0.7-5.1-0.7c-0.7,0-1,0-1.5,0s-0.7,0-1,0c-1,7.3-1.9,13.9-3.2,21.5c1,0,1.9-0.7,3.4-0.7c4.1-1,7.5-1.6,11.4-1.6
    h0.7C1620.7,2941.2,1622,2937.8,1622.4,2929.6z M2193.8,3421.6c1,0,1.5,0,2.5,0c3.2,0,5.1,0,6-1c3.2-2.5,5.6-5.7,8.2-9.2
    c0.7-0.7,1-1.6,1.5-1.9c-2.5-1.6-6-1.9-9.7-1.9c-1.5,0-3.2,0-4.7,0C2197,3411.6,2195.4,3416.3,2193.8,3421.6z M2158.3,3524.1
    c4.7-1.9,8.8-3.2,13.8-3.2c9.7,0,19.5,4.1,28.6,7.5l0,0c0.7,0,1.5-0.7,1.9-0.7c0.7,0,1-0.7,1.9-0.7l3.2-0.7v1.6l8.2-0.7l3.2-7.3
    l1.9,8.9c1,3.4,1,3.4,2.5,3.4h0.7c0.7-7.5,1-15.9,1.5-25.6h-4.1c-6-0.7-11.6-0.7-15.8-1l-4.1-0.7l1.9-3.4c1.9-4.1,4.1-7.5,6-11.6
    l1.5-2.5l0,0c1-6-1-9.8-7.3-11.4l-1.9,1.6c-7.3,4.1-14.1,8.2-21.4,12.6c-1.9,1.6-4.1,3.2-4.1,4.1c0,8.9-2.5,14.9-15.4,18.3
    c-1,0.7-1.9,1-2.5,2.5C2157.6,3518.1,2157.6,3520.6,2158.3,3524.1z M2283.4,2648.6l0.7,1.9L2283.4,2648.6L2283.4,2648.6z
    M2196.3,2757.9c1.9,2.5,4.7,3.4,7.3,3.4c1,0,2.5,0,4.1-1.6c0-0.7,0-1,0-1c-3.2-1-6.6-2.5-10-3.2
    C2197.3,2757,2197,2757.7,2196.3,2757.9L2196.3,2757.9z M2150.1,2937.1c0-0.7-0.7-0.7-0.7-0.7l0,0
    C2149.4,2937.1,2149.4,2937.8,2150.1,2937.1C2150.1,2937.8,2150.1,2937.8,2150.1,2937.1z M2170.2,2661.2c0,0-0.7,0-1-0.7l0,0
    c-0.7,0.7-1,1.9-1,2.5l0,0c0,0.7,0.7,1.6,1.5,1.9l0.7-0.7c0-0.7,0-0.7,0-1C2170.2,2663.5,2170.8,2662.5,2170.2,2661.2z
    M2144.5,2613.8c0.7,1,1,2.5,1.5,3.4c11.4-5.1,16.3-7.3,21.4-7.3c3.2,0,5.6,0.7,8.8,1.9c0.7-6,0-11.4-5.1-15.5
    c-3.4,2.5-6.6,5.7-9.7,8.2c-2.5,1.9-4.7,3.4-7.3,5.7c-2.5,1.9-5.1,3.2-7.5,3.2C2146,2614.1,2145.4,2614.1,2144.5,2613.8z
    M2280.9,2294.3C2280.9,2294.3,2281.5,2294.3,2280.9,2294.3c1-1,1.5-2.5,2.5-3.4c-0.7,0-0.7-0.7-1-0.7
    C2282.2,2291.8,2281.5,2293.3,2280.9,2294.3z M1637.2,2317.1c0.7,0,1,0,1.5-0.7c-2.5-2.5-5.1-3.4-8.2-3.4c-0.7,0-1,0-1.9,0
    C1631.4,2314.2,1634.6,2315.5,1637.2,2317.1z M1658.4,2341.3c-13.2,1.9-16.3,3.2-20.4,7.3c4.7,1.9,8.2,3.2,10,3.2l0,0
    C1651.9,2351.9,1654.1,2349.3,1658.4,2341.3z M1702.2,2448.5c1,1.6,2.5,2.5,3.4,4.1c1.5,1.6,2.5,1.6,3.2,1.6c1.5,0,3.2-0.7,4.7-1
    c0.7,0,0.7-0.7,1-0.7c0.7-1.6,0.7-2.5,0.7-4.1c0-1,0.7-1.6,0.7-2.5c-1.5-0.7-2.5-1-4.1-1c-4.7-1.6-8.8-2.5-13.2-4.8
    c-0.7-0.7-1-0.7-1.9-1c-3.2-1.6-5.6-3.2-8.8-3.2c-0.7,0-1.5,0-1.9,0c2.5,1,5.1,1.9,7.5,3.4
    C1697.2,2442.2,1699.7,2445.4,1702.2,2448.5z M1692.2,2249.4c-2.5,0.7-4.7,1.6-6.6,2.5c0.7,0,1,0,1.5,0.7c6,1,11.6,1.9,18.2,3.4
    c1-3.2,2.5-5.7,4.1-9.2c-1,0-1.9,0.7-3.2,0.7C1700.7,2247.2,1696.3,2248.4,1692.2,2249.4z M1701.9,2216.5c3.2,2.5,6,5.1,9.7,7.5
    c2.5-5.7,4.1-9.2,4.1-11.4c0-1,0.7-1.6-1.5-2.5c-2.5-1.6-4.7-2.5-6.6-2.5c-3.4,0-7.3,1.9-11.4,4.8
    C1698.2,2214,1699.7,2215.6,1701.9,2216.5z M1724.9,2384.3c-5.6,5.7-10.7,11.6-16.3,18.1c-1.9,1.9-4.1,4.8-6,6.7c1,0.7,2.5,1,3.4,1
    c4.1,1.6,7.5,2.5,11.4,4.1c18.2-11.4,26.5-15.5,41.8-15.5c4.1,0,8.8,0.7,13.8,0.7c-0.7-0.7-0.7-1.6-0.7-1.9
    c-2.5-7.5-4.1-11.4-9.2-11.4c-1.5,0-3.2,0.7-5.1,1c-0.7,0-1,0-1.5,0c-3.4,0-8.8-2.5-10.7-5.7c-1.9-3.4-3.4-4.1-5.1-4.1
    c-1.9,0-4.1,1-6,1.6C1729.9,2380.9,1726.7,2382.5,1724.9,2384.3z M1731.5,2349.3c-0.7,0-1-0.7-1-0.7l0,0
    C1730.3,2349.3,1730.8,2349.3,1731.5,2349.3z M1740.1,2272.4v0.7c-1,3.4-0.7,5.1,1,6l1-0.7l1.5,3.2c1.5,3.2,3.2,6.7,4.7,9.8
    l9.7-0.7c9.2-0.7,18-1.6,25.5-1.9c1.9-1.6,1.9-4.1,2.5-6.7c0.7-3.4,1-8.2,5.6-11.4c1.9-1.6,3.2-6,3.4-10.8c0.7-1.9,1-4.8,1.5-6.7
    l0.7-2.5h1c1-1.6,1.5-2.5,1-3.2c0-0.7-1.5-1.6-3.2-2.5l-1-0.7l0,0c-3.2,0-6,0.7-9.2,0.7v0.7l0,0l-1,1.9l-2.5-2.5
    c-2.5-1.9-5.1-4.1-8.2-7.3c-0.7,2.5-1,5.1-1.5,7.3l-0.7,2.5l0.7,0.7l-1,1.9c-1.5,3.2-3.4,6.7-5.1,9.8c-1,1.9-1.9,3.4-3.2,5.7
    l-1.9,3.4l-1.5-1.9h-0.7l-1-0.7c-4.7-1.6-8.2-3.2-10-3.2C1745.7,2264.7,1744.2,2266.4,1740.1,2272.4L1740.1,2272.4L1740.1,2272.4z
    M1780.2,2069.5c2.5,3.2,5.6,5.7,8.2,8.9c3.2,3.2,5.6,6,8.2,8.9l0.7,0.7v1c0.7,7.3-3.2,10.8-6.6,14.2c-1.5,1.6-3.2,3.2-4.1,5.1
    c4.7-3.2,9.2-6,13.8-9.2c4.7-3.2,9.2-6,13.2-9.2c10-6.7,13.8-14.2,11.6-24.1c-1.9-9.2,1.5-14.9,7.3-20l0,0c1-0.7,1.5-1.6,1-3.2
    c0-1-0.7-2.5-1.9-3.4c-1,1-1.9,2.5-3.2,3.4c-3.4,4.1-6.6,7.5-8.8,11.6c-1,1.9-0.7,5.1-0.7,8.2c0.7,2.5,0.7,5.1,0,7.5
    c-0.7,3.2-1.9,6-3.2,9.2c-0.7,1.6-1,2.5-1.9,4.1l-1.5,4.1l-3.2-3.2c-10-10.1-10.7-11.6-4.7-24.6c-3.4-6-9.2-9.2-17.3-11.6
    C1789.1,2057.9,1787.7,2060.1,1780.2,2069.5z M1799.8,2025.3c1,0,2.5-0.7,3.4-1c-4.1-2.5-6.6-3.4-8.2-3.4c-0.7,0-1,0.7-1.9,1.6
    C1795.7,2023.8,1797.5,2025.3,1799.8,2025.3z M1761,2490.2L1761,2490.2C1760.4,2490.9,1760.4,2490.9,1761,2490.2
    c1.5,0.7,3.4,0.7,5.6,0.7c5.6,0,9.2-1.6,12.2-4.8c1.5-1.6,1.9-4.8,1.9-7.5c-0.7-0.7-1.9-1.9-3.2-1.9c-1.9,0.7-4.1,1-6,1
    c-8.8,0-14.8-5.7-19.9-10.8c0,0,0,0-0.7,0s-1,0-1.5,0l8.8,23.4L1761,2490.2L1761,2490.2z M1727.4,2455.2c-1.5,0-3.2,0.7-5.1,1
    c4.7,4.1,10,5.1,16.7,5.1C1735.6,2457.1,1731.5,2455.2,1727.4,2455.2z M1723.3,2607.1c5.1,0,10,0,14.8,0c0.7-5.1,1.5-9.8,2.5-13.9
    c-1.9,0-3.4,0-5.6,0c-0.7-1.9-0.7-4.8-0.7-6.7c3.2-0.7,6-1,9.2-1c1.5-2.5,3.4-4.1,6-4.1c1,0.7,2.5,0.7,3.4,1c0.7,0.7,1,0.7,1.9,1
    c1,0,1.9-0.7,3.2-0.7c0-0.7-0.7-1-0.7-1c0.7-0.7,1-0.7,1.5-1c1-0.7,1.9-1.6,3.2-1.9c1-1,1.5-1.9,2.5-2.5c2.5-3.2,5.1-6,8.8-8.9
    c5.1-4.1,10-7.5,15.4-11.4c1.5-1,3.2-1.9,4.7-3.2c-12.2-9.8-15.8-22.2-10-37.2c-8.8-2.5-15.8-4.8-22.3-7.3
    c-6.6,8.2-8.2,16.7-4.1,28.2c1.5,4.1,1.5,8.2,1.5,11.6c0,1.6,0,2.5,0,4.1v1.6l-1,1c-3.4,3.2-6.6,4.8-10,5.7
    c-1.9-4.1-3.4-7.5-5.1-11.4c-6.6-3.2-13.8-6.7-20.4-9.8c-5.6,3.2-11.6,8.2-20.4,13.9c11.4-1.9,18.2-3.4,25.5-5.1l1.5,4.1
    c-3.4,2.5-7.3,5.1-10.7,7.5c-5.6,10.1,1.9,23.1-5.1,32.9C1716.7,2598.1,1720.8,2603,1723.3,2607.1z M1755.7,2716.2
    c0-0.7,0-1.6,0-1.9c0-1.6-0.7-4.1-0.7-4.8l0,0c-2.5-1.9-5.6-3.2-9.2-4.8c-1-0.7-1.5-0.7-2.5-1c-2.5,5.1-4.7,9.2-6.6,12.6
    C1742.5,2716.6,1748.8,2716.6,1755.7,2716.2z M1742.2,2732.1c-1.9-1.9-4.1-3.4-6.6-5.7c0.7,2.5,1,5.1,1.9,7.5c0.7,0.7,1.9,1,3.2,1
    c0.7,0,0.7,0,1,0.7v-0.7C1742.2,2734.5,1742.2,2733,1742.2,2732.1z M1740,2856.2l1.9-1.6c5.6-4.8,5.6-9.8,0.7-16.7
    c-1.9-2.5-3.2-5.7-3.2-7.5c-1.9,0.7-3.4,0.7-5.6,1c-1.9,0.7-4.1,1-6,1c-0.7,8.9-6.6,12.3-11.6,14.9c1,1.9,1.5,4.1,2.5,5.7
    c1.9,4.1,3.4,8.2,5.6,12.3c2.5,5.7,4.1,6,5.6,6s3.4-0.7,6-1.9c2.5-1.6,6-3.2,6.6-5.7c0.7-1.6,0-3.2-1.5-5.7L1740,2856.2z
    M1759.5,2781.1c0-0.7,0-0.7,0-1c-1,0-1.5,0.7-2.5,1c-1.5,0.7-2.5,1-4.1,1c-1.9,0-3.4-1-4.1-3.2c-1.9-7.5,1.9-11.4,4.7-13.9
    c1.9-1.9,3.2-2.5,3.2-4.1h-0.7c-5.6,0-10,2.5-15.4,5.1c-3.2,1.6-6,3.2-9.2,4.1c-1.5,4.8-2.5,8.2-4.1,13.3
    c8.8,4.8,13.2,6.7,17.3,6.7l0,0C1749.1,2789.3,1752.9,2786.7,1759.5,2781.1z M1770.2,2607.5c3.2-0.7,6-1.9,8.8-3.4
    c6-5.1,12.2-10.8,18.2-16.4c1.5-1.6,3.2-3.2,5.1-4.8c-1.5-2.5-4.1-4.1-8.2-4.1c-1.5,0-3.2,0-4.7,0.7c-5.6,0.7-11.4,1.9-16.3,3.2
    c-0.7,0-1.5,0.7-1.9,0.7c1.5,1.6,2.5,3.2,3.2,5.1c1.5,3.4-5.1,8.2-7.3,8.9c-3.4,1.6-7.3,1.6-11.4,1.6c-5.1,0-9.2,0.7-13.2,3.4
    c7.3,3.4,14.1,5.1,21.4,5.1C1765.4,2608.8,1767.7,2608.1,1770.2,2607.5z M1787.4,3066.7c-0.7-0.7-1.9-1-2.5-1.6c0,0,0,0-0.7,0
    c-0.7,1-1.5,1.9-1.9,3.2c1,0.7,2.5,1,4.1,1.6l0,0C1786.8,3068.3,1787.4,3067,1787.4,3066.7z M1810.5,2972.5l-1.5-1
    c-1-0.7-1.9-1-3.2-1c-1.9,0-4.1,1-6,1.9c-2.5,1-5.1,2.5-8.2,2.5c-1,0-1.9,0-2.5-0.7c-3.2-1-6-3.2-8.8-5.1c-0.7-0.7-1-0.7-1.5-1
    c-4.1,4.8-2.5,8.2-0.7,13.9c1,3.4,2.5,7.3,1.9,10.8c1,1,4.1,3.2,6,4.8c0.7,0.7,1.5,1,1.9,1.6c2.5-14.2,4.7-18.1,15.8-23.4
    C1806.4,2974.2,1808.2,2972.8,1810.5,2972.5z M1818.9,2520.6c-2.5,1-5.6,1.9-8.2,3.4c-0.7,2.5-0.7,5.1-1,7.5
    c-0.7,3.2-0.7,5.7-1,8.2c2.5,0.7,4.7,1,6,1c4.7,0,7.5-1.6,10.7-5.7c1.5-1.9,1-2.5-1-4.8c-1.9-2.2-5.1-5.1-2.5-9.8l0.7-0.7l0.7-0.7
    c2.5-1.9,6-3.2,9.2-4.1c3.2-1,5.6-1.6,7.5-3.2h-0.7C1832.7,2514.9,1826.1,2517.5,1818.9,2520.6z M1825.2,2484.9
    c1,0.7,2.5,1.6,3.4,1.6l0,0c1.9,0,3.2-1.6,3.2-1.9c1-1.6,1-3.2,0-4.8c-0.7-0.7-1.5-1-1.9-1c-1.5,0-3.2,1-4.1,2.5
    C1825.2,2482.3,1824.6,2483.7,1825.2,2484.9L1825.2,2484.9z M1822.4,2624.5c1,0.7,1.9,0.7,2.5,1c4.7,1.6,9.2,3.2,13.2,4.8
    c1.5-3.2,0.7-5.7-3.4-9.2L1822.4,2624.5z M1830.2,2588.5L1830.2,2588.5c0.7,0,1.9-0.7,3.2-0.7l0,0c-0.7-1.6-0.7-2.5-1-3.4
    c-3.2-0.7-6.6-1-9.7-1l0,0C1825.5,2584.4,1827.7,2586.6,1830.2,2588.5z M1831.9,3043.7c-0.7,0-1,0.7-1.5,0.7l0,0l0,0
    c-0.7,0.7-1,1-1.5,1.9c1,0,1.5-0.7,1.9-0.7C1831.2,3045.2,1831.2,3044.7,1831.9,3043.7z M1838.7,3132.1c-1,1.6-1.9,2.5-3.2,4.1
    C1837.8,3136.9,1838.7,3135.3,1838.7,3132.1z M1841.2,2956.8c-5.6,0.7-10.7,1-15.4,1.6c-4.1,0.7-8.2,1-11.6,1
    c0,3.2-0.7,5.7-0.7,8.2l-0.7,4.1c1-0.7,1.9-0.7,3.2-1c2.5-0.7,5.1-1,7.5-1c2.5,0,4.7,0.7,6,1.6c1.9,1.9,2.5,5.1,3.4,8.9
    C1837.8,2971.6,1840.2,2964.3,1841.2,2956.8z M1862.3,2715.6c-4.7-2.5-8.8-5.1-12.2-7.3c-1-0.7-2.5-1-3.4-1c-0.7,0-1,0-2.5,1.6
    l0.7,0.7c4.1,4.1,7.5,7.3,12.6,7.3C1858.6,2716.6,1860.1,2716.2,1862.3,2715.6z M1862.3,2425.5c-5.6-6.7-10.7-12.6-19.9-12.6
    c-1,0-1.9,0-2.5,0h-0.7c-2.5,0-4.7-1.9-6-3.2c-0.7,0-0.7-0.7-0.7-0.7l-0.7-0.7l-0.7-0.7c-1.9-3.2-1.9-6.7-1.5-9.2
    c0-3.2,0.7-4.8-1.9-6c-1.5,1.6-3.4,3.2-5.6,5.1l-2.5,2.5l-1.9-4.1c-2.5-4.1-5.1-8.9-8.2-13.9c-2.5,3.4-4.7,6.7-6.6,9.8
    c-1.9,2.5-3.4,5.1-5.1,7.5c2.5,10.8,4.7,20,6.6,30.4l0.7,3.2c1-0.7,1.5-0.7,2.5-1c5.6-1.9,10.7-4.1,16.7-5.1c0.7,0,0.7,0,1,0
    c2.5,0,4.7,1.9,7.3,4.1c1,0.7,1.5,1.6,2.5,1.9l3.2,1.9l-7.5,5.7c-8.2,6-16.3,11.6-24.8,18.1l-0.7,0.7h-0.7c-1,0-1.5,0.7-2.5,0.7
    c-2.5,0-4.7-1-6.6-1.6c-1.5-0.7-3.2-1.6-4.7-1.6c-0.7,0-1.5,0-2.5,1c1.5,2.5,3.2,5.7,5.1,8.9l0.7,1c1.9,0,4.1,0.7,6,0.7
    c2.5,0.7,5.1,0.7,7.5,0.7c2.5,0,4.7-0.7,5.6-1c5.6-4.8,12.2-7.3,18.2-10.1c6.6-3.2,13.2-6.7,18.9-11.4c4.1-3.4,6.6-8.2,9.7-13.3
    C1860.1,2429.6,1861.4,2427.4,1862.3,2425.5z M1849.1,2086.6c1.5-1.6,2.5-2.5,2.5-3.2c0-1-1.5-3.2-4.1-6
    C1847.5,2080.3,1848.5,2083.5,1849.1,2086.6z M1863.3,2142.9c0-0.7-0.7-1-1-1c-1.5,0.7-2.5,1-3.2,1.6l0,0l0,0
    c-0.7,1-0.7,2.5-0.7,4.8c0,0.7,0,1,0,1.6c0.7,0.7,1,1,1.5,1.6C1861.4,2147.9,1862.6,2145.5,1863.3,2142.9z M1870.5,2570.8
    L1870.5,2570.8c-0.7-0.7-1-1.6-1.5-1.9c-0.7,0-1.5,1-1.9,1.6c1,0.7,1.9,1,3.2,1C1870.5,2571.1,1870.5,2570.8,1870.5,2570.8z
    M1881.2,3228.9c-0.7-0.7-1-0.7-2.5-0.7c-0.7,0-1,0-1.9,0.7c-1,0-1.5,1-2.5,1.9c1,0.7,2.5,1.6,3.2,1.6l0,0
    C1878.6,3231.4,1880,3229.8,1881.2,3228.9z M1889.7,2697.3c1.5-1.9,2.5-4.8,3.4-7.5c0,0,0-0.7,0.7-1c-0.7,0-1,0-1.5,0
    c-3.4,0-5.6,1.6-6.6,5.1c-0.7,0.7,0.7,3.2,1,3.4c0,0,0.7,0,1.5,0C1888.8,2697.6,1889.7,2697.3,1889.7,2697.3z M1890.7,2507.9
    c1.9-1,3.4-1.9,4.7-3.4c-4.7,1.9-7.3,3.4-8.8,5.1c-0.7,0.7-0.7,1-1,1.9C1887.8,2509.8,1889.3,2508.9,1890.7,2507.9z M1897.5,3199.7
    c-0.7,0-1,0-1.5,0c-0.6,0-1,0-1,0c-0.7,0-1,1.6-1.5,2.5c0.7,0,1,0,1,0c1,0,1.5,0,1.9,0C1896.3,3201.7,1896.9,3200.7,1897.5,3199.7z
    M1908.5,3030.7c-3.4-1-7.3-3.2-8.8-8.2c0,0-0.7,0-1,0c0,0,0,0-0.7,0c-0.7,0.7-1,1-1,1.6c0.7,1.9,1.5,4.1,3.2,6.7
    c0.7,0.7,0.7,1.6,1,1.9L1908.5,3030.7z M1917.3,3294c-0.7,0-1-0.7-1.9-0.7l0,0c-3.2,1-3.4,2.5-3.2,6.7c0,1.6,0,3.2,0,5.1l0,0
    c0,0.7,0.7,1.6,0.7,2.5c0,0,0,0.7,0.7,0.7s1,0,1.5,0c1,0,1.9,0,1.9-0.7l0,0C1919.2,3305,1921.8,3300.9,1917.3,3294z M1947.9,3269.9
    c-1.5-1.6-3.2-3.2-4.7-4.8h-1l0,0c0,0.7,0,1,0,1l0,0c0,4.8,2.5,4.8,3.2,4.8l0,0C1945.7,3271.2,1947.2,3270.2,1947.9,3269.9z
    M1957.9,3340.4c-0.7,0-0.7,0-1,0c-1,0-2.5,0.7-3.2,1l0,0c0,0,0,0.7-0.7,0.7c0.7,0,0.7,0,1,0c1,0,1.9,0,2.5-0.7l0,0
    C1956.9,3341.4,1957.6,3341.1,1957.9,3340.4z M1968.3,3085c-0.7-1-1-2.5-1.5-3.4c-1.5-4.1-3.2-7.5-5.1-9.8
    c-4.7-4.8-11.4-8.9-18-11.4c-3.4-1.6-8.2-1.6-12.6-1.6c-1.5,0-2.5,0-4.1,0c-0.7,1.6-1,3.2-1.5,4.1c-1.5,4.1-3.2,7.3-3.4,10.8
    c-0.7,1.9,0,4.1,1,5.1c1,1.6,3.2,1.9,6,2.5h1.5l0.7,1.6c1,1.9,0.7,4.8,0,6.7c-0.7,3.4-0.7,4.1,2.5,4.8c1,0,1.5,0,2.5,0
    c3.2,0,5.1-1,5.6-5.7l0.7-3.4l3.4,1.6c3.2,1.6,6,1.9,9.2,1.9C1958.8,3088.2,1962.9,3087,1968.3,3085z M1955.4,2702.6
    c0.7-0.7,1-0.7,1-1c0-0.7-1-1.6-1.5-2.5l-0.7,0.7C1954.7,2700.7,1955.4,2701.7,1955.4,2702.6z M1977.5,2729.9
    c-1.5,0.7-3.2,0.7-5.1,1c0,0.7,0,0.7,0,1C1974.3,2731.1,1975.8,2730.4,1977.5,2729.9z M1986.5,2647.6c1.5-1.6,2.5-2.5,4.1-4.1
    c-5.6-6-7.3-7.3-13.2-8.9c-2.5,15.5-4.1,16.7-19.5,19.6c-3.4,0.7-6.6,1.6-10,1.9c1,1,1.9,1.6,3.2,2.5c4.1,3.2,7.5,6,11.6,8.9
    c1,0.7,1.9,1.6,2.5,1.6c1-0.7,3.2-3.2,6-6l1.5-1.6C1976.8,2657.1,1981.5,2652,1986.5,2647.6z M2024.6,3517.4c0-0.7-1-1.9-1.9-1.9
    l0,0h-0.7c-1.9,0-5.1,1.6-5.6,2.5l0,0c-1,1-1,3.4-1,5.7c0,0.7,0,0.7,0,1c1.9,0,4.1,0,5.6-0.7c1,0,1.9-1,3.2-1.9
    C2025.2,3521.5,2025.9,3519.6,2024.6,3517.4z M2026.8,3318.9c-10.7-9.8-25.5-14.9-45.3-15.5c-0.7,0.7-1,1-1.5,1.9
    c1,0.7,2.5,1.6,3.4,1.9c0.7,0.7,1.5,0.7,3.2,1c1,0,2.5,0.7,4.1,1l2.5,0.7l-1,4.8c-1.9,8.2-3.4,14.2-5.1,20.8
    c6.6,8.2,13.8,12.3,22.9,12.3c1,0,1.9,0,3.4,0c1.5-1.6,3.2-3.2,4.1-4.8c4.1-4.1,7.5-7.5,11.4-11.6
    C2031.2,3327.8,2030.3,3322.1,2026.8,3318.9z M2066.5,2677c-2.5,2.5-5.1,4.8-7.3,6l-2.5,1.9c-1.9,1.9-4.7,2.5-7.3,3.4
    c-3.2,1-5.1,1.6-6,3.4c1.9,0,4.7,0.7,6.6,0.7c2.5,0,5.6,0.7,8.2,0.7c1-2.5,1.9-5.1,3.4-7.3C2064,2683.4,2065.5,2680.9,2066.5,2677z
    M2094.1,3450.5c-1-1-1.9-1.9-3.4-3.4c-0.7-0.7-1-1-1.9-1.9c-0.7,1-1,2.5-1,3.4c-1.5,4.8-2.5,6.7-0.7,9.2c0.7,0,0.7-0.7,1-0.7
    C2092,3455.5,2094.4,3454.6,2094.1,3450.5z M2096.9,2638.2c5.1-4.8,10-7.5,16.3-9.8c-10.7,0.7-21.4,3.2-31.1,11.4
    c4.1,1,6.6,1.6,8.8,1.6C2092.5,2642,2094.1,2641.3,2096.9,2638.2z M2113.9,2892.6c-1.9-0.7-5.1-1.6-8.2-1.6c-1.5,0-2.5,0-3.2,0.7
    l0,0c-1,0.7-1.9,3.2-3.2,5.1c-0.7,0.7-0.7,1.6-1,1.9c1,0,1.5,0,2.5-0.7c5.1-0.7,8.8-1,12.2-1.9c0,0,0.7-0.7,1-1.6
    C2114.3,2894.1,2113.9,2893.2,2113.9,2892.6z M2114.3,2863.5c1-0.7,1.9-1.6,3.2-1.9c-1-0.7-3.4-3.2-4.7-3.4c-0.7,0.7-1,1.6-1.5,1.9
    c-2.5,2.5-4.1,4.8-2.5,8.2C2110.8,2866.6,2112.4,2865.1,2114.3,2863.5z M2125,3208c6.6-1.9,9.7-6,9.2-12.3c-3.2,1.6-6,3.4-9.7,5.7
    c-2.5,1.6-5.1,3.2-8.2,4.8c0.7,0,1.5,0.7,1.9,0.7c2.5,1,4.7,1.6,6,1.6C2124.6,3208,2125,3208,2125,3208z M2146,3308.1
    c-4.1,0-7.3,0-10.7,0c-1.5,0-3.4,0-5.6,0c0.7,1.6,0.7,2.5,1,4.1c1.5,6.7,2.5,11.4,9.7,12.6
    C2142.3,3319.9,2144.5,3314.2,2146,3308.1L2146,3308.1z M2121.5,3172.6c1,3.4,1.9,6,3.2,7.3c2.5,2.5,5.6,4.1,9.2,6
    c1,0.7,1.5,1,2.5,1.6c1.5-0.7,3.2-1.6,5.6-2.5c-0.7,0-1-0.7-1.5-0.7c-4.1-1.6-6.6-2.5-9.2-4.1
    C2128,3178.3,2125.6,3176.1,2121.5,3172.6z M2134.7,2808.8c-1.9,0.7-3.4,0.7-5.1,1c-3.4,3.4-6.6,6.7-10,10.1c2.5,0,4.7,0,6.6,0
    c0.7,0,1,0,1.5,0c4.7-3.4,7.3-6.7,8.2-11.4C2135.3,2808.2,2135.3,2808.8,2134.7,2808.8z M2184.1,2242.1c0,0.7,0.7,1,0.7,1.6
    c4.7,1.6,8.8,3.2,13.8,5.1c1.5,0.7,3.4,1,5.6,1.9c0-0.7,0-0.7,0-1c0-1.9,0.7-4.8,0-5.7c-1.9-3.2-5.6-5.1-9.7-5.1
    C2190.7,2239,2187.2,2240.2,2184.1,2242.1z M2116.8,2142.3L2116.8,2142.3c5.1,1.6,9.7,3.2,13.8,3.2c1.9,0,4.1-0.7,6-1
    c-1.5-0.7-3.2-1.6-4.1-1.9c-3.2-1.6-5.6-3.2-8.8-3.2C2122.4,2138.8,2119.9,2139.5,2116.8,2142.3z M2083.5,2256.6
    c9.2-4.8,17.3-6.7,23.9-6.7c9.2,0,17.3,3.4,24.8,11.6l1,1l-0.7,1.6c-2.5,7.3-7.5,12.6-11.6,18.3c-5.1,6-9.7,11.6-11.6,19
    c8.8,4.1,27,12.6,27,12.6l-1,2.5c-4.1,9.8-8.8,20-12.2,29c5.6,5.1,10,9.2,14.8,13.9c1-0.7,1.5-1.6,2.5-1.9c3.2-1.9,5.6-4.1,8.2-6
    l3.2-1.9v0.7c1.9-0.7,4.1-1,6-1c3.2,0,6.6,1,9.7,1.6c2.5,0.7,5.1,1.6,7.5,1.6c0.7,0,1,0,1.5,0c3.2-1.6,4.7-4.8,6-8.2
    c1.9-4.1,4.7-9.2,10.7-11.4c2.5-1,3.2-1.9,3.2-2.5c0.7-1.9-1-5.7-4.7-9.8c-0.7-0.7-1-1-1.5-1.9c-3.2-4.1-6.6-8.2-6-14.9
    c0.7-16.4,0.7-30.7,0-44.6c0-2.5-1.5-5.1-3.2-7.5c0-0.7-0.7-1-0.7-1.6c-0.7,0-1.5,0.7-2.5,0.7c-2.5,0.7-4.7,1-6.6,1
    c-1,0-3.2,0-4.7-1.9c-3.2-4.8-6.6-4.8-11.6-5.1c-2.5,0-4.7-0.7-7.3-1l-1.9-0.7v-4.1c0-1.6,0-3.2,0-4.1l-1.5-0.7
    c-8.2-1.9-12.2-3.2-14.8-3.2c-1.9,0-3.4,0.7-7.3,1.9c-3.2,1.6-6,2.5-9.2,4.1C2103.2,2240.2,2092.5,2244.7,2083.5,2256.6z
    M2118,2388.1c-1,1-1.5,1.9-2.5,3.2c1.5,0,2.5,0,2.5-0.7C2118.3,2390.1,2118.3,2389.1,2118,2388.1z M2101,2499.7c0.7-0.7,1-1,1.5-1
    C2101.7,2498.5,2101.7,2499.1,2101,2499.7z M2098.5,2364.5c0-0.7-0.7-1-1.5-1.9c-0.7,0.7-0.7,1-0.7,1c0,1,0.7,2.5,1.5,4.1
    C2098.5,2366.1,2099.1,2365.1,2098.5,2364.5z M2081.3,2146.4l6-1.6c-0.7-0.7-1.5-0.7-1.9-0.7
    C2083.7,2144.5,2082.8,2145.1,2081.3,2146.4z M2054.8,1912.1L2054.8,1912.1c0.7-0.7,1-1,1.5-1.6l0,0
    C2055.1,1910.6,2054.8,1912.1,2054.8,1912.1z M2052.6,2496c2.5-0.7,4.7-1.6,7.3-1.9c10-2.5,20.4-5.1,30.2-14.9c-2.5,0-4.7,0-7.3,0
    c-8.8,0.7-15.4,0.7-21.4,1.9C2055.1,2483.3,2052.3,2488.3,2052.6,2496z M2051.7,2602.5c-1.9,2.5-4.7,3.2-6,3.2
    c-2.5,0-5.6-1.6-7.5-4.1c-2.5-2.5-5.6-4.1-9.7-4.1c-3.2,0-6,0.7-9.2,1.6h-0.7c-4.1,1-8.2,3.2-12.2,5.1c-1.9,1-4.1,1.9-6,3.2l-1.9,1
    l-1.5-1.9c-0.7-1-1.5-1.9-2.5-3.2c-2.5-3.2-5.1-5.7-4.1-8.9c2.5-8.2,0.7-13.9-7.5-18.1c-3.2-1.6-6-3.2-9.2-4.8
    c-1.5-0.7-2.5-1-4.1-1.9l-1-0.7l-0.7-1l-3.2,1l1-4.8c2.5-10.1,11.6-11.4,17.3-11.6c1,0,1.5,0,2.5,0c1.9,0,4.1,0,4.7-1
    c0.7-0.7,0.7-1,0.7-1.6c-4.1,1-8.2,1.6-12.2,2.5c-3.2,0.7-6,1.6-9.2,1.9c-6,1-8.8,1.9-9.7,3.2c-1.5,1.6-1.9,5.1-2.5,11.6v1.9l0,0v1
    l-1.5,0.7c-1,0.7-2.5,1-4.1,1.9c-3.2,1.6-5.6,2.5-8.2,2.5c-1,0-1.9,0-3.2-0.7c-3.2-1.6-6.6-2.5-10-2.5c-5.1,0-10,1.6-15.4,3.2
    c-3.4,1-7.3,1.9-10.7,3.2l0,0c1.9,0.7,3.4,1,5.6,1.6c1.5,0.7,3.2,1,5.1,1.6l2.5,1l-2.2,6.7c-1,3.4-1.9,6.7-3.2,9.8
    c2.5,1.6,4.7,1.9,6.6,1.9c1.5,0,4.1-0.7,7.3-4.1c0.7-1,1.5-1.9,1.9-3.4c1.9-3.4,4.7-8.9,11.4-8.9c1.5,0,3.2,0.7,4.7,1l1.5,0.7
    l0.7,1.6c1,3.2,1.5,5.7,2.5,8.9c1.5,5.7,3.2,11.6,5.1,18.1c3.2,9.8,6,12.3,13.8,12.3c1.9,0,5.1,0,8.2-0.7c1-1,1.5-1.6,2.5-2.5
    c2.5-2.5,4.7-5.1,7.3-7.3l1-1l1.5,0.7c7.3,1.9,13.8,4.1,19.5,6l12.2,3.4l0.7,1c5.6,11.4,5.6,21.5-1,32.3c5.6,1.9,10,4.1,14.1,5.1
    c10-5.1,9.7-13.3,7.5-23.1l-0.7-1.9c0,0,13.8-7.3,16.3-8.9c-0.7,0-1.5-0.7-2.5-0.7c-1.9-0.7-4.1-1-5.6-3.2c-1-1.6-1-3.2-0.7-5.7
    c1-4.8,6-7.5,9.2-9.2l0,0c2.5-1,5.1-1,7.5-1c0.7,0,1.9,0,1.9,0c0.7-8.9,1.5-18.1,11.4-23.1c1.5-1,3.4-1.6,6-1.6c1.5,0,3.2,0,4.7,1
    c2.5,1.6,5.6,6,5.1,9.8c-0.7,3.4-1.9,7.3-3.4,11.4c0,0.7-0.7,1-0.7,1.6c0.7,0,1,0,1-0.7c4.1-1.6,8.2-2.5,12.2-3.2
    c1.5,0,3.4-0.7,5.6-0.7c1.9,0,4.1,0,5.6,0c1.9,0,3.4,0,5.6,0c1.5,0,2.5,0,3.4,0c3.4-0.7,7.3-1,11.4-1.9l0,0c-1-1-1.5-1.9-2.5-3.2
    c-8.2-9.8-15.8-18.3-27-23.4c-3.2-1.6-5.1-4.1-6.6-7.3v-0.7c-3.4-5.7-4.7-12.6-2.5-19c2.5-6.7,7.5-11.4,14.1-13.3
    c1.9-0.7,4.1-0.7,6-1c1.9,0,3.4,0,5.1-0.7c3.2-1,4.7-2.5,5.1-4.8c0.7-1.9,0.7-4.1-0.7-7.3c-4.7,1.9-9.7,2.5-14.8,3.2
    c-8.8,0.7-17.3,1-23.9,12.3l-4.1,6.7l-1-7.5c0-1.6-0.7-2.5-0.7-3.4c-1-9.2-1.5-12.6-6-12.6v0.7c-0.7,4.1-1.5,10.1-10.7,10.8
    c-5.6,0-7.5,0.7-8.2,1c-3.4,9.2-11.4,13.9-18.2,18.1c-1.9,1.6-4.1,2.5-6,4.1c1.5,1,3.2,1.9,4.1,3.2c4.1,3.2,7.3,5.1,10.7,7.5
    c5.6,4.1,5.1,9.8,4.7,13.9c0,0.7,0,1,0,1.6v1.9l-1,0.7c0.7,0.7,0.7,0.7,1,1C2052.6,2591.7,2056.7,2596.4,2051.7,2602.5z
    M2013.9,2529.4c1,1.9,1.5,2.5,1.9,2.5c1,0.7,3.2,0.7,4.7,0.7c0.7,0,0.7,0,1,0c0,0,0-0.7,0.7-0.7c0.7-0.7,1-1,1-1
    c0-0.7-0.7-1.6-1-1.6c-1.5-0.7-3.4-1-5.6-1c-0.7,0-1,0-1.5,0C2015.2,2528.8,2014.5,2528.8,2013.9,2529.4z M2011.4,2195h0.7
    c0-0.7,0.7-1.9,0.7-2.5c0,0,0,0-0.7,0C2012,2193.5,2012,2194.1,2011.4,2195z M2002.9,2147.9c0,0.7,0,1,0,1c0.7,0,1,0,1.5,0
    C2003.8,2148.9,2002.9,2147.9,2002.9,2147.9z M1995.7,2428.9c-2.5-1.9-5.6-4.8-7.5-7.3l0,0c-1,0-1.5,0-1.5,0.7
    c-0.7,0.7-1.5,1.6-1.5,1.9c1.5,5.1,1.5,10.8,1.5,15.9c0,6,0.7,11.4,2.5,15.9c2.5,6,3.2,11.6,3.2,16.7c0,3.4,0.7,7.3,1,10.1
    c3.2,10.8,0,16.7-6.6,23.1c-1.9,1.9-4.1,4.1-6,6.7l-0.7,0.7c1,1,1.9,1,2.5,1l0,0c1,0,1.9-1,3.4-2.5c1-1,1.9-1.9,3.2-3.2
    c4.1-3.2,8.8-4.1,13.8-5.7c1.5-0.7,3.2-1,4.7-1.6c0.7-4.1,1-8.2,1.5-12.3c1.9-11.6,3.4-23.1-1.9-33.8l-0.7-1l0.7-1
    C2010.5,2441.2,2003.8,2435.6,1995.7,2428.9z M1982.4,1916.6c1.9-0.7,3.2-1,3.4-1.9c0.7-1,1-3.2,1.5-5.7
    C1986,1912.1,1984.1,1914.1,1982.4,1916.6z M1953.9,2331.2c-1.9,0.7-4.7,1-6.6,1.9c5.6,1.6,11.4,3.2,15.8,4.1
    c8.2,1.9,15.8,4.1,23.3,6c1-0.7,1.5-1.6,2.5-1.9c2.5-1.9,4.7-4.1,5.6-6l0,0c1-3.2,1-7.5-0.7-10.8c-0.7-1-3.4-2.5-6-2.5
    c-0.7,0-0.7,0-1,0C1976.1,2324.6,1966.8,2327.8,1953.9,2331.2z M1976.1,2474.5c-0.7-0.7-1-1-1.9-1.6c-0.7,1-1.5,1.9-1.9,3.2
    c1,0.7,1.9,1,2.5,1C1975.3,2477,1975.8,2476,1976.1,2474.5C1976.1,2475.1,1976.1,2475.1,1976.1,2474.5
    C1976.1,2475.1,1976.1,2474.5,1976.1,2474.5z M1957.6,2147.7c0,0.7,2.5,1.9,4.1,2.5c0.7,0.7,1,0.7,1.5,1c0-1.9,0.7-4.8,0.7-6.7
    l0.7-3.4v-0.7c0.7-1,1-1.6,1.5-1.9c-1.9,1-3.4,1.6-5.1,2.5C1958.6,2141.9,1956.9,2146,1957.6,2147.7z M1949.4,2091.7
    c1,1.6,5.1,1.9,8.8,1.9l0,0c-0.7-2.5-1-4.8-1.9-6.7c-0.7-1-2.5-2.5-3.4-2.5c-1,0.7-2.5,2.5-3.2,4.1
    C1948.1,2090.7,1948.8,2091.7,1949.4,2091.7z M1944.7,2195.7c1,2.5,4.1,5.1,10.7,8.9c5.6-6,6.6-12.6,3.4-20.5
    c-3.4-1.6-7.3-3.2-11.4-4.8C1945.4,2188.1,1943.8,2192.6,1944.7,2195.7z M1942.2,2482.3c0.7-0.7,0.7-1,1-1.6l0,0
    C1942.8,2481.1,1942.8,2481.7,1942.2,2482.3z M1927.4,2532.3c1,0,1.5,0.7,2.5,0.7c5.1,1,11.6,3.2,14.1,11.4
    c7.5-6,16.7-7.5,25.5-9.8c6.6-1.6,13.2-2.5,18.9-5.7c-1.5,0-3.2,0-4.1,0c-6,0-11.4-0.7-16.3-0.7c-1,0-1.5,0-2.5,0c-0.7,0-0.7,0-1,0
    c-7.5,0-12.6-1.6-15.8-5.1c-3.2-3.2-4.7-7.5-4.7-14.2c0-0.7,0-1-0.7-1.6c-0.7-0.7-1.9-1-3.4-1c-0.7,0-1.9,0-2.5,1
    c-3.2,3.4-5.6,7.3-7.3,10.8c-1.5,3.2-1.9,6.7-3.2,10.8C1927.4,2530.4,1927.4,2531.3,1927.4,2532.3z M1892.9,2255.1
    c1,0,1.9,0.7,3.2,0.7c4.1,0.7,8.8,1.6,13.2,1.9c-0.7-8.9,5.1-13.3,9.2-16.7c1-0.7,1.5-1.6,2.5-1.9c-1.5-2.5-3.4-5.1-5.1-5.1
    c-1,0-3.2,1-4.7,3.2c-1.9,1.9-4.1,3.4-6,3.4c-2.5,0-4.1-1.6-5.6-2.5l-0.7-0.7C1896.3,2242.8,1894.7,2248.4,1892.9,2255.1z
    M1884,2393.5c0-1.6-0.7-2.5-0.7-3.4c0-1.6-0.7-3.2-0.7-4.1C1882.7,2388.1,1882.7,2390.7,1884,2393.5z M1883,2411.3
    c3.4,1.9,6,3.2,7.5,3.2c0.7,0,1.5-1.9,1.9-4.8C1890.7,2409.9,1884,2411.3,1883,2411.3z M1874.9,2454.2c-1.9,0-4.7,1-7.5,1.9
    c-1,0.7-1.5,0.7-2.5,1c4.1,9.8,7.5,17.4,11.4,26.3c1.5-0.7,2.5-1,4.1-1c4.1-1,7.5-2.5,11.6-2.5c1,0,1.9,0,2.5,0
    c1.5,0.7,3.2,0.7,4.7,0.7c4.1,0,7.3-1.6,10.7-5.7c-6-13.9-18-16.7-30.6-20c-1,0-1.9-0.7-2.5-0.7
    C1875.5,2454.2,1874.9,2454.2,1874.9,2454.2z M1864.8,1879c1.9,1,3.4,1.6,4.7,1.6c0.7,0,1.5-0.7,2.5-1c-1-1-2.5-2.5-3.2-3.2l0,0
    C1867.9,1876.1,1866.4,1877.8,1864.8,1879z M1817.3,1880.2c4.1-0.7,8.2-0.7,11.6-1h1l1,1c2.5,2.5,5.1,4.8,7.3,6.7
    c0-5.7,0-11.4-2.5-14.9c-3.2-4.8-1.9-9.2-1-13.3c1.5-5.1,1.9-7.5-2.5-9.8c-1.5,1.6-3.2,3.4-4.1,5.1
    C1821.4,1862.6,1815.4,1869.2,1817.3,1880.2z M1787.7,1553.8c0.7,0,0.7,0.7,1,0.7l0,0c0,0,0,0,0-0.7
    C1788.4,1553.8,1787.7,1553.8,1787.7,1553.8z M1778.7,1733.6L1778.7,1733.6c0.7,0,1,0,1,0s0,0,0-0.7l0,0
    C1779.6,1733,1779.2,1733.6,1778.7,1733.6z M1746.3,1984.9c0,3.2,3.2,5.1,5.6,5.1c1,0,1.9-0.7,2.5-1c0.7-0.7,1-1,1.5-1.6
    c4.1-3.2,8.2-6,10-9.8c1.5-2.5,1.9-5.7,2.5-10.1c0.7-2.5,1-5.1,1.5-8.2l1-4.8l10.7,10.1c4.7,4.1,8.2,7.5,13.2,12.3
    c4.1-0.7,8.8-1,13.8-1.9c4.7-0.7,9.7-1.6,15.4-1.9h1.5l1,1c1.9,1.9,3.4,4.1,5.1,5.7c1,1,1.5,1.6,1.9,2.5c1.9-0.7,4.1-0.7,6.6-1
    c4.7-0.7,9.2-1.6,11.6-3.4c3.2-1.9,5.1-6,7.3-10.1c0.7-1.6,1.5-2.5,1.9-4.1c-11.6-19-2.5-35.7,6-50.5c1-1.6,1.5-3.2,2.5-4.8
    l-4.7-2.5c-5.6,1.9-11.6,4.8-19.5,7.3c-1.5,4.1-2.5,7.3-4.1,11.4l-1.5,4.1l-2.5-0.7c-3.2-1-5.6-3.4-7.3-5.1c-1-1.6-2.5-2.5-3.4-2.5
    c-0.7,0-1,0.7-1.9,1c-1.9,1.6-3.4,5.7-4.7,8.9c-0.7,1-0.7,1.6-1,1.9c0,0.7,0,1,0.7,1.6c0.7,1,0.7,1.9,0.7,3.2
    c0,13.3-6.6,23.4-18,28.2c-3.2,1-6,1.9-9.2,1.9c-8.8,0-16.3-5.7-20.4-15.5c0,0-1-0.7-1.5-1c-8.8,9.2-10,9.8-26.1,16.7
    c0,1.6,0,3.2,0,4.8C1746.3,1976,1746.3,1980.8,1746.3,1984.9z M1796.3,2258.7c-3.4,3.4-6.6,7.3-7.3,12.3c8.8,0.7,18,1,24.8,1.6
    c8.8-5.7,11.4-13.9,13.8-22.4c1.9-6,4.1-12.6,8.8-17.4c-3.2-8.2-5.6-15.5-8.2-23.1h-0.7c-1,4.1-1.9,7.5-3.2,12.3
    c-0.7,2.5-1.9,9.2-1.9,9.2l-1,0.7c-1,0.7-2.5,1-3.4,1.6c-6.6,2.5-12.6,4.8-13.8,13.9C1803,2251.1,1799.9,2255.2,1796.3,2258.7z
    M1710.4,2062L1710.4,2062c6,0,8.2-0.7,11.6-1.9c-1-0.7-1.9-0.7-3.2-0.7C1716,2059.5,1713.5,2060.5,1710.4,2062z M1705.3,2170.1
    c1.5,0,3.2,0.7,4.7,0.7l0,0c5.1,0,9.7-1.6,14.1-4.1c-3.2-0.7-6-0.7-9.2-0.7c-2.5,0-5.1,0-7.5,0.7c-1.5,0-1.5,0.7-1.5,1
    C1705.3,2167.9,1705.3,2169.2,1705.3,2170.1z M1686.1,1985.2c0.7,0.7,1.5,1.6,2.5,1.9c0.7,0.7,0.7,0.7,1,1c0-0.7,0.7-1,0.7-1.6
    c1-1.9,1.5-3.4,1.5-4.8c-0.7-0.7-1.5-1-1.9-1C1688,1981.7,1687.1,1983.6,1686.1,1985.2z M1676.4,2123.6c1.5,1,3.4,1,5.6,1
    c2.5,0,5.6-0.7,8.8-1c2.5-0.7,5.1-1,8.2-1.6c-2.5-1.6-5.1-2.5-8.2-2.5C1686.5,2119.9,1681.5,2120.8,1676.4,2123.6z M1675.4,2463.7
    c-1.9,3.2-3.4,6-5.6,9.8c4.1,1.9,7.3,3.2,10,3.2l0,0c3.4,0,6-1.6,8.2-5.1c3.2-5.7,1.5-10.1-6.6-18.1
    C1679,2457.1,1677.4,2460.3,1675.4,2463.7z M1661.6,2210.5c0,3.2,0.7,5.1,1.5,6c0.7,0.7,1.5,1.6,3.4,1.6h0.7c1.5,0,2.5-0.7,3.2-1
    c0.7-0.7,1-1.9,1-3.2C1668.3,2213.1,1665.1,2211.5,1661.6,2210.5z M1666.7,2137.8c0.7,1,1.5,1.6,1.9,2.5c0.7-0.7,0.7-0.7,0.7-0.7
    C1669.1,2139.5,1668.6,2138.2,1666.7,2137.8z M1663.5,1886.5c1,0.7,1.9,1,3.2,1c0.7,0,1-0.7,1-1c0-0.7-1.5-1.6-2.5-1.6
    c-0.7,0-0.7,0-0.7,0C1664.2,1885.6,1663.5,1886,1663.5,1886.5z M1652.8,2139.5c-1,0-2.5,0.7-3.4,0.7c-6,1-11.4,1.6-13.2,8.9
    c1.5,0.7,3.2,0.7,4.1,1c5.1,1.6,10,2.5,15.4,2.5l0,0c4.1,0,7.3-0.7,10.7-2.5l-2.5-2.5C1658.4,2146.4,1655.4,2142.9,1652.8,2139.5z
    M1633.1,2119.9c4.7,2.5,8.8,5.1,12.6,7.5c2.5,1.6,5.6,3.4,8.2,5.1c0.7,0,1-0.7,1.5-0.7c-1.9-8.2-8.8-11.6-15.8-15.9
    c-1.5-0.7-2.5-1.6-4.1-1.9C1635.2,2116.3,1634,2118,1633.1,2119.9z M1630.5,2196.7c0,0.7,0.7,1,0.7,1.6c3.4,4.1,6,6.7,7.5,6.7
    c1,0,1.9-0.7,4.7-1.9c1.9-1.6,3.4-4.8,3.4-6c-1-2.5-1.9-2.5-4.1-2.5c-1.5,0-3.2,0.7-5.1,1c-1,0-1.5,0.7-2.5,0.7
    C1634,2195.7,1632.1,2196,1630.5,2196.7z M1627,2264.3c0.7,0,1,0,1.5,0c4.7-0.7,9.2-1,13.8-1.6c9.2-1.6,19.5-3.2,28.9-5.1
    c0.7-0.7,0.7-1.9,1-2.5c0-0.7,0.7-1.6,0.7-1.9c-1.9-8.2-6-12.6-13.2-14.2c-5.1,2.5-11.6,6-18,9.8l-0.7,0.7c-1.5,1-3.2,2.5-4.7,3.4
    C1630.5,2255.4,1626.5,2258.5,1627,2264.3z M1634,2292.7c6,3.2,10.7,6.7,14.8,10.1c2.5,2.5,5.1,4.1,8.2,4.1l0,0
    c3.2,0,6.6-1,10.7-2.5l0,0c-18.2-8.2-32.8-19.6-45.9-34.1l0,0C1620.4,2280.7,1624.6,2287.7,1634,2292.7z M1618.8,2012
    c1-3.4,1.9-6.7,2.5-9.8c-1-1-1.5-1.6-1.9-1.6c-0.7,0-0.7,0.7-1,1.6C1617.3,2004.8,1616.6,2008.2,1618.8,2012z M1495.6,2314.2
    c0-1.6-0.7-3.2-1.5-4.8c-0.7,1-1.5,1.6-1.9,2.5c-0.7,1.6-1,3.2-1,4.8c0.7,0,0.7,0.7,1,0.7
    C1494.6,2315.8,1495.6,2314.8,1495.6,2314.2z M1501.6,2158.4v-0.7c-1-6-1.9-7.5-5.6-7.5l0,0c0,6.7,0,12.3,4.7,18.1v-0.7
    C1500.6,2163.8,1501.2,2160.9,1501.6,2158.4z M1513.5,2279.2c-1-1.6-2.5-1.9-3.4-1.9c-1.5,0-3.4,1-6,3.4l0.7,0.7
    c1,1.9,1.9,3.4,3.4,4.8l0,0c0.7,0,1,0.7,1.9,0.7s1.9,0,3.2-0.7c0.7,0,0.7,0,1,0c0-0.7,0-0.7,0-1
    C1513.8,2282.6,1513.8,2279.5,1513.5,2279.2z M1557.2,2405.5c-0.7,0-1.5,0-1.9,0c-3.2,0-4.7,0-5.6,0.7c-2.5,1-4.7,2.5-6.6,4.1
    c0,0.7,0.7,0.7,0.7,1c1.9,0.7,3.4,0.7,5.6,0.7c1.5,0,1.9,0,3.2-0.7c1.5-0.7,2.5-2.5,4.7-5.1
    C1556.6,2406.5,1556.6,2405.8,1557.2,2405.5z M1558.8,2444.4c-0.7,0.7-0.7,1-0.7,1l0.7,0.7
    C1558.8,2445.4,1558.8,2444.8,1558.8,2444.4z M1570.4,2371c-0.7-0.7-1-1.6-1-1.9l-1.5-0.7c-2.5-0.7-5.1-1-7.5-1.6
    c-1.5,1.9-4.1,4.1-7.5,4.1c-1,0-1.9,0-3.2-0.7l0,0l-3.2-1c-1.9-1-3.4-1.6-5.6-2.5c-4.1-1.9-8.2-4.1-12.2-5.1
    c-1.9-0.7-3.4-0.7-4.7-0.7c-1.5,0-2.5,0.7-4.1,5.7c1.5-0.7,3.2-1,5.1-1c3.2,0,5.6,1,8.2,3.2c7.5,6,16.3,8.2,24.5,9.8
    c1.9-1,3.4-2.5,5.6-3.4C1565.7,2374.3,1567.9,2372.7,1570.4,2371z M1527.6,2173.5c0.7-0.7,1-1,1-1s0-0.7-0.7-1.6
    C1527.1,2171.6,1527.1,2171.6,1527.6,2173.5z M1540.2,2219.1c5.1,0,9.2-1.9,13.8-5.1c-0.7,0-1.5,0-1.9,0c-4.7,0-9.7-1-15.8-2.5
    h-0.7c-1,0-1.5,0.7-2.5,1c-1,1-1.5,2.5-1.9,4.1C1534.2,2218.1,1537.4,2219.1,1540.2,2219.1z M1555.6,2362c-0.7-1.9-1-3.4-1.5-5.7
    c-0.7,1.9-1,4.1-1.9,6L1555.6,2362z M1567.3,2322.4c-1,0.7-1.9,1-2.5,1.6c-0.7,0.7-0.7,1-1,1.6
    C1564.5,2325.6,1566.3,2323.7,1567.3,2322.4z M1578,2455.5L1578,2455.5c-0.7,0-1,1-1,1.6c0,0,0,0,0.7,0.7
    C1577.7,2456.7,1578,2456.1,1578,2455.5z M1587.7,2247.8c-1-0.7-1.5-1-1.9-1.9c-1.9-2.5-1-5.7-0.7-8.2c0-0.7,0.7-1.6,0.7-1.9
    c-3.2,0-5.6-0.7-8.8-0.7c-8.2-0.7-16.7-1.6-24.8-1.6c0,0,0,0-0.7,0c-1.9,0-3.4,0.7-4.7,1.9c-0.7,1-1.5,1.9-1.5,4.1
    c0,4.1,2.5,9.2,4.1,9.8c5.6,1.9,11.6,3.4,18,3.4l0,0C1573.8,2252.5,1580.5,2251,1587.7,2247.8z M1601.5,1964.3c1,0.7,1.5,0.7,2.5,1
    c0,0,0,0,0-0.7c-0.7-0.7-1-1-1.5-1.9C1601.9,1963.7,1601.9,1963.7,1601.5,1964.3z M1593.4,2099.2c1,1,1.9,1.9,3.2,1.9
    c0.7,0,1.5,0,2.5-0.7c3.4-1.6,4.7-3.4,3.4-7.5c-0.7,0-0.7,0-1,0l-1,0.7C1599.1,2097.4,1595.9,2099,1593.4,2099.2z M1577,2021.5
    c0.7,0,1,0,1.5,0l0,0c0-1.6,0-3.2,0-4.8c0,0-1,0-1.5,0C1577,2018.7,1577,2020.5,1577,2021.5z M1567.9,2144.5
    c0.7,10.1,1.5,16.4,12.2,17.4c0.7-1,1-1.9,1.5-3.4c1.5-3.4,3.2-7.5,5.1-10.8c1-1.6,1.9-2.5,3.2-3.4c2.5-2.5,4.1-4.1,2.5-7.3
    C1584.3,2137.8,1576.4,2140.4,1567.9,2144.5z M1558.2,1935.5c0,0.7,0.7,0.7,1,1c2.5-1,4.7-2.5,6.6-3.4c-0.7,0-1,0-1.5,0
    c-1.9,0-4.1,1-5.1,1.9C1558.2,1935.3,1558.2,1935.5,1558.2,1935.5L1558.2,1935.5z M1551.6,2004.8c0.7,1,1,1.9,1,2.5
    c0,0,0.7-0.7,1-1c0.7-0.7,0.7-2.5,0.7-3.4c-0.7-0.7-0.7-0.7-0.7-0.7c-0.7,0-1,1-1.5,1.9C1552.2,2003.8,1552.2,2004.1,1551.6,2004.8
    z M1534.2,2087.3c-3.4,1.6-6.6,1.9-10,1.9c-5.1,0-9.7-1.6-13.8-3.2c-3.4-1.6-7.5-2.5-12.6-3.4c-1.5-0.7-3.4-1-5.1-1
    c-0.7,1.9-1.5,4.8-1.9,6.7c-1,4.1-2.5,7.5-3.4,11.4c-3.2,7.5-9.7,11.6-18,11.6c-1.5,0-2.5,0-4.1-0.7c-4.1-0.7-8.2-3.4-9.7-7.3
    c-1.5-3.2-1.5-6.7,0.7-9.8l1-1.6c2.5-4.1,4.7-8.2,5.1-12.3c-5.1,1-8.8,1.6-9.7,2.5c-1.5,1-1.5,4.1-1.5,10.1v1
    c-0.7,7.3-0.7,13.9-6,20c-1,1-1.5,3.4-1.5,6.7c0,1.9,0.7,4.1,1,5.1c0.7,0,1.5,0,1.9,0c1.9,0,4.1-0.7,6-1c1-0.7,2.5-1.6,4.1-3.2
    c0.7-0.7,1.5-1,1.9-1.9l1-1l1.5,0.7c5.6,1.9,11.4,3.4,16.7,5.7c3.2,1,5.6,1.9,8.2,2.5c2.5-1,4.7-3.2,6.6-4.8c4.1-3.2,8.2-6,13.2-6
    c3.4,0,6.6,1.6,10,4.1l1.5,1l-0.7,1.9c-1,3.2-1.9,6-3.4,9.2c-0.7,1.9-1.5,3.4-1.9,5.7c1,0.7,2.5,1.6,3.4,1.9c5.6,3.2,10.7,6,16.7,6
    l0,0c1,0,1.9,0,2.5,0c7.3-1,14.8-2.5,22.1-4.8c1.5-0.7,3.2-0.7,4.1-1c-7.3-12.6-6.6-16.7,1.9-27.2c3.2-4.1,6.6-7.5,10-11.6
    c0.7-0.7,1-1.6,1.9-1.9l-0.7-0.7c-5.1-6.7-8.8-11.6-16.3-11.6c-0.7,0-0.7,0-1,0c-0.7,0-1,0-1.5,0c-2.5,0-4.7,0-7.3-0.7
    c-1.9,0-4.7-0.7-6.6-0.7C1538.3,2086.1,1536.1,2086.6,1534.2,2087.3z M1502.1,2019.7c1-6,1.5-11.6,2.5-18.3c0,0,0,0-0.7,0
    c-1.5-1-3.2-1.6-4.1-1.6l0,0c-1.9,0-4.7,0.7-6.6,1.6c-0.7,0-1,0.7-1.5,0.7c-1,1.9-1.9,4.1-3.2,5.7c-2.5,5.1-5.1,9.2-3.4,14.2
    c0.7,1,1,2.5,1.9,4.1l0.7,0.7C1492.4,2023.8,1497.5,2021.5,1502.1,2019.7z M1497.5,2047.8c-4.7,1-7.3,1.9-9.2,3.2
    c-0.7,0.7-1.5,3.2-1.5,5.1c0,5.1,4.1,9.2,9.2,9.2h0.7c1.5-4.8,2.5-10.1,4.7-18.3C1499.7,2047.1,1498.7,2047.8,1497.5,2047.8z
    M1517.9,1872c2.5,1,4.7,1.6,6.6,2.5c0-0.7,0.7-1.6,0.7-1.9c-1-1.6-3.2-2.5-5.6-3.4c-0.7,0.7-0.7,1-1,1.9
    C1518.6,1871.1,1517.9,1871.7,1517.9,1872z M1502.1,1897.3c0,0-1.5,0-4.7,2.5c0,0.7,0,1,0,1.6c13.8,8.9,13.8,23.1,13.8,35.4
    c0,1.6,0,3.4,0,5.7c0,3.4-0.7,7.3-0.7,10.8v0.7c3.2,0.7,5.6,0.7,7.5,0.7l0,0c6.6,0,8.2-1.9,9.7-5.7c1.5-5.7-0.7-8.2-8.2-11.4
    c-1.9-1-2.5-3.2-3.2-5.1c0-0.7-0.7-1.6-0.7-1.9l-0.7-1.9l1.5-1c2.5-1.9,5.6-4.1,8.2-6.7c2.5-1.9,4.7-3.4,7.3-5.7
    c-1.5-1.9-3.2-4.1-1.9-6c1.5-3.4,0-5.1-4.1-7.5l0,0l-0.7-0.7l-1-0.7l0,0c-2.5-1.6-3.4-1.9-4.1-1.9c0,0.7-0.7,1-1.5,3.4l4.7,4.1
    l-8.8-1l0,0h0.7l0.7-1.6l-1,1.6c-1-0.7-1.9-1-3.2-1.9C1507.9,1899.8,1505.3,1898.3,1502.1,1897.3z M1482.7,1798.4
    c0.7,0,0.7,0.7,1,0.7c1.5-1.6,3.2-3.2,4.7-4.8l0,0c-1.9,1-3.4,1.6-5.1,2.5C1482.7,1796.9,1482.7,1798.1,1482.7,1798.4z
    M1441.1,1875.2l0.7,0.7c6,4.8,12.2,9.2,18,9.2c1.5,0,2.5-0.7,4.1-1c5.6-3.2,11.4-4.8,18.2-4.8c4.7,0,8.8,0.7,13.2,1
    c1,0,1.5,0,2.5,0.7c1-5.1-0.7-9.8-2.5-14.2c-0.7-1-1-2.5-1.5-4.1c-5.1,0-10-0.7-15.8-0.7c-6.6,0-13.2-0.7-19.9-0.7
    C1453.4,1866,1447.7,1870.1,1441.1,1875.2z M1429.1,2261.3L1429.1,2261.3c2.5,6,5.6,6,6.6,6c1.5,0,3.4-0.7,5.6-1.9
    c2.5-1.6,5.6-3.4,8.2-5.1c-3.2-0.7-6.6-1.6-10-1.9l-5.1-1l3.4-3.4c12.6-12.3,16.7-16.7,23.3-16.7c3.2,0,6,1,10.7,2.5l4.1,1l-6,7.3
    c-3.2,3.4-6,6.7-8.8,9.8l3.4,0.7l-0.7,0.7c0.7,0,1.5,0,1.9,0l0,0c4.7,0,8.2-2.5,11.4-8.2c1.9-3.4,5.6-5.7,9.2-7.5
    c0.7,0,0.7-0.7,0.7-0.7l-1.9-1.6l5.1-1.6c1-4.8,1.9-10.1,3.4-14.9c-4.7-5.1-8.2-9.2-11.4-12.6c-0.7,0.7-1.5,1.6-1.9,1.9
    c-3.2,3.2-6.6,6.7-10.7,7.3c-8.8,1-14.1,4.8-20.4,11.4c-3.4,4.1-8.8,8.2-14.1,11.4C1427.9,2247.3,1426.6,2252.4,1429.1,2261.3z
    M1383,2109.1c-0.7-1.9-1.9-5.1-4.1-5.1c-0.7,0-1.5,0-1.9,0.7c-3.2,1-6,3.4-9.2,6c-1.5,1.6-3.4,2.5-5.6,4.1l-3.4,2.5l-0.7-4.8
    c0-1.9-0.7-4.1-0.7-6c-0.7-3.4-0.7-6.7-1-10.1h-0.7c-0.7,0-1,0-1.5,0c-3.4,0-6,1.9-9.2,7.3c-1,1.6-1.5,2.5-2.5,4.1
    c-3.2,4.8-5.1,8.2-3.4,11.6l0,0c1,3.2,5.6,6.7,7.3,6.7l0,0C1360,2124,1374.5,2120.8,1383,2109.1z M1381.4,2019.7c1,0,1.9,0,2.5-0.7
    c0-0.7,0-1,0.7-1c-0.7,0-0.7-0.7-1-0.7C1382.3,2018.1,1382,2019,1381.4,2019.7z M1385.2,1984.3c0-1,0-1.9-0.7-3.2
    c-0.7-0.7-1-1-1.5-1C1383.5,1981.7,1383.9,1983.3,1385.2,1984.3z M1385.5,2149.6c-1.9-4.1-5.1-7.5-8.2-11.4c0-0.7-0.7-0.7-0.7-1
    c-3.2,4.1-4.1,7.5-3.4,10.8c0.7,2.5,1.9,4.8,5.1,5.7c0,0,0.7,0,1,0c1.5,0,3.2-0.7,4.1-1C1383.9,2152.1,1385.2,2150.5,1385.5,2149.6
    z M1400.3,1946.3c-1,1.6-0.7,4.8,1,9.2c1.5,4.1,3.2,8.2,5.6,12.6c0.7,0.7,0.7,1,1,1.6c0-0.7,0-0.7,0-1c0.7-4.8,1-8.2,1.9-12.3
    c0.7-3.4,1.5-7.5,1.9-11.4c0.7-1.6,0.7-2.5,1-4.1C1405.3,1942.8,1401.9,1944.4,1400.3,1946.3z M1382.3,1841.1c1,1,2.5,1.9,3.4,3.4
    c3.2,3.2,6,5.7,9.2,8.9c0.7,0,1.5,0.7,1.9,0.7c0.7,0,1,0,1.5,0.7c1.5-1.9,3.2-4.1,4.7-6c1-1.6,1.9-3.2,3.2-4.1
    c-1.5-9.8-2.5-16.4-3.4-23.1l0,0l-1-1.9c-1.5-4.1-3.4-8.9-5.6-13.9c-1-1.9-1.9-4.8-3.2-7.3c-0.7,0.7-1.5,0.7-1.9,0.7
    c-2.5,1-5.1,1.6-6,3.2c-3.2,4.1-3.2,9.2,0,12.6c1.5,1.6,3.2,3.2,5.1,4.8s3.4,3.2,5.1,4.8s1.5,4.8,1.5,6c0,1.6-1,2.5-1.5,3.2
    c-2.5,1.9-6.6,1.9-8.2,1.9l0,0c-1.5,0-3.4-0.7-5.1-1c-1-0.7-2.5-0.7-3.2-0.7C1377.3,1835.4,1378.6,1837.3,1382.3,1841.1z
    M1397.1,1879c-0.7,6,0,7.5,3.4,8.9h0.7c2.5-1,5.6-1.9,8.2-3.2C1405.9,1882.4,1401.9,1880.2,1397.1,1879z M1366.3,1769.4
    c0.7,0.7,0.7,0.7,1,1c1,0,1.5-0.7,1.9-1c0.7-0.7,0.7-1.6,0.7-3.2c-0.7,0-0.7-0.7-1-0.7C1367.9,1766.8,1366.7,1768.4,1366.3,1769.4z
    M1314.4,1904.9c1,0.7,2.5,1.6,3.4,1.9c4.1,2.5,8.2,4.8,11.6,7.3h0.7h0.7c2.5-1.9,5.1-4.1,9.2-7.3l1.5-1l1.5,1
    c4.1,1.9,8.2,4.8,12.2,7.3c4.7,2.5,9.7,5.7,14.1,8.2c3.4,1.9,7.3,3.2,10,3.2c4.7,0,9.2-1.9,13.2-5.7c2.5-2.5,4.7-5.1,7.5-8.9
    c0,0,0-0.7,0.7-0.7c-6.6,1.6-12.2,2.5-18,3.4l-1.5,0.7l-1-1c-1.9-1.9-4.7-4.1-7.3-5.7c-5.6-4.1-11.6-8.9-14.1-14.9
    c-4.7-10.8-12.2-12.3-21.4-13.9c-1.9-0.7-4.7-0.7-7.3-1.6c-0.7,10.8-5.6,18.1-15.8,22.4c-0.7,0.7-0.7,1.9-1,3.2
    C1314.7,1903.9,1314.7,1904.3,1314.4,1904.9z M1336.8,1967.5c0,0-0.7,0-1,0.7c-0.7,0.7-1,2.5-1,4.1c1-1,2.5-1.6,3.4-2.5
    C1337.7,1967.8,1337,1967.5,1336.8,1967.5z M1312.2,2069.5C1312.2,2069.5,1312.8,2069.5,1312.2,2069.5c4.7-2.5,8.8-5.1,13.2-6
    c4.1-1,8.8-1,11.6-1c1.5,0,3.4,0,5.1,0s3.4,0,5.1,0c0.7,0,1,0,1.5,0h1.5l1,1c1.5,1.6,3.2,3.4,4.7,5.7c1,1,1.9,2.5,3.2,3.4
    c4.7-3.2,8.8-5.7,12.6-8.9c1.9-1.6,4.1-2.5,6-4.1c0.7-2.5,1-5.1,1.5-7.5c0.7-1.9,1-4.1,1-6c-4.1-2.5-7.5-5.7-11.4-8.2
    c-1.5-1-3.2-1.9-4.7-3.4l-0.7,4.8l-4.7-7.5c0-0.7-0.7-0.7-0.7-1l-0.7-0.7l0,0v-0.7c-1-1-1.9-1.9-3.4-3.2c-1.5-1-2.5-2.5-4.1-3.4
    l-2.5-2.5l7.5-5.1l1.5,2.5c1,1.9,2.5,4.1,3.4,5.7c0.7,0.7,0.7,1,1,1.9c0.7-0.7,1-1.6,1.5-1.9c-1-9.2-3.4-18.3-11.4-24.1
    c-15.4,12.3-15.4,12.3-17.3,26.6c-1,5.7-1.9,9.2-6,13.9C1321,2049.4,1315.4,2058.8,1312.2,2069.5z M1214.3,2178.6
    c-0.7,0-1-0.7-1.5-0.7c-1.5-0.7-3.2-1-3.4-1c-0.7,0.7-1,1-1.5,1.9c1.5,1,3.2,1.6,4.7,1.6
    C1212.5,2180.8,1213.4,2179.8,1214.3,2178.6z M1239.8,2045.6c-3.4-3.2-6.6-5.7-9.7-8.2c-1-0.7-1.5-1.6-2.5-1.9
    c-1.5,3.4-7.3,14.9-8.2,17.4c4.1-1.6,8.2-2.5,13.2-4.1C1234.8,2047.1,1237.4,2046.8,1239.8,2045.6z M1253,2085.1L1253,2085.1
    L1253,2085.1c-1.5-0.7-3.2-0.7-4.1-0.7h-0.7c0,0,0,0,0,0.7C1250,2085.1,1251.5,2085.1,1253,2085.1z M1255.6,1902.4
    c-1,0.7-2.5,1.6-3.4,2.5l0.7,0.7C1253.7,1904.3,1254.6,1903.4,1255.6,1902.4z M1296.8,1974.4c0-0.7,0-1.6,0-1.9
    c-1.9-0.7-3.4-1.6-5.6-2.5c-3.2-1.6-6-3.2-8.2-3.2c-1.5,0-3.2,0.7-5.1,4.1c-1.9,4.1,0,7.3,6,12.3c1,1,2.5,2.5,3.4,3.4
    c0.7,0.7,1.5,0.7,2.5,0.7c3.2,0,6.6-1.9,7.3-4.1C1297.4,1981.1,1297.4,1978.2,1296.8,1974.4z M1286.7,1733.9c0,0,0.7,0,1,0h0.7
    c1.9-1.6,3.4-3.2,5.1-4.8c-1-1-1.5-1.9-2.5-3.2c-0.7-0.7-1-1.6-1.9-1.9c-1,1.6-2.5,3.4-3.2,5.1
    C1285.8,1730.5,1286.1,1732,1286.7,1733.9z M1260.7,1716.6c3.2,0,6-1,9.2-1.6c3.2-0.7,6.6-1.6,9.7-1.6c3.2,0,6,0.7,8.8,1.9l0,0
    l0.7,0.7c0.7,0.7,1.5,0.7,1.9,1v0.7l5.1,5.7l0,0c0,0,1,1,1.5,1.6c1-1.6,6-8.9,7.5-10.8c-8.8-1.6-17.3-2.5-25.5-4.1
    c-5.1-0.7-10-1.6-15.4-1.9c-0.7,1.6-1,3.2-1.9,4.8C1261.9,1714.1,1261.2,1714.9,1260.7,1716.6z M1258.8,2081c1.5,1,2.5,1.9,3.2,4.1
    c0,0.7,0,1.6,0,1.9c1,0.7,1.9,1,3.2,1c5.6-1.6,10.7-4.1,15.8-6.7c1.5-0.7,2.5-1,4.1-1.6c3.2-9.2,4.7-14.2,4.7-18.1
    c0-3.8-1.9-6.7-6-13.3l-0.7-1l0.7-1.6c1.5-3.4,4.1-6.7,6-9.8c4.7-6,8.8-11.6,7.5-20.5c-0.7,0-0.7,0.7-1,0.7c-3.2,1.9-6,3.4-9.7,4.1
    c-1.5,0-3.4,0.7-5.6,0.7c-2.5,0-4.7,0-7.3-0.7c-0.7,0-1,0-1,0C1274.1,2042.7,1269.4,2062.7,1258.8,2081z M1240.8,1875.2
    c1-1,2.5-1.6,3.4-1.6c1.9,0,3.4,1,4.7,1.9c0.7,0.7,1.5,1,1.5,1c0.7,0,1,0,1.5,0c0.7,0,2.5,0,3.2-4.1c0-1,0-1.6-0.7-1.9
    s-1.9-1-4.1-1l0,0C1244.9,1869.6,1243,1869.6,1240.8,1875.2z M1229.2,1943.5c-0.7-0.7-1-0.7-1.5-0.7c-1,0-2.5,1.6-4.1,3.2
    c-1.5,1.9-3.4,3.4-6.6,4.8c-9.2,1.9-18.9,4.1-27.4,12.6c0.7,0,1,0.7,1.5,0.7c3.2,1,5.6,1.9,8.2,3.4c5.1,4.1,10.7,6,16.7,6l0,0
    c6.6,0,13.2-1.9,18.9-4.1c3.4-1.6,6-3.2,6.6-5.7c0.7-1.6,0.7-4.1-1-6C1236.7,1950.4,1233.3,1946.1,1229.2,1943.5z M1199.5,1902.4
    c1.9,1,3.4,1.9,5.1,2.5c0.7-0.7,1-1,1.5-1.6l0,0c-1.5-1.6-3.2-2.5-4.7-3.2C1201.2,1900.8,1200.2,1901.5,1199.5,1902.4
    c0,0,0,0-0.7,0.7C1199.3,1902.4,1199.5,1902.4,1199.5,1902.4z M1116.6,1873.3c3.2,0,5.6-0.7,7.5-0.7c3.2-0.7,6-0.7,8.8-0.7
    c0.7-1.6,1-3.2,1.5-4.8c1.5-5.1,2.5-9.8,5.1-13.9c2.5-4.8,6-9.2,9.7-13.3c1.5-1.9,3.2-4.1,4.7-6l1-1h1.5c7.3,0.7,15.4,0.7,25.5,1
    c7.5-24.6,28-36.4,49.4-44.9c-0.7-1-1-1.9-1.5-2.5c-1.5-3.4-3.2-6.7-4.1-9.8c-1-3.2-1-6-1.5-9.2c-0.7-2.5-0.7-5.1-1-7.5
    c-1.5-7.5-7.5-10.1-12.2-10.8c-6.6-1-13.8-1.6-20.4-1.6c-1,0-1.9,0-3.4,0l0.7,0.7c2.5,1.9,4.7,4.8,6.6,7.3c1,1.6,1,3.4,1,5.1
    c0,0.7,0,1,0,1.6v3.2l-3.2-0.7c-0.7,0-1.5,0-2.5-0.7c-3.2-0.7-5.6-0.7-6.6-3.2s-1.5-5.7-1.9-8.9c-4.7,6-8.8,12.6-8.2,20.8
    c0.7,0,0.7,0,1,0c1,0,1.9,0,3.4,0l0,0c0.7,0,1,0,1.9,0c6.6,0,11.4,1.6,13.2,5.1c2.5,3.2,2.5,7.5,1,13.3
    c-2.5,8.2-8.2,13.9-15.8,15.5c-9.2,1.6-15.8,7.3-22.9,14.2c-11.4,11.4-22.1,23.1-31.4,35.7c-2.5,3.2-3.4,6.7-5.6,11.4
    C1117.5,1869.6,1117.1,1871.1,1116.6,1873.3z M1172.5,1866.7c-1,0-1.5,0.7-2.5,1l0,0c0,1.6,0.7,3.4,1,5.1l0,0c0.7,1,1,2.5,2.5,4.8
    c1-1.9,1.9-4.1,2.5-6c0.7-1,0.7-1.6,1-2.5C1175.1,1867.6,1173.8,1866.7,1172.5,1866.7z M1139.5,1928c-0.7,0-2.5,1.6-4.1,2.5
    c-0.7,0.7-1,0.7-1.5,1c0.7,0.7,1,1,1.9,1c1.9,1.6,4.1,2.5,5.6,3.4c0,0,0,0,0-0.7c0.7-0.7,1.5-1.9,1.5-2.5
    C1142.6,1931.2,1140.4,1928.7,1139.5,1928z M1104.9,1831.9c1.5,4.8,3.4,5.1,5.6,5.1l0,0c0.7,0,1,0,1.5,0c0,0,0,0,0-0.7
    c0-1,0.7-2.5,0.7-3.2c-1-2.5-2.5-5.1-5.6-5.1c-0.7,0-0.7,0-1,0C1104.9,1829.1,1104.3,1830.7,1104.9,1831.9z M1133.6,1990
    c0-1-0.7-1.6-1.5-1.9c-4.1,3.4-8.8,6.7-13.2,10.1l0,0c-1,0.7-1.9,1-3.2,1.6c-0.7,0.7-1.5,0.7-1.5,1c-6,6-8.2,18.1-3.4,26.3
    c0.7,0.7,4.1,1,6,1.6c0.7,0,1.5,0,1.9,0.7c-0.7-3.4-0.7-6.7-1-9.8c-0.7-3.2-0.7-6-1-9.2v-1.6l1-1c1-1,1.9-1.6,2.5-2.5
    c3.4-3.4,7.5-7.3,11.4-10.8C1132.9,1991.8,1133.6,1990.8,1133.6,1990z M1159.9,1969.4L1159.9,1969.4c-0.7-1.9-0.7-3.4,0-4.8
    c0-0.7,0-1.6,0-1.9v-0.7l0.7-0.7c1.5-1.9,3.4-3.4,5.6-5.1c2.5-1.6,4.7-3.2,5.1-5.7c-8.8,3.4-17.3,6.7-26.5,10.1l-8.8,3.4l-1-1.9
    c-7.3-11.6-18.9-14.2-31.1-17.4c-1.5-0.7-3.4-1-5.1-1c-1-0.7-1.9-0.7-3.2-0.7c-3.4,0-6.6,1.9-9.2,6c48.4,10.8,49.4,12.3,63.2,28.7
    c1,1,1.9,2.5,3.4,4.1c5.6-1.6,9.7-2.5,10-3.4C1162.5,1978.2,1161.5,1974.4,1159.9,1969.4z M1182.9,2003.8c-1.5,0-3.2-0.7-4.7-0.7
    c-4.7-0.7-8.8-1-12.2-1c-0.7,0-0.7,0-1,0c-1,0-2.5,1.9-3.2,2.5l0,0c0,1,1.5,3.4,2.5,4.1c2.5,1.6,5.1,1.9,7.3,1.9l0,0
    C1174.7,2010.8,1178.3,2009.8,1182.9,2003.8z M1190.5,1923.9c0.7-0.7,0.7-0.7,0.7-1.6c-1.5-7.3-8.2-9.2-14.8-11.4
    c-1-0.7-1.9-0.7-3.4-1c-1-0.7-1.5-0.7-2.5-0.7c-3.4,0-6,2.5-7.3,6.7c0,1-0.7,1.9-1,3.2h0.7l1,5.1c-0.7,0.7-1,0.7-1.9,0.7l0.7,1
    h-1.5c0.7,1.6,1.5,1.6,1.9,1.6c1,0,1.9,0,3.4-0.7s2.5-0.7,4.1-0.7h0.7c1.9,0,3.4,0,5.1,0C1187.3,1927,1189.8,1924.8,1190.5,1923.9z
    M1177.9,2151.8c0.7,1,1.9,1,3.4,1.6c0.7-1.6,0.7-2.5,0-3.2c-0.7-1-1-1.6-2.5-1.9c-0.7,0-1,0.7-1,1
    C1177.3,2150.1,1177.3,2151.1,1177.9,2151.8z M1149.9,2289.9c0.7,0,1,0.7,1.5,0.7c0.7-0.7,1-1.6,1.5-2.5c-0.7,0-1,0-1.5,0.7
    C1150.9,2288.2,1150.2,2289.2,1149.9,2289.9z M1124.8,2379.9L1124.8,2379.9c0.7,0.7,1,0.7,1.5,0.7c0.7,0,0.7,0,1,0c1,0,1.9,0,3.2,0
    c1,0,1.5,0,2.5,0c2.5,0,4.1-0.7,5.1-3.4c0-0.7-0.7-1.6-1-1.9c-1-0.7-2.5-1-3.4-1c-2.5,0-5.1,1.6-8.2,4.8
    C1124.8,2379.4,1124.8,2379.4,1124.8,2379.9z M957.7,2362L957.7,2362c1.5,0.7,2.5,0.7,3.2,1c1.5-1.9,2.5-4.1,3.2-6
    c-0.7-0.7-1-1-1.5-1c-1,1.6-1.5,2.5-2.5,4.1C958.6,2360.1,958.4,2361,957.7,2362z M857.4,2364.5c1,0,1.5,0,1.9,0
    c5.1,0,9.2-1,12.6-4.1c-1.5-2.5-3.2-4.8-4.7-6.7c-1.5,1.9-3.2,4.8-4.7,6C860.9,2362,859,2363.5,857.4,2364.5z M855.8,2427
    c4.1,1.6,8.2,3.2,12.2,5.1c2.5-14.2,9.7-23.1,22.3-27.2c0-0.7,0-1,0-1c-1,0-1.9,0-2.5,0C870.6,2403.3,860.6,2411.3,855.8,2427z
    M786,2372.7c1.5,0.7,3.2,1,4.1,1l0,0c0,0,0.7,0,1,0c0-1-1-4.8-1.5-5.7c-1,0.7-1.9,0.7-2.5,1C786.6,2370.2,786.6,2371,786,2372.7
    L786,2372.7z M764.6,2403.3c-3.2-1-6.6-1.6-10-2.5c0,1,0,1.9,0,3.2c0,1,0,2.5-0.7,4.1c0.7,0,1.5,0,1.9-0.7
    C760.5,2407.2,763.4,2406.5,764.6,2403.3z M744.8,2324c5.6-3.2,10-5.7,14.1-7.5l3.2-1.6c-1,0-1.9,0-3.2-0.7
    c-1.9-0.7-3.4-0.7-5.6-0.7c-1.5,0-2.5,0-3.4,0.7c-1.5,1-2.5,3.4-4.7,7.5C745.4,2322.4,744.8,2323,744.8,2324z M719.9,2398.3
    c1.5,3.4,4.1,4.1,8.2,5.1c0.7,0,1.5,0.7,1.9,0.7c0-1.6,0-2.5,0-4.1c0-1,0-1.9,0-3.2C726.4,2397.3,723.7,2397.6,719.9,2398.3z
    M686.8,2439.7c0.7,0.7,1,1,1,1.6c0.7,0,1,0,1.5,0s1.5,0,1.9,0c-1-0.7-1.9-1-3.2-1.6c0,0,0,0-0.7,0
    C687.8,2439.3,687.5,2439.3,686.8,2439.7z M627.7,2659.4c4.7,4.8,8.2,9.8,11.4,14.9c3.4-3.2,6.6-4.8,8.8-7.5c1-1.9,1.5-4.1,1.9-6.7
    C643.8,2666,635.9,2667.6,627.7,2659.4z M595.6,2740.3c0,1,2.5,1.9,3.4,2.5l0,0c-1-1.6-1.9-2.5-3.2-4.1
    C595.6,2738.9,595.6,2739.6,595.6,2740.3z M541.9,2658.7c-1.9-1-4.1-1.6-5.1-1.6c-1.5,0-3.2,0.7-5.1,3.2l0,0
    C535.3,2660.2,538.1,2659.7,541.9,2658.7z M2301.4,2832.8c-3.4,5.1-7.3,9.2-10,13.9c0,0.7,0.7,0.7,0.7,1c3.2,0,6,0,9.7,0.7v-15.6
    H2301.4z M1418.5,1912.1L1418.5,1912.1L1418.5,1912.1C1418.5,1912.5,1418.5,1912.5,1418.5,1912.1
    C1418.5,1912.5,1418.5,1912.1,1418.5,1912.1z M1597.8,1900.8L1597.8,1900.8L1597.8,1900.8L1597.8,1900.8L1597.8,1900.8z
    M1370.7,2772.5L1370.7,2772.5c0,0,0-0.7,0.7-0.7C1370.7,2771.9,1370.7,2771.9,1370.7,2772.5z M1620.7,3045.2
    C1620.4,3045.2,1620.4,3045.2,1620.7,3045.2C1620.4,3045.2,1620.4,3045.2,1620.7,3045.2L1620.7,3045.2z M2115.8,2206.8
    c0,0,0.7,0,0.7,0.7C2116.5,2206.8,2115.8,2206.8,2115.8,2206.8L2115.8,2206.8z M1870.5,1908L1870.5,1908
    C1870.5,1908.4,1870.5,1908,1870.5,1908L1870.5,1908z M2096.9,2666.3C2096.9,2666,2096.9,2666,2096.9,2666.3
    C2096.9,2666,2096.9,2666,2096.9,2666.3C2096.9,2666,2096.9,2666.3,2096.9,2666.3z M1557.2,3393.5c-1.5,0.7-3.2,1.9-7.5,6
    c5.6,4.1,9.2,6.7,12.6,9.2c2.5-1.9,5.1-3.2,5.6-4.8c0.7-3.4,0.7-7.3,0.7-10.8C1564.5,3393.2,1560.7,3393.2,1557.2,3393.5z
    M1501.6,3453.9c-1,1.6-1.5,2.5-2.5,4.1c1.5,0.7,3.2,1,5.1,1c0,0,1.5-1.9,1-2.5C1504.1,3456.1,1502.8,3455.1,1501.6,3453.9z
    M1511,3484.8c-0.7,1-0.7,1.6-1,2.5c0.7,0.7,1,1.6,1.9,1.9c0.7-1,1-1.6,1-2.5C1512.3,3485.8,1511.3,3485.2,1511,3484.8z
    M1302.1,1698.9C1302.1,1698.9,1302.5,1699.2,1302.1,1698.9L1302.1,1698.9z M544.7,2798.1c-0.7,1-1,1.6-1.5,2.5
    c-8.8-5.1-22.3-14.9-19.5-11.6c7.3,7.3,13.2,11.4,18.9,12.6c-1,1.6-1.9,3.2-2.5,4.1c3.4,1.6,8.2,5.7,8.2-2.5c1.5,0.7,1.5,0.7,0.7-1
    h-0.7l0,0c0-0.7-0.7-1-0.7-1.6c0.7,0.7,1,1,1.5,1.6l0,0c-0.7-0.7-1-1-1.5-1.6C546.9,2800,546,2799,544.7,2798.1z M1050.5,1970
    c0,0,0.7,1,2.5,2.5C1052.1,1971.6,1050.8,1970.3,1050.5,1970z M1252.2,1680.8c0-0.7,0.7-1,1-1.6l-9.7-3.4h-0.7l0,0l0,0
    C1246.4,1677.4,1249,1679.2,1252.2,1680.8z M2102,1557c3.4,3.4,6.6,6.7,9.2,10.1C2119.3,1575.3,2121.5,1576.8,2102,1557z
    M1604.1,1697.3C1603.5,1697.3,1603.5,1697.5,1604.1,1697.3c0,0.7,0,0.7,0.7,0.7c0-0.7,0.7-0.7,0.7-1
    C1604.4,1697.3,1604.1,1697.3,1604.1,1697.3z M1603.5,1676.1c-5.6,1-11.6,2.5-17.3,3.2c3.2,6,5.6,11.4,8.2,15.9
    c2.5,4.8,5.6,3.4,9.2,1.9C1611.7,1691,1609.8,1684.3,1603.5,1676.1z M1656.9,2612.2c1.5,9.2,2.5,16.7,3.4,25.6
    c5.6-5.7,10-9.8,14.8-14.2c-1.5-4.8-2.5-8.9-4.1-14.2C1666.7,2610,1662,2611.2,1656.9,2612.2z M1662,2922.3c-0.7,0-1,0.7-2.5,1.6
    c1.5,0,2.5,0,3.4,0C1662.6,2922.9,1662,2921.6,1662,2922.3z M1481.4,2589.9c0.7,1,1.5,2.5,1.9,3.4c1-1,2.5-1.9,3.4-3.2
    c-1.5-0.7-2.5-1.6-4.1-1.9C1482.7,2588.2,1480.8,2589.9,1481.4,2589.9z M1213.4,2367.9c0.7,0.7,1,0.7,1.5,0.7l-3.2-1.9
    C1212.5,2366.9,1212.5,2367.6,1213.4,2367.9z M1216,2368.6c-0.7,0-1,0-1,0l1.5,1C1216.5,2369.2,1216.5,2369.2,1216,2368.6
    L1216,2368.6L1216,2368.6z M1452.2,2451.1c13.8,13.9,14.1,10.1,8.8,0C1456.2,2452,1453.4,2452.6,1452.2,2451.1z M1676.4,2623
    L1676.4,2623L1676.4,2623L1676.4,2623c-0.7,0-0.7,0.7-0.7,0.7s0,0,0,0.7l0,0h0.7C1676.4,2623.3,1676.4,2623,1676.4,2623
    L1676.4,2623z M1716.7,2978.6l-1.9,0.7l1.5,1.6L1716.7,2978.6z M2198.2,1868.2c0,1,1.5,1.6,2.5,2.5c0-1-0.7-1.6-0.7-2.5
    c0-1.6,0-3.4-0.7-5.1v-0.7l0,0l0,0C2199.5,1865.1,2198.2,1866.7,2198.2,1868.2z M1621.4,2772.5c-3.2-1.6-6-3.4-9.2-5.1
    c-1,1.9-1.9,4.8-3.2,6.7c3.4,1.6,6.6,3.2,9.7,4.1c0.7,0,1.5-0.7,4.7-1.9C1622,2774.1,1622,2772.8,1621.4,2772.5z M1628,2858.4
    c1.5,0.7,3.2,1,4.7,1.6c0,0,1-1.6,1.5-1.9c-1.5-0.7-3.2-1.6-4.7-1.9C1628.9,2855.9,1628.7,2857.2,1628,2858.4z M1646.2,2786.1
    l-0.7,1c0.7-0.7,1-0.7,1.5-1C1647.2,2785.7,1646.9,2785.7,1646.2,2786.1z M1631.4,2837.9c-2.5-3.4-5.6-4.8-9.7-0.7
    c3.2,1,5.6,1.9,8.2,2.5C1630.5,2839.8,1631.4,2838.5,1631.4,2837.9z M1638.7,2763.7L1638.7,2763.7c2.5,7.5,4.7,15.5,7.3,23.1
    l0.7-0.7C1643.7,2779.1,1641.8,2771.9,1638.7,2763.7C1639.4,2764.2,1639.4,2764.2,1638.7,2763.7c0.7,0,0.7,0,0.7,0
    S1639.4,2763.7,1638.7,2763.7L1638.7,2763.7z M1618,2814.9c-0.7,0-0.7,0-1,0l0,0l0,0v0.7C1617.3,2815.4,1617.3,2814.9,1618,2814.9
    L1618,2814.9z M2290,2847c0-0.7,0.7-0.7,0.7-1l0,0v0.7L2290,2847L2290,2847z M1629.5,2805.7c-1.5,0-3.4,0-4.7,0.7
    c-2.5,2.5-4.7,5.7-7.3,8.2c3.4-0.7,7.3-0.7,10-1.9C1628.9,2812.3,1628.9,2808.2,1629.5,2805.7z M2270.8,2795.9
    c-1,0.7-1.5,3.2-1.9,4.8c1.5,0.7,3.4,1.6,4.7,1c1-0.7,1.5-2.5,1.9-4.1C2274,2796.8,2271.8,2795.6,2270.8,2795.9z M1618.8,2556.3
    c-5.1,0.7-10,3.4-9.2,11.4c10-1.9,20.4-3.4,31.4-11.4C1631.1,2556.9,1624.8,2555.4,1618.8,2556.3z M1563.2,2588.2
    c1.9,1,4.7,2.5,7.3,1.9c1.9,0,4.1-1,6-1.9c-1.9-1-4.7-2.5-6.6-3.2s-4.7,0-6.6,0.7C1562.9,2585.6,1562.9,2588.2,1563.2,2588.2z
    M1568.5,2466.8c-1,0-1.5,0-2.5,0.7c-0.7,0.7-1.5,1-1.9,1.6c0.7,0.7,1.5,1,1.9,1C1567,2468.8,1567.3,2467.8,1568.5,2466.8z
    M1586.7,2564.5l0.7,1.9l1.9-1.6L1586.7,2564.5z M1610.7,2631.5c0,0.7,0,1,0.7,1.9c1,0,1.5,0,2.5,0c0-1-0.7-1.9-0.7-3.2
    C1612.2,2630.5,1611.3,2631.2,1610.7,2631.5z M1637.2,2675.1l20.4-5.7C1648.4,2664.3,1648.4,2664.3,1637.2,2675.1z M1578.6,2588.5
    c-0.7,0-0.7-0.7-1-0.7l-0.7,1C1577.7,2588.5,1578,2588.5,1578.6,2588.5z M1577.7,2587.6c-0.7,0-1,0.7-1.5,0.7c0,0,0.7,0,0.7,0.7
    C1577,2588.5,1577.7,2587.6,1577.7,2587.6z M1471.7,2662.8c-1.5,1.6-1.5,4.1-2.5,5.7c1.5,0,4.1,0.7,5.1-0.7c1-1,1.5-3.2,2.5-4.8
    C1475.1,2663.5,1472.6,2662.5,1471.7,2662.8z M1559.7,2666.9c0.7-1,1.5-1.9,1.5-3.2c-0.7,0-1.5,0-1.9,0c-0.7,0.7-1,1-1.5,1.9
    C1558.2,2666.3,1559.1,2666.9,1559.7,2666.9z M1550.9,2654.6c-0.7,0-1,0.7-1.9,1c1.5,0,2.5,0,3.2,0
    C1551.6,2655.6,1550.9,2654.6,1550.9,2654.6z M1602.5,2596.4c-6.6-7.5-15.4-8.2-23.9-7.5
    C1592.5,2598.1,1592.8,2598.1,1602.5,2596.4z M1582.7,2704.2c2.5,5.7,5.6,9.8,13.8,5.7l-5.6-20
    C1585.9,2693.5,1579.2,2696,1582.7,2704.2z M2270.2,2136.9c0.7,0,1-1,1.5-1.6c-0.7,0-1.5-0.7-1.9-1c-0.7,0.7-1,1-1.5,1.6
    C2268.6,2136.9,2269.9,2137.3,2270.2,2136.9z M2197.9,1924.6c-1,0.7-2.5,0.7-3.4,1c1.5,0,3.2,0.7,4.1,0.7
    C2198.2,1925.4,2197.9,1924.6,2197.9,1924.6z M1070.4,1860c-1.5-0.7-3.2-1.6-4.7-1.9c0,0.7,0.7,1.9,0.7,1.9
    C1067.8,1860,1069.4,1860,1070.4,1860z M1082.6,1809.2c1,0,1.9,0,4.1,0.7c-1.5-1-1.9-1.6-2.5-1.6
    C1083.5,1807.6,1082.6,1808.8,1082.6,1809.2z M2192.2,2116.3c-6,2.5-2.5,6-1.9,9.8c3.2-1.9,7.5-3.2,4.1-8.9
    C2193.8,2116.7,2192.9,2116.3,2192.2,2116.3z M1565.3,2683.4c1.5,1,2.5,1.9,4.1,3.4c0.7-1.6,1-2.5,1.9-4.1
    c-1.5-0.7-3.2-1.6-5.1-1.6C1566.3,2681.2,1565.7,2682.7,1565.3,2683.4z M1591.2,2689.4L1591.2,2689.4v1l0.7-0.7L1591.2,2689.4
    L1591.2,2689.4C1591.2,2689.1,1590.8,2689.1,1591.2,2689.4L1591.2,2689.4z M1589.3,2666c0-0.7-3.2,1.6-3.4,1.9l4.1,14.2
    c1.5,0,3.2,0.7,3.4,1.6c0.7,0.7,0.7,0.7,0.7,1c0,1-1,1.9-2.5,3.2l0.7,1.6c7.5-1,16.7,5.7,22.9-6c-5.1-1.6-10.7-1.9-14.8-4.8
    C1595.2,2675.1,1592.8,2670.1,1589.3,2666z M1573.6,2629.6c9.7,2.5,15.4,4.1,22.3,5.7C1592.5,2624.5,1587.1,2620.8,1573.6,2629.6z
    M1576,2641c-1,0-1.5,0.7-3.4,1c1.9,0.7,3.2,1,4.7,1C1576.4,2642,1576.4,2641,1576,2641z M1573,2613.8c0.7-0.7,1-1,1.5-1.6
    c-1,0-1.5-0.7-1.9-0.7L1573,2613.8z M1480.2,2687.7c-1.9,0.7-3.2,4.1-5.1,7.3c3.2,1.6,6,3.4,7.5,2.5c1.9-1,3.4-4.1,3.4-6.7
    C1486.5,2689.4,1481.7,2687.5,1480.2,2687.7z M1312.2,2493.4L1312.2,2493.4L1312.2,2493.4L1312.2,2493.4L1312.2,2493.4z
    M809.2,2768.6c-0.7-0.7-1-1.6-1.5-1.9c-0.7,0-1.5,0.7-2.5,1C806.1,2768.4,807.6,2768.4,809.2,2768.6z M809.2,2611.6
    c0.7,0.7,1,1.6,1.9,1.6c0.7,0,1.5-0.7,1.9-0.7c-0.7-0.7-1-1.6-1.9-1.6C810.8,2611.2,809.5,2611.6,809.2,2611.6z M760.2,2656.8
    c0.7,1.6,1.5,3.4,3.2,4.8c0.7,0.7,2.5-1.6,4.7-2.5c-1.9-1.9-3.4-3.4-5.6-5.1C761.2,2654.6,759.6,2655.6,760.2,2656.8z
    M783.4,2566.1c-0.7-0.7-1-1.6-1.5-1.9c-1.5,0.7-3.4,1-4.1,3.2l0,0C779.4,2567,780.9,2566.7,783.4,2566.1z M815.8,2902.3
    c-0.7-0.7-1.5,0-1.9,0c0,0.7-0.7,1.6,0,1.6c0.7,0.7,1.5,0,1.9,0.7C815.8,2904,815.8,2902.3,815.8,2902.3z M748.9,2749.4
    c18,13.9,34.6,15.5,51,21.5C786.6,2759.6,770.3,2755.1,748.9,2749.4z M803.6,2811.7c0.7,0.7,1.5,0.7,1.9,0.7c0-0.7,0.7-1.6,0-1.6
    c-0.7-0.7-1.5-0.7-1.9-0.7C804.2,2810.8,803.6,2811.7,803.6,2811.7z M775.5,2839.8c0,0,1-1,1.9-1.6c-1-1-1.9-1.9-3.4-2.5
    c-0.7,0-1.5,1-1.9,1.6C773,2837.9,774,2838.8,775.5,2839.8z M775.5,2698.8c-0.7-0.7-0.7-1-1-1.9c-1,0.7-1.5,1.6-2.5,1.9
    c0.7,0.7,1.5,1,1.9,1C774.7,2699.8,775,2699.1,775.5,2698.8z M751.9,2580.3c-0.7,0-1.9,1.6-3.2,1.9c1.5,1,3.2,1.9,4.7,2.5
    c0.7,0,1.5-1,1.9-1.6C755.1,2583.2,753.6,2581.5,751.9,2580.3z M815.5,2644.5c-8.2-9.8-16.3-19-26.5-30.7
    c-0.7,13.9-9.7,22.4-4.1,35.7C795.5,2653,805.5,2655.6,815.5,2644.5z M792,2776.9c0.7,1.9,1.9,4.1,4.1,6c0.7,0.7,3.4-1.6,4.1-2.5
    c0.7-1.9-0.7-4.8-1-8.9C794.7,2774.4,791.3,2775.3,792,2776.9z M806.1,2581.9c-0.7,0.7,0,3.2-0.7,4.1c1.5,0,4.1,0.7,5.1-0.7
    c1-0.7,0-3.2,0.7-4.8C809.2,2581.5,807,2581.5,806.1,2581.9z M788.5,2546.8c5.1-0.7,6.6-5.7,6.6-10.8c-9.7,1-19.5,1.6-32.8,3.2
    C772.4,2547.7,780.9,2547.7,788.5,2546.8z M793.8,2497.2c1,0,1.9,0,2.5-0.7c0.7-0.7,0.7-1.6,0.7-2.5c-1-0.7-1.5-0.7-2.5-1
    C794.1,2494.3,794.1,2496,793.8,2497.2z M793.3,2508.9l1.9,1.6l0.7-1.9L793.3,2508.9z M760.2,2548.1c-1,4.8-2.5,9.2-1.9,13.9
    c1,7.3,5.1,11.6,13.8,9.8C768,2563.6,764.3,2555.9,760.2,2548.1z M789.2,2866.6h1.9l-1.5-1.6L789.2,2866.6z M809.2,2549.6
    c-1.9-1-6.6,1.6-7.3,2.5c-0.7,3.4,0,7.3,0.7,12.3c0.7-0.7,0.7-0.7,1.5-1c1.9-1.6,4.7-2.5,7.3-4.1c-2.5,2.5-4.7,5.1-7.3,8.2
    c-0.7-0.7-0.7-0.7-1-1c0,0.7,0,1,0,1.6c5.6-3.4,10-4.1,11.4-7.3C815.8,2556.3,814.3,2551.3,809.2,2549.6z M803,2573.3
    c1,6.7,7.3,4.8,11.6,6.7C810.5,2577.8,806.4,2575.2,803,2573.3z M796.9,2704.2c-7.3,4.1-14.1,7.5-22.3,11.6
    c1.9,4.8,4.1,7.5,7.3,14.9c6.6-7.5,10-11.4,13.8-15.9c1.9-2.5,6.6-5.1,3.4-9.8C798.5,2705.5,797.6,2704.2,796.9,2704.2z
    M816.2,2580.3C816.2,2581,816.2,2581,816.2,2580.3c-5.1,6.7-10.7,13.3-16.3,20c-3.2,4.1-3.2,9.2,1,12.3c1.9,1,6-1.6,9.7-1.9
    c1.5,0,3.4,0,5.1,0C827.5,2599.3,827.5,2599.3,816.2,2580.3C816.2,2581,816.2,2580.3,816.2,2580.3z M803,2838.5
    c-1.5-1.6-6-1.9-7.5-1c-3.4,2.5-5.6,6.7-8.2,10.1c0,0.7,2.5,3.4,3.4,3.4c5.1-1,10-2.5,15.8-4.8
    C804.8,2842.9,804.5,2839.8,803,2838.5z M813,2923.8c2.5-0.7,4.7-1.9,7.3-3.2c-1.5-1.6-3.4-3.2-5.6-4.8c-1.5-1-4.1-1-5.1,0
    c-1,0.7-1.5,3.4-0.7,4.8C809.6,2922.3,811.5,2924.5,813,2923.8z M816.2,2611.2h0.7C819.6,2612.2,817.8,2611.6,816.2,2611.2z
    M752.6,2540.2c-1-1-2.5-1.9-3.4-3.2c-0.7,1-1.9,2.5-1.9,3.2c0.7,1.6,1.9,2.5,3.2,3.4C751,2542.7,751.7,2541.1,752.6,2540.2z
    M659.8,3294L659.8,3294l-0.7-1C659.5,3292.7,659.5,3293.3,659.8,3294C659.5,3293.3,659.8,3294,659.8,3294z M659.5,3292.7
    L659.5,3292.7L659.5,3292.7L659.5,3292.7z M670.5,3138.8c-6,3.4-11.6,7.5-18,11.4c4.7,6,5.1,6.7,18.9-0.7c-0.7-1-0.7-1.9-0.7-3.2
    c0.7-0.7,0.7-0.7,1-1c0,0,0,0,0.7,0c0.7,1,1,1.9,1.5,3.2c1.5-1,3.4-1.9,5.6-3.2C676.1,3143.5,673.1,3141.3,670.5,3138.8z
    M659.5,3292.7L659.5,3292.7L659.5,3292.7L659.5,3292.7z M773,3222.2c-4.1-6.7-6.6-10.8-10-16.4
    C761.4,3216.2,761.4,3216.2,773,3222.2z M760.2,3230.8c0.7,0.7,1,1.9,1.5,1.9c1.9,0,3.4-1,5.6-1.6c-1-1-1.5-3.2-2.5-3.2
    C763.3,3228.9,761.7,3230.4,760.2,3230.8z M787.5,3243.7c0,0.7-0.7,0.7-0.7,1c0.7,0,1-0.7,1.9-0.7
    C788.5,3244.4,788.2,3243.7,787.5,3243.7z M779.1,2542.1c-6.6-0.7-15.8-6-18.9,5.7c8.2,1.6,16.3,3.4,24.5,5.7
    C788.1,2546.2,783.7,2542.7,779.1,2542.1z M749.2,3254.5c-0.7,0-0.7,2.5-1,4.1c1.5,0,3.4,1,5.1,0.7c1-0.7,1.9-1.9,3.4-4.1
    C753.6,3255,751,3254.5,749.2,3254.5z M751.9,3141.3c2.5,1.6,5.6,3.4,8.8,5.1c0.7,0,1.9-1.9,5.1-5.1c-4.1-1.6-7.3-2.5-10-3.2
    C754.8,3138.1,753.3,3140.3,751.9,3141.3z M695.7,3232l0.7,0.7l0,0c3.4,9.2,0.7,20,7.5,28.2c0,0,0.7,0,0.7-0.7l0,0c0,0,0,0,0.7,0
    C707.4,3248.7,708.2,3238.6,695.7,3232c0.7,0,0.7,0.7,0.7,0.7L695.7,3232z M741.9,3055.3c-1.5-0.7-3.2-0.7-4.7-0.7
    c1,1.6,1.9,3.2,3.4,4.1c0.7,0.7,1.5,0.7,2.5,0.7C742.9,3057.8,742.9,3056,741.9,3055.3z M737.1,3098.3c4.7-0.7,10-0.7,11.4-6.7
    c0.7-6-4.7-7.5-9.2-8.9c-1.9-0.7-3.4-1.6-5.6-1.6c-8.2,0.7-16.3,1.6-25.5,1.9C726.4,3099,726.4,3099.3,737.1,3098.3z M737.1,2726.3
    c0.7,0.7,1,1,1.5,1.6c0.7,0,1-0.7,1.5-1c-1-1-1.5-1.6-2.5-2.5C737.8,2724.8,737.8,2725.8,737.1,2726.3z M717.4,2785.7
    c-3.2-0.7-6-1-8.8-0.7c-1.5,0-3.2,1.9-3.4,4.1c-0.7,1.6,0,4.1,1,5.1c3.4,5.1,9.2,6.7,15.4,5.7c1.9-0.7,4.1-3.4,5.6-5.7
    c0.7-1-0.7-4.1-1.5-5.1C722.7,2787.6,719.9,2786.7,717.4,2785.7z M736.9,2665.3c-1.9-4.1-3.4-7.3-5.6-9.8c-0.7-1-3.4,0-5.1,0
    c1,3.2,1.9,6.7,4.1,9.2C730.3,2666,733.4,2665.3,736.9,2665.3z M713.9,2907.1L713.9,2907.1C712.3,2907.1,713,2907.1,713.9,2907.1z
    M695.7,3231.4L695.7,3231.4L695.7,3231.4L695.7,3231.4L695.7,3231.4c0,0,0.7,0,0.7,0.7c0.7-4.8,1-10.1-4.1-12.3
    c-5.1-1.9-10.7-4.1-16.3-3.4c-4.1,0.7-8.2,4.8-12.2,7.3c-12.2,8.2-12.2,19.6,1,26.6c4.7,2.5,9.2,4.8,14.1,7.3c0.7,0,0.7,0,1-0.7
    v0.7v-0.7h0.7c-0.7-5.1-1-10.8-0.7-15.9c0.7-3.4,3.2-7.5,5.6-9.2c2.5-1.6,7.3,0.7,10.7,0.7C696,3232,695.7,3232,695.7,3231.4z
    M790.7,3213l-1.5-1l-0.7,1.6L790.7,3213z M710.8,3268.3c8.8,8.9,8.8,8.9,21.4,10.8C727.1,3272.8,720.5,3270.2,710.8,3268.3z
    M704.2,3152.1c-0.7,0.7,1,4.8,1.5,4.8c3.2-0.7,5.6-1.6,9.7-2.5c-1.5-4.1-2.5-6-3.4-8.2C709.2,3148,706.4,3149.5,704.2,3152.1z
    M877.9,3438.1c4.7-0.7,10-1,10-6.7c0.7-6.7-4.7-9.2-10-10.8C877.9,3426.4,877.9,3432.4,877.9,3438.1z M867.2,3442.2
    c-0.7,1,1.9,4.8,2.5,4.1c1.9-0.7,4.7-1.6,6-3.2c1-1,1-3.2,1-4.8c-1.5-0.7-3.4-1.6-4.7-1C870.3,3438.7,868.1,3440.3,867.2,3442.2z
    M844.2,3427.3c0.7,1.6,0.7,2.5,1,4.1c1,0,1.5,0,2.5,0c-0.7-1.6-0.7-3.2-1-4.1C846.7,3427.1,845.1,3427.3,844.2,3427.3z
    M979.7,3267.7L979.7,3267.7L979.7,3267.7L979.7,3267.7z M839.2,3299c-0.7,1-0.7,1.9-0.7,3.2c1,0,1.5,0,1.9,0
    c0-0.7,0.7-1.6,0.7-1.9C840.7,3299.4,839.5,3299.4,839.2,3299z M977.3,3246.8c1,6.7,1.5,13.9,2.5,20.5c2.5-4.8,5.1-9.2,6.6-14.2
    C986.3,3250.9,982.6,3248.5,977.3,3246.8z M850.7,3464.7c1-0.7,1.9-0.7,1.9-1c0-0.7-0.7-1.6-1-2.5c-0.7,0-1.5,0-2.5,0
    C849.9,3462.1,850.2,3463.7,850.7,3464.7z M940.4,3269.9c-6-8.2-13.8-6-20.4-7.5C925.6,3265.2,930.4,3273.4,940.4,3269.9z
    M903.4,3488.4c1.9,0.7,6.6-1,7.3-2.5c1-3.2,0-6.7-0.7-13.3c-5.6,3.4-9.7,4.8-11.4,7.3C897.1,3483.3,899.3,3487.4,903.4,3488.4z
    M921.6,3324.7c-1,1.6-1.5,3.2-1.9,4.8c1.5,0,3.2-0.7,4.7-1c0,0,0-1.6-0.7-2.5C923.8,3325,922.2,3324,921.6,3324.7z M916.5,3261.7
    L916.5,3261.7L916.5,3261.7c1,0.7,2.5,1,3.4,1C919,3262,918.1,3261.7,916.5,3261.7z M924.1,3429.8c-1.9-0.7-4.7,1.6-7.3,2.5
    c1,1.9,1.9,5.7,3.4,6c1.9,1,4.7-1,8.8-1.9C927.2,3433.6,925.6,3429.8,924.1,3429.8z M938.5,3413.1c0-3.4,1-7.5-0.7-10.8
    c-4.1-7.5-11.4-9.2-19.5-8.2c1.9,6,3.2,12.6,6.6,18.1C927.2,3414.7,933.8,3413.1,938.5,3413.1z M962.5,3454.6c3.2,1,6,1.9,9.2,3.2
    c-0.7-2.5,0-6-1-7.3C966.6,3447.9,964.4,3451.4,962.5,3454.6z M994.8,3320.9c7.3-4.1,9.7-10.1,5.6-14.2
    c-5.1-5.1-13.2-7.3-14.1-15.9C981.7,3303.2,990.4,3311.4,994.8,3320.9z M957.1,3304c0,0.7,0.7,1.6,1,2.5c1,0,1.9,0,3.2,0
    c-0.7-1.6-1-2.5-1.5-4.1C958.6,3303.2,957.7,3303.2,957.1,3304z M916.5,3261.7L916.5,3261.7
    C915.9,3261.7,916.5,3261.7,916.5,3261.7z M979.7,3267.7L979.7,3267.7c-5.6-4.8-11.4-9.2-18.9-15.9
    C962.7,3265.8,974.4,3262,979.7,3267.7z M960.3,3453.9C959.3,3453.6,958.6,3452.9,960.3,3453.9L960.3,3453.9z M958.6,3161.3
    c0,0-0.7-0.7-0.7-1c0,0.7-0.7,1-0.7,1.6C957.7,3161.8,958.4,3161.8,958.6,3161.3z M985.8,3331.5c-8.8-6-11.6,8.2-19.5,5.7
    c4.1,1.6,8.8,3.4,12.6,4.1C984.8,3341.4,986.6,3337.3,985.8,3331.5z M1012.1,3208.9c-1,2.5-2.5,4.8-4.1,7.3
    c2.5-1.6,5.1-2.5,7.3-4.8c2.5-3.2,5.6-7.3,6.6-11.4C1021.9,3196.6,1015.9,3201.4,1012.1,3208.9z M966.6,3342.9
    c-1.5,6.7-1,11.4,7.5,11.4c0.7-7.3-5.6-11.4-8.2-16.7C966.6,3339.5,966.9,3341.1,966.6,3342.9z M675.3,3104.6
    c-5.6,1-11.4,3.2-16.7,3.2c-4.1,0-8.2-5.7-13.2-2.5c1.5,8.9,3.2,16.7,5.1,26.3c7.3,2.5,14.1,5.1,21.4,7.3
    c11.4-4.8,15.4-16.4,24.5-24.6c3.2,1.6,6,4.1,9.7,5.1c8.2,2.5,9.2,1,8.8-12.6C701.6,3105,688.4,3103.1,675.3,3104.6z M618.6,3229.4
    c1-0.7,2.5-1,4.1-1.6c0-1-0.7-1.6-0.7-2.5c-1,0.7-2.5,1-3.4,1.6C618.3,3227.3,618.6,3228.2,618.6,3229.4z M695,2581.5
    c-3.4-7.3-7.5-10.8-15.4-14.9C679.9,2579.1,686.5,2579.3,695,2581.5z M739.4,2521.6c1,1,1.9,2.5,3.4,3.2c2.5,0.7,4.7,0.7,12.2,0.7
    c-6.6-3.4-8.8-4.8-10.7-5.1C742.9,2519.7,741.2,2520.6,739.4,2521.6z M756,2494.1c-0.7,0-1.5,1-1.9,1.6c1.5,1.9,3.2,4.1,4.7,5.1
    c1.9,0,3.2,0.7,3.4,0c0.3-0.7,1-1.9,0.7-1.9C760.8,2497.2,758.9,2495.6,756,2494.1z M701.6,2685l1-1.9l-7.5-1.6
    C697.5,2682.4,699.8,2684,701.6,2685z M698.2,3042.7c1,1,2.5,1.9,3.4,3.2c0,0,1-1,1.9-1.9c-1-1-2.5-1.9-3.4-3.2
    C699.8,3040.4,698.5,3042.1,698.2,3042.7z M702.3,2805.7c3.4-1.6,6.6-1,9.2,0c0.7,1,1.5,1.9,1.9,3.4l1.5,2.5c0-0.7,0.7-0.7,0.7-1
    c1.5,3.4,1.5,7.5,1.9,12.6c-5.1,2.5-10.7,7.3-12.2,6c-4.1-3.2-7.5-8.2-9.7-12.6C694.1,2812.3,697.2,2807.6,702.3,2805.7z
    M699.1,2817.3c3.2,0.7,5.1,1,7.3,1c3.4,0,5.1-1,7.5-5.7l-2.5,1C707.4,2814.9,703.2,2816.4,699.1,2817.3z M877.9,3419.8
    C877.9,3420.4,877.9,3420.4,877.9,3419.8c7.3,0,14.1,0,20.7-0.7c2.5-4.1,5.6-9.2,8.8-13.3c3.4-4.8,7.3-8.2,11.4-12.3v0.7
    c3.2-2.5,6.6-4.8,7.5-7.5c3.4-11.6,9.7-20.8,22.3-21.5c-4.7-23.4-7.3-25.6-25.5-21.5c-7.3,1.6-12.6,2.5-18.9-3.2
    c-3.2-2.5-9.2-2.5-15.8-4.1c8.2,24.9,12.6,48.7-1.9,71.2c-15.8,1-32.1,1.9-48.4,2.5C850.2,3419.1,864,3418.8,877.9,3419.8z
    M580,2994c5.6,0.7,10,0.7,15.4,1c-1.5-1.9-3.2-4.1-5.1-5.1C586.2,2986.8,582.2,2985.8,580,2994z M862.4,3361.9
    c3.2,3.2,6,6.7,9.7,8.9c4.1,2.5,9.7,3.2,12.2-1.9c3.2-5.1-1-9.2-5.1-12.3c-0.7-0.7-1.5-1-4.1-1.9
    C871.6,3356.2,867.2,3358.8,862.4,3361.9z M848.3,3372.7c-0.7-1.6-1-2.5-1.5-4.1c-1,0.7-2.5,1.6-4.1,2.5c0,0,0.7,1.9,1,1.9
    C845.8,3373.6,847.3,3373.3,848.3,3372.7z M602.2,2972.5c1.5-0.7,3.4-0.7,4.1-1.9c1-1,0.7-3.2,1-5.1c-1.9,0.7-4.1,1-6,1.9
    C601.4,2968.4,602.2,2970.9,602.2,2972.5z M665.4,3025.6c-1.9,7.3-3.4,14.9-5.6,22.2c1.9,1.6,4.1,3.4,6.6,5.7
    c7.3-4.8,13.8-9.2,20.7-13.9C675.8,3026.3,675.8,3026.3,665.4,3025.6z M631.8,3175.1l0.7,1.6l1-1.6H631.8z M612.6,3129.6
    c1,1,2.5,3.4,3.2,3.4c2.5-0.7,4.7-1.9,6.6-3.2c-1-1.6-2.5-2.5-4.1-4.8C616.7,3127.1,614.5,3128.7,612.6,3129.6z M879.4,3309.8
    c0,0-1.9,0-2.5,1c-0.7,1.6,0,3.2,0,4.1c1.5-0.7,3.2-1,4.7-1.6C881,3312.4,880.3,3310.7,879.4,3309.8z M607,2768.6l0.7,1.9
    c1.5,0.7,2.5,0,4.1,0l-1.5-4.1C608.9,2767.1,608,2767.8,607,2768.6z M868.7,2501c-0.7,1.6-0.7,3.4-0.7,5.1c1.5-0.7,3.2-0.7,4.7-0.7
    c0-1.6,0-3.4,0-5.1C871.3,2500.6,869,2500.1,868.7,2501z M689.1,2980.1c-6.6-23.1-6.6-23.1-20.7-25.6
    C679.3,2973.5,679.3,2973.5,689.1,2980.1z M640,3008c1,4.8,1.9,8.2,2.5,11.6c-6.6,0.7-13.2,0.7-19.5,1c0,2.5-0.7,5.1,0.7,6
    c5.1,4.1,11.4,7.5,15.8,12.6c3.4,4.1,5.6,9.2,9.7,16.7c-11.4,1.9-20.4,4.1-31.1,6c1.9,11.4,11.6,15.9,17.3,23.4
    c11.4-2.5,22.3-5.1,34-7.5c-1.9-11.4-13.2-18.3-10-29.7c-9.2-14.9-8.8-16.7,6-23.1c1.5-4.8,3.2-9.2,4.7-13.9
    c1-4.1,1.5-8.9,1.9-13.3C664.6,3007.3,651.7,3005.7,640,3008z M597.3,2993.4c3.2-3.2,7.5-5.1,11.4-2.5c1,0.7,1.9,1.6,3.2,1.9
    c-0.7-0.7-1-0.7-1-1c-0.7-0.7-0.7-1.6-0.7-3.2c0.7,0.7,3.4,4.1,4.7,5.7c-0.7,0-1-0.7-1.5-0.7c18.9,10.8,39.3,5.1,59.1,3.2
    c-5.6,0-6.6-5.1-9.7-8.2c-6-6.7-8.8-16.7-19.5-19c0-0.7,0.7-0.7,0.7-1c0,0-0.7,0-0.7,0.7c-3.4,3.2-7.3,6-10,9.2
    c-2.5-1.9-5.1-3.4-6.6-5.1c-8.8,3.4-16.7,6-23.9,10.1C599.7,2985.8,598.8,2989.9,597.3,2993.4z M680.9,2945.3l-0.7,1.6h1.9
    L680.9,2945.3z M672.7,2601.8c0.7-0.7,1-1,1-1.9c-1,0-1.9-0.7-3.2-0.7c0,0.7,0,1.6,0,1.9C671.2,2601.8,672.1,2602.5,672.7,2601.8z
    M676.8,2547.2c0,0.7,0,1.6,0,1.9c1,0,1.9,0,3.2,0C678.7,2548.1,677.8,2547.7,676.8,2547.2z M718.9,2550.3c1,1.6,1.9,3.4,3.4,4.1
    c1,0.7,2.5-0.7,5.1-1L718.9,2550.3z M651.7,2922.3c0.7,0.7,1,1.6,1.5,1.9c0.7,0.7,1.5,0,2.5,0c-0.7-0.7-1-1.6-1.9-1.9
    C653.9,2921.6,652.9,2921.6,651.7,2922.3z M625.2,2734l1.9,0.7l-0.7-2.5L625.2,2734z M551.3,2972.8c8.2-1.6,8.2-1.9,7.5-10.8
    C551.3,2962.1,553.5,2969.4,551.3,2972.8z M645.7,2734c-1.5,0.7-3.2,1-4.7,1c1,2.5,1.5,4.8,1.9,7.3c1.9-1.6,4.1-3.2,6-4.8
    C648.1,2736.5,647.2,2735.5,645.7,2734z M650.7,2822.1c0-0.7-0.7-1.6-0.7-1.9c-0.7,0.7-1.5,1-1.5,1.6s0.7,1.6,0.7,1.9
    C649.8,2823.1,650.7,2822.4,650.7,2822.1z M791.6,3153c-1.5-1-5.1-0.7-5.1,0.7c-1,2.5-1.5,5.7-0.7,7.5c1,2.5,3.4,4.1,5.1,6
    c1.9-2.5,4.7-5.1,5.6-8.2C797.3,3157.7,793.8,3154.6,791.6,3153z M878.8,2515.8C878.8,2515.8,879.4,2516.5,878.8,2515.8
    C879.4,2516.5,879.4,2516.5,878.8,2515.8C879.4,2515.8,879.4,2515.8,878.8,2515.8z M878.8,2515.8L878.8,2515.8l-0.7,1l0,0
    L878.8,2515.8z M563.3,2987.3c-1.5-0.7-3.2-1-4.1-1.6l-0.7,1.9c1.5,0.7,2.5,1,4.1,1.6L563.3,2987.3z M704.8,2604.9
    c10.7,7.3,18.9,19.6,34,18.1c-13.2-7.5-15.8-28.2-34-29.7C700.1,2596.7,700.7,2602.5,704.8,2604.9z M883.8,2556.9
    c1-1,1.9-3.2,1.5-4.8c0-0.7-3.2-1.9-3.4-1.9c-1,0.7-2.5,2.5-2.5,3.4c-0.7,1.6,0.7,3.2,1.5,4.8
    C881.3,2558.5,882.8,2557.9,883.8,2556.9z M854.9,2828.1c-0.7-0.7-1.5-1-1.9-1c-0.7,0-1,1-1,1.6c0.7,0.7,1.5,1,1.9,1.6
    C854.3,2829.1,854.3,2828.7,854.9,2828.1z M879.4,2516.5l-0.7,0.7C878.8,2517.1,879.4,2517.1,879.4,2516.5L879.4,2516.5z
    M759.3,2492.4v2.5l1.9-1.6L759.3,2492.4z M820.6,2539.5c-0.7-8.2-5.6-12.3-10.7-16.7l-3.2,4.1c0,0-0.7,0-0.7-0.7l-10.7,8.9
    C803,2542.7,811.1,2545.3,820.6,2539.5z M886.4,2470c0.7-0.7,1-1,1-1.6c-0.7-0.7-1-1-1.5-1c-0.7,0.7-1,1-1,1.6
    C885,2469.4,886,2470,886.4,2470z M934.5,3156.2c9.7,5.1,13.8,7.5,18,8.9c1,0.7,3.2-1,4.7-2.5c0,0-0.7,0-0.7-0.7
    c-3.2-1-5.6-2.5-7.3-4.1c0-0.7-0.7-1,0-2.5C946,3155.5,941.4,3155.5,934.5,3156.2z M804,2525.7L804,2525.7
    C803.3,2525.3,803.3,2525.3,804,2525.7z M837.6,2622c1,6.7,1.5,12.3,2.5,18.3s4.1,8.2,8.8,8.9c1.5-3.2,3.2-6,5.1-9.8
    c0.7-1,1-1.9,1.5-3.2c-0.7-1.9-0.7-4.1-1-6C852.4,2624.5,852.4,2624.9,837.6,2622z M861.4,2776c1.9-5.1,4.7-9.8,5.1-14.9
    c0.7-2.5-3.2-7.5-5.6-7.5c-2.5,0-7.5,3.2-8.2,5.1C852.9,2765.2,851.7,2772.5,861.4,2776z M796.7,3247.2c-0.7,0-1,0-1.5-0.7
    c0.7,0,0.7,0.7,1,0.7C796.4,3247.8,796.7,3247.8,796.7,3247.2z M836.9,3410.6c-5.1,1.6-10.7,2.5-15.8,4.1
    C827.5,3417.2,832.5,3415.7,836.9,3410.6z M831.9,3362.9c-0.7-0.7-1.5-1.6-1.5-1.6c-1,1-1.9,1.9-3.2,3.2c0.7,0.7,1,1,1.5,1.6
    C829.4,3364.4,830.3,3363.8,831.9,3362.9z M814.7,3435.3c8.2,6,9.2,14.2,7.5,24.1c3.2-3.4,6-7.3,8.8-10.8
    C828.5,3439.4,822.2,3436.2,814.7,3435.3z M805.5,3173.2c-5.6-1.9-9.7-3.4-13.2-5.1C794.8,3172,797.3,3175.1,805.5,3173.2z
    M791.6,3167.6L791.6,3167.6c-0.7,0-0.7,0-1,0.7L791.6,3167.6z M790.7,3167.8c0.7,0,1,0.7,1.5,0.7c0-0.7-0.7-0.7-0.7-1
    L790.7,3167.8z M739.1,3227c-2.5-3.2-11.6-7.5-13.2,1.9c-1.5,8.2,7.3,8.2,12.2,9.8c5.1,1.6,11.4,1.6,16.7,1.6
    c7.5-0.7,8.8-6,8.2-12.3C755.2,3218.8,747.3,3236.4,739.1,3227z M792.3,3257.9c1.9,2.5,7.5,1.9,11.6,3.2c-2.5-4.8-5.1-8.9-7.5-13.3
    C793.3,3250.9,788.5,3253.8,792.3,3257.9z M801.4,3266.1c1-1.6,1.5-3.2,2.5-4.8l0,0l0,0l0,0l0,0l0,0
    C803.3,3263,802.3,3264.6,801.4,3266.1z M826.9,2781.6c-0.7,1.9,4.1,7.3,6,7.5c8.2,0.7,7.5-6,6.6-11.6
    C834.1,2774.4,828.8,2775,826.9,2781.6z M795.5,3247.2c-0.7-0.7-1.5-0.7-2.5-1l-0.7,0.7C793.3,3246.8,794.1,3246.8,795.5,3247.2z
    M796.7,3247.8H796C796.4,3247.8,796.4,3247.8,796.7,3247.8C796.4,3247.8,796.7,3247.8,796.7,3247.8z M780.3,2673.5
    c0.7-1.6-4.1-5.7-5.6-7.3c-11.4-4.1-18.9-2.5-24.5,5.1c-1.5,1.9-1.9,5.7-1,7.5c0.7,1.9,4.1,5.1,6,4.8c5.6-1,10-3.2,16.3-1.6
    C773.3,2682.7,778.7,2677,780.3,2673.5z M778.7,2950.4c1.5,0.7,3.2,0,4.7,0c-0.7-1.6-1-3.2-1.5-4.1c-1.5,0.7-2.5,1-3.4,1.9
    C777.4,2948.6,778.1,2950.4,778.7,2950.4z M757.6,2919.7c-7.5,3.4-14.8,7.3-22.3,10.8l-18.9-7.3c0-0.7,0-1,0-1.9
    c0.7,0,1.5,0.7,2.5,0.7c0-1,0-2.5,0-3.4c0-1.9,0-4.1,0-6.7c-1,0.7-1.5,1-1.9,1.9c0-0.7,0-1-0.7-1.6c-1-3.4-4.1-6.7-5.1-10.1
    c-1.5-6.7,1.9-13.3,8.8-16.4c6-3.2,11.4-1,15.4,4.1C742,2898.9,749.5,2908.4,757.6,2919.7z M796.3,2907.4c-1.5-0.7-3.4-1-5.1-1.6
    C792.9,2906.5,794.4,2907.1,796.3,2907.4z M805.1,2961.7l-3.4-1.6c0.7,0.7,1,1.6,1.5,1.9C803.6,2962.1,804.2,2961.7,805.1,2961.7z
    M748.5,2655.6c0-1.6-0.7-4.1-1.5-5.1c-0.7-0.7-4.1,0.7-4.1,1c0,2.5,0.7,4.8,1,7.5C746,2657.8,747.8,2657.1,748.5,2655.6z
    M756.7,2884.4c1,0.7,3.4-0.7,5.1-1l-0.7-5.1c-1.9,0.7-4.1,1-6,1.6c0,0.7,0,0.7-0.7,1C755.1,2881.8,755.8,2884,756.7,2884.4z
    M761.8,2893.6l0.7-1.9l-1.9,0.7L761.8,2893.6z M832.5,2850.6c-1,3.2-1.5,6.7-1,10.1c0.7,1.6,4.7,1.6,7.3,2.5
    c0.7-3.2,1.5-6.7,1.5-9.8c0-1.6-1.5-3.2-2.5-4.1C835.4,2849.6,832.5,2849.6,832.5,2850.6z M825.3,2707.4
    c-26.1-10.8-38.1-8.9-51,9.8c4.7,0,8.2,0.7,11.4,0c4.1-1,8.8-1,9.2,3.4c0.7,1.9-4.1,5.1-6.6,7.5c2.5,9.2,11.6,3.4,17.3,7.3
    c5.6,3.4,12.2,5.7,16.7,8.2c12.6-4.8,10.7-13.3,9.7-22.2C826.2,2718.8,825.3,2713.3,825.3,2707.4z M759.6,2866.1
    c-1.5,1.6-4.7,3.4-4.1,4.1c1,1.9,3.2,4.1,5.1,6c1.5-1,2.5-2.5,4.7-4.1C763.4,2870.2,761.8,2868,759.6,2866.1z M825.9,2829.7
    c0-1,0-1.6,0.7-2.5c-1,0-1.9,0-3.2,0c0,1,0,1.6,0.7,2.5C824.4,2829.7,825.3,2829.1,825.9,2829.7z M740,2839.5
    c-6.6-1.6-12.6-3.4-13.2-12.6c5.6-2.5,11.4-5.1,16.7-7.5C750.1,2828.1,745.1,2833.8,740,2839.5z M735.9,2834.7L735.9,2834.7
    c-1,0-1,0-1.5,0C734.7,2834.7,735.3,2834.7,735.9,2834.7z M762.7,3207.4l3.4,1.6l-2.5-1.6l0,0l0,0
    C763.4,3206.7,762.7,3206.7,762.7,3207.4L762.7,3207.4L762.7,3207.4z M759.6,3072.4c0.7-1-0.7-3.4-1.5-4.1c-0.9-0.7-3.4-0.7-5.1,0
    c-1.9,1-3.4,3.2-5.6,5.1c2.5,0.7,4.7,1,7.3,1C756.4,3074.4,759.3,3073.4,759.6,3072.4z M759.6,3161.3c-3.2,5.1-5.6,10.8-9.2,17.4
    C759.3,3174.5,759.3,3174.5,759.6,3161.3z M777.8,3169.1c-6-3.2-12.2-5.1-18.2-7.5c6,5.7,12.6,11.4,18.9,16.7
    C784.8,3173.6,780.9,3170.4,777.8,3169.1z M719.9,2876.7c-7.3,6.7-9.2,13.9-4.1,22.4c1,1.9,0.7,5.1,0.7,8.2c1.9,0.7,4.7,1,4.7,0.7
    c1.5-5.1,1.5-10.8,4.1-15.9c1.9-4.8,6-8.9,11.6-15.5C728.7,2876.7,724.6,2876.7,719.9,2876.7z M827.5,3173.2
    c3.2,2.5,5.1,6.7,7.5,10.1c-0.7,0.7-1,1.6-1.9,1.9c1-0.7,1.5-0.7,2.5-1l0,0l0,0l-0.7-1.6c9.2,10.8,9.7,10.8,20.4,4.1
    c1.9,1.6,4.1,2.5,6,4.1v-0.7c-1.9-1-4.1-2.5-6-3.4l0,0c-11.6-3.2-13.8-5.7-10.7-13.3c1.5-3.2,4.1-5.7,6-8.9
    c-18-16.7-20.4-17.4-39.6-3.2C817.1,3165.9,822.8,3169.1,827.5,3173.2z M730.3,2929.6c0,2.5,0,4.8,0,7.3c4.1-1.6,4.7-1.6,5.6-1.9
    c-1.5-1.9-2.5-4.1-3.4-6C732.2,2928.6,730.3,2928.9,730.3,2929.6z M726.2,3051c1.5,1,3.2,2.5,5.1,3.4c1.5,0,3.4,0,5.6,0
    c2.5-2.5,5.1-5.1,7.5-7.5c11.6-13.3,8.8-20.8-10.7-26.3c-5.6,6,8.8,11.6,1,18.1c-6,4.8-2.5,10.8-3.2,15.9
    C729.6,3052.9,728.1,3051.9,726.2,3051z M814.7,3138.1C815.2,3137.9,815.2,3137.9,814.7,3138.1L814.7,3138.1z M793.8,3168.5
    c-3.4-0.7-7.5,0-10.7,1.6c-1,0.7-1.9,5.7-1,6.7c4.7,4.1,11.4,3.2,16.3-2.5C796.9,3172,796.1,3168.5,793.8,3168.5z M730.1,3213.4
    c12.6-2.5,22.3-4.8,32.8-6c0.7-1.9,1-3.4,1.5-5.7c-0.7-0.7-1-1.9-1.5-1.9c-14.8,1-30.2,1.9-45.3,3.2
    C720.6,3208,725.6,3211.5,730.1,3213.4z M831.9,3187.4c0.7-0.7,1-1,1.5-1.9c-1,0.7-2.5,1-3.4,1.6
    C830.3,3187.4,831.3,3187.4,831.9,3187.4z M1306.2,3533.3c-0.7,0.7-1,1.6-1,1.9c0.7,1.6,1.5,3.2,2.5,4.8c0.7,0.7,2.5-1,4.7-1.9
    C1310.3,3536.4,1308.1,3534.8,1306.2,3533.3z M1225,3152.7c0.7,1.9,1,4.8,1.5,6.7c2.5-0.7,6-1.6,10.7-2.5
    C1233.3,3155.2,1229.2,3154.3,1225,3152.7z M1823.9,3414.1c0.7-0.7,1-1,1.9-1.6c-1-1-1.9-1.9-3.2-3.4c0,0-1,0.7-1.9,1
    C1822,3412.2,1823,3413.1,1823.9,3414.1z M1885.2,3378.3c-1-1.6-1.9-2.5-3.2-3.4c-0.7,0-1.5,0.7-1.9,1c1,1,1.9,2.5,3.2,3.4
    C1883,3379.3,1884.7,3378.6,1885.2,3378.3z M1845.3,3472.5C1845.3,3472.5,1846,3472.9,1845.3,3472.5h-1
    C1845,3472.5,1845.3,3472.5,1845.3,3472.5c-1-3.4-1.9-7.3-3.2-10.8c-0.7,0-0.7,0-1-0.7l0.7-0.7l-0.7,0.7c-0.7,0-0.7,0-1-0.7
    c-4.1,0.7-8.8,1.6-12.6,1.9c-1.9,7.5-4.7,15.5,4.1,20.5c4.7-3.4,6-10.8,13.2-10.8l-0.7-0.7L1845.3,3472.5h-0.7
    c8.2,11.4,9.2,11.6,20.7,6.7C1860.1,3474.1,1853.1,3473.5,1845.3,3472.5z M1818,3347.7c0,0.7,0,1.6,0.7,1.9c0.7,0,1.5,0,1.9,0
    c0-1-0.7-1.9-0.7-3.2C1819.5,3347,1818.6,3347,1818,3347.7z M1845,3491.8c0-0.7-1.5-1.6-1.9-1c-5.1,1-4.7,4.1-2.5,7.3
    C1844.4,3498.1,1846.9,3496.9,1845,3491.8z M1785.3,3150.2v1.9l1.9-1L1785.3,3150.2z M1840.2,3236.1c-1-0.7-2.5-1-3.4-1.9
    c0.7,0.7,1,1.6,1.5,1.9C1838.4,3236.4,1839.4,3236.1,1840.2,3236.1z M1821.2,3212.5c1.5,3.2,4.1,5.7,5.1,9.2
    c2.5,8.2,0,10.8-12.2,12.3l0,0c-1.9,1-4.7,1.9-6.6,3.2v0.7c1.9-1,4.7-2.5,6.6-3.4c10,7.5,10.7,7.5,20.7-1.9l-1,1l0,0l0,0
    c1,0.7,1.5,1,2.5,1c-0.7-0.7-1-1.6-1.5-2.5c2.5-3.2,5.1-7.3,8.8-9.2c5.1-3.4,11.4-6,16.7-9.2
    C1842.4,3197.3,1840,3197.6,1821.2,3212.5z M1809.1,3418.2c-1-1-2.5-1.9-3.4-2.5c-0.7,1.6-1.5,2.5-1.9,4.1c1,0.7,2.5,1,4.1,1
    C1808.2,3420.4,1809.1,3418.2,1809.1,3418.2z M1840.9,3521.5c0,1-0.7,1.6-0.7,2.5l11.4,1
    C1847.9,3524.1,1844.4,3522.5,1840.9,3521.5z M1719.2,3267.1c1.9,4.1-5.1,12.3,5.6,12.6v0.7l0,0l0,0l0,0l0,0h0.7
    c3.4,3.4,7.5,3.2,11.6,1.9l0.7,0.7l0.7-1l-0.7,1l0.7-0.7c-2.5,8.9,3.2,13.9,8.8,19c8.8,0,4.7-15.5,15.8-13.3c5.1,1,9.2-1.9,9.7-7.5
    c-13.8-11.6-13.8-11.6-18.2-26.6c16.3,14.2,16.7,14.2,38.7,12.6c0,3.4,0.7,7.5-1,10.1c-3.2,5.1-8.2,8.9-10.7,13.3
    c-3.2,5.7-1.9,11.4,5.6,13.9c8.2-4.8,16.3-10.8,24.8-13.3c4.7-1,9.2-1.6,13.8-1.9c-0.7-1.6-1.5-3.2-2.5-5.1c4.7,1,8.2,2.5,11.4,4.8
    c2.5,0,5.1,0,7.5,0v-3.4c-8.8-1.9-18-4.1-28.6-6.7c3.2-9.2,5.6-18.1,8.8-28.2c-19.9-1.6-37.1-11.6-57.6-4.1
    c12.2-12.3,26.5-20.8,38.1-34.8c-16.3-4.8-9.7-18.1-11.4-28.7l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c-8.2-3.2-16.3-6-24.5-8.9
    c-1-0.7-3.2,1-5.1,1.9c1.5,6,14.1,5.7,9.7,15.5c-5.6,0.7-11.4,1-14.1,1.6c-10.7,11.4-19.5,20.8-28.6,31.6c0.7,18.1,1,18.3-10,29.7
    C1714.2,3260.1,1716.7,3263,1719.2,3267.1z M1859.8,3189.1L1859.8,3189.1C1859.8,3188.4,1859.8,3188.4,1859.8,3189.1z
    M1790.3,3509.2c0,0.7,1.5,1,1.9,1.6c0.7-1,1-1.9,1.5-3.4c-1,0-1.5,0-2.5,0C1791.6,3507.7,1790.3,3508.9,1790.3,3509.2z
    M1769.5,3464.3c-5.6,1-10.7,3.2-11.4,9.8c0,5.7,5.1,6.7,9.7,7.5C1768.9,3475.4,1768.9,3470,1769.5,3464.3z M1773.6,3481.7
    c-1-0.7-3.2,0-4.7,0.7c0,1.6,0,3.4,1,4.8c1.5,1.6,3.4,3.2,5.6,3.4c0.7,0,3.4-3.2,3.2-4.1
    C1777.7,3484.8,1775.8,3482.6,1773.6,3481.7z M1768,3411.6c1.9,5.7,7.5,5.1,12.2,3.2c4.1-1.9,7.3-5.1,10.7-7.5
    c-4.7-3.2-8.8-6-12.2-8.9c-2.5,1-3.4,1.6-4.7,1.9C1770.2,3402.4,1765.4,3405.8,1768,3411.6z M1800.1,3474.1c-0.7,1-1,2.5-1.5,4.1
    c1,0,1.5,0,2.5,0.7c0.7-1,1-2.5,1.5-4.1C1801.6,3474.1,1800.1,3473.5,1800.1,3474.1z M1722.3,3308.1c9.7,4.1,14.8-3.2,20.7-5.7
    C1736.5,3303.2,1728.9,3300.6,1722.3,3308.1z M1733,3364.4c0,1-0.7,2.5-0.7,2.5c1.5,0.7,3.2,1,4.7,1.6c-0.7-1.6-0.7-3.2-1.5-4.8
    C1734.9,3363.8,1734,3364.4,1733,3364.4z M1781.1,3354.7c0-0.7-1.9-1-1.9-1c-1,1-1.5,1.9-2.5,3.2c1.5,0.7,3.2,1.6,4.7,1.9
    C1780.9,3357.8,1781.1,3356.2,1781.1,3354.7z M1746.6,3302.2L1746.6,3302.2c-1.5,0-2.5,0-3.4,0.7
    C1744,3302.5,1745,3302.5,1746.6,3302.2L1746.6,3302.2z M1746.6,3302.2L1746.6,3302.2C1746.6,3302.2,1747.2,3302.2,1746.6,3302.2z
    M1689,3377.8c0-1.6,0.7-3.4,0.7-5.7c-3.2,5.1-9.7,8.9-9.7,15.9C1688.7,3389.1,1690,3385,1689,3377.8z M1684.6,3489.3
    L1684.6,3489.3C1686.1,3488.4,1685.6,3488.9,1684.6,3489.3z M1701.9,3340.4c0-1-1-1-1.9-1.9c-0.7,1.6-1.5,2.5-1.9,4.1
    c1,0,1.9,0,3.2,0.7C1701.2,3342.1,1702.2,3341.1,1701.9,3340.4z M1683.4,3302.2C1683.4,3301.6,1683.4,3301.6,1683.4,3302.2
    C1683.4,3301.6,1683.4,3302.2,1683.4,3302.2L1683.4,3302.2z M1678.3,3286.7c0.7,5.1,3.2,9.8,5.1,14.9c1.5-6.7,3.2-13.3,4.1-20.5
    C1682.1,3282,1678,3284.8,1678.3,3286.7z M1725.8,3477.6c1.5-0.7,3.2-3.4,4.1-5.7c-2.5-1-4.7-3.4-6.6-3.2c-1.9,0-3.4,3.4-6,6
    C1720.8,3476.6,1723.6,3478.2,1725.8,3477.6z M1711.3,3440.3c-1.9,2.5-1,7.3-1.5,10.1c4.7,0.7,11.4,2.5,12.6,0.7
    c4.1-4.8,6-11.4,8.8-16.7C1723.6,3432.1,1716.7,3433.1,1711.3,3440.3z M1674.9,3324.7c-1.9,8.2-10,9.2-15.8,14.2
    c-4.7,4.1-2.5,10.1,4.1,14.9C1668.6,3344.5,1678,3337,1674.9,3324.7z M1689.7,3372.7c-7.5,1.6-9.2-12.3-18.9-7.5
    c-1.5,5.7,0,9.8,5.6,10.1C1680.8,3375.2,1685.6,3373.6,1689.7,3372.7z M1703.8,3287.6c-8.8,6-14.1,10.1-20.4,14.2l0,0
    C1688.7,3296.8,1699.7,3301.6,1703.8,3287.6z M1660.1,3247.8c-1-2.5-2.5-5.1-3.4-7.5c-3.2-8.2-8.8-13.9-8.8-9.2
    c0.7,4.1,3.2,8.2,5.6,11.6C1655,3245.3,1657.9,3246.3,1660.1,3247.8z M1822.4,3180.2c-2.5,0.7-6,1.6-6.6,1c-4.1-6-8.8-6-14.1-1.9
    c-3.2,1.9-6.6,3.2-9.7,4.8c7.5,3.4,14.8,6.7,22.3,10.1C1818,3187.8,1819.8,3184.3,1822.4,3180.2z M1758.2,3122.4l1.9,0.7l-0.7-1.9
    L1758.2,3122.4z M1720.1,3202.3c4.1-0.7,8.8-3.2,18.9-7.3c-10.7-1-14.8-2.5-18.9-1.9c-1.9,0-4.1,3.2-5.6,5.1
    C1716,3199.7,1718.6,3202.6,1720.1,3202.3z M1743.7,3161.8L1743.7,3161.8C1744,3161.3,1744,3161.3,1743.7,3161.8
    C1744,3161.3,1743.7,3161.8,1743.7,3161.8z M1747.2,3302.2L1747.2,3302.2C1746.6,3302.2,1746.6,3302.2,1747.2,3302.2L1747.2,3302.2
    z M1770.2,3463.3c13.8,0,27.4,1.9,39.6-6c-15.8-2.5-32.1-4.8-47.8-7.3c-12.2-24.1-5.6-46.4,5.1-71.2c-6.6,1-12.6,0.7-15.8,2.5
    c-6.6,5.1-12.6,3.4-19.5,1c-18-5.7-20.4-4.1-27.4,19c12.6,1.9,18,11.6,20.4,23.4c0.7,3.2,4.1,5.7,6.6,8.2v-0.7
    c3.2,4.8,6.6,8.9,9.7,13.3c3.2,4.8,5.1,9.8,7.5,14.2C1755.7,3461.8,1762.9,3462.8,1770.2,3463.3
    C1769.5,3463.7,1770.2,3463.3,1770.2,3463.3z M1822.4,3180.2L1822.4,3180.2L1822.4,3180.2L1822.4,3180.2z M1674.9,3484.8
    c-1.5,0.7-1.5,4.8-1.9,7.3c3.2-0.7,6-1,9.7-1.9C1680.8,3486.2,1679,3482.6,1674.9,3484.8z M1742.5,3520.6c-1-2.5-5.1-4.1-10-8.2
    c-1,6.7-2.5,10.1-1.5,13.3c0.7,1.6,4.7,3.4,6.6,3.4C1742.2,3528.2,1744.7,3524.7,1742.5,3520.6z M1360,3337.8
    c5.1-0.7,10-0.7,18.2-1.6C1367.9,3332.9,1367.2,3332.2,1360,3337.8z M1359.7,3337.8l-0.7,0.7c0.7-0.7,1-0.7,1-1
    C1360,3337.8,1359.7,3337.8,1359.7,3337.8z M1359.1,3337.8L1359.1,3337.8C1359.1,3338.5,1359.1,3338.5,1359.1,3337.8L1359.1,3337.8
    z M1396.8,3314.2C1396.8,3314.2,1396.8,3313.9,1396.8,3314.2C1396.8,3313.9,1396.8,3314.2,1396.8,3314.2L1396.8,3314.2z
    M1298.9,3322.1c0,4.1,0,8.9,0,12.6c9.7-10.1,20.7-0.7,30.2-1c9.2,1.6,21.4-7.5,30.2,3.4c0.7-4.8,1-8.9,1-12.6
    c-16.7-5.1-16.7-5.7-28-0.7c-1,0.7-1.9,1-3.2,1.6c-1-0.7-1.9-1-2.5-1.9C1315.7,3318.3,1315.7,3318.3,1298.9,3322.1z M1297.4,3334.7
    c0.7,0.7,0.7,0.7,1,1c0,0,0-0.7-0.7-1C1298.4,3334.7,1298.1,3334.7,1297.4,3334.7z M1401.9,3316.5c-1.5-0.7-3.2-1.6-4.7-1.9
    c1,1.6,1.5,3.2,2.5,4.1c0.7,0.7,2.5-0.7,4.1-0.7C1403.4,3317.3,1402.7,3316.5,1401.9,3316.5z M1396.8,3313.9L1396.8,3313.9
    L1396.8,3313.9L1396.8,3313.9C1397.1,3313.9,1397.1,3313.9,1396.8,3313.9C1397.1,3313.9,1397.1,3313.9,1396.8,3313.9L1396.8,3313.9
    z M1395.9,3314.8c-2.5,1.6-1,0.7,0.7-0.7C1394.6,3315.5,1392.7,3316.5,1395.9,3314.8z M1437.4,3166.3c18.2,6.7,18.2,6.7,25.5-5.7
    C1453.7,3162.8,1445.5,3164.4,1437.4,3166.3z M1453.4,3148.6c-1.9,0-4.7-0.7-6,1c2.5,0.7,4.7,0.7,7.3,1l0,0c0,0.7,0,1,0,1.6
    c-0.7,0.7-1,1-1.9,1c3.4-0.7,8.2-1.6,14.1-2.5C1458.4,3148.9,1455.9,3148.6,1453.4,3148.6z M1404.4,3110.1c1.9-2.5,3.2-5.7,4.1-9.2
    c0-1-1.9-2.5-3.2-4.1c-1,0-2.5,0-2.5,0.7c-1.9,3.2-3.4,6-5.1,9.8C1400,3108.4,1403.7,3111.3,1404.4,3110.1z M1298.9,3335.7v-0.7
    C1298.4,3334.7,1298.4,3335.4,1298.9,3335.7L1298.9,3335.7z M1332,3460.9c1.5-1,2.5-3.2,2.5-5.1c0-1.6-1-3.2-1.9-4.8
    c-0.7-0.7-1.5-0.7-3.2-0.7c-1.9,1.9-5.1,3.4-5.6,5.7c-1,1.9,0.7,5.1,1,7.5C1327.6,3462.8,1330.2,3462.1,1332,3460.9z
    M1327.9,3382.7l-0.7-1.6l-0.7,0.7l-0.7-0.7l-0.7,1.6l0,0l0,0l0,0h1.5H1327.9L1327.9,3382.7L1327.9,3382.7L1327.9,3382.7z
    M1358.2,3564.5v-1.9l-1.9,1.6L1358.2,3564.5z M1212.5,3148.6C1212.5,3148.6,1212.5,3148.9,1212.5,3148.6c0.7,0.7,0.7,0,0.7,0
    C1212.8,3148.6,1212.5,3148.6,1212.5,3148.6z M1330.5,3541.5c1.5-1,2.5-2.5,3.2-4.1c0-0.7-0.7-1.6-1-1.9c-1.9,1-4.1,2.5-6.6,4.1
    C1327.9,3540.5,1330.2,3541.5,1330.5,3541.5z M1344.3,3510.4c-3.2,0-5.6-1-8.2,0c-2.5,1-4.7,3.2-8.8,6
    C1336.1,3517.4,1341.7,3518.1,1344.3,3510.4z M1305,3508.9c-1.9-1-5.1-0.7-8.2-0.7c1.9,8.2,7.5,7.5,15.8,7.5
    C1309.1,3512.3,1307.4,3509.9,1305,3508.9z M1313.7,3459.6c1.9,1.6,4.7,2.5,6.6,3.4c0.7-2.5,2.5-5.1,1.9-7.5
    c-0.7-2.5-3.4-4.1-5.1-6c-1.5,0-2.5,0-3.2,0.7c-1,1.6-2.5,3.2-2.5,4.8C1311.6,3456.8,1312.2,3458.7,1313.7,3459.6z M1471,3227.3
    c-1,1.9-1.5,4.1-2.5,6.7c1.5,0,4.1,1.6,5.1,1c1.5-1.6,2.5-4.1,3.4-5.7C1475.8,3228.2,1474.2,3228.2,1471,3227.3z M1434,3262.7
    c-0.7,0.7-1,0.7-1.5,1l0,0c0,0.7,0,1.6-0.7,1.9c0.7-0.7,1-1,1-1.6l-0.7-0.7l0.7,0.7C1433.3,3263,1434,3262.7,1434,3262.7z
    M1461,3187.4c-4.7,1-7.5,1.9-10.7,2.5c1.5,0.7,3.2,1,4.7,1.6c-1.9,5.1-6,8.2-10,11.4c-1.9,1.6-4.7,3.4-6,5.7c-3.2-1-6-1.9-9.2-3.2
    c-4.7-1.6-8.8-3.2-13.2-4.8c-0.7,8.9-1,18.1-1.5,27.5c2.5,1,5.1,1.9,7.5,3.4c0.7,0,1-0.7,1.9-0.7h0.7c0,0.7,0,1-0.7,1
    c1.5,0.7,2.5,1.6,4.1,2.5c4.1,3.2,7.3,7.3,13.2,13.3c2.5-10.1,3.2-18.1,6-24.9c2.5-6,7.3-11.4,10.7-16.7
    C1447.1,3198.2,1468.5,3195.6,1461,3187.4z M1430.8,3269.9c0.7-1.6,0.7-3.2,1-4.8c-1,1-1.5,1.9-2.5,3.2
    C1429.2,3268.3,1430.4,3269.3,1430.8,3269.9z M1436.2,3259.5c-1,1-1.5,1.9-1.9,3.2c1.5-0.7,3.2-1.6,4.1-1.9
    C1437.4,3260.1,1436.4,3259.3,1436.2,3259.5z M1392,3417.2c-1-1-2.5-1.9-3.4-2.5l-1.5,4.8c1.5,0,3.2,0.7,4.7,0.7
    C1392,3419.8,1392.7,3417.5,1392,3417.2z M1476.1,3312.9C1476.1,3314.2,1476.6,3315.8,1476.1,3312.9
    C1477.3,3315.5,1476.6,3314.2,1476.1,3312.9z M1211.2,3176.1c-6.6,6.7,12.6,11.4,1.9,18.3c3.2,6,7.3,11.6,9.2,18.1
    c1.9,7.3,1.9,14.9,3.4,25.6c6.6-5.1,10-9.2,14.1-11.6c4.1-2.5,8.8-3.4,13.8-5.1c0.7-11.6,1-23.4,1.5-34.1c-1.9-1.9-3.2-4.8-4.7-4.8
    c-19.5,0.7-21.4,0.7-36.8-5.1c4.1,0,3.2-3.4-6.6-13.3c-0.7-0.7-1-1.6-1.9-1.9l0,0c-0.7,0-1,0-1.9,0c-1.9,0-4.7-0.7-7.5-0.7
    c0-0.7,0-0.7,0-0.7c0-1.9,0-3.4,0.7-4.1c1-1.6,1.9-3.2,3.2-4.8c0.7-4.8,8.8-5.7,19.5-5.1h0.7c1-0.7,1.5-0.7,2.5-1
    c0,0.7,0,1,0.7,1.6c24.5,1.6,58.5,8.9,43.7-6c-4.1-4.1-5.6-7.5-6-11.4c-0.7,0-1,0.7-1.5,0.7c-1.9,0.7-4.1,1.6-5.6,2.5
    c-12.6-4.1-22.3-13.9-32.1-23.4l-2.5-2.5l-3.4,5.7c-6,9.2-11.6,17.4-16.7,25.6l0,0c-0.7,1-0.7,1.9,0,3.2c-1,0-2.5,0-3.4,0
    c-3.2,0-6,0-9.2,0.7l1.5-2.5l-4.1,1.6l-1-4.1l-3.2,5.7h-1v0.7h-0.7c-1,0-1.5,0-2.5,0c-4.1,0-7.3,2.5-9.2,8.2l-2.5,6.7l6-3.2
    c4.1-1.9,7.3-3.4,9.7-5.1c1,6,1,11.6,0,16.7C1192.7,3169.5,1204.3,3174.5,1211.2,3176.1z M1075.3,3142.3c-1.9,6.7-1,12.6,2.5,18.3
    C1078.5,3154.6,1084.2,3147.6,1075.3,3142.3z M1474.2,3312.9c0.7,0,0.7-0.7,1-1c-1,0.7-1.9,0.7-3.2,1
    C1472.6,3313.2,1473.2,3313.2,1474.2,3312.9z M1383.5,3190c-0.7,0-1.5,0-1.9,0c0.7,0.7,1.5,1.6,1.9,1.9
    C1383,3191,1383.5,3190.6,1383.5,3190z M1483.3,3144.8l1.9-1l-1.9-0.7V3144.8z M1501.2,3137.9l0.7,1.9l1.9-1.6L1501.2,3137.9z
    M1475.8,3311.4C1475.8,3311.4,1475.8,3311.7,1475.8,3311.4L1475.8,3311.4c-0.7,0.7-0.7,0.7-0.7,0.7
    C1475.1,3312.4,1475.1,3311.7,1475.8,3311.4c0.7,0.7,0.7,1,0.7,1.6C1475.8,3312.4,1475.8,3312.4,1475.8,3311.4L1475.8,3311.4z
    M1370.4,3419.8l1.9-1.6l-2.5-0.7L1370.4,3419.8z M1373.2,3457.7c0-1,0-1.9-0.7-1.9c-0.7-0.7-1.9,0-2.5,0c0.7,1,1,2.5,1,4.1
    C1371.6,3458.7,1372.3,3458,1373.2,3457.7z M1381.1,3367.9c0.7,0.7,0.7,1.6,1,1.9c0-1,0.7-1.9,0.7-3.2L1381.1,3367.9z
    M1368.2,3445.4l-0.7-1.6l-1.5,1L1368.2,3445.4z M1373.2,3505.7c1.5-0.7,3.2-1.6,4.1-1.9c-0.7-0.7-0.7-0.7-1-1c-0.7,0-1,0-1.5,0
    l0,0l0,0c-0.7,0-1-0.7-1.5-1c-0.7,1-1.5,1.9-1.9,2.5C1371.6,3505.1,1372.3,3506.3,1373.2,3505.7z M1454.7,3133.1l-1.9,1l1.5,0.7
    L1454.7,3133.1z M1432.3,3395.2c4.7,0,9.2-1,13.2-1c0-1,0.7-1.9,0.7-3.2c-5.1-1-9.7-2.5-14.1-3.2c-1,0-3.2,2.5-3.2,3.4
    C1428.9,3393.2,1430.8,3395.2,1432.3,3395.2z M1418.5,3250.4L1418.5,3250.4C1418.5,3250.4,1418.5,3250.9,1418.5,3250.4
    C1418.5,3250.9,1418.5,3250.9,1418.5,3250.4z M1450.6,3318.9c-1.5-0.7-3.2-1.6-4.7-1c-0.7,0-1,2.5-1.9,3.4c1.5,0.7,3.2,1,5.1,1.6
    C1449.6,3320.9,1450.3,3319.9,1450.6,3318.9z M1404.4,3275.3l2.5,0.7l-0.7-1c-0.7,0-0.7-0.7-1-0.7L1404.4,3275.3z M1390.5,3250.4
    c-1.5-1.9-3.2-4.1-4.7-6.7c-0.7-1-1.5-1.9-1.9-3.4c-5.1,0.7-11.6-1-13.2,6.7C1377.3,3250.9,1383.5,3253.8,1390.5,3250.4z
    M1157.7,3236.4l0.7-1.9l-2.5,0.7L1157.7,3236.4z M1191.4,3214.9c1,1.9,1.5,4.8,3.2,6c1,1,3.4-0.7,5.1-0.7c-0.7-2.5-1-4.8-1.9-6.7
    C1194.6,3214.6,1193,3214.9,1191.4,3214.9z M1143,3194.1c-1.5,0-4.1,3.2-3.4,3.4c1,3.2,2.5,6.7,5.1,9.2c1,1,4.7-1.6,6.6-1.9
    C1152.4,3199.2,1147,3193.2,1143,3194.1z M1295.5,3103.4c-1,0-1.9,0-3.2,0c-3.2,0-5.6,0-8.2,1.9l-0.7,0.7c-3.2,1.9-6.6,4.8-9.2,7.3
    c6.6-2.5,15.4-4.1,23.9-3.4c0-1,0-1.9,0-3.4v-3.2L1295.5,3103.4z M1264.4,3231.4c4.1-0.7,8.2-1.6,12.2-2.5l0,0c-4.1,0-8.2,0-12.6,0
    C1264.4,3229.8,1264.4,3230.8,1264.4,3231.4z M1258.1,3266.8l-1,1.9h2.5L1258.1,3266.8z M1295.8,3243.1c-1.9-12.6-18-1.9-19.5-13.9
    c-1.9,4.1-4.1,8.2-6,11.6C1279.2,3249.7,1287.4,3247.2,1295.8,3243.1z M1277,3228.9C1277,3228.9,1276.7,3228.9,1277,3228.9
    C1276.7,3228.9,1276.7,3228.9,1277,3228.9C1276.7,3228.9,1277,3228.9,1277,3228.9z M1232.6,3253.5c-0.7-0.7-1-0.7-1.5-1
    c0.7,0.7,0.7,1,1,1.6C1232.3,3253.8,1232.3,3253.8,1232.6,3253.5l-1,0.7c0.7,0.7,0.7,1,1,1.6
    C1233.3,3255,1233.3,3254.5,1232.6,3253.5L1232.6,3253.5z M1227.2,3249.7c1.5,1,2.5,1.6,4.1,2.5c-0.7-1-1-1.9-1.9-3.2
    C1229.8,3249.4,1228.2,3249.7,1227.2,3249.7z M1254.6,3241.5c-1.5-1-2.5-2.5-4.1-2.5c-1,0-1.9,1.9-3.2,3.2c1,1,2.5,3.2,3.4,2.5
    C1252.7,3244.6,1253.7,3242.7,1254.6,3241.5z M1235.5,3258.6c-0.7-1-1.5-1.9-1.9-3.2c0,1.6,0.7,3.2,0.7,4.8
    C1234.2,3260.1,1235.7,3258.6,1235.5,3258.6z M649.8,3092.3h1.9l-1.5-1.6L649.8,3092.3z M2301.4,3149.5c-1-1-3.4-1-4.7-0.7
    c-1,0.7-1.9,3.2-1.9,4.8c1.5,4.1,3.4,7.5,6,12.3l0.7-0.7v-15.7H2301.4z M2301.4,2972.5c-0.7-0.7-0.7-0.7-1-1c-0.7,3.4-1,6-0.7,8.2
    c0,0.7,1,0.7,1.5,1v-8.2H2301.4z M2300.7-8.5h-1.9l1.5,1.6L2300.7-8.5z M2301.4-41.6c-0.7-1.9-0.7-4.1-1-5.7
    c-7.3-2.5-14.1-5.1-21.4-7.3c-11.4,4.8-15.4,16.4-24.5,24.6c-3.2-1.6-6-4.1-9.7-5.1c-6.6-1.9-8.8-1.6-8.8,6l0,0
    c1,1.6,2.5,3.2,3.4,4.8c0.7,1,1.5,1.9,1.9,2.5c10.7,1,22.1,2.5,33,1c5.6-1,11.4-3.2,16.7-3.2c3.2,0,6,3.2,9.7,3.4v-21.2h0.8
    L2301.4-41.6L2301.4-41.6z M2177.7-382.1L2177.7-382.1L2177.7-382.1L2177.7-382.1z M2177.1-138.4c4.1,6.7,6.6,10.8,10,16.4
    C2188.7-132.3,2188.7-132.3,2177.1-138.4z M1304.3-261.9c0-0.7-0.7-1-0.7-1.6c-1.5-4.8-2.5-8.9-5.1-12.3c0,0-0.7,0-1.9,0
    c-1,0-2.5,0-4.1,0c-0.7,0-1.5,0-1.9,0v0.7c0,1.9-0.7,4.8,0.7,6c2.5,4.8,6,6.7,12.2,6.7C1303.7-261.9,1304.3-261.9,1304.3-261.9z
    M1306.1-298.3c-0.7-1-1-1.9-1-2.5l0,0c-0.7,0-1.9,0.7-3.2,1l0,0c0.7,1,1.5,1.9,1.5,2.5C1304.3-297,1305.3-298.3,1306.1-298.3
    L1306.1-298.3z M951.7,40.2c-0.7-1.9-1-4.1-1.9-5.7c-0.7,0-1,0.7-1.5,0.7h-0.7v0.6c1,1.9,1.5,4.1,2.5,5.7c0,0,0,0,0.7,0
    C950.7,40.5,951.4,40.2,951.7,40.2z M1084.1-62.8c-1-1.9-1.9-4.8-3.4-6c-0.7,0-1.5,0.7-1.9,1c-0.7,0-0.7,0.7-1,0.7
    c0,1.6,1,2.5,1.9,4.1c1,1,1.9,1.9,3.2,1.9l0,0C1083.2-61.9,1083.7-62.1,1084.1-62.8z M1238.8-164.6c-0.7-4.1-3.2-8.2-5.6-12.6
    c-1-1.6-1.5-3.2-2.5-4.1c-1.9,1.6-3.4,3.4-3.4,5.1c0,1,0,1.9,1,3.4c1.5,1.6,3.2,2.5,5.1,4.1
    C1235.4-168.1,1237.6-166.5,1238.8-164.6z M1257.4-214.9c-0.7-0.7-1.5-1-2.5-1c0.7,1.9,1.5,3.4,2.5,5.1
    C1258.4-212.7,1257.7-213.6,1257.4-214.9z M977.8,380.2c-0.7-0.7-1-1-1-1c-0.7,0-0.7,0-1,0c-0.7,0-1.5-0.7-1.9-0.7l0,0
    c-1,0.7-1.9,1-3.2,1.9c0.7,0.7,1,1,1.5,1.6c0.7,0.7,1.5,0.7,2.5,0.7h0.7C975.6,382.5,976.9,381.6,977.8,380.2z M981.9,446.3
    l-0.7-0.7c-0.7,1-1.5,1.9-1.9,3.2C982.3,448.9,985.3,450.2,981.9,446.3z M989.4,455.5c0-1-0.7-2.5-1.5-3.4c-0.7,0-1.5,0.7-2.5,1
    c0,0,0,0-0.7,0c0,0,0,0.7,0.7,0.7c0.7,1,1,2.5,1.5,2.5C987.9,456.2,988.8,455.5,989.4,455.5z M1026.6,447c0-1-2.5-1.9-3.4-2.5l0,0
    c1,1.6,1.9,2.5,3.2,4.1C1026.6,447.6,1027.2,447,1026.6,447z M1055.8,374.9c-1.9-1.9-3.4-3.2-5.6-3.2c-0.7,0-0.7,0-1,0
    c1,3.2,2.5,4.8,3.4,4.8C1053,376.5,1054.2,376.5,1055.8,374.9z M1276.2,237.1c0,1,1,1.6,1.9,1.9l0,0c0,0,0,0,1,0l0,0
    c0-1.9,0-4.8-0.7-6.7l-0.7-0.7c-0.7,0.7-0.7,1-1,1.6C1276.6,234.6,1276.2,236.1,1276.2,237.1z M1240.4,328.1c0.7,0.7,1,1.6,1.5,1.9
    c0.7,0,1-0.7,1.9-1.9c0-0.7-0.7-0.7-0.7-0.7C1242.9,327.8,1242,327.8,1240.4,328.1z M1233.6,352.8c3.4,9.2,6.6,16.4,16.3,19
    c-1.5-8.9-2.5-13.9-5.1-16.4C1242.6,353.4,1239.5,352.8,1233.6,352.8z M1159.6,403.4c0.7,0.7,1,1,1.5,1.6c0-0.7,0.7-1,0.7-1
    C1161.2,404,1160.5,404,1159.6,403.4z M1141.3,350.9c0.7,1,1,1.6,1.5,1.9c0-0.7,0-1,0-1.6C1142.7,350.9,1142.3,350.9,1141.3,350.9z
    M1125.3,387.2c0,0.7,0.7,1.6,0.7,1.6c1.9-1,4.7-1.6,6.6-2.5v-0.7c0,0-0.7-0.7-1-0.7C1130.1,386,1127.9,386.6,1125.3,387.2
    C1126,387.2,1126,387.2,1125.3,387.2z M1093.9,360.1c0.7,1,1.9,1.6,2.5,1.6c3.2,0.7,6,1,9.2,1.6l-12.2-4.8
    C1093.2,358.8,1093.9,359.4,1093.9,360.1z M1100.5,449.5c-1-0.7-1.9-1-3.2-1c-1.9,0-3.4,1.6-5.6,5.1c-2.5,4.8-1,6,3.2,9.2
    L1100.5,449.5z M1163.7,446.3c-1-1-1.5-1.9-2.5-3.4c-3.4-1-7.3-2.5-10.7-3.4c-13.8-4.8-28-9.2-41.8-14.2c-4.1-1.6-7.3-3.4-10.7-6
    c1.9,1.9,4.7,3.4,8.2,5.1c14.1,5.7,28.6,11.4,42.8,16.4C1153.4,442.2,1158.7,444.4,1163.7,446.3z M1179.4,428l-7.3-9.8
    c-1.5-0.7-3.4-1.6-5.1-1.9c-1.5-0.7-2.5-1-4.1-1.6c-3.2,3.2-4.7,4.8-5.1,6c-0.7,1.6,1,4.1,3.2,8.9c1,1.6,1.5,3.4,2.5,5.1
    c0,0,0,0.7,0.7,0.7C1167.8,430.2,1170.3,428.7,1179.4,428z M1187,407.5c-0.7-0.7-1-1-1.9-1.6c-2.5-1.6-4.7-3.4-7.3-4.8
    c-2.5-1.6-4.1-1.9-5.6-1.9c-0.7,0-1.9,0-4.1,3.2c1.9,3.4,4.1,6.7,6,9.8c1,0,1.9,0,3.2,0C1182.6,412.5,1185.3,411.3,1187,407.5z
    M1194.2,495.6c-1.5-1-3.4-1.9-5.6-2.5C1190.1,494.1,1192,495,1194.2,495.6z M1206.4,453c-3.2-2.5-6-4.8-9.2-6c0,3.4,3.2,6.7,8.2,6
    C1205.9,453,1206.4,453,1206.4,453z M1208.1,337.3c-1-1-1.5-1.9-2.5-2.5c-4.1-4.1-8.2-8.2-12.6-12.6c-5.1,1-10,1.9-15.4,3.2
    c-1.5,0.7-3.4,0.7-5.6,1c0.7,1,0.7,2.5,1,3.4c0.7,0,1,0,1.5,0c7.3,0,13.8,1.9,19.9,4.1C1198.9,335.5,1203.3,337,1208.1,337.3z
    M1233.6,442.2c-4.1-1.6-8.2-1.9-12.2-1.9c-4.1,0-8.2,0.7-12.2,1.9c3.2,4.8,6,9.2,7.3,9.2c1,0,4.7-4.8,8.2-8.9l1-1h1.5
    C1228.8,441.9,1231,442.2,1233.6,442.2z M1293,389.1c-1-1-1.9-1.6-3.2-2.5c1.9-1.6,4.7-3.2,6.6-4.8l-1.9-7.3
    c-3.2,2.5-5.6,5.1-8.2,7.3c-2.5-4.8-1.9-10.1,1-19c1-1.9,1.9-4.1,3.4-7.3c0-0.7,0.7-0.7,0.7-1c-3.2-5.7-6.6-11.4-10-17.4l-1-1.9
    h-0.7h-1.5c-1.5,0-2.5,0-3.4,0.7c-1.9,1-4.1,2.5-6,4.1l0,0c1.9,3.2,3.4,6,5.6,8.9c2.5,4.8,5.6,8.9,8.2,13.3l0.7,1l-0.7,1
    c-0.7,1.9-1,3.4-1.5,5.7c-1,4.8-1.9,9.8-3.4,14.2c-0.7,1.9-1,4.1-1.9,6c-1.5,1-2.5,1-2.5,1c0.7,0.7,1,1,1.5,1
    c-2.5,4.8-7.3,8.2-14.1,8.2c-1.5,0-2.5,0-4.1-0.7l0,0c-0.7,0-2.5,1.6-4.1,2.5c-1,1-2.5,1.9-4.1,2.5l-1.9,1l-1.5-1.6
    c-4.7-5.1-9.2-10.1-13.8-15.9c-1.5,1.6-2.5,3.2-3.4,4.1c1.5,6,3.2,11.6,4.7,18.1c0.7,2.5,1.5,5.7,1.9,8.2c0,0,0.7,0,0.7-0.7
    c3.4-2.5,7.3-5.1,10.7-6.7s7.5-2.5,11.4-3.2c1,0,1.5-0.7,2.5-0.7l1-1.9l1.5,1.6l4.1-1l-0.7,1c4.1,0,6-0.7,8.2-4.8
    c1.9-4.1,4.7-8.2,6.6-11.4c0.7-0.7,0.7-1,1-1.6c2.5-1,5.1-1.9,7.5-4.1c0.7,0,0.7,0,1,0C1289.5,388.6,1291.4,389.1,1293,389.1z
    M1296.4,436.2L1296.4,436.2C1296.1,436.2,1296.1,436.2,1296.4,436.2c0,1,0.7,1.9,1,3.2c0.7,0,1,0,1.5,0s1,0,1,0c0-0.7,0-0.7,0-1
    C1299.5,437.2,1297.6,436.9,1296.4,436.2z M1303,264.3c-4.1,2.5-3.4,4.8-1,8.9l0,0C1305.3,268.7,1306.1,267.4,1303,264.3z
    M1866.1,546.2c0,0-0.7-1.6-5.6-3.4c-1,0.7-1.5,1-2.5,1.6c-2.5,1.6-4.7,3.4-5.6,5.1c-0.7,0.7,0,3.2,1.5,6.7l0,0
    c1.5,0,3.4-0.7,4.1-1c2.5-1.6,5.1-4.8,7.3-7.3C1866.1,547.2,1866.1,546.8,1866.1,546.2z M2081.9,824.6c0,0.7,0.7,1,0.7,1.6l0,0
    C2082.4,825.3,2082.4,824.6,2081.9,824.6z M2033.1,794c-1.5-1-3.2-1.9-4.1-3.4c0,0.7,0.7,1.6,0.7,1.9c1,1.6,3.4,3.2,8.2,5.7
    C2036.5,796.6,2035,795.6,2033.1,794z M1992.8,666.9c-4.1,1-8.8,1.9-13.2,3.4c-1,0-1.9,0.7-3.2,0.7l11.6,4.8
    C1992.8,672.7,1993.7,670.1,1992.8,666.9z M2024.8,584.1c-1.9-1-4.1-1.6-6-1.6c-4.7,0-8.2,3.2-12.2,6l-0.7,0.7
    c1,1.6,1.9,1.6,2.5,1.6c1,0,1.9-0.7,3.2-1C2015.8,588.3,2019.2,586.6,2024.8,584.1z M2018.5,728.5c-1,0-1.9,0.7-3.2,1
    c0.7,0.7,1.5,1.6,1.9,1.6c0.7-0.7,1-1,1.5-1.6l0,0C2018.5,728.9,2018.5,728.9,2018.5,728.5z M2048.1,828.7
    c-3.4-4.8-6.6-6.7-12.2-6.7c-0.7,0-1.5,0-2.5,0c-0.7,0-0.7,0-1,0c-4.7,0-8.8-1.6-13.2-3.4c-1-0.7-2.5-1-3.4-1.6
    c-4.7,8.2-8.8,15.9-13.8,24.6c1,0.7,1.5,1,2.5,1c3.2,1.6,5.6,3.2,7.5,3.2h0.7c1,0,1.9-0.7,3.2-0.7
    C2029,843.6,2041.3,842,2048.1,828.7z M2053.8,614.1c0-0.7,0-0.7,0-1c-1-3.2-1.5-5.7-3.2-8.2c-0.7,0-1.5-0.7-2.5-0.7
    c-0.7,0-0.7,0-1,0c-0.7,0.7-1.5,2.5-1,3.4C2046.6,612.2,2049.4,614.1,2053.8,614.1C2053.5,614.1,2053.8,614.1,2053.8,614.1z
    M2116.4,606.3c-0.7,1-1,1.9-1,2.5l0.7,0.7c0.7-0.7,1.5-1.6,1.9-1.9C2117.7,606.6,2117,606.6,2116.4,606.3z M1259.9,416.6
    L1259.9,416.6L1259.9,416.6L1259.9,416.6z M2284.7,57.9c1.9-7.3,3.4-14.9,5.6-22.2c-1.9-1.6-4.1-3.4-6.6-5.7
    c-7.3,4.8-13.8,9.2-20.7,13.9C2274.2,57,2274.2,57.6,2284.7,57.9z M1010.8-398.5c0.7,1.6,1.5,3.2,2.5,5.7c0.7,0,0.7,0,1,0v0.7v-0.7
    l0.7,0.7c3.2-1.6,6-3.2,9.2-4.8c0-0.7,0-1,0.7-1h-13.8v-0.6L1010.8-398.5L1010.8-398.5z M1063.7-380.2c5.1,1.9,10,3.4,15.4,5.7
    C1075.6-380.2,1070.3-382.1,1063.7-380.2z M1061.1-398.5h-4.7c-3.2,3.4-5.6,8.9-11.4,9.2l0.7,0.7l-1-0.7h0.7
    c-3.4-5.1-5.6-7.5-8.2-9.2h-7.3c-1.5,0.7-3.2,1-5.6,1.9c5.6,5.7,12.6,6.7,20.4,7.5v-0.3l1,0.7c-0.7,0-0.7,0-1,0
    c1,3.4,1.9,7.3,3.2,10.8c0.7,0,0.7,0,1,0.7l-0.7,0.7l0.7-0.7c0.7,0,0.7,0,1,0.7c4.1-0.7,8.8-1.6,12.6-1.9
    C1064.3-386.8,1066.5-393.4,1061.1-398.5z M1070.3-262.9c0.7-0.7,1.9-0.7,1.9-1c0-0.7,0-1.6-0.7-1.9c-0.7,0-1.5,0-1.9,0
    C1070-265.4,1070-264.4,1070.3-262.9z M1050.4-152.3c1,0.7,2.5,1,3.4,1.9c-0.7-0.7-1-1.6-1.5-1.9
    C1052.1-152.9,1051.1-152.9,1050.4-152.3z M1030.7-105.2L1030.7-105.2C1030.7-104.8,1030.7-104.8,1030.7-105.2z M1070-129.2
    c-1.5-3.2-4.1-5.7-5.1-9.2c-2.5-8.2,0-10.8,12.2-12.3l0,0c1.9-1,4.7-1.9,6.6-3.2v-0.7c-1.9,1-4.7,2.5-6.6,3.4
    c-10-7.5-10.7-7.5-20.7,1.9l1-1l0,0l0,0c-1-0.7-1.5-1-2.5-1c0.7,0.7,1,1.6,1.5,2.5c-2.5,3.2-5.1,7.3-8.8,9.2
    c-5.1,3.4-11.4,6-16.7,9.2C1047.9-114,1050.4-114,1070-129.2z M1010.2-291.9c-1-1-1.9-2.5-3.2-3.4c-0.7,0-1.5,0.7-1.9,1
    c1,1.6,1.9,2.5,3.2,3.4C1009-290.4,1009.8-291.6,1010.2-291.9z M1014-182l-1.5-0.7v1.6L1014-182z M1039.4-365.6
    c-3.2,1.9-6,3.4-9.7,5.7c-1,1.6-1.5,3.2-2.5,4.8c3.2-0.7,6.6-0.7,9.7-1.6C1039.7-358,1044.5-360.2,1039.4-365.6z M1039.7-310
    c1-1,1.9-2.5,3.2-3.4c-0.7-0.7-1.5-1-1.9-1.6c-1,1.6-1.9,2.5-3.2,4.1C1037.9-311.6,1038.9-310.3,1039.7-310z M1067.4-325.7
    c0,0,1-0.7,1.9-1c-1-1-1.9-1.9-3.2-3.4c-0.7,0.7-1,1-1.9,1.6C1065.2-328.3,1066.5-327.4,1067.4-325.7z M2202-8.2
    c-0.7,6,4.7,7.5,9.2,8.9h0.7c-1-5.7-1.5-10.8-1.9-15.5C2205.7-14.2,2202.5-12.6,2202-8.2z M2252.6,41.2c-1-1-2.5-1.9-3.4-3.2
    c0,0-1,1-1.9,1.9c1,1,2.5,1.9,3.4,3.2C2251.1,42.7,2252,41.8,2252.6,41.2z M2206.6,24.1c0.7,1,0.7,3.4,1.5,3.4
    c1.5,0.7,3.2,0.7,4.7,0.7c-1-1.6-1.9-3.2-3.4-4.1C2208.6,24.1,2207.6,24.8,2206.6,24.1z M2271.5-62.1c3.2,2.5,5.6,4.8,8.8,6.7
    c6-3.4,11.6-7.5,18-11.4C2292.2-73.6,2292.2-73.6,2271.5-62.1z M2246.3-176.3L2246.3-176.3C2246.3-176.3,2246-176.3,2246.3-176.3
    c-3.4,11.4-4.7,21.5,7.5,28.2v-0.7l0.7,0.7l-0.7-0.7l0,0c-3.4-9.2-0.7-20-7.5-28.2C2246.3-176.3,2246.3-176.3,2246.3-176.3z
    M1020-29c0,0,6,0.7,12.6,1c-0.7,3.4,0,6.7-1.5,8.9c-2.5,4.1-5.6,7.5-8.8,11.6c-1.5-1.9-3.2-5.1-3.4-5.1c-5.1,1.6-10,4.1-18,7.5
    c-1.9-10.8-4.1-19.6-6-28.7c3.2-3.4,6-7.3,8.8-10.1C1015.6-44.2,1009-34.1,1020-29z M1009.3-18.3c-1.5-3.2-2.5-3.4-4.7-3.4
    c-1,0-2.5,0-4.7,0.7C1002.7-20.1,1005.8-19.3,1009.3-18.3z M2201-170.6c0.7,0,0.7-2.5,1-4.1c-1.5,0-3.4-1-5.1-0.7
    c-1,0.7-1.9,1.9-3.4,4.1C2196.6-171.2,2199.1-170.6,2201-170.6z M2198.5-58c-2.5-1.6-5.6-3.4-8.8-5.1c-0.7,0-1.9,1.9-5.1,5.1
    c4.1,1.6,7.3,2.5,10,3.2C2195.4-54.6,2196.9-56.5,2198.5-58z M2239.7-184.5c-8.8-8.9-8.8-8.9-21.4-10.8
    C2223.1-189.3,2229.7-186.7,2239.7-184.5z M2232.8-245.8c-1,0-2.5,1.6-4.7,2.5c2.5,1.9,5.1,4.1,7.5,4.8c1,0.7,4.1-1.6,4.7-3.2
    C2241.3-248.7,2235.6-245.5,2232.8-245.8z M2238.2-62.1c2.5-1.9,5.6-3.4,8.2-6c0.7-0.7-1-4.8-1.5-4.8c-3.2,0.7-5.6,1.6-9.7,2.5
    C2236.2-66.9,2237.2-64.4,2238.2-62.1z M2185.9-145c1.5,0,3.2-1.6,4.7-1.9c-0.7-0.7-1-1.9-1.5-1.9c-1.9,0-3.4,1-5.6,1.6
    C2183.7-147.2,2184.7-145,2185.9-145z M2227.1-261.9l1.9-0.7l-1.9-1V-261.9z M1838.4,490C1838.4,490.5,1838.4,490.5,1838.4,490
    c0,0.7,0,1,0.7,1l0,0l0,0l0,0l0,0v-0.7C1838.9,490.5,1838.9,490.5,1838.4,490L1838.4,490z M1818.5,500.1c4.7,1.6,9.2,3.2,14.1,4.8
    c1.9-5.7,3.4-9.8,5.6-13.9c-4.1-4.8-8.2-9.8-13.2-15.5C1822.6,483.3,1821.1,490.9,1818.5,500.1z M1207.4-218.3L1207.4-218.3
    L1207.4-218.3L1207.4-218.3z M1143.9-218.3L1143.9-218.3L1143.9-218.3L1143.9-218.3z M1144.2,325.6
    C1144.2,326.3,1144.2,326.3,1144.2,325.6C1144.2,326.3,1144.2,326.3,1144.2,325.6z M1067.8-96.6L1067.8-96.6L1067.8-96.6
    L1067.8-96.6z M1157.4-280.8c0-1,0.7-2.5,0.7-2.5c-1.5-0.7-3.2-1-4.7-1.6c0.7,1.6,0.7,3.2,1.5,4.8
    C1155.6-280.3,1157.1-280.8,1157.4-280.8z M1122.5-328c-1.9-5.7-7.5-5.1-12.2-3.2c-4.1,1.9-7.3,5.1-10.7,7.5
    c4.7,3.2,8.8,6,12.2,8.9c2.5-1,3.4-1.6,4.7-1.9C1120.3-318.5,1125-322.3,1122.5-328z M1109.6-271.1c0,0.7,1.9,1,1.9,1
    c1-1,1.5-1.9,2.5-3.2c-1.5-0.7-3.2-1.6-4.7-1.9C1109.6-274.3,1109.2-272.6,1109.6-271.1z M1168.7-224.3c-9.7-4.1-14.8,3.2-20.7,5.7
    C1153.9-219.2,1161.5-216.8,1168.7-224.3z M1148-219c-1,0-2.5,0.7-3.4,0.7l0,0l0,0C1145.1-218.3,1146.4-218.3,1148-219z
    M1143.9-218.3L1143.9-218.3L1143.9-218.3z M1081-334.6c1,1,2.5,1.9,3.4,2.5c0.7-1.6,1.5-2.5,1.9-4.1c-1-0.7-2.5-1-4.1-1
    C1082.2-336.5,1081-334.6,1081-334.6z M1160.5-388.4c2.5,1,4.7,3.4,6.6,3.2c1.9,0,3.4-3.4,6-6c-3.4-1.6-6.6-3.4-8.8-2.5
    C1163.1-393.4,1162.1-390.3,1160.5-388.4z M1178.8-356.4c1.9-2.5,1-7.3,1.5-10.1c-4.7-0.7-11.4-2.5-12.6-0.7
    c-4.1,4.8-6,11.4-8.8,16.7C1166.5-348.8,1174.4-349.5,1178.8-356.4z M1120.9-380.5c5.6-1,10.7-3.2,11.4-9.8c0-5.7-5.1-6.7-9.7-7.5
    C1121.8-392.5,1121.3-386.2,1120.9-380.5z M1188.5-256.6c0,1,1,1,1.9,1.9c0.7-1.6,1.5-2.5,1.9-4.1c-1,0-1.9,0-3.2-0.7
    C1189.2-258.1,1188.5-257.2,1188.5-256.6z M1227.3-270.1c-5.6,9.2-14.8,16.7-11.6,29c1.9-8.2,10-9.2,15.8-14.2
    C1236-259.5,1233.9-265.4,1227.3-270.1z M1123.8,215c2.5,0.7,5.1,0.7,7.5,0.7c0.7,0,1-1.6,1.5-1.9c-1.9-1-4.1-1.9-5.6-2.5
    C1125.3,212.4,1124.3,213.7,1123.8,215z M892.6-39.8c-1,0-1.9,0-2.5,0.7c-1.5,1-2.5,2.5-3.4,3.4c1.5,0,3.2,0,4.7-0.7
    C891.6-36.3,892.2-38.2,892.6-39.8z M1133.5,0.7c-5.6-2.5-11.6-5.7-17.3-8.2c0.7,3.4,1,7.5,1.5,11.4
    C1122.8,2.6,1128.4,1.7,1133.5,0.7z M1108.7,12.5c1.9,0,5.6,0.7,6.6-1c1.5-1.6,1.5-4.1,1.5-6.7c-1.9,1-4.7,1.6-6.6,3.2
    C1109.2,8.9,1109.2,10.8,1108.7,12.5z M1131-57.2c-1.9,1-3.4,2.5-5.1,4.8c2.5,1,4.1,2.5,5.6,1.9c1-0.7,2.5-2.5,2.5-4.1
    C1134.2-54.9,1131.6-57.7,1131-57.2z M1139.1,89.2c1,0.7,3.2,2.5,3.4,1.9c1.5-0.7,2.5-2.5,3.2-4.1s0-3.2,0-4.8
    c-1.5,0.7-3.2,1-4.1,1.9C1140.8,86.1,1140.1,88.3,1139.1,89.2z M880-35.7c-1.9,1-3.4,1.9-5.1,3.2C876.8-33.4,878.5-34.7,880-35.7
    c0.7,5.7-3.4,11.4,1.9,16.7c7.5,6.7-7.5,10.8-2.5,17.4c19.9-3.4,23.9-10.8,13.2-24.9c-1.9-3.2-5.1-5.7-7.3-8.9
    C883.4-35.7,881.9-35.7,880-35.7z M1130.1-39.1l0.7,1.9l1.5-1.6L1130.1-39.1z M1133.5,0.7c0-4.8,0.7-9.2,0-13.3c0-0.7-1.5-1-1.9-1
    c-1,0-1.9,1-1.9,1.6C1131-8.2,1132-3.4,1133.5,0.7L1133.5,0.7z M1146.7-78L1146.7-78L1146.7-78L1146.7-78z M1267.5,108.8
    c-0.7-0.7-1.5-1-1.9-1.6c-0.7,0.7-1,1.9-1,1.9c1,1,1.9,1.6,3.2,1.9C1267.5,110.3,1267.5,109.8,1267.5,108.8z M1010.8-137.1
    c0-0.7,0.7-0.7,0.7-1c-0.7,0-1,0-1.5,0.7L1010.8-137.1z M1283.2,121.4L1283.2,121.4L1283.2,121.4L1283.2,121.4z M1242,95.5
    c1.9-2.5,1.5-6.7,1.9-9.8c0-0.7-1.5-1.6-1.9-1.6c-2.5,1-5.6,1.6-6.6,3.4c-1.9,3.2-3.2,6.7-5.6,12.6c1-0.7,1.9-1,3.2-1
    c0-0.7,0-0.7,0-1c0-1,0-2.5,1-3.2c1-1,1.9-1,3.2-1l0,0c0.7,0,1.5,0,1.5,0.7c0.7,0.7,0.7,1.6,0.7,2.5
    C1240.2,96.5,1241.1,95.8,1242,95.5z M1286.4,148v-1c0.7-3.2,1-5.7,1.5-8.9c-2.5-0.7-5.1-1.6-7.5-1.9c-1.5,3.2-4.7,6.7-3.4,9.2
    c0.7,1.9,3.2,4.1,5.6,5.1c1-0.7,1.9-1.6,3.2-1.9L1286.4,148z M1196,278.5c-1.5-1-3.2-3.2-4.1-2.5c-1,0-1.9,1.9-3.2,3.4
    c1,1,2.5,2.5,3.4,2.5C1194.2,281.7,1195.2,280.1,1196,278.5z M1182.6,52.6l0.7-0.7C1182.9,51.9,1182.6,51.9,1182.6,52.6
    C1182.6,51.9,1182.6,52.6,1182.6,52.6z M1224.1,178.6c-0.7,0.7-0.7,0.7-1,0.7h0.7C1224.1,179.9,1224.1,178.6,1224.1,178.6z
    M1223.2,179.9c-6.6-3.2-13.2-6-20.4-8.9c-1.5-0.7-5.6,1.6-6.6,3.4c-1,1.9-0.7,6,1,7.5C1206.4,189.4,1213,189,1223.2,179.9z
    M1162.7,240.6c1.5,1.9,3.4,4.8,5.6,4.8c1.5,0,3.2-3.2,6-6c-4.1-1-6-2.5-8.2-2.5C1164.6,237.1,1162.7,240.6,1162.7,240.6z
    M1190.3,15.8c-5.6-0.7-0.1-5.9-0.1-2.5c0,2.5,0,5.1,0,7.5c3.4-1,7.3-1.9,10.7-3.2c0.7-1.6,20.9-15.1,20.9-16.7
    C1214.7-4.7,988.2-29.9,1190.3,15.8z M1298,13.3v-1.2l-1.5,1L1298,13.3z M1265-20.8c0.7,3.2,3.4,6.7,6,8.2c1.5,1,6.6-0.7,7.3-1.9
    c1-2.5,1.9-6,1.5-8.9c-1,0-1.5,0-1.9,0c-1.5,0-3.2,0-4.1-0.7l0,0l0,0c-0.7,0-1-1.6-1.9-1.9c0.7-0.7,1.5-1,1.9-1.6
    c1.5,0.7,3.4,1,5.6,2.5l0,0c-2.5-3.2-7.5-4.1-11.4-6C1266.5-27.5,1265-23.9,1265-20.8z M1128.4-366.2c12.2,24.1,5.6,46.4-5.1,71.2
    c6.6-1,12.6-0.7,15.8-2.5c6.6-5.1,12.6-3.4,19.5-1c18,5.7,20.4,4.1,27.4-19c-12.6-1.9-18-11.6-20.4-23.4c-0.7-3.2-4.1-5.7-6.6-8.2
    v0.7c-3.2-4.8-6.6-8.9-9.7-13.3c-3.2-4.8-5.1-9.8-7.5-14.2c-6.6-1-13.8-1.6-20.4-2.5c0,0,0,0,0,0.7c-13.8,0-27.4-1.9-39.6,6
    C1097-371.3,1112.8-368.7,1128.4-366.2z M1219.7-281.8c1.5-5.7,0-9.8-5.6-10.1c-4.1-0.7-8.8,1.6-13.2,2.5
    C1209-290.4,1210.6-276.7,1219.7-281.8z M1115.8-398.5c0.7,0,0.7,0.7,1,0.7c1,0.7,3.2,0,4.7-0.7H1115.8z M963.1-41.6
    c1,1,3.4,1.6,5.1,0.7c1-0.7,1.9-3.2,1.5-4.1c-0.7-1.6-2.5-2.5-4.1-2.5c-2.5,0-5.1,0.7-7.3,0.7C959.9-45.4,960.9-43.2,963.1-41.6z
    M1011.5-137.4c4.1-1,8.2-1.9,13.8-3.4C1016.8-144.1,1014-141.2,1011.5-137.4z M1004.2-130.8c-1,1.9-1,5.1-0.7,7.5
    c0,1,3.4,1.6,5.1,1c2.5-1,6-4.1,5.6-5.1c-0.7-3.2-3.2-5.7-4.7-8.9C1008.3-134.9,1005.8-133.3,1004.2-130.8z M1009.8-137.1l0.7,0.7
    c0,0,0,0,0.7,0L1009.8-137.1z M1182.6,52.6c0.7,1.6,0.7,3.2,1.5,4.8c0.7,1,1.9,1.6,3.2,1.9c-0.7-1-1-1.6-1.5-2.5
    C1184.5,55.3,1183.5,53.8,1182.6,52.6z M1201.8-294.5c0,1.6-0.7,3.4-0.7,5.7c3.2-5.1,9.7-8.9,9.7-15.9
    C1201.8-305.9,1200.8-301.1,1201.8-294.5z M1020-227.2c-4.1,3.4,0,7.3,3.2,10.1c3.2-4.1,6-8.2,9.2-12.3
    C1028.2-228.4,1022.4-229.1,1020-227.2z M1032.2-229.1L1032.2-229.1L1032.2-229.1c-0.7-1.9-1.5-3.4-1.9-5.1
    C1030.7-232.5,1031.2-230.6,1032.2-229.1L1032.2-229.1L1032.2-229.1L1032.2-229.1z M1014-212.7c2.5-1,5.6-1.9,8.2-3.2
    c-3.2,0.7-5.6,0.7-8.8,1C1013.4-214.2,1013.4-213.6,1014-212.7z M1014-330.5c7.5,3.2,13.2-1,17.3-9.2c-1.9-3.2-4.7-6.7-7.3-10.1
    c-0.7,0.7-1,1.6-1,1.9c-1.9,3.2-5.1,4.8-8.8,4.8c-2.5,0-5.1-0.7-7.3-1.9c-10,1.9-22.1-1.6-32.8,0.7c-2.5,5.1-4.7,10.1-7.5,14.9
    c-2.5,4.1-6.6,7.3-11.4,12.6c5.6,0,8.8,0.7,11.4,0c3.4-1,7.5-2.5,10-5.1C987.9-333.1,999.5-336.5,1014-330.5z M976.2-292.6
    l-0.7-1.9l-1.9,1.6L976.2-292.6z M1023.4-216.4h-0.7c-0.7,0-0.7,0-1,0.7C1022.4-215.8,1023.1-215.8,1023.4-216.4z M971.2-151.9
    c2.5,7.3,5.1,12.3,7.3,18.1C979.4-146.6,979.4-146.6,971.2-151.9z M998.6-148.8c-6.6,5.1-13.2,9.8-20.4,14.9
    c6-1.9,12.6-3.4,18.9-5.7C1000.8-141.2,1004.2-143.7,998.6-148.8z M1023.4-216.4C1023.1-216.4,1023.1-216.4,1023.4-216.4
    c-0.7-0.7-0.7,0-0.7,0H1023.4z M1087.9-390.9c1,0,1.9,0.7,2.5,0.7c0.7-1,1-2.5,1.5-4.1c-1,0-1.5,0-2.5-0.7
    C1088.8-393.4,1088.2-392.5,1087.9-390.9z M1240.2,65.2c1-1.9,1-4.8,1-7.3c-2.5,0.7-4.7,0.7-6.6,1.6c-5.1,1.9-11.4,3.2-9.7,10.8
    c0,0.7,1.5,1,1.9,1.6C1233.9,75,1237,69.9,1240.2,65.2z M2139.3,470.4c-0.7,0-1.5,0.7-1.9,0.7c0.7,0.7,1,1.6,1.9,1.6
    c0.7,0,1.5-0.7,1.9-0.7C2140.3,471.6,2140,470.4,2139.3,470.4z M1518.5,655.6C1518.5,655.6,1518.5,655.3,1518.5,655.6
    c-0.7-0.7-0.7-0.7,0-1c0,0-0.7,0-0.7,0.7c-2.5-2.5-5.1-5.1-8.2-7.3c-1.9-1-5.6-1-8.8-1.6c-3.4,5.1-7.3,10.1-10.7,14.9
    c5.6-2.5,8.8-4.1,11.6-4.1c2.9,0,5.6,1.6,10,5.1c1.5-2.5,3.4-4.8,5.1-7.3C1518.5,655.6,1518.5,655.6,1518.5,655.6z M1187-12.6
    c-4.7,0.7-10.7-3.2-12.6,4.8C1181.3-3.4,1185.1-6.6,1187-12.6z M1237.6-88.8c-1,0.7-1.9,0.7-2.5,1.6c-0.7,1.6-1,3.2-1,4.8
    c1.5-0.7,3.2-1,4.1-1.9C1238.5-85.3,1238-87.4,1237.6-88.8z M1066.9,308.9c1.5-1,3.2-1.6,4.1-2.5c-1-0.7-1.9-1.6-3.2-2.5
    L1066.9,308.9z M1232.9-82.8C1232.9-82.1,1232.9-82.1,1232.9-82.8c0,0.7,0.7,0.7,1,0.7l0,0l0,0l0,0l0,0c0-0.7,0-0.7,0-1
    C1233.9-82.8,1233.6-82.8,1232.9-82.8z M1214-73.6c8.2-0.7,16.3,1,18.9-8.9C1225.4-83.3,1217.4-83.3,1214-73.6z M1157.1,31.4
    c5.6,3.4,11.4,4.1,18,2.5c6-1,12.2-0.7,18.2-1c-1-3.4-1.9-7.3-3.2-10.8c-6,0-12.2-0.7-18.2-0.7
    C1169.4,28.2,1163.1,30.4,1157.1,31.4z M1070,178.6c-0.7,0-1,0.7-1.5,1.6c0.7,0,1.5,1,1.9,1c0.7,0,1-1,1.9-1.6
    C1071,179.3,1070.3,178.6,1070,178.6z M1065.2,197.6c-1,0-1.9,1.9-2.5,3.4c2.5,0.7,5.1,1.6,7.5,1.9c0.7,0,1.5-2.5,3.2-4.8
    C1070,198.2,1067.4,197.6,1065.2,197.6z M1066.9,309.2L1066.9,309.2C1066.9,308.9,1066.5,308.9,1066.9,309.2
    C1066.5,309.2,1066.9,309.2,1066.9,309.2z M1204.3-34.7c7.3,0.7,12.6,0,18,1.6c5.1,1.6,9.7,7.3,14.8,8.2c3.4,1,8.8-0.7,12.6-1.6
    c-10-19.6-15.8-22.4-40.9-20.5C1207.4-43.9,1206.4-40.4,1204.3-34.7z M1227.3,160.3c0.7,0.7,1,1.6,1,1.6c0.7,0,1-0.7,2.5-1.6
    C1229.5,160.3,1228.1,160,1227.3,160.3z M1086.6,174.8L1086.6,174.8L1086.6,174.8L1086.6,174.8z M1250.8-26.8c-0.7,0-0.7,0-1,0
    c0,0.7,0.7,0.7,0.7,1L1250.8-26.8z M1257.7-6.6c-3.4,1-6.6,1.9-10.7,3.2C1252.7,0,1255.2-4.1,1257.7-6.6z M1193.3,33
    C1193.6,33,1193.6,33,1193.3,33c12.6,7.3,24.5,3.2,35.5-1.6c5.1-1.9,10-4.1,14.8-6c10,14.2-6.6,16.4-9.2,25.6
    c1.9,1.9,4.7,4.1,6.6,6c8.2,3.2,14.8-0.7,22.1-2.5c7.5-2.5,16.3-2.5,23.9-3.4c6-18.1-18.9-23.1-12.2-38.9c-6-1.6-11.6,7.5-16.3-1
    c2.5-3.2,4.7-6,7.3-9.2c-3.2-3.2-5.6-6-8.2-8.9l0,0c-7.3,14.2-19.5,21.5-35.2,22.4c-7.3,0.7-14.8,1.9-22.1,3.2
    C1198.9,23.8,1195.8,28.2,1193.3,33z M1075,349.3l-1.5,1l1.5,0.7V349.3z M1107,192.2c-2.5-1-5.6-2.5-8.2-4.1c-0.7,0-1-0.7-1.5-0.7
    c-4.7,0.7-4.7,3.2,0,10.8C1101.1,196.3,1103.9,194.1,1107,192.2z M1144.2,142c-0.7-1.6-1.5-2.5-2.5-3.4c-1.5,1-3.4,1.6-4.1,3.2
    c-1,3.2-1,6.7-1.5,9.8c1.5,0,3.4,0.7,5.1,0C1145.7,149.6,1146.4,146.1,1144.2,142z M1091.7,256.1c-1,1-1.9,2.5-3.2,3.4
    c1.5,0.7,2.5,0.7,4.1,1c0.7-1.6,1-2.5,1-4.1C1094.4,256.1,1092.2,255.4,1091.7,256.1z M1055.2,355.3c0.7-0.7,1-1.6,1.5-1.9
    c-1-0.7-1.9-1-3.2-1.6c0,1-0.7,1.9-0.7,2.5C1053,354.6,1054.2,354.6,1055.2,355.3z M1049.6,313C1049.6,313,1048.9,313,1049.6,313
    C1048.9,313,1048.9,313,1049.6,313C1048.9,313,1048.9,313,1049.6,313L1049.6,313L1049.6,313z M1113.6,185.6
    c2.5-5.7,9.2-10.1,1.5-16.4c-0.7-0.7-1.9-0.7-2.5,0C1103.9,174.8,1111.2,179.9,1113.6,185.6c0,0.7,0,1,0,1
    C1113.1,185.9,1113.1,185.6,1113.6,185.6z M1259.3,224.5c-0.7,0-1-0.7-1.5-0.7c0,0-1,1.6-1.5,1.9c1.5,0.7,2.5,1,4.1,1.6
    C1260.2,226.4,1259.9,225.4,1259.3,224.5z M689.1-364.6c1-1.9,2.5-4.8,2.5-6.7c0-1-1.9-3.2-3.4-3.4c-3.4-0.7-6.6,4.1-4.7,7.3
    C685-366.2,686.8-365.6,689.1-364.6z M645-327.4c0-0.7,0-1.6,0.7-1.9c-1,0-1.5-0.7-2.5-0.7C643.8-329,644-328.3,645-327.4z
    M634.3-359.5c3.2-5.1,2.5-9.2-3.4-11.4L634.3-359.5z M637.2-291c0,0,0-0.7-0.7-0.7c-1.9,1-4.1,1.9-6,3.4l0,0
    C633.1-288.8,635-290,637.2-291z M764.3-282.2c7.5-1.6,14.1-2.5,20.4-3.4C772.4-293.5,772.4-293.5,764.3-282.2z M787.2-338.7
    c-1.5,1.9-2.5,4.8-3.4,6.7c1.5,0,4.1,1,5.1,0.7c1.9-1.6,2.5-3.4,4.1-6C790.3-338.2,787.8-339,787.2-338.7z M627.7-339.7
    c-6.6,3.4-13.2-1.6-15.8,7.3c5.1,4.1,9.7,7.5,14.1,11.6C626.8-326.4,627.4-331.5,627.7-339.7z M609.5-356.4c1.9,1,4.1,1,6.6,1.6
    c0-1.6,0-3.4-0.7-4.1c-1-1.6-3.4-1.6-5.6-1.9C610.1-359.5,608.9-357.1,609.5-356.4z M625.2-371c1.5,0,3.2-0.7,4.1-1
    c-1.9-1-3.4-1.9-5.6-2.5c-0.7,1-1.5,1.6-1,2.5C622.8-371.3,624.3-370.3,625.2-371z M831.6-280.3L831.6-280.3L831.6-280.3
    L831.6-280.3z M832.1-281.2l-0.7,0.7v0.7L832.1-281.2L832.1-281.2z M831.6-280.3L831.6-280.3L831.6-280.3L831.6-280.3z
    M836.3-333.1v-1.6l-1.9,1L836.3-333.1z M857.9-358c1.5-1,1-5.7,0-7.5c-1-1.9-3.4-4.8-6-4.8c-7.5-1-12.2,3.4-16.3,9.2
    c-4.7,5.7-1.5,10.1,1.5,14.9C847.2-344.1,852-352.3,857.9-358z M847.2-322.3c0,0.7,1.9,0.7,2.5,0.7c1-1,1.5-1.9,1.9-3.4
    c-1.5-0.7-3.2-0.7-4.7-1C847.2-325.2,846.9-323.9,847.2-322.3z M885-307.5c0,1,3.2,3.4,4.1,3.2c2.5-0.7,5.1-2.5,8.2-4.1
    c-1.5-1.6-3.2-3.2-4.1-3.2C890-310.3,884.4-314.4,885-307.5z M899.7-328l-1.9,1l1.9,0.7V-328z M902.9-259.5
    c-13.2,0.7-13.2,0.7-22.3,8.9C890.7-252.1,897.3-253.7,902.9-259.5z M618.6-260.3c-0.7-0.7-0.7-1-1-1.6c-0.7,0.7-1.5,1-1.9,1
    c0.7,0.7,0.7,1.6,1,1.6C617.7-259.5,617.7-259.7,618.6-260.3z M630.3-289.4c0-3.2,0-5.7-0.7-8.9c-0.7,0-1.5,0-1.9,0.7
    C629-295.1,629.3-291.9,630.3-289.4z M634.3-358c-5.6,1-11.4,2.5-16.7,3.4c5.6,5.1,11.4,3.2,17.3,0.7
    C634.3-355.4,634.3-357.1,634.3-358z M672.7-290c-1,0-1.5,0.7-1.9,1c0.7,0.7,1,1.6,1,1.9c1,0,1.5-1,1.9-1
    C673.6-288.5,673.1-290,672.7-290z M616.7-318.5c-7.3,1-9.2,5.1-8.2,11.4c3.2-1.6,6-3.2,8.8-5.1c3.2-1.9,6-5.1,8.8-7.5
    C622.8-319.8,619.6-319.1,616.7-318.5z M603.6-307.5c-1,0-1.5,1.9-1.9,3.4c0.7,0,1.5,0.7,2.5,0c1.5-1,2.5-1.9,4.1-3.2
    C606.3-307.5,604.8-307.8,603.6-307.5z M656.4-393.4L656.4-393.4L656.4-393.4c1.9-1,4.1-1.9,6-3.2L656.4-393.4L656.4-393.4
    L656.4-393.4L656.4-393.4z M694.1-271.4c0.7,0,1.5-1,1.9-1.6c-1-1-1.9-1.6-3.4-2.5l-1.9,1.6C691.9-273.6,693.1-272.1,694.1-271.4z
    M706.7-305.2c-9.7,1.9-10,2.5-19.9,10.8c6-0.7,10.7,0.7,14.8-1C706.7-296.7,707.9-300.8,706.7-305.2z M760.8-392.8
    c1,0,1.5,0.7,2.5,0.7c0.7,0,0.7-1.6,0.7-2.5c-1,0-1.9-0.7-2.5-0.7L760.8-392.8z M651.7-259.5c0.7,0.7,1,1.6,1.5,1.6
    c0.7,0,1.5-1,1.9-1c-0.7-0.7-0.7-1.6-1-1.9C653.9-260.7,652.9-260.3,651.7-259.5z M680.9-350.5c0.7,0,1.5-0.7,1.9-1
    c0,0-0.7-1-0.7-1.6l-2.5,0.7C680.2-351.3,680.9-350.8,680.9-350.5z M658.6-320.8c-1.5,0-2.5,0-3.4,0c0.7,0.7,1,1.6,1,1.6
    C656.4-319.1,656.9-319.8,658.6-320.8z M659.8-307.8c0.7,0,1-1,1.5-1.6c-1,0-2.5,0.7-3.4,0.7C658.6-308.3,659.5-307.8,659.8-307.8z
    M649.1-398.5c2.5,1.6,4.7,3.4,6.6,5.1c0.7-1.9,1-3.4,1.9-5.1H649.1z M860.1-291l-2.5-0.7l0.7,1.9L860.1-291z M601-347.2
    c0-0.7-1-1-1.9-1.6c-0.7,1-1,1.9-1.5,3.2c1,0,1.5,0,1.9,0.7C599.7-345.7,601-346.6,601-347.2z M983-165.6c0-1,0-1.9,0-1.9
    c1.5-0.7,3.2,0,4.7-0.7c0,1,0,2.5,0,2.5C986.2-165.6,984.6-165.6,983-165.6z M996.8,120.4c0.7,0.7,0.7,1,1,1.9
    c-1-1-1.5-1.9-2.5-3.2C995.6,119.5,996.3,120,996.8,120.4z M1011,55c-1-0.7-1.9-1.6-3.2-1.9l1.5-1.9c0.7,0.7,1,1,1.5,1.6L1011,55
    L1011,55z M997.8-173.8c0.7-0.7,1-1,1.5-1.6c0.7,1,1,1.6,1.5,2.5c-0.7,0-1.5,0.7-1.9,0.7C998.1-172.3,997.8-173.1,997.8-173.8z
    M991.5,20.5c6,8.9,5.1,22.4-1,29.7c-1,1.6-3.4,1.6-4.7,3.2c-5.1,4.1-9.2,8.9-13.8,12.6c-6-3.4-5.1-7.5-2.5-10.8
    c3.4-5.1,8.2-10.1,12.6-14.9c-1.5-7.3-2.5-13.3-4.1-22.2C983.4,18.6,989.6,18.1,991.5,20.5z M1016,213.9c1,1.9,0.7,6.7-0.7,7.3
    c-5.1,1.9-9.2-1-11.6-5.1c-1-1.9,0-4.8,0-6.7C1011,206.4,1014.1,210.2,1016,213.9z M996.8,237l-1.5,1.6l-0.7-1.9L996.8,237z
    M1020.1,247.7c-1,0-1.9,0-3.2,0l1.5-1.6C1019.5,246.9,1019.5,247.5,1020.1,247.7z M1028.9,373.2c-1.5,0-3.4,0-5.6,0
    c-1.5,0-3.4,0-5.1,0c2.5-6,5.6-12.3,10.7-17.4c0.7,0,1,0,1,0c4.1,0,6.6,2.5,7.3,6c0.7,3.2-2.5,7.3-3.2,7.5
    C1032.4,372.3,1030.2,373.2,1028.9,373.2z M1008.8,262.4c0,0.7-0.7,1.6-0.7,1.9c-0.7-0.7-1.5-0.7-1.5-1.6c0-0.7,0.7-1.6,0.7-1.9
    C1007.8,261.7,1008.8,261.7,1008.8,262.4z M900.6,299.6c-1.9-5.1-2.5-10.8-4.7-16.4c-1.5-4.1-4.1-8.2-7.5-13.9l0,0
    c22.3-11.6,41.8-24.9,46.2-50.5c10-0.7,18.2-1,27.4-1.6c4.7-7.3,9.7-13.3,12.6-20.5c2.5-6,3.2-13.3,4.7-20.5
    c6.6-1,13.2-2.5,22.3-4.1c-3.2,9.2-3.4,16.4-7.3,21.5c-8.8,13.3-18.2,26.6-28.6,38.9c-6,7.5-12.6,14.9-22.9,17.4
    c-7.3,1.9-11.4,7.5-12.2,14.9c-1.9,10.8,3.2,15.5,15.4,13.3c1.9,0,3.4-0.7,7.3-1c4.1,12.6-3.2,22.2-9.2,31.6V308l0,0l0,0l0,0
    c-1-4.8-1.9-9.2-4.1-13.3c-0.7-1-4.7-0.7-7.3-0.7c0,1.6-0.7,4.1,0.7,5.1c3.2,3.2,7.3,6,10.7,8.9v0.7c-0.7,1-1,1.9-1,1.9
    c-9.2,0.7-18,1-27,0.7C908.5,310.4,903.2,306.9,900.6,299.6z M1015.5-158.1c-1.5-0.7-2.5-2.5-4.1-3.4c1-1,2.5-3.2,3.2-3.2
    c1.9,1,4.1,3.2,6.6,5.1C1018.5-158.3,1017-157.4,1015.5-158.1z M1025.2,92.9c-56.6-7.3-51.8-7.5-72.4-27.2
    c-15.4,5.7-15.4,5.7-9.7,18.3c0.7,1.6,0,3.4,0.7,5.7c-2.5,5.1-11.6,6.7-9.2,16.7c11.6-6,22.1-11.4,35.5-18.3
    c10,13.3,25.5,13.9,39.6,16.4C1017.7,106.5,1022.6,101.4,1025.2,92.9z M1122.7,350.8c-0.7,1-1,1.9-1.5,3.2
    C1121.5,352.7,1122,351.7,1122.7,350.8z M1122.7,344.5c-0.7-1-1-1.6-1.5-2.5c0.7,0,0.7,0,1-0.7
    C1122.7,343.2,1122.7,344.2,1122.7,344.5z M2300.4,826.3c-0.7,0-1,0.7-1,0c-0.7,0-1.5-1-1.9-1.6c1-1,1.5-1.9,2.5-3.2
    c0,0.7,0.7,1,0.7,1.6L2300.4,826.3L2300.4,826.3z M2300.4,599.9c-1,0-1.9,0-3.2,0c0-3.4-0.7-6.7,0-9.8c0,0,1.5-0.7,3.2-0.7V599.9z
    M2296.9,659.3v1.9l-1.5-1L2296.9,659.3z M2300.4,708.3c-0.7,0.7-1,1-1.9,1.6c-1-1-2.5-2.5-1.9-3.4c0.7-1.6,1.9-1.9,3.2-3.4
    c0,0.7,0.7,0.7,0.7,1v4.8l0,0v-0.4L2300.4,708.3L2300.4,708.3z M2294,477.2c-5-0.9-10.8-2-15.4-3.3c0.9-5.7,0.9-11.5,15.4-12.6
    V477.2z M2250.1,743.1c-1.9,2.5-5.1,4.1-8.8,5.1c-0.7,0.7-2.5-3.4-5.1-7.5c5.1-1.9,9.2-3.4,12.6-4.8
    C2249.4,738.7,2251,742.1,2250.1,743.1z M2229.6,882.5c1-1,4.1-1,5.1,0s1.5,4.1,1,4.8c-3.2,3.2-6.6,5.7-10,9.2
    C2222.1,888.6,2226.5,885.3,2229.6,882.5z M2237.7,862.6c-0.7,1-0.7,1.6-1,1.9c-3.2-1-6-2.5-9.2-3.4c0-0.7,0.7-1,0.7-1.6
    L2237.7,862.6z M2185.9,883.5c18.9,7.5,18.9,7.5,24.5,18.3c-9.2-4.1-16.7-6.7-24.5-10.8C2184.9,891,2185.9,886.6,2185.9,883.5z
    M2293.1,804.1c-1.5,0.7-3.2,0.7-5.1,1c0-1.6-0.7-3.4,0-5.1c0.7-1,3.2-0.7,4.7-1L2293.1,804.1z M2205.1,875.8
    c-4.7-3.4-8.8-7.3-12.6-10.1c4.1-5.1,6.6-8.9,9.7-12.3c1.5,5.1,3.4,10.1,5.1,15.9C2207.3,870.2,2206.3,872,2205.1,875.8z
    M2288.7,743.1l-0.7-1.9h1.5L2288.7,743.1z M2265.7,822.2c-0.7,0-1,0.7-1.9,1c-0.7-0.7-1-1-1.5-1c0.7-0.7,1.5-1,1.9-1
    C2264.5,821.2,2265.1,821.5,2265.7,822.2z M2253.5,771c0.7-0.7,1.5-0.7,1.9-0.7c0,1-0.7,2.5-0.7,3.4c-0.7-0.7-1-0.7-1.9-1
    C2253.5,771.8,2253.5,771,2253.5,771z M2266.7,866.7c-0.7,0,0-1.6,0-1.9c0.7,0,1.5,0,1.9,0c0.7,0,0,1,0,1.9
    C2268.3,866.7,2267.3,867.1,2266.7,866.7z M2259.8,605c3.4,1.6,7.3,3.2,10.7,5.1c-4.1-0.7-7.5-1.6-11.6-1.9
    C2258.5,606.6,2259.1,605.7,2259.8,605z M2270.8,610.1L2270.8,610.1c1.9-3.2,3.2-8.2,8.2-3.4c1.5,1.6,1.9,6.7,0.7,7.5
    C2274.9,618,2273.2,612.3,2270.8,610.1z M2281.2,674.2c3.2,1.6,6,3.4,9.2,5.1c0.7,0.7,0.7,1.9,0.7,2.5c-0.7,0.7-1,1-1.9,1.6
    c-4.7,1.6-11.4-1.6-11.4-5.1C2278,676.7,2280.5,674.2,2281.2,674.2z M2226.1,941.9l1.9,0.7l-1.5,1.6L2226.1,941.9z M2202.2,853.1
    L2202.2,853.1v-0.7V853.1z M2209.2,855.3c-1.5,0.7-3.2,1.6-3.4,1c-1-0.7-1.9-1.6-3.2-3.2c1.5,0.7,3.4,0.7,5.1,0.7
    C2208.2,854.4,2208.8,854.6,2209.2,855.3z M2202.2,853.1l-0.7-1C2202.2,852.8,2202.2,852.8,2202.2,853.1
    C2202.5,853.1,2202.2,853.1,2202.2,853.1z M2219.5,795.2c-1,1-1.9,1.6-3.2,2.5l-2.5-3.4c1.5-0.7,3.2-0.7,4.1-0.7
    C2218.9,793.3,2219.9,795,2219.5,795.2z M2207.3,813c2.5,1.9,4.7,5.1,7.3,7.3c-1.9,1.9-3.2,3.2-3.2,3.2c-2.5-1-5.1-1.9-7.5-4.1
    c-1-1-1.5-3.4-1-4.8C2203.5,813.3,2206.6,812.3,2207.3,813z M1112,415.3c-7.3-0.7-14.1-1-22.1-1c1.5-3.4,2.5-7.3,4.1-10.8
    c1.9-6,4.1-12.3,7.5-18.1c3.4-5.7,6.6-11.4,10-16.4c3.4,3.2,8.2,1.6,12.2,0.7c3.4-0.7,7.5-1,11.4-1.6
    C1148.5,395.4,1119.8,399.8,1112,415.3z M1103.8-171.6c-0.7,4.1-1.5,8.2-1.9,11.6c0-4.1,0-9.2,0.7-14.9l1.9,0.7L1103.8-171.6z
    M1041.5-115.4c-7.3-8.9-11.4-13.3-15.4-18.3C1040.9-140,1042.5-139.1,1041.5-115.4z M1145,323c1,0.7,1.5,1.6,2.5,2.5
    c-1.5-0.7-3.2-1-4.7-1C1144.1,324.2,1145,323,1145,323z M1190.3,318.9c0,0.7,0.7,1.9,0,1.9c-0.7,0.7-1.5,0-1.9,0
    c0-0.7-0.7-1.9,0-1.9C1188.8,318.9,1189.6,318.9,1190.3,318.9z M1249.4,320.5c-6.6,14.2-22.1,13.9-34,22.2c-3.4-6-7.3-12.3-10-18.3
    c8.2,0.7,15.4-4.1,21.4-8.9C1233.1,321.8,1240.6,322.7,1249.4,320.5z M1044,235.1c-0.7-0.7-1-1-1.5-1.9c1-1,1.9-1.9,3.2-3.2
    c0.7,0.7,1.5,1.6,1.5,1.9C1046.6,232.9,1045,234.2,1044,235.1z M1057.9-224.4c-0.7,0.7-1,1-1.9,1c0-1,0-1.9,0-3.2
    C1056.9-226,1057.3-225.4,1057.9-224.4z M1076.1-187.8c-0.7,0-1,0-1.5,0l0,0C1075.8-187.8,1076.1-187.8,1076.1-187.8z M1091.6-189
    c-0.7,0-1,0-1.9,0c-4.7-6.7-9.2-13.3-13.8-20.5C1086.8-203.8,1087.7-203.5,1091.6-189z M992.7-258.2c-1-3.4-1.9-7.5,3.2-8.9
    c1.5,0,4.1,4.8,5.1,7.3c0.7,1.9-1,4.8-2.5,8.9C995.3-254.7,993.7-256.3,992.7-258.2z M1074.3-225.4c0,1.6-1,3.2-1.5,4.1
    c-1-1-1.9-2.5-3.2-3.4c1-1,1.9-1.6,3.2-2.5C1072.7-226.9,1074.3-226,1074.3-225.4z M1052.8-55c0.7-1.6,0.7-3.4,1-4.1
    c1-1,3.2-0.7,5.1-0.7c-0.7,1.6,0,3.4-1,4.1C1056.9-55,1054.7-55,1052.8-55z M1048.1-250c1,0,1.9,0,2.5,0c-0.7,1.6-0.7,3.4-1.5,4.1
    c-1,0.7-2.5,0-4.1-0.7C1046.2-247.5,1047.2-249.1,1048.1-250z M1682,978.2c-0.7,0-1.5,0-1.9,0l1-3.2
    C1681.1,976,1681.6,977,1682,978.2z M1561.4,306.9c-3.2,6.7-5.6,12.6-8.8,19.6c-13.2-6.7-28.6-5.1-36.2-18.3
    c8.8,1,16.3-0.7,22.3-7.3C1546.4,304.1,1553.2,307.8,1561.4,306.9z M1657.8,968.5l-0.7,1.9l-1.9-1.6L1657.8,968.5z M1516.8,307.2
    L1516.8,307.2l-2.5,9.2l0,0L1516.8,307.2z M1632.6,871.2c0.7-1.6,0.7-3.4,1-5.1c0.7-1,2.5-1.6,3.4-1.9c-0.7,1-0.7,3.2-1,3.4
    C1635.1,869.5,1633.9,870.2,1632.6,871.2z M1546.4,268.4l-9.7-2.5c0.7-0.7,0.7-1,0.7-1.6C1540.1,265.1,1543.2,266.7,1546.4,268.4
    C1546.6,268.4,1546.6,268.4,1546.4,268.4C1546.6,268.4,1546.6,268.4,1546.4,268.4L1546.4,268.4z M1313,316.4L1313,316.4L1313,316.4
    L1313,316.4L1313,316.4z M53.3,370.7c-14.1,14.2-28,24.9-38.7,32.9c22.3,4.8,61.7,8.9,46.9,24.1c12.6-12.6,37.1-19.6,55.4-17.4
    c-1.5-1-2.5-1.9-4.1-3.2c0-0.7,0-1,0-1.6c0,0,0.7,0,1,0h0.7c0.7,0,0.7,0.7,1,0.7c1.5,1.6,3.2,2.5,3.4,4.1c22.1,3.4,33,20,0.7,52.8
    c-5.6,5.7,59.1-18.3,64.2-23.4c17.3-17.4,87.1-32.3,64.2-9.8c6-6,21.4-16.7,35.5-24.9c3.4,0,5.1-1.6,6-3.4
    c18.9-10.8,32.1-14.2,9.7,7.5c9.2-9.2,20.4-16.4,30.2-22.4c-0.7-0.7-1-1-1-1c1.5-1.9,3.2-3.4,4.7-5.1c0,0,0.7,0,1,0h0.7
    c0.7,1,1,1.6,1,2.5c6-3.4,11.4-7.3,14.8-11.4c-18,18.1,17.3,8.9,48.4-18.1c-1-1.6-2.5-3.2-3.2-5.1c-1-2.5-1.5-4.8-1.9-7.5
    c-0.7-3.2-1.5-6-2.5-8.9c-1-3.2-3.2-6-4.7-9.2c-0.7-1-1-1.6-1.5-2.5c20.4-10.8,39.6-24.6,44.7-49.7c10-0.7,18.2-1,25.5-1.6h1.5
    l0.7-1c1.5-1.9,3.2-4.1,4.1-6c3.2-4.8,6-9.2,8.8-14.2c1.9-4.8,2.5-9.2,3.4-14.2c0.7-1.6,0.7-3.2,1-5.1c3.2-0.7,5.6-1,8.8-1.6
    c2.5-0.7,5.1-1,7.5-1.6c-0.7,1.6-1,3.2-1,4.8c-1,5.1-1.9,8.9-4.1,11.6c-8.8,13.9-18.2,26.6-28,38.9c-6.6,8.2-12.6,14.2-22.1,16.7
    c-7.5,2.5-12.6,8.2-14.1,16.7c-1,5.7-0.7,9.8,1.9,12.6c2.5,3.2,6,4.1,11.4,4.1c1.5,0,3.2,0,5.1-0.7c1,0,1.9-0.7,3.4-0.7
    c0.7,0,0.7,0,1,0c0.7,1.9,0.7,4.8,0.7,6.7c15.8-8.9,28.6-15.9,23.9-10.8c2.5-2.5,4.7-4.8,6-6c0.7,10.1,13.2,11.4,14.1,20
    c-3.4,5.1-8.8,4.1-13.2,3.2c-3.4-0.7-7.5-0.7-11.4-0.7c-10.7,28.2,18.2,30.4,27.4,44.6c9.2-1.6,18.2-3.2,28-3.4
    c1.9,0,6.6,5.1,6.6,7.5c0,4.8-4.7,4.8-8.8,3.4c-2.5-0.7-5.6-0.7-7.5-1c-2.5,3.2-4.7,6-7.3,9.2h-0.9l0,0l0,0h1.5
    c2.5,2.5,5.1,5.7,7.5,8.2c1.9-0.7,5.1-0.7,7.5-1.6c1.5-0.7,3.2-1,4.7-1c0.7-0.7,1.5-1,1.9-1.9l0,0c1-1,1.5-2.5,1.9-3.4
    c0.7-0.7,0.7-1.6,1-1.9c3.4-4.1,5.6-4.8,7.3-4.8c1.9,0,4.1,1,6.6,2.5c0.7,0.7,1,1.6,1.9,2.5c1,1.6,1.9,3.4,4.1,4.1l1,0.7
    c3.4,1.6,7.5,3.2,11.6,3.2c4.7,0,8.2-1.9,11.6-6l0,0c1-1.6,4.7-3.2,6-3.2l0,0c3.4,1.9,7.3,2.5,11.4,2.5c6.6,0,12.6-2.5,18.2-5.1
    c1.5-0.7,3.2-1.6,4.7-1.9c0.7,4.8-1.9,7.3-5.6,10.8c-3.2,3.2-6.6,6-6.6,11.6v1l0.7,1c1.5,1.6,2.5,2.5,4.7,3.4
    c5.1-8.9,9.7-17.4,14.1-26.3l0,0l0,0l0,0l0,0c-8.8-12.6-17.3-25.6-27-37.9c-9.7-12.6-11.6-30.7-26.5-40.5c-1.5-1-1.9-3.2-3.4-4.8
    c-5.6-6-11.4-6.7-18-1c-1.9,1.9-2.5,5.7-5.1,6.7c-6.6,3.4-13.8,7.5-21.4,0.7c-1.9-1.9-7.5-4.1-9.7-3.2c-10.7,7.3-22.1,1.9-32.1-1
    c8.2-8.2,1.9-3.4-1.9-0.7c-0.7,0-1,0-1-0.7c2.5-3.2,9.2-10.1,23.3-24.1C485,302.3,590,227.2,539,278.4
    c-39.3,39.5,10.7,23.1,43.7,10.1l1,0.7v-1c14.1-5.7,25.5-10.8,24.8-9.8c-15.4,15.5,33.6,56.9,59.5,33.8c-1.9-4.8-3.2-9.8-4.1-15.5
    c14.8-9.8,30.6-20.5,45.9-30.7c4.7,3.2,9.2,6,14.1,9.8l3.2,1.9c-0.7,2.5-1,6-1.9,9.8l-0.7,2.5l2.5,0.7c2.5,0.7,4.7,1.6,7.3,1.9
    c0,0.7,0,1-0.7,1.6c-1.5,2.5-8.8,5.7-18.9,5.7l0,0c-5.6,0-10.7-1-15.8-2.5c-2.5-0.7-5.1-1.6-7.5-2.5c-1-0.7-1.9-1-3.4-1.6l-1.5-0.7
    l-1,1c-8.2,7.3-9.2,15.9-9.7,24.1c0,1.9-0.7,4.1-0.7,6c-3.2-3.4-5.1-7.5-7.3-11.6c-14.8,15.5-6.6,21.5,6.6,24.9
    c0.7,0.7,0.7,1,1,1.6c0-1.9,1-4.1,3.2-5.7c1-0.7,1.9-1,3.2-1c0.7,0,1,0,2.5,1.6c1.5,1.9,3.2,4.1,4.7,6.7c18,3.4,34.6,7.5,16.7,25.6
    c-22.3,22.4,28,70.5,30.6,68.6c39.6-39.8-47.5,108.1-16.3,76.8c26.5-26.6,23.3,24.9-1.5,52.8c3.4,1.6,7.3,2.5,10.7,3.4
    c6.6,1.9,12.6,3.4,17.3,7.5c-4.7,0-8.8,1-12.2,3.2c15.8,13.9,36.8,33.1,38.7,31.3c-2.5,2.5,30.6-18.3,43.4-25.6l1.5-6l3.2-1.6
    l-2.5-1.9l1-3.4l-4.1,1.6c-1.5,0-3.4,0-5.6-0.7l-4.7-0.7v3.2l0,0c0,0.7,0,1,0,1.6c-0.7,0-1.5,0-1.9,0c-2.5,0-4.7-0.7-7.3-2.5
    c5.6-5.1,9.7-11.4,13.8-17.4c5.6-9.2,11.4-17.4,22.1-21.5c4.7-1.6,7.3-6,9.7-10.8c0.7-1,1.5-2.5,1.9-3.4c1-1.6,2.5-3.4,3.4-5.1
    c7.3-10.8,13.8-21.5,10.7-18.3c9.7-9.2,35.5-23.4,34.6-22.4c-28.9,29-9.2,35.7,10,31.3c-4.1-1.9-5.1-6-5.1-13.3c0.7,0,0.7,0,1,0.7
    c1.9,1,4.1,1.6,4.7,1.9c2.5,2.5,4.7,5.7,7.3,8.2c4.7-1.9,8.8-4.1,11.4-7.3c22.1-22.2,51-11.6,38.1,12.3c-1,0.7-1.9,1.9-2.5,3.2
    c0.7,0,0.7,0,1-0.7c-1.9,3.2-4.7,6.7-8.2,10.1c-0.7,0.7,4.7,11.4,11.6,23.4l0,0c-1,1-1,4.1-0.7,5.1c0.7,1,3.4,1.9,4.7,1.6
    c3.2,5.7,6.6,10.8,9.7,15.9l-1,1.6c0.7,0,1.5,0,1.9,0.7c8.2,12.3,15.8,22.2,18.9,19c7.3-7.3-0.7,8.9-9.7,27.5
    c-1.5-2.5-2.5-4.8-3.4-6.7c-12.6,1-24.8,1.6-38.1,2.5c-5.1,5.1-11.4,10.8-18.9,18.1c10,6,20.7,14.2,29.6,8.9c7.5-5.1,15.4-6,23.3-6
    c-10,22.4-17.3,41.5,1.9,25.6c0-9.8,3.4-13.3,20.7-13.3l0,0c0,1,0,2.5-0.7,3.2c-6.6,6.7-9.7,14.2-10.7,24.6c0,0,0,0,0,0.7
    c3.4,1.9,7.5,3.2,12.2,4.8c0.7,3.4,0.7,7.5,1,11.4c0.7,4.8,0.7,9.2,0.7,13.9c15.4,0.7,20.4-1,22.1-8.9c1.5-8.9-3.4-11.4-10-13.3
    c22.1,4.1,45.3,5.7,36.8,14.2c28.6-28.7,74.2-29.7,50,6c-1-1-3.2-1.9-4.1-3.4c-1,1.9-1.9,4.1-3.4,7.3c1.5,0.7,3.2,1,4.1,1.6
    c-3.2,4.1-7.3,8.2-11.6,12.6c-5.1,5.1,9.7,1.9,26.1-1.9c0,0,0,0,0,0.7c0.7,0,1.5,0,1.9,0c0,0,0-0.7,0-1c16.7-4.1,34.6-9.2,32.8-7.5
    c6-6,14.8-10.8,23.3-13.3c-0.7,0.7-1,1-1.5,1.9c1,0.7,3.2,1.6,3.4,1c1.5-1,3.2-3.2,4.7-4.8c25.5-5.1,50,4.1,28.9,25.6
    c-45.9,46.1,84.9-30.4,42.8,12.3c-37.1,37.2,22.1,49,46.2,24.1c0,0,16.3,4.1,27.4,11.6c-0.7,1-1,1.9-1,3.2
    c-1.5,4.8,1.5,11.4,1.5,16.7v0.7c3.2-1.6,5.6-3.2,8.8-4.8c-0.7,2.5-1.9,4.1-4.7,6c-1.9,1.6-3.4,3.2-5.1,5.7l-0.7,0.7v0.7
    c0,0.7,0,1,0,1.9c0,1.6,0,3.2,0.7,4.8l0,0c1,1.9,1.5,3.4,1.9,5.1c3.2-3.2,5.6-6,7.5-9.2c5.1,0.7,13.2,1.9,21.4,4.8
    c0.7,1,0.7,1.9,1,3.2c0.7-0.7,1.5-1.6,1.9-1.9c17.3,6,31.1,18.1,6,43c-1.5,1.6,1.9,4.1,7.3,7.5l-0.7,0.7c-1,2.5-0.7,5.7-0.7,8.2
    c1.5,1,3.2,2.5,4.7,3.2c0.7,0,1.9-0.7,2.5-1c0.7-1.9,1-3.4,1.5-5.7c5.6,4.1,11.4,8.9,14.1,14.2c0.7-1,0.7-1.6,1-2.5
    c1-3.4,2.5-6.7,3.4-10.1c1,0.7,1.9,1.6,3.2,1.9c3.2,1.9,6,4.8,10,7.3c-2.5,1-4.7,1.9-6.6,3.2c-3.2,1.6-6,2.5-9.2,4.1
    c1.9,6.7,1,13.9-7.3,22.2c-38.7,38.9,6.6,28.2,28.9,28.2c-1.5-16.4-0.7-42.3,2.5-45.6c7.3-7.3-3.4-81.3-12.2-72.7
    c-8.2,8.2-11.4,0.7-13.2-12.6c-5.1,1.9-10,3.4-15.8,3.4c-5.1,0-10-1.6-14.8-4.8c-2.5-1.6-5.1-1.9-8.2-2.5c-0.7,0-1,0-1.5-0.7
    c7.5-9.8,16.7-12.6,26.1-15.9c2.5-1,4.7-3.2,6-5.1c1.5-1.6,2.5-3.2,4.1-3.2c0.7,0,1,0,1.5,0.7c-2.5-21.5-6.6-39.8-22.9-23.4
    c-1.9,1.9-5.6-4.1-9.2-13.3c-0.7,0-0.7,0-1-0.7c0,0-0.7,0-0.7-0.7c0,0,0,0,0.7-0.7l0.7-0.7c-4.1-9.8-8.8-23.1-13.2-32.9
    c3.2-1,5.6-1.9,7.5-3.4c4.7-4.1,6-11.4,9.2-17.4c-15.4-18.3-5.6-35.7,1.9-52.8c-14.8,9.8-27,22.2-30.6,41.1
    c-8.2,0.7-14.8,0.7-20.7,1c-1.9,6.7-6,11.6-12.2,15.5c-1.5,0.7-2.5,1-4.1,1.6c-1,1.6-1.9,3.4-2.5,5.7c-1,3.2-1.9,6-2.5,9.2
    c1,1,1.9,1.9,3.4,2.5c7.5-1.9,18.2-4.8,28.9-7.3l0,0c-10.7,6-22.9,8.9-33.6,7.5c0,0.7,0,1,0,1.6c-0.7,1.6-0.7,3.2-1,5.1
    c-3.2,0.7-5.6,1-8.8,1.6c-2.5,0.7-4.7,1-7.5,1.6c0.7-1.6,1-3.2,1-4.8c0.7-3.2,1.5-5.7,1.9-7.5c-1.9-1-4.1-1.6-6-3.2
    c0.7-3.4,0.7-7.5,1-12.6c3.4,2.5,6.6,5.1,9.7,7.5c1.5-2.5,3.2-4.8,4.7-7.3c-5.1-1.6-9.2-5.1-12.6-11.4c-1-1.6-3.2-1.6-4.7-2.5
    c-1.5,1.9-3.2,4.1-4.1,5.1c0,0,0,0,0,0.7l0,0c-1.5,1.9-3.2,3.4-4.7,5.1c-0.7-1.9-0.7-4.1,0-6c-0.7-0.7-1.5-1.6-1.9-3.2
    c-0.7-0.7,0-2.5,0-3.4c0.7,0,1-0.7,1.5-0.7c0.6,0,1.5,0,1.9,0.7c1.9-6,6.6-12.3,13.8-19.6c-14.1,14.2-101.9,44.9-35.5-21.5
    c-11.6,11.6-32.8,20-43.7,20.8c0,0.7-0.7,1-0.7,1c0-0.7,0-1,0-1c-7.3,0-9.7-3.2-1-11.6c-10,10.1,8.8-111-43.4-58.7
    c10.7-10.8,10-17.4,16.7-23.4c-11.4,11.4-6.6-1.9,0.7-19c-4.1,1-8.2,2.5-12.2,4.1c-1.5,0.7-3.2,1.6-4.7,1.9
    c-0.7-4.8,1.9-7.3,5.6-10.8c3.2-3.2,6.6-6,6.6-11.6v-1l-0.7-0.7c-2.5-3.2-5.1-4.1-8.8-4.1c-1.9,0-4.1,0.7-6,1c-0.7,0-1,0-1.5,0.7
    c-1.9,0.7-4.7,0.7-7.3,1c-0.7,0-1.5,0-1.9,0c-6.6-14.9,1.5-21.5,11.4-29c4.7-3.4,9.2-7.3,12.2-12.3c1.9,0,4.1,0,5.6,0.7
    c3.4,0,7.5,0.7,11.4,0.7c0-5.7-1-11.4-1.5-16.4c-1.5-1.6-3.2-3.2-4.7-4.8c1.5-1.6,2.5-3.4,4.1-5.1c0.7-3.4,1-6,3.2-8.2
    c-1.9,1.9-50-1-44.3-24.1c-4.1-4.8-6-10.1-3.2-20c0.7,0,1.5,0,1.9,0c2.5,0,4.7,0,7.3,0.7c0.7,0,1,0,1.5,0c1.5,0.7,3.2,0.7,5.1,0.7
    h0.7c3.2-4.1,4.1-9.2,4.7-15.5c-1-1-1.9-1.9-3.2-2.5c-3.4-3.2-6.6-5.1-6.6-10.1c1.5,0.7,3.2,1,4.7,1.6c1.5,0.7,3.2,1,4.7,1.6
    c-1.5-18.1-10.7-34.8-18.9-26.3c3.2-3.2-68.3-1.6-47.8-22.4c28.9-29-72.4,1.6-47.5-23.4c-33,33.1-28-31.6-65.7,6
    c4.7-4.8-5.1-8.9-18.2-11.4c-4.1-8.9-5.6-9.2-23.3-16.4c0,3.2-1,7.3,0,7.5c5.1,2.5,10.7,5.1,16.7,7.3c-17.3-2.5-37.1-3.2-39.6-1
    c8.8-8.9-15.4-54.3-66.1-3.2c18.9-19-46.2-46.4-53.5-39.5c-1,1-1.5,1.6-2.5,2.5c1.9,0.7,4.7,1.6,7.3,2.5c-1,1.6-1.9,2.5-3.2,3.4
    c-3.4,4.8-7.3,8.9-7.3,16.4c0,10.8-3.2,14.2-20.7,14.2l0,0c0-1,0-2.5,0.7-3.2c6.6-6.7,9.7-14.2,10.7-24.6l0,0
    c-32.1,17.4-56.6-12.6-45.3-23.4c-4.1,4.1-11.6-67.6-50-31.3c-0.7-0.7-1-0.7-1.5-1c-1-1.9-4.1-4.1-8.8-6.7
    c-7.5-6.7-13.8-15.5-16.7-27.2c-10,0.7-18.2,1-27.4,1.6c-5.1-6.7-10.7-12.3-14.1-19c-3.2-5.7-4.1-12.6-6.6-20
    c-7.3-0.7-13.2-1-22.9-1.9c4.1,8.9,5.1,15.9,9.2,20.5c10,12.6,20.7,24.6,32.1,36.4c1.9,1.9,4.1,4.1,6.6,6c-3.4,1.6-6.6,3.4-9.7,6.7
    c-5.6,5.7-71.4-5.1-57.6-18.3c19.5-19.6-16.7-37.2-52.5-1.6c38.7-38.9-67.3-37-72.4-31.3c1-1,1-1.6,1-1.9c-1.5,1-3.2,1.6-5.1,2.5
    c0.7-1.6,1-3.2,2.5-4.1c-4.7-1.6-14.8-1.6-26.1-1.6c-6.6,6.7-14.8,9.2-22.3,11.4c-2.5,1-4.7,3.2-6,5.1c-1.5,1.6-2.5,3.2-4.1,3.2
    c-0.7,0-1,0-1.5-0.7c-4.1-1.9-8.8-6.7-12.6-12.6c-1.5-1.9-1.9-4.1-1.5-6c0.7-1.6,1.9-3.4,4.1-4.8c-10-4.1-13.2-11.4,1-25.6
    c-27.4,27.5-58.1-33.8-93.1,1.6c8.8-8.9,4.7-5.7-4.7,1.9c7.5-1,10.7,5.1,6,16.4c-13.2-1-25.5,5.1-38.7,11.4
    c10.7-9.2,23.9-20.5,32.8-27.5c-7.3,1-18.9,8.2-33,22.4c-31.4,31.6-19.9,22.4-0.7,6c1,4.8,1.5,7.3,2.5,11.4c7.3-1,14.8-1,21.4-4.1
    c1.9-1,4.1-1.6,6-1.9c-7.3,10.8-18,24.1-33,39.5c-0.7,0.7,1,2.5,3.4,6c0.7,0,1,0.7,1.9,0.7c5.1,2.5,10,4.8,15.4,7.3
    c4.7,1.9,8.8,4.1,12.6,6l1.5,1l1.5-1c3.4-3.4,6-6,8.8-8.2h2.2c0.7,0,0.7,0,0.7,0l0,0c4.1,1.9,8.2,3.4,12.6,5.7
    c1.5,0.7,2.5,1,4.1,1.9c0,0.7,0,1-0.7,1.9c0,1-0.7,2.5-0.7,3.2c-5.6,3.4-9.7,7.3-11.6,11.6c11.4,4.1,20.4,29-4.1,53.8
    c9.7-9.8,14.1-4.8,12.2,6.7c-9.2-3.2-18.2-6.7-27.4-8.9c-3.4-0.7-11.4,3.2-11.6,5.7c-2.5,12.6-11.4,16.7-22.1,20l0,0
    c1.5,1,2.5,1.9,3.4,3.2c1.5,1.6,1.9,2.5,2.5,4.8c3.4,0.7,7.3,1,11.6,1.9c-6.6,3.4-11.4,7.3-16.3,8.9c-1,0.7-1.5,1-2.5,1
    c-0.7,0.7-1.5,0.7-1.9,1c-0.7-0.7-0.7-1.6-1-1.9c-1-0.7-2.5-0.7-3.4-1.6c5.1,6.7,9.7,13.3,14.8,20c-16.3,7.3-33.6,15.5-34.6,15.9
    c-3.2,3.2-6,6-10,8.9c-1.5-3.4-1-8.2,1.5-12.6c5.6-10.8,12.2-21.5,18.2-32.3c-8.8,5.7-15.4,0.7-26.5-4.8c9.7-2.5,15.8-4.1,22.1-6
    c-2.5-2.5-3.4-4.8-5.1-5.1c-10.7-1.9-19.9-5.7-23.3-18.1c-1-2.5-8.8-5.7-12.2-4.8c-9.7,3.2-18.2,7.5-27.4,11.6l3.4,9.8l3.2,7.5l0,0
    l0,0l0,0c-2.5,0.7-5.1,1-7.3,1c0-0.7,0-0.7,0-0.7l7.3-0.7l0,0c-7.3-2.5-14.1-6-22.9,3.4c17.3,9.8,21.4,31.6,42.8,37.2
    c4.7,1,8.2,7.5,11.6,11.6c1.9,1.9,3.4,4.8,5.6,6.7c-35.2,23.4-83.6,36.4-51,3.4C95.8,410.9,95.1,329.4,53.3,370.7z M1663.8,903.1
    c0.7,0,0,1.6,0,1.9c-0.7,0-1.5,0.7-1.9,0c-0.7,0,0-1.6,0-1.9C1662.2,903.1,1663.1,903.1,1663.8,903.1z M1480.6,203
    c1.5,9.2,5.6,15.9,12.2,22.2c1.5,1.6,1.5,4.8,1.9,7.5c-5.1,0.7-9.7,1-13.2,0.7c0.7-0.7,1-1,1.5-1.6c0-0.7,0-1-0.7-1.6h-0.3
    c-0.7,0-0.7,0-1,0c-0.7,0.7-0.7,0.7-1,0.7c-0.7,0.7-1.5,1-1.9,1.9c-8.2-1-11.4-5.1-12.2-14.9c-0.9-9.8-7.5-12.3-13.2-20
    C1468.9,188.1,1478.1,189.7,1480.6,203z M1476.9,270.9c1,0.7,1.9,1,3.2,1.9C1479,273.3,1476.9,275.6,1476.9,270.9z M1345.9,292.1
    c3.4,12.3,12.6,15.9,23.3,18.1c1.5,0.7,2.5,2.5,5.1,5.1c-6.6,1.9-12.2,3.4-22.1,6c11.4,5.1,18,10.1,26.5,4.8
    c-6,10.8-12.6,20.8-18.2,32.3c-4.1,7.5-4.1,14.9,6,19c7.5,3.2,7.3,10.8,7.3,20.5c-5.1-1.9-8.8-2.5-10.7-5.1
    c-6-7.3-12.2-15.5-18.2-22.4c-3.4-4.8-7.3-10.8-11.6-11.6c-21.4-5.7-26.1-27.5-42.8-37.2c9.2-9.8,15.8-6,22.9-3.4l0,0l-7.3,0.7v0.7
    c2.5-0.7,5.1-1,7.3-1l0,0l0,0l0,0l-3.2-7.5l-3.4-9.8c9.2-4.1,18-8.9,27.4-11.6C1336.9,286.3,1345.1,289.5,1345.9,292.1z
    M1282.4,282.5c1-0.7,1.9-1.6,3.2-1.9C1285.9,284.8,1284.1,283.2,1282.4,282.5z M1430.3,282.5c9.7,1.9,18.9,5.7,28.6,8.9l-2.5,9.8
    l-1.9,8.2l0,0l0,0l0,0c2.5,0,5.1,0.7,7.3,0.7c0-0.7,0-0.7,0-0.7h-7.3l0,0c6.6-3.2,13.8-7.3,23.3,1.6
    c-16.3,11.4-18.2,33.8-39.3,41.5c-4.7,1.6-7.5,8.2-10.7,12.6c-5.6,8.2-10,16.4-15.8,24.1c-1.9,1.9-5.6,3.2-10,5.7
    c-1-9.8-1.9-16.7,5.1-20.8c9.2-5.1,8.8-12.3,4.1-19.6c-6.6-10.1-14.1-20-21.4-30.4c9.2,4.8,15.4-1,26.1-7.3
    c-10-1.6-15.8-2.5-22.3-4.1c1.9-2.5,3.2-5.1,4.7-5.7c10.7-3.2,19.5-7.3,22.1-20C1418.3,285.8,1426.5,282.2,1430.3,282.5z
    M1249.8,320.5l3.4,9.2l0,0L1249.8,320.5C1249.4,320.5,1249.8,320.5,1249.8,320.5z M1444.5,261.7c0,1.6-1.5,2.5-2.5,4.1
    c-0.7-1.6-1.5-3.2-1.9-4.8c0-0.7,1-1.6,1.5-1.9C1442.3,260.2,1444.5,261,1444.5,261.7z M1362.1,244.3c-7.3,4.8-13.2,10.8-20.7,17.4
    c9.2,10.1,19.9,18.3,27,29.7c1,1.6,6,0.7,10,0.7c-3.2-9.2-5.1-15.9-6.6-21.5c3.2-2.5,6-4.8,8.8-6.7c2.5,1.6,5.6,3.4,9.2,5.7
    c-1,5.7-2.5,12.6-4.7,22.2c4.1-0.7,9.2,0,10-1.6c6-11.6,15.8-20.8,23.9-32.3c-8.2-5.7-14.8-11.4-22.3-15.5c-6-3.4-12.2-4.1-18-1.6
    C1374,238.7,1368,240.2,1362.1,244.3z M1369.6,256.9c2.5-5.7,6-7.5,10.7-9.2c4.1,1,8.2,2.5,11.4,8.2c-4.1,1-7.5,1.9-10.7,2.5
    C1377.2,258.2,1373.6,257.6,1369.6,256.9z M1453,309.7L1453,309.7L1453,309.7L1453,309.7L1453,309.7z M1317,267.7
    c0-0.7,1.9-1.9,3.2-3.2c0.7,0.7,1.5,1.6,1.5,1.9c-0.7,1.6-1,3.2-1.5,4.8C1319.3,270.6,1317.7,269.2,1317,267.7z M1388.4,325.2
    c0,6.7-0.7,13.3-0.7,19.6c-1,0.7-1.9,0.7-3.2,1l-1-18.1l0.7,0.7C1384.3,327.8,1386.9,325.6,1388.4,325.2z M1379.4,325.6
    c1.5,0.7,4.1,2.5,4.1,1.9l0.7-0.7l1,18.1c-1,0-1.9-0.7-3.2-0.7C1380.6,338.5,1379.6,331.9,1379.4,325.6z M1363.3-290.4
    c-0.7,0.7-2.5-1.6-4.1-1.9c0.7-1,1.5-2.5,1.9-3.2c0.7,1,0.7,1.9,0.7,3.2c1,0,1.9,0,3.4,0C1364.8-292.1,1364.6-291.1,1363.3-290.4z
    M1344.4-289.9c-1.9-1-3.4-3.2-5.1-4.8c1.9-0.7,3.4-1.9,5.1-1.6c1.5,0,2.5,1.9,3.4,3.4C1347.3-291.4,1345.1-289.5,1344.4-289.9z
    M1321.8-297.7c-3.2,1.9-7.5,1.6-11.6,1.6c-1-1.6-1.9-3.2-3.4-4.8c6-4.1,12.6-8.2,23.3-15.9
    C1325.9-305.9,1325.2-299.6,1321.8-297.7z M1285.6-290.4l1.5-1.6l0.7,1.6H1285.6z M2290,393.1c3.2-3.2,5.6-6,7.5-9.2v13.3
    C2293.2,395.3,2290,393.1,2290,393.1z M550.4-722.8c-12.2,29.7-20.7,57.9,8.2,28.7c-11.4,11.4,21.4,9.8,42.8,15.9v-0.7
    c0.7-1.6,1.5-1.9,1.9-1.9l0,0c0.7,0,1,0.7,1.9,1.6c1.9,2.5,4.7,4.1,7.3,4.1c1.9,0,4.1-1,6-1.6c1.5-0.7,3.2-1,4.7-1
    c0.7,0,1,0,1.9,0.7c-0.7,1-0.7,1.6-1,2.5c-1.5,3.2-3.2,6.7-4.1,10.1c-1.5,4.1-3.2,8.2-4.7,12.3c0,0.7-0.7,1-0.7,1.6l0,0
    c0-1,0.7-1.9,0.7-3.4c-2.5,4.1-6,8.9-11.6,14.2c-22.3,22.4,34.6,53.8,29.6,59.4c15.4-15.5,37.7,51.2,30.2,58.7
    c-11.4,11.4,30.6,62.6,38.7,54.3c9.7-9.8,16.3,58.7,22.1,53.1c-9.7,9.8-9.2,16.7-4.1,23.1l0,0l0,0c0,0,0,0,0.7,0.7
    c-0.7,0.7-0.7,1.6-1,1.9v-1c-2.5,4.8-11.6,4.8-9.2,13.3c9.2,3.2,14.1-1.6,19.9-6.7c18,10.8,44.7,18.3,26.1,37
    c28-28.2,91.2,12.6,65.1,38.9c42.8-43,52.5-5.1,63.2,6.7c-2.5,0-5.6,0.7-8.2,1.6c-1,0-2.5,2.5-2.5,4.1s1.9,4.1,3.2,4.1
    c6-0.7,11.6-1.9,18.9-3.2c-1.5-1.6-2.5-2.5-3.4-3.4c1.5-0.7,2.5-1.6,4.7-3.2c-1.5,1.6,30.6,8.2,63.5-24.9
    c0.7-0.7,42.8,32.3,67.3,7.5c-12.6,12.6,74.9-13.3,65.7-4.1c29.6-29.7,79-40.5,87.1-24.1c1.9-4.8,4.7-9.8,8.8-18.3l0,0
    c5.6-10.1,13.2-18.3,21.4-26.6c0,0,0,0,0.7-0.7c5.1,5.7,9.2,11.6,8.2,14.9s-8.2,5.7-15.4,7.5l0,0c-1,0.7-1.9,1.6-2.5,1.9
    c0,0,0,0.7-0.7,0.7l-1,1v1c0,7.5-0.7,15.5-0.7,23.1c0,8.2-0.7,16.4-0.7,24.6v1l1,1c9.2,8.9,11.4,10.1,22.3,12.3
    c-4.1,1.9-7.5,4.1-11.4,5.7l-1.9,1l0.7,1.9c2.5,8.9,6.6,18.1,18.9,18.1c0.7,0,1.5,0,1.9,0c0.7,0,1,0,1.5,0c5.1,0,8.2,1.9,10,4.8
    c-3.2-0.7-6-1-9.2-1c-10.7-1.6-22.1-3.2-33-4.8c-2.5-0.7-5.1-1.9-7.3-4.1c-0.7-0.7-1-0.7-1.5-1c-5.6-3.4-8.8-6-12.2-6
    c-4.1,0-7.3,2.5-12.6,8.2c-1.5-1-3.2-1.9-4.7-2.5c4.1,17.4,12.2,37.9,27.4,22.4c-26.1,26.3,4.1,73.3,23.3,53.8
    c-4.7,4.8-7.3,10.8-9.2,17.4h0.7c1.5,1.6,3.2,3.2,4.7,4.8c4.7,4.1,8.8,8.2,12.2,12.6c2.5,3.2,6,3.4,9.2,3.4c1,0,1.9,0,3.2,0
    c1,0,1.9,0,2.5,0c3.2,0,5.6,0.7,6,6l0.7,3.2l3.2-1c0.7,0,1.5-0.7,2.5-0.7c1.9-0.7,4.1-1,6-1.9l0,0c1-1,1.5-1.9,1.9-3.2
    c1-1.6,1.5-1.9,1.9-1.9c0,0,0,0,0.7,0c0.7,5.7-3.2,11.4-6,12.6c-13.8,6.7-17.3,17.4-16.7,31.6c-6-3.4-12.2-7.3-18.2-10.8
    s-12.6-7.3-19.5-11.4c0-0.7,0-1,0.7-1.9c-39.3,41.1-13.8,68.6-4.1,58.7c-28.9,29-28.9,43-33.6,49c-0.7-0.7-38.7,31.6-39.3,31.3
    c-14.1,19.6,21.4,36.4,24.5,33.8c6.6-6.7,1,42.3-2.5,45.6c36.8-37,5.1,31.3-9.2,44.9c-9.2,9.2,66.6-4.1,6.6,56.9
    c-11.6,11.6,32.1,39.8,12.6,58.7c-7.5,7.5,3.4,8.2,18,8.9c-0.7,2.5,0.7,5.1,2.5,8.2c2.5-2.5,4.1-5.1,4.1-8.2
    c23.9,1,50.3,4.8,16.3,38.2c27.4-27.5,92.8,3.2,68.3,27.5c7.5-7.5,12.6-10.1,18-11.6c1,3.2,3.2,6,5.6,9.8
    c5.6-1.6,11.6-3.4,16.7-5.1c-1.9-3.4-4.1-6.7-6-9.8c3.4-1.6,7.5-4.1,12.6-8.2c0,0.7,0.7,1,0.7,1.6c1,1,2.5,1.9,4.1,2.5
    c-0.7-1.6-0.7-3.4-1.5-4.8c-0.7-0.7-1-0.7-1.5-0.7c4.1-3.2,8.2-7.3,13.8-12.3c33-33.1,41.3,0,48.4-5.1c1.9-2.5,46.9-49.7,46.9-50.2
    c8.8-8.2,30.6-17.4,46.9-22.2c0,0.7,0.7,1.6,0.7,1.9l2.5-1c-0.7-0.7-1-1-1-1.9c5.6-1.6,10.7-2.5,13.8-1.9c-0.7,1-1,2.5-1.9,3.4
    l2.5,0.7c0-1.6,0.7-2.5,0.7-4.1c2.5,0.7,3.2,2.5-0.7,6c-18,18.1,80.9-28.2,60.1-7.3c3.2-3.2,80.9-6,62.3,13.3
    c8.2-8.2,37.1-8.9,40.9,5.7c-3.4-1.9-7.5-4.1-11.4-6c-6,5.1-11.6,9.8-18.9,15.5c0.7,1.9,1.9,6.7,3.4,11.4c-2.5,1-5.1,1.9-8.2,3.2
    c-4.1,10.1,19.9,14.2,38.1,6c3.4-1.6,7.3-3.2,10.7-5.1c12.2,8.2,9.2,22.2,13.8,32.9c7.5-10.1,12.2-21.5,11.6-35.7
    c-7.5-4.1-15.8-8.2-23.3-12.6c36.8-10.8,63.5,22.2,10,75.8c-4.7,4.8,22.3,8.2,44.3,12.6c0,0.7-0.7,0.7-0.7,1c0.7,0,1-0.7,1.5-0.7
    c14.8,3.2,27,6.7,22.3,10.8c7.5-7.5,12.6-1.9,17.3,8.2c-5.1-1-10-3.2-14.8-4.1c-3.4,14.2,12.6,14.9,13.8,24.6
    c-3.4,5.1-8.8,4.1-13.2,3.2c-3.4-0.7-7.5-0.7-11.4-0.7c-10.7,28.2,18.2,30.4,27.4,44.6c9.2-1.6,18.2-3.2,28-3.4
    c1.9,0,6.6,5.1,6.6,7.5c0,4.8-4.7,4.8-8.8,3.4c-2.5-0.7-5.6-0.7-7.5-1c-2.5,3.2-4.7,6-7.3,9.2h-0.7l0,0l0,0h0.7
    c2.5,2.5,5.1,5.7,7.5,8.2c1.9-0.7,5.1-0.7,7.5-1.6c4.1-1.6,8.8-1.9,9.2,3.2c0.7,2.5-3.4,8.2-6,8.2c-9.2,0.7-18.9-0.7-28-1
    c-8.2,15.5-36.8,20-23.3,47.1c3.4-0.7,7.5-1,11.4-1.6c4.7-1,9.2-3.2,13.2,1.9c0,9.8-16.3,11.6-11.4,26.3
    c11.4-3.4,22.3-11.6,35.2-5.1c1.9,1,7.5-1.6,9.7-4.1c6.6-8.2,13.8-4.8,20.7-1.6c1.9,1,3.4,4.8,5.6,6c7.3,4.8,12.2,4.1,17.3-3.2
    c1-1.6,1.5-4.1,3.2-5.1c13.8-11.4,13.8-29,22.3-43c1.5-1.9,2.5-4.1,4.1-6c16.3,3.4,33,3.4,38.1-1c-15.4,15.5-18,22.4-13.2,24.6
    c0,0.7,0,1,0.7,1c1,1.9,3.2,4.1,4.7,6.7c-1.5,0.7-3.2,1-3.2,1s1.5-0.7,3.2-0.7c0.7-2.5,1.9-4.8,1.9-7.3c17.3-1.6,51.6-17.4,51-16.7
    c-5.1,5.1-5.6,9.2-2.5,11.6c-0.7,1-1,2.5-1.5,3.4l0.7-0.7l0,0v0.7c1.5-0.7,3.2-1,4.7-1.6c18.9,8.2,83.9-1,92.8-9.8
    c9.7-9.8,10.7,67,60.1,17.4c-5.1,5.1,40.9-35.4,46.2-41.1c8.2-8.2,20.7-20.5,28.6-27.2c22.3-29.7,15.4-58.7,15.8-59.4
    c-34.6,34.8,0-48,1-66.1c-0.7,0-0.7,0-0.7,0c-0.7,0-1.5,0-1.9-0.7c1-1,1.9-1,3.2-1c0-2.5-1.5-2.5-4.7,0.7
    c32.8-32.9,13.8-67,8.8-61.3c62.3-62.6,9.2-36.4,26.5-54.3l0,0c0.7,0,1,0,1,0c0-0.7,0.7-1,0.7-1.9c11.4-11.6,18.9-23.1,22.3-31.6
    c1.5,1,3.4,0,7.5-2.5c0.7,0,0.7,0,1,0.7v-0.7v0.7v-1c4.1-2.5,7.5-5.1,5.6-11.4c-0.7-1,1.9-3.4,3.4-5.7c-6-4.8-11.6-9.2-17.3-13.9
    c0.7-1.9,1-5.1,1.9-5.1c6.6-2.5,12.6-6,9.7-14.9c-2.5-7.5-7.5-9.2-22.9-6.7c0.7,4.8,1,9.2,1,13.9c0,14.2,1.9,29-12.6,38.2
    c-1,0.7-1,4.1,0,5.1c1,0.7,1.9,1.6,3.2,1.9c-21.4,14.9,15.8-86.6,16.7-87.6c-4.1,4.1-18-2.5-24.5-15.5c2.5,1,5.6,1.9,8.2,1.9
    c2.5,0,5.1-1.9,7.5-3.2c-1-7.5-1.5-14.2-2.5-21.5c-4.1-1.6-8.2-3.4-12.6-5.7c2.5-4.8,5.6-9.2,10.7-14.2
    c-12.6,12.6-12.6,7.5-7.3-4.1c2.5,0.7,5.6,1.6,9.7,2.5c-1.5-5.7-2.5-10.1-3.4-14.2c5.1-9.2,11.4-20,16.7-28.7c4.7-1,9.7-1,15.4,1
    c6.6,2.5,15.4,0,22.9,0.7c10.7,0.7,14.8-7.3,20.7-15.5c-4.7-5.7-9.2-10.1-13.2-15.5c-8.8-10.8-8.8-13.3,0-27.5
    c-9.7-1.9-19.9-4.8-29.6-6c-4.1-0.7-7.5,0-11.4,1c-0.7-4.1,3.4-11.4,14.8-23.1c-30.2,30.4-16.3-6.7-5.6-38.9c1.5,1,3.2,1,4.7,0.7
    c10-3.2,19.5-4.1,29.6,0.7c-9.7-7.5-18.9-15.9-30.2-13.3c6.6-22.2,8.2-35.7-14.1-13.9c3.2-3.2,5.6-5.7,8.2-8.2
    c5.1-3.2,10.7-5.7,16.7-7.3c-1.9,2.5-3.4,7.3-3.4,10.8c-0.7,5.1,4.1,9.2,9.2,9.2c8.8-0.7,17.3-1,26.1-1.9v-72.1
    c0-0.7-0.7-1-0.7-1.6h0.7v-76.2c-0.7-1.6-0.7-3.2-1-4.8c-1.5-5.7-3.4-10.8-11.4-10.8c-2.5,0-5.6,0.7-10,1.6c-0.7-0.7-1-0.7-1-1
    c0,0,0.7-1,1.5-1.9c4.1-4.8,8.2-9.2,11.6-13.9c1.5-1.6,2.5-3.2,4.1-4.8l0,0c0.7-0.7,1-0.7,1.5-1c1.5-1,3.2-1.9,4.7-2.5h0.7v-24.6
    c-1.5,0-3.4,0.7-5.1,1c-2.5,1-5.1,1.6-7.5,1.6c-6.6,0-12.2-2.5-18.2-5.7c-4.7-1.9-9.2-4.1-13.8-5.1c-1-0.7-1.9-2.5-1.9-3.4
    c0.7-3.4,2.5-6.7,3.2-7.3c3.4-0.7,6.6-1.9,9.7-3.2c5.1-1.6,10-3.4,15.4-3.4c3.2,0,6,0.7,9.2,1.9c3.2,1.6,6.6,2.5,9.7,3.4v-26.6
    c-6-1.9-11.6-2.5-16.3-2.5c-1,0-2.5,0-3.4,0h-0.7c-1.5,0-3.2,0-4.1,0c-2.5,0-5.6,0-8.8-1c-13.8-3.4-27.4-7.5-42.1-11.6
    c-4.7-1.6-9.7-2.5-14.1-4.1c0-0.7-0.7-1.9-0.7-2.5c-0.7-3.4-1-9.8-8.8-11.6c-1.9-0.7-4.1-3.4-5.6-6.7c-0.7-1-1-1.9-1.5-2.5
    c6.6-10.1,11.6-19,18-28.2c1.5,0,3.2,0,5.1-0.7c3.4-0.7,7.5-0.7,11.4-0.7c0.7,0,1,0,1.5,0s1.5,0.7,1.9,1.6c0.7,1.6,0.7,4.1-0.7,5.7
    c-1.9,2.5-3.4,3.4-5.1,3.4c-1,0-1.9-0.7-3.4-1c-0.7-0.7-1.5-0.7-2.5-0.7c-2.5,0-5.6,1.6-7.3,2.5c-2.5,1.9-1.5,7.3-0.7,9.2
    c1.9,4.1,6,11.4,15.4,11.6c4.1,0,8.8,1,13.2,1.6c1.5,0.7,3.4,0.7,5.1,1l0.7,0.7c6,8.2,12.2,16.7,23.9,20.5l3.2,1l0.7-3.2
    c0-0.7,0-1,0-1.6c0-1.6,0.7-2.5,0-3.4c-0.7-1.9-1-4.1-1.5-5.7v-0.7c-2.5-8.2-4.1-13.9-2.5-16.4c1.5-2.5,7.5-3.2,14.1-3.2
    c5.6,0,11.4-0.7,16.7-1.6v-41.5c-5.6-5.1-13.2-6-19.5-6l0,0c-6-11.4-17.3-16.7-28-22.2c-2.5-1.6-5.6-2.5-8.2-4.1
    c0-3.4,0.7-6.7,0.7-9.8c0,0,0,0,0.7,0l5.6,1l-2.5-2.5c1-1,1.5-2.5,1.9-3.4c0-0.7,0.7-1,0.7-1.6c5.6,1.9,11.6,4.1,17.3,6
    c3.2,1,6.6,1.9,9.7,3.4c9.2,3.2,17.3,6,22.9,12.3V-625c-9.2-14.9-6.6-57.2,0-67.6v-0.7c-1.5,1-1.5-1.9-0.7-7.5h0.7v-62.6l0,0l0,0
    v-33.1c-1.9-1.6-4.1-2.5-6-3.4c1-1.6,1.9-3.4,2.5-5.1c-1.9,1-4.7,1.9-6,3.2c-3.2-1.9-6-3.4-8.2-5.7c5.6-1.9,10.7-5.7,15.4,1.6l0,0
    c1-1,1.9-1.6,3.2-1.9v-4.8c-4.1-4.8-8.2-9.2-12.6-14.2c-4.1,1-7.3,2.5-9.7,4.8c1-2.5,3.4-5.7,6.6-8.9c-17.3,17.4-42.1-5.1,1.9-56.9
    c1,0.7,1.5,1.6,2.5,1.9l0,0l-0.7-0.7l0,0l0,0l0,0c0.7,0,0.7,0,1,0c4.1-6.7,5.6-8.9,9.7-5.7v-20.8c-7.5-0.7-15.4,1-23.3,1.6
    c-0.7,1.6-0.7,2.5-0.7,4.1c-2.5-1-4.7-2.5-6.6-4.1c-1,0.7-1.9,1.6-3.4,2.5c-1,1-2.5,1.9-3.4,3.2c-7.5,6.7-14.8,13.3-24.5,13.3
    c-1,0-1.9,0-3.2,0h-1l0,0l-1.5,0.7c-4.7,1.6-7.5,2.5-9.2,2.5s-3.2,0-4.7-8.2c0-1,0.7-3.2,1-4.8c0-0.7,0.7-1.6,0.7-1.9v-1l0,0l0,0
    v-0.7l0,0v-0.7c-0.7-3.4-1-7.5,0-8.9c1-1.2,5.6-1.6,10-1.9l0.7,0.7l1-0.7h0.7l0,0l0,0c5.6-3.2,10.7-5.7,16.3-8.2l6.6-3.4l-1-1.9
    c-0.7-1.9-1-3.4-1.9-5.1c0.7,0,1,0,1,0c1,0,2.5,0,3.4,0c1.9,0,4.7,0,6.6-1.6c4.1-2.5,8.2-4.1,12.6-4.8c1.9-9.8,3.4-26.6,6-35.7
    c-1,0-1.9,0-2.5,0c-3.4,0-8.8,1-10,7.3c-1,0-1.5,0-2.5,0c-3.4,0-5.1-0.7-5.6-2.5c1.5-1.6,3.4-2.5,5.1-4.1c1.5-1,2.5-1.9,4.1-3.2
    l2.5-1.9l-1.9-1.9c-1.9-1.9-3.4-3.4-5.6-5.7c-1-1-1.5-1.6-2.5-2.5c7.5-8.2,14.1-12.6,24.5-14.2c-1.9,5.1-3.4,9.8-5.6,14.2l-1,1.9
    l1,1c1,1.9,2.5,3.4,3.4,5.7c11.6-12.3-3.4-56.3-12.6-47.8c1.5-1.6-0.7-6-3.4-11.6c0.7,1,1,1.9,1.5,3.2c1.9,4.1,3.2,6,0,9.2
    c-1,0-2.5,0.7-3.4,0.7c-2.5,0.7-5.6,0.7-8.2,1l-1.9,0.7v1.9c0,1.6-0.7,3.2-0.7,4.8c-3.4-1.9-7.3-4.1-10.7-5.7
    c-9.7-5.7-19.5-11.4-31.4-12.3c-0.7-0.7-1.9-2.5-3.2-4.1c-0.7-0.7-0.7-1-1-1.6c11.4-4.8,18.2-12.6,25.5-20.8c2.5-3.2,5.1-6,7.5-8.9
    c-5.6-8.2-18.9-33.1-8.2-43.6c-6,6-8.2,0.7-8.2-9.8l-3.4,1c-6.6,1.6-12.6,3.2-18.9,5.1c-0.7-4.8-0.7-8.2-1-11.6v-1l-0.7-0.7
    c-2.5-2.5-5.6-2.5-8.2-3.2c-2.5,0-3.4-0.7-4.7-1.6c5.1-1.9,6.6-2.5,9.7-2.5c2.5,0,5.1,0.7,10,1h1h0.7c1.5,0,3.2-1,4.1-1.6
    c0.7-0.7,1-0.7,1.5-1l3.2-1.6l-2.5-2.5c-3.2-3.2-5.1-5.7-7.5-8.9c1.5-0.7,3.2-0.7,4.7-0.7c5.1,0,9.2,1.9,13.8,4.8
    c1-18.3,1-35.4-8.8-28.7c-1-0.7-1.9-1-3.4-1.6c0-13.3,5.1-55.3,10.7-61c-4.1,4.1-7.5,6-10,6.7c-1.5-1-3.2-1.6-5.1-1.9
    c-7.5-8.9-3.2-45.6,3.2-62.6c0.7-0.7,1-0.7,1.9-1c-0.7-0.7-0.7-1-1-1.6c1-2.5,1.9-4.8,3.2-5.7c1.9-1.9,4.7-4.8,8.2-8.2
    c0,0,0,0.7,0.7,0.7c1-1,1.5-1.6,2.5-2.5c0,0-0.7,0-0.7-0.7c13.8-13.9,33.6-34.1,35.2-35.7c18.2-18.3-10-5.1,34.6-50.2
    c0.7-0.7,1.5-1.6,1.9-1.9v-195h-151.9c-7.5,3.2-12.2,3.4-9.2,0h-1.5v0.7c-18.2,12.6-91.2,61.3-55,24.9c-4.1,4.1-22.3,14.2-50.3,42
    c30.6-30.7-33,24.6-50.3,38.2c3.4,1.6,2.5,19.6-4.1,37.9c-1-1.9-1.9-4.1-3.2-6c-15.8-3.2-34,7.3-45.9-9.8
    c-3.4,5.1-7.3,10.1-10.7,14.9c4.1,8.9,9.7,12.3,17.3,9.2c13.8-6,24.8-2.5,35.2,6.7c-2.5,4.8-5.6,8.9-9.2,12.3
    c-5.1,5.1,1.9,17.4-34,54.3c-8.8,8.9-10,16.7-8.8,24.6c-0.7,1-1,2.5-1.5,3.4l0,0l0,0l0,0l0,0l0,0c0.7-1.6,1-2.5,1.5-3.4
    c0,1.6,0.7,2.5,1,4.1c-1,0-1.5,0-2.5,0c1.5,1.9,3.2,4.1,4.7,6c3.4,10.1,8.2,19.6-1,28.7c-15.8,15.9,0.7,63.8,1,63.5
    c7.5-7.5,13.2-7.5,15.8-3.4c-1.5,1.9-3.2,4.1-3.4,6.7c0,1,2.5,3.4,5.1,5.1c-1.5,11.4-8.8,28.2-23.9,43.6c-6,6-11.6,11.6-16.3,16.4
    c0.7,0,1.5,0.7,1.9,0.7c-1.5,4.1-3.2,5.7-5.1,5.7c-0.7,0-1.5,0-1.9-0.7h-0.7c-0.7,0.7-1,1-1.5,1.6c-1-1-1.9-1.9-3.2-3.2
    c-3.4-0.7-7.3-1.6-9.2-4.8c-7.3,3.4-14.8,7.5-23.3,12.6c0,11.6,0.7,23.4,0.7,35.4c7.3,5.1,14.1,10.1,20.7,14.9
    c-15.4,11.4-24.5,3.2-28.9-6c2.5-0.7,4.7-3.2,7.5-8.9c-4.7-3.2-8.2-5.1-11.4-4.8c0-0.7,0-0.7,0.7-0.7c18-18.1-64.2-13.9-54.4-23.4
    c-23.3,23.4-39.6,20.8-47.8,8.9c1,0,1.9,0,3.2,0c-1-1.9-1.5-4.1-1.9-5.7c-2.5,3.4-1.5,8.2-0.7,11.6c0.7,1.6,1,3.2,1,4.8
    c-1.5,0.7-3.2,0.7-4.7,1c-5.1,1-9.7,1.9-14.8,3.2c-0.7,0-0.7,0-1,0c-1,0-2.5-0.7-4.1-0.7h-0.7c-4.7-9.8-8.8-19-12.6-27.5
    c-2.5-5.7-5.1-11.4-7.5-16.4l3.2-5.7c3.2-6,5.6-11.6,8.8-16.7c1,0,1.9,0,3.2,0l0,0c8.8,0,14.8,4.8,21.4,11.6c1.9,1.9,4.1,3.4,6,4.8
    c1.9-5.1,4.7-9.8,8.8-13.9c0.7-0.7,1-1,1.5-1.6c-0.7-0.7-0.7-1.6-0.7-1.9l0,0l0,0v-0.7c1,0,2.5,0,3.4,0.7
    c64.2-67.6-24.8-53.8-43.7-35.4c4.7-4.8-38.1,13.9-23.9-1c15.4-15.5-40.9,13.9-10-16.4c3.4-3.4,10-103.3-15.4-77.7
    c35.5-35.7-87.1-20-45.3-62.6c15.4-15.5-19.5-29.7-43.4-38.9c3.4,5.1,5.6,11.4,6,18.1h0.7c0,0.7-0.7,1-0.7,1
    c0,12.6-7.3,28.2-24.5,46.1c-11.6,11.6,77.1-7.5,34,35.4c11.4-11.4,15.4-9.2,15.8-1.6c2.5-0.7,4.7-0.7,6.6-0.7
    c4.7,0,6.6,1,11.4,4.1c-3.4,1-7.3,1.6-10.7,1.6c-2.5,0-4.7-0.7-6.6-1.6c0,18.3-9.7,54.6,9.2,35.7c-11.6,11.6-21.4,28.7-26.1,43
    c-1-1-1.5-1.9-2.5-2.5c-1-0.7-4.7,1.9-6.6,3.2c0,4.1,3.2,8.2,6.6,9.8c-4.1,22.4,9.7,30.7,56.9-16.4c-43.7,43.9-1.9,52.1,4.7,53.8
    c-17.3,14.9-83.6,74.6-46.9,37.9c40.9-41.1-28.9,24.1-43.7,38.9c-37.1,37.2-69.8,32.3-55,17.4c-20.7,20.8-43.4-14.9-60.7,1.9
    c15.4-15.5-19.9-74.3-50.3-43.9c-3.4,3.4-8.2,6.7-12.6,8.9c0,0.7,0,0.7,0,1c0,0.7,0,1-0.7,1.6l-0.7,1.6l1,1
    c1.5,1.9,3.4,4.8,5.1,6.7c4.1,5.7,7.5,10.1,12.2,14.2c5.1,4.1,11.4,7.3,16.7,10.1c2.5,1.6,5.6,3.2,8.2,4.1c1.9,1.6,2.5,1.9,2.5,2.5
    l0,0c0,0.7,0,1.6,0,2.5v0.7c-2.5-0.7-5.1-0.7-7.3-0.7c-10-0.7-18.9-1-24.5-12.6c-1.9-4.1-9.2-6-14.8-7.3
    c-8.8-1.6-19.5-3.2-30.2-3.2c-6,0-11.6,0.7-16.7,1.6c-1-1.6-1.5-2.5-2.5-3.4c-3.2-4.8-6-9.2-9.7-12.6l0,0c-1-1-3.2-1.6-5.1-1.6
    s-4.7,0.7-6,1.6l0,0l0,0c-2.5,1.9-4.7,7.3-3.2,10.1c1.5,3.4,3.4,6.7,5.1,9.2c0.7,0.7,1,1,1,1.6c-1.5,4.1-3.2,8.2-4.7,12.6
    c-1,3.4-2.5,7.3-4.1,11.4l-1.5,4.8l4.7-1c4.1-0.7,7.3-1.6,10-1.9c3.4-1,7.3-1.6,10.7-1.9c2.5,2.5,5.1,5.7,7.5,8.9l0,0
    c0,0.7,0,0.7,0,1c-10,5.7-20.7,11.4-30.6,16.7c-3.4-2.5-6.6-5.1-9.7-7.3c-1.9-1.6-3.4-2.5-5.1-4.1c1-1,1.9-1.9,3.2-3.2
    c1-1,2.5-2.5,3.4-4.1l2.5-3.2l-3.4-1.6c-1.9-1-4.1-1.6-6-1.6c-4.7,0-7.5,1.9-10.7,4.1c-1.5,1-3.2,1.9-4.7,2.5
    c-0.7,0-1.5,0.7-2.5,0.7s-2.5,0-4.1-0.7c-1.5-0.7-3.4-0.7-5.6-0.7c-5.1,0-8.8,1.6-12.2,5.7l-2.5,2.5l3.2,1.6c2.5,1,4.7,2.5,6.6,3.4
    c3.4,1.9,6.6,3.4,9.7,5.1c1,0.7,1,1,1,4.1s0,8.2,6,9.2c1,2.5,2.5,5.1,3.4,7.3c-2.5,1-4.7,1.6-6.6,1.9l-1.9,0.7l0.7,1.9
    c0,1.6,0.7,3.2,0.7,4.8c0.7,3.2,1,6.7,1.5,9.8c0.7,3.2,1.5,6,2.5,9.2c0.7,1,0.7,1.6,1,2.5c-3.4,1-6.6,1.9-9.7,3.2h-0.7
    c-1-3.2-1-6-1.5-8.9c-0.7-3.4-1-7.3-1.9-10.8c-0.7-1.6-1-3.2-1-4.8c-1-5.7-1.9-12.3-13.8-12.3h-1.5l-1,1c-2.5,4.1-4.7,8.9-6,13.3
    c-3.4,9.8-7.3,18.3-18.9,23.1c-3.2-1-6-1.9-9.7-3.2c-8.8-2.5-18.2-5.7-27.4-9.2c-1.9-0.7-3.2-1.9-4.1-3.4c-1-1.9-0.7-4.8,0.7-7.3
    c1-1.9,2.5-4.8,4.1-6.7c0.7-0.7,1-1.6,1.5-1.9l1-1.9l-1.9-1.6c-8.8-7.3-15.4-10.1-20.4-10.1c-9.7,0-12.6,10.8-14.1,18.3
    c-0.7,1.6-0.7,3.2-1,4.8c0,1-0.7,1.6-0.7,2.5c0,0-10.7,3.2-13.8,4.1c-4.1-6-4.1-9.8,0.7-13.9c9.7-9.2,18.9-19,28.6-28.7
    c3.4-3.4,6.6-6.7,10-10.1l1.5-1.9l-1.5-1.9c-1.9-2.5-4.1-4.8-6-7.3c-1.5-1.6-2.5-3.2-4.1-4.8l-1.5-1.6l-1.9,1
    c-5.6,3.4-9.2,6-13.2,8.2c-1.9-1.6-4.1-3.2-6.6-4.8c-1.5-1-3.4-2.5-5.6-4.1c5.6-4.8,10.7-8.9,15.4-12.6l1-0.7v-1
    c0.7-5.7,1-11.4,1-16.7l0.7-5.7l-1.9-0.7c-1.5-0.7-3.2-1-5.1-1.6c-2.5-1-4.7-1.6-7.3-2.5c-1-3.4-3.2-5.7-5.1-7.3
    c2.5-0.7,5.1-0.7,8.2-0.7c3.2,0,6.6,0,11.4,0.7c4.7,8.2,5.6,10.8,9.2,10.8c1,0,1.9-0.7,3.2-0.7c5.6-1.9,11.4-3.2,16.3-3.2
    c10,0,19.5,4.1,27.4,12.6c1.5,1.6,3.2,1.6,4.7,1.6c0.7,0,0.7,0,1,0c0.7,0,1,0,1.5,0c0.7,0,1,0,1.5,0h2.5l0.7-2.5
    c0-1,0.7-1.9,0.7-3.2c0.7-2.5,1-5.1,0.7-7.5c-0.7-3.4-1.5-6.7-1.9-9.2c0-0.7-0.7-1-0.7-1c1-1.6,2.5-1.9,4.1-3.2
    c3.2-1.9,7.3-4.8,5.6-11.6v-1l-1-0.7c-6-4.8-13.2-9.8-20.4-14.9l-4.7-3.4c0-4.8,0-9.8,0-14.2c-22.9,12.3-55.4,32.3-47.5,24.1
    c0.7-0.7-71.4,34.8-28-8.9c24.5-24.6-46.9-6.7-50.3-3.2c15.8-15.9-60.7,11.6-48.8,0c-23.9,24.1-42.8,3.4-41.3,1.9
    c-6.6,6.7-10,5.1-17.3,7.3c-1-1.6-1.5-3.4-0.7-6.7c5.6-1,11.4-1.9,14.1-2.5c9.2-12.3,17.3-23.1,25.5-34.1
    c-2.5-18.1-2.5-18.3,7.3-30.7c4.1-5.7,1.5-8.2-1-12.3c-2.5-3.4,4.1-12.6-6.6-12.3v-0.7l0,0l0,0l0,0l0,0c0,0-0.7,0-0.7,0.7
    c-3.4-3.4-7.5-2.5-12.2-1l-0.7-0.7l-0.7,1l0.7-1c0,0.7-0.7,0.7-0.7,1c1.5-8.9-4.1-13.3-10-18.1c-8.8,1-3.2,15.9-14.1,14.9
    c-5.1-0.7-8.8,3.2-9.2,8.9c14.8,10.1,14.8,10.1,20.7,24.6c-18-12.6-18-12.6-39.6-9.2c0-3.4-1-7.5,0-10.8c2.5-5.1,7.3-9.2,9.2-14.2
    c2.5-6,1-11.4-6.6-12.6c-7.5,5.1-15.4,12.3-23.9,15.5c-9.2,3.4-20.4,3.4-30.6,5.1v3.4c9.2,1,18,2.5,28.9,4.1
    c-1.9,9.2-3.4,18.1-5.6,28.7c19.9,0,38.1,7.5,58.1-1.6c-10.7,13.9-24.5,23.4-34.6,38.2c16.3,3.2,11.4,16.7,14.1,27.2l0,0l0,0l0,0
    l0,0l0,0l0,0l0,0l0,0c2.5,0.7,4.7,1,7.3,1.9c-8.2,5.1-18.9,13.9-34.6,29.7c11.6-11.6-16.3,23.4-34,46.4c-5.1,0-9.2,0-9.2,0
    c-12.2-3.4-6-14.2-17.3-13.3c-1.9,3.2-4.7,6.7-7.5,10.8c3.2,9.2,5.6,18.1,8.8,28.2c5.6-3.2,9.7-5.7,14.1-7.5
    c0.7,3.2,2.5,10.1,4.7,17.4c-3.2,6-6.6,12.6-10.7,20.5c-11.6-10.1-22.9-18.3-36.2-21.5c-6.6,4.8-16.7,5.1-18,16.7
    c-0.7,5.1-6.6,9.8-10.7,13.3c-1.9,1.9-6,1.6-9.7,2.5c-1.9-20.8,14.8-29.7,22.1-43.9c-3.4-6-3.2-13.3-8.8-19c-6-6-1-12.3,5.6-15.9
    c0.7-5.1,1.5-10.8,2.5-15.9c-5.6-4.1-11.6-8.2-18.2-13.3c-1.5,7.3-1.9,11.4-3.4,18.3c-7.3-4.1-13.2-8.2-19.9-12.3
    c-10.7,7.5-2.5,22.4-11.4,30.7c1.9,10.1,4.7,20.5,6,30.7c1,10.1-6.6,20.5-0.7,31.3c1,1.9,1.9,5.1,2.5,6
    c-5.1,8.2-8.8,16.4-14.8,22.2c-6.6,6.7-15.8,11.4-23.3,16.4c-1.5,1-3.2,2.5-3.4,4.8c-0.7,1.6,0.7,3.4,0.7,7.5
    c13.2-3.4,28.6,0,35.2-18.3c1-3.2,7.5-5.7,12.2-6.7c15.8-4.1,31.4-7.5,46.9-5.7c4.7-7.5,7.3-13.9,11.4-19c1-1.6,5.6-1.6,7.5-0.7
    c1.5,1,3.4,5.1,3.2,7.3c-1,4.8-4.1,8.9-5.6,12.3c3.4,7.5,6.6,14.2,11.4,24.1c-9.7-1-15.8-1.6-22.3-1.9c-2.5,3.4-5.6,7.3-8.2,11.4
    c-0.7,0.7,0.7,1.9,0.7,4.1c11.6,5.1,23.9,10.1,35.2,15.5c5.1-4.8,9.2-8.9,13.2-12.3c-0.7,0-1,0.7-1.5,0.7c-1-2.5-1.9-5.1-3.2-7.5
    c-2.5-6-5.1-13.3-8.2-21.5c0.7-1,1.5-3.4,2.5-6.7c1.5,1.6,2.5,3.2,3.4,4.1c5.1,5.7,9.2,10.8,13.2,15.9c1.5,1.9,1.9,5.1,3.2,7.5
    c0,0.7,0.7,1,0.7,1.6c-1.9,1-3.4,1.9-5.6,3.2c3.2,7.5,11.4,7.5,27-7.5c1.9,0.7,4.1,1.6,6.6,3.4c-6,4.1-10.7,7.5-15.4,10.1
    c-6,3.2,2.5,12.6-6.6,13.9c-1.5,4.1-3.2,7.5-4.7,11.6c4.1,1,7.3,1.6,10,1.9c0,4.8,0,9.2-0.7,13.9c-0.7,4.8-1.5,8.9-3.2,14.2
    c6,1.6,10.7,2.5,15.4,3.4c1-3.2,1-6,1-8.9c-1.9-1-3.4-2.5-5.6-7.3c2.5,0.7,4.1,1,6,1c0-2.5,0.7-5.1,1-7.5
    c1.5-7.3-1.5-14.9,10.7-16.4c3.2,3.4,5.6,8.9,8.2,13.9c-2.5,4.8-6,9.2-10,13.9c0.7,0.7,1.5,1.6,2.5,2.5c-1,0-1.9,0.7-3.2,0.7
    c3.4,8.2,11.6,67.6,23.9,55.3c-18,18.1,16.3,92.6,18,90.7c1-1,1.9,0.7,1.9,3.4c-0.7,0-0.7-0.7-1-0.7l-0.7,5.1c0.7-0.7,1-0.7,1.5-1
    c0,13.9-6,43-23.3,60.4c-2.5,2.5-3.2,8.2-1.9,15.5c0.7-0.7,0.7-1,1-1c0.7-1,1-1.6,1.9-1.6c0.7,0,1.5,0.7,1.9,1.6
    c0.7,1.6,1,5.1,1,6.7c-1.5,1-3.2,1.6-4.1,2.5c4.7,23.1,18,53.1,19.9,50.5c9.2-9.2,40.3,1.6,64.2,2.5c-1,1-1.5,1.9-2.5,2.5
    c1,1,1.9,1.6,3.2,2.5c1.9-1.9,3.4-3.4,5.6-5.7c8.2,0,14.8-1.9,19.5-6c15.4-15.5,26.1-28.7,34-39.8c0,1,0.7,1.9,0.7,3.2
    c1,1.9,5.1,1.9,7.5,3.2c0-2.5,0-5.7-0.7-8.2c-0.7-1.6-1.9-3.2-3.4-4.8c4.7-7.5,8.2-13.9,10-19.6c0-0.7,0-0.7,0-1
    c0-6.7-5.6-8.9-9.2-10.1l0,0c0.7-1.9,1.5-2.5,4.1-2.5c0.7,0,0.7,0,1,0h0.7c4.1,0,7.5-1.9,9.2-5.1c1.5-3.2,1.5-6.7-0.7-9.8
    c-1.9-3.2-4.7-5.1-7.3-7.3c-0.7-0.7-1-1-1.9-1.6l1.9-8.2c1.5,0.7,3.2,1,4.1,1.6c4.1,1.6,7.5,2.5,11.6,2.5c1,0,1.9,0,2.5,0
    c1.9-0.7,4.1-0.7,5.6-0.7c10.7,0,18.2,5.7,26.1,18.3c1,1.6,1.9,3.4,3.2,5.1c8.2-4.1,15.4-8.2,19.9-12.6c1.5-1.6,3.2-3.2,4.7-4.8
    c3.4-1.6,7.3-2.5,10.7-4.1c-1-1-2.5-2.5-3.4-4.8c0.7,0,1,0,1.9,0c1.9,0,4.1,0.7,6,1.6c0.7,0,1,0.7,1.5,0.7c1-0.7,1.9-1,3.4-1
    c2.5,1,4.7,3.2,6,5.7c0.7,0.7,1.5,0.7,1.9,1c-0.7,0-1,0.7-1.5,0.7c1.9,3.4,3.4,8.9,4.7,13.9c-2.5-1.6-5.6-1.9-10,1l7.5,19.6
    c1.5-1.6,3.4-2.5,5.1-4.1c2.5,20.8,3.4,41.1,14.1,30.7c-6,6-12.6,17.4-17.3,28.7c-0.7,0-1,0-1.5,0c0.7,0.7,0.7,1.6,1,1.9
    c-11.6,29-9.2,57.9,48.8-0.7c-19.5,19.6-6,28.7,11.4,29.7c0,1.6,0.7,2.5,1,2.5c10.7,4.1,14.8,10.8,11.6,22.2c1.5,0,3.4,0.7,5.6,0.7
    c1.9-5.1,3.4-10.8,5.6-15.9c1.9-5.1,4.1-10.1,6-14.9c-0.7-0.7-1-1-1.5-1.6c1-0.7,1.9-1.6,3.2-2.5c15.4-15.5,23.9,44.6,33,35.4
    c-7.5,7.5,40.3,4.8,60.1,10.8c-5.1,2.5-10,5.1-15.8,8.2c2.5,4.1,4.7,7.3,6.6,9.8c4.7-4.8,9.2-8.9,13.2-13.3
    c0.7-0.7,0.7-1.6,0.7-2.5c4.1,2.5,5.1,6-0.7,11.6c-28,28.2,46.9-13.9,56-14.9c1-1.9,1.9-4.8,4.1-7.5c0.7,0,1,0,1.5,0
    c-0.7-0.7-0.7-0.7-1-0.7c9.2-18.1,26.5-50.5,20.7-52.8c0.7-1,1-1.6,0.7-1.9c-0.7-1.6-2.5-1.9-4.1-3.4c-1,1.6-2.5,3.4-2.5,4.1
    c0.7,1,1.5,1.6,2.5,1.9c-2.5,1.6-6,4.8-11.6,9.8c-26.5,26.6-10.7-44.9-10.7-44.9c-5.1,5.1-3.2,1.6,2.5-6c5.1,2.5,10.7,2.5,18.2,1
    c-4.7-5.1-9.2-7.3-13.8-7.5c6-8.2,13.8-18.3,18.9-26.3c1.9-0.7,5.1-1.6,8.8-3.2c5.6,1.6,11.4,3.2,16.7,4.8l0,0
    c-5.6-1.6-11.4-3.2-16.7-4.8c8.2-3.2,18.9-8.2,28.9-12.6c-2.5-1.6-4.1-2.5-6-4.8c3.2,0.7,5.1,1.6,6.6,4.1
    c11.4-5.7,21.4-11.4,23.9-14.2c2.5-2.5,26.5-11.6,46.2-16.7c1.5,1,3.2,2.5,5.1,3.2c1,0.7,2.5-1,4.1-1.6c-1-1-1.9-1.9-2.5-2.5
    c15.4-3.4,26.5-4.1,18,4.1c18-18.1,49.4-42.3,31.4-23.1c-1.5,0-3.2-0.7-5.1-0.7c1.5,1,2.5,1.6,3.4,1.9c-2.5,3.2-6.6,7.3-11.6,11.6
    c-1.5,1.6,18-0.7,29.6-5.1c2.5,4.1,7.3,6.7,12.2,7.5c22.1,6,43.7,12.3,66.1,18.3c1,0,1.9,0,2.5-0.7c1.5-1.6,3.2-3.4,4.7-5.1
    c-5.6,1.9-10,1.9-13.8-4.8l0,0l0,0l0,0l0,0l0,0l0,0c-18.2-4.1-36.8-8.2-55-12.6c-4.1-1-8.2-3.2-11.6-5.7c0.7,0,0.7-0.7,1-0.7
    c-0.7,0.7-1,0.7-1.5,0.7l-0.7-0.7c-1.5-1.6-1-6.7,0-13.9c1,0.7,1.9,1.6,3.2,1c1-0.7,1.9-2.5,3.2-3.4c-1.5-0.7-3.2-1.9-4.1-1.9
    c-0.7,0-1,0.7-1.5,1c1.5-9.2,5.1-20.8,9.2-32.3c1.9,0,4.1,0,6,0v-2.5l-4.7-1.6c3.4-9.8,8.2-19,12.2-25.6c2.5,1,4.7,3.4,7.3,5.7
    c1-5.1,0-8.9-3.2-11.4l0.7-0.7c0,0,19.5-44.6,22.9-66.1c-2.5,2.5-4.7,3.2-5.6,3.2c-1.5,0-3.2-1.6-4.7-4.1c1.9-3.2,4.7-5.1,7.5-5.1
    c1,0,1.5,0,2.5,0.7c0-7.5-3.4-8.9-14.8,2.5c-4.7,4.8-6,3.2-5.6-1.9c1-0.7,2.5-1.6,4.1-1.9c-1.5-1-2.5-1.6-3.4-2.5
    c3.2-23.1,22.1-78.7,6-62.8c8.8-8.9,16.3-20,22.3-31.6c0.7,3.2,0.7,7.3,1.9,9.2c1.9,2.5,6.6,3.2,12.2,5.1c-2.5-6-3.2-10.1-5.6-12.6
    c-1-1.9-4.7-2.5-6.6-3.4c-0.7,0-1,0-1.5,0.7c2.5-5.1,4.7-9.8,6.6-14.9c1.9,2.5,5.1,4.1,9.7,2.5c1-0.7,1.9-1,1.9-1.6
    c1.5-7.3-3.4-8.9-8.8-10.8c1-2.5,1.5-5.1,1.9-7.5c9.2,6,20.4,12.6,21.4,11.6c6.6-6.7,12.2,56.9,18.2,50.5
    c42.1-42.3,30.6,5.7-14.8,51.2c32.1-32.3,5.1,25.6-3.2,53.1c1,1.9,2.5,4.1,4.7,4.8c0.7,0,1,0.7,1.9,0.7c2.5,0,5.1-1.6,7.3-2.5
    c0.7-0.7,1-0.7,1.5-1c12.2,1,13.2,1.9,14.1,13.9l-1.9-1l1.9,6c0,0.7,0.7,1,0.7,1.6l0.7,5.7l1-1.6c1.5,4.1,3.4,8.9,8.2,10.8
    c-1.5,3.2-3.4,4.8-6,4.8c-1,0-2.5-0.7-4.1-1c-3.2-1-6-3.2-8.8-5.1c-2.5-1.6-5.1-3.4-8.2-5.1l0,0c-3.4-1.6-6.6-3.2-9.2-4.8v0.7
    c-0.7-0.7-1-1-1.5-1.6c-5.1-2.5-7.5-4.8-10.7-7.5c-7.3,1.9-14.8,4.8-23.3,7.3c1.5,4.1,2.5,8.9,3.2,8.9c9.2-1.9,18.2,1,27.4,2.5
    c-6,11.4-12.6,23.1-16.7,27.2c-5.6,5.7-9.7,10.8-12.2,15.5c-0.7-0.7-1.9,0-3.2,0.7c0.7,0.7,1.5,0.7,1.9,1
    c-4.7,8.2-5.6,15.9-5.1,22.2c-1.5,0-3.4-0.7-5.1-0.7c1.9,1.9,4.7,4.1,6.6,6c1,2.5,2.5,4.8,4.1,6.7c-4.7,5.1-6,7.3-5.1,10.1
    c0,0,0,0,0.7,0l0,0l0,0c1,0,2.5,1,4.7,1.9c0,0.7,0,0.7,0,1c-1,0.7-2.5,0.7-3.4,0.7l0,0c0.7,1,1.5,2.5,2.5,4.1
    c1.9,3.2,3.4,6,5.6,9.2c5.6-9.8,5.6-9.8,20.4-12.3l-4.1-4.8c4.7,0.7,8.8-1,11.6-3.4c-4.7,4.8-5.6,14.2-4.7,24.9
    c-1.9-0.7-4.7,0-7.3-0.7c2.5,1.6,5.1,2.5,7.5,4.1c1,6,2.5,11.6,4.7,16.4c-1.5-0.7-3.4-1.6-5.6-1.9c-1.5,0-3.2,1-5.1,1.6l11.4,3.2
    c0-0.7,0.7-0.7,0.7-1c4.1,8.2,10,11.6,16.7,5.1c-3.2,3.2-6,9.8-8.8,17.4c-4.1-1.9-8.8-3.2-13.8-2.5c3.4,5.7,8.2,7.3,12.6,7.5
    c-0.7,2.5-1,5.1-1.5,8.2c-1-0.7-2.5-0.7-4.1-1l-3.4,12.3l-15.4-4.1c0,0.7,0,0.7,0,1c5.1,1,10,1.9,15.4,3.4c1.9,1.6,4.1,3.2,6,4.8
    c0,5.1,0.7,10.1,1.9,13.9c-1,0.7-3.2,1-4.7,1c-6-0.7-8.2,1.9-8.2,7.3c4.1,1.6,9.2,2.5,9.2,8.9c0,3.2-0.7,5.7-1,8.9
    c0,1.6,0,2.5,0,4.1c9.7,0.7,15.8,3.4,24.8,12.3c1.5,1.6,1.9,4.1,1.5,6.7c0,1-1,3.2-2.5,3.4c-4.1,1-7.5,1.6-10,1.6
    c-1.9,0-3.4,0-4.7-0.7c-0.7,0-1-0.7-1.9-0.7c1.9,2.5,5.1,4.8,9.2,6c-11.4,6.7-1.9,18.3-12.6,23.4c-4.7,1.9-8.2,5.7-12.2,9.2
    c-20.4-1.6-46.9,0-47.8,1c-15.4,15.5-34,67,5.6,27.5c-4.7,4.8-8.2,8.2-10.7,11.6c-0.7,0-0.7,0-1-0.7c0,0.7,0,1,0,1.6
    c-11.6,15.5-8.2,21.5-1.5,24.1c-0.7,0.7-1,1-1.5,1.6c6-6-114.5,35.4-64.8-15.5c12.6-12.6,1-11.6-22.9,5.7c-1.9-2.5-5.1-4.1-10-4.8
    c-0.7,4.8-1,9.2-1,13.3c-1.5,1-3.2,2.5-4.1,3.4c-0.7,0-1.5,0-1.9,0.7c0.7,0,0.7,0.7,1,0.7c-4.7,4.1-9.7,8.9-15.4,14.2
    c0,0-0.7,0-0.7-0.7c0,0,0,0.7,0.7,0.7l-0.7,0.7l-0.7,0.7c0-0.7,0-1.6,0-1.9l0,0c0.7,0,0.7,0,1,0.7c0-0.7,0-0.7-0.7-1l0,0v-0.7
    c-6,0.7-12.2,1-20.7,1.6c4.1,1.9,7.3,4.1,10.7,6c-9.2,1.9-19.9-0.7-27.4-1.9c-1.9,3.4-4.7,7.5-7.3,12.3c3.2,1,5.6,4.1,5.6,7.3
    c0,0.7-1,1-1,1.6l0,0c-0.7,0-1.5,0.7-1.9,0.7c-1.5,0-2.5-1.6-4.1-4.8c0-0.7-0.7-0.7-0.7-1c-11.4,18.1-27,39.5-32.1,44.6
    c9.7-9.8,47.8,28.7,8.8,67.6c0,0-14.1,27.2-22.1,47.1c-1-0.7-1.9-1-3.2-1.6v0.7c0.7,1.6,1.5,2.5,1.9,3.4
    c-5.6,14.2-7.3,24.1,3.4,12.6c-8.8,8.9-16.3,22.4-39.3,45.6c-8.2,8.2-14.1,14.9-19.5,19.6c0.7,0.7,1.5,1,1.9,1.6
    c1.9,1.6,4.7,3.2,7.3,4.1c3.2,1.6,5.6,2.5,7.5,4.8c1.5,1.6,4.1,2.5,6,3.2c3.4,1,5.1,1.6,5.1,5.7c-1.9,1.6-4.7,2.5-6.6,4.1
    c-4.1,2.5-8.2,5.1-12.2,7.5l-1.5,1l0.7,1.9c0,0.7,0.7,1.6,0.7,1.9c0.7,1.6,0.7,3.4,1.5,5.1c1.9,1.9,3.2,4.1,2.5,5.7
    c0,0.7,0,1.6-1.9,2.5c-0.7,0.7-1,0.7-1.5,0.7c-1,0-2.5-1.6-3.4-3.2c-0.7-0.7-0.7-0.7-1-1l0,0l0,0c-1-1-2.5-1.9-4.1-2.5
    c-0.7-0.7-1.5-0.7-1.9-1l-1.5-1l-4.1,3.4c-1.5,1.6-3.2,2.5-5.1,4.1c0-1-0.7-1.6-0.7-2.5c-1-5.1-1.9-10.8-4.7-15.5
    c-1.5-3.4-4.7-6.7-7.3-9.8c-1.9-1.9-4.1-4.1-5.6-6.7c-3.4-5.7-8.8-5.7-11.6-5.7c-0.7,0-1,0-1.5,0c-0.6,0-1,0-1.5,0
    c-3.2,0-5.1-0.7-6.6-1.6c-0.7-1.6-0.7-3.4-1-5.1c-1-5.1-2.5-10.1-1.9-14.9c0-0.7,0-1.6,0.7-1.9c-16.3-2.5-35.5-4.8-20.7-19.6
    c0.7-0.7-10-0.7-21.4-1.9c-0.7,0-0.7,0-1,0c0,0,0,0,0-0.7c-19.5-2.5-40.3-10.1-18.2-32.3c-18.9,19-16.3,0-11.4-21.5
    c1.5,1.6,2.5,3.2,3.4,4.1c0,0,0.7,0,0.7-0.7l0,0c0,0,0,0,0.7,0c1.5-3.2,3.2-6.7,5.1-9.2c2.5-3.2,6-5.7,9.2-8.9l0,0l0,0v-0.7
    c-5.6,0-10.7-0.7-15.8,1.6c4.7-18.3,9.2-34.1,1.9-27.5c4.1-4.1,2.5-10.1-1.9-16.4c3.4-4.8,6-11.4,7.5-19c-6-1.9-12.2-3.4-18.2-5.7
    c0,4.8-0.7,8.9-1.9,12.6c-11.4-8.2-24.8-12.6-29.6-8.2c1.9-1.9-59.1-19-44.3-33.8c-27.4,27.5-113.5,32.9-67.3-13.9
    c10.7-10.8-77.1-6.7-64.2-19c-1,1-20.7,6-38.7,9.2c1.5,3.2,3.2,5.7,4.7,8.9l-1,3.2c0,0.7-0.7,1.6-0.7,1.9c0,0.7,0,1-0.7,1
    c-1.9,0.7-3.4,0.7-4.7,0.7c-3.2,0-5.1-0.7-5.6-8.2c0-1.9,0.7-4.1,1-6c-13.8,2.5-24.8,3.4-22.3,1c3.4-3.4-49.4-33.1-69.2-13.3
    c13.2-13.3-34.6-16.7-57.6-10.1c-4.7-1.9-6-1.6-7.5,3.2c-1,0.7-1.9,1-2.5,1.9c10-10.1-90.2,41.1-58.1,8.9c6-6-6.6-76.8-37.1-46.1
    c-1.9,1.9-4.1,3.4-6,4.8c0.7-1,1-1.6,1.9-2.5c-4.7-1-7.5-1.9-9.7-1.6c-1.5,0-3.2,0.7-4.1,1.6c-10.7-9.2-19.9-32.3-38.7-13.3
    c-43.7,43.9-30.2-56.9-27.4-59.4c-26.5,26.6-29.6-49.7-18-61c1.5-1.6,1-10.1-1.9-20.5c1-1,1.9-3.2,1.5-4.1c-1-1.9-2.5-3.4-4.7-5.1
    c-2.5-7.3-5.6-13.9-9.7-19c6-4.1,11.6-8.2,18-11.6c7.3,6.7,13.2,12.3,18.9,17.4c8.8-4.1,12.2-9.2,9.7-16.7c-1.5-4.1-4.1-8.2-6-12.3
    l0,0c0,0,0,0,0-0.7c0,0,0-0.7-0.7-0.7h0.7c1.9-8.9,1.9-16.7-6-22.4c-7.5-5.7-14.1-3.2-20.7,1.9c1,4.1,1.9,7.5,3.2,11.4v-0.7l0,0
    l0,0c1-1,2.5-1.6,4.1-2.5c-1.5,1-3.2,1.9-4.1,3.2c-3.2,11.4-10,14.9-21.4,11.4c-1.5-0.7-4.1,1.6-7.5,3.2
    c17.3-30.4,14.1-66.4-3.4-57.9c-0.7,1-1,2.5-1.5,2.5c-0.7,0-1.5-0.7-1.9-0.7c-1.5,1-2.5,1.9-4.1,3.4
    c-11.4,11.4-45.9-68.6-54.1-60.4c-9.7,9.8-1-14.2-3.4-24.1c-0.7,1-1,1-1.5,1c-0.7,0-1.5,0-3.2-1.6c1-1,2.5-1.9,3.4-2.5
    c-2.5-2.5-8.8-0.7-21.4,12.3c3.2,0,6,1.6,8.8,3.4h-0.7c1,3.4,1.5,7.3,3.4,9.8c0.7,0.7,1.9,0.7,3.4,0.7c7.3,19,3.4,51.9-6.6,62
    c1.9-1.9-3.2,9.2-9.7,24.1c-0.7,0-1.5-0.7-2.5-0.7c-4.7,6.7-9.2,12.6-13.2,19c1.9,1,3.4,1.9,5.6,3.4l0,0
    C549.2-721.8,549.8-722.5,550.4-722.8z M1750.9,313.3c-2.5-0.7-4.7-1.6-6.6-2.5c0.7,0,1,0,1.5-0.7c6-1,11.6-1.9,18.2-3.4
    c1,3.2,2.5,5.7,4.1,9.2c-1,0-1.9-0.7-3.2-0.7C1759.4,315.5,1755,314.3,1750.9,313.3z M1760.6,346.2c3.2-2.5,6-5.1,9.7-7.5
    c2.5,5.7,4.1,9.2,4.1,11.4c0,1,0.7,1.6-1.5,2.5c-2.5,1.6-4.7,2.5-6.6,2.5c-3.4,0-7.3-1.9-11.4-4.8
    C1756.9,348.7,1758.4,347.1,1760.6,346.2z M1838.9,493.2c2.5-3.2,5.6-5.7,8.2-8.9c3.2-3.2,5.6-6,8.2-8.9l0.7-0.7v-1
    c0.7-7.3-3.2-10.8-6.6-14.2c-1.5-1.6-3.2-3.2-4.1-5.1c4.7,3.2,9.2,6,13.8,9.2c4.7,3.2,9.2,6,13.2,9.2c10,6.7,13.8,14.2,11.6,24.1
    c-1.9,9.2,1.5,14.9,7.3,20l0,0c1,0.7,1.5,1.6,1,3.2c0,1-0.7,2.5-1.9,3.4c-1-1-1.9-2.5-3.2-3.4c-3.4-4.1-6.6-7.5-8.8-11.6
    c-1-1.9-0.7-5.1-0.7-8.2c0.7-2.5,0.7-5.1,0-7.5c-0.7-3.2-1.9-6-3.2-9.2c-0.7-1.6-1-2.5-1.9-4.1l-1.5-4.1l-3.2,3.2
    c-10,10.1-10.7,11.6-4.7,24.6c-3.4,6-9.2,9.2-17.3,11.6C1847.8,504.8,1846.4,502.6,1838.9,493.2z M1858.5,537.4c1,0,2.5,0.7,3.4,1
    c-4.1,2.5-6.6,3.4-8.2,3.4c-0.7,0-1-0.7-1.9-1.6C1854.4,539,1856.3,537.4,1858.5,537.4z M1907.8,476.1c1.5,1.6,2.5,2.5,2.5,3.2
    c0,1-1.5,3.2-4.1,6C1906.2,482.4,1907.2,479.2,1907.8,476.1z M1922,419.8c0,0.7-0.7,1-1,1c-1.5-0.7-2.5-1-3.2-1.6l0,0l0,0
    c-0.7-1-0.7-2.5-0.7-4.8c0-0.7,0-1,0-1.6c0.7-0.7,1-1,1.5-1.6C1920.1,414.8,1921.3,417.2,1922,419.8z M2242.8,320.6
    c0-0.7,0.7-1,0.7-1.6c4.7-1.6,8.8-3.2,13.8-5.1c1.5-0.7,3.4-1,5.6-1.9c0,0.7,0,0.7,0,1c0,1.9,0.7,4.8,0,5.7
    c-1.9,3.2-5.6,5.1-9.7,5.1C2249.4,323.7,2245.9,322.5,2242.8,320.6z M2175.5,420.5L2175.5,420.5c5.1-1.6,9.7-3.2,13.8-3.2
    c1.9,0,4.1,0.7,6,1c-1.5,0.7-3.2,1.6-4.1,1.9c-3.2,1.6-5.6,3.2-8.8,3.2C2181.1,423.9,2178.6,423.2,2175.5,420.5z M2142.2,306.1
    c9.2,4.8,17.3,6.7,23.9,6.7c9.2,0,17.3-3.4,24.8-11.6l1-1l-0.7-1.6c-2.5-7.3-7.5-12.6-11.6-18.3c-5.1-6-9.7-11.6-11.6-19
    c8.8-4.1,27-12.6,27-12.6l-1-2.5c-4.1-9.8-8.8-20-12.2-29c5.6-5.1,10-9.2,14.8-13.9c1,0.7,1.5,1.6,2.5,1.9c3.2,1.9,5.6,4.1,8.2,6
    l3.2,1.9v-0.7c1.9,0.7,4.1,1,6,1c3.2,0,6.6-1,9.7-1.6c2.5-0.7,5.1-1.6,7.5-1.6c0.7,0,1,0,1.5,0c3.2,1.6,4.7,4.8,6,8.2
    c1.9,4.1,4.7,9.2,10.7,11.4c2.5,1,3.2,1.9,3.2,2.5c0.7,1.9-1,5.7-4.7,9.8c-0.7,0.7-1,1-1.5,1.9c-3.2,4.1-6.6,8.2-6,14.9
    c0.7,16.4,0.7,30.7,0,44.6c0,2.5-1.5,5.1-3.2,7.5c0,0.7-0.7,1-0.7,1.6c-0.7,0-1.5-0.7-2.5-0.7c-2.5-0.7-4.7-1-6.6-1
    c-1,0-3.2,0-4.7,1.9c-3.2,4.8-6.6,4.8-11.6,5.1c-2.5,0-4.7,0.7-7.3,1l-1.9,0.7v4.1c0,1.6,0,3.2,0,4.1l-1.5,0.7
    c-8.2,1.9-12.2,3.2-14.8,3.2c-1.9,0-3.4-0.7-7.3-1.9c-3.2-1.6-6-2.5-9.2-4.1C2161.9,322.5,2151.2,318,2142.2,306.1z M2140,416.4
    l6,1.6c-0.7,0.7-1.5,0.7-1.9,0.7C2142.4,418.2,2141.5,417.6,2140,416.4z M2113.5,650.6L2113.5,650.6c0.7,0.7,1,1,1.5,1.6l0,0
    C2113.8,652.1,2113.5,650.6,2113.5,650.6z M2070.2,367.7h0.7c0,0.7,0.7,1.9,0.7,2.5c0,0,0,0-0.7,0
    C2070.7,369.2,2070.7,368.6,2070.2,367.7z M2061.7,414.8c0-0.7,0-1,0-1c0.7,0,1,0,1.5,0C2062.5,413.8,2061.7,414.8,2061.7,414.8z
    M2041.1,646.1c1.9,0.7,3.2,1,3.4,1.9c0.7,1,1,3.2,1.5,5.7C2044.7,650.6,2042.8,648.6,2041.1,646.1z M2016.3,415
    c0-0.7,2.5-1.9,4.1-2.5c0.7-0.7,1-0.7,1.5-1c0,1.9,0.7,4.8,0.7,6.7l0.7,3.4v0.7c0.7,1,1,1.6,1.5,1.9c-1.9-1-3.4-1.6-5.1-2.5
    C2017.3,420.8,2015.7,416.7,2016.3,415z M2008.2,471c1-1.6,5.1-1.9,8.8-1.9l0,0c-0.7,2.5-1,4.8-1.9,6.7c-0.7,1-2.5,2.5-3.4,2.5
    c-1-0.7-2.5-2.5-3.2-4.1C2006.8,472,2007.5,471,2008.2,471z M2003.4,367c1-2.5,4.1-5.1,10.7-8.9c5.6,6,6.6,12.6,3.4,20.5
    c-3.4,1.6-7.3,3.2-11.4,4.8C2004.1,374.6,2002.5,370.1,2003.4,367z M1951.6,307.6c1,0,1.9-0.7,3.2-0.7c4.1-0.7,8.8-1.6,13.2-1.9
    c-0.7,8.9,5.1,13.3,9.2,16.7c1,0.7,1.5,1.6,2.5,1.9c-1.5,2.5-3.4,5.1-5.1,5.1c-1,0-3.2-1-4.7-3.2c-1.9-1.9-4.1-3.4-6-3.4
    c-2.5,0-4.1,1.6-5.6,2.5l-0.7,0.7C1955,319.9,1953.5,314.3,1951.6,307.6z M1923.6,683.7c1.9-1,3.4-1.6,4.7-1.6c0.7,0,1.5,0.7,2.5,1
    c-1,1-2.5,2.5-3.2,3.2l0,0C1926.6,686.6,1925.1,684.9,1923.6,683.7z M1876,682.5c4.1,0.7,8.2,0.7,11.6,1h1l1-1
    c2.5-2.5,5.1-4.8,7.3-6.7c0,5.7,0,11.4-2.5,14.9c-3.2,4.8-1.9,9.2-1,13.3c1.5,5.1,1.9,7.5-2.5,9.8c-1.5-1.6-3.2-3.4-4.1-5.1
    C1880.1,700.1,1874.1,693.5,1876,682.5z M1837.4,829.2L1837.4,829.2c0.7,0,1,0,1,0s0,0,0,0.7l0,0
    C1838.3,829.7,1838,829.2,1837.4,829.2z M1805,577.9c0-3.2,3.2-5.1,5.6-5.1c1,0,1.9,0.7,2.5,1c0.7,0.7,1,1,1.5,1.6
    c4.1,3.2,8.2,6,10,9.8c1.5,2.5,1.9,5.7,2.5,10.1c0.7,2.5,1,5.1,1.5,8.2l1,4.8l10.7-10.1c4.7-4.1,8.2-7.5,13.2-12.3
    c4.1,0.7,8.8,1,13.8,1.9c4.7,0.7,9.7,1.6,15.4,1.9h1.5l1-1c1.9-1.9,3.4-4.1,5.1-5.7c1-1,1.5-1.6,1.9-2.5c1.9,0.7,4.1,0.7,6.6,1
    c4.7,0.7,9.2,1.6,11.6,3.4c3.2,1.9,5.1,6,7.3,10.1c0.7,1.6,1.5,2.5,1.9,4.1c-11.6,19-2.5,35.7,6,50.5c1,1.6,1.5,3.2,2.5,4.8
    l-4.7,2.5c-5.6-1.9-11.6-4.8-19.5-7.3c-1.5-4.1-2.5-7.3-4.1-11.4l-1.5-4.1l-2.5,0.7c-3.2,1-5.6,3.4-7.3,5.1c-1,1.6-2.5,2.5-3.4,2.5
    c-0.7,0-1-0.7-1.9-1c-1.9-1.6-3.4-5.7-4.7-8.9c-0.7-1-0.7-1.6-1-1.9c0-0.7,0-1,0.7-1.6c0.7-1,0.7-1.9,0.7-3.2
    c0-13.3-6.6-23.4-18-28.2c-3.2-1-6-1.9-9.2-1.9c-8.8,0-16.3,5.7-20.4,15.5c0,0-1,0.7-1.5,1c-8.8-9.2-10-9.8-26.1-16.7
    c0-1.6,0-3.2,0-4.8C1805,586.7,1805,582,1805,577.9z M1777,281c-3.4-3.4-6.6-7.3-7.3-12.3c8.8-0.7,18-1,24.8-1.6
    c8.8,5.7,11.4,13.9,13.8,22.4c1.9,6,4.1,12.6,8.8,17.4c-3.2,8.2-5.6,15.5-8.2,23.1h-0.7c-1-4.1-1.9-7.5-3.2-12.3
    c-0.7-2.5-1.9-9.2-1.9-9.2l-1-0.7c-1-0.7-2.5-1-3.4-1.6c-6.6-2.5-12.6-4.8-13.8-13.9C1783.6,288.7,1780.5,284.6,1777,281z
    M1769.1,500.7L1769.1,500.7c6,0,8.2,0.7,11.6,1.9c-1,0.7-1.9,0.7-3.2,0.7C1774.7,503.3,1772.2,502.3,1769.1,500.7z M1764,392.6
    c1.5,0,3.2-0.7,4.7-0.7l0,0c5.1,0,9.7,1.6,14.1,4.1c-3.2,0.7-6,0.7-9.2,0.7c-2.5,0-5.1,0-7.5-0.7c-1.5,0-1.5-0.7-1.5-1
    C1764,394.9,1764,393.5,1764,392.6z M1744.9,577.5c0.7-0.7,1.5-1.6,2.5-1.9c0.7-0.7,0.7-0.7,1-1c0,0.7,0.7,1,0.7,1.6
    c1,1.9,1.5,3.4,1.5,4.8c-0.7,0.7-1.5,1-1.9,1C1746.7,581,1745.8,579.1,1744.9,577.5z M1735.1,439.1c1.5-1,3.4-1,5.6-1
    c2.5,0,5.6,0.7,8.8,1c2.5,0.7,5.1,1,8.2,1.6c-2.5,1.6-5.1,2.5-8.2,2.5C1745.2,442.9,1740.2,442,1735.1,439.1z M1720.4,352.2
    c0-3.2,0.7-5.1,1.5-6c0.7-0.7,1.5-1.6,3.4-1.6h0.7c1.5,0,2.5,0.7,3.2,1c0.7,0.7,1,1.9,1,3.2C1727,349.6,1723.8,351.2,1720.4,352.2z
    M1725.4,424.9c0.7-1,1.5-1.6,1.9-2.5c0.7,0.7,0.7,0.7,0.7,0.7C1727.9,423.2,1727.3,424.6,1725.4,424.9z M1722.2,676.2
    c1-0.7,1.9-1,3.2-1c0.7,0,1,0.7,1,1c0,0.7-1.5,1.6-2.5,1.6c-0.7,0-0.7,0-0.7,0C1722.9,677.1,1722.2,676.7,1722.2,676.2z
    M1711.5,423.2c-1,0-2.5-0.7-3.4-0.7c-6-1-11.4-1.6-13.2-8.9c1.5-0.7,3.2-0.7,4.1-1c5.1-1.6,10-2.5,15.4-2.5l0,0
    c4.1,0,7.3,0.7,10.7,2.5l-2.5,2.5C1717.2,416.4,1714.1,419.8,1711.5,423.2z M1691.8,442.9c4.7-2.5,8.8-5.1,12.6-7.5
    c2.5-1.6,5.6-3.4,8.2-5.1c0.7,0,1,0.7,1.5,0.7c-1.9,8.2-8.8,11.6-15.8,15.9c-1.5,0.7-2.5,1.6-4.1,1.9
    C1693.9,446.4,1692.7,444.7,1691.8,442.9z M1689.3,366c0-0.7,0.7-1,0.7-1.6c3.4-4.1,6-6.7,7.5-6.7c1,0,1.9,0.7,4.7,1.9
    c1.9,1.6,3.4,4.8,3.4,6c-1,2.5-1.9,2.5-4.1,2.5c-1.5,0-3.2-0.7-5.1-1c-1,0-1.5-0.7-2.5-0.7C1692.7,367,1690.8,366.7,1689.3,366z
    M1685.7,298.4c0.7,0,1,0,1.5,0c4.7,0.7,9.2,1,13.8,1.6c9.2,1.6,19.5,3.2,28.9,5.1c0.7,0.7,0.7,1.9,1,2.5c0,0.7,0.7,1.6,0.7,1.9
    c-1.9,8.2-6,12.6-13.2,14.2c-5.1-2.5-11.6-6-18-9.8l-0.7-0.7c-1.5-1-3.2-2.5-4.7-3.4C1689.3,307.3,1685.2,304.2,1685.7,298.4z
    M1677.6,550.7c1,3.4,1.9,6.7,2.5,9.8c-1,1-1.5,1.6-1.9,1.6c-0.7,0-0.7-0.7-1-1.6C1676,557.9,1675.4,554.5,1677.6,550.7z
    M1474.4-198.5v0.7c-1,6-1.9,7.5-5.6,7.5l0,0c0-6.7,0-12.3,4.7-18.1v0.7C1473.4-203.8,1474-201,1474.4-198.5z M1500.4-213.6
    c0.7,0.7,1,1,1,1s0,0.7-0.7,1.6C1499.8-211.7,1499.8-211.7,1500.4-213.6z M1513-259.2c5.1,0,9.2,1.9,13.8,5.1c-0.7,0-1.5,0-1.9,0
    c-4.7,0-9.7,1-15.8,2.5h-0.7c-1,0-1.5-0.7-2.5-1c-1-1-1.5-2.5-1.9-4.1C1507-258.2,1510.2-259.2,1513-259.2z M1560.5-287.9
    c-1,0.7-1.5,1-1.9,1.9c-1.9,2.5-1,5.7-0.7,8.2c0,0.7,0.7,1.6,0.7,1.9c-3.2,0-5.6,0.7-8.8,0.7c-8.2,0.7-16.7,1.6-24.8,1.6
    c0,0,0,0-0.7,0c-1.9,0-3.4-0.7-4.7-1.9c-0.7-1-1.5-1.9-1.5-4.1c0-4.1,2.5-9.2,4.1-9.8c5.6-1.9,11.6-3.4,18-3.4l0,0
    C1546.6-292.6,1553.2-291.1,1560.5-287.9z M1660.2,598.4c1-0.7,1.5-0.7,2.5-1c0,0,0,0,0,0.7c-0.7,0.7-1,1-1.5,1.9
    C1660.6,599,1660.6,599,1660.2,598.4z M1652.1,463.5c1-1,1.9-1.9,3.2-1.9c0.7,0,1.5,0,2.5,0.7c3.4,1.6,4.7,3.4,3.4,7.5
    c-0.7,0-0.7,0-1,0l-1-0.7C1657.8,465.4,1654.6,463.7,1652.1,463.5z M1635.8,541.2c0.7,0,1,0,1.5,0l0,0c0,1.6,0,3.2,0,4.8
    c0,0-1,0-1.5,0C1635.8,544.1,1635.8,542.2,1635.8,541.2z M1540.7-184.6c0.7-10.1,1.5-16.4,12.2-17.4c0.7,1,1,1.9,1.5,3.4
    c1.5,3.4,3.2,7.5,5.1,10.8c1,1.6,1.9,2.5,3.2,3.4c2.5,2.5,4.1,4.1,2.5,7.3C1557.1-177.9,1549.1-180.5,1540.7-184.6z M1530.9,24.4
    c0-0.7,0.7-0.7,1-1c2.5,1,4.7,2.5,6.6,3.4c-0.7,0-1,0-1.5,0c-1.9,0-4.1-1-5.1-1.9C1530.9,24.6,1530.9,24.4,1530.9,24.4L1530.9,24.4
    z M1524.3-44.9c0.7-1,1-1.9,1-2.5c0,0,0.7,0.7,1,1c0.7,0.7,0.7,2.5,0.7,3.4c-0.7,0.7-0.7,0.7-0.7,0.7c-0.7,0-1-1-1.5-1.9
    C1525-43.9,1525-44.2,1524.3-44.9z M1507-127.4c-3.4-1.6-6.6-1.9-10-1.9c-5.1,0-9.7,1.6-13.8,3.2c-3.4,1.6-7.5,2.5-12.6,3.4
    c-1.5,0.7-3.4,1-5.1,1c-0.7-1.9-1.5-4.8-1.9-6.7c-1-4.1-2.5-7.5-3.4-11.4c-3.2-7.5-9.7-11.6-18-11.6c-1.5,0-2.5,0-4.1,0.7
    c-4.1,0.7-8.2,3.4-9.7,7.3c-1.5,3.2-1.5,6.7,0.7,9.8l1,1.6c2.5,4.1,4.7,8.2,5.1,12.3c-5.1-1-8.8-1.6-9.7-2.5
    c-1.5-1-1.5-4.1-1.5-10.1v-1c-0.7-7.3-0.7-13.9-6-20c-1-1-1.5-3.4-1.5-6.7c0-1.9,0.7-4.1,1-5.1c0.7,0,1.5,0,1.9,0
    c1.9,0,4.1,0.7,6,1c1,0.7,2.5,1.6,4.1,3.2c0.7,0.7,1.5,1,1.9,1.9l1,1l1.5-0.7c5.6-1.9,11.4-3.4,16.7-5.7c3.2-1,5.6-1.9,8.2-2.5
    c2.5,1,4.7,3.2,6.6,4.8c4.1,3.2,8.2,6,13.2,6c3.4,0,6.6-1.6,10-4.1l1.5-1l-0.7-1.9c-1-3.2-1.9-6-3.4-9.2c-0.7-1.9-1.5-3.4-1.9-5.7
    c1-0.7,2.5-1.6,3.4-1.9c5.6-3.2,10.7-6,16.7-6l0,0c1,0,1.9,0,2.5,0c7.3,1,14.8,2.5,22.1,4.8c1.5,0.7,3.2,0.7,4.1,1
    c-7.3,12.6-6.6,16.7,1.9,27.2c3.2,4.1,6.6,7.5,10,11.6c0.7,0.7,1,1.6,1.9,1.9l-0.7,0.7c-5.1,6.7-8.8,11.6-16.3,11.6
    c-0.7,0-0.7,0-1,0c-0.7,0-1,0-1.5,0c-2.5,0-4.7,0-7.3,0.7c-1.9,0-4.7,0.7-6.6,0.7C1511.1-126.1,1508.9-126.7,1507-127.4z
    M1474.9-59.7c1,6,1.5,11.6,2.5,18.3c0,0,0,0-0.7,0c-1.5,1-3.2,1.6-4.1,1.6l0,0c-1.9,0-4.7-0.7-6.6-1.6c-0.7,0-1-0.7-1.5-0.7
    c-1-1.9-1.9-4.1-3.2-5.7c-2.5-5.1-5.1-9.2-3.4-14.2c0.7-1,1-2.5,1.9-4.1l0.7-0.7C1465.2-63.8,1470.3-61.6,1474.9-59.7z
    M1470.3-87.9c-4.7-1-7.3-1.9-9.2-3.2c-0.7-0.7-1.5-3.2-1.5-5.1c0-5.1,4.1-9.2,9.2-9.2h0.7c1.5,4.8,2.5,10.1,4.7,18.3
    C1472.5-87.2,1471.5-87.9,1470.3-87.9z M1490.7,87.9c2.5-1,4.7-1.6,6.6-2.5c0,0.7,0.7,1.6,0.7,1.9c-1,1.6-3.2,2.5-5.6,3.4
    c-0.7-0.7-0.7-1-1-1.9C1491.3,88.8,1490.7,88.2,1490.7,87.9z M1474.9,62.6c0,0-1.5,0-4.7-2.5c0-0.7,0-1,0-1.6
    c13.8-8.9,13.8-23.1,13.8-35.4c0-1.6,0-3.4,0-5.7c0-3.4-0.7-7.3-0.7-10.8V5.9c3.2-0.7,5.6-0.7,7.5-0.7l0,0c6.6,0,8.2,1.9,9.7,5.7
    c1.5,5.7-0.7,8.2-8.2,11.4c-1.9,1-2.5,3.2-3.2,5.1c0,0.7-0.7,1.6-0.7,1.9l-0.7,1.9l1.5,1c2.5,1.9,5.6,4.1,8.2,6.7
    c2.5,1.9,4.7,3.4,7.3,5.7c-1.5,1.9-3.2,4.1-1.9,6c1.5,3.4,0,5.1-4.1,7.5l0,0l-0.7,0.7l-1,0.7l0,0c-2.5,1.6-3.4,1.9-4.1,1.9
    c0-0.7-0.7-1-1.5-3.4l4.7-4.1l-8.8,1l0,0h0.7l0.7,1.6l-1-1.6c-1,0.7-1.9,1-3.2,1.9C1480.6,60.1,1478.1,61.6,1474.9,62.6z
    M1823.7,42.9c0,0,0,1.5-2.5,4.7c-0.7,0-1,0-1.6,0c-9-13.8-23.2-13.7-35.5-13.6c-1.6,0-3.4,0-5.7,0c-3.4,0-7.3,0.7-10.8,0.8l-0.7,0
    c-0.7-3.2-0.7-5.6-0.7-7.5l0,0c0-6.6,1.9-8.2,5.7-9.7c5.7-1.5,8.2,0.7,11.4,8.1c1,1.9,3.2,2.5,5.1,3.2c0.7,0,1.6,0.7,1.9,0.7
    l1.9,0.7l1-1.5c1.9-2.5,4.1-5.6,6.7-8.2c1.9-2.5,3.4-4.7,5.7-7.3c1.9,1.5,4.1,3.2,6,1.9c3.4-1.5,5.1,0,7.5,4.1l0,0l0.7,0.7l0.7,1
    l0,0c1.6,2.5,1.9,3.4,1.9,4.1c-0.7,0-1,0.7-3.4,1.5l-4.1-4.7l1,8.8l0,0l0-0.7l1.6-0.7l-1.6,1c0.7,1,1,1.9,1.9,3.2
    C1821.1,37.2,1822.6,39.7,1823.7,42.9z M1455.5,161.5c0.7,0,0.7-0.7,1-0.7c1.5,1.6,3.2,3.2,4.7,4.8l0,0c-1.9-1-3.4-1.6-5.1-2.5
    C1455.5,163.1,1455.5,161.8,1455.5,161.5z M1413.9,84.7l0.7-0.7c6-4.8,12.2-9.2,18-9.2c1.5,0,2.5,0.7,4.1,1
    c5.6,3.2,11.4,4.8,18.2,4.8c4.7,0,8.8-0.7,13.2-1c1,0,1.5,0,2.5-0.7c1,5.1-0.7,9.8-2.5,14.2c-0.7,1-1,2.5-1.5,4.1
    c-5.1,0-10,0.7-15.8,0.7c-6.6,0-13.2,0.7-19.9,0.7C1426.1,93.9,1420.5,89.8,1413.9,84.7z M1779.2,68.9l0.7-0.7
    c6-4.8,12.2-9.2,18-9.2c1.5,0,2.5,0.7,4.1,1c5.6,3.2,11.4,4.8,18.2,4.8c4.7,0,8.8-0.7,13.2-1c1,0,1.5,0,2.5-0.7
    c1,5.1-0.7,9.8-2.5,14.2c-0.7,1-1,2.5-1.5,4.1c-5.1,0-10,0.7-15.8,0.7c-6.6,0-13.2,0.7-19.9,0.7C1791.4,78.1,1785.8,74,1779.2,68.9
    z M1403.2-319.2L1403.2-319.2c2.5-6,5.6-6,6.6-6c1.5,0,3.4,0.7,5.6,1.9c2.5,1.6,5.6,3.4,8.2,5.1c-3.2,0.7-6.6,1.6-10,1.9l-5.1,1
    l3.4,3.4c12.6,12.3,16.7,16.7,23.3,16.7c3.2,0,6-1,10.7-2.5l4.1-1l-6-7.3c-3.2-3.4-6-6.7-8.8-9.8l3.4-0.7l-0.7-0.7
    c0.7,0,1.5,0,1.9,0l0,0c4.7,0,8.2,2.5,11.4,8.2c1.9,3.4,5.6,5.7,9.2,7.5c0.7,0,0.7,0.7,0.7,0.7l-1.9,1.6l5.1,1.6
    c1,4.8,1.9,10.1,3.4,14.9c-4.7,5.1-8.2,9.2-11.4,12.6c-0.7-0.7-1.5-1.6-1.9-1.9c-3.2-3.2-6.6-6.7-10.7-7.3
    c-8.8-1-14.1-4.8-20.4-11.4c-3.4-4.1-8.8-8.2-14.1-11.4C1402-305.3,1400.7-310.4,1403.2-319.2z M1355.8-149.2
    c-0.7,1.9-1.9,5.1-4.1,5.1c-0.7,0-1.5,0-1.9-0.7c-3.2-1-6-3.4-9.2-6c-1.5-1.6-3.4-2.5-5.6-4.1l-3.4-2.5l-0.7,4.8
    c0,1.9-0.7,4.1-0.7,6c-0.7,3.4-0.7,6.7-1,10.1h-0.7c-0.7,0-1,0-1.5,0c-3.4,0-6-1.9-9.2-7.3c-1-1.6-1.5-2.5-2.5-4.1
    c-3.2-4.8-5.1-8.2-3.4-11.6l0,0c1-3.2,5.6-6.7,7.3-6.7l0,0C1332.8-164.1,1347.3-160.8,1355.8-149.2z M1354.2-59.7
    c1,0,1.9,0,2.5,0.7c0,0.7,0,1,0.7,1c-0.7,0-0.7,0.7-1,0.7C1355.1-58.2,1354.8-59.1,1354.2-59.7z M1358-24.4c0,1,0,1.9-0.7,3.2
    c-0.7,0.7-1,1-1.5,1C1356.3-21.8,1356.6-23.4,1358-24.4z M1358.3-189.7c-1.9,4.1-5.1,7.5-8.2,11.4c0,0.7-0.7,0.7-0.7,1
    c-3.2-4.1-4.1-7.5-3.4-10.8c0.7-2.5,1.9-4.8,5.1-5.7c0,0,0.7,0,1,0c1.5,0,3.2,0.7,4.1,1C1356.6-192.2,1358-190.5,1358.3-189.7z
    M1373.1,13.6c-1-1.6-0.7-4.8,1-9.2c1.5-4.1,3.2-8.2,5.6-12.6c0.7-0.7,0.7-1,1-1.6c0,0.7,0,0.7,0,1c0.7,4.8,1,8.2,1.9,12.3
    c0.7,3.4,1.5,7.5,1.9,11.4c0.7,1.6,0.7,2.5,1,4.1C1378.1,17.1,1374.6,15.5,1373.1,13.6z M1355.1,118.8c1-1,2.5-1.9,3.4-3.4
    c3.2-3.2,6-5.7,9.2-8.9c0.7,0,1.5-0.7,1.9-0.7c0.7,0,1,0,1.5-0.7c1.5,1.9,3.2,4.1,4.7,6c1,1.6,1.9,3.2,3.2,4.1
    c-1.5,9.8-2.5,16.4-3.4,23.1l0,0l-1,1.9c-1.5,4.1-3.4,8.9-5.6,13.9c-1,1.9-1.9,4.8-3.2,7.3c-0.7-0.7-1.5-0.7-1.9-0.7
    c-2.5-1-5.1-1.6-6-3.2c-3.2-4.1-3.2-9.2,0-12.6c1.5-1.6,3.2-3.2,5.1-4.8s3.4-3.2,5.1-4.8s1.5-4.8,1.5-6c0-1.6-1-2.5-1.5-3.2
    c-2.5-1.9-6.6-1.9-8.2-1.9l0,0c-1.5,0-3.4,0.7-5.1,1c-1,0.7-2.5,0.7-3.2,0.7C1350,124.5,1351.4,122.6,1355.1,118.8z M1776.8,58.8
    c-0.7-6,0-7.5,3.4-8.9h0.7c2.5,1,5.6,1.9,8.2,3.2C1785.6,55.4,1781.5,57.6,1776.8,58.8z M1339.1,190.5c0.7-0.7,0.7-0.7,1-1
    c1,0,1.5,0.7,1.9,1c0.7,0.7,0.7,1.6,0.7,3.2c-0.7,0-0.7,0.7-1,0.7C1340.7,193.1,1339.4,191.5,1339.1,190.5z M1287.2,55
    c1-0.7,2.5-1.6,3.4-1.9c4.1-2.5,8.2-4.8,11.6-7.3h0.7h0.7c2.5,1.9,5.1,4.1,9.2,7.3l1.5,1l1.5-1c4.1-1.9,8.2-4.8,12.2-7.3
    c4.7-2.5,9.7-5.7,14.1-8.2c3.4-1.9,7.3-3.2,10-3.2c4.7,0,9.2,1.9,13.2,5.7c2.5,2.5,4.7,5.1,7.5,8.9c0,0,0,0.7,0.7,0.7
    c-6.6-1.6-12.2-2.5-18-3.4l-1.5-0.7l-1,1c-1.9,1.9-4.7,4.1-7.3,5.7c-5.6,4.1-11.6,8.9-14.1,14.9c-4.7,10.8-12.2,12.3-21.4,13.9
    c-1.9,0.7-4.7,0.7-7.3,1.6c-0.7-10.8-5.6-18.1-15.8-22.4c-0.7-0.7-0.7-1.9-1-3.2C1287.5,56,1287.5,55.6,1287.2,55z M1309.5-7.5
    c0,0-0.7,0-1-0.7c-0.7-0.7-1-2.5-1-4.1c1,1,2.5,1.6,3.4,2.5C1310.4-7.9,1309.8-7.5,1309.5-7.5z M1284.9-109.6
    C1284.9-109.6,1285.6-109.6,1284.9-109.6c4.7,2.5,8.8,5.1,13.2,6c4.1,1,8.8,1,11.6,1c1.5,0,3.4,0,5.1,0s3.4,0,5.1,0
    c0.7,0,1,0,1.5,0h1.5l1-1c1.5-1.6,3.2-3.4,4.7-5.7c1-1,1.9-2.5,3.2-3.4c4.7,3.2,8.8,5.7,12.6,8.9c1.9,1.6,4.1,2.5,6,4.1
    c0.7,2.5,1,5.1,1.5,7.5c0.7,1.9,1,4.1,1,6c-4.1,2.5-7.5,5.7-11.4,8.2c-1.5,1-3.2,1.9-4.7,3.4l-0.7-4.8l-4.7,7.5
    c0,0.7-0.7,0.7-0.7,1l-0.7,0.7l0,0v0.7c-1,1-1.9,1.9-3.4,3.2c-1.5,1-2.5,2.5-4.1,3.4l-2.5,2.5l7.5,5.1l1.5-2.5
    c1-1.9,2.5-4.1,3.4-5.7c0.7-0.7,0.7-1,1-1.9c0.7,0.7,1,1.6,1.5,1.9c-1,9.2-3.4,18.3-11.4,24.1c-15.4-12.3-15.4-12.3-17.3-26.6
    c-1-5.7-1.9-9.2-6-13.9C1293.8-89.5,1288.1-98.9,1284.9-109.6z M1187.1-218.7c-0.7,0-1,0.7-1.5,0.7c-1.5,0.7-3.2,1-3.4,1
    c-0.7-0.7-1-1-1.5-1.9c1.5-1,3.2-1.6,4.7-1.6C1185.2-220.9,1186.2-219.9,1187.1-218.7z M1212.6-85.7c-3.4,3.2-6.6,5.7-9.7,8.2
    c-1,0.7-1.5,1.6-2.5,1.9c-1.5-3.4-7.3-14.9-8.2-17.4c4.1,1.6,8.2,2.5,13.2,4.1C1207.6-87.2,1210.2-86.9,1212.6-85.7z M1225.8-125.1
    L1225.8-125.1L1225.8-125.1c-1.5,0.7-3.2,0.7-4.1,0.7h-0.7c0,0,0,0,0-0.7C1222.7-125.1,1224.3-125.1,1225.8-125.1z M1228.4,57.5
    c-1-0.7-2.5-1.6-3.4-2.5l0.7-0.7C1226.5,55.6,1227.4,56.5,1228.4,57.5z M1269.6-14.5c0,0.7,0,1.6,0,1.9c-1.9,0.7-3.4,1.6-5.6,2.5
    c-3.2,1.6-6,3.2-8.2,3.2c-1.5,0-3.2-0.7-5.1-4.1c-1.9-4.1,0-7.3,6-12.3c1-1,2.5-2.5,3.4-3.4c0.7-0.7,1.5-0.7,2.5-0.7
    c3.2,0,6.6,1.9,7.3,4.1C1270.2-21.2,1270.2-18.3,1269.6-14.5z M1259.5,226c0,0,0.7,0,1,0h0.7c1.9,1.6,3.4,3.2,5.1,4.8
    c-1,1-1.5,1.9-2.5,3.2c-0.7,0.7-1,1.6-1.9,1.9c-1-1.6-2.5-3.4-3.2-5.1C1258.6,229.5,1258.9,227.9,1259.5,226z M1233.4,243.3
    c3.2,0,6,1,9.2,1.6c3.2,0.7,6.6,1.6,9.7,1.6c3.2,0,6-0.7,8.8-1.9l0,0l0.7-0.7c0.7-0.7,1.5-0.7,1.9-1v-0.7l5.1-5.7l0,0
    c0,0,1-1,1.5-1.6c1,1.6,6,8.9,7.5,10.8c-8.8,1.6-17.3,2.5-25.5,4.1c-5.1,0.7-10,1.6-15.4,1.9c-0.7-1.6-1-3.2-1.9-4.8
    C1234.6,245.9,1234,245,1233.4,243.3z M1231.6-121c1.5-1,2.5-1.9,3.2-4.1c0-0.7,0-1.6,0-1.9c1-0.7,1.9-1,3.2-1
    c5.6,1.6,10.7,4.1,15.8,6.7c1.5,0.7,2.5,1,4.1,1.6c3.2,9.2,4.7,14.2,4.7,18.1c0,3.8-1.9,6.7-6,13.3l-0.7,1l0.7,1.6
    c1.5,3.4,4.1,6.7,6,9.8c4.7,6,8.8,11.6,7.5,20.5c-0.7,0-0.7-0.7-1-0.7c-3.2-1.9-6-3.4-9.7-4.1c-1.5,0-3.4-0.7-5.6-0.7
    c-2.5,0-4.7,0-7.3,0.7c-0.7,0-1,0-1,0C1246.9-82.8,1242.1-102.8,1231.6-121z M1213.6,84.7c1,1,2.5,1.6,3.4,1.6c1.9,0,3.4-1,4.7-1.9
    c0.7-0.7,1.5-1,1.5-1c0.7,0,1,0,1.5,0c0.7,0,2.5,0,3.2,4.1c0,1,0,1.6-0.7,1.9s-1.9,1-4.1,1l0,0
    C1217.7,90.3,1215.8,90.3,1213.6,84.7z M1202,16.4c-0.7,0.7-1,0.7-1.5,0.7c-1,0-2.5-1.6-4.1-3.2c-1.5-1.9-3.4-3.4-6.6-4.8
    c-9.2-1.9-18.9-4.1-27.4-12.6c0.7,0,1-0.7,1.5-0.7c3.2-1,5.6-1.9,8.2-3.4c5.1-4.1,10.7-6,16.7-6l0,0c6.6,0,13.2,1.9,18.9,4.1
    c3.4,1.6,6,3.2,6.6,5.7c0.7,1.6,0.7,4.1-1,6C1209.5,9.5,1206.1,13.9,1202,16.4z M1172.3,57.5c1.9-1,3.4-1.9,5.1-2.5
    c0.7,0.7,1,1,1.5,1.6l0,0c-1.5,1.6-3.2,2.5-4.7,3.2C1174,59.1,1173,58.4,1172.3,57.5c0,0,0,0-0.7-0.7
    C1172.1,57.5,1172.3,57.5,1172.3,57.5z M1089.4,86.6c3.2,0,5.6,0.7,7.5,0.7c3.2,0.7,6,0.7,8.8,0.7c0.7,1.6,1,3.2,1.5,4.8
    c1.5,5.1,2.5,9.8,5.1,13.9c2.5,4.8,6,9.2,9.7,13.3c1.5,1.9,3.2,4.1,4.7,6l1,1h1.5c7.3-0.7,15.4-0.7,25.5-1
    c7.5,24.6,28,36.4,49.4,44.9c-0.7,1-1,1.9-1.5,2.5c-1.5,3.4-3.2,6.7-4.1,9.8c-1,3.2-1,6-1.5,9.2c-0.7,2.5-0.7,5.1-1,7.5
    c-1.5,7.5-7.5,10.1-12.2,10.8c-6.6,1-13.8,1.6-20.4,1.6c-1,0-1.9,0-3.4,0l0.7-0.7c2.5-1.9,4.7-4.8,6.6-7.3c1-1.6,1-3.4,1-5.1
    c0-0.7,0-1,0-1.6v-3.2l-3.2,0.7c-0.7,0-1.5,0-2.5,0.7c-3.2,0.7-5.6,0.7-6.6,3.2s-1.5,5.7-1.9,8.9c-4.7-6-8.8-12.6-8.2-20.8
    c0.7,0,0.7,0,1,0c1,0,1.9,0,3.4,0l0,0c0.7,0,1,0,1.9,0c6.6,0,11.4-1.6,13.2-5.1c2.5-3.2,2.5-7.5,1-13.3
    c-2.5-8.2-8.2-13.9-15.8-15.5c-9.2-1.6-15.8-7.3-22.9-14.2c-11.4-11.4-22.1-23.1-31.4-35.7c-2.5-3.2-3.4-6.7-5.6-11.4
    C1090.2,90.3,1089.9,88.8,1089.4,86.6z M1145.3,93.2c-1,0-1.5-0.7-2.5-1l0,0c0-1.6,0.7-3.4,1-5.1l0,0c0.7-1,1-2.5,2.5-4.8
    c1,1.9,1.9,4.1,2.5,6c0.7,1,0.7,1.6,1,2.5C1147.8,92.3,1146.6,93.2,1145.3,93.2z M1112.3,31.9c-0.7,0-2.5-1.6-4.1-2.5
    c-0.7-0.7-1-0.7-1.5-1c0.7-0.7,1-1,1.9-1c1.9-1.6,4.1-2.5,5.6-3.4c0,0,0,0,0,0.7c0.7,0.7,1.5,1.9,1.5,2.5
    C1115.4,28.7,1113.2,31.3,1112.3,31.9z M1077.7,128c1.5-4.8,3.4-5.1,5.6-5.1l0,0c0.7,0,1,0,1.5,0c0,0,0,0,0,0.7
    c0,1,0.7,2.5,0.7,3.2c-1,2.5-2.5,5.1-5.6,5.1c-0.7,0-0.7,0-1,0C1077.7,130.8,1077.1,129.2,1077.7,128z M1106.4-30
    c0,1-0.7,1.6-1.5,1.9c-4.1-3.4-8.8-6.7-13.2-10.1l0,0c-1-0.7-1.9-1-3.2-1.6c-0.7-0.7-1.5-0.7-1.5-1c-6-6-8.2-18.1-3.4-26.3
    c0.7-0.7,4.1-1,6-1.6c0.7,0,1.5,0,1.9-0.7c-0.7,3.4-0.7,6.7-1,9.8c-0.7,3.2-0.7,6-1,9.2v1.6l1,1c1,1,1.9,1.6,2.5,2.5
    c3.4,3.4,7.5,7.3,11.4,10.8C1105.7-31.9,1106.4-30.9,1106.4-30z M1132.7-9.5L1132.7-9.5c-0.7,1.9-0.7,3.4,0,4.8c0,0.7,0,1.6,0,1.9
    v0.6l0.7,0.7c1.5,1.9,3.4,3.4,5.6,5.1c2.5,1.6,4.7,3.2,5.1,5.7c-8.8-3.4-17.3-6.7-26.5-10.1l-8.8-3.4l-1,1.9
    c-7.3,11.6-18.9,14.2-31.1,17.4c-1.5,0.7-3.4,1-5.1,1c-1,0.7-1.9,0.7-3.2,0.7c-3.4,0-6.6-1.9-9.2-6c48.4-10.8,49.4-12.3,63.2-28.7
    c1-1,1.9-2.5,3.4-4.1c5.6,1.6,9.7,2.5,10,3.4C1135.3-18.3,1134.3-14.5,1132.7-9.5z M1155.7-43.9c-1.5,0-3.2,0.7-4.7,0.7
    c-4.7,0.7-8.8,1-12.2,1c-0.7,0-0.7,0-1,0c-1,0-2.5-1.9-3.2-2.5l0,0c0-1,1.5-3.4,2.5-4.1c2.5-1.6,5.1-1.9,7.3-1.9l0,0
    C1147.5-50.9,1151-49.9,1155.7-43.9z M1163.3,36c0.7,0.7,0.7,0.7,0.7,1.6c-1.5,7.3-8.2,9.2-14.8,11.4c-1,0.7-1.9,0.7-3.4,1
    c-1,0.7-1.5,0.7-2.5,0.7c-3.4,0-6-2.5-7.3-6.7c0-1-0.7-1.9-1-3.2h0.7l1-5.1c-0.7-0.7-1-0.7-1.9-0.7l0.7-1h-1.5
    c0.7-1.6,1.5-1.6,1.9-1.6c1,0,1.9,0,3.4,0.7s2.5,0.7,4.1,0.7h0.7c1.9,0,3.4,0,5.1,0C1160.1,32.9,1162.6,35.1,1163.3,36z
    M1150.7-191.9c0.7-1,1.9-1,3.4-1.6c0.7,1.6,0.7,2.5,0,3.2c-0.7,1-1,1.6-2.5,1.9c-0.7,0-1-0.7-1-1
    C1150-190.2,1150-191.2,1150.7-191.9z M1391.3,47.8L1391.3,47.8L1391.3,47.8C1391.3,47.4,1391.3,47.4,1391.3,47.8
    C1391.3,47.4,1391.3,47.8,1391.3,47.8z M1656.5,661.9L1656.5,661.9L1656.5,661.9L1656.5,661.9L1656.5,661.9z M2174.5,355.9
    c0,0,0.7,0,0.7-0.7C2175.2,355.9,2174.5,355.9,2174.5,355.9L2174.5,355.9z M1929.2,654.7L1929.2,654.7
    C1929.2,654.3,1929.2,654.7,1929.2,654.7L1929.2,654.7z M1274.9,261C1274.9,261,1275.2,260.7,1274.9,261L1274.9,261z M1023.3-10.1
    c0,0,0.7-1,2.5-2.5C1024.8-11.6,1023.6-10.4,1023.3-10.1z M1224.9,279.1c0,0.7,0.7,1,1,1.6l-9.7,3.4h-0.7l0,0l0,0
    C1219.2,282.5,1221.7,280.7,1224.9,279.1z M1662.8,865.4C1662.2,865.4,1662.2,865.2,1662.8,865.4c0-0.7,0-0.7,0.7-0.7
    c0,0.7,0.7,0.7,0.7,1C1663.1,865.4,1662.8,865.4,1662.8,865.4z M1662.2,886.6c-5.6-1-11.6-2.5-17.3-3.2c3.2-6,5.6-11.4,8.2-15.9
    c2.5-4.8,5.6-3.4,9.2-1.9C1670.4,871.7,1668.5,878.4,1662.2,886.6z M2256.9,694.5c0-1,1.5-1.6,2.5-2.5c0,1-0.7,1.6-0.7,2.5
    c0,1.6,0,3.4-0.7,5.1v0.7l0,0l0,0C2258.2,697.6,2256.9,696,2256.9,694.5z M2269.2,425.8c0.7,0,1,1,1.5,1.6c-0.7,0-1.5,0.7-1.9,1
    c-0.7-0.7-1-1-1.5-1.6C2267.6,425.8,2268.9,425.4,2269.2,425.8z M2256.6,638.2c-1-0.7-2.5-0.7-3.4-1c1.5,0,3.2-0.7,4.1-0.7
    C2256.9,637.3,2256.6,638.2,2256.6,638.2z M1043.1,99.9c-1.5,0.7-3.2,1.6-4.7,1.9c0-0.7,0.7-1.9,0.7-1.9
    C1040.6,99.9,1042.1,99.9,1043.1,99.9z M1055.4,150.8c1,0,1.9,0,4.1-0.7c-1.5,1-1.9,1.6-2.5,1.6
    C1056.3,152.3,1055.4,151.1,1055.4,150.8z M2251,446.4c-6-2.5-2.5-6-1.9-9.8c3.2,1.9,7.5,3.2,4.1,8.9
    C2252.5,446.1,2251.6,446.4,2251,446.4z M1672.7,339.6c-0.7,0-1,0-1,0.7c-1.5,6-2.5,12.3-4.1,18.1c5.1,5.7,9.2,10.1,13.2,15.5
    c0.7,0,1,0,1,0l0,0l0,0l0,0l0,0c0,0,0,0.7-0.7,0.7c4.7-3.4,8.8-9.2,13.2-9.8c9.7-1,16.3-5.1,22.1-12.3c3.4-4.1,8.8-8.2,13.8-10.8
    c9.7-5.7,9.7-12.6,6.6-22.2l0,0c-3.4-7.5-8.8-9.2-15.8-5.1c-4.7,3.2-9.2,5.7-14.1,8.2h0.7c5.1,1,10.7,1.9,16.7,3.2
    c-18.2,18.1-18.2,18.1-31.4,13.9c5.6-6,10-11.6,15.4-16.7l0,0l0,0h-1l0,0c-9.2-3.2-16.7-3.2-22.9,7.3c-2.5,4.1-8.2,6-12.6,9.2
    C1672.3,339.4,1672.3,339.4,1672.7,339.6z M1807.9,1318.9c0-2.5,0.7-4.8,1-5.1c1.9-1.6,4.7-2.5,9.2-4.1c1-0.7,2.5-1,3.4-1.6
    c-0.7,4.1-1,7.5-1.5,10.8c1.9,0,2.5,1,1.5,1.9c32.1-32.3,52.8,6,62.3,22.2c3.2,0,5.6,1.6,7.5,3.2c71.7-71.7,6,27.5,16.3,17.4
    c29.6-29.7-24.8,34.8-10,22.2c-3.4,3.2-7.3,6-10,8.9c0.7,0.7,1,1.6,1.5,1.9c-1.5,0.7-2.5,1-4.1,1.6c-1.5,0.6-2.5,1-4.1,1.6
    c-8.8,5.7-15.4,7.5-19.5,8.2c0-1,0-1.6-0.7-2.5c-0.7,0-1,0-1.5,0c-5.1,0-10-1.9-15.4-4.8c-1.5-0.7-2.5-1-4.1-1.6
    c0.7-1.9,1-4.1,1.5-5.7c0-0.7,0.7-1,0.7-1.6c-6-7.3-13.8-3.4-20.7-5.1c0-1,0-1.6,0-2.5c-0.7,0.7-1,1-1.5,1c-1.9,1.9-4.1,4.1-6,5.1
    c-2.5-0.7-5.6-1-8.8-1.9c-5.6-1-11.4-2.5-17.3-4.1l-1.5-0.7l-1,1c-0.7,0.7-1,1.6-1.9,1.9c-1,1.6-2.5,2.5-3.4,3.2
    c-2.5,1-5.1,1.6-7.3,1.6c-0.7,0-0.7,0-1,0c0,0-0.7-0.7-0.7-1c-8.2,5.7-15.8,13.3-20.7,26.3c20.4-14.9,37.1-20.8,54.4-6
    c-4.1,15.5-18.2,24.9-20.4,42c9.7,3.4,18.9,6.7,27.4,10.1c-1.9,6.7-4.1,13.9-6.6,20.8c-9.7,11.6-18.9,22.2-20.7,24.6
    c-0.7,0.7-1,1.6-1.5,1.9c-0.7-0.7-1.5-1-1.9-1.6c-0.7,1.9-2.5,4.1-1.9,6c0.7,1,1,2.5,1.5,3.4c0,1.9,0.7,4.8,1.5,6.7
    c3.4-3.2,7.3-6.7,9.7-10.1c5.1-7.3,10.7-11.4,18.9-13.3c4.1-1,7.3-4.8,10-7.5c0.7-1,1.5-1.6,1.9-1.9c3.4,3.4,7.5,7.3,12.6,11.6
    c-0.7,5.1-1.5,10.1-1.9,15.5l-5.1,1.9l1.9,1.6l-0.7,0.7c-3.4,2.5-7.3,4.8-8.8,8.9c-3.4,8.2-8.2,9.2-12.2,9.2h-0.7l0.7-0.7l-3.4-0.7
    c2.5-3.4,5.1-6.7,7.5-10.1l5.6-7.5l-4.1-1c-4.1-1-6.6-1.6-9.2-1.6c-6.6,0-10,3.4-19.9,14.9c1,3.2,1.9,5.7,1.9,8.9
    c2.5,0,4.7,0.7,6.6,0.7c-1.9,1.6-4.7,3.2-6.6,5.1c-0.7,6-3.4,13.3-11.4,21.5c8.2-8.2-23.9,93.6,39.6,29.7
    c16.3-16.4-9.7,70.2,15.8,44.9c9.7-9.8,35.5,67.6,65.1,37.9c11.4-11.4,20.7-12.3,29.6-8.9c-1.5,0.7-3.4,0.7-5.1,0.7
    c-6,15.9-21.4,21.5-34.6,30.7c1,5.1,1.5,9.8,1.9,14.2c6-4.1,4.7,7.3,9.7,4.8c8.8-4.1,17.3-8.2,26.1-11.6c10-4.8,19.9-9.8,24.5-18.1
    c6,7.5,11.4,14.2,15.4,14.9c-1.5,0-3.4,0.7-5.1,0.7c-0.7,7.3-1,13.3-1.9,20.8c10-1,18.2-1.9,26.5-2.5c0,7.3,0,13.3,0,19.6
    c7.5,1,11.4-3.4,12.2-7.5c1.5-5.7,4.1-10.1,7.5-13.9c12.2,8.9,24.8,15.5,33,7.3c7.5-7.5,0,18.1-4.1,21.5
    c10.7-10.8-15.8,24.1-15.4,24.6c-5.6,4.1-51,39.5-64.8,50.2c-3.4,5.7-19.5,24.9-33.6,43h-0.7v0.7c-22.3,28.2-40.3,52.8-1.5,13.3
    c3.2-3.2,62.3-7.3,29.6,25.6c-20.7,20.8,95.6-17.4,64.2,13.9c35.5-35.7,78.3,12.3,61.7,29c-8.8,8.9-6.6,14.9,1,18.3
    c0,0,0-0.7-0.7-0.7c-0.7-0.7,0-3.2,1.5-6.7c1.5,0,3.4,0.7,4.1,1c2.5,1.6,5.1,4.8,7.3,7.3c0.7,1,1,1.6,1,1.6c12.6,1,28.6-1,40.9-6
    c-0.7,0-0.7,0-1,0c-1,0-2.5-0.7-3.2-1c-1-2.5-1.9-4.8-2.5-7.5c2.5,1.6,5.1,3.2,7.3,5.1c0,0.7,0,1.9,0,3.2c0,0,0,0,0,0.7
    c5.1-1.9,9.7-5.1,12.6-8.2c-17.3,17.4,61,33.8,46.9,48.7c-19.5,19.6,76.4,13.3,44.3,45.6c32.8-32.9,64.2,25.6,35.5,54.3
    c9.2-9.2,40.3,13.9,21.4,33.1c4.7-4.8,8.2-7.5,11.6-9.8v-201.2c-5.1-5.1-7.5-8.9-5.6-11.4c2.5-2.5,4.7-5.1,5.6-8.2V1896
    c-8.2-22.2-42.8-41.1-54.4-29c28.9-29-47.8-52.8-80.5-20c31.1-31.3,9.2-22.4-13.2-11.6l-0.7,0.7c-1.9,1.9-5.1,3.4-8.2,4.8
    c-1,0.7-1.9,0.7-3.2,0.7c-14.1,6.7-24.8,11.6-16.3,3.2c-26.1,26.3-77.3,30.7-53.5,6.7c3.4-3.4-21.4,0.7-28.6-7.5c0,0,0,0-0.7,0
    c0,0,0,0,0-0.7c-3.4-4.8-1.9-12.6,13.2-28.2c-5.6,5.7-5.6,10.1-1.5,11.4c3.2-2.5,6.6-5.1,10.7-7.5c1.5-1,4.1-1,7.3-1
    c1,0,1.9,0,3.2,0c6-4.1,12.6-9.8,20.7-18.1c48.4-48.7-23.9-1.6,14.8-41.1c11.6-11.6-1-67-12.6-54.6c20.4-20.5,0-22.2-23.3-19
    c0,0.7,0,1,0,1.6c0,1.6,0,2.5-0.7,4.1c-0.7,0-0.7,0.7-1,0.7c-1.5,1-3.4,1.6-4.7,1.6c-0.7,0-1.5,0-3.2-1s-2.5-2.5-4.1-3.4
    c-0.7,0-0.7-0.7-1-0.7c-15.8,3.2-29.6,7.3-31.1,8.9c-1.9,1.9-18,3.4-33.6,1.9c-1.9-1.9-4.7-3.4-6.6-5.7c0.7,1.6,0.7,3.2,0.7,4.8
    c-19.5-3.2-37.1-11.4-27.4-29c1,1,1.9,2.5,1.9,3.4c0,6.7-1.9,13.9,8.8,18.1c1-4.1,1.9-7.3,1.9-10.8c0.7-17.4,1.5-19,20.7-22.4
    c-7.3-10.8-18.9-14.9-30.6-19c-4.7-1.6-9.2-6-6.6-10.8c1.9-3.4,7.3-6,11.4-7.3c5.1-1,11.4,4.8,14.8-1.6
    c-12.6-5.7-25.5-11.4-37.7-16.7c-1.5-9.8-3.2-18.1-4.7-27.5c4.7-0.7,8.2-1,11.6-1.6c3.4-0.7,7.3-1,10.7-1.9c0-0.7,0-1,0-1
    c-3.4,1-7.3,1.9-10.7,3.2c-5.1-5.1-10.7-4.8-15.8-1c-7.5,5.1-15.8,6.7-24.5,7.3h-0.7c-0.7,0.7-1,1-1,1c0-0.7,0.7-1,0.7-1
    c-4.7,0.7-8.8,0.7-12.6,1.9c-7.3,2.5-13.8,5.1-20.4,7.5c-6-18.3-29.6-39.8-33-36.4c-5.1,5.1-1.9,1,4.7-8.2c-1-1-1.5-1.9-2.5-3.2
    l-0.7-0.7c1.9-2.5,4.1-4.1,6-4.1c1,0,1.5,0.7,2.5,1c8.8-11.4,20.7-25.6,30.6-37.2c-0.7-1.6-1-2.5-1-4.1c-0.7-1.9,0.7-3.4,1-4.1
    c1-1.6,3.2-1.9,4.7-2.5c1.5,0,3.2,0,4.1,0c1-1.6,1.9-2.5,3.2-3.4c0,1,1,1.9,2.5,3.4c5.1,0,10,0,15.4,0c3.2,0,5.6,0,8.8,0
    c0,0.7,0,1.6-0.7,1.9c-0.7,2.5-1,5.7,1,8.2c0.7,1,1.5,1.6,2.5,1.6c-3.4,1.9-6.6,3.4-10,4.8l0.7,0.7c0,0,0,0-0.7,0
    c2.5,1,5.1,2.5,8.2,3.4c17.3,4.1,27-4.8,22.3-21.5c-1-3.2-1.9-6-3.4-9.8c7.5-3.2,15.8-8.2,22.9-15.5c1-1,52.5-42,58.1-43.9
    c12.2-14.9,56-66.1,67.3-81.8c2.5-1,4.7-2.5,6-6c4.1-8.2,5.6-15.9-1-23.4c-1.9-2.5-2.5-5.7-4.1-8.9c5.6-1.9,10.7-4.8,15.8-8.2
    c0.7-1.9,0-4.8,0-7.5c3.2,0.7,5.6,1,7.5,1.9c5.6-4.8,11.6-9.8,18-13.9c1.5-1,0.7-6.7,1-9.8c-1.9,0-3.4,0-5.6,0c0-0.7,0.7-1,0.7-1
    c-0.7,0.7-1.5,0.7-1.9,1c0.7,0,1,0,1.5,0c-2.5,5.1-5.1,9.8-11.4,10.1c-11.6,1-18.2,8.2-23.9,17.4v-1l-0.7,0.7
    c-1.5-1-3.2-1.9-4.7-1.9c-3.4,0.7-7.5,0.7-10,2.5c-4.7,4.1-1,8.9,1.9,12.3c1,1,2.5,1.9,3.4,3.2v0.7h1c-13.8,8.2-15.4,19.6-8.8,32.3
    c3.4,0,7.3,0.7,10,0c-3.4,3.2-8.2,7.5-14.1,13.9c-8.8,8.9-22.3-6.7-32.1-24.1c1-4.1,1.9-8.2,3.2-12.3c-3.4,1-5.6,1.9-7.5,3.2
    c-5.6-11.6-8.8-23.1-6-25.6c1.9-1.9-11.6,1-28,3.2c1-1,1.9-1.9,3.2-3.2c-1.9-1.6-4.7-3.2-7.3-4.1c-0.7-0.7-1.5-0.7-2.5-0.7
    c0,2.5,0.7,5.1,0.7,9.2c-26.5,3.4-56,4.8-35.5-15.9c-43.7,43.9-88.7,19-69.2-0.7c-46.9,47.1-83.9,22.4-68.8,7.3
    c-9.7,9.8-52.5,21.5-69.8,18.1c-0.7,1-1.5,1.9-2.5,3.2c-20.4,20.5,31.4,12.6,58.1,10.8C1807.9,1318.9,1807.9,1318.9,1807.9,1318.9z
    "/>
    </g>
    </pattern>
    </template>
<script>
import pattern from "./pattern.js";

export default {
mixins: [pattern]
}
</script>