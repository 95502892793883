export let patterns = [
    {
        name  : 'Abstract Stripes',
        value : 'abstract-stripes',
        image : '/images/pattern/abstract-stripes.png',
        colors: ['#FFFFFF', '#1C1F2A'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Caos',
        value : 'caos',
        image : '/images/pattern/caos.png',
        colors: ['#382F2D', '#C7C9C7', '#8986CA', '#E5E1E6', '#FE5000'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Clouds',
        value : 'clouds',
        image : '/images/pattern/clouds.png',
        colors: ['#C6DAE7', '#41748D'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Dots',
        value : 'dots',
        image : '/images/pattern/dots.png',
        colors: ['#F1B434', '#FFFFFF'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Driehoeken',
        value : 'Triangles',
        image : '/images/pattern/triangles.png',
        colors: ['#403A60', '#E5E1E6', '#EE2737'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Drops',
        value : 'drops',
        image : '/images/pattern/drops.png',
        colors: ['#2D2926', '#F2ACB9', '#FF9E1B', '#00966C', '#F8485E'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Maze',
        value : 'maze',
        image : '/images/pattern/maze.png',
        colors: ['#84620F', '#D1A019'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Panter',
        value : 'panther',
        image : '/images/pattern/panther.png',
        colors: ['#FFFFFF', '#2D2926'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Verloop lijnen',
        value : 'gradient-lines',
        image : '/images/pattern/gradient-lines.png',
        colors: ['#E03E52', '#1B365D'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Scheiding',
        value : 'separation',
        image : '/images/pattern/separation.png',
        colors: ['#1B365D', '#E5E1E6', '#D22630'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Strepen schuin',
        value : 'stripes-diagonally',
        image : '/images/pattern/stripes-diagonally.png',
        colors: ['#ffffff', '#9EA2A2'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Strepen',
        value : 'stripes',
        image : '/images/pattern/stripes.png',
        colors: ['#FFFFFF', '#E40046'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Vierkantjes',
        value : 'checkerboard',
        image : '/images/pattern/checkerboard.png',
        colors: ['#3F4444', '#54585A'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Waves',
        value : 'waves',
        image : '/images/pattern/waves.png',
        colors: ['#373A36', '#4698CB', '#307FE2', '#F8E08E'],
        scale : 150,
        rotate: 0
    },
    {
        name  : 'Zebra',
        value : 'zebra',
        image : '/images/pattern/zebra.png',
        colors: ['#236192', '#1B365D'],
        scale : 150,
        rotate: 0
    }
];