<template>
    <div>
        <div class="colors">
            <div v-if="detail.nullable" class="color-wrapper nullable" @click="$emit('selectColor', {id: detail.key, color: {hex: '', name: 'Empty'}})">
                <div class="position-relative">
                    <font-awesome-icon icon="check" v-if="selectedColor === ''"/>

                    <div class="color">
                        <font-awesome-icon icon="times"/>
                    </div>
                </div>
            </div>

            <div v-for="color in colorOptions" :key="color.name" class="color-wrapper" @click="$emit('selectColor', {id: detail.key, color})">
                <div class="position-relative">
                    <font-awesome-icon icon="check" v-if="selectedColor === color.hex"/>
                    <div class="color" :style="{'background-color': color.hex}"></div>
                </div>
            </div>

            <div class="color-wrapper open-modal" @click="$emit('openColorModal', detail.key)" v-if="showColorModal">
                <div class="color">
                    <font-awesome-icon icon="plus"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['detail', 'selectedColor', 'previewColors'],
    computed: {
        colorOptions() {
            if (this.detail.options) {
                return this.detail.options;
            }

            return this.previewColors;
        },
        showColorModal() {
            return !this.detail.options;
        }
    }
}
</script>