<template>
    <pattern :id="id" x="0px" y="0px"
             viewBox="0 0 1024 1024" width="1024" height="1024" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect :fill="colors[`${id}-p-0`]" width="1024" height="1024"/>
        <path :fill="colors[`${id}-p-1`]" d="M78,74.1c-0.8,5.2-5.4,10.3-10.8,10.5c-4.5,0.1-8.5-2.9-11.3-6.1c-3.5-4-5-9.7-6.2-14.7c-2.2-9.4-2-19-1-28.5
	c6.6,8.4,15,15.2,22.2,23C74.7,62.6,78.9,68.1,78,74.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M34.6,127.1c-4.9,1.6-9.7-1.3-11.6-5.9c-3-7.3-1.8-16.7-0.4-24.2c1.3-6.7,3.7-15.1,9.4-19.5
	c0.6,5.7,2.6,11.1,4.6,16.4c2.5,6.5,5.8,13.3,6.3,20.3C43.5,119.9,40,125.2,34.6,127.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M86.7,132.2c-1.8,3.8-6.9,3.5-9.9,1.6c-3.4-2.1-5.5-6.4-6.9-10c-1.7-4.4-2.2-9.2-3.3-13.8
	c-0.9-3.6-2.1-7.1-3.7-10.5c6.4,2.9,12.2,6.9,17.2,11.9C84.7,116.3,90.1,125.3,86.7,132.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M61.9,170.1c-1.9,3.9-5.8,7.2-10.2,5.8c-4.2-1.3-6.6-7.1-7.8-10.9c-1.3-4.5-1.1-9.4-0.4-14
	c0.8-4.5,1.9-9,3.4-13.3c0.9-2.7,2.1-5.4,3-8.2c1.1,3.9,2.4,7.6,4.1,11.3c2.2,4.8,5.2,9.1,7.6,13.8
	C64.2,159.3,64.3,165.1,61.9,170.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M82.2,14.6c-2.8-3.8-5.4-7.8-7.7-12c-0.5-0.9-0.9-1.7-1.3-2.6h-6.4c-0.2,1.2-0.4,2.3-0.6,3.5
	c-0.8,5-1.3,10.2-0.2,15.2c0.9,4.9,3.2,9.4,6.5,13.1c2,2.1,4.9,4.7,8,4.3c4.1-0.4,6.5-6.4,6.5-10C87,21.8,84.7,18.1,82.2,14.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M106.2,99.7c-4.6,2.4-8.7-0.5-10.8-4.6c-3-5.7-0.5-13.1,1.6-18.6c3-7.8,7.1-16.7,6.4-25.2
	c1.1,1.8,2.2,3.5,3.2,5.4c2.6,4.9,4.7,10.2,6.2,15.6C115.2,81.3,115.7,94.7,106.2,99.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M120.8,135.8c-8.1,1.9-10.6-6.5-12.5-12.4c-1.5-4.4-3.3-10.4-6.9-14.1c2.5,0.6,5,1.5,7.4,2.5
	c3.8,2,7.3,4.6,10.3,7.6c2.6,2.6,5,5.6,5.8,9.3C125.6,132.1,124.2,135,120.8,135.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M102.3,180.6c-0.2,4.3-3.9,8.6-8.3,8.8c-3.8,0.1-7.5-3.3-9.8-6c-2.4-3-4-6.5-4.7-10.3
	c-1.5-6.4-1.3-13.2-1.5-19.8c6.5,5.3,12.6,11.1,18.7,16.8C100,172.9,102.5,176.1,102.3,180.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M39.8,206.8c-0.3,4-0.9,8.2-3.8,11.2s-7.1,2.1-9.4-1.2c-3.4-4.9-2.7-11.4-1.2-16.9s4.1-10.3,5.8-15.6
	c1.2-4.2,1.9-8.4,2.1-12.8c3.6,7,5.3,14.8,6.2,22.5C40,198.3,40.1,202.5,39.8,206.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M70,238.1c-1.8,3.9-6.2,6.9-9.9,3.5c-3-2.7-3.9-7.6-4.3-11.3c-0.4-4.4,0-8.9,0.9-13.3c1.3-6,3.4-11.7,6.5-17
	c0.9,7.1,5,14.1,7.2,20.5C72.3,226.4,72.7,232.3,70,238.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M94.8,220.2c-2.6,0.7-5.2-1.8-6.8-3.4c-2.2-2.5-4-5.3-5.3-8.4c-0.5-1.1-1-2.2-1.4-3.3l0.4,0.1
	c2.4,0.8,4.9,1.4,7.4,2c3.2,0.9,10.7,2.2,10.6,6.7C99.3,216.7,97.5,219.2,94.8,220.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M31.2,250.3c-3.8-2.1,1.7-10.4,3-12.5c1.7-2.6,3.5-5,5.6-7.2c0.8-0.9,1.6-1.8,2.5-2.6c-0.3,4.6,0,9.2-0.9,13.8
	S37,253.6,31.2,250.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M98.5,257.1c-1.1,2.6-3.5,3.9-6.1,2.6c-3.1-1.5-3.7-5.4-3.9-8.4c-0.3-4.8,0.2-9.5-0.1-14.3
	c1.9,2,3.8,3.9,5.7,5.9C97.7,246.8,100.8,251.7,98.5,257.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M141,184.7c-1.2,5-5.7,7.1-10.5,5.9c-5.7-1.5-8.4-7.1-9.7-12.3c-1.3-5.5-0.9-11.1-2.2-16.6c-1-4-2.7-7.9-5-11.3
	c5.2,4,10.7,7.7,15.8,12C135.8,167.8,143.2,175.6,141,184.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M139.8,72.5c-1.9,3.6-6.1,5.3-10,4.1c-4.5-1.3-6.4-6.6-7.5-10.7c-1.4-5.2-1.5-10.6-1.6-15.9
	c-0.1-7.2-0.8-14.8,1.2-21.7c2.8,5,5.6,10,8.2,15c2.6,4.9,5.6,9.4,8.2,14.3C140.8,62.2,142.4,67.6,139.8,72.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M171.9,114.8c-2.9,3.2-8.4,2.6-10.9-0.8c-2.1-2.9-2.1-7.2-2.3-10.6c-0.3-5.1-0.1-12.1-2.8-17.1l1,0.7
	c3.2,2.2,6.2,4.7,9.1,7.3C170.9,98.7,177.7,108.5,171.9,114.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M152.8,132.9c-0.6,3.8-3.4,8.7-7.7,8.8c-4.5,0.1-7-5.5-7.9-9c-0.8-3.8-1.1-7.7-0.9-11.5
	c0.1-9.3-0.4-18.7,0.4-28.1c4.3,8.3,8.3,16.7,12.4,25.1C151.1,122.5,153.6,128,152.8,132.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M178.4,163.6c-0.4,3.1-2.3,7.6-5.9,7.9c-4.6,0.5-5.7-6.2-5.6-9.4c0-3.4,0.9-6.7,1.4-10.1
	c0.7-5.4,2.4-10.4,0.7-15.2c0.9,1.2,1.8,2.4,2.6,3.6c2.3,3.5,4.2,7.3,5.6,11.2C178.5,155.5,178.9,159.6,178.4,163.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M162.8,210.7c-2.1,4.2-6.9,5.3-10.2,1.9c-4.4-4.5-2.6-13.9-1.6-19.2c0.9-4.8,2.1-9.5,3.6-14.1
	c1,6.2,3,12.2,6,17.7C162.9,201.1,165.2,206.1,162.8,210.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M136.8,239.8c-0.4,4.4-2,9.9-6.6,11.5c-4.3,1.5-8.3-2.5-10.1-6c-2.8-5.3-1.6-11.9-0.3-17.4
	c1.6-7.2,5.4-16.1,2.5-23.4c2,2.2,3.9,4.5,5.7,6.9c3,4.2,5.3,8.8,7,13.7C136.6,229.8,137.2,234.9,136.8,239.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M155.2,259c-1.8,4-5.1,3.2-7.5,0.1c-3.4-4.4-2.1-11.6-1.3-16.7c0.7-4.3,1.7-8.5,2.3-12.8
	c0.2,0.4,0.4,0.9,0.6,1.3c0.9,2.2,1.9,4.4,2.7,6.6c1.5,3.6,2.7,7.3,3.7,11C156.8,252,156.6,255.7,155.2,259z"/>
        <path :fill="colors[`${id}-p-3`]" d="M194.9,213c-6.8,2.8-10.8-6.3-12.7-11c-2.1-5.2-3.3-10.9-6.4-15.6c5.3,3,10.6,6,15.1,10.2
	c2.8,2.5,4.9,5.5,6.2,9C198,208.1,197.8,211.8,194.9,213z"/>
        <path :fill="colors[`${id}-p-2`]" d="M76.9,287.9c-2.1,2.5-6,1.1-8.2-0.4c-4.3-2.8-5.3-7.8-5.6-12.5c-0.2-4.6-0.4-8.9-1.8-13.3c-0.9-2.6-2-5-3.4-7.3
	c1.6,0.9,3.2,1.9,4.7,3c3.4,2.6,6.5,5.8,9,9.3c2.5,3.3,4.4,6.9,5.7,10.7C78.2,280.5,79.2,285.2,76.9,287.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M113.1,295.6c-4.3,1.1-8.2-1.5-10.9-4.4s-4.8-6.7-7.3-9.9c-2.3-2.9-4.6-5.6-7-8.4c5.4,1.2,11.2,1,16.7,2.2
	c6.7,1.4,14.8,5.6,15.2,13.4C119.9,292.4,116.5,294.7,113.1,295.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M89.7,331.2c-4,0.2-6.1-4.2-6.8-7.4c-0.9-4.4,1.3-9.2,2.6-13.3c1.4-4.7,3.4-10.1,3.5-15.2
	c3.1,5.3,6.2,10.7,7.7,16.7S98.6,330.8,89.7,331.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M132.7,264.5c-1.7,1.2-4.1,1.2-6.1,1.2c-3.4,0-6.8-0.5-10-1.5c2.5-0.6,5.1-2.2,7.1-3.1s8.4-4.2,10.1-1.5
	C134.6,261.4,134.1,263.4,132.7,264.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M72.1,321c-1.9,6.9-5.3,14.4-12.7,16.6c-5,1.5-10.1-2.5-10.3-7.6c-0.2-4.6,4.1-8.7,7.2-11.6s6.9-5.5,10.2-8.3
	c3.2-2.5,6.1-5.3,8.9-8.2C74.8,308.4,73.7,314.8,72.1,321z"/>
        <path :fill="colors[`${id}-p-3`]" d="M75.8,354.1c-0.4,5.8-1,12.7-4.4,17.6c-1.2,1.8-3.4,2.5-5.3,1.8c-4.2-1.2-3.4-7-2.7-10.2
	c0.9-4.1,2.9-7.9,5.8-10.9c2.3-2.5,5-4.7,7.1-7.3C76.2,348,76.1,351,75.8,354.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M39.5,310c-3.5,1.8-8,3.1-11.8,1.5c-4.1-1.8-6.2-6.1-3.8-10.1c1.7-2.8,5.5-3.7,8.5-4.2c3-0.6,6.2-0.7,9.2-0.3
	c2.5,0.4,8.2,2,12.1,1.2C49.4,302.6,45,307.2,39.5,310z"/>
        <path :fill="colors[`${id}-p-3`]" d="M44.4,276.5c-3.1,5.5-9.3,9.7-15.8,7.9c-6.2-1.7-0.5-10.2,2.4-12.2s6.3-3,9.5-4.4c3.1-1.4,5.8-3.5,8-6.1
	C47.7,266.9,47,271.8,44.4,276.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M138,331c-7.1,3.7-15.1-1.4-19.1-7.2c-1.8-2.9-3.4-5.8-4.8-8.9c-0.8-1.9-1.8-3.7-2.9-5.5
	c3.8,0.4,7.3,2.2,10.8,3.5c3.4,1.3,6.9,2.1,10.4,3.1s6.9,2.4,8.7,5.6C143.2,325.5,141.6,329,138,331z"/>
        <path :fill="colors[`${id}-p-4`]" d="M213,247.4c-0.3,6-5.8,10.1-11.3,10.7c-9,1-15.4-7.2-19.2-14.2c-3.5-6.6-5.7-13.9-8.1-21
	c4.9,2.3,10.4,3,15.6,4.2c5.5,1.1,10.7,3.2,15.4,6.3C209.9,236.6,213.2,241.6,213,247.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M181.5,286.1c-0.2,4-1.5,8.8-5.7,10.2c-3.9,1.4-7.5-1.5-9.6-4.6c-3.1-4.5-3-10.3-2.2-15.5s2.5-10,3.5-15
	c0.9-4.6,1.5-9.3,1.9-14.1c0.1,0,0.1-0.1,0.2-0.1c1.7,8.6,6.5,16.4,9.5,24.7C180.8,276.3,181.6,281.2,181.5,286.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M213.4,308.4c-0.2,3.8-2.7,9.6-7.4,7.6c-3.8-1.6-6.4-6.1-8.2-9.6c-2.1-4.1-3.6-8.5-4.6-13
	c-1.7-7-2-14.1-2.9-21.1c3.1,3.3,6,6.8,8.9,10.2c3.6,4.1,7.5,7.8,10.5,12.4C212.3,298.8,213.6,303.6,213.4,308.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M152.2,309.2c-3.6,1.1-6.7-3.4-7.7-6.2c-1.3-3.5-1.3-7.4-1.3-11c0-3.5,0.1-7,0.3-10.5c0-0.9,0.1-1.9,0.1-2.9
	c0.5,0.5,1,1.1,1.5,1.6c2.5,2.9,4.7,6,6.5,9.3C154,293.8,159.8,307,152.2,309.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M182,360.8c-3.6,1.1-7.4-1.4-9.8-3.9c-3.8-4-5.2-10-6.1-15.3c-1.1-6.1-1.5-12.4-1.4-18.6c0.2-5.6,1.3-11,1-16.5
	c2.3,3.7,5.1,7.1,7.7,10.7c3.5,4.6,6.7,9.5,9.5,14.6c2.8,5.1,4.5,10.8,4.8,16.6C187.9,352.8,187,359.2,182,360.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M122.5,385.4c-3.5,1.9-7.8-1.5-10.1-3.8c-4-4-4.8-9-4.8-14.4c0-6,0.3-11.8,0.2-17.8c-0.2-5.3,0.4-10.6,0.6-16
	c2.6,3.4,5.3,6.6,7.7,10.2c3,4.5,5.3,9.4,7.1,14.5c1.8,4.8,3,9.7,3.7,14.7C127.2,377.1,126.8,383,122.5,385.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M161.2,389.4c-0.2,4.9-3.3,11-8.7,11.6c-5.6,0.6-8.5-6.3-9.5-10.7c-1.1-4.9-0.7-10.1-0.3-15.1
	c0.8-10.6,1.8-21.5,4.9-31.7c1.7,4.5,3.8,8.7,5.7,13.1c2.3,5.2,3.7,10.8,5.2,16.3S161.4,383.8,161.2,389.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M100.9,410.2c-2.2,4.9-7.6,8.6-12.9,6.1s-8.3-9.1-8.8-14.6c-0.3-4,1.1-8.3,2.3-12c1.3-4,2.9-8,4.3-12
	c2.3-6.9,3.9-14.1,4.8-21.3c2.8,7.8,5.9,15.5,8.3,23.5C101.7,389.1,104.9,401,100.9,410.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M41,365.9c-0.3,5.8-1.3,12.2-4.7,17.1c-2.2,3.2-6.5,6.5-10.6,5.8c-5.4-0.9-5.7-9.1-5.9-13.2
	c-0.3-6.4,1.2-12.8,3.4-18.7c2.4-6.1,5.4-12,8.9-17.5c2.6-4.2,5.4-8.2,7.9-12.5c-0.4,6.5,0.9,13,1.2,19.5
	C41.5,352.9,41.4,359.4,41,365.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M59.8,440.4c-2,2.9-5,5.4-8.6,5.6c-3.1,0.2-5.7-1.9-7.7-4c-5.5-5.8-6.1-14.3-4.9-21.8s4.1-14.8,6.8-21.8
	c2.5-6.8,4.6-13.8,7.4-20.5c1.1,3.6,2.5,7.2,3.7,10.8c2.1,5.9,4,11.9,5.5,18C64.7,417.5,66.5,430.6,59.8,440.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M129,452.7c-3.7-0.2-6.6-4-8.3-6.8c-2.8-4.8-3.7-11-4.5-16.4c-0.9-5.9-1.2-11.8-1.5-17.7
	c-0.1-2.5-0.1-5.3-0.4-8c4.8,8.6,11.9,15.7,17.2,23.9c2.9,4.4,5.4,9.2,5.3,14.6C136.8,446.6,134.1,453,129,452.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M194.6,413.9c-3.4,2-7.8,1.3-10.4-1.7c-3.6-4-4.1-9.9-4.6-14.9c-0.5-4.8-1.5-9.5-2.8-14.1
	c-1.1-4.4-2.4-8.7-3.9-12.9c6,6,11.8,12.4,17.2,19C195.2,395.4,204.9,407.5,194.6,413.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M179.4,64.5c-2.2,5.9-7.8,8.2-13.6,5.7c-5.3-2.3-7.4-8.3-8.8-13.5c-2.7-10.4-3-22.5-12.4-29.5
	c-0.6-0.4-1.2-0.8-1.8-1.2c2.9,1.2,6,2.1,8.9,3.2c5.5,2.2,10.8,4.9,15.8,8.1c4.6,3,8.8,6.8,11.4,11.7
	C181.5,53.9,181.2,59.6,179.4,64.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M220.6,111.4c-1.7,5.6-6.4,9-12.2,8c-9.4-1.7-13.2-11.8-16.4-19.7c-2.3-5.7-4.9-13-9.9-17.3
	c12.6,0.9,26.1,4.8,34.9,14C220.9,100.3,222.3,106.1,220.6,111.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M204,170.5c-4.5-0.3-8.1-4.4-10.3-7.9c-2.7-4.3-4-9.3-4.9-14.2c-1.6-8.9-1-17.7-0.8-26.7c3.1,9,11,15.6,17,22.7
	c3.7,4.4,7.4,9.2,7.5,15.2C212.7,164.8,209.8,170.9,204,170.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M231.8,178.4c-6.9-2-8.7-10.1-9.3-16.3c-0.2-2.7-0.4-5.4-0.8-8c-0.6-3.1-1.4-6.2-2.4-9.2
	c-0.9-3.7-2.2-7.2-3.7-10.7c1.7,1.2,3.5,2.2,5.2,3.3c4.1,2.6,8.2,5.1,12.1,8.1c8,6.1,14.2,14.9,11.3,25.4
	C242.6,176.3,237.3,180,231.8,178.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M237.4,226.1c-1.7,4.4-5.2,7.9-10.1,8.2c-5.6,0.4-8.8-4.8-10.7-9.3c-2.2-5.3-2.6-11.1-2.5-16.7
	c0.2-10.2,3.1-20.7,1.8-30.9c8.1,9.4,14.9,19.2,19.7,30.6C238,213.8,239.8,220.1,237.4,226.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M239.4,304.8c-4.9,2.9-10.6,0.6-13.8-3.6c-4.2-5.6-3.9-13.8-3.2-20.4c0.7-6.5,2.2-12.9,4.3-19.1
	c1.2-3.9,2.5-8.1,2.5-12.3c2,2.1,3.8,4.3,5.4,6.7c3.5,5.3,6.4,11,8.5,17.1C246.3,282.4,250,298.6,239.4,304.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M231.5,369.6c-1.7,4.6-5.6,8.8-10.8,8.9c-5.7,0.1-9.1-5.8-10.7-10.5c-2.2-6.6-1.4-13.9-0.1-20.6
	c1.1-5.8,2.9-11.5,4.5-17.2c1.1-4.5,2.7-8.8,4.8-12.9c0.6,5.1,1.6,10.2,3,15.2c1.8,6.2,5,11.9,7.6,17.8
	C232.7,356.6,233.9,363.1,231.5,369.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M236.3,436.1c-1,5.6-6.3,9.4-11.9,8.6c-5.1-0.8-9-5.5-11.3-9.7c-2.7-4.9-3.9-10.5-4.8-15.9
	c-1.6-10-0.7-20.1-2-30.1c3.9,5.4,7.4,11,11.2,16.5c3.7,5.1,7.8,9.9,12.3,14.4C234.1,424.4,237.5,429.5,236.3,436.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M266.3,349.6c-1.3,4.8-6.6,7.8-11.2,5.6c-4.8-2.2-6.8-7.8-7.9-12.6s-2-9.3-3.9-13.8c-1.8-4.2-4.1-8.1-6.7-11.8
	c6.1,3.2,12.5,6,18.2,10C261.5,331.9,268.8,340.6,266.3,349.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M255.9,397.9c-1.1,3.4-4.5,5.4-8,4.7c-7.2-1.5-7.5-11.9-7.3-17.5c0.3-6.9,2.2-13.4,3.3-20.1
	c4.3,6.6,8.6,13.6,10.8,21.1C255.7,389.9,257,394,255.9,397.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M247.3,108.8c-5.4,3.7-10.7-2.5-12.5-7.1c-2-5-1.9-10.7-1.6-15.9c0.3-5.6,1.1-11.1,0.9-16.8
	c-0.2-5.6-1-11.1-2.6-16.5c2,3,4.4,5.8,6.4,8.6c3.7,5.1,7,10.5,9.8,16.1C252.2,85.9,257.5,101.8,247.3,108.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M255,143.4c-6.3,3.4-11.8-4.9-14.1-9.5c-2.2-4.3-3.4-9.1-5.3-13.6c1.9,1.4,3.9,2.4,6.1,3.2
	c3.2,1.3,6.7,1.9,10,3.1s6.1,3.4,7.3,6.8C260.4,137.4,258.6,141.6,255,143.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M283.5,187.6c-3,3.5-7.8,2-10.7-0.8c-4.3-4.1-3.8-11-2.9-16.3s2.4-10.3,2.2-15.8c-0.1-2-0.3-3.9-0.8-5.8
	c3.7,4.7,7.1,9.5,10.2,14.6C285.6,170.4,289.6,180.6,283.5,187.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M261.2,221.5c-2.8,5.5-8.6,5.3-12.3,0.9c-5.3-6.2-4.3-16.5-3-23.9c0.8-4.9,2.1-9.7,3.9-14.3
	c0.6,1.8,1.3,3.6,2.2,5.3c2.4,5,5.5,9.7,8,14.7C262.4,209.7,264,216,261.2,221.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M294.5,243.4c-0.9,5.5-4.2,10.4-9.5,12.3c-6,2.1-11.2-1.4-14.4-6.2c-3.4-5-4.1-11.2-3.9-17.1
	c0.3-8.6,3.2-17.3,3.8-26c4.6,7,12,12.3,17.5,18.6C292.3,230.1,295.6,236.5,294.5,243.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M306.5,211.8c-5.2-1.8-9-7.3-13.2-10.7c-1.4-1.1-2.9-2-4.6-2.6c9-1.2,18.6-3.5,26.6,1.1
	C323.7,204.4,313.4,214.2,306.5,211.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M353.4,241.8c-4.9,1.5-9.5-0.5-13-3.9c-5.3-5.2-9-13-15.5-16.7c5.5,0.1,11,0.5,16.4,1.3
	c6.6,1,14.7,3.1,17.9,9.6C361.5,236.9,357.7,240.5,353.4,241.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M273.6,285c-1.8,5.5-7.9,10-13.6,7.2c-4.3-2.1-6.3-7.5-7.4-11.8c-1.1-5.2-1.5-10.6-1.1-15.9
	c0.3-8.1,1.8-16,3.4-23.9c1.1,4.3,2.6,8.5,4.6,12.5c2.7,5.6,6.5,10.1,10.2,15S275.6,278.9,273.6,285z"/>
        <path :fill="colors[`${id}-p-2`]" d="M305.5,308.4c-4.2,2.6-9.5,1-13-1.9s-5-7.5-5.4-12c-0.8-8,1.4-16.2-0.7-23.9c1.5,1,3.1,1.8,4.8,2.6
	c4.4,2.1,8.4,5,11.8,8.4C309.4,288.3,315.8,302.1,305.5,308.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M292.4,344.3c-1.1,4.6-4.5,8.5-9.5,6.9c-4.4-1.4-7.4-6.4-9.3-10.3c-1.9-4.5-3-9.3-3.2-14.1
	c-0.6-8.4,0.9-16.6,2.5-24.8c5.7,8.9,13,16.8,17.7,26.1C293.1,333.1,293.7,338.9,292.4,344.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M348,294.4c-2.4,4.8-8.1,6.8-13,4.6c-4.6-2.1-7-7.6-8.8-12.1c-1.9-5-3.2-10.2-5.8-14.9c-1.4-2.4-3-4.8-4.8-6.9
	c-0.7-0.8-1.4-2.1-2.3-3c8.3,0.6,16.6,3.9,23.5,8.2C345.2,275.3,353.3,284.3,348,294.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M342.9,334.1c-0.7,4.8-5,6.6-9.3,5.4c-7.2-1.9-9.1-9.6-9.6-16.1c-0.3-4.3-0.1-8.6-0.1-13
	c3.6,4.9,10,7.4,14.6,11.5C341.8,325,343.5,329.5,342.9,334.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M379.9,261c-4.8,7.3-13.4,5.4-19.6,1.5c-5.9-3.7-10.6-9-15.9-13.4c4.6-0.5,9.1-1.7,13.7-2.2
	c4-0.4,7.9,0.1,11.7,1.3C374.9,249.9,384.2,254.4,379.9,261z"/>
        <path :fill="colors[`${id}-p-1`]" d="M368.4,326.4c-12-2.5-6.1-17.6-3.3-24.9c1.6-4.2,2.8-9.4,2.1-14c4.9,4.8,8.8,10.6,11.2,17.1
	C381,312.4,380.6,328.9,368.4,326.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M319.6,374.2c-1.7,4-5.2,7-9.4,8.1c-4.9,1.1-8.3-3-9.8-7.1c-2.4-6.2-2-13.1-1-19.5c0.9-5.9,2.3-11.7,3.1-17.6
	c0.6-4.4,1.6-8.7,3-12.8c2.2,5.3,3.8,10.9,6,16.2s5.5,10.1,8,15.3C322.2,362.3,322.2,368.7,319.6,374.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M413.5,317.1c-8.5-1.7-14-10.2-19.5-16.5c4.5,0.1,9.2-0.6,12.8-0.9c7.3-0.6,21.6-5.8,24.7,4.4
	C434.8,315.4,422,318.8,413.5,317.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M435.8,233.6c-4.7,4.1-10.4,6.9-16.5,7.9c4.9-4.7,4.6-14.6,6-20.5c1.2-5.1,4-9.1,9.1-10.8
	c3.8-1.4,8.1,0.5,9.5,4.3c0.1,0.2,0.1,0.3,0.2,0.5C446.3,222.2,440.7,229.3,435.8,233.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M480.2,284.5c-2.4,9.1-12.1,12.7-20.5,14.1c-7,1.2-15.4,0.8-21.4-3.4c3.8-0.6,7.4-2.1,10.5-4.5
	c4.4-3.2,7.8-7.6,11.5-11.5c3.6-3.8,8.6-8.9,14.4-7.5S481.5,279.6,480.2,284.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M488,322.8c-2,4.1-6.3,7.5-10.5,9.1c-4.6,1.7-9.6,0.5-13.8-1.5c-5.9-2.8-11-7.4-15.6-12.3
	c5.3,1.8,13,0.1,17.2-1.1c5.4-1.5,10.6-4,16.4-3.8c2.7,0.1,5.7,1.1,7,3.6C489.6,318.6,488.8,321.1,488,322.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M414.8,358.1c-9.9,4.1-17.5-5-20.6-13.3c-2.6-7.1-3.3-14.7-4.4-22.2c2.8,2.7,6,4.8,9.5,6.4
	c4.4,2.1,8.5,4.6,12.4,7.5c3.7,2.9,7.7,6.5,9.2,11.1S418.7,356.4,414.8,358.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M486,220.5c-3.5,5.4-9,9.2-13.9,13.2c1.2-2.4,1.9-5.1,1.9-7.9c0.2-3.6,0.2-7.2,0.4-10.7
	c0.2-3.8,0.8-7.4,3.2-10.5c1.8-2.3,4.9-3.8,7.7-2.7c3.1,1.3,3.7,5.7,3.9,8.6C489.2,214,488.1,217.5,486,220.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M502.3,245.8c-1.8,3.3-4.2,6.2-7.2,8.6c-6.2,5.3-14.1,8.2-22.1,9.2c5.4-4.3,7.1-10.6,10.1-16.7
	s9.2-15.4,17.3-11.2C503.9,237.6,503.8,242.6,502.3,245.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M374.6,376.8c-2.7,4.2-7.6,6.4-12.5,5.5c-10.8-1.8-11.7-15-10.9-23.6c0.7-7.4,2.8-14.7,3.4-22.1
	c1.9,3.5,4.9,6.5,7.5,9.4c4,4.2,8.1,8.5,11.2,13.5C376.5,364.7,377.8,371.3,374.6,376.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M402.3,395.8c-3.4,1-6.4-3.3-8-5.5c-2-3.1-3.4-6.5-4.3-10.1c-1-3.9-1.5-7.8-1.7-11.8c0-0.7,0-1.4-0.1-2.1
	c2.9,4.7,7.7,7.6,12.1,11c2.9,2.2,5.5,4.9,6.4,8.6C407.5,389.1,405.8,394.7,402.3,395.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M522.1,341.8c-6.9,12.4-22.4,15.7-35,11.3c-3-1.2-5.9-2.6-8.7-4.3c-1.7-0.9-3.2-2-4.7-3.1
	c2.7,2.2,6,3.4,9.5,3.4c5.4-0.1,10.5-3.5,13.9-7.5c5.5-6.5,8.6-20.6,18.8-21.1C528.4,319.9,526,334.6,522.1,341.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M503.9,293.6c-5,5.2-12.2,10-19.7,10c7.6-4.7,10-13.2,12.1-21.6c1.3-5.1,3.1-10.3,7.7-13.3
	c4.3-2.8,9.6-2,10.1,3.8C514.9,280.2,508.9,288.3,503.9,293.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M417.1,227.9c-1.3,5.5-4.7,10.3-9.4,13.4c-5.2,3.7-11.5,5.4-17.9,5c-3.5-0.3-6.9-1.5-9.8-3.4
	c-1.6-1.1-3.2-2.4-4.6-3.7c9.1-0.9,15.8-11.6,20.1-18.6c2.5-4.2,5.2-9,9.5-11.6c4.7-2.9,9.9,0.1,11.7,4.8
	C418.4,218.1,418.1,223.4,417.1,227.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M457.2,196.4c-0.4,4.5-1.5,8.9-3.4,13c-0.8,1.8-2,3.4-3.4,4.8c-0.2,0.2-0.5,0.4-0.8,0.6
	c1.3-2.6,1.6-5.6,0.8-8.4c-1.4-5.3-5.8-9.7-8.8-14.2c-2.6-3.7-3.6-8.3-2.8-12.8c1.1-5.3,5.5-6.8,10.4-5.8
	C458.5,175.5,457.8,189.3,457.2,196.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M545.8,291.9c-3.9,5.5-9.8,9.6-15.8,12.5c-3,1.4-6.1,2.5-9.3,3.2c-2.8,0.5-5.6,0.7-8.5,0.6
	c3.9-3.3,7-8,9.4-12.3c2.9-5.1,5.2-10.5,8.2-15.6c3.1-5.3,7.1-11,13.3-12.7c5.8-1.6,7.8,3.6,7.9,8.4
	C551,281.7,549.2,287.3,545.8,291.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M565.9,327.7c-3.9,0.7-7.9,0.9-11.9,0.6c-4.6-0.5-9.1-1.6-13.5-3.3c2-0.1,4-0.4,5.9-1.1c5-1.8,9.1-5.2,13.5-8
	c4.7-3,18-11,21.5-2.5C585.4,323.1,573,326.5,565.9,327.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M289.4,408.8c-2,5.4-7.7,9.3-13.4,7.9c-5.4-1.3-8.4-7-9.7-11.9c-1.3-5.2-0.8-10.9,0-16.2
	c1.2-7.4,3.6-14.4,5.8-21.5c0.8,3.2,2,6.2,3.5,9.1c2.9,5.4,7.2,9.9,10.5,14.9C289.8,396.4,291.7,402.6,289.4,408.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M341.4,405.9c-4.6,4.3-10.8,2.2-13-3.5c-1.8-4.9,0.3-10.5,2-15.1c1.9-5,2.7-10.2,3.3-15.5
	c0.2-2.1,0.4-4.3,0.5-6.4c1.8,4.7,4.6,9.1,6.9,13.4C345.8,387.4,349.8,398.2,341.4,405.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M94.2,479c-3.6,6.9-11.5,9.7-17.9,4.7c-8.9-7-7.5-21.1-5.6-30.7c1.9-9.9,5.6-19.3,7.5-29.2
	c3.7,4,5.8,9.6,7.8,14.6c2.6,6.3,6,12.2,8.3,18.6C96.9,463.9,97.7,472.1,94.2,479z"/>
        <path :fill="colors[`${id}-p-4`]" d="M173.7,452.5c-19.7,2.1-16.7-26.5-21.9-38.5l-0.1-0.3c8.4,5.7,18.7,8.8,24.7,17.6
	C180.7,437.7,185.3,451.3,173.7,452.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M161.5,501.8c-2.1,5.6-11.7,11.8-17.3,7.3c-4.8-3.8-4-12.5-3.6-17.8c0.6-8.4,2.6-16.8,4.7-24.9
	c1-3.9,2-7.8,3.2-11.6c3.3,6.5,6.8,13.1,9.5,19.9C161.2,482.9,164.7,493.1,161.5,501.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M309.2,457.5c-5.6-0.3-9.2-5.4-10.9-10.1c-2.2-5.8-1.8-12.2-0.8-18.2c1.7-10.3,6.3-20.6,6.2-31.2
	c6.9,9.6,12,20.3,15.2,31.7C321.2,438.1,323,458.1,309.2,457.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M366.4,418.8c-0.6,2.9-2.8,5.6-6,5.8c-3.3,0.1-5.8-3.6-6.8-6.3c-1-3-0.7-6.3-0.2-9.3c0.4-2.7,1.1-5.3,1.5-8
	c0.3-2.3,0.8-4.5,1.5-6.6c1.5,5.2,5.3,9.7,8,14.4C366.2,411.8,367.2,415.1,366.4,418.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M392.9,415.8c-2.1,3.7-6.8,1.6-8.9-0.8c-2.3-2.5-2.9-6.3-3.5-9.4c-0.7-3.5-1.6-6.9-2.6-10.2
	c-0.2-0.6-0.4-1.3-0.7-2c3,2.2,6,4.3,8.9,6.6C390.3,403.4,396.2,409.9,392.9,415.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M384.4,465.1c-4.2,1.7-7.5-3-8.8-6.3c-1.3-3.5-1.2-7.5-0.9-11.3c0.5-7.9,1.7-15.7,2.9-23.6
	c0.8,3.4,1.7,6.7,2.9,9.9c1.6,3.7,3.6,7.1,6,10.3c2.4,3.5,4.4,7,4.3,11.3C390.7,459.3,388,463.7,384.4,465.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M341.3,452.8c-3.6,2.3-6.4-0.5-7.3-3.9c-1.6-5.9-0.1-12.5,1.9-18.1c1.1-3.2,2.7-6.2,4.6-8.9
	c0.6-0.9,1.3-1.7,2-2.5c0,7.9,3.3,15.5,3.5,23.4C346.1,446.8,344.7,450.6,341.3,452.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M361.8,477.6c-2.2,2.4-5.4,3.5-8.6,2.9c-3.7-0.8-5-4.8-5.2-8.2c-0.2-4.1,2.3-7.7,4.6-10.9c2.4-3,4.4-6.2,6-9.6
	c1-2.3,1.7-4.7,2.1-7.1c0.5,2.5,0.9,4.9,1.3,7.4c0.7,4.2,1.6,8.4,2.5,12.6C365.3,469.4,365.1,473.9,361.8,477.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M608.9,323.6c-4,11.8-16.7,17.9-27.3,23c1.5-1.7,2.8-3.5,4.1-5.4c1.8-2.9,3.3-5.9,4.5-9.1
	c2.5-6.3,5.1-13.1,9.5-18.4c1.4-1.7,3.5-3.8,5.8-3c1.5,0.5,2.5,2.2,3.3,3.5C610.5,317.1,609.9,320.7,608.9,323.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M205.2,470.2c-4.4,1.8-8.2-1-10.2-4.8c-2.3-4.6-1.3-10.8-1.3-15.7c-0.2-4.6-0.8-9.2-1.8-13.7
	c-0.3-1.8-0.8-3.6-1.3-5.3c6.5,4.1,12,9.6,16.2,16C211,453.3,215,466.2,205.2,470.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M245.4,496.2c-3.2,3.5-8.4,5.3-12.3,1.9c-7.7-6.7-5.6-19.2-3-27.7c2.1-6.9,5.6-13.3,8.4-20
	c1.2,10.5,7.8,19.8,10.1,30.1C249.8,486,249.2,491.9,245.4,496.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M275.3,498c-2.6,1.3-6.4,1.3-8.4-0.9s-2.4-6.1-2.9-9c-0.4-2.6-0.7-7.1-1.9-10.7c2.6,2.5,5.3,4.9,7.8,7.5
	C272.1,487.1,280.8,495.3,275.3,498z"/>
        <path :fill="colors[`${id}-p-1`]" d="M297.9,494.2c-2.9,3.4-7.2,2.6-10-0.3c-4.9-5-4.8-14.1-4.3-20.6c0.5-5.8,1.8-11.5,3.8-17
	c3.1,8.3,8.5,15.7,11.4,24.1C300.4,484.9,301.1,490.4,297.9,494.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M58.6,493.9c-1.2,5.9-5.3,11.6-11,13.7c-5.6,2.1-10.8-0.8-13-6.1c-3.9-9.3,1.7-20.1,6.7-27.8
	c4.3-6.3,9.3-12,15.1-17c-0.5,5.5,0.7,11.1,1.6,16.5C59,479.8,60,487.1,58.6,493.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M91.8,524.5c-0.9,3.6-4.5,5.2-7.9,4.5c-4.4-0.9-6.3-5.8-7.2-9.7c-1-4.3-0.7-8.9-0.8-13.3c0-2.6-0.4-5.2-1.1-7.6
	c3,3.2,6.2,6.3,9.2,9.5C87.9,512,93.3,518.2,91.8,524.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M122.1,477.3c-2.2,2.6-5.8,3.4-8.9,2c-4.1-1.9-5.4-7-5.8-11.1c-0.3-4.3-0.4-8.7-0.1-13c0.1-2.9,0.3-6.1,0.1-9.1
	c2.9,3.9,6.4,7.3,9.6,11C121.4,462.1,127.5,470.8,122.1,477.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M180.9,493.3c-9.8-0.2-6.6-11.9-4.5-17.5c1.5-4,3.4-7.8,5.2-11.7c0.2-0.4,0.4-0.7,0.5-1.1
	c0.2,0.9,0.5,1.7,0.6,2.5c0.8,3.9,1.6,7.7,2.2,11.6C185.7,481.2,187.9,493.5,180.9,493.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M214.3,521.4c-3.8,3.4-9.2,4.4-13.3,1.1c-3.6-2.9-4.2-7.7-4-12s1.4-8.4,2-12.6c0.6-5.2,0.9-10.4,0.6-15.6
	C208.7,493.8,229.3,507.8,214.3,521.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M255.4,550.2c-2.9,4.5-8,3.4-11.1,0c-4.3-4.8-3.6-12.3-3.6-18.3s-1.4-15-6.1-20c5.7,3.8,11.5,7.8,16.3,12.6
	C257.6,531.2,260.9,541.7,255.4,550.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M127.6,538.6c-3.4,4.9-9.7,7.6-15.2,4.7c-10-5.3-6.1-20.9-3.2-29.2c3.8-10.7,10.8-19.5,18.4-27.8
	c-0.5,4.3-0.6,8.6-0.5,12.9c0.1,7,1.4,13.5,2.7,20.3S131.8,532.7,127.6,538.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M185.9,558.6c-5.7,1.8-10.9-2.2-13.3-7.1c-2.8-5.6-1.2-12.5,0.4-18.2s3.5-11.2,3.7-17.1
	c0.2-4.2-0.6-8.3-2.1-12.1c0.8,0.7,1.5,1.3,2.2,2c2.3,2.1,4.4,4.3,6.4,6.7c4.1,4.8,7.3,10.2,9.5,16.1
	C196.3,538.1,197.9,554.8,185.9,558.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M63,549.2c-0.5,6.4-3,12.5-7.9,16.7c-4.2,3.6-10.9,5-15.3,1.1c-4.2-3.6-5.1-10.4-4.8-15.6
	c0.3-6.1,2.6-12.1,5.5-17.4c3-5.5,6.8-10.6,11.2-15.1c2.3-2.3,4.7-4.4,7.2-6.4c-1.2,5.5,1.1,11.2,2.5,16.6
	C63,535.6,63.6,542.4,63,549.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M331.1,507.5c-5.5,1.6-10.5-2.3-13.4-6.7c-2.6-4.1-4-8.8-5.5-13.4c-1.9-6.1-1.1-15.4-5.9-20.4
	c3.6,1.8,7.5,2.6,11.5,3.9c5.5,1.8,10.4,5.7,14.4,9.7c4.2,4.2,7.4,9.2,7.9,15.2C340.6,501.4,336,506.1,331.1,507.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M312.8,552.8c-4.2,4.5-9.9,3.2-13.8-0.8c-6.8-6.8-5.9-18.1-3.8-26.7c1.8-7.4,4.7-14.6,8.4-21.2
	c0.7,11.4,9.2,20.9,12.4,31.7C317.7,541.6,317,548.2,312.8,552.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M94.1,600.6c-5.5,1.6-11.2-2.2-14.3-6.4c-3.3-4.5-5.1-10.1-6.2-15.6c-1.9-9-1.8-18.4-0.8-27.5
	c4.2,8,12,14.7,18.2,20.4c4.5,4.1,9.2,8.5,10.6,14.6C103.1,591.9,100,598.9,94.1,600.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M143.8,603.9c-4.4,2.7-10.1,0.8-14-1.8c-5.8-3.9-9.1-11.2-12-17.3c-3.3-6.9-6.5-13.8-10.2-20.5
	c-2-3.5-4-7-5.9-10.5c9,3.1,17.7,7.1,25.9,11.9c8.9,5.4,17.8,13.1,20.2,23.7C149,594.5,148.7,600.9,143.8,603.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M160.5,560c-1.3,2.9-4.2,2.5-6.5,1.1c-5.9-3.7-7.2-13.3-7.7-19.6c-0.5-5.5-0.1-11.1,1-16.5
	c2,3.8,3.8,7.6,5.8,11.4s4.4,7.2,6.3,11S162.4,556,160.5,560z"/>
        <path :fill="colors[`${id}-p-4`]" d="M223.4,572.3c-2.8,2.9-7.3,0.2-9.4-2.2c-2.7-3-2.4-8-2.3-11.8c0.2-6.5,0.4-13.7-1.7-20c4.1,3.8,7.7,8,11,12.5
	C224.9,556.5,229.1,566.3,223.4,572.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M247.4,586.5c-2,0.1-3.6-2.3-4.5-3.7c-1.6-2.7-2.9-5.6-3.9-8.7c-1.1-3.2-2.2-6.7-3-10.1c4.2,2.2,8,4.9,11.5,8
	c2.3,2.1,4.7,4.9,4.7,8.2C252.4,582.7,250,586.3,247.4,586.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M280.7,533.6c-1.8,3.5-5.4,3.7-8,0.9c-2.1-2.2-2.5-6-2.9-8.8c-0.9-5.6-1.9-11.3-4.9-16
	C274.4,515.1,286.3,523,280.7,533.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M336.9,538.6c-0.6,2.4-3.2,5-5.8,4.9c-2.1-0.1-3.7-2.8-4.6-4.5c-1.4-2.7-2.5-5.6-3.2-8.5
	c-1.1-4.1-1.8-8.3-2.6-12.4c5.5,2,10.5,6.9,13.6,11.5C336.2,532.2,337.7,535.4,336.9,538.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M280.3,454.5c-2.1,4.1-7.1,5.7-11.1,3.6c-0.8-0.4-1.5-0.9-2.1-1.5c-4-3.7-5.5-9.8-7.1-14.7
	c-3.2-9.5-7.8-19.9-15.7-26.3c3.5,1.6,7,3,10.6,4.3c5.2,1.8,10.1,4.1,14.8,6.9C278.5,432.1,286.1,444.4,280.3,454.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M373.4,530.2c-7.8,4.9-15.6-6.4-18.8-11.9c-2.3-4.2-4.3-8.6-5.7-13.1c-0.2-0.7-0.5-1.4-0.7-2.2
	c4.9,3,10.7,5,15.6,7.8c3.9,2.2,7.8,4.7,10.5,8.2S377.5,527.7,373.4,530.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M397,517.5c-4.2,2.7-9.5,0.6-12-3.3c-2.3-3.6-2.8-8.4-3.4-12.6c-1.1-8.6-3-17.4-6.7-25.3c2.1,1.6,4.6,3,6.6,4.4
	c4.2,3,8.2,6.4,11.8,10.1C399.4,497.4,407.2,510.9,397,517.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M323.6,251.7c-2.2,2.7-6.2,3.6-8.9,1.1c-2.1-1.9-2.9-5.2-3.5-7.9c-0.8-3.3-1.3-6.6-2.2-9.8c-1-3.5-2.4-7-4-10.3
	c6.4,1.8,12.1,4,17,8.8C327.1,238.7,328.4,245.9,323.6,251.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M539.4,247c-2.7,6.1-9.1,11.8-15.4,14.3c0.4-3.1,0.4-6.2,0-9.2c-0.5-6.2,0.7-13.2,5.6-17.5
	c3-2.6,6.3-0.5,8.5,1.9C541,239.5,541,243.4,539.4,247z"/>
        <path :fill="colors[`${id}-p-3`]" d="M318.5,178.8c-5.3,5.9-13.6,3.8-19-0.6c-4.8-3.9-7.6-9-9.5-14.6c4.7,3.3,10.9,4.6,16.4,4.5c3.3,0,6.7-0.1,9.9,1
	C320.7,170.7,321.4,175.7,318.5,178.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M384.6,188.3c-2.7-3.1-4.4-6.9-5-11c-0.9-6.1,0.7-12.7,2.8-18.7c0.3,2.3,1.1,4.6,2.2,6.7
	c1.9,3.6,4.1,7.1,6.5,10.4c2.5,3.5,5.6,6.8,7,11c1.3,3.8,0.1,8.4-4.5,7.9C389.8,194.1,386.9,191,384.6,188.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M406.6,190.5c-1.5-1.3-3-2.6-4.4-4c2.2,0.4,4.4,0.5,6.6,0.3c4.1-0.2,8.1-1.4,11.7,1.2c1.4,1,2.9,2.6,2.8,4.5
	c0,1.6-2,2.8-3.3,3.4C415.4,197.8,409.7,193.1,406.6,190.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M501.5,198.7c-1.1,6.5-4.3,11.8-8.2,16.6c0.1-0.3,0.1-0.7,0.2-1c0.6-4.1-0.8-8-2.5-11.6
	c-2.1-4.5-4.4-8.4-4.2-13.5c0.1-3.8,2.5-8.9,6.8-9.2s6.8,4,7.8,7.5C502.2,191.2,502.3,195,501.5,198.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M294.2,140.4c-8.6,5.6-18.1-1.4-23-8.4c-2.5-3.7-4.6-7.6-6.2-11.7l0,0c4.1,3.3,9.4,3.7,14.5,3.5
	c5.7-0.2,12.7-1.5,17.6,2.3C302.6,130.5,299,137.3,294.2,140.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M326.7,134.4c-1.2,4.7-5.1,8.4-8.7,11.4c-6.8,5.7-14.8,8-23.2,8.4c4.5-3,8-8.2,10.2-12.4
	c3.5-6.6,8.1-19.4,17.8-17.1C327.1,125.7,327.6,130.8,326.7,134.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M383.5,141.8c3.4-5.1,9-7.9,14.9-9.4c-0.3,0.3-0.5,0.7-0.8,1c-1.6,2.3-2.8,4.9-3.5,7.6
	c-1.4,4.7-2.4,10.8-5.8,14.5c-1.6,1.8-3.5,0.1-4.7-1.3C380.2,150.4,380.8,145.9,383.5,141.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M460.6,174.3c-1.5-7.2-9.1-12.6-14.2-17.2c-4.4-3.9-8.1-8.8-8.8-14.7c-0.6-5.1,2.3-10.1,7.8-10.2
	c4.9-0.1,9.5,4.3,12.2,8c2.5,3.7,4.1,7.9,4.6,12.2C463.2,159.6,462.4,167.2,460.6,174.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M518.5,178.3c-0.5,6.3-2.5,12.4-5.8,17.7c1.4-6.7,0.1-13.7-3.5-19.4c-2.4-3.5-5.7-6.3-7.7-10
	c-2.5-4.6-1-10.9,4.8-11.5c5-0.5,9.4,5,11.1,9.1C519.2,168.6,518.8,173.6,518.5,178.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M575,295.8c-5.1,4.6-11.3,7.9-18,9.6c4.7-5.5,5.2-13.7,6.4-20.6c0.9-5,2.4-10.7,6.8-13.8s9.6-0.6,12.1,3.4
	C587,281.8,580.3,290.9,575,295.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M612.9,286.8c-3.7,5.6-8.7,10.3-14.5,13.7c1.8-4.4,1.7-9.6,1.3-14.5c-0.6-7.9,0.4-22.1,11.5-21.4
	c4.5,0.3,7.1,5.1,7.1,9.1C618.4,278.3,615.5,283,612.9,286.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M570.7,255c-7.7,3.5-16.2,4.8-24.6,3.9c3.6-1.9,7-4.3,10-7.2c3.7-3.7,5.9-8.3,8-13c2-4.5,4.4-9.6,9.3-11.4
	c5.2-1.9,8.9,2,10.2,6.6C586.5,243.5,578.7,251.2,570.7,255z"/>
        <path :fill="colors[`${id}-p-4`]" d="M606.7,246.9c-2.5,3.5-5.5,6.7-8.9,9.4c0.5-1.3,0.9-2.6,1.2-3.9c0.7-3.9-0.6-7.8-1.4-11.5
	c-0.9-4.4-0.6-9.1,2.5-12.5c2.4-2.7,6.5-2.8,8.8,0.1C613.2,234.2,610.3,241.7,606.7,246.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M569.2,206.7c-0.7,7.4-4.6,14.1-9.3,20.1c0.1-0.6,0.2-1.2,0.3-1.8c0.7-5.6-0.9-10.6-3.2-15.6s-5.2-9.7-5.6-15.2
	c-0.3-3.6,1.1-9.5,5.5-10c4.6-0.4,8.5,4.8,10.2,8.3C569.3,196.9,569.6,202,569.2,206.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M597.5,202.4c-1.3,6-3,12.7-6.8,17.7c1.4-7.1-2.9-13.9-6-20.1c-1.9-3.8-3.4-7.9-2.4-12.2c0.7-3.4,3.4-6.1,7-5.5
	c3.9,0.6,6.7,4.8,8,8.2C598.7,194.3,598.4,198.5,597.5,202.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M642.9,316.8c-1.9,3.9-4.6,7.3-8,10c-5.3,4.3-11.6,7.2-18.3,8.4c5.5-4.5,7.2-12.3,9-19c1.3-4.8,2.9-9.6,6.7-13
	c3.3-3,8.7-3.9,11.4,0.4C645.9,307.4,644.5,313.1,642.9,316.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M639.8,279.4c-1.9,2.7-3.9,5.3-6.2,7.7c1.5-5.2,0.7-11.5,0.3-16.2c-0.3-4.3-0.6-9.5,2.8-12.7s7.2,0,8.4,3.5
	C647.2,267.7,643.1,274.6,639.8,279.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M633.2,247c-3.7,4.9-9.1,8.2-15.1,9.4c3.3-3.7,5.1-9.6,6.3-13.6s1.8-8.3,3.9-12.1c1.8-3.3,5.5-5.3,8.6-2.4
	C642.2,233,636.5,242.8,633.2,247z"/>
        <path :fill="colors[`${id}-p-3`]" d="M625.8,209.1c-5.1,4.1-11.1,7-17.5,8.4c2.3-2.2,4.2-4.8,5.6-7.7c1.8-3.7,2.4-7.9,3.2-11.9
	c0.9-4.2,2.1-8.6,5.1-11.7c3.8-4,8.9-3,11.4,1.6C637.7,195.4,631.3,204.5,625.8,209.1z"/>
        <path :fill="colors[`${id}-p-0`]" d="M481.3,187.2c-1.5,4.8-4.4,9.1-7.4,13.1c-2.4,3.1-5.4,6.7-9,8.7c0.5-1.4,0.7-2.9,0.8-4.4
	c0.4-3.7,0.4-7.4,0.5-11.2c0-4,0.3-7.9,0.8-11.9c0.5-2.7,1.2-6.2,3.6-7.9c2.7-1.8,7.1,0.2,9.3,2
	C483.3,178.5,482.5,183.5,481.3,187.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M575.4,140.2c-5.7-2.2-19-12.1-10.5-18c13.2-9.3,23.7,12.7,24.7,25.9C586.2,144.1,580,142,575.4,140.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M433,140.3c1.2,4.5,1.5,9.1,0.9,13.7c-0.5,5.3-1.9,10.6-4.1,15.5c0-0.5,0-0.9,0-1.4c0-4.7-1.9-9-3.8-13.1
	c-2.4-5.3-4.5-10.7-4.9-16.6c-0.2-2.6,0.3-9.6,4.7-8.3C429.7,131.3,431.9,136.9,433,140.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M551.2,176.6c-4.1,4.9-9.1,8.9-14.9,11.7c1.9-3.1,3.2-6.5,4.1-10.1c1.1-4.6,0.7-9.4,1.3-14.1s2.4-9.4,6.2-12.2
	s8.1-0.1,9.6,4C560.4,163.1,555.7,171.1,551.2,176.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M577.3,167.8c-0.1,3.5-0.7,7-1.9,10.4c-0.5-3.1-1.6-6.1-3-8.9c-1.1-2.4-2.6-4.5-3.5-7c-0.7-2.2-0.4-6.1,2.5-6.4
	C576.7,155.2,577.4,164.5,577.3,167.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M628.3,162.1c-2.1,4.4-5.5,8.1-9.7,10.7c-6.6,4-14.4,4.2-21.8,2.9c6.4-2.4,11.3-7.1,14.6-13.5
	c1.9-3.6,3.4-7.4,6.2-10.4s9-4.7,11.6-0.7C631.3,154.3,629.8,159.1,628.3,162.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M635.4,122.6c-0.1,4.1-3.4,8.5-5.8,11.6c-1.5,1.9-3.2,3.5-5.2,4.9c-1.3,0.9-2.8,1.3-4.4,1.2
	c1.3-2.7,2.3-5.5,2.8-8.4c0.6-3.1,0.6-6.2,1.4-9.2c0.9-3.3,3.2-6.2,6.8-6.7S635.5,120,635.4,122.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M528.3,136.6c-2.9-2.6-6-4.9-8-8.4c-2.4-4.3,0.1-9.7,4.5-11.3c4.3-1.6,8.1,1.7,10.5,4.9
	c2.2,3.3,3.6,7.2,3.8,11.1c0.5,6.1-0.2,12.3-2.1,18.1C536.3,145.5,532.7,140.6,528.3,136.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M546.3,103.8c-2.4-3.6-4.4-7.5-4.2-12c0.2-3.4,2.1-7.4,5.7-8.2s7.5,2.9,9.4,5.6c2.2,3.3,3.5,7.2,3.8,11.2
	c0.6,8.1-3,16.5-6.8,23.7c-0.2-3.1-0.8-6.2-1.7-9.1C551.1,110.8,548.6,107.3,546.3,103.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M580.5,102c-2.9-2.6-6.1-5.3-7.3-9.1c-1-3.3,0.1-8.5,4.3-8.5c3.2,0,6.8,3.6,8.5,6c1.8,2.6,3,5.5,3.5,8.5
	c1,4.9,1.1,10,0.3,15C588.2,109.2,584.3,105.4,580.5,102z"/>
        <path :fill="colors[`${id}-p-3`]" d="M490.9,115.2c-3.8-2.4-6.1-6.5-6.2-11c-0.3-3.9,2.6-7.2,6.5-7.5c0.1,0,0.3,0,0.4,0c7.2-0.3,12.6,6.5,15.1,12.5
	c2.2,5.4,3.1,11.2,2.7,17C505.1,120.6,496.5,119,490.9,115.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M594.6,69.7c-1.6-4.4-2.7-10.2,0.9-14s9.7-0.2,12.8,2.5c6,5.4,5.4,14.1,3.2,21.1c-2.2,6.7-5.7,12.9-10.3,18.2
	C603.1,87.9,597.9,78.8,594.6,69.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M468.6,112.8c-3.8-3.2-8-6-11.6-9.4s-7-8.2-5.3-13.2c1.6-4.9,7.5-5.1,11.5-3.4c6.6,2.9,10.2,10.5,12.1,17
	c1.7,5.7,2.5,11.7,2.4,17.7C474.8,118.4,471.8,115.5,468.6,112.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M521.8,106.2c-0.6-3.8-1.6-7.6-3.1-11.2c-2.1-5.1-5.6-9.2-8.5-13.8c-2.7-4-3.9-8.7-3.5-13.5
	c0.4-3.8,1.9-8.2,5.2-10.4c4-2.7,8.7,0.7,11.1,4c2.5,3.6,3.7,8.1,4.3,12.3C529.2,85.1,525.2,95.6,521.8,106.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M557.4,68.3c-2.5-3.3-3.3-7.6-2.3-11.7c1-4,5.4-5.3,8.8-3.5c8.9,4.8,6.4,18.5,2.2,26.6
	C564.4,75.5,559.9,71.8,557.4,68.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M290.9,107.7c-2.9,2.5-7.3,2.9-10.9,2.1c-4.3-0.9-7.1-3.9-9.6-7.2c-4.6-6.1-8.4-14-9.5-21.9
	c7.5,3.8,16.3,3.9,23.9,7.7c3.7,1.8,7.3,4.3,9.2,8S293.9,105.1,290.9,107.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M208.6,80.3c-8.9-0.7-13.1-11-15.1-18.3c-1.2-4.5-2-9-2.3-13.6c-0.1-0.7-0.1-1.4-0.1-2.1l0.7,0.8
	c2.2,2.4,4.6,4.7,7.2,6.7c4.3,3.5,9.2,6.1,13.2,9.8c3,2.8,6.7,6.7,7.1,11C219.7,79.6,212.2,80.5,208.6,80.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M222.9,54.5c-9.3,0-12.9-8.2-13.5-16.3c-0.2-3.7-0.1-7.4,0.3-11.1c1.4,2,3,3.8,4.7,5.6c2.9,3,6.2,5.1,8.7,8.4
	C225.8,44.8,231.3,54.6,222.9,54.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M270,57.7c-2.5,3.9-8.2,4-12.2,2.9c-5.6-1.6-10.5-6.2-13.9-10.7c-4.4-6-6.8-13.1-8.9-20.2
	c0.9,0.8,1.8,1.6,2.7,2.4c4.4,3.4,9.4,5.9,14.8,7.3c4.6,1.4,9.4,2.5,13.5,5C270.7,47.1,273,52.9,270,57.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M305.5,78c-8.2,7-19.1-0.8-23.4-8.3c-3.4-6-2.7-13.7-1.8-20.2c0.5-3.3,1.1-6.5,1.6-9.8c4,6.1,11,10.3,16.8,14.6
	C305.7,59.4,314.3,70.6,305.5,78z"/>
        <path :fill="colors[`${id}-p-3`]" d="M328.4,112.1c-0.9,3-5.8,4.2-8.4,4.2c-3.8,0-6.4-3.3-8-6.3c-3-5.7-3.3-12-4.2-18.3l0.5,0.3c3,1.8,6,3.7,8.8,5.8
	c3,2.1,5.8,4.5,8.3,7.2C327,106.8,329.2,109.5,328.4,112.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M366.1,116.7c-9.2,4.8-18.8,0-25.1-6.9c-5.6-6.1-9.7-13.7-13.6-21.1c5,1.3,9.9,2.6,14.9,3.8
	c5.5,1.3,11.1,0.5,16.7,1.3c5.8,0.8,10.7,4.9,12.4,10.5C373,109,370.8,114.4,366.1,116.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M334.5,74.5c-11.7,10.9-16.6-17.6-17.4-26.5l1,0.9c3.2,2.8,6.5,5.3,9.7,8.1C332.4,61,340.9,68.6,334.5,74.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M362.7,78.5c-2.3,4.2-7.6,4.3-11.3,2.2c-6-3.4-7.8-11.6-8.7-17.8c-0.7-5-1.1-10-1.1-15.1
	c4.8,6.7,11.9,11.2,17.5,17.2C362.4,68.5,365.3,73.8,362.7,78.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M445,101.2c-5.8-1.6-11.4,0.4-17.4,2.4c-4.9,1.7-9.7,3.9-15,3.8c-4.4-0.1-9-2.9-7.5-7.8c1.2-4,5.6-6.9,9.2-8.3
	c4-1.6,8.7-1.3,12.8-0.3C433.8,92.9,440,96.4,445,101.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M629.7,94c-6,3.6-12.5,6.1-19.3,7.4c6.7-5.4,9-13.7,11.6-21.8c1.5-4.7,5.2-10,10.1-11.5s9.3,4.2,10.3,8.4
	C644.4,84.3,635.4,90.7,629.7,94z"/>
        <path :fill="colors[`${id}-p-3`]" d="M647.7,160c-0.6,3.2-2.1,6.2-4.2,8.7c-1.9,2.4-4.2,4.4-6.9,5.9c-1.8,1-3.8,1.5-5.8,1.7
	c3.2-3.3,4.4-7.9,4.8-12.5c0.1-2.6,0.4-5.2,0.9-7.8c0.9-3.4,4.8-6.5,8.2-4.5C647.5,153,648.1,157.2,647.7,160z"/>
        <path :fill="colors[`${id}-p-3`]" d="M622.3,64.9c0.4-5.8-2.7-11.4-3.9-17c-0.8-3.8-0.7-8,2-11.1c2.2-2.6,5.9-2.3,8.6-0.9c6.5,3.4,5.6,12.1,3,17.6
	C629.8,58.2,626.2,61.6,622.3,64.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M577,56c-1.4-3.7-1.7-7.5,0.2-11.1c0.8-1.4,1.9-2.6,3.3-3.3c2-1.2,3.9,0.4,5.3,1.8c4,4.2,2.1,11.2,0.5,16
	c-1.2,3.4-2.7,6.8-4.4,10C581.5,64.7,578.6,60.2,577,56z"/>
        <path :fill="colors[`${id}-p-3`]" d="M545.4,55.2c-1.5,6.6-4.6,12.6-8,18.5c0.8-3.5,0.9-7.1,0.5-10.7c-0.6-4.5-3.3-8.2-5.7-11.9
	c-2.5-3.8-4.9-7.9-3.7-12.6c1-4.3,5.3-6.9,9.6-5.9c0,0,0.1,0,0.1,0c4.1,1.1,6.7,6.4,7.7,10.1C546.9,46.8,546.3,51.2,545.4,55.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M639.5,106.1c-3.8,1.4-8.2,1-12.2,0.1c2.5-1.8,5.2-3.6,7.6-5.5c1.6-1.3,3.2-2.4,4.9-3.5c1.8-1,6.5-1.9,6.7,1.2
	S642.1,105.1,639.5,106.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M514.5,44.5c-3.3-1.7-7-2.4-10.5-3.6s-7.6-2.6-9.9-5.7c-2.9-3.8-1.9-8.3,2.3-10.4c6.6-3.4,14.9,1.2,18.8,6.6
	c1.9,2.8,3.4,5.9,4.2,9.2c0.6,2.6,1,5.2,1.2,7.9C518.7,46.9,516.7,45.6,514.5,44.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M306.9,40.8c-1.3,3-5,1.7-7,0.5c-5.6-3.5-5.1-10.6-4.3-16.3c0.3-2,0.4-4,0.7-6.1c0-0.3,0.1-0.7,0.1-1.1
	c1.5,1.6,3,3.2,4.3,5c2,2.6,3.7,5.4,5.3,8.4C307.5,34,308.2,37.7,306.9,40.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M396.8,67.4c-1.4,5.4-8.6,7.2-13.4,7c-9.9-0.5-15-10.6-17.9-18.8c-2.1-6.5-3.7-13.1-4.9-19.7
	c3.6,3.6,7.6,6.7,12,9.3c5.1,3,10.8,4.7,15.9,7.8C393.2,55.8,398.3,61.5,396.8,67.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M440.5,45.3c5.2-3.1,11.9-1,16.8,1.5c5.2,2.9,9.8,6.7,13.4,11.4c7.5,9.1,12.3,21.3,10.3,33.2
	c0,0.2-0.1,0.3-0.1,0.5c-3.3-4.7-7.5-8.6-12.3-11.7c-5.7-3.6-12.4-5.2-18.6-7.8c-5.4-2.2-10.9-5.3-13.4-10.8S434.9,48.6,440.5,45.3z
	"/>
        <path :fill="colors[`${id}-p-3`]" d="M201,608.9c-2.1,4.8-6.8,7.2-12,7.1c-11.1-0.4-18.8-12.2-21.6-21.7c-2.9-10-1.8-20.3,0.2-30.3
	c5,4.9,10.3,9.5,15.8,13.8c5.2,4.1,10.8,7.9,14.9,13.2S203.7,602.7,201,608.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M283.4,565.3c-1,3.6-4.6,5.4-8.1,5.3c-7.7-0.1-9.8-8.5-9.8-14.6c0-5,1-9.8,1.5-14.7c2.9,5.5,8.4,9.6,12.8,13.9
	C282.6,557.9,284.5,561.3,283.4,565.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M352,589.2c-8.1,9.8-23.9,7.7-33.9,2.3c-10.5-5.7-16.2-17.4-21.4-27.9c4.4,2.8,9.4,4.6,14.6,5.4
	c7.6,0.9,14.8-1,22.3-2.3c7.1-1.2,15.3-0.5,19.6,6C356.8,578,355.9,584.7,352,589.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M379.3,555.5c-1,4.6-6,6.7-10.2,6.5c-7.7-0.4-13.2-8.1-16.6-14.1c-1.9-3.4-3.5-7.1-4.8-10.8c-0.7-2-1.2-4-1.8-6
	c4.1,2.3,8.1,4.9,12.3,7.1c4.5,2.4,9.5,3.8,14,6.2C376.3,546.4,380.4,550.3,379.3,555.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M240.3,616.1c-2.7,4.5-7.9,4.7-12.4,3.2c-5.1-1.7-8.9-6.3-11.7-10.7c-3.3-5.2-5.3-11.1-7.3-16.9
	c-1.9-5.3-3.3-10.7-4.8-16.2c4.8,2.1,8.9,5.6,13.2,8.5s9.3,5,13.8,7.7C239.4,597,246,606.6,240.3,616.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M43.4,622.1c-10.2-4.6-8.3-18.4-4.8-26.7c4.3-10,12.7-17.7,21-24.8c-0.5,5-0.2,10.1,0.7,15
	c1.2,6.8,4.4,13,5.1,19.9C66.6,617,54.5,627.1,43.4,622.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M118,639.5c-4.3-2.2-6.3-6.9-7.5-11.3c-2.2-8.1-1.5-16.6-0.6-24.8c0.5,0.8,1.1,1.6,1.7,2.3c2.6,3,6.1,5.3,9,7.8
	c5.8,5.1,13.3,12.7,8.8,20.9C127.1,638.8,122.8,642,118,639.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M544.4,210.5c-0.5,9-10.8,14.6-18.2,17.1c-7.2,2.4-15.9,3.3-23.5,1.4c4.1-2.1,8-4.7,11.6-7.6
	c4.1-3.3,7.4-7.1,10.8-11.1c3.1-3.6,6.9-7.8,11.9-8.2S544.6,206,544.4,210.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M611.4,140.9c-3,6.1-7.5,11.4-13.2,15.1c0.8-1.7,1.5-3.5,2-5.3c1.2-4.4,1.7-9,1.4-13.5
	c-0.3-4.9-1.8-10.5,0.5-15.1c1.8-3.5,6.8-6.5,10.3-3.4c2.7,2.4,3,7,2.7,10.2C614.7,133.1,613.5,137.2,611.4,140.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M419.9,61.6c-3.6,6.3-10,11.4-16.6,14.5c1.1-2.4,1.7-5,2-7.7c0.4-4.2,0.5-8.5,0.3-12.8c-0.1-4-0.3-8,0.9-11.9
	c1.5-4.7,6.4-8,10.9-5.3C425.3,43.2,423.7,55,419.9,61.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M554.1,47.9c0.6-2.1,0.8-4.4,1.1-5.9c1-5.1,1.5-10.8,4.3-15.3c0.9-1.7,2.6-2.7,4.5-2.6c3.6,0.3,6.4,3.3,6.4,6.9
	C570.7,40.8,560.9,43.6,554.1,47.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M284,615.8c-1.7,5.2-6.5,7.8-11.6,5.4c-8-3.7-9.6-14.2-9.7-22c0.1-5.4,0.4-10.8,1-16.2
	c4.4,7.2,12,11.8,17.4,18.3C284.6,605.3,285.7,610.8,284,615.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M310.4,614.4c-3.8,2.7-9-1.2-11.3-4.1c-2.4-3.2-3.8-7.1-5.4-10.8c-1.7-3.8-3.8-7.5-5.8-11.2
	c-0.8-1.6-1.8-3.1-2.8-4.5l2.5,0.8c5,1.7,9.6,4.1,13.8,7.3c4.3,3.1,8.6,7.3,10.6,12.3C313.3,607.3,313.5,612.1,310.4,614.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M177,641c-1.4,4.1-5.5,5.5-9.4,4.4c-4.5-1.3-7.3-6-8.9-10.1c-2-5.1-2.1-10.7-1.8-16.1s1.2-10.4,1.9-15.6
	l0.1-0.9c1.6,2.8,3.3,5.5,5.3,8c3.4,4.6,6.6,9.3,9.6,14.2C176.9,629.9,178.9,635.2,177,641z"/>
        <path :fill="colors[`${id}-p-3`]" d="M87.5,639.3c-3.1,2.9-8,2.6-11.6,0.9c-6.7-3.2-7.8-11.8-7.3-18.4c0.5-6.3,2.5-12.3,6-17.6
	c6.2,6.3,12.8,13.4,15.8,21.8C92.2,630.6,91.1,635.9,87.5,639.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M39.9,920.8c-1,5-1,9.9-1.2,14.9c-0.3,4.8-1.3,9.7-4.7,13.3s-10.4,5.6-13.2,0.4c-4.2-7.8,2.8-20.6,6.7-27.3
	c3.6-6.5,9-11.9,15.5-15.5C42,911.3,40.8,916,39.9,920.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M35.4,523.1c-1,3.3-1.7,6.7-2.9,10c-1.2,3.4-3.1,6.5-5.5,9.2c-2.7,2.8-7.6,2.9-10.1-0.3
	c-2.2-2.9-1.5-7.3,0-10.4c2.1-4.3,5.9-7.6,9.8-10.2c3-1.9,6.2-3.2,9.7-4l1.9-0.4C36.7,518.5,36,521.2,35.4,523.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M689.8,367c0.8-3,0.2-6.5-0.5-9.5c-1.2-5.5-4.1-11.5-3-17.1c0.7-3.6,4.9-5.7,8.3-5.2c4.3,0.6,5.8,6.5,5.9,10
	C700.8,353.4,695.7,361.2,689.8,367z"/>
        <path :fill="colors[`${id}-p-4`]" d="M984.6,465.6c-3.8,2.8-8.7-1.5-10.5-4.6s-2.3-7.2-3-10.7c-1-4.7-1.4-11-3.9-15.6c4.6,2.5,9.2,4.9,13,8.6
	C985,447.9,991.7,460.4,984.6,465.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M997.5,482.3c-1.4,2.9-6.3,2.3-8.8,2.1c-4.9-0.7-9.7-1.9-14.2-3.9c2-0.5,4-1.2,5.9-1.7c5-1.2,11.1-2.9,16.1-1.4
	C998.9,478.2,998.4,480.6,997.5,482.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M996.6,540.8c-3.4,6.6-13.2-2.5-15.9-5.3c-5.1-5.2-7.7-12.2-10.8-18.8c6.5,0.9,13.2,2.9,18.2,7.1
	C992,527.2,999.5,535.2,996.6,540.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M767.1,350c-2.7,7.7-8.5,13.5-14.7,18.6c2.2-5.1-0.1-11.7-1.3-17.2c-1.4-6.1-2.5-12.4-0.9-18.5
	c1.3-5.3,7.3-9.6,12.7-8.2c4.9,1.3,6.1,8,6.3,12.3C769.4,341.4,768.6,345.8,767.1,350z"/>
        <path :fill="colors[`${id}-p-3`]" d="M786.5,355.2c2.9-4.6,3.8-10.6,5.3-16.1c1.4-5.2,3.6-10.2,7.5-14c5.5-5.4,11.7-0.8,12.4,5.7
	C813.2,344.1,798.5,350.7,786.5,355.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M364.9,651.2c-5.6,3.2-10.9,0.8-13.9-4.5c-3.9-7-1.1-14.7,2.3-21.2c3-5.7,6.7-11.1,8.6-17.3s1.6-13.1,1.7-19.7
	c3,2.5,4.8,6.8,6.2,10.1c1.9,4.6,3.5,9.3,4.7,14.2C377.6,625.4,377.6,643.7,364.9,651.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M386.7,705.4c-6.1-0.3-9.4-6.2-10.6-11.5c-1.9-8.4,2.1-17.4,5.5-24.9c4.9-10.8,11.9-23.4,8.9-35.5
	c2.4,6,4.9,11.9,7.1,17.9c2.8,7.6,3.6,15.5,4.5,23.5C403.2,685.8,402.1,706,386.7,705.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M679.7,324.9c-2.1,4.4-7.1,6.6-11.5,8c-4.8,1.4-9.7,2-14.7,1.7c-2.8-0.2-5.5-0.7-8.1-1.5c1-0.7,2-1.5,2.9-2.2
	c3.3-2.5,6.5-5.1,9.9-7.5c3.5-2.6,7.2-4.9,11.1-7c2.5-1.3,6.5-3.5,9.3-1.9C681.8,316.2,680.9,322.3,679.7,324.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M858.4,334.9c-2.5,5.6-7.1,13.3-13.4,15.5c1.2-9.6-3.1-19.5-1.3-29.2c0.7-3.9,2.1-8,5-10.9
	c3.3-3.3,8.4-1.6,11.2,1.4C865.1,317.4,861,328.8,858.4,334.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M992.6,576.3c-2,3.4-8.4,2.7-11.5,1.6c-6-2-4.9-11.4-4.7-16.2c0.3-3.6,0.4-7.2,0.2-10.8
	c5.9,4.2,11.8,8.2,15.3,14.8C993.5,568.9,994.5,573.2,992.6,576.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M734.5,307c-1.2,5.2-5.6,9.7-10,12.5c-7.4,4.6-15.6,4.1-23.7,5.1c5.3-4.7,8.5-11.5,10.6-18.4
	c1.2-4.1,2.5-8.3,5.6-11.4c3.5-3.5,10.8-3.7,14.6-0.8S735.5,302.8,734.5,307z"/>
        <path :fill="colors[`${id}-p-4`]" d="M677.6,290.1c-6.4,6.7-14.2,12.1-22.7,15.9c0.6-0.9,1.1-1.9,1.6-2.8c2-4,3.7-8,5.3-12.2
	c2.3-5.8,6-19.6,13.8-20.3c4-0.4,8.5,3.6,8.7,7.5C684.4,282.8,680.5,287.1,677.6,290.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M784.6,292.9c-6,12.9-21.1,16.8-34.2,20.3c2.8-4.2,4.5-9.5,6-13.9c2.7-7.8,5.1-18.7,12.4-23.6
	c3.7-2.5,8.2-1.6,12.1,0.1C788.2,278.9,787.5,286.7,784.6,292.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M710.9,274.8c-2.3,8.3-10.2,14.5-17.1,19.8c2.5-4.7,4-9.8,4.3-15.1c0.3-6.4-2.8-17.1,5.1-20.1
	C713,255.6,712.5,269.1,710.9,274.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M746.5,261.6c-2.2,7.2-9.9,13.8-16.1,18.1c1-3.7,0.5-8.6,0.6-11.9c0.2-5.2-0.4-13.3,3.3-17.6
	c2-2.2,5.2-0.6,7.2,0.6C746,253.2,748,256.8,746.5,261.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M783.7,318.8c0.1-6-0.8-17.7,7.4-18.6c10.3-1.1,4.5,10.2,1.9,14.8c-2.9,4.9-6.1,9.5-9.7,13.9
	C783.7,325.5,783.8,322.1,783.7,318.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M828.1,318.3c-0.8-3.3-1.9-6.5-3.2-9.6c-3-8.1-7.2-17.6,0.2-24.6c8.2-7.8,10.8,6.7,11,12.4
	C836.5,304.9,832.5,311.6,828.1,318.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M867.8,268.9c-0.4,9.7-6.7,18.1-14.2,24c-0.1-3.7-0.8-7.5-1.1-10.3c-0.7-7-2.8-17.6,2.2-23.6
	c2.6-3.1,8.2-4.3,10.9-0.8C867.8,261.1,867.9,265.4,867.8,268.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M678.4,250c-4,9.2-14.4,14.7-23.7,17.3c1.5-2.1,2.7-4.3,3.8-6.6c1.9-3.7,2.8-7.7,3.8-11.7
	c1.5-6.1,4.9-17.7,12.7-17.8C684.6,231.2,680.5,245.2,678.4,250z"/>
        <path :fill="colors[`${id}-p-3`]" d="M786.1,246c-4.9,5.5-12.7,12.7-20.7,14.9c3.2-4.7,4.5-11.9,5.6-16c2.3-9.1,5.1-21,17-19.1
	C800,227.7,790.3,241.3,786.1,246z"/>
        <path :fill="colors[`${id}-p-2`]" d="M895.9,335.3c-5.9,2.4-12,4.3-18.3,5.7c-3.3,0.9-6.7,1.6-10.1,2c11.4-12.2,33.4-51.1,46.8-28.7
	C920.4,324.5,902.7,332.4,895.9,335.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M988.5,383.5c-5.8,6.7-13.9,10.2-22.3,12.2c2.4-4.4,4.1-9.4,6.2-13.8c2.6-5.5,6.6-15.2,13.1-17
	c3.5-1,6.5,3.9,7.3,6.6C994.2,375.8,991.2,380.4,988.5,383.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M679.2,202.2c-2.6,4.1-7.2,7.4-11.1,10.1c-5.5,3.8-14.3,9.2-22.1,9c3.9-4.2,6.1-11.3,7.9-15.7
	c2.2-5.9,5.4-11.3,9.3-16.2c3.7-4.4,12.5-9.5,17-3.2C683.3,190.5,681.8,198.1,679.2,202.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M705.5,236.9c-2.7,4.4-6.1,8.3-10.1,11.6c0.5-2.8,0.3-6.3,0.4-8.1c0.3-4.7,0.2-12.1,3.9-15.6
	c2.1-2,6,0.7,7.2,2.4C709.1,230.1,707,234.3,705.5,236.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M731.7,233c-3.4,6.3-9,10.9-14.8,14.9c0-0.6,0-1.1,0.1-1.6c0.2-3.1,0.4-6.2,0.6-9.3c0.5-5.2,0.8-14.3,5.8-17.4
	c2.7-1.7,6.7,0.7,8.7,2.5C735.3,225,733.4,229.8,731.7,233z"/>
        <path :fill="colors[`${id}-p-1`]" d="M750.5,222.2c-2.8-6.5-7.2-14.9-5.7-22.1c0.8-3.6,4.4-5.1,7.8-5.2c5.3-0.2,7.5,6.1,8.4,10.3
	c1.9,9-1.1,18.6-6.5,26C753.4,228,751.6,224.8,750.5,222.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M772.3,216.7c7.9-12.5,14.8-39.2,31-28.6C817,197.1,783.7,213.2,772.3,216.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M812.8,268.4c-1.5,5.4-4.9,11.6-9.7,15l-0.1-0.6c-0.6-4.1-1.3-8.2-1.8-12.3c-0.5-3.5-0.4-7,0.2-10.5
	c0.9-3.8,3.9-4.8,7.5-4.9C815.6,255.1,813.9,264.4,812.8,268.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M825.9,233.4c-3.3,3.1-7,5.7-10.9,7.9c-2.6,1.5-5.3,2.8-8.1,3.7c-1.2,0.3-2.5,0.6-3.8,0.7
	c4.3-2.6,7.4-8.6,9.2-12.6c3-6.5,5.4-13.8,11-18.7c4.3-3.8,8.3,0.6,9.5,5C834.2,224.7,829.2,230.2,825.9,233.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M884,299.5c-5.1,1.1-9.3,4.6-14.3,5.5c4.2-7.9,6.8-16.8,10.8-24.8s11.8-21,22.6-15.4
	C921,274.1,900.1,290.4,884,299.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M948,327.9c-5.7,5-13.7,9.5-21.6,8.7c8.1-6.9,8.5-17.5,12.3-27.3c2-5.2,5.7-10.8,10.7-13.4
	c5.7-3,10.9,3.3,11.6,8.4C962.1,313.5,954.3,322.3,948,327.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M903.7,221.4c-1.2,7.1-9,13.1-14,17.6c-3,2.7-6.8,5.6-9.7,8.9c0.1-3.4,0.1-6.7,0.3-10.1
	c0.6-6,2.5-11.8,5.6-17.1c2.1-3.8,6.1-9.9,11.2-9.2C901.3,212.1,904.3,217.5,903.7,221.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M827.9,265.5c4.5-8.2,5.7-33.2,16.5-28.5C856.5,242.1,837.3,260.7,827.9,265.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M272.2,893.2c-5.6,3-12.7,1.8-18.5-0.1c-7.6-2.6-14.6-6.6-20.7-11.9l1.2,0.2c8.6,1.1,15.7-3.3,23.3-6.2
	c5.3-2,25.5-9.8,25.1,1.4C282.1,883.6,278.2,889.8,272.2,893.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M286,843c-2,5.7-6.9,9.3-12.5,11c-14.1,4.1-34.7-2.9-43.4-16c1.3,0.3,2.6,0.4,3.9,0.4
	c10.2,0.1,20.4-4.1,29.9-7.1c4.9-1.5,18.3-7.1,22.4-1.5C288.8,833.3,287.3,839.4,286,843z"/>
        <path :fill="colors[`${id}-p-4`]" d="M332,833.7c-6.1,12.4-20.1,21-33.4,25.2c6.2-6.3,10.1-15.7,11-23.8c0.6-5.3-0.3-10.7-0.1-16
	c0.3-6.8,4-10.2,10.7-11s12.3,1.5,14.3,8C336.2,821.8,334.5,828.5,332,833.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M313.9,764.3c0.4-7.4-3.8-14.4-8.1-20.1c-4.1-5.5-6.7-10.7-7.4-17.7c-0.5-5.7,0.7-12.9,7.6-13.1
	c15.7-0.5,19.6,18.4,18.7,30.4c-0.7,9.5-4.6,21.3-12.4,27.7C313.3,769.2,313.8,766.8,313.9,764.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M343.3,793.6c3.9-5.2,5.5-13.1,5.5-18.5c0-8.3-7.8-26.5,6.5-27.4c5.6-0.4,9.5,6.3,11.1,10.8
	c1.9,5.4,0.4,11.2-1.9,16.3C360.4,783.6,352.4,790.2,343.3,793.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M340,738.8c1.9-5.2-0.7-12.6-3-17.2c-5.2-10.4-18.9-22-8.6-34.1c3.9-4.6,10.6-3,15-0.2
	c6.4,4.1,10.8,12.4,11.4,19.8C355.8,719.4,347.6,729.9,340,738.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M280.3,677.9c-4.8-1.2-10-1.2-14.5-3.5c-6-3.1-6.2-12.5-0.9-16.2c9.9-6.9,24.8,0.8,29.7,10.3
	c3.8,7.4,3.8,16,4.4,24.3C295.1,685.5,288.3,680.1,280.3,677.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M322.3,660.5c-4.2-4.5-10-6.4-14.5-10.2c-4.2-3.5-17.6-17.1-9-21.6c4.9-2.6,11.5-0.8,15.9,2
	c4.6,3,7.7,8.1,9.7,13.1c1.9,4.9,2.8,10.1,2.8,15.3c0,2.4-0.3,4.7-0.8,7C325.2,664,323.8,662.2,322.3,660.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M260.6,644.4c-4.8,4.1-9.2,8.7-13.1,13.6c-4.4,5.4-9.4,10.7-16.3,12.7c-6.1,1.8-14.3-0.3-15.8-7.2
	c-1.3-5.8,2.7-12.2,6.3-16.3c4.2-4.7,9.8-8,15.9-9.5c9.6-2.5,19.9-0.7,29.4,2.3C264.7,641.3,262.5,642.7,260.6,644.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M260.7,783.3c-6.3,5.7-15.4,10.8-24.4,12c6.6-4.4,10.5-12.9,11.4-20.5c0.7-6.1-0.3-12.2,1-18.2
	c1.9-8.8,11.3-11.2,17.9-5.8C278.8,760.7,269.5,775.4,260.7,783.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M247.5,730.2c-8.7-2.5-23.7-2.4-25.3-14.2c-0.8-6,2.2-11.2,8.2-12.4c6.6-1.4,13.3,3.3,17.9,7.4
	c4.6,4.3,7.9,9.8,9.6,15.8c1,3.8,1.2,7.8,0.8,11.8C256.6,734.5,252.5,731.6,247.5,730.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M206.7,690.2c-3.6-0.8-5.7-5.5-4.1-8.8c1.9-3.9,9-4.1,12.7-4.3c9.5-0.4,18.3,1.6,26.9,4.9
	c-3.4,0.7-6.8,1.6-10.1,2.8C224.5,687.2,214.8,692.1,206.7,690.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M179.5,686.2c-5,8-14,24.2-25,14.8c-10.8-9.2,5.7-26.7,13.6-31.7c5.1-3.2,10.8-5.1,16.8-5.5
	c5.3-0.4,9.4,1.3,13.7,3.5C189.3,669.3,184.3,678.7,179.5,686.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M194.2,651.3c-14.9-2.5,1.5-19.1,11.1-26C201.9,634.2,200.9,652.4,194.2,651.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M125,678c-2.6,7.5-7.3,15-15.2,17.7s-13.9-3.2-15.3-10.6c-2.2-11.6,10.5-20.1,19.2-24.8
	c6.8-3.6,14-6.4,21.5-8.3C129.5,659,127.5,670.5,125,678z"/>
        <path :fill="colors[`${id}-p-3`]" d="M240.7,750.2c1.7,12.3-6.1,24.4-17.4,28.7c-9.2,3.4-19.5,0.7-27.2-5.1c8.7-0.6,16.4-6.1,19.9-14.1
	c2.5-5.7,3.6-12.5,8.6-16.8c3.2-2.7,8.7-2.6,12.2-0.5C239.5,744,240.3,747.3,240.7,750.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M207.8,743.4c-5.5,6.3-12,17.4-21.2,18.4c-4.9,0.5-10.9-3.6-11.5-8.5c-0.7-5.7,5.4-11.2,9.4-14.4
	c4.8-3.9,10.7-6.2,16.9-6.6c5-0.3,10,1.2,14.7,2.9C213,737.6,210.2,740.4,207.8,743.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M129.5,755.8c-4.7,4.8-13.5,1.5-17.8-2.1c-5.8-5-6.4-13.6-4.6-20.5c2-7.2,6.5-13.9,12-19.8
	c-1.6,4.4-2.4,8.8-2,12.2c0.7,5.3,4.8,9.6,8.1,13.5C129.3,743.7,135.1,750,129.5,755.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M193.8,719.6c-0.8,3.2-4,3.7-6.9,3.7c-5.4,0-6.6-5.7-6.9-10c-0.7-10,3.9-19.7,12.2-25.3
	C188.5,696.4,195.6,712.3,193.8,719.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M167,765.3c-0.3,4.6-5.7,7.1-9.5,8.2c-7.1,2-12.2-3.4-14.4-9.5c-4.4-11.9,0.9-25.3,8.4-35.6
	c0.4,4.5,2.8,9.1,4.8,12.9C160.2,748.4,167.6,756.8,167,765.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M374.4,872.7c-6,10.7-19.6,11.1-30.3,9.2c-7.6-1.4-15.1-3.6-22.2-6.6c3.2-0.3,6.4-0.9,9.4-1.9
	c7.4-2.4,13-6.8,18.7-12s13.9-13.2,22.4-9.3C380.4,855.8,377.8,866.7,374.4,872.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M382.6,742.8c0,1.1-0.3,2.1-0.9,3.1c-2.2-5.4-7.9-9.6-12-13.6c-3.4-3.3-5.8-7.1-6.1-11.8
	c-0.2-3.5,1-7.9,5.1-7.8c7.8,0.1,11.1,12.9,12.6,18.7C382.3,735.1,382.8,738.9,382.6,742.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M354.3,677.2c-5.5-2.5-12.2-4.9-16.3-9.5c-1.8-2-0.6-3.9,1.2-5.4c3.6-3.1,7.8-1.6,11.3,0.8
	c5.3,3.7,10.3,11,12.1,17.7C359.8,679.4,357.1,678.2,354.3,677.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M348.4,923.4c-3,6.4-11.7,7.4-17.8,6.4c-6.1-1.2-11.7-4-16.4-8c-8.5-7.3-15.4-16.1-20.5-26.1
	c3,2.4,6.1,4.6,9.4,6.6c5.1,2.8,11,3.4,16.7,2.6c7.9-1.1,16.6-4.3,24.2-0.2C350.7,908.3,351.4,917.1,348.4,923.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M65.5,685.7c-1.8,7-3.4,14.3-9.6,18.9c-7.2,5.4-14.8,1.5-17.3-6.3c-4.3-13.2,5.9-26.6,16.5-33.2
	c8.2-5.1,17.5-7.4,26.9-8.7C72.5,662.6,68.2,675.2,65.5,685.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M86.8,761.9c-3.4,7.8-11.8,9.7-18.2,4.2c-9.3-7.9-6.9-22.2-3.9-32.5c2-6.5,4.5-12.9,7.6-19
	c1.2,8.6,6.3,17.5,10,23.9C86.4,745.6,90.3,753.7,86.8,761.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M42,780.1c-4.6,7.9-13.4,6.8-19.1,1c-8.4-8.3-3.8-22.3,0.5-31.5c2.9-6.2,6.3-12,10.3-17.5
	c0,1.9,0.1,3.8,0.4,5.7c1,7.5,3.9,13.7,7.1,20.5C44.5,765.2,46.2,773,42,780.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M103.1,852.3c-13.5,8.8-30.8,3.2-38.8-10.1c-6.7-11.2-7.4-24.8-6.7-37.6c3.2,10,9.2,18.8,19.8,22.3
	c8.1,2.7,17.1,2.5,24.9,6.2c3.9,1.7,6.6,5.3,7.2,9.5C109.9,846.8,106.3,850.2,103.1,852.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M107.7,815.2c-4,2.6-9.3,2.2-12.9-0.9c-6.3-5.6-4.4-18-3-25.2c1.1-6.3,3.6-12.2,7.2-17.4
	c2.5,9.8,10.8,17.3,14.2,26.8C115.7,804.6,113.4,811.7,107.7,815.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M155.8,843.8c-4.3,6.3-11.9,7.2-18.1,3.2c-7.1-4.6-8.2-14-7.4-21.7c0.9-8.1,3.6-16.1,5.3-24
	c1.5-6.8,2.1-13.7,1.7-20.6c7.2,8.1,13.1,17.2,17.5,27C159.9,818.9,163.1,832.9,155.8,843.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M42.6,845.9c-1,6.2-5.2,11.4-11.1,13.5c-5.6,2-10.8-1.1-12.6-6.6c-3.1-9.4,0.9-21.2,4.6-29.8
	c2.9-6.9,7.2-13,12.7-18c0.3,1.9,0.8,3.8,1.1,5.5c0.6,3.9,1.2,7.8,2,11.7C40.9,830,44,837.8,42.6,845.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M77.8,931.7c-13.3-2.1-18.2-19.1-20.1-30.2c-1.7-10.6-2-21.4-0.8-32.1c8,4.5,15.5,9.7,23.3,14.4
	c6.8,4.1,14,8.4,19.2,14.5C112.4,913.3,95.2,934.4,77.8,931.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M154.2,950.6c-7.2,3-15.2,1.3-21.5-2.9c-7.9-5.3-10.4-15-10.8-23.9c-0.5-8.8,0.7-17.6,1-26.4
	c0.2-7.4,0.8-14.7,1.8-22c3.4,5.6,7.5,10.8,12.2,15.5c6.2,6.3,13.4,11.3,19.8,17.4s10.9,13.7,10.6,22.8
	C167,939.5,161.9,947.1,154.2,950.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M209.2,913.6c-3.8,7.2-13,8.3-19.8,5.1c-7.2-3.3-9.3-12.8-10.5-19.8c-1.3-7.5-2.1-15-4.8-22.2
	c-1.1-2.9-2.6-5.6-4.4-8.2c7.3,4.4,15.3,7.7,22.7,12C203.1,886.8,216.4,900.2,209.2,913.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M75.1,980.8c-2.1-4-4.4-7.9-7-11.7v0l-1.8-2.7l-3.3-4.7c-1.8-2.5-3.7-5-5.5-7.5c-0.3-0.4-0.7-0.9-1-1.3
	l-1.2-1.6c-1.2-1.8-2.4-3.5-3.5-5.3c-0.9,2.4-1.9,4.7-2.8,7.1c-0.5,1.3-1,2.6-1.5,3.8c-0.9,2.3-1.7,4.5-2.5,6.8
	c-1.2,3.4-2,7-2.6,10.6c-0.3,2.1-0.5,4.2-0.6,6.3c0,1.3,0,2.5,0,3.8s0.2,2.7,0.4,4c1.2,8.2,4.9,16,12.9,19.4
	c6.2,2.6,13.3,0.9,17.8-4.1C78.9,996.8,79.1,988.6,75.1,980.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M142.5,958.8c-8.1-2.9-16.6-4.4-24.6-7.7c-6.2-2.4-12.1-5.3-17.8-8.7c4.8,20.2,16.8,48.8,40.6,44.7
	c6.5-1.1,15.3-4,16.6-11.5C158.8,966.7,149.6,961.4,142.5,958.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M212.9,958.9c-0.6-0.6-1.2-1.1-1.8-1.7s-1-0.9-1.5-1.3c-0.9-0.8-1.9-1.5-2.9-2.2c-9.2-6.8-21.6-13.8-25.6-24.5
	c-3.6,9.8-5.9,20.3-5.8,30.4c0,1.3,0.1,2.7,0.2,4c0.1,0.8,0.2,1.7,0.3,2.5c0.3,2.1,0.7,4.2,1.4,6.2c1.3,4.6,3.7,8.9,7,12.4
	c0.9,0.9,1.8,1.7,2.8,2.4c0.9,0.6,1.8,1.2,2.8,1.7c2.5,1.2,5.2,1.9,7.9,2.1c2.8,0.2,5.6,0,8.3-0.6c1.7-0.4,3.3-0.9,4.9-1.6
	c4.6-2,8.5-5.4,9.6-10.5C222.2,970.4,218.2,964,212.9,958.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M34.3,651.2c-0.2,5,0.3,18.3-7,18.6c-3.5,0.1-8.6-1.9-9.1-5.8s2.3-8.1,4.4-11.3c3.3-5,7.4-9.6,12-13.4
	C34.1,643.4,34.5,648,34.3,651.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M237.8,957.8c-14.4,0.3-17.6-16.5-17.1-27.5c0.4-7.4,1.7-14.6,3.9-21.7c3,8.7,10.7,16.4,17.3,21.6
	c4.8,3.8,9.4,8,9.2,14.6C250.8,952.1,245,957.7,237.8,957.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M302.8,980.1c-1.2-1.1-2.6-1.8-4.1-2.3c-0.9-0.3-1.8-0.5-2.7-0.7s-1.7-0.3-2.5-0.4c-0.6-0.1-1.2-0.1-1.9-0.2
	c-2-0.1-4.1-0.1-6.1,0c-1.1,0.1-2.3,0.2-3.4,0.3l-0.5,0.1l-1,0.1c-2.6,0.4-5.2,0.8-7.8,1c-0.9,0.1-1.8,0.1-2.6,0.2
	c-2.3,0.1-4.6,0-6.8-0.4c-0.7-0.1-1.5-0.3-2.2-0.4c-4-1-7.9-2.7-11.3-5l-0.1-0.1c0,0.1,0,0.3,0.1,0.4c2.1,11.6,8.9,22.9,19.4,28.4
	l0.1,0.1c0.4,0.2,0.8,0.4,1.1,0.6c0.8,0.4,1.5,0.7,2.3,1c2.1,0.8,4.3,1.3,6.5,1.5c4.2,0.5,8.4,0.1,12.4-1.3c2-0.7,3.9-1.7,5.7-2.9
	l0.5-0.3l0.3-0.2c2.2-1.7,4.1-3.7,5.5-6.1c0.3-0.5,0.6-1,0.8-1.6l0,0C306.5,987.7,306,983,302.8,980.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M305.7,947.6c-1.1,5.2-8.8,8-13.2,8.4c-7.6,0.7-14.3-4-17.8-10.5c-4-7.4-3.8-15.7-2-23.6c2,2.3,4.4,4.2,6.9,5.9
	c7.2,4.9,15.6,8,22.4,13.5C303.8,942.8,306.3,945,305.7,947.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M396.5,975.9c-0.1-0.4-0.1-0.7-0.2-1.1c-0.8-2.9-3-5.3-7.2-6.5c-0.9-0.2-1.8-0.4-2.8-0.6
	c-5.7-0.8-11.5,0.8-17.1,2.6c-1.9,0.6-3.8,1.2-5.6,1.8c-8,2.5-16.2,4.2-24.6,2.6l-1.4-0.3c-1.2-0.3-2.4-0.6-3.6-0.9l-1.3-0.4
	c-0.8-0.3-1.6-0.6-2.4-0.9l-0.8-0.3c-1.2-0.5-2.4-1-3.5-1.6s-2.4-1.2-3.6-1.9c-0.6-0.3-1.2-0.7-1.9-1c0.4,1.4,0.8,2.9,1.3,4.3
	c1,2.9,2.1,5.6,3.5,8.3c0.5,0.9,1,1.8,1.5,2.7c1,1.6,2,3.1,3.2,4.6c0.4,0.5,0.8,1,1.2,1.5c0.6,0.7,1.2,1.3,1.8,2
	c3.4,3.6,7.4,6.5,11.8,8.7c1.5,0.7,3.1,1.4,4.7,1.9c14,4.7,29.5,1,39.8-9.6c0.8-0.8,1.5-1.6,2.2-2.4l0.7-0.9
	c2.2-2.7,3.6-5.8,4.2-9.2C396.6,978.2,396.6,977,396.5,975.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M385.8,937.2c-3,8.5-11.7,13.9-20.4,16.6c3.4-4.3,5.3-9.5,5.6-15c0.1-5.2-7-18.5-0.3-21.1
	c5.3-2,10.5,1.5,13.4,5.6C386.9,927.3,387.5,932.5,385.8,937.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M832.7,170.3c-0.3,6-2.1,11.9-5.1,17.1c-1.4,2.4-3,4.6-4.8,6.6c-0.4,0.5-0.8,0.9-1.2,1.3
	c0.9-8.3-4.6-17.1-8.2-24.4c-3.1-6.4-8.4-22.8,3.6-23.6C828.6,146.7,833,161.4,832.7,170.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M798.2,124.5c-7.9-4.3-17.4-8.1-23.4-15.1c-2.6-3.1-2.4-6.4-0.3-9.7c3.8-6.1,12.6-3,17.6-0.6
	c5.8,2.9,10.7,7.3,14.1,12.8c4.4,6.9,5.3,14.9,7,22.7C809.7,130.1,802.4,126.8,798.2,124.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M787.8,158.7c-4.8-3.4-10.1-6-15.7-7.7c-5-1.7-10.3-3.7-14.2-7.4c-4.8-4.6,0.8-10.9,5.6-12.7
	c9-3.4,18.3,7.9,22.8,14.1s9,14.4,10.1,22.4C794.3,163.9,790.8,160.9,787.8,158.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M736.3,111.2c-3.1,3.9-5.9,8.1-8.4,12.4c-2.4,3.9-6,9.8-11.1,10.2c-7,0.6-8.7-7.7-8-12.8
	c0.9-5.9,3.4-11.5,7.3-16.1c8.7-10.2,21.5-6.7,32.4-2l-1.6,0.5C742.6,104.7,739.2,107.8,736.3,111.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M687.2,169c-0.6,3.6-3.6,6.3-7.3,6.4c-7.5,0.8-13-4.1-13.6-11.4c-1.2-13.6,9.6-25,21.1-31.8
	c-1.2,2.9-1.8,6-1.7,9.1C685.9,150.4,689.1,159.9,687.2,169z"/>
        <path :fill="colors[`${id}-p-4`]" d="M731,154.6c0,5.1,0,10.3-1,15.3c-1.3,6-6.4,12.8-13.3,11.8c-6-0.9-8.8-8.4-8.9-13.6c0-5.7,1.6-11.2,4.7-15.9
	c4.6-7.4,12.9-12.6,21.4-15.3C731.1,142.1,731,150,731,154.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M706.9,205.3c-4.3,0.1-9.1-2.8-11.4-6.3c-1.7-2.7-1.5-6.7-0.7-10.4c5.4,3.9,12.8,7.2,14.8,8.5
	c1.2,0.8,3.6,2.2,3.7,3.9C713.6,203.8,708.7,205.3,706.9,205.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M770.4,184.6c-13.3,4.6-27.2-16.2-22-28.2c0.5,5.7,5.7,11.4,11.2,13.8c4.5,2,9.2,3,13.6,5.3
	c1.4,0.8,4.2,2.2,4.4,4.1C777.9,182,772,184.1,770.4,184.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M686.8,100.4c-3.6,3.4-6.3,6.7-11.1,8.5c-3.5,1.3-8.1,1.8-11.2-0.7c-3.7-2.9-3.4-9-1.2-12.7
	c2.5-4.2,7.6-6.8,12-8.5c4.3-1.7,8.8-2.5,13.4-2.5c5.8,0.2,11.3,2.9,16.6,5.5C698.3,91.2,691.5,96,686.8,100.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M758.7,75.7c-4.8-3.4-11-4.2-16.7-4.6c-7.3-0.6-14.9-0.3-21.4-4.1c-7-4.1-9.2-13.5-3.6-19.7
	c4.4-4.8,12.4-4.8,18.2-3.2s10.6,5.7,14.6,10.1c6.7,7.5,11.6,17.4,14.8,27.1C762.9,79.2,761,77.3,758.7,75.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M684.5,50.7c-4.8,7.3-8.7,15.6-15.1,21.7c-2.3,2.2-5.2,3.6-8.3,2.1c-6.1-2.8-7.1-9.3-5.6-15.2
	c4.4-16.8,22.8-21.6,38.1-19.3C689.8,42.4,686.8,47.2,684.5,50.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M869.3,189.6c-7.6,6.8-18.1,9.9-28.2,10.4c7.2-4.9,13-12.3,14.8-20.7c1.7-8.1,3.1-20.7,14.6-17.8
	c5.8,1.5,7.2,9.3,7,14.3C877.2,181.3,873.2,186.1,869.3,189.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M883,135.7c-2.8,2.6-5.8,5.1-9,7.3c-1.9,1.2-3.7,2.6-5.3,4.1c3.8-4.7,3.6-12.3,3.5-18c-0.1-4.9-0.3-10,3-14
	c4.2-5,8.7-3.2,12.5,1.1C893.7,122.9,888.2,130.6,883,135.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M826.6,107.1c-0.4,4.5-1.2,8.9-2.5,13.1c-2.1-6.8-8-13-11-18.8c-1.7-3.2-3.2-7.4-2.5-11.1
	c0.9-4.3,7.1-3.7,10-2.3C827.3,91.1,827.1,101,826.6,107.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M840.2,92.7c-2.9-3.3-7.4-9.2-5.3-13.9c2-4.4,9.6-5.2,13.6-3.7c8.2,2.9,10.6,15.2,11.2,22.6
	c0.5,6.2,0.3,14.2-2.5,20.4C856,107.8,846.5,100,840.2,92.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M789.1,69.8c-5.2-6-11.8-10.9-16.2-17.5c-1.7-2.6-2.5-5.4-1.5-8.4c1-2.6,3-4.7,5.6-5.7c3.1-1.2,6.4,1.1,8.5,3.1
	c4.5,4.2,6.9,11.2,8.2,17s1.1,12.2,0.3,18.3C792.6,74.2,791,71.9,789.1,69.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M875.3,212.3c-0.8,4.2-5.1,6.3-8.8,7.4c-6.5,2-12.9,1-19.4,1.2c2.6-1.6,5.2-3.9,6.7-4.9
	c5.2-3.6,11.9-9.1,18.5-9.5C875.5,206.4,875.8,210,875.3,212.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M946.4,276.3c-5.2,9.3-13.5,16.5-23.4,20.4c3.8-7.3,1.2-18.1,0.4-25.7c-0.7-7.4-1.1-15,1.2-22.2
	c2.3-7.4,9.3-10.1,16-6.7C953.6,248.6,952.4,265.5,946.4,276.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M918.9,199.4c-3.7-5.6-8.3-10.8-8.5-17.9c-0.2-6.4,3.5-13.5,10.9-12.2c6,1,10.5,7.5,12.5,12.8s1.9,11.3,1,16.8
	c-1.5,8.1-4.9,15.7-9.9,22.2C926.4,214,923.2,205.9,918.9,199.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M900.2,165.9c-1.3,3.2-2.8,6.3-4.5,9.3c-0.3-4.9-2.2-10.2-3.4-14.3c-1.1-3.8-2.4-8.1-1.4-12s7.2-4.8,10.3-3.4
	C907.5,148.4,901.8,161.8,900.2,165.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M919.2,133.7c-3.5-6-9.4-11.6-11.1-18.5c-1.1-4.6-0.8-13.4,5.7-13.3c5.1,0.1,9.7,4.8,11.9,8.9
	c2.5,4.6,3,10.2,2.9,15.4c-0.2,8.3-2.3,16.5-6.2,23.9c0-0.4,0-0.9,0-1.2C922.6,143.4,921.9,138.4,919.2,133.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M907.9,69.6c-0.5,7.5-2.9,14.8-6.9,21.2c0.4-5.3-0.4-10.6-2.4-15.6c-2.7-5.7-6.6-10.8-8.8-16.7
	c-2-5.2-1.2-12.8,5.1-14.3c5-1.2,8.6,4.2,10.4,8C907.7,57.6,908.2,63.8,907.9,69.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M828.6,44.6c-5.8-2.2-13.9-3.3-18.5-7.8c-2-1.9,0.4-4.2,1.9-5.6c4-3.8,8.7-4.7,13.4-1.7c6,3.7,11,12,12.7,19.6
	C835.3,46.9,831.2,45.6,828.6,44.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M970,213c-7,6.3-15.4,10.8-24.6,13c1.5-1.6,3-3.4,4.3-5.2c4.1-5.5,5.4-12.3,5.5-19c0.2-7.2-1.9-16.1,4-21.7
	c4.9-4.6,12.5-4.3,17.1,0.5c0.3,0.3,0.6,0.7,0.9,1.1C985.1,192.2,978.2,205.4,970,213z"/>
        <path :fill="colors[`${id}-p-4`]" d="M963.2,136.2c-0.8,7.2-3,14.1-6.4,20.5c0.1-4.7-0.9-9.3-2.8-13.6c-2.5-5.2-6.2-9.7-8.8-14.8
	c-3.3-6.1-0.9-13.8,5.2-17c0.5-0.2,1-0.5,1.4-0.7c4.9-1.8,8.3,4.8,9.8,8.3C963.9,124.5,963.8,130.3,963.2,136.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M992,308.7c-3.6,9.3-12.1,16.9-21.5,20.8c3.7-6,5.1-13.9,5.6-20.3c0.5-7.3,0.3-20.9,10.1-22
	C997.6,286.1,994.4,302.7,992,308.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M985.5,251.8c-0.6,11.4-9,20-18.1,26.2c1.5-4.5,1.4-10,1-13.9c-0.8-7.7-5-19.5,1.3-25.9
	c3.6-3.7,9.5-4.5,12.7-0.1C985.1,241.9,985.7,247.3,985.5,251.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M851.7,157.1c-0.7,2.3-1.6,4.5-2.8,6.5c-0.1,0.2-0.3,0.4-0.4,0.6c0.3-2.1,0.4-4.2,0.2-6.3
	c-0.4-4.9-2.3-9.5-5.4-13.3c-3.2-4.1-7-7.7-9.3-12.4s-1.2-11.1,4.8-11.4c11.2-0.7,15,13.5,15.2,22.1
	C853.8,147.7,853.1,152.5,851.7,157.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M891.1,196.9c-0.5,2.6-1.4,5.1-2.8,7.4c-0.4-2.4-1.1-4.8-2.2-7c-1.3-2.8-5.2-11.4,0.5-11.8
	C892.5,185.1,891.6,193.3,891.1,196.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M937,163.2c-2.7-2-8.4-4.8-4.8-8.4c1.6-1.6,4.7-3.5,7-2.4c4,1.8,4.6,8.8,5,12.5c0.3,2.7,0.4,5.3,0.3,8
	C942.9,169,940.3,165.7,937,163.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M968.3,141.9c2-4.7,7-3.5,9.7-0.5c2.1,2.4,3.1,5.6,3.9,8.6c1,4,1,8.2,0.2,12.2c-0.7-1.8-1.7-3.5-3-5
	C975.2,153,965.3,149,968.3,141.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M913.8,240.3c-1.5,5.2-5.1,8.8-9.3,11.8c1.5-6.4-0.6-16.5,2.5-20.2c2-2.4,6.1-3,7.2,0.4
	C915,234.8,914.5,237.8,913.8,240.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M843.9,993.4c-2.5,0.7-5.1,1.3-7.7,1.8c-2,0.4-4.1,0.8-6,1.3c-0.4,0.1-0.9,0.3-1.3,0.4c-2.1,0.7-4,1.7-5.6,3.1
	c-0.8,0.7-1.6,1.4-2.3,2.2c-0.4,0.4-0.8,0.9-1.2,1.4c-0.8,0.9-1.4,1.9-2,3c-0.9,1.6-1.1,3.5-0.6,5.3c1.8,4.7,10.5,5.4,15,4.5l0,0
	l0.5-0.1c3.2-0.9,6.1-2.6,8.4-5c0.6-0.6,1.2-1.3,1.8-2c0.5-0.6,1-1.3,1.4-1.9c1-1.4,1.8-2.9,2.6-4.5c0.7-1.4,1.4-2.9,2.1-4.4
	c0.7-1.7,1.4-3.5,2-5.2c0.4-1.2,0.8-2.4,1.2-3.6C849.6,991.4,846.8,992.6,843.9,993.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M1010.5,676.1c-6.4,2-13.7,1-20.4,0.9c-8.1-0.2-18.9,0-25.9,5.2c5.1-7.2,11.5-13.4,18.9-18.3
	c8.8-5.9,27.2-13.3,35.1-2.1C1022.7,668.2,1016.5,674.2,1010.5,676.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1018.4,747.8c-0.8,3.9-4.3,5.1-7.7,5.8c-6.2,1.1-12.9-0.3-19-0.7c-7.6-0.5-18.3-2.3-25.8,1.4
	c5.1-6.8,12.7-12.2,19.7-16.2c5-2.9,10.6-5.3,16.4-6.1c6.2-0.8,11.2,2.1,14.5,7.3C1018.2,741.7,1018.9,744.8,1018.4,747.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M991,834.8c-8.3,2.7-16.1-6.6-20.4-12.2c-4.7-6.2-8.1-13-12.1-19.6c6.2,0.4,12.4,0.6,18.3,2.5
	c5,1.7,9,5.5,12.7,9.1c3.2,3.1,6.4,6.8,7.9,11.1C999.2,830.4,995.1,833.4,991,834.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M470.1,361.3c-1.3,5.6-7.4,8.1-12.6,7.9c-9.4-0.3-15.1-10.2-18.8-17.5c-4-7.9-6.3-16.9-11.2-24.3
	c5.8,3.3,10.9,8.1,16.6,11.4s12.2,4.9,18.1,7.9C467.6,349.3,471.5,355.3,470.1,361.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M445.2,402.6c-2.3,5-7.1,7.1-12,4.3c-8.5-4.9-11.1-15-10.4-24.1c0.6-7.4,3-14.8,5.6-21.8
	c3.9,8.9,11.1,15.9,15.5,24.6C446.7,390.9,447.8,397.1,445.2,402.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M567.8,375.2c-8.8,7.5-18.9-3-23.5-9.9c-4-6-7.2-12.5-10.3-19l0.7,0.3c2.8,1.3,5.8,2.3,8.8,3.2
	c5.3,1.5,11,1.9,16.4,3.1c4.8,1,9.9,2.8,12.6,7.2C575.5,365.2,571.8,371.7,567.8,375.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M420.5,421.6c-3,0.2-5.6-1.6-7.7-3.6c-2-2.1-3.8-4.4-5.5-6.8c-1.4-2-2.9-3.9-4.6-5.7c8.1,1.4,20,1.4,23.4,10.3
	C427.5,419.4,423.4,421.4,420.5,421.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M417.3,447.1c-1.1,3.1-3.9,4.2-6.9,3.6c-3.6-0.7-5.6-3.6-6.3-7c-0.6-3.3,0-6.8,0.1-10.1c0-3.8-1.3-7.8-1.7-11.6
	C410.1,427.3,420.7,437.2,417.3,447.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M454,439.8c-1,3.2-4.5,5.9-7.7,6.5c-3.5,0.7-7.2-1.4-10-3.2c-5.2-3.5-9.4-8.3-14.4-12.1
	c4.1-0.6,8.2-1.6,12.3-1.6c2.7,0.1,5.4,0.4,8.1,1.1C446.3,431.5,455.8,434,454,439.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M490.1,400.4c-7.2,7.1-18,2.5-24.3-3.1c-5.5-5-9.1-11.3-13.3-17.2c7.8,2.9,16.1,2.6,24.4,2.8
	c4.8,0.1,9.8,0.9,13.5,4.3C494.6,391.1,493.9,396.7,490.1,400.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M501.7,378.9c-2.9,0.7-6.3-1.8-8.4-3.6c-2-1.9-3.7-4-5.1-6.2c-1.9-2.8-3.5-5.9-5.5-8.6
	c5.6,0.8,11.1,2.3,16.3,4.5c2.6,1.1,4.8,2.9,6.2,5.3c1.6,2.7,0.8,6.3-2,7.9C502.7,378.5,502.2,378.7,501.7,378.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M539.7,387.9c-0.7,3.6-4.2,5.6-7.6,5.5c-4.3-0.1-7.2-4.2-7.7-8.1c-0.5-4.1,0.7-8.3,1.6-12.3
	c0.8-3.7,1.2-7.5,1.4-11.2c0.1-2,0.1-4.1-0.2-6.1c1.8,1.9,3.5,3.9,4.9,6c2.4,4,4.3,8.3,5.6,12.7C539,378.4,540.5,383.5,539.7,387.9z
	"/>
        <path :fill="colors[`${id}-p-3`]" d="M524.2,411.8c-0.1,3.7-3.2,6.5-6.8,6.4c-0.1,0-0.1,0-0.2,0c-6.9-0.1-10.5-7.8-12.4-13.3
	c-1.4-3.9-2.2-8-3.5-11.9c6.2,3.3,13.5,4.2,18.8,9C522.9,404.5,524.4,408.1,524.2,411.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M479.6,438.1c-9.2,2-16.1-7.9-19.1-15.1c-2.2-5.4-3.2-11.4-4.9-17.1c2.3,2.2,4.8,4.1,7.4,5.9
	c3.7,2.7,7.6,5.1,11.6,7.2c4.3,2.2,8.5,4.7,11,9.1C488.4,433,484.2,437.1,479.6,438.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M493.9,466.8c-5.8,6.3-15.6,1.3-20.5-3.4c-4.4-4.1-7.5-9.5-10.2-14.9c6.2,3.1,12.9,2.1,19.9,2.4
	c4.1,0.2,8.1,1.3,11,4.3C497.3,458.4,496.9,463.5,493.9,466.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M540.7,430.7c-0.6,8.2-10.1,12.4-17,13.8c-8.3,1.6-16.8-1.3-23.9-5.5c4.4-0.9,8.7-2.1,12.8-3.8
	c4-1.6,7.2-4.1,10.5-6.8s7.4-6.1,12-6.2S540.9,427.1,540.7,430.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M577,404.7c-3.4,2.4-7.2,4.2-11.3,5.1c-6.3,1.5-12.8,2-19.2,1.6c1.7-0.7,3.3-1.6,4.8-2.6
	c4.1-2.7,7.1-6.5,9.9-10.5c3.3-4.5,6.8-9.2,12.2-11.2c5.1-2,10.6,1.1,10.4,6.7C583.7,398,580.2,402.2,577,404.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M604.4,372c-7.2,3.3-15.7,4.9-23.7,5.4c3.9-1.5,7.3-4.9,9.8-8c3.2-3.9,5.1-8.6,6.9-13.3
	c1.7-4.5,3.4-9.8,7.8-12.4c6-3.5,11.7,1.4,13.1,7.2C620.5,360.6,612.2,368.3,604.4,372z"/>
        <path :fill="colors[`${id}-p-3`]" d="M419.4,482.6c-2.2,3.7-6,1.7-8.4-0.6c-2.8-2.9-4.5-7-6-10.6c-1.9-4.8-3.7-9.6-5.3-14.5c4,2.9,8.2,5.4,12.5,7.5
	c3.2,1.7,6.5,3.9,7.9,7.4C421.5,475.4,421.2,479.3,419.4,482.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M459.5,500.9c-2.1,5.9-7.6,5-11.8,1.7c-4.7-3.7-7.1-9.9-9.1-15.4s-3.6-11.3-5.5-17c-1.2-3.8-2.6-7.5-3.7-11.3
	c0.6,0.6,1.2,1.2,1.8,1.8c4.3,4,8.8,7.7,13.5,11.2C453.7,478.8,463.9,488.4,459.5,500.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M554.4,459c-1.4,4.2-3.9,8-7.2,10.9c-4.5,4-10.5,4.9-16.3,3.9s-11.2-4-16-7.5c-2.6-1.9-5.1-3.9-7.4-6.1
	c3.4,0.4,6.8,0.2,10.1-0.6c6.1-1.3,11-4.7,16.3-7.8s11.8-5.8,17.8-3C555.7,450.6,555.4,455.4,554.4,459z"/>
        <path :fill="colors[`${id}-p-1`]" d="M591.8,427.8c-3.9,8.3-14.6,10.3-22.3,6.8c-6.2-2.8-10.7-7.8-15.5-12.4c6.4,1,13.9-3.2,19.6-4.8
	c5.1-1.4,11.3-2.3,16.2,0.4C593.5,420,593.4,424.4,591.8,427.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M502.2,515.4c-10.9,0.8-17.9-9.8-22-18.3c-3.3-6.7-5.4-14.3-9-21c4.4,3.1,9.2,5.8,14.2,7.9
	c5.6,2.4,11.7,3.2,17.3,5.7c5.8,2.5,10.2,7.2,10.6,13.7S508.4,515,502.2,515.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M630.6,392c-2.2,9.5-12.4,13.5-21.1,11.2c-7-1.9-13.5-6.9-18.3-12.6c6.1,0.5,12.7-4,17.8-6.3
	c4.5-2.1,9.6-4.5,14.7-3.7C629.2,381.4,631.8,387,630.6,392z"/>
        <path :fill="colors[`${id}-p-3`]" d="M640.9,362c-2.1,2.9-4.9,5.1-8.2,6.5c-2.7,1-5.5,1.3-8.3,0.9c2.8-2.3,5-5.4,6.2-8.8c2.3-5.9,2.9-13.9,10.3-15.8
	c3.6-0.9,4.9,2.8,4.9,5.6C645.8,354.7,643.4,358.7,640.9,362z"/>
        <path :fill="colors[`${id}-p-3`]" d="M644.3,421.1c-1.4,4.1-5.4,7.4-9.2,9.2c-3.9,1.9-8.6,1.5-12.6,0.4c-6.4-1.9-12.1-6.1-17-10.9
	c2.7,0.4,5.4,0.4,8.1,0c4.9-0.6,9.1-2.6,13.5-4.7s9.6-4.6,14.7-2.9C645.6,413.4,645.4,418.1,644.3,421.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M600.3,474.4c-9.3,4.6-17.9-2.6-23.6-9.3c-3.8-4.7-7.3-9.6-10.4-14.7c6.8,4,16.8,3.4,24,2.6
	c6.7-0.7,15.1,0.6,17.4,7.9C609.6,466.8,605.3,471.9,600.3,474.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M639.9,456.6c-6.9,7.1-17.5,0.9-23.3-4.1c-4.3-3.8-8.3-8.1-11.7-12.8c6.1,3.1,13.7,2,20.7,1.3
	c5.3-0.6,11.4-0.8,15.5,3.1C644.8,447.6,643,453.4,639.9,456.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M560.8,507.7c-2.5,4.8-7.5,7.7-12.9,7.7c-6-0.1-11-4.3-14.9-8.5s-7.2-9.3-10.5-14c-2.5-3.5-4.9-7.4-7.5-11
	c3.1,1.6,6.4,2.7,9.9,3.1c5.4,0.9,10.7,1.6,16,2.7C550,489.7,567.2,495,560.8,507.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M591.4,509.5c-3.3,3.3-8.3,2.8-12,0.4c-4-2.5-6.4-7.2-8.2-11.3c-4.7-10.5-6.1-21.9-6.3-33.2
	c2.2,4.1,4.8,7.9,7.9,11.3c3.7,4.1,8,6.9,12.5,9.9c4.2,2.8,8.5,6,9.6,11.1C595.7,502,594.4,506.4,591.4,509.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M644.4,483.2c-0.8,9.9-13.1,14.3-21.5,14c-7.5-0.2-15-3.2-21.4-7.3c3-0.1,5.9-0.9,8.5-2.2
	c4.5-2.2,8.1-6,12.1-8.9c4.5-3.3,9.8-6.3,15.6-5.2C642.5,474.5,644.8,478.8,644.4,483.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M432.3,526.8c-2.4,5.2-7.8,8.8-12.7,4.4c-5.9-5.2-3.9-15-2.2-21.6c1.4-5.1,3.3-10.2,5.5-15
	c0.5,1.4,1.2,2.8,1.7,3.8c1.6,3.9,3.3,7.7,5.2,11.5C432.2,514.9,434.9,521.2,432.3,526.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M414.6,560.4c-2.9,3.5-8,4.2-12.2,3.1c-6.8-1.8-10.3-9.3-12.4-15.3c-1.7-5.4-2.9-11-3.5-16.7
	c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.3c5.1,1.9,10,4.8,14.7,7.6c4.2,2.5,8.6,4.8,12,8.5C417.2,550.7,418,556.3,414.6,560.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M466.9,543.2c-3.6,2.2-8,2.2-11.6,0.1c-3.3-1.9-5.5-5.2-7.1-8.7c-2.4-5.1-3.1-10.6-3.6-16.2
	c4.2,5,10.9,8.1,17,9.6c3.6,0.9,8,1.7,10,5.2C474,537.5,470.4,541.3,466.9,543.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M699.5,461.2c-1,6.4-7.3,8.2-12.7,6.6c-4.7-1.5-8.4-4.9-12.6-7.2c-4.7-2.6-10.1-3.7-15.5-3.2
	c4-2.2,8.1-4.1,12.4-5.6c5.5-1.9,11.4-2.5,17.2-1.7C693.5,451,700.4,455.2,699.5,461.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M684.3,518.6c-8,2-11.9-6.3-12.1-12.8c-0.2-5.9,1.2-11.7,1-17.7c-0.2-4.9-1.6-9.7-4-14
	c7.3,2.9,13.7,7.6,18.6,13.8C694.5,496.5,698.6,515,684.3,518.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M728,500.5c-1.1,5.3-8.6,5.2-12.5,4.1c-4.6-1.2-7.2-5.5-9.4-9.4c-3.2-5.8-6.8-11.8-12.1-15.3
	c5.5-0.3,10.9,0.1,16.3,1.1c4.5,0.8,8.6,2.8,12,5.9C725.7,490,729,495.7,728,500.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M707.9,573.8c-7-1.9-10.5-9.1-10.6-15.8c-0.2-7.7,3.4-15.4,6.5-22.2c2.7-5.9,9.3-14.4,8.3-21.7
	c5.7,9.3,11.2,18.9,14,29.2C729.5,555.6,725.2,578.6,707.9,573.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M627.8,576.5c-6.4-4-6-11.2-2.6-17c3.9-6.8,10.9-9.3,18.3-9.4c7.2-0.1,14.1,0.7,21.3-0.3
	c6.3-1,12.3-3.1,17.8-6.1C673.1,562.9,651.5,591.2,627.8,576.5z"/>
        <path :fill="colors[`${id}-p-4`]" d="M782.1,544.9c-1.1,7.4-8.8,9.2-15,7.4c-7.5-2.2-9.8-9.8-11.4-16.5c-1.3-5.5-2.3-10.6-5.9-15.2
	c-1.5-1.9-3.5-3.4-5.7-4.4c6.9,0.8,13.7,1.6,20.3,3.7C775.3,523.3,783.9,532.9,782.1,544.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M763.6,448c-5.9,6.3-15.6,4.7-23,2.8c-4.9-1.3-11.2-3.4-17-3.7c3.1-3.2,6.5-6.3,10.1-9
	c7.5-5.6,18.1-11.5,27.3-6.5C767.8,435.2,768.8,442.5,763.6,448z"/>
        <path :fill="colors[`${id}-p-3`]" d="M800.8,499.8c-6.2,4.3-14.4,1.9-19.7-2.6s-8.3-10.8-11.8-16.5c-3-4.9-6.5-9.6-10.4-13.9
	c17.2-2.7,39.9-2.9,46.5,15.6C807.7,488.9,806.8,495.7,800.8,499.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M817,566.6c-6.3,3.7-13.7-1-17.2-6.4s-2.8-12.4-1.5-18.3c1.4-6.5,5.9-15.6,3.4-22.2c6.1,3.6,11.4,8.4,15.7,14
	C823.8,542.2,828.5,560,817,566.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M841,648.4c-3.5,7.4-10.1,11.4-17.6,7.1c-7.8-4.4-11.2-13.8-11.3-22.3c-0.2-9.6,3.3-19.1,4.3-28.6
	c0.7-5.5-0.2-11-2.4-16c6.8,6.9,13,14.3,18.5,22.3C839.6,621.3,846.9,635.8,841,648.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M793.1,664.8c-2,11.6-5.9,26.5-17.4,32.2c-7.1,3.3-15.5,1.5-20.5-4.5c-6.6-7.7-4-17.6,2-24.5
	s14.3-11.4,21.4-16.9c6.3-4.9,13.6-10.5,17.5-17.7C795.7,643.9,794.8,654.4,793.1,664.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M673.1,636.2c-6.6,1.9-13.7-0.2-18.1-5.4c-6-6.9-3.1-16.7,1.3-23.4c8.7-13.1,22.8-21.9,29.5-36.3
	C693.1,592.3,701,627.7,673.1,636.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M720,631.6c-2.4,4.5-8,7.2-12,3.2c-4.7-4.6-2.8-12.4-1.7-18c1.2-6.1,2.5-12.1,3.6-18.2c0.7-3.8,2-8.4,1.7-12.6
	c2.7,6.9,5.3,13.9,7.6,21C721.5,614.6,723.9,624.2,720,631.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M738.7,603.1c-5.4-1.7-7-8.7-7-13.5c0-6,3.8-11.7,6.7-16.7c2.4-4,5.8-8.9,6.9-13.8c3.4,6.3,7.5,12.3,9.3,19.4
	C757,587.8,751.5,607.1,738.7,603.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M747.4,654.1c-3.2,11.5-10.2,24.3-22.7,27.4c-7.7,1.9-13.3-4.2-13.3-11.4c0-8.1,5.5-13.8,11.5-18.4
	c10.5-8.1,21.2-15.5,28.8-26.5C751.3,635,749.9,644.7,747.4,654.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M664.1,695.3c-6.7,0.3-14.3-2.6-16.9-9.2c-2.7-7,1.1-14.9,6.4-19.5c5.9-5.1,14.6-6.6,21.8-9
	c7.5-2.5,14-7.2,21.3-10c-1.8,9.6-4.7,19-8.9,27.8C683.4,685,675.6,694.9,664.1,695.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M736.4,722.9c-1.8,10.4-6,23-17.3,26.2c-7.3,1.9-14.8-2-17.5-8.9c-2.9-7.6,1.7-16.6,6.3-22.5
	c5.2-6.6,12.3-10.5,19.6-14.2c3.7-1.7,7.4-3.7,10.9-5.8C738.4,706.1,737.7,714.5,736.4,722.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M670.3,758.7c-8.3,13.1-29.4,37.3-46.2,22.7c-8.1-7-7.6-17.7-3.4-26.7c4.5-9.6,12.9-16.4,21.7-21.9
	s18.4-9.7,27.4-15c8.4-5.2,16.1-11.5,22.8-18.7C687.3,719.5,681.7,740.6,670.3,758.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M753.7,807.8c-2.6,6-9.6,14.4-16.9,10.5c-5.7-3.1-8-11.6-8.8-17.5c-1.1-8.6,3.6-17.1,8.2-24
	c8.7-13.2,19.9-25.5,23.8-40.9c-0.8,16.1-1.3,32.3-1.9,48.4C757.8,792.2,756.9,800.4,753.7,807.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M825.3,761.5c-7.9,3.9-15.4-0.4-19.3-7.5c-5.4-9.8-3.4-21.2,0.3-31.1s8.6-19.2,10.2-29.7
	c1.3-8.4,0.7-17.2-0.7-25.6c10.3,13.2,19.4,27.1,24,43.5C844.5,727.8,843.1,752.7,825.3,761.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M701.4,805.1c-0.7,13.2-2,27.2-7.6,39.3c-2.2,4.8-5.4,9.2-10.1,11.9c-5.4,3.1-12.1,2.2-17.7,0.2
	c-9.9-3.6-15.8-14-15-24.2c0.8-10.6,9-18.8,16.8-25c7.4-5.9,15.7-10.6,23.3-16.2c4-2.9,8.2-6.5,11.1-10.8
	C702,788.5,701.8,796.8,701.4,805.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M597.4,732.3c-16.1,3.2-36.9-0.8-43.2-18.1c-2.4-6.6-1.7-14.3,3.6-19.2c7.3-6.7,19.5-3.8,27.5-0.2
	c8.7,3.9,16.3,9.7,25,13.7c9.4,4.3,19.2,6.7,29.3,4.5C627.2,722.3,612.3,729.4,597.4,732.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M633.5,539.3c-5,0.5-9.7-1.2-11.4-6.3c-2.3-6.7,3.9-14.2,8.2-18.5c8.1-7.9,19.2-11.2,26.2-20.1
	C662,512.1,653.6,537.2,633.5,539.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M640,608.1c-0.3,6.3-1.4,12.6-3.2,18.7c-3,10.3-10.4,25.8-23.5,23.8c-6.7-1.1-12-6.4-12.9-13.2
	c-0.9-8.2,5.7-15.3,11.5-20.2c5.3-4.5,11.8-7.5,17.6-11.2c4-2.5,8.8-6.2,10.1-11C639.5,599.4,640.2,603.7,640,608.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M607.8,534.1c-1.6,9.3-6.9,23.6-18.3,23.8c-6.4,0.2-11-5.7-11-11.7c0-6.4,5.5-11.5,10-15.4
	c6.6-5.8,15.7-11.5,19.1-20c0.4,2.1,0.8,4.2,1,6.3C609,522.8,608.7,528.5,607.8,534.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M606.9,587.5c-2.5,10.5-8.8,22.8-20.8,24.3c-6.6,0.8-11.2-4.3-11.9-10.5c-0.8-7.5,3.3-13.8,8.6-18.7
	c7.6-7.2,19.1-9.9,26.9-16.7C608.8,573.1,608.6,580.4,606.9,587.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M577.7,676c-3.7-3.8-1.8-8.8,2.3-11.3c5.7-3.5,13.4-0.9,19.3,0.7c9.7,2.7,19,2.8,28.2,0
	C615.9,679.3,592.1,690.8,577.7,676z"/>
        <path :fill="colors[`${id}-p-3`]" d="M560.8,535.6c-2,7.9-1.6,17.3-6,24.4c-3.8,5.8-10.4,9.1-17.3,8.8c-5.6-0.4-9.9-5.3-9.5-10.9
	c0-0.3,0.1-0.6,0.1-0.9c1.6-10,12.1-18.4,19.4-24.3c6.7-5.4,13.8-10.3,20.9-15.1C564.4,522.8,562.4,529.2,560.8,535.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M560.2,588.4c-1.4,6.9-1.8,13.8-4.2,20.5c-2.3,6.5-6.7,11.2-13.2,13.3c-5,1.6-10.9,0.8-13.7-4
	c-3.3-5.7-0.4-13.3,3.4-17.9s9-7.6,13.9-10.5c5.6-3.3,11.8-7.2,16.2-12.3C561.8,581.2,561,584.8,560.2,588.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M541.8,679.7c-7.6,0.1-11.1-6.6-11.4-13.3c-0.3-8,4.9-15.1,10.2-20.5c9.1-9.3,21.8-14.2,28.1-25.9
	C569.6,630.9,555.5,679.6,541.8,679.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M890,716.8c-2.3,7.3-10.4,8.7-17,7.3c-8.5-1.8-14.3-8.9-16.2-17.1s-0.6-16.5-2-24.7c-1-6-4.1-12.5-9-16.5
	c10.1,5.1,19.7,11.1,28.6,18.1C884.2,692,894.4,703.3,890,716.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M519.8,536c-0.3,8.8-3.2,19.4-12.2,22.9c-5.2,2-9.1-1-11-5.8c-2-5.3,1.4-11.5,4.1-15.9
	c5.1-8.2,12.4-14.7,17.7-22.8h0.1c0.1,2.7,0.3,5.4,0.7,8.1C519.7,527,519.9,531.5,519.8,536z"/>
        <path :fill="colors[`${id}-p-2`]" d="M503.6,634.8c-5.9,0.6-10-5.1-11.7-10c-2.7-7.6,0.4-15,4.3-21.5c5.7-9.4,14-17,19-26.7c1.8,9.2,3.5,18.6,3.6,28
	C519.1,614.4,516.1,633.5,503.6,634.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M480.8,583.2c-0.3,6.8-2.8,13.2-8.4,17.3c-4.1,3-9.9,4.7-14.6,2.1c-5.7-3.1-5.8-11.9-5-17.4
	c1-6.7,4.3-12.8,7.9-18.4c5.3-8.4,12.3-15.6,18-23.6c-0.3,5.8,0.9,11.6,1.4,17.4C480.8,568.1,481,575.6,480.8,583.2z"/>
        <path :fill="colors[`${id}-p-1`]" d="M750.3,403.4c-4.5,5.7-11.8,7.8-18.2,10.4c-7.1,2.9-16,8.7-21.2,16c0.4-1.7,0.8-3.4,1.3-5
	c1.9-6.1,4.5-11.9,7.8-17.4c3.1-5.3,7.1-10,11.8-14c4.6-3.8,12.1-7.5,18-4.5C755.5,391.7,753.4,399.6,750.3,403.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M843.2,445.9c-3.8,7.7-14,9.5-21.6,8.1c-7.4-1.3-14.2-5.4-21.1-8c-4.9-1.8-10-3-15.2-3.5
	c6.5-4.5,13.3-8.6,20.3-12.2c9.3-4.6,22.5-9.6,31.8-2.4C842.7,432,846.2,439.6,843.2,445.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M878.5,588.6c-3.9,5-11.6,3.4-16.4,1c-8.1-4.1-10.8-12.6-11.2-21.1c-0.4-7.8,0.2-20.8-4.8-28.5
	c7.9,3.6,15.4,8.3,22.2,13.8c4.8,4.1,9.1,9.1,11.5,15S882.6,583.4,878.5,588.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M935.6,546.1c-3.3,5.7-10.1,9.5-16.6,10c-7.8,0.5-15.8-4.4-21.6-9.2c-5.5-4.5-8.5-10.7-12.9-16.1
	c-3.3-4.2-7.6-7.6-12.4-9.9c14.7-5.6,30.1-10.5,45.4-3.8C928.6,522,943.5,532.5,935.6,546.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M887.4,632c-1.9,12.7-19.3,17.7-24.4,4.5c-2.3-6-2.6-12.7-4-18.9s-5.7-11.3-7.7-17.4
	c7.4,3.7,14.9,7.1,21.9,11.4S888.8,622.6,887.4,632z"/>
        <path :fill="colors[`${id}-p-2`]" d="M934.3,623.9c-7,3.9-13.9-3.8-16.6-9.3c-3.8-7.6-1.9-17-3.1-25.1c-0.8-5.2-2.5-10.2-5.1-14.8
	c7.9,3.3,15.8,6.6,22.5,12.2C941.4,594.8,947.4,616.6,934.3,623.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M683.8,416.9c-8.3,10.1-20.3,15-33,16.1c6.1-8.5,6.7-19.6,8.9-29.5c2.5-11.4,9.9-23.1,23.3-19.6
	c6.4,1.7,9.5,7.8,9.5,14C692.5,404.9,688,411.6,683.8,416.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M727.9,373c-4.9,7.3-11.6,13.7-18.6,19.1c0.7-5.3,1.5-10.2,0.7-15.7c-1.1-7.1-2.9-14-2.4-21.2
	c0.5-7,4.1-14.6,10.9-17.2c6.4-2.4,12.3,2.9,15,8.2C738.1,355,732.9,365.6,727.9,373z"/>
        <path :fill="colors[`${id}-p-1`]" d="M667.7,370.4c-4.7,5-11.5,8.2-17.7,11.2c3-3.4,4.2-7.9,4.9-12.6c1-6.8,1.2-15.4,8.7-18.3c5.1-1.9,8.3,4,8.8,8.2
	C672.9,363.3,670.5,367.4,667.7,370.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M791,392.8c-5.3,7.8-12.4,13.6-20.4,18.4c2.2-6.4,1.4-15,1.4-20.4c0-6.4,1-12.4,5.5-17.2
	c3.8-4.1,9.9-7.5,15.6-6.3C805.5,369.8,794.7,387.3,791,392.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M831.1,397.5c-5.5,8.7-17.5,13.4-27.1,16.5c3-3.2,5-8,6.5-11.5c2.7-5.9,4.8-13.5,10.5-17.2
	c3.3-2.2,7.8-1.8,10.7,0.9C834.6,389.2,833,394.5,831.1,397.5z"/>
        <path :fill="colors[`${id}-p-0`]" d="M869.7,396.9c-2.1,7.8-11.9,8.5-18.5,9.4c-3.1,0.3-6.2,0.8-9.3,1.5c4.7-5.3,8.6-11.4,14.6-14.9
	C860.7,390.5,871.7,389.1,869.7,396.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M863,429.8c-6.5-0.6-11.7-4-16.8-7.7c3.8-0.5,7.6-1.3,11.4-1.9c5.2-0.8,14.5-2.2,17.6,3.3
	C879.1,430.5,866.4,430.1,863,429.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M897.2,399.9c-2.5,2.7-6.8,3.6-10.3,4.4c-1.1,0.2-8.6,1.1-12.3,2.7c2.6-4.5,6.1-8.4,10.2-11.6
	c2.3-1.8,11.7-8.7,14.3-4.5C900.9,393.5,899.1,397.9,897.2,399.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M871.4,463.4c-1.1,3.7-5.8,4.8-9,3.1c-4.6-2.4-4.7-11.1-5.3-15.5c-0.4-3.1-0.8-8.8-3-12
	c6.1,4.4,14.9,5,17.1,13.3C872.2,455.9,872.3,459.7,871.4,463.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M898.2,469.9c-3.6,1.2-7.7-2.8-9.9-5c-3-3.2-5.1-7-6.4-11.1c-1.2-3.7-1.8-7.7-3.1-11.4
	c4.3,1.2,8.5,2.8,12.6,4.8c5.3,2.6,12.5,6.9,12.7,13.6C904.3,464.2,901.5,468.8,898.2,469.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M913.5,421.7c-4.4,3.1-11.1,2.7-16.2,2.1c-5.1-0.5-10-2.1-14.5-4.6c7.7-2.7,15-8.1,23.6-8
	C912.2,411.3,921.4,416.2,913.5,421.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M922.9,456.7c-6,3.7-10.7-9-14.3-17.8C918.2,442.6,932.3,450.8,922.9,456.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M883.7,497.7c-1.4,1.4-4.5,3.3-6.2,1.5c-3.3-3.6-2.1-12.3-1.8-16.7c0.1-2.4,0.3-4.8,0.5-7.2
	c2.4,4.1,5.4,8,7.4,12.2C885.2,490.6,886.4,494.8,883.7,497.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M923,501.3c-3.1,0.9-6.9-0.9-9.5-2.2c-7.4-3.9-13.3-10.1-16.8-17.6c4.6,1.1,9.4,1.6,14.1,2.6
	c5.5,1.2,13.7,2.9,15.7,9C927.4,496.1,926.3,500.3,923,501.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M923.1,390.8c-3.2,4.5-7.7,8.3-13.2,9.4c0.7-2.7,1-5.6,1-8.4c0.2-4,0.9-7.9,2.1-11.6c1-4.1,3.9-7.4,7.7-9
	c3.5-1.4,7-0.2,8,3.6C930.1,380.2,926,386.6,923.1,390.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M889.1,371c-1,5.7-5.5,10.8-9,15.4c0.4-5.7,2.4-10.9,2-16.7c-0.2-2.4-0.2-4.8,0.2-7.2c0.5-2.5,3.8-5.2,5.8-2.5
	S889.7,367.8,889.1,371z"/>
        <path :fill="colors[`${id}-p-3`]" d="M953.6,401.7c-2.3,4.1-10.3,5-14.3,5.6c-4,0.7-8.2,0.9-12.3,0.6c3.7-1.8,7.5-6.9,10-8.7
	c3.5-2.4,8.2-4.4,12.5-3.6C951.8,396,955,399.1,953.6,401.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M941.6,438.4c-3.1-1.4-5.5-4.4-7.9-6.8c-1.3-1.4-2.5-2.8-3.8-4.2c1.8,0.7,3.7,1.1,5.6,1.4
	c5.2,0.7,13.3,0,16.3,5.3C956.3,441.8,945.4,440.1,941.6,438.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M958.9,473c-0.9,2.8-5.7,4.2-8.1,3c-3.1-1.6-4.6-6-5.7-9.1c-1.3-3.7-2.3-7.5-3.3-11.2c-0.2-0.9-0.5-1.9-0.7-2.9
	c2.1,1.6,4.3,3.2,6.3,4.9C951,460.7,960.7,467.2,958.9,473z"/>
        <path :fill="colors[`${id}-p-3`]" d="M937.3,480.5c-5.3,3.9-6.2-8.4-6.9-13.6C934.2,470.4,940.7,478,937.3,480.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M971.3,505.2c-6.2,3.4-14.2-1.1-19.7-4c-1.5-0.8-4-2.5-6.7-3.8c6.9-1.8,13.9-3.3,21.1-3.6
	c3.3-0.1,6.5,0.9,9.2,2.9C979.2,499.8,974.1,503.7,971.3,505.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M966.5,541.5c-1.1,5.6-6,6.1-9.9,2.9s-5.1-8.7-6.5-13.3c-1.3-4.2-2.8-8.4-4.3-12.5c-0.4-1.3-0.9-2.5-1.5-3.7
	c4.1,2.5,7.9,5.4,11.5,8.5C960.6,527.6,967.8,534.4,966.5,541.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M964.1,566.6c-2.4,4.8-9.1,5.8-13.8,5.8c-5.7-0.1-11.1-2.5-16-5.3c-2.6-1.6-5.3-2.9-8.2-4.1
	c4.4-1.1,8.8-2.3,13.2-3.4c4.5-1.3,9.1-2.2,13.8-2.5C958,556.8,967.4,560,964.1,566.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M979.2,418.5c-2.9,3.2-8,3.1-12,2.9c-4.1-0.1-8.1-0.7-11.9-1.9c-1.4-0.5-2.7-1.2-4-1.9
	c8.3-1.4,15.3-12.3,24.7-9.6C981.1,409.5,982.7,414.6,979.2,418.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M444.7,574.9c-1.3,6.3-8.5,8.6-14.2,6.9s-4.1-11.4-3.2-15.6c1.5-6.7,3.8-13.1,7-19.2c0.1-0.2,0.3-0.6,0.6-1
	C441.4,553.8,446.7,565.3,444.7,574.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M434.5,666.9c-13,3.7-12.8-18.3-11.2-25.6c3-14.2,18.3-24,17.6-39.1c6.5,9.4,9.9,21.1,10.4,32.4
	C452,647.4,448.5,663,434.5,666.9z"/>
        <path :fill="colors[`${id}-p-2`]" d="M403.5,615c-8.1-0.2-9.3-9.5-12.1-15.3c-3.1-6.4-6.8-12.4-11.2-18c-2-2.6-4.3-5-6.9-7.1
	c17.9,2,45.1-1.5,45,22.3C418.3,605.5,413.2,615.3,403.5,615z"/>
        <path :fill="colors[`${id}-p-2`]" d="M498.4,681.7c-0.5,4.7-2.3,9.3-6.6,11.6c-4.6,2.5-11.8,1.3-15.8-1.9c-8.9-7.2-5.6-24.1-4.4-33.7
	c1-8,2.3-16.1,2.3-24.2c0-3.9-0.3-8.2-1.8-11.8c7.9,5,13.2,13.9,17.3,22C495.1,655,499.7,668.8,498.4,681.7z"/>
        <path :fill="colors[`${id}-p-1`]" d="M517.5,723.4c-5.3,3.2-10.7,1.1-13-4.5c-2.9-7,0.4-15.2,3.4-21.6c2.5-5.5,5.7-10.6,7.9-16.3
	c1.9-5.2,2.9-10.7,2.9-16.2c4.3,8,7.5,16.6,9.7,25.4C531.1,702,528.8,716.5,517.5,723.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M542,751.8c-1.6,13.8-6.9,31.1-21.2,36.4c-8.1,3-16.8-1.8-17.3-10.7c-0.5-9.4,7.4-17.7,13.6-23.7
	c10.2-9.9,22-19.2,25-32.9C543.1,731.2,543.1,741.5,542,751.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M426,756c-1.7,4.9-5.4,11.6-11.2,12.2c-7.3,0.8-10.8-7.8-11.5-13.5c-0.8-6.6,0.3-13.4,1.6-19.8
	c1.6-8.8,3.6-17.4,6.1-26c1.9-6.1,4-12.1,6.2-18.1c-0.4,15,4.8,30.3,8.4,44.6C427.5,742.7,428.5,748.8,426,756z"/>
        <path :fill="colors[`${id}-p-3`]" d="M458.5,736.7c-4.9,1.5-10-1.5-12.7-5.5c-4-5.8-3.1-13.3-1.2-19.7c1.7-6,4.2-11.8,5.7-17.9
	c0.9-3.9,1.4-7.8,1.4-11.8c5.5,7,9.5,15.2,11.7,23.9C465.7,715.4,472.6,732.4,458.5,736.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M486.3,754.4c-0.4,9.4,0.2,18.8-1.7,28.1c-2.9,13.7-16.5,29.8-32.2,25c-8.4-2.6-10-12.9-8.5-20.3
	c1.8-8.5,7.2-16,12.3-22.8c5.5-7.4,11-14.8,17.2-21.6c4.8-5.2,9.9-10.2,15.4-14.7C487.5,736.8,486.7,745.6,486.3,754.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M567.9,814.4c-16.3,1.4-13.2-24-12.3-33.5c0.7-7.1,0.5-14.2,1.3-21.3c0.7-6.4,1.3-13.1-1.2-19.2
	c8.1,11.4,13.5,25,18.6,38C578.1,788.2,585.1,813,567.9,814.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M598.3,763.1c-1,6.1-3,15.1-10.4,15.9c-6.3,0.7-3.5-12.4-2.5-15.3c2.5-7.4,7.8-12.9,13.9-17.3
	C598.8,751.9,599.2,757.5,598.3,763.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M600.9,814c-0.5,9.2,0.4,18.3,0.1,27.5s-2.9,18.3-8.9,25.5c-4.5,5.2-11.4,9.9-18.5,8.3
	c-9.2-2-12-13.4-11.4-21.4c0.8-9.2,6.2-17.8,11.1-25.2c8.7-13.2,18-25.4,30.3-35.1C602.1,800.3,601.2,807.1,600.9,814z"/>
        <path :fill="colors[`${id}-p-3`]" d="M635.4,888c-3.5,5.2-12,3.4-15.7-0.6c-3.9-4.3-3.9-11.5-3.8-16.9c0.2-7.3,1.7-14.6,3.3-21.7
	c1.4-6.3,3-12.5,4.4-18.7c1.3-6.6,1.9-13.3,1.8-20c2.2,10.1,4.8,20.1,7.5,30.1c2.5,9.2,5.2,18.4,5.8,27.9
	C639.2,874.6,639.2,882.4,635.4,888z"/>
        <path :fill="colors[`${id}-p-2`]" d="M791.3,608.4c-1.2,6.4-4.9,14.1-11.6,16.2c-8.9,2.9-10.5-9.6-10.2-15.3c0.3-6.9,2.3-13.6,2.3-20.6
	c0-5.7-1.6-11.8-3-17.3c-0.9-3.7-2.4-7.3-4.4-10.6c0.9,0.4,1.8,0.8,2.7,1.3c3.6,2,6.9,4.5,9.9,7.3c5.5,4.9,9.8,11,12.5,17.8
	C792.1,594,792.7,601.3,791.3,608.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M841.3,511.8c-7.2,0.2-10.9-8.8-12.5-14.4c-1.8-6.1-2.4-12.4-3.8-18.6c-1.2-5.2-3.8-12.2-8.2-16
	c4.1,0.2,8.2,0.8,12.2,1.9c7.5,2.2,13.3,7.7,17.7,14c4,5.6,6.8,12.4,6.7,19.3C853.4,504.5,848,511.5,841.3,511.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M831.3,352.8c-0.6,5.5-2.5,10.8-5.5,15.4c-3.2,5.2-7.8,8.6-13.1,11.1c1.5-4.1,1.6-9.9,1.9-12.9
	c0.8-7.7,0.6-16.7,4.4-23.7c1.3-2.9,4.5-4.3,7.5-3.4C831.9,341,831.8,348.5,831.3,352.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M867.7,371c-2.3,3.5-6.4,5.2-10.3,6.2c-6,1.5-12.1,0.5-18.2,0.3c4.9-2.5,9.4-10.9,11.5-13.9
	c2.5-3.7,5-6.5,9.4-7.8c3.3-1,7.5-0.8,9.6,2.3C872.4,361.9,870,367.7,867.7,371z"/>
        <path :fill="colors[`${id}-p-4`]" d="M904.8,652.5c-4.9,1.4-8.1-3.1-9.5-7.1c-2.1-5.8-0.3-12.3,1.3-18c2.5-9,6.7-19.7,5.6-29.3
	c5.6,8,10.1,17.1,12,26.5C916,633.9,916.1,649.3,904.8,652.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M974.7,605.8c-4.9,11.1-20.3-1-22.8-8.1c-1.9-5.5-1.8-12.3-5.3-16.5c5.6,1.3,11,3.4,16,6.1
	C968.8,590.7,978.4,597.5,974.7,605.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M870.9,788.7c-4.8,4.8-13,2-17.3-1.9c-5.8-5.3-5.1-14.6-3.7-21.5c1.5-7.6,3.9-15.1,5.6-22.7
	c1.1-4.1,1.9-8.3,2.4-12.5c4.7,6.8,8.8,14.2,12,21.8C874,761.9,879.9,779.6,870.9,788.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M789,777.1c-3.8,1.4-7.9-2.9-9.8-5.6c-3.7-5.4-3.9-12.8-3.5-19.1c0.5-6.9,1.9-13.7,2.4-20.6
	c0.2-3.7,0.2-7.3,0.2-11c0-1.7,0.2-3.6,0.3-5.4c4.6,9.1,7.4,18.9,10.9,28.7c2.1,5.9,4,11.8,4.7,18.1
	C794.5,766.5,794,775.3,789,777.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M956,373.9c-3.9,3.9-10.1,10.3-16.3,11c5.4-6.4,5.2-16,7-24c1.2-5.1,2.9-10.6,7.6-13.5c6-3.7,12.3-1.1,13.2,5.8
	C968.5,361.5,961.3,368.5,956,373.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M916.3,357.8c-1,6.3-6.7,12.2-10.9,17.2c-0.2-2.7-0.7-5.4-1.1-8c-0.9-5.6-2.5-12.9,1.5-17.7
	c2-2.4,6.4-4.5,9-1.9C917.2,349.8,916.8,354.8,916.3,357.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M415.1,799.1c-3.2,12-17.5,16.3-28.3,17.6c-7.9,0.9-15.8,0.9-23.6,0c8.5-2.9,16.6-10.9,21-16.9
	s8.6-12.6,16.3-14.7C409.4,782.8,417.6,789.9,415.1,799.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M428,842.3c-3.9,3.4-8.3,6.3-13,8.5c1.8-4,2.8-8.3,3-12.7c0.1-6-2.5-12.1-1.6-18c1.2-8.4,11-9.7,16.4-4.8
	C441.7,823.3,435,836,428,842.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M421.6,894.9c-11.6,6.6-23.9,5.5-36.2,3.2c3.7-2.9,7-6.3,9.6-8.5c7.6-6.7,16.6-14.8,26.8-17
	c4.9-1,7.8,1.8,9.1,6.3C433.1,886.5,427.8,891.3,421.6,894.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M473.9,851.1c-2.2,8.4-8.6,15.5-15.7,21.2c2.1-7.6,0-16.3-3.4-23.4c-2.8-5.9-6.8-11.8-5.9-18.7
	c0.7-5.3,4.8-9.5,10.2-8.3c5.1,1.1,8.7,5.9,11.4,10C474.4,837.5,475.7,844.5,473.9,851.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M498.4,852.8c2.5-6.4,0.8-14.9-0.6-21.1c-1.4-6.5-4.8-12-4.7-18.9c0.2-8.5,8.6-13.4,15.9-9.5
	c11.3,6.1,12.3,21.9,7.8,32.5C513.3,843.7,506.6,849.9,498.4,852.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M436.8,944c-6.5,6.1-15.9,10.3-25,11.7c4.8-5.4,7.5-13.4,8-19.8c0.6-7.1-0.5-14.7,4.3-20.7s12.1-6.8,17.3-1
	C449.6,923.5,444.6,936.7,436.8,944z"/>
        <path :fill="colors[`${id}-p-1`]" d="M497,950.4c-10.2,8.8-24.1,13.9-37.5,11.3c10.2-7.1,15.3-19.9,19.1-31.2c2.5-7.4,5.7-15.1,12.2-19.8
	c6.9-5,16-4.4,20.5,3.2C519,926.6,506.2,942.6,497,950.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M794.6,822.6c-9.4-2.8-9-15.8-10.2-23.6c-0.4-3.1-1.1-6.5-2.9-9.1c6.9,2.5,13.5,5.8,18.5,11.3
	c3.4,3.6,5.4,8.2,5.6,13.1C805.8,820.7,800.3,824.3,794.6,822.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M842,809.4c-3.5,4-10.8,2.3-14.1-1c-2.9-2.9-3.8-7.7-5.3-11.4c-2-4.9-5.5-11.3-10.7-14.1
	c6.3,0.1,12.4,1.5,18.1,4.1C836.9,790.3,849,801.3,842,809.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M904.1,838.3c-6.6,4.2-14.6,1.2-19-4.6c-3.8-5-5.5-11.3-8.4-16.8c-2.6-4.8-6.6-8.7-11.4-11.3
	c9.8-1.4,19.4-2.2,29,2.1C904.8,812.5,917.9,829.6,904.1,838.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M874.4,875c-4.3,6.7-11.3,4.1-15.2-1.3c-4.2-5.9-2.7-13.1-0.2-19.3c2.9-7.3,6.5-15.8,4.7-23.4
	c3.3,5.1,6.1,10.3,8.6,15.8C876.2,855.2,879.9,866.5,874.4,875z"/>
        <path :fill="colors[`${id}-p-2`]" d="M896.9,921.7c-7,0.5-10-6.2-10.4-12.2c-0.5-8.4,5.2-16.3,8.6-23.6c3.1-6.4,4.6-13.4,4.5-20.4
	c0-0.7-0.1-1.5-0.1-2.3c6.7,7.9,12.1,17.2,14.5,27C917.2,902.5,911.8,920.7,896.9,921.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M867.9,942.7c-1.4,14-7,28.2-21.9,31.8c-9.6,2.3-17.3-6-18.5-14.9c-1.3-10.6,5.7-20.6,12.8-27.6
	c6.8-6.7,15.1-11,22.5-16.9c1.9-1.5,3.7-3.2,5.2-5.1C868.6,920.9,869,931.9,867.9,942.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M900.3,978c0-0.4,0-0.8,0-1.2c0-0.2,0-0.4,0-0.6c-0.2-2.9-0.6-5.7-1.2-8.5c-0.2-1.1-0.5-2.1-0.8-3.2
	c-0.3-1.2-0.7-2.3-1.1-3.5c-0.9-2.5-1.9-5-3.1-7.5c-0.4-0.8-0.8-1.5-1.1-2.3s-0.9-1.8-1.4-2.7s-1-1.8-1.5-2.7
	c-0.1,1.2-0.3,2.5-0.6,3.7c-0.3,1.1-0.6,2.1-1,3.1c-0.3,0.7-0.5,1.4-0.8,2.1c-1.6,3.4-3.4,6.8-5.4,10c-0.5,0.8-0.9,1.6-1.3,2.3
	l-0.2,0.4c-0.5,1-1.1,2-1.6,3c-1.5,2.7-2.5,5.7-3.2,8.7c-0.3,1.6-0.3,3.2-0.2,4.8c0.1,0.4,0.1,0.9,0.2,1.3s0.3,1.2,0.5,1.7
	c0.6,2,1.5,3.9,2.7,5.6c1.2,1.8,2.9,3.1,4.8,4c0.5,0.2,1,0.4,1.5,0.5c4.1,0.9,8.5-0.5,11.2-3.8c0.7-0.8,1.2-1.7,1.7-2.6
	c0.1-0.2,0.2-0.5,0.3-0.7c1.1-2.7,1.7-5.7,1.8-8.6C900.3,980.3,900.4,979.1,900.3,978z"/>
        <path :fill="colors[`${id}-p-3`]" d="M802.9,962.3c-1.2,0.6-2.4,1-3.7,1.4c-1.3,0.4-2.6,0.6-3.9,0.8c-0.4,0.1-0.9,0.1-1.3,0.2s-0.9,0.1-1.3,0.1
	c-2.4,0.2-4.8,0.3-7.1,0.2c-1.1,0-2.3,0-3.4-0.1c-2.7-0.1-5.5-0.3-8.2-0.3H772c-2.9-0.1-5.9,0.1-8.8,0.5c-1.4,0.2-2.7,0.5-4,0.9
	c-1.7,0.5-3.3,1.2-4.7,2.2c-1.7,1.1-3.1,2.6-4.2,4.3c-4.3,6.9-2.7,16.5,2.5,22.6c0.5,0.5,0.9,1,1.4,1.5c1,1.1,2.2,2.1,3.4,3
	c1,0.7,2,1.4,3.1,1.9c6.7,3.3,13.4,1.3,19.5-3.3c0.8-0.6,1.6-1.2,2.3-1.9s1.7-1.6,2.5-2.4c2.9-3,5.6-6.3,8-9.8
	c0.3-0.4,0.5-0.8,0.8-1.2c1.2-1.8,2.4-3.7,3.5-5.6c2-3.4,3.8-6.8,5.4-9.9c1.3-2.6,2.4-5.1,3.3-7.2
	C805.1,961.1,804,961.8,802.9,962.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M935.6,781.1c-6.4,0.7-13-1.2-19.1-3s-12.2-4.1-18.8-3.6c-3.1,0.3-6.1,1-9,2.2c3-2.4,6.2-4.5,9.6-6.2
	c5.3-3,10.8-5.9,16.3-8.6c6.6-3.2,13.8-5.9,21.2-5.4c6.8,0.3,12.2,5.7,12.7,12.5C948.8,776.1,941.8,780.4,935.6,781.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M955,843.5c-5.1,6.7-14.5,8.4-21.5,3.8c-7.2-4.6-9.5-13.6-10.8-21.5c-1.2-7.5-1.1-15.4-3.7-22.6
	c-1-2.5-2.3-4.9-4-7.1c9.9,1.7,19.3,5.3,27.8,10.6C954.6,814.3,965.5,830.4,955,843.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M964.5,910.6c-2.3,7-8.7,12.7-16.3,11.4c-9-1.5-12-10.8-13.3-18.6c-1.5-8.9-0.9-18.1-1.7-27.1
	c-0.3-3.8-1-7.5-2.3-11c6.7,4.2,13.1,8.8,19.1,13.8C959.1,886.8,968.6,898,964.5,910.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M944.4,956.2c-2.9-6.3-6.3-12.4-10.2-18.1l-1.2-1.8c-0.3-0.4-0.6-0.9-0.9-1.3c-0.7-0.9-1.4-1.9-2-2.8
	c0.1,1,0.1,2,0.1,3s0,1.7-0.1,2.5s-0.2,1.8-0.3,2.8c-0.9,5.2-2.2,10.2-3.9,15.2c-0.2,0.5-0.3,1-0.5,1.5l-0.8,2.4
	c-1.3,3.8-2.3,7.7-3.1,11.7c0,0.2-0.1,0.4-0.1,0.6v0.2c-0.2,1-0.3,2.1-0.5,3.2c-0.4,3.7-0.3,7.5,0.5,11.1c0.1,0.3,0.1,0.5,0.2,0.8
	c0.2,0.7,0.4,1.3,0.6,1.9c1,2.8,2.7,5.3,4.9,7.2c4.8,4,11.4,3.2,15.7-1.3c1.7-1.8,3.1-3.8,4.1-6.1c0.7-1.4,1.2-2.9,1.5-4.5
	C950.7,975.2,948.2,964.6,944.4,956.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M835.7,880.1c-4.9,1.9-10.5,0-14.3-3.3c-6-5.2-5.3-13.4-3.6-20.3c2.7-10.8,6.7-23.6,0.4-33.7
	c10.1,5.4,18.4,13.4,24.3,23.2C848.2,856.1,849,875,835.7,880.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M820.5,927c-9.3,9.5-26.6,17.3-37.2,5.4c-5.1-5.8-4.5-14.5-0.5-20.5c3.7-5.5,11.5-6.5,17.5-7
	c6.9-0.5,13.6-0.5,20.3-2.2c6.5-1.7,12.6-4.9,17.7-9.4C835.7,906,829.5,917.6,820.5,927z"/>
        <path :fill="colors[`${id}-p-3`]" d="M923.6,730.5c-3.4,5.1-10.5,7-16,8.8s-10.5,3.5-15.1,7c-2.3,1.8-4.4,3.8-6.3,6c0.9-7.2,3-14.1,6.1-20.7
	c4.7-9.3,14.6-19.6,26.2-16.5C925.9,717.1,927.4,724.7,923.6,730.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M979.6,722.7c-2.5,5.5-8.3,8-13.8,9.4s-11,1.9-16.5,3.1c-5.3,1.3-10.4,3.3-15.1,6c2-9.8,9-17.6,15.3-25.1
	c6.2-7.3,20.9-18.7,29.2-7.7C981.7,712.5,981.5,718.3,979.6,722.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M998.3,790c-5.4,2.9-12.6,0-17.7-2.2c-7.6-3.3-14.4-8.5-22.4-10.2c6.5-3.3,12.9-7,20-9
	c9.2-2.6,21.6-1.4,25.1,9.1C1004.9,782.4,1002.8,787.7,998.3,790z"/>
        <path :fill="colors[`${id}-p-3`]" d="M994.8,880.4c-6.1,2.3-12.4-1-17.2-4.6c-6.3-4.9-11.2-11.1-18.2-14.8c14.1-3.6,34.4-6.9,40.3,9.1
	C1001.4,874.6,999.3,878.7,994.8,880.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1004.4,964.1c-0.4-3.2-1.4-6.3-2.8-9.3c-0.3-0.7-0.7-1.5-1.1-2.2c-0.3-0.5-0.6-1-0.9-1.4
	c-3.2-5.2-7.8-9.4-13.2-12.1c-9.8-4.7-20.3-5.2-30.8-5.8c4,3,7.3,6.9,9.6,11.3c3.5,6.7,4.1,14,6.4,21.1c2.4,7.4,6.9,14.5,14.9,16.6
	c7.2,1.8,15.1-0.2,17.3-8.1c0.4-1.4,0.6-2.8,0.8-4.3c0.1-0.7,0.1-1.3,0.1-1.9C1004.6,966.7,1004.6,965.4,1004.4,964.1z"/>
        <path :fill="colors[`${id}-p-4`]" d="M750,950.4c-8.8-0.4-17.4-2.4-25.4-6c-14-6.2-32.5-21.9-28-39.3c2.6-10,13.6-11.8,22.1-9.1
	c9.4,3.1,14.3,11.3,18,19.9s6.7,16.6,13,23.5c4,4.4,9,7.7,14.7,9.7C759.6,950.1,754.8,950.5,750,950.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M795.7,882.6c-5.8,9-16.2,13.3-26.4,14.3c-9.9,0.9-20-0.8-25.8-8.9c5.2-0.2,10.4-0.8,14.9-3.6
	c6-3.7,7.2-10.9,8-17.2c1-8.7,4.6-17.2,13.2-20.8c4.9-2,10.4-0.8,15.3,1.3c4,2.8,5.8,7.3,6.3,13.2
	C801.8,868.4,799.9,876.1,795.7,882.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M722.8,971.8c-1.2,0.2-2.4,0.4-3.6,0.5c-2.3,0.3-4.7,0.4-7.1,0.5c-3.2,0.1-6.4,0.1-9.4,0.1h-4.3
	c-3.2,0-6.5,0.4-9.6,1.1c-1.3,0.3-2.6,0.7-3.9,1.3c-0.9,0.4-1.7,0.8-2.5,1.3c-2.1,1.3-3.9,3-5.3,5c-4.2,6-6.9,13.8-2.5,20.5
	c0.3,0.4,0.6,0.8,0.8,1.2c1.5,1.9,3.3,3.5,5.4,4.7c1.8,1,3.8,1.8,5.9,2.2c2.3,0.4,4.6,0.5,6.9,0.3c2.1-0.2,4.2-0.6,6.3-1.1
	c1.7-0.5,3.3-1,4.9-1.7c2.5-1.1,4.8-2.3,7-3.9c1.2-0.8,2.3-1.7,3.3-2.6c1.3-1.1,2.5-2.3,3.7-3.6c1.6-1.6,3-3.3,4.4-5.1
	c6.2-8,10.7-17.2,13.2-27C732.9,969,728,970.8,722.8,971.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M947.1,673c-4.8,3.2-11,7.3-15.7,12.4c-0.1-1.4-0.1-2.7-0.1-3.9c0.2-8.4,2.2-16.2,7.4-22.8
	c5.9-7.5,19.8-22.5,28.6-10.4C974.3,657.7,953.2,668.9,947.1,673z"/>
        <path :fill="colors[`${id}-p-3`]" d="M915.1,681.3c-4.9,7.6-10.1,14.9-14.3,22.9c-0.8-5.4-1.1-10.9-0.8-16.4c0.4-7.5,1.5-16.6,7.4-21.9
	c3.2-2.8,7.8-3.8,10.4,0.1C921.2,671.1,917.9,677,915.1,681.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M456,904.6c-2.8-3.6-7-5.6-10.5-8.5c-4.6-3.8-3.4-12,2.7-13.5c4.6-1.2,10.4,1.8,13.5,5.1
	c3.5,3.7,4.8,9.2,5.4,14c0.8,7.3,0,14.7-2.4,21.7C463.6,916.5,459.8,909.5,456,904.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M524.5,892.6c-2.8-3.6-7.9-4.6-12.2-5.1c-11.3-1.3-24.3,1.5-33.4-7.3c-6-5.8,7.4-10.8,11.2-11.9
	c6.7-2.1,13.5-0.4,19.3,3.2c8,5,14.2,14.4,16.9,24.1C525.8,894.5,525.2,893.5,524.5,892.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M552.9,846.1c-0.8,7.8-3.3,15.4-7.4,22.1c0-0.1,0-0.2,0-0.3c0.1-5.1-2.3-9.9-4.4-14.4
	c-2.4-5.1-4.9-9.8-5.8-15.4c-0.6-3.6-1-9.1,1.6-12c3.2-3.6,10.2,1.8,12.4,4.2C553.2,834.4,553.4,840.8,552.9,846.1z"/>
        <path :fill="colors[`${id}-p-2`]" d="M458.1,979.5c-1.9-6.5-8.5-11.5-15.5-8.7c-5,2.1-9,6.5-11.8,11.2c-0.5,0.9-1,1.8-1.5,2.8l-0.3,0.5
	c-3.4,6.8-7.4,13.2-13.8,16c7.8,4.7,16.6,7.2,25.9,4.5c2.9-0.8,5.6-2.1,8.1-3.9c1.5-1,2.8-2.2,4-3.6
	C457.8,993.4,460.2,986.7,458.1,979.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M528.7,963.8l-0.1-0.2l0,0c-1-2.1-2.4-3.9-4.2-5.3l-0.2-0.2l-0.1-0.1c-5.1-4-12.2-3.9-17.2,0.2
	c-0.1,0.1-0.2,0.1-0.2,0.2c-0.5,0.4-0.9,0.8-1.3,1.3c-1.3,1.5-2.5,3.2-3.4,5.1l-0.1,0.2c-0.7,1.4-1.2,2.8-1.7,4.2
	c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.7-0.4,1.4-0.6,2l-0.1,0.2c-1,3.7-1.6,7.4-2.4,11.1c-2.3,10.6-8.4,20-17.2,26.3
	c5.3,0.2,10.6-0.7,15.6-2.5c7.3-2.9,13.2-7.5,18.9-12.8c2.8-2.5,5.3-5.3,7.7-8.2c0-0.1,0.1-0.1,0.2-0.2l0.2-0.3l0.2-0.2
	c0.3-0.4,0.6-0.8,0.9-1.2c0-0.1,0.1-0.2,0.2-0.2c1.9-2.5,3.4-5.2,4.6-8.2l0.1-0.2c0.8-1.9,1.2-4,1.2-6c0-0.1,0-0.2,0-0.2
	C529.6,966.9,529.3,965.3,528.7,963.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M566.8,952c-3.3-6.6-9.5-12.1-14.5-17.4c-5.5-5.8-11.1-12.1-10.8-20.5c0.3-7.1,6.2-12.6,13.3-12.4
	c1.1,0,2.3,0.2,3.3,0.6c12,3.6,14.4,21,14.6,31.4c0.1,7.7-0.8,16.7-3.6,24.5C568.6,956,567.8,954,566.8,952z"/>
        <path :fill="colors[`${id}-p-3`]" d="M605,964.3c-0.2-7.9-7.3-8.8-13.8-6.8c-1.6,0.5-3.2,1.2-4.7,2l-0.3,0.2c-4,2.4-7.6,5.4-10.5,9l-0.4,0.4
	c-1,1.2-2,2.3-2.9,3.5c-4.3,5.3-9.2,12.4-15,17.8c-0.7,0.6-1.3,1.2-2,1.8s-1.2,1-1.9,1.4c-3.8,3-8.4,4.7-13.1,5
	c8.9,0.4,17.8-0.3,26.4-2.1c1.1-0.2,2.1-0.5,3.2-0.8c0.1,0,0.2,0,0.2-0.1c2.9-0.8,5.8-1.7,8.6-2.8
	C590.3,988.2,605.4,978.7,605,964.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M594.4,924.1c-4.8-5.8-7.8-11.5-7.6-19.2s7.4-11.2,13.9-7.9c11,5.5,14.5,20,14.9,31.1
	c0.3,8.6-1.5,17.1-5.2,24.9C609.7,942.2,601,932,594.4,924.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M651.1,944c-4.9-5.5-12.5-5.8-18.5-1.8c-8.2,5.5-11,15.2-11.2,24.6c0,3.2,0.1,6.4,0.1,9.6c0,0.1,0,0.2,0,0.2
	c0,1.8,0,3.5-0.1,5.3c0,0.1,0,0.2,0,0.2c-0.1,4.1-0.8,8.2-2.2,12.1c-2.7,7.5-7.3,13.7-14.3,17.6c-0.1,0-0.2,0.1-0.2,0.1
	c13.2-4.2,25.1-11.5,34.8-21.3l0.2-0.2c0.7-0.7,1.4-1.5,2-2.2v-0.1l0.1-0.1c5.4-6.1,10.1-13.3,12.1-21.3
	C655.8,959.8,656.4,949.9,651.1,944z"/>
        <path :fill="colors[`${id}-p-1`]" d="M658,926c-5.7-3.4-11.1-7.4-12.6-14.3c-1.6-7.5,3.6-12.7,10.5-13.9c13.6-2.5,23.7,8.9,26.6,20.8
	c2.6,10.1,1.9,20.8,2.5,31.2C680,938.9,667.6,931.7,658,926z"/>
        <path :fill="colors[`${id}-p-3`]" d="M718.2,868.3c-2.9,4.3-7.4,9.6-13,9.7c-4.5,0.1-6.1-4.1-5.9-7.8c0.5-9.2,7.3-18.6,11.9-26.2
	c3.3-5.5,8.1-11.6,10.1-18.1c1.6,8.3,2.4,16.6,2.6,25.1C723.9,857.7,721.9,862.8,718.2,868.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M691.2,435.9c-2.5,1.1-5.1,1.9-7.8,2.1c-2.1,0.3-4.1,0.4-6.2,0.2l0.7-0.3c2.4-1.2,4.5-3,6-5.1
	c2.2-2.8,5.8-11.9,10.7-9.3c2.2,1.2,2.7,4.5,2.1,6.6C695.7,432.8,693.5,434.7,691.2,435.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M26.1,49c-2-8.2-8.4-14-14.5-19.3c-4-3.4-7.8-7-11.6-10.6v32c2.3,6.4,6.4,12.5,13.1,14.6
	c4.7,1.4,9.6,0.1,11.9-4.5C26.9,57.4,27.2,53.1,26.1,49z"/>
        <path :fill="colors[`${id}-p-3`]" d="M2.2,146.2c-0.8,0.4-1.5,0.9-2.2,1.3v23.1c0.7-0.5,1.3-1,1.9-1.5c2.6-2.5,4.7-5.5,6.1-8.8
	c2.6-5.9,3.7-12.4,4.4-18.7C8.9,142.9,5.5,144.5,2.2,146.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M9.6,235.3c-3.2-0.3-6.4-0.6-9.6,0v16.1c2.7-0.7,5.2-1.9,7.5-3.5c4.9-3.2,9-7.4,13.6-10.9
	C17.3,236.2,13.5,235.6,9.6,235.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M16,256.9c-4.4,5-10.4,8.3-15,13.2c-0.3,0.4-0.6,0.7-1,1.1v14.2c0.4,0.3,0.8,0.5,1.2,0.6
	c2.6,0.8,5.5,0.1,7.5-1.9c2.2-2.3,3.6-5.2,4-8.3c0.6-3.1,1.1-6.2,1.6-9.4S16.1,260.1,16,256.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M0,334.6v20.9c5.4-0.1,10.1-3.9,13.1-8.5c3.5-5.4,5.6-11.6,8.6-17.3C14.4,329.4,6.3,330.7,0,334.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M12,429.9c-0.1-4.5-0.5-8.9-0.6-13.4c0-5-0.3-10-0.9-15c-0.4,1-0.8,2-1.3,3c-2.1,4.4-4.1,8.8-6.4,13
	c-0.9,1.7-1.9,3.5-2.8,5.3v24c0.5,0.3,1,0.6,1.5,0.7c3.4,0.8,6.8-3.1,8.2-5.7C11.6,438.1,12,434,12,429.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M22.3,469.7c-7.3,0.9-5.9-9.2-5-13.4c0.6-3,1.4-6,2.2-8.9c0.5-2.4,1.2-4.7,2.1-7c0.3,1.5,0.7,3.1,1.1,4.6
	c0.9,3.3,2.2,6.5,3.2,9.8C27.3,459.2,29.5,468.7,22.3,469.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M15.3,556.9c-5.6,3.6-10.7,7.8-15.3,12.6V596c5.5-2,9.3-9,11.7-13.8c2.2-4.2,4-8.6,5.2-13.1
	c1.1-4.8,1.9-9.8,2.3-14.8C17.9,555.2,16.6,556,15.3,556.9z"/>
        <path :fill="colors[`${id}-p-1`]" d="M22,488.6c-3.5,1.1-6.9,2.8-9.9,4.8c-3.7,2.2-7.2,4.8-10.4,7.6c-0.5,0.5-1.1,1-1.6,1.6v19.8
	c1.1-0.3,2.2-0.7,3.2-1.2c3.8-1.9,7.1-4.6,9.7-7.9c5.5-6.8,7.3-15.3,9.2-23.6C21.9,489.4,21.9,489,22,488.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M19.9,604.2c-1.6,3.3-3.7,6.3-6.2,9c-4.4,4.8-9.2,9-13.7,13.7v30.4c4.1,0.5,7.7-2.3,10.2-5.3
	c5.4-6.3,7.4-14.8,8.7-22.8C20.3,621,19.8,612.6,19.9,604.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M24.6,726.7c-2.1,2.8-5.2,4.7-8.6,5.3c-3.7,0.6-6.6-1.2-8.3-4.3c-2-3.5-0.9-8,0.3-11.5
	c1.5-3.7,3.2-7.4,5.2-10.9c2.1-4.1,4.2-8.2,6.6-12.1c1.8-3,3.8-5.8,6-8.4c-0.4,9.5,1.6,19,1.8,28.5
	C27.8,717.9,27.2,722.8,24.6,726.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M0,803.6v42.5c0.7-0.4,1.4-0.9,2-1.5c6.6-6.1,7.8-16.5,8.8-24.9c1.1-9.8,2.6-19.7,3.3-29.6
	C9.4,794.7,4.6,799,0,803.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M16.6,878c-6,3.4-12.3,7.3-16.6,12.9v25.9c4.7,3,11.3,2.2,16.2-1.2c6.5-4.4,10.2-11.9,12.5-19.1
	c3-9.5,5.5-19.3,7.7-29C30,871.3,23.1,874.4,16.6,878z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1017.2,12.6c1.7,4.8,2.8,9.7,3.3,14.7c0.8,6.2,0.7,12.6,2,18.7c0.4,1.7,0.8,3.4,1.4,5.1v-32
	C1021.7,17,1019.5,14.8,1017.2,12.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1020.9,149.7c-1.6,1-3.1,2.2-4.5,3.5c-2.6,2.5-5.1,5.9-4.7,9.8c0.3,3.2,1.9,7.4,5.1,8.9
	c2.5,1.2,5.1,0.4,7.2-1.1v-23.2C1023,148.2,1021.9,149,1020.9,149.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1022.8,235.6c-3.3,1-6.2,3.2-8,6.1c-1.3,2.1-2.2,5.1-0.9,7.5c1.7,2.9,5.4,3.1,8.4,2.5c0.6-0.1,1.2-0.2,1.7-0.4
	v-16.1C1023.6,235.4,1023.2,235.5,1022.8,235.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1020.9,279c0.1,2.4,1,5.1,3.1,6.5v-14.3C1022,273.3,1020.9,276.1,1020.9,279z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1021.2,336.6c-3,2.4-6.1,5.9-6.3,10c-0.2,3.5,1.3,6.9,4.8,8.1c1.4,0.5,2.9,0.8,4.4,0.8v-20.9
	C1023,335.2,1022.1,335.9,1021.2,336.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1020.4,431.2c-1,3.2-1,6.6,0.1,9.8c0.6,1.9,1.7,4.5,3.5,5.8v-24C1022.5,425.4,1021.3,428.2,1020.4,431.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1018.3,576.4c-2.3,3.2-4.6,7.1-4.8,11.1c0,0.4,0,0.9,0,1.3c0,0.3,0.1,0.7,0.1,1c0.2,1,0.5,2.1,1,3
	c0.9,1.8,2.5,3,4.4,3.4c1.7,0.4,3.4,0.2,5-0.3v-26.5C1022,571.6,1020,574,1018.3,576.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M1018.3,511.3c-1,2.8-1.9,6.8-0.2,9.5l0,0c1.1,1.8,3,2.1,5,1.8l0,0c0.3,0,0.6-0.1,0.9-0.2v-19.8
	C1021.5,505.1,1019.5,508,1018.3,511.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M1023.9,627c-2.1,2.2-4,4.5-5.6,7.1c-2.2,3.3-3.3,7.1-3.2,11c0.1,1.4,0.3,2.9,0.8,4.2c0.5,1.6,1.3,3.1,2.2,4.4
	c1.1,1.6,2.8,2.8,4.7,3.4l0.3,0.1c0.3,0.1,0.5,0.1,0.8,0.1L1023.9,627C1024,626.9,1024,627,1023.9,627z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1023.2,804.4c-1,1-1.9,2-2.8,3c-2.3,2.5-4.4,5.1-6.3,7.8l0,0c-0.7,1-1.3,2-1.9,3c-0.7,1.3-1.4,2.6-2,4
	c-0.2,0.4-0.4,0.9-0.5,1.3c-0.9,2.4-1.6,4.9-1.9,7.5c-0.1,1.1-0.2,2.3-0.2,3.5c0,0.4,0,0.9,0,1.3c0,0.7,0.1,1.5,0.2,2.2l0,0
	c0.1,0.5,0.2,1.1,0.3,1.7c0.3,1.9,1.1,3.7,2.3,5.2c1,1.2,2.4,2.1,3.9,2.6c1.3,0.4,2.6,0.6,4,0.5c2.1-0.2,4.1-0.8,5.9-2v-42.5
	L1023.2,804.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M1022.8,892.6c-1.6,2.3-2.8,4.9-3.5,7.6l0,0c-0.3,0.9-0.5,1.9-0.6,2.8c-0.1,1-0.2,2-0.2,2.9
	c0,2.4,0.6,4.8,1.9,6.9c0.5,0.8,1,1.5,1.6,2.2c0.6,0.7,1.3,1.2,2.1,1.7v-25.9C1023.6,891.5,1023.1,892,1022.8,892.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M19.5,990.5c-0.1-0.8-0.3-1.6-0.5-2.4s-0.3-1.5-0.5-2.2c-0.1-0.5-0.2-1-0.4-1.5c-0.7-3-1.4-5.9-1.9-8.9
	c0-0.1,0-0.2,0-0.3c-0.3-1.9-0.6-3.8-0.9-5.7c-0.2-1.7-0.5-3.4-0.7-5.1c-0.1-0.9-0.2-1.8-0.3-2.6c-1,1-1.9,2-2.8,3.1
	c-1.5,1.8-2.9,3.6-4.2,5.5c-1.2,1.7-2.3,3.4-3.4,5.1c-1.4,2.3-2.8,4.7-4,7.1v41.5h15.4c0.4-0.5,0.7-1.1,1-1.6c1.1-1.8,2-3.8,2.6-5.8
	c0.8-2.4,1.3-4.8,1.6-7.3c0.1-0.9,0.2-1.8,0.2-2.7c0.1-1.2,0.1-2.4,0.1-3.5C20.8,998.8,20.3,994.6,19.5,990.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1022.1,986.1c-3.7,7.1-7.6,14.8-8.6,22.8c-0.1,0.9-0.2,1.7-0.2,2.6c-0.1,1.4,0,2.8,0.2,4.2
	c0.1,0.5,0.1,1,0.2,1.6c0.4,2.3,1.1,4.5,2.2,6.6h8.1v-41.5C1023.4,983.7,1022.7,984.9,1022.1,986.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M0,0v7.1c0.8,0.2,1.6,0.3,2.4,0.3c5.5,0.2,9.9-3.1,13-7.5H0z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1015.9,0c1.7,3.4,4.4,6.2,8.1,7.1V0H1015.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M876.9,81.4c-1.3,3.7-3.1,7.2-5.2,10.5c-0.8-4.4-0.8-8.8-1.6-13.2c-0.8-4.7-3.1-8.9-5.2-13.1s-4-8.3-4.2-13
	c0-4.7,2.1-9.2,5.6-12.4c3.3-3,8.5-3.8,11.8-0.4s3.7,8.8,3.9,13.1C882.4,62.4,880.2,72.4,876.9,81.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M939.2,65.2c-0.3,4.5-2.6,8.8-5.3,12.2c-4.4,5.6-10,9.6-16.1,12.9c1.5-1.7,2.6-3.7,3.3-5.9
	c1.3-3.6,1.2-7.6,1.7-11.3c0.5-4.2,1.3-8.3,3.8-11.9c1.8-2.7,4.8-5.2,8.2-5C938.9,56.5,939.4,62.2,939.2,65.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M919.2,45.3c-0.7-3.9-3.3-7.1-5.8-10.1s-4.8-6-5.1-9.8c-0.3-3.8,2-7.3,5.6-8.5c3.7-1.1,7,1.4,8.9,4.3
	c2.2,3.4,2.5,7.7,2.3,11.7c-0.3,6.4-2.4,12.6-5.9,18C919.5,49.1,919.5,47.2,919.2,45.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M947.5,97.4c-1.6,2.9-3.6,5.6-5.3,8.5c-1.6,2.7-3.1,5.6-4.2,8.6c-3-4.8-4.7-10.5-3.7-16.2
	c0.9-5.4,4.7-10.8,10-12.8c2.1-0.8,4.3-0.7,5.4,1.5C951.3,90.2,949,94.7,947.5,97.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M828.5,64.3c-2.2-0.6-4.4-0.9-6.6-1.1c-3-0.2-6-0.3-9-0.6c-3.8-0.2-7.2-2.3-9.1-5.6c-1.8-3-1.9-6.9,2-8.2
	c3.2-1,6.9,1,9.5,2.6c2.8,1.7,5.6,3.5,8.3,5.4c3,2.1,4.9,5,6.7,8C829.8,64.6,829.1,64.4,828.5,64.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M991.2,99.6c-0.9,8.5-5.8,16.4-12,22.1c-1.8,1.6-3.7,3.1-5.8,4.3c1.7-3.2,2.8-6.7,3.2-10.2
	c0.5-4.4-0.6-8.7-2-12.8c-2.3-6.8-7-20.1,3.8-22.3c4.7-1,9,2.7,11,6.6C991.5,91,991.7,95.6,991.2,99.6z"/>
        <path :fill="colors[`${id}-p-1`]" d="M961,26.6c-0.3,4.4-3.4,8.5-6.5,11.4c-6.8,6.6-15.8,9.1-24.7,11.8c2.2-2.4,4.1-5.1,5.5-8.1
	c1.6-3.6,2.9-7.3,4.1-11c1.2-3.5,2.8-7,5.4-9.7c2.7-2.8,6.7-3.9,10.5-2.9C959,19.3,961.4,22.9,961,26.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M971.3,62.4c-2.3,5-6.9,8.2-11.6,10.8c0.3-2,0.4-3.9,0.2-5.9c-0.2-3.6-0.7-7.1-0.9-10.7s0.7-7,3.2-9.5
	c2.1-2.2,5.5-2.5,7.9-0.7C975.2,49.8,973.4,57.8,971.3,62.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M994.4,217.7c0.5-2.8,0.6-5.6,0.3-8.4c-0.5-4.8-2.5-9.2-4.4-13.6c-2-4.3-2.8-9-2.4-13.7
	c0.4-4.2,2.9-9.2,7.6-9.6c4.6-0.4,8.5,4.3,10.1,8.1s1.5,8.1,0.8,12.1c-1.6,8.7-5.4,16.9-11,23.8
	C995.1,216.9,994.7,217.3,994.4,217.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M984.2,23.8c-1.6,6.8-5.2,12.6-12.2,14.5c3.2-4.4,3.8-10,3-15.6c-0.6-4.2-3-13.1,3.6-13.6
	c3.2-0.2,5,2.7,5.8,5.4C985.2,17.7,985.1,20.8,984.2,23.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1004.1,59.4c-1.9,3.5-4.7,6.5-8.2,8.6c-4.1,2.7-8.5,4.9-13.2,6.3c-1.3,0.4-2.6,0.6-3.9,0.6
	c3.8-3.3,5.6-8.9,6.7-13.6c1.6-7.1,3.9-16.8,12.1-18.8c3.7-0.8,7.4,1.4,8.5,5C1007.3,51.3,1005.8,56.1,1004.1,59.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M857.5,25.3c-0.6,6.2-3.8,11.9-8.9,15.7c-0.2-3.2-0.8-6.4-1.9-9.5c-1.3-3.6-3.2-6.8-4.6-10.3
	c-1.2-3.2-2.2-6.9-0.8-10.2c1.3-3.3,5-4.9,8.2-3.6c0.1,0.1,0.3,0.1,0.4,0.2C856.3,10.7,858,19,857.5,25.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M891.7,0h-19.7c1.1,1.9,2.5,3.8,3.9,5.4c5.6,6.5,9.7,14.1,12.2,22.3h0.2c0.4-0.7,0.8-1.4,1.1-2.1
	c1.9-4.4,1.9-9,2.2-13.7C892.1,7.9,892.1,4,891.7,0z"/>
        <path :fill="colors[`${id}-p-3`]" d="M753.8,41.5c-3.1-2.3-6.6-3.9-10.3-4.8c-5-1.3-15.2-3.1-13.5-10.6c0.7-2.8,4.2-3.5,6.6-3.6
	c2.9-0.2,5.8,0.4,8.3,1.9c7,4.2,10.8,12.2,13.3,19.6c0.2,0.7,0.5,1.4,0.7,2.2C757.3,44.5,755.6,42.9,753.8,41.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M766.5,39.7c-0.6-3-2.5-5.9-4.2-8.5c-1.7-2.2-3-4.6-4-7.2c-1-3,0.3-6.6,3.8-6.9c2.5-0.2,4.6,2,5.9,3.9
	c1.2,2.1,1.9,4.4,2,6.9C770.2,32,768.5,36,766.5,39.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M654.4,35.5c-2,5.8-5.2,11.1-9.5,15.5c0-1,0-1.9-0.1-2.9c-0.2-2-0.5-4-0.9-6c-0.4-1.9-1.1-3.8-1.9-5.6
	c-1.8-4-3.2-8.4-2.1-12.8c0.9-3.6,3.8-6,7.5-5.1c2.9,0.8,5.3,2.8,6.7,5.5C656.2,27.4,655.6,31.8,654.4,35.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M445,35.9c-3.7,3.4-8.8,6.1-13.9,5.1c1.6-2.2,2.7-4.6,3.3-7.3c0.6-2.8,1-5.7,1.1-8.6c0.1-3.2,0.7-6.4,1.8-9.4
	c0.6-1.7,1.6-3.3,2.9-4.5c1.7-1.5,4.2-1.5,6.4-1c3.9,0.9,6.7,4.2,7,8.1C454.2,25,449.7,31.6,445,35.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M137.6,26.5c-1.6,1.8-4.6,2.4-6.5,0.7c-2.7-2.4-1.9-7.5-1.6-10.6c0.2-1.9,0.4-3.9,0.6-5.8
	c0.1-1.4,0.3-2.7,0.7-4c0.9,1.5,1.8,3,2.7,4.4c1.6,2.5,3.6,4.9,5.1,7.6C139.9,21.3,139.5,24.4,137.6,26.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M225.9,25.5c-1.8,0-3.7-1.7-4.6-3.1s-0.7-3.5-0.5-5.2c0.4-2.6,1.4-5.2,1.6-7.9c2,2.3,4.3,4.3,5.8,6.9
	S230.4,25.4,225.9,25.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M284.3,14.6c-0.9-3-2-5.9-3.3-8.8c-1.4,3.6-3.8,6.9-5.6,10.2c-1.4,2.4-2.5,4.8-3.3,7.4
	c-0.6,1.9-1.1,4.3-0.1,6.2c1.1,2.1,4.1,3.6,6.5,2.6c2.8-1.2,4.2-4.4,5-7.2C284.5,21.5,285.2,18.2,284.3,14.6z"/>
        <path :fill="colors[`${id}-p-2`]" d="M335.6,42.1c-2.6,1.6-5.6,0.6-7.6-1.5c-2.8-2.9-3.2-6.6-3.5-10.5c-0.5-6.5-2-13.5-5.4-19.2
	c2.8,1,5.6,2.2,8.2,3.6c3.2,1.7,5.8,4.2,7.6,7.3C338.1,27.2,342.7,37.7,335.6,42.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M354.2,29c-0.3,1.2-0.9,2.3-1.8,3.2c-1.3,1.3-3,0.8-4.1-0.4c-3-2.8-1.4-8.4-0.2-11.7c0.6-1.8,1.2-3.6,1.8-5.3
	c0.3-0.8,0.6-1.5,1-2.2c0.5,2.7,1.3,5.4,2.3,7.9C354.1,23.1,355.1,26.3,354.2,29z"/>
        <path :fill="colors[`${id}-p-3`]" d="M384.5,34c-1.7,2.2-4.1,3.8-6.8,4.3c-8.2,1.5-7.5-10.7-7-15.6c0.3-3.2,0.6-6.4,1-9.5c0.2-1.9,0.2-3.9,0-5.9
	c2.6,2.1,5.1,4.3,7.4,6.8c2.4,3,4.3,6.3,5.6,9.9C386,27.2,386.6,30.9,384.5,34z"/>
        <path :fill="colors[`${id}-p-1`]" d="M491.4,19.4c-0.8,3.5-3.3,6.3-5.8,8.7c-3,2.8-6.4,5.1-10.1,6.9c-5.8,3-12,5-18.5,5.8c0.9-0.5,1.7-1,2.5-1.5
	c3.7-2.6,6.1-6.5,7.9-10.6c2.9-6.5,4.6-14.7,11.5-18.3c3-1.6,7.8-1.4,10.5,0.7C491.8,13.1,492.1,16.5,491.4,19.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M529.5,3.2C529.3,2.1,529,1,528.7,0H511c1.2,3.1,3.3,5.8,5.5,8.3c4.8,5.7,7.9,12.7,8.9,20.2h0.2l0,0
	c0-0.1,0-0.2,0.1-0.4C528.8,20.5,531.1,11.4,529.5,3.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M604.7,33.9c-0.9,2.7-2,5.3-3.3,7.8c-1.4,2.5-3.3,5.8-6,7.2c0.3-3.6,0.1-7.2-0.3-10.7c-0.5-3.3-1.5-6.6-1.6-9.9
	c-0.2-4.2,1.3-10.6,6.8-8.9C606.7,21.2,606.3,28.7,604.7,33.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M582.8,19.5c-0.4,4-0.8,8.2-2.4,11.9c0-0.7,0-1.4-0.1-2.1c-0.5-2.5-1.4-4.8-2.8-6.9c-1.4-2.3-3.2-4.7-3.5-7.4
	c-0.4-2.2,0.9-4.3,3-5.1c2-0.6,4.2,0.4,5.1,2.3C583.3,14.3,583,17.2,582.8,19.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M704.7,12.8c-2,3.2-2.7,7-4.6,10.2c-1.7,2.9-4.5,5-7.8,5.7c-2.4,0.5-6.2,0.5-7.7-1.8s0.4-5.5,1.7-7.3
	c1.8-2.3,4-4.4,6.5-6c5.1-3.5,10.9-5.1,16.8-6.4C707.6,8.7,706,10.7,704.7,12.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M725.8,18.8c-0.3-4.8-1.3-9.6-2.9-14.1c-0.2,4.1-1.4,8.4-2.5,12.2c-0.7,2.5-1.5,5.3-1,7.9
	c0.3,1.5,2.1,5.3,4.2,3.9c1.4-0.9,1.9-3.1,2.1-4.6C726,22.3,726,20.6,725.8,18.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M706.6,70c-0.1,3.2-2.9,6.2-6.2,5.7c-2.5-0.4-4.6-2.4-5.8-4.5c-1.6-2.7-1.4-6.4-1-9.4
	c0.8-6.3,3.7-12.1,6.8-17.6c0,2.6,0.1,5.3,0.3,7.9c0.5,3.1,1.6,6.1,3.1,8.8C705.2,63.7,706.7,66.7,706.6,70z"/>
        <path :fill="colors[`${id}-p-3`]" d="M807.6,26.3c-3.5,2.3-7.3,4.3-11.1,5.9c1.1-1.4,2.2-2.9,3.1-4.5c1.7-2.9,2.6-6.2,4.1-9.2c1.3-2.6,3-5.5,5.6-7
	s5.3,0.8,6.3,3.2C817.3,19.6,811,24.1,807.6,26.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M777.7,81.7c-0.8-1.5-1.7-3.1-2.7-4.5c-1.4-2.3-3.8-6.1-1.7-8.7c2.8-3.3,4.7,1.9,5.3,4.1
	C779.4,75.7,778.6,78.8,777.7,81.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M989.3,0c0.4,0.7,0.8,1.3,1.2,2c2.4,3.7,4,7.9,4.7,12.3c0.4,3.3,0.3,6.6-0.3,9.9c3.1-3.3,5.2-7.5,6-12
	c0.9-4,1.5-8,2-12.1L989.3,0z"/>
        <path :fill="colors[`${id}-p-3`]" d="M388.8,0c0.5,2.7,1.3,5.4,1.8,8.2c1.3,7.4,1.5,15.5-0.6,22.8c1.9-2.1,3.7-4.3,5.4-6.6c5.1-7,9.6-15.4,9.5-24.3
	H388.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M68.4,1014.3c-0.5,3.2-1.1,6.5-1.6,9.7h6.4C71.6,1020.8,70.1,1017.5,68.4,1014.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M891.7,1023.4c0-0.1,0-0.2,0-0.4c-0.5-3.9-1.8-8.6-4.9-11.2c-2.7-2.3-6.4-3.3-9.9-2.6c-3.9,1-6.7,4.5-6.6,8.5
	l0,0c0,1.1,0.2,2.2,0.6,3.2c0.3,1,0.8,2,1.3,3h19.7C891.7,1023.8,891.7,1023.6,891.7,1023.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M527.4,1021.2c-0.7-1.3-1.7-2.4-2.8-3.4c-2.3-2-5.8-4.3-9-3.5c-3.7,0.9-6,4.7-5,8.4c0,0.1,0,0.2,0.1,0.2
	c0.1,0.3,0.2,0.7,0.4,1h17.6C528.3,1023,527.9,1022.1,527.4,1021.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1003.1,1017.7L1003.1,1017.7c-0.1-3-0.6-6.1-2.4-8.4c-1.9-2.5-5.1-4.4-8.4-4c-4.1,0.5-6.8,4.6-6.8,8.5
	c0,0.7,0.1,1.5,0.2,2.2c0.7,2.9,1.9,5.6,3.5,8h13.6c0.1-0.8,0.1-1.6,0.2-2.4c0-0.1,0-0.1,0-0.2
	C1003.1,1020.3,1003.2,1019,1003.1,1017.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M404.8,1022.1c-0.3-3.5-1.8-6.8-4.2-9.4c-1.8-2-5.1-5-8.1-3.5c-3.8,2-4.6,6.7-4.3,10.5c0.1,1.4,0.3,2.8,0.6,4.2
	h16.1C404.9,1023.4,404.8,1022.8,404.8,1022.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M1001.5,131.6c-3.5,1.8-7.3,2.1-11.2,2.4c3.7-2.1,6.8-5.2,8.8-9c3-5.8,2.2-12.8,4.8-18.9
	c1.2-2.8,3.2-5.1,6.3-5.7s6,0.6,7.2,3.6c2.5,6.4-1.2,14-5.1,18.9C1009.4,126.6,1005.7,129.5,1001.5,131.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M995.2,274.4c-2.8,1.6-5.6,2.2-8.6,2.9c-1.4,0.3-2.8,0.7-4.1,1.2c4.1-3.8,7.3-8.4,9.4-13.5
	c2.2-5.4,1.9-12.2,5.7-16.9c1.6-2,4.5-2.4,6.6-0.8c0.2,0.1,0.3,0.2,0.4,0.4c2.1,2,2.8,5.4,2.8,8.2
	C1007.8,263.7,1001.6,270.6,995.2,274.4z"/>
        <path :fill="colors[`${id}-p-1`]" d="M1010.4,328.3c-0.4,4.1-2.6,8-5.1,11.1c-2.9,3.4-6.3,6.2-10.1,8.4c-4.5,2.8-9.1,5.3-14.2,6.6
	c-0.3,0.1-0.7,0.1-1,0.2c2.6-3.3,5.1-6.7,7.4-10.2c2.2-3.8,4.1-7.8,5.5-11.9c1.4-3.6,3-7.3,5.8-10.1c2.2-2.2,6.8-4.6,9.7-2.3
	C1010.6,322,1010.6,325.7,1010.4,328.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M1013.3,391.3c-2.7,3.6-6.1,6.7-9.9,9.1c-3.7,2.5-8,4.1-12.4,4.5l-1,0c5.6-4.3,9.8-10.4,10.8-17.5
	c0.6-4.2,0.3-8.5,1.5-12.7c0.5-2.1,1.7-3.9,3.4-5.2c1.6-1.1,3.4-0.6,4.9,0.3c3.1,1.9,4.9,5.7,5.7,9.1
	C1017.3,383.4,1015.8,387.7,1013.3,391.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1007.8,445.6c-1.4,1.4-3.8,1.4-5.4,0.6c-1.7-0.9-3.1-2.4-4-4.1c-2.1-3.5-2.3-7.7-2.6-11.7
	c-0.1-3.4-0.9-6.8-2.4-9.9c-1.1-2.3-2.7-4.4-4.7-6c6.9,1,11.6,6.4,16,11.5c2.5,2.7,4.3,6,5.2,9.6
	C1010.6,438.8,1010.3,443.1,1007.8,445.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1007.8,514.8c-1.5,2.3-4.7,2.9-6.9,1.3c-0.2-0.1-0.3-0.2-0.4-0.3c-2.3-1.8-3.6-4.6-4.6-7.2s-2.1-5.3-3.4-7.8
	c-1.1-2.2-2.4-4.3-3.8-6.4c2.1,0.5,4.2,1.2,6.2,2c2.7,1.3,5.2,2.8,7.6,4.6C1006.5,504,1012,509.5,1007.8,514.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M1006.9,613.3c-0.8,3.8-2.9,7.8-6.9,8.8c-4.5,1.1-8.1-3.8-9.7-7.2c-1.9-4.1-2.3-8.7-3.4-13
	c-1.2-4.8-4.1-9.1-6.1-13.6c0-0.1,0-0.1-0.1-0.2C992.1,591.2,1009.8,598.7,1006.9,613.3z"/>
        <path :fill="colors[`${id}-p-4`]" d="M981.3,634.2c-1.7,0.9-3.7,1-5.6,0.4c-2.3-0.7-3.9-2.9-5.2-4.8c-2.7-4-5.9-7.7-8.8-11.7c2.7,0.2,5.3,0.3,8,0.7
	c2.9,0.5,5.6,1.5,8.1,3c2.5,1.3,4.4,3.5,5.4,6.1C984.1,630.4,983.7,632.9,981.3,634.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1014.5,561.9c-0.6,2.7-1.7,6.2-4,7.9c-3.1,2.3-6.2-1.9-7-4.5c-1-3.8-0.1-7.7,0.9-11.4
	c1.7-6.8,3.1-13.7,3.9-20.7c1.7,2.1,3.1,4.5,3.9,7.2c1.2,3.1,2.1,6.3,2.7,9.6C1015.5,553.9,1015.4,558,1014.5,561.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1014.6,696.8c-2.5,2.5-6.6,2.7-10,2.5c-5.9-0.4-11.6-1.8-17.4-0.9c3.6-4.2,7.8-7.9,12.5-10.8
	c5.1-3,13.2-5,16.6,1.4C1017.8,691.7,1016.6,694.8,1014.6,696.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1021.2,468.6c-3.4-2.4-7-4.5-10.7-6.4c-3-1.4-5.8-3.2-8.4-5.2c0.3,0.5,0.5,1,0.8,1.6c2,4.5,3.6,9.2,4.8,14
	c0.8,3.7,2,7.4,3.4,11c1.5,3.5,3.6,7.3,7.1,9.2c1.8,1,3.9,1.3,5.9,0.6v-22.5C1023.1,470.1,1022.2,469.3,1021.2,468.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M0,470.9v22.5c2-0.7,3.6-2.1,4.7-4C8.6,482.6,5.2,475.7,0,470.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M969.8,1001.1c-0.4-5.6-1.3-11.2-2.7-16.7c-1.2,3.6-3,6.9-5.2,10c-1,1.4-2.1,2.8-3.2,4.1
	c-2.1,2.5-4.3,4.9-6.5,7.1c-4.5,4.6-11.2,11.2-10.8,18.3h22.8c3.2-4.8,5.1-10.3,5.6-16c0-0.2,0-0.4,0-0.6
	C969.9,1005.3,969.9,1003.2,969.8,1001.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M941.3,0c0,0.6,0.1,1.2,0.2,1.8c0.8,3.6,3.4,7.2,7.4,7.5c3.5,0.2,6.9-1.4,9.6-3.4c2.2-1.6,4-3.6,5.5-5.9H941.3z
	"/>
        <path :fill="colors[`${id}-p-3`]" d="M243.4,813.6c-7.1,5.2-17,5-25.3,3.8c-2.2-0.3-4.4-0.3-6.5-0.8c-2.2-0.5-4.2-1.5-5.9-2.9c4-0.7,8-1.6,11.9-2.8
	c4-1.3,7.7-3.3,10.9-5.9c2.9-2.2,5.8-4.4,9.2-5.5s7.7-0.9,10.5,1.4C252.7,804.5,246.4,811.3,243.4,813.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M342.5,1016.4c-0.1-0.5-0.2-1-0.4-1.5c-0.9-2.3-2.4-4.4-4.3-6c-1.1-0.9-2.2-1.8-3.4-2.6c-0.7-0.4-1.3-0.8-2-1.2
	l0,0c-3-1.8-6.1-3.2-9.3-4.8c-2.6-1.3-5.1-2.9-7.4-4.7c-1.1-0.8-2.1-1.7-3.1-2.6c0.1,0.5,0.1,1.1,0.2,1.6s0.1,0.9,0.1,1.3
	c0.1,0.6,0.1,1.2,0.2,1.9c0.3,3.6,0.7,7.4,1.3,11.2c0.1,0.8,0.3,1.6,0.5,2.3l0,0c0.5,2.4,1.3,4.8,2.2,7.1c0.7,1.6,1.5,3,2.5,4.4
	c0.3,0.4,0.6,0.8,0.9,1.2h20.4c1-1.5,1.6-3.2,1.8-5C342.8,1018.1,342.7,1017.2,342.5,1016.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M320.5,0c2.2,2.7,5.2,4.8,8.7,5.2c3.9,0.4,7.7-0.9,10.5-3.6c0.5-0.5,0.9-1,1.3-1.6H320.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M109.8,1000.9c-1.2-4.8-4.1-8.9-7.1-12.7c-3.3-4.1-6.3-8.5-8.9-13.1c-1.3-2.3-2.5-4.8-3.5-7.2
	c-0.1,1.5-0.3,3.1-0.5,4.6c-0.3,2.5-0.6,5-0.9,7.5c-0.6,5.2-0.8,10.4-1.3,15.6c-0.3,2.6-0.4,5.1-0.3,7.7c-0.1,2.3,0.3,4.6,1.3,6.7
	c3.3,5.5,10.4,7.3,15.9,4c0.3-0.2,0.6-0.4,0.9-0.6C109.1,1010.4,110.9,1005.6,109.8,1000.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M241.2,994.1c-0.3-7.1-4-16.2-10.5-20c-0.4-0.2-0.8-0.4-1.2-0.6c0.2,1.1,0.3,2.3,0.2,3.5c0,2.7-0.2,5.4-0.4,8
	c0,0.8,0,1.6-0.2,2.3c-0.6,1.9-1.3,3.6-2.2,5.3c-0.2,0.4-0.4,0.8-0.6,1.2c-1.3,2.1-2.4,4.2-3.4,6.4c-0.6,1.3-1,2.7-1.2,4.1
	c-0.6,3.7,0.5,9.5,5,10.2c0.7,0.1,1.4,0.1,2.1,0c0.8-0.1,1.6-0.4,2.4-0.8c1.2-0.6,2.2-1.4,3.1-2.3c1.1-1.1,2.1-2.3,3-3.6
	C240,1003.9,241.4,999,241.2,994.1z"/>
        <path :fill="colors[`${id}-p-3`]" d="M1018.8,68.4c-7.4,0-14.5,3.4-20.6,7.4c2,0.2,4,0.6,5.9,1.5c3.7,1.6,6.5,4.4,9.6,7c2.9,2.3,6.6,3.5,10.4,3.2
	v-18C1022.3,68.8,1020.5,68.5,1018.8,68.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M0,69.5v18c2.6-0.2,4.9-1.6,6.3-3.7C10.2,77.4,5.8,71.7,0,69.5z"/>
        <path :fill="colors[`${id}-p-2`]" d="M144.4,879.8c-6.3,0.7-11-4.4-14.1-9.2c-4.8-7.7-7-16.6-6.4-25.6c3.3,5,8,9,13.4,11.5
	c5.4,2.7,12.1,5.2,14.5,11.3c1.8,4.5-0.4,9.7-4.9,11.5C146.1,879.5,145.3,879.7,144.4,879.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M211.1,805.8c-4.1,3.5-10.5,4.5-15.6,3.4c-9.4-2.1-16.5-11.4-17.2-20.8c3.2,3.2,7.5,4.9,12,4.8
	c5.6,0,11.5-2,17-0.4c3,1,5.4,3.4,6.3,6.5C214.2,801.9,212.9,804.2,211.1,805.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M151.1,721.8c-4.3,3.9-11,1.9-14.5-1.9c-2.8-3-3.9-7.2-4.3-11.2c-0.5-5.7-0.1-12.1,2.2-17.3
	c0.4-0.8,0.8-1.6,1.3-2.3c0,1.5,0.4,3,1.1,4.3c1.1,1.8,2.4,3.4,4,4.8c1.9,1.8,3.9,3.6,5.7,5.5C150.6,708,157,716.5,151.1,721.8z"/>
        <path :fill="colors[`${id}-p-1`]" d="M657.9,145.3c-2.9,0.2-5.7-1-7.5-3.2c-2.1-2.4-2.7-5.4-2.9-8.4c-0.5-8.1,3-17.5,9.4-22.8
	c-1.6,2.6-1.7,5.8-0.7,9.1c1.5,5.1,5.5,8.5,8.4,12.7c1.5,2.2,2.6,4.8,1.5,7.4C664.9,143,661.1,144.9,657.9,145.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M753.2,97.4c-3-2-6.3-3.7-8.6-6.6s-3.2-8.1,0.2-10.6c3.2-2.2,7.4-0.4,10.2,1.5c3.1,2.3,5.5,5.3,7.1,8.8
	c3.2,6.7,3.6,14.7,2.2,21.9c-0.1,0.5-0.2,1.1-0.4,1.6c-0.6-3-1.6-5.8-3-8.5C759,102.2,756.4,99.4,753.2,97.4z"/>
        <path :fill="colors[`${id}-p-2`]" d="M765.7,502c-0.6,2.4-1.9,4.5-3.7,6.2c-2.2,1.7-5,2.3-7.7,1.6c-6.7-1.4-8.4-9.1-9.4-14.9
	c-0.6-3.5-1.2-6.9-2.8-10.1c-1.6-3.2-4.2-5.9-7.3-7.6c10-0.6,21.5,1.1,27.7,9.8C765.7,491.3,766.9,496.8,765.7,502z"/>
        <path :fill="colors[`${id}-p-2`]" d="M706.5,473.3c-1.6,0.1-3.1,0-4.7-0.1c4.4-2.7,7.8-6.6,10-11.2c2-4.1,4.3-10.9,10.2-9.3c2.8,0.6,5.1,2.8,5.8,5.6
	c0.6,2.7-0.7,5.4-2.5,7.4C720.5,470.7,713.1,472.9,706.5,473.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M485.3,156.9c-3.6-3.9-8.5-6.2-12.3-9.9c-3.6-3.5-6.5-10.1-2.4-14.3c3.4-3.5,9.6-3,13.5-0.6
	c6.2,3.8,8.3,11.4,8.6,18.2c0.2,6.5-0.8,12.9-3,19.1C490,164.7,488.4,160.2,485.3,156.9z"/>
        <path :fill="colors[`${id}-p-4`]" d="M456.4,127.6c-3.4-2.5-7.5-3.7-11-6c-3.3-2.2-6.5-7-3.8-10.8c2.2-3.2,7.1-3.7,10.4-2.4
	c5.4,2.1,8.1,7.7,9.2,13.1c1.1,5,1.2,10.2,0.3,15.3C461.2,133.1,459.3,129.8,456.4,127.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M424.2,123.3c-4,1.2-7.5,3.8-11.4,5.1c-3.8,1.3-9.5,1-11-3.5c-1.2-3.7,1.4-7.9,4.4-9.8c4.9-3.1,11-1.8,16,0.5
	c4.7,2.2,8.9,5.2,12.4,8.9C431.4,122.8,427.6,122.3,424.2,123.3z"/>
        <path :fill="colors[`${id}-p-3`]" d="M516.5,147.8c-3.1-0.4-6.4-0.5-9.2-2.1c-2.8-1.5-3.9-5.1-2.3-7.9c0,0,0-0.1,0-0.1c2.9-5.4,9.8-4.1,13.9-1
	c4.3,3.2,7.2,8.3,8.9,13.3c0.5,1.6,0.9,3.2,1.1,4.8C526.8,150.2,521.2,148.4,516.5,147.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M434.8,197c-0.8,2.4-3.3,4.3-5.4,5.6c-4.1,2.6-10.1,4.6-14.9,2.8c1.5-0.4,3-1,4.4-1.7c2.1-1.3,3.8-3.3,4.8-5.6
	c0.9-2.2,1.9-4.4,3.1-6.4c1.5-2.2,4.7-3.4,6.9-1.4C435.5,191.9,435.5,194.9,434.8,197z"/>
        <path :fill="colors[`${id}-p-4`]" d="M392,116.4c-1.2,5.3-1.5,11-4.7,15.6s-10.8,6.8-15.1,2.5c-4.1-4.1-2.7-11-0.2-15.5c2.7-4.8,7.2-8.4,12-11.1
	c4.8-2.6,9.9-4.5,15.2-5.7C395.1,106,393.2,111.1,392,116.4z"/>
        <path :fill="colors[`${id}-p-4`]" d="M405.1,151.2c0.1,3.1,1.5,6.1,2.3,9.1c1,3.6,0.6,7-1.9,9.8c-1.8,2-4.6,2.8-7.2,2.1c-2.8-0.9-4.9-3.1-5.7-5.8
	c-2-6,0-12.2,3.4-17.2c4.1-5.8,9.9-10.1,16.6-12.3C408.4,140.5,404.8,145.6,405.1,151.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M420.6,178.2c-3.1,2-6.5,3.3-10.1,3.8c-3.9,0.6-7.6-0.5-11.1-2c3.2-0.1,6.4-3.5,8.3-5.8c3-3.6,4.1-8.2,7.1-11.9
	c1.6-2.1,4.2-3.2,6.9-2.8c2.6,0.4,4.2,2.6,5,4.9C428.3,170.1,425.1,175.2,420.6,178.2z"/>
        <path :fill="colors[`${id}-p-4`]" d="M362.6,187.7c-5,0.3-9.2-3.8-11-8.1c-1.3-3.6-1.9-7.5-1.6-11.4c0.3-9.1,2.7-19.1,8.1-26.6
	c1.9-2.8,4.6-5,7.8-6.2c-2.1,2.4-2.9,5.8-2.9,9.2c0.1,5,1.2,9.9,3.2,14.5c2.2,5.4,5.6,10.6,5.9,16.6
	C372.4,181.2,368.5,187.3,362.6,187.7z"/>
        <path :fill="colors[`${id}-p-4`]" d="M379,219.8c-7.5,0.6-14.2-4-18-10.1c-3.7-5.9-5.5-12.8-7.6-19.3c5.8,3.5,11.4,7.4,18,9.1
	c4.7,1.2,9.5,0.5,14.2,1.6c2.3,0.5,4.3,1.8,5.6,3.6c1.5,2.3,1.7,5.2,0.6,7.7C389.7,217.1,383.8,219.4,379,219.8z"/>
        <path :fill="colors[`${id}-p-4`]" d="M435.2,83.7c-5.4-1.3-12-1.4-14.9-7c-1.5-2.4-0.9-5.5,1.2-7.2c2.2-1.6,4.9-2.3,7.5-1.8c6.1,0.9,9.9,7.1,12.7,12
	c2.3,4.1,4.6,8.3,5.6,13C444.7,88.1,440.3,84.8,435.2,83.7z"/>
        <path :fill="colors[`${id}-p-2`]" d="M399.6,91.2c-4.6,3.8-9.4,8.8-15.8,8.5c-5.6-0.3-8.4-5.5-7-10.6c1.5-5.3,7.1-8.1,12.1-9.2c4.9-1,10-1,14.9,0
	c4.7,0.8,9.2,2.1,13.6,4c-3.4,0.5-6.7,1.4-10,2.6C404.6,87.6,402,89.2,399.6,91.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M498.7,74.1c-2.2,6.2-6,12.7-11.9,16c-0.3-7.2,0.1-14.4-2.2-21.3c-1.6-4.9-4.9-9.1-6.3-14.2
	c-0.8-2.4-0.7-5,0.3-7.2c1.5-2.8,5-4.2,8-4.4c6.6-0.4,12.1,5.5,13.8,11.4C502.3,60.7,500.8,68.1,498.7,74.1z"/>
        <path :fill="colors[`${id}-p-1`]" d="M273.1,816.2c-2.2,0.8-4.4,1.5-6.6,2.1c3.6-4.8,6.2-10.4,7.5-16.3c1.2-5.6,1-11.3,1.3-17
	c0.3-5.2,1.2-10.7,4.2-15.1c3.4-5.1,10.2-6.8,15.5-3.8c5.3,3.1,7.8,9.4,8.1,15.3c0.3,5.5-1.1,10.9-4,15.5
	C293.4,806.3,283.1,812.5,273.1,816.2z"/>
        <path :fill="colors[`${id}-p-2`]" d="M217.7,855.9c-2.2,4.4-6.3,7.4-11.2,8.2c-10.2,2-20.6-3.6-26.7-11.4c-5.8-7.3-8.4-16.8-9.9-25.8
	c-1.4-8.2-1.8-16.5-2.5-24.7c2.5,4.3,5.7,8.2,9.4,11.6c4.3,3.8,9.1,7,14.3,9.5c9.1,4.7,20.4,7.7,26.1,17
	C219.9,845.1,220.1,850.9,217.7,855.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M350.2,141.7c-1.8,4.8-4.2,10.3-8.7,13.2c-2.3,1.5-5.1,1.7-7.6,0.5c-1.9-1.1-3.1-3.1-3.2-5.3
	c-0.5-5.1,2.6-10,5.6-13.9c5.2-6.8,12.6-11.6,21-13.5C353.5,128.5,352.5,135.3,350.2,141.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M342.5,183.3c-2.3,1.6-5.1,2-7.7,1.1c-6-1.9-9-8.3-9.9-14.1s0-11.5,0.5-17.2c2.1,5,5.3,9.3,9.5,12.8
	c2.1,1.6,4.3,3,6.4,4.5s4,3.2,4.7,5.7C346.7,178.8,344.6,181.7,342.5,183.3z"/>
        <path :fill="colors[`${id}-p-1`]" d="M353.7,213.8c-1.2,2.5-3.5,4.3-6.2,4.8c-4.5,1-9.2-0.8-12.5-3.9c-4.5-4.2-6.9-10.5-8.5-16.4
	c-1.4-5.3-2.4-10.8-2.9-16.3c3.7,5.2,9,8.8,14.3,12.1c5.1,3.3,11.8,5.8,15.1,11.3C354.5,208,354.8,211.1,353.7,213.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M448.5,261.5c-5.3,9.1-15.7,13.1-25.6,15c3-2.3,4.9-5.8,6.4-9.3c2-4.8,3.1-9.9,4.7-14.9
	c1.5-4.6,5.7-12.4,11.8-9.4c3.2,1.6,5.1,5.6,5.4,9C451.3,255.3,450.4,258.7,448.5,261.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M414,270c-4.9,4.4-10.9,7.4-17.4,8.5c2-4.2,3.5-8.6,4.6-13.1c1.1-4.5,1.6-9.3,3.2-13.7c0.7-2.2,1.9-4.1,3.6-5.7
	c1.7-1.5,4.1-2.1,6.3-1.8c4,0.5,6.5,4.3,7.3,8C423.1,258.9,418.7,265.6,414,270z"/>
        <path :fill="colors[`${id}-p-3`]" d="M461.7,259c-0.2-3.2-1.1-6.4-2.8-9.2c-3-5.6-8.1-10.1-9.6-16.4c-1.3-5.4,1.5-11.9,7.8-11.7
	c5.5,0.2,9.1,5.4,10.7,10c2.1,6.1,1.6,12.9,0.1,19c-1.3,5.5-3.8,10.7-7.5,15C461.3,263.5,461.7,261.3,461.7,259z"/>
        <path :fill="colors[`${id}-p-3`]" d="M384.2,279.5c-4.1,2.1-10.1,3.8-14.4,1.3c4-2.6,6.4-7.2,9.6-10.6c2-2.1,4.3-4.1,7.2-4.7c2.7-0.5,5.8,1,5.9,3.9
	C392.8,274.1,387.9,277.6,384.2,279.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M408.6,290.2c-0.5,2.1-3.1,3.4-5,4c-2.3,0.7-4.7,0.8-7,0.4c-4.5-0.6-8.8-2.4-12.5-5.1c2-0.4,4-0.8,6-1.4
	c2.5-0.8,4.9-1.8,7.3-2.8c2.1-0.9,4.5-1.6,6.8-1S409.3,287.6,408.6,290.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M555.8,0h-13c1.3,1.4,2.8,2.7,4,3.9c3.9,3.9,6.3,9.1,6.7,14.6c1.4-3.9,2.3-8.1,2.5-12.2
	C556.1,4.2,556,2.1,555.8,0z"/>
        <path :fill="colors[`${id}-p-3`]" d="M551.6,1013.7c-1.6-1.9-3.9-3.2-6.4-3.4c-2.6-0.2-4.9,1.4-5.7,3.9c-1.3,4,0.7,7.1,3.3,9.8h13
	C555.4,1020.2,554,1016.7,551.6,1013.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M591.7,0c0,0.3,0.1,0.6,0.1,0.9c0.4,3.3,0.7,6.7,0.8,10.1c0,1.1,0,2.3-0.1,3.4c2.3-4.6,6.1-8.2,8.4-12.8
	c0.3-0.5,0.5-1,0.7-1.6L591.7,0z"/>
        <path :fill="colors[`${id}-p-3`]" d="M632.2,0.8c-0.1-0.2-0.3-0.5-0.4-0.8h-14.3c-2.6,5.2-1,12.2-0.8,17.6c0.3,5.8-0.6,11.7-2.9,17.1
	c5.4-6.5,12.4-11.6,16.7-19C633.3,11.2,634.8,5.8,632.2,0.8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M653,0c-0.2,1-0.3,2-0.3,3c0,6.1,1.5,12.2,4.2,17.6c2.2,4.9,5.3,9.2,9.3,12.8c-1.9-4.9-0.5-10.8,0.9-15.7
	c0.8-3,1.8-6,2.8-9C671,6,671.7,3,672,0H653z"/>
        <path :fill="colors[`${id}-p-3`]" d="M602.5,1017.6c-0.5-2.5-1.7-5-4.3-5.9c-2.4-0.8-5,0.5-5.9,2.8c-1.3,3-0.9,6.3-0.5,9.4h10
	C602.6,1022,602.8,1019.8,602.5,1017.6z"/>
        <path :fill="colors[`${id}-p-3`]" d="M626.5,1019.5c-2.4-0.7-5.1-0.1-6.9,1.7c-0.8,0.8-1.5,1.8-2.1,2.8h14.3C630.7,1021.8,628.8,1020.2,626.5,1019.5
	z"/>
        <path :fill="colors[`${id}-p-3`]" d="M672,1022c-0.3-3.3-1.9-7.3-5-8.9c-2.3-1.1-4.9-0.9-7,0.5c-3.8,2.3-6.3,6.1-7,10.4h19
	C672,1023.3,672,1022.7,672,1022z"/>
        <path :fill="colors[`${id}-p-3`]" d="M433.2,1014c-1.4-2.3-4-4.1-6.8-3.5c-6.2,1.3-7.1,8-7.4,13.5h14.4c0.2-0.5,0.3-0.9,0.4-1.4
	C434.6,1019.8,434.8,1016.6,433.2,1014z"/>
        <path :fill="colors[`${id}-p-3`]" d="M419,0c-0.1,1-0.1,2-0.1,2.9c-0.4,6.2-1.3,12.8-4.2,18.4c3.2-2.7,6.4-5.3,9.4-8.2c3.8-3.7,7.6-8,9.4-13.1L419,0
	z"/>
        <path :fill="colors[`${id}-p-3`]" d="M754.8,1018.4c-1.6-5-2.4-10.2-5.1-14.9c-2.6-4.5-6.6-8.7-12-9.2c-5.7-0.5-10.6,3.1-13,8.1
	c-2.3,4.7-2.8,10.4-0.5,15.2c1.2,2.3,2.6,4.5,4.4,6.4h28.5C756.2,1022.2,755.5,1020.3,754.8,1018.4z"/>
        <path :fill="colors[`${id}-p-3`]" d="M763.9,8c-2.9-2.1-5.2-4.8-6.8-8h-28.5c2,2.2,4.4,4.1,7,5.6c9.2,5.4,20.8,6.6,31.1,4
	C765.8,9.2,764.8,8.6,763.9,8z"/>
        <path :fill="colors[`${id}-p-3`]" d="M801.2,0h-18c-2.2,4.6-2.7,9.5-3.2,14.4c-0.6,6.7-2.3,13.3-4.9,19.6c6.6-3.7,13-8.1,18-13.8
	c3.9-4.4,7.5-10,8.1-16C801.4,2.8,801.4,1.4,801.2,0z"/>
        <path :fill="colors[`${id}-p-3`]" d="M800.4,1021.7c-1.2-2.1-3.2-3.6-5.6-4.1c-2.5-0.5-5-0.6-7.2,0.8c-1.9,1.3-3.3,3.1-4.3,5.2l-0.2,0.4h18
	C801,1023.2,800.8,1022.4,800.4,1021.7z"/>
        <path :fill="colors[`${id}-p-3`]" d="M43.5,9.5c0-3.1-0.2-6.3-0.2-9.5H33.1c-0.2,0.1-0.3,0.3-0.4,0.4c-5,5.1-10.2,11.4-10.9,18.8
	c-0.5,5.6,2.2,12.2,7.9,14.2c2.7,0.9,6.5,0.9,8.9-0.9c2.2-1.8,3.8-4.2,4.5-7C44.3,20.2,43.5,14.8,43.5,9.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M33.1,1024h10.2c0-3.3,0.3-6.5,1.2-9.7C40.5,1017.3,36.7,1020.5,33.1,1024z"/>
        <path :fill="colors[`${id}-p-2`]" d="M1022.9,190.8c-5,3.6-8,9.3-10,15s-3.2,11.5-5.6,17.1c-1.9,4.4-4.5,8.5-7.8,12.1c8.7-2.2,17.2-5.4,24.5-10.6
	v-34.3C1023.6,190.3,1023.2,190.6,1022.9,190.8z"/>
        <path :fill="colors[`${id}-p-2`]" d="M13.4,190.5c-3.9-3.5-9.2-2.9-13.4-0.4v34.3c2.2-1.6,4.3-3.3,6.2-5.2c4.1-4,7.8-8.7,9.6-14.2
	C17.3,200.2,17.5,194.1,13.4,190.5z"/>
        <path :fill="colors[`${id}-p-3`]" d="M178.9,5.9c-1.6-2.1-3.5-4.1-5.5-5.9h-19.4c0.7,6.2,2.3,12.5,5.8,17.6c2.2,3.2,5.3,6.1,9.2,6.8
	c4.5,0.7,9.7-1.1,11.9-5.3S181.6,9.6,178.9,5.9z"/>
        <path :fill="colors[`${id}-p-3`]" d="M203.8,0h-6.9c-0.7,3-1.7,6-2.5,8.7c-1.2,3.9-2.8,7.7-4.2,11.6c-1.5,4.1-2.6,8.5-1,12.8c0.8,2.2,2.3,4.4,4.7,5
	c2.1,0.4,4.2,0.1,6-1c4.8-2.6,6.9-7.6,7.8-12.8C209,16.2,207.2,7.7,203.8,0z"/>
        <path :fill="colors[`${id}-p-3`]" d="M169,1020.2c-3.7-3-7.3-6.1-10.1-10c-2.2-3.1-3.8-6.6-4.7-10.2c-0.7,5.9-0.9,11.8-0.6,17.7
	c0.1,2.1,0.3,4.2,0.5,6.3h19.4C172,1022.7,170.5,1021.5,169,1020.2z"/>
        <path :fill="colors[`${id}-p-3`]" d="M196.9,1012.3c1.3,3.7,1,7.7,0.1,11.7h6.9C201.9,1019.9,199.6,1015.9,196.9,1012.3z"/>
        <path :fill="colors[`${id}-p-2`]" d="M259.1,0h-11.6c0.1,2.7,0.4,5.5,0.9,8.2c1.1,5.8,3.7,12.3,9.1,15.4c2.1,1.2,4.8,1.9,7,0.5
	c2.7-1.8,4.3-5.5,4.2-8.7C268.6,8.8,263.5,4.1,259.1,0z"/>
        <path :fill="colors[`${id}-p-2`]" d="M258.6,1023.6c-4.3-4-9-8.2-11.6-13.7c0.3,4.7,0.2,9.4,0.5,14.1h11.6L258.6,1023.6z"/>
        <path :fill="colors[`${id}-p-4`]" d="M279.7,727c1.6-3.5,2.3-7.4,1.9-11.3c-0.5-4.9-2.8-8.9-4.8-13.3c-1.6-3.7-4-9.7,0.8-12s8.8,0.9,11.2,5
	c1.2,2.2,2.1,4.6,2.5,7.1c0.5,2.6,0.3,5.3-0.5,7.8C288.6,716.8,284.8,722.5,279.7,727z"/>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern],
}
</script>