<template>
    <pattern :id="id" x="0" y="0" width="2300" height="3287.6" viewBox="0 0 2300 3287.6" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect y="-0.3" :fill="colors[`${id}-p-0`]" width="2300" height="1080.2"/>
        <rect y="1080.4" :fill="colors[`${id}-p-1`]" width="2300" height="2207.4"/>
        <rect x="18.7" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -744.7323 361.4416)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="146.6" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -707.3186 451.8664)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="274.4" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -669.8373 542.2441)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="402.3" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -632.3632 632.6689)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="530.2" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -594.9495 723.0936)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="657.9" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -557.54 813.442)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="785.8" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -520.066 903.8668)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="913.7" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -482.5921 994.2917)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="1041.5" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -445.1711 1084.6694)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <g>
            <polygon :fill="colors[`${id}-p-2`]" points="43,866.9 44.4,868.2 44.4,907.4 43,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="13.9,837.8 15.2,839.2 15.2,936.5 13.9,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="83.5,868.2 84.9,866.9 84.9,908.7 83.5,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="170.8,866.9 172.2,868.2 172.2,907.4 170.8,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="141.8,837.8 143.1,839.2 143.1,936.5 141.8,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="112.6,839.2 113.9,837.8 113.9,937.9 112.6,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="89.1,1118.4 67.9,1139.6 67.9,1019.7 76.9,1028.7 76.9,1002.7 74.5,1005.1 74.5,898.4 82.5,906.4
		82.5,997.1 91.5,988.2 91.5,1043.2 89.1,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="9.7,1070 7.3,1072.4 7.3,959 16.2,968.1 16.2,935.5 24.2,927.6 24.2,976 21.8,973.6 21.8,1057.8
		30.9,1048.8 30.9,1110.5 9.7,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="38.8,1040.8 36.4,1043.2 36.4,988.2 45.4,997.1 45.4,906.4 53.3,898.4 53.3,1005.1 51,1002.7
		51,1028.7 59.9,1019.7 59.9,1139.6 38.8,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="118.2,1070 118.2,1089.3 97,1110.5 97,1048.8 106,1057.8 106,1057.8 106,973.6 103.6,976 103.6,927.6
		111.6,935.5 111.6,968.1 120.5,959 120.5,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="211.3,868.2 212.7,866.9 212.7,908.7 211.3,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="298.7,866.9 300.1,868.2 300.1,907.4 298.7,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="269.7,837.8 270.9,839.2 270.9,936.5 269.7,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="240.5,839.2 241.8,837.8 241.8,937.9 240.5,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="216.9,1118.4 195.8,1139.6 195.8,1019.7 204.7,1028.7 204.7,1002.7 202.4,1005.1 202.4,898.4
		210.3,906.4 210.3,997.1 219.4,988.2 219.4,1043.2 216.9,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="137.5,1070 135.2,1072.4 135.2,959 144.1,968.1 144.1,935.5 152.1,927.6 152.1,976 149.7,973.6
		149.7,1057.8 158.7,1048.8 158.7,1110.5 137.5,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="166.6,1040.8 164.2,1043.2 164.2,988.2 173.3,997.1 173.3,906.4 181.2,898.4 181.2,1005.1
		178.9,1002.7 178.9,1028.7 187.8,1019.7 187.8,1139.6 166.6,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="246.1,1070 246.1,1089.3 224.8,1110.5 224.8,1048.8 233.9,1057.8 233.9,1057.8 233.9,973.6 231.5,976
		231.5,927.6 239.5,935.5 239.5,968.1 248.4,959 248.4,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="339.2,868.2 340.6,866.9 340.6,908.7 339.2,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="426.6,866.9 427.9,868.2 427.9,907.4 426.6,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="397.4,837.8 398.8,839.2 398.8,936.5 397.4,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="368.4,839.2 369.7,837.8 369.7,937.9 368.4,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="344.8,1118.4 323.7,1139.6 323.7,1019.7 332.6,1028.7 332.6,1002.7 330.2,1005.1 330.2,898.4
		338.2,906.4 338.2,997.1 347.1,988.2 347.1,1043.2 344.8,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="265.3,1070 263,1072.4 263,959 272,968.1 272,935.5 280,927.6 280,976 277.6,973.6 277.6,1057.8
		286.6,1048.8 286.6,1110.5 265.3,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="294.5,1040.8 292.1,1043.2 292.1,988.2 301.1,997.1 301.1,906.4 309,898.4 309,1005.1 306.6,1002.7
		306.6,1028.7 315.7,1019.7 315.7,1139.6 294.5,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="373.9,1070 373.9,1089.3 352.7,1110.5 352.7,1048.8 361.8,1057.8 361.8,1057.8 361.8,973.6 359.3,976
		359.3,927.6 367.2,935.5 367.2,968.1 376.3,959 376.3,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="467.1,868.2 468.4,866.9 468.4,908.7 467.1,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="554.5,866.9 555.7,868.2 555.7,907.4 554.5,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="525.3,837.8 526.7,839.2 526.7,936.5 525.3,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="496.2,839.2 497.5,837.8 497.5,937.9 496.2,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="472.7,1118.4 451.4,1139.6 451.4,1019.7 460.5,1028.7 460.5,1002.7 458.1,1005.1 458.1,898.4
		466.1,906.4 466.1,997.1 475,988.2 475,1043.2 472.7,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="393.2,1070 390.8,1072.4 390.8,959 399.8,968.1 399.8,935.5 407.7,927.6 407.7,976 405.4,973.6
		405.4,1057.8 414.4,1048.8 414.4,1110.5 393.2,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="422.4,1040.8 420,1043.2 420,988.2 429,997.1 429,906.4 436.9,898.4 436.9,1005.1 434.5,1002.7
		434.5,1028.7 443.5,1019.7 443.5,1139.6 422.4,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="501.7,1070 501.7,1089.3 480.6,1110.5 480.6,1048.8 489.6,1057.8 489.6,1057.8 489.6,973.6 487.2,976
		487.2,927.6 495.1,935.5 495.1,968.1 504.1,959 504.1,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="595,868.2 596.2,866.9 596.2,908.7 595,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="682.3,866.9 683.6,868.2 683.6,907.4 682.3,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="653.2,837.8 654.6,839.2 654.6,936.5 653.2,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="624,839.2 625.4,837.8 625.4,937.9 624,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="600.5,1118.4 579.3,1139.6 579.3,1019.7 588.3,1028.7 588.3,1002.7 585.9,1005.1 585.9,898.4
		593.9,906.4 593.9,997.1 602.9,988.2 602.9,1043.2 600.5,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="521.1,1070 518.7,1072.4 518.7,959 527.7,968.1 527.7,935.5 535.6,927.6 535.6,976 533.3,973.6
		533.3,1057.8 542.2,1048.8 542.2,1110.5 521.1,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="550.2,1040.8 547.8,1043.2 547.8,988.2 556.9,997.1 556.9,906.4 564.8,898.4 564.8,1005.1
		562.4,1002.7 562.4,1028.7 571.4,1019.7 571.4,1139.6 550.2,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="629.6,1070 629.6,1089.3 608.5,1110.5 608.5,1048.8 617.5,1057.8 617.5,1057.8 617.5,973.6 615.1,976
		615.1,927.6 623,935.5 623,968.1 632,959 632,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="722.8,868.2 724.1,866.9 724.1,908.7 722.8,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="810.2,866.9 811.5,868.2 811.5,907.4 810.2,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="781,837.8 782.3,839.2 782.3,936.5 781,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="751.9,839.2 753.3,837.8 753.3,937.9 751.9,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="728.3,1118.4 707.2,1139.6 707.2,1019.7 716.2,1028.7 716.2,1002.7 713.8,1005.1 713.8,898.4
		721.7,906.4 721.7,997.1 730.7,988.2 730.7,1043.2 728.3,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="649,1070 646.5,1072.4 646.5,959 655.6,968.1 655.6,935.5 663.5,927.6 663.5,976 661.1,973.6
		661.1,1057.8 670.1,1048.8 670.1,1110.5 649,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="678,1040.8 675.7,1043.2 675.7,988.2 684.6,997.1 684.6,906.4 692.6,898.4 692.6,1005.1 690.2,1002.7
		690.2,1028.7 699.3,1019.7 699.3,1139.6 678,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="757.5,1070 757.5,1089.3 736.3,1110.5 736.3,1048.8 745.3,1057.8 745.3,1057.8 745.3,973.6 742.9,976
		742.9,927.6 750.9,935.5 750.9,968.1 759.9,959 759.9,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="850.7,868.2 852,866.9 852,908.7 850.7,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="938,866.9 939.4,868.2 939.4,907.4 938,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="908.9,837.8 910.2,839.2 910.2,936.5 908.9,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="879.8,839.2 881.1,837.8 881.1,937.9 879.8,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="856.2,1118.4 835,1139.6 835,1019.7 844.1,1028.7 844.1,1002.7 841.7,1005.1 841.7,898.4 849.6,906.4
		849.6,997.1 858.6,988.2 858.6,1043.2 856.2,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="776.8,1070 774.4,1072.4 774.4,959 783.5,968.1 783.5,935.5 791.4,927.6 791.4,976 788.9,973.6
		788.9,1057.8 798,1048.8 798,1110.5 776.8,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="805.9,1040.8 803.6,1043.2 803.6,988.2 812.5,997.1 812.5,906.4 820.5,898.4 820.5,1005.1
		818.1,1002.7 818.1,1028.7 827.1,1019.7 827.1,1139.6 805.9,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="885.3,1070 885.3,1089.3 864.2,1110.5 864.2,1048.8 873.1,1057.8 873.1,1057.8 873.1,973.6 870.8,976
		870.8,927.6 878.7,935.5 878.7,968.1 887.8,959 887.8,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="978.5,868.2 979.9,866.9 979.9,908.7 978.5,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1065.8,866.9 1067.2,868.2 1067.2,907.4 1065.8,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1036.8,837.8 1038.1,839.2 1038.1,936.5 1036.8,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1007.6,839.2 1008.9,837.8 1008.9,937.9 1007.6,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="984.1,1118.4 962.9,1139.6 962.9,1019.7 971.9,1028.7 971.9,1002.7 969.5,1005.1 969.5,898.4
		977.4,906.4 977.4,997.1 986.5,988.2 986.5,1043.2 984.1,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="904.7,1070 902.3,1072.4 902.3,959 911.3,968.1 911.3,935.5 919.2,927.6 919.2,976 916.8,973.6
		916.8,1057.8 925.8,1048.8 925.8,1110.5 904.7,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="933.8,1040.8 931.4,1043.2 931.4,988.2 940.4,997.1 940.4,906.4 948.4,898.4 948.4,1005.1 946,1002.7
		946,1028.7 955,1019.7 955,1139.6 933.8,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1013.2,1070 1013.2,1089.3 992,1110.5 992,1048.8 1001,1057.8 1001,1057.8 1001,973.6 998.6,976
		998.6,927.6 1006.6,935.5 1006.6,968.1 1015.5,959 1015.5,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1106.3,868.2 1107.7,866.9 1107.7,908.7 1106.3,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1135.5,839.2 1136.8,837.8 1136.8,937.9 1135.5,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1111.9,1118.4 1090.8,1139.6 1090.8,1019.7 1099.7,1028.7 1099.7,1002.7 1097.4,1005.1 1097.4,898.4
		1105.3,906.4 1105.3,997.1 1114.3,988.2 1114.3,1043.2 1111.9,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1032.5,1070 1030.2,1072.4 1030.2,959 1039.1,968.1 1039.1,935.5 1047.1,927.6 1047.1,976
		1044.7,973.6 1044.7,1057.8 1053.7,1048.8 1053.7,1110.5 1032.5,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1061.6,1040.8 1059.2,1043.2 1059.2,988.2 1068.2,997.1 1068.2,906.4 1076.2,898.4 1076.2,1005.1
		1073.8,1002.7 1073.8,1028.7 1082.8,1019.7 1082.8,1139.6 1061.6,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1141.1,1070 1141.1,1089.3 1119.8,1110.5 1119.8,1048.8 1128.9,1057.8 1128.9,1057.8 1128.9,973.6
		1126.5,976 1126.5,927.6 1134.5,935.5 1134.5,968.1 1143.4,959 1143.4,1072.4 	"/>
        </g>
        <g>
            <g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="43,1292.3 44.4,1291 44.4,1251.8 43,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="13.9,1321.4 15.2,1320.1 15.2,1222.7 13.9,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="83.5,1291 84.9,1292.3 84.9,1250.5 83.5,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="170.8,1292.3 172.2,1291 172.2,1251.8 170.8,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="141.8,1321.4 143.1,1320.1 143.1,1222.7 141.8,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="112.6,1320.1 113.9,1321.4 113.9,1221.4 112.6,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="89.1,1040.8 67.9,1019.7 67.9,1139.6 76.9,1130.6 76.9,1156.6 74.5,1154.2 74.5,1260.9 82.5,1252.9
				82.5,1162.1 91.5,1171.1 91.5,1116.1 89.1,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="9.7,1089.3 7.3,1086.9 7.3,1200.3 16.2,1191.2 16.2,1223.8 24.2,1231.7 24.2,1183.3 21.8,1185.6
				21.8,1101.4 30.9,1110.5 30.9,1048.8 9.7,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="38.8,1118.4 36.4,1116.1 36.4,1171.1 45.4,1162.1 45.4,1252.9 53.3,1260.9 53.3,1154.2 51,1156.6
				51,1130.6 59.9,1139.6 59.9,1019.7 38.8,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="118.2,1089.3 118.2,1070 97,1048.8 97,1110.5 106,1101.4 106,1101.4 106,1185.6 103.6,1183.3
				103.6,1231.7 111.6,1223.8 111.6,1191.2 120.5,1200.3 120.5,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="211.3,1291 212.7,1292.3 212.7,1250.5 211.3,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="298.7,1292.3 300.1,1291 300.1,1251.8 298.7,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="269.7,1321.4 270.9,1320.1 270.9,1222.7 269.7,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="240.5,1320.1 241.8,1321.4 241.8,1221.4 240.5,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="216.9,1040.8 195.8,1019.7 195.8,1139.6 204.7,1130.6 204.7,1156.6 202.4,1154.2 202.4,1260.9
				210.3,1252.9 210.3,1162.1 219.4,1171.1 219.4,1116.1 216.9,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="137.5,1089.3 135.2,1086.9 135.2,1200.3 144.1,1191.2 144.1,1223.8 152.1,1231.7 152.1,1183.3
				149.7,1185.6 149.7,1101.4 158.7,1110.5 158.7,1048.8 137.5,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="166.6,1118.4 164.2,1116.1 164.2,1171.1 173.3,1162.1 173.3,1252.9 181.2,1260.9 181.2,1154.2
				178.9,1156.6 178.9,1130.6 187.8,1139.6 187.8,1019.7 166.6,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="246.1,1089.3 246.1,1070 224.8,1048.8 224.8,1110.5 233.9,1101.4 233.9,1101.4 233.9,1185.6
				231.5,1183.3 231.5,1231.7 239.5,1223.8 239.5,1191.2 248.4,1200.3 248.4,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="339.2,1291 340.6,1292.3 340.6,1250.5 339.2,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="426.6,1292.3 427.9,1291 427.9,1251.8 426.6,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="397.4,1321.4 398.8,1320.1 398.8,1222.7 397.4,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="368.4,1320.1 369.7,1321.4 369.7,1221.4 368.4,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="344.8,1040.8 323.7,1019.7 323.7,1139.6 332.6,1130.6 332.6,1156.6 330.2,1154.2 330.2,1260.9
				338.2,1252.9 338.2,1162.1 347.1,1171.1 347.1,1116.1 344.8,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="265.3,1089.3 263,1086.9 263,1200.3 272,1191.2 272,1223.8 280,1231.7 280,1183.3 277.6,1185.6
				277.6,1101.4 286.6,1110.5 286.6,1048.8 265.3,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="294.5,1118.4 292.1,1116.1 292.1,1171.1 301.1,1162.1 301.1,1252.9 309,1260.9 309,1154.2
				306.6,1156.6 306.6,1130.6 315.7,1139.6 315.7,1019.7 294.5,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="373.9,1089.3 373.9,1070 352.7,1048.8 352.7,1110.5 361.8,1101.4 361.8,1101.4 361.8,1185.6
				359.3,1183.3 359.3,1231.7 367.2,1223.8 367.2,1191.2 376.3,1200.3 376.3,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="467.1,1291 468.4,1292.3 468.4,1250.5 467.1,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="554.5,1292.3 555.7,1291 555.7,1251.8 554.5,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="525.3,1321.4 526.7,1320.1 526.7,1222.7 525.3,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="496.2,1320.1 497.5,1321.4 497.5,1221.4 496.2,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="472.7,1040.8 451.4,1019.7 451.4,1139.6 460.5,1130.6 460.5,1156.6 458.1,1154.2 458.1,1260.9
				466.1,1252.9 466.1,1162.1 475,1171.1 475,1116.1 472.7,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="393.2,1089.3 390.8,1086.9 390.8,1200.3 399.8,1191.2 399.8,1223.8 407.7,1231.7 407.7,1183.3
				405.4,1185.6 405.4,1101.4 414.4,1110.5 414.4,1048.8 393.2,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="422.4,1118.4 420,1116.1 420,1171.1 429,1162.1 429,1252.9 436.9,1260.9 436.9,1154.2 434.5,1156.6
				434.5,1130.6 443.5,1139.6 443.5,1019.7 422.4,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="501.7,1089.3 501.7,1070 480.6,1048.8 480.6,1110.5 489.6,1101.4 489.6,1101.4 489.6,1185.6
				487.2,1183.3 487.2,1231.7 495.1,1223.8 495.1,1191.2 504.1,1200.3 504.1,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="595,1291 596.2,1292.3 596.2,1250.5 595,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="682.3,1292.3 683.6,1291 683.6,1251.8 682.3,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="653.2,1321.4 654.6,1320.1 654.6,1222.7 653.2,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="624,1320.1 625.4,1321.4 625.4,1221.4 624,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="600.5,1040.8 579.3,1019.7 579.3,1139.6 588.3,1130.6 588.3,1156.6 585.9,1154.2 585.9,1260.9
				593.9,1252.9 593.9,1162.1 602.9,1171.1 602.9,1116.1 600.5,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="521.1,1089.3 518.7,1086.9 518.7,1200.3 527.7,1191.2 527.7,1223.8 535.6,1231.7 535.6,1183.3
				533.3,1185.6 533.3,1101.4 542.2,1110.5 542.2,1048.8 521.1,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="550.2,1118.4 547.8,1116.1 547.8,1171.1 556.9,1162.1 556.9,1252.9 564.8,1260.9 564.8,1154.2
				562.4,1156.6 562.4,1130.6 571.4,1139.6 571.4,1019.7 550.2,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="629.6,1089.3 629.6,1070 608.5,1048.8 608.5,1110.5 617.5,1101.4 617.5,1101.4 617.5,1185.6
				615.1,1183.3 615.1,1231.7 623,1223.8 623,1191.2 632,1200.3 632,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="722.8,1291 724.1,1292.3 724.1,1250.5 722.8,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="810.2,1292.3 811.5,1291 811.5,1251.8 810.2,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="781,1321.4 782.3,1320.1 782.3,1222.7 781,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="751.9,1320.1 753.3,1321.4 753.3,1221.4 751.9,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="728.3,1040.8 707.2,1019.7 707.2,1139.6 716.2,1130.6 716.2,1156.6 713.8,1154.2 713.8,1260.9
				721.7,1252.9 721.7,1162.1 730.7,1171.1 730.7,1116.1 728.3,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="649,1089.3 646.5,1086.9 646.5,1200.3 655.6,1191.2 655.6,1223.8 663.5,1231.7 663.5,1183.3
				661.1,1185.6 661.1,1101.4 670.1,1110.5 670.1,1048.8 649,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="678,1118.4 675.7,1116.1 675.7,1171.1 684.6,1162.1 684.6,1252.9 692.6,1260.9 692.6,1154.2
				690.2,1156.6 690.2,1130.6 699.3,1139.6 699.3,1019.7 678,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="757.5,1089.3 757.5,1070 736.3,1048.8 736.3,1110.5 745.3,1101.4 745.3,1101.4 745.3,1185.6
				742.9,1183.3 742.9,1231.7 750.9,1223.8 750.9,1191.2 759.9,1200.3 759.9,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="850.7,1291 852,1292.3 852,1250.5 850.7,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="938,1292.3 939.4,1291 939.4,1251.8 938,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="908.9,1321.4 910.2,1320.1 910.2,1222.7 908.9,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="879.8,1320.1 881.1,1321.4 881.1,1221.4 879.8,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="856.2,1040.8 835,1019.7 835,1139.6 844.1,1130.6 844.1,1156.6 841.7,1154.2 841.7,1260.9
				849.6,1252.9 849.6,1162.1 858.6,1171.1 858.6,1116.1 856.2,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="776.8,1089.3 774.4,1086.9 774.4,1200.3 783.5,1191.2 783.5,1223.8 791.4,1231.7 791.4,1183.3
				788.9,1185.6 788.9,1101.4 798,1110.5 798,1048.8 776.8,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="805.9,1118.4 803.6,1116.1 803.6,1171.1 812.5,1162.1 812.5,1252.9 820.5,1260.9 820.5,1154.2
				818.1,1156.6 818.1,1130.6 827.1,1139.6 827.1,1019.7 805.9,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="885.3,1089.3 885.3,1070 864.2,1048.8 864.2,1110.5 873.1,1101.4 873.1,1101.4 873.1,1185.6
				870.8,1183.3 870.8,1231.7 878.7,1223.8 878.7,1191.2 887.8,1200.3 887.8,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="978.5,1291 979.9,1292.3 979.9,1250.5 978.5,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1065.8,1292.3 1067.2,1291 1067.2,1251.8 1065.8,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1036.8,1321.4 1038.1,1320.1 1038.1,1222.7 1036.8,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1007.6,1320.1 1008.9,1321.4 1008.9,1221.4 1007.6,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="984.1,1040.8 962.9,1019.7 962.9,1139.6 971.9,1130.6 971.9,1156.6 969.5,1154.2 969.5,1260.9
				977.4,1252.9 977.4,1162.1 986.5,1171.1 986.5,1116.1 984.1,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="904.7,1089.3 902.3,1086.9 902.3,1200.3 911.3,1191.2 911.3,1223.8 919.2,1231.7 919.2,1183.3
				916.8,1185.6 916.8,1101.4 925.8,1110.5 925.8,1048.8 904.7,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="933.8,1118.4 931.4,1116.1 931.4,1171.1 940.4,1162.1 940.4,1252.9 948.4,1260.9 948.4,1154.2
				946,1156.6 946,1130.6 955,1139.6 955,1019.7 933.8,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1013.2,1089.3 1013.2,1070 992,1048.8 992,1110.5 1001,1101.4 1001,1101.4 1001,1185.6
				998.6,1183.3 998.6,1231.7 1006.6,1223.8 1006.6,1191.2 1015.5,1200.3 1015.5,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1106.3,1291 1107.7,1292.3 1107.7,1250.5 1106.3,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1135.5,1320.1 1136.8,1321.4 1136.8,1221.4 1135.5,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1111.9,1040.8 1090.8,1019.7 1090.8,1139.6 1099.7,1130.6 1099.7,1156.6 1097.4,1154.2
				1097.4,1260.9 1105.3,1252.9 1105.3,1162.1 1114.3,1171.1 1114.3,1116.1 1111.9,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1032.5,1089.3 1030.2,1086.9 1030.2,1200.3 1039.1,1191.2 1039.1,1223.8 1047.1,1231.7
				1047.1,1183.3 1044.7,1185.6 1044.7,1101.4 1053.7,1110.5 1053.7,1048.8 1032.5,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1061.6,1118.4 1059.2,1116.1 1059.2,1171.1 1068.2,1162.1 1068.2,1252.9 1076.2,1260.9
				1076.2,1154.2 1073.8,1156.6 1073.8,1130.6 1082.8,1139.6 1082.8,1019.7 1061.6,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1141.1,1089.3 1141.1,1070 1119.8,1048.8 1119.8,1110.5 1128.9,1101.4 1128.9,1101.4 1128.9,1185.6
				1126.5,1183.3 1126.5,1231.7 1134.5,1223.8 1134.5,1191.2 1143.4,1200.3 1143.4,1086.9 			"/>
                </g>
            </g>
        </g>
        <rect x="1168.4" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -408.0089 1174.4072)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="1296.4" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -370.5246 1264.8612)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="1424.1" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -333.114 1355.2097)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="1552" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -295.6398 1445.6344)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="1679.9" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -258.2262 1536.0592)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="1807.7" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -220.746 1626.4369)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="1935.5" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -183.3427 1716.8324)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="2063.4" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -145.8689 1807.2572)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <rect x="2191.2" y="1034.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -108.4479 1897.6351)" :fill="colors[`${id}-p-2`]" width="90.4" height="90.4"/>
        <g>
            <polygon :fill="colors[`${id}-p-2`]" points="1192.8,866.9 1194.1,868.2 1194.1,907.4 1192.8,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1163.6,837.8 1164.9,839.2 1164.9,936.5 1163.6,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1233.3,868.2 1234.6,866.9 1234.6,908.7 1233.3,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1320.5,866.9 1321.9,868.2 1321.9,907.4 1320.5,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1291.5,837.8 1292.8,839.2 1292.8,936.5 1291.5,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1262.3,839.2 1263.7,837.8 1263.7,937.9 1262.3,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1238.8,1118.4 1217.6,1139.6 1217.6,1019.7 1226.7,1028.7 1226.7,1002.7 1224.2,1005.1 1224.2,898.4
		1232.2,906.4 1232.2,997.1 1241.2,988.2 1241.2,1043.2 1238.8,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1159.4,1070 1157,1072.4 1157,959 1166,968.1 1166,935.5 1173.9,927.6 1173.9,976 1171.5,973.6
		1171.5,1057.8 1180.6,1048.8 1180.6,1110.5 1159.4,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1188.5,1040.8 1186.2,1043.2 1186.2,988.2 1195.1,997.1 1195.1,906.4 1203.1,898.4 1203.1,1005.1
		1200.7,1002.7 1200.7,1028.7 1209.7,1019.7 1209.7,1139.6 1188.5,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1267.9,1070 1267.9,1089.3 1246.8,1110.5 1246.8,1048.8 1255.7,1057.8 1255.7,1057.8 1255.7,973.6
		1253.4,976 1253.4,927.6 1261.3,935.5 1261.3,968.1 1270.3,959 1270.3,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1361,868.2 1362.4,866.9 1362.4,908.7 1361,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1448.4,866.9 1449.8,868.2 1449.8,907.4 1448.4,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1419.4,837.8 1420.6,839.2 1420.6,936.5 1419.4,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1390.2,839.2 1391.5,837.8 1391.5,937.9 1390.2,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1366.6,1118.4 1345.5,1139.6 1345.5,1019.7 1354.5,1028.7 1354.5,1002.7 1352.1,1005.1 1352.1,898.4
		1360,906.4 1360,997.1 1369.1,988.2 1369.1,1043.2 1366.6,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1287.3,1070 1284.9,1072.4 1284.9,959 1293.9,968.1 1293.9,935.5 1301.8,927.6 1301.8,976
		1299.4,973.6 1299.4,1057.8 1308.4,1048.8 1308.4,1110.5 1287.3,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1316.3,1040.8 1314,1043.2 1314,988.2 1323,997.1 1323,906.4 1331,898.4 1331,1005.1 1328.6,1002.7
		1328.6,1028.7 1337.5,1019.7 1337.5,1139.6 1316.3,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1395.8,1070 1395.8,1089.3 1374.5,1110.5 1374.5,1048.8 1383.6,1057.8 1383.6,1057.8 1383.6,973.6
		1381.2,976 1381.2,927.6 1389.2,935.5 1389.2,968.1 1398.1,959 1398.1,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1488.9,868.2 1490.3,866.9 1490.3,908.7 1488.9,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1576.3,866.9 1577.7,868.2 1577.7,907.4 1576.3,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1547.2,837.8 1548.5,839.2 1548.5,936.5 1547.2,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1518.1,839.2 1519.4,837.8 1519.4,937.9 1518.1,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1494.5,1118.4 1473.4,1139.6 1473.4,1019.7 1482.3,1028.7 1482.3,1002.7 1480,1005.1 1480,898.4
		1487.9,906.4 1487.9,997.1 1496.9,988.2 1496.9,1043.2 1494.5,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1415,1070 1412.7,1072.4 1412.7,959 1421.7,968.1 1421.7,935.5 1429.7,927.6 1429.7,976 1427.3,973.6
		1427.3,1057.8 1436.3,1048.8 1436.3,1110.5 1415,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1444.2,1040.8 1441.8,1043.2 1441.8,988.2 1450.8,997.1 1450.8,906.4 1458.7,898.4 1458.7,1005.1
		1456.4,1002.7 1456.4,1028.7 1465.4,1019.7 1465.4,1139.6 1444.2,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1523.7,1070 1523.7,1089.3 1502.4,1110.5 1502.4,1048.8 1511.5,1057.8 1511.5,1057.8 1511.5,973.6
		1509,976 1509,927.6 1517,935.5 1517,968.1 1526,959 1526,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1616.8,868.2 1618.2,866.9 1618.2,908.7 1616.8,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1704.2,866.9 1705.4,868.2 1705.4,907.4 1704.2,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1675,837.8 1676.4,839.2 1676.4,936.5 1675,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1645.9,839.2 1647.2,837.8 1647.2,937.9 1645.9,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1622.4,1118.4 1601.2,1139.6 1601.2,1019.7 1610.2,1028.7 1610.2,1002.7 1607.9,1005.1 1607.9,898.4
		1615.8,906.4 1615.8,997.1 1624.8,988.2 1624.8,1043.2 1622.4,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1542.9,1070 1540.6,1072.4 1540.6,959 1549.5,968.1 1549.5,935.5 1557.5,927.6 1557.5,976
		1555.1,973.6 1555.1,1057.8 1564.2,1048.8 1564.2,1110.5 1542.9,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1572.1,1040.8 1569.7,1043.2 1569.7,988.2 1578.7,997.1 1578.7,906.4 1586.6,898.4 1586.6,1005.1
		1584.3,1002.7 1584.3,1028.7 1593.2,1019.7 1593.2,1139.6 1572.1,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1651.4,1070 1651.4,1089.3 1630.3,1110.5 1630.3,1048.8 1639.3,1057.8 1639.3,1057.8 1639.3,973.6
		1636.9,976 1636.9,927.6 1644.9,935.5 1644.9,968.1 1653.8,959 1653.8,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1744.7,868.2 1745.9,866.9 1745.9,908.7 1744.7,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1832,866.9 1833.3,868.2 1833.3,907.4 1832,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1802.9,837.8 1804.3,839.2 1804.3,936.5 1802.9,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1773.8,839.2 1775.1,837.8 1775.1,937.9 1773.8,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1750.2,1118.4 1729,1139.6 1729,1019.7 1738,1028.7 1738,1002.7 1735.6,1005.1 1735.6,898.4
		1743.6,906.4 1743.6,997.1 1752.6,988.2 1752.6,1043.2 1750.2,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1670.8,1070 1668.5,1072.4 1668.5,959 1677.4,968.1 1677.4,935.5 1685.4,927.6 1685.4,976 1683,973.6
		1683,1057.8 1691.9,1048.8 1691.9,1110.5 1670.8,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1699.9,1040.8 1697.5,1043.2 1697.5,988.2 1706.6,997.1 1706.6,906.4 1714.5,898.4 1714.5,1005.1
		1712.1,1002.7 1712.1,1028.7 1721.1,1019.7 1721.1,1139.6 1699.9,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1779.3,1070 1779.3,1089.3 1758.2,1110.5 1758.2,1048.8 1767.2,1057.8 1767.2,1057.8 1767.2,973.6
		1764.8,976 1764.8,927.6 1772.7,935.5 1772.7,968.1 1781.7,959 1781.7,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1872.5,868.2 1873.8,866.9 1873.8,908.7 1872.5,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1959.9,866.9 1961.2,868.2 1961.2,907.4 1959.9,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1930.7,837.8 1932.1,839.2 1932.1,936.5 1930.7,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1901.7,839.2 1903,837.8 1903,937.9 1901.7,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1878.1,1118.4 1856.9,1139.6 1856.9,1019.7 1865.9,1028.7 1865.9,1002.7 1863.5,1005.1 1863.5,898.4
		1871.5,906.4 1871.5,997.1 1880.4,988.2 1880.4,1043.2 1878.1,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1798.7,1070 1796.2,1072.4 1796.2,959 1805.3,968.1 1805.3,935.5 1813.2,927.6 1813.2,976
		1810.9,973.6 1810.9,1057.8 1819.8,1048.8 1819.8,1110.5 1798.7,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1827.8,1040.8 1825.4,1043.2 1825.4,988.2 1834.4,997.1 1834.4,906.4 1842.3,898.4 1842.3,1005.1
		1839.9,1002.7 1839.9,1028.7 1849,1019.7 1849,1139.6 1827.8,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1907.2,1070 1907.2,1089.3 1886,1110.5 1886,1048.8 1895.1,1057.8 1895.1,1057.8 1895.1,973.6
		1892.6,976 1892.6,927.6 1900.6,935.5 1900.6,968.1 1909.6,959 1909.6,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2000.4,868.2 2001.7,866.9 2001.7,908.7 2000.4,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2087.8,866.9 2089.1,868.2 2089.1,907.4 2087.8,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2058.6,837.8 2059.9,839.2 2059.9,936.5 2058.6,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2029.5,839.2 2030.8,837.8 2030.8,937.9 2029.5,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2005.9,1118.4 1984.7,1139.6 1984.7,1019.7 1993.8,1028.7 1993.8,1002.7 1991.4,1005.1 1991.4,898.4
		1999.4,906.4 1999.4,997.1 2008.3,988.2 2008.3,1043.2 2005.9,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1926.5,1070 1924.1,1072.4 1924.1,959 1933.2,968.1 1933.2,935.5 1941.1,927.6 1941.1,976
		1938.7,973.6 1938.7,1057.8 1947.7,1048.8 1947.7,1110.5 1926.5,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="1955.7,1040.8 1953.3,1043.2 1953.3,988.2 1962.3,997.1 1962.3,906.4 1970.2,898.4 1970.2,1005.1
		1967.8,1002.7 1967.8,1028.7 1976.8,1019.7 1976.8,1139.6 1955.7,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2035,1070 2035,1089.3 2013.9,1110.5 2013.9,1048.8 2022.8,1057.8 2022.8,1057.8 2022.8,973.6
		2020.5,976 2020.5,927.6 2028.4,935.5 2028.4,968.1 2037.5,959 2037.5,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2128.3,868.2 2129.6,866.9 2129.6,908.7 2128.3,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2215.6,866.9 2216.9,868.2 2216.9,907.4 2215.6,908.7 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2186.5,837.8 2187.8,839.2 2187.8,936.5 2186.5,937.9 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2157.3,839.2 2158.7,837.8 2158.7,937.9 2157.3,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2133.8,1118.4 2112.6,1139.6 2112.6,1019.7 2121.6,1028.7 2121.6,1002.7 2119.2,1005.1 2119.2,898.4
		2127.1,906.4 2127.1,997.1 2136.2,988.2 2136.2,1043.2 2133.8,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2054.4,1070 2052,1072.4 2052,959 2061,968.1 2061,935.5 2068.9,927.6 2068.9,976 2066.5,973.6
		2066.5,1057.8 2075.5,1048.8 2075.5,1110.5 2054.4,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2083.5,1040.8 2081.1,1043.2 2081.1,988.2 2090.1,997.1 2090.1,906.4 2098.1,898.4 2098.1,1005.1
		2095.7,1002.7 2095.7,1028.7 2104.7,1019.7 2104.7,1139.6 2083.5,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2162.9,1070 2162.9,1089.3 2141.8,1110.5 2141.8,1048.8 2150.7,1057.8 2150.7,1057.8 2150.7,973.6
		2148.4,976 2148.4,927.6 2156.3,935.5 2156.3,968.1 2165.3,959 2165.3,1072.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2256.1,868.2 2257.4,866.9 2257.4,908.7 2256.1,907.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2285.2,839.2 2286.6,837.8 2286.6,937.9 2285.2,936.5 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2261.6,1118.4 2240.5,1139.6 2240.5,1019.7 2249.5,1028.7 2249.5,1002.7 2247.1,1005.1 2247.1,898.4
		2255,906.4 2255,997.1 2264,988.2 2264,1043.2 2261.6,1040.8 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2182.3,1070 2179.9,1072.4 2179.9,959 2188.9,968.1 2188.9,935.5 2196.8,927.6 2196.8,976
		2194.4,973.6 2194.4,1057.8 2203.4,1048.8 2203.4,1110.5 2182.3,1089.3 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2211.3,1040.8 2209,1043.2 2209,988.2 2217.9,997.1 2217.9,906.4 2226,898.4 2226,1005.1
		2223.5,1002.7 2223.5,1028.7 2232.6,1019.7 2232.6,1139.6 2211.3,1118.4 	"/>
            <polygon :fill="colors[`${id}-p-2`]" points="2290.8,1070 2290.8,1089.3 2269.6,1110.5 2269.6,1048.8 2278.6,1057.8 2278.6,1057.8 2278.6,973.6
		2276.3,976 2276.3,927.6 2284.2,935.5 2284.2,968.1 2293.2,959 2293.2,1072.4 	"/>
        </g>
        <g>
            <g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1192.8,1292.3 1194.1,1291 1194.1,1251.8 1192.8,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1163.6,1321.4 1164.9,1320.1 1164.9,1222.7 1163.6,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1233.3,1291 1234.6,1292.3 1234.6,1250.5 1233.3,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1320.5,1292.3 1321.9,1291 1321.9,1251.8 1320.5,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1291.5,1321.4 1292.8,1320.1 1292.8,1222.7 1291.5,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1262.3,1320.1 1263.7,1321.4 1263.7,1221.4 1262.3,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1238.8,1040.8 1217.6,1019.7 1217.6,1139.6 1226.7,1130.6 1226.7,1156.6 1224.2,1154.2
				1224.2,1260.9 1232.2,1252.9 1232.2,1162.1 1241.2,1171.1 1241.2,1116.1 1238.8,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1159.4,1089.3 1157,1086.9 1157,1200.3 1166,1191.2 1166,1223.8 1173.9,1231.7 1173.9,1183.3
				1171.5,1185.6 1171.5,1101.4 1180.6,1110.5 1180.6,1048.8 1159.4,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1188.5,1118.4 1186.2,1116.1 1186.2,1171.1 1195.1,1162.1 1195.1,1252.9 1203.1,1260.9
				1203.1,1154.2 1200.7,1156.6 1200.7,1130.6 1209.7,1139.6 1209.7,1019.7 1188.5,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1267.9,1089.3 1267.9,1070 1246.8,1048.8 1246.8,1110.5 1255.7,1101.4 1255.7,1101.4 1255.7,1185.6
				1253.4,1183.3 1253.4,1231.7 1261.3,1223.8 1261.3,1191.2 1270.3,1200.3 1270.3,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1361,1291 1362.4,1292.3 1362.4,1250.5 1361,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1448.4,1292.3 1449.8,1291 1449.8,1251.8 1448.4,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1419.4,1321.4 1420.6,1320.1 1420.6,1222.7 1419.4,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1390.2,1320.1 1391.5,1321.4 1391.5,1221.4 1390.2,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1366.6,1040.8 1345.5,1019.7 1345.5,1139.6 1354.5,1130.6 1354.5,1156.6 1352.1,1154.2
				1352.1,1260.9 1360,1252.9 1360,1162.1 1369.1,1171.1 1369.1,1116.1 1366.6,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1287.3,1089.3 1284.9,1086.9 1284.9,1200.3 1293.9,1191.2 1293.9,1223.8 1301.8,1231.7
				1301.8,1183.3 1299.4,1185.6 1299.4,1101.4 1308.4,1110.5 1308.4,1048.8 1287.3,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1316.3,1118.4 1314,1116.1 1314,1171.1 1323,1162.1 1323,1252.9 1331,1260.9 1331,1154.2
				1328.6,1156.6 1328.6,1130.6 1337.5,1139.6 1337.5,1019.7 1316.3,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1395.8,1089.3 1395.8,1070 1374.5,1048.8 1374.5,1110.5 1383.6,1101.4 1383.6,1101.4 1383.6,1185.6
				1381.2,1183.3 1381.2,1231.7 1389.2,1223.8 1389.2,1191.2 1398.1,1200.3 1398.1,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1488.9,1291 1490.3,1292.3 1490.3,1250.5 1488.9,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1576.3,1292.3 1577.7,1291 1577.7,1251.8 1576.3,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1547.2,1321.4 1548.5,1320.1 1548.5,1222.7 1547.2,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1518.1,1320.1 1519.4,1321.4 1519.4,1221.4 1518.1,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1494.5,1040.8 1473.4,1019.7 1473.4,1139.6 1482.3,1130.6 1482.3,1156.6 1480,1154.2 1480,1260.9
				1487.9,1252.9 1487.9,1162.1 1496.9,1171.1 1496.9,1116.1 1494.5,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1415,1089.3 1412.7,1086.9 1412.7,1200.3 1421.7,1191.2 1421.7,1223.8 1429.7,1231.7 1429.7,1183.3
				1427.3,1185.6 1427.3,1101.4 1436.3,1110.5 1436.3,1048.8 1415,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1444.2,1118.4 1441.8,1116.1 1441.8,1171.1 1450.8,1162.1 1450.8,1252.9 1458.7,1260.9
				1458.7,1154.2 1456.4,1156.6 1456.4,1130.6 1465.4,1139.6 1465.4,1019.7 1444.2,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1523.7,1089.3 1523.7,1070 1502.4,1048.8 1502.4,1110.5 1511.5,1101.4 1511.5,1101.4 1511.5,1185.6
				1509,1183.3 1509,1231.7 1517,1223.8 1517,1191.2 1526,1200.3 1526,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1616.8,1291 1618.2,1292.3 1618.2,1250.5 1616.8,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1704.2,1292.3 1705.4,1291 1705.4,1251.8 1704.2,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1675,1321.4 1676.4,1320.1 1676.4,1222.7 1675,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1645.9,1320.1 1647.2,1321.4 1647.2,1221.4 1645.9,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1622.4,1040.8 1601.2,1019.7 1601.2,1139.6 1610.2,1130.6 1610.2,1156.6 1607.9,1154.2
				1607.9,1260.9 1615.8,1252.9 1615.8,1162.1 1624.8,1171.1 1624.8,1116.1 1622.4,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1542.9,1089.3 1540.6,1086.9 1540.6,1200.3 1549.5,1191.2 1549.5,1223.8 1557.5,1231.7
				1557.5,1183.3 1555.1,1185.6 1555.1,1101.4 1564.2,1110.5 1564.2,1048.8 1542.9,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1572.1,1118.4 1569.7,1116.1 1569.7,1171.1 1578.7,1162.1 1578.7,1252.9 1586.6,1260.9
				1586.6,1154.2 1584.3,1156.6 1584.3,1130.6 1593.2,1139.6 1593.2,1019.7 1572.1,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1651.4,1089.3 1651.4,1070 1630.3,1048.8 1630.3,1110.5 1639.3,1101.4 1639.3,1101.4 1639.3,1185.6
				1636.9,1183.3 1636.9,1231.7 1644.9,1223.8 1644.9,1191.2 1653.8,1200.3 1653.8,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1744.7,1291 1745.9,1292.3 1745.9,1250.5 1744.7,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1832,1292.3 1833.3,1291 1833.3,1251.8 1832,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1802.9,1321.4 1804.3,1320.1 1804.3,1222.7 1802.9,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1773.8,1320.1 1775.1,1321.4 1775.1,1221.4 1773.8,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1750.2,1040.8 1729,1019.7 1729,1139.6 1738,1130.6 1738,1156.6 1735.6,1154.2 1735.6,1260.9
				1743.6,1252.9 1743.6,1162.1 1752.6,1171.1 1752.6,1116.1 1750.2,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1670.8,1089.3 1668.5,1086.9 1668.5,1200.3 1677.4,1191.2 1677.4,1223.8 1685.4,1231.7
				1685.4,1183.3 1683,1185.6 1683,1101.4 1691.9,1110.5 1691.9,1048.8 1670.8,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1699.9,1118.4 1697.5,1116.1 1697.5,1171.1 1706.6,1162.1 1706.6,1252.9 1714.5,1260.9
				1714.5,1154.2 1712.1,1156.6 1712.1,1130.6 1721.1,1139.6 1721.1,1019.7 1699.9,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1779.3,1089.3 1779.3,1070 1758.2,1048.8 1758.2,1110.5 1767.2,1101.4 1767.2,1101.4 1767.2,1185.6
				1764.8,1183.3 1764.8,1231.7 1772.7,1223.8 1772.7,1191.2 1781.7,1200.3 1781.7,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1872.5,1291 1873.8,1292.3 1873.8,1250.5 1872.5,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1959.9,1292.3 1961.2,1291 1961.2,1251.8 1959.9,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1930.7,1321.4 1932.1,1320.1 1932.1,1222.7 1930.7,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1901.7,1320.1 1903,1321.4 1903,1221.4 1901.7,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1878.1,1040.8 1856.9,1019.7 1856.9,1139.6 1865.9,1130.6 1865.9,1156.6 1863.5,1154.2
				1863.5,1260.9 1871.5,1252.9 1871.5,1162.1 1880.4,1171.1 1880.4,1116.1 1878.1,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1798.7,1089.3 1796.2,1086.9 1796.2,1200.3 1805.3,1191.2 1805.3,1223.8 1813.2,1231.7
				1813.2,1183.3 1810.9,1185.6 1810.9,1101.4 1819.8,1110.5 1819.8,1048.8 1798.7,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1827.8,1118.4 1825.4,1116.1 1825.4,1171.1 1834.4,1162.1 1834.4,1252.9 1842.3,1260.9
				1842.3,1154.2 1839.9,1156.6 1839.9,1130.6 1849,1139.6 1849,1019.7 1827.8,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1907.2,1089.3 1907.2,1070 1886,1048.8 1886,1110.5 1895.1,1101.4 1895.1,1101.4 1895.1,1185.6
				1892.6,1183.3 1892.6,1231.7 1900.6,1223.8 1900.6,1191.2 1909.6,1200.3 1909.6,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2000.4,1291 2001.7,1292.3 2001.7,1250.5 2000.4,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2087.8,1292.3 2089.1,1291 2089.1,1251.8 2087.8,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2058.6,1321.4 2059.9,1320.1 2059.9,1222.7 2058.6,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2029.5,1320.1 2030.8,1321.4 2030.8,1221.4 2029.5,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2005.9,1040.8 1984.7,1019.7 1984.7,1139.6 1993.8,1130.6 1993.8,1156.6 1991.4,1154.2
				1991.4,1260.9 1999.4,1252.9 1999.4,1162.1 2008.3,1171.1 2008.3,1116.1 2005.9,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1926.5,1089.3 1924.1,1086.9 1924.1,1200.3 1933.2,1191.2 1933.2,1223.8 1941.1,1231.7
				1941.1,1183.3 1938.7,1185.6 1938.7,1101.4 1947.7,1110.5 1947.7,1048.8 1926.5,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="1955.7,1118.4 1953.3,1116.1 1953.3,1171.1 1962.3,1162.1 1962.3,1252.9 1970.2,1260.9
				1970.2,1154.2 1967.8,1156.6 1967.8,1130.6 1976.8,1139.6 1976.8,1019.7 1955.7,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2035,1089.3 2035,1070 2013.9,1048.8 2013.9,1110.5 2022.8,1101.4 2022.8,1101.4 2022.8,1185.6
				2020.5,1183.3 2020.5,1231.7 2028.4,1223.8 2028.4,1191.2 2037.5,1200.3 2037.5,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2128.3,1291 2129.6,1292.3 2129.6,1250.5 2128.3,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2215.6,1292.3 2216.9,1291 2216.9,1251.8 2215.6,1250.5 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2186.5,1321.4 2187.8,1320.1 2187.8,1222.7 2186.5,1221.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2157.3,1320.1 2158.7,1321.4 2158.7,1221.4 2157.3,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2133.8,1040.8 2112.6,1019.7 2112.6,1139.6 2121.6,1130.6 2121.6,1156.6 2119.2,1154.2
				2119.2,1260.9 2127.1,1252.9 2127.1,1162.1 2136.2,1171.1 2136.2,1116.1 2133.8,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2054.4,1089.3 2052,1086.9 2052,1200.3 2061,1191.2 2061,1223.8 2068.9,1231.7 2068.9,1183.3
				2066.5,1185.6 2066.5,1101.4 2075.5,1110.5 2075.5,1048.8 2054.4,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2083.5,1118.4 2081.1,1116.1 2081.1,1171.1 2090.1,1162.1 2090.1,1252.9 2098.1,1260.9
				2098.1,1154.2 2095.7,1156.6 2095.7,1130.6 2104.7,1139.6 2104.7,1019.7 2083.5,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2162.9,1089.3 2162.9,1070 2141.8,1048.8 2141.8,1110.5 2150.7,1101.4 2150.7,1101.4 2150.7,1185.6
				2148.4,1183.3 2148.4,1231.7 2156.3,1223.8 2156.3,1191.2 2165.3,1200.3 2165.3,1086.9 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2256.1,1291 2257.4,1292.3 2257.4,1250.5 2256.1,1251.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2285.2,1320.1 2286.6,1321.4 2286.6,1221.4 2285.2,1222.7 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2261.6,1040.8 2240.5,1019.7 2240.5,1139.6 2249.5,1130.6 2249.5,1156.6 2247.1,1154.2
				2247.1,1260.9 2255,1252.9 2255,1162.1 2264,1171.1 2264,1116.1 2261.6,1118.4 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2182.3,1089.3 2179.9,1086.9 2179.9,1200.3 2188.9,1191.2 2188.9,1223.8 2196.8,1231.7
				2196.8,1183.3 2194.4,1185.6 2194.4,1101.4 2203.4,1110.5 2203.4,1048.8 2182.3,1070 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2211.3,1118.4 2209,1116.1 2209,1171.1 2217.9,1162.1 2217.9,1252.9 2226,1260.9 2226,1154.2
				2223.5,1156.6 2223.5,1130.6 2232.6,1139.6 2232.6,1019.7 2211.3,1040.8 			"/>
                </g>
                <g>
                    <polygon :fill="colors[`${id}-p-2`]" points="2290.8,1089.3 2290.8,1070 2269.6,1048.8 2269.6,1110.5 2278.6,1101.4 2278.6,1101.4 2278.6,1185.6
				2276.3,1183.3 2276.3,1231.7 2284.2,1223.8 2284.2,1191.2 2293.2,1200.3 2293.2,1086.9 			"/>
                </g>
            </g>
        </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>