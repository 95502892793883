export let colors = [
    {
        "name": "White",
        "hex": "#FFFFFF"
    },
    {
        "name": "Black C",
        "hex": "#2D2926"
    },
    {
        "name": "293 C",
        "hex": "#003DA5"
    },
    {
        "name": "Yellow C",
        "hex": "#FEDD00"
    },
    {
        "name": "Yellow 012 C",
        "hex": "#FFD700"
    },
    {
        "name": "Orange 021 C",
        "hex": "#FE5000"
    },
    {
        "name": "Bright Red C",
        "hex": "#F93822"
    },
    {
        "name": "Warm Red C",
        "hex": "#F9423A"
    },
    {
        "name": "Red 032 C",
        "hex": "#EF3340"
    },
    {
        "name": "Rubine Red C",
        "hex": "#CE0058"
    },
    {
        "name": "Rhodamine Red C",
        "hex": "#E10098"
    },
    {
        "name": "Pink C",
        "hex": "#D62598"
    },
    {
        "name": "Purple C",
        "hex": "#BB29BB"
    },
    {
        "name": "Medium Purple C",
        "hex": "#4E008E"
    },
    {
        "name": "Violet C",
        "hex": "#440099"
    },
    {
        "name": "Blue 072 C",
        "hex": "#10069F"
    },
    {
        "name": "Dark Blue C",
        "hex": "#00239C"
    },
    {
        "name": "Reflex Blue C",
        "hex": "#001489"
    },
    {
        "name": "Process Blue C",
        "hex": "#0085CA"
    },
    {
        "name": "Green C",
        "hex": "#00AB84"
    },
    {
        "name": "Yellow 0131 C",
        "hex": "#F2F0A1"
    },
    {
        "name": "Red 0331 C",
        "hex": "#FCAEBB"
    },
    {
        "name": "Magenta 0521 C",
        "hex": "#F1B2DC"
    },
    {
        "name": "Violet 0631 C",
        "hex": "#BF9BDE"
    },
    {
        "name": "Blue 0821 C",
        "hex": "#74D1EA"
    },
    {
        "name": "Green 0921 C",
        "hex": "#9DE7D7"
    },
    {
        "name": "Black 0961 C",
        "hex": "#9E978E"
    },
    {
        "name": "801 C",
        "hex": "#009ACE"
    },
    {
        "name": "802 C",
        "hex": "#44D62C"
    },
    {
        "name": "803 C",
        "hex": "#FFE900"
    },
    {
        "name": "804 C",
        "hex": "#FFAA4D"
    },
    {
        "name": "805 C",
        "hex": "#FF7276"
    },
    {
        "name": "806 C",
        "hex": "#FF3EB5"
    },
    {
        "name": "807 C",
        "hex": "#EA27C2"
    },
    {
        "name": "871 C",
        "hex": "#84754E"
    },
    {
        "name": "872 C",
        "hex": "#85714D"
    },
    {
        "name": "873 C",
        "hex": "#866D4B"
    },
    {
        "name": "874 C",
        "hex": "#8B6F4E"
    },
    {
        "name": "875 C",
        "hex": "#87674F"
    },
    {
        "name": "876 C",
        "hex": "#8B634B"
    },
    {
        "name": "877 C",
        "hex": "#8A8D8F"
    },
    {
        "name": "100 C",
        "hex": "#F6EB61"
    },
    {
        "name": "101 C",
        "hex": "#F7EA48"
    },
    {
        "name": "102 C",
        "hex": "#FCE300"
    },
    {
        "name": "103 C",
        "hex": "#C5A900"
    },
    {
        "name": "104 C",
        "hex": "#AF9800"
    },
    {
        "name": "105 C",
        "hex": "#897A27"
    },
    {
        "name": "7401 C",
        "hex": "#F5E1A4"
    },
    {
        "name": "7402 C",
        "hex": "#ECD898"
    },
    {
        "name": "7403 C",
        "hex": "#EED484"
    },
    {
        "name": "7404 C",
        "hex": "#F4DA40"
    },
    {
        "name": "7405 C",
        "hex": "#F2CD00"
    },
    {
        "name": "7406 C",
        "hex": "#F1C400"
    },
    {
        "name": "7407 C",
        "hex": "#CBA052"
    },
    {
        "name": "2001 C",
        "hex": "#F8E59A"
    },
    {
        "name": "2002 C",
        "hex": "#F9E17D"
    },
    {
        "name": "2003 C",
        "hex": "#F9E267"
    },
    {
        "name": "2004 C",
        "hex": "#FBDD7A"
    },
    {
        "name": "2005 C",
        "hex": "#FED880"
    },
    {
        "name": "2006 C",
        "hex": "#EBBC4E"
    },
    {
        "name": "2007 C",
        "hex": "#E0A526"
    },
    {
        "name": "106 C",
        "hex": "#F9E547"
    },
    {
        "name": "107 C",
        "hex": "#FBE122"
    },
    {
        "name": "108 C",
        "hex": "#FEDB00"
    },
    {
        "name": "109 C",
        "hex": "#FFD100"
    },
    {
        "name": "110 C",
        "hex": "#DAAA00"
    },
    {
        "name": "111 C",
        "hex": "#AA8A00"
    },
    {
        "name": "112 C",
        "hex": "#9C8412"
    },
    {
        "name": "113 C",
        "hex": "#FAE053"
    },
    {
        "name": "114 C",
        "hex": "#FBDD40"
    },
    {
        "name": "115 C",
        "hex": "#FDDA24"
    },
    {
        "name": "116 C",
        "hex": "#FFCD00"
    },
    {
        "name": "117 C",
        "hex": "#C99700"
    },
    {
        "name": "118 C",
        "hex": "#AC8400"
    },
    {
        "name": "119 C",
        "hex": "#897322"
    },
    {
        "name": "127 C",
        "hex": "#F3DD6D"
    },
    {
        "name": "128 C",
        "hex": "#F3D54E"
    },
    {
        "name": "129 C",
        "hex": "#F3D03E"
    },
    {
        "name": "130 C",
        "hex": "#F2A900"
    },
    {
        "name": "131 C",
        "hex": "#CC8A00"
    },
    {
        "name": "132 C",
        "hex": "#A07400"
    },
    {
        "name": "133 C",
        "hex": "#6C571B"
    },
    {
        "name": "1205 C",
        "hex": "#F8E08E"
    },
    {
        "name": "1215 C",
        "hex": "#FBD872"
    },
    {
        "name": "1225 C",
        "hex": "#FFC845"
    },
    {
        "name": "1235 C",
        "hex": "#FFB81C"
    },
    {
        "name": "1245 C",
        "hex": "#C69214"
    },
    {
        "name": "1255 C",
        "hex": "#AD841F"
    },
    {
        "name": "1265 C",
        "hex": "#886B25"
    },
    {
        "name": "120 C",
        "hex": "#FBDB65"
    },
    {
        "name": "121 C",
        "hex": "#FDD757"
    },
    {
        "name": "122 C",
        "hex": "#FED141"
    },
    {
        "name": "123 C",
        "hex": "#FFC72C"
    },
    {
        "name": "124 C",
        "hex": "#EAAA00"
    },
    {
        "name": "125 C",
        "hex": "#B58500"
    },
    {
        "name": "126 C",
        "hex": "#9A7611"
    },
    {
        "name": "7548 C",
        "hex": "#FFC600"
    },
    {
        "name": "7549 C",
        "hex": "#FFB500"
    },
    {
        "name": "7550 C",
        "hex": "#D19000"
    },
    {
        "name": "7551 C",
        "hex": "#B47E00"
    },
    {
        "name": "7552 C",
        "hex": "#73531D"
    },
    {
        "name": "7553 C",
        "hex": "#5A4522"
    },
    {
        "name": "7554 C",
        "hex": "#4B3D2A"
    },
    {
        "name": "7555 C",
        "hex": "#D29F13"
    },
    {
        "name": "7556 C",
        "hex": "#B78B20"
    },
    {
        "name": "7557 C",
        "hex": "#9F7D23"
    },
    {
        "name": "7558 C",
        "hex": "#967126"
    },
    {
        "name": "7559 C",
        "hex": "#8F6A2A"
    },
    {
        "name": "7560 C",
        "hex": "#7D622E"
    },
    {
        "name": "7561 C",
        "hex": "#6C5D34"
    },
    {
        "name": "134 C",
        "hex": "#FDD26E"
    },
    {
        "name": "135 C",
        "hex": "#FFC658"
    },
    {
        "name": "136 C",
        "hex": "#FFBF3F"
    },
    {
        "name": "137 C",
        "hex": "#FFA300"
    },
    {
        "name": "138 C",
        "hex": "#DE7C00"
    },
    {
        "name": "139 C",
        "hex": "#AF6D04"
    },
    {
        "name": "140 C",
        "hex": "#74531C"
    },
    {
        "name": "2008 C",
        "hex": "#EFC06E"
    },
    {
        "name": "2009 C",
        "hex": "#EFB661"
    },
    {
        "name": "2010 C",
        "hex": "#FFAD00"
    },
    {
        "name": "2011 C",
        "hex": "#ED9B33"
    },
    {
        "name": "2012 C",
        "hex": "#EF9600"
    },
    {
        "name": "2013 C",
        "hex": "#FF9800"
    },
    {
        "name": "2014 C",
        "hex": "#B97000"
    },
    {
        "name": "1345 C",
        "hex": "#FDD086"
    },
    {
        "name": "1355 C",
        "hex": "#FFC56E"
    },
    {
        "name": "1365 C",
        "hex": "#FFB549"
    },
    {
        "name": "1375 C",
        "hex": "#FF9E1B"
    },
    {
        "name": "1385 C",
        "hex": "#D57800"
    },
    {
        "name": "1395 C",
        "hex": "#996017"
    },
    {
        "name": "1405 C",
        "hex": "#6E4C1E"
    },
    {
        "name": "141 C",
        "hex": "#F2C75C"
    },
    {
        "name": "142 C",
        "hex": "#F1BE48"
    },
    {
        "name": "143 C",
        "hex": "#F1B434"
    },
    {
        "name": "144 C",
        "hex": "#ED8B00"
    },
    {
        "name": "145 C",
        "hex": "#CF7F00"
    },
    {
        "name": "146 C",
        "hex": "#A76D11"
    },
    {
        "name": "147 C",
        "hex": "#715C2A"
    },
    {
        "name": "7408 C",
        "hex": "#F6BE00"
    },
    {
        "name": "7409 C",
        "hex": "#F0B323"
    },
    {
        "name": "7410 C",
        "hex": "#FEAD77"
    },
    {
        "name": "7411 C",
        "hex": "#E6A65D"
    },
    {
        "name": "7412 C",
        "hex": "#D38235"
    },
    {
        "name": "7413 C",
        "hex": "#DC8633"
    },
    {
        "name": "7414 C",
        "hex": "#C16C18"
    },
    {
        "name": "7562 C",
        "hex": "#BD9B60"
    },
    {
        "name": "7563 C",
        "hex": "#D69A2D"
    },
    {
        "name": "7564 C",
        "hex": "#DB8A06"
    },
    {
        "name": "7565 C",
        "hex": "#CD7925"
    },
    {
        "name": "7566 C",
        "hex": "#AD6433"
    },
    {
        "name": "7567 C",
        "hex": "#89532F"
    },
    {
        "name": "7568 C",
        "hex": "#775135"
    },
    {
        "name": "7569 C",
        "hex": "#D78825"
    },
    {
        "name": "7570 C",
        "hex": "#D3832B"
    },
    {
        "name": "7571 C",
        "hex": "#C67D30"
    },
    {
        "name": "7572 C",
        "hex": "#B67233"
    },
    {
        "name": "7573 C",
        "hex": "#A7662B"
    },
    {
        "name": "7574 C",
        "hex": "#9E6A38"
    },
    {
        "name": "7575 C",
        "hex": "#835D32"
    },
    {
        "name": "712 C",
        "hex": "#FCC89B"
    },
    {
        "name": "713 C",
        "hex": "#FDBE87"
    },
    {
        "name": "714 C",
        "hex": "#FDAA63"
    },
    {
        "name": "715 C",
        "hex": "#F68D2E"
    },
    {
        "name": "716 C",
        "hex": "#EA7600"
    },
    {
        "name": "717 C",
        "hex": "#D45D00"
    },
    {
        "name": "718 C",
        "hex": "#BE4D00"
    },
    {
        "name": "2015 C",
        "hex": "#F8CFA9"
    },
    {
        "name": "2016 C",
        "hex": "#FFB673"
    },
    {
        "name": "2017 C",
        "hex": "#F8AD6D"
    },
    {
        "name": "2018 C",
        "hex": "#FF7500"
    },
    {
        "name": "2019 C",
        "hex": "#D76B00"
    },
    {
        "name": "2020 C",
        "hex": "#BE5400"
    },
    {
        "name": "2021 C",
        "hex": "#9D4815"
    },
    {
        "name": "148 C",
        "hex": "#FECB8B"
    },
    {
        "name": "149 C",
        "hex": "#FFC27B"
    },
    {
        "name": "150 C",
        "hex": "#FFB25B"
    },
    {
        "name": "151 C",
        "hex": "#FF8200"
    },
    {
        "name": "152 C",
        "hex": "#E57200"
    },
    {
        "name": "153 C",
        "hex": "#BE6A14"
    },
    {
        "name": "154 C",
        "hex": "#9B5A1A"
    },
    {
        "name": "155 C",
        "hex": "#EFD19F"
    },
    {
        "name": "156 C",
        "hex": "#EFBE7D"
    },
    {
        "name": "157 C",
        "hex": "#ECA154"
    },
    {
        "name": "158 C",
        "hex": "#E87722"
    },
    {
        "name": "159 C",
        "hex": "#CB6015"
    },
    {
        "name": "160 C",
        "hex": "#A1561C"
    },
    {
        "name": "161 C",
        "hex": "#603D20"
    },
    {
        "name": "1485 C",
        "hex": "#FFAE62"
    },
    {
        "name": "1495 C",
        "hex": "#FF8F1C"
    },
    {
        "name": "1505 C",
        "hex": "#FF6900"
    },
    {
        "name": "1525 C",
        "hex": "#B94700"
    },
    {
        "name": "1535 C",
        "hex": "#94450B"
    },
    {
        "name": "1545 C",
        "hex": "#653819"
    },
    {
        "name": "1555 C",
        "hex": "#FFB990"
    },
    {
        "name": "1565 C",
        "hex": "#FFA06A"
    },
    {
        "name": "1575 C",
        "hex": "#FF7F32"
    },
    {
        "name": "1585 C",
        "hex": "#FF6A13"
    },
    {
        "name": "1595 C",
        "hex": "#D86018"
    },
    {
        "name": "1605 C",
        "hex": "#A65523"
    },
    {
        "name": "1615 C",
        "hex": "#8B4720"
    },
    {
        "name": "2022 C",
        "hex": "#FAAA8D"
    },
    {
        "name": "2023 C",
        "hex": "#FA9370"
    },
    {
        "name": "2024 C",
        "hex": "#F87C56"
    },
    {
        "name": "2025 C",
        "hex": "#FF8A3D"
    },
    {
        "name": "2026 C",
        "hex": "#F4633A"
    },
    {
        "name": "2027 C",
        "hex": "#EE5340"
    },
    {
        "name": "2028 C",
        "hex": "#EB3300"
    },
    {
        "name": "162 C",
        "hex": "#FFBE9F"
    },
    {
        "name": "163 C",
        "hex": "#FF9D6E"
    },
    {
        "name": "164 C",
        "hex": "#FF7F41"
    },
    {
        "name": "165 C",
        "hex": "#FF671F"
    },
    {
        "name": "166 C",
        "hex": "#E35205"
    },
    {
        "name": "167 C",
        "hex": "#BE531C"
    },
    {
        "name": "168 C",
        "hex": "#73381D"
    },
    {
        "name": "7576 C",
        "hex": "#DB864E"
    },
    {
        "name": "7577 C",
        "hex": "#E07E3C"
    },
    {
        "name": "7578 C",
        "hex": "#DC6B2F"
    },
    {
        "name": "7579 C",
        "hex": "#DC582A"
    },
    {
        "name": "7580 C",
        "hex": "#C05131"
    },
    {
        "name": "7581 C",
        "hex": "#864A33"
    },
    {
        "name": "7582 C",
        "hex": "#674736"
    },
    {
        "name": "1625 C",
        "hex": "#FFA38B"
    },
    {
        "name": "1635 C",
        "hex": "#FF8D6D"
    },
    {
        "name": "1645 C",
        "hex": "#FF6A39"
    },
    {
        "name": "1655 C",
        "hex": "#FC4C02"
    },
    {
        "name": "1665 C",
        "hex": "#DC4405"
    },
    {
        "name": "1675 C",
        "hex": "#A9431E"
    },
    {
        "name": "1685 C",
        "hex": "#833921"
    },
    {
        "name": "169 C",
        "hex": "#FFB3AB"
    },
    {
        "name": "170 C",
        "hex": "#FF8674"
    },
    {
        "name": "171 C",
        "hex": "#FF5C39"
    },
    {
        "name": "172 C",
        "hex": "#FA4616"
    },
    {
        "name": "173 C",
        "hex": "#CF4520"
    },
    {
        "name": "174 C",
        "hex": "#963821"
    },
    {
        "name": "175 C",
        "hex": "#6B3529"
    },
    {
        "name": "7583 C",
        "hex": "#C4622D"
    },
    {
        "name": "7584 C",
        "hex": "#BA5826"
    },
    {
        "name": "7585 C",
        "hex": "#AF5C37"
    },
    {
        "name": "7586 C",
        "hex": "#9E5330"
    },
    {
        "name": "7587 C",
        "hex": "#924C2E"
    },
    {
        "name": "7588 C",
        "hex": "#7B4D35"
    },
    {
        "name": "7589 C",
        "hex": "#5C4738"
    },
    {
        "name": "7590 C",
        "hex": "#D4B59E"
    },
    {
        "name": "7591 C",
        "hex": "#C07D59"
    },
    {
        "name": "7592 C",
        "hex": "#B15533"
    },
    {
        "name": "7593 C",
        "hex": "#9D432C"
    },
    {
        "name": "7594 C",
        "hex": "#7C3A2D"
    },
    {
        "name": "7595 C",
        "hex": "#6B3D2E"
    },
    {
        "name": "7596 C",
        "hex": "#5C3D31"
    },
    {
        "name": "7597 C",
        "hex": "#D14124"
    },
    {
        "name": "7598 C",
        "hex": "#BD472A"
    },
    {
        "name": "7599 C",
        "hex": "#B33D26"
    },
    {
        "name": "7600 C",
        "hex": "#8D3F2B"
    },
    {
        "name": "7601 C",
        "hex": "#83412C"
    },
    {
        "name": "7602 C",
        "hex": "#7B4931"
    },
    {
        "name": "7603 C",
        "hex": "#674230"
    },
    {
        "name": "7604 C",
        "hex": "#E4D5D3"
    },
    {
        "name": "7605 C",
        "hex": "#E1BBB4"
    },
    {
        "name": "7606 C",
        "hex": "#D6938A"
    },
    {
        "name": "7607 C",
        "hex": "#C26E60"
    },
    {
        "name": "7608 C",
        "hex": "#A4493D"
    },
    {
        "name": "7609 C",
        "hex": "#823B34"
    },
    {
        "name": "7610 C",
        "hex": "#683431"
    },
    {
        "name": "2337 C",
        "hex": "#F8C1B8"
    },
    {
        "name": "2338 C",
        "hex": "#DEA39C"
    },
    {
        "name": "2339 C",
        "hex": "#F09491"
    },
    {
        "name": "2340 C",
        "hex": "#CF6F77"
    },
    {
        "name": "2341 C",
        "hex": "#B66C6D"
    },
    {
        "name": "2342 C",
        "hex": "#B65A65"
    },
    {
        "name": "2343 C",
        "hex": "#A85566"
    },
    {
        "name": "7611 C",
        "hex": "#DDBCB0"
    },
    {
        "name": "7612 C",
        "hex": "#CA9A8E"
    },
    {
        "name": "7613 C",
        "hex": "#BC8A7E"
    },
    {
        "name": "7614 C",
        "hex": "#A37F74"
    },
    {
        "name": "7615 C",
        "hex": "#866761"
    },
    {
        "name": "7616 C",
        "hex": "#6B4C4C"
    },
    {
        "name": "7617 C",
        "hex": "#583D3E"
    },
    {
        "name": "7520 C",
        "hex": "#EABEB0"
    },
    {
        "name": "7521 C",
        "hex": "#C09C83"
    },
    {
        "name": "7522 C",
        "hex": "#B46A55"
    },
    {
        "name": "7523 C",
        "hex": "#AB5C57"
    },
    {
        "name": "7524 C",
        "hex": "#A45248"
    },
    {
        "name": "7525 C",
        "hex": "#9A6A4F"
    },
    {
        "name": "7526 C",
        "hex": "#8A391B"
    },
    {
        "name": "489 C",
        "hex": "#ECC3B2"
    },
    {
        "name": "488 C",
        "hex": "#ECBAA8"
    },
    {
        "name": "487 C",
        "hex": "#EAA794"
    },
    {
        "name": "486 C",
        "hex": "#E8927C"
    },
    {
        "name": "485 C",
        "hex": "#DA291C"
    },
    {
        "name": "484 C",
        "hex": "#9A3324"
    },
    {
        "name": "483 C",
        "hex": "#653024"
    },
    {
        "name": "2029 C",
        "hex": "#F2827F"
    },
    {
        "name": "2030 C",
        "hex": "#DD7975"
    },
    {
        "name": "2031 C",
        "hex": "#D66965"
    },
    {
        "name": "2032 C",
        "hex": "#D05A57"
    },
    {
        "name": "2033 C",
        "hex": "#C54644"
    },
    {
        "name": "2034 C",
        "hex": "#E73C3E"
    },
    {
        "name": "2035 C",
        "hex": "#D6001C"
    },
    {
        "name": "2344 C",
        "hex": "#F18070"
    },
    {
        "name": "2345 C",
        "hex": "#FF6D6A"
    },
    {
        "name": "2346 C",
        "hex": "#FF5869"
    },
    {
        "name": "2347 C",
        "hex": "#E10600"
    },
    {
        "name": "2348 C",
        "hex": "#E5554F"
    },
    {
        "name": "2349 C",
        "hex": "#CA3604"
    },
    {
        "name": "2350 C",
        "hex": "#AF231C"
    },
    {
        "name": "176 C",
        "hex": "#FFB1BB"
    },
    {
        "name": "177 C",
        "hex": "#FF808B"
    },
    {
        "name": "178 C",
        "hex": "#FF585D"
    },
    {
        "name": "179 C",
        "hex": "#E03C31"
    },
    {
        "name": "180 C",
        "hex": "#BE3A34"
    },
    {
        "name": "181 C",
        "hex": "#81312F"
    },
    {
        "name": "1765 C",
        "hex": "#FFA3B5"
    },
    {
        "name": "1775 C",
        "hex": "#FF8DA1"
    },
    {
        "name": "1785 C",
        "hex": "#F8485E"
    },
    {
        "name": "1788 C",
        "hex": "#EE2737"
    },
    {
        "name": "1795 C",
        "hex": "#D22630"
    },
    {
        "name": "1805 C",
        "hex": "#AF272F"
    },
    {
        "name": "1815 C",
        "hex": "#7C2529"
    },
    {
        "name": "1767 C",
        "hex": "#FCAFC0"
    },
    {
        "name": "1777 C",
        "hex": "#FB637E"
    },
    {
        "name": "1787 C",
        "hex": "#F4364C"
    },
    {
        "name": "1797 C",
        "hex": "#CB333B"
    },
    {
        "name": "1807 C",
        "hex": "#A4343A"
    },
    {
        "name": "1817 C",
        "hex": "#643335"
    },
    {
        "name": "7618 C",
        "hex": "#C66E4E"
    },
    {
        "name": "7619 C",
        "hex": "#C04C36"
    },
    {
        "name": "7620 C",
        "hex": "#B7312C"
    },
    {
        "name": "7621 C",
        "hex": "#AB2328"
    },
    {
        "name": "7622 C",
        "hex": "#93272C"
    },
    {
        "name": "7623 C",
        "hex": "#8A2A2B"
    },
    {
        "name": "7624 C",
        "hex": "#802F2D"
    },
    {
        "name": "7625 C",
        "hex": "#E1523D"
    },
    {
        "name": "7626 C",
        "hex": "#C63527"
    },
    {
        "name": "7627 C",
        "hex": "#A72B2A"
    },
    {
        "name": "7628 C",
        "hex": "#9E2A2B"
    },
    {
        "name": "7629 C",
        "hex": "#6D3332"
    },
    {
        "name": "7630 C",
        "hex": "#633231"
    },
    {
        "name": "7631 C",
        "hex": "#572D2D"
    },
    {
        "name": "7415 C",
        "hex": "#E6BAA8"
    },
    {
        "name": "7416 C",
        "hex": "#E56A54"
    },
    {
        "name": "7417 C",
        "hex": "#E04E39"
    },
    {
        "name": "7418 C",
        "hex": "#CD545B"
    },
    {
        "name": "7419 C",
        "hex": "#B04A5A"
    },
    {
        "name": "7420 C",
        "hex": "#9B2242"
    },
    {
        "name": "7421 C",
        "hex": "#651D32"
    },
    {
        "name": "182 C",
        "hex": "#FABBCB"
    },
    {
        "name": "183 C",
        "hex": "#FC9BB3"
    },
    {
        "name": "184 C",
        "hex": "#F65275"
    },
    {
        "name": "185 C",
        "hex": "#E4002B"
    },
    {
        "name": "186 C",
        "hex": "#C8102E"
    },
    {
        "name": "187 C",
        "hex": "#A6192E"
    },
    {
        "name": "188 C",
        "hex": "#76232F"
    },
    {
        "name": "196 C",
        "hex": "#ECC7CD"
    },
    {
        "name": "197 C",
        "hex": "#E89CAE"
    },
    {
        "name": "198 C",
        "hex": "#DF4661"
    },
    {
        "name": "199 C",
        "hex": "#D50032"
    },
    {
        "name": "200 C",
        "hex": "#BA0C2F"
    },
    {
        "name": "201 C",
        "hex": "#9D2235"
    },
    {
        "name": "202 C",
        "hex": "#862633"
    },
    {
        "name": "189 C",
        "hex": "#F8A3BC"
    },
    {
        "name": "190 C",
        "hex": "#F67599"
    },
    {
        "name": "191 C",
        "hex": "#EF426F"
    },
    {
        "name": "192 C",
        "hex": "#E40046"
    },
    {
        "name": "193 C",
        "hex": "#BF0D3E"
    },
    {
        "name": "194 C",
        "hex": "#9B2743"
    },
    {
        "name": "195 C",
        "hex": "#782F40"
    },
    {
        "name": "1895 C",
        "hex": "#F5B6CD"
    },
    {
        "name": "1905 C",
        "hex": "#F59BBB"
    },
    {
        "name": "1915 C",
        "hex": "#EF4A81"
    },
    {
        "name": "1925 C",
        "hex": "#E0004D"
    },
    {
        "name": "1935 C",
        "hex": "#C5003E"
    },
    {
        "name": "1945 C",
        "hex": "#A6093D"
    },
    {
        "name": "1955 C",
        "hex": "#8A1538"
    },
    {
        "name": "705 C",
        "hex": "#F5DADF"
    },
    {
        "name": "706 C",
        "hex": "#F7CED7"
    },
    {
        "name": "707 C",
        "hex": "#F9B5C4"
    },
    {
        "name": "708 C",
        "hex": "#F890A5"
    },
    {
        "name": "709 C",
        "hex": "#EF6079"
    },
    {
        "name": "710 C",
        "hex": "#E03E52"
    },
    {
        "name": "711 C",
        "hex": "#CB2C30"
    },
    {
        "name": "698 C",
        "hex": "#F2D4D7"
    },
    {
        "name": "699 C",
        "hex": "#F4C3CC"
    },
    {
        "name": "700 C",
        "hex": "#F2ACB9"
    },
    {
        "name": "701 C",
        "hex": "#E68699"
    },
    {
        "name": "702 C",
        "hex": "#D25B73"
    },
    {
        "name": "703 C",
        "hex": "#B83A4B"
    },
    {
        "name": "704 C",
        "hex": "#9E2A2F"
    },
    {
        "name": "203 C",
        "hex": "#ECB3CB"
    },
    {
        "name": "204 C",
        "hex": "#E782A9"
    },
    {
        "name": "205 C",
        "hex": "#E0457B"
    },
    {
        "name": "206 C",
        "hex": "#CE0037"
    },
    {
        "name": "207 C",
        "hex": "#A50034"
    },
    {
        "name": "208 C",
        "hex": "#861F41"
    },
    {
        "name": "209 C",
        "hex": "#6F263D"
    },
    {
        "name": "210 C",
        "hex": "#F99FC9"
    },
    {
        "name": "211 C",
        "hex": "#F57EB6"
    },
    {
        "name": "212 C",
        "hex": "#F04E98"
    },
    {
        "name": "213 C",
        "hex": "#E31C79"
    },
    {
        "name": "214 C",
        "hex": "#CE0F69"
    },
    {
        "name": "215 C",
        "hex": "#AC145A"
    },
    {
        "name": "216 C",
        "hex": "#7D2248"
    },
    {
        "name": "2036 C",
        "hex": "#F8BED6"
    },
    {
        "name": "2037 C",
        "hex": "#F395C7"
    },
    {
        "name": "2038 C",
        "hex": "#EF60A3"
    },
    {
        "name": "2039 C",
        "hex": "#E63888"
    },
    {
        "name": "2040 C",
        "hex": "#DB0A5B"
    },
    {
        "name": "2041 C",
        "hex": "#A1224E"
    },
    {
        "name": "2042 C",
        "hex": "#70273D"
    },
    {
        "name": "7422 C",
        "hex": "#F4CDD4"
    },
    {
        "name": "7423 C",
        "hex": "#E06287"
    },
    {
        "name": "7424 C",
        "hex": "#E24585"
    },
    {
        "name": "7425 C",
        "hex": "#B52555"
    },
    {
        "name": "7426 C",
        "hex": "#A4123F"
    },
    {
        "name": "7427 C",
        "hex": "#971B2F"
    },
    {
        "name": "7428 C",
        "hex": "#6A2C3E"
    },
    {
        "name": "7632 C",
        "hex": "#D6C9CA"
    },
    {
        "name": "7633 C",
        "hex": "#C4A4A7"
    },
    {
        "name": "7634 C",
        "hex": "#C16784"
    },
    {
        "name": "7635 C",
        "hex": "#C63663"
    },
    {
        "name": "7636 C",
        "hex": "#BC204B"
    },
    {
        "name": "7637 C",
        "hex": "#912F46"
    },
    {
        "name": "7638 C",
        "hex": "#7E2D40"
    },
    {
        "name": "217 C",
        "hex": "#EABEDB"
    },
    {
        "name": "218 C",
        "hex": "#E56DB1"
    },
    {
        "name": "219 C",
        "hex": "#DA1884"
    },
    {
        "name": "220 C",
        "hex": "#A50050"
    },
    {
        "name": "221 C",
        "hex": "#910048"
    },
    {
        "name": "222 C",
        "hex": "#6C1D45"
    },
    {
        "name": "7639 C",
        "hex": "#936D73"
    },
    {
        "name": "7640 C",
        "hex": "#934054"
    },
    {
        "name": "7641 C",
        "hex": "#8E2C48"
    },
    {
        "name": "7642 C",
        "hex": "#732E4A"
    },
    {
        "name": "7643 C",
        "hex": "#672E45"
    },
    {
        "name": "7644 C",
        "hex": "#582D40"
    },
    {
        "name": "7645 C",
        "hex": "#502B3A"
    },
    {
        "name": "223 C",
        "hex": "#EF95CF"
    },
    {
        "name": "224 C",
        "hex": "#EB6FBD"
    },
    {
        "name": "225 C",
        "hex": "#DF1995"
    },
    {
        "name": "226 C",
        "hex": "#D0006F"
    },
    {
        "name": "227 C",
        "hex": "#AA0061"
    },
    {
        "name": "228 C",
        "hex": "#890C58"
    },
    {
        "name": "229 C",
        "hex": "#672146"
    },
    {
        "name": "230 C",
        "hex": "#F4A6D7"
    },
    {
        "name": "231 C",
        "hex": "#F277C6"
    },
    {
        "name": "232 C",
        "hex": "#E93CAC"
    },
    {
        "name": "233 C",
        "hex": "#C6007E"
    },
    {
        "name": "234 C",
        "hex": "#A20067"
    },
    {
        "name": "235 C",
        "hex": "#840B55"
    },
    {
        "name": "670 C",
        "hex": "#EAD3E2"
    },
    {
        "name": "671 C",
        "hex": "#E6BCD8"
    },
    {
        "name": "672 C",
        "hex": "#DFA0C9"
    },
    {
        "name": "673 C",
        "hex": "#D986BA"
    },
    {
        "name": "674 C",
        "hex": "#C6579A"
    },
    {
        "name": "675 C",
        "hex": "#AE2573"
    },
    {
        "name": "676 C",
        "hex": "#960051"
    },
    {
        "name": "2043 C",
        "hex": "#EAC4D2"
    },
    {
        "name": "2044 C",
        "hex": "#E793B7"
    },
    {
        "name": "2045 C",
        "hex": "#DD74A1"
    },
    {
        "name": "2046 C",
        "hex": "#CF578A"
    },
    {
        "name": "2047 C",
        "hex": "#A54570"
    },
    {
        "name": "2048 C",
        "hex": "#973961"
    },
    {
        "name": "2049 C",
        "hex": "#852C4D"
    },
    {
        "name": "677 C",
        "hex": "#E5CEDB"
    },
    {
        "name": "678 C",
        "hex": "#E3C8D8"
    },
    {
        "name": "679 C",
        "hex": "#DEBED2"
    },
    {
        "name": "680 C",
        "hex": "#C996B6"
    },
    {
        "name": "681 C",
        "hex": "#B06C96"
    },
    {
        "name": "682 C",
        "hex": "#994878"
    },
    {
        "name": "683 C",
        "hex": "#7C2855"
    },
    {
        "name": "2050 C",
        "hex": "#EFD7E5"
    },
    {
        "name": "2051 C",
        "hex": "#DDC2CF"
    },
    {
        "name": "2052 C",
        "hex": "#C89FB6"
    },
    {
        "name": "2053 C",
        "hex": "#B3809D"
    },
    {
        "name": "2054 C",
        "hex": "#9E708B"
    },
    {
        "name": "2055 C",
        "hex": "#905F7B"
    },
    {
        "name": "2056 C",
        "hex": "#7E4966"
    },
    {
        "name": "684 C",
        "hex": "#E4C6D4"
    },
    {
        "name": "685 C",
        "hex": "#DCB6C9"
    },
    {
        "name": "686 C",
        "hex": "#D0A1BA"
    },
    {
        "name": "687 C",
        "hex": "#BE84A3"
    },
    {
        "name": "688 C",
        "hex": "#A76389"
    },
    {
        "name": "689 C",
        "hex": "#893B67"
    },
    {
        "name": "690 C",
        "hex": "#612141"
    },
    {
        "name": "510 C",
        "hex": "#EBBECB"
    },
    {
        "name": "509 C",
        "hex": "#E8B3C3"
    },
    {
        "name": "508 C",
        "hex": "#E4A9BB"
    },
    {
        "name": "507 C",
        "hex": "#D592AA"
    },
    {
        "name": "506 C",
        "hex": "#84344E"
    },
    {
        "name": "505 C",
        "hex": "#6F2C3F"
    },
    {
        "name": "504 C",
        "hex": "#572932"
    },
    {
        "name": "7429 C",
        "hex": "#E2BCCB"
    },
    {
        "name": "7430 C",
        "hex": "#DCA9BF"
    },
    {
        "name": "7431 C",
        "hex": "#C9809E"
    },
    {
        "name": "7432 C",
        "hex": "#B55C80"
    },
    {
        "name": "7433 C",
        "hex": "#A73A64"
    },
    {
        "name": "7434 C",
        "hex": "#9B3259"
    },
    {
        "name": "7435 C",
        "hex": "#872651"
    },
    {
        "name": "691 C",
        "hex": "#E9CDD0"
    },
    {
        "name": "692 C",
        "hex": "#E4BEC3"
    },
    {
        "name": "693 C",
        "hex": "#D7A3AB"
    },
    {
        "name": "694 C",
        "hex": "#C48490"
    },
    {
        "name": "695 C",
        "hex": "#B46B7A"
    },
    {
        "name": "696 C",
        "hex": "#984856"
    },
    {
        "name": "697 C",
        "hex": "#893C47"
    },
    {
        "name": "496 C",
        "hex": "#F2C6CF"
    },
    {
        "name": "495 C",
        "hex": "#F1BDC8"
    },
    {
        "name": "494 C",
        "hex": "#E9A2B2"
    },
    {
        "name": "493 C",
        "hex": "#DC8699"
    },
    {
        "name": "492 C",
        "hex": "#8F3237"
    },
    {
        "name": "491 C",
        "hex": "#7F3035"
    },
    {
        "name": "490 C",
        "hex": "#5D2A2C"
    },
    {
        "name": "503 C",
        "hex": "#E9C4C7"
    },
    {
        "name": "502 C",
        "hex": "#E5BAC1"
    },
    {
        "name": "501 C",
        "hex": "#DAA5AD"
    },
    {
        "name": "500 C",
        "hex": "#C6858F"
    },
    {
        "name": "499 C",
        "hex": "#7A3E3A"
    },
    {
        "name": "498 C",
        "hex": "#6A3735"
    },
    {
        "name": "497 C",
        "hex": "#512F2E"
    },
    {
        "name": "5035 C",
        "hex": "#DFC2C3"
    },
    {
        "name": "5025 C",
        "hex": "#DBB7BB"
    },
    {
        "name": "5015 C",
        "hex": "#CCA1A6"
    },
    {
        "name": "5005 C",
        "hex": "#B07C83"
    },
    {
        "name": "4995 C",
        "hex": "#9C6169"
    },
    {
        "name": "4985 C",
        "hex": "#874B52"
    },
    {
        "name": "4975 C",
        "hex": "#3F2021"
    },
    {
        "name": "236 C",
        "hex": "#F1A7DC"
    },
    {
        "name": "237 C",
        "hex": "#EC86D0"
    },
    {
        "name": "238 C",
        "hex": "#E45DBF"
    },
    {
        "name": "239 C",
        "hex": "#DB3EB1"
    },
    {
        "name": "240 C",
        "hex": "#C5299B"
    },
    {
        "name": "241 C",
        "hex": "#AF1685"
    },
    {
        "name": "242 C",
        "hex": "#80225F"
    },
    {
        "name": "2057 C",
        "hex": "#CF92B7"
    },
    {
        "name": "2058 C",
        "hex": "#BB85AB"
    },
    {
        "name": "2059 C",
        "hex": "#B87BA1"
    },
    {
        "name": "2060 C",
        "hex": "#C86BA8"
    },
    {
        "name": "2061 C",
        "hex": "#AD5389"
    },
    {
        "name": "2062 C",
        "hex": "#B4458D"
    },
    {
        "name": "2063 C",
        "hex": "#A5307C"
    },
    {
        "name": "2365 C",
        "hex": "#EFBAE1"
    },
    {
        "name": "2375 C",
        "hex": "#E277CD"
    },
    {
        "name": "2385 C",
        "hex": "#D539B5"
    },
    {
        "name": "2395 C",
        "hex": "#C800A1"
    },
    {
        "name": "2405 C",
        "hex": "#B0008E"
    },
    {
        "name": "2415 C",
        "hex": "#9E007E"
    },
    {
        "name": "2425 C",
        "hex": "#830065"
    },
    {
        "name": "243 C",
        "hex": "#EAB8E4"
    },
    {
        "name": "244 C",
        "hex": "#E59BDC"
    },
    {
        "name": "245 C",
        "hex": "#DD7FD3"
    },
    {
        "name": "246 C",
        "hex": "#C724B1"
    },
    {
        "name": "247 C",
        "hex": "#BB16A3"
    },
    {
        "name": "248 C",
        "hex": "#A51890"
    },
    {
        "name": "249 C",
        "hex": "#80276C"
    },
    {
        "name": "7646 C",
        "hex": "#A56E87"
    },
    {
        "name": "7647 C",
        "hex": "#A83D72"
    },
    {
        "name": "7648 C",
        "hex": "#991E66"
    },
    {
        "name": "7649 C",
        "hex": "#8A1B61"
    },
    {
        "name": "7650 C",
        "hex": "#722257"
    },
    {
        "name": "7651 C",
        "hex": "#6A2A5B"
    },
    {
        "name": "7652 C",
        "hex": "#5E2751"
    },
    {
        "name": "250 C",
        "hex": "#E7BAE4"
    },
    {
        "name": "251 C",
        "hex": "#DD9CDF"
    },
    {
        "name": "252 C",
        "hex": "#C964CF"
    },
    {
        "name": "253 C",
        "hex": "#AD1AAC"
    },
    {
        "name": "254 C",
        "hex": "#981D97"
    },
    {
        "name": "255 C",
        "hex": "#72246C"
    },
    {
        "name": "2064 C",
        "hex": "#E0A2D4"
    },
    {
        "name": "2065 C",
        "hex": "#DEA8DD"
    },
    {
        "name": "2066 C",
        "hex": "#D28DD3"
    },
    {
        "name": "2067 C",
        "hex": "#C069C2"
    },
    {
        "name": "2068 C",
        "hex": "#B14EB5"
    },
    {
        "name": "2069 C",
        "hex": "#A438A8"
    },
    {
        "name": "2070 C",
        "hex": "#8F1A95"
    },
    {
        "name": "517 C",
        "hex": "#EBC6DF"
    },
    {
        "name": "516 C",
        "hex": "#E6BEDD"
    },
    {
        "name": "515 C",
        "hex": "#E2ACD7"
    },
    {
        "name": "514 C",
        "hex": "#D48BC8"
    },
    {
        "name": "513 C",
        "hex": "#93328E"
    },
    {
        "name": "512 C",
        "hex": "#833177"
    },
    {
        "name": "511 C",
        "hex": "#612C51"
    },
    {
        "name": "2351 C",
        "hex": "#BD7AB3"
    },
    {
        "name": "2352 C",
        "hex": "#B565A7"
    },
    {
        "name": "2353 C",
        "hex": "#BF4DA5"
    },
    {
        "name": "2354 C",
        "hex": "#924E8C"
    },
    {
        "name": "2355 C",
        "hex": "#87027B"
    },
    {
        "name": "2356 C",
        "hex": "#720062"
    },
    {
        "name": "2357 C",
        "hex": "#621244"
    },
    {
        "name": "7653 C",
        "hex": "#948794"
    },
    {
        "name": "7654 C",
        "hex": "#A2789C"
    },
    {
        "name": "7655 C",
        "hex": "#A15A95"
    },
    {
        "name": "7656 C",
        "hex": "#8E3A80"
    },
    {
        "name": "7657 C",
        "hex": "#6E2B62"
    },
    {
        "name": "7658 C",
        "hex": "#6A3460"
    },
    {
        "name": "7659 C",
        "hex": "#5D3754"
    },
    {
        "name": "5175 C",
        "hex": "#D8C8D1"
    },
    {
        "name": "5165 C",
        "hex": "#D3C0CD"
    },
    {
        "name": "5155 C",
        "hex": "#BFA5B8"
    },
    {
        "name": "5145 C",
        "hex": "#9B7793"
    },
    {
        "name": "5135 C",
        "hex": "#7E5475"
    },
    {
        "name": "5125 C",
        "hex": "#693C5E"
    },
    {
        "name": "5115 C",
        "hex": "#512A44"
    },
    {
        "name": "5245 C",
        "hex": "#DBCDD3"
    },
    {
        "name": "5235 C",
        "hex": "#D0BEC7"
    },
    {
        "name": "5225 C",
        "hex": "#C6B0BC"
    },
    {
        "name": "5215 C",
        "hex": "#AF95A6"
    },
    {
        "name": "5205 C",
        "hex": "#86647A"
    },
    {
        "name": "5195 C",
        "hex": "#66435A"
    },
    {
        "name": "5185 C",
        "hex": "#4A3041"
    },
    {
        "name": "524 C",
        "hex": "#D5C2D8"
    },
    {
        "name": "523 C",
        "hex": "#C9B1D0"
    },
    {
        "name": "522 C",
        "hex": "#BA9CC5"
    },
    {
        "name": "521 C",
        "hex": "#A57FB2"
    },
    {
        "name": "520 C",
        "hex": "#642F6C"
    },
    {
        "name": "519 C",
        "hex": "#59315F"
    },
    {
        "name": "518 C",
        "hex": "#4B3048"
    },
    {
        "name": "7436 C",
        "hex": "#EEDAEA"
    },
    {
        "name": "7437 C",
        "hex": "#CCAED0"
    },
    {
        "name": "7438 C",
        "hex": "#D59ED7"
    },
    {
        "name": "7439 C",
        "hex": "#B288B9"
    },
    {
        "name": "7440 C",
        "hex": "#A277A6"
    },
    {
        "name": "7441 C",
        "hex": "#9F5CC0"
    },
    {
        "name": "7442 C",
        "hex": "#963CBD"
    },
    {
        "name": "2562 C",
        "hex": "#D7A9E3"
    },
    {
        "name": "2572 C",
        "hex": "#C98BDB"
    },
    {
        "name": "2582 C",
        "hex": "#AC4FC6"
    },
    {
        "name": "2592 C",
        "hex": "#9B26B6"
    },
    {
        "name": "2602 C",
        "hex": "#87189D"
    },
    {
        "name": "2612 C",
        "hex": "#772583"
    },
    {
        "name": "2622 C",
        "hex": "#653165"
    },
    {
        "name": "531 C",
        "hex": "#DFC8E7"
    },
    {
        "name": "530 C",
        "hex": "#D7B9E4"
    },
    {
        "name": "529 C",
        "hex": "#CAA2DD"
    },
    {
        "name": "528 C",
        "hex": "#B580D1"
    },
    {
        "name": "527 C",
        "hex": "#8031A7"
    },
    {
        "name": "526 C",
        "hex": "#702F8A"
    },
    {
        "name": "525 C",
        "hex": "#572C5F"
    },
    {
        "name": "256 C",
        "hex": "#D6BFDD"
    },
    {
        "name": "257 C",
        "hex": "#C6A1CF"
    },
    {
        "name": "258 C",
        "hex": "#8C4799"
    },
    {
        "name": "259 C",
        "hex": "#6D2077"
    },
    {
        "name": "260 C",
        "hex": "#642667"
    },
    {
        "name": "261 C",
        "hex": "#5D285F"
    },
    {
        "name": "262 C",
        "hex": "#51284F"
    },
    {
        "name": "2563 C",
        "hex": "#CBA3D8"
    },
    {
        "name": "2573 C",
        "hex": "#B884CB"
    },
    {
        "name": "2583 C",
        "hex": "#A05EB5"
    },
    {
        "name": "2593 C",
        "hex": "#84329B"
    },
    {
        "name": "2603 C",
        "hex": "#702082"
    },
    {
        "name": "2613 C",
        "hex": "#671E75"
    },
    {
        "name": "2623 C",
        "hex": "#5F2167"
    },
    {
        "name": "2078 C",
        "hex": "#B59DBB"
    },
    {
        "name": "2079 C",
        "hex": "#9B7EA4"
    },
    {
        "name": "2080 C",
        "hex": "#9873AC"
    },
    {
        "name": "2081 C",
        "hex": "#865E9C"
    },
    {
        "name": "2082 C",
        "hex": "#784E90"
    },
    {
        "name": "2083 C",
        "hex": "#965EC8"
    },
    {
        "name": "2084 C",
        "hex": "#7F35B2"
    },
    {
        "name": "2071 C",
        "hex": "#C7B2DE"
    },
    {
        "name": "2072 C",
        "hex": "#B896D4"
    },
    {
        "name": "2073 C",
        "hex": "#AF95D3"
    },
    {
        "name": "2074 C",
        "hex": "#9569BE"
    },
    {
        "name": "2075 C",
        "hex": "#8866BC"
    },
    {
        "name": "2076 C",
        "hex": "#8659B5"
    },
    {
        "name": "2077 C",
        "hex": "#6E3FA3"
    },
    {
        "name": "7660 C",
        "hex": "#9991A4"
    },
    {
        "name": "7661 C",
        "hex": "#8D6E97"
    },
    {
        "name": "7662 C",
        "hex": "#7A4183"
    },
    {
        "name": "7663 C",
        "hex": "#6B3077"
    },
    {
        "name": "7664 C",
        "hex": "#653279"
    },
    {
        "name": "7665 C",
        "hex": "#5E366E"
    },
    {
        "name": "7666 C",
        "hex": "#5C4E63"
    },
    {
        "name": "2567 C",
        "hex": "#C1A0DA"
    },
    {
        "name": "2577 C",
        "hex": "#A77BCA"
    },
    {
        "name": "2587 C",
        "hex": "#8246AF"
    },
    {
        "name": "2597 C",
        "hex": "#5C068C"
    },
    {
        "name": "2607 C",
        "hex": "#500778"
    },
    {
        "name": "2617 C",
        "hex": "#470A68"
    },
    {
        "name": "2627 C",
        "hex": "#3C1053"
    },
    {
        "name": "263 C",
        "hex": "#D7C6E6"
    },
    {
        "name": "264 C",
        "hex": "#C1A7E2"
    },
    {
        "name": "265 C",
        "hex": "#9063CD"
    },
    {
        "name": "266 C",
        "hex": "#753BBD"
    },
    {
        "name": "267 C",
        "hex": "#5F259F"
    },
    {
        "name": "268 C",
        "hex": "#582C83"
    },
    {
        "name": "269 C",
        "hex": "#512D6D"
    },
    {
        "name": "2085 C",
        "hex": "#DECDE7"
    },
    {
        "name": "2086 C",
        "hex": "#A08BCB"
    },
    {
        "name": "2087 C",
        "hex": "#9B7DD4"
    },
    {
        "name": "2088 C",
        "hex": "#825DC7"
    },
    {
        "name": "2089 C",
        "hex": "#7248BD"
    },
    {
        "name": "2090 C",
        "hex": "#6638B6"
    },
    {
        "name": "2091 C",
        "hex": "#4C12A1"
    },
    {
        "name": "2635 C",
        "hex": "#C5B4E3"
    },
    {
        "name": "2645 C",
        "hex": "#AD96DC"
    },
    {
        "name": "2655 C",
        "hex": "#9678D3"
    },
    {
        "name": "2665 C",
        "hex": "#7D55C7"
    },
    {
        "name": "2685 C",
        "hex": "#330072"
    },
    {
        "name": "2695 C",
        "hex": "#2E1A47"
    },
    {
        "name": "2092 C",
        "hex": "#B8ACD6"
    },
    {
        "name": "2093 C",
        "hex": "#A699C1"
    },
    {
        "name": "2094 C",
        "hex": "#8E7FAE"
    },
    {
        "name": "2095 C",
        "hex": "#7965B2"
    },
    {
        "name": "2096 C",
        "hex": "#654EA3"
    },
    {
        "name": "2097 C",
        "hex": "#6244BB"
    },
    {
        "name": "2098 C",
        "hex": "#4A25AA"
    },
    {
        "name": "2099 C",
        "hex": "#B1A2CA"
    },
    {
        "name": "2100 C",
        "hex": "#9F8FCA"
    },
    {
        "name": "2101 C",
        "hex": "#8A75D1"
    },
    {
        "name": "2102 C",
        "hex": "#6558B1"
    },
    {
        "name": "2103 C",
        "hex": "#5949A7"
    },
    {
        "name": "2104 C",
        "hex": "#483698"
    },
    {
        "name": "2105 C",
        "hex": "#39207C"
    },
    {
        "name": "270 C",
        "hex": "#B4B5DF"
    },
    {
        "name": "271 C",
        "hex": "#9595D2"
    },
    {
        "name": "272 C",
        "hex": "#7474C1"
    },
    {
        "name": "273 C",
        "hex": "#24135F"
    },
    {
        "name": "274 C",
        "hex": "#211551"
    },
    {
        "name": "275 C",
        "hex": "#201747"
    },
    {
        "name": "276 C",
        "hex": "#221C35"
    },
    {
        "name": "2705 C",
        "hex": "#A7A4E0"
    },
    {
        "name": "2715 C",
        "hex": "#8B84D7"
    },
    {
        "name": "2725 C",
        "hex": "#685BC7"
    },
    {
        "name": "2735 C",
        "hex": "#2E008B"
    },
    {
        "name": "2745 C",
        "hex": "#280071"
    },
    {
        "name": "2755 C",
        "hex": "#250E62"
    },
    {
        "name": "2765 C",
        "hex": "#201547"
    },
    {
        "name": "7667 C",
        "hex": "#6E7CA0"
    },
    {
        "name": "7668 C",
        "hex": "#686E9F"
    },
    {
        "name": "7669 C",
        "hex": "#615E9B"
    },
    {
        "name": "7670 C",
        "hex": "#565294"
    },
    {
        "name": "7671 C",
        "hex": "#514689"
    },
    {
        "name": "7672 C",
        "hex": "#4C4184"
    },
    {
        "name": "7673 C",
        "hex": "#535486"
    },
    {
        "name": "7443 C",
        "hex": "#DDDAE8"
    },
    {
        "name": "7444 C",
        "hex": "#B6B8DC"
    },
    {
        "name": "7445 C",
        "hex": "#A7A2C3"
    },
    {
        "name": "7446 C",
        "hex": "#8986CA"
    },
    {
        "name": "7447 C",
        "hex": "#5D4777"
    },
    {
        "name": "7448 C",
        "hex": "#4B384C"
    },
    {
        "name": "7449 C",
        "hex": "#41273B"
    },
    {
        "name": "7674 C",
        "hex": "#878CB4"
    },
    {
        "name": "7675 C",
        "hex": "#7C7FAB"
    },
    {
        "name": "7676 C",
        "hex": "#7566A0"
    },
    {
        "name": "7677 C",
        "hex": "#6F5091"
    },
    {
        "name": "7678 C",
        "hex": "#68478D"
    },
    {
        "name": "7679 C",
        "hex": "#563D82"
    },
    {
        "name": "7680 C",
        "hex": "#523178"
    },
    {
        "name": "663 C",
        "hex": "#E5E1E6"
    },
    {
        "name": "664 C",
        "hex": "#E0DBE3"
    },
    {
        "name": "665 C",
        "hex": "#C6BCD0"
    },
    {
        "name": "666 C",
        "hex": "#A192B2"
    },
    {
        "name": "667 C",
        "hex": "#7C6992"
    },
    {
        "name": "668 C",
        "hex": "#614B79"
    },
    {
        "name": "669 C",
        "hex": "#3F2A56"
    },
    {
        "name": "5315 C",
        "hex": "#D8D7DF"
    },
    {
        "name": "5305 C",
        "hex": "#C6C4D2"
    },
    {
        "name": "5295 C",
        "hex": "#B3B0C4"
    },
    {
        "name": "5285 C",
        "hex": "#8D89A5"
    },
    {
        "name": "5275 C",
        "hex": "#595478"
    },
    {
        "name": "5265 C",
        "hex": "#403A60"
    },
    {
        "name": "5255 C",
        "hex": "#1E1A34"
    },
    {
        "name": "2358 C",
        "hex": "#ADA4AE"
    },
    {
        "name": "2359 C",
        "hex": "#7F7384"
    },
    {
        "name": "2360 C",
        "hex": "#7B798E"
    },
    {
        "name": "2361 C",
        "hex": "#70708D"
    },
    {
        "name": "2362 C",
        "hex": "#6C6A81"
    },
    {
        "name": "2363 C",
        "hex": "#605D75"
    },
    {
        "name": "2364 C",
        "hex": "#66596C"
    },
    {
        "name": "2106 C",
        "hex": "#B1B5CE"
    },
    {
        "name": "2107 C",
        "hex": "#9099BA"
    },
    {
        "name": "2108 C",
        "hex": "#777FA8"
    },
    {
        "name": "2109 C",
        "hex": "#676D99"
    },
    {
        "name": "2110 C",
        "hex": "#5B618F"
    },
    {
        "name": "2111 C",
        "hex": "#464E7E"
    },
    {
        "name": "2112 C",
        "hex": "#351F65"
    },
    {
        "name": "2113 C",
        "hex": "#A5B0E3"
    },
    {
        "name": "2114 C",
        "hex": "#838DC8"
    },
    {
        "name": "2115 C",
        "hex": "#6F79BD"
    },
    {
        "name": "2116 C",
        "hex": "#6068B2"
    },
    {
        "name": "2117 C",
        "hex": "#44499C"
    },
    {
        "name": "2118 C",
        "hex": "#343579"
    },
    {
        "name": "2119 C",
        "hex": "#2C2D65"
    },
    {
        "name": "2366 C",
        "hex": "#6A6DCD"
    },
    {
        "name": "2367 C",
        "hex": "#4F4CB1"
    },
    {
        "name": "2368 C",
        "hex": "#4D49BE"
    },
    {
        "name": "2369 C",
        "hex": "#3B3FB6"
    },
    {
        "name": "2370 C",
        "hex": "#2F2A95"
    },
    {
        "name": "2371 C",
        "hex": "#33058D"
    },
    {
        "name": "2372 C",
        "hex": "#281E78"
    },
    {
        "name": "538 C",
        "hex": "#C5CFDA"
    },
    {
        "name": "537 C",
        "hex": "#BBC7D6"
    },
    {
        "name": "536 C",
        "hex": "#A2B2C8"
    },
    {
        "name": "535 C",
        "hex": "#8E9FBC"
    },
    {
        "name": "534 C",
        "hex": "#1B365D"
    },
    {
        "name": "533 C",
        "hex": "#1F2A44"
    },
    {
        "name": "532 C",
        "hex": "#1C1F2A"
    },
    {
        "name": "7541 C",
        "hex": "#D9E1E2"
    },
    {
        "name": "7542 C",
        "hex": "#A4BCC2"
    },
    {
        "name": "7543 C",
        "hex": "#98A4AE"
    },
    {
        "name": "7544 C",
        "hex": "#768692"
    },
    {
        "name": "7545 C",
        "hex": "#425563"
    },
    {
        "name": "7546 C",
        "hex": "#253746"
    },
    {
        "name": "7547 C",
        "hex": "#131E29"
    },
    {
        "name": "2162 C",
        "hex": "#9EA6B4"
    },
    {
        "name": "2163 C",
        "hex": "#869CAE"
    },
    {
        "name": "2164 C",
        "hex": "#7E93A7"
    },
    {
        "name": "2165 C",
        "hex": "#688197"
    },
    {
        "name": "2166 C",
        "hex": "#57728B"
    },
    {
        "name": "2167 C",
        "hex": "#506D85"
    },
    {
        "name": "2168 C",
        "hex": "#254A5D"
    },
    {
        "name": "2373 C",
        "hex": "#72829F"
    },
    {
        "name": "2374 C",
        "hex": "#4D5F80"
    },
    {
        "name": "2376 C",
        "hex": "#4E5B73"
    },
    {
        "name": "2377 C",
        "hex": "#315470"
    },
    {
        "name": "2378 C",
        "hex": "#384967"
    },
    {
        "name": "2379 C",
        "hex": "#3B4559"
    },
    {
        "name": "2380 C",
        "hex": "#28334A"
    },
    {
        "name": "552 C",
        "hex": "#B9D3DC"
    },
    {
        "name": "551 C",
        "hex": "#A3C7D2"
    },
    {
        "name": "550 C",
        "hex": "#8DB9CA"
    },
    {
        "name": "549 C",
        "hex": "#6BA4B8"
    },
    {
        "name": "548 C",
        "hex": "#003D4C"
    },
    {
        "name": "547 C",
        "hex": "#00313C"
    },
    {
        "name": "546 C",
        "hex": "#072B31"
    },
    {
        "name": "5455 C",
        "hex": "#BFCED6"
    },
    {
        "name": "5445 C",
        "hex": "#B7C9D3"
    },
    {
        "name": "5435 C",
        "hex": "#A6BBC8"
    },
    {
        "name": "5425 C",
        "hex": "#7A99AC"
    },
    {
        "name": "5415 C",
        "hex": "#5B7F95"
    },
    {
        "name": "5405 C",
        "hex": "#4F758B"
    },
    {
        "name": "5395 C",
        "hex": "#081F2C"
    },
    {
        "name": "2155 C",
        "hex": "#9EB5CB"
    },
    {
        "name": "2156 C",
        "hex": "#8BA6C1"
    },
    {
        "name": "2157 C",
        "hex": "#7594B3"
    },
    {
        "name": "2158 C",
        "hex": "#5C82A5"
    },
    {
        "name": "2159 C",
        "hex": "#4E769C"
    },
    {
        "name": "2160 C",
        "hex": "#3E6991"
    },
    {
        "name": "2161 C",
        "hex": "#285780"
    },
    {
        "name": "2134 C",
        "hex": "#93A5CF"
    },
    {
        "name": "2135 C",
        "hex": "#758CC0"
    },
    {
        "name": "2136 C",
        "hex": "#8394B8"
    },
    {
        "name": "2137 C",
        "hex": "#6E80A9"
    },
    {
        "name": "2138 C",
        "hex": "#647692"
    },
    {
        "name": "2139 C",
        "hex": "#4D6995"
    },
    {
        "name": "2140 C",
        "hex": "#3A5382"
    },
    {
        "name": "642 C",
        "hex": "#D1DDE6"
    },
    {
        "name": "643 C",
        "hex": "#C6D6E3"
    },
    {
        "name": "644 C",
        "hex": "#9BB8D3"
    },
    {
        "name": "645 C",
        "hex": "#7DA1C4"
    },
    {
        "name": "646 C",
        "hex": "#5E8AB4"
    },
    {
        "name": "647 C",
        "hex": "#236192"
    },
    {
        "name": "648 C",
        "hex": "#002E5D"
    },
    {
        "name": "649 C",
        "hex": "#DBE2E9"
    },
    {
        "name": "650 C",
        "hex": "#CED9E5"
    },
    {
        "name": "651 C",
        "hex": "#A7BCD6"
    },
    {
        "name": "652 C",
        "hex": "#7D9BC1"
    },
    {
        "name": "653 C",
        "hex": "#326295"
    },
    {
        "name": "654 C",
        "hex": "#003A70"
    },
    {
        "name": "655 C",
        "hex": "#002554"
    },
    {
        "name": "656 C",
        "hex": "#DDE5ED"
    },
    {
        "name": "657 C",
        "hex": "#C8D8EB"
    },
    {
        "name": "658 C",
        "hex": "#B1C9E8"
    },
    {
        "name": "659 C",
        "hex": "#7BA4DB"
    },
    {
        "name": "660 C",
        "hex": "#407EC9"
    },
    {
        "name": "661 C",
        "hex": "#003594"
    },
    {
        "name": "662 C",
        "hex": "#001A70"
    },
    {
        "name": "2127 C",
        "hex": "#B8C9E1"
    },
    {
        "name": "2128 C",
        "hex": "#98B6E4"
    },
    {
        "name": "2129 C",
        "hex": "#5F8DDA"
    },
    {
        "name": "2130 C",
        "hex": "#5576D1"
    },
    {
        "name": "2131 C",
        "hex": "#365ABD"
    },
    {
        "name": "2132 C",
        "hex": "#0957C3"
    },
    {
        "name": "2133 C",
        "hex": "#1C57A5"
    },
    {
        "name": "7450 C",
        "hex": "#BDC5DB"
    },
    {
        "name": "7451 C",
        "hex": "#89ABE3"
    },
    {
        "name": "7452 C",
        "hex": "#8094DD"
    },
    {
        "name": "7453 C",
        "hex": "#7BA6DE"
    },
    {
        "name": "7454 C",
        "hex": "#5F8FB4"
    },
    {
        "name": "7455 C",
        "hex": "#3A5DAE"
    },
    {
        "name": "7456 C",
        "hex": "#606EB2"
    },
    {
        "name": "2120 C",
        "hex": "#BECAEA"
    },
    {
        "name": "2121 C",
        "hex": "#8FACD9"
    },
    {
        "name": "2122 C",
        "hex": "#9BAEE4"
    },
    {
        "name": "2123 C",
        "hex": "#7A93DC"
    },
    {
        "name": "2124 C",
        "hex": "#6F7BD4"
    },
    {
        "name": "2125 C",
        "hex": "#5461C8"
    },
    {
        "name": "2126 C",
        "hex": "#303AB2"
    },
    {
        "name": "2706 C",
        "hex": "#CBD3EB"
    },
    {
        "name": "2716 C",
        "hex": "#9FAEE5"
    },
    {
        "name": "2726 C",
        "hex": "#485CC7"
    },
    {
        "name": "2736 C",
        "hex": "#1E22AA"
    },
    {
        "name": "2746 C",
        "hex": "#171C8F"
    },
    {
        "name": "2756 C",
        "hex": "#151F6D"
    },
    {
        "name": "2766 C",
        "hex": "#141B4D"
    },
    {
        "name": "2708 C",
        "hex": "#B8CCEA"
    },
    {
        "name": "2718 C",
        "hex": "#5C88DA"
    },
    {
        "name": "2728 C",
        "hex": "#0047BB"
    },
    {
        "name": "2738 C",
        "hex": "#06038D"
    },
    {
        "name": "2748 C",
        "hex": "#001871"
    },
    {
        "name": "2758 C",
        "hex": "#001E62"
    },
    {
        "name": "2768 C",
        "hex": "#071D49"
    },
    {
        "name": "2707 C",
        "hex": "#C3D7EE"
    },
    {
        "name": "2717 C",
        "hex": "#A7C6ED"
    },
    {
        "name": "2727 C",
        "hex": "#307FE2"
    },
    {
        "name": "2747 C",
        "hex": "#001A72"
    },
    {
        "name": "2757 C",
        "hex": "#001E60"
    },
    {
        "name": "2767 C",
        "hex": "#13294B"
    },
    {
        "name": "277 C",
        "hex": "#ABCAE9"
    },
    {
        "name": "278 C",
        "hex": "#8BB8E8"
    },
    {
        "name": "279 C",
        "hex": "#418FDE"
    },
    {
        "name": "280 C",
        "hex": "#012169"
    },
    {
        "name": "281 C",
        "hex": "#00205B"
    },
    {
        "name": "282 C",
        "hex": "#041E42"
    },
    {
        "name": "283 C",
        "hex": "#92C1E9"
    },
    {
        "name": "284 C",
        "hex": "#6CACE4"
    },
    {
        "name": "285 C",
        "hex": "#0072CE"
    },
    {
        "name": "286 C",
        "hex": "#0033A0"
    },
    {
        "name": "287 C",
        "hex": "#003087"
    },
    {
        "name": "288 C",
        "hex": "#002D72"
    },
    {
        "name": "289 C",
        "hex": "#0C2340"
    },
    {
        "name": "7681 C",
        "hex": "#94A9CB"
    },
    {
        "name": "7682 C",
        "hex": "#6787B7"
    },
    {
        "name": "7683 C",
        "hex": "#426DA9"
    },
    {
        "name": "7684 C",
        "hex": "#385E9D"
    },
    {
        "name": "7685 C",
        "hex": "#2C5697"
    },
    {
        "name": "7686 C",
        "hex": "#1D4F91"
    },
    {
        "name": "7687 C",
        "hex": "#1D428A"
    },
    {
        "name": "2141 C",
        "hex": "#8BBEE8"
    },
    {
        "name": "2142 C",
        "hex": "#7FADE3"
    },
    {
        "name": "2143 C",
        "hex": "#3E87CB"
    },
    {
        "name": "2144 C",
        "hex": "#0067B9"
    },
    {
        "name": "2145 C",
        "hex": "#004EA8"
    },
    {
        "name": "2146 C",
        "hex": "#00358E"
    },
    {
        "name": "2147 C",
        "hex": "#002677"
    },
    {
        "name": "545 C",
        "hex": "#C6DAE7"
    },
    {
        "name": "544 C",
        "hex": "#BDD6E6"
    },
    {
        "name": "543 C",
        "hex": "#A4C8E1"
    },
    {
        "name": "542 C",
        "hex": "#7BAFD4"
    },
    {
        "name": "541 C",
        "hex": "#003C71"
    },
    {
        "name": "540 C",
        "hex": "#003057"
    },
    {
        "name": "539 C",
        "hex": "#00263A"
    },
    {
        "name": "290 C",
        "hex": "#B9D9EB"
    },
    {
        "name": "291 C",
        "hex": "#9BCBEB"
    },
    {
        "name": "292 C",
        "hex": "#69B3E7"
    },
    {
        "name": "294 C",
        "hex": "#002F6C"
    },
    {
        "name": "295 C",
        "hex": "#002855"
    },
    {
        "name": "296 C",
        "hex": "#041C2C"
    },
    {
        "name": "2905 C",
        "hex": "#8DC8E8"
    },
    {
        "name": "2915 C",
        "hex": "#62B5E5"
    },
    {
        "name": "2925 C",
        "hex": "#009CDE"
    },
    {
        "name": "2935 C",
        "hex": "#0057B8"
    },
    {
        "name": "2945 C",
        "hex": "#004C97"
    },
    {
        "name": "2955 C",
        "hex": "#003865"
    },
    {
        "name": "2965 C",
        "hex": "#00263E"
    },
    {
        "name": "2169 C",
        "hex": "#81B0D2"
    },
    {
        "name": "2170 C",
        "hex": "#5F9BC6"
    },
    {
        "name": "2171 C",
        "hex": "#489FDF"
    },
    {
        "name": "2172 C",
        "hex": "#147BD1"
    },
    {
        "name": "2173 C",
        "hex": "#0084D5"
    },
    {
        "name": "2174 C",
        "hex": "#0076CE"
    },
    {
        "name": "2175 C",
        "hex": "#006AC6"
    },
    {
        "name": "2381 C",
        "hex": "#5E93DB"
    },
    {
        "name": "2382 C",
        "hex": "#008AD8"
    },
    {
        "name": "2383 C",
        "hex": "#2774AE"
    },
    {
        "name": "2384 C",
        "hex": "#0061A0"
    },
    {
        "name": "2386 C",
        "hex": "#2D68C4"
    },
    {
        "name": "2387 C",
        "hex": "#0762C8"
    },
    {
        "name": "2388 C",
        "hex": "#0050B5"
    },
    {
        "name": "297 C",
        "hex": "#71C5E8"
    },
    {
        "name": "298 C",
        "hex": "#41B6E6"
    },
    {
        "name": "299 C",
        "hex": "#00A3E0"
    },
    {
        "name": "300 C",
        "hex": "#005EB8"
    },
    {
        "name": "301 C",
        "hex": "#004B87"
    },
    {
        "name": "302 C",
        "hex": "#003B5C"
    },
    {
        "name": "303 C",
        "hex": "#002A3A"
    },
    {
        "name": "7688 C",
        "hex": "#4698CB"
    },
    {
        "name": "7689 C",
        "hex": "#298FC2"
    },
    {
        "name": "7690 C",
        "hex": "#0076A8"
    },
    {
        "name": "7691 C",
        "hex": "#006298"
    },
    {
        "name": "7692 C",
        "hex": "#005587"
    },
    {
        "name": "7693 C",
        "hex": "#004976"
    },
    {
        "name": "7694 C",
        "hex": "#01426A"
    },
    {
        "name": "2148 C",
        "hex": "#6191B4"
    },
    {
        "name": "2149 C",
        "hex": "#4B82A8"
    },
    {
        "name": "2150 C",
        "hex": "#36749D"
    },
    {
        "name": "2151 C",
        "hex": "#256BA2"
    },
    {
        "name": "2152 C",
        "hex": "#1A658F"
    },
    {
        "name": "2153 C",
        "hex": "#00567E"
    },
    {
        "name": "2154 C",
        "hex": "#004680"
    },
    {
        "name": "2183 C",
        "hex": "#0092BD"
    },
    {
        "name": "2184 C",
        "hex": "#0084D4"
    },
    {
        "name": "2185 C",
        "hex": "#0076A5"
    },
    {
        "name": "2186 C",
        "hex": "#004986"
    },
    {
        "name": "2187 C",
        "hex": "#004677"
    },
    {
        "name": "2188 C",
        "hex": "#00426A"
    },
    {
        "name": "2189 C",
        "hex": "#003349"
    },
    {
        "name": "2975 C",
        "hex": "#99D6EA"
    },
    {
        "name": "2985 C",
        "hex": "#5BC2E7"
    },
    {
        "name": "2995 C",
        "hex": "#00A9E0"
    },
    {
        "name": "3005 C",
        "hex": "#0077C8"
    },
    {
        "name": "3015 C",
        "hex": "#00629B"
    },
    {
        "name": "3025 C",
        "hex": "#004F71"
    },
    {
        "name": "3035 C",
        "hex": "#003E51"
    },
    {
        "name": "2190 C",
        "hex": "#56B7E6"
    },
    {
        "name": "2191 C",
        "hex": "#00A3E1"
    },
    {
        "name": "2192 C",
        "hex": "#0091DA"
    },
    {
        "name": "2193 C",
        "hex": "#0090DA"
    },
    {
        "name": "2194 C",
        "hex": "#0086D6"
    },
    {
        "name": "2195 C",
        "hex": "#0076CF"
    },
    {
        "name": "2196 C",
        "hex": "#0069B1"
    },
    {
        "name": "7695 C",
        "hex": "#7BA7BC"
    },
    {
        "name": "7696 C",
        "hex": "#6399AE"
    },
    {
        "name": "7697 C",
        "hex": "#4E87A0"
    },
    {
        "name": "7698 C",
        "hex": "#41748D"
    },
    {
        "name": "7699 C",
        "hex": "#34657F"
    },
    {
        "name": "7700 C",
        "hex": "#165C7D"
    },
    {
        "name": "7701 C",
        "hex": "#005776"
    },
    {
        "name": "2176 C",
        "hex": "#A6B8C1"
    },
    {
        "name": "2177 C",
        "hex": "#7FA0AC"
    },
    {
        "name": "2178 C",
        "hex": "#6B8F9C"
    },
    {
        "name": "2179 C",
        "hex": "#527A8A"
    },
    {
        "name": "2180 C",
        "hex": "#3F6C7D"
    },
    {
        "name": "2181 C",
        "hex": "#2A5B6C"
    },
    {
        "name": "2182 C",
        "hex": "#174A5B"
    },
    {
        "name": "2204 C",
        "hex": "#AFCDD7"
    },
    {
        "name": "2205 C",
        "hex": "#89B2C4"
    },
    {
        "name": "2206 C",
        "hex": "#779FB5"
    },
    {
        "name": "2207 C",
        "hex": "#659AB1"
    },
    {
        "name": "2208 C",
        "hex": "#5489A3"
    },
    {
        "name": "2209 C",
        "hex": "#4986A0"
    },
    {
        "name": "2210 C",
        "hex": "#074F71"
    },
    {
        "name": "7457 C",
        "hex": "#BBDDE6"
    },
    {
        "name": "7458 C",
        "hex": "#71B2C9"
    },
    {
        "name": "7459 C",
        "hex": "#4298B5"
    },
    {
        "name": "7460 C",
        "hex": "#0086BF"
    },
    {
        "name": "7461 C",
        "hex": "#007DBA"
    },
    {
        "name": "7462 C",
        "hex": "#00558C"
    },
    {
        "name": "7463 C",
        "hex": "#002B49"
    },
    {
        "name": "304 C",
        "hex": "#9ADBE8"
    },
    {
        "name": "305 C",
        "hex": "#59CBE8"
    },
    {
        "name": "306 C",
        "hex": "#00B5E2"
    },
    {
        "name": "307 C",
        "hex": "#006BA6"
    },
    {
        "name": "308 C",
        "hex": "#00587C"
    },
    {
        "name": "309 C",
        "hex": "#003B49"
    },
    {
        "name": "635 C",
        "hex": "#A4DBE8"
    },
    {
        "name": "636 C",
        "hex": "#8BD3E6"
    },
    {
        "name": "637 C",
        "hex": "#4EC3E0"
    },
    {
        "name": "638 C",
        "hex": "#00AFD7"
    },
    {
        "name": "639 C",
        "hex": "#0095C8"
    },
    {
        "name": "640 C",
        "hex": "#0082BA"
    },
    {
        "name": "641 C",
        "hex": "#0067A0"
    },
    {
        "name": "2389 C",
        "hex": "#4C9FC8"
    },
    {
        "name": "2390 C",
        "hex": "#077CAB"
    },
    {
        "name": "2391 C",
        "hex": "#0081A6"
    },
    {
        "name": "2392 C",
        "hex": "#166886"
    },
    {
        "name": "2393 C",
        "hex": "#0092CB"
    },
    {
        "name": "2394 C",
        "hex": "#008BCE"
    },
    {
        "name": "2396 C",
        "hex": "#0099B2"
    },
    {
        "name": "7702 C",
        "hex": "#48A9C5"
    },
    {
        "name": "7703 C",
        "hex": "#009CBD"
    },
    {
        "name": "7704 C",
        "hex": "#0085AD"
    },
    {
        "name": "7705 C",
        "hex": "#007096"
    },
    {
        "name": "7706 C",
        "hex": "#006A8E"
    },
    {
        "name": "7707 C",
        "hex": "#00617F"
    },
    {
        "name": "7708 C",
        "hex": "#005670"
    },
    {
        "name": "2197 C",
        "hex": "#74D2E7"
    },
    {
        "name": "2198 C",
        "hex": "#4AC9E3"
    },
    {
        "name": "2199 C",
        "hex": "#00BBDC"
    },
    {
        "name": "2200 C",
        "hex": "#04A9C7"
    },
    {
        "name": "2201 C",
        "hex": "#00AED6"
    },
    {
        "name": "2202 C",
        "hex": "#00A5DF"
    },
    {
        "name": "2203 C",
        "hex": "#0087AE"
    },
    {
        "name": "628 C",
        "hex": "#B8DDE1"
    },
    {
        "name": "629 C",
        "hex": "#9BD3DD"
    },
    {
        "name": "630 C",
        "hex": "#77C5D5"
    },
    {
        "name": "631 C",
        "hex": "#3EB1C8"
    },
    {
        "name": "632 C",
        "hex": "#0093B2"
    },
    {
        "name": "633 C",
        "hex": "#007396"
    },
    {
        "name": "634 C",
        "hex": "#005F83"
    },
    {
        "name": "310 C",
        "hex": "#6AD1E3"
    },
    {
        "name": "311 C",
        "hex": "#05C3DE"
    },
    {
        "name": "312 C",
        "hex": "#00A9CE"
    },
    {
        "name": "313 C",
        "hex": "#0092BC"
    },
    {
        "name": "314 C",
        "hex": "#007FA3"
    },
    {
        "name": "315 C",
        "hex": "#00677F"
    },
    {
        "name": "316 C",
        "hex": "#004851"
    },
    {
        "name": "3105 C",
        "hex": "#68D2DF"
    },
    {
        "name": "3115 C",
        "hex": "#00C1D5"
    },
    {
        "name": "3125 C",
        "hex": "#00AEC7"
    },
    {
        "name": "3135 C",
        "hex": "#008EAA"
    },
    {
        "name": "3145 C",
        "hex": "#00778B"
    },
    {
        "name": "3155 C",
        "hex": "#006272"
    },
    {
        "name": "3165 C",
        "hex": "#004F59"
    },
    {
        "name": "7709 C",
        "hex": "#63B1BC"
    },
    {
        "name": "7710 C",
        "hex": "#00A7B5"
    },
    {
        "name": "7711 C",
        "hex": "#0097A9"
    },
    {
        "name": "7712 C",
        "hex": "#00859B"
    },
    {
        "name": "7713 C",
        "hex": "#007D8A"
    },
    {
        "name": "7714 C",
        "hex": "#007680"
    },
    {
        "name": "7715 C",
        "hex": "#006269"
    },
    {
        "name": "2218 C",
        "hex": "#72B0BD"
    },
    {
        "name": "2219 C",
        "hex": "#5CA3B3"
    },
    {
        "name": "2220 C",
        "hex": "#4797A8"
    },
    {
        "name": "2221 C",
        "hex": "#2D8C9E"
    },
    {
        "name": "2222 C",
        "hex": "#1D8296"
    },
    {
        "name": "2223 C",
        "hex": "#00788D"
    },
    {
        "name": "2224 C",
        "hex": "#006980"
    },
    {
        "name": "317 C",
        "hex": "#B1E4E3"
    },
    {
        "name": "318 C",
        "hex": "#88DBDF"
    },
    {
        "name": "319 C",
        "hex": "#2DCCD3"
    },
    {
        "name": "320 C",
        "hex": "#009CA6"
    },
    {
        "name": "321 C",
        "hex": "#008C95"
    },
    {
        "name": "322 C",
        "hex": "#007377"
    },
    {
        "name": "323 C",
        "hex": "#005F61"
    },
    {
        "name": "2225 C",
        "hex": "#78D5E1"
    },
    {
        "name": "2226 C",
        "hex": "#3CCBDA"
    },
    {
        "name": "2227 C",
        "hex": "#59BEC9"
    },
    {
        "name": "2228 C",
        "hex": "#00A5BD"
    },
    {
        "name": "2229 C",
        "hex": "#009CB6"
    },
    {
        "name": "2230 C",
        "hex": "#008CA0"
    },
    {
        "name": "2231 C",
        "hex": "#007C91"
    },
    {
        "name": "7464 C",
        "hex": "#A0D1CA"
    },
    {
        "name": "7465 C",
        "hex": "#40C1AC"
    },
    {
        "name": "7466 C",
        "hex": "#00B0B9"
    },
    {
        "name": "7467 C",
        "hex": "#00A3AD"
    },
    {
        "name": "7468 C",
        "hex": "#007398"
    },
    {
        "name": "7469 C",
        "hex": "#005F86"
    },
    {
        "name": "7470 C",
        "hex": "#005A70"
    },
    {
        "name": "7471 C",
        "hex": "#7EDDD3"
    },
    {
        "name": "7472 C",
        "hex": "#5CB8B2"
    },
    {
        "name": "7473 C",
        "hex": "#279989"
    },
    {
        "name": "7474 C",
        "hex": "#007681"
    },
    {
        "name": "7475 C",
        "hex": "#487A7B"
    },
    {
        "name": "7476 C",
        "hex": "#0D5257"
    },
    {
        "name": "7477 C",
        "hex": "#244C5A"
    },
    {
        "name": "2211 C",
        "hex": "#6F9BA4"
    },
    {
        "name": "2212 C",
        "hex": "#508590"
    },
    {
        "name": "2213 C",
        "hex": "#337079"
    },
    {
        "name": "2214 C",
        "hex": "#2F6F7A"
    },
    {
        "name": "2215 C",
        "hex": "#2E5665"
    },
    {
        "name": "2216 C",
        "hex": "#2E4D58"
    },
    {
        "name": "2217 C",
        "hex": "#003A40"
    },
    {
        "name": "5523 C",
        "hex": "#B6CFD0"
    },
    {
        "name": "5513 C",
        "hex": "#ABC7CA"
    },
    {
        "name": "5503 C",
        "hex": "#94B7BB"
    },
    {
        "name": "5493 C",
        "hex": "#7FA9AE"
    },
    {
        "name": "5483 C",
        "hex": "#4F868E"
    },
    {
        "name": "5473 C",
        "hex": "#115E67"
    },
    {
        "name": "5463 C",
        "hex": "#07272D"
    },
    {
        "name": "2232 C",
        "hex": "#71B0B4"
    },
    {
        "name": "2233 C",
        "hex": "#58A7AF"
    },
    {
        "name": "2234 C",
        "hex": "#4F9FA6"
    },
    {
        "name": "2235 C",
        "hex": "#28939D"
    },
    {
        "name": "2236 C",
        "hex": "#2C9199"
    },
    {
        "name": "2237 C",
        "hex": "#05868E"
    },
    {
        "name": "2238 C",
        "hex": "#006975"
    },
    {
        "name": "7716 C",
        "hex": "#00968F"
    },
    {
        "name": "7717 C",
        "hex": "#00857D"
    },
    {
        "name": "7718 C",
        "hex": "#007672"
    },
    {
        "name": "7719 C",
        "hex": "#006D68"
    },
    {
        "name": "7720 C",
        "hex": "#00635B"
    },
    {
        "name": "7721 C",
        "hex": "#005E5D"
    },
    {
        "name": "7722 C",
        "hex": "#005151"
    },
    {
        "name": "324 C",
        "hex": "#9CDBD9"
    },
    {
        "name": "325 C",
        "hex": "#64CCC9"
    },
    {
        "name": "326 C",
        "hex": "#00B2A9"
    },
    {
        "name": "327 C",
        "hex": "#008675"
    },
    {
        "name": "328 C",
        "hex": "#007367"
    },
    {
        "name": "329 C",
        "hex": "#00685E"
    },
    {
        "name": "330 C",
        "hex": "#00534C"
    },
    {
        "name": "3242 C",
        "hex": "#71DBD4"
    },
    {
        "name": "3252 C",
        "hex": "#2AD2C9"
    },
    {
        "name": "3262 C",
        "hex": "#00BFB3"
    },
    {
        "name": "3272 C",
        "hex": "#00A499"
    },
    {
        "name": "3282 C",
        "hex": "#008578"
    },
    {
        "name": "3292 C",
        "hex": "#00594F"
    },
    {
        "name": "3302 C",
        "hex": "#004C45"
    },
    {
        "name": "2397 C",
        "hex": "#00B7BD"
    },
    {
        "name": "2398 C",
        "hex": "#00BAB3"
    },
    {
        "name": "2399 C",
        "hex": "#00ACA0"
    },
    {
        "name": "2400 C",
        "hex": "#00B097"
    },
    {
        "name": "2401 C",
        "hex": "#34A798"
    },
    {
        "name": "2402 C",
        "hex": "#009D85"
    },
    {
        "name": "2403 C",
        "hex": "#00897B"
    },
    {
        "name": "3245 C",
        "hex": "#7CE0D3"
    },
    {
        "name": "3255 C",
        "hex": "#2CD5C4"
    },
    {
        "name": "3265 C",
        "hex": "#00C7B1"
    },
    {
        "name": "3275 C",
        "hex": "#00B398"
    },
    {
        "name": "3285 C",
        "hex": "#009681"
    },
    {
        "name": "3295 C",
        "hex": "#007864"
    },
    {
        "name": "3305 C",
        "hex": "#004E42"
    },
    {
        "name": "3248 C",
        "hex": "#6DCDB8"
    },
    {
        "name": "3258 C",
        "hex": "#49C5B1"
    },
    {
        "name": "3268 C",
        "hex": "#00AB8E"
    },
    {
        "name": "3278 C",
        "hex": "#009B77"
    },
    {
        "name": "3288 C",
        "hex": "#008264"
    },
    {
        "name": "3298 C",
        "hex": "#006A52"
    },
    {
        "name": "3308 C",
        "hex": "#034638"
    },
    {
        "name": "566 C",
        "hex": "#B9DCD2"
    },
    {
        "name": "565 C",
        "hex": "#A1D6CA"
    },
    {
        "name": "564 C",
        "hex": "#86C8BC"
    },
    {
        "name": "563 C",
        "hex": "#6BBBAE"
    },
    {
        "name": "562 C",
        "hex": "#006F62"
    },
    {
        "name": "561 C",
        "hex": "#00594C"
    },
    {
        "name": "560 C",
        "hex": "#1D3C34"
    },
    {
        "name": "573 C",
        "hex": "#B5E3D8"
    },
    {
        "name": "572 C",
        "hex": "#A5DFD3"
    },
    {
        "name": "571 C",
        "hex": "#98DBCE"
    },
    {
        "name": "570 C",
        "hex": "#6BCABA"
    },
    {
        "name": "569 C",
        "hex": "#00816D"
    },
    {
        "name": "568 C",
        "hex": "#006C5B"
    },
    {
        "name": "567 C",
        "hex": "#173F35"
    },
    {
        "name": "559 C",
        "hex": "#ADCAB8"
    },
    {
        "name": "558 C",
        "hex": "#9ABEAA"
    },
    {
        "name": "557 C",
        "hex": "#85B09A"
    },
    {
        "name": "556 C",
        "hex": "#6FA287"
    },
    {
        "name": "555 C",
        "hex": "#28724F"
    },
    {
        "name": "554 C",
        "hex": "#205C40"
    },
    {
        "name": "553 C",
        "hex": "#284734"
    },
    {
        "name": "2404 C",
        "hex": "#A5B99C"
    },
    {
        "name": "2406 C",
        "hex": "#819E87"
    },
    {
        "name": "2407 C",
        "hex": "#6B8D73"
    },
    {
        "name": "2408 C",
        "hex": "#497B55"
    },
    {
        "name": "2409 C",
        "hex": "#4E6548"
    },
    {
        "name": "2410 C",
        "hex": "#445A3E"
    },
    {
        "name": "2411 C",
        "hex": "#1C4220"
    },
    {
        "name": "5595 C",
        "hex": "#BFCEC2"
    },
    {
        "name": "5585 C",
        "hex": "#A7BDB1"
    },
    {
        "name": "5575 C",
        "hex": "#92ACA0"
    },
    {
        "name": "5565 C",
        "hex": "#7F9C90"
    },
    {
        "name": "5555 C",
        "hex": "#5C7F71"
    },
    {
        "name": "5545 C",
        "hex": "#43695B"
    },
    {
        "name": "5535 C",
        "hex": "#183028"
    },
    {
        "name": "5665 C",
        "hex": "#BAC5B9"
    },
    {
        "name": "5655 C",
        "hex": "#B0BDB0"
    },
    {
        "name": "5645 C",
        "hex": "#A3B2A4"
    },
    {
        "name": "5635 C",
        "hex": "#94A596"
    },
    {
        "name": "5625 C",
        "hex": "#708573"
    },
    {
        "name": "5615 C",
        "hex": "#5E7461"
    },
    {
        "name": "5605 C",
        "hex": "#22372B"
    },
    {
        "name": "5527 C",
        "hex": "#BCC9C5"
    },
    {
        "name": "5517 C",
        "hex": "#B1C0BC"
    },
    {
        "name": "5507 C",
        "hex": "#9DB0AC"
    },
    {
        "name": "5497 C",
        "hex": "#829995"
    },
    {
        "name": "5487 C",
        "hex": "#5D7975"
    },
    {
        "name": "5477 C",
        "hex": "#3E5D58"
    },
    {
        "name": "5467 C",
        "hex": "#18332F"
    },
    {
        "name": "621 C",
        "hex": "#D1E0D7"
    },
    {
        "name": "622 C",
        "hex": "#B7CDC2"
    },
    {
        "name": "623 C",
        "hex": "#9AB9AD"
    },
    {
        "name": "624 C",
        "hex": "#789F90"
    },
    {
        "name": "625 C",
        "hex": "#507F70"
    },
    {
        "name": "626 C",
        "hex": "#285C4D"
    },
    {
        "name": "627 C",
        "hex": "#13322B"
    },
    {
        "name": "331 C",
        "hex": "#A7E6D7"
    },
    {
        "name": "332 C",
        "hex": "#8CE2D0"
    },
    {
        "name": "333 C",
        "hex": "#3CDBC0"
    },
    {
        "name": "334 C",
        "hex": "#009775"
    },
    {
        "name": "335 C",
        "hex": "#007B5F"
    },
    {
        "name": "336 C",
        "hex": "#00664F"
    },
    {
        "name": "2239 C",
        "hex": "#00CFB4"
    },
    {
        "name": "2240 C",
        "hex": "#00C19F"
    },
    {
        "name": "2241 C",
        "hex": "#58A291"
    },
    {
        "name": "2242 C",
        "hex": "#00A376"
    },
    {
        "name": "2243 C",
        "hex": "#00957A"
    },
    {
        "name": "2244 C",
        "hex": "#1B806D"
    },
    {
        "name": "2245 C",
        "hex": "#008655"
    },
    {
        "name": "337 C",
        "hex": "#8FD6BD"
    },
    {
        "name": "338 C",
        "hex": "#6ECEB2"
    },
    {
        "name": "339 C",
        "hex": "#00B388"
    },
    {
        "name": "340 C",
        "hex": "#00965E"
    },
    {
        "name": "341 C",
        "hex": "#007A53"
    },
    {
        "name": "342 C",
        "hex": "#006747"
    },
    {
        "name": "343 C",
        "hex": "#115740"
    },
    {
        "name": "7723 C",
        "hex": "#50A684"
    },
    {
        "name": "7724 C",
        "hex": "#00966C"
    },
    {
        "name": "7725 C",
        "hex": "#008755"
    },
    {
        "name": "7726 C",
        "hex": "#007B4B"
    },
    {
        "name": "7727 C",
        "hex": "#006F44"
    },
    {
        "name": "7728 C",
        "hex": "#006845"
    },
    {
        "name": "7729 C",
        "hex": "#005844"
    },
    {
        "name": "3375 C",
        "hex": "#7AE1BF"
    },
    {
        "name": "3385 C",
        "hex": "#47D7AC"
    },
    {
        "name": "3395 C",
        "hex": "#00C389"
    },
    {
        "name": "3405 C",
        "hex": "#00AF66"
    },
    {
        "name": "3415 C",
        "hex": "#007749"
    },
    {
        "name": "3425 C",
        "hex": "#006341"
    },
    {
        "name": "3435 C",
        "hex": "#154734"
    },
    {
        "name": "2246 C",
        "hex": "#A8D5BA"
    },
    {
        "name": "2247 C",
        "hex": "#77C19A"
    },
    {
        "name": "2248 C",
        "hex": "#71B790"
    },
    {
        "name": "2249 C",
        "hex": "#5CAA7F"
    },
    {
        "name": "2250 C",
        "hex": "#00B373"
    },
    {
        "name": "2251 C",
        "hex": "#00AD6F"
    },
    {
        "name": "2252 C",
        "hex": "#00A74A"
    },
    {
        "name": "2412 C",
        "hex": "#48D597"
    },
    {
        "name": "2413 C",
        "hex": "#34B78F"
    },
    {
        "name": "2414 C",
        "hex": "#00BB7E"
    },
    {
        "name": "2416 C",
        "hex": "#00B176"
    },
    {
        "name": "2417 C",
        "hex": "#249E6B"
    },
    {
        "name": "2418 C",
        "hex": "#00873E"
    },
    {
        "name": "2419 C",
        "hex": "#007C58"
    },
    {
        "name": "344 C",
        "hex": "#A0DAB3"
    },
    {
        "name": "345 C",
        "hex": "#91D6AC"
    },
    {
        "name": "346 C",
        "hex": "#71CC98"
    },
    {
        "name": "347 C",
        "hex": "#009A44"
    },
    {
        "name": "348 C",
        "hex": "#00843D"
    },
    {
        "name": "349 C",
        "hex": "#046A38"
    },
    {
        "name": "350 C",
        "hex": "#2C5234"
    },
    {
        "name": "351 C",
        "hex": "#A2E4B8"
    },
    {
        "name": "352 C",
        "hex": "#8FE2B0"
    },
    {
        "name": "353 C",
        "hex": "#80E0A7"
    },
    {
        "name": "354 C",
        "hex": "#00B140"
    },
    {
        "name": "355 C",
        "hex": "#009639"
    },
    {
        "name": "356 C",
        "hex": "#007A33"
    },
    {
        "name": "357 C",
        "hex": "#215732"
    },
    {
        "name": "7478 C",
        "hex": "#9BE3BF"
    },
    {
        "name": "7479 C",
        "hex": "#26D07C"
    },
    {
        "name": "7480 C",
        "hex": "#00BF6F"
    },
    {
        "name": "7481 C",
        "hex": "#00B74F"
    },
    {
        "name": "7482 C",
        "hex": "#009F4D"
    },
    {
        "name": "7483 C",
        "hex": "#275D38"
    },
    {
        "name": "7484 C",
        "hex": "#00573F"
    },
    {
        "name": "2253 C",
        "hex": "#BDE9C9"
    },
    {
        "name": "2254 C",
        "hex": "#ADDFB3"
    },
    {
        "name": "2255 C",
        "hex": "#86D295"
    },
    {
        "name": "2256 C",
        "hex": "#56C271"
    },
    {
        "name": "2257 C",
        "hex": "#00AD50"
    },
    {
        "name": "2258 C",
        "hex": "#008522"
    },
    {
        "name": "2259 C",
        "hex": "#027223"
    },
    {
        "name": "7730 C",
        "hex": "#4B9560"
    },
    {
        "name": "7731 C",
        "hex": "#228848"
    },
    {
        "name": "7732 C",
        "hex": "#007A3E"
    },
    {
        "name": "7733 C",
        "hex": "#007041"
    },
    {
        "name": "7734 C",
        "hex": "#286140"
    },
    {
        "name": "7735 C",
        "hex": "#36573B"
    },
    {
        "name": "7736 C",
        "hex": "#395542"
    },
    {
        "name": "7737 C",
        "hex": "#6BA539"
    },
    {
        "name": "7738 C",
        "hex": "#48A23F"
    },
    {
        "name": "7739 C",
        "hex": "#319B42"
    },
    {
        "name": "7740 C",
        "hex": "#3A913F"
    },
    {
        "name": "7741 C",
        "hex": "#44883E"
    },
    {
        "name": "7742 C",
        "hex": "#4A773C"
    },
    {
        "name": "7743 C",
        "hex": "#44693D"
    },
    {
        "name": "2260 C",
        "hex": "#B6CFAE"
    },
    {
        "name": "2261 C",
        "hex": "#9ABB91"
    },
    {
        "name": "2262 C",
        "hex": "#8CAC89"
    },
    {
        "name": "2263 C",
        "hex": "#74956C"
    },
    {
        "name": "2264 C",
        "hex": "#6B9560"
    },
    {
        "name": "2265 C",
        "hex": "#5C7E51"
    },
    {
        "name": "2266 C",
        "hex": "#335525"
    },
    {
        "name": "358 C",
        "hex": "#ADDC91"
    },
    {
        "name": "359 C",
        "hex": "#A1D884"
    },
    {
        "name": "360 C",
        "hex": "#6CC24A"
    },
    {
        "name": "361 C",
        "hex": "#43B02A"
    },
    {
        "name": "362 C",
        "hex": "#509E2F"
    },
    {
        "name": "363 C",
        "hex": "#4C8C2B"
    },
    {
        "name": "364 C",
        "hex": "#4A7729"
    },
    {
        "name": "2267 C",
        "hex": "#9BE198"
    },
    {
        "name": "2268 C",
        "hex": "#79D97C"
    },
    {
        "name": "2269 C",
        "hex": "#7CCC6C"
    },
    {
        "name": "2270 C",
        "hex": "#2DC84D"
    },
    {
        "name": "2271 C",
        "hex": "#00BB31"
    },
    {
        "name": "2272 C",
        "hex": "#009A17"
    },
    {
        "name": "2273 C",
        "hex": "#257226"
    },
    {
        "name": "2420 C",
        "hex": "#00C65E"
    },
    {
        "name": "2421 C",
        "hex": "#31B700"
    },
    {
        "name": "2422 C",
        "hex": "#00A82D"
    },
    {
        "name": "2423 C",
        "hex": "#00AA13"
    },
    {
        "name": "2424 C",
        "hex": "#279F00"
    },
    {
        "name": "2426 C",
        "hex": "#008C15"
    },
    {
        "name": "2427 C",
        "hex": "#035F1D"
    },
    {
        "name": "7485 C",
        "hex": "#D0DEBB"
    },
    {
        "name": "7486 C",
        "hex": "#BCE194"
    },
    {
        "name": "7487 C",
        "hex": "#8EDD65"
    },
    {
        "name": "7488 C",
        "hex": "#78D64B"
    },
    {
        "name": "7489 C",
        "hex": "#74AA50"
    },
    {
        "name": "7490 C",
        "hex": "#719949"
    },
    {
        "name": "7491 C",
        "hex": "#79863C"
    },
    {
        "name": "2274 C",
        "hex": "#CFDCB1"
    },
    {
        "name": "2275 C",
        "hex": "#C3DC93"
    },
    {
        "name": "2276 C",
        "hex": "#89A84F"
    },
    {
        "name": "2277 C",
        "hex": "#55951B"
    },
    {
        "name": "2278 C",
        "hex": "#4E801F"
    },
    {
        "name": "2279 C",
        "hex": "#5E7E29"
    },
    {
        "name": "2280 C",
        "hex": "#4A6A1D"
    },
    {
        "name": "365 C",
        "hex": "#C2E189"
    },
    {
        "name": "366 C",
        "hex": "#B7DD79"
    },
    {
        "name": "367 C",
        "hex": "#A4D65E"
    },
    {
        "name": "368 C",
        "hex": "#78BE20"
    },
    {
        "name": "369 C",
        "hex": "#64A70B"
    },
    {
        "name": "370 C",
        "hex": "#658D1B"
    },
    {
        "name": "371 C",
        "hex": "#546223"
    },
    {
        "name": "2281 C",
        "hex": "#D9EA9A"
    },
    {
        "name": "2282 C",
        "hex": "#C7E995"
    },
    {
        "name": "2283 C",
        "hex": "#A7E163"
    },
    {
        "name": "2284 C",
        "hex": "#B3D57D"
    },
    {
        "name": "2285 C",
        "hex": "#93DA49"
    },
    {
        "name": "2286 C",
        "hex": "#7ACC00"
    },
    {
        "name": "2287 C",
        "hex": "#5BC500"
    },
    {
        "name": "372 C",
        "hex": "#D4EB8E"
    },
    {
        "name": "373 C",
        "hex": "#CDEA80"
    },
    {
        "name": "374 C",
        "hex": "#C5E86C"
    },
    {
        "name": "375 C",
        "hex": "#97D700"
    },
    {
        "name": "376 C",
        "hex": "#84BD00"
    },
    {
        "name": "377 C",
        "hex": "#7A9A01"
    },
    {
        "name": "378 C",
        "hex": "#59621D"
    },
    {
        "name": "2288 C",
        "hex": "#C8E379"
    },
    {
        "name": "2289 C",
        "hex": "#C5D97A"
    },
    {
        "name": "2290 C",
        "hex": "#AADB1E"
    },
    {
        "name": "2291 C",
        "hex": "#A7D500"
    },
    {
        "name": "2292 C",
        "hex": "#93C90E"
    },
    {
        "name": "2293 C",
        "hex": "#8BC400"
    },
    {
        "name": "2294 C",
        "hex": "#78AA00"
    },
    {
        "name": "2295 C",
        "hex": "#E0EC89"
    },
    {
        "name": "2296 C",
        "hex": "#D6E865"
    },
    {
        "name": "2297 C",
        "hex": "#C0DF16"
    },
    {
        "name": "2298 C",
        "hex": "#B7DB57"
    },
    {
        "name": "2299 C",
        "hex": "#A4D233"
    },
    {
        "name": "2300 C",
        "hex": "#A9C23F"
    },
    {
        "name": "2301 C",
        "hex": "#8FAD15"
    },
    {
        "name": "580 C",
        "hex": "#C4D6A4"
    },
    {
        "name": "579 C",
        "hex": "#BCD19B"
    },
    {
        "name": "578 C",
        "hex": "#B7CE95"
    },
    {
        "name": "577 C",
        "hex": "#A9C47F"
    },
    {
        "name": "576 C",
        "hex": "#789D4A"
    },
    {
        "name": "575 C",
        "hex": "#67823A"
    },
    {
        "name": "574 C",
        "hex": "#4E5B31"
    },
    {
        "name": "5807 C",
        "hex": "#D0D1AB"
    },
    {
        "name": "5797 C",
        "hex": "#C6C89B"
    },
    {
        "name": "5787 C",
        "hex": "#BABD8B"
    },
    {
        "name": "5777 C",
        "hex": "#A2A569"
    },
    {
        "name": "5767 C",
        "hex": "#8A8D4A"
    },
    {
        "name": "5757 C",
        "hex": "#6D712E"
    },
    {
        "name": "5747 C",
        "hex": "#3D441E"
    },
    {
        "name": "5875 C",
        "hex": "#D2CE9E"
    },
    {
        "name": "5865 C",
        "hex": "#CBC793"
    },
    {
        "name": "5855 C",
        "hex": "#C0BB87"
    },
    {
        "name": "5845 C",
        "hex": "#AFA96E"
    },
    {
        "name": "5835 C",
        "hex": "#A09958"
    },
    {
        "name": "5825 C",
        "hex": "#89813D"
    },
    {
        "name": "5815 C",
        "hex": "#555025"
    },
    {
        "name": "5803 C",
        "hex": "#C3C6A8"
    },
    {
        "name": "5793 C",
        "hex": "#B3B995"
    },
    {
        "name": "5783 C",
        "hex": "#A3AA83"
    },
    {
        "name": "5773 C",
        "hex": "#899064"
    },
    {
        "name": "5763 C",
        "hex": "#737B4C"
    },
    {
        "name": "5753 C",
        "hex": "#5E6738"
    },
    {
        "name": "5743 C",
        "hex": "#3E4827"
    },
    {
        "name": "7492 C",
        "hex": "#BFCC80"
    },
    {
        "name": "7493 C",
        "hex": "#BBC592"
    },
    {
        "name": "7494 C",
        "hex": "#9CAF88"
    },
    {
        "name": "7495 C",
        "hex": "#8F993E"
    },
    {
        "name": "7496 C",
        "hex": "#76881D"
    },
    {
        "name": "7497 C",
        "hex": "#7A7256"
    },
    {
        "name": "7498 C",
        "hex": "#5B6236"
    },
    {
        "name": "2302 C",
        "hex": "#AEB862"
    },
    {
        "name": "2303 C",
        "hex": "#9EB356"
    },
    {
        "name": "2304 C",
        "hex": "#A0AB4D"
    },
    {
        "name": "2305 C",
        "hex": "#9EA700"
    },
    {
        "name": "2306 C",
        "hex": "#808C24"
    },
    {
        "name": "2307 C",
        "hex": "#686F12"
    },
    {
        "name": "2308 C",
        "hex": "#5D551D"
    },
    {
        "name": "7744 C",
        "hex": "#BABC16"
    },
    {
        "name": "7745 C",
        "hex": "#ABAD23"
    },
    {
        "name": "7746 C",
        "hex": "#999B30"
    },
    {
        "name": "7747 C",
        "hex": "#888D30"
    },
    {
        "name": "7748 C",
        "hex": "#7C8034"
    },
    {
        "name": "7749 C",
        "hex": "#727337"
    },
    {
        "name": "7750 C",
        "hex": "#656635"
    },
    {
        "name": "379 C",
        "hex": "#E2E868"
    },
    {
        "name": "380 C",
        "hex": "#DBE442"
    },
    {
        "name": "381 C",
        "hex": "#CEDC00"
    },
    {
        "name": "382 C",
        "hex": "#C4D600"
    },
    {
        "name": "383 C",
        "hex": "#A8AD00"
    },
    {
        "name": "384 C",
        "hex": "#949300"
    },
    {
        "name": "385 C",
        "hex": "#787121"
    },
    {
        "name": "386 C",
        "hex": "#E9EC6B"
    },
    {
        "name": "387 C",
        "hex": "#E3E935"
    },
    {
        "name": "388 C",
        "hex": "#E0E721"
    },
    {
        "name": "389 C",
        "hex": "#D0DF00"
    },
    {
        "name": "390 C",
        "hex": "#B5BD00"
    },
    {
        "name": "391 C",
        "hex": "#9A9500"
    },
    {
        "name": "392 C",
        "hex": "#827A04"
    },
    {
        "name": "587 C",
        "hex": "#E3E48D"
    },
    {
        "name": "586 C",
        "hex": "#E0E27C"
    },
    {
        "name": "585 C",
        "hex": "#DBDE70"
    },
    {
        "name": "584 C",
        "hex": "#D2D755"
    },
    {
        "name": "583 C",
        "hex": "#B7BF10"
    },
    {
        "name": "582 C",
        "hex": "#8E8C13"
    },
    {
        "name": "581 C",
        "hex": "#625D20"
    },
    {
        "name": "393 C",
        "hex": "#F0EC74"
    },
    {
        "name": "394 C",
        "hex": "#EDE939"
    },
    {
        "name": "395 C",
        "hex": "#ECE81A"
    },
    {
        "name": "396 C",
        "hex": "#E1E000"
    },
    {
        "name": "397 C",
        "hex": "#BFB800"
    },
    {
        "name": "398 C",
        "hex": "#ADA400"
    },
    {
        "name": "399 C",
        "hex": "#A09200"
    },
    {
        "name": "3935 C",
        "hex": "#F3EA5D"
    },
    {
        "name": "3945 C",
        "hex": "#F3E500"
    },
    {
        "name": "3955 C",
        "hex": "#EFDF00"
    },
    {
        "name": "3965 C",
        "hex": "#EEDC00"
    },
    {
        "name": "3975 C",
        "hex": "#BBA600"
    },
    {
        "name": "3985 C",
        "hex": "#9A8700"
    },
    {
        "name": "3995 C",
        "hex": "#685C20"
    },
    {
        "name": "600 C",
        "hex": "#F1EB9C"
    },
    {
        "name": "601 C",
        "hex": "#F0E991"
    },
    {
        "name": "602 C",
        "hex": "#F0E87B"
    },
    {
        "name": "603 C",
        "hex": "#EDE04B"
    },
    {
        "name": "604 C",
        "hex": "#EADA24"
    },
    {
        "name": "605 C",
        "hex": "#E1CD00"
    },
    {
        "name": "606 C",
        "hex": "#CFB500"
    },
    {
        "name": "607 C",
        "hex": "#EBE49A"
    },
    {
        "name": "608 C",
        "hex": "#E9E186"
    },
    {
        "name": "609 C",
        "hex": "#E6DE77"
    },
    {
        "name": "610 C",
        "hex": "#E1D555"
    },
    {
        "name": "611 C",
        "hex": "#D7C826"
    },
    {
        "name": "612 C",
        "hex": "#C4B000"
    },
    {
        "name": "613 C",
        "hex": "#B39B00"
    },
    {
        "name": "461 C",
        "hex": "#E9DF97"
    },
    {
        "name": "460 C",
        "hex": "#E4D77E"
    },
    {
        "name": "459 C",
        "hex": "#DECD63"
    },
    {
        "name": "458 C",
        "hex": "#D9C756"
    },
    {
        "name": "457 C",
        "hex": "#B89D18"
    },
    {
        "name": "456 C",
        "hex": "#A28E2A"
    },
    {
        "name": "455 C",
        "hex": "#695B24"
    },
    {
        "name": "614 C",
        "hex": "#DCD59A"
    },
    {
        "name": "615 C",
        "hex": "#D6CF8D"
    },
    {
        "name": "616 C",
        "hex": "#D0C883"
    },
    {
        "name": "617 C",
        "hex": "#C0B561"
    },
    {
        "name": "618 C",
        "hex": "#AC9F3C"
    },
    {
        "name": "619 C",
        "hex": "#9F912A"
    },
    {
        "name": "620 C",
        "hex": "#8A7B19"
    },
    {
        "name": "7751 C",
        "hex": "#CAB64B"
    },
    {
        "name": "7752 C",
        "hex": "#CFB023"
    },
    {
        "name": "7753 C",
        "hex": "#C1A01E"
    },
    {
        "name": "7754 C",
        "hex": "#A08629"
    },
    {
        "name": "7755 C",
        "hex": "#897630"
    },
    {
        "name": "7756 C",
        "hex": "#736635"
    },
    {
        "name": "7757 C",
        "hex": "#675E33"
    },
    {
        "name": "7758 C",
        "hex": "#D4C304"
    },
    {
        "name": "7759 C",
        "hex": "#C4B200"
    },
    {
        "name": "7760 C",
        "hex": "#91852C"
    },
    {
        "name": "7761 C",
        "hex": "#747136"
    },
    {
        "name": "7762 C",
        "hex": "#5D6439"
    },
    {
        "name": "7763 C",
        "hex": "#585C3B"
    },
    {
        "name": "7764 C",
        "hex": "#535435"
    },
    {
        "name": "7765 C",
        "hex": "#BBB323"
    },
    {
        "name": "7766 C",
        "hex": "#B4A91F"
    },
    {
        "name": "7767 C",
        "hex": "#AA9D2E"
    },
    {
        "name": "7768 C",
        "hex": "#8F7E35"
    },
    {
        "name": "7769 C",
        "hex": "#716135"
    },
    {
        "name": "7770 C",
        "hex": "#635939"
    },
    {
        "name": "7771 C",
        "hex": "#4E4934"
    },
    {
        "name": "4545 C",
        "hex": "#D5CB9F"
    },
    {
        "name": "4535 C",
        "hex": "#CFC493"
    },
    {
        "name": "4525 C",
        "hex": "#C5B783"
    },
    {
        "name": "4515 C",
        "hex": "#B3A369"
    },
    {
        "name": "4505 C",
        "hex": "#998542"
    },
    {
        "name": "4495 C",
        "hex": "#8C7732"
    },
    {
        "name": "4485 C",
        "hex": "#614F25"
    },
    {
        "name": "2323 C",
        "hex": "#B8B298"
    },
    {
        "name": "2324 C",
        "hex": "#B2A384"
    },
    {
        "name": "2325 C",
        "hex": "#A09074"
    },
    {
        "name": "2326 C",
        "hex": "#8B7C5E"
    },
    {
        "name": "2327 C",
        "hex": "#837958"
    },
    {
        "name": "2328 C",
        "hex": "#786748"
    },
    {
        "name": "2329 C",
        "hex": "#68593C"
    },
    {
        "name": "454 C",
        "hex": "#CAC7A7"
    },
    {
        "name": "453 C",
        "hex": "#BFBB98"
    },
    {
        "name": "452 C",
        "hex": "#B0AA7E"
    },
    {
        "name": "451 C",
        "hex": "#9B945F"
    },
    {
        "name": "450 C",
        "hex": "#594A25"
    },
    {
        "name": "449 C",
        "hex": "#524727"
    },
    {
        "name": "448 C",
        "hex": "#4A412A"
    },
    {
        "name": "7499 C",
        "hex": "#F1E6B2"
    },
    {
        "name": "7500 C",
        "hex": "#DFD1A7"
    },
    {
        "name": "7501 C",
        "hex": "#D9C89E"
    },
    {
        "name": "7502 C",
        "hex": "#CEB888"
    },
    {
        "name": "7503 C",
        "hex": "#A89968"
    },
    {
        "name": "7504 C",
        "hex": "#94795D"
    },
    {
        "name": "7505 C",
        "hex": "#816040"
    },
    {
        "name": "468 C",
        "hex": "#DDCBA4"
    },
    {
        "name": "467 C",
        "hex": "#D3BC8D"
    },
    {
        "name": "466 C",
        "hex": "#C6AA76"
    },
    {
        "name": "465 C",
        "hex": "#B9975B"
    },
    {
        "name": "464 C",
        "hex": "#8B5B29"
    },
    {
        "name": "463 C",
        "hex": "#744F28"
    },
    {
        "name": "462 C",
        "hex": "#5C462B"
    },
    {
        "name": "7506 C",
        "hex": "#EFDBB2"
    },
    {
        "name": "7507 C",
        "hex": "#FCD299"
    },
    {
        "name": "7508 C",
        "hex": "#E1B87F"
    },
    {
        "name": "7509 C",
        "hex": "#D6A461"
    },
    {
        "name": "7510 C",
        "hex": "#C6893F"
    },
    {
        "name": "7511 C",
        "hex": "#B77729"
    },
    {
        "name": "7512 C",
        "hex": "#A6631B"
    },
    {
        "name": "719 C",
        "hex": "#EDC8A3"
    },
    {
        "name": "720 C",
        "hex": "#E7B78A"
    },
    {
        "name": "721 C",
        "hex": "#DDA46F"
    },
    {
        "name": "722 C",
        "hex": "#C88242"
    },
    {
        "name": "723 C",
        "hex": "#B36924"
    },
    {
        "name": "724 C",
        "hex": "#934D11"
    },
    {
        "name": "725 C",
        "hex": "#7D3F16"
    },
    {
        "name": "475 C",
        "hex": "#F3CFB3"
    },
    {
        "name": "474 C",
        "hex": "#F1C6A7"
    },
    {
        "name": "473 C",
        "hex": "#F0BF9B"
    },
    {
        "name": "472 C",
        "hex": "#E59E6D"
    },
    {
        "name": "471 C",
        "hex": "#B86125"
    },
    {
        "name": "470 C",
        "hex": "#A45A2A"
    },
    {
        "name": "469 C",
        "hex": "#693F23"
    },
    {
        "name": "726 C",
        "hex": "#E0C09F"
    },
    {
        "name": "727 C",
        "hex": "#D9B48F"
    },
    {
        "name": "728 C",
        "hex": "#CDA077"
    },
    {
        "name": "729 C",
        "hex": "#B58150"
    },
    {
        "name": "730 C",
        "hex": "#9E652E"
    },
    {
        "name": "731 C",
        "hex": "#774212"
    },
    {
        "name": "732 C",
        "hex": "#623412"
    },
    {
        "name": "2316 C",
        "hex": "#BD9A7A"
    },
    {
        "name": "2317 C",
        "hex": "#B4875E"
    },
    {
        "name": "2318 C",
        "hex": "#A47449"
    },
    {
        "name": "2319 C",
        "hex": "#8C5C30"
    },
    {
        "name": "2320 C",
        "hex": "#714623"
    },
    {
        "name": "2321 C",
        "hex": "#6F5137"
    },
    {
        "name": "2322 C",
        "hex": "#4E3524"
    },
    {
        "name": "2309 C",
        "hex": "#E7CEB5"
    },
    {
        "name": "2310 C",
        "hex": "#D9C0A9"
    },
    {
        "name": "2311 C",
        "hex": "#CDA786"
    },
    {
        "name": "2312 C",
        "hex": "#B79A81"
    },
    {
        "name": "2313 C",
        "hex": "#C39367"
    },
    {
        "name": "2314 C",
        "hex": "#AC7644"
    },
    {
        "name": "2315 C",
        "hex": "#914910"
    },
    {
        "name": "4685 C",
        "hex": "#E0C6AD"
    },
    {
        "name": "4675 C",
        "hex": "#DCBFA6"
    },
    {
        "name": "4665 C",
        "hex": "#CDA788"
    },
    {
        "name": "4655 C",
        "hex": "#BF9474"
    },
    {
        "name": "4645 C",
        "hex": "#AD7C59"
    },
    {
        "name": "4635 C",
        "hex": "#946037"
    },
    {
        "name": "4625 C",
        "hex": "#4F2C1D"
    },
    {
        "name": "7513 C",
        "hex": "#E1B7A7"
    },
    {
        "name": "7514 C",
        "hex": "#D5A286"
    },
    {
        "name": "7515 C",
        "hex": "#C58B68"
    },
    {
        "name": "7516 C",
        "hex": "#99552B"
    },
    {
        "name": "7517 C",
        "hex": "#85431E"
    },
    {
        "name": "7518 C",
        "hex": "#6D4F47"
    },
    {
        "name": "7519 C",
        "hex": "#5E4B3C"
    },
    {
        "name": "4755 C",
        "hex": "#D7C4B7"
    },
    {
        "name": "4745 C",
        "hex": "#CDB5A7"
    },
    {
        "name": "4735 C",
        "hex": "#C0A392"
    },
    {
        "name": "4725 C",
        "hex": "#AE8A79"
    },
    {
        "name": "4715 C",
        "hex": "#956C58"
    },
    {
        "name": "4705 C",
        "hex": "#7C4D3A"
    },
    {
        "name": "4695 C",
        "hex": "#5B3427"
    },
    {
        "name": "482 C",
        "hex": "#DBC8B6"
    },
    {
        "name": "481 C",
        "hex": "#D3BBA8"
    },
    {
        "name": "480 C",
        "hex": "#C6A992"
    },
    {
        "name": "479 C",
        "hex": "#AA8066"
    },
    {
        "name": "478 C",
        "hex": "#703F2A"
    },
    {
        "name": "477 C",
        "hex": "#623B2A"
    },
    {
        "name": "476 C",
        "hex": "#4E3629"
    },
    {
        "name": "7527 C",
        "hex": "#D6D2C4"
    },
    {
        "name": "7528 C",
        "hex": "#C5B9AC"
    },
    {
        "name": "7529 C",
        "hex": "#B7A99A"
    },
    {
        "name": "7530 C",
        "hex": "#A39382"
    },
    {
        "name": "7531 C",
        "hex": "#7A6855"
    },
    {
        "name": "7532 C",
        "hex": "#63513D"
    },
    {
        "name": "7533 C",
        "hex": "#473729"
    },
    {
        "name": "7534 C",
        "hex": "#D1CCBD"
    },
    {
        "name": "7535 C",
        "hex": "#B7B09C"
    },
    {
        "name": "7536 C",
        "hex": "#A69F88"
    },
    {
        "name": "7537 C",
        "hex": "#A7ACA2"
    },
    {
        "name": "7538 C",
        "hex": "#949A90"
    },
    {
        "name": "7539 C",
        "hex": "#8E9089"
    },
    {
        "name": "7540 C",
        "hex": "#4B4F54"
    },
    {
        "name": "427 C",
        "hex": "#D0D3D4"
    },
    {
        "name": "428 C",
        "hex": "#C1C6C8"
    },
    {
        "name": "429 C",
        "hex": "#A2AAAD"
    },
    {
        "name": "430 C",
        "hex": "#7C878E"
    },
    {
        "name": "431 C",
        "hex": "#5B6770"
    },
    {
        "name": "432 C",
        "hex": "#333F48"
    },
    {
        "name": "433 C",
        "hex": "#1D252D"
    },
    {
        "name": "420 C",
        "hex": "#C7C9C7"
    },
    {
        "name": "421 C",
        "hex": "#B2B4B2"
    },
    {
        "name": "422 C",
        "hex": "#9EA2A2"
    },
    {
        "name": "423 C",
        "hex": "#898D8D"
    },
    {
        "name": "424 C",
        "hex": "#707372"
    },
    {
        "name": "425 C",
        "hex": "#54585A"
    },
    {
        "name": "426 C",
        "hex": "#25282A"
    },
    {
        "name": "441 C",
        "hex": "#BEC6C4"
    },
    {
        "name": "442 C",
        "hex": "#A2ACAB"
    },
    {
        "name": "443 C",
        "hex": "#919D9D"
    },
    {
        "name": "444 C",
        "hex": "#717C7D"
    },
    {
        "name": "445 C",
        "hex": "#505759"
    },
    {
        "name": "446 C",
        "hex": "#3F4444"
    },
    {
        "name": "447 C",
        "hex": "#373A36"
    },
    {
        "name": "413 C",
        "hex": "#BABBB1"
    },
    {
        "name": "414 C",
        "hex": "#A8A99E"
    },
    {
        "name": "415 C",
        "hex": "#919388"
    },
    {
        "name": "416 C",
        "hex": "#7E7F74"
    },
    {
        "name": "417 C",
        "hex": "#65665C"
    },
    {
        "name": "418 C",
        "hex": "#51534A"
    },
    {
        "name": "419 C",
        "hex": "#212322"
    },
    {
        "name": "2330 C",
        "hex": "#CFCDC9"
    },
    {
        "name": "2331 C",
        "hex": "#AEA8A5"
    },
    {
        "name": "2332 C",
        "hex": "#8C8985"
    },
    {
        "name": "2333 C",
        "hex": "#716E6A"
    },
    {
        "name": "2334 C",
        "hex": "#6C6463"
    },
    {
        "name": "2335 C",
        "hex": "#5E4F47"
    },
    {
        "name": "2336 C",
        "hex": "#4E4B48"
    },
    {
        "name": "400 C",
        "hex": "#C4BFB6"
    },
    {
        "name": "401 C",
        "hex": "#AFA9A0"
    },
    {
        "name": "402 C",
        "hex": "#9D968D"
    },
    {
        "name": "403 C",
        "hex": "#8C857B"
    },
    {
        "name": "404 C",
        "hex": "#776E64"
    },
    {
        "name": "405 C",
        "hex": "#696158"
    },
    {
        "name": "406 C",
        "hex": "#C4BCB7"
    },
    {
        "name": "407 C",
        "hex": "#B2A8A2"
    },
    {
        "name": "408 C",
        "hex": "#978C87"
    },
    {
        "name": "409 C",
        "hex": "#857874"
    },
    {
        "name": "410 C",
        "hex": "#746661"
    },
    {
        "name": "411 C",
        "hex": "#5E514D"
    },
    {
        "name": "412 C",
        "hex": "#382F2D"
    },
    {
        "name": "434 C",
        "hex": "#D0C4C5"
    },
    {
        "name": "435 C",
        "hex": "#C1B2B6"
    },
    {
        "name": "436 C",
        "hex": "#AB989D"
    },
    {
        "name": "437 C",
        "hex": "#7B6469"
    },
    {
        "name": "438 C",
        "hex": "#584446"
    },
    {
        "name": "439 C",
        "hex": "#453536"
    },
    {
        "name": "440 C",
        "hex": "#382E2C"
    },
    {
        "name": "Warm Gray 1 C",
        "hex": "#D7D2CB"
    },
    {
        "name": "Warm Gray 2 C",
        "hex": "#CBC4BC"
    },
    {
        "name": "Warm Gray 3 C",
        "hex": "#BFB8AF"
    },
    {
        "name": "Warm Gray 4 C",
        "hex": "#B6ADA5"
    },
    {
        "name": "Warm Gray 5 C",
        "hex": "#ACA39A"
    },
    {
        "name": "Warm Gray 6 C",
        "hex": "#A59C94"
    },
    {
        "name": "Warm Gray 7 C",
        "hex": "#968C83"
    },
    {
        "name": "Warm Gray 8 C",
        "hex": "#8C8279"
    },
    {
        "name": "Warm Gray 9 C",
        "hex": "#83786F"
    },
    {
        "name": "Warm Gray 10 C",
        "hex": "#796E65"
    },
    {
        "name": "Warm Gray 11 C",
        "hex": "#6E6259"
    },
    {
        "name": "Cool Gray 1 C",
        "hex": "#D9D9D6"
    },
    {
        "name": "Cool Gray 2 C",
        "hex": "#D0D0CE"
    },
    {
        "name": "Cool Gray 3 C",
        "hex": "#C8C9C7"
    },
    {
        "name": "Cool Gray 4 C",
        "hex": "#BBBCBC"
    },
    {
        "name": "Cool Gray 5 C",
        "hex": "#B1B3B3"
    },
    {
        "name": "Cool Gray 6 C",
        "hex": "#A7A8AA"
    },
    {
        "name": "Cool Gray 7 C",
        "hex": "#97999B"
    },
    {
        "name": "Cool Gray 8 C",
        "hex": "#888B8D"
    },
    {
        "name": "Cool Gray 9 C",
        "hex": "#75787B"
    },
    {
        "name": "Cool Gray 10 C",
        "hex": "#63666A"
    },
    {
        "name": "Cool Gray 11 C",
        "hex": "#53565A"
    },
    {
        "name": "Black 2 C",
        "hex": "#332F21"
    },
    {
        "name": "Black 3 C",
        "hex": "#212721"
    },
    {
        "name": "Black 4 C",
        "hex": "#31261D"
    },
    {
        "name": "Black 5 C",
        "hex": "#3E2B2E"
    },
    {
        "name": "Black 6 C",
        "hex": "#101820"
    },
    {
        "name": "Black 7 C",
        "hex": "#3D3935"
    },
    {
        "name": "3514 C",
        "hex": "#F1B300"
    },
    {
        "name": "3596 C",
        "hex": "#DEB681"
    },
    {
        "name": "3547 C",
        "hex": "#C18722"
    },
    {
        "name": "3588 C",
        "hex": "#FE8F1D"
    },
    {
        "name": "3564 C",
        "hex": "#F06400"
    },
    {
        "name": "2428 C",
        "hex": "#DD872C"
    },
    {
        "name": "2429 C",
        "hex": "#C25E1C"
    },
    {
        "name": "2430 C",
        "hex": "#D7A179"
    },
    {
        "name": "2431 C",
        "hex": "#DE9252"
    },
    {
        "name": "2432 C",
        "hex": "#DD8C59"
    },
    {
        "name": "2433 C",
        "hex": "#DB7A51"
    },
    {
        "name": "2434 C",
        "hex": "#E3775E"
    },
    {
        "name": "2435 C",
        "hex": "#C16F58"
    },
    {
        "name": "2436 C",
        "hex": "#B6523D"
    },
    {
        "name": "2437 C",
        "hex": "#F1BF9F"
    },
    {
        "name": "2438 C",
        "hex": "#E3A184"
    },
    {
        "name": "2439 C",
        "hex": "#C48571"
    },
    {
        "name": "2440 C",
        "hex": "#B49289"
    },
    {
        "name": "2441 C",
        "hex": "#A76E5E"
    },
    {
        "name": "2442 C",
        "hex": "#9F5F53"
    },
    {
        "name": "2443 C",
        "hex": "#8D3921"
    },
    {
        "name": "3544 C",
        "hex": "#E1B6A1"
    },
    {
        "name": "2444 C",
        "hex": "#DCA59C"
    },
    {
        "name": "3519 C",
        "hex": "#E0A4A1"
    },
    {
        "name": "3572 C",
        "hex": "#FE9B96"
    },
    {
        "name": "2445 C",
        "hex": "#DD878D"
    },
    {
        "name": "2446 C",
        "hex": "#D0858A"
    },
    {
        "name": "2447 C",
        "hex": "#B06F76"
    },
    {
        "name": "2448 C",
        "hex": "#DA6253"
    },
    {
        "name": "3556 C",
        "hex": "#E63422"
    },
    {
        "name": "3516 C",
        "hex": "#D1350F"
    },
    {
        "name": "3546 C",
        "hex": "#C00D1E"
    },
    {
        "name": "3517 C",
        "hex": "#BA0020"
    },
    {
        "name": "3523 C",
        "hex": "#733438"
    },
    {
        "name": "2449 C",
        "hex": "#472426"
    },
    {
        "name": "3568 C",
        "hex": "#F1C3D3"
    },
    {
        "name": "3595 C",
        "hex": "#EFB8CD"
    },
    {
        "name": "2450 C",
        "hex": "#D64388"
    },
    {
        "name": "3527 C",
        "hex": "#D12B92"
    },
    {
        "name": "3582 C",
        "hex": "#C8689A"
    },
    {
        "name": "2451 C",
        "hex": "#B74271"
    },
    {
        "name": "2452 C",
        "hex": "#C2366F"
    },
    {
        "name": "2453 C",
        "hex": "#B896C3"
    },
    {
        "name": "3520 C",
        "hex": "#AC75BD"
    },
    {
        "name": "3559 C",
        "hex": "#85459F"
    },
    {
        "name": "3593 C",
        "hex": "#9063AD"
    },
    {
        "name": "3575 C",
        "hex": "#926EBF"
    },
    {
        "name": "3543 C",
        "hex": "#A08ACC"
    },
    {
        "name": "3515 C",
        "hex": "#590776"
    },
    {
        "name": "3558 C",
        "hex": "#949CD0"
    },
    {
        "name": "3555 C",
        "hex": "#491D70"
    },
    {
        "name": "3583 C",
        "hex": "#471D7C"
    },
    {
        "name": "3574 C",
        "hex": "#574676"
    },
    {
        "name": "3566 C",
        "hex": "#452B6F"
    },
    {
        "name": "3535 C",
        "hex": "#370178"
    },
    {
        "name": "3542 C",
        "hex": "#2E1373"
    },
    {
        "name": "3506 C",
        "hex": "#2460A7"
    },
    {
        "name": "3590 C",
        "hex": "#3C539A"
    },
    {
        "name": "3584 C",
        "hex": "#333D79"
    },
    {
        "name": "3591 C",
        "hex": "#142E7B"
    },
    {
        "name": "3597 C",
        "hex": "#21366E"
    },
    {
        "name": "3581 C",
        "hex": "#283266"
    },
    {
        "name": "3524 C",
        "hex": "#2F2B4E"
    },
    {
        "name": "3577 C",
        "hex": "#85B3D1"
    },
    {
        "name": "3545 C",
        "hex": "#00B8DE"
    },
    {
        "name": "3551 C",
        "hex": "#009EC0"
    },
    {
        "name": "3538 C",
        "hex": "#008BCD"
    },
    {
        "name": "3553 C",
        "hex": "#006CB0"
    },
    {
        "name": "2454 C",
        "hex": "#277DA1"
    },
    {
        "name": "3526 C",
        "hex": "#788F98"
    },
    {
        "name": "2455 C",
        "hex": "#92BCB3"
    },
    {
        "name": "3533 C",
        "hex": "#52D2BC"
    },
    {
        "name": "3534 C",
        "hex": "#00AD9F"
    },
    {
        "name": "2456 C",
        "hex": "#309B8A"
    },
    {
        "name": "3560 C",
        "hex": "#009F92"
    },
    {
        "name": "3557 C",
        "hex": "#00797C"
    },
    {
        "name": "3541 C",
        "hex": "#00919D"
    },
    {
        "name": "2457 C",
        "hex": "#8BAA99"
    },
    {
        "name": "2458 C",
        "hex": "#689881"
    },
    {
        "name": "2459 C",
        "hex": "#55AF92"
    },
    {
        "name": "2460 C",
        "hex": "#6AAEAA"
    },
    {
        "name": "2461 C",
        "hex": "#259490"
    },
    {
        "name": "2462 C",
        "hex": "#5A8D84"
    },
    {
        "name": "2463 C",
        "hex": "#5B7570"
    },
    {
        "name": "2464 C",
        "hex": "#78BD70"
    },
    {
        "name": "2465 C",
        "hex": "#1D6E2F"
    },
    {
        "name": "3522 C",
        "hex": "#008330"
    },
    {
        "name": "3536 C",
        "hex": "#007B3E"
    },
    {
        "name": "3500 C",
        "hex": "#006B38"
    },
    {
        "name": "3537 C",
        "hex": "#00482B"
    },
    {
        "name": "2466 C",
        "hex": "#29504D"
    },
    {
        "name": "3570 C",
        "hex": "#A8C700"
    },
    {
        "name": "3507 C",
        "hex": "#ADCB00"
    },
    {
        "name": "3561 C",
        "hex": "#79C000"
    },
    {
        "name": "3501 C",
        "hex": "#44A705"
    },
    {
        "name": "3529 C",
        "hex": "#1CA421"
    },
    {
        "name": "3539 C",
        "hex": "#3D9525"
    },
    {
        "name": "3508 C",
        "hex": "#5B792F"
    },
    {
        "name": "3599 C",
        "hex": "#D7BB77"
    },
    {
        "name": "2467 C",
        "hex": "#B7916C"
    },
    {
        "name": "2468 C",
        "hex": "#97694C"
    },
    {
        "name": "2469 C",
        "hex": "#8F573A"
    },
    {
        "name": "2470 C",
        "hex": "#9A7F61"
    },
    {
        "name": "2471 C",
        "hex": "#A58876"
    },
    {
        "name": "2472 C",
        "hex": "#5F4028"
    },
    {
        "name": "2473 C",
        "hex": "#C3B7BC"
    },
    {
        "name": "2474 C",
        "hex": "#B0A098"
    },
    {
        "name": "2475 C",
        "hex": "#A0867F"
    },
    {
        "name": "2476 C",
        "hex": "#826B69"
    },
    {
        "name": "2477 C",
        "hex": "#705853"
    },
    {
        "name": "2478 C",
        "hex": "#4B353A"
    },
    {
        "name": "2479 C",
        "hex": "#3E332E"
    }
];