<template>
    <pattern :id="id" x="0" y="0" width="1788" height="4092.9" viewBox="0 0 1788 4092.9" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <path :fill="colors[`${id}-p-0`]" d="M1788.1,2997.5H-0.2V-1.1h1788.3V2997.5z"/>
        <path :fill="colors[`${id}-p-1`]" d="M884.7,2565.9c1.8,139.9,3.7,279.9,5.5,419.8c0.7,371.2,1.3,742.4,2,1113.6c-299.7,0.8-599.4,1.7-899.1,2.5
	l7.8-2092l3.7,2.3l7.7-429.2l-3.7-2.3l7.8-429.2l8.5,439.5l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3
	l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.7,439.4l-3.7-2.4l7.9,439
	l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3
	l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2
	l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4
	l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2
	l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4
	l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.6-429.3l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424
	l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3
	l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439
	l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3
	l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2
	l8.6,439.4l-3.7-2.4l7.9,439l3.7,2.4l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,438.9l3.7,2.4
	l7.8,424l7.6-414.4l3.7,2.3l7.8-429.2l-3.7-2.3l7.8-429.2l8.6,439.4l-3.7-2.4l7.9,439L884.7,2565.9z M1787.8,2009.8l-3.7,2.3
	l-7.8-429l3.7-2.3l-7.8-429.3l-8.6,439.5l3.7-2.4l-7.9,438.9l-3.7,2.4l-7.9,424l-7.6-414.4l-3.7,2.3l-7.8-429l3.7-2.3l-7.8-429.2
	l-8.6,439.5l3.7-2.4l-7.9,438.9l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429l3.7-2.3l-7.8-429.3l-8.6,439.5l3.7-2.4l-7.9,438.9
	l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-428.9l3.7-2.3l-7.8-429.3l-8.6,439.5l3.7-2.4l-7.9,438.8l-3.7,2.4l-7.8,424l-7.6-414.4
	l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-6.8-413.8l-3.7,2.3l-7.8-429.2l3.7-2.3
	l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.4,439.3l3.7-2.4
	l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.9-429.3l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424
	l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.6-429.2
	l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4
	l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4
	l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3
	l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2
	l-8.6,439.4l3.7-2.4l-7.9,438.9l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439
	l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4
	l-3.7,2.3l-7.8-429.2l3.7-2.3l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4l-7.8,424l-7.6-414.4l-3.7,2.3l-7.8-429.2l3.7-2.3
	l-7.8-429.2l-8.6,439.4l3.7-2.4l-7.9,439l-3.7,2.4c-3.8,140.8-7.7,281.5-11.5,422.3c0.7,371.2,1.3,742.4,2,1113.6
	c300.4,0,600.7,0,901.1,0L1787.8,2009.8z"/>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>
