<template>
    <div class="modal fade color-modal" id="colorModal" tabindex="-1" aria-labelledby="colorModalLabel" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="colorModalLabel">Color Picker</h5>
                    <div class="ms-lg-3 input-group">
                        <span class="input-group-text" id="search">
                            <font-awesome-icon icon="search"/>
                        </span>
                        <input type="text" class="form-control" v-model="search" placeholder="ZOEK" aria-label="Username" aria-describedby="search">
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="colors">
                        <div v-for="color in filteredColors" :key="color.name" class="color-wrapper" @click="$emit('selectColor', {id, color})">
                            <div class="position-relative">
                                <font-awesome-icon icon="check" v-if="selectedColor === color.hex"/>
                                <div class="color" :style="{'background-color': color.hex}"></div>
                            </div>
                            <span class="title">{{ color.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['id', 'initialised', 'selectedColor', 'colorOptions'],
    data() {
        return {
            search: '',
        }
    },
    computed: {
        filteredColors() {
            if (!this.initialised) {
                return [];
            }

            if (!this.search) {
                return this.colorOptions;
            }

            return this.colorOptions.filter(color => {
                return color.name.toLowerCase().includes(this.search.toLowerCase());
            });
        }
    }
}
</script>