<template>
    <pattern :id="id" x="0" y="0" width="995.8" height="1498.1" viewBox="0 0 995.8 1498.1" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <g id="Laag_2_1_">
            <g id="Layer_1">
                <rect x="0" y="0" :fill="colors[`${id}-p-0`]" width="995.8" height="1498.1"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -67.7035 136.7838)" :fill="colors[`${id}-p-1`]" cx="814.3" cy="488.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="843.3" cy="428.6" r="9.9"/>
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -84.3175 681.9208)" :fill="colors[`${id}-p-1`]" cx="781" cy="442.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="781" cy="362.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="321.5" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,400.2c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C757.2,410.1,761.6,405.7,761.6,400.2z"/>
                <path :fill="colors[`${id}-p-1`]" d="M833.3,261.9c0,5.5,4.4,9.9,9.9,9.9c5.5,0,9.9-4.4,9.9-9.9s-4.4-9.9-9.9-9.9c-2.6,0-5.1,1-7,2.9
			C834.4,256.8,833.3,259.3,833.3,261.9z"/>
                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 227.0547 891.9575)" :fill="colors[`${id}-p-1`]" cx="781" cy="276.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="383.3" r="9.9"/>
                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -68.1745 215.1027)" :fill="colors[`${id}-p-1`]" cx="889.8" cy="400.4" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 173.2711 1150.6296)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="445.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="483.5" r="9.9"/>
                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 104.9236 1107.3531)" :fill="colors[`${id}-p-1`]" cx="881.1" cy="475.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="364.4" r="9.9"/>
                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 252.9518 1097.3949)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="359.4" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.968 -0.2509 0.2509 0.968 -85.6866 261.0428)" :fill="colors[`${id}-p-1`]" cx="981" cy="466.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="881.1" cy="308.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="299.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="509.9" cy="428.6" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M519.8,261.9c0-5.5-4.4-9.9-9.9-9.9s-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9c0,0,0,0,0,0
			C515.4,271.8,519.8,267.4,519.8,261.9C519.8,261.9,519.8,261.9,519.8,261.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="556.5" cy="400.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="445.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.6" cy="404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="483.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="547.7" cy="475.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="546.6" cy="364.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="676.6" cy="345.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="359.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.6" cy="466.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="547.8" cy="308.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.7" cy="300" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="723.1" cy="483.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="713.2" cy="447.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="723.1" cy="317" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="391.8" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="713.2" cy="281" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="781" cy="195.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="154.9" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,233.5c0-5.5-4.5-9.9-9.9-9.9c-5.5,0-9.9,4.5-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C757.2,243.5,761.6,239,761.6,233.5z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="843.3" cy="95.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="781" cy="109.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="216.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="781" cy="29" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,66.9c0-5.5-4.4-9.9-9.9-10c-5.5,0-9.9,4.4-10,9.9c0,3.2,1.5,6.1,4,8c1.7,1.3,3.8,2,5.9,2
			C757.2,76.8,761.6,72.3,761.6,66.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="49.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="889.8" cy="233.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="238.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="197.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="192.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="889.8" cy="67" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="71.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="150.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="881.1" cy="141.8" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="31" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="26.1" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="133.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="509.9" cy="95.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -38.8021 134.0744)" :fill="colors[`${id}-p-1`]" cx="556.5" cy="233.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -37.3981 155.1341)" :fill="colors[`${id}-p-1`]" cx="647.6" cy="238.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="546.6" cy="197.7" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -19.8729 110.7168)" :fill="colors[`${id}-p-1`]" cx="676.6" cy="178.6" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -27.844 146.2657)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="192.7" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -0.5155 129.6183)" :fill="colors[`${id}-p-1`]" cx="556.5" cy="67" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.6" cy="71.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="150.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -17.9272 129.6152)" :fill="colors[`${id}-p-1`]" cx="547.7" cy="141.8" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9998 -1.998802e-02 1.998802e-02 0.9998 -0.5107 10.931)" :fill="colors[`${id}-p-1`]" cx="546.6" cy="31" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 6.8314 108.563)" :fill="colors[`${id}-p-1`]" cx="676.6" cy="11.9" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 3.757 98.7677)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="26.1" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -12.9865 105.4918)" :fill="colors[`${id}-p-1`]" cx="647.6" cy="133.3" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="723.1" cy="150.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="225.2" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 128.038 537.8257)" :fill="colors[`${id}-p-1`]" cx="713.2" cy="114.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="58.5" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -147.8161 143.2473)" :fill="colors[`${id}-p-1`]" cx="814.4" cy="988.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="843.3" cy="928.6" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -437.8752 828.3637)" :fill="colors[`${id}-p-1`]" cx="781" cy="942.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="781" cy="862.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="821.5" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,900.2c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C757.2,910.1,761.6,905.7,761.6,900.2z"/>
                <path :fill="colors[`${id}-p-1`]" d="M833.3,761.9c0,5.5,4.4,9.9,9.9,9.9c5.5,0,9.9-4.4,9.9-9.9c0-5.5-4.4-9.9-9.9-9.9c-2.6,0-5.1,1-7,2.9
			C834.4,756.8,833.3,759.3,833.3,761.9z"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -234.8868 1200.6167)" :fill="colors[`${id}-p-1`]" cx="781" cy="776.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="883.3" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -183.0324 228.4677)" :fill="colors[`${id}-p-1`]" cx="889.8" cy="900.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -288.6704 1459.2887)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="945.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="904.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="983.5" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -357.0179 1416.0122)" :fill="colors[`${id}-p-1`]" cx="881.1" cy="975.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="864.4" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -208.9805 1406.0479)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="859.4" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.968 -0.2509 0.2509 0.968 -211.1288 277.0404)" :fill="colors[`${id}-p-1`]" cx="981" cy="966.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="779" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="816.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="881.1" cy="808.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="799.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="509.9" cy="928.6" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M519.8,761.9c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9c0,0,0,0,0,0
			C515.4,771.8,519.8,767.4,519.8,761.9C519.8,761.9,519.8,761.9,519.8,761.9z"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -473.675 657.2001)" :fill="colors[`${id}-p-1`]" cx="556.5" cy="900.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -200.7972 166.3926)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="945.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.6" cy="904.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="983.5" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -209.356 151.892)" :fill="colors[`${id}-p-1`]" cx="547.7" cy="975.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="546.6" cy="864.4" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -126.6898 119.3342)" :fill="colors[`${id}-p-1`]" cx="676.6" cy="845.3" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -427.7677 686.1058)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="859.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -204.7199 174.6073)" :fill="colors[`${id}-p-1`]" cx="647.6" cy="966.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="779" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="816.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="547.8" cy="808.5" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -119.8033 114.1094)" :fill="colors[`${id}-p-1`]" cx="647.7" cy="799.9" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -206.6327 192.4107)" :fill="colors[`${id}-p-1`]" cx="723.1" cy="983.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="713.2" cy="947.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="723.1" cy="817" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="891.8" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -160.3354 184.7122)" :fill="colors[`${id}-p-1`]" cx="713.2" cy="781" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -263.1566 755.9937)" :fill="colors[`${id}-p-1`]" cx="781" cy="695.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="654.9" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,733.5c0-5.5-4.5-9.9-9.9-9.9c-5.5,0-9.9,4.5-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C757.2,743.5,761.6,739,761.6,733.5z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="843.3" cy="595.2" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -80.9018 1097.7355)" :fill="colors[`${id}-p-1`]" cx="781" cy="609.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="716.6" r="9.9"/>
                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -100.6301 193.5418)" :fill="colors[`${id}-p-1`]" cx="781" cy="529" rx="9.9" ry="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,566.9c0-5.5-4.4-9.9-9.9-10s-9.9,4.4-10,9.9c0,3.2,1.5,6.1,4,8c1.7,1.3,3.8,2,5.9,2
			C757.2,576.8,761.6,572.3,761.6,566.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="549.9" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -258.193 844.0929)" :fill="colors[`${id}-p-1`]" cx="889.8" cy="733.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9832 -0.1826 0.1826 0.9832 -118.2918 191.5162)" :fill="colors[`${id}-p-1`]" cx="981" cy="738.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="697.7" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -98.7517 160.7936)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="692.7" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -140.3352 795.28)" :fill="colors[`${id}-p-1`]" cx="889.8" cy="567" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="612.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="571.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="650.2" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -49.0522 1210.2281)" :fill="colors[`${id}-p-1`]" cx="881.1" cy="641.8" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="531" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="526.1" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="633.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="509.9" cy="595.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -153.6597 147.4416)" :fill="colors[`${id}-p-1`]" cx="556.4" cy="733.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -152.2557 168.5013)" :fill="colors[`${id}-p-1`]" cx="647.6" cy="738.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="546.6" cy="697.7" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -99.9839 117.1801)" :fill="colors[`${id}-p-1`]" cx="676.6" cy="678.6" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -142.7018 159.6306)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="692.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -115.3731 142.9856)" :fill="colors[`${id}-p-1`]" cx="556.5" cy="567" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="612.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.6" cy="571.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="650.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -132.7828 142.9799)" :fill="colors[`${id}-p-1`]" cx="547.7" cy="641.8" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="546.6" cy="531" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="676.6" cy="511.9" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -76.3556 105.2311)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="526.1" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -93.0991 111.9553)" :fill="colors[`${id}-p-1`]" cx="647.7" cy="633.3" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="723.1" cy="650.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="725.2" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -225.5126 684.2657)" :fill="colors[`${id}-p-1`]" cx="713.2" cy="614.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="558.5" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -227.9287 149.7107)" :fill="colors[`${id}-p-1`]" cx="814.4" cy="1488.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="843.3" cy="1428.6" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -791.43 974.8137)" :fill="colors[`${id}-p-1`]" cx="781" cy="1442.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="781" cy="1362.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="1321.5" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,1400.2c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C757.2,1410.1,761.6,1405.7,761.6,1400.2z"/>
                <path :fill="colors[`${id}-p-1`]" d="M833.3,1261.9c0,5.5,4.4,9.9,9.9,9.9c5.5,0,9.9-4.4,9.9-9.9c0-5.5-4.4-9.9-9.9-9.9c-2.6,0-5.1,1-7,2.9
			C834.4,1256.8,833.3,1259.3,833.3,1261.9z"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -696.8221 1509.2849)" :fill="colors[`${id}-p-1`]" cx="781" cy="1276.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="1383.3" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -297.8879 241.8324)" :fill="colors[`${id}-p-1`]" cx="889.8" cy="1400.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -750.6026 1767.9418)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="1445.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="1404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="1483.5" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -818.9502 1724.6652)" :fill="colors[`${id}-p-1`]" cx="881.1" cy="1475.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="1364.4" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -670.9219 1714.707)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="1359.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.968 -0.2509 0.2509 0.968 -336.5685 293.0377)" :fill="colors[`${id}-p-1`]" cx="981" cy="1466.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="1279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="1316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="881.1" cy="1308.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="1299.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="509.9" cy="1428.6" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M519.8,1261.9c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9c0,0,0,0,0,0
			C515.4,1271.8,519.8,1267.4,519.8,1261.9C519.8,1261.9,519.8,1261.9,519.8,1261.9z"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -827.2298 803.6501)" :fill="colors[`${id}-p-1`]" cx="556.5" cy="1400.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -315.6551 179.7576)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="1445.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.6" cy="1404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="1483.5" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -324.2138 165.2569)" :fill="colors[`${id}-p-1`]" cx="547.7" cy="1475.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="546.6" cy="1364.4" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -206.8008 125.7976)" :fill="colors[`${id}-p-1`]" cx="676.7" cy="1345.2" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -781.3225 832.5558)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="1359.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -319.5778 187.9722)" :fill="colors[`${id}-p-1`]" cx="647.6" cy="1466.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="1279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="1316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="547.8" cy="1308.5" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -199.9159 120.5728)" :fill="colors[`${id}-p-1`]" cx="647.7" cy="1299.9" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -321.4882 205.7753)" :fill="colors[`${id}-p-1`]" cx="723.1" cy="1483.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="713.2" cy="1447.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="723.1" cy="1317" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="1391.8" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -275.191 198.0769)" :fill="colors[`${id}-p-1`]" cx="713.2" cy="1281" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -616.7114 902.4437)" :fill="colors[`${id}-p-1`]" cx="781" cy="1195.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="1154.9" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,1233.5c0-5.5-4.5-9.9-9.9-9.9c-5.5,0-9.9,4.5-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C757.2,1243.5,761.6,1239,761.6,1233.5L761.6,1233.5z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="843.3" cy="1095.2" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -542.8372 1406.4038)" :fill="colors[`${id}-p-1`]" cx="781" cy="1109.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="1216.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="780.9" cy="1029" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M761.6,1066.9c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,3.1,1.5,6.1,4,8c1.7,1.3,3.8,2,5.9,2
			C757.2,1076.8,761.6,1072.4,761.6,1066.9C761.6,1066.9,761.6,1066.9,761.6,1066.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="814.3" cy="1049.9" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -611.7407 990.54)" :fill="colors[`${id}-p-1`]" cx="889.8" cy="1233.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9832 -0.1826 0.1826 0.9832 -209.5802 199.9222)" :fill="colors[`${id}-p-1`]" cx="981" cy="1238.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="879.9" cy="1197.7" r="9.9"/>

                <ellipse transform="matrix(0.9872 -0.1595 0.1595 0.9872 -178.7154 166.4605)" :fill="colors[`${id}-p-1`]" cx="947.3" cy="1196.2" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -493.8929 941.7229)" :fill="colors[`${id}-p-1`]" cx="889.8" cy="1067" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="947.7" cy="1112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="1071.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="918.4" cy="1150.2" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -510.9937 1518.8872)" :fill="colors[`${id}-p-1`]" cx="881.1" cy="1141.8" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 -325.6934 1650.4938)" :fill="colors[`${id}-p-1`]" cx="879.9" cy="1031" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.2567 -0.9665 0.9665 0.2567 -287.2745 1678.6232)" :fill="colors[`${id}-p-1`]" cx="947.7" cy="1026.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="981" cy="1133.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="509.9" cy="1095.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -268.5153 160.8063)" :fill="colors[`${id}-p-1`]" cx="556.4" cy="1233.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -267.1135 181.8663)" :fill="colors[`${id}-p-1`]" cx="647.6" cy="1238.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="546.6" cy="1197.7" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -180.0965 123.6436)" :fill="colors[`${id}-p-1`]" cx="676.7" cy="1178.6" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -257.5572 172.9976)" :fill="colors[`${id}-p-1`]" cx="614.3" cy="1192.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -230.2287 156.3503)" :fill="colors[`${id}-p-1`]" cx="556.4" cy="1067" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="614.3" cy="1112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="647.6" cy="1071.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="585" cy="1150.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -247.6403 156.3472)" :fill="colors[`${id}-p-1`]" cx="547.7" cy="1141.8" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -615.1263 1141.4232)" :fill="colors[`${id}-p-1`]" cx="546.6" cy="1031" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -153.3939 121.4899)" :fill="colors[`${id}-p-1`]" cx="676.6" cy="1011.9" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.2304 -0.9731 0.9731 0.2304 -529.5293 1388.4612)" :fill="colors[`${id}-p-1`]" cx="613" cy="1029" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -173.2118 118.4172)" :fill="colors[`${id}-p-1`]" cx="647.7" cy="1133.3" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="723.1" cy="1150.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="1225.2" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -579.0674 830.7158)" :fill="colors[`${id}-p-1`]" cx="713.2" cy="1114.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="714.4" cy="1058.5" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -234.3922 69.5981)" :fill="colors[`${id}-p-1`]" cx="314.4" cy="1488.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="343.3" cy="1428.6" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -937.8729 621.2559)" :fill="colors[`${id}-p-1`]" cx="281" cy="1442.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="1362.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="1321.5" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,1400.2c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C257.2,1410.1,261.6,1405.7,261.6,1400.2z"/>
                <path :fill="colors[`${id}-p-1`]" d="M333.3,1261.9c0,5.5,4.4,9.9,9.9,9.9c5.5,0,9.9-4.4,9.9-9.9s-4.4-9.9-9.9-9.9c-2.6,0-5.1,1-7,2.9
			C334.4,1256.8,333.3,1259.3,333.3,1261.9z"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -1005.4812 1047.3435)" :fill="colors[`${id}-p-1`]" cx="281" cy="1276.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="1383.3" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -311.2549 126.9771)" :fill="colors[`${id}-p-1`]" cx="389.8" cy="1400.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -1059.2617 1306.0002)" :fill="colors[`${id}-p-1`]" cx="447.7" cy="1445.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="1404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="1483.5" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -1127.6093 1262.7238)" :fill="colors[`${id}-p-1`]" cx="381.1" cy="1475.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="379.9" cy="1364.4" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -979.5811 1252.7655)" :fill="colors[`${id}-p-1`]" cx="447.7" cy="1359.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -228.7738 96.0242)" :fill="colors[`${id}-p-1`]" cx="481" cy="1466.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="1279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="1316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="381.1" cy="1308.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="1299.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="9.9" cy="1428.6" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M19.8,1261.9c0-5.5-4.4-9.9-9.9-9.9s-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9c0,0,0,0,0,0
			C15.4,1271.8,19.8,1267.4,19.8,1261.9C19.8,1261.9,19.8,1261.9,19.8,1261.9z"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -973.6727 450.0924)" :fill="colors[`${id}-p-1`]" cx="56.5" cy="1400.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -329.0201 64.8997)" :fill="colors[`${id}-p-1`]" cx="114.2" cy="1445.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="1404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="1483.5" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -337.5785 50.4013)" :fill="colors[`${id}-p-1`]" cx="47.7" cy="1475.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="46.6" cy="1364.4" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -213.2641 45.6866)" :fill="colors[`${id}-p-1`]" cx="176.7" cy="1345.3" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -927.7654 478.9981)" :fill="colors[`${id}-p-1`]" cx="114.3" cy="1359.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -332.9427 73.1144)" :fill="colors[`${id}-p-1`]" cx="147.6" cy="1466.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="1279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="1316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="47.8" cy="1308.5" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -206.3794 40.4602)" :fill="colors[`${id}-p-1`]" cx="147.7" cy="1300" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -334.8555 90.9177)" :fill="colors[`${id}-p-1`]" cx="223.1" cy="1483.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="213.2" cy="1447.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="223.1" cy="1317" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="1391.8" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -288.5557 83.2214)" :fill="colors[`${id}-p-1`]" cx="213.2" cy="1281" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -763.1543 548.886)" :fill="colors[`${id}-p-1`]" cx="281" cy="1195.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="1154.9" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,1233.5c0-5.5-4.5-9.9-9.9-9.9c-5.5,0-9.9,4.5-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C257.2,1243.5,261.6,1239,261.6,1233.5L261.6,1233.5z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="343.3" cy="1095.2" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -851.4962 944.4623)" :fill="colors[`${id}-p-1`]" cx="281" cy="1109.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="1216.6" r="9.9"/>

                <ellipse transform="matrix(0.9975 -7.084528e-02 7.084528e-02 0.9975 -72.192 22.4883)" :fill="colors[`${id}-p-1`]" cx="280.9" cy="1029" rx="9.9" ry="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,1066.9c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,3.1,1.5,6.1,4,8c1.7,1.3,3.8,2,5.9,2
			C257.2,1076.8,261.6,1072.4,261.6,1066.9C261.6,1066.9,261.6,1066.9,261.6,1066.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="1049.9" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -758.1907 636.9852)" :fill="colors[`${id}-p-1`]" cx="389.8" cy="1233.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -192.1802 93.0713)" :fill="colors[`${id}-p-1`]" cx="481" cy="1238.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="379.9" cy="1197.7" r="9.9"/>

                <ellipse transform="matrix(0.9872 -0.1595 0.1595 0.9872 -185.1245 86.4534)" :fill="colors[`${id}-p-1`]" cx="445.8" cy="1196.1" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -640.3329 588.1723)" :fill="colors[`${id}-p-1`]" cx="389.8" cy="1067" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="1112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="1071.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="1150.2" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -819.6528 1056.9457)" :fill="colors[`${id}-p-1`]" cx="381.1" cy="1141.8" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -698.6549 1240.8549)" :fill="colors[`${id}-p-1`]" cx="379.9" cy="1031" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -636.8726 1303.5912)" :fill="colors[`${id}-p-1`]" cx="447.7" cy="1026.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="1133.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="9.9" cy="1095.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -281.8825 45.9487)" :fill="colors[`${id}-p-1`]" cx="56.4" cy="1233.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -280.4785 67.0084)" :fill="colors[`${id}-p-1`]" cx="147.6" cy="1238.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="46.6" cy="1197.7" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -186.56 43.5309)" :fill="colors[`${id}-p-1`]" cx="176.6" cy="1178.6" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -270.9244 58.14)" :fill="colors[`${id}-p-1`]" cx="114.3" cy="1192.7" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -243.5957 41.495)" :fill="colors[`${id}-p-1`]" cx="56.4" cy="1067" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="1112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="1071.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="1150.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -261.0053 41.4893)" :fill="colors[`${id}-p-1`]" cx="47.7" cy="1141.8" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -978.5914 911.829)" :fill="colors[`${id}-p-1`]" cx="46.6" cy="1031" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="176.6" cy="1011.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.4" cy="1026.1" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -179.6752 38.3062)" :fill="colors[`${id}-p-1`]" cx="147.7" cy="1133.3" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="223.1" cy="1150.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="1225.2" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -725.5174 477.1609)" :fill="colors[`${id}-p-1`]" cx="213.2" cy="1114.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="1058.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.4" cy="988.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="343.3" cy="928.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="942.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="862.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="821.5" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,900.2c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C257.2,910.1,261.6,905.7,261.6,900.2z"/>
                <path :fill="colors[`${id}-p-1`]" d="M333.3,761.9c0,5.5,4.4,9.9,9.9,9.9c5.5,0,9.9-4.4,9.9-9.9c0-5.5-4.4-9.9-9.9-9.9c-2.6,0-5.1,1-7,2.9
			C334.4,756.8,333.3,759.3,333.3,761.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="776.1" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="883.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="389.8" cy="900.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="945.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="904.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="983.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="381.1" cy="975.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="379.9" cy="864.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="859.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="966.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="779" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="816.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="381.1" cy="808.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="799.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="9.9" cy="928.6" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M19.8,761.9c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9c0,0,0,0,0,0
			C15.4,771.8,19.8,767.4,19.8,761.9C19.8,761.9,19.8,761.9,19.8,761.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="56.5" cy="900.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="945.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="904.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="983.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="47.7" cy="975.1" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="46.6" cy="864.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="176.6" cy="845.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="859.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="966.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="779" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="816.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="47.8" cy="808.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.7" cy="800" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="223.1" cy="983.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="213.2" cy="947.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="223.1" cy="817" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="891.8" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="213.2" cy="781" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="695.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="654.9" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,733.5c0-5.5-4.5-9.9-9.9-9.9c-5.5,0-9.9,4.5-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C257.2,743.5,261.6,739,261.6,733.5z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="343.3" cy="595.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="609.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="716.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="529" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,566.9c0-5.5-4.4-9.9-9.9-10c-5.5,0-9.9,4.4-10,9.9c0,3.2,1.5,6.1,4,8c1.7,1.3,3.8,2,5.9,2
			C257.2,576.8,261.6,572.3,261.6,566.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="549.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="389.8" cy="733.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="738.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="379.9" cy="697.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="692.8" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="389.8" cy="567" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="612.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="571.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="650.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="381.1" cy="641.8" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="379.9" cy="531" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="526.1" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="633.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="9.9" cy="595.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="56.4" cy="733.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="738.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="46.6" cy="697.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="176.6" cy="678.6" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="692.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="56.4" cy="567" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="612.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="571.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="650.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="47.7" cy="641.8" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="46.6" cy="531" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -79.7447 34.9138)" :fill="colors[`${id}-p-1`]" cx="176.6" cy="511.9" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="526.1" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.7" cy="633.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="223.1" cy="650.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="725.2" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="213.2" cy="614.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="558.5" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -74.1668 56.6728)" :fill="colors[`${id}-p-1`]" cx="314.3" cy="488.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="343.3" cy="428.6" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -230.7674 328.366)" :fill="colors[`${id}-p-1`]" cx="281" cy="442.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="281" cy="362.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="321.5" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,400.2c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C257.2,410.1,261.6,405.7,261.6,400.2z"/>
                <path :fill="colors[`${id}-p-1`]" d="M333.3,261.9c0,5.5,4.4,9.9,9.9,9.9c5.5,0,9.9-4.4,9.9-9.9s-4.4-9.9-9.9-9.9c-2.6,0-5.1,1-7,2.9
			C334.4,256.8,333.3,259.3,333.3,261.9z"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -81.5983 430.0252)" :fill="colors[`${id}-p-1`]" cx="281" cy="276.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="383.3" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -81.5418 100.2451)" :fill="colors[`${id}-p-1`]" cx="389.8" cy="400.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -135.388 688.6882)" :fill="colors[`${id}-p-1`]" cx="447.7" cy="445.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="483.5" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -203.7355 645.4116)" :fill="colors[`${id}-p-1`]" cx="381.1" cy="475.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="379.9" cy="364.4" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -55.7073 635.4534)" :fill="colors[`${id}-p-1`]" cx="447.7" cy="359.4" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -68.5502 83.0974)" :fill="colors[`${id}-p-1`]" cx="481" cy="466.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="381.1" cy="308.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="299.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="9.9" cy="428.6" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M19.8,261.9c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9c0,0,0,0,0,0
			C15.4,271.8,19.8,267.4,19.8,261.9C19.8,261.9,19.8,261.9,19.8,261.9z"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -266.5673 157.2024)" :fill="colors[`${id}-p-1`]" cx="56.5" cy="400.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -99.3066 38.1701)" :fill="colors[`${id}-p-1`]" cx="114.3" cy="445.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="404.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="483.5" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -107.8653 23.6694)" :fill="colors[`${id}-p-1`]" cx="47.7" cy="475.1" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="46.6" cy="364.4" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -53.0405 32.7598)" :fill="colors[`${id}-p-1`]" cx="176.6" cy="345.3" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -220.66 186.1081)" :fill="colors[`${id}-p-1`]" cx="114.3" cy="359.4" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -103.2293 46.3847)" :fill="colors[`${id}-p-1`]" cx="147.6" cy="466.6" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="279" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="316.9" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="47.8" cy="308.5" r="9.9"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -46.1542 27.5333)" :fill="colors[`${id}-p-1`]" cx="147.7" cy="300" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -105.1397 64.1878)" :fill="colors[`${id}-p-1`]" cx="223.1" cy="483.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="213.2" cy="447.7" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="223.1" cy="317" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="391.8" r="9.9"/>
                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -58.8425 56.4894)" :fill="colors[`${id}-p-1`]" cx="213.2" cy="281" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -56.0488 255.996)" :fill="colors[`${id}-p-1`]" cx="281" cy="195.7" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="154.9" r="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,233.5c0-5.5-4.5-9.9-9.9-9.9c-5.5,0-9.9,4.5-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9
			C257.2,243.5,261.6,239,261.6,233.5z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="343.3" cy="95.2" r="9.9"/>
                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 72.3776 327.1502)" :fill="colors[`${id}-p-1`]" cx="281" cy="109.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="216.6" r="9.9"/>
                <ellipse transform="matrix(0.9697 -0.2444 0.2444 0.9697 1.4433 69.5579)" :fill="colors[`${id}-p-1`]" cx="281" cy="29" rx="9.9" ry="9.9"/>
                <path :fill="colors[`${id}-p-1`]" d="M261.6,66.9c0-5.5-4.4-9.9-9.9-10c-5.5,0-9.9,4.4-10,9.9c0,3.2,1.5,6.1,4,8c1.7,1.3,3.8,2,5.9,2
			C257.2,76.8,261.6,72.3,261.6,66.9z"/>
                <circle :fill="colors[`${id}-p-1`]" cx="314.3" cy="49.9" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -51.0853 344.0952)" :fill="colors[`${id}-p-1`]" cx="389.8" cy="233.7" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -31.9565 80.1444)" :fill="colors[`${id}-p-1`]" cx="481" cy="238.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="379.9" cy="197.7" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -25.1025 74.2191)" :fill="colors[`${id}-p-1`]" cx="447.7" cy="192.8" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 66.7697 295.2752)" :fill="colors[`${id}-p-1`]" cx="389.8" cy="67" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="71.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="418.4" cy="150.2" r="9.9"/>

                <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 104.2302 439.6275)" :fill="colors[`${id}-p-1`]" cx="381.1" cy="141.8" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(1.281734e-02 -0.9999 0.9999 1.281734e-02 344.0182 410.4813)" :fill="colors[`${id}-p-1`]" cx="379.9" cy="31" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="447.7" cy="26.1" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="481" cy="133.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="9.9" cy="95.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -52.1668 19.2188)" :fill="colors[`${id}-p-1`]" cx="56.5" cy="233.7" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -50.7651 40.2788)" :fill="colors[`${id}-p-1`]" cx="147.6" cy="238.2" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="46.6" cy="197.7" r="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -26.3362 30.6057)" :fill="colors[`${id}-p-1`]" cx="176.6" cy="178.6" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -41.2112 31.408)" :fill="colors[`${id}-p-1`]" cx="114.3" cy="192.7" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -13.8825 14.763)" :fill="colors[`${id}-p-1`]" cx="56.5" cy="67" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="114.3" cy="112.3" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="147.6" cy="71.5" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="85" cy="150.2" r="9.9"/>

                <ellipse transform="matrix(0.9733 -0.2297 0.2297 0.9733 -31.2921 14.7574)" :fill="colors[`${id}-p-1`]" cx="47.7" cy="141.8" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(1.281734e-02 -0.9999 0.9999 1.281734e-02 14.9607 77.1787)" :fill="colors[`${id}-p-1`]" cx="46.6" cy="31" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 0.3679 28.4503)" :fill="colors[`${id}-p-1`]" cx="176.6" cy="11.9" rx="9.9" ry="9.9"/>
                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -2.7064 18.655)" :fill="colors[`${id}-p-1`]" cx="114.3" cy="26.1" rx="9.9" ry="9.9"/>

                <ellipse transform="matrix(0.9871 -0.1602 0.1602 0.9871 -19.4499 25.3792)" :fill="colors[`${id}-p-1`]" cx="147.6" cy="133.3" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="223.1" cy="150.4" r="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="225.2" r="9.9"/>

                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -18.4049 184.268)" :fill="colors[`${id}-p-1`]" cx="213.2" cy="114.4" rx="9.9" ry="9.9"/>
                <circle :fill="colors[`${id}-p-1`]" cx="214.4" cy="58.5" r="9.9"/>
            </g>
        </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>