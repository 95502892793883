export default {
    props: {
        id     : {
            required: true
        },
        scale   : {
            type    : Number,
            required: true
        },
        colors: {
            required: true,
        },
        rotate: {
            required: true
        }
    },

    computed: {
        transform() {
            let transform = [];

            if (this.rotate) {
                transform.push(`rotate(${this.rotate})`);
            }

            if (this.scale) {
                let scale = this.scale / 100;
                transform.push(`scale(${scale}, ${scale})`);
            }

            return transform.join(' ');
        }
    }
}