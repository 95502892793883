<template>
    <pattern :id="id" x="0" y="0" width="2300" height="2300" viewBox="0 0 2300 2300" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <rect :fill="colors[`${id}-p-0`]" width="2300" height="2300"/>
        <g>
            <defs>
                <rect id="SVGID_1_" width="2300" height="2300"/>
            </defs>
            <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
            </clipPath>
            <path :fill="colors[`${id}-p-1`]" d="M555,1171.1c-8,1.4-14.9,6.2-23.6,9.9c3.1-18.6,3.1-18.4,13.5-31.4c1.7-2.3,0.7-6.2,1-11
        c13.5,0.3,26.7-12.4,42.6,1.7c-1.4,8.5-8,18.9,2.4,27.7c-3.5,1.4-7.3,3.4-11.1,3.4C571.6,1171.7,563,1169.4,555,1171.1z"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1682.9,2303.6 1586.8,2294.8 1482.6,2313.5 1289.2,2300.8 1199.2,2307.5 1066.3,2314.3 706.7,2310.1
        664.2,2299.6 990.1,2271.9 1177.5,2263.2 1345.4,2270.5 1429.1,2259.5 1527,2261.2 1964.6,2257.5 2185.5,2269.7 2259,2293.1
        2132.4,2296.5 1980.9,2285.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1682.9,4.2 1586.8,-4.6 1482.6,14.1 1289.2,1.4 1199.2,8.1 1066.3,14.9 706.7,10.7 664.2,0.2
        990.1,-27.5 1177.5,-36.2 1345.4,-28.9 1429.1,-39.9 1527,-38.2 1964.6,-41.9 2185.5,-29.7 2259,-6.3 2132.4,-2.9 1980.9,-13.9
        "/>
            <polygon :fill="colors[`${id}-p-1`]" points="257.5,2259.7 431.3,2263.4 700.8,2238.2 820,2237.9 864.7,2231.2 1006.6,2226.1 942,2216.8
        778.7,2209.4 622.5,2204 426.3,2191 360.8,2193 254.3,2183.1 -23.4,2199.2 -72.1,2194.7 -78.9,2214.8 303,2209.7 343,2217.3
        305.2,2223.8 -141.2,2249.8 5.4,2275 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1362.8,2211.4 1450.8,2217.9 1522.9,2206.3 1634.5,2205.2 1711.9,2213.1 1767.7,2208.8 2098.8,2219
        2134.5,2211.1 2104.5,2196.4 2058,2199.5 2019.3,2196.4 1942.8,2190.2 1777.9,2172.1 1672.3,2169.9 1420.4,2176.1 1155.7,2164.2
        883.6,2157.7 757,2145.8 603.1,2151.2 333.6,2150.9 351.6,2165.6 617.2,2175.5 728.8,2179.7 812.8,2181.4 1041,2212.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1874.1,2148.4 1780.7,2145.6 1652.9,2146.1 1437.4,2128.9 1141.7,2119 1150.8,2095.8 1219.6,2101.2
        1301.4,2077.2 1599.8,2066.2 1767.8,2057.7 1772.3,2065.3 1655.9,2076.1 1555.5,2091.6 1834.4,2106.6 1883.7,2092.2 1940.2,2091.3
        2106.5,2106.9 2111.7,2122.4 2190,2134 2240.4,2158.6 2098.7,2163.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="511.7,2130 586.2,2121.5 659,2111.1 831.9,2124.1 993.1,2127.2 896.4,2111.9 891.9,2098.1
        899.8,2097.8 1008.3,2081.4 1066.2,2067.3 1116.1,2060.5 1176.4,2046.1 1275.8,2046.1 1497,2038.2 1456.4,2030.3 1332,2031.4
        1259.9,2029.7 1210.7,2035.1 1038.5,2047.2 949,2061.4 886.7,2073 837.1,2085.9 699.5,2081.7 641.6,2090.5 554.3,2092.4
        394.9,2129.2 433.4,2126.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="115.9,2017.9 196.7,2020.1 244.8,2042.4 235.5,2058 193.9,2062.8 299.6,2055.2 357.1,2053.7
        394.5,2061.4 447.6,2061.9 617.7,2055.7 557.4,2043.9 480.1,2032.3 450.3,2028 314.8,2036.5 294.7,2031.4 298.9,2024.1
        264.6,2019.3 225.4,2009.1 132.5,1999.8 91.3,1999.8 -80.6,1995.3 -93.8,1999.2 -126,2021.5 -62.2,2013.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1866.7,2049.5 1819.8,2038.5 1793.9,2023.8 1665.7,2008 1575.4,2009.1 1497.2,1994.7 1384.4,2000.1
        1321.5,2006.3 1164.3,2008 1297.7,1984.8 1397,1991.3 1493.6,1963.9 1390.6,1956.3 1378.6,1956 1384.8,1950.3 1427,1950.6
        1677.8,1928.9 1775.5,1942.7 1930.6,1939.3 1894.1,1959.1 1842.9,1960.5 1789.6,1966.4 1722.6,1976.9 1904.8,1990.5 1981.2,1998.1
        2131.7,2000.6 2155.9,2022.7 2182.8,2063.3 2103.2,2076.3 2019.7,2052.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1048.9,2011.4 1000,2024.4 940.4,2022.4 862.7,2039.9 764.3,2053.5 729.3,2055.4 705.4,2054.9
        607.7,2030.3 577.9,2020.7 513.8,2016.2 495,2013.6 486.4,2000.3 563.7,2008 605.3,1998.1 642.3,2013.1 688.4,2017 773.3,2021.8
        757.4,2008.8 676.6,1986.8 658.3,1985.1 843,1986.5 871.4,1988.8 875.6,1997.8 899.1,2003.7 1014.5,1987.3 1082.8,1998.1
        1103.6,2004.6 1078,2001.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-37.3,1950.3 68.8,1969.5 144,1965.6 167.9,1979.2 317.3,1988.5 464.2,1977.7 262.2,1955.1
        235.5,1942.1 158.9,1944.4 62.9,1934 -92.4,1932.5 -145,1945.5 -165.1,1956 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1104.6,1950.6 1040.2,1957.1 941.4,1959.7 916.1,1962.2 869.3,1962.5 790,1954.9 652,1958
        593.8,1958.8 539.1,1957.1 482.9,1955.1 421.9,1943.8 586.9,1938.8 653.4,1934 708.9,1933.7 892.9,1926.6 791.7,1939.9
        897.4,1938.5 952.5,1926 1060.3,1934.8 1205.8,1927.7 1128.6,1937.3 1164.6,1940.2 	"/>
            <path :fill="colors[`${id}-p-1`]" d="M252.8,2131.2l-111.6-9.9l-17-13.8l-3.8-2.8l20.1-2.3l92.9-14.7l-77.6-12.1l-84.2-10.7l-124.1-3.7l-41.9-7.1
        l83.9-12.1l112.3-8.2l-65.5-3.7l-223.9,0.8l-38.8,13.3l32.9,5.9l-8.3,21.2l-22.5,1.1l-188.2,4.5l-48.5,4.2l11.8,6.8l156.3,4.5
        l89.8,1.7l67.2,7.3l62.4,13.6l-20.8,17.2l-36.4,4l-11.1,7.9l14.6,8.5l126.5-9l91.5,20.1l154.6-3.7l77.3,13L252.8,2131.2z
         M26.5,2108.3c-9.7-1.4-19.4-3.4-29.5-4.8c1-1.7,2.4-3.1,4.5-4c-20.8-1.1-41.6-2-62.7-3.1c2.1-2,4.5-3.7,7.3-5.4
        c-23.9,2-48.9,1.1-72.4-2.3c8.7-1.1,17.3-2.5,26-3.7c-20.8,1.4-42.3-0.6-61.3-5.9c16.6-7.9,9.4-3.1,26-10.2
        c-0.7-0.3-0.7-0.3,0.7-0.3c-0.3,0-0.7,0.3-0.7,0.3c3.1,0.6,19.4,1.7,13.5,2c25,0.3,49.9,1.1,74.5,2.5c-2.8,1.4-6.2,2.5-10.1,2.8
        c10.1,1.7,20.8-0.8,30.8,0.8c5.2,0.8,9.7,3.1,14.9,4.5c6.6,2,13.9,2.5,20.8,4c12.8,2.8,25.3,9.9,28.4,17.8
        C39.7,2110,35.9,2109.7,26.5,2108.3z"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-143.3,1430.2 -104.2,1426.5 349.1,1415.2 371,1424.8 369.6,1416.1 358.9,1417.2 200.8,1401.1
        58,1411 -25.5,1402.8 -136,1405.9 -385.2,1409 -372,1417.8 -245.5,1429.1 -201.9,1420.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="530.1,1405.9 649.8,1413.8 691.4,1417.8 963.6,1433 1007.6,1429.9 1089.1,1429.4 1351.5,1441.2
        1662.8,1440.7 1611.6,1422.6 1524.4,1425.4 1442.5,1419.5 1363.7,1410.2 1288.1,1411 1291,1399.4 1255.6,1401.1 1139.4,1407.6
        1102.6,1393.5 1031.9,1403.1 861.9,1395.7 752.5,1410.2 676.4,1383.9 603.8,1387.3 432.3,1389.5 480.3,1391.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1892,1439 1851.7,1443.8 1779.7,1425.4 1690.3,1411 1625.9,1397.7 1596.8,1383 1761.8,1398.3
        1810.1,1399.4 1833.8,1406.2 1913.6,1406.5 1989.6,1399.4 2152.6,1392.4 2286.9,1422.9 2092.3,1432.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="32.1,1460.7 197.6,1455.6 531.4,1455.9 806.2,1462.1 917.5,1463 1068.9,1472.3 1106.3,1465.5
        1137.5,1461.9 1290.9,1458.5 1583.5,1455.9 1907.7,1456.2 1997.9,1440.4 2070.3,1429.4 2151,1429.6 2215,1426.8 2230.4,1443.8
        2175.4,1457.3 2173.4,1473.7 2131.4,1477.7 1782.2,1478.2 1643.3,1483.6 1238,1469.2 1199.9,1469.2 1183.2,1478.2 1155.7,1483.6
        1028.1,1488.7 968.7,1489.8 701.4,1491.2 595,1484.5 237.5,1486.7 -181.6,1472.9 -287.5,1459.9 -347.4,1448 -300.5,1445.7
        -212.3,1450.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1608.9,1501.4 1490.7,1506.8 1318.5,1499.4 1282.1,1497.7 1168.8,1508.2 1110.9,1513.3 1032.2,1519.8
        973.3,1531.1 980.6,1539.8 1009.3,1528 1075.9,1528.2 1426.6,1524.3 1498.7,1524.6 1537.8,1516.9 1596.1,1515.3 1802.3,1523.4
        1839.7,1529.9 1839,1509.3 1728.1,1515.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="7.8,1494.9 106.6,1506.5 178.6,1502.8 291.3,1507.9 508.2,1508.8 678.4,1523.4 280.9,1523.2
        190.1,1524.6 149.9,1542.4 -10.9,1531.4 -118,1546.3 -226.5,1540.7 -300.6,1512.7 -201.2,1501.4 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="120.1,1550 195.3,1558.8 277.1,1540.1 428.2,1552.8 498.5,1546 602.5,1539.3 883.9,1543.5 917.2,1554
        662.1,1581.6 515.5,1590.4 384.2,1583.1 318.7,1594.1 242.4,1592.4 -100.3,1596.1 -272.9,1583.9 -330.5,1560.5 -231.7,1557.1
        -113.2,1568.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1419,1569.8 1286.6,1566.4 1081.4,1591.2 990.6,1591.5 956.7,1598.3 848.5,1603.4 897.8,1612.7
        1022.2,1620.1 1141,1625.4 1290.4,1638.4 1340.3,1636.5 1421.4,1646.3 1632.8,1630.2 1669.9,1634.8 1675.1,1614.7 1384.3,1619.8
        1353.8,1612.2 1382.6,1605.7 1722.9,1579.7 1611,1554.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="883.2,1209.8 750.8,1206.2 546,1231 455.2,1231.6 420.9,1238.1 313.1,1243.5 362,1252.8 486.4,1260.1
        605.3,1265.2 754.6,1278.5 804.9,1276.2 885.6,1286.1 1097,1270.3 1134.1,1274.5 1139.3,1254.5 848.5,1259.8 818.4,1252.2
        846.8,1245.4 1187.1,1219.7 1075.2,1194.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="436.5,1618.1 367.2,1611.6 310.3,1623.2 222.7,1624.3 161.3,1616.4 117.3,1620.6 -143.7,1610.5
        -171.7,1618.4 -148.2,1633.1 -111.4,1630 -80.9,1633.1 -20.6,1639.3 109.3,1657.4 192.5,1659.6 391.1,1653.4 599.7,1665.3
        814.2,1671.8 914,1683.6 1035.3,1678.3 1248.1,1678.5 1233.6,1663.9 1024.2,1654 936.2,1649.7 870,1648 690.2,1616.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-13.7,1681.1 44.2,1683.9 123.2,1683.4 256.6,1700.6 439.6,1710.5 434.1,1733.6 391.8,1728.3
        340.8,1752.3 156.1,1763.3 52.1,1772.1 49.7,1764.4 121.5,1753.4 183.8,1737.9 10.9,1722.9 -19.6,1737.3 -54.6,1738.2
        -157.5,1722.6 -160.6,1707.1 -209.2,1695.5 -240.3,1670.9 -152.7,1665.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1069.6,1699.5 995.1,1707.9 922.7,1718.4 749.4,1705.4 588.3,1702.3 685,1717.5 689.5,1731.4
        681.5,1731.7 573,1748.1 515.1,1762.2 465.2,1769 404.9,1783.4 305.5,1783.4 84.4,1791.3 124.9,1799.2 249.3,1798.1 321.4,1799.8
        370.6,1794.4 542.9,1782.2 632.3,1768.1 694.7,1756.5 744.2,1743.5 881.8,1747.8 939.7,1739 1027,1737 1186.4,1700.6 1148,1703.4
        "/>
            <polygon :fill="colors[`${id}-p-1`]" points="1465.4,1811.6 1385,1809.4 1336.5,1787 1345.9,1771.5 1387.4,1766.7 1281.7,1774.3 1224.2,1775.7
        1186.8,1768.1 1133.8,1767.5 963.6,1773.8 1024.2,1785.6 1101.2,1797.2 1131,1801.4 1266.5,1793 1286.6,1798.1 1282.4,1805.4
        1316.7,1810.2 1355.9,1820.4 1448.8,1829.7 1490,1829.7 1661.9,1834.2 1675.1,1830.3 1707.3,1807.9 1643.5,1815.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-43.8,1780 -13.4,1791 3.6,1805.7 87.8,1821.5 147.5,1820.4 198.4,1834.8 272.6,1829.4 313.8,1823.2
        417.1,1821.5 329.4,1844.7 264.2,1838.2 200.8,1865.6 268.4,1873.2 276.4,1873.5 272.2,1879.1 244.5,1878.9 79.9,1900.6
        16.1,1886.8 -86.1,1890.2 -62.2,1870.4 -28.6,1869 6.4,1863 50.8,1852.6 -69.1,1839 -119.4,1831.4 -218.2,1828.9 -234.1,1806.8
        -251.8,1766.1 -199.5,1753.1 -144.7,1776.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="532.5,1818.1 581.3,1805.1 641,1807.1 718.6,1789.9 817,1776 852,1774 875.9,1774.6 973.6,1799.2
        1003.5,1808.8 1067.6,1813.3 1086.6,1815.9 1094.9,1829.1 1017.7,1821.5 976.1,1831.4 939,1816.4 892.9,1812.5 808,1807.7
        823.9,1820.7 904.7,1842.7 923,1844.4 738.3,1843 710.3,1840.7 706.1,1831.7 682.2,1825.7 566.8,1842.4 498.9,1831.4 477.7,1824.9
        503.4,1828 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2023.6,1879.1 1831.8,1859.9 1695.9,1863.9 1652.7,1850.6 1382.8,1841.3 1117.1,1851.7 1482.4,1874.3
        1530.7,1887.3 1668.9,1885.1 1842.5,1895.5 2123.7,1896.9 2218.4,1883.9 2255.3,1873.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="476.7,1878.9 541.1,1872.4 639.9,1869.8 665.2,1867.3 712,1867 791.7,1874.6 929.3,1871.5
        987.5,1870.7 1042.3,1872.6 1098.4,1874.3 1159.7,1885.6 994.4,1890.7 928.2,1895.5 872.5,1895.8 688.4,1902.9 789.6,1889.6
        683.9,1891 628.8,1903.4 521,1894.7 375.5,1901.7 452.8,1892.1 416.7,1889.3 	"/>
            <path :fill="colors[`${id}-p-1`]" d="M2212.6,1648.6l-101.6,13l-203.1-3.7l-120.2,20.1l-166.2-9l-19.2,8.5l14.6,7.9l47.8,4l27.3,17.2l-82,13.6
        l-88.3,7.3l-118,1.7l-205.4,4.5l-15.5,6.8l63.7,4.2l247.3,4.5l29.6,1.1l10.9,21.2l-43.2,5.9l51,13.3l294.2,0.8l86.1-3.7l-147.6-8.2
        l-110.2-12.1l55.1-7.1l163.1-3.7l110.6-10.7l102-12.1l-122.1-14.7l-26.4-2.3l5-2.8l22.3-13.8l146.6-9.9L2212.6,1648.6z
         M1838.4,1717.8c-4.1,7.9-20.5,15-37.3,17.8c-9.1,1.4-18.7,2-27.3,4c-6.8,1.4-12.7,3.7-19.6,4.5c-12.7,1.7-27.3-0.8-40.5,0.8
        c5,0.6,9.6,1.4,13.3,2.8c-32.8,1.4-65.2,2-97.9,2.5c8.1,0.3-13.3,1.4-17.7,2c0.4,0,0.9,0.3,0.9,0.3c-2.2,0-2.2-0.3-0.9-0.3
        c-22.3-7.1-12.4-2.3-34.2-10.2c25.1-5.4,53.2-7.3,80.5-5.9c-11.4-1.1-22.7-2.5-34.2-3.7c31-3.4,63.3-4.2,95.1-2.3
        c-3.7-1.7-6.8-3.4-9.6-5.4c27.3-1.1,54.7-2,82.4-3.1c-2.8-1.1-5-2.5-5.9-4c13.3-1.4,25.5-3.4,38.8-4.8
        C1836.5,1711.6,1841.5,1711.6,1838.4,1717.8z"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1542.3,899.1 1508.7,903.9 1449.1,885.5 1374.3,871.4 1320.9,858.1 1296.6,843.1 1434.2,858.4
        1474.1,859.5 1494.2,866.3 1560.4,866.9 1623.8,859.8 1759.6,852.4 1871.9,883 1709.7,892.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="46.7,921.1 209,916 535.2,916 804.4,922.5 913.5,923.1 1061.1,932.4 1098,925.6 1128.5,921.9
        1278.6,918.6 1564.9,916 1882.2,916.6 1969.9,900.5 2041.2,889.5 2120,889.7 2182.2,886.9 2197.7,903.9 2144,917.4 2141.6,933.8
        2100.9,937.8 1758.9,938.3 1623.1,943.7 1227,929.6 1189.1,929.3 1173.3,938.3 1146.4,943.7 1021.5,948.8 963.4,949.9 702,951.3
        597.4,944.6 247.6,946.8 -161.8,933 -265.5,920 -324.6,908.1 -278.2,905.8 -192,910.4 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1632.1,961.5 1513.6,966.9 1341.7,959.5 1305.3,957.8 1191.6,968.3 1134.1,973.4 1055.1,979.9
        996.5,991.2 1003.5,1000.2 1032.6,988.1 1098.8,988.6 1449.8,984.4 1521.9,985 1561.1,977 1619.3,975.3 1825.1,983.5 1862.9,990
        1861.9,969.4 1751,975.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="31,955 129.8,966.6 201.9,962.9 314.5,968 531.4,968.8 701.3,983.5 303.7,983.3 213.3,984.7
        173.1,1002.5 12.3,991.7 -95.1,1006.4 -203.3,1000.8 -277.8,972.8 -178,961.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="143.3,1010.1 218.1,1018.9 299.9,1000.5 451,1012.9 521.7,1006.4 625.7,999.4 906.8,1003.6
        940.4,1014.1 685.3,1041.7 538.7,1050.5 407.4,1043.2 341.9,1054.2 265.3,1052.5 -77.5,1056.1 -250.1,1044 -307.6,1020.6
        -208.8,1017.2 -89.9,1028.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1679.6,1029.9 1492.5,1026.5 1201.7,1051.3 1073.1,1051.6 1024.5,1058.4 871.8,1063.5 941.6,1072.8
        1117.3,1080.2 1286.3,1085.5 1497.5,1098.5 1568.6,1096.5 1683.6,1106.4 1982.7,1090.3 2035.7,1094.9 2042.7,1074.8 1630.6,1079.9
        1587.8,1072.3 1628.6,1065.8 2110.4,1039.8 1951.7,1014.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="459.7,1078.5 390.4,1071.7 333.2,1083.3 245.5,1084.4 184.2,1076.5 140.5,1080.7 -120.4,1070.6
        -148.9,1078.5 -124.9,1093.2 -88.2,1090.1 -58.1,1093.2 2.6,1099.4 132.2,1117.5 215.7,1119.7 414.3,1113.5 622.6,1125.4
        837.4,1131.9 936.9,1143.7 1058.6,1138.4 1271,1138.9 1256.4,1124 1047.5,1114.1 959.4,1109.8 893.2,1108.1 713.4,1076.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="9.5,1141.2 67.4,1144 146.1,1143.4 279.8,1160.7 462.5,1170.9 457.3,1193.7 414.6,1188.4
        364.1,1212.4 179,1223.4 75.4,1232.2 72.6,1224.5 144.7,1213.5 206.7,1198.3 34.1,1183 3.3,1197.4 -31.7,1198.3 -134.6,1182.7
        -137.4,1167.2 -185.9,1155.6 -217.1,1131 -129.4,1125.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-21,1240.1 9.5,1251.1 26.8,1265.8 111.1,1281.6 170.3,1280.5 221.3,1294.9 295.4,1289.5 337,1283.6
        439.9,1281.9 352.3,1304.8 287.5,1298.3 223.7,1325.7 291.6,1333.3 299.2,1333.6 295.4,1339.2 267.7,1339.2 103.1,1360.7
        39,1346.9 -63.3,1350.3 -39.3,1330.8 -5.7,1329.1 29.3,1323.4 73.6,1313 -45.9,1299.4 -96.5,1291.5 -195.3,1288.9 -211.2,1266.9
        -228.6,1226.5 -176.2,1213.2 -121.8,1236.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1966.6,1326.5 1794.4,1307.3 1671.3,1311.3 1632.4,1298 1389,1288.7 1149.7,1299.1 1478.8,1321.7
        1522.9,1334.7 1647,1332.5 1804,1342.9 2056.8,1344.3 2142,1331.3 2175.4,1320.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="499.9,1339 564.4,1332.5 663.1,1329.9 688.1,1327.7 734.9,1327.1 814.6,1335 952.5,1331.6
        1010.7,1330.8 1065.5,1332.7 1121.6,1334.4 1182.6,1345.7 1017.7,1350.8 951.1,1355.6 895.3,1356.2 711.6,1363 812.8,1349.7
        707.1,1351.1 651.7,1363.5 544.3,1354.8 398.7,1361.8 475.6,1352.2 439.9,1349.4 	"/>
            <path :fill="colors[`${id}-p-1`]" d="M1241.5,1272.3l100.5-13l201.1,3.7l119-20.1l164.5,9l19-8.5l-14.4-7.9l-47.3-3.7l-27.1-17.2l81.2-13.6
        l87.4-7.3l116.8-1.7l203.3-4.5l15.3-6.8l-63.1-4.2L1953,1172l-29.3-1.1l-10.8-21.2l42.8-5.9l-50.5-13.3l-291.2-0.8l-85.2,3.7
        l146.1,8.2l109.1,12.1l-54.5,7.1l-161.4,3.7l-109.5,10.7l-100.9,12.1l120.8,14.7l26.1,2.3l-4.9,2.8l-22.1,13.8l-145.2,9.9
        L1241.5,1272.3z M1612.1,1203.1c4-7.9,20.3-15,36.9-17.8c9-1.4,18.5-2,27.1-4c6.8-1.4,12.6-3.7,19.4-4.5
        c12.6-1.7,27.1,0.8,40.1-0.8c-4.9-0.6-9.5-1.4-13.1-2.8c32.5-1.4,64.5-2,96.9-2.5c-8.1-0.3,13.1-1.4,17.6-2c-0.4,0-0.9-0.3-0.9-0.3
        c2.2,0,2.2,0.3,0.9,0.3c22.1,7.1,12.2,2.3,33.8,10.2c-24.8,5.4-52.7,7.3-79.7,5.9c11.3,1.1,22.5,2.5,33.8,3.7
        c-30.7,3.4-62.7,4.2-94.2,2.3c3.6,1.7,6.8,3.4,9.5,5.4c-27.1,1.1-54.1,2-81.6,3.1c2.7,1.1,4.9,2.5,5.9,4
        c-13.1,1.4-25.2,3.4-38.4,4.8C1613.4,1209.3,1608.4,1209.3,1612.1,1203.1z"/>
            <polygon :fill="colors[`${id}-p-1`]" points="-85.4,771.4 -45.9,775 407,786.3 428.9,776.7 427.8,785.2 417.1,784.1 259,800.5 115.9,790.6
        32.4,798.8 -78.2,795.7 -327,792.6 -313.8,783.8 -187.7,772.5 -144,781 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="560.5,795.4 646.1,787.5 675.6,783.8 870.4,768.5 902.3,771.6 960.5,772.2 1148.3,760.1 1370.8,760.9
        1334.1,778.7 1271.7,775.9 1213.5,781.8 1156.6,791.1 1102.6,790.3 1105,801.9 1079.7,800.5 996.5,793.7 969.8,808.1 919.2,798.5
        797.6,805.8 719.3,791.4 664.9,817.7 613.2,814.3 490.2,812 524.5,809.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1736,762.6 1680.6,757.8 1582,775.9 1459.5,790.3 1371.6,803.6 1331.6,818.5 1558,803.3 1623.6,801.9
        1656.7,795.4 1765.6,794.8 1869.9,801.9 2093.3,809.2 2277.6,778.4 2011.2,768.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="43.7,740.6 189.3,745.7 482.1,745.7 723.7,739.2 821.5,738.6 954,729.3 987.2,735.8 1014.6,739.7
        1149.3,742.8 1406.3,745.7 1691,745.1 1769.7,760.9 1833.2,771.9 1904.4,771.9 1960.3,774.5 1973.8,757.8 1925.6,744.2
        1923.8,727.6 1887,723.9 1580.4,723.1 1458.4,718 1103,732.1 1068.9,732.4 1054.4,723.3 1030.3,718 918.5,712.9 866.4,711.8
        631.8,710.3 537.9,717.1 224,714.9 -143.9,728.7 -236.6,741.7 -289.6,753.3 -248,755.5 -170.6,751.3 	"/>
            <polyline :fill="colors[`${id}-p-1`]" points="-199.8,868 29.6,861.5 122.5,860.9 248.6,851.6 279.8,858.4 305.8,862.1 433.7,865.4 677.7,868
        948,867.4 1022.9,883.5 1083.5,894.5 1150.7,894.3 1204.1,897.1 1216.9,880.1 1171.2,866.6 1169.5,850.2 1134.5,846.2 843,845.7
        727.2,840.3 389.7,854.4 357.8,854.7 343.9,845.7 321.1,840.3 214.7,835.2 165.1,834.1 -57.7,832.7 -146.4,839.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1856.7,698.7 1703.2,691.1 1479.9,701.4 1432.6,703.8 1284.9,689.1 1210.2,681.9 1107.6,672.8
        1031.5,656.8 1040.6,644.1 1077.9,661.2 1164.4,660.3 1619.9,666.4 1713.6,665.6 1764.3,676.7 1839.9,679.1 2107.8,667.5
        2156.9,658.4 2155.6,687.6 2011.5,678.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="66,706.7 164.8,695.1 236.9,698.8 349.5,693.4 566.1,692.8 736.3,678.1 338.8,678.1 248.3,677
        208.1,659.2 47.3,669.9 -60.1,655.2 -168.6,660.9 -242.8,688.9 -143,699.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="298.9,580.9 397.7,569.4 469.8,573 582.4,567.9 799.3,567.1 969.1,552.4 572,552.7 481.2,551.3
        441,533.5 280.2,544.2 173.1,529.5 64.6,535.2 -9.5,563.1 89.9,574.4 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="178.3,651.6 253.2,642.8 334.9,661.2 486,648.7 556.4,655.2 660.4,662.3 941.8,658.1 975.4,647.3
        720,619.9 573.7,611.2 442,618.5 376.5,607.5 300.3,609.2 -42.5,605.2 -215,617.7 -272.6,641.1 -173.8,644.5 -54.9,633.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1612,631.5 1448.2,635.2 1194.8,610.3 1082.6,610 1040,603.3 906.8,597.9 967.2,588.9 1121.1,581.2
        1268.1,576.1 1452.9,562.9 1515,565.1 1614.9,555.2 1876.4,571.3 1922.3,566.8 1928.7,586.9 1569,581.8 1531.7,589.1 1566.9,595.9
        1987.8,621.6 1849.3,647.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="44.5,520.2 102.4,517.7 181.1,518.2 314.8,501 497.5,490.8 492.3,467.9 449.7,473.3 399.1,449
        214,438.3 110.4,429.5 107.6,437.1 179.3,448.2 241.7,463.4 69.1,478.7 38.3,464.3 3.3,463.1 -99.6,479 -102.4,494.5 -150.9,506.1
        -182.1,530.7 -94.8,536 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1127.9,501.8 1053.4,493.6 980.6,483.2 807.6,495.9 646.5,499 742.8,484 747.7,470.2 739.7,469.9
        630.9,453.5 573.4,439.4 523.5,432.3 462.8,417.9 363.4,418.2 142.3,410 182.8,402.4 307.6,403.5 379.3,401.5 428.5,407.2
        600.7,419.1 690.2,433.5 752.9,445 802.4,458 939.7,453.5 997.6,462.6 1084.9,464.5 1244.3,501 1205.8,497.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1786,389.7 1663.5,392.2 1589.6,414.5 1603.7,430.1 1667.7,434.9 1506.1,427.3 1418.4,425.6
        1361.5,433.5 1280.5,433.7 1021.8,427.8 1113.7,415.9 1231,404.4 1276.4,399.8 1482.9,408.3 1513.5,403.5 1507.1,396.2
        1559.4,391.1 1619.1,380.9 1761.1,371.6 1823.9,371.9 2085.4,367.4 2105.9,371.3 2155,393.6 2057.9,385.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1015.6,236.8 934.8,239.1 886.7,261.4 895.7,276.9 937.6,281.8 831.9,274.4 774.4,272.7 736.6,280.3
        683.9,280.9 513.8,274.7 574.1,262.8 651.3,251.2 681.2,247 816.7,255.5 836.8,250.4 832.6,243.3 866.9,238.2 906.1,228.1
        998.9,218.7 1040.2,218.7 1212.1,214.2 1225.2,218.2 1257.5,240.5 1193.7,232.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="14,421.6 44.5,410.6 61.9,395.9 146.1,380.1 205.3,380.9 256.3,366.8 330.4,372.2 372,378.1
        474.9,379.8 387.3,356.9 322.1,363.1 258.7,336 326.6,328.4 334.2,327.8 330.4,322.4 302.7,322.4 138.1,301 74,314.8 -28.3,311.4
        -4.3,330.9 29.3,332.6 64.3,338.3 108.6,348.7 -10.9,362.3 -61.5,369.9 -160.3,372.7 -176.2,394.8 -193.6,435.2 -141.2,448.4
        -86.8,424.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="590.4,383.2 639.2,396.5 699.2,394.5 776.5,411.7 874.9,425.6 910.2,427.3 934.1,427 1031.5,402.4
        1061.7,392.8 1125.4,388.3 1144.5,385.7 1152.8,372.4 1075.5,380.1 1033.9,370.2 997.2,385.2 951.1,389.1 865.9,393.9 882.2,380.9
        962.9,358.9 981.3,357.2 796.2,358.6 768.1,360.6 764,369.9 740.4,375.8 624.7,359.2 556.7,370.2 535.9,376.4 561.6,373.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1921.5,322.2 1764.1,341.6 1651.6,337.7 1616,351 1393.7,360.3 1175,349.8 1475.7,327.2 1516,314
        1629.4,316.2 1772.9,306 2003.9,304.6 2081.8,317.6 2112.3,328.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="534.6,322.7 599,329.2 698.1,331.8 723.1,334 769.9,334.6 849.6,326.7 987.2,329.8 1045.7,330.6
        1100.5,328.9 1156.6,327.2 1217.6,315.9 1052.7,310.9 986.1,306 930.3,305.5 746.7,298.7 847.5,312 741.8,310.3 686.7,298.1
        579.3,306.9 433.7,299.8 510.6,309.4 474.6,312 	"/>
            <path :fill="colors[`${id}-p-1`]" d="M2164.9,511.2l-144.3-9.9l-22-13.8l-4.9-2.8l26-2.3l120.2-14.7l-100.4-12.1l-108.9-10.7l-160.5-3.7l-54.2-7.1
        l108.5-12.1l145.3-8.2l-84.7-3.7l-289.6,0.8l-50.2,13.3l42.6,5.9l-10.7,21.2l-29.1,1.1l-243.4,4.5l-62.7,4.2l15.3,6.8l202.2,4.5
        l116.1,1.7l86.9,7.3l80.7,13.6l-26.9,17.2l-47.1,3.7l-14.4,7.9l18.9,8.5l163.6-9l118.3,20.1l200-3.7l100,13L2164.9,511.2z
         M1871.8,488.6c-12.5-1.4-25.1-3.4-38.2-4.8c1.3-1.7,3.1-3.1,5.8-4c-26.9-1.1-53.8-2-81.1-3.1c2.7-2,5.8-3.7,9.4-5.4
        c-30.9,2-63.2,1.1-93.6-2.3c11.3-1.1,22.4-2.5,33.6-3.7c-26.9,1.4-54.7-0.6-79.3-5.9c21.5-7.9,12.2-3.1,33.6-10.2
        c-0.9-0.3-0.9-0.3,0.9-0.3c-0.4,0-0.9,0.3-0.9,0.3c4,0.6,25.1,1.7,17.5,2c32.3,0.3,64.5,1.1,96.4,2.5c-3.6,1.4-8,2.5-13.1,2.8
        c13.1,1.7,26.9-0.8,39.8,0.8c6.7,0.8,12.5,3.1,19.3,4.5c8.5,2,18,2.5,26.9,4c16.6,2.8,32.7,9.9,36.7,17.8
        C1889.2,490,1884.3,490,1871.8,488.6z"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1817.9,43 1738.9,45.6 1631.7,45 1449.2,62.2 1199.9,72.4 1207,95.6 1265.3,90.2 1334.3,114.2
        1586.7,125.2 1728.1,133.7 1731.8,126.1 1633.5,115.3 1548.9,99.8 1784.3,84.5 1826.3,99.2 1874.1,100.1 2014.6,84.3 2018.3,68.7
        2084.6,57.4 2127.1,32.8 2007.5,27.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="379.8,26.8 468.6,37.8 555.3,52.2 761.6,34.7 953.8,30.5 838.9,51.1 833.1,69.3 842.6,69.7
        972.4,91.7 1041.1,111.1 1100.6,120.3 1172.9,139.6 1291.5,139.2 1555.2,150.3 1506.4,160.9 1358.1,159 1272.1,161.7 1213.8,154.4
        1008.4,138.2 901.8,118.8 826.9,103.2 767.8,86.1 604.1,91.7 535.2,79.6 430.9,77.4 240.8,27.8 286.7,32 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="174.1,173.5 254.5,171 303.1,149 293.7,133.4 251.8,128.3 357.8,136 415.3,137.7 452.8,129.8
        505.8,129.5 675.6,135.4 615.3,147.3 538.4,159.1 508.6,163.4 373.1,154.9 353,159.7 357.1,167 322.8,172.1 283.3,182.3
        190.4,191.6 149.2,191.6 -22.4,195.9 -35.9,192.2 -68.1,169.6 -4.3,177.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1868,141.6 1825.3,152.6 1801,167.3 1683.1,183.1 1600.1,182.3 1528.8,196.7 1424.9,191.3
        1366.6,185.1 1222.5,183.4 1345.3,206.3 1436.1,200.1 1525.3,227.5 1430.2,234.9 1419.6,235.4 1424.9,240.8 1463.7,240.8
        1694.2,262.3 1784,248.7 1927.2,251.8 1893.7,232.3 1846.7,230.6 1797.5,225 1735.5,214.5 1903,200.9 1973.7,193.3 2112.1,190.5
        2134.5,168.5 2158.7,128.1 2085.5,114.8 2009.1,138.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="1107.1,180 1058.2,166.8 998.3,168.7 921,151.5 822.2,137.9 787.2,136 763.3,136.3 665.9,161.1
        635.8,170.4 572,175.2 552.9,177.5 544.6,191.1 621.9,183.4 663.5,193.3 700.2,178.3 746.3,174.4 831.6,169.6 815.3,182.6
        734.5,204.6 716.2,206 901.2,204.6 929.3,202.6 933.4,193.3 957,187.4 1072.8,204.1 1140.7,193.3 1161.5,186.8 1135.8,189.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="21,241.1 126.7,221.6 202.2,225.5 226.1,212.2 375.1,202.9 522.4,213.7 320.4,236.3 293.4,249.3
        217.1,247 120.8,257.5 -34.5,258.9 -87.2,245.9 -107.3,235.4 	"/>
            <path :fill="colors[`${id}-p-1`]" d="M-258,52.1l111.6,9.9l17,13.8l3.8,2.8l-20.1,2.3l-92.9,14.7l77.6,12.1l84.2,10.7l124.1,3.7l41.9,7.1L5.3,141.3
        l-112.3,8.2l65.5,3.7l223.9-0.8l38.8-13.3l-32.9-5.9l8.3-21.2l22.5-1.1l188.2-4.5l48.5-4.2L444,95.4l-156.3-4.5L198,89.1l-67.2-7.3
        L68.4,68.2L89.2,51l36.4-3.7l11.1-7.9l-14.6-8.5l-126.5,9l-91.5-20.1l-154.5,3.7l-77.3-13L-258,52.1z M-31.4,74.9
        c9.7,1.4,19.4,3.4,29.5,4.8c-1,1.7-2.4,3.1-4.5,4c20.8,1.1,41.6,2,62.7,3.1c-2.1,2-4.5,3.7-7.3,5.4c23.9-2,48.9-1.1,72.4,2.3
        c-8.7,1.1-17.3,2.5-26,3.7c20.8-1.4,42.3,0.6,61.3,5.9c-16.6,7.9-9.4,3.1-26,10.2c0.7,0.3,0.7,0.3-0.7,0.3c0.3,0,0.7-0.3,0.7-0.3
        c-3.1-0.6-19.4-1.7-13.5-2c-25-0.3-49.9-1.1-74.5-2.5c2.8-1.4,6.2-2.5,10.1-2.8c-10.1-1.7-20.8,0.8-30.8-0.8
        c-5.2-0.8-9.7-3.1-14.9-4.5c-6.6-2-13.9-2.5-20.8-4c-12.8-2.8-25.3-9.9-28.4-17.8C-44.9,73.2-41.1,73.5-31.4,74.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1039.1,283.4c-4.9-0.8-43.3,0.6-26.3-1.7h-1h-2.8h0.3l2.8-0.3c-5.9-0.8-11.4-2-17.7-2.8
        c43.7-1.7,65.5-1.7,71,0l0,0l0,0l0,0l0,0h0.3h0.3c3.8-0.6,6.9-1.1,11.8-1.4c8-0.3,17.3-0.6,26-0.8c-2.4,0.3-3.1,0.8-7.6,1.1
        c-10.7,0.8-22.2,1.4-34,2c0,0-0.3,0-0.3,0.3c25.6-0.6,51.3-1.4,82.5-2c-5.9,1.4-11.4,2.8-17.3,4.2c12.1,0.3,24.3,0.6,37.1,0.8
        c-37.4,1.1-74.9,2.3-113.3,3.1C1046.1,285.1,1044.3,284.3,1039.1,283.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M638.9,523.9c-7.3,0-13.5-1.4-19.1-2.3c-6.9-1.1-14.9-1.4-21.8,0.3c-2.4,0.6-0.3,2,2.1,2.8
        c5.9,2,1.7,4-8.3,5.4c-16.6,2.3-35.3,4.2-51.3,6.5c-13.5,2-23.9,4.8-36.4,7.6c-12.8-2.5,2.1-4,6.2-5.7c5.2-2,11.4-3.7,2.4-5.4
        c-11.4-2.3-5.9-5.1,1-7.9c7.6-2.8,18.4-4.8,36.4-5.4c11.8-0.6,24.3-2.5,34.3-4.2c14.9-2.5,27-2.8,41.2-1.4
        c2.4,0.3,10.1-1.1,19.1-2.3l19.1,9.6C653.8,522.5,645.5,523.9,638.9,523.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M399.7,285.1L399.7,285.1l4.5,0.3h-0.3h0.3c-0.3,0-0.3,0-0.7,0c-1,0-2.1,0.3-3.1,0.3c-0.3,0-0.3-0.3-0.7-0.3
        c-36.4-0.6-73.5-0.8-108.8-2.3c-9.7-0.6-18-4.5-19.4-7.3c-0.7-1.7,12.5-4.8,21.8-5.9c18.7-2,40.2-4.2,57.5-4
        c32.6,0.6,63.8,2.5,95.7,4C447.9,276.9,423.3,280.9,399.7,285.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1137.9,553.8L1137.9,553.8l4.5,0.3h-0.3h0.3c-0.3,0-0.3,0-0.7,0c-1,0-1.7,0.3-2.8,0.3c-0.3,0-0.3-0.3-0.7-0.3
        c-34.3-0.6-69.3-1.1-102.6-2.5c-9.4-0.6-17-4.5-18.4-7.3c-0.7-1.7,11.8-4.8,20.4-5.9c17.7-2,37.8-4,54.4-4
        c30.8,0.6,60.3,2.8,90.5,4.2C1183.3,545.9,1160.1,549.9,1137.9,553.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1420.7,527.5L1420.7,527.5l3.8,0.3h-0.3h0.3c-0.3,0-0.3,0-0.7,0c-0.7,0-1.7,0.3-2.4,0.3l-0.3-0.3
        c-29.8-0.8-60-1.4-88.7-3.1c-8-0.6-14.9-4.5-15.9-7.3c-0.7-1.7,10.4-4.8,17.7-5.7c15.2-1.7,32.9-4,47.1-3.7
        c26.7,0.8,52.3,3.1,78.3,4.8C1459.9,519.9,1439.8,523.6,1420.7,527.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M846.1,592c-8.7,2.5-37.8,5.4-60.6,8.2c-33.6,0.6-97.4,2.3-97.7,1.4c-2.1-2,14.9-5.1,42.3-7.6
        c17-1.7,58.2-3.7,94.6-5.4C836.1,589.4,852.7,590.3,846.1,592z"/>
            <path :fill="colors[`${id}-p-1`]" d="M689.1,1179.9c-34,3.7-78.7,6.8-126.8,6.2l0,0l0,0l-4.5,0.6l0,0c-0.7,0-1.4-0.3-2.1-0.3c-3.1,0-6.2,0-9.4,0
        c-54.8,0.3-56.1,0.3-11.8-4c-0.7,0-1-0.3-1.7-0.3h0.3h-0.3h5.9c-15.2-3.7,5.9-7.9,41.2-12.4c5.9-0.3,11.1-0.8,17-0.8
        c101.2-3.7,163.2-3.4,151.4,1.7C741.5,1173.1,716.8,1176.8,689.1,1179.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2217.5,250.7c6.5,5.4,31.2,10.2,47.1,15.4c-70.4,1.8-143.2,2.6-209.7,5.4c-153.6,6.6-307.4,14.2-514.5,24
        c70.4-6.2,101.6-10.2,140.9-12.4c158.4-9.2,327.2-16.8,369.7-34c8.1-4,104.2-8.8,160-13.6C2310.3,239,2210.1,246,2217.5,250.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1806.8,1276.2c2.1,4.2,11.4,9,17.3,13.6c-28.4-1.1-57.5-3.1-84.6-3.4c-62.4-0.3-124.8,0-208.6,0.3
        c28.8-2.3,41.9-4.2,57.9-4.5c64.5-1.4,133.1-1.4,151.4-13c3.8-2.8,42.6-3.1,65.5-4.5C1845.2,1270.6,1804.3,1272.6,1806.8,1276.2z"
            />
            <path :fill="colors[`${id}-p-1`]" d="M1127.2,68.2c1.7,2.3,2.4,5.4,6.2,5.9c10.7,1.4,23.2,1.1,40.5,2c-5.9,4-8.3,7.6-14.9,9.6
        c-15.6,4.5-32.9,8.2-52,12.7c-17.3-4.8-32.9-9-51.6-14.1C1081.8,78.3,1104.3,73.2,1127.2,68.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1395.1,278.4c-23.6,2.3-33.6,4.5-32.2,9c0,0.8-7.6,2.5-13.2,3.4c-20.4,3.4-41.2,6.5-61.3,9.9
        c-21.1,0.3-31.9-1.4-29.5-5.4l-12.1,1.4c3.8-0.6,7.6-1.1,11.4-1.4c6.6-2.8,12.8-5.4,19.1-8.2c-15.9,0.6-35.3,1.1-59.6,2.3
        c0.3-5.1,0.3-9.9,0.3-14.4c18.4-1.7,20.4,0,23.2,1.7l0.7-0.6l33.6-2v0.3l0,0c4.9-0.6,10.4-1.1,14.6-1.1c38.1,1.1,75.5,2,113-3.7
        C1414.5,273.6,1406.8,277.2,1395.1,278.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M826.4,1169.2L826.4,1169.2c-0.3,0-0.7,0-1-0.3L826.4,1169.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M562.6,902.5c104.3-8.2,25-11.3,196.8-18.6c115.4,6.5,129.6,8.5,95.3,15C687.4,906.4,742.5,895.7,562.6,902.5z
        "/>
            <path :fill="colors[`${id}-p-1`]" d="M363.7,1928.3c-28.1,0.8-55.8,2.5-80.1,2.3c-29.1-0.3-58.2-1.4-80.7-3.7c-17.7-1.7-24.3-5.4-37.8-8.5
        c35.7-4.2,71.4-7.3,111.2-6.5c1.4-4.2,17.3-6.2,43.7-6.8c36.4-0.8,13.2,4.5,21.8,6.8c27.7-1.1,44.4,0.6,42.6,4.5
        C383.5,1920.1,372.4,1923.8,363.7,1928.3z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1309.5,1926.9c-23.9,3.7-48.9,5.7-75.2,5.1c-1.7-1.7-3.5-3.1-5.5-5.1c6.2-0.6,15.6-2,19.4-1.4
        c18.7,2.8,37.1,4.8,61,1.1l0,0c19.4-5.7,38.5-11.6,58.6-17.2c6.2-1.7,15.2-3.1,23.9-4c29.1-2.8,56.1-2.3,84.2,0
        c-19.4,7.3-47.5,5.7-68.3,8.8c-5.9,0.8-14.2,3.7-12.8,4.2c3.1,1.7,10.1,2.8,15.6,4.2c-19.1,2-38.1,4.5-57.9,5.9
        C1336.1,1930.6,1319.2,1931.4,1309.5,1926.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M871.8,1188.9c140-22.6,293.2-29.1,437.7-39.8c29.8,9.9-21.8,17.8-90.1,22.3
        C1126.8,1177.9,1022.2,1181.6,871.8,1188.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1325.4,1690.1c30.2-0.8,62.4-4,97.7-6.5c25.3,4.5,48.5,8.8,77.6,13.8c-27.4,2-54.4,5.4-77.6,5.7
        c-72.1,1.1-150.8,3.4-189.9-5.1C1259.2,1693,1285.5,1691,1325.4,1690.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M925.1,2149.2c5.9-10.7,144.9-15,186.4-26C1130.6,2133.4,1130.6,2133.4,925.1,2149.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1214.9,1350.8c30.8-5.9,49.6-15.5,73.5-23.7c6.6,1.1,13.5,2,20.4,3.1c-11.8,6.2-23.6,12.4-35.3,18.4
        c0.7,0,1-0.3,1.7-0.3h0.3l0,0h0.3h-0.3c-0.3,0.3-0.3,0.8-0.7,1.1c37.4,0.3,74.9,0.8,112.3,1.1c29.1,0.3,48.5,4,45.7,9
        c-3.8,6.2-31.9,11-65.2,10.2c-18.4-0.6-33.6-3.7-52-3.7c-16.3,0-33.6,2.5-50.3,4l0,0c-0.7,0-1.4,0-1.7,0c-0.7,0-1,0-1.7,0.3l0,0
        c-25.6,1.4-51.3,3.1-76.9,4.5C1183.3,1366.4,1173.3,1358.7,1214.9,1350.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M239.6,895.4c-39.9,1.7-73.5,3.1-106.7,4.5c-43.3,2.3-53.7,5.9-48.5,10.2c-23.9-1.7-47.5-3.4-67.6-4.5
        c-4.9-4-8.7-7.6-13.2-11c28.1-3.7,45.4-7.3,56.1-11.9c6.2-2.5,24.3-5.9,63.1-6.8c-1,3.7-2.4,7.6-3.1,12.1
        c41.9-2,81.4-3.7,133.8-5.9C248.3,887.2,244.1,891.2,239.6,895.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M452.8,2098.1c-32.9,1.4-60.6,2.5-88,4c-35.7,1.7-44.4,5.4-39.9,9.3c-19.8-1.7-39.2-3.1-56.1-4.5
        c-4.2-3.7-7.3-7.1-11.1-10.2c23.2-3.1,37.4-6.5,46.4-10.7c5.2-2.5,20.1-5.4,52.3-5.9c-1,3.4-1.7,7.1-2.4,11
        c34.7-1.7,67.2-3.1,110.6-5.1C460,2090.5,456.6,2094.1,452.8,2098.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M390.7,1686.5c39.2-0.3,79.4-0.6,124.4-1.1c61,11.9,62,18.9,56.8,26.6c-83.5-13-83.5-13-235-19.5
        c-10.4-4.8-21.1-9.3-31.5-13.8C334.9,1683.1,363,1686.7,390.7,1686.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M14,273.8c-19.8,8.2-34,13.8-48.2,19.8c-66.9,3.7-133.1,6.5-194.4,5.4c35.7-5.9,65.2-12.4,108.8-17.8
        C-95.5,278.4-46.6,277.2,14,273.8z"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2425.8,2259.7 2558.1,2263.4 2763.3,2238.2 2854.1,2237.9 2888.1,2231.2 2996.2,2226.1 2947,2216.8
        2822.6,2209.4 2703.7,2204 2554.3,2191 2504.4,2193 2423.3,2183.1 2211.9,2199.2 2174.8,2194.7 2169.6,2214.8 2460.4,2209.7
        2490.9,2217.3 2462.1,2223.8 2122.2,2249.8 2233.8,2275 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2379.3,2017.9 2460.1,2020.1 2508.2,2042.4 2498.9,2058 2457.3,2062.8 2563,2055.2 2620.5,2053.7
        2657.9,2061.4 2711,2061.9 2881.1,2055.7 2820.8,2043.9 2743.5,2032.3 2713.7,2028 2578.2,2036.5 2558.1,2031.4 2562.3,2024.1
        2528,2019.3 2488.8,2009.1 2395.9,1999.8 2354.7,1999.8 2182.8,1995.3 2169.6,1999.2 2137.4,2021.5 2201.2,2013.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2226.1,1950.3 2332.2,1969.5 2407.4,1965.6 2431.3,1979.2 2580.7,1988.5 2727.6,1977.7 2525.6,1955.1
        2498.9,1942.1 2422.3,1944.4 2326.3,1934 2171,1932.5 2118.4,1945.5 2098.3,1956 	"/>
            <path :fill="colors[`${id}-p-1`]" d="M2516.2,2131.2l-111.6-9.9l-17-13.8l-3.8-2.8l20.1-2.3l92.9-14.7l-77.6-12.1l-84.2-10.7l-124.1-3.7l-41.9-7.1
        l83.9-12.1l112.3-8.2l-65.5-3.7l-223.9,0.8l-38.8,13.3l32.9,5.9l-8.3,21.2l-22.5,1.1l-188.2,4.5l-48.5,4.2l11.8,6.8l156.3,4.5
        l89.8,1.7l67.2,7.3l62.4,13.6l-20.8,17.2l-36.4,4l-11.1,7.9l14.6,8.5l126.5-9l91.5,20.1l154.6-3.7l77.3,13L2516.2,2131.2z
         M2289.9,2108.3c-9.7-1.4-19.4-3.4-29.5-4.8c1-1.7,2.4-3.1,4.5-4c-20.8-1.1-41.6-2-62.7-3.1c2.1-2,4.5-3.7,7.3-5.4
        c-23.9,2-48.9,1.1-72.4-2.3c8.7-1.1,17.3-2.5,26-3.7c-20.8,1.4-42.3-0.6-61.3-5.9c16.6-7.9,9.4-3.1,26-10.2
        c-0.7-0.3-0.7-0.3,0.7-0.3c-0.3,0-0.7,0.3-0.7,0.3c3.1,0.6,19.4,1.7,13.5,2c25,0.3,49.9,1.1,74.5,2.5c-2.8,1.4-6.2,2.5-10.1,2.8
        c10.1,1.7,20.8-0.8,30.8,0.8c5.2,0.8,9.7,3.1,14.9,4.5c6.6,2,13.9,2.5,20.8,4c12.8,2.8,25.3,9.9,28.4,17.8
        C2303.1,2110,2299.3,2109.7,2289.9,2108.3z"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2120.1,1430.2 2159.2,1426.5 2612.5,1415.2 2634.4,1424.8 2633,1416.1 2622.3,1417.2 2464.2,1401.1
        2321.4,1411 2237.9,1402.8 2127.4,1405.9 1878.2,1409 1891.4,1417.8 2017.9,1429.1 2061.5,1420.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2232.4,1460.7 2370.3,1455.6 2648.6,1455.9 2877.7,1462.1 2970.5,1463 3096.7,1472.3 3127.9,1465.5
        3153.9,1461.9 3281.8,1458.5 3525.7,1455.9 3796,1456.2 3871.2,1440.4 3931.6,1429.4 3998.8,1429.6 4052.2,1426.8 4065,1443.8
        4019.2,1457.3 4017.5,1473.7 3982.5,1477.7 3691.4,1478.2 3575.6,1483.6 3237.7,1469.2 3205.9,1469.2 3192,1478.2 3169.1,1483.6
        3062.7,1488.7 3013.2,1489.8 2790.3,1491.2 2701.6,1484.5 2403.6,1486.7 2054.2,1472.9 1965.9,1459.9 1916,1448 1955.1,1445.7
        2028.6,1450.3 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2271.2,1494.9 2370,1506.5 2442,1502.8 2554.7,1507.9 2771.6,1508.8 2941.8,1523.4 2544.3,1523.2
        2453.5,1524.6 2413.3,1542.4 2252.5,1531.4 2145.4,1546.3 2036.9,1540.7 1962.8,1512.7 2062.2,1501.4 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2383.5,1550 2458.7,1558.8 2540.5,1540.1 2691.6,1552.8 2761.9,1546 2865.9,1539.3 3147.3,1543.5
        3180.6,1554 2925.5,1581.6 2778.9,1590.4 2647.6,1583.1 2582.1,1594.1 2505.8,1592.4 2163.1,1596.1 1990.5,1583.9 1932.9,1560.5
        2031.7,1557.1 2150.2,1568.1 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2699.9,1618.1 2630.6,1611.6 2573.7,1623.2 2486.1,1624.3 2424.7,1616.4 2380.7,1620.6 2119.7,1610.5
        2091.7,1618.4 2115.2,1633.1 2152,1630 2182.5,1633.1 2242.8,1639.3 2372.7,1657.4 2455.9,1659.6 2654.5,1653.4 2863.1,1665.3
        3077.6,1671.8 3177.4,1683.6 3298.7,1678.3 3511.5,1678.5 3497,1663.9 3287.6,1654 3199.6,1649.7 3133.4,1648 2953.6,1616.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2249.7,1681.1 2307.6,1683.9 2386.6,1683.4 2520,1700.6 2703,1710.5 2697.5,1733.6 2655.2,1728.3
        2604.2,1752.3 2419.5,1763.3 2315.5,1772.1 2313.1,1764.4 2384.9,1753.4 2447.2,1737.9 2274.3,1722.9 2243.8,1737.3 2208.8,1738.2
        2105.9,1722.6 2102.8,1707.1 2054.2,1695.5 2023.1,1670.9 2110.7,1665.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2219.6,1780 2250,1791 2267,1805.7 2351.2,1821.5 2410.9,1820.4 2461.8,1834.8 2536,1829.4
        2577.2,1823.2 2680.5,1821.5 2592.8,1844.7 2527.6,1838.2 2464.2,1865.6 2531.8,1873.2 2539.8,1873.5 2535.6,1879.1 2507.9,1878.9
        2343.3,1900.6 2279.5,1886.8 2177.3,1890.2 2201.2,1870.4 2234.8,1869 2269.8,1863 2314.2,1852.6 2194.3,1839 2144,1831.4
        2045.2,1828.9 2029.3,1806.8 2011.6,1766.1 2063.9,1753.1 2118.7,1776.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2255.2,921.1 2393.5,916 2671.5,916 2900.9,922.5 2993.8,923.1 3119.6,932.4 3151.1,925.6
        3177.1,921.9 3305,918.6 3548.9,916 3819.3,916.6 3894.1,900.5 3954.8,889.5 4022,889.7 4075,886.9 4088.2,903.9 4042.4,917.4
        4040.4,933.8 4005.7,937.8 3714.3,938.3 3598.5,943.7 3261,929.6 3228.7,929.3 3215.2,938.3 3192.3,943.7 3085.9,948.8
        3036.4,949.9 2813.6,951.3 2724.5,944.6 2426.4,946.8 2077.5,933 1989.1,920 1938.8,908.1 1978.3,905.8 2051.8,910.4 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2294.4,955 2393.2,966.6 2465.3,962.9 2577.9,968 2794.8,968.8 2964.7,983.5 2567.1,983.3
        2476.7,984.7 2436.5,1002.5 2275.7,991.7 2168.3,1006.4 2060.1,1000.8 1985.6,972.8 2085.4,961.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2406.7,1010.1 2481.5,1018.9 2563.3,1000.5 2714.4,1012.9 2785.1,1006.4 2889.1,999.4 3170.2,1003.6
        3203.8,1014.1 2948.7,1041.7 2802.1,1050.5 2670.8,1043.2 2605.3,1054.2 2528.7,1052.5 2185.9,1056.1 2013.3,1044 1955.8,1020.6
        2054.6,1017.2 2173.5,1028.2 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2723.1,1078.5 2653.8,1071.7 2596.6,1083.3 2508.9,1084.4 2447.6,1076.5 2403.9,1080.7 2143,1070.6
        2114.5,1078.5 2138.5,1093.2 2175.2,1090.1 2205.3,1093.2 2266,1099.4 2395.6,1117.5 2479.1,1119.7 2677.7,1113.5 2886,1125.4
        3100.8,1131.9 3200.3,1143.7 3322,1138.4 3534.4,1138.9 3519.8,1124 3310.9,1114.1 3222.8,1109.8 3156.6,1108.1 2976.8,1076.8 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2272.9,1141.2 2330.8,1144 2409.5,1143.4 2543.2,1160.7 2725.9,1170.9 2720.7,1193.7 2678,1188.4
        2627.5,1212.4 2442.4,1223.4 2338.8,1232.2 2336,1224.5 2408.1,1213.5 2470.1,1198.3 2297.5,1183 2266.7,1197.4 2231.7,1198.3
        2128.8,1182.7 2126,1167.2 2077.5,1155.6 2046.3,1131 2134,1125.6 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2242.4,1240.1 2272.9,1251.1 2290.2,1265.8 2374.5,1281.6 2433.7,1280.5 2484.7,1294.9 2558.8,1289.5
        2600.4,1283.6 2703.3,1281.9 2615.7,1304.8 2550.9,1298.3 2487.1,1325.7 2555,1333.3 2562.6,1333.6 2558.8,1339.2 2531.1,1339.2
        2366.5,1360.7 2302.4,1346.9 2200.1,1350.3 2224.1,1330.8 2257.7,1329.1 2292.7,1323.4 2337,1313 2217.5,1299.4 2166.9,1291.5
        2068.1,1288.9 2052.2,1266.9 2034.8,1226.5 2087.2,1213.2 2141.6,1236.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2178,771.4 2217.5,775 2670.4,786.3 2692.3,776.7 2691.2,785.2 2680.5,784.1 2522.4,800.5
        2379.3,790.6 2295.8,798.8 2185.2,795.7 1936.4,792.6 1949.6,783.8 2075.7,772.5 2119.4,781 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2290.2,740.6 2428.5,745.7 2706.5,745.7 2935.9,739.2 3028.8,738.6 3154.6,729.3 3186.1,735.8
        3212.1,739.7 3340,742.8 3584,745.7 3854.3,745.1 3929.1,760.9 3989.4,771.9 4057,771.9 4110,774.5 4122.9,757.8 4077.1,744.2
        4075.4,727.6 4040.4,723.9 3749.3,723.1 3633.5,718 3296,732.1 3263.7,732.4 3249.9,723.3 3227,718 3120.9,712.9 3071.4,711.8
        2848.6,710.3 2759.5,717.1 2461.4,714.9 2112.1,728.7 2024.1,741.7 1973.8,753.3 2013.3,755.5 2086.8,751.3 	"/>
            <polyline :fill="colors[`${id}-p-1`]" points="2063.6,868 2293,861.5 2385.9,860.9 2512,851.6 2543.2,858.4 2569.2,862.1 2697.1,865.4 2941.1,868
        3211.4,867.4 3286.3,883.5 3346.9,894.5 3414.1,894.3 3467.5,897.1 3480.3,880.1 3434.6,866.6 3432.9,850.2 3397.9,846.2
        3106.4,845.7 2990.6,840.3 2653.1,854.4 2621.2,854.7 2607.3,845.7 2584.5,840.3 2478.1,835.2 2428.5,834.1 2205.7,832.7
        2117,839.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2329.4,706.7 2428.2,695.1 2500.3,698.8 2612.9,693.4 2829.5,692.8 2999.7,678.1 2602.2,678.1
        2511.7,677 2471.5,659.2 2310.7,669.9 2203.3,655.2 2094.8,660.9 2020.6,688.9 2120.4,699.9 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2441.7,651.6 2516.6,642.8 2598.3,661.2 2749.4,648.7 2819.8,655.2 2923.8,662.3 3205.2,658.1
        3238.8,647.3 2983.4,619.9 2837.1,611.2 2705.4,618.5 2639.9,607.5 2563.7,609.2 2220.9,605.2 2048.4,617.7 1990.8,641.1
        2089.6,644.5 2208.5,633.5 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2307.9,520.2 2365.8,517.7 2444.5,518.2 2578.2,501 2760.9,490.8 2755.7,467.9 2713.1,473.3
        2662.5,449 2477.4,438.3 2373.8,429.5 2371,437.1 2442.7,448.2 2505.1,463.4 2332.5,478.7 2301.7,464.3 2266.7,463.1 2163.8,479
        2161,494.5 2112.5,506.1 2081.3,530.7 2168.6,536 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2277.4,421.6 2307.9,410.6 2325.3,395.9 2409.5,380.1 2468.7,380.9 2519.7,366.8 2593.8,372.2
        2635.4,378.1 2738.3,379.8 2650.7,356.9 2585.5,363.1 2522.1,336 2590,328.4 2597.6,327.8 2593.8,322.4 2566.1,322.4 2401.5,301
        2337.4,314.8 2235.1,311.4 2259.1,330.9 2292.7,332.6 2327.7,338.3 2372,348.7 2252.5,362.3 2201.9,369.9 2103.1,372.7
        2087.2,394.8 2069.8,435.2 2122.2,448.4 2176.6,424.7 	"/>
            <polygon :fill="colors[`${id}-p-1`]" points="2284.4,241.1 2390.1,221.6 2465.6,225.5 2489.5,212.2 2638.5,202.9 2785.8,213.7 2583.8,236.3
        2556.8,249.3 2480.5,247 2384.2,257.5 2228.9,258.9 2176.2,245.9 2156.1,235.4 	"/>
            <path :fill="colors[`${id}-p-1`]" d="M2005.4,52.1L2117,62l17,13.8l3.8,2.8l-20.1,2.3l-92.9,14.7l77.6,12.1l84.2,10.7l124.1,3.7l41.9,7.1
        l-83.9,12.1l-112.3,8.2l65.5,3.7l223.9-0.8l38.8-13.3l-32.9-5.9l8.3-21.2l22.5-1.1l188.2-4.5l48.5-4.2l-11.8-6.8l-156.3-4.5
        l-89.7-1.8l-67.2-7.3l-62.4-13.6l20.8-17.2l36.4-3.7l11.1-7.9l-14.6-8.5l-126.5,9l-91.5-20.1L2013,23.5l-77.3-13L2005.4,52.1z
         M2232,74.9c9.7,1.4,19.4,3.4,29.5,4.8c-1,1.7-2.4,3.1-4.5,4c20.8,1.1,41.6,2,62.7,3.1c-2.1,2-4.5,3.7-7.3,5.4
        c23.9-2,48.9-1.1,72.4,2.3c-8.7,1.1-17.3,2.5-26,3.7c20.8-1.4,42.3,0.6,61.3,5.9c-16.6,7.9-9.4,3.1-26,10.2
        c0.7,0.3,0.7,0.3-0.7,0.3c0.3,0,0.7-0.3,0.7-0.3c-3.1-0.6-19.4-1.7-13.5-2c-25-0.3-49.9-1.1-74.5-2.5c2.8-1.4,6.2-2.5,10.1-2.8
        c-10.1-1.7-20.8,0.8-30.8-0.8c-5.2-0.8-9.7-3.1-14.9-4.5c-6.6-2-13.9-2.5-20.8-4c-12.8-2.8-25.3-9.9-28.4-17.8
        C2218.5,73.2,2222.3,73.5,2232,74.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2277.4,273.8c-19.8,8.2-34,13.8-48.2,19.8c-66.9,3.7-133.1,6.5-194.4,5.4c35.7-5.9,65.2-12.4,108.8-17.8
        C2167.9,278.4,2216.8,277.2,2277.4,273.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M177.9,1367.2c154.9-5.1,141,5.4,188.9,12.1c-90.5,4-158.7,7.1-251.9,11.3
        C138.4,1381.9,156.8,1374.8,177.9,1367.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M794.1,1051.9c13.2,0.6,28.4,0.6,24.6,1.4c-8.7,2,33.3,2.8-60,7.1c-17.7,0.8-49.2,1.7-57.5,1.7
        c-48.9,0-66.2-1.7,4.9-6.2C719.6,1055,746.3,1054.2,794.1,1051.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1268.2,813.7c-15.9,3.7-31.2,7.1-46.4,10.7c-0.3,0-0.7,0-1,0h1c0,0,0,0,0.3,0c3.1,0,6.2-0.3,8.7,0
        c18,1.1,52.7-2,56.8,2c4.2,5.1-24.6,7.6-54.1,9.6c-22.5-6.8-73.1-7.6-116.4-10.2c-11.1-0.6-23.2-0.8-32.9-1.7
        c-18.7-1.4-15.6-3.1,11.1-8.2c23.2,1.1,42.3-1.4,61.3-3.7c36.7-4.5,69.7-4,100.2-2C1263.4,810.6,1264.1,812.3,1268.2,813.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1779,1603.7c45.1-3.7,130-6.2,221.5-9.9c-9.4,5.9,5.5,10.2-28.1,14.1c-33.6,4-118.2,8.2-183,11.3
        c-24.6,1.1-82.1-0.3-78.7-2C1717.4,1613,1735.4,1607.4,1779,1603.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M417.8,821.1c-41.9,0.6-85.6,1.4-113.7,0.8c-11.1-0.3,11.8-3.4,18-5.1c45.1-1.1,89.1-2,128.6-2.5
        C458,814.6,434.4,818,417.8,821.1z"/>
        </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>