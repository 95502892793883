<template>
    <div class="modal active start-customize" id="orderCreated" tabindex="-1" aria-labelledby="orderCreatedLabel" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="fs-4 my-2 fw-bold">Bedankt voor je bestelling.</p>
                    <p>Je ontvangt spoedig een betaallink, zodat we na betaling de productie kunnen starten.</p>

                    <div class="d-flex flex-column align-items-center">
                        <font-awesome-icon :icon="['far', 'check-circle']" size="4x"/>

                        <button type="button" class="btn btn-primary mt-5" data-bs-dismiss="modal" aria-label="Close">
                            Bekijk ontwerp
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>