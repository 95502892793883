<template>
    <pattern :id="id" x="0" y="0" width="2300" height="2300" viewBox="0 0 2300 2300" patternUnits="userSpaceOnUse"
             :patternTransform="transform">
        <rect x="-0.5" y="-2.3" :fill="colors[`${id}-p-0`]" width="2301" height="2306.8"/>
        <g>
            <defs>
                <rect style="stroke:#000000;stroke-width:0.25;stroke-linecap:round;stroke-linejoin:round;" id="SVGID_1_" x="-0.5" y="-2.3" width="2301" height="2306.8"/>
            </defs>
            <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_" style="overflow:visible;"/>
            </clipPath>
            <path :fill="colors[`${id}-p-1`]" d="M-397.1-14.7c95.5,72.1,190,145.4,280.7,214.6c6,4.4,9.4,6.3,11.6,1.2c2.1-4.3,5.4-7.9,9.6-10.3
		C45,104,155.4-65.7,328.1-282.4c7.5-9.7,15.2-13.9,24.6-13.6c55.4,2.5,110.7,3.8,166.1,5.4l10.4,0.4c0,1.3,0.4,2.8,0.4,4.1
		c-20,23.3-39.2,46.1-57.7,68.4C186,123.5,83.4,369.1-207.6,398.4c-3.8,0.4-7.8,1.2-9,3.9c-3.7,8.2-11.7,4.5-26.3-7.5
		c-33.6-27.6-68.1-55-103.1-83.9c-5.4-34.7-11-69.1-16.4-103.8c5,4,10.2,7.7,15.2,11.9c52,42.9,102.9,84.1,152.5,124.1
		c11.1,8.8,15.3,9.4,18.7,3.9c11.1-18.7,22.4-37.8,32.6-58.3c1.1-2.8,2-5.7,2.8-8.6c-78.8-61.3-158.8-125.4-240.6-191.3
		C-386.2,54.4-391.8,19.8-397.1-14.7z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-167.6,1443.1c84.5-20.8,170.8-36.3,229-70.2c249.4,279.2,459.6,459.2,558.5,678.8
		c-19.2-1.2-35.9-2.1-55.4-3.5c11.5,108.8,3.8,224.7-30.5,362.5c-26.4-0.7-51.8-1.2-76.7-2.9c-2.4-0.1-2.7-7.5-1.9-12.1
		c8.7-43.8,15-88,18.6-132.5c5.7-71.3,3.1-143-7.8-213.6c-0.4-2.8-0.3-5.6-0.6-10.1c21.1,1.8,40.3,3.5,62.3,5.1
		c-103.7-199.1-300.5-377.8-533.3-639.2c-19.5,9.2-37.9,17.1-55.3,25.5c-2.4,1.2,0.3,6.4,3.2,9.8
		c107.5,120.5,208.1,225.7,294.5,322.5c82.7,93,152.6,178.3,204.4,266c1.1,2,1.7,3.8,3.6,7.8c-23.5-2.2-44.9-5.8-67.6-6.4
		c-25.7-0.9-37.8-10.1-48.5-25.8C222,1848.5,59,1687.1-127.2,1478.9c-3.4-3.8-7.5-7.6-11.7-11.9l-24.5,5.2
		C-164.6,1462.4-166,1452.9-167.6,1443.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-327.7,426.9c10.7,8.7,21.1,17.1,31.3,26.1c6.1,5.3,11.2,11.6,19.5,19.9c16.9,4.3,36.7,9.5,54.6,12.2
		c10.8,1.6,17.3,6.2,27.1,18.7c94.9,122.6,192.4,238.7,288.6,342C200.7,961.4,307,1061.8,408.8,1146c5,4.3,10.1,8.6,16.2,13.6
		c-15.3,12.4-31.4,23.8-48,34.4c-119.7,78.1-299,136.5-536.9,188.7c-5,1.1-11.1,1.5-16.6,2.1c-5.6-35.2-11.2-70.7-16.7-106
		c2.3,0.1,4.6-0.1,6.9-0.4c208.3-37.7,367-82.9,479.9-145c6.6-3.6,3.7-7.1-4.1-14.2c-177.9-157.1-367.9-364.7-551-600.3
		c-10.5-13.5-21.5-27.1-32.9-42c-8.4,4.6-15.7,8.6-23.2,12.4C-321,468.7-324.3,447.8-327.7,426.9z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-202.5,1221.1c-5.5-35.3-11.1-70.9-16.6-106.2c3.3,0.2,6.7,0.2,10,0c79.5-10.5,150.5-23,213.8-37.9
		c19.6-4.6,38.5-9.7,56.9-15C15,1016.4-30.3,969.7-76.1,920.8c-70.1-74.9-141-155.4-211.4-240.3c-7-44.2-14-88.6-21-132.8
		c2.6,3.2,5.5,6.3,7.9,9.5C-133,770.4,40,960,203.4,1110.3c1.9,1.8,3.4,3.7,5.4,5.8c-3,1.5-5.5,2.7-8.3,4
		c-82,34.6-183.4,62.5-306.1,85.9C-136.1,1211.6-169,1216.2-202.5,1221.1z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-150.1,1554.2c48.1,52.5,94.5,102.1,137.9,149.5c101.5,110.2,189.8,208.2,259.9,302.8
		c3.4,4.5,12.1,8.6,19.4,10.1c17.6,3.5,35.9,4.4,52.8,7.6c5.7,1,12.5,5.6,13.4,9.4c22.3,88.6,31.6,180,27.6,271.2
		c-1.2,28.2-3.8,57.2-7.7,87.1c-0.5,4-1.3,8.1-2.2,13.1c-27.3-0.8-53.4-1.5-80.7-2.5c2.1-33.6,2.3-66.3,1.2-98.3
		c-3.6-97.6-22.3-189.6-50.3-281c-3.1-10.3-9.6-15.4-25.2-16.1c-42-1.3-65.5-16.2-86.5-43c-61.1-78.9-139.2-160.5-223.2-250.9
		c-3.7-4-8.9-7.7-13.2-11.6C-134.5,1652.3-142.3,1603.2-150.1,1554.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M-107.9,1822.2c18.5,19,36.7,37.9,53.7,56.3c29.3,31.7,56.2,62.8,82.7,93c3.7,4.3,11,9.7,17.1,10.7
		c12.7,2.1,18,5.6,21.2,13.4c53.8,131.7,92.1,259.9,101.4,396.3c0.2,1.9,0.2,3.9,0.1,7c-27-0.8-53.4-1.5-80.9-2.5
		c-6.4-33.2-14.3-66.5-23.8-99.6c-30.1-106.9-75.1-212.6-128.8-322.8c-3.4-6.9-4.3-15.2-21.7-16.8
		C-93.8,1912.1-100.8,1867.2-107.9,1822.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M304.2,1292.5c35.2-17.2,69.3-36.8,101.9-58.6c31.1-20.9,60.6-44.1,88.2-69.4c4.3-4.3,2.7-7.1-4.4-12.6
		C295.6,1000.6,84.3,781.9-121,518c-3.9-5.1-8.1-10.3-13.3-17.1c9.5,1.4,19.1,2.3,28.8,2.6c19.9,0,40.6,0.6,56.6-4.1
		c248.4-73.2,348.1-336.8,640.6-669.6c18.8-21.7,38.5-43.3,60.3-67c6.2,15.9,11.9,31.3,18.4,45.7C737.6-40.8,783.1,107.4,843.6,218
		c7.6,14.3,15.2,28.5,22.5,41.3c-0.2,1.5-0.6,2.9-1.1,4.3c-11.7,19.2-11.4,19.5-2,37.8c6.6,12.8,13.7,25.3,21.8,38.9
		c25-38.5,51.7-76.6,82.2-116c-4.3-8.3-8.2-15.9-12.2-23.5c-33.1-63.2-58.9-140.3-92.2-217c-30.3-68.7-47.8-156.4-71.4-245.8
		c-1.3-5.2-2.1-11.1-4.1-20.1c17.6,0.4,33,1.1,48.6,1.5c19.4,0.7,38.7,2.1,58.2,1.4c8.9-0.1,12.8,5.4,14.8,17
		C924.3-176.8,944.4-92.1,969-8.7c3.5,11.5,8.2,19.2,17.8,19.8c28.3,1.6,43.5,21.8,53.8,52.8c18.6,55.7,43.3,102,68.7,145.4
		c0.8,1.6,1.3,3.2,2.5,5.8c-19.2,21.8-37.3,43.5-54.6,65.1c-37.6,47.1-72.4,96.4-104.4,147.5c-4.3,6.8-4.9,11.1-0.1,16.9
		c10.1,12.5,19.8,25.1,30.8,38.2c59.3-100.5,138.2-202.2,240.3-304.3c-4.5-7.8-8.7-15.2-12.8-22.6c-15.3-27.4-28.9-55.6-40.6-84.7
		c-3.9-10.1-9.9-15.6-19.9-17.4c-20.8-3.7-41.4-8.6-61.7-14.5c-4.7-1.4-8.5-4.9-10.3-9.4c-28.5-83.8-50.8-181.7-69.1-296.8
		c-0.3-1.5,0-3.2-0.3-6.7c3.2-0.6,6.5-1.1,9.8-1.2c32.3,0.8,64.5,2.3,97.1,2.5c8.3,0,11.6,4.3,13.2,14.7
		c16.3,125.1,40.6,227.6,73.9,314.2c1.5,4.1,3.5,9.1,7.1,9.9c34.2,7.4,69.1,13.5,104.9,19.5c0.9-1.1,2-1.9,2.9-3
		c-43-93.6-71.6-205.2-87.5-350.1c4.1-0.5,8.2-0.8,12.3-0.9c31.7,0.9,63,2.4,94.7,2.6c8.8,0,12.6,3.9,13.6,13.7
		c12.6,123,38.7,221.3,79,306c7.9,16.7,16.4,32.8,23.4,51.4c-38.2-5-74.8-10.1-110.8-15.6c-0.9,0.8-1.7,1.4-2.6,2.3
		c14,26.9,29.6,52.9,46.7,77.9c-149,127.8-258.9,258.7-331.2,387.6c-18.2-12.2-35.3-24.8-52.4-37.8
		C865.6,416.5,745,262.7,624.2,24.1c-6.1-12.3-9.9-10.6-17.1-1.6C498.1,156.9,418,279.4,343.8,379.8c-1.7,2.5-2.4,5.6-1.9,8.6
		c10.5-7,21.4-13.7,31.4-22c5.4-4.4,9.3-3.7,15.7,6C491.6,526.6,594.5,648.7,698,742.6c4,4.1,7,8.9,8.9,14.3
		c4.6,12.5,8.6,25.1,14,40.5c8.6-17.5,16.1-33.1,25.4-52.4c2,2.8,4.2,5.4,6.7,7.8c150.8,110,300.4,166.5,457,228.6
		c103.5,41.1,210.1,86,321.2,150.7c8.7,5.2,11.6,9.1,10.9,17.1c-1.4,22.2-1.6,44.9-2.4,68c-0.2,3.3-0.3,6.6-0.7,11.1
		c-257.9-163.7-496.8-198.2-744.1-348.1c-7.3,16.2-14,31.3-21.9,48.4c-6.7-15-12.3-28.2-17.8-41.5c-3.9-9.2-6.9-19.1-11.4-28.2
		c-3-5.4-6.9-10.2-11.7-14.1c-93.2-73.6-186.8-166.4-280.4-283c-36.8-45.7-74-95.1-110.6-148.1c-7.9-11.7-13.2-15.8-20.7-9.8
		c-10.5,8.3-22.2,13.9-34.5,20.7c3.5,5.4,5.9,9.2,8.4,12.8c157.5,219.7,314.1,378.7,468.4,486.7c8.2,5.2,14.5,12.9,18,22
		c8.9,21.5,18.9,42.5,28.8,63.4c1.9,4,3.4,7.8,6.3,14.2c-6-3.5-9.7-5.8-13.6-8.3c-181.7-113.3-369.8-289.7-559-546.5
		c-10.1-13.6-15.7-16.3-23.5-11.6c-10.7,6.4-22.8,10.1-35.8,14.8C412,779.6,637.4,977.2,851.4,1098.3c-2.6-6-4-9.4-5.5-12.6
		c-7.7-15.4-15.5-31-22.7-47c-1.8-4.3-2-9-0.7-13.4c7.8-18.7,16.6-37.2,23.9-56.4c2.7-6.9,6-6.9,13.6-2.9
		c145.4,75.3,287.6,119.2,431.2,181.4c78.2,33.9,157.2,73.3,237.3,125.1c2.5,1.6,4.7,3.4,8.6,6.3c0.6-3.7,1-7.4,1.1-11.2
		c-0.4-17.6,2.7-35.1,9.1-51.5c52.2-148.3,120.4-271.9,274.7-372.6c6.9-4.5,10.1-10,9.1-17.9c-2.5-18.9-3.4-37.7-4.3-59.6
		c6,5.5,9.2,8.3,12,11.2c39.5,39,80.1,80.2,121.5,123.5c5.9,6.2,13.7,12.2,21.8,19.4c-26.4,12.7-52.2,26.8-77.2,42.2
		c-117.9,72.5-184.7,162.5-235.6,271.6c-36.1,77.2-65.1,164-102.3,258.2c-2.6,6.4-2.1,11.4,1.6,18.9
		c41.5,82.3,78.7,175.1,109.5,281.8c3.1,10.5,6.2,14,13.2,14.7c14.8,1.4,29.4,4,43.8,7.7c5.5,1.7,10.3,5.4,13.3,10.3
		c105.7,167.3,209.7,382.5,316.4,620.1c1.3,2.8,2.3,5.9,4.8,12.3c-25.1-0.8-48.7-0.8-72.1-3c-3.4-0.4-7.1-10.7-10-17.3
		c-50.9-120.3-100.9-234.3-151-337.5c-51.6-106.4-103.3-201.6-155.8-284.4c-4-6.4-10.7-11.3-16.2-12.3c-14.7-3.2-29.8-4-44.7-2.4
		c106.1,164.1,207,385.2,312.4,653.1c-17-0.6-31.6-3.6-46-0.7c-21.4,4.3-33.5-10.8-44.6-41.4c-92-251.1-184-454.9-282-606.7
		c-2-3.1-3.8-6.4-7.2-12c8.3-0.2,14.8-0.5,23-0.7c-17.8-66.7-40.2-132.1-67.1-195.8c-7,37.1-15,72-24.8,108.8
		c-115.3-124.1-234.4-198.3-367-262.8c-25.5,42.6-54,83.2-85.5,121.5c-1.7-7.1-3-12.2-4.9-20.1c-71.8,110.3-176,217-315.7,323.6
		c0.3-8.6-0.4-14.1,0.8-19.8c5.9-28.6-7.5-50.6-26-72.7c-68-81.7-148.7-162.9-239.2-253.9c-68-68-140.9-141.6-217.2-223.3
		c-8.2-8.7-8.7-13.4-2.7-18.9c10.8-9.7,25.3-9.5,48.6,1.3c9.7,4.5,19.9,8.2,29.7,12.1c1.5,1.2,3,2.5,4.3,3.9
		c143.8,150.4,276.8,269.4,383.1,384.1c2.8,2.9,5.8,5.8,8.8,8.4c1.2-0.5,2.5-0.7,3.7-1.2c-1.5-10.4-2.4-20.7-5-30.8
		c-1.9-7.9,0.6-13.5,8.1-19.6c119.2-98.3,196.8-201.1,246.4-308.5c1.2-2.4,3.2-5.7,2.1-8.1c-5.1-13.6-11.2-27.1-17.1-40.6l-2.7-0.1
		c-47.4,112.3-123.2,218.7-246.4,320.5c-4.3-3.7-7.2-6.1-9.7-8.7c-74.8-73.7-160.3-150.8-248.7-240c-22.8-22.9-45.6-39.9-84.9-47.9
		C332.9,1301.2,318.6,1296.6,304.2,1292.5z M1530.2,1428.6c1.3-15.6,1.8-29.7,3.3-43.1c1.1-8.1-1.3-12.4-8.7-17.4
		c-184.2-130.7-365.4-184.2-555.7-264.2c-20.2-8.6-40.6-17.4-62.2-27.2c-10.7,26.3-23.6,50.3-33,76.1
		c-40.4,112.2-100,216.6-206.6,312.7c-3.3,2.9-6.6,5.8-11.8,10.2c-1.9-5.7-3-9-4.1-12.2c-5.8-18.2-12.1-36.3-18.8-54.6
		c-1.3-3.6-3.4-6.9-6.2-9.6c-10.3-9.6-21.2-19.2-32.2-29c-2.9-2.4-7.1-5.7-10.7-6.4c-24.4-4.9-49.2-9.6-77-16
		c3.4,3.9,5.5,6.3,7.7,8.4c45.2,42.8,88.4,82.9,130.4,120.9c10.8,9.7,18.9,19.2,20.9,30.5c0.4,3,2.4,6,4.3,10.9
		c118.3-101.4,185-208.4,228.3-325.6c8.4,17.1,15.1,31.1,23,47.6c16.3-30,31.1-60.9,44.2-92.4
		C1162,1231,1344.7,1286.6,1530.2,1428.6L1530.2,1428.6z M1508.8,1591.5c3-15.9,4.4-30.7,7.8-43.6c2.9-12-1.1-17.5-9.3-24.5
		c-49.8-41.5-102.9-79-158.6-112.1c-102.8-61-208.3-103.3-319.4-149.9c-7.2-3.1-9.8-2.1-12.8,3.8c-10.7,23-22.1,46.2-36.7,68.1
		c-8.4,12.8-11.7,24.3-3.2,39.4c4.3,7.6,5.6,16.3,9.3,24.2c3.3,7.2,2.3,13.1-1.3,19.7c-56.7,104.7-142.8,206.5-265.2,305
		c-3.9,3.2-10.3,7-10.3,10.3c0.2,14.2,1.9,28.5,2.6,44.3c3.6-2.5,5.8-3.8,7.6-5.3c128.2-101.1,220.7-204.8,281.8-311.1
		c1.1-2.5,2-5,2.8-7.6c1.5-4.5,1.9-9.4,4.4-13.2c18.1-26.8,34.6-53.7,49.5-80.7c5.4-9.7,10.7-19.2,16-29.6
		C1228.7,1394.6,1369.8,1462.4,1508.8,1591.5L1508.8,1591.5z M72.7,500.7c234.3,306.9,468.3,521.4,682.6,653.4
		c-36.7,76.4-85.7,146-161.1,212.7c33-0.9,25.1,16.3,35.4,26.6c86.9-79.7,139.8-165.8,177.3-259.9c-4.5-2.8-7.8-4.9-11.3-7
		c-161-97.4-331.5-235.6-505-434.1c-52.1-59.1-104.4-123.2-156.8-190.7c-3.3-4.3-10.7-10.4-13.2-9.9
		C105.2,494.5,90.2,498.1,72.7,500.7z M-55.8,507c224.3,290.5,452.3,516.3,659.9,663.4c-44.1,57.7-100.7,109.2-177.7,157.5
		c19.2,5.3,34.2,8.7,49,12.8c8.8,2.5,13.9,1.9,18.6-1.8c73-53.1,126.4-110.6,167-173.9c3.3-5.1,2.9-8.1-5.3-13.5
		c-151.3-104-312.4-246.4-475.7-432.7c-55-62.6-110.1-129.6-165.4-199.6C11.2,514.9,3.6,508,1.4,508
		C-16.8,507.5-34.9,507.8-55.8,507z"/>
            <path :fill="colors[`${id}-p-1`]" d="M631.5,363.9c1.2,22.3,2.6,39.9,4.7,61.1c-38.5-54.6-74.8-112.2-111.8-177.4c18.2-15.2,35.8-30.1,54.1-44.5
		c1.5-1.2,6,3.5,7.8,7.1c11.1,20.2,21.8,40,32.6,59.1c2.1,3.5,4.1,6.8,8.3,13.3c-0.8-38.7-0.8-74.8,0.9-118.2
		c197.6,336,395.7,455.4,648.8,569.7c0.3-0.5,0.9-1,1.2-1.6c-19.2-12.8-38.3-26-57.9-39.9c8.6-15.8,17.5-31.7,27.3-47.5
		c87.5-143.6,237.9-283.5,439.7-418.8c32.6-22.2,66.7-44.1,100.8-66.4c6.8-4.9,15.3-6.7,23.5-5c25,4.3,51.1,8.2,77.6,12.6
		c5,0.9,10.1,1.3,19.4,2.7c-130.2-130.4-207.8-252-233.4-424c3-0.5,6.1-0.8,9.2-0.9c33.2,0.8,66.6,1.6,99.5,3.4
		c5.5,0.4,10.3,4,12,9.2c24.6,106.7,74.6,195.9,150,284.3c5.8,6.6,7.3,12.8,4.3,20.8c-7.2,18.5-13.4,37.3-18.6,56.4
		c-1.6,6-1.3,13.4,2.1,18.7c81.2,121.5,196.1,243.9,329.6,389.7c51.7,56.3,106.6,116.1,162.7,179.6c4.9,5.5,8.8,11.7,9.7,16.7
		c9.3,56.2,18.1,112,26.9,167.8c0.2,1.5,0.2,3.1,0,4.6c-10.8-17-188.3-208.5-326.4-361.9c-143.9-159.1-248.5-280-245.6-291
		c-22.2,12.8-43.2,24.9-63.7,37.4c-249.1,146.4-424.5,298.2-513.9,459.6c-4.8,8.2-4.9,13.1,5.5,18.7c12.4,6.8,24,15.2,37.5,24.2
		c86.6-159.3,257.8-304.8,508.5-444.6c-5.1,17.3,13.5,29.5,25,42.8c135.8,155.6,314.6,331.8,503.7,558.7
		c28.9,34.5,58.4,70.2,87.4,106.9c4.8,6,8.3,12.2,9.1,17.3c6.2,35.2,10.9,69.8,17.1,105.2c1.5,8-3.1,9.2-15,6.4
		c-40.6-8.9-78.8-15.8-118.2-22.5c15.3,18.6,29.9,36.5,44.3,54.4c16.6,20.5,33.5,41.6,49.2,62.6c8,10.7,15.4,17.1,28.9,18.8
		c8.1,0.9,17.7,4.3,29.1,7.2c3.2,20.3,6.1,39.1,9.7,61.3c-15-19.6-27.3-35.7-41.3-53.7c-5.5,0-10.1-0.3-14.4,0
		c-232.5,20.5-363.2,82.6-458.3,195.5c-4.5,5.3-1.2,12,3.1,20.5c65.8,131.6,127.8,277.7,184,437.3c1.1,3.1,1.9,6.2,3.9,12.1
		c-18.3-7.9-35.5-15-56.5-23.4c112.1,172.7,221.3,359.7,329.9,524.6c-25.8-0.9-49-1.2-72.1-2.8c-3.3-0.2-7.9-7.3-10.9-12.1
		c-87-142.4-174.7-295.9-262.5-438.4c-20.7-33.6-41.2-66.7-59.2-98.9c-0.5-5.3,0-10.9-1.6-16c-44.7-131-93.8-250.1-146.2-358
		c-16.2-33.3-32.5-65.6-49.6-96.8c-4.9-8.8-4.6-13.4-1.7-18.2c89.8-145.9,214.3-234.8,468.2-278.5c23-4,20.1-1,2.1-23.2
		c-15.6-19.2-32.1-38.2-49.7-58.3c-20.9,4.3-40.1,8.6-58.4,13.1c-273.3,66.6-377.1,191.4-467.3,384.8c-2.9,5.9-1.5,11.5,2.6,19.5
		C1833.1,1592,1883,1711,1927.2,1846c1.7,4.9,3,10.1,5.3,17.7c-20.1-6.2-38.5-11.5-60-17.2c112.2,177.2,221.7,391.5,335.8,615.8
		c-13.6-0.4-25.4-3.3-36.3-0.6c-28.1,6.8-46-13.6-64.5-53.4c-98.5-211-197.4-404.8-295.9-562.8c-2.7-4.5-5.4-8.9-9.4-15.5
		c6,0.5,10.2,0.7,15.6,1.3c-9.7-32.2-19.5-61.9-29.5-90.7c-38.1-108.4-80.9-204-127.4-289c-5.4-10-6-16-3-23.5
		c84.7-216.4,167.1-369,434.6-465.3c2.9-1.2,5.6-2.7,8.3-4.4c-6.4-5.8-13.8-11.3-18.9-17c-85.4-94.6-167.9-178.5-244.8-255.1
		c-3-3.1-6.3-5.9-12.5-11.8c-0.4,10.4-0.9,18.1-0.8,26c0,13.7-0.3,27.4,1.3,41.2c0.9,7.4-2.8,12.4-9.8,16.7
		c-131.8,81.2-211.1,173.8-262.6,280.4c-5,10.5-5.3,23.6-7,36c-0.9,5.2-0.7,10.9-1.3,17.7c-156.1-91-301.1-142.6-439.9-200.5
		S832.3,767.1,697.5,649.3c-4.7,8.6-9.1,16.6-13.5,24.9c-1.1-1.1-2.4-2.1-2.4-2.8c-1.6-12.8-8.8-20.9-17-29.6
		C586.5,560.1,509,459.2,432.1,339.1c-2-3.3-3.8-6.8-6.9-11.9c16.7-13.1,32.8-26.4,49.6-39.2c1.7-1.2,6.9,3.6,9,7.3
		c51.3,84.2,102.6,158.7,154.5,223.5c3.5,4.3,7.1,8.6,12.4,15c-5.2-25.4,8.8-30.5,14.4-46.3c268.3,302.1,543.3,327.7,886.6,520.3
		c1.7-14.6,3.9-27.9,5.2-41.5c0-1.8-4.9-5.7-8.1-7.5c-86.9-48.9-170.4-87.3-250.1-122.4C1063.5,735,864.7,651.2,670,411.9
		C658,397.2,645.9,382.5,631.5,363.9z M1846,472.5c-1.5,0.1-3,0.4-4.5,0.5c-1,7.4-0.7,15.2-3.2,22.1c-1.9,6.3-6,11.8-11.6,15.3
		c-176.5,100.2-296.5,205.9-368.8,322.4c-7.8,12.6-7.8,12.6,8.6,23.5c11.7,7.8,23.5,15.8,36,24.5c2.3-3.5,4-5.9,5.5-8.4
		c61.7-95.5,154.8-182.6,291.6-262.9c18.4-10.9,35-21.6,31.1-40.9c0-0.4,1.4-0.7,2.8-1.5c2.5,1.8,4.8,3.8,6.9,6
		c45,46.4,91.7,95,142.6,146.7c91.9,92.8,176.9,196.2,287.4,312.9c6.8,7.1,11.3,12.8,22.9,13.3c24.6,0.9,52.5,6.6,85.3,24.1
		c1.7,0.9,3.7,1,8.8,2.3C2189.6,824.8,1996.9,634.5,1846,472.5L1846,472.5z"/>
            <path :fill="colors[`${id}-p-1`]" d="M709.1,1915.2c138.1-104.3,247.1-211.1,323-319c0.9,0,1.7-0.1,2.6,0c3.2,23.1,5.8,46.6,7.4,73.8
		c43.1-46.6,82.2-96.8,116.7-150c8.4,4.6,16.8,9.1,24.8,14c97.6,57.1,187.1,126.6,273.3,225c6.6,7.5,8.1,13.6,3.8,24.1
		c-3.9,9.7-6.6,19.8-8,30.1c0.8-1.3,1.9-2.1,2.1-3.5c3.7-15.6,9.3-24,21.9-21.4c9.6,2.1,18.7,0.4,27.1,13.3
		c100,152.9,191.5,362.7,283.4,628.5c1.7,5.1,3.3,10.4,6.1,19.5c-24.9-0.8-48.3-0.9-71.2-2.8c-3.3-0.2-6.2-8.7-8.1-14.7
		c-61.8-193-124.2-355-191-484.8c-21.2-41.4-42.9-79.4-65.3-114.9c-2-3.3-4.3-6.1-7.4-10.2c-6,16.8-12.6,32.2-18.1,48.6
		c-1.4,3.8,0.7,11.5,2.8,15.2c33.9,60.2,64.3,122.3,91,186c43.4,102.5,84.4,221.4,124.9,356.8c1.3,4.3,2.4,9.1,4.3,16.8
		c-24.6-0.7-47.5-0.6-70.4-3c-3.8-0.5-6.9-9.9-8.8-16.7c-48.4-176.6-99.1-322.8-155.8-440.4c-6.2-12.8-12.5-25.1-20-39.7
		c-8.6,19.1-17.4,36.5-25.7,54.8c-1.2,2.7,0.6,8.9,2.1,12.2c45.5,100.3,86.4,220.5,124.5,363.9c5.3,19.8,10.3,40.2,16.3,64.5
		c-25.3-0.7-48.2-0.7-70.8-2.9c-3.8-0.5-6.4-9.6-8-16c-30.2-130-62.8-241-99.9-335.5c-1.5-4-3.2-7.8-5-12.3
		c-3.4,5.8-6.4,10.7-12,19.8c-2.9-6.4-5.2-12.2-7.8-17.4c-31.7-61-69.1-118.9-111.7-173c-6-7.5-9.9-7.5-18.3,1.3
		c-57.5,59.6-123.5,118.4-196.4,176.3c-2.4,2.1-5.1,3.7-10.4,7.5c6-18.8,10.9-34.9,15.6-50.7c10-34,18.1-67,25.7-98.7
		c1.9-6.9,5.6-13,10.9-17.8c64.5-57.4,121.5-115.2,169.9-173.5c5.4-6.6,8.2-5.5,12.8-1.4c60.5,54.4,116.4,118.9,169.3,199.4
		c2.6,4,5.5,8.2,9.6,14.3c10.5-22.8,19.9-43.3,27.6-64.4c1-2.6-1.9-9-4.1-12c-50.9-70.7-103.9-128.3-160.7-177.2
		c-23.5-20.4-47.7-39.3-73.4-57.7c-2.1,2.4-4.3,4.4-6.2,7c-39.3,50.2-81.9,97.7-127.6,142.3c-4.1,4.2-8.3,11.6-8.1,16.6
		c0.4,24.4-10,45.6-32.1,64.8c-16.6,14.5-31.5,31.6-48.3,47.2c-7,6.3-8.7,11.5-8,18.7c7,69.9,6.3,140.3-2.1,210
		c-2.4,19.7-12.5,41.8-19.9,63.5c-1.9,5.4-4.3,10.9-8.1,20.7c104.5-75.7,196-151.5,276.1-229.1c31.9,50.2,59.8,102.8,83.7,157.3
		c6.8-10.2,12.6-19,19.8-30.2c29.9,85.9,55.9,182.9,80.1,299.3c-13.9-0.3-25.2-3.4-37.5-0.6c-31,7.2-43-14-48.5-51.3
		c-9.2-59.9-23.9-111.6-37.7-161.1c-1.1-3.9-2.4-7.4-4.3-13.1c-12.8,20.4-26,39.4-38.5,59.1c-2.3,3.5-2.1,11.8-1.2,16.2
		c9.2,40.2,18,82.7,26,127.8c0.9,5.1,1.5,10.2,2.8,18.4c-25.3-0.7-48.5-1-71.3-2.5c-2.7,0-4.1-5.9-4.9-9.9
		c-3.2-19.4-5.9-39-9.9-57.1c-1.7-8.1,1-13.8,6.7-21.8c24-32.9,23.5-33.4,13.9-61.1c-10-27.6-20-53.9-31.2-80.1
		c-19.6,16.8-39.5,33.3-59.6,49.6c-67.1,54.3-139.9,106.9-215.9,159.7c-19.2,13.5-32.1,11.4-45.9,10.2c-2.1-0.2-2.3-7.8-0.9-12.7
		c15.3-58.3,27.2-112.9,35.7-164.3c19.7-118,21.8-218.6,9.6-308.9c-1.1-8.3,1.5-13.4,9.1-20.1c57.4-49.9,111-104,160.3-161.9
		c4-5,6.5-11.1,7.2-17.5c1.2-13.8,0.5-27.4-0.1-42.8c-3.1,3.1-4.9,4.5-6.4,6.2c-69.1,86.1-158.4,172-264.5,256.1
		c-6.9,5.4-9.2,10-8.6,16.9c14,116.1,4.8,247-36.5,407.7c-3.1,12-6.7,24.2-10.7,38.3c-26-0.8-51.2-1.2-75.8-2.7
		c-2.4-0.1-2.8-6.8-1.7-11c21.7-82.9,34.9-158,40.2-227.5c2.8-34.4,3.7-69,2.5-103.5c0-2.1-0.9-4.3,0-6.4
		c12.8-30.7-6.1-50.3-16.8-72c-79.6-156.3-214.3-290.5-377.1-461.6c-52-54.5-106.9-112.2-164-174.3c-7.9-8.6-8.7-13.4-0.4-17.4
		c17.1-8,32.2-17.1,48.7-26.7C398.2,1561.2,594.9,1726.2,709.1,1915.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M1515.1,138c-5.9-8.1-11-15.8-16.1-23.2l2.1-2.4c26.7,3.3,53.9,6.7,82,10.3c0.7-0.9,1.5-1.7,2.3-2.5
		c-72.9-103.2-116.6-219.8-132.2-380.8c3.6-0.6,7.2-1,10.9-1.2c32.7,0.9,65.7,1.6,98.1,3.2c4.7,0.3,9.7,3.6,10.5,8.3
		c20,161.2,77.6,278.1,173.5,389.7c0.9,1.1,1.2,2.4,2.9,5.5c-12.4-1.7-22.7-2.8-32.1-4.8c-8.7-1.7-15,0.3-22.5,5.4
		c-127.3,86.9-240.8,175-329.9,265.1c-79.3,79.6-140,160.2-184.8,241.2c-1.3,2.2-2.5,4.5-4.1,7.2c-2-0.9-4-2-5.9-3.1
		c-23.4-17.5-46.1-35.9-68.4-54.5c-5-4.2-4.3-7.6-1.4-12.6c44.8-79.4,103.6-159.5,178.7-239c65-68.9,142-137.3,228.4-204.9
		C1509.5,143.2,1511.8,140.9,1515.1,138z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2712.5,2477.2c-15.9,3.2-27.1-0.1-40.1-15.9c-87.4-108-181.3-248.9-276.9-391c-8.8-13-16-27-21.5-41.7
		c-66.5-181.3-139.3-353.3-216.9-509.7c-4.3-8.7-7.2-15.3-1.5-19.4c88.1-62.8,204.2-97.1,375-106.9c2.6,0,5.5,0.1,11,0.5
		c0.9,5.3,1.4,8.6,1.9,12c7.7,48.6,15,97.1,23.4,146c1.3,8-1.8,11.1-9.8,11.5c-93.2,4.5-167.9,20.4-231.1,46.9
		c-6.8,3-7,8.3-2.1,19.2c70.2,152.8,135,314.8,194.2,475.6c2.1,5.7,4.1,11.2,6.8,18.9c-17.4-8.8-32.8-16.6-50.9-25.5
		c4.9,7.7,7.9,12.3,11,16.7c71.9,103.8,141.6,203.6,207.4,285.5c5.6,7.1,9.5,15.4,11.5,24.2
		C2707.9,2442.3,2709.9,2459.8,2712.5,2477.2z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2405.7,555.8c-29-31.4-57.3-62-84.6-91.5C2173.9,304.1,2052,171.4,1973.3,36.7c-6.5-11.1-8.3-21.4-2.4-33.8
		c5.6-11.7,8.9-24,13.4-36.7c10.2,0.9,18.7,1.5,30.9,2.4c-55.6-69.9-94.1-138.2-117.4-217.4c12.8,0.3,23.1,0.8,33.2,1
		c24.6,0.7,49.3,1.8,74.1,2.1c8.8,0,13.1,3.1,16.4,10.5c33.9,76.6,87.1,146.9,157.3,220c7.6,8,16.4,12.3,29.2,14.1
		c26.6,3.7,53.8,8.7,81.5,13.3c19.9,3.4,36.2,14.2,38.5,28.3c9.5,58.3,18.2,116.5,27.1,174.8c0,0.2-0.8,0.4-2.8,1
		c-24.2-26.6-47.4-52.2-69.1-77.3c-39.1-45.2-75.4-88.7-104.4-132.3c-8.1-12.3-17.4-16-33.1-18.1c-29.2-3.7-57.5-8.6-87.9-12.8
		c2.7,5.5,4.3,8.9,6.4,12.4c72.1,120.2,179.7,240.3,309.7,380.7c3.7,4,7.5,8.6,8,12.1c9.5,58.3,18.7,116.7,27.8,174.8
		C2408.4,555.9,2407,555.9,2405.7,555.8z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2345.2,2466.4c-23.3-0.7-45.4-0.6-67.7-2.9c-4.4-0.4-10.5-8.3-13.9-14.8c-72.4-134.1-144-269.7-215.6-394.8
		c-35.5-62.2-70.9-121.9-106.2-178.1c-1.3-2.1-2.5-4.5-5.1-8.7c2.9,0,5.9,0.3,8.7,1c19,6,38.2,12,57.7,19.6c5.3,2.6,9.8,6.6,13,11.7
		c109,171.4,218.2,371.1,327.4,561C2344.2,2462.4,2344.8,2464.4,2345.2,2466.4z"/>
            <path :fill="colors[`${id}-p-1`]" d="M2622.7,2475.5c-24.7-0.9-47.5-1.3-70.5-2.7c-3.1-0.2-7.7-5.2-10.2-9c-99.9-142.2-203.3-312.1-307.6-471.6
		c-2.3-3.7-4.5-7.8-8.6-14.8c21.2,9.5,40.8,19.2,60.2,27c16.5,6.5,28.9,19,42.2,39.1c98,148.6,194.8,300,286.9,420.9
		C2617.3,2467.2,2619.3,2470.5,2622.7,2475.5z"/>
        </g>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";

export default {
    mixins: [pattern]
}
</script>