<template>
    <pattern :id="id" x="0" y="0" width="150" height="150" viewBox="0,0,8,8" patternUnits="userSpaceOnUse" :patternTransform="transform">
        <polygon points="0,0 4,0 0,4" :fill="colors[`${id}-p-0`]"></polygon>
        <polygon points="0,8 8,0 8,4 4,8" :fill="colors[`${id}-p-0`]"></polygon>
        <polygon points="0,4 0,8 8,0 4,0" :fill="colors[`${id}-p-1`]"></polygon>
        <polygon points="4,8 8,8 8,4" :fill="colors[`${id}-p-1`]"></polygon>
    </pattern>
</template>
<script>
import pattern from "./pattern.js";
export default {
    mixins: [pattern]
}
</script>