import 'bootstrap';
import { createApp } from 'vue'
import App from './App.vue'
import './scss/style.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faCheck, faPlus, faTimes, faArrowLeft, faMinus, faSpinner, faChevronLeft, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faHandPointer, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faSearch, faCheck, faPlus, faTimes, faHandPointer, faArrowLeft, faMinus, faSpinner, faChevronLeft, faChevronRight, faChevronDown, faCheckCircle)

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')